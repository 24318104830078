import { ListView, EditView } from "@/core/magnet";
import app from "@/app";

export default ListView.extend({
  module: "integrations",
  templates: {
    initial: require("../templates/list.hbs"),
  },
  limit: 100,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-request-integration-chat]": "openRequestIntegrationChat",
    });
  },
  organize: function () {
    this.additionalsData.integrations.map(
      function (item) {
        item.enabled = this.collection.findWhere({ id: item.id }) ? true : false;
        item.hidden = (item.id === "transactional" || item.id === 'tokkobroker' || item.id === 'vnda') && !item.enabled;
      }.bind(this)
    );
  },
  openRequestIntegrationChat: function (e) {
    e.preventDefault();
    app.integrations.intercom.message(window.lang.integrations.requestNewIntegration);
  },
});
