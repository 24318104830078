<template>
  <div ref="refElement" class="rounded-md bg-white">
    <Transition
      mode="out-in"
      leave-active-class="transition duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      enter-active-class="transition duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      @enter="updateMode"
      @leave="updateMode"
    >
      <div :key="mode" class="rounded-md">
        <div v-show="mode === 'simple'" class="flex w-full space-x-2 px-3 py-2">
          <button
            v-for="color in colors"
            :key="color"
            class="glow-ring relative h-6 w-6 rounded-full focus-visible:outline-none"
            :style="{
              color: `${color}`,
            }"
            @click="() => updateColor(color)"
          >
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 8 8">
              <circle cx="4" cy="4" r="3" />
            </svg>
            <CheckIcon v-show="selected === color" class="absolute left-1.5 top-1.5 h-3 w-3 text-black" />
          </button>
          <div class="mx-4 my-auto h-4 border border-gray-200" />
          <IconButton
            class="m-0 h-6 w-6 rounded p-0 px-1 focus-visible:outline-none"
            size="min"
            :label="t('colorPickerButton')"
            @click="toggleMode"
          >
            <span class="h-6 w-6 text-gray-500"> # </span>
          </IconButton>
        </div>
        <ColorPicker
          v-show="mode === 'advance'"
          class="box-content"
          theme="light"
          :color="selected"
          @changeColor="changeColor"
        />
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";

// Components
import IconButton from "@atoms/IconButton.vue";
import { ColorPicker } from "vue-color-kit";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Application
import { useColorsApplication } from "@application";

type Mode = "simple" | "advance";

const colorsApp = useColorsApplication();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    selected: HexColor;
    open: boolean;
  }>(),
  {
    open: false,
  }
);

const emit = defineEmits<{
  (e: "update:color", color: HexColor): void;
  (e: "update:mode", mode: Mode): void;
}>();

const updateColor = (color: HexColor) => {
  emit("update:color", color);
};

const updateMode = () => {
  emit("update:mode", mode.value);
};

const colors: Array<HexColor> = colorsApp.getTagsColors().map((color) => color.hexValue);

const mode = ref<Mode>("simple");
const toggleMode = () => {
  mode.value = mode.value === "advance" ? "simple" : "advance";
  updateMode();
};

watchEffect(() => {
  if (props.open === false) {
    emit("update:mode", "simple");
    mode.value = "simple";
  }
});

// Methods
const changeColor = (color) => {
  updateColor(color.hex);
};

// Expose
const refElement = ref();
defineExpose({
  refElement,
});
</script>

<style scoped>
.glow-ring:hover {
  box-shadow: 0 0 4px currentColor, inset 0 0 4px currentColor;
}

.glow-ring:focus-visible {
  box-shadow: 0 0 4px currentColor, inset 0 0 4px currentColor;
}
</style>

<i18n lang="json">
{
  "es": {
    "colorPickerButton": "Color personalizado"
  },
  "pt": {
    "colorPickerButton": "Cor personalizada"
  }
}
</i18n>
