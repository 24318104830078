import app from "@/app";
import { EditView } from "@/core/magnet";
import dashboardTpl from "../templates/index.hbs";

export default EditView.extend({
  module: "dashboard",
  templates: {
    initial: dashboardTpl,
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    var isChildLimited = false;
    if (app.session.attributes?.details?.account?.plan?.type) {
      isChildLimited =
        app.session.attributes.details.account.plan.type === "CHILD_LIMITED";
    }
    this.item.set("isChildLimited", isChildLimited);
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Draw charts
    $(".chart").easyPieChart({
      scaleColor: false,
      lineWidth: 7,
      size: 100,
    });

    app.setPlan(this.item.attributes.plan);

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },
});
