/* eslint-disable prettier/prettier */
import { Collection } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Collection.extend({
  url: function () {
    return "automations/" + this.automationId() + "/activity";
  },
  parse: function (response) {
    return response.data;
  },
  additionals: {
    automation: function (collection) {
      return $.get("automations/" + collection.automationId());
    },
    metrics: function (collection) {
      var query = "";
      if (
        collection.query.filter &&
        collection.query.filter.created &&
        collection.query.filter.created.gtrel
      ) {
        query =
          "filters.created.gtrel=" + collection.query.filter.created.gtrel;
      }
      if (collection.query.filter && collection.query.filter.step_id) {
        query =
          (query ? query + "&" : "") +
          "filters.step_id=" +
          collection.query.filter.step_id;
      }

      return $.get(
        `automations/${collection.automationId()}/metrics${query ? "?" + query : ""
        }`
      );
    },
    plan: function () {
      return $.get("plan");
    },
  },
  parseAdditionals: function (additionals) {
    if (_.isDefined(additionals.metrics)) {
      additionals.metrics.total.openedP = additionals.metrics.total.sent
        ? parseInt(
          (100 * additionals.metrics.total.opened) /
          additionals.metrics.total.sent
        )
        : 0;
      additionals.metrics.total.clickedP = additionals.metrics.total.sent
        ? parseInt(
          (100 * additionals.metrics.total.clicked) /
          additionals.metrics.total.sent
        )
        : 0;
    }

    // Return parsed additionals
    return additionals;
  },
  automationId: function () {
    return this.query.id;
  },
});
