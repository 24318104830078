import { ListView } from "@/core/magnet";
import app from "@/app";

export default ListView.extend({
  module: "accounts",
  templates: { initial: require("../templates/list.hbs") },
  gender: "F",
  limit: 1000,
  fetchTimeout: 30 * 1000,
  query: {
    order: "name asc"
  },
  sortOptions: [
    { column: "name", type: "asc", label: "name" },
    { column: "created", type: "desc", label: "created" },
    { column: "lastUsage", type: "desc", label: "lastUsage" }
  ],
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=goto-account]": "gotoAccount"
    });
  },

  // Shows action buttons according to the checked elements
  toggleActions: function () {
    var self = this,
      toolbar = self.$("[data-role=toolbar]"),
      none = self.$("[data-type=none]"),
      onePlus = self.$("[data-type=one-plus]"),
      checkboxes = self.$("[data-action=check]");
    if (checkboxes.is(":checked")) {
      none.addClass("hide");
      onePlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    } else {
      none.removeClass("hide");
      onePlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Hide enable action if no disabled accounts are selected
    if (checkboxes.filter("[data-item-disabled]").is(":checked")) {
      self.$("[data-method=enable]").removeClass("hide");
    } else {
      self.$("[data-method=enable]").addClass("hide");
    }

    // Hide disable if all checked items are disabled
    if (checkboxes.filter("[data-item-disabled]:checked").length === checkboxes.filter(":checked").length) {
      self.$("[data-method=disable]").addClass("hide");
    } else {
      self.$("[data-method=disable]").removeClass("hide");
    }
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions

    app.layout.updateLayout();
  },

  gotoAccount: function (e) {
    e.preventDefault();
    this.changeAccount(e);
  },

  changeAccount: function (e) {
    const account = $(e.currentTarget).data("code");
    app.router.changeAccount(account);
  }
});
