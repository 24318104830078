import { EditView } from "@/core/magnet";
import app from "@/app";
import editTpl from "../templates/edit.hbs";

export default EditView.extend({
  module: "fields",
  templates: {
    initial: editTpl,
    show: editTpl,
    edit: editTpl,
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "keyup [name=name]": "updateTagName",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Runs after updating a model
  afterUpdate: function (attributes) {
    this.cache.removeItem("columns");
    this.showUpdatedMessage(attributes);
    this.back();
  },

  // Runs after creating a model
  afterCreate: function (attributes) {
    this.cache.removeItem("columns");
    this.showCreatedMessage(attributes);
    this.back();
  },

  // Update tag name
  updateTagName: function (e) {
    var text = $(e.currentTarget).val(),
      regex = new RegExp(" ", "g"),
      tag = text.replace(regex, "").toUpperCase(),
      replacements = [
        [/Á/gi, "A"],
        [/É/gi, "E"],
        [/Í/gi, "I"],
        [/Ó/gi, "O"],
        [/Ú/gi, "U"],
        [/Ñ/gi, "N"],
      ];
    replacements.forEach(
      function (replacement) {
        tag = tag.replace(replacement[0], replacement[1]);
      }.bind(this)
    );
    this.$("[name=tag]").val(tag).attr("data-changed", 1);
  },
});
