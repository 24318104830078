<template>
  <div>
    <div>
      <p class="text-base font-medium">
        {{ t("text.title") }}
      </p>
      <p>{{ t("text.description") }}</p>
      <i18n-t keypath="preview.text">
        <template #link>
          <a :href="linkPreview" target="_blank"
            ><span class="font-medium">{{ t("preview.link") }}</span></a
          >
        </template>
      </i18n-t>
    </div>

    <div class="mt-4">
      <label class="font-bold"> {{ t("edition.url.label") }} </label>
      <InputUpload
        file-types=".png, .jpg, .jpeg, .gif"
        :file-name="fileUrl"
        :loading="uploadingImage"
        @clear="clearFile"
        @update:model-value="updateImageFile"
      />
      <input name="gc_landing_logo" type="hidden" :value="fileUrl" />
      <p class="mt-2">{{ t("edition.url.description") }}</p>
    </div>

    <div class="mt-4">
      <label class="font-bold"> {{ t("edition.color.label") }} </label>
      <InputColorPicker v-model="giftCardColor" />
      <input name="gc_landing_color" type="hidden" :value="giftCardColor" />
      <p class="mt-2">{{ t("edition.color.description") }}</p>
    </div>

    <div class="mt-4">
      <label class="font-bold"> {{ t("edition.background.label") }} </label>
      <InputColorPicker v-model="giftCardBackground" />
      <input name="gc_landing_bg" type="hidden" :value="giftCardBackground" />
      <p class="mt-2">{{ t("edition.background.description") }}</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import md5 from "md5";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

// Components
import InputColorPicker from "@atoms/InputColorPicker.vue";
import InputUpload from "@molecules/InputUpload.vue";

// API
import { useAccountFiles } from "@api/modules/accountfiles";

interface FileAPIResponse {
  success: boolean;
  data: {
    id: string;
    name: string;
    folder: string; //"/imageTest",
    created: string; //"2024-06-25T18:39:29Z",
    extension: string; // "jpeg",
    type: "file";
    location: {
      file_name: string; //"miak_wdkzud",
      absolute: string; //"https://files.myperfit.net/francoperfittn/ba142a3f/miak_wdkzud.jpg",
      relative: string; //"/francoperfittn/ba142a3f/miak_wdkzud.jpg"
    };
    size: number;
    mime_type: string; //"image/jpeg"
  };
}

// Props
const props = withDefaults(
  defineProps<{
    inputUrlValue?: string;
    inputColorValue?: string;
    inputBackgroundValue?: string;
  }>(),
  {},
);

// States
const { t } = useI18n();
const accountFiles = useAccountFiles();
const fileUrl = ref("");
const giftCardColor = ref("");
const giftCardBackground = ref("");
const uploadingImage = ref(false);

// Computed
const linkPreview = computed(() => {
  return "https://sm.pemres.net/giftcard/" + encodedInputs();
});

// Conditions Props
if (props.inputUrlValue) {
  fileUrl.value = props.inputUrlValue;
}
if (props.inputColorValue) {
  giftCardColor.value = props.inputColorValue;
}
if (props.inputBackgroundValue) {
  giftCardBackground.value = props.inputBackgroundValue;
}

// Functions
const clearFile = () => {
  fileUrl.value = "";
};
const encodedInputs = () => {
  const couponValue = 0;
  const couponCode = "GIFTTEST";
  const couponUrl = "";

  const securityString = couponValue + couponCode + couponUrl;
  const securityHash = md5(securityString);
  const securityHashSlice = securityHash.slice(-6);

  let encodedCoupon = {
    code: couponCode,
    endDate: "",
    landingBg: giftCardBackground.value,
    landingColor: giftCardColor.value,
    landingLogo: fileUrl.value,
    security: securityHashSlice,
    urlStore: couponUrl,
    value: couponValue,
  };

  return generateGiftCardEncoded(encodedCoupon);
};
const generateGiftCardEncoded = (coupon) => {
  const jsonString = JSON.stringify(coupon);
  const base64String = btoa(jsonString);
  return base64String;
};
const uploadImage = async (file): Promise<FileAPIResponse> => {
  const res: FileAPIResponse = (await accountFiles.postImages({
    file,
    folder: "/imageTest",
  })) as unknown as FileAPIResponse;
  return res;
};
const updateImageFile = async (newFile: unknown) => {
  uploadingImage.value = true;

  try {
    const uploadedFile = await uploadImage(newFile);
    fileUrl.value = uploadedFile.data.location.absolute;
  } finally {
    uploadingImage.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "text": {
      "title": "Edición de la Gift Card",
      "description": "Una vez realizada la compra, el contacto recibirá un mail donde podrá compartir la Gift Card para un regalo."
    },
    "preview": {
      "text": "Desde este {link} podrás previsualizar la Gift Card que el contacto podrá compartir.",
      "link": "link"
    },
    "edition": {
      "url": {
        "label": "Logo de la Gift Card",
        "description": "Opcional. Logo de la tienda que aparecerá en la Gift Card."
      },
      "color": {
        "label": "Color de la tipografía de la Gift Card",
        "description": "Opcional: Código del color que aparecerá en los textos de la Gift Card."
      },
      "background": {
        "label": "Color del fondo de la Gift Card",
        "description": "Opcional. Código del color que aparecerá como fondo de la Gift Card."
      }
    }
  },
  "pt": {
    "text": {
      "title": "Edição do Gift Card",
      "description": "Uma vez realizada a compra, o contato receberá um e-mail onde poderá compartilhar o Gift Card como presente."
    },
    "preview": {
      "text": "A partir deste {link} você poderá visualizar o Gift Card que o contato poderá compartilhar.",
      "link": "link"
    },
    "edition": {
      "url": {
        "label": "Logo do Gift Card",
        "description": "Opcional. Logo da loja que aparecerá no Gift Card."
      },
      "color": {
        "label": "Cor da tipografia do Gift Card",
        "description": "Opcional: Código da cor que aparecerá nos textos do Gift Card."
      },
      "background": {
        "label": "Cor de fundo do Gift Card",
        "description": "Opcional. Código da cor que aparecerá como fundo do Gift Card."
      }
    }
  }
}
</i18n>
