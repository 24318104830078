<template>
  <div ref="elementRef" :class="[!everyOptionHasValue && 'divide-y divide-gray-100 ', '  rounded-md bg-white py-3']">
    <div class="grid h-auto max-h-[15rem] grid-cols-1 gap-1 overflow-y-auto">
      <div
        v-for="option in attributeOptions.options"
        ref="optionsRef"
        :key="option.id"
        class="my-1 flex items-center space-x-4 px-4"
      >
        <input
          :id="option.id"
          :name="option.id"
          type="radio"
          :checked="selectedOption?.id === option.id ?? false"
          class="h-4 w-4 border-gray-300 text-sky-500 outline-none focus:!outline-none focus:ring-sky-400"
          @change="() => checkOption(option)"
          @keydown.enter="() => checkOption(option)"
        />
        <label :for="option.id" class="m-0 block text-sm font-normal text-gray-400">{{ option.text }}</label>
      </div>
    </div>
    <div v-if="!everyOptionHasValue" class="mt-2 flex justify-between space-x-4 px-4 pt-3">
      <div v-if="selectedOption?.value === undefined" class="w-auto flex-shrink flex-grow-0">
        <SimpleInput
          v-if="!attributeOptions.valueOptions"
          :model-value="selected?.value?.toString()"
          :has-error="error"
          :type="selected?.type === 'number' ? 'number' : 'text'"
          @update:model-value="updateSelectedValue"
          @keypress.enter="close"
        />
        <ComboBox
          v-else
          :model-value="selectedToDataItem"
          :data-items="attributeOptions.valueOptions"
          :show-key="false"
          @update:model-value="updateSelectedValueDataItem"
        />
      </div>
      <button
        class="my-auto flex h-8 w-8 flex-shrink-0 flex-grow items-center justify-center rounded-full bg-sky-400 hover:bg-sky-500 focus-visible:bg-sky-500 focus-visible:outline-none"
        @click="close"
      >
        <CheckIcon class="h-5 w-5 text-white" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";

// Components
import SimpleInput from "@/vue/components/atoms/SimpleInput.vue";
import ComboBox from "@molecules/ComboBox.vue";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";

// Utils
import { onClickOutside } from "@vueuse/core";

// Types
import type { DataItem } from "@domain/data";
import type { AttributeWithId, AttributeComparisonOption, AttributeComparisonOptions } from "@domain/predicates";

const props = withDefaults(
  defineProps<{
    selected: AttributeWithId;
    attributeOptions: AttributeComparisonOptions;
    error?: boolean;
  }>(),
  {
    error: false,
  }
);

const emit = defineEmits<{
  (e: "update:selected", option: AttributeWithId): void;
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

// value
const updateSelectedValue = (value: string) => {
  emit("update:selected", {
    ...props.selected,
    value: props.selected.type === "number" ? Number(value) : value,
  });
};
const updateSelectedValueDataItem = (item: DataItem) => {
  emit("update:selected", { ...props.selected, value: item.key, name: item.value.toString() });
};

const checkOption = (option: AttributeComparisonOption) => {
  if (option.value !== undefined) {
    return emit("update:selected", {
      ...props.selected,
      name: option?.name,
      value: option.value,
      operator: option.operator,
    });
  }

  if (option.operator !== "exists" && props.selected.operator !== "exists") {
    return emit("update:selected", {
      ...props.selected,
      name: option?.name || props.selected.name,
      operator: option.operator,
    });
  }

  emit("update:selected", { ...props.selected, name: option?.name, value: "", operator: option.operator });
};

// Option selected
const isSelectedOption = (option: AttributeComparisonOption, attribute: AttributeWithId): boolean => {
  if (option.operator === "exists" && option.value !== undefined) {
    return attribute.value === option.value;
  }

  if (option.value !== undefined) {
    return option.operator === attribute.operator && option.value === attribute.value;
  }

  return option.operator === attribute.operator;
};

const getAttributeOption = (attribute: AttributeWithId): AttributeComparisonOption | undefined =>
  props.attributeOptions.options.find((option) => isSelectedOption(option, attribute));

const selectedOption = computed(() => getAttributeOption(props.selected));
const everyOptionHasValue = computed(() => {
  return props.attributeOptions.options.every((option) => option.value);
});

// values
const selectedToDataItem = computed(() => {
  if (!props.selected.value || typeof props.selected.value === "boolean") return undefined;

  const element: DataItem = {
    key: props.selected.value,
    value: props.selected.name ?? props.selected.value,
  };
  return element;
});

// Events
const elementRef = ref();
onClickOutside(elementRef, close);

const optionsRef = ref();

onMounted(() => {
  optionsRef.value?.[0].firstChild?.focus();
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
