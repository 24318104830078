<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <TextParagraph>
      <i18n-t keypath="description">
        <template #link>
          <a href="https://docs.myperfit.com/articles/1157486" target="_blank">
            {{ t("descriptionLink") }}
          </a>
        </template>
      </i18n-t>
    </TextParagraph>
    <div class="space-y-4">
      <div class="flex space-x-4">
        <SelectMenuLabel
          v-model:selected="selectedField"
          :rem-height="11"
          :label="t('filterLabel')"
          :data-items="fieldDataItems"
        />
        <SelectMenuLabel
          v-model:selected="selectedCondition"
          :rem-height="11"
          :label="t('conditionLabel')"
          :data-items="conditions"
        />
      </div>
      <div class="relative space-y-8">
        <FormInput
          v-if="useTextValue"
          ref="inputRef"
          :readonly="useCalendar"
          v-model="value"
          :type="inputType"
          :label="t('valueLabel')"
          :error="error ? t('errorEmptyValue') : undefined"
          class="w-full"
          @focus="openCalendar"
        />
        <SelectMenuLabel
          v-else-if="!useCalendar && !useTextValue && !isBooleanCondition"
          v-model:selected="selectedValueOption"
          :label="t('valueLabel')"
          :data-items="valueOptions"
        />
        <DatePicker
          v-if="useCalendar"
          ref="datePickerRef"
          v-show="calendarOpened"
          :model-value="dateSelected"
          :model-config="datePickerConfig"
          :locale="locale"
          :columns="1"
          :step="1"
          @update:model-value="updateDateValue"
          class="absolute top-8 z-10"
        />

        <div class="border-b border-gray-200" />
        <FormInput
          v-model="name"
          :error="getNameErrorMessage()"
          :label="t('newListNameLabel')"
          class="w-full"
          @input="clearNameError"
        />
      </div>
    </div>
    <template #buttons>
      <SimpleButton :disabled="duplicatedNameError" :loading="loading" @click="filter">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import { DataItem, DataItems } from "@molecules/SelectMenuLabel.vue";
import FormInput from "@molecules/FormInput.vue";

import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";
import { formatDate } from "@helpers/formatters";
import { onClickOutside } from "@vueuse/core";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";
import { Field, Fields } from "@domain/fields";

// Type
import { AxiosError } from "axios";

const { t, locale } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    id: string;
    fields: Fields;
    listName: string;
    open: boolean;
  }>(),
  {
    open: false,
  },
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const loading = ref(false);
const value = ref("");

// DatePicker config
const datePickerConfig = ref({
  start: {
    timeAdjust: "00:00:00",
  },
  end: {
    timeAdjust: "23:59:59",
  },
});
const dateSelected = ref();
const calendarOpened = ref(false);
const openCalendar = () => {
  if (!useCalendar.value) return;
  calendarOpened.value = true;
};
const closeCalendar = () => {
  calendarOpened.value = false;
};

const inputRef = ref();
const datePickerRef = ref();

onClickOutside(
  datePickerRef,
  () => {
    closeCalendar();
  },
  {
    ignore: [inputRef],
  },
);

const updateDateValue = (date: Date) => {
  dateSelected.value = date;
  value.value = formatDate(date, "y-MM-dd");
  closeCalendar();
};

const selectedField = ref<DataItem>();
const fieldDataItems = computed<DataItems>(() => {
  return props.fields.map((field) => ({
    key: field.id.toString(),
    value: field.name,
    data: field,
  }));
});

const inputType = computed<"number" | "text">(() => {
  if (!selectedField.value) return "text";

  if ((selectedField.value?.data as Field).format === "INT") return "number";
  return "text";
});

const isBooleanCondition = computed<boolean>(() => {
  return ["SET", "NOT_SET"].includes(selectedCondition.value.key);
});

const useTextValue = computed<boolean>(() => {
  const fieldTags = ["SOURCE", "GENDER", "LANGUAGE"];

  if (!selectedField.value) return true;

  if (fieldTags.includes((selectedField.value?.data as Field)?.tag) || isBooleanCondition.value) return false;
  return true;
});

const useCalendar = computed<boolean>(() => {
  if (!selectedField.value) return false;

  if ((selectedField.value?.data as Field)?.format === "DATE") return true;
  return false;
});

const selectedValueOption = ref<DataItem>();
const valueOptions = computed<DataItems>(() => {
  const optionsByTag: Record<string, DataItems> = {
    SOURCE: [
      {
        key: "MANUAL",
        value: t("sources.MANUAL"),
      },
      {
        key: "OPTIN_FORM",
        value: t("sources.OPTIN_FORM"),
      },
      {
        key: "IMPORTED_CSV",
        value: t("sources.IMPORTED_CSV"),
      },
      {
        key: "INTEGRATION",
        value: t("sources.INTEGRATION"),
      },
    ],
    GENDER: [
      {
        key: "M",
        value: t("gender.M"),
      },
      {
        key: "F",
        value: t("gender.F"),
      },
    ],
    LANGUAGE: [
      {
        key: "es",
        value: t("languages.es"),
      },
      {
        key: "en",
        value: t("languages.en"),
      },
      {
        key: "de",
        value: t("languages.de"),
      },
      {
        key: "fr",
        value: t("languages.fr"),
      },
      {
        key: "it",
        value: t("languages.it"),
      },
      {
        key: "pt",
        value: t("languages.pt"),
      },
    ],
  };

  return optionsByTag?.[(selectedField.value?.data as Field)?.tag] ?? [];
});

watch([valueOptions], () => {
  selectedValueOption.value = valueOptions.value?.[0];
});

const conditions = computed<DataItems>(() => {
  const defaultConditions = [
    {
      key: "EQUALS",
      value: t("filterConditions.EQUALS"),
    },
    {
      key: "NOT_EQUALS",
      value: t("filterConditions.NOT_EQUALS"),
    },
    {
      key: "SET",
      value: t("filterConditions.SET"),
    },
    {
      key: "NOT_SET",
      value: t("filterConditions.NOT_SET"),
    },
  ];
  const dateConditions = [
    {
      key: "BEFORE",
      value: t("filterConditions.BEFORE"),
    },
    {
      key: "AFTER",
      value: t("filterConditions.AFTER"),
    },
  ];
  const textConditions = [
    {
      key: "CONTAINS",
      value: t("filterConditions.CONTAINS"),
    },
    {
      key: "NOT_CONTAINS",
      value: t("filterConditions.NOT_CONTAINS"),
    },
    {
      key: "STARTS_WITH",
      value: t("filterConditions.STARTS_WITH"),
    },
    {
      key: "ENDS_WIDTH",
      value: t("filterConditions.ENDS_WIDTH"),
    },
  ];

  const numberConditions = [
    {
      key: "GREATER_THAN",
      value: t("filterConditions.GREATER_THAN"),
    },
    {
      key: "LESS_THAN",
      value: t("filterConditions.LESS_THAN"),
    },
  ];

  const conditionsByType = {
    TEXT: [...defaultConditions, ...textConditions],
    DATE: [...defaultConditions, ...dateConditions],
    INT: [...defaultConditions, ...numberConditions],
  };

  const conditionsByFields = {
    SOURCE: [
      {
        key: "EQUALS",
        value: t("filterConditions.EQUALS"),
      },
      {
        key: "NOT_EQUALS",
        value: t("filterConditions.NOT_EQUALS"),
      },
    ],
    GENDER: [
      {
        key: "EQUALS",
        value: t("filterConditions.EQUALS"),
      },
    ],
    LANGUAGE: defaultConditions,
  };

  return (
    conditionsByFields?.[(selectedField.value?.data as Field)?.tag ?? ""] ??
    conditionsByType?.[(selectedField.value?.data as Field)?.format ?? "TEXT"]
  );
});

const selectedCondition = ref<DataItem>(conditions.value[0]);
const name = ref(
  t("defaultName", {
    name: props.listName,
    field: selectedField.value?.value,
    condition: selectedCondition.value.value,
    value: value.value,
  }).slice(0, 50),
);

watch([selectedCondition, selectedValueOption, selectedField, value], () => {
  error.value = false;
  name.value = t("defaultName", {
    name: props.listName,
    field: selectedField.value?.value,
    condition: selectedCondition.value.value,
    value: useTextValue.value || useCalendar.value ? value.value : selectedValueOption.value?.value,
  }).slice(0, 50);
});

watch([selectedField], () => {
  if (useCalendar.value) {
    value.value = "";
  }
});

const error = ref(false);
const duplicatedNameError = ref(false);
const nameLengthError = ref(false);
const clearNameError = () => {
  duplicatedNameError.value = false;
  nameLengthError.value = false;
};

const getNameErrorMessage = (): string | undefined => {
  if (duplicatedNameError.value) return t("duplicatedName");
  if (nameLengthError.value) return t("maxLengthName");
  return undefined;
};

const filter = async () => {
  if (error.value) return;
  if ((useTextValue.value || useCalendar.value) && !isBooleanCondition && value.value === "") {
    error.value = true;
    return;
  }

  if (name.value.length > 50) {
    nameLengthError.value = true;
    return;
  }

  try {
    loading.value = true;

    await listAPI.filter({
      id: props.id,
      condition: selectedCondition.value.key as any,
      fieldId: (selectedField.value?.data as Field)?.id.toString(),
      newListName: name.value,
      value: useTextValue.value || useCalendar.value ? value.value : selectedValueOption.value?.key ?? "",
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.filter.title"),
      text: t("notifications.filter.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } catch (e) {
    duplicatedNameError.value = (e as AxiosError<any>).response?.data?.error?.validationErrors.newListName;
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Filtrar",
    "description": "Se creará una nueva lista con los contactos que cumplan esta condición. {link}",
    "descriptionLink": "Leer más",
    "filterLabel": "Filtrar por el campo",
    "conditionLabel": "Condición",
    "valueLabel": "Valor",
    "newListNameLabel": "Nombre de la nueva lista",
    "defaultName": "{name} - {field} {condition} {value}",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "errorEmptyValue": "Este campo no puede estar vacio",
    "duplicatedName": "El nombre de la lista ya existe",
    "maxLengthName": "El nombre no puede superar los 50 caracteres",
    "filterConditions": {
      "EQUALS": "Igual a",
      "NOT_EQUALS": "Distinto de",
      "CONTAINS": "Contiene",
      "NOT_CONTAINS": "No contiene",
      "STARTS_WITH": "Empieza con",
      "ENDS_WIDTH": "Termina con",
      "SET": "Tiene contenido",
      "NOT_SET": "No tiene contenido",
      "GREATER_THAN": "Mayor a",
      "LESS_THAN": "Menor a",
      "BEFORE": "Antes de",
      "AFTER": "Después de"
    },
    "sources": {
      "MANUAL": "Creado manualmente",
      "OPTIN_FORM": "Suscripto mediante formulario",
      "IMPORTED_CSV": "Importado desde CSV",
      "INTEGRATION": "Obtenido desde integración"
    },
    "gender": {
      "M": "Masculino",
      "F": "Femenino"
    },
    "languages": {
      "es": "Español",
      "en": "Inglés",
      "de": "Alemán",
      "fr": "Francés",
      "it": "Italiano",
      "pt": "Portugués"
    },
    "notifications": {
      "filter": {
        "title": "Filtrando lista",
        "text": "La lista se está filtrando"
      }
    }
  },
  "pt": {
    "title": "Filtrar",
    "description": "Será criada uma nova lista com os contatos que atendem a esta condição. {link}",
    "descriptionLink": "Leia mais",
    "filterLabel": "Filtrar por campo",
    "conditionLabel": "Condição",
    "valueLabel": "Valor",
    "newListNameLabel": "Nome da nova lista",
    "defaultName": "{name} - {field} {condition} {value}",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "errorEmptyValue": "Este campo no puede estar vacio",
    "duplicatedName": "O nome da lista já existe",
    "maxLengthName": "O nome não pode ultrapassar 50 caracteres",
    "filterConditions": {
      "EQUALS": "Igual a",
      "NOT_EQUALS": "Diferente de",
      "CONTAINS": "Contém",
      "NOT_CONTAINS": "Não contém",
      "STARTS_WITH": "Começa com",
      "ENDS_WIDTH": "Termina com",
      "SET": "Tem conteúdo",
      "NOT_SET": "Não tem conteúdo",
      "GREATER_THAN": "Maior que",
      "LESS_THAN": "Menor que",
      "BEFORE": "Antes de",
      "AFTER": "Depois de"
    },
    "sources": {
      "MANUAL": "Criado manualmente",
      "OPTIN_FORM": "Inscrito através de formulário",
      "IMPORTED_CSV": "Importado de CSV",
      "INTEGRATION": "Obtido através de integração"
    },
    "gender": {
      "M": "Masculino",
      "F": "Feminino"
    },
    "languages": {
      "es": "Espanhol",
      "en": "Inglês",
      "de": "Alemão",
      "fr": "Francês",
      "it": "Italiano",
      "pt": "Português"
    },
    "notifications": {
      "filter": {
        "title": "Filtrando lista",
        "text": "A lista está sendo filtrada"
      }
    }
  }
}
</i18n>
