<template>
  <div class="mr-8 py-3">
    <div class="flex items-center gap-2">
      <a :href="`/contacts/${id}`" class="line-clamp-1 text-sm font-medium text-sky-400">
        <slot />
      </a>
      <div class="flex shrink-0 justify-center">
        <div v-if="status === 'ACTIVE'" class="flex">
          <StarIcon v-for="index in starCount" :key="`starCount${index}`" class="h-4 w-4 text-sky-400" />
          <StarIcon v-for="index in startEmptyCount" :key="`startEmptyCount${index}`" class="h-4 w-4 text-gray-200" />
        </div>
        <div v-else class="pr-4">
          <SimpleBadge theme="red" square>
            {{ t(`status.${status}`) }}
          </SimpleBadge>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Componnets
import SimpleBadge from "@atoms/SimpleBadge";

// Icons
import { StarIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  id: string;
  quality: number;
  status: string;
}>();

const MAX_STARS = 3;

const starCount = computed<number>(() => {
  return Math.ceil(props.quality * MAX_STARS);
});
const startEmptyCount = computed<number>(() => {
  return MAX_STARS - starCount.value;
});
</script>
<i18n lang="jsonc">
{
  "es": {
    "status": {
      "ACTIVE": "Activo",
      "BOUNCED": "Rebotado",
      "UNSUBSCRIBED": "Desuscripto",
      "COMPLAINED": "SPAM"
    }
  },
  "pt": {
    "status": {
      "ACTIVE": "Ativo",
      "BOUNCED": "Rejeitado",
      "UNSUBSCRIBED": "Descadastrado",
      "COMPLAINED": "SPAM"
    }
  }
}
</i18n>
