import { EditView } from "@/core/magnet";
import { objHas } from "@/helpers/utils";
import { h } from "vue";
import AlertBox from "@atoms/AlertBox.vue";
import { mountComponent } from "@/helpers/vue";
import app from "@/app";
const moment = window.moment;

export default EditView.extend({
  module: "users",
  templates: {
    initial: require("../templates/show.hbs"),
    show: require("../templates/show.hbs"),
    edit: require("../templates/edit.hbs"),
  },
  fetchParams: {
    headers: {
      "Account-Prefix": false,
    },
  },
  saveParams: {
    headers: {
      "Account-Prefix": false,
    },
  },
  destroyParams: {
    headers: {
      "Account-Prefix": false,
    },
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click .toggle-password": "togglePassword",
      "blur [name=email]": "autocompleteUsername",
      "click [data-modal=open-change-password-modal]": "openChangePasswordModal",
      "submit [data-form=change-password]": "changePassword",
      "click [data-modal=open-api-key-modal]": "openApiKeyModal",
      "click [data-action=delete-api-key]": "deleteApiKey",
      "click [data-action=copy-api-key]": "copyApiKey",
      "change [name=timezone]": "showLocalTime",
    });
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    this.enableRestricted = false;
    if (objHas(app.session.attributes, "details", "plan", "type")) {
      if (app.session.attributes.details.plan.type === "FREE") {
        this.enableRestricted = true;
      }
    }
    callback();
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      lang: window.lang,
      enableRestricted: this.enableRestricted,
      config: window.config,
      session: app.session,
    };
  },

  // Runs custom actions after renderization
  afterRender: function () {
    mountComponent(
      h(AlertBox, null, {
        default: () => h("span", null, window.lang.users.storeAdminAlert),
      }),
      null,
      "#vue-storeadmin-alert",
    );

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    this.showLocalTime();
  },

  // Toggles from password to text input
  togglePassword: function (e) {
    var target = $(e.currentTarget),
      destination = target.parent().find("input"),
      type = destination.attr("type");
    if (type == "text") {
      destination.attr("type", "password");
      target.find("i").removeClass("fa-eye-slash").addClass("fa-eye");
    } else {
      destination.attr("type", "text");
      target.find("i").removeClass("fa-eye").addClass("fa-eye-slash");
    }
  },

  // Completes the username based on the email
  autocompleteUsername: function (e) {
    var email = $(e.currentTarget).val(),
      username = email.split("@"),
      destination = $("[name=username]");
    username = username[0].replace(/[^a-zA-Z0-9]/gi, "").toLowerCase();
    if (destination.val() === "") {
      destination.val(username).attr("data-changed", 1);
    }
  },

  // Gets a form data
  getFormData: function (form) {
    var self = this,
      fields = self.getFormFieldsObject(form),
      passNeverExpires = $("[name=passNeverExpires]"),
      cascadePermissions = $("[name=cascadePermissions]");

    // Set options to booleans
    if (passNeverExpires.data("changed")) {
      fields.passNeverExpires = passNeverExpires.is(":checked");
    }
    if (cascadePermissions.data("changed")) {
      fields.cascadePermissions = cascadePermissions.is(":checked");
    }

    // Return
    return fields;
  },

  // Shows a message when an item is created
  showCreatedMessage: function (attributes) {
    var self = this,
      message;
    message = [
      window.lang["article" + self.gender],
      window.lang.users.singular,
      window.lang["hasBeenCreated" + self.gender],
    ].join(" ");
    if (attributes.password === undefined) {
      message += window.lang.users.instructionsSent;
    }
    self.displayMessage(message);
  },

  // Opens the modal to change the password
  openChangePasswordModal: function (e) {
    var self = this;
    e.preventDefault();
    self.openModal(require("../templates/modals/changePassword.hbs"), self.renderData());
  },

  // Updates the password of the user
  changePassword: function (e) {
    var self = this,
      values = self.getFormData(form),
      url = app.router.current.options.account + "/users/",
      form = $(e.currentTarget);
    e.preventDefault();

    if (self.item.get("myself")) {
      url += "me/password";
    } else {
      url += self.item.attributes.username + "/password";
    }

    self.cleanFormErrorMessages(form);

    if ($("[data-field=password]").val() !== $("[data-field=password2]").val()) {
      self.showValidationErrors({ password2: window.lang.users.passwordsDontMatch }, form);
      return false;
    }

    delete values.password2;

    $.ajax({
      url: url,
      method: "PUT",
      headers: {
        "Account-Prefix": false,
      },
      data: {
        password: $("[data-field=password]").val(),
      },
      success: function () {
        $(self.modal).modal("hide");
        self.displayMessage(window.lang.users.passwordUpdated);
      },
    });
  },

  // Opens the modal to get api key
  openApiKeyModal: function (e) {
    var self = this;
    e.preventDefault();

    // Display alert for free accounts - Without use
    /*if (objHas(app.session.attributes, "details", "plan", "type")) {
      if (app.session.attributes.details.plan.type === "FREE") {
        //this.displayMessage(window.lang.users.apiKeyUnavailable, 5000, 'error');
        this.openRestrictedFeatureModal({ title: window.lang.users.getApiKey });
        return false;
      }
    }*/

    // Request API key and show modal
    $.ajax({
      url: "users/" + self.item.id + "/apikey",
      success: function (response) {
        self.openModal(require("../templates/modals/apiKey.hbs"), {
          lang: window.lang,
          data: response.data,
        });
      },
    });
  },

  // Delete the current API key
  deleteApiKey: function (e) {
    var self = this,
      button = $(e.currentTarget);
    e.preventDefault();
    e.stopPropagation();

    // Abort if the button has a confirm data attribute
    self.confirmModal(
      window.lang.users.confirmDeleteApiKey,
      button,
      window.lang.users.revoke,
      "warning",
      "data-confirm",
      function () {
        $.ajax({
          url: "users/" + self.item.id + "/apikey",
          method: "DELETE",
          success: function () {
            self.displayMessage(window.lang.users.deletedApiKey);
            self.getModal().modal("hide");
          },
        });
      },
    );
  },

  // Selects the text in the image link input
  selectApiKeyText: function () {
    document.getElementById("apikey").select();
  },

  // Copies the API key input content
  copyApiKey: function (e) {
    var button = $(e.currentTarget);
    this.selectApiKeyText();
    if (document.execCommand("copy")) {
      button.text(window.lang.done);
    }
  },

  beforeCreate: function (attributes, form) {
    this.updateEmailReports(form);
    return true;
  },

  beforeUpdate: function (attributes, form) {
    this.updateEmailReports(form);
    return true;
  },

  updateEmailReports: function (form) {
    var reports = { daily: 0, weekly: 0, monthly: 0 };
    if ($(form).find("#reportDaily").is(":checked")) {
      reports.daily = true;
    }
    if ($(form).find("#reportWeekly").is(":checked")) {
      reports.weekly = true;
    }
    if ($(form).find("#reportMonthly").is(":checked")) {
      reports.monthly = true;
    }

    var id = this.item.attributes.username || $(form).find("[name=username]").val();
    var email = $(form).find("[name=email]").val();
    var name = $(form).find("[name=name]").val();

    this.item.updateEmailReports(reports, id, email, name);
  },

  afterUpdate: function (attributes) {
    if (attributes.timezone) {
      //se modificó el timezone
      app.session.setTimezone(attributes.timezone);

      // app.session.reload(() => {
      //     app.session.setTimezone();
      // });
    }

    if (attributes.language) {
      // El idioma fue modificado
      app.updateLang(attributes.language).then(() => {
        app.layout.render();
        app.router.session();
        app.router.jobs();
      });
    }

    this.showUpdatedMessage(attributes);
    this.back();
  },

  showLocalTime: function () {
    const selectedTimezone = $("[name=timezone]").val();
    if (selectedTimezone) {
      $("#local-time-sample").text(moment().tz(selectedTimezone).format("HH:mm"));
    }
  },
});
