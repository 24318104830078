<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem]">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="https://docs.myperfit.com/articles/877" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <FormInput v-model="listsNumber" :label="t('listsNumber.label')" type="number" class="w-full" min="2" max="50" />
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" @click="divide">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    id: string;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const listsNumber = ref("2");
const loading = ref(false);
const divide = async () => {
  try {
    loading.value = true;

    await listAPI.split({
      id: props.id,
      number: listsNumber.value,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.divide.title"),
      text: t("notifications.divide.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Dividir",
    "description": "Se crearán nuevas listas dividiendo los contactos en partes iguales. La lista original no será modificada. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "listsNumber": {
      "label": "Cantidad de listas"
    },
    "notifications": {
      "divide": {
        "title": "Dividiendo lista",
        "text": "La lista se está dividiendo."
      }
    }
  },
  "pt": {
    "title": "Dividir",
    "description": "Serão criadas novas listas dividindo os contatos em partes iguais. A lista original não será modificada. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "listsNumber": {
      "label": "Número de listas"
    },
    "notifications": {
      "divide": {
        "title": "Dividindo lista",
        "text": "A lista está sendo dividida."
      }
    }
  }
}
</i18n>
