<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-8 flex flex-col">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0">
                  {{ t("date") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                  {{ t("paymentType") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                  {{ t("paymentNumber") }}
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                  {{ t("currencyAmount") }}
                </th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-6 md:pl-0">
                  {{ t("dueDate") }}
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="item in items" :key="item.id">
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium uppercase text-gray-500 sm:pl-6 md:pl-0"
                >
                  {{ formatDate(item.date) }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-400">
                  {{ item.type === "ST" ? "-" : item.type }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-400">{{ item.number }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-400">
                  {{ item.currency }} {{ formatAmount(item.amount) }}
                </td>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium uppercase text-gray-400 sm:pl-6 md:pl-0"
                >
                  {{ formatDate(item.due_date) }}
                </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                  <div class="flex justify-end gap-x-4">
                    <a
                      :href="item.link"
                      download
                      target="_blank"
                      class="flex items-center text-sky-500 hover:text-sky-700"
                      >{{ t("download") }}<DownloadIcon class="ml-2 h-4 w-4"
                    /></a>
                    <a
                      v-if="isMasterUser"
                      class="flex items-center text-red-500 hover:text-red-700"
                      @click="addReceiptToDelete(item.id)"
                      >{{ t("delete") }}<TrashIcon class="ml-2 h-4 w-4"
                    /></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ConfirmationModal
            id="deleteReceiptAlertModal"
            v-bind="deleteReceiptAlertModal"
            @accept="acceptDeleteReceiptModal"
            @cancel="closeDeleteReceiptModal"
          >
            <template #acceptButtonLeading>
              <TrashIcon class="h-5 w-5" aria-hidden="true" />
            </template>
          </ConfirmationModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { parseISO } from "date-fns";

// Icons
import { DownloadIcon, TrashIcon } from "@heroicons/vue/outline";

// Components
import ConfirmationModal from "@/vue/components/molecules/ConfirmationModal.vue";

// API
import { useReceipts } from "@/vue/api/modules/receipts/receipts";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Types
import type { GetReceiptsResponse } from "@api/modules/receipts/receipts.types";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

// Lang
const { d, t, n } = useI18n();

const formatAmount = (amount: string) => {
  return n(parseFloat(amount));
};

const formatDate = (date: string) => {
  if (!date) return "-";
  return d(parseISO(date.split("T")[0]), "shortWithYear");
};

const emit = defineEmits<{
  (e: "receiptsLoaded", receipts: number): void;
}>();

const emitQuantity = (itemsLength: number) => {
  emit("receiptsLoaded", itemsLength);
};

// Const
const ReceiptsAPI = useReceipts();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value?.isMasterUser;

// State
const items = ref<GetReceiptsResponse>();
const receiptToDelete = ref("");
const deleteReceiptAlertModal = reactive<ConfirmationModalType>({
  open: false,
  acceptLoading: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage"),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  severity: "warning",
});

// Methods
const getReceiptsItems = async () => {
  items.value = await getReceipts();
  if (!items.value) return;
  emitQuantity(items.value.length);
};

const getReceipts = async (): Promise<GetReceiptsResponse | undefined> => {
  try {
    const items = await ReceiptsAPI.getReceipts();
    return items;
  } catch (e) {
    return undefined;
  }
};

const addReceiptToDelete = (id: string) => {
  receiptToDelete.value = id;
  deleteReceiptAlertModal.open = true;
};

// Modal Functions
const acceptDeleteReceiptModal = async () => {
  if (receiptToDelete.value) {
    deleteReceiptAlertModal.acceptLoading = true;
    await ReceiptsAPI.deleteReceipts({ id: receiptToDelete.value });
    deleteReceiptAlertModal.acceptLoading = false;
  }
  getReceiptsItems();
  closeDeleteReceiptModal();
};

const closeDeleteReceiptModal = () => {
  deleteReceiptAlertModal.open = false;
};

onMounted(async () => {
  getReceiptsItems();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "date": "Emisión",
    "paymentType": "Tipo",
    "paymentNumber": "Número",
    "currencyAmount": "Importe",
    "dueDate": "Vencimiento",
    "download": "Descargar",
    "delete": "Eliminar",
    "deleteAlert": {
      "confirmationTitle": "Eliminar recibo",
      "confirmationMessage": "El recibo será eliminado, ¿Quieres continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "date": "Emissão",
    "paymentType": "Tipo",
    "paymentNumber": "Número",
    "currencyAmount": "Quantia",
    "dueDate": "Vencimiento",
    "download": "Baixar",
    "delete": "Excluir",
    "deleteAlert": {
      "confirmationTitle": "Excluir recibo",
      "confirmationMessage": "O recibo será excluído, deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
