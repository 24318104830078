<template>
  <div class="h-full">
    <a v-show="false" ref="newListRef" href="/lists/new" />
    <Teleport to="#audience-menuOptions">
      <div class="flex space-x-4">
        <PermissionsButton href="/lists/import" :permissions="['contacts:import']" theme="green-lime">
          <template #leading>
            <UploadIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("importListButton") }}
        </PermissionsButton>
        <DropDownButton
          theme="black"
          :navigation="options"
          position="bottom-end"
          :auto-placements="['bottom-end']"
          @update:selected="HandleMenuOptionSelection"
        />
      </div>
    </Teleport>
    <Teleport to="#audience-stickyContent">
      <FiltersPanel v-show="!firstLoad && (rows.length > 0 || selectedSearch.length > 0 || loadingTable)">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div>
            <span v-show="!loadingTable" class="text-sm text-gray-500">
              {{ t("listsCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount }) }}
            </span>
            <span v-show="loadingTable" class="animate-pulse rounded bg-gray-50 px-6" />
          </div>
        </div>
      </FiltersPanel>
    </Teleport>
    <Table
      v-show="!loadingTable && rows.length > 0"
      :sort-by="sortBy"
      :columns="columns"
      :rows="rows"
      :selected-rows="selectedRows"
      :loading="loadingNext"
      :skeleton="skeletonTable"
      :skeleton-count="skeletonCount"
      :selectable="listsUpdate"
      class="ml-6 h-full overflow-x-hidden bg-white pb-16"
      @update:sort-by="updateSortBy"
      @update:selected-rows="updateSelectedRows"
      @scrollBottom="fetchNextList"
    >
      <template #name="{ row }">
        <EmailCell
          v-if="row"
          :id="row.id"
          :description="row.data?.description"
          :tags="row.data?.tags"
          :quality="Number(row.content?.quality ?? 0)"
          @select-tag="selectTagSearch"
        >
          {{ row.content?.name }}
        </EmailCell>
      </template>
      <template #name-skeleton>
        <div class="flex gap-2 py-6">
          <div class="my-3 w-60 animate-pulse rounded-md bg-sky-50 py-2" />
          <div class="my-3 mr-6 flex animate-pulse justify-end">
            <StarIcon v-for="index in 3" :key="index" class="h-4 w-4 text-gray-100" />
          </div>
        </div>
      </template>
      <template #contacts="{ row }">
        <a v-if="row" :href="`contacts?filter.list=${row.data.id}`" class="pr-5 text-sm text-sky-400">
          {{ n(Number(row.content?.contacts) ?? 0, "decimal") }}
        </a>
      </template>
      <template #contacts-skeleton>
        <div class="my-3 ml-auto mr-5 w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #activity="{ row }">
        <span v-if="row" class="text-sm text-gray-500"> {{ row.content?.activity }}% </span>
      </template>
      <template #activity-skeleton>
        <div class="my-3 ml-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #disabled="{ row }">
        <span v-if="row" class="text-sm text-gray-500"> {{ row.content?.disabled }}% </span>
      </template>
      <template #disabled-skeleton>
        <div class="my-3 ml-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #created="{ row }">
        <span v-if="row" class="ml-auto flex w-max pr-5 text-sm text-gray-500">
          {{ row.content?.created ? createDateFormatter(row.content?.created) : "" }}
        </span>
      </template>
      <template #created-skeleton>
        <div class="my-3 ml-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="listOptions"
            theme="transparent-white"
            position="bottom-end"
            :auto-placements="['bottom-end', 'left']"
            :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="flex justify-center">
          <div class="my-3 w-4 animate-pulse rounded-md bg-gray-50 py-2" />
        </div>
      </template>
    </Table>
    <EmptyState
      v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
      :title="t('emptyState.title')"
      :action-text="t('emptyState.integrationsButton')"
      :article-id="8593204"
      :article-text="t('emptyState.moreAbout')"
      :points="[t('emptyState.first'), t('emptyState.second'), t('emptyState.third')]"
      @action="goToIntegrations"
    >
      <template #buttonIcon>
        <ViewGridAddIcon class="h-5 w-5" />
      </template>
      <template #image>
        <EmptyStateImage v-show="lgBp" />
      </template>
    </EmptyState>
    <EmptySearchState
      v-show="!loadingTable && selectedSearch.length > 0 && rows.length === 0"
      @cleanSearch="cleanSearch"
    />
    <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>

    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selectedLists",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(totalCount) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
          <IconButton
            v-if="selectedRows?.length < totalCount"
            :label="t('selectAllButton')"
            size="min"
            theme="none"
            class="bg-gray-600"
            @click="selectAll"
          >
            <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
          </IconButton>
        </template>
      </div>
      <div class="flex space-x-4">
        <DropDownButton
          :small="true"
          theme="transparent-black"
          :auto-placements="['top']"
          :navigation="selectionOptions"
          :modifiers="[{ name: 'offset', options: { offset: [0, 16] } }]"
          @update:selected="handleSelectionOption"
        />
        <PermissionsIconButton
          :permissions="['lists:delete']"
          :label="t('deleteSelectedButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </PermissionsIconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>
    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteSelectedAlertModal"
    >
      <template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
      <template #body>
        <div class="mt-8">
          <RadioList :options="deleteOptions" v-model:selected="selectedDeleteOption" />
          <AlertBox theme="info" class="mt-8">
            <i18n-t keypath="deleteListsAlertInfo">
              <a target="_blank" href="http://docs.myperfit.com/articles/6236706">{{ t("deleteListsAlertLink") }}</a>
            </i18n-t>
          </AlertBox>
        </div>
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteList"
      @cancel="closeDeleteListAlertModal"
    >
      <template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
      <template #body>
        <div class="mt-8">
          <RadioList :options="deleteOptions" v-model:selected="selectedDeleteOption" />
          <AlertBox theme="info" class="mt-8">
            <i18n-t keypath="deleteListsAlertInfo">
              <a target="_blank" href="http://docs.myperfit.com/articles/6236706">{{ t("deleteListsAlertLink") }}</a>
            </i18n-t>
          </AlertBox>
        </div>
      </template>
    </ConfirmationModal>
    <CleanModal
      v-if="cleanModalIsOpen"
      v-model:open="cleanModalIsOpen"
      :ids="selectedRowsIds && selectedRowsIds?.length > 0 ? selectedRowsIds : [selectedRow?.data?.id.toString() ?? '']"
      @close="closeCleanModal"
      @clear-selected="clearSelected"
    />
    <FilterModal
      v-if="filterModalIsOpen"
      :id="selectedRow?.data?.id.toString() ?? ''"
      v-model:open="filterModalIsOpen"
      :fields="fields"
      :list-name="selectedRow?.data?.name ?? ''"
      @close="closeFilterModal"
      @clear-selected="clearSelected"
    />
    <DuplicateModal
      v-if="duplicateModalIsOpen"
      :id="selectedRow?.data?.id.toString() ?? ''"
      v-model:open="duplicateModalIsOpen"
      :list-name="selectedRow?.data?.name ?? ''"
      @close="closeDuplicateModal"
      @clear-selected="clearSelected"
    />
    <DivideModal
      v-if="divideModalIsOpen"
      :id="selectedRow?.data?.id.toString() ?? ''"
      v-model:open="divideModalIsOpen"
      @close="closeDivideModal"
      @clear-selected="clearSelected"
    />
    <SampleModal
      v-if="sampleModalIsOpen"
      :id="selectedRow?.data?.id.toString() ?? ''"
      v-model:open="sampleModalIsOpen"
      :list-name="selectedRow?.data?.name ?? ''"
      @close="closeSampleModal"
      @clear-selected="clearSelected"
    />
    <MergeModal
      v-if="mergeModalIsOpen && selectedRows.length > 0"
      v-model:open="mergeModalIsOpen"
      :rows="selectedRows"
      @close="closeMergeModal"
      @clear-selected="clearSelected"
    />
    <IntersectModal
      v-if="intersectModalIsOpen && selectedRows.length > 0"
      v-model:open="intersectModalIsOpen"
      :rows="selectedRows"
      @close="closeIntersectModal"
      @clear-selected="clearSelected"
    />
    <SubtractModal
      v-if="subtractModalIsOpen && selectedRows.length > 0"
      v-model:open="subtractModalIsOpen"
      :rows="selectedRows"
      @close="closeSubtrackModal"
      @clear-selected="clearSelected"
    />
    <AutoSegmentsModal
      v-if="autoSegmentsModalIsOpen"
      v-model:open="autoSegmentsModalIsOpen"
      @close="closeAutoSegmentsModal"
      @clear-selected="clearSelected"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRaw, reactive, watch, onMounted } from "vue";

import {
  getMenuOptions,
  getListOptions,
  getSelectionOptions,
  getColumns,
  listsToRows,
  listTagsToDataItems,
} from "./audienceLists.data";
import {
  OptionsKeys,
  ListOptionsKeys,
  SelectionOptionsKeys,
  ColumnsIds,
  ListRows,
  ListRow,
  ListsFilterData,
} from "./audienceLists.types";

// Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyState from "@molecules/EmptyState/EmptyState.vue";
import EmptyStateImage from "./EmptyStateImage.vue";
import EmailCell from "./components/EmailCell.vue";
import CleanModal from "./components/CleanModal.vue";
import FilterModal from "./components/FilterModal.vue";
import SampleModal from "./components/SampleModal.vue";
import DuplicateModal from "./components/DuplicateModal.vue";
import DivideModal from "./components/DivideModal.vue";
import MergeModal from "./components/MergeModal.vue";
import IntersectModal from "./components/IntersectModal.vue";
import SubtractModal from "./components/SubtractModal.vue";
import AutoSegmentsModal from "./components/AutoSegmentsModal.vue";
import FiltersPanel from "@templates/FiltersPanel";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText, SelectedValueTag } from "@molecules/FilterInput";
import Table from "@molecules/Table";
import type { Columns, SortColumn, SortingModes } from "@molecules/Table";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import RadioList from "@molecules/RadioList";
import type { Option, Options } from "@molecules/RadioList";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";
import AlertBox from "@atoms/AlertBox.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import {
  // TagIcon,
  DocumentIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/vue/outline";

import { UploadIcon, PencilIcon, TagIcon, StarIcon, ViewGridAddIcon } from "@heroicons/vue/solid";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";

// Utils
import { formatDate } from "@helpers/formatters";
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";
import { useBreakpoints } from "@composables/breakpoints";

// I18n
import { useI18n } from "vue-i18n";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore, useSessionStore, useAlertStore } from "@store";

// Application
import { useRouterApp } from "@application";
import { useLocalStorageApp } from "@application";

// Service
import { useFields } from "@api/modules/fields/fields";
import { useLists } from "@api/modules/lists/lists";

// Domain
import type { Tag } from "@domain/tag";
import type { Fields } from "@domain/fields";
import type { DataItems } from "@domain/data";
import { getPrimaryHighlightList } from "@domain/filters";

const { t, n } = useI18n();
const fieldsAPI = useFields();
let listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();
const localStorageApp = useLocalStorageApp();
const routerApp = useRouterApp();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();
const { lgBp } = useBreakpoints();

const listsUpdate = sessionStore.hasPermission("lists:update");
const listsCreate = sessionStore.hasPermission("lists:create");
const listsDelete = sessionStore.hasPermission("lists:delete");

const goToIntegrations = () => {
  routerApp.navigate({
    path: "/integrations",
  });
};

const createDateFormatter = (dateString: DateString) => {
  const date = new Date(dateString);
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  if (isThisYear) {
    return formatDate(date, "MMM dd, HH:mm");
  }

  return formatDate(date, "MMM dd, y");
};

const deleteOptions = ref<Options<"onlyList" | "listAndContacts">>([
  {
    key: "onlyList",
    label: t("deleteOptions.onlyList"),
  },
  {
    key: "listAndContacts",
    label: t("deleteOptions.listAndContacts"),
  },
]);
const selectedDeleteOption = ref<Option<"onlyList" | "listAndContacts">>({
  key: "onlyList",
  label: t("deleteOptions.onlyList"),
});
const resetSelectedDeleteOption = () => {
  selectedDeleteOption.value = {
    key: "onlyList",
    label: t("deleteOptions.onlyList"),
  };
};

// Table
const fetchlimit = 20;
const skeletonTable = ref(false);
const skeletonCount = ref(5);
const calculateSkeletonCount = () => {
  if (rows.value.length > fetchlimit) {
    skeletonCount.value = fetchlimit;
    return;
  }

  skeletonCount.value = rows.value.length;
  return;
};

const firstLoad = ref(false);
const loadingTable = ref(false);
const loadingNext = ref(false);
const columns: Columns<ColumnsIds | "options"> = getColumns();
const listTags = ref<Array<string>>([]);
const rows = ref<ListRows>([]);
const fields = ref<Fields>([]);
const totalCount = ref<number>(0);
const selectedRows = ref<ListRows>([]);
const selectedRowsIds = computed<Array<string> | undefined>(() => {
  if (!selectedRows.value) return;

  return selectedRows.value.reduce((ids, row) => {
    if (!row.data?.id) return ids;

    ids.push(row.data.id.toString());
    return ids;
  }, [] as Array<string>);
});
const selectedRow = ref<ListRow>();
const clearSelected = () => {
  selectedRows.value = [];
};
const selectAll = () => {
  selectedRows.value = rows.value;
};

const savedSortBy = localStorageApp.get<SortColumn>({ id: "audience_lists_sort_by" });
const sortBy = ref<SortColumn>(
  savedSortBy ?? {
    columnId: "totalContacts",
    sort: "DESC",
  },
);
const updateSortBy = (newSortBy: SortColumn) => {
  sortBy.value = newSortBy;
  localStorageApp.save<SortColumn>({ id: "audience_lists_sort_by", value: sortBy.value });
};
const updateSelectedRows = (newRows: ListRows) => {
  selectedRows.value = newRows;
};
const removeRows = (rowsToDelete: ListRows) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  rows.value = filteredRows;
};
const removeRow = (rowToDelete: ListRow) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return row.id !== rowToDelete.id;
  });
  rows.value = filteredRows;
};

const fetchNextList = async () => {
  if (totalCount.value > 0 && totalCount.value <= rows.value.length) return;
  await fetchLists();
};

const fetchLists = async () => {
  loadingNext.value = true;
  const getListsearch = selectedSearch.value.find((search) => search.filterId === "lists") as SelectedValueText;
  const getTagsSearch = selectedSearch.value.filter((search) => search.filterId === "tags") as Array<SelectedValueText>;

  const lists = await listAPI.get({
    sortBy: sortBy.value?.columnId,
    sortDir: sortBy.value?.sort === "DESC" ? "desc" : "asc",
    search: getListsearch ? getListsearch.value : undefined,
    limit: fetchlimit,
    tags: getTagsSearch.length > 0 ? [...getTagsSearch.map((t) => t.value)] : undefined,
  });

  if (lists.isNewRequest) {
    rows.value = listsToRows(lists.value);
    totalCount.value = lists.total ?? 0;
    loadingNext.value = false;
    return;
  }

  rows.value = [...toRaw(rows.value), ...listsToRows(lists.value)];

  loadingNext.value = false;
};

const fetchTags = async () => {
  const tags = await listAPI.tags();

  listTags.value = tags;
};

const fieldsFetchLimit = 100000;
const fetchFields = async () => {
  const res = await fieldsAPI.getFields({
    limit: fieldsFetchLimit,
  });

  fields.value = res.value;
};

const exportList = async () => {
  if (!selectedRow.value) return;

  try {
    await listAPI.export({ id: selectedRow.value.data.id.toString() });
    notify({
      title: t("notifications.export.title"),
      text: t("notifications.export.text"),
      theme: "info",
    });
  } catch {
    // No Handler
  } finally {
    notificationStore.fetchTasks();
  }
};

const exportListsBulk = async () => {
  try {
    const exportPromises = selectedRows.value?.map((row) => listAPI.export({ id: row.data.id.toString() }));
    await Promise.all(exportPromises);
    notify({
      title: t("notifications.exportBulk.title"),
      text: t("notifications.exportBulk.text"),
      theme: "info",
    });
  } catch {
    // No Handler
  } finally {
    notificationStore.fetchTasks();
    clearSelected();
  }
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) =>
      listAPI.delete({
        id: selected.id,
        cleanContacts: selectedDeleteOption.value.key === "listAndContacts",
        showError: false,
      }),
    );
    await Promise.all(deletePromises);
    totalCount.value = totalCount.value - selectedRows.value.length;
    removeRows(selectedRows.value);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchLists();
    }
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
  } catch {
    // No handler
    notify({
      title: t("notifications.deleteBulkError.title"),
      text: t("notifications.deleteBulkError.text"),
      theme: "error",
    });
  } finally {
    clearSelected();
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.open = false;
  resetSelectedDeleteOption();
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteSelected = async () => {
  try {
    deleteSelectedAlertModal.acceptLoading = true;
    await deleteBulk();
    resetSelectedDeleteOption();
  } finally {
    deleteSelectedAlertModal.acceptLoading = false;
    deleteSelectedAlertModal.open = false;
  }
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
  resetSelectedDeleteOption();
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteList = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await listAPI.delete({ id, cleanContacts: selectedDeleteOption.value.key === "listAndContacts" });

    totalCount.value = totalCount.value - 1;
    removeRow(rowToDelete);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchLists();
    }

    notify({
      title: t("notifications.delete.title"),
      text: t("notifications.delete.text"),
      theme: "success",
    });
  } finally {
    deleteAlertModal.acceptLoading = false;
    resetSelectedDeleteOption();
    closeDeleteListAlertModal();
  }
};

const cleanModalIsOpen = ref(false);
const closeCleanModal = () => {
  cleanModalIsOpen.value = false;
  selectedRow.value = undefined;
};
const openCleanModal = () => {
  cleanModalIsOpen.value = true;
};

const filterModalIsOpen = ref(false);
const closeFilterModal = () => {
  filterModalIsOpen.value = false;
};

const duplicateModalIsOpen = ref(false);
const closeDuplicateModal = () => {
  duplicateModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const divideModalIsOpen = ref(false);
const closeDivideModal = () => {
  divideModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const sampleModalIsOpen = ref(false);
const closeSampleModal = () => {
  sampleModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const mergeModalIsOpen = ref(false);
const closeMergeModal = () => {
  mergeModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const intersectModalIsOpen = ref(false);
const closeIntersectModal = () => {
  intersectModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const subtractModalIsOpen = ref(false);
const closeSubtrackModal = () => {
  subtractModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const autoSegmentsModalIsOpen = ref(false);
const closeAutoSegmentsModal = () => {
  autoSegmentsModalIsOpen.value = false;
  selectedRow.value = undefined;
};

const newListRef = ref();
const options = getMenuOptions();
const handleSelectedMenuOption: Record<OptionsKeys, () => void> = {
  newList: () => {
    newListRef.value?.click();
  },
  autoSegments: () => {
    autoSegmentsModalIsOpen.value = true;
  },
  reCalculateTotals: async () => {
    await listAPI.refreshcache();
    notify({
      title: t("notifications.recalculate.title"),
      text: t("notifications.recalculate.text"),
    });
    listAPI = useLists();
    await fetchLists();
  },
};

const HandleMenuOptionSelection = async (option: DropDownDataItem) => {
  if (option.key === "newList" && !listsCreate) {
    alertStore.showPermissionDenied(["lists:create"]);
    return;
  }

  if (!listsUpdate) {
    alertStore.showPermissionDenied(["lists:update"]);
    return;
  }

  await handleSelectedMenuOption[option.key]?.();
};

const listOptions = getListOptions();
const listOptionHandler: Record<ListOptionsKeys, (row: ListRow) => void> = {
  export: (row) => {
    selectedRow.value = row;
    exportList();
  },
  clean: (row) => {
    selectedRow.value = row;
    openCleanModal();
  },
  filter: (row) => {
    selectedRow.value = row;
    filterModalIsOpen.value = true;
  },
  duplicate: (row) => {
    selectedRow.value = row;
    duplicateModalIsOpen.value = true;
  },
  divide: (row) => {
    selectedRow.value = row;
    divideModalIsOpen.value = true;
  },
  sample: (row) => {
    selectedRow.value = row;
    sampleModalIsOpen.value = true;
  },
  analyze: async (row) => {
    await listAPI.analyze({
      id: row.id,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.analyze.title"),
      text: t("notifications.analyze.text"),
      theme: "info",
    });
  },
  delete: (row) => {
    if (!sessionStore.hasPermission("lists:delete")) {
      alertStore.showPermissionDenied(["lists:delete"]);
      return;
    }

    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: ListRow) => {
  if (!listsDelete && option.key === "delete") {
    alertStore.showPermissionDenied(["lists:delete"]);
    return;
  }

  if (!listsUpdate) {
    alertStore.showPermissionDenied(["lists:update"]);
    return;
  }

  await listOptionHandler[option.key]?.(row);
};

const selectionOptions = computed(() => {
  return getSelectionOptions(selectedRows.value?.length ?? 0);
});
const selectionOptionHandler: Record<SelectionOptionsKeys, () => void> = {
  export: () => {
    if (!selectedRows.value) return;
    exportListsBulk();
  },
  clean: () => {
    if (!selectedRows.value) return;
    openCleanModal();
  },
  analyze: async () => {
    if (!selectedRows.value) return;

    const promises = selectedRows.value.map((row) =>
      listAPI.analyze({
        id: row.id,
      }),
    );

    await Promise.all(promises);
    await notificationStore.fetchTasks();
    clearSelected();
  },
  sample: () => {
    selectedRow.value = selectedRows.value?.[0];
    if (!selectedRow.value) return;
    sampleModalIsOpen.value = true;
  },
  divide: () => {
    selectedRow.value = selectedRows.value?.[0];
    if (!selectedRow.value) return;
    divideModalIsOpen.value = true;
  },
  duplicate: () => {
    selectedRow.value = selectedRows.value?.[0];
    if (!selectedRow.value) return;
    duplicateModalIsOpen.value = true;
  },
  filter: () => {
    selectedRow.value = selectedRows.value?.[0];
    if (!selectedRow.value) return;
    filterModalIsOpen.value = true;
  },
  intersect: () => {
    intersectModalIsOpen.value = true;
  },
  merge: () => {
    mergeModalIsOpen.value = true;
  },
  subtract: () => {
    subtractModalIsOpen.value = true;
  },
};

const handleSelectionOption = async (option: DropDownDataItem) => await selectionOptionHandler[option.key]?.();

// Search
const selectedSearch = ref<SelectedValues>([]);
const filterInputIsOpen = ref(false);
const highlightSearches = ref<DataItems>([]);

const cleanSearch = () => {
  selectedSearch.value = [];
};

const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "audience_lists_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "audience_lists_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "lists");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "lists");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const selectTagSearch = (tag: Tag) => {
  if (selectedSearch.value.some((s) => (s as SelectedValueTag)?.tag?.id === tag?.id)) return;
  const tagIndex = selectedSearch.value.findIndex((s) => s.filterId === "tags");

  if (tagIndex !== -1) {
    selectedSearch.value.splice(tagIndex, 1);
  }

  selectedSearch.value = [
    ...toRaw(selectedSearch.value),
    {
      filterId: "tags",
      id: tag.id,
      value: tag.id,
    },
  ];
};

const filterData = computed<ListsFilterData>(() => ({
  filters: [
    { id: "lists", text: t("filters.lists"), icon: PencilIcon, highlightList: highlightSearches.value },
    {
      id: "tags",
      text: t("filters.tags"),
      icon: TagIcon,
      list: listTagsToDataItems(listTags.value),
    },
  ],
  filtersRoles: {
    primary: "lists",
  },
}));

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    lists: "q",
    tags: "filter.tags",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

const updateQueryParams = () => {
  cleanQueryParams();
  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    if (isSelectedValueTag(selected)) {
      updateQueryParam({ key: paramName, value: selected.tag.id });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  if (sortBy.value) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  }
};

watch([selectedSearch], async () => {
  updateQueryParams();

  loadingTable.value = true;
  await fetchLists();
  loadingTable.value = false;
});

watch([sortBy], async () => {
  calculateSkeletonCount();
  skeletonTable.value = true;

  updateQueryParams();

  await fetchLists();
  skeletonTable.value = false;
});

onMounted(async () => {
  firstLoad.value = true;
  loadingTable.value = true;
  const queryParams = getQueryParams();

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      id: value,
      value,
    });
  });

  const sortParams = queryParams?.get("sort")?.split(" ");

  if (!sortParams) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  } else {
    sortBy.value = {
      columnId: sortParams?.[0],
      sort: sortParams?.[1] as SortingModes,
    };
  }

  insertRecommmendedNames();
  await Promise.all([fetchLists(), fetchFields(), fetchTags()]);
  loadingTable.value = false;
  firstLoad.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderSearch": "Buscar listas",
    "importListButton": "Importar lista",
    "listsCount": "0 listas | 1 lista | {count} listas",
    "selectedLists": "1 lista seleccionada de {total} | {count} listas seleccionadas de {total}",
    "deleteSelectedButton": "Eliminar",
    "closeButton": "Cancelar",
    "selectAllButton": "Seleccionar todo",
    "deleteListsAlertInfo": "Para más información sobre eliminación de listas puedes leer el siguiente artículo en nuestro {0}.",
    "deleteListsAlertLink": "centro de ayuda",
    "emptyState": {
      "title": "El primer paso es construir y conocer a tu audiencia",
      "integrationsButton": "Ver integraciones",
      "moreAbout": "Saber más sobre audiencia",
      "first": "Integra tu tienda o importa una lista para empezar. ¡Hora de construir tu audiencia!",
      "second": "Releva cuáles son sus intereses para segmentar mejor y enviarles contenido de su preferencia.",
      "third": "Utiliza los campos para personalizar el contenido de tus envíos y fidelizar a tu audiencia."
    },
    "deleteOptions": {
      "onlyList": "Eliminar únicamente esta lista.",
      "listAndContacts": "Eliminar esta lista y sus contactos de todas las listas."
    },
    "menuOptions": {
      "newList": "Crear lista vacía",
      "autoSegments": "Crear segmentos por actividad",
      "reCalculateTotals": "Recalcular totales (master user)"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar listas",
      "confirmationMessage": "Se eliminará 1 lista seleccionada. Es posible que esta lista contenga contactos que pertenecen a otras listas. ¿Qué deseas hacer? | Se eliminarán {count} listas seleccionadas. Es posible que estas listas contengan contactos que pertenecen a otras listas. ¿Qué deseas hacer?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar lista",
      "confirmationMessage": "Se eliminará la lista {name}. Es posible que esta lista contenga contactos que pertenecen a otras listas. ¿Qué deseas hacer?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "listOptions": {
      "clean": "Limpiar",
      "export": "Exportar",
      "filter": "Filtrar",
      "duplicate": "Duplicar",
      "divide": "Dividir",
      "sample": "Muestrear",
      "analyze": "Analizar",
      "delete": "Eliminar"
    },
    "selectionOptions": {
      "clean": "Limpiar",
      "analyze": "Analizar",
      "sample": "Muestrear",
      "divide": "Dividir",
      "duplicate": "Duplicar",
      "filter": "Filtrar",
      "intersect": "Intersectar",
      "subtract": "Sustraer",
      "merge": "Unir"
    },
    "filters": {
      "lists": "Nombre",
      "tags": "Etiquetas"
    },
    "columns": {
      "name": "Nombre",
      "contacts": "Contactos",
      "activity": "Actividad",
      "disabled": "Inhabilitados",
      "created": "Creación",
      "quality": "Calidad"
    },
    "notifications": {
      "analyze": {
        "title": "Analizando lista",
        "text": "La lista se está analizando."
      },
      "delete": {
        "title": "Lista eliminada",
        "text": "La lista se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Listas eliminadas",
        "text": "Las Listas se eliminaron correctamente."
      },
      "deleteBulkError": {
        "title": "Listas eliminadas",
        "text": "Algunas listas no pudieron ser eliminadas"
      },
      "recalculate": {
        "title": "Recalculo en proceso",
        "text": "Recalculando total de contactos por lista."
      },
      "export": {
        "title": "Exportando lista",
        "text": "La lista será exportada en breve. Te notificaremos al finalizar."
      },
      "exportBulk": {
        "title": "Exportando listas",
        "text": "Las listas serán exportadas en breve. Te notificaremos al finalizar."
      }
    }
  },
  "pt": {
    "placeholderSearch": "Pesquisar listas",
    "importListButton": "Importar lista",
    "listsCount": "0 listas | 1 lista | {count} listas",
    "selectedLists": "1 lista selecionada de {total} | {count} listas selecionadas de {total}",
    "deleteSelectedButton": "Excluir",
    "closeButton": "Cancelar",
    "selectAllButton": "Selecionar tudo",
    "deleteListsAlertInfo": "Para mais informações sobre limpeza de listas, você pode ler o seguinte artigo em nosso {0}.",
    "deleteListsAlertLink": "centro de ajuda",
    "emptyState": {
      "title": "O primeiro passo é construir e conhecer sua audiência",
      "integrationsButton": "Ver integrações",
      "moreAbout": "Aprender mais sobre Audiência",
      "first": "Integre sua loja ou importe uma lista para começar. Hora de construir sua audiência!",
      "second": "Analise quais são seus interesses para segmentar melhor e enviar conteúdos de sua preferência.",
      "third": "Utilize os campos para personalizar o conteúdo dos seus envios e fidelizar a sua audiência."
    },
    "deleteOptions": {
      "onlyList": "Excluir somente esta lista.",
      "listAndContacts": "Excluir esta lista e seus contatos de todas as listas."
    },
    "menuOptions": {
      "newList": "Criar lista vazia",
      "autoSegments": "Criar segmentos por atividade",
      "reCalculateTotals": "Recalcular totais (usuário mestre)"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir listas",
      "confirmationMessage": "Será excluída 1 lista selecionada. É possível que esta lista contenha contatos que pertencem a outras listas. O que deseja fazer? | Serão excluídas {count} listas selecionadas. É possível que estas listas contenham contatos que pertencem a outras listas. O que deseja fazer?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir lista",
      "confirmationMessage": "Será excluída a lista {name}. É possível que esta lista contenha contatos que pertencem a outras listas. O que deseja fazer?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "listOptions": {
      "clean": "Limpar",
      "export": "Exportar",
      "filter": "Filtrar",
      "duplicate": "Duplicar",
      "divide": "Dividir",
      "sample": "Amostrar",
      "analyze": "Analisar",
      "delete": "Excluir"
    },
    "selectionOptions": {
      "clean": "Limpar",
      "analyze": "Analisar",
      "sample": "Amostrar",
      "divide": "Dividir",
      "duplicate": "Duplicar",
      "filter": "Filtrar",
      "intersect": "Interseccionar",
      "subtract": "Subtrair",
      "merge": "Unir"
    },
    "filters": {
      "lists": "Nome",
      "tags": "Etiquetas"
    },
    "columns": {
      "name": "Nome",
      "contacts": "Contatos",
      "activity": "Atividade",
      "disabled": "Desabilitados",
      "created": "Criação",
      "quality": "Qualidade"
    },
    "notifications": {
      "analyze": {
        "title": "Analisando lista",
        "text": "A lista está sendo analisada."
      },
      "delete": {
        "title": "Lista excluída",
        "text": "A lista foi excluída com sucesso."
      },
      "deleteBulk": {
        "title": "Listas excluídas",
        "text": "As Listas foram excluídas com sucesso."
      },
      "deleteBulkError": {
        "title": "Listas excluídas",
        "text": "Algumas listas não puderam ser excluídas"
      },
      "recalculate": {
        "title": "Recálculo em andamento",
        "text": "Recalculando o total de contatos por lista."
      },
      "export": {
        "title": "Exportando lista",
        "text": "A lista será exportada em breve. Notificaremos você ao finalizar."
      },
      "exportBulk": {
        "title": "Exportando listas",
        "text": "As listas serão exportadas em breve. Notificaremos você ao finalizar."
      }
    }
  }
}
</i18n>
