import { EditView } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";
import editTpl from "../templates/edit.hbs";
import showTpl from "../templates/show.hbs";
import FeedActivityContact from "@organisms/Contacts/FeedContact/FeedActivityContact.vue";
import { mountComponent } from "@/helpers/vue";

export default EditView.extend({
  module: "contacts",
  templates: {
    initial: showTpl,
    show: showTpl,
    edit: editTpl,
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=unsubscribe]": "unsubscribe",
      "click [data-action=respawn]": "respawn",
      "click [data-action=reset]": "reset",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Bind datepickers
    this.$("[data-format=DATE]").datepicker();

    const propsFeedActivityContact = {
      id: this.item.get("id"),
    };

    mountComponent(FeedActivityContact, propsFeedActivityContact, "#vue-feed-activity-contact");

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Check if the contact has at least one list selected
  validateLists: function (attributes, form) {
    if (_.isArray(attributes.lists) && !attributes.lists.length) {
      this.showValidationErrors({ selectedLists: true }, $(form));
      return false;
    }
    return true;
  },

  // Runs before updating a model
  beforeCreate: function (attributes, form) {
    if (_.isUndefined(attributes.lists)) {
      attributes.lists = [];
    }
    return this.validateLists(attributes, form);
  },

  // Runs before updating a model
  beforeUpdate: function (attributes, form) {
    return this.validateLists(attributes, form);
  },

  // Collects the data from the edit form
  getFormData: function (form) {
    var values = this.getFormFieldsObject(form),
      customFields = [],
      lists = [],
      interests = [];

    // Arrange custom fields object
    if (values.customFields !== undefined) {
      _.each(values.customFields, function (value, index) {
        if (value !== undefined) {
          customFields.push({ id: index, value: value });
        }
      });
      values.customFields = customFields;
    }

    // Arrange lists
    if (values.lists !== undefined) {
      if (_.isArray(values.lists)) {
        values.lists = values.lists.map(function (value) {
          return { id: value };
        });
      } else {
        values.lists = [];
      }
    }

    // Arrange interests
    if (values.interests !== undefined) {
      if (_.isArray(values.interests)) {
        values.interests = values.interests.map(function (value) {
          return { id: value };
        });
      } else {
        values.interests = [];
      }
    }

    // Return final values
    return values;
  },

  // Unsuscribes a contact
  unsubscribe: function (e) {
    var button = $(e.currentTarget);
    e.preventDefault();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Sent request
    this.item.unsubscribe().done(
      function () {
        this.displayMessage(window.lang.contacts.feedback.unsubscribed);
        this.reload();
      }.bind(this)
    );
  },

  // Respawns an unsubscribed contact
  respawn: function (e) {
    e.preventDefault();
    this.item.respawn().done(
      function () {
        this.displayMessage(window.lang.contacts.feedback.respawned);
        this.reload();
      }.bind(this)
    );
  },

  // Resets the activity of a contact
  reset: function (e) {
    e.preventDefault();
    this.item.reset().done(
      function () {
        this.displayMessage(window.lang.contacts.feedback.reseted);
        this.reload();
      }.bind(this)
    );
  },
});
