<script setup lang="ts">
import { ref, PropType, computed, watch } from "vue";
import type { Component } from "vue";
import { useBreakpoints } from "@composables/breakpoints";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

export interface TabItem {
  id: string;
  label: string;
  count?: string;
  icon?: Component;
}

const props = defineProps({
  tabs: {
    type: Object as PropType<TabItem[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  teleportTabs: {
    type: String,
    default: null,
  },
  teleportPanel: {
    type: String,
    default: null,
  },
  teleportContent: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(["tabChange"]);

const { smBp } = useBreakpoints();

const selectedTabId = ref(props.tabs[0]?.id);

const selectedTabIndex = computed(() => {
  return props.tabs.findIndex((t) => t.id === selectedTabId.value);
});

const onTabChange = (index: number) => {
  selectedTabId.value = props.tabs[index].id;
};

watch(selectedTabId, (value) => {
  emit("tabChange", value);
});

// watch(
//   () => [...props.tabs],
//   () => {
//     if (!selectedTabId.value && props.tabs?.length > 0) {
//       // console.log("tabs change", selectedTabId.value, props.tabs?.length, props.tabs[0].id);
//       selectedTabId.value = props.tabs[0].id;
//       // console.log("selectedTabId.value", selectedTabId.value);
//     }
//   }
// );
</script>

<template>
  <template v-if="smBp">
    <TabGroup as="div" :default-index="selectedTabIndex" @change="onTabChange">
      <teleport :to="teleportTabs" :disabled="!teleportTabs">
        <div class="z-10 flex justify-between border-b border-gray-200 bg-white px-8 md:sticky md:top-0 md:h-14">
          <div v-if="loading" class="flex animate-pulse space-x-4 self-center">
            <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
            <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
            <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
          </div>
          <div v-else class="self-end">
            <TabList as="nav" class="-mb-px flex space-x-8" aria-label="Tabs">
              <Tab v-for="tab in tabs" :key="tab.id" v-slot="{ selected }" as="template">
                <button
                  :class="[
                    selected
                      ? 'border-sky-500 text-sky-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium ring-0 focus:outline-none',
                    'focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sky-200',
                  ]"
                  :aria-current="selected ? 'page' : undefined"
                >
                  <component
                    :is="tab.icon"
                    :class="[
                      selected ? 'text-sky-500' : 'text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5',
                    ]"
                    aria-hidden="true"
                  />
                  <span>{{ tab.label }}</span>
                  <span
                    v-if="tab.count !== undefined"
                    :class="[
                      selected ? 'bg-sky-100 text-sky-600' : 'bg-gray-100 text-gray-400 group-hover:text-gray-500',
                      'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                    ]"
                    >{{ tab.count }}</span
                  >
                </button>
              </Tab>
            </TabList>
          </div>
          <div class="ml-4 self-center">
            <slot name="right-pane"></slot>
          </div>
        </div>
      </teleport>

      <teleport :to="teleportPanel" :disabled="!teleportPanel">
        <TabPanels as="section" class="mx-8">
          <TabPanel v-for="(tab, idx) in tabs" :key="idx" tabindex="-1">
            <teleport :to="teleportContent" :disabled="!teleportContent">
              <slot :name="tab.id"></slot>
            </teleport>
          </TabPanel>
        </TabPanels>
      </teleport>
    </TabGroup>
  </template>
  <template v-else>
    <div class="mx-4 mt-4">
      <div>
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          id="tabs"
          v-model="selectedTabId"
          name="tabs"
          class="block w-full rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500"
        >
          <option v-for="tab in tabs" :key="tab.id" :value="tab.id">
            {{ tab.label }} {{ tab.count !== undefined ? `(${tab.count})` : "" }}
          </option>
        </select>
      </div>

      <div class="mt-4">
        <slot name="right-pane"></slot>
      </div>
    </div>
    <section class="mx-4 mt-4">
      <slot :name="selectedTabId"></slot>
    </section>
  </template>
</template>
