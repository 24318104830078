<template>
  <div class="max-w- mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
      </p>
    </div>

    <div class="mt-5">
      <SimpleButton type="button" theme="white" class="mt-6 w-full" @click="goToLogin">
        <template #leading>
          <ChevronLeftIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        {{ t("goBack") }}
      </SimpleButton>

      <div class="mt-5 text-center text-sm leading-6">
        <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
          t("loginProblems")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// components
import SimpleButton from "@atoms/SimpleButton.vue";

// Icons
import { ChevronLeftIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  goToLogin: [void];
  helpArticle: [void];
}>();

const goToLogin = () => {
  emit("goToLogin");
};

const openHelpArticle = () => {
  emit("helpArticle");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Recuperar contraseña",
    "subtitle": "Revisa tu bandeja de entrada. Te hemos enviado un email con instrucciones para actualizar tu contraseña.",
    "loginProblems": "¿Problemas para ingresar?",
    "goBack": "Volver"
  },
  "pt": {
    "title": "Recuperar senha",
    "subtitle": "Verifique sua caixa de entrada. Enviamos um e-mail com instruções para atualizar sua senha.",
    "loginProblems": "Problemas ao entrar?",
    "goBack": "Voltar"
  }
}
</i18n>
