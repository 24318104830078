export const updateQueryParam = ({ key, value }: { key: string; value: string }): void => {
  const urlObject = new URL(location.href);

  if (urlObject.searchParams.has(key)) {
    urlObject.searchParams.set(key, value);
  } else {
    urlObject.searchParams.append(key, value);
  }

  window.history.replaceState({}, "", urlObject.toString());
};

export const removeQueryParam = ({ key }: { key: string }): void => {
  const urlObject = new URL(location.href);
  urlObject.searchParams.delete(key);
  window.history.replaceState({}, "", urlObject.toString());
};

export const getQueryParams = (): Map<string, string> => {
  const queryParams = new Map<string, string>();
  const url = new URL(location.href);

  url.searchParams.forEach((value, key) => {
    queryParams.set(key, value);
  });

  return queryParams;
};

export const cleanQueryParams = (): void => {
  const baseUrl = window.location.href.split("?")[0];

  window.history.replaceState({}, "", baseUrl);
};
