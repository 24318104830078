var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog'>\n    <form data-form='save-params'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"stepParams"))) && lookupProperty(stack1,"configuration")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group'>\n                        <label>Plantilla</label>\n                        <select name=\"template_id\" class='form-control'>\n                            <option value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"template_id") || (depth0 != null ? lookupProperty(depth0,"template_id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"template_id","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":58}}}) : helper)))
    + "\" selected>"
    + alias2(((helper = (helper = lookupProperty(helpers,"template_id") || (depth0 != null ? lookupProperty(depth0,"template_id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"template_id","hash":{},"data":data,"loc":{"start":{"line":20,"column":69},"end":{"line":20,"column":84}}}) : helper)))
    + "</option>\n                        </select>\n                       \n                        <p class=\"input-legend\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":24,"column":48},"end":{"line":24,"column":65}}}) : helper))) != null ? stack1 : "")
    + "</p>\n                    </div>\n\n                </div>\n                <div class='modal-footer'>\n                    <button type='button' class='btn btn-link' data-dismiss='modal'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                    </button>\n                    <button type='submit' class='btn btn-default btn-primary'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                    </button>\n                </div>\n            </div>\n    </form>\n</div>";
},"useData":true});