<template>
  <div class="flex items-end space-x-4" data-intercom-target="PageTitleLinks">
    <a
      v-if="tourIdSelected && mdBp && !isWhiteLabel"
      ref="vueTakeTourRef"
      href=""
      class="inline-flex items-center gap-2 text-sm font-medium leading-5 text-gray-400 transition-colors duration-150 hover:text-gray-300 focus:text-gray-300 active:focus:text-gray-400"
      data-intercom-target="PageTitleLink-TakeTour"
      @click.prevent="takeTour"
    >
      <AnnotationIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />
      <span v-if="lgBp" class="whitespace-nowrap">{{ t("takeTour") }}</span>
    </a>
    <a
      v-if="helpIdSelected && mdBp && !isWhiteLabel"
      ref="vueHelpCenterRef"
      href=""
      target="_blank"
      class="inline-flex items-center gap-2 text-sm font-medium leading-5 text-gray-400 transition-colors duration-150 hover:text-gray-300 focus:text-gray-300 active:focus:text-gray-400"
      data-intercom-target="PageTitleLink-HelpArticle"
      @click.prevent="openArticle"
    >
      <BookOpenIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />
      <span v-if="lgBp" class="whitespace-nowrap">{{ t("helpCenter") }}</span>
    </a>
    <a
      v-if="videosArticleSelected && mdBp && !isWhiteLabel"
      ref="vueVideoTutorialsRef"
      href=""
      target="_blank"
      class="inline-flex items-center gap-2 text-sm font-medium leading-5 text-gray-400 transition-colors duration-150 hover:text-gray-300 focus:text-gray-300 active:focus:text-gray-400"
      data-intercom-target="PageTitleLink-VideoTutorial"
      @click.prevent="openVideosArticle"
    >
      <PlayIcon class="-ml-0.5 h-4 w-4" aria-hidden="true" />
      <span v-if="lgBp" class="whitespace-nowrap">{{ t("videoTutorials") }}</span>
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

//Icons
import { BookOpenIcon, AnnotationIcon, PlayIcon } from "@heroicons/vue/solid";

//Utils
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@composables/breakpoints";
import { localesLanguages } from "@locales/i18n";
import { useTooltip } from "@composables/tooltips";

//Types
export interface Link {
  helpId?: number;
  tourId?: number;
  videosArticleId?: number;
}

export type Links = Partial<Record<localesLanguages, Link>>;

const { lgBp, mdBp } = useBreakpoints();
const { t, locale } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    links: Links;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: "tour"): void;
  (e: "help"): void;
  (e: "video"): void;
}>();

const vueTakeTourRef = ref();
const vueHelpCenterRef = ref();
const vueVideoTutorialsRef = ref();

const takeTourText = ref(t("takeTour"));
const helpCenterText = ref(t("helpCenter"));
const videoTutorialsText = ref(t("videoTutorials"));

const vueTakeTourTooltip = useTooltip(vueTakeTourRef, takeTourText, { placement: "bottom" });
const vueHelpCenterTooltip = useTooltip(vueHelpCenterRef, helpCenterText, { placement: "bottom" });
const vueVideoTutorialsTooltip = useTooltip(vueVideoTutorialsRef, videoTutorialsText, { placement: "bottom" });

watchEffect(() => {
  if (lgBp.value) {
    vueTakeTourTooltip.disable();
    vueHelpCenterTooltip.disable();
    vueVideoTutorialsTooltip.disable();
  } else {
    vueTakeTourTooltip.enable();
    vueHelpCenterTooltip.enable();
    vueVideoTutorialsTooltip.enable();
  }
});

//State
const windowConfig = computed(() => window.config);
const isWhiteLabel = computed(() => windowConfig.value?.whiteLabel ?? false);

const lang = computed(() => (locale.value?.slice(0, 2) as localesLanguages) || Object.keys(props.links)[0]);

const helpIdSelected = computed(() => (props.links[lang.value] ? props.links[lang.value]?.helpId : undefined));
const tourIdSelected = computed(() => (props.links[lang.value] ? props.links[lang.value]?.tourId : undefined));
const videosArticleSelected = computed(() =>
  props.links[lang.value] ? props.links[lang.value]?.videosArticleId : undefined
);

const takeTour = () => {
  const element = document.getElementById("perfitPageUniqueID");

  if (element) {
    element.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  emit("tour");
  window.Intercom("startTour", tourIdSelected.value);
};

const openArticle = () => {
  emit("help");
  window.Intercom("showArticle", helpIdSelected.value);
};
const openVideosArticle = () => {
  emit("video");
  window.Intercom("showArticle", videosArticleSelected.value);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "helpCenter": "Ayuda",
    "takeTour": "Tour",
    "videoTutorials": "Videos"
  },
  "pt": {
    "helpCenter": "Ajuda",
    "takeTour": "Tour",
    "videoTutorials": "Videos"
  }
}
</i18n>
