<template>
  <div>
    <div v-if="selected.length > 0">
      <h2 class="sticky m-0 border-b border-gray-100 px-8 py-4 text-sm font-medium text-gray-900">
        {{ t("selectedTitle") }}
      </h2>
      <button
        v-for="listItem in selected"
        :key="listItem.key"
        :class="[
          {
            'bg-sky-50': selectedListKeys.includes(listItem.key),
          },
          'flex w-full items-center space-x-4 border-b px-8 py-4',
        ]"
        @click="selectList(listItem)"
      >
        <CheckBox :model-value="selectedListKeys.includes(listItem.key)" class="pointer-events-none" />
        <h3 class="m-0 text-sm font-medium text-sky-400">{{ listItem.value }}</h3>
        <div v-if="listItem.content?.dateText" class="flex items-center space-x-2 text-gray-400">
          <CalendarIcon class="h-4 w-4" />
          <span class="text-sm">{{ listItem.content?.dateText }}</span>
        </div>
        <div v-if="listItem.content?.contactsText" class="flex items-center space-x-2 text-gray-400">
          <UsersIcon class="h-4 w-4" />
          <span class="text-sm">{{ listItem.content?.contactsText }}</span>
        </div>
        <TagsGroup
          v-if="listItem.content?.tags"
          :selected-tags="listItem.content?.tags"
          :max-visible-tags="0"
          :show-tag-colors="false"
          tag-size="large"
          readonly
          placement="bottom-start"
          :auto-placements="['bottom-start', 'top-start', 'right-start']"
        />
      </button>
    </div>
    <div v-for="list in filteredLists" :key="list.id">
      <h2 class="m-0 border-b border-gray-100 px-8 py-4 text-sm font-medium text-gray-900">
        {{ list.value }}
      </h2>
      <button
        v-for="listItem in list.list"
        :key="listItem.key"
        :class="[
          {
            'bg-sky-50': selectedListKeys.includes(listItem.key),
          },
          'flex w-full items-center space-x-4 border-b px-8 py-4',
        ]"
        @click="selectList(listItem)"
      >
        <CheckBox :model-value="selectedListKeys.includes(listItem.key)" class="pointer-events-none" />
        <h3 class="m-0 text-sm font-medium text-sky-400">{{ listItem.value }}</h3>
        <div v-if="listItem.content?.dateText" class="flex items-center space-x-2 text-gray-400">
          <CalendarIcon class="h-4 w-4" />
          <span class="text-sm">{{ listItem.content?.dateText }}</span>
        </div>
        <div v-if="listItem.content?.contactsText" class="flex items-center space-x-2 text-gray-400">
          <UsersIcon class="h-4 w-4" />
          <span class="text-sm">{{ listItem.content?.contactsText }}</span>
        </div>
        <TagsGroup
          v-if="listItem.content?.tags"
          :selected-tags="listItem.content?.tags"
          :max-visible-tags="0"
          :show-tag-colors="false"
          tag-size="large"
          readonly
        />
      </button>
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="
    ListId extends string = string,
    ListValueType extends string = string,
    KeyType extends string = string,
    ValueType extends string = string,
    DataType = Record<string, unknown>
  "
>
import type { ListItems, ListDataItem, Lists } from "../listSelector.types";

// Components
import CheckBox from "@atoms/CheckBox.vue";
import TagsGroup from "@organisms/Tags/TagsGroup/TagsGroup.vue";

// Icon
import { CalendarIcon, UsersIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

withDefaults(
  defineProps<{
    selectedListKeys: Array<string>;
    filteredLists: Lists<ListId, ListValueType, KeyType, ValueType, DataType>;
    selected: ListItems<KeyType, ValueType, DataType>;
  }>(),
  {},
);

const emit = defineEmits<{
  selectList: [ListDataItem<KeyType, ValueType, DataType>];
}>();

const selectList = (list: ListDataItem<KeyType, ValueType, DataType>) => {
  emit("selectList", list);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "selectedTitle": "Seleccionadas"
  },
  "pt": {
    "selectedTitle": "Seleccionadas"
  }
}
</i18n>
