<template>
  <ListSelector :open="open" allow-create @close="close" @select-lists="addToLists" />
</template>

<script lang="ts" setup>
import type { ListItems } from "@organisms/Lists/ListSelector";

// Components
import ListSelector from "@organisms/Lists/ListSelector";

// Composables
import { useNotifications } from "@composables/notifications";

// Utils
import { v4 as uuid } from "uuid";

// Store
import { useNotificationStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { useContacts } from "@api/modules/contacts/contacts";

// Domain
import type { ContactRows } from "../audienceContacts.types";

const contactsAPI = useContacts();
const { notify } = useNotifications();
const { t } = useI18n();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    contacts: ContactRows;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  addedToLists: [void];
}>();

const close = () => {
  emit("close");
};

const addToLists = async (selectedList: ListItems) => {
  let counter = 0;
  const maxCounter = props.contacts.length * selectedList.length;

  const taskId = uuid();
  close();

  if (props.contacts.length > 1 || selectedList.length > 1) {
    notificationStore.createFixedTask({
      id: taskId,
      name: t("taskImport.name"),
      message: t("taskImport.message"),
      progress: 0,
      state: "PROCESSING",
      type: "CUSTOM",
    });
    notify({
      title: t(`notifications.addingToList.title`),
      text: t(`notifications.addingToList.text`),
      theme: "success",
      timeout: 8000,
    });
    const openNotificationsButton = document.getElementById("open-notifications-button-id");
    openNotificationsButton?.click();
  }

  for (const list of selectedList) {
    if (!list.data?.id) continue;

    for (const contact of props.contacts) {
      if (!list.data?.id || !contact.data.id) continue;

      try {
        await contactsAPI.addToList({
          contactId: contact.data.id.toString(),
          listId: list.data.id.toString(),
        });
      } catch {
        // No handler
      } finally {
        counter++;
        const percentage = (counter * 100) / maxCounter;
        notificationStore.updateFixedTaskProgress(taskId, percentage);
      }
    }
  }
  notificationStore.deleteTask(taskId);

  emit("addedToLists");
  notify({
    title: t(`notifications.addToLists.title`, {}, { plural: selectedList.length }),
    text: t(`notifications.addToLists.text`, {}, { plural: selectedList.length }),
    theme: "success",
  });
};
</script>
<i18n lang="json">
{
  "es": {
    "taskImport": {
      "name": "Agregando contactos",
      "message": "Se están agregando los contactos a las listas."
    },
    "notifications": {
      "addingToList": {
        "title": "Agregando contactos",
        "text": "Se están agregando los contactos a la lista. No cierre la pestaña del navegador hasta que termine el proceso."
      },
      "addToLists": {
        "title": "Agregado a lista | Agregado a listas",
        "text": "Se agregó el contacto a la lista. | Se agregaron los contactos a las listas."
      }
    }
  },
  "pt": {
    "taskImport": {
      "name": "Adicionando contatos",
      "message": "Os contatos estão sendo adicionados às listas."
    },
    "notifications": {
      "addingToList": {
        "title": "Adicionando contatos",
        "text": "Os contatos estão sendo adicionados à lista. Não feche a aba do navegador até que o processo seja concluído."
      },
      "addToLists": {
        "title": "Adicionado à lista | Adicionados às listas",
        "text": "O contato foi adicionado à lista. | Os contatos foram adicionados às listas."
      }
    }
  }
}
</i18n>
