import { DataPoint } from "@composables/heatmap";
import { random } from "lodash";

export interface GetDataPointsParams {
  element: HTMLIFrameElement;
  metrics: Array<{
    clicks: number;
    url: string;
    linkId: string;
  }>;
}

const HEATMAP_POINTS_SPREAD_Y = 20;
const HEATMAP_POINTS_SPREAD_X = 30;
const HEATMAP_POINTS_MINIMUM_TO_SPREAD = 5;

export const getDataPoints = ({
  element,
  metrics,
}: GetDataPointsParams): {
  dataPoints: Array<DataPoint>;
  randomDataPoints: Array<DataPoint>;
} => {
  return metrics.reduce<{
    dataPoints: Array<DataPoint>;
    randomDataPoints: Array<DataPoint>;
  }>(
    (data, metric) => {
      const linkId: string | undefined = metric.linkId.split("_")?.[2];

      const aTagElements: NodeListOf<HTMLAnchorElement> | undefined =
        linkId === undefined
          ? element?.contentDocument?.querySelectorAll(`a[href="${metric.url}"]`)
          : element?.contentDocument?.querySelectorAll(`a[data-id="${linkId}"]`);

      if (!aTagElements || aTagElements.length === 0) return data;

      aTagElements.forEach((element) => {
        const elementReference = element.children.item(0) ?? element;
        const ElementRect = elementReference?.getBoundingClientRect();

        const centerX = (ElementRect.left + ElementRect.right) / 2;
        const centerY = (ElementRect.top + ElementRect.bottom) / 2;

        if (centerX === 0 && centerY === 0) return undefined;

        if (metric.clicks >= HEATMAP_POINTS_MINIMUM_TO_SPREAD) {
          for (let i = 0; i < metric.clicks; i++) {
            const XFloor = Number(centerX) - HEATMAP_POINTS_SPREAD_X;
            const XCeiliing = Number(centerX) + HEATMAP_POINTS_SPREAD_X;

            const YFloor = Number(centerY) - HEATMAP_POINTS_SPREAD_Y;
            const YCeiliing = Number(centerY) + HEATMAP_POINTS_SPREAD_Y;

            data.randomDataPoints.push({
              x: Math.round(random(XFloor, XCeiliing)),
              y: Math.round(random(YFloor, YCeiliing)),
              url: metric.url,
              left: ElementRect.left,
              right: ElementRect.right,
              bottom: ElementRect.bottom,
              top: ElementRect.top,
              value: 1,
            });
          }
        } else {
          data.randomDataPoints.push({
            x: Math.round(Number(centerX)),
            y: Math.round(Number(centerY)),
            url: metric.url,
            left: ElementRect.left,
            right: ElementRect.right,
            bottom: ElementRect.bottom,
            top: ElementRect.top,
            value: metric.clicks,
          });
        }

        data.dataPoints.push({
          x: Math.round(Number(centerX)),
          y: Math.round(Number(centerY)),
          url: metric.url,
          left: ElementRect.left,
          right: ElementRect.right,
          bottom: ElementRect.bottom,
          top: ElementRect.top,
          value: metric.clicks,
        });
      });

      return data;
    },
    {
      dataPoints: [],
      randomDataPoints: [],
    } as {
      dataPoints: Array<DataPoint>;
      randomDataPoints: Array<DataPoint>;
    }
  );
};

export interface MetricsUrlsHaveDataIdsParams {
  metrics: Array<{
    clicks: number;
    url: string;
    linkId: string;
  }>;
}
export const metricsUrlsHaveDataIds = ({ metrics }: MetricsUrlsHaveDataIdsParams) => {
  return metrics.some((metric) => {
    const linkId: string | undefined = metric.linkId.split("_")?.[2];
    return linkId !== undefined;
  });
};
