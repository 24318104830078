<template>
  <ModalDialog :title="t('title')" :open="open" without-overflow @close="closeModal">
    <div class="w-[36rem] space-y-4">
      <SelectMenuLabel
        v-model:selected="selectedPeriod"
        :label="t('monthPeriodLabel')"
        :data-items="periods"
        :rem-height="11"
        class="w-40"
      />
      <InputCurrencyFormat
        v-model="amount"
        :currency="currency"
        :label="t('amountLabel')"
        :placeholder="t('amountPlaceholder')"
        :error="v$.amount.$error ? t('amountRequired') : undefined"
        @blur="v$.$validate()"
      />
      <FormCheckBox v-model:model-value="markAsPaid" :label="t('markAsPaidLabel')" />
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" :disabled="loading || v$.$error" @click="remakeTN">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="closeModal">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

//Components
import SimpleButton from "@atoms/SimpleButton.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItem, DataItems } from "@molecules/SelectMenuLabel.vue";

import InputCurrencyFormat from "@molecules/InputCurrencyFormat.vue";
import type { Currencies } from "@molecules/InputCurrencyFormat.vue";

import FormCheckBox from "@molecules/FormCheckBox.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { useInvoiceService } from "@services";

//Props
const props = withDefaults(
  defineProps<{
    invoiceId: string;
    amount: string;
    currency: Currencies;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [void];
}>();

const { t } = useI18n();

const invoicesService = useInvoiceService();

//Const
const periods = ref([
  {
    key: "1",
    value: "1"
  },
  {
    key: "3",
    value: "3"
  },
  {
    key: "6",
    value: "6"
  },
  {
    key: "12",
    value: "12"
  }
] as DataItems<unknown, string, string>);

const loading = ref(false);

const selectedPeriod = ref<DataItem<unknown, string, string>>(periods[0]);
const amount = ref<string>("");
const markAsPaid = ref<boolean>(false);

const validationRules = {
  amount: { required }
};

const v$ = useVuelidate(validationRules, { amount });

const open = ref(false);

const remakeTN = async () => {
  try {
    const validation = await v$.value.$validate();
    if (!validation) return;

    loading.value = true;
    await invoicesService.remakeTNInvoice({
      invoiceId: props.invoiceId,
      amount: amount.value,
      mark_as_paid: markAsPaid.value,
      month_period: selectedPeriod.value?.value
    });
    loading.value = false;
    closeModal();
  } catch (e) {
    loading.value = false;
  }
};

const resetValues = () => {
  selectedPeriod.value = periods[0];
  amount.value = props.amount;
};

const openModal = () => {
  resetValues();
  open.value = true;
};
const closeModal = () => {
  open.value = false;
  emit("close");
};

defineExpose({
  openModal,
  closeModal
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Facturación TN",
    "monthPeriodLabel": "Periodos (Meses)",
    "amountLabel": "Monto",
    "amountPlaceholder": "Monto",
    "amountRequired": "El monto es requerido",
    "markAsPaidLabel": "Marcar como pagada",
    "acceptButton": "Facturar",
    "cancelButton": "Cancelar"
  },
  "pt": {
    "title": "Faturamento TN",
    "monthPeriodLabel": "Períodos (Meses)",
    "amountLabel": "Valor",
    "amountPlaceholder": "Valor",
    "amountRequired": "O valor é obrigatório",
    "markAsPaidLabel": "Marcar como pago",
    "acceptButton": "Faturar",
    "cancelButton": "Cancelar"
  }
}
</i18n>
