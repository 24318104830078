import app from "@/app";
import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import { Languages } from "@/vue/locales/i18n";
const moment = window.moment;

export default Model.extend({
  url: function () {
    return this.id;
  },
  relationships: {
    industries: [
      "clothing_accesories",
      "antiques",
      "art",
      "cars",
      "home_garden",
      "food_drinks",
      "industrial",
      "sports",
      "digital",
      "education",
      "electronics_it",
      "clothing",
      "office_supplies",
      "jewelry",
      "toys",
      "books",
      "pets",
      "music_movies",
      "gifts",
      "health_beauty",
      "services",
      "travel",
      "erotics",
      "bookstore_graphic",
      "equipment_machines",
    ],
    languages: ["es", "pt"],
    countries: function () {
      return $.get("/data/countries-es.json");
    },
  },
  additionals: {
    users: function () {
      var route = app.router.currentRoute(),
        account = app.session.get("account");
      if (route === "accounts/new") {
        return app.helpers.emptyRelationship();
      }
      if (route.startsWith(["accounts/"])) {
        account = route.replace("accounts/", "");
      }
      return $.ajax({
        headers: {
          "Account-Prefix": false,
        },
        url: account + "/users",
        data: {
          limit: 100000,
          sortBy: "name",
          sortDir: "asc",
        },
      });
    },
    experience: [
      { id: "none", name: "" },
      { id: "noob", name: "¿Qué es email marketing?" },
      { id: "experienced", name: "Tengo algo de experiencia" },
      { id: "specialist", name: "Sí, soy especialista" },
    ],
    mediums: [
      { id: "none", name: "" },
      { id: "tiendanube", name: "Tiendanube" },
      { id: "user", name: "Ya utilicé Perfit en otra ocasión" },
      { id: "recommendation", name: "Recomendación" },
      { id: "agency", name: "Mi agencia lo sugirió" },
      { id: "mshops", name: "Mercado Shops" },
      { id: "footer", name: "Recibí un correo con el pie de Perfit" },
      { id: "ads", name: "Publicidad online" },
      { id: "search", name: "Búsqueda en Google" },
      { id: "social", name: "Redes sociales" },
      { id: "blog", name: "Blog" },
      { id: "other", name: "Otro" },
    ],
    baseLength: [
      { id: "none", name: "" },
      { id: "idk", name: "No lo se" },
      { id: "0", name: "No tengo una lista de contactos" },
      { id: "1k", name: "Menos de 1.000 contactos" },
      { id: "10k", name: "Entre 1.000 y 10.000 contactos" },
      { id: "50k", name: "Entre 10.000 y 50.000 contactos" },
      { id: "100k", name: "Entre 50.000 y 100.000 contactos" },
      { id: "100k+", name: "Mas de 100.000 contactos" },
    ],
    goals: [
      { id: "none", name: "" },
      { id: "sales", name: "Aumentar mis ventas" },
      { id: "branding", name: "Posicionar mi marca" },
      { id: "comunication", name: "Mejorar la comunicación" },
      { id: "costs", name: "Reducir costos de mi solución actual" },
      { id: "browsing", name: "Sólo estoy mirando" },
      { id: "other", name: "Otro" },
    ],
    preferredContactChannels1: [
      { id: "whatsapp", name: "Whatsapp" },
      { id: "email", name: "Email" },
    ],
    preferredContactChannels2: [
      { id: "phone", name: "Llamado telefónico" },
      { id: "chat", name: "Chat online" },
    ],
    account: function () {
      return $.ajax({
        url: app.session.get("account"),
        headers: {
          "Account-Prefix": false,
        },
      });
    },
    approval: function () {
      if (app.session.get("isMasterUser")) {
        return $.get("campaigns/approval");
      }
      return app.helpers.emptyRelationship();
    },
    timezone: function () {
      const countryTimezones = moment.tz.zonesForCountry(app.detectedCountry);
      const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!countryTimezones[userTz]) {
        countryTimezones.unshift(userTz);
      }
      const otherTimezones = moment.tz.names().filter((tz) => !countryTimezones.includes(tz));
      const tz = [...countryTimezones, "-", ...otherTimezones];

      return {
        success: function (callback) {
          callback({ data: tz });
        },
      };
    },
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  parse: function (account) {
    // Get data from API direct call
    if (_.isDefined(account.data)) {
      account = account.data;
    }

    // Parse data
    account.id = account.code;
    account.hasEmailsLimit = account.plan.emails.limit && account.plan.type === "CHILD_LIMITED";
    return account;
  },
  parseAdditionals: function (additionals) {
    // Add myself flag in users
    if (_.isDefined(additionals.users) && _.isArray(additionals.users)) {
      additionals.users = additionals.users.map(function (user) {
        var username = app.session.get("user"),
          account = app.session.get("account");
        user.myself = user.username === username && account === app.session.get("mainAccount");
        return user;
      });
    }

    // Return parsed additionals
    return additionals;
  },

  // Enables a user
  enable: function (options) {
    return $.ajax(
      $.extend(true, options, {
        headers: {
          "Account-Prefix": false,
        },
        type: "POST",
        url: this.id + "/enable",
      }),
    );
  },

  // Disables a user
  disable: function (options) {
    return $.ajax(
      $.extend(true, options, {
        headers: {
          "Account-Prefix": false,
        },
        type: "POST",
        url: this.id + "/disable",
      }),
    );
  },

  // Removes a user
  destroy: function (options) {
    return $.ajax(
      $.extend(true, options, {
        headers: {
          "Account-Prefix": false,
        },
        type: "DELETE",
        url: this.id,
      }),
    );
  },

  // Activates drip
  activateDrip: function () {
    return $.post("drips/enable");
  },

  // Activates ads
  activateAd: function () {
    return $.ajax({
      type: "PUT",
      url: "features",
      data: { PERFIT_AD: "1" },
    });
  },

  // Activates ads
  activateSendersV2: function () {
    return $.ajax({
      type: "POST",
      url: "senders/migrate/" + app.session.attributes.token,
    });
  },

  // Removes a user
  requireCampaignsApproval: function (blocked) {
    return $.ajax({
      type: "PUT",
      url: "campaigns/approval",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        blocked: blocked,
      }),
    });
  },

  // allowDisableExclude: function () {
  //   return $.ajax({
  //     type: "PUT",
  //     url: "features",
  //     data: { ALLOW_DISABLE_EXCLUDE: "1" },
  //   });
  // },

  // disallowDisableExclude: function () {
  //   return $.ajax({
  //     type: "PUT",
  //     url: "features",
  //     data: { ALLOW_DISABLE_EXCLUDE: "0" },
  //   });
  // },

  blockImport: function () {
    return $.ajax({
      type: "PUT",
      url: "features",
      data: { IMPORT_BLOCKED: "1" },
    });
  },

  unblockImport: function () {
    return $.ajax({
      type: "PUT",
      url: "features",
      data: { IMPORT_BLOCKED: "0" },
    });
  },

  unchild: function (options) {
    return $.ajax(
      $.extend(true, options, {
        headers: {
          "Account-Prefix": false,
        },
        type: "POST",
        url: this.id + "/unchild",
        data: JSON.stringify({})
      }),
    );
  },
});
