import { nextTick } from "vue";

export const refreshHeight = async ({ iframeID, iframeRef }: { iframeID?: string; iframeRef?: HTMLIFrameElement }) => {
  return new Promise((resolve, reject) => {
    if (!iframeID && !iframeRef) return reject();

    const iframeHost = iframeRef || (iframeID && (document.getElementById(iframeID) as HTMLIFrameElement)) || undefined;

    if (!iframeHost) return reject();
    nextTick(() => {
      if (!iframeHost.contentWindow) return reject();
      iframeHost.style.height = iframeHost.contentWindow.document.body.offsetHeight + "px";
      resolve(true);
    });
  });
};

export const attachToNewIframe = async ({
  html,
  iframeID,
  iframeRef,
}: {
  html: string;
  iframeID?: string;
  iframeRef?: HTMLIFrameElement;
}) => {
  return new Promise((resolve, reject) => {
    if (!iframeID && !iframeRef) return reject();

    const iframeHost = iframeRef || (iframeID && (document.getElementById(iframeID) as HTMLIFrameElement)) || undefined;

    if (!iframeHost) return reject();

    const headTagClosingIndex = html.indexOf("</head>");

    const formattedHTML =
      html.slice(0, headTagClosingIndex) + `<base target="_blank">` + html.slice(headTagClosingIndex);

    iframeHost.addEventListener("load", () => {
      refreshHeight({ iframeRef, iframeID });
      resolve(true);
    });

    iframeHost.contentDocument?.open();
    iframeHost.contentDocument?.write(formattedHTML);
    iframeHost.contentDocument?.close();

    // setTimeout(() => {
    //   console.log("timeout");
    //   refreshHeight({ iframeRef, iframeID });
    // }, 1000);
  });
};
