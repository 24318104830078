<template>
  <div class="flex flex-grow flex-col space-y-6 overflow-auto p-1">
    <Teleport :disabled="noTemplatesCreated" to="#gallery-content-campaigns-filters">
      <FiltersPanel class="z-30 shadow-none">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-4 py-2">
          <SearchInput
            v-if="!noTemplatesCreated"
            :model-value="search"
            :placeholder="t('searchPlaceholder')"
            class="w-[30rem]"
            @finishTyping="updateSearchValue"
          />
          <ToggleButtons
            v-if="!noTemplatesCreated"
            :model-value="tabMode"
            :buttons="[
              { id: 'grid', label: t('toggleButtons.gridTab'), icon: ViewGridIcon },
              { id: 'list', label: t('toggleButtons.listTab'), icon: ViewListIcon },
            ]"
            full
            @update:modelValue="toggleTab"
          />
        </div>
      </FiltersPanel>
    </Teleport>
    <TemplateCatalog
      v-show="(loadingTemplates || isTemplateAvailable) && tabMode === 'grid'"
      :key="tabMode"
      :templates="templates"
      :loading="isTemplateAvailable && loadingTemplates"
      :skeleton="loadingTemplates && !isTemplateAvailable"
      class="w-full overflow-auto pr-1"
      @select="selectTemplate"
      @bottom="fetchNextTemplates"
    />
    <div v-show="(loadingTemplates || isTemplateAvailable) && tabMode === 'list'" class="!m-0 h-full">
      <TemplateCatalogTable
        :templates="templates"
        :skeleton="!isTemplateAvailable && loadingTemplates"
        :skeleton-count="lastTemplatesCount"
        :loading="isTemplateAvailable && loadingTemplates"
        :show="{
          optionsMenu: false,
          selection: false,
        }"
        @select="selectTemplate"
        @bottom-reached="fetchNextTemplates"
      />
    </div>

    <EmptySearch v-if="!loadingTemplates && !isTemplateAvailable && !emptySearch" />
    <TabCampaignsEmpty v-else-if="!loadingTemplates && !isTemplateAvailable && emptySearch" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect } from "vue";

//Components
import SearchInput from "@atoms/SearchInput.vue";
import FiltersPanel from "@templates/FiltersPanel";
import TemplateCatalog from "@organisms/Templates/TemplateCatalog/TemplateCatalog.vue";
import EmptySearch from "@organisms/Templates/TemplateEmptySearch/TemplateEmptySearch.vue";
import TabCampaignsEmpty from "./TabCampaignsEmpty.vue";
import ToggleButtons from "@/vue/components/atoms/ToggleButtons.vue";
import TemplateCatalogTable from "@organisms/Templates/TemplateCatalogTable/TemplateCatalogTable.vue";

// Icons
import { ViewListIcon, ViewGridIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";

//API
import { useEmailTemplate } from "@/vue/api/modules/templates/templates";

//Types
import type { TemplateItem, TemplateList } from "@/vue/types/Templates";
import type { GetTemplatesAsyncGenerator } from "@api/modules/templates/templates.types";
type TabMode = "grid" | "list";

//TemplatesAPI
const templatesAPI = useEmailTemplate();
let templatesGen: GetTemplatesAsyncGenerator | undefined = undefined;

const { t } = useI18n();

//Emits
const emit = defineEmits<{
  (e: "select", selectedTemplate: TemplateItem): void;
}>();

const selectTemplate = (selectedTemplate: TemplateItem) => emit("select", selectedTemplate);

// Toggle
const tabMode = ref<TabMode>("grid");
const toggleTab = () => {
  tabMode.value = tabMode.value === "grid" ? "list" : "grid";
};

//Templates
const loadingTemplates = ref(false);
const noTemplatesCreated = ref(false);
const templates = ref<TemplateList>([]);
const templatesTotal = ref(0);
const lastTemplatesCount = ref(3);
const clearTemplates = () => (templates.value = []);

const search = ref("");
const emptySearch = computed(() => search.value.length === 0);
const updateSearchValue = (newSearchValue: string) => (search.value = newSearchValue);

const isTemplateAvailable = computed(() => {
  if (!templates.value) return false;

  return templates.value.length > 0;
});

const getTemplatesGen = async (search?: string) => {
  loadingTemplates.value = true;
  const gen = await templatesAPI.getTemplates({ name: search, relation: "campaign" });
  loadingTemplates.value = false;

  return gen;
};

const fetchNextTemplates = async () => {
  if (!templatesGen) return;
  loadingTemplates.value = true;

  const value = await templatesGen.next();
  if (value.done) {
    templatesGen = undefined;
    loadingTemplates.value = false;
    return;
  }
  templatesTotal.value = value.value.total;
  templates.value = [...templates.value, ...value.value.data];
  lastTemplatesCount.value = templates.value.length;

  noTemplatesCreated.value = false;
  if (templates.value.length === 0 && emptySearch.value) {
    noTemplatesCreated.value = true;
  }
  loadingTemplates.value = false;
};

//Life cycle
watchEffect(async () => {
  loadingTemplates.value = true;
  clearTemplates();
  templatesGen = await getTemplatesGen(search.value);
  await fetchNextTemplates();
  loadingTemplates.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "searchPlaceholder": "Busca una plantilla por su nombre"
  },
  "pt": {
    "searchPlaceholder": "Procure um template pelo nome"
  }
}
</i18n>
