<template>
  <MenuItem
    v-slot="{ active }"
    :class="[
      'focus:outline-none group flex w-full cursor-pointer items-center rounded-md px-3 py-2 text-sm font-medium focus:ring-0',
    ]"
    @click="select()"
  >
    <div
      :class="[
        item.count !== undefined && 'flex justify-between',
        selected ? 'bg-sky-100 text-gray-900' : 'text-gray-600 ',
        selected && active && 'bg-gray-50',
        !selected && active && 'bg-gray-50',
      ]"
    >
      <span :class="[selected && 'text-gray-900', 'truncate']">
        {{ item.text }}
      </span>
      <span
        v-if="item.count !== undefined"
        :class="[
          selected ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
          'ml-auto inline-block rounded-full py-0.5 px-3 text-xs',
        ]"
      >
        {{ item.count }}
      </span>
    </div>
  </MenuItem>
</template>

<script lang="ts" setup>
//Components
import { MenuItem } from "@headlessui/vue";

//Types
import type { CategoryItem } from "../CategoryMenu.types";

//Props
withDefaults(
  defineProps<{
    item: CategoryItem;
    selected?: boolean;
  }>(),
  {}
);

//Emits
const emit = defineEmits<{
  (e: "select"): void;
}>();

const select = () => emit("select");
</script>
