// I18n
import { useI18n } from "vue-i18n";

// Type
import type { AutomationsTabs } from "./automations.types";
import type { Items } from "@templates/PageTemplate";
import type { DataItems, DataItem } from "@domain/data";
import type { Items as CheckboxListItems } from "@molecules/CheckboxListButton";

// Icons
import { TrashIcon, PauseIcon, PlayIcon } from "@heroicons/vue/outline";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { ColumnsIds, AutomationsRow, AutomationsRows } from "./automations.types";
import type { Column, Columns } from "@molecules/Table";
import type { Automation, Automations } from "@domain/automations";
import { markRaw } from "vue";

export const getTabs = (): Items<AutomationsTabs> => {
  const { t } = useI18n();
  return [
    {
      key: "all",
      value: t("tabs.all"),
    },
    {
      key: "active",
      value: t("tabs.active"),
    },
    {
      key: "inactive",
      value: t("tabs.inactive"),
    },
  ];
};

export const automationTagsToDataItems = (tags: Array<string>): DataItems => {
  return tags.map<DataItem>((tag) => ({
    key: tag,
    value: tag,
  }));
};

export const getColumns = (): Columns<ColumnsIds | "options", ColumnsIds> => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);

  return [
    {
      id: "name",
      text: t("columns.name"),
      sortModes: ["ASC", "DESC"],
      textMaxWidth: false,
      headerTextAlign: "left",
      textAlign: "left",
      headerWidthRem: 42,
    },
    {
      id: "triggered",
      text: t("columns.triggered"),
      textMaxWidth: true,
      headerTextAlign: "right",
      textAlign: "right",
      tooltip: t("tooltip.triggered"),
    },
    {
      id: "active",
      text: t("columns.active"),
      textMaxWidth: true,
      headerTextAlign: "right",
      textAlign: "right",
      tooltip: t("tooltip.active"),
    },
    ...((session.value?.isMasterUser
      ? [
          {
            id: "filtered",
            text: t("columns.filtered"),
            textMaxWidth: false,
            headerTextAlign: "right",
            textAlign: "right",
            tooltip: t("tooltip.filtered"),
          },
        ]
      : []) as Columns<ColumnsIds | "options", ColumnsIds>),
    {
      id: "completed",
      text: t("columns.completed"),
      textMaxWidth: false,
      headerTextAlign: "right",
      textAlign: "right",
      tooltip: t("tooltip.completed"),
    },
    ...((session.value?.isMasterUser
      ? [
          {
            id: "failed",
            text: t("columns.failed"),
            textMaxWidth: false,
            headerTextAlign: "right",
            textAlign: "right",
            tooltip: t("tooltip.failed"),
          },
        ]
      : []) as Columns<ColumnsIds | "options", ColumnsIds>),
    {
      id: "convertedAmount",
      text: t("columns.convertedAmount"),
      textMaxWidth: false,
      headerTextAlign: "right",
      textAlign: "right",
      tooltip: t("tooltip.convertedAmount"),
    },
    {
      id: "options",
      headerWidthRem: 3,
    },
  ];
};

export const getListOptions = ({ automationIsActive }: { automationIsActive: boolean }): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      automationIsActive
        ? {
            key: "pause",
            value: t("listOptions.pause"),
            icon: PauseIcon,
          }
        : {
            key: "start",
            value: t("listOptions.start"),
            icon: PlayIcon,
          },
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const automationToRow = (automation: Automation): AutomationsRow => {
  return {
    id: automation.id.toString(),
    content: {
      name: automation.name,
      state: automation.enabled.toString(),
      active: automation?.stats?.active.toString() ?? "0",
      completed: automation?.stats?.completed.toString() ?? "0",
      filtered: (Number(automation?.stats?.exited ?? 0) + Number(automation.stats?.aborted ?? 0)).toString() ?? "0",
      failed: automation?.stats?.failed.toString() ?? "0",
      triggered: automation?.stats?.triggered.toString() ?? "0",
      convertedAmount: automation?.stats?.converted_amount.toString() ?? "",
      options: "",
    },
    data: automation,
  };
};

export const automationsToRows = (automations: Automations): AutomationsRows => {
  return automations.map<AutomationsRow>(automationToRow);
};

export const columnsToList = (
  columns: Columns<ColumnsIds | "options", ColumnsIds>,
): CheckboxListItems<ColumnsIds | "options", string, Column<ColumnsIds | "options", ColumnsIds>> => {
  const fixedColumnsIds = ["name", "state"];
  const filteredColumnsIds = ["options"];
  const filteredColumns = columns.filter((c) => !filteredColumnsIds.includes(c.id));

  return filteredColumns.map((col) => {
    const isDisabled = fixedColumnsIds.some((c) => c.includes(col.id));
    return {
      key: col.id as ColumnsIds | "options",
      value: col.text ?? "",
      data: col,
      content: {
        disabled: isDisabled,
      },
    };
  });
};
