<template>
  <thead
    :class="['sticky z-10 bg-white']"
    :style="{
      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1)',
      top: `${options?.stickyTop ?? 0}rem`,
    }"
  >
    <tr>
      <th v-if="selectable" class="w-12 pl-2 pr-4">
        <CheckBox v-show="!skeleton" :model-value="AllRowsAreSelected" @update:model-value="toggleSelect" />
        <div v-show="skeleton" class="w-2 animate-pulse rounded bg-gray-100 p-2" />
      </th>
      <th
        v-for="column in columns"
        :key="column.id"
        :class="[
          {
            hidden:
              column.breakpoint !== undefined &&
              ((column.breakpoint === 'sm' && !smBp) ||
                (column.breakpoint === 'md' && !mdBp) ||
                (column.breakpoint === 'lg' && !lgBp) ||
                (column.breakpoint === 'xl' && !xlBp) ||
                (column.breakpoint === '2xl' && !xxlBp)),
            'px-8': !column.headerTextAlign || column.headerTextAlign === 'center',
            'pl-10': column.headerTextAlign === 'right',
            'pr-10': column.headerTextAlign === 'left',
          },
          'group bg-white py-4 text-xs font-medium text-gray-400',
        ]"
        :style="{
          width: column.headerWidthRem ? `${column.headerWidthRem}rem` : 'auto',
        }"
      >
        <slot
          v-if="$slots?.[`${column.id}-header`]"
          :name="`${column.id}-header`"
          :item="column"
          :row="({} as Row<RowId, ColId, RowData>)"
          :rows="rows"
          :columns="columns"
          :column="column"
        />
        <button
          v-else-if="column.sortModes"
          :class="[
            {
              'min-w-max': column.textMaxWidth !== undefined && column.textMaxWidth,
              'justify-center': !column.headerTextAlign || column.headerTextAlign === 'center',
              'justify-end': column.headerTextAlign === 'right',
              'justify-start': column.headerTextAlign === 'left',
            },
            sortBy && [column.id, column.sortKey].includes(sortBy?.columnId) ? 'text-sky-500' : 'text-gray-500',
            'group flex w-full items-center space-x-1  focus-within:outline-none',
          ]"
          @click="() => updateSortBy(column)"
        >
          <span
            :ref="(el) => setTooltip(el as Element, column?.tooltip)"
            :style="{
              textAlign: `${column.headerTextAlign ?? 'center'}`,
            }"
            class="uppercase group-focus-visible:outline group-focus-visible:outline-2 group-focus-visible:outline-sky-400"
          >
            {{ column.text }}
          </span>
          <div
            :class="[
              (!sortBy || ![column.id, column.sortKey].includes(sortBy?.columnId)) &&
                'invisible group-hover:visible group-hover:text-gray-300',
              'my-auto',
            ]"
          >
            <ChevronDownIcon
              v-if="
                [column.id, column.sortKey].includes(sortBy?.columnId)
                  ? sortBy?.sort === 'DESC'
                  : column.sortModes.at(0) === 'DESC'
              "
              class="h-5 w-5"
            />
            <ChevronUpIcon v-else class="h-5 w-5" />
          </div>
        </button>
        <div
          v-else-if="column?.text"
          :class="[
            {
              'min-w-max': column.textMaxWidth !== undefined && column.textMaxWidth,
              'justify-center': !column.headerTextAlign || column.headerTextAlign === 'center',
              'justify-end': column.headerTextAlign === 'right',
              'justify-start': column.headerTextAlign === 'left',
            },
            sortBy && [column.id, column.sortKey].includes(sortBy?.columnId) ? 'text-sky-500' : 'text-gray-500',
            'flex',
          ]"
        >
          <span
            :ref="(el) => setTooltip(el as Element, column?.tooltip)"
            class="uppercase"
            :style="{
              textAlign: `${column.headerTextAlign ?? 'center'}`,
            }"
          >
            {{ column.text }}
          </span>
        </div>
      </th>
    </tr>
  </thead>
</template>
<script lang="ts" setup generic="ColId extends string,SortIdType extends string, ColData,RowId extends string, RowData">
import { computed } from "vue";

//Components
import CheckBox from "@atoms/CheckBox.vue";

// Icons
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";

// Composables
import { useBreakpoints } from "@composables/breakpoints";
import { useTooltip } from "@composables/tooltips";

// Types
import type { Column, Columns, Row, Rows, SortColumn, OptionsProps } from "../table.type";

const { smBp, mdBp, lgBp, xlBp, xxlBp } = useBreakpoints();

//Props
const props = withDefaults(
  defineProps<{
    columns: Columns<ColId, SortIdType, ColData>;
    rows: Rows<RowId, ColId, RowData>;
    selectable?: boolean;
    selectedRows?: Rows<RowId, ColId, RowData>;
    allSelected?: boolean;
    sortBy?: SortColumn<ColId | SortIdType>;
    skeleton?: boolean;
    options?: OptionsProps;
  }>(),
  {
    selectable: false,
    allSelected: false,
    selectedRows: () => [],
    sortBy: undefined,
    options: () => ({
      stickyTop: 0,
    }),
  }
);

const emit = defineEmits<{
  (e: "selectAll"): void;
  (e: "deselectAll"): void;
  (e: "sortBy", column: Column<ColId, SortIdType, ColData>): void;
}>();

const toggleSelect = () => {
  if (AllRowsAreSelected.value) {
    emit("deselectAll");
    return;
  }
  emit("selectAll");
};

const updateSortBy = (column: Column<ColId, SortIdType, ColData>) => {
  emit("sortBy", column);
};

const AllRowsAreSelected = computed(() => {
  if (!props.selectedRows) return false;
  if (props.selectedRows.length === 0) return false;

  const mapSelectedRows = props.selectedRows.map((row) => row.id);
  const mapRows = props.rows.filter((row) => row.options?.selectable !== false).map((row) => row.id);

  const sortedRows = mapRows.sort();
  const sortedSelectedRows = mapSelectedRows.sort();

  return sortedRows.every((row, index) => row === sortedSelectedRows?.[index]);
});

const setTooltip = (element: Element | null, text: string | undefined) => {
  if (!text || !element) return;

  useTooltip(element, text, {
    placement: "bottom",
    zIndex: 9999,
  });
};
</script>
