export type CountryCode =
  | "af"
  | "al"
  | "de"
  | "dz"
  | "ad"
  | "ao"
  | "ai"
  | "aq"
  | "ag"
  | "an"
  | "sa"
  | "ar"
  | "am"
  | "aw"
  | "au"
  | "at"
  | "az"
  | "be"
  | "bs"
  | "bh"
  | "bd"
  | "bb"
  | "bz"
  | "bj"
  | "bt"
  | "by"
  | "mm"
  | "bo"
  | "ba"
  | "bw"
  | "br"
  | "bn"
  | "bg"
  | "bf"
  | "bi"
  | "cv"
  | "kh"
  | "cm"
  | "ca"
  | "td"
  | "cl"
  | "cn"
  | "cy"
  | "va"
  | "co"
  | "km"
  | "cg"
  | "cd"
  | "kp"
  | "kr"
  | "ci"
  | "cr"
  | "hr"
  | "cu"
  | "dk"
  | "dm"
  | "ec"
  | "eg"
  | "sv"
  | "ae"
  | "er"
  | "sk"
  | "si"
  | "es"
  | "us"
  | "ee"
  | "et"
  | "ph"
  | "fi"
  | "fj"
  | "fr"
  | "ga"
  | "gm"
  | "ge"
  | "gh"
  | "gi"
  | "gd"
  | "gr"
  | "gl"
  | "gp"
  | "gu"
  | "gt"
  | "gf"
  | "gg"
  | "gn"
  | "gq"
  | "gw"
  | "gy"
  | "ht"
  | "hn"
  | "hk"
  | "hu"
  | "in"
  | "id"
  | "ir"
  | "iq"
  | "ie"
  | "bv"
  | "im"
  | "cx"
  | "nf"
  | "is"
  | "bm"
  | "ky"
  | "cc"
  | "ck"
  | "ax"
  | "fo"
  | "gs"
  | "hm"
  | "mv"
  | "fk"
  | "mp"
  | "mh"
  | "pn"
  | "sb"
  | "tc"
  | "um"
  | "vg"
  | "vi"
  | "il"
  | "it"
  | "jm"
  | "jp"
  | "je"
  | "jo"
  | "kz"
  | "ke"
  | "kg"
  | "ki"
  | "kw"
  | "lb"
  | "la"
  | "ls"
  | "lv"
  | "lr"
  | "ly"
  | "li"
  | "lt"
  | "lu"
  | "mx"
  | "mc"
  | "mo"
  | "mk"
  | "mg"
  | "my"
  | "mw"
  | "ml"
  | "mt"
  | "ma"
  | "mq"
  | "mu"
  | "mr"
  | "yt"
  | "fm"
  | "md"
  | "mn"
  | "me"
  | "ms"
  | "mz"
  | "na"
  | "nr"
  | "np"
  | "ni"
  | "ne"
  | "ng"
  | "nu"
  | "no"
  | "nc"
  | "nz"
  | "om"
  | "nl"
  | "pk"
  | "pw"
  | "ps"
  | "pa"
  | "pg"
  | "py"
  | "pe"
  | "pf"
  | "pl"
  | "pt"
  | "pr"
  | "qa"
  | "gb"
  | "cf"
  | "cz"
  | "do"
  | "re"
  | "rw"
  | "ro"
  | "ru"
  | "eh"
  | "ws"
  | "as"
  | "bl"
  | "kn"
  | "sm"
  | "mf"
  | "pm"
  | "vc"
  | "sh"
  | "lc"
  | "st"
  | "sn"
  | "rs"
  | "sc"
  | "sl"
  | "sg"
  | "sy"
  | "so"
  | "lk"
  | "za"
  | "sd"
  | "se"
  | "ch"
  | "sr"
  | "sj"
  | "sz"
  | "tj"
  | "th"
  | "tw"
  | "tz"
  | "io"
  | "tf"
  | "tl"
  | "tg"
  | "tk"
  | "to"
  | "tt"
  | "tn"
  | "tm"
  | "tr"
  | "tv"
  | "ua"
  | "ug"
  | "uy"
  | "uz"
  | "vu"
  | "ve"
  | "vn"
  | "wf"
  | "ye"
  | "dj"
  | "zm"
  | "zw";

export type CountryName =
  | "Afganistán"
  | "Albania"
  | "Alemania"
  | "Algeria"
  | "Andorra"
  | "Angola"
  | "Anguila"
  | "Antártida"
  | "Antigua y Barbuda"
  | "Antillas Neerlandesas"
  | "Arabia Saudita"
  | "Argentina"
  | "Armenia"
  | "Aruba"
  | "Australia"
  | "Austria"
  | "Azerbayán"
  | "Bélgica"
  | "Bahamas"
  | "Bahrein"
  | "Bangladesh"
  | "Barbados"
  | "Belice"
  | "Benín"
  | "Bhután"
  | "Bielorrusia"
  | "Birmania"
  | "Bolivia"
  | "Bosnia y Herzegovina"
  | "Botsuana"
  | "Brasil"
  | "Brunéi"
  | "Bulgaria"
  | "Burkina Faso"
  | "Burundi"
  | "Cabo Verde"
  | "Camboya"
  | "Camerún"
  | "Canadá"
  | "Chad"
  | "Chile"
  | "China"
  | "Chipre"
  | "Ciudad del Vaticano"
  | "Colombia"
  | "Comoras"
  | "Congo"
  | "Congo"
  | "Corea del Norte"
  | "Corea del Sur"
  | "Costa de Marfil"
  | "Costa Rica"
  | "Croacia"
  | "Cuba"
  | "Dinamarca"
  | "Dominica"
  | "Ecuador"
  | "Egipto"
  | "El Salvador"
  | "Emiratos Árabes Unidos"
  | "Eritrea"
  | "Eslovaquia"
  | "Eslovenia"
  | "España"
  | "Estados Unidos de América"
  | "Estonia"
  | "Etiopía"
  | "Filipinas"
  | "Finlandia"
  | "Fiyi"
  | "Francia"
  | "Gabón"
  | "Gambia"
  | "Georgia"
  | "Ghana"
  | "Gibraltar"
  | "Granada"
  | "Grecia"
  | "Groenlandia"
  | "Guadalupe"
  | "Guam"
  | "Guatemala"
  | "Guayana Francesa"
  | "Guernsey"
  | "Guinea"
  | "Guinea Ecuatorial"
  | "Guinea-Bissau"
  | "Guyana"
  | "Haití"
  | "Honduras"
  | "Hong kong"
  | "Hungría"
  | "India"
  | "Indonesia"
  | "Irán"
  | "Irak"
  | "Irlanda"
  | "Isla Bouvet"
  | "Isla de Man"
  | "Isla de Navidad"
  | "Isla Norfolk"
  | "Islandia"
  | "Islas Bermudas"
  | "Islas Caimán"
  | "Islas Cocos (Keeling)"
  | "Islas Cook"
  | "Islas de Åland"
  | "Islas Feroe"
  | "Islas Georgias del Sur y Sandwich del Sur"
  | "Islas Heard y McDonald"
  | "Islas Maldivas"
  | "Islas Malvinas"
  | "Islas Marianas del Norte"
  | "Islas Marshall"
  | "Islas Pitcairn"
  | "Islas Salomón"
  | "Islas Turcas y Caicos"
  | "Islas Ultramarinas Menores de Estados Unidos"
  | "Islas Vírgenes Británicas"
  | "Islas Vírgenes de los Estados Unidos"
  | "Israel"
  | "Italia"
  | "Jamaica"
  | "Japón"
  | "Jersey"
  | "Jordania"
  | "Kazajistán"
  | "Kenia"
  | "Kirgizstán"
  | "Kiribati"
  | "Kuwait"
  | "Líbano"
  | "Laos"
  | "Lesoto"
  | "Letonia"
  | "Liberia"
  | "Libia"
  | "Liechtenstein"
  | "Lituania"
  | "Luxemburgo"
  | "México"
  | "Mónaco"
  | "Macao"
  | "Macedônia"
  | "Madagascar"
  | "Malasia"
  | "Malawi"
  | "Mali"
  | "Malta"
  | "Marruecos"
  | "Martinica"
  | "Mauricio"
  | "Mauritania"
  | "Mayotte"
  | "Micronesia"
  | "Moldavia"
  | "Mongolia"
  | "Montenegro"
  | "Montserrat"
  | "Mozambique"
  | "Namibia"
  | "Nauru"
  | "Nepal"
  | "Nicaragua"
  | "Niger"
  | "Nigeria"
  | "Niue"
  | "Noruega"
  | "Nueva Caledonia"
  | "Nueva Zelanda"
  | "Omán"
  | "Países Bajos"
  | "Pakistán"
  | "Palau"
  | "Palestina"
  | "Panamá"
  | "Papúa Nueva Guinea"
  | "Paraguay"
  | "Perú"
  | "Polinesia Francesa"
  | "Polonia"
  | "Portugal"
  | "Puerto Rico"
  | "Qatar"
  | "Reino Unido"
  | "República Centroafricana"
  | "República Checa"
  | "República Dominicana"
  | "Reunión"
  | "Ruanda"
  | "Rumanía"
  | "Rusia"
  | "Sahara Occidental"
  | "Samoa"
  | "Samoa Americana"
  | "San Bartolomé"
  | "San Cristóbal y Nieves"
  | "San Marino"
  | "San Martín (Francia)"
  | "San Pedro y Miquelón"
  | "San Vicente y las Granadinas"
  | "Santa Elena"
  | "Santa Lucía"
  | "Santo Tomé y Príncipe"
  | "Senegal"
  | "Serbia"
  | "Seychelles"
  | "Sierra Leona"
  | "Singapur"
  | "Siria"
  | "Somalia"
  | "Sri lanka"
  | "Sudáfrica"
  | "Sudán"
  | "Suecia"
  | "Suiza"
  | "Surinám"
  | "Svalbard y Jan Mayen"
  | "Swazilandia"
  | "Tadjikistán"
  | "Tailandia"
  | "Taiwán"
  | "Tanzania"
  | "Territorio Británico del Océano Índico"
  | "Territorios Australes y Antárticas Franceses"
  | "Timor Oriental"
  | "Togo"
  | "Tokelau"
  | "Tonga"
  | "Trinidad y Tobago"
  | "Tunez"
  | "Turkmenistán"
  | "Turquía"
  | "Tuvalu"
  | "Ucrania"
  | "Uganda"
  | "Uruguay"
  | "Uzbekistán"
  | "Vanuatu"
  | "Venezuela"
  | "Vietnam"
  | "Wallis y Futuna"
  | "Yemen"
  | "Yibuti"
  | "Zambia"
  | "Zimbabue";

export const countriesNameCodeMap: Record<CountryCode, CountryName> = {
  af: "Afganistán",
  al: "Albania",
  de: "Alemania",
  dz: "Algeria",
  ad: "Andorra",
  ao: "Angola",
  ai: "Anguila",
  aq: "Antártida",
  ag: "Antigua y Barbuda",
  an: "Antillas Neerlandesas",
  sa: "Arabia Saudita",
  ar: "Argentina",
  am: "Armenia",
  aw: "Aruba",
  au: "Australia",
  at: "Austria",
  az: "Azerbayán",
  be: "Bélgica",
  bs: "Bahamas",
  bh: "Bahrein",
  bd: "Bangladesh",
  bb: "Barbados",
  bz: "Belice",
  bj: "Benín",
  bt: "Bhután",
  by: "Bielorrusia",
  mm: "Birmania",
  bo: "Bolivia",
  ba: "Bosnia y Herzegovina",
  bw: "Botsuana",
  br: "Brasil",
  bn: "Brunéi",
  bg: "Bulgaria",
  bf: "Burkina Faso",
  bi: "Burundi",
  cv: "Cabo Verde",
  kh: "Camboya",
  cm: "Camerún",
  ca: "Canadá",
  td: "Chad",
  cl: "Chile",
  cn: "China",
  cy: "Chipre",
  va: "Ciudad del Vaticano",
  co: "Colombia",
  km: "Comoras",
  cg: "Congo",
  cd: "Congo",
  kp: "Corea del Norte",
  kr: "Corea del Sur",
  ci: "Costa de Marfil",
  cr: "Costa Rica",
  hr: "Croacia",
  cu: "Cuba",
  dk: "Dinamarca",
  dm: "Dominica",
  ec: "Ecuador",
  eg: "Egipto",
  sv: "El Salvador",
  ae: "Emiratos Árabes Unidos",
  er: "Eritrea",
  sk: "Eslovaquia",
  si: "Eslovenia",
  es: "España",
  us: "Estados Unidos de América",
  ee: "Estonia",
  et: "Etiopía",
  ph: "Filipinas",
  fi: "Finlandia",
  fj: "Fiyi",
  fr: "Francia",
  ga: "Gabón",
  gm: "Gambia",
  ge: "Georgia",
  gh: "Ghana",
  gi: "Gibraltar",
  gd: "Granada",
  gr: "Grecia",
  gl: "Groenlandia",
  gp: "Guadalupe",
  gu: "Guam",
  gt: "Guatemala",
  gf: "Guayana Francesa",
  gg: "Guernsey",
  gn: "Guinea",
  gq: "Guinea Ecuatorial",
  gw: "Guinea-Bissau",
  gy: "Guyana",
  ht: "Haití",
  hn: "Honduras",
  hk: "Hong kong",
  hu: "Hungría",
  in: "India",
  id: "Indonesia",
  ir: "Irán",
  iq: "Irak",
  ie: "Irlanda",
  bv: "Isla Bouvet",
  im: "Isla de Man",
  cx: "Isla de Navidad",
  nf: "Isla Norfolk",
  is: "Islandia",
  bm: "Islas Bermudas",
  ky: "Islas Caimán",
  cc: "Islas Cocos (Keeling)",
  ck: "Islas Cook",
  ax: "Islas de Åland",
  fo: "Islas Feroe",
  gs: "Islas Georgias del Sur y Sandwich del Sur",
  hm: "Islas Heard y McDonald",
  mv: "Islas Maldivas",
  fk: "Islas Malvinas",
  mp: "Islas Marianas del Norte",
  mh: "Islas Marshall",
  pn: "Islas Pitcairn",
  sb: "Islas Salomón",
  tc: "Islas Turcas y Caicos",
  um: "Islas Ultramarinas Menores de Estados Unidos",
  vg: "Islas Vírgenes Británicas",
  vi: "Islas Vírgenes de los Estados Unidos",
  il: "Israel",
  it: "Italia",
  jm: "Jamaica",
  jp: "Japón",
  je: "Jersey",
  jo: "Jordania",
  kz: "Kazajistán",
  ke: "Kenia",
  kg: "Kirgizstán",
  ki: "Kiribati",
  kw: "Kuwait",
  lb: "Líbano",
  la: "Laos",
  ls: "Lesoto",
  lv: "Letonia",
  lr: "Liberia",
  ly: "Libia",
  li: "Liechtenstein",
  lt: "Lituania",
  lu: "Luxemburgo",
  mx: "México",
  mc: "Mónaco",
  mo: "Macao",
  mk: "Macedônia",
  mg: "Madagascar",
  my: "Malasia",
  mw: "Malawi",
  ml: "Mali",
  mt: "Malta",
  ma: "Marruecos",
  mq: "Martinica",
  mu: "Mauricio",
  mr: "Mauritania",
  yt: "Mayotte",
  fm: "Micronesia",
  md: "Moldavia",
  mn: "Mongolia",
  me: "Montenegro",
  ms: "Montserrat",
  mz: "Mozambique",
  na: "Namibia",
  nr: "Nauru",
  np: "Nepal",
  ni: "Nicaragua",
  ne: "Niger",
  ng: "Nigeria",
  nu: "Niue",
  no: "Noruega",
  nc: "Nueva Caledonia",
  nz: "Nueva Zelanda",
  om: "Omán",
  nl: "Países Bajos",
  pk: "Pakistán",
  pw: "Palau",
  ps: "Palestina",
  pa: "Panamá",
  pg: "Papúa Nueva Guinea",
  py: "Paraguay",
  pe: "Perú",
  pf: "Polinesia Francesa",
  pl: "Polonia",
  pt: "Portugal",
  pr: "Puerto Rico",
  qa: "Qatar",
  gb: "Reino Unido",
  cf: "República Centroafricana",
  cz: "República Checa",
  do: "República Dominicana",
  re: "Reunión",
  rw: "Ruanda",
  ro: "Rumanía",
  ru: "Rusia",
  eh: "Sahara Occidental",
  ws: "Samoa",
  as: "Samoa Americana",
  bl: "San Bartolomé",
  kn: "San Cristóbal y Nieves",
  sm: "San Marino",
  mf: "San Martín (Francia)",
  pm: "San Pedro y Miquelón",
  vc: "San Vicente y las Granadinas",
  sh: "Santa Elena",
  lc: "Santa Lucía",
  st: "Santo Tomé y Príncipe",
  sn: "Senegal",
  rs: "Serbia",
  sc: "Seychelles",
  sl: "Sierra Leona",
  sg: "Singapur",
  sy: "Siria",
  so: "Somalia",
  lk: "Sri lanka",
  za: "Sudáfrica",
  sd: "Sudán",
  se: "Suecia",
  ch: "Suiza",
  sr: "Surinám",
  sj: "Svalbard y Jan Mayen",
  sz: "Swazilandia",
  tj: "Tadjikistán",
  th: "Tailandia",
  tw: "Taiwán",
  tz: "Tanzania",
  io: "Territorio Británico del Océano Índico",
  tf: "Territorios Australes y Antárticas Franceses",
  tl: "Timor Oriental",
  tg: "Togo",
  tk: "Tokelau",
  to: "Tonga",
  tt: "Trinidad y Tobago",
  tn: "Tunez",
  tm: "Turkmenistán",
  tr: "Turquía",
  tv: "Tuvalu",
  ua: "Ucrania",
  ug: "Uganda",
  uy: "Uruguay",
  uz: "Uzbekistán",
  vu: "Vanuatu",
  ve: "Venezuela",
  vn: "Vietnam",
  wf: "Wallis y Futuna",
  ye: "Yemen",
  dj: "Yibuti",
  zm: "Zambia",
  zw: "Zimbabue",
};

export const getCountryByCode = (code: CountryCode): CountryName => {
  return countriesNameCodeMap[code];
};
