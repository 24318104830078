<template>
  <div class="flex h-full w-full flex-col">
    <div v-show="loading" class="z-50 w-full shadow-sm">
      <PageHeader>
        <template #title>
          <PageTitle
            size="extraLarge"
            :links="{
              es: {
                helpId: links?.es?.helpId,
                tourId: links?.es?.tourId,
                videosArticleId: links?.es?.videosArticleId,
              },
              pt: {
                helpId: links?.pt?.helpId,
                tourId: links?.pt?.tourId,
                videosArticleId: links?.pt?.videosArticleId,
              },
            }"
          >
            {{ title }}
          </PageTitle>
        </template>
      </PageHeader>
      <div class="z-10 flex justify-between border-b border-gray-200 bg-white px-8 md:h-14">
        <div class="flex animate-pulse space-x-4 self-center">
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
        </div>
      </div>
    </div>

    <div v-show="!loading" class="z-50 w-full shadow-sm">
      <PageHeader>
        <template #title>
          <PageTitle
            size="extraLarge"
            :links="{
              es: {
                helpId: links?.es?.helpId,
                tourId: links?.es?.tourId,
                videosArticleId: links?.es?.videosArticleId,
              },
              pt: {
                helpId: links?.pt?.helpId,
                tourId: links?.pt?.tourId,
                videosArticleId: links?.pt?.videosArticleId,
              },
            }"
          >
            {{ title }}
          </PageTitle>
        </template>
        <template #subtitle>
          <ButtonTabs :tabs="tabs" :selected="selected" @update:selected="updateSelected" />
        </template>
        <template #right>
          <slot name="options" />
        </template>
      </PageHeader>
      <slot name="content" />
    </div>
    <div class="h-10 flex-grow">
      <template v-if="tabs?.length === 0">
        <slot />
      </template>
      <template v-for="tab in tabs" v-else :key="tab.key">
        <div
          v-if="
            !loading &&
            tab.key === selected?.key &&
            (!hasPermission || (tab.content?.permission && !sessionStore.hasPermission(tab.content?.permission)))
          "
          class="flex h-full flex-col items-center justify-center"
        >
          <div class="mx-10 max-w-md">
            <div class="mx-auto flex items-center justify-center">
              <LockClosedIcon class="h-16 w-16 stroke-1 text-yellow-400" aria-hidden="true" />
            </div>
            <h1 class="m-0 mt-4 text-center text-lg font-medium text-gray-900">{{ t("title") }}</h1>
            <div class="mt-1 text-center">
              <TextParagraph>
                {{ t("permissionMessage") }}
              </TextParagraph>
              <div v-show="permissions" class="mt-2 flex flex-col">
                <h3 class="m-0 mb-1 text-sm font-medium text-gray-900">
                  {{ permissions && permissions?.length > 1 ? t("permissionsNeeded") : t("permissionNeeded") }}:
                </h3>
                <p v-if="tab.content?.permission" class="py-0.5 text-sm font-normal text-gray-500">
                  {{ t(`permissions.${tab.content?.permission}`) }}
                </p>
                <p v-for="permission in permissions" :key="permission" class="py-0.5 text-sm font-normal text-gray-500">
                  {{ t(`permissions.${permission}`) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <slot v-else-if="!loading && tab.key === selected?.key" :name="tab.key" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts" generic="ItemKey extends string">
import { computed } from "vue";

// Components
import PageHeader from "@templates/PageHeader.vue";
import PageTitle from "@molecules/PageTitle.vue";
import ButtonTabs from "@molecules/ButtonTabs";
import type { Item, Items } from "@molecules/ButtonTabs";
import TextParagraph from "@atoms/TextParagraph.vue";

// Icon
import { LockClosedIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore } from "@store";

// Domain
// TODO: Fix import {Links} from PageTitle
import type { Links } from "@molecules/HeaderLinks/HeaderLinks.vue";
import type { Permissions } from "@domain/permissions";

const sessionStore = useSessionStore();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    tabs?: Items<ItemKey>;
    selected?: Item<ItemKey>;
    loading?: boolean;
    links?: Links;
    title?: string;
    permissions?: Permissions;
  }>(),
  {
    tabs: () => [],
    selected: undefined,
    loading: false,
    links: undefined,
    title: "",
    permissions: () => [],
  },
);

//Emits
const emit = defineEmits<{
  "update:selected": [tab: Item<ItemKey>];
}>();

const updateSelected = (tab: Item<ItemKey>) => {
  if (props.selected?.key === tab.key) return;

  emit("update:selected", tab);
};

const hasPermission = computed<boolean>(() => {
  return props.permissions.every((p) => sessionStore.hasPermission(p));
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Acción restringida",
    "permissionMessage": "Parece que no tienes los permisos suficientes para ver este contenido, contacta al administrador de la cuenta para obtenerlos.",
    "permissionNeeded": "Necesitas el siguiente permiso",
    "permissionsNeeded": "Necesitas los siguientes permisos",
    "permissions": {
      "campaigns:list": "Ver campañas",
      "campaigns:create": "Crear campañas",
      "campaigns:update": "Modificar campañas",
      "campaigns:delete": "Eliminar campañas",
      "campaigns:launch": "Enviar campañas",
      "campaigns:metrics": "Ver reportes de campañas",
      "lists:list": "Ver listas",
      "lists:create": "Crear listas",
      "lists:update": "Modificar listas",
      "lists:delete": "Eliminar listas",
      "contacts:list": "Ver contactos",
      "contacts:create": "Crear contactos",
      "contacts:update": "Modificar contactos",
      "contacts:delete": "Eliminar contactos",
      "contacts:import": "Importar contactos",
      "contacts:export": "Exportar contactos",
      "fields:list": "Ver campos",
      "fields:create": "Crear campos",
      "fields:update": "Modificar campos",
      "fields:delete": "Eliminar campos",
      "interests:list": "Ver intereses",
      "interests:create": "Crear intereses",
      "interests:update": "Modificar intereses",
      "interests:delete": "Eliminar intereses",
      "optins:list": "Ver formularios",
      "optins:create": "Crear formularios",
      "optins:update": "Modificar formularios",
      "optins:delete": "Eliminar formularios",
      "templates:list": "Ver diseños",
      "templates:create": "Crear diseños",
      "templates:update": "Modificar diseños",
      "templates:delete": "Eliminar diseños",
      "images:list": "Ver imágenes",
      "images:create": "Crear imágenes",
      "images:update": "Modificar imágenes",
      "images:delete": "Eliminar  imágenes",
      "account:plan": "Modificar plan contratado",
      "account:update": "Modificar cuenta",
      "apps:list": "Listar integraciones",
      "apps:create": "Crear integraciones",
      "apps:delete": "Eliminar integraciones",
      "tasks:cancel": "Cancelar tareas"
    }
  },
  "pt": {
    "title": "Acción restringida",
    "permissionMessage": "Parece que no tienes los permisos suficientes para ver este contenido, contacta al administrador de la cuenta para obtenerlos.",
    "permissionNeeded": "Necesitas el siguiente permiso",
    "permissionsNeeded": "Necesitas los siguientes permisos",
    "permissions": {
      "campaigns:list": "Ver campañas",
      "campaigns:create": "Crear campañas",
      "campaigns:update": "Modificar campañas",
      "campaigns:delete": "Eliminar campañas",
      "campaigns:launch": "Enviar campañas",
      "campaigns:metrics": "Ver reportes de campañas",
      "lists:list": "Ver listas",
      "lists:create": "Crear listas",
      "lists:update": "Modificar listas",
      "lists:delete": "Eliminar listas",
      "contacts:list": "Ver contactos",
      "contacts:create": "Crear contactos",
      "contacts:update": "Modificar contactos",
      "contacts:delete": "Eliminar contactos",
      "contacts:import": "Importar contactos",
      "contacts:export": "Exportar contactos",
      "fields:list": "Ver campos",
      "fields:create": "Crear campos",
      "fields:update": "Modificar campos",
      "fields:delete": "Eliminar campos",
      "interests:list": "Ver intereses",
      "interests:create": "Crear intereses",
      "interests:update": "Modificar intereses",
      "interests:delete": "Eliminar intereses",
      "optins:list": "Ver formularios",
      "optins:create": "Crear formularios",
      "optins:update": "Modificar formularios",
      "optins:delete": "Eliminar formularios",
      "templates:list": "Ver diseños",
      "templates:create": "Crear diseños",
      "templates:update": "Modificar diseños",
      "templates:delete": "Eliminar diseños",
      "images:list": "Ver imágenes",
      "images:create": "Crear imágenes",
      "images:update": "Modificar imágenes",
      "images:delete": "Eliminar  imágenes",
      "account:plan": "Modificar plan contratado",
      "account:update": "Modificar cuenta",
      "apps:list": "Listar integraciones",
      "apps:create": "Crear integraciones",
      "apps:delete": "Eliminar integraciones",
      "tasks:cancel": "Cancelar tareas"
    }
  }
}
</i18n>
