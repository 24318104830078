import { ListView } from "@/core/magnet";
import app from "@/app";
import listTpl from "../templates/list.hbs";

export default ListView.extend({
  module: "fields",
  templates: {
    initial: listTpl,
  },
  limit: 100000,
  query: {
    order: "id asc",
    limit: 300,
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },
});
