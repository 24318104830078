<script setup lang="ts">
import { computed } from "vue";
import type { Component } from "vue";

import { useBreakpoints } from "@composables/breakpoints";
import { TabGroup, TabList, Tab } from "@headlessui/vue";

export interface TabItem {
  id: string;
  label: string;
  count?: string;
  icon?: Component;
}

const props = withDefaults(
  defineProps<{
    tabs: Array<TabItem>;
    selectedTabId?: string | undefined;
    loading?: boolean;
  }>(),
  {
    loading: false,
    selectedTabId: "",
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:selectedTabId", tab: string): void;
}>();

const UpdateSelectedTabId = (tab: string) => {
  emit("update:selectedTabId", tab);
};

//consts
const { smBp } = useBreakpoints();

//computed
const selectedTabIndex = computed(() => props.tabs.findIndex((t) => t.id === props.selectedTabId));

//Methods
const onTabChange = (index: number) => {
  UpdateSelectedTabId(props.tabs[index].id);
};

const updateSelectedTab = (event: Event) => {
  const selectedTab = (event.target as HTMLSelectElement).value;
  UpdateSelectedTabId(selectedTab);
};
</script>

<template>
  <template v-if="smBp">
    <div v-if="loading" class="z-10 shadow-sm md:sticky md:top-0">
      <slot name="header"></slot>
      <div class="z-10 flex justify-between border-b border-gray-200 bg-white px-8 md:sticky md:top-0 md:h-14">
        <div v-if="loading" class="flex animate-pulse space-x-4 self-center">
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
          <div class="h-4 w-24 rounded-xl bg-gray-100"></div>
        </div>
      </div>
    </div>

    <TabGroup
      v-if="!loading"
      :key="tabs.length"
      as="div"
      :default-index="selectedTabIndex"
      class="z-10 shadow-sm md:sticky md:top-0"
      @change="onTabChange"
    >
      <slot name="header"></slot>
      <div class="z-10 flex justify-between border-b border-gray-200 bg-white px-8 md:sticky md:top-0 md:h-14">
        <div class="self-end">
          <TabList as="nav" class="-mb-px flex space-x-8" aria-label="Tabs" data-intercom-target="TabList">
            <Tab v-for="tab in tabs" :key="tab.id" v-slot="{ selected }" as="template">
              <button
                :class="[
                  selected
                    ? 'border-sky-500 text-sky-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium ring-0 focus:outline-none',
                  'focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-sky-200',
                ]"
                :aria-current="selected ? 'page' : undefined"
                :data-intercom-target="`TabButton-${tab.id}`"
              >
                <component
                  :is="tab.icon"
                  :class="[
                    selected ? 'text-sky-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5',
                  ]"
                  aria-hidden="true"
                />
                <span class="w-max">{{ tab.label }}</span>
                <span
                  v-if="tab.count !== undefined"
                  :class="[
                    selected ? 'bg-sky-100 text-sky-600' : 'bg-gray-100 text-gray-400 group-hover:text-gray-500',
                    'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block',
                  ]"
                  >{{ tab.count }}</span
                >
              </button>
            </Tab>
          </TabList>
        </div>
        <div class="ml-4 self-center" data-intercom-target="TabsRightPane">
          <slot name="right-pane"></slot>
        </div>
      </div>
    </TabGroup>
  </template>

  <template v-if="!smBp">
    <slot name="header"></slot>
    <div class="mx-4 my-4">
      <div>
        <label for="tabs" class="sr-only">Select a tab</label>
        <select
          id="tabs"
          name="tabs"
          class="block w-full rounded-md border-gray-300 focus:border-sky-500 focus:ring-sky-500"
          :model-value="selectedTabId"
          @change="updateSelectedTab"
        >
          <option v-for="tab in tabs" :key="tab.id" :value="tab.id">
            {{ tab.label }} {{ tab.count !== undefined ? `(${tab.count})` : "" }}
          </option>
        </select>
      </div>

      <div class="mt-4">
        <slot name="right-pane"></slot>
      </div>
    </div>
  </template>

  <section v-if="!loading" class="overflow-auto px-4 pt-4 h-[calc(100%-19rem)] sm:h-[calc(100%-12rem)] sm:px-8 sm:pt-8">
    <slot :name="selectedTabId"></slot>
  </section>
</template>
