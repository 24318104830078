<template>
  <div class="h-full">
    <Teleport to="#audience-menuOptions">
      <div class="flex space-x-4">
        <PermissionsButton href="/interests/new" :permissions="['interests:create']" theme="green-lime">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createInterestButton") }}
        </PermissionsButton>
      </div>
    </Teleport>
    <Teleport to="#audience-stickyContent">
      <FiltersPanel v-show="!firstLoad && (rows.length > 0 || selectedSearch.length > 0 || loadingTable)">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div>
            <span v-show="!loadingTable" class="text-sm text-gray-500">{{
              t("interestCount", { count: n(Number(rows.length) ?? 0, "decimal") }, { plural: rows.length })
            }}</span>
            <span v-show="loadingTable" class="animate-pulse rounded bg-gray-100 px-12 text-sm" />
          </div>
        </div>
      </FiltersPanel>
    </Teleport>
    <Table
      v-show="!loadingTable && rows.length > 0"
      :columns="columns"
      :rows="rows"
      :selected-rows="selectedRows"
      :skeleton="skeletonTable"
      :skeleton-count="skeletonCount"
      :selectable="interestsUpdate"
      class="ml-6 h-full overflow-x-hidden bg-white pb-16"
      @update:selected-rows="updateSelectedRows"
    >
      <template #name="{ row }">
        <NameCell v-if="row" :id="row.id">
          {{ row.content?.name }}
        </NameCell>
      </template>
      <template #name-skeleton>
        <div class="my-3 w-20 animate-pulse rounded bg-sky-50 py-2" />
      </template>
      <template #expiration="{ row }">
        <span class="flex justify-start text-sm text-gray-500">
          {{ t("expirationDays", { days: row.content?.expiration }, { plural: Number(row.content?.expiration ?? 0) }) }}
        </span>
      </template>
      <template #expiration-skeleton>
        <div class="my-3 w-20 animate-pulse rounded bg-gray-50 py-2" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="listOptions"
            theme="transparent-white"
            position="bottom-end"
            :auto-placements="['bottom-end', 'left']"
            :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="mx-auto my-2 w-4 animate-pulse rounded bg-gray-50 py-2" />
      </template>
    </Table>
    <EmptyStateSimple
      v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
      :title="t('emptyState.title')"
      :message="t('emptyState.message')"
      :article-id="8593204"
      :article-text="t('emptyState.cleanSearch')"
    >
      <template #icon>
        <HeartIcon class="h-16 w-16 stroke-1 text-sky-400" />
      </template>
    </EmptyStateSimple>
    <EmptySearchState
      v-show="!loadingTable && selectedSearch.length > 0 && rows.length === 0"
      @cleanSearch="cleanSearch"
    />
    <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>

    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selectedLists",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(totalCount) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
        </template>
        <IconButton
          v-if="selectedRows?.length < totalCount"
          :label="t('selectAllButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="selectAll"
        >
          <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
      <div class="flex space-x-4">
        <PermissionsIconButton
          :permissions="['interests:delete']"
          :label="t('deleteSelectedButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </PermissionsIconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteField"
      @cancel="closeDeleteListAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteFontAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted, reactive, toRaw } from "vue";

import type { ColumnsIds, ListOptionsKeys, InterestRows, InterestRow } from "./audienceInterests.types";
import { getColumns, interestsToRows, getListOptions } from "./audienceInterests.data";

// Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyStateSimple from "@molecules/EmptyState/EmptyStateSimple.vue";
import NameCell from "./components/NameCell.vue";
import FiltersPanel from "@templates/FiltersPanel";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import Table from "@molecules/Table";
import type { Columns } from "@molecules/Table";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icon
import { PlusIcon, PencilIcon } from "@heroicons/vue/solid";
import { HeartIcon, DocumentIcon, DocumentDuplicateIcon, TrashIcon, XIcon } from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";

// Utils
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";

// Store
import { useSessionStore, useAlertStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Application
import { useLocalStorageApp } from "@application";

// Service
import { useInterests } from "@api/modules/interests/interests";

// Domain
import type { FilterData } from "@domain/filters";
import type { DataItems } from "@domain/data";
import { getPrimaryHighlightList } from "@domain/filters";

const { t, n } = useI18n();
const interestsAPI = useInterests();
const { notify } = useNotifications();
const localStorageApp = useLocalStorageApp();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const interestsUpdate = sessionStore.hasPermission("interests:update");

// Table
const fetchlimit = 10000;
const skeletonTable = ref(false);
const skeletonCount = ref(5);
const loadingTable = ref(false);
const loadingNext = ref(false);
const firstLoad = ref(false);

const columns: Columns<ColumnsIds | "options"> = getColumns();
const rows = ref<InterestRows>([]);
const totalCount = ref<number>(0);
const selectedRows = ref<InterestRows>([]);
const selectedRow = ref<InterestRow>();
const updateSelectedRows = (newRows: InterestRows) => {
  selectedRows.value = newRows;
};
const clearSelected = () => {
  selectedRows.value = [];
};
const selectAll = () => {
  selectedRows.value = rows.value;
};

const removeRows = (rowsToDelete: InterestRows) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  rows.value = filteredRows;
};

const removeRow = (rowToDelete: InterestRow) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return row.id !== rowToDelete.id;
  });
  rows.value = filteredRows;
};

const fetchInterest = async () => {
  loadingNext.value = true;

  const getInterestSearch = selectedSearch.value.find((search) => search.filterId === "interests") as SelectedValueText;

  const interests = await interestsAPI.list({
    search: getInterestSearch ? getInterestSearch.value : undefined,
    limit: fetchlimit,
  });

  if (interests.isNewRequest) {
    rows.value = interestsToRows(interests.value);
    totalCount.value = interests.total ?? 0;
    loadingNext.value = false;
    return;
  }

  rows.value = [...rows.value, ...interestsToRows(interests.value)];

  loadingNext.value = false;
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteField = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await interestsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;
    totalCount.value = totalCount.value - 1;
    removeRow(rowToDelete);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchInterest();
    }
  } finally {
    closeDeleteListAlertModal();
  }
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) => interestsAPI.delete({ id: selected.id }));
    await Promise.all(deletePromises);
  } finally {
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    totalCount.value = totalCount.value - selectedRows.value.length;
    removeRows(selectedRows.value);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchInterest();
    }
    clearSelected();
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

// Search
const selectedSearch = ref<SelectedValues>([]);
const filterInputIsOpen = ref(false);
const highlightSearches = ref<DataItems>([]);

const cleanSearch = () => {
  selectedSearch.value = [];
};

const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "audience_interests_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "audience_interests_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "interests");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "interests");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const filterData = computed<FilterData>(() => ({
  filters: [
    { id: "interests", text: t("filters.interests"), icon: PencilIcon, highlightList: highlightSearches.value },
  ],
  filtersRoles: {
    primary: "interests",
  },
}));

const listOptions = getListOptions();
const listOptionHandler: Record<ListOptionsKeys, (row: InterestRow) => void> = {
  delete: (row) => {
    if (!sessionStore.hasPermission("interests:delete")) {
      alertStore.showPermissionDenied(["interests:delete"]);
      return;
    }

    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: InterestRow) => {
  if (!interestsUpdate) {
    alertStore.showPermissionDenied(["interests:update"]);
    return;
  }

  await listOptionHandler[option.key]?.(row);
};

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    interests: "q",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

watch([selectedSearch], async () => {
  cleanQueryParams();
  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    if (isSelectedValueTag(selected)) {
      updateQueryParam({ key: paramName, value: selected.tag.id });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  loadingTable.value = true;
  await fetchInterest();
  loadingTable.value = false;
});

onMounted(async () => {
  firstLoad.value = true;
  loadingTable.value = true;
  const queryParams = getQueryParams();

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      value,
      id: value,
    });
  });

  insertRecommmendedNames();
  await fetchInterest();
  loadingTable.value = false;
  firstLoad.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderSearch": "Buscar intereses",
    "interestCount": "0 intereses | 1 interés | {count} intereses",
    "expirationDays": "nunca | 1 dia | {days} dias",
    "createInterestButton": "Crear interés",
    "selectedLists": "1 interés seleccionado de {total} | {count} intereses seleccionados de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Eliminar",
    "selectAllButton": "Seleccionar todo",
    "emptyState": {
      "title": "Aún no hay intereses",
      "message": "Releva los intereses de tus contactos mediante formularios, o a través de las compras de tu tienda.",
      "cleanSearch": "Apreder más sobre audiencia"
    },
    "filters": {
      "interests": "Nombre"
    },
    "columns": {
      "name": "Nombre",
      "expiration": "Expiración"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar interés",
      "confirmationMessage": "Se eliminará 1 interés seleccionado. ¿Deseas continuar? | Se eliminaran {count} intereses seleccionados. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar interés",
      "confirmationMessage": "Se eliminará el interés {name}. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Interés eliminado",
        "text": "El interés se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Intereses eliminados",
        "text": "Los intereses se eliminaron correctamente."
      }
    },
    "listOptions": {
      "delete": "Eliminar"
    }
  },
  "pt": {
    "placeholderSearch": "Pesquisar interesses",
    "interestCount": "0 interesses | 1 interesse | {count} interesses",
    "expirationDays": "nunca | 1 dia | {days} dias",
    "createInterestButton": "Criar interesse",
    "selectedLists": "1 interesse selecionado de {total} | {count} interesses selecionados de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Excluir",
    "selectAllButton": "Selecionar tudo",
    "emptyState": {
      "title": "Ainda não tem interesses",
      "message": "Obtenha os interesses de seus contatos mediante formulário ou através das compras da sua loja.",
      "cleanSearch": "Aprender mais sobre audiência"
    },
    "filters": {
      "interests": "Nome"
    },
    "columns": {
      "name": "Nome",
      "expiration": "Expiração"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir interesse",
      "confirmationMessage": "Será excluído 1 interesse selecionado. Deseja continuar? | Serão excluídos {count} interesses selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir interesse",
      "confirmationMessage": "O interesse {name} será excluído. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Interesse excluído",
        "text": "O interesse foi excluído com sucesso."
      },
      "deleteBulk": {
        "title": "Interesses excluídos",
        "text": "Os interesses foram excluídos com sucesso."
      }
    },
    "listOptions": {
      "delete": "Excluir"
    }
  }
}
</i18n>
