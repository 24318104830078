// API
import { useRequests } from "@api/requests";

// Types
import type { AccountService } from "@application/ports";
import type { GetAPIResponse, PutAPIData, PutAPIResponse, GetConfigAPIResponse } from "./account.types";

// Utils
import { ok } from "neverthrow";

export const useAccountService = (): AccountService => {
  const requestAPIV2 = useRequests();

  const accountService: AccountService = {
    async get() {
      const res = await requestAPIV2<GetAPIResponse>({
        url: ``,
        method: "get",
      });
      return ok(res.data);
    },
    async getConfig() {
      const res = await requestAPIV2<GetConfigAPIResponse>({
        url: `/config`,
        method: "get",
      });
      return ok(res.data.data);
    },
    async put(params) {
      const res = await requestAPIV2<PutAPIResponse, PutAPIData>({
        url: ``,
        method: "put",
        data: {
          id: params.data.code,
          country: params.data.country,
          industry: params.data.industry,
          name: params.data.name,
          postalAddress: params.data.address,
        },
      });

      return ok(res.data);
    },
  };

  return accountService;
};
