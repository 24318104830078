<template>
  <div class="flex w-full justify-between">
    <div class="flex w-full items-center space-x-2">
      <div class="relative">
        <button
          ref="buttonRef"
          class="focus-visible:outline-none rounded border border-gray-300 p-2.5 hover:bg-gray-50 focus-visible:border-sky-400"
          @click="toggleColorPicker"
        >
          <svg
            :style="{
              color: `${tagToEdit.color}`,
            }"
            class="h-3 w-3"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
        </button>
        <ColorPickerBar
          ref="colorPickerRef"
          :open="colorPickerIsOpen"
          :selected="colorSelected"
          :class="[colorPickerIsOpen ? 'visible' : 'invisible', 'absolute z-20 border border-gray-200 shadow-md']"
          @update:color="updateTagColor"
          @update:mode="updatePopper"
        />
      </div>
      <div class="w-full">
        <SimpleInput
          ref="inputRef"
          :model-value="tagToEdit.name"
          :disabled="loadingAccept"
          maxlength="50"
          @update:model-value="updateTagName"
          @enter="() => acceptEdit(tagToEdit)"
        />
      </div>
    </div>
    <div class="mx-4 h-full border border-gray-200" />
    <div class="flex space-x-2">
      <SimpleButton theme="white" @click="cancelEdit">{{ t("cancel") }} </SimpleButton>
      <SimpleButton
        theme="primary-light"
        :disabled="tagToEdit.name.trim().length === 0"
        :loading="loadingAccept"
        @click="() => acceptEdit(tagToEdit)"
      >
        <template #leading><CheckIcon class="h-5 w-5" /> </template>
        {{ t("accept") }}
      </SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watchEffect, toRaw, nextTick } from "vue";

// Components
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import ColorPickerBar from "@organisms/Colors/ColorPickerBar.vue";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";

// Utils
import { cloneDeep } from "lodash";
import { onClickOutside } from "@vueuse/core";
import { Instance as PopperInstance, createPopper } from "@popperjs/core";

// I18n
import { useI18n } from "vue-i18n";

// Types
import type { Tag, NewTag } from "@domain/tag";

const { t } = useI18n();

// Props
const props = withDefaults(
  defineProps<{
    tag: Tag | NewTag;
    loadingAccept?: boolean;
  }>(),
  {
    loadingAccept: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "accept", tag: Tag | NewTag): void;
  (e: "cancel"): void;
}>();

const acceptEdit = (tag: Tag | NewTag) => {
  emit("accept", tag);
};

const cancelEdit = () => {
  emit("cancel");
};

const tagToEdit = ref<Tag | NewTag>(cloneDeep(toRaw(props.tag)));
const colorSelected = computed<HexColor>(() => tagToEdit.value.color);
const colorPickerIsOpen = ref(false);

const toggleColorPicker = () => {
  colorPickerIsOpen.value = !colorPickerIsOpen.value;
};

const updateTagName = (newName: string) => {
  tagToEdit.value.name = newName;
};

const updateTagColor = (color: HexColor) => {
  tagToEdit.value.color = color;
};

watchEffect(() => {
  if (!props.tag) return;

  tagToEdit.value = cloneDeep(toRaw(props.tag));
});

const buttonRef = ref();
const colorPickerRef = ref();

onClickOutside(colorPickerRef, () => {
  colorPickerIsOpen.value = false;
});

let popper: PopperInstance;
const updatePopper = () => {
  if (!popper) return;
  nextTick(() => {
    popper.update();
  });
};

const createPopperColorPicker = () => {
  popper = createPopper(buttonRef.value, colorPickerRef.value?.refElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParents",
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "bottom", "right"],
          fallbackPlacements: ["top", "bottom", "right"],
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
};

const inputRef = ref();
const focusInput = () => {
  inputRef.value?.focus();
};

onMounted(() => {
  createPopperColorPicker();
  focusInput();
});
</script>
