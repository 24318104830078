<template>
  <div
    :class="[$slots.subtitle || $slots['button-right'] ? 'sm:py-4' : 'sm:py-6', , 'py-4 flex justify-between bg-gray-600']"
  >
    <div class="flex w-[50%] max-w-[50%]">
      <slot name="button-left" class="my-auto" />

      <div
        :class="[
          {
            'space-y-2': $slots.subtitle || $slots['button-right'],
          },
          $slots.subtitle && 'flex flex-col space-y-4',
          !$slots['button-left'] && 'pl-8',
          'w-full',
        ]"
      >
        <slot name="title" />
        <slot name="subtitle" />
      </div>
    </div>
    <div class="flex max-w-[50%]">
      <div
        :class="[
          smBp ? 'visible' : 'invisible',
          pdfMode && 'mt-5',
          $slots.subtitle ? 'mt-auto' : 'flex items-end',
          !$slots['button-left'] && 'pr-8',
          'w-full',
        ]"
      >
        <slot name="right" />
      </div>
      <slot name="button-right" class="my-auto" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { useBreakpoints } from "@composables/breakpoints";
const { smBp } = useBreakpoints();
const pdfMode = inject("pdfMode", false);
</script>
