import { Model } from "@/core/magnet";
import { _, round } from "@/helpers/utils";
import app from "@/app";

export default Model.extend({
  urlRoot: "lists",
  parse: function (list) {
    // Get data from API direct call
    if (_.isDefined(list.data)) {
      list = list.data;
    }

    // Set rating
    list.rating = list.quality ? round(list.quality, 1) * 100 : 0;
    if (list.quality > 0.33 && list.quality < 0.66) {
      list.rating = 50;
    }
    return list;
  },
  relationships: {
    tags: function () {
      return $.get("lists/tags", { limit: 100 });
    },
  },
  export: function () {
    const eventData = {
      id: this.id,
      name: this.attributes.name,
    };
    return $.post("lists/" + this.id + "/export").then(() => {
      app.integrations.amplitude.event("APP_LIST_EXPORTED", eventData, true);
      app.integrations.intercom.event("APP_LIST_EXPORTED", eventData);
    });
  },
  substract: function (data) {
    return $.post("lists/" + this.id + "/substract", data);
  },
  clean: function (data) {
    const eventData = {
      id: this.id,
      name: this.attributes.name,
      options: data,
    };
    return $.post("lists/" + this.id + "/clean", data).then(() => {
      app.integrations.amplitude.event("APP_LIST_CLEANED", eventData, true);
      app.integrations.intercom.event("APP_LIST_CLEANED", eventData);
    });
  },
  unsubscribe: function () {
    const eventData = {
      id: this.id,
      name: this.attributes.name,
    };
    return $.post("lists/" + this.id + "/unsubscribe").then(() => {
      app.integrations.amplitude.event("APP_LIST_UNSUBSCRIBED", eventData, true);
      app.integrations.intercom.event("APP_LIST_UNSUBSCRIBED", eventData);
    });
  },
  respawn: function () {
    const eventData = {
      id: this.id,
      name: this.attributes.name,
    };
    return $.post("lists/" + this.id + "/respawn").then(() => {
      app.integrations.amplitude.event("APP_LIST_RESPAWN", eventData, true);
      app.integrations.intercom.event("APP_LIST_RESPAWN", eventData);
    });
  },
  analyze: function () {
    return $.post("lists/" + this.id + "/analyze");
  },
  delete: function (deleteContacts) {
    const eventData = {
      id: this.id,
      name: this.attributes.name,
      delete_contacts: deleteContacts,
    };
    return $.ajax({
      url: "lists/" + this.id + (deleteContacts ? "?clean_contacts=true" : ""),
      method: "DELETE",
    }).then(() => {
      app.integrations.amplitude.event("APP_LIST_DELETED", eventData, true);
      app.integrations.intercom.event("APP_LIST_DELETED", eventData);
    });
  },
});
