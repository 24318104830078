import { ContactsList } from "@domain/lists";
import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";
import { SuccessAPIOffsetPaging } from "../../models/requestWrappers";

//Types
import type {
  ListAPIParams,
  ListsServices,
  ListsAsyncGenerator,
  GetParams,
  CreateListResponse,
  GetColumnsResponse,
  GetTagsResponse,
} from "./lists.types";

export function useLists(): ListsServices {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let getListsGenerator: ListsAsyncGenerator | undefined = undefined;
  let apiParams: GetParams | undefined = undefined;

  return {
    async create(params) {
      const data = await request<CreateListResponse>({
        url: `/lists`,
        method: "post",
        data: {
          name: params.name,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });

      return data.data;
    },
    async get(options) {
      let isNewRequest = false;
      if (!isEqual(apiParams, options) || !getListsGenerator) {
        isNewRequest = true;
        apiParams = options;
        getListsGenerator = requestPaging<ContactsList, unknown, ListAPIParams>(
          {
            url: `/lists`,
            method: "get",
            urlParams: {
              "filters.tags": apiParams?.tags?.join() || undefined,
              q: options?.search ? `*${options?.search}*` : undefined,
              sortBy: apiParams?.sortBy ?? "name",
              sortDir: apiParams?.sortDir ?? "asc",
            },
          },
          { limit: apiParams?.limit || 100000, offset: apiParams?.offset || 0 },
          true,
        );
      }

      if (!getListsGenerator || getListsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };

      const data = (await getListsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<ContactsList>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
        isNewRequest,
      };
    },
    async tags() {
      const res = await request<GetTagsResponse>({
        url: `/lists/tags`,
        method: "get",
        // urlParams: {
        //   limit: 100000,
        // },
      });

      return res.data;
    },
    async getColumns(params) {
      const res = await request<GetColumnsResponse>({
        url: `/lists/0/columns`,
        method: "get",
        urlParams: {
          limit: params.limit,
        },
      });

      return res.data;
    },
    async saveColumns(params) {
      await request({
        url: `/lists/0/columns`,
        method: "put",
        data: {
          columns: params.ids,
        },
      });
    },
    async clean(params) {
      await request({
        url: `/lists/${params.id}/clean`,
        method: "post",
        data: {
          inactive: Number(params.inactive),
          unsubscribed: Number(params.unsubscribed),
          complained: Number(params.complained),
          bounced: Number(params.bounced),
          action: params.action,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async filter(params) {
      await request({
        url: `/lists/${params.id}/filter`,
        method: "post",
        data: {
          field: params.fieldId,
          condition: params.condition,
          value: params.value,
          newListName: params.newListName,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async subtract(params) {
      await request({
        url: `/lists/${params.id}/substract`,
        method: "post",
        data: {
          substractListIds: params.ids,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async merge(params) {
      await request({
        url: `/lists/join`,
        method: "post",
        data: {
          listIds: params.ids,
          newListName: params.name,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async intersect(params) {
      await request({
        url: `/lists/intersect`,
        method: "post",
        data: {
          listIds: params.ids,
          newListName: params.name,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async duplicate(params) {
      await request({
        url: `/lists/${params.id}/duplicate`,
        method: "post",
        data: {
          newListName: params.name,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async sample(params) {
      await request({
        url: `/lists/${params.id}/sample`,
        method: "post",
        data: {
          sampleSize: params.size,
          newListName: params.name,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async split(params) {
      await request({
        url: `/lists/${params.id}/split`,
        method: "post",
        data: {
          numLists: params.number,
        },
        disableNotify400: params?.showErrorAlert ? !params?.showErrorAlert : true,
      });
    },
    async analyze(params) {
      await request({
        url: `/lists/${params.id}/analyze`,
        method: "post",
      });
    },
    async export(params) {
      await request({
        url: `/lists/${params.id}/export`,
        method: "post",
      });
    },
    async delete(params) {
      await request({
        url: `/lists/${params.id}`,
        method: "delete",
        urlParams: {
          clean_contacts: params?.cleanContacts ? params.cleanContacts : undefined,
        },
        disableNotifyErrors: params.showError === false,
      });
    },
    async refreshcache() {
      await request({
        url: `/lists/refreshcache`,
        method: "post",
      });
    },
    async autoSegments() {
      await request({
        url: `/lists/segments`,
        method: "post",
      });
    },
  };
}
