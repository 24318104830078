var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n                        <i class='fa fa-warning text-warning'></i>\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"publicDomainLegend")), depth0))
    + "\n                    </p>\n                    <hr />\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"CHANGE_SUGGESTED",{"name":"is","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"UNKNOWN",{"name":"is","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":43,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"CHANGE_REQUIRED",{"name":"is","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":50,"column":27}}})) != null ? stack1 : "")
    + "                    <hr>\n                    <div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),"PASS",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":53,"column":24},"end":{"line":79,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <hr>\n                    <div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"dkim") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),"PASS",{"name":"is","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":83,"column":24},"end":{"line":104,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                    <hr />\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/isNot.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"OK",{"name":"isNot","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":118,"column":30}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/replace.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"###",((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"domain") : stack1),{"name":"replace","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":34,"column":36}}})) != null ? stack1 : "")
    + "                    </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(__default(require("../../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"statusExtended")),((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":33,"column":81}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class='text-success'>\n                            <i class='fa fa-check'></i>\n                            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"spfPass")), depth0))
    + "\n                        </p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            <span class='text-warning'>\n                                <i class='fa fa-warning text-warning'></i>\n                                "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"spfFail")), depth0))
    + "\n                            </span>\n                            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"spfHelpLink")), depth0)) != null ? stack1 : "")
    + "\n                        </p>\n                        <div class='form-group'>\n                            <label>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/replace.js")).call(alias2,"###",((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"domain") : stack1),{"name":"replace","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":32},"end":{"line":70,"column":44}}})) != null ? stack1 : "")
    + "                            </label>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/notIn.js")).call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"spfErrors")),{"name":"notIn","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":74,"column":38}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/handlebars/in.js")).call(alias2,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"spfErrors")),{"name":"in","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":77,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = __default(require("../../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"modifySpf")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":69,"column":32},"end":{"line":69,"column":90}}})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <textarea class='form-control monospace' readonly>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"corrected") : stack1), depth0))
    + "</textarea>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <textarea class='form-control monospace' readonly>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"spf") : stack1)) != null ? lookupProperty(stack1,"current") : stack1), depth0))
    + "</textarea>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p class='text-success'>\n                            <i class='fa fa-check'></i>\n                            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"dkimPass")), depth0))
    + "\n                        </p>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <p>\n                            <span class='text-warning'>\n                                <i class='fa fa-warning text-warning'></i>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"dkimFail")), depth0))
    + "\n                            </span>\n                            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"dkimHelpLink")), depth0)) != null ? stack1 : "")
    + "\n                        </p>\n                        <div class='form-group'>\n                            <label>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/replace.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"###",((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"domain") : stack1),{"name":"replace","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":32},"end":{"line":100,"column":44}}})) != null ? stack1 : "")
    + "                            </label>\n                            <textarea class='form-control monospace' readonly>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"dkim") : stack1)) != null ? lookupProperty(stack1,"corrected") : stack1), depth0))
    + "</textarea>\n                        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = __default(require("../../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"modifyDkim")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"dkim") : stack1)) != null ? lookupProperty(stack1,"result") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":99,"column":32},"end":{"line":99,"column":92}}})) != null ? stack1 : "")
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/replace.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"###",((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"domain") : stack1),{"name":"replace","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":24},"end":{"line":111,"column":36}}})) != null ? stack1 : "")
    + "                    </p>\n                    <a data-action='open-send-instructions-modal' class='btn btn-primary btn-outline'\n                        data-id='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "' data-domain='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"domain") : stack1), depth0))
    + "'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"sendInstructions")), depth0))
    + "\n                    </a>\n                    <hr />\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"dnsMessage")), depth0)) != null ? stack1 : "")
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"text-warning\">\n                        <i class=\"fa fa-warning text-warning\"></i>\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"refreshLater")), depth0))
    + "\n                    </p>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type='button' class='btn btn-primary' data-dismiss='modal'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"close")), depth0))
    + "\n                </button>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"willDoLater")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"refresh")), depth0))
    + "\n                </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog modal-lg'>\n    <form data-form='update-from'>\n        <input class='form-control' name='email' type='hidden' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"verify")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group hidden'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"email")), depth0))
    + "\n                        </label>\n                        <input type='text' readonly value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "' class='form-control'>\n                    </div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"PUBLIC_DOMAIN",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":119,"column":27}}})) != null ? stack1 : "")
    + "                    <p>\n                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"learnMore")), depth0)) != null ? stack1 : "")
    + "\n                    </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"tryAgain") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":20},"end":{"line":128,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class='modal-footer'>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),"PUBLIC_DOMAIN",{"name":"is","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":132,"column":16},"end":{"line":143,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});