<template>
  <RadioGroup v-model="selection" :disabled="disabled">
    <RadioGroupLabel v-if="srLabel" class="sr-only">{{ srLabel }}</RadioGroupLabel>
    <div
      :class="[full ? 'margin-0 w-full' : 'w-max']"
      class="flex items-center space-x-0.5 rounded-lg bg-gray-100 p-0.5"
    >
      <RadioGroupOption
        v-for="button in buttons"
        v-slot="{ checked, disabled: optionDisabled }"
        :key="button.id"
        :ref="setItemRef($el, button.id)"
        :value="button.id"
        :disabled="button.disabled"
        as="template"
      >
        <button
          type="button"
          :disabled="optionDisabled"
          :class="[
            !optionDisabled
              ? checked
                ? 'bg-white text-gray-500 shadow-sm'
                : 'text-gray-400 hover:bg-gray-50 hover:shadow-sm'
              : checked
              ? 'bg-gray-50 text-gray-300 shadow-sm'
              : 'text-gray-300',
          ]"
          class="flex w-full items-center rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500"
        >
          <div class="m-auto flex">
            <component :is="button.icon" v-if="button.icon" :style="'height: ' + (iconHeight || '1.25') + 'rem'" />
            <span
              :class="[button.showLabel || !button.icon ? 'mx-1 whitespace-nowrap text-sm font-medium' : 'sr-only']"
              >{{ button.label }}</span
            >
          </div>
        </button>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup lang="ts">
import { ref, PropType, watch, watchEffect, onBeforeUpdate, onUpdated } from "vue";
import type { Component } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";

export interface ToggleOption {
  id: string;
  label: string;
  showLabel?: boolean;
  disabled?: boolean;
  icon?: Component;
  tooltip?: string;
  full?: boolean;
}

const props = defineProps({
  srLabel: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttons: {
    type: Array as PropType<ToggleOption[]>,
    required: true,
  },
  iconHeight: {
    type: Number,
    default: null,
  },
  modelValue: {
    type: String,
    default: null,
  },
  full: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const selection = ref(props.modelValue);

watchEffect(() => {
  selection.value = props.modelValue;
});

watch(selection, (value) => {
  emit("update:modelValue", value);
});

let itemRefs = {};
const setItemRef = (el, id) => {
  itemRefs[id] = el;
};
onBeforeUpdate(() => {
  itemRefs = {};
});
onUpdated(() => {
  // console.log(itemRefs);
});
</script>
