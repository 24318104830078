import { EditView } from "@/core/magnet";
import app from "@/app";
import editTpl from "../templates/edit.hbs";

export default EditView.extend({
  module: "interests",
  templates: {
    initial: editTpl,
    show: editTpl,
    edit: editTpl,
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Gets a form data
  getFormData: function (form) {
    var self = this,
      values = self.getFormFieldsObject(form);

    // If the user disables the expiration days option, send it as zero
    if (
      !self.item.isNew() &&
      values.expirationDays === undefined &&
      self.item.get("expirationDays")
    ) {
      values.expirationDays = 0;
    }

    // Return form values
    return values;
  },
});
