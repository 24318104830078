<template>
  <FormGroup :id="id" :label="label" :hint="hint">
    <RadioGroup
      class="select-none"
      :disabled="disabled"
      :model-value="selected"
      :class="disabled && 'opacity-50'"
      @update:model-value="UpdateSelected"
    >
      <RadioGroupLabel class="sr-only"> Privacy setting </RadioGroupLabel>
      <div class="bg-white rounded-md -space-y-px">
        <RadioGroupOption
          v-for="(option, optionIndex) in options"
          :key="option.key"
          v-slot="{ checked, active }"
          :value="option"
          as="template"
        >
          <div
            :class="[
              optionIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              optionIndex === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
              'relative border p-4 flex cursor-pointer focus:outline-none',
            ]"
          >
            <span
              :class="[
                checked ? 'bg-sky-500 border-transparent' : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-sky-400' : '',
                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
              ]"
              aria-hidden="true"
            >
              <span class="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <div class="ml-3 flex flex-col">
              <RadioGroupLabel
                as="span"
                :class="[checked ? 'text-sky-500' : 'text-gray-700', 'block text-sm font-medium']"
              >
                {{ option.title }}
              </RadioGroupLabel>
              <RadioGroupDescription as="span" class="text-gray-500 text-sm">
                {{ option.description }}
              </RadioGroupDescription>
              <transition
                leave-active-class="transition-all transform duration-200 overflow-hidden"
                leave-from-class="max-h-96"
                leave-to-class="max-h-0"
                enter-active-class="transition-all transform duration-200 overflow-hidden"
                enter-to-class="max-h-96"
                enter-from-class="max-h-0"
              >
                <div
                  v-if="option.key == selected.key && $slots[option.key]"
                  class="mt-3"
                  @click.stop="UpdateSelected(option)"
                >
                  <slot :name="option.key" />
                </div>
              </transition>
            </div>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </FormGroup>
</template>

<script lang="ts" setup>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import FormGroup from "@molecules/FormGroup.vue";

import { v4 as uuid } from "uuid";

export interface RadioOption {
  key: string;
  title: string;
  description: string;
}

export type RadioOptions = Array<RadioOption>;

//Props
withDefaults(
  defineProps<{
    id?: string;
    label?: string;
    hint?: string;
    options: RadioOptions;
    selected: RadioOption;
    disabled?: boolean;
  }>(),
  {
    id: uuid(),
    label: "",
    hint: "",
    options: () => [] as RadioOptions,
    selected: () => ({} as RadioOption),
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: "update:selected", selected: RadioOption): void;
}>();

//Emits
const UpdateSelected = (selected: RadioOption) => {
  emit("update:selected", selected);
};
</script>
