import { Collection } from "@/core/magnet";
import CampaignsModel from "./model";

export default Collection.extend({
  url: "campaigns",
  model: CampaignsModel,
  parse: function (response) {
    // Loop campaigns
    return response.data.map(function (campaign) {
      // Check if the campaign is pending
      campaign = $.extend(true, {}, campaign, {
        draft: false,
        pending: false,
        done: false,
      });

      // Set link according to state
      switch (campaign.state) {
        case "DRAFT":
          campaign.link = "campaigns/" + campaign.id;
          campaign.draft = true;
          campaign.group = "DRAFT";
          break;
        case "SCHEDULED":
        case "PENDING_APPROVAL":
          campaign.link = "metrics/" + campaign.id + "/contents";
          campaign.pending = true;
          campaign.group = "PENDING";
          break;
        case "SENDING":
          campaign.link = "metrics/" + campaign.id;
          if (campaign.metrics.sent > 0) {
            campaign.done = true;
            campaign.link = "metrics/" + campaign.id;
          } else {
            campaign.done = false;
            campaign.link = "metrics/" + campaign.id + "/contents";
          }
          campaign.group = "PENDING";
          break;
        default:
          campaign.link = "metrics/" + campaign.id;
          campaign.done = true;
          campaign.group = "DONE";
          break;
      }

      // Return each campaign
      return campaign;
    });
  },
  additionals: {
    pendingStates: ["SCHEDULED", "PENDING_APPROVAL", "SENDING"],
    plan: function () {
      return $.get("plan");
    },
  },
  filters: {
    tags: {
      key: "tags",
      icon: "pi-filter-tag",
      name: function () {
        return window.lang.tags.plural;
      },
      source: function () {
        return $.get("campaigns/tags", { limit: 1000 });
      },
      parser: function (item) {
        return {
          id: item,
          title: item,
          keywords: item,
          params: {
            key: "filter.tags",
            value: item,
          },
        };
      },
    },
    state: {
      key: "state",
      icon: "pi-filter-state",
      remove: ["filter.state", "filter.archived"],
      name: function () {
        return window.lang.status;
      },
      source: function () {
        var data = [
          { id: "DRAFT" },
          { id: "SENT" },
          { id: "SCHEDULED" },
          { id: "PENDING_APPROVAL" },
          { id: "SENDING" },
          { id: "CANCELLED" },
          { id: "ARCHIVED" },
        ];
        return Promise.resolve({ data: data });
      },
      parser: function (item) {
        var translated = window.lang.campaigns.filters.state[item.id];
        return {
          id: item.id,
          title: translated,
          keywords: translated,
          params: {
            key: item.id === "ARCHIVED" ? "filter.archived" : "filter.state",
            value: item.id === "ARCHIVED" ? true : item.id,
            remove: item.id === "ARCHIVED" ? "filter.state" : "filter.archived",
          },
        };
      },
    },
  },
});
