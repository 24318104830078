import axios from "axios";

// Utils
import { ok, err } from "neverthrow";

// Types
import type { BlogService } from "@application/ports";
import type { GetPostsAPIParams, GetPostsAPIResponse } from "./blog.types";

const blogESAPI = axios.create({
  timeout: 20000,
  baseURL: "https://perfit.ghost.io/ghost/api/content/posts/?key=e2031112ebb1178bb009019c3f&include=tags"
});

const blogPTAPI = axios.create({
  timeout: 20000,
  baseURL: "https://perfit-2.ghost.io/ghost/api/content/posts/?key=e9e1e77289717734438373986e&include=tags"
});

export const useBlogService = (): BlogService => {
  return {
    async getPosts(params) {
      const blogRequest = params.lang === "pt" ? blogPTAPI.request : blogESAPI.request;
      const apiParams: GetPostsAPIParams = {
        limit: params.limit ?? 5
      };

      try {
        const posts = await blogRequest<GetPostsAPIResponse>({
          url: ``,
          method: "get",
          params: apiParams
        });

        return ok(
          posts.data.posts.map((p) => ({
            id: p.id,
            title: p.title,
            publishedDate: p.published_at,
            url: p.url,
            tags: p.tags
          }))
        );
      } catch (e) {
        return err({
          type: "NO_DATA"
        });
      }
    }
  };
};
