<template>
  <div class="flex space-x-2" data-intercom-target="TemplateGalleryNav">
    <button
      v-for="(tab, index) in tabs"
      :key="tab.key?.toString() ?? index"
      :class="[
        tab.key === selected?.key ? 'bg-gray-700' : 'hover:text-gray-300',
        'rounded-md px-3 py-2 text-sm font-normal text-gray-100 outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-1 focus-visible:ring-offset-gray-600',
      ]"
      :data-intercom-target="tab.content?.dataIntercomTarget"
      @click="updateSelected(tab)"
    >
      {{ tab.value }}
    </button>
  </div>
</template>

<script lang="ts" setup generic="ItemKey">
import type { Items, Item } from "./buttonTabs.types";

withDefaults(
  defineProps<{
    tabs: Items<ItemKey>;
    selected?: Item<ItemKey>;
  }>(),
  {},
);

const emit = defineEmits<{
  "update:selected": [tab: Item<ItemKey>];
}>();

const updateSelected = (newTab: Item<ItemKey>) => {
  emit("update:selected", newTab);
};
</script>
