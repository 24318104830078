<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="relative z-100">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="transform overflow-hidden rounded bg-white text-left align-middle shadow-xl transition-all xl:w-full xl:max-w-screen-xl"
            >
              <div class="flex min-h-full w-full flex-1">
                <div class="flex flex-1 flex-col justify-center space-y-4 overflow-hidden px-10 py-12 xl:flex-none">
                  <div class="flex">
                    <transition
                      mode="out-in"
                      leave-active-class=" transition duration-200"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0 transform -translate-x-10"
                      enter-active-class="transition duration-200"
                      enter-to-class="opacity-100"
                      enter-from-class="opacity-0 transform translate-x-10"
                    >
                      <AccountForm v-if="step === 'account'" @continue="goToUserStep" />
                      <UserForm v-else-if="step === 'user'" @close="closeModal" />
                    </transition>
                  </div>
                  <ol role="list" class="mx-auto flex items-center space-x-5">
                    <li v-for="stepDot in ['account', 'user']">
                      <div class="relative flex items-center justify-center" aria-current="step">
                        <span v-show="step === stepDot" class="absolute flex h-5 w-5 p-px" aria-hidden="true">
                          <span class="h-full w-full rounded-full bg-sky-200"></span>
                        </span>
                        <span
                          :class="[
                            step === stepDot ? 'bg-sky-500' : 'bg-sky-600',
                            'relative block h-2.5 w-2.5 rounded-full bg-sky-500',
                          ]"
                          aria-hidden="true"
                        />
                        <span class="sr-only">{{ stepDot }} step</span>
                      </div>
                    </li>
                  </ol>
                </div>
                <div class="tw-hidden relative w-1/2 flex-1 bg-sky-400 xl:block">
                  <img
                    class="absolute inset-0 h-full w-full object-scale-down"
                    :key="img.key"
                    :src="img.src"
                    :alt="img.alt"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

// Components
import AccountForm from "./components/AccountStepForm.vue";
import UserForm from "./components/UserStepForm.vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";

// Stores
import { storeToRefs } from "pinia";
import { useAccountStore, useFeaturesStore, useIntegrationsStore, useSessionStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const accountStore = useAccountStore();
const featuresStore = useFeaturesStore();
const integrationStore = useIntegrationsStore();

const step = ref<"account" | "user">("account");
const goToUserStep = () => {
  step.value = "user";
};

const open = ref(false);
const openModal = () => {
  open.value = true;
};

const closeModal = () => {
  open.value = false;
};

const img = computed(() => {
  if (step.value === "account") {
    if (locale.value.slice(0, 2).toLocaleLowerCase() === "pt") {
      return {
        key: "accountStep",
        src: "/img/authentication/accountStep_br.png",
        alt: "Account step",
      };
    } else {
      return {
        key: "accountStep",
        src: "/img/authentication/accountStep.png",
        alt: "Account step",
      };
    }
  }

  if (locale.value.slice(0, 2).toLocaleLowerCase() === "pt") {
    return {
      key: "userStep",
      src: "/img/authentication/userStep_br.png",
      alt: "User step",
    };
  } else {
    return {
      key: "userStep",
      src: "/img/authentication/userStep.png",
      alt: "User step",
    };
  }
});

onMounted(async () => {
  if (["", "*"].includes(session.value.account.code)) return;

  await Promise.all([
    accountStore.fetchAccountData(),
    featuresStore.fetchFeatures(),
    integrationStore.fetchIntegrations(),
  ]);
  const features = featuresStore.getFeatures();

  if (
    !features?.FIRST_LOGIN ||
    !session?.value ||
    session.value.account.parent ||
    session.value.isMasterUser ||
    features?.FIRST_LOGIN === "0"
  )
    return;
  openModal();
});
</script>
