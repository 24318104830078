<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { onClickOutside } from "@vueuse/core";
import { isEqual } from "lodash";
import {
  startOfDay,
  subDays,
  endOfDay,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
  endOfWeek,
  parseISO,
} from "date-fns";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { useBreakpoints } from "@composables/breakpoints";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { CalendarIcon } from "@heroicons/vue/outline";
import { nextTick } from "process";
import AlertBox from "@atoms/AlertBox.vue";

interface Preset {
  id: string;
  name: string;
  from?: Date;
  to?: Date;
}

type Options = "lastDay" | "last7d" | "last30d" | "last90d" | "lastW" | "lastM" | "custom" | "all";

const props = withDefaults(
  defineProps<{
    from?: Date;
    to?: Date;
    selectedPresetId?: string;
    options?: Array<Options>;
  }>(),
  { options: () => ["last7d", "last30d", "last90d", "lastW", "lastM", "custom"] },
);

const emit = defineEmits<{
  (e: "update:from", value: Date | undefined): void;
  (e: "update:to", value: Date | undefined): void;
  (e: "update:selectedPresetID", value: string): void;
}>();

const { t, d, locale } = useI18n();
const { smBp } = useBreakpoints();

const lastDay = endOfDay(subDays(new Date(), 1));

const calcFrom = (days: number) => startOfDay(subDays(lastDay, days - 1));

const presets = computed<Array<Preset>>(() => {
  const mapping: Record<Options, Preset> = {
    all: {
      id: "all",
      name: t("all"),
      from: undefined,
      to: undefined,
    },
    lastDay: {
      id: "lastDay",
      name: t("lastDay", 1),
      from: calcFrom(1),
      to: calcFrom(0),
    },
    last7d: {
      id: "last7d",
      name: t("lastDays", 7),
      from: calcFrom(7),
      to: lastDay,
    },
    last30d: {
      id: "last30d",
      name: t("lastDays", 30),
      from: calcFrom(30),
      to: lastDay,
    },
    last90d: {
      id: "last90d",
      name: t("lastDays", 90),
      from: calcFrom(90),
      to: lastDay,
    },
    lastW: {
      id: "lastW",
      name: t("lastWeek"),
      from: startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
      to: endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
    },
    lastM: {
      id: "lastM",
      name: t("lastMonth"),
      from: startOfMonth(subMonths(new Date(), 1)),
      to: endOfMonth(subMonths(new Date(), 1)),
    },
    custom: {
      id: "custom",
      name: t("custom"),
    },
  };

  const presetsArray: Preset[] = props.options.map((option) => mapping[option] || null).filter(Boolean);

  return presetsArray;
});

const period = ref(
  props.selectedPresetId && props.selectedPresetId != "custom"
    ? presets.value.find((preset) => preset.id == props.selectedPresetId) || presets.value[1]
    : presets.value[1],
);

const previousPeriod = ref(presets.value[1]);

const datepicker = ref();
const selectingCustom = ref(false);
const customSelection = ref<{
  start?: Date;
  end?: Date;
}>({
  start: undefined,
  end: undefined,
});
const datePickerConfig = ref({
  start: {
    timeAdjust: "00:00:00",
  },
  end: {
    timeAdjust: "23:59:59",
  },
});
const datePickerMinDate = startOfMonth(parseISO("2020-10-01"));

onClickOutside(datepicker, () => {
  selectingCustom.value = false;
  if (!(customSelection.value.start && customSelection.value.end)) {
    period.value = previousPeriod.value;
  } else {
    emitUpdates();
  }
});
watch(customSelection, () => {
  if (customSelection.value.start && customSelection.value.end) {
    period.value.from = customSelection.value.start;
    period.value.to = customSelection.value.end;
    period.value.id = "custom";
    selectingCustom.value = false;
    emitUpdates();
  }
});

const clickedPreset = (preset: Preset) => {
  if (preset.id === "custom") {
    if (!customSelection.value.start || !customSelection.value.end) {
      customSelection.value.start = previousPeriod.value.from;
      customSelection.value.end = previousPeriod.value.to;
      period.value.from = previousPeriod.value.from;
      period.value.to = previousPeriod.value.to;
    }
    nextTick(() => {
      selectingCustom.value = true;
    });
  }
};

const emitUpdates = () => {
  if (period.value.id !== "custom" || (period.value.from && period.value.to)) {
    emit("update:from", period.value.from);
    emit("update:to", period.value.to);
    emit("update:selectedPresetID", period.value.id);
  }
};

emitUpdates();

watch(
  () => props.selectedPresetId,
  () => {
    const selectedPreset = presets.value.find((preset) => preset.id === props.selectedPresetId);

    if (!selectedPreset || isEqual(selectedPreset, period.value)) return;

    period.value = selectedPreset;
  },
);

watch(period, (newValue, oldValue) => {
  if (newValue.id !== "custom") previousPeriod.value = newValue;
  if (period.value.id === "custom") {
    selectingCustom.value = true;
    return;
  }
  emitUpdates();
});
</script>

<template>
  <Listbox v-model="period" as="div" data-intercom-target="PeriodSelectorMenu">
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
      >
        <span class="flex w-full items-center truncate text-gray-500">
          <span><CalendarIcon class="mr-2 h-5 w-5" /></span>
          <span class="flex-shrink-0">{{ period.name }}</span>
          <span v-if="period.from && period.to" class="ml-2 truncate text-gray-400">
            {{ d(period.from, "short") }} - {{ d(period.to, "short") }}
          </span>
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          data-intercom-target="PeriodSelectorOptions"
        >
          <ListboxOption
            v-for="preset in presets"
            :key="preset.id"
            v-slot="{ active, selected }"
            as="template"
            :value="preset"
            @click="clickedPreset(preset)"
          >
            <li
              :class="[
                active ? 'bg-sky-500 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
              :data-intercom-target="`PeriodSelectorOption-${preset.id}`"
            >
              <div class="flex">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'flex-shrink-0']">
                  {{ preset.name }}
                </span>
                <span
                  v-if="preset.from && preset.to"
                  :class="[active ? 'text-sky-200' : 'text-gray-400', 'ml-2 truncate']"
                >
                  {{ d(preset.from, "short") }} - {{ d(preset.to, "short") }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-sky-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
      <div
        v-if="selectingCustom"
        class="absolute right-0 z-10 mt-1 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <div class="p-4">
          <DatePicker
            ref="datepicker"
            v-model="customSelection"
            is-range
            :model-config="datePickerConfig"
            :locale="locale"
            :columns="smBp ? 2 : 1"
            :step="1"
            :min-date="datePickerMinDate"
            :max-date="lastDay"
            class="mb-4"
          />
          <AlertBox theme="info"> {{ t("calendarText") }}</AlertBox>
        </div>
      </div>
    </div>
  </Listbox>
</template>

<i18n lang="json">
{
  "es": {
    "all": "Historial completo",
    "lastDay": "Último día",
    "lastDays": "Ayer | Últimos {n} días",
    "lastWeeks": "Última semana | Últimas {n} semanas",
    "lastWeek": "Semana pasada",
    "lastMonth": "Mes pasado",
    "custom": "Personalizado",
    "calendarText": "Las métricas se actualizan diariamente. La actividad de hoy estará disponible a partir de mañana."
  },
  "pt": {
    "all": "Histórico completo",
    "lastDay": "Último día",
    "lastDays": "Ontem | Últimos {n} dias",
    "lastWeeks": "Na semana passada | Últimas {n} semanas",
    "lastWeek": "Semana passada",
    "lastMonth": "Mês passado",
    "custom": "Personalizado",
    "calendarText": "As métricas se atualizam diariamente. A atividade de hoje estará disponível a partir de amanhã."
  }
}
</i18n>
