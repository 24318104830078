mt-4
<template>
  <div
    ref="QRElementRef"
    spellcheck="false"
    class="group relative m-2 h-[37rem] w-[26rem] overflow-hidden border bg-[#95C11F]"
  >
    <div class="absolute top-0 h-1/2 w-full transform" />

    <div class="relative mx-5">
      <h1
        contenteditable="true"
        :data-placeholder="t('emptyTitle')"
        class="m-0 mx-8 flex h-48 flex-col justify-center overflow-hidden break-words rounded rounded-b-xl border-2 border-transparent bg-[#751B0D] px-3 py-6 text-center text-white focus:outline-none group-hover:border-dashed group-hover:border-gray-700 group-hover:focus:border-sky-500"
        :class="[title && titleFontSize, title ? 'font-bold' : 'opacity-40']"
        @input="UpdateTitle"
      >
        {{ title }}
      </h1>
      <h1
        contenteditable="true"
        :data-placeholder="t('emptyTag')"
        class="m-0 mx-6 mt-4 flex h-6 flex-col justify-center border-2 border-transparent text-center text-xs uppercase text-[#751B0D] focus:outline-none group-hover:border-dashed group-hover:border-gray-700 group-hover:focus:border-sky-500"
        :class="[pdfMode && 'pb-3']"
        @input="UpdateTag"
      >
        {{ tag }}
      </h1>
      <LoadingSpinner v-if="loading" class="mt-4 h-6 w-6 text-sky-500" aria-hidden="true" />

      <img v-else-if="qrDataImage" :src="qrDataImage" class="mx-auto h-56 w-56 rounded-xl" />
      <div v-else class="mx-auto mt-4 flex h-24 w-24 items-center justify-center rounded border-2 border-gray-700">
        QR
      </div>
      <h1
        contenteditable="true"
        :data-placeholder="t('emptyDescription')"
        class="m-0 mx-6 mt-3 flex h-16 flex-col justify-center break-words rounded border-2 border-transparent text-center text-[#751B0D] focus:outline-none group-hover:border-dashed group-hover:border-gray-700 group-hover:focus:border-sky-500"
        :class="[
          description && descriptionFontSize,
          description ? 'font-medium' : 'opacity-40',
          pdfMode ? 'overflow-visible pb-4' : 'overflow-hidden',
        ]"
        @input="UpdateDescription"
      >
        {{ description }}
      </h1>
      <div v-if="!windowConfig.whiteLabel" class="flex justify-center">
        <PerfitLogo class="h-12 w-16" color="#751B0D" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect, ref, computed, inject } from "vue";
import QRCode from "qrcode";

//Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

//Logo
import PerfitLogo from "../PerfitLogo.vue";

//Utils
import { useNotifications } from "@composables/notifications";
import { useI18n } from "vue-i18n";

const { notify } = useNotifications();
const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    tag?: string;
    url: string;
    qrDataImage: string;
  }>(),
  {
    title: "",
    description: "",
    tag: "",
    url: "",
    qrDataImage: "",
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:title", modelValue: string): void;
  (e: "update:description", modelValue: string): void;
  (e: "update:tag", modelValue: string): void;
  (e: "update:qrDataImage", modelValue: string): void;
}>();

const maxTitleLength = 300;
const UpdateTitle = (event: Event) => {
  const text = (event.target as HTMLHeadElement)?.textContent;

  if (text && text.length > maxTitleLength) (event.target as HTMLHeadElement).textContent = props.title;
  emit("update:title", (event.target as HTMLHeadElement)?.textContent || "");
};

const maxDescriptionLength = 120;
const UpdateDescription = (event: Event) => {
  const text = (event.target as HTMLHeadElement)?.textContent;

  if (text && text.length > maxDescriptionLength) (event.target as HTMLHeadElement).textContent = props.description;
  emit("update:description", (event.target as HTMLHeadElement)?.textContent || "");
};

const maxTagLength = 30;
const UpdateTag = (event: Event) => {
  const text = (event.target as HTMLHeadElement)?.textContent;

  if (text && text.length > maxTagLength) (event.target as HTMLHeadElement).textContent = props.tag;
  emit("update:tag", (event.target as HTMLHeadElement)?.textContent || "");
};

const pdfMode = inject("pdfMode", false);
const windowConfig = computed(() => window.config);

//Refs
const QRElementRef = ref();

//State
const loading = ref(false);

//Functions
const generateQR = async (text) => {
  try {
    loading.value = true;

    const QRImage = await QRCode.toDataURL(text, {
      errorCorrectionLevel: "H",
      scale: 12,
      color: {
        dark: "#ffffff",
        light: "#751B0D",
      },
      margin: 2,
    });

    emit("update:qrDataImage", QRImage);

    loading.value = false;
  } catch (err) {
    loading.value = false;
    notify({
      title: t(`errors.server.title`),
      text: t(`errors.server.text`),
      theme: "error",
      timeout: 0,
    });
  }
};

const titleFontSize = computed(() => {
  if (props.title.length >= 220) {
    return "text-xs";
  }
  if (props.title.length >= 180) {
    return "text-sm";
  }
  if (props.title.length >= 140) {
    return "text-base";
  }
  if (props.title.length >= 120) {
    return "text-lg";
  }
  if (props.title.length >= 90) {
    return "text-xl";
  }
  if (props.title.length >= 70) {
    return "text-2xl";
  }
  return "text-3xl";
});

const descriptionFontSize = computed(() => {
  if (props.description.length >= 80) {
    return "text-xs";
  }
  if (props.description.length >= 60) {
    return "text-sm";
  }
  if (props.description.length >= 40) {
    return "text-base";
  }
  return "text-lg";
});

//Watchers
watchEffect(async () => {
  if (props.url) {
    await generateQR(props.url);
  } else {
    emit("update:qrDataImage", "");
  }
});
</script>

<style scoped>
[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-placeholder);
}
</style>

<i18n lang="jsonc">
// TODO: i18n
{
  "es": {
    "emptyTitle": "Titulo",
    "emptyDescription": "Descripción",
    "emptyTag": "Etiqueta"
  },
  "pt": {
    "emptyTitle": "Título",
    "emptyDescription": "Descrição",
    "emptyTag": "Etiqueta"
  }
}
</i18n>
