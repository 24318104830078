<template>
  <div ref="scrollElementRef" :class="['relative max-h-full overflow-y-auto pr-1']">
    <table class="w-full">
      <THead
        :columns="columns"
        :rows="rows"
        :selectable="selectable"
        :selected-rows="selectedRows"
        :sort-by="sortBy"
        :options="options"
        :skeleton="skeleton"
        @select-all="selectAllRows"
        @deselect-all="cleanSelectedRows"
        @sort-by="updateSortBy"
      >
        <template v-for="(_, slot) of $slots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </THead>
      <TBody
        :columns="columns"
        :rows="rows"
        :selectable="selectable"
        :selected-rows="selectedRows"
        :loading="loading"
        :skeleton="skeleton"
        :skeleton-count="skeletonCount"
        @select="selectRow"
        @selects="selectRows"
        @deselect="deselectRow"
      >
        <template v-for="(_, slot) of $slots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </TBody>
    </table>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="ColId extends string, SortIdType extends string, ColData, RowId extends string, RowData"
>
import { toRaw, ref, watch } from "vue";

import type { Column, Columns, Row, Rows, SortColumn, SortingModes, OptionsProps } from "./table.type";

// Components
import TBody from "./components/TBody.vue";
import THead from "./components/THead.vue";

// Composables
import { useInfiniteScroll } from "@vueuse/core";
import { hasScroll } from "@composables/components";

// Utils
import { cloneDeep, isEqual } from "lodash";

//Props
const props = withDefaults(
  defineProps<{
    columns: Columns<ColId, SortIdType, ColData>;
    rows: Rows<RowId, ColId, RowData>;
    selectedRows?: Rows<RowId, ColId, RowData>;
    selectable?: boolean;
    sortBy?: SortColumn<ColId | SortIdType>;
    skeleton?: boolean;
    loading?: boolean;
    skeletonCount?: number;
    options?: OptionsProps;
  }>(),
  {
    selectable: false,
    selectedRows: () => [],
    sortBy: undefined,
    skeleton: false,
    loading: false,
    skeletonCount: 4,
    options: () => ({
      stickyTop: 0,
    }),
  },
);

const emit = defineEmits<{
  "update:selectedRows": [Rows<RowId, ColId, RowData>];
  "update:sortBy": [SortColumn<ColId | SortIdType>];
  scrollBottom: [];
}>();

const updateSelectedRows = (rows: Rows<RowId, ColId, RowData>) => {
  emit("update:selectedRows", rows);
};

function getNextSortMode(modes: Array<SortingModes>, selectedMode: SortingModes) {
  const index = modes.indexOf(selectedMode);
  const nextIndex = (index + 1) % modes.length;
  return modes[nextIndex];
}

const updateSortBy = (column: Column<ColId, SortIdType, ColData>) => {
  if (!column.sortModes) return;

  if (props.sortBy && [column.id, column.sortKey].includes(props.sortBy?.columnId)) {
    const sort = getNextSortMode(column.sortModes, props.sortBy?.sort);
    const newSortBy = {
      columnId: column?.sortKey ?? column.id,
      sort: sort,
    };
    if (isEqual(props.sortBy, newSortBy)) return;

    emit("update:sortBy", newSortBy);
    return;
  }

  emit("update:sortBy", {
    columnId: column?.sortKey ?? column.id,
    sort: column.sortModes[0],
  });
};

const selectAllRows = () => {
  const selectableRows = toRaw(props.rows).filter((row) => row.options?.selectable !== false);
  updateSelectedRows(cloneDeep(selectableRows));
};

const cleanSelectedRows = () => {
  updateSelectedRows([]);
};

const selectRow = (row: Row<RowId, ColId, RowData>) => {
  updateSelectedRows([...toRaw(props.selectedRows), toRaw(row)]);
};

const selectRows = (rows: Rows<RowId, ColId, RowData>) => {
  const uniqueIds = new Set<string>();
  const selectedRows = [...toRaw(props.selectedRows), ...toRaw(rows)];

  const filteredSelectedRows = selectedRows.filter((row) => {
    if (!uniqueIds.has(row.id)) {
      uniqueIds.add(row.id);
      return true;
    }
    return false;
  });
  updateSelectedRows(filteredSelectedRows);
};

const deselectRow = (rowToRemove: Row<RowId, ColId, RowData>) => {
  const filteredRows = toRaw(props.selectedRows).filter((row) => row.id !== rowToRemove.id);
  updateSelectedRows(filteredRows);
};

const scrollElementRef = ref<HTMLElement>();

const scrollable = ref(false);

useInfiniteScroll(
  scrollElementRef,
  () => {
    emit("scrollBottom");
  },
  { distance: 50 },
);

watch(
  [() => props.skeleton],
  () => {
    const element = scrollElementRef.value;
    if (element === undefined) return;

    scrollable.value = hasScroll({ element });
  },
  { immediate: true },
);
</script>
