import { ListView } from "@/core/magnet";
import ListMixin from "@/mixins/list";
import { _ } from "@/helpers/utils";
import app from "@/app";
import listTpl from "../templates/list.hbs";

export default ListView.mix(ListMixin).extend({
  module: "lists",
  templates: {
    initial: listTpl,
  },
  gender: "F",
  cache: false,
  limit: 100000,
  query: {
    order: "name asc",
  },
  sortOptions: [
    { column: "name", type: "asc", label: "name" },
    { column: "quality", type: "desc", label: "quality" },
    { column: "totalContacts", type: "desc", label: "totalContacts" },
  ],
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=import]": "import",
      "click [data-action=open-create-segments-modal]": "openCreateSegmentsModal",
      "click [data-action=open-clean-modal]": "openCleanModal",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-split-modal]": "openSplitModal",
      "click [data-action=open-sample-modal]": "openSampleModal",
      "click [data-action=open-join-modal]": "openJoinModal",
      "click [data-action=open-intersect-modal]": "openIntersectModal",
      "click [data-action=open-export-modal]": "openExportModal",
      "click [data-action=open-substract-modal]": "openSubstractModal",
      "click [data-action=open-filter-modal]": "openFilterModal",
      "click [data-action=open-delete-modal]": "openDeleteModal",
      "submit [data-form=delete-list]": "deleteLists",
      "submit [data-form=segment]": "createSegments",
      "click [data-action=refresh-cache]": "refreshCache",
      "submit [data-form=import]": "redirectToImport",
      "submit [data-form=create-task]": "createTask",
      "change [data-action=change-substraction-list]": "changeSubstractionList",
      "change [data-action=change-filter-condition]": "changeFilterCondition",
      "change [data-format-type]": "toogleConditionValue",
      "submit [data-form=substract]": "substract",
      "submit [data-form=clean]": "clean",
      "submit [data-form=export]": "export",
      "keyup [data-value=ALL]": "updateListName",
      "change [data-value=DATEPICKER]": "updateListName",
      "change [data-role=filter-value] select": "updateListName",
      "change [name=deleteContacts]": "onChangeDeleteRadio",
    });
  },

  // Emmit events
  broadcast: function () {
    if (this.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_LISTS");
    }
  },

  // Empty cache after deleting a list
  afterMass: function (params, method) {
    if (method === "destroy") {
      localStorage.removeItem("lists");
    }
    if (method === "analyze") {
      app.instances.singleton("tasks", "list").render();
    }
  },

  // Hides or show action buttons depending on the number of items checked
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      none = toolbar.find("[data-type=none]"),
      justOne = toolbar.find("[data-type=just-one]"),
      onePlus = toolbar.find("[data-type=one-plus]"),
      twoPlus = toolbar.find("[data-type=two-plus]"),
      checked = this.getChecked(),
      count = checked.length;

    // Hide buttons when no items are checked
    if (count === 0) {
      none.removeClass("hide");
      justOne.addClass("hide");
      onePlus.addClass("hide");
      twoPlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Show actions when just one item is checked
    if (count === 1) {
      none.addClass("hide");
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      twoPlus.addClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Show actions when two or more items are checked
    if (count > 1) {
      none.addClass("hide");
      justOne.addClass("hide");
      onePlus.removeClass("hide");
      twoPlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }
  },

  // Creates segments
  createSegments: function (e) {
    var view = this;
    e.preventDefault();
    $.post("lists/segments", function () {
      app.session.reload(function () {
        view.finish(window.lang.lists.feedback.segmented);
      });
    });
  },

  refreshCache: function (e) {
    var view = this;
    e.preventDefault();
    $.post("lists/refreshcache", function () {
      app.session.reload(function () {
        view.finish("Recalculando tamaños de listas");
      });
    });
  },

  // Opens the clean modal
  openCreateSegmentsModal: function () {
    return this.openModal(require("../templates/modals/segment.hbs"));
  },

  // Opens the clean modal
  openCleanModal: function () {
    return this.openModal(require("../templates/modals/clean.hbs"), {
      data: this.getChecked(),
    });
  },

  // Opens the duplicate modal
  openDuplicateModal: function () {
    return this.openModal(require("../templates/modals/duplicate.hbs"), {
      data: this.getFirstChecked(),
    });
  },

  // Opens the split modal
  openSplitModal: function () {
    return this.openModal(require("../templates/modals/split.hbs"), {
      data: this.getFirstChecked(),
    });
  },

  // Opens the sample modal
  openSampleModal: function () {
    return this.openModal(require("../templates/modals/sample.hbs"), {
      data: this.getFirstChecked(),
    });
  },

  // Opens the join modal
  openJoinModal: function () {
    return this.openModal(require("../templates/modals/join.hbs"), {
      data: this.getChecked(),
    });
  },

  // Opens the intersect modal
  openIntersectModal: function () {
    return this.openModal(require("../templates/modals/intersect.hbs"), {
      data: this.getChecked(),
    });
  },

  // Opens the export modal
  openExportModal: function () {
    var checked = this.getChecked(),
      lang = window.lang;
    return this.openModal(require("../templates/modals/export.hbs"), {
      lang: lang,
      data: checked,
      legend:
        checked.length > 1
          ? lang.lists.exportLegendPlural.replace("###", checked.length)
          : lang.lists.exportLegendSingular,
    });
  },

  // Opens the substract modal
  openSubstractModal: function () {
    return this.openModal(require("../templates/modals/substract.hbs"), {
      data: this.getChecked(),
    });
  },

  // Opens the filter modal
  openFilterModal: function () {
    this.requestFilters(this.getChecked().pop(), require("../templates/modals/filter.hbs"));
  },

  openDeleteModal: function () {
    return this.openModal(require("../templates/modals/delete.hbs"), {
      lang: window.lang,
    });
  },

  // Cleans one or more lists
  deleteLists: function (e) {
    var view = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      lists = this.getChecked(),
      resolve = [];
    e.preventDefault();

    // Create one deferred per checked list
    resolve = lists.map(function (list) {
      return list.delete(!!data.deleteContacts);
    });

    // Resolve deferreds
    $.when.apply(null, resolve).done(function () {
      view.finish(window.lang.lists.feedback.massDeleted);
    });
  },

  onChangeDeleteRadio: function () {
    $("#button-delete-list").removeAttr("disabled");
  },

  // Creates a task based on the modal options
  createTask: function (e) {
    var view = this,
      form = $(e.currentTarget),
      feedback = form.data("feedback"),
      data = form.serializeObject(),
      url = form.attr("action");
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // If received, parse quality
    if (_.isDefined(data.quality)) {
      data.quality = data.quality / 5;
    }

    // Performs an AJAX call to the action endpoint
    $.post(url, data, function () {
      view.finish(feedback);
    });
  },

  // Updates the list selector on the substraction modal
  changeSubstractionList: function (e) {
    var id = $(e.currentTarget).val();
    this.getModal()
      .find("[data-id=" + id + "]")
      .addClass("hide")
      .siblings()
      .removeClass("hide");
  },

  // Substracts one list from another
  substract: function (e) {
    var view = this,
      main = parseInt($(e.currentTarget).find("select").val(), 10),
      list,
      excluded = [];
    e.preventDefault();

    // Collects the IDs of the list to substract
    excluded = _.pluck(this.getChecked(), "id").filter(function (id) {
      return id !== main;
    });

    // Get the list to substract from
    list = _.findWhere(this.getChecked(), { id: main });

    // Performs an AJAX call to substract the lists
    list.substract({ substractListIds: excluded }).done(function () {
      view.finish(window.lang.lists.feedback.substracted);
    });
  },

  // Cleans one or more lists
  clean: function (e) {
    var view = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      lists = this.getChecked(),
      resolve = [];
    e.preventDefault();

    // Validate form
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Transform quality value
    if (_.isDefined(data.quality)) {
      data.quality = data.quality / 10;
    }

    // Create one deferred per checked list
    resolve = lists.map(function (list) {
      return list.clean(data);
    });

    // Resolve deferreds
    $.when.apply(null, resolve).done(function () {
      view.finish(window.lang.lists.feedback.massCleaned);
    });
  },

  // Exports the lists
  export: function (e) {
    var view = this,
      lists = this.getChecked(),
      resolve = [];
    e.preventDefault();

    // Create one deferred per checked list
    resolve = lists.map(function (list) {
      return list.export();
    });

    // Resolve deferreds
    $.when.apply(null, resolve).done(function () {
      view.finish(window.lang.lists.feedback.massExported);
    });
  },

  // Reloads after a successful batch of resolved promises
  finish: function (feedback) {
    // Update the tasks indicator
    app.instances.singleton("tasks", "list").render();

    // Close modal
    this.getModal().modal("hide");

    // Display feedback
    this.displayMessage(feedback);

    // Reload
    this.reload();
  },

  // Show warning and redirect to import page
  import: function (e) {
    e.preventDefault();
    app.router.navigateTo("lists/import");
  },

  // Redirects to import page
  redirectToImport: function (e) {
    e.preventDefault();
    app.router.navigateTo("lists/import");
  },
});
