import { usePagingRequests, usePagingRequestsDataWrapper, useRequests } from "@api/requests";
import { formatISO } from "date-fns";
import qs from "qs";

import { isEqual } from "lodash";

// Mapper
import { mapFilter } from "@/vue/api/modules/contacts/contacts.mapper";

//Types
import type { SuccessAPILegacyPaging, SuccessAPIOffsetPaging } from "@api/models/requestWrappers";
import type {
  ContactsBulkListAPIParams,
  GetParams,
  ContactsListAsyncGenerator,
  ActivityListAPIParams,
  ActivityListAsyncGenerator,
  ContactsService,
  ActivityListParams,
  SubscribeToListsParams,
} from "./contacts.types";
import { SuccessAPIBase } from "@api/models/requestWrappers";
import type { Activities, Contacts } from "@domain/contacts";
import type { Task } from "@domain/notifications";

export function useContacts(): ContactsService {
  const requestPaging = usePagingRequests();
  const requestPagingDataWrapper = usePagingRequestsDataWrapper();
  const request = useRequests();

  let getListGenerator: ContactsListAsyncGenerator | undefined = undefined;
  let getListParams: GetParams | undefined = undefined;

  let getActivityListGenerator: ActivityListAsyncGenerator | undefined = undefined;
  let getActivityListParams: ActivityListParams | undefined = undefined;

  return {
    async get(options) {
      let isNewRequest = false;
      if (!isEqual(getListParams, options) || !getListGenerator) {
        isNewRequest = true;
        getListParams = options;
        getListGenerator = await requestPaging<Contacts, unknown, ContactsBulkListAPIParams>(
          {
            url: `/contacts`,
            method: "get",
            urlParams: {
              fields: "_all,customFields[_all]",
              q: getListParams?.search ? `*${getListParams?.search}*` : undefined,
              "filters.list": getListParams.listId,
              "filters.interest": getListParams.interestId,
              "filters.state": getListParams.state,
              sortBy: getListParams?.sortBy ?? "email",
              sortDir: getListParams?.sortDir ?? "asc",
            },
          },
          { limit: getListParams?.limit || 20, offset: getListParams?.offset || 0 },
          true,
        );
      }

      if (!getListGenerator || getListGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };

      const data = (await getListGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<Contacts>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
        isNewRequest,
      };
    },
    async addToList(params) {
      const res = await request({
        url: `/contacts/${params.contactId}/lists/${params.listId}`,
        method: "put",
      });

      return res.data;
    },
    async removeFromList(params) {
      await request({
        url: `/contacts/${params.contactId}/lists/${params.listId}`,
        method: "delete",
      });
    },
    async subscribeToLists(params) {
      const res = await request<SuccessAPIBase<Task>, SubscribeToListsParams>({
        url: `/contacts/subscribe`,
        method: "post",
        data: {
          ...params,
          query: params.query ? `*${params.query}*` : undefined,
        },
      });

      return res.data;
    },
    async addInterest(params) {
      const res = await request({
        url: `/contacts/${params.contactId}/interests/${params.interestId}`,
        method: "put",
      });

      return res.data;
    },
    async bulkList(options) {
      const res = await request<SuccessAPIBase<Contacts>>({
        url: `/contacts/_bulk`,
        method: "post",
        data: { action: options?.action || "GET", ids: options.ids },
      });

      return res.data;
    },
    async export(params) {
      const res = await request<SuccessAPIBase<Task>>({
        url: `/contacts/export`,
        method: "post",
        data: qs.stringify({
          listId: params.listId,
          interestId: params.interestId,
          state: params.state,
          query: params.query ? `*${params.query}*` : undefined,
        }),
      });

      return res.data;
    },
    async activityList(options) {
      const filterType = options?.type ? mapFilter(options?.type) : "";
      const isAssisted = options?.sale === "onlyAssisted" ? true : false;
      const periodFrom = options?.gt ? formatISO(options?.gt).substr(0, 19) + "Z" : "";
      const periodTo = options?.lt ? formatISO(options?.lt).substr(0, 19) + "Z" : "";

      if (!isEqual(getActivityListParams, options) || !getActivityListGenerator) {
        getActivityListParams = options;
        getActivityListGenerator = await requestPagingDataWrapper<Activities, unknown, ActivityListAPIParams>(
          {
            url: `/contactevents`,
            urlScroll: `/contactevents/scroll`,
            method: "get",
            urlParams: {
              "filters.contact_id":
                getActivityListParams?.id !== undefined ? getActivityListParams?.id.toString() : undefined,
              ...(filterType !== "" ? { "filters.type.in": filterType } : {}),
              ...(periodFrom !== "" ? { "filters.created.gt": periodFrom } : {}),
              ...(periodTo !== "" ? { "filters.created.lt": periodTo } : {}),
              ...(isAssisted ? { "filters.assisted": isAssisted } : {}),
            },
          },
          { "filters.limit": getActivityListParams?.limit || 10, "filters.offset": getActivityListParams?.offset || 0 },
        );
      }

      if (!getActivityListGenerator || getActivityListGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
        };

      const data = (await getActivityListGenerator.next()) as IteratorResult<SuccessAPILegacyPaging<Activities>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
        };
      }

      return {
        done: false,
        total: data.value.data.total,
        value: data.value.data.data,
      };
    },
    async delete(params) {
      await request({
        url: `/contacts/${params.id}`,
        method: "delete",
      });
    },
  };
}
