import { EditView } from "@/core/magnet";
import app from "@/app";
import qs from "qs";
const { notify } = useNotifications();


// Application
import { useLocalStorageApp } from "@application";

// Composables
import { useNotifications } from "@composables/notifications";

export default EditView.extend({
  gender: "F",

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    if (this.item.attributes.options?.UNLAYER_TPL_ID && !app.session.attributes.isGuest) {
      this.useUnlayer = true;
      this.unlayerTemplateId = this.item.attributes.options?.UNLAYER_TPL_ID;
    }

    const localStorageApp = useLocalStorageApp();
    const isEcommerce = localStorageApp.get({
      id: "isEcommerce",
    });

    // Get list display preference
    this.showConversionsColumn = isEcommerce;

    // Continue with rendering
    callback();
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      lang: window.lang,
      config: window.config,
      session: app.session,
      isDesktop: window.innerWidth > 640,
      splitABSubjects: this.item.splitABSubjects(),
      version: this.options.version,
      useUnlayer: this.useUnlayer,
      showConversionsColumn: this.showConversionsColumn,
    };
  },

  createModelInstance: function (options) {
    return new app.models[this.module]({
      id: options.id,
      version: options.version,
    });
  },

  // Performs an action before fetching the model
  beforeFetch: function (params) {
    $(".mobile-splash").addClass("hidden");
    this.item;
    return params;
  },

  // Opens the share modal
  openShareModal: function () {
    this.item.share().done(
      function (response) {
        this.openModal(require("../templates/modals/share.hbs"), {
          data: this.item,
          link: `${window.location.origin}/${response.data.replace("&", "?").replace("#", "")}`,
        });
      }.bind(this)
    );
  },

  // Selects the text in the image link input
  selectShareLinkText: function () {
    document.getElementById("share").select();
  },

  // Copies the image link to the clipboard
  copyShareUrl: function (e) {
    var button = $(e.currentTarget);
    this.selectShareLinkText();
    if (document.execCommand("copy")) {
      button.text(window.lang.done);
    }
  },

  // Opens the duplicate modal
  openDuplicateModal: function () {
    this.openModal(require("../templates/modals/duplicate.hbs"), {
      data: this.item,
    });
  },

  // Duplicates the campaign
  duplicate: function (e) {
    var form = e.currentTarget,
      data = $(form).serializeObject();
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Send request
    this.item.duplicate(data).done(
      function (response) {
        this.getModal().modal("hide");
        app.router.navigateTo(`campaigns/${response.data.id}`);
      }.bind(this)
    );
  },

  // Archives campaigns
  archive: function () {
    this.item.archive().done(function () {
      app.router.navigateTo("campaigns");
    });
  },

  // Unarchives campaigns
  unarchive: function () {
    this.item.unarchive().done(function () {
      app.router.navigateTo("campaigns");
    });
  },

  // Stops a campaign
  stop: function (e) {
    const self = this;
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Stop via API
    this.item.stop().done(function () {
      self.displayMessage(window.lang.campaigns.sendingStopped);
      app.router.navigateTo("campaigns");
    });
  },

  // Redirect to contents if the campaign is pending
  redirectToContents: function (campaign) {
    if (campaign.state === "SCHEDULED" || campaign.state === "PENDING_APPROVAL") {
      app.router.navigateTo(`metrics/${campaign.id}/contents`);
    }
  },

  // Opens the sent criterias modal
  openCriteriasModal: function (e) {
    e.preventDefault();

    // Open modal
    this.openModal(require("../templates/modals/criterias.hbs"), {
      data: this.item,
      session: app.session,
    });
  },


  join: function () {
    const self = this;
    const apiUrl = "lists/join";
    const listIds = this.item.attributes.include.lists;
    const campaignName = this.item.attributes.name;

    const truncateString = (str) => {
      if (str.length <= 50) return str;
      return str.slice(0, 50);
    };

    const randomString = (length) => {
      const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join("");
    };

    const newListName = truncateString("_admin-" + randomString(3) + "-" + campaignName);

    return $.ajax({
      type: "POST",
      url: apiUrl,
      data: qs.stringify({
        newListName: newListName,
        listIds: listIds,
      }),
      success: function () {
        notify({
          text: window.lang.lists.feedback.joined,
          theme: "info",
        });
      },
      error: function () {
        notify({
          text: window.lang.ajaxError,
          theme: "error",
        });
      },
    });
  },

});
