// API
import { useRequests } from "@api/requests";

// Types
import type { IntegrationsService } from "@application/ports";
import type { GetIntegrationsAPIResponse, IntegrateAPIResponse } from "./integrations.types";

// Utils
import { ok, err } from "neverthrow";

export const useIntegrationsService = (): IntegrationsService => {
  const requestAPIV2 = useRequests();

  return {
    async getIntegrations() {
      const res = await requestAPIV2<GetIntegrationsAPIResponse>({
        url: `/integrations`,
        method: "get",
      });

      return ok(res.data);
    },
    async integrate(params) {
      const data = params.store !== "woocommerce" ? params.data : undefined;

      try {
        const res = await requestAPIV2<IntegrateAPIResponse>({
          url: `/integrations/${params.store}/init`,
          method: "post",
          data,
        });

        console.log("integrate res: ", res);

        return ok(undefined);
      } catch (e) {
        return err({
          type: "FAILED_TO_INTEGRATE",
        });
      }
    },
  };
};
