<template>
  <div>
    <FormGroup :id="id" :label="label" :hint="hint">
      <div class="relative">
        <SimpleInput
          type="number"
          :model-value="modelValue"
          :placeholder="placeholder"
          :loading="loading"
          :has-error="error"
          :model-modifiers="{
            maxDecimals: 2,
          }"
          :class="disabled && 'pointer-events-none select-none bg-gray-100'"
          class="mt-1 pr-16 pl-9"
          @update:model-value="UpdateModelValue"
          @blur="EmitBlur"
        />
        <span :class="disabled ? 'text-gray-400' : 'text-gray-500'" class="absolute top-1.5 left-4">{{
          CurrenciesSimbols[currency]
        }}</span>
        <span v-if="!loading" :class="disabled ? 'text-gray-400' : 'text-gray-500'" class="absolute top-1.5 right-4">{{
          currency
        }}</span>
      </div>
    </FormGroup>
  </div>
</template>

<script lang="ts">
export enum Currencies {
  ARS = "ARS",
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
  MXP = "MXP",
  PEN = "PEN",
  PYG = "PYG",
  UYU = "UYU",
  GTQ = "GTQ",
  HNL = "HNL",
  SVC = "SVC",
  CLP = "CLP",
  COP = "COP",
  CRC = "CRC",
  BOB = "BOB",
  CAD = "CAD",
  VEF = "VEF",
}
</script>

<script lang="ts" setup>
import { v4 as uuid } from "uuid";

//Components
import FormGroup from "@molecules/FormGroup.vue";
import SimpleInput from "@atoms/SimpleInput.vue";

enum CurrenciesSimbols {
  ARS = "$",
  BRL = "R$",
  USD = "$",
  EUR = "€",
  MXP = "$",
  PEN = "S/",
  PYG = "₲",
  UYU = "$",
  GTQ = "Q",
  HNL = "L",
  SVC = "$",
  CLP = "$",
  COP = "$",
  CRC = "₡",
  BOB = "$",
  CAD = "$",
  VEF = "Bs",
}

//Props
withDefaults(
  defineProps<{
    id?: string;
    modelValue: string;
    currency: Currencies;
    label?: string;
    hint?: string;
    placeholder?: string;
    error: boolean;
    disabled?: boolean;
    loading?: boolean;
  }>(),
  {
    id: uuid(),
    modelValue: "",
    currency: Currencies.ARS,
    label: "",
    hint: "",
    placeholder: "0.00",
    error: false,
    disabled: false,
    loading: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "blur", ev: Event): void;
}>();

const UpdateModelValue = (value: string) => emit("update:modelValue", value);

const EmitBlur = (ev: Event) => emit("blur", ev);
</script>
