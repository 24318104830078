<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem] space-y-4">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="https://docs.myperfit.com/articles/1614" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <div class="space-y-2">
        <Label>{{ t("cleanOptions.title") }}:</Label>
        <CheckboxDescription
          v-model="selectedClean.inactive"
          :label="t('cleanOptions.inactive.label')"
          :description="t('cleanOptions.inactive.description')"
        />
        <CheckboxDescription
          v-model="selectedClean.bounced"
          :label="t('cleanOptions.bounced.label')"
          :description="t('cleanOptions.bounced.description')"
        />
        <CheckboxDescription
          v-model="selectedClean.unsubscribed"
          :label="t('cleanOptions.unsubscribed.label')"
          :description="t('cleanOptions.unsubscribed.description')"
        />
        <CheckboxDescription
          v-model="selectedClean.complained"
          :label="t('cleanOptions.complained.label')"
          :description="t('cleanOptions.complained.description')"
        />
      </div>
      <div class="space-y-2">
        <Label>{{ t("actionsOptions.title") }}:</Label>
        <RadioList :options="actionOptions" :selected="selectedAction" />
      </div>
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" @click="clean">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, reactive } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import CheckboxDescription from "@molecules/CheckboxDescription";
import RadioList from "@molecules/RadioList";
import type { Option } from "@molecules/RadioList";
import Label from "@atoms/Label.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    ids: Array<string>;
  }>(),
  {
    open: false,
  },
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const actionOptions: Array<{
  key: "remove" | "delete";
  label: string;
}> = [
  {
    key: "remove",
    label: t("actionsOptions.remove"),
  },
  {
    key: "delete",
    label: t("actionsOptions.delete"),
  },
];
const selectedAction = ref<Option<"remove" | "delete">>(actionOptions[0]);
const selectedClean = reactive({
  inactive: false,
  bounced: true,
  unsubscribed: true,
  complained: true,
});

const loading = ref(false);
const clean = async () => {
  try {
    loading.value = true;

    const promises = props.ids.map((id) =>
      listAPI.clean({
        id: id,
        bounced: selectedClean.bounced,
        complained: selectedClean.complained,
        inactive: selectedClean.inactive,
        unsubscribed: selectedClean.unsubscribed,
        action: selectedAction.value.key,
      }),
    );
    await Promise.all(promises);
    await notificationStore.fetchTasks();
    if (props.ids.length === 1) {
      notify({
        title: t("notifications.clean.title"),
        text: t("notifications.clean.text"),
        theme: "info",
      });
    } else {
      notify({
        title: t("notifications.cleans.title"),
        text: t("notifications.cleans.text"),
        theme: "info",
      });
    }

    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Limpiar",
    "description": "Es importante mantener tus listas con un alto nivel de interacción para mejorar los resultados de tus campañas. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "cleanOptions": {
      "title": "Limpiar los contactos",
      "inactive": {
        "label": "Sin actividad",
        "description": "Nunca tuvieron actividad y no abrieron ninguno de tus últimos 10 envíos. Tuvieron actividad hace más de 6 meses y no abrieron ninguno de tus últimos 15 envíos."
      },
      "bounced": {
        "label": "Rebotados",
        "description": "Se les intentó enviar previamente y fueron rechazados por el servidor de destino."
      },
      "unsubscribed": {
        "label": "Desuscriptos",
        "description": "Solicitaron explícitamente no seguir recibiendo tus correos."
      },
      "complained": {
        "label": "Con informes por spam",
        "description": "Marcaron como correo no solicitado alguno de tus envíos anteriores."
      }
    },
    "actionsOptions": {
      "title": "Acción a tomar",
      "remove": "Remover estos contactos sólo de las listas seleccionadas",
      "delete": "Eliminar estos contactos de todas las listas."
    },
    "notifications": {
      "clean": {
        "title": "Limpiando lista",
        "text": "La lista se está limpiando."
      },
      "cleans": {
        "title": "Limpiando listas",
        "text": "Las listas se están limpiando."
      }
    }
  },
  "pt": {
    "title": "Limpar",
    "description": "É importante manter suas listas com um alto nível de interação para melhorar os resultados de suas campanhas. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "cleanOptions": {
      "title": "Limpar os contatos",
      "inactive": {
        "label": "Inativos",
        "description": "Nunca tiveram atividade e não abriram nenhum dos seus últimos 10 envios. Tiveram atividade há mais de 6 meses e não abriram nenhum dos seus últimos 15 envios."
      },
      "bounced": {
        "label": "Rejeitados",
        "description": "Tentou-se enviá-los anteriormente e foram rejeitados pelo servidor de destino."
      },
      "unsubscribed": {
        "label": "Descadastrados",
        "description": "Solicitaram explicitamente não continuar recebendo seus emails."
      },
      "complained": {
        "label": "Com queixas de spam",
        "description": "Marcaram como spam algum dos seus envios anteriores."
      }
    },
    "actionsOptions": {
      "title": "Ação a ser tomada",
      "remove": "Remover esses contatos apenas das listas selecionadas",
      "delete": "Eliminar esses contatos de todas as listas."
    },
    "notifications": {
      "clean": {
        "title": "Limpando lista",
        "text": "A lista está sendo limpa."
      },
      "cleans": {
        "title": "Limpando listas",
        "text": "As listas estão sendo limpas."
      }
    }
  }
}
</i18n>
