var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <button type='button' class='btn btn-primary "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":49},"end":{"line":3,"column":99}}})) != null ? stack1 : "")
    + "'\n        data-action='open-send-test-modal' data-require-permission='campaigns:update'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"testRecipients")), depth0))
    + "\n    </button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"details"))) && lookupProperty(stack1,"account"))) && lookupProperty(stack1,"features"))) && lookupProperty(stack1,"SENDERSV2")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":92,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <buton type='button' data-action=\"open-content-advanced-options-modal\" class='btn btn-link'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"replyToAddress")), depth0))
    + "\n    </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='row no-content'>\n    <div class='content-options'>\n        <div class='row'>\n            <div class='col-md-4'>\n                <a class='eddie-content' data-action='open-eddie-content-modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentEddie")), depth0))
    + "\n                    <p class='text-muted'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentEddieLegend")), depth0))
    + "\n                    </p>\n                </a>\n            </div>\n            <div class='col-md-4'>\n                <a class='template-content' data-action='create-from-template'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"chooseExistingDesign")), depth0))
    + "\n                    <p class='text-muted'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"chooseExistingDesignLegend")), depth0))
    + "\n                    </p>\n                </a>\n            </div>\n            <div class='col-md-4'>\n                <a class='html-content' data-action='open-create-html-content-modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"importHTML")), depth0))
    + "\n                    <p class='text-muted'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"importHTMLLegend")), depth0))
    + "\n                    </p>\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='preview' data-role='preview'>\n    <div class='content-preview'>\n        <div class='head'>\n            <div class='actions'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"EDDIE",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":59,"column":23}}})) != null ? stack1 : "")
    + "                <a data-action=\"open-social-header-modal\">\n                    <i class='fa fa-share-alt'></i>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentHeader")), depth0))
    + "\n                </a>\n                <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"preview") : stack1), depth0))
    + "\" target=\"_blank\">\n                    <i class='fa fa-globe'></i>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"onlinePreview")), depth0))
    + "\n                </a>\n                <a data-action=\"open-save-content-as-template-modal\">\n                    <i class='fa fa-cloud-download'></i>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"save")), depth0))
    + "\n                </a>\n                <a data-action='delete-content' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"confirmDeleteContent")), depth0))
    + "'>\n                    <i class='fa fa-trash'></i>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"deleteContent")), depth0))
    + "\n                </a>\n            </div>\n            <div class='device-switch'>\n                <button type='button' class='desktop active' data-action='change-preview' data-device='desktop'>\n                    <i class='fa fa-desktop'></i>\n                </button>\n                <button type='button' class='mobile' data-action='change-preview' data-device='mobile'>\n                    <i class='fa fa-mobile'></i>\n                </button>\n            </div>\n        </div>\n        <div class='loading-box hidden'></div>\n        <div class='frame'>\n            <iframe id='content-preview' src='' frameborder='0'></iframe>\n        </div>\n    </div>\n</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-action='open-eddie-content-modal'>\n                    <i class='fa fa-pencil'></i>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"editContent")), depth0))
    + "\n                </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-action='open-html-content-edit-modal'>\n                    <i class='fa fa-pencil'></i>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"editHTML")), depth0))
    + "\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":93,"column":9}}})) != null ? stack1 : "");
},"useData":true});