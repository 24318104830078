import { ListView } from "@/core/magnet";
import { _, objHas, Sequence, formatInteger } from "@/helpers/utils";
import app from "@/app";
import listTpl from "../templates/list.hbs";
import rowsTpl from "../templates/rows.hbs";

export default ListView.extend({
  module: "contacts",
  templates: {
    initial: listTpl,
    rows: rowsTpl,
    empty: require("../templates/empty.hbs"),
  },
  cache: false,
  searchWrapper: ["*", "*"],
  query: {
    order: "email asc",
    fields: "_all",
  },
  paginate: true,
  sortOptions: [
    { column: "email", type: "asc", label: "email" },
    { column: "quality", type: "desc", label: "quality" },
    { column: "created", type: "desc", label: "created" },
  ],
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=open-lists-modal]": "openListsModal",
      "click [data-action=open-interests-modal]": "openInterestsModal",
      "click [data-action=open-columns-modal]": "openColumnsModal",
      "click [data-action=remove-from-list]": "removeFromList",
      "submit [data-form=manage-columns]": "manageColumns",
      "change [data-check=select-column]": "selectColumn",
      "click [data-action=open-export-modal]": "openExportModal",
      "submit [data-form=export]": "export",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Send events
    this.broadcast();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Emmit events
  broadcast: function () {
    if (this.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_CONTACTS");
    }
  },

  // Adds parameters to the fetch call
  beforeFetchItems: function () {
    var columns = this.additionalsData.columns,
      params = this.query;
    if (_.isDefined(this.addedCustomFieldsToQuery)) {
      return this;
    }
    this.addedCustomFieldsToQuery = true;
    params.fields = params.fields || "_all";
    if (columns !== undefined && columns.length) {
      columns.forEach(function (column) {
        if (column.shown && column.custom) {
          params.fields += ",customFields[" + column.id + "]";
        }
      });
    }
    return this;
  },

  // Hides or show action buttons depending on the number of items checked
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      none = this.$("[data-type=none]"),
      onePlus = this.$("[data-type=one-plus]"),
      onePlusList = this.$("[data-type=one-plus-list]"),
      checkboxes = this.$("[data-action=check]"),
      count = checkboxes.filter(":checked").length;

    // Hide buttons when no items are checked
    if (count === 0) {
      none.removeClass("hide");
      onePlus.addClass("hide");
      onePlusList.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Show actions when items are checked
    if (count > 0) {
      none.addClass("hide");
      onePlus.removeClass("hide");
      toolbar.addClass("edit-mode");

      // Show actions depending on the current query
      if (this.query.filter !== undefined && this.query.filter.list !== undefined) {
        onePlusList.removeClass("hide");
      }
    }
  },

  // Opens the modal to assign lists
  openListsModal: function (e) {
    var view = this,
      button = $(e.currentTarget),
      options = button.data(),
      selector = app.instances.get(options.selectorView, "selector");
    e.preventDefault();
    if (button.attr("data-preview") && options.type === "none") {
      return this.confirmModal(
        window.lang.contacts.filterToList.replace("###", formatInteger(view.paging.total)),
        button,
        window.lang.contacts.addToLists,
        "info",
        "data-preview",
        function () {
          this.openListsSelector(selector, options, button);
        }.bind(this),
      );
    }
    this.openListsSelector(selector, options, button);
  },

  openListsSelector: function (selector, options, button) {
    var view = this;
    selector.open(options).on("done", function () {
      view.addToLists(selector.options.selectedItems);
      selector.reset().off("done");
      selector.undelegateEvents();
      button.attr("data-preview", "1");
    });
  },

  // Opens the modal to assign lists
  openInterestsModal: function (e) {
    var view = this,
      button = $(e.currentTarget),
      options = button.data(),
      selector = app.instances.get(options.selectorView, "selector");
    e.preventDefault();
    selector.open(options).on("done", function () {
      view.addToInterests(selector.options.selectedItems);
      selector.reset().off("done");
      selector.undelegateEvents();
    });
  },

  // Opens the modal to select columns
  openColumnsModal: function (e) {
    var modal = this.getModal();
    this.collection.getColumns().done(
      function (response) {
        var renderData = $.extend({}, this.renderData(), { columns: response.data });

        // Render and open the modal
        this.openModal(require("../templates/modals/columns.hbs"), renderData, function () {
          var form = modal.find("form"),
            count = form.find("input:checked").length;

          // Activate drag and drop sort
          modal.find("[data-sortable]").dragsort({
            dragBetween: true,
            dragSelector: ".handler",
            scrollContainer: ".modal-body.scrollbar",
          });

          // Allow only 4 selections
          if (count > 3) {
            form.find("input").not(":checked").attr("disabled", true);
          }
        });
      }.bind(this),
    );
  },

  // Show the progress feedback message for each mass action call
  showProgressMessage: function (response, call, sequence) {
    this.eachMass({
      id: call.item.id,
      method: call.method,
      response: response,
      params: call.params,
      count: sequence.index + 1,
      length: sequence.total,
    });
  },

  // Adds contacts to lists
  addToLists: function (lists) {
    var view = this,
      contacts = this.getChecked(),
      calls = [],
      lang = window.lang.contacts.feedback,
      message = contacts.length > 1 ? contacts.length + " " + lang.massAddedToLists : lang.addedToLists,
      sequence,
      params;

    lists = lists || [];
    // Return if no lists are selected
    if (!lists.length) {
      return this;
    }

    // Manage massive subscription
    if (!contacts.length) {
      params = {
        listId: objHas(this.query, "filter", "list") ? this.query.filter.list : null,
        interestId: objHas(this.query, "filter", "interest") ? this.query.filter.interest : null,
        state: objHas(this.query, "filter", "state") ? this.query.filter.state : null,
        query: objHas(this.query, "q") ? this.query.q : null,
        subscribeToLists: lists,
      };

      // Make API call
      $.ajax({
        type: "POST",
        url: "contacts/subscribe",
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify(params),
      }).done(
        function (response) {
          this.displayMessage(lang.massSubscribed);
          app.instances.singleton("tasks", "list").render();
          this.reload();
        }.bind(this),
      );
      return false;
    }

    // Create calls
    contacts.forEach(function (contact) {
      lists.forEach(function (listId) {
        calls.push({
          item: contact,
          method: "addToList",
          params: listId,
          success: view.showProgressMessage.bind(view),
        });
      });
    });

    // Perform calls
    sequence = new Sequence({
      calls: calls,
      completed: function () {
        this.displayMessage(message);
        this.reload();
      }.bind(this),
    });
    sequence.execute();
  },

  // Adds contacts to lists
  addToInterests: function (interests) {
    var view = this,
      contacts = this.getChecked(),
      calls = [],
      lang = window.lang.contacts.feedback,
      message = contacts.length > 1 ? contacts.length + " " + lang.massAddedToInterests : lang.addedToInterests,
      sequence;

    interests = interests || [];

    // Return if no contacts or interests are selected
    if (!interests.length || !contacts.length) {
      return this;
    }

    // Create calls
    contacts.forEach(function (contact) {
      interests.forEach(function (interestId) {
        calls.push({
          item: contact,
          method: "addToInterest",
          params: interestId,
          success: view.showProgressMessage.bind(view),
        });
      });
    });

    // Perform calls
    sequence = new Sequence({
      calls: calls,
      completed: function () {
        this.displayMessage(message);
        this.reload();
      }.bind(this),
    });
    sequence.execute();
  },

  // Removes contacts from a list
  removeFromList: function (e) {
    var view = this,
      button = $(e.currentTarget),
      contacts = this.getChecked(),
      listId = parseInt(this.query.filter.list, 10),
      sequence,
      calls = [],
      lang = window.lang.contacts.feedback,
      count = contacts.length,
      message = count > 1 ? count + " " + lang.massRemovedFromList : lang.removedFromList;

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Create calls
    contacts.forEach(function (contact) {
      calls.push({
        item: contact,
        method: "removeFromList",
        params: listId,
        success: view.showProgressMessage.bind(view),
      });
    });

    // Perform calls
    sequence = new Sequence({
      calls: calls,
      completed: function () {
        this.displayMessage(message);
        this.reload();
      }.bind(this),
    });
    sequence.execute();
  },

  // Checks a column to be shown in the list view
  selectColumn: function (e) {
    var checkbox = $(e.currentTarget),
      form = checkbox.closest("form"),
      checkboxes = form.find("input");

    // Allow only 4 selections
    if (checkbox.is(":checked")) {
      if (form.find("input:checked").length > 3) {
        checkboxes.not(":checked").attr("disabled", true);
      } else {
        checkboxes.removeAttr("disabled");
      }
    } else {
      checkboxes.removeAttr("disabled");
    }
  },

  // Saves the selected columns for the list view
  manageColumns: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();
    if (!_.size(data)) {
      data = { columns: [] };
    }

    // Make API call
    $.ajax({
      type: "PUT",
      url: "lists/0/columns",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(data),
    }).done(
      function (response) {
        this.getModal().modal("hide");
        this.reload();
      }.bind(this),
    );
  },

  // Opens the export modal
  openExportModal: function () {
    this.openModal(require("../templates/modals/export.hbs"), { data: this.getChecked() });
  },

  // Exports the interests
  export: function (e) {
    var modal = $(self.modal),
      data = {},
      filter = this.query.filter,
      query = this.query.q;
    e.preventDefault();

    // Get the query
    if (filter !== undefined) {
      if (filter.list !== undefined) {
        data.listId = filter.list;
      }
      if (filter.interest !== undefined) {
        data.interestId = filter.interest;
      }
      if (filter.state !== undefined) {
        data.state = filter.state;
      }
    }
    if (query !== undefined) {
      data.query = query;
    }
    if (data.limit !== undefined) {
      delete data.limit;
    }
    if (data.offset !== undefined) {
      delete data.offset;
    }

    // Export via API
    this.collection.export(data).done(
      function () {
        app.integrations.amplitude.event("APP_CONTACTS_EXPORTED", data, true);
        app.integrations.intercom.event("APP_CONTACTS_EXPORTED", data);

        app.instances.singleton("tasks", "list").render();
        this.displayMessage(window.lang.contacts.feedback.massExported);
        this.getModal().modal("hide");
        this.reload();
      }.bind(this),
    );
  },
});
