<template>
  <Menu class="focus:outline-none">
    <MenuItems static class="space-y-1 w-full bg-white rounded h-full overflow-auto">
      <template v-if="!loading">
        <template v-for="section in navSections" :key="section.id">
          <h6
            v-if="section.id && itemsBySection(section.id).length"
            class="px-3 pt-3 pb-1 text-xs font-medium uppercase text-sky-500"
          >
            {{ section.name }}
          </h6>
          <MenuItem
            v-for="item in itemsBySection(section.id)"
            v-slot="{ active }"
            :key="item.name"
            :class="[
              'group flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer w-full focus:ring-0 focus:outline-none',
            ]"
            :data-intercom-target="`VerticalNavigation-${item.key}`"
            @click="updateSelected(item)"
          >
            <div
              :class="[
                item.key == selected.key ? 'bg-sky-100 text-gray-900' : 'text-gray-600 ',
                item.key !== selected.key && active && 'bg-gray-50',
              ]"
            >
              <component
                :is="item.icon"
                :class="[
                  item.key == selected.key ? 'text-sky-400' : 'text-gray-500 group-hover:text-sky-400',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                ]"
                aria-hidden="true"
              />
              <span :ref="(el) => assignToRefs(el, item.key)" class="truncate">
                {{ item.name }}
                <SimpleBadge v-if="item.hasNew" class="mx-2" :dot="true" theme="green">{{ t("new") }}</SimpleBadge>
              </span>
              <span
                v-if="item.count !== undefined"
                :class="[
                  item.key == selected.key ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                  'ml-auto inline-block py-0.5 px-3 text-xs rounded-full',
                ]"
              >
                {{ item.count }}
              </span>
            </div>
          </MenuItem>
        </template>
      </template>
      <div v-if="loading" class="mb-1 animate-pulse px-3 py-3 space-y-7">
        <div class="flex justify-between items-center">
          <div class="h-3 w-40 bg-gray-100 rounded-xl"></div>
          <div class="h-5 w-7 bg-gray-100 rounded-xl"></div>
        </div>
        <div class="flex justify-between items-center">
          <div class="h-3 w-36 bg-gray-100 rounded-xl"></div>
          <div class="h-5 w-7 bg-gray-100 rounded-xl"></div>
        </div>
        <div class="flex justify-between items-center">
          <div class="h-3 w-28 bg-gray-100 rounded-xl"></div>
          <div class="h-5 w-7 bg-gray-100 rounded-xl"></div>
        </div>
        <div class="flex justify-between items-center">
          <div class="h-3 w-40 bg-gray-100 rounded-xl"></div>
          <div class="h-5 w-7 bg-gray-100 rounded-xl"></div>
        </div>
      </div>
    </MenuItems>
  </Menu>
</template>

<script lang="ts" setup>
import { RenderFunction, computed, ref, watch } from "vue";
import { Menu, MenuItems, MenuItem } from "@headlessui/vue";
import { useI18n } from "vue-i18n";
import { useTooltip } from "@composables/tooltips";

import SimpleBadge from "@atoms/SimpleBadge";
import { nextTick } from "process";

//Types
export interface NavItem {
  key: string;
  name: string;
  icon?: RenderFunction;
  count?: number;
  section?: string;
  hasNew?: boolean;
  tooltip?: string;
}

export interface NavSection {
  id: string;
  name: string;
}

export type NavItemArray = Array<NavItem>;
export type NavSectionArray = Array<NavSection>;

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    sections?: NavSectionArray;
    items: NavItemArray;
    selected: NavItem;
    loading?: boolean;
  }>(),
  {
    sections: () => [] as NavSectionArray,
    items: () => [] as NavItemArray,
    selected: () => ({} as NavItem),
  }
);

const itemRefs = ref({});
const assignToRefs = (el, key: string) => (itemRefs.value[key] = el);

watch(props.items, () => {
  nextTick(() => {
    props.items.forEach((i) => {
      if (i.tooltip) useTooltip(itemRefs.value[i.key], ref(i.tooltip), { placement: "right" });
    });
  });
});

//Emits
const emit = defineEmits<{
  (e: "update:selected", selected: NavItem): void;
}>();

const updateSelected = (selected: NavItem) => {
  emit("update:selected", selected);
};

const navSections = computed<NavSectionArray>(() => {
  return [{ id: "", name: "" }, ...props.sections] as NavSectionArray;
});

const itemsBySection = (sectionId: string): NavItemArray => {
  if (!sectionId) return props.items.filter((i) => i.section === undefined) as NavItemArray;
  else return props.items.filter((i) => i.section === sectionId) as NavItemArray;
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "new": "Nuevos"
  },
  "pt": {
    "new": "Novos"
  }
}
</i18n>
