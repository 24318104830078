<template>
  <div class="space-y-6 p-6">
    <FormSwitch
      :model-value="monitorOpenings"
      :label="t('monitorOpenings.title')"
      :description="monitorOpenings ? t('monitorOpenings.descriptionActive') : t('monitorOpenings.description')"
      reverse
      @update:model-value="updateMonitorOpenings"
    />
    <FormSwitch
      :model-value="monitorClicks"
      :label="t('monitorClicks.title')"
      :description="monitorClicks ? t('monitorClicks.descriptionActive') : t('monitorClicks.description')"
      reverse
      @update:model-value="updateMonitorClicks"
    />
    <AlertBox v-if="!monitorOpenings || !monitorClicks" theme="warning" class="mt-2">
      <span class="block">
        {{ t("alertDisableOpeningsOrClicks.title") }}
      </span>
      <span class="block">
        {{ t("alertDisableOpeningsOrClicks.description") }}
      </span>
    </AlertBox>
    <div class="space-y-2">
      <FormSwitch
        :model-value="trackAnalytics"
        :label="t('trackAnalytics.title')"
        :description="trackAnalytics ? t('trackAnalytics.descriptionActive') : t('trackAnalytics.description')"
        reverse
        @update:model-value="updateTrackAnalytics"
      >
        <template #other>
          <div v-if="trackAnalytics" class="mt-2 pr-4 xl:mr-72 2xl:mr-96">
            <FormInput
              :model-value="campaignName"
              :placeholder="t('trackAnalytics.placeholder')"
              :hint="t('trackAnalytics.label')"
              @update:model-value="updateCampaignName"
            ></FormInput>
          </div>
        </template>
      </FormSwitch>
      <!-- <div v-if="trackAnalytics" class="ml-20 pr-4 xl:mr-72 2xl:mr-96">
        <FormInput
          :model-value="campaignName"
          :placeholder="t('trackAnalytics.placeholder')"
          :hint="t('trackAnalytics.label')"
          @update:model-value="updateCampaignName"
        ></FormInput>
      </div> -->
    </div>
  </div>
</template>
<script lang="ts" setup>
//Components
import FormSwitch from "@molecules/FormSwitch.vue";
import FormInput from "@molecules/FormInput.vue";
import AlertBox from "@atoms/AlertBox.vue";

//I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

// Props
withDefaults(
  defineProps<{
    monitorOpenings: boolean;
    monitorClicks: boolean;
    trackAnalytics: boolean;
    campaignName: string;
  }>(),
  {}
);

// Emits
const emit = defineEmits<{
  (e: "update:monitorOpenings", value: boolean): void;
  (e: "update:monitorClicks", value: boolean): void;
  (e: "update:trackAnalytics", value: boolean): void;
  (e: "update:campaignName", value: string): void;
}>();

const updateMonitorOpenings = (value: boolean) => emit("update:monitorOpenings", value);
const updateMonitorClicks = (value: boolean) => emit("update:monitorClicks", value);
const updateTrackAnalytics = (value: boolean) => emit("update:trackAnalytics", value);
const updateCampaignName = (value: string) => emit("update:campaignName", value);

//State
</script>

<i18n lang="jsonc">
{
  "es": {
    "monitorOpenings": {
      "title": "Monitorear aperturas",
      "description": "Habilita esta opción para medir las aperturas de tus envíos.",
      "descriptionActive": "Podrás hacer un seguimiento de los contactos que abren tus envíos."
    },
    "monitorClicks": {
      "title": "Monitorear clicks",
      "description": "Habilita esta opción para medir los clicks sobre los enlaces.",
      "descriptionActive": "Podrás hacer un seguimiento de los contactos que hacen click sobre los enlaces."
    },
    "trackAnalytics": {
      "title": "Etiquetar para Google Analytics",
      "description": "Habilita esta opción para vincular este email con tus reportes de Google Analytics.",
      "descriptionActive": "Podrás identificar el tráfico que llega a tu sitio proveniente de este email.",
      "placeholder": "Puedes personalizar aquí el nombre de la campaña de analytics.",
      "label": "Por defecto usaremos el nombre del envío para identificar las visitas que lleguen a tu sitio."
    },
    "alertDisableOpeningsOrClicks": {
      "title": "Ten en cuenta que al desactivar el monitoreo de aperturas o clicks también se afectará la medición de las conversiones.",
      "description": "No podrás medir las ventas que se realicen en tu tienda y hayan sido asistidas por este email."
    }
  },
  "pt": {
    "monitorOpenings": {
      "title": "Monitorar aberturas",
      "description": "Habilite esta opção para medir as aberturas dos seus envios.",
      "descriptionActive": "Você poderá identificar quais contatos abriram seu envio."
    },
    "monitorClicks": {
      "title": "Monitorar cliques",
      "description": "Habilite esta opção para medir os cliques nos links.",
      "descriptionActive": "Você poderá identificar quais contatos clicaram nos links do seu envio."
    },
    "trackAnalytics": {
      "title": "Etiquetar para Google Analytics",
      "description": "Habilite esta opção para vincular este envio à sua conta do Google Analytics.",
      "descriptionActive": "Você poderá identificar o tráfego que chega ao seu site proveniente deste email.",
      "placeholder": "Personalize aqui o nome da campanha para o analytics.",
      "label": "De forma predeterminada, usaremos o nome do envio para identificar as visitas que chegam ao seu site."
    },
    "alertDisableOpeningsOrClicks": {
      "title": "Tenha em mente que ao desativar o monitoramento de aberturas ou cliques também afetará a medição das conversões.",
      "description": "Você não poderá medir as vendas realizadas em sua loja que tenham sido assistidas por este e-mail."
    }
  }
}
</i18n>
