<!--
TODO
- corregir en mobile
- sacar div de botones si no tiene botonos (slot vacio)
-->
<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog
      as="div"
      static
      :open="open"
      class="fixed z-10 inset-0 overflow-y-auto"
      style="z-index: 2000"
      @close="clickOutside"
    >
      <div class="flex items-end justify-center min-h-screen px-4 -mt-4 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-max sm:w-full sm:p-8"
            :class="withoutOverflow ? '' : 'overflow-hidden'"
          >
            <div v-if="showCloseButton" class="block absolute top-0 right-0 pt-8 pr-8">
              <button
                tabindex="-1"
                type="button"
                class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-300"
                @click="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div class="text-left sm:mt-0">
              <div class="flex space-x-4 items-center" :class="{ 'mb-8': !!title || $slots.title }">
                <DialogTitle
                  v-if="title || $slots.title"
                  as="h3"
                  class="m-0 text-lg font-semibold leading-6 text-gray-600"
                  :class="showCloseButton && 'pr-4'"
                >
                  <slot name="title">{{ title }}</slot>
                </DialogTitle>
                <TitleIcons v-if="helpId" :help-id="helpId" />
              </div>

              <div class="space-y-6">
                <slot></slot>
              </div>
            </div>

            <div v-if="$slots.buttons" class="relative flex flex-col mt-6 gap-4 sm:flex-row-reverse">
              <slot name="buttons"></slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { useNotifications } from "@composables/notifications";
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import TitleIcons from "@molecules/TitleIcons.vue";

export default {
  name: "ModalDialog",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    XIcon,
    TitleIcons,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    withoutOverflow: {
      type: Boolean,
      default: false,
    },
    helpId: {
      type: String,
      default: undefined,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { isEmpty: noToasts } = useNotifications();

    const clickOutside = () => {
      // los clicks en las toasts hacen cerrar el modal, evito eso
      if (noToasts.value) emit("close");
    };

    return { clickOutside };
  },
};
</script>
