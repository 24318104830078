<template>
  <button
    class="group grid w-64 grid-cols-1 place-content-start content-between overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 text-left outline-none transition-all duration-100 hover:bg-gray-50 hover:shadow focus:outline-none focus:ring-2 focus:ring-sky-200 focus:ring-offset-2 sm:p-6"
    :class="!disabled && 'cursor-pointer  hover:border-sky-200'"
    :disabled="disabled"
    @click="emitValue"
  >
    <div class="drop-shadow-md">
      <span
        :class="
          disabled
            ? 'bg-gray-100 text-gray-400'
            : 'bg-sky-100 text-sky-400 group-hover:bg-sky-400 group-hover:text-white group-focus:bg-sky-400 group-focus:text-white'
        "
        class="inline-flex h-12 w-12 rounded-lg p-3 ring-0 transition-all duration-100"
      >
        <component :is="icon" class="h-6 w-6" aria-hidden="true" />
      </span>
    </div>
    <div v-if="disabled" class="absolute pl-8 pt-8 drop-shadow-md">
      <span class="inline-flex h-6 w-6 rounded-full bg-sky-100 p-1 ring-0">
        <LockClosedIcon class="h-4 w-4 text-sky-400" />
      </span>
    </div>
    <div class="mt-4">
      <div class="text-lg font-medium leading-6 text-gray-400" :class="!disabled && 'text-sky-400'">
        <span>{{ title }} </span>
      </div>
      <div class="mt-2 text-sm font-normal leading-5 text-gray-400">
        <span>{{ description }}</span>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import type { Component } from "vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;
    disabled?: boolean;
    icon: Component;
  }>(),
  {
    title: "",
    description: "",
    disabled: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "click"): void;
}>();

const emitValue = () => {
  if (!props.disabled) emit("click");
};
</script>
