<template>
  <PageTemplate
    :permissions="['campaigns:list']"
    :title="t('title')"
    :tabs="tabs"
    :selected="selectedTab"
    :links="{
      es: {
        helpId: 6840772,
      },
      pt: {
        helpId: 6840772,
      },
      en: {
        helpId: 6840772,
      },
    }"
    @update:selected="updateSelectedTab"
  >
    <template #options>
      <div class="flex space-x-4">
        <PermissionsButton theme="green-lime" :permissions="['campaigns:create']" @click="openCampaignSelector">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createButton") }}
        </PermissionsButton>
        <CampaignSelector ref="campaignSelectorRef" @create="goToNewCampaign" />
        <DropDownButton
          theme="black"
          :navigation="options"
          position="bottom-end"
          :auto-placements="['bottom-end']"
          @update:selected="HandleMenuOptionSelection"
        />
      </div>
    </template>
    <template #content>
      <FiltersPanel v-show="!firstLoad && (campaigns.length > 0 || selectedSearch.length > 0 || loadingTable)">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div class="flex space-x-2">
            <div class="my-auto">
              <span v-show="!loadingTable" class="text-sm text-gray-500">
                {{ t("listsCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount }) }}
              </span>
              <span v-show="loadingTable" class="animate-pulse rounded bg-gray-100 px-12 text-sm" />
            </div>

            <ToggleButtons
              :model-value="selectedToggle"
              class="mr-4"
              :buttons="[
                {
                  id: 'grid',
                  label: t('toggle.grid'),
                  icon: ViewGridIcon,
                },
                {
                  id: 'table',
                  label: t('toggle.table'),
                  icon: ViewListIcon,
                },
              ]"
              @update:modelValue="toggleTab"
            />
            <CheckboxListButton
              v-show="selectedToggle === 'table' && ['all', 'sent'].includes(selectedTab.key)"
              v-model:selected="selectedColumns"
              :label="t('columnsCheckBoxListButton')"
              :tooltip="t('columnsButton')"
              :items="columnItems"
            />
            <RadioListButton
              v-show="selectedToggle === 'grid' && ['all', 'sent'].includes(selectedTab.key)"
              :selected="selectedSortBy"
              :label="t('sortByListButton')"
              :tooltip="t('sortByButton')"
              :items="sortableColumns"
              @update:selected="updateSortBy"
            />
          </div>
        </div>
      </FiltersPanel>
    </template>
    <template #all>
      <div v-show="!loadingTable && campaigns.length > 0" class="h-full">
        <CampaignsComponent
          v-model:campaigns="campaigns"
          v-model:total-count="totalCount"
          :sort-by="sortBy"
          :render="selectedToggle"
          :columns="columns"
          :selected-columns="selectedColumns"
          :skeleton="skeleton"
          :loading="loadingCampaigns"
          @update:sort-by="setSortBy"
          @fetchCampaigns="fetchNextCampaigns"
          @select-tag-search="selectTagSearch"
        />
      </div>
      <EmptyState
        v-show="!loadingTable && selectedSearch.length === 0 && campaigns.length === 0"
        :title="t('emptyStateAll.title')"
        :article-id="6840772"
        :article-text="t('emptyStateAll.moreAbout')"
        :points="[t('emptyStateAll.first'), t('emptyStateAll.second'), t('emptyStateAll.third')]"
      >
        <template #image>
          <EmptyStateImage v-show="lgBp" />
        </template>
      </EmptyState>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && campaigns.length === 0"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
    <template #sent>
      <div v-show="!loadingTable && campaigns.length > 0" class="h-full">
        <CampaignsComponent
          v-model:campaigns="campaigns"
          v-model:total-count="totalCount"
          :sort-by="sortBy"
          :render="selectedToggle"
          :columns="columns"
          :selected-columns="selectedColumns"
          :skeleton="skeleton"
          :loading="loadingCampaigns"
          @update:sort-by="setSortBy"
          @fetchCampaigns="fetchNextCampaigns"
          @select-tag-search="selectTagSearch"
        />
      </div>
      <EmptyStateSimple
        v-show="!loadingTable && selectedSearch.length === 0 && campaigns.length === 0"
        :title="t('emptyStateSent.title')"
        :message="t('emptyStateSent.message')"
        :article-id="6840772"
        :article-text="t('emptyStateSent.cleanSearch')"
      >
        <template #icon>
          <MailIcon class="h-16 w-16 stroke-1 text-sky-400" />
        </template>
      </EmptyStateSimple>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && campaigns.length === 0"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
    <template #scheduled>
      <div v-show="!loadingTable && campaigns.length > 0" class="h-full">
        <CampaignsComponent
          v-model:campaigns="campaigns"
          v-model:total-count="totalCount"
          :sort-by="sortBy"
          :render="selectedToggle"
          :columns="columns"
          :selected-columns="selectedColumns"
          :skeleton="skeleton"
          :loading="loadingCampaigns"
          @update:sort-by="setSortBy"
          @fetchCampaigns="fetchNextCampaigns"
          @select-tag-search="selectTagSearch"
        />
      </div>
      <EmptyStateSimple
        v-show="!loadingTable && selectedSearch.length === 0 && campaigns.length === 0"
        :title="t('emptyStateScheduled.title')"
        :message="t('emptyStateScheduled.message')"
        :article-id="6840772"
        :article-text="t('emptyStateScheduled.cleanSearch')"
      >
        <template #icon>
          <MailIcon class="h-16 w-16 stroke-1 text-sky-400" />
        </template>
      </EmptyStateSimple>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && campaigns.length === 0"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
    <template #draft>
      <div v-show="!loadingTable && campaigns.length > 0" class="h-full">
        <CampaignsComponent
          v-model:campaigns="campaigns"
          v-model:total-count="totalCount"
          :sort-by="sortBy"
          :render="selectedToggle"
          :columns="columns"
          :selected-columns="selectedColumns"
          :skeleton="skeleton"
          :loading="loadingCampaigns"
          @update:sort-by="setSortBy"
          @fetchCampaigns="fetchNextCampaigns"
          @select-tag-search="selectTagSearch"
        />
      </div>
      <EmptyStateSimple
        v-show="!loadingTable && selectedSearch.length === 0 && campaigns.length === 0"
        :title="t('emptyStateDraft.title')"
        :message="t('emptyStateDraft.message')"
        :article-id="6840772"
        :article-text="t('emptyStateDraft.cleanSearch')"
      >
        <template #icon>
          <MailIcon class="h-16 w-16 stroke-1 text-sky-400" />
        </template>
      </EmptyStateSimple>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && campaigns.length === 0"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
  </PageTemplate>
  <ConfirmationModal
    id="exportAlertModal"
    v-bind="exportAlertModal"
    @accept="exportCampaigns"
    @cancel="closeExportAlertModal"
  >
    <template #acceptButtonLeading>
      <DownloadIcon class="h-5 w-5" />
    </template>
  </ConfirmationModal>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed, toRaw, reactive } from "vue";

import { columnsToList, getTabs, getColumns, getOptions } from "./campaigns.data";
import type { CampaignsTabs, ColumnsIds, OptionsKeys } from "./campaigns.types";

// Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyStateImage from "./EmptyStateImage.vue";
import EmptyState from "@molecules/EmptyState/EmptyState.vue";
import EmptyStateSimple from "@molecules/EmptyState/EmptyStateSimple.vue";

import CampaignsComponent from "./Campaigns.vue";
import PageTemplate from "@templates/PageTemplate";
import FiltersPanel from "@templates/FiltersPanel";
import CampaignSelector from "@organisms/Campaigns/CampaignSelector/CampaignSelector.vue";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText, SelectedValueTag, SelectedValue } from "@molecules/FilterInput";
import type { Columns, Column, SortColumn, SortingModes } from "@molecules/Table";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import CheckboxListButton from "@molecules/CheckboxListButton";
import type { Items as CheckboxListItem } from "@molecules/CheckboxListButton";
import RadioListButton from "@molecules/RadioListButton";
import type { Item as RadioItem, Items as RadioItems } from "@molecules/RadioListButton";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import { MailIcon as MailIconSolid, PencilIcon, PlusIcon, TagIcon, ArchiveIcon } from "@heroicons/vue/solid";
import { ViewGridIcon, ViewListIcon, DownloadIcon, MailIcon } from "@heroicons/vue/outline";

// Composable
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useBreakpoints } from "@composables/breakpoints";
import { useNotifications } from "@composables/notifications";

// Application
import { useLocalStorageApp, useRouterApp } from "@application";

// Utils
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";
import { useDebounceFn } from "@vueuse/core";

// Store
import { useNotificationStore } from "@store";

// Domain
import type { Tag } from "@domain/tag";
import type { FilterData } from "@domain/filters";
import type { Item, Items } from "@templates/PageTemplate";
import type { Campaigns, ListColumns } from "@/vue/types/campaigns";
import type { ListColumn, CampaignTypes, CampaignStates } from "@domain/campaigns";
import type { DataItems } from "@domain/data";
import { getPrimaryHighlightList } from "@domain/filters";

// Service
import { useCampaigns } from "@api/modules/campaigns/campaigns";

// I18n
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();
const campaignsAPI = useCampaigns();
const trackingEventsService = useTrackingEvents();
const { xxlBp, xlBp, lgBp, mdBp } = useBreakpoints();
const localStorageApp = useLocalStorageApp();
const routerApp = useRouterApp();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    initialTab?: CampaignsTabs;
  }>(),
  {
    initialTab: undefined,
  },
);

const emit = defineEmits<{
  changeRoute: [route: string];
  changeNavigation: [route: string];
}>();

const tabSelectedSaved = localStorageApp.get<"grid" | "table">({ id: "campaigns_tab" });
const selectedToggle = ref<"grid" | "table">(tabSelectedSaved ?? "grid");
const toggleTab = () => {
  selectedToggle.value = selectedToggle.value === "grid" ? "table" : "grid";
  localStorageApp.save<"grid" | "table">({ id: "campaigns_tab", value: selectedToggle.value });
};

const tabs: Items<CampaignsTabs> = getTabs();
const pathName = location.pathname;
const tab = tabs.find((t) => pathName.includes(t.key));
const selectedTab = ref<Item<CampaignsTabs>>(tab ?? tabs.find((tab) => tab.key === props.initialTab) ?? tabs[0]);
const updateSelectedTab = (newTab: Item<CampaignsTabs>) => {
  selectedTab.value = newTab;
  selectedSearch.value = [];
  emit("changeRoute", newTab.key);
};

const columnsOrder = ref<ListColumns>([
  {
    id: "sent",
    name: t("columns.sent"),
  },
  {
    id: "openedP",
    name: t("columns.openedP"),
  },
  {
    id: "clickedP",
    name: t("columns.clickedP"),
  },
  {
    id: "bouncedP",
    name: t("columns.bouncedP"),
  },
  {
    id: "conversionsAmount",
    name: t("columns.conversionsAmount"),
  },
]);

const colLimits = computed(() => {
  if (xxlBp.value) return 6;
  if (xlBp.value) return 5;
  if (lgBp.value) return 4;
  if (mdBp.value) return 3;

  return 2;
});
const columns = computed<Columns<ColumnsIds | "options">>(() => {
  return getColumns(selectedColumns.value, selectedTab.value.key, colLimits.value);
});

const columnItems = computed<CheckboxListItem<ColumnsIds | "options", string, ListColumn>>(() => {
  return columnsToList(toRaw(columnsOrder.value));
});

const selectedColumns = ref<CheckboxListItem<ColumnsIds | "options", string, ListColumn>>(columnItems.value);

const sortableColumns = computed<RadioItems<ColumnsIds, string, Column<ColumnsIds>>>(() =>
  columns.value.reduce<RadioItems<ColumnsIds, string, Column<ColumnsIds>>>((sortColumns, column) => {
    if (!column.sortModes?.[0]) return sortColumns;

    sortColumns.push({
      key: column.id as ColumnsIds,
      value: column.text ?? "",
      data: column as Column<ColumnsIds>,
    });

    return sortColumns;
  }, []),
);

const savedSortBy = localStorageApp.get<SortColumn<ColumnsIds>>({ id: "campaigns_sort_by" });
const sortBy = ref<SortColumn<ColumnsIds>>(
  savedSortBy ?? {
    columnId: "launchDate",
    sort: "DESC",
  },
);

const setSortBy = (newSortBy: SortColumn<ColumnsIds>) => {
  sortBy.value = newSortBy;
  localStorageApp.save<SortColumn<ColumnsIds>>({ id: "campaigns_sort_by", value: sortBy.value });
};

const selectedSortBy = computed<RadioItem<ColumnsIds, string, Column<ColumnsIds>>>(() => {
  const selectedSortByColumn = sortableColumns.value.find((c) => c.key === sortBy.value.columnId) as RadioItem<
    ColumnsIds,
    string,
    Column<ColumnsIds>
  >;

  if (!selectedSortByColumn) return {};

  return {
    key: selectedSortByColumn.key,
    value: selectedSortByColumn.value,
    data: selectedSortByColumn.data,
  };
});

const updateSortBy = (newItem: RadioItem<ColumnsIds, string, Column>) => {
  if (!newItem.data?.id) return;

  setSortBy({
    columnId: newItem.data.id as ColumnsIds,
    sort: newItem.key as SortingModes,
  });
};

const selectedSearch = ref<SelectedValues>([]);
const cleanSearch = () => {
  selectedSearch.value = [];
};

const fetchArchived = computed(() => selectedSearch.value.some((f) => f.filterId === "archived"));
const highlightSearches = ref<DataItems>([]);

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "campaigns_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "campaigns_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "campaigns");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "campaigns");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const selectTagSearch = (tag: Tag) => {
  if (selectedSearch.value.some((s) => (s as SelectedValueTag)?.tag?.id === tag?.id)) return;
  const tagIndex = selectedSearch.value.findIndex((s) => s.filterId === "tags");

  if (tagIndex !== -1) {
    selectedSearch.value.splice(tagIndex, 1);
  }

  selectedSearch.value = [
    ...toRaw(selectedSearch.value),
    {
      filterId: "tags",
      id: tag.id,
      value: tag.id,
    },
  ];
};

const filterInputIsOpen = ref(false);
const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const tags = ref<Array<string>>([]);
const filterData = computed<FilterData>(() => ({
  filters: [
    { id: "campaigns", text: t("filters.campaigns"), icon: PencilIcon, highlightList: highlightSearches.value },
    {
      id: "tags",
      text: t("filters.tags"),
      icon: TagIcon,
      list: tags.value.map((tag) => ({
        key: tag.toString(),
        value: tag.toString(),
        content: {
          id: tag.toString(),
        },
      })),
    },
    {
      id: "type",
      text: t("filters.type"),
      icon: MailIconSolid,
      list: [
        {
          key: "SIMPLE",
          value: t("campaignType.SIMPLE"),
          content: {
            id: "SIMPLE",
          },
        },
        {
          key: "SPLIT_AB",
          value: t("campaignType.SPLIT_AB"),
          content: {
            id: "SPLIT_AB",
          },
        },
        {
          key: "SMART",
          value: t("campaignType.SMART"),
          content: {
            id: "SMART",
          },
        },
      ],
    },
    ...(selectedTab.value.key === "all"
      ? [
          {
            id: "state",
            text: t("filters.state"),
            icon: MailIconSolid,
            list: [
              {
                key: "SCHEDULED",
                value: t("campaignState.SCHEDULED"),
                content: {
                  id: "SCHEDULED",
                },
              },
              {
                key: "SENDING",
                value: t("campaignState.SENDING"),
                content: {
                  id: "SENDING",
                },
              },
              {
                key: "CANCELLED",
                value: t("campaignState.CANCELLED"),
                content: {
                  id: "CANCELLED",
                },
              },
              {
                key: "DRAFT",
                value: t("campaignState.DRAFT"),
                content: {
                  id: "DRAFT",
                },
              },
              {
                key: "SENT",
                value: t("campaignState.SENT"),
                content: {
                  id: "SENT",
                },
              },
              {
                key: "PENDING_APPROVAL",
                value: t("campaignState.PENDING_APPROVAL"),
                content: {
                  id: "PENDING_APPROVAL",
                },
              },
            ],
          },
        ]
      : []),

    ...(selectedTab.value.key === "sent"
      ? [
          {
            id: "archived",
            text: t("filters.archived"),
            badgeText: t("filters.archivedBadge"),
            icon: ArchiveIcon,
            isAction: true,
          },
        ]
      : []),
  ],
  filtersRoles: {
    primary: "campaigns",
  },
}));

const openCampaignSelector = () => {
  campaignSelectorRef.value.openModal();
};

const fetchlimit = 40;
const skeleton = ref(false);
const loadingTable = ref(false);
const firstLoad = ref(false);

const loadingCampaigns = ref(false);
const campaigns = ref<Campaigns>([]);

const totalCount = ref<number>(0);
let fetchCampaignsController = new AbortController();

const getSearch = computed<SelectedValueText | undefined>(() => {
  return selectedSearch.value.find((search) => search.filterId === "campaigns") as SelectedValueText | undefined;
});

const getTag = computed<SelectedValueText | undefined>(() => {
  return selectedSearch.value.find((search) => search.filterId === "tags") as SelectedValueText | undefined;
});

const fetchCampaigns = async () => {
  fetchCampaignsController.abort();
  fetchCampaignsController = new AbortController();

  loadingCampaigns.value = true;

  const getType = selectedSearch.value.find((search) => search.filterId === "type") as SelectedValue<
    string,
    CampaignTypes,
    string,
    {
      id: CampaignTypes;
    }
  >;

  const stateFilter = selectedSearch.value.find((search) => search.filterId === "state") as SelectedValue<
    string,
    CampaignStates,
    string,
    {
      id: CampaignStates;
    }
  >;

  const getState = () => {
    if (selectedTab.value.key === "all") {
      return stateFilter?.id;
    }

    return selectedTab.value?.key;
  };

  const resCampaigns = await campaignsAPI.get({
    sortBy: sortBy.value?.columnId,
    sortDir: sortBy.value?.sort === "DESC" ? "desc" : "asc",
    search: getSearch.value ? getSearch.value.value : undefined,
    tag: getTag.value ? getTag.value.value : undefined,
    state: getState(),
    archived: fetchArchived.value,
    type: getType?.id ? getType.id : undefined,
    limit: fetchlimit,
  });

  if (fetchCampaignsController.signal.aborted) return;

  if (resCampaigns.isNewRequest) {
    campaigns.value = [...resCampaigns.value];
    totalCount.value = resCampaigns.total ?? 0;

    totalCount.value = resCampaigns.total ?? 0;

    setTimeout(() => {
      loadingCampaigns.value = false;
      loadingTable.value = false;

      skeleton.value = false;
    }, 500);

    return;
  }

  campaigns.value = [...toRaw(campaigns.value), ...resCampaigns.value];
  totalCount.value = resCampaigns.total ? resCampaigns.total : totalCount.value ?? 0;

  setTimeout(() => {
    loadingCampaigns.value = false;
    loadingTable.value = false;

    skeleton.value = false;
  }, 500);
};

const fetchCampaignsDebounce = useDebounceFn(async () => {
  await fetchCampaigns();
}, 500);

const fetchNextCampaigns = async () => {
  if (campaigns.value.length !== 0 && campaigns.value.length >= totalCount.value) return;
  loadingCampaigns.value = true;
  await fetchCampaigns();
};

const fetchTags = async () => {
  const list = await campaignsAPI.tags();

  tags.value = list;
};

const campaignSelectorRef = ref();
const goToNewCampaign = ({
  campaignType,
  useEddie,
}: {
  campaignType: "simple" | "splitab" | "smart";
  useEddie: boolean;
}) => {
  campaignSelectorRef.value.closeModal();

  const actions = {
    simple: {
      eventSuffix: "SIMPLE",
      route: useEddie ? "/campaigns/new" : "/campaigns/new/simple",
    },
    splitab: {
      eventSuffix: "SPLIT_AB",
      route: useEddie ? "/campaigns/newsplitab" : "/campaigns/new/splitab",
    },
    smart: {
      eventSuffix: "SMART",
      route: useEddie ? "/campaigns/newsmart" : "/campaigns/new/smart",
    },
  };
  const action = actions[campaignType];

  if (action) {
    trackingEventsService.dispatchAll({
      name: "APP_CAMPAIGN_CREATE",
      data: { campaign_type: campaignType },
    });
    trackingEventsService.dispatchAll({
      name: `APP_CAMPAIGN_CREATE_${action.eventSuffix}`,
    });

    routerApp.navigate({
      path: action.route,
    });
  }
};

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    campaigns: "q",
    tags: "filter.tags",
    state: "filter.state",
    type: "filter.type",
    archived: "filter.archived",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

const exportAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("exportAlert.confirmationTitle"),
  message: t("exportAlert.confirmationMessage"),
  acceptText: t("exportAlert.confirmButton"),
  cancelText: t("exportAlert.cancelButton"),
  acceptLoading: false,
  severity: "info",
});

const closeExportAlertModal = () => {
  exportAlertModal.open = false;
};

const openExportAlertModal = () => {
  exportAlertModal.open = true;
};

const exportCampaigns = async () => {
  try {
    await campaignsAPI.export({
      query: getSearch.value ? getSearch.value.value : undefined,
      tags: getTag.value ? [getTag.value.value] : undefined,
    });
    notify({
      title: t("notifications.export.title"),
      text: t("notifications.export.text"),
      theme: "info",
    });
    notificationStore.fetchTasks();
  } catch {
    // No Handler
  } finally {
    closeExportAlertModal();
  }
};

const options = getOptions();
const handleMenuOptionSelection: Record<OptionsKeys, () => void> = {
  export: () => {
    openExportAlertModal();
  },
};
const HandleMenuOptionSelection = async (option: DropDownDataItem) => await handleMenuOptionSelection[option.key]?.();

watch([selectedTab], () => {
  selectedSearch.value = selectedSearch.value.filter((s) => s.filterId !== "archived");
});

watch([selectedSearch, selectedTab, fetchArchived], async () => {
  loadingTable.value = true;
  await fetchCampaignsDebounce();
});

watch([sortBy], async () => {
  skeleton.value = true;

  await fetchCampaignsDebounce();
});

watch([selectedSearch, sortBy, fetchArchived], async () => {
  cleanQueryParams();
  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    if (paramName === "filter.archived") {
      updateQueryParam({ key: paramName, value: "true" });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  if (sortBy.value) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  }
});

const searchFilterText = (filterId: string, id: string) => {
  if (filterId === "archived") {
    return t(`filters.archivedBadge`);
  }
  if (filterId === "type") {
    return t(`campaignType.${id}`);
  }
  if (filterId === "state") {
    return t(`campaignState.${id}`);
  }
  return id;
};

const openCampaignCreate = ref(false);
onMounted(async () => {
  firstLoad.value = true;
  loadingTable.value = true;

  await fetchTags();

  const queryParams = getQueryParams();

  openCampaignCreate.value = !!queryParams.get("campaign_create");

  if (openCampaignCreate.value) {
    openCampaignSelector();
  }

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      id: value,
      value: searchFilterText(filterId, value) ?? "",
    });
  });

  const sortParams = queryParams?.get("sort")?.split(" ");

  if (!sortParams) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  } else {
    sortBy.value = {
      columnId: sortParams?.[0] as ColumnsIds,
      sort: sortParams?.[1] as SortingModes,
    };
  }

  insertRecommmendedNames();
  loadingCampaigns.value = true;
  await fetchCampaigns();
  loadingTable.value = false;
  firstLoad.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Campañas",
    "placeholderSearch": "Buscar campañas",
    "createButton": "Crear campaña",
    "columnsCheckBoxListButton": "Mostrar columnas",
    "columnsButton": "Columnas",
    "sortByListButton": "Ordenar por",
    "sortByButton": "Ordenamiento",
    "listsCount": "0 campañas | 1 campaña | {count} campañas",
    "emptyStateAll": {
      "title": "Envía campañas impactantes y conquista tu audiencia",
      "moreAbout": "Saber más sobre campañas",
      "first": "Promociona nuevos productos y ofertas, o comunica descuentos para fechas especiales.",
      "second": "Planifica y programa tu estrategia de envíos, mide los resultados y vuelve a enviar.",
      "third": "Inserta productos tomando las imágenes, precios y detalles desde tu tienda muy fácilmente."
    },
    "emptyStateSent": {
      "title": "Sin campañas enviadas",
      "message": "Cuando comiences a enviar campañas, se mostraran aquí.",
      "cleanSearch": "Aprender más sobre campañas"
    },
    "emptyStateScheduled": {
      "title": "Sin campañas pendientes",
      "message": "Cuando programes campañas para enviar a futuro, se mostrarán aquí.",
      "cleanSearch": "Aprender más sobre campañas"
    },
    "emptyStateDraft": {
      "title": "Sin campañas borradores",
      "message": "Aquí verás las campañas en las que estás trabajando.",
      "cleanSearch": "Aprender más sobre campañas"
    },
    "columns": {
      "name": "Nombre",
      "state": "Estado",
      "launchDate": "Envío",
      "date": "Fecha",
      "sent": "Envíos",
      "openedP": "Aperturas",
      "clickedP": "Clicks",
      "bouncedP": "Rebotes",
      "conversionsAmount": "Ventas"
    },
    "notifications": {
      "export": {
        "title": "Exportando campañas",
        "text": "Las campañas se están exportando. Se notificará cuando los resultados estén listos para descargar."
      }
    },
    "filters": {
      "campaigns": "Nombre",
      "tags": "Etiquetas",
      "type": "Tipo",
      "state": "Estado",
      "archived": "Ver archivadas",
      "archivedBadge": "Archivadas"
    },
    "exportAlert": {
      "confirmationTitle": "Exportar campañas",
      "confirmationMessage": "Las campañas se exportarán utilizando los filtros actuales. Se notificará cuando los resultados estén listos para descargar.",
      "confirmButton": "Exportar",
      "cancelButton": "Cancelar"
    },
    "options": {
      "export": "Exportar todas"
    },
    "tabs": {
      "all": "Todas",
      "sent": "Enviadas",
      "pending": "Programadas",
      "drafts": "Borradores"
    },
    "toggle": {
      "grid": "Grilla",
      "table": "Tabla"
    },
    "campaignType": {
      "SIMPLE": "Simple",
      "SPLIT_AB": "Test A/B",
      "SMART": "Inteligente"
    },
    "campaignState": {
      "SCHEDULED": "Pendientes",
      "SENDING": "Enviando",
      "CANCELLED": "Canceladas",
      "DRAFT": "Borradores",
      "SENT": "Enviadas",
      "PENDING_APPROVAL": "En revisión"
    }
  },
  "pt": {
    "title": "Campanhas",
    "placeholderSearch": "Pesquisar campanhas",
    "createButton": "Criar campanha",
    "columnsCheckBoxListButton": "Mostrar colunas",
    "columnsButton": "Colunas",
    "sortByListButton": "Ordenar por",
    "sortByButton": "Ordenar",
    "listsCount": "0 campanhas | 1 campanha | {count} campanhas",
    "emptyStateAll": {
      "title": "Envie campanhas impactantes e conquiste sua audiência",
      "moreAbout": "Aprenda mais sobre campanhas",
      "first": "Promova novos produtos e ofertas ou comunique descontos para datas especiais.",
      "second": "Planeje e programe sua estratégia de envios, meça os resultados e reenvie quando necessário.",
      "third": "Insira produtos facilmente utilizando imagens, preços e detalhes diretamente da sua loja."
    },
    "emptyStateSent": {
      "title": "Sem campanhas enviadas",
      "message": "Quando começar a enviar campanhas, elas serão exibidas aqui.",
      "cleanSearch": "Saiba mais sobre campanhas"
    },
    "emptyStateScheduled": {
      "title": "Sem campanhas agendadas",
      "message": "Quando programar campanhas para enviar no futuro, elas serão exibidas aqui.",
      "cleanSearch": "Saiba mais sobre campanhas"
    },
    "emptyStateDraft": {
      "title": "Sem campanhas rascunho",
      "message": "Aqui você verá as campanhas em que está trabalhando.",
      "cleanSearch": "Saiba mais sobre campanhas"
    },
    "columns": {
      "name": "Nome",
      "state": "Estado",
      "launchDate": "Data de Envio",
      "date": "Data",
      "sent": "Envios",
      "openedP": "Aberturas",
      "clickedP": "Cliques",
      "bouncedP": "Rejeições",
      "conversionsAmount": "Vendas"
    },
    "notifications": {
      "export": {
        "title": "Exportando campanhas",
        "text": "As campanhas estão sendo exportadas. Você será notificado quando os resultados estiverem prontos para download."
      }
    },
    "filters": {
      "campaigns": "Nome",
      "tags": "Etiquetas",
      "type": "Tipo",
      "state": "Estado",
      "archived": "Ver arquivadas",
      "archivedBadge": "Arquivadas"
    },
    "exportAlert": {
      "confirmationTitle": "Exportar campanhas",
      "confirmationMessage": "As campanhas serão exportadas com os filtros atuais. Você será notificado quando os resultados estiverem prontos para download.",
      "confirmButton": "Exportar",
      "cancelButton": "Cancelar"
    },
    "options": {
      "export": "Exportar todas"
    },
    "tabs": {
      "all": "Todas",
      "sent": "Enviadas",
      "pending": "Programadas",
      "drafts": "Rascunhos"
    },
    "toggle": {
      "grid": "Grade",
      "table": "Tabela"
    },
    "campaignType": {
      "SIMPLE": "Simples",
      "SPLIT_AB": "Teste A/B",
      "SMART": "Inteligente"
    },
    "campaignState": {
      "SCHEDULED": "Programadas",
      "SENDING": "Enviando",
      "CANCELLED": "Canceladas",
      "DRAFT": "Rascunhos",
      "SENT": "Enviadas",
      "PENDING_APPROVAL": "Em revisão"
    }
  }
}
</i18n>
