<template>
  <Listbox as="div" class="relative" :default-value="selectedTags" multiple @update:model-value="onSelectTag">
    <ListboxButton
      tabindex="-1"
      @keydown.stop.space="() => onToggle()"
      @keydown.stop.enter="() => onToggle()"
      @click.stop.prevent="() => onToggle()"
    >
      <slot name="button-content" />
    </ListboxButton>
    <ListboxOptions
      v-show="open"
      static
      class="focus:outline-none absolute bottom-10 -left-36 z-10 mt-1.5 overflow-hidden rounded-md border border-b-0 border-gray-300 shadow-md"
      @keydown.stop.escape="() => onToggle()"
    >
      <TagsOptions
        ref="TagsSelectorMenuRef"
        :open="open"
        :selected-tags="selectedTags"
        :tags="tags"
        :allow-create="false"
        class="min-w-[20rem] max-w-[20rem] divide-y bg-white"
        @toggle-tag="onToggleTag"
        @toggle="onToggle"
      />
    </ListboxOptions>
    <div v-show="open" class="absolute bottom-8 left-0 z-10 inline-block w-16 overflow-hidden">
      <div class="h-5 w-5 origin-top-left -rotate-45 transform bg-white shadow-md"></div>
    </div>
  </Listbox>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/vue";
import TagsOptions from "@organisms/Tags/TagsDropDown/components/TagsOptions.vue";

// Utils
import { onClickOutside } from "@vueuse/core";

// Types
import type { Tags, Tag } from "@/vue/types/tag";

// Props
interface Props {
  selectedTags: Tags;
  tags: Tags;
  open: boolean;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selectedTags: () => [],
  tags: () => [],
  open: false,
  loading: false,
});

// Emits
const emit = defineEmits<{
  (e: "toggleTag", tag: Tag): void;
  (e: "open"): void;
  (e: "close"): void;
}>();

const onToggleTag = (tag: Tag) => emit("toggleTag", tag);

const TagsSelectorMenuRef = ref();
onClickOutside(TagsSelectorMenuRef, () => {
  emit("close");
});

const onToggle = () => {
  if (props.open) {
    emit("close");
    return;
  }
  emit("open");
};

const onSelectTag = (values: [Tag]) => {
  const value = values[0];
  emit("toggleTag", value);
};
</script>
