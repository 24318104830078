import { ListView } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";
import notificationsListTpl from "../templates/list.hbs";
import { useSessionStore } from "@store";

export default ListView.extend({
  module: "notifications",
  templates: {
    initial: notificationsListTpl,
  },
  gender: "F",
  renderArea: ".notifications",
  useLoader: false,
  hasFilters: false,
  events: {
    "click [data-action=open]": "open",
    "click [data-action=read]": "read",
    "click [data-action=read-all]": "readAll",
    "click [data-action=follow-internal-link]": "followInternalLink",
  },
  query: {
    limit: 100,
  },
  firstCheck: true,

  // Runs custom actions after renderization
  afterRenderPage: function () {
    var self = this;

    // Activate tooltips
    self.$('[data-toggle="tooltip"]').tooltip();
  },

  // Opens a notification
  open: function (e) {
    var self = this,
      element = $(e.currentTarget),
      id = element.data("id"),
      data = self.collection.get(id).attributes || {},
      template = require("../templates/modals/edit.hbs"),
      html = template({
        data: data,
        lang: window.lang,
        session: app.session,
        config: window.config,
      }),
      modal = $(self.modal);
    e.preventDefault();

    // Show notification modal
    modal.html(html);
    modal.modal("show");

    // Rebind events
    self.delegateEvents(self.events);
  },

  // Read
  read: function (e) {
    var self = this,
      element = $(e.currentTarget),
      id = element.data("id"),
      item = new app.models.notifications({ id: id }),
      modal = $(self.modal);
    e.preventDefault();
    e.stopPropagation();

    // Send AJAX call
    item.read(id).success(function () {
      self.render();
      modal.modal("hide");
    });
  },

  // Read all
  readAll: function (e) {
    var self = this,
      items = self.collection.models,
      deferreds = [];
    e.preventDefault();

    // Create array of deferreds
    $.each(items, function (key, item) {
      var itemModel = new app.models.notifications({ id: item.id });
      deferreds.push(itemModel.read(itemModel.id));
    });

    // Process deferreds calls and render
    $.when.apply(null, deferreds).always(function () {
      // Refresh the list when done
      self.render();
    });
  },

  // Close the modal and follow an internal link
  followInternalLink: function (e) {
    var self = this;
    e.preventDefault();
    $(self.modal).modal("hide");
  },

  // Show a message for the last notification
  check: function () {
    var self = this,
      notification,
      diff;

    // Save a copy of the collection
    self.previousCollection = self.collection.toJSON();

    // Get notifications
    self.collection.fetch({ data: { limit: 100 } }).success(function () {
      // Render
      self.paging.results = self.collection.length;
      self.renderPage();

      // Save a copy of the current collection
      self.currentCollection = self.collection.toJSON();

      // Do not compare on the first load
      if (self.firstCheck) {
        self.firstCheck = false;
        return self;
      }

      // Compare collections and show notification
      diff = _.difference(_.pluck(self.currentCollection, "id"), _.pluck(self.previousCollection, "id"));
      if (diff.length) {
        notification = self.collection.get(diff.pop());

        const sessionStore = useSessionStore();
        sessionStore.fetchPlan();
        if (window.app.layout.shouldApplyNewLayout) return;

        self.displayMessage(
          notification.get("message"),
          5000,
          notification.get("priority") === "WARNING" ? "error" : "info",
        );
      }
    });
  },
});
