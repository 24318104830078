<template>
  <div class="relative rounded-md shadow-sm">
    <input
      ref="buttonRef"
      class="block w-full rounded-md focus:outline-none sm:text-sm"
      :class="[isValid, disabled ? 'bg-gray-100 text-gray-300' : '']"
      type="text"
      :value="modelValue"
      :disabled="disabled"
      placeholder="#a1b2c3"
      @input="emitValue"
    />
    <div class="absolute inset-y-0 right-0 flex items-center pr-3">
      <div
        v-if="!disabled"
        :style="{ backgroundColor: modelValue }"
        class="h-5 w-5 cursor-pointer rounded-full border border-solid border-gray-300"
        aria-hidden="true"
        @click="openPicker"
      ></div>
      <div
        ref="colorPickerRef"
        class="absolute right-0 top-12 z-10 box-content"
        :class="showColorPicker ? 'visible' : 'invisible'"
      >
        <ColorPicker
          v-if="!disabled"
          :key="forceRenderKey"
          class="box-content"
          theme="light"
          :color="modelValue"
          @changeColor="changeColor"
        />
      </div>
      <div v-if="disabled" class="h-5 w-5 rounded-full border border-solid border-gray-300"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { onClickOutside, onKeyStroke } from "@vueuse/core";
import { ColorPicker } from "vue-color-kit";
import { validateHTMLColorHex } from "validate-color";

import { createPopper } from "@popperjs/core";

// Props
const props = withDefaults(
  defineProps<{
    modelValue: string;
    disabled?: boolean;
  }>(),
  {
    modelValue: "",
    disabled: false
  }
);

// Emits
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

// Methods
const emitValue = (e) => {
  let value = e.target.value;
  emit("update:modelValue", value);
};

// Computed
const isValid = computed(() => {
  if (validateHTMLColorHex(props.modelValue) || props.modelValue == "") {
    return "border-gray-300 focus:ring-sky-500 focus:border-sky-500";
  } else {
    return "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
  }
});

// States
const showColorPicker = ref(false);
const colorPickerRef = ref();
const buttonRef = ref();
const lastColor = ref("");
const forceRenderKey = ref(0);

// Methods
const changeColor = (color) => {
  const hexColor = color.hex;
  if (color.rgba.a < 1) {
    emit("update:modelValue", `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`);
  } else {
    emit("update:modelValue", hexColor);
  }
};

const openPicker = () => {
  forceRenderKey.value += 1;
  showColorPicker.value = true;
};

const closePicker = () => {
  lastColor.value = props.modelValue;
  showColorPicker.value = false;
};

const formatLastColor = () => { 
  const formattedColor = "#" + props.modelValue;

  if (validateHTMLColorHex(formattedColor)) {
    lastColor.value = formattedColor;
    emit("update:modelValue", lastColor.value);
  }
};

onKeyStroke("Escape", (e) => {
  emit("update:modelValue", lastColor.value);
  closePicker();
});

onMounted(() => {
  onClickOutside(colorPickerRef, closePicker);
  createPopper(buttonRef.value, colorPickerRef.value, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParents"
        }
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top-end", "bottom-end"],
          fallbackPlacements: ["top-end", "bottom-end"],
          altBoundary: true
        }
      },
      {
        name: "offset",
        options: {
          offset: [0, 4]
        }
      }
    ]
  });
  formatLastColor();
});

watch(props, () => {
  formatLastColor();
});
</script>

<style>
/* vue-color-kit/dist/vue-color-kit.css */
.hu-color-picker {
  padding: 10px;
  background: #1d2024;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 10;
}
.hu-color-picker.light {
  background: #f7f8f9;
}
.hu-color-picker.light .color-show .sucker {
  background: #eceef0;
}
.hu-color-picker.light .color-type .name {
  background: #e7e8e9;
}
.hu-color-picker.light .color-type .value {
  color: #666;
  background: #eceef0;
}
.hu-color-picker.light .colors.history {
  border-top: 1px solid #eee;
}
.hu-color-picker canvas {
  vertical-align: top;
}
.hu-color-picker .color-set {
  display: flex;
}
.hu-color-picker .color-show {
  margin-top: 8px;
  display: flex;
}
.saturation {
  position: relative;
  cursor: pointer;
}
.saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.color-alpha {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.color-alpha .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.sucker {
  width: 30px;
  fill: #9099a4;
  background: #2e333a;
  cursor: pointer;
  transition: all 0.3s;
}
.sucker.active,
.sucker:hover {
  fill: #1593ff;
}
.hue {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}
.hue .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.colors {
  padding: 0;
  margin: 0;
}
.colors.history {
  margin-top: 10px;
  border-top: 1px solid #2e333a;
}
.colors .item {
  position: relative;
  width: 16px;
  height: 16px;
  margin: 10px 0 0 10px;
  border-radius: 3px;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
}
.colors .item:nth-child(8n + 1) {
  margin-left: 0;
}
.colors .item:hover {
  transform: scale(1.4);
}
.colors .item .alpha {
  height: 100%;
  border-radius: 4px;
}
.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.color-type {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
}
.color-type .name {
  width: 60px;
  height: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  background: #252930;
}
.color-type .value {
  flex: 1;
  height: 30px;
  min-width: 100px;
  padding: 0 12px;
  border: 0;
  color: #fff;
  background: #2e333a;
  box-sizing: border-box;
}
</style>

<style>
.hu-color-picker {
  @apply shadow-md;
}
</style>
