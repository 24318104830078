// Check if a value is contained in the array
export default function (value, collection, options) {
  if (!collection || !collection.length) {
    return false;
  }
  if (collection.indexOf(value) > -1) {
    return options.fn(this);
  }
  return options.inverse(this);
}
