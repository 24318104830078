<template>
  <ModalDialog :title="t('testSendTitle')" :open="isOpen" without-overflow @close="onClose">
    <TextParagraph> {{ t("testSendMessage") }} </TextParagraph>
    <AlertBox v-if="!senderId" theme="warning"> {{ t("alertNoSender") }}</AlertBox>
    <FormGroup
      id="testSendModal"
      :label="t('labelTextArea')"
      :hint="t('hintTextArea')"
      :error="badgesWithError ? t('invalidEmailMessage') : limitExceeded ? t('emailLimitExceeded') : ''"
    >
      <BadgesTextArea
        v-model:modelValue="value"
        class="min-h-[6rem] w-[50vw]"
        placeholder="email@test.com"
        :badges="lastEmailBadges"
        :error="limitExceeded || badgesWithError"
        :disabled="!senderId"
        @badgeClicked="onLastBadgeClicked"
        @paste="onPaste"
        @keydown="onKeyDown"
        @delete="deleteLastEmailBadges"
      />
    </FormGroup>
    <div v-if="senderId && !isEmpty(otherEmailBadges)">
      <Label class="mb-2"> {{ t("usedPreviouslyMessage") }} </Label>
      <div class="flex w-[40vw] flex-wrap">
        <SimpleBadge
          v-for="badge in otherEmailBadges"
          :key="badge.id"
          class="mb-2.5 mr-2 cursor-pointer"
          v-bind="badge"
          :model-value="'+ ' + badge.modelValue"
          :focusable="true"
          :deletable="false"
          :clickable="true"
          @keydown="onBadgeKeyDown($event, badge.id)"
          @click="onOtherBadgeClicked(badge.id)"
        />
      </div>
    </div>

    <SelectMenuLabel
      v-if="relation?.relationType === 'campaign_testab'"
      v-model:selected="selectedSubject"
      :label="t('labelSelectedSubject')"
      :data-items="subjectDataItems"
    ></SelectMenuLabel>

    <AlertBox v-if="email && senderId" theme="info">{{ t("alertEmailSelected", { email }) }}</AlertBox>
    <template #buttons>
      <Button
        theme="primary-light"
        :loading="senderId && loadingSendButton"
        :disabled="limitExceeded || badgesWithError || (value == '' && noEmailsSelected) || !senderId"
        @click="sendTest"
      >
        <template #leading><MailIcon /></template>
        {{ t("sendButton") }}
      </Button>
      <Button theme="white" @click="onClose"> {{ t("cancelButton") }} </Button>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from "vue";
//Components
import ModalDialog from "@molecules/ModalDialog.vue";
import SelectMenuLabel from "@/vue/components/molecules/SelectMenuLabel.vue";
import BadgesTextArea, { BadgeID } from "@molecules/BadgesTextarea/BadgesTextArea.vue";
import FormGroup from "@molecules/FormGroup.vue";
import AlertBox from "@atoms/AlertBox.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import Button from "@atoms/SimpleButton.vue";
import Label from "@atoms/Label.vue";
import TextParagraph from "@atoms/TextParagraph.vue";

//Icons
import { MailIcon } from "@heroicons/vue/solid";

//I18n
import { useI18n } from "vue-i18n";

//Utils
import { useNotifications } from "@composables/notifications";
import { isEmpty } from "lodash";
import { email as emailValidator } from "@vuelidate/validators";

//API
import { useEmailTemplate } from "@/vue/api/modules/templates/templates";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

//Types
import type { Relation } from "../TemplateEditor/TemplateEditor.vue";
import type { DataItem, DataItems } from "@/vue/components/molecules/SelectMenuLabel.vue";
export interface BadgeIDCustom extends BadgeID {
  custom?: boolean;
  error?: boolean;
}

export type BadgesCustom = Array<BadgeIDCustom>;

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    tplId: string;
    subjects?: Array<string> | undefined;
    relation?: Relation | undefined;
    senderId?: string;
    contactId?: string | number;
    email?: string;
    utmCampaignName?: string;
    useAutoSave?: boolean;
  }>(),
  {
    senderId: undefined,
    isOpen: false,
    contactId: undefined,
    relation: undefined,
    subjects: undefined,
    email: undefined,
    utmCampaignName: undefined,
    useAutoSave: false,
  }
);
//Emits
const emit = defineEmits<{
  (e: "update:isOpen", value: boolean): void;
  (e: "close"): void;
}>();

const onClose = () => {
  emit("update:isOpen", false);
  emit("close");
};

const TemplatesAPI = useEmailTemplate();
const trackingEventsService = useTrackingEvents();
const { notify } = useNotifications();

//Const
const separators = [",", ";", "\n", " "];
const EMAILS_LIMIT = 15;

//State
const value = ref<string>("");
const clearValue = () => (value.value = "");
const numberOfBadges = ref(0);

const lastEmailBadges = ref<BadgesCustom>([]);
const otherEmailBadges = ref<BadgesCustom>([]);

const selectedTestEmails = computed<Array<string>>(
  () => lastEmailBadges.value.map((badge) => badge.modelValue) as Array<string>
);
const noEmailsSelected = computed(() => isEmpty(selectedTestEmails.value));
const badgesWithError = computed(() => lastEmailBadges.value.some((badge) => badge.error));
const limitExceeded = computed(() => lastEmailBadges.value.length > EMAILS_LIMIT);

const selectedSubject = ref<DataItem>();
const subjectDataItems = computed<DataItems>(() =>
  props.subjects ? props.subjects.map((subject) => ({ key: subject, value: subject } as DataItem)) : []
);

const transferBadge = (badgeListFrom: Ref<BadgesCustom>, badgeListTo: Ref<BadgesCustom>, badgeId: number | string) => {
  const badgeFound = badgeListFrom.value.find((badge) => badge.id === badgeId);

  if (!badgeFound || badgeFound.custom) return;

  badgeListFrom.value = badgeListFrom.value.filter((badge) => badge.id !== badgeId);
  badgeListTo.value.push(badgeFound);
};

const onLastBadgeClicked = (id: number | string) => {
  transferBadge(lastEmailBadges, otherEmailBadges, id);
};

const onOtherBadgeClicked = (id: number | string) => {
  transferBadge(otherEmailBadges, lastEmailBadges, id);
};

const deleteLastEmailBadges = (id: number | string) => {
  const badge = lastEmailBadges.value.find((badge) => badge.id === id);
  if (!badge) return;

  removeBadgeFromList(lastEmailBadges.value, badge);
  if (badge?.custom) return;

  otherEmailBadges.value.push(badge);
};

//Flags
const loadingSendButton = ref(false);

//Methods
const createNewBadge = (email: string, id: string | number, custom?: boolean, invalid?: boolean): BadgeIDCustom => ({
  id: id.toString(),
  modelValue: email.toLowerCase(),
  theme: invalid ? "red" : "white",
  deletable: true,
  custom: custom,
  error: invalid,
});

const handleSendTestEmail = async () => {
  loadingSendButton.value = true;

  const data = await TemplatesAPI.getTestEmails();

  lastEmailBadges.value = data.last?.map<BadgeIDCustom>((email) => {
    numberOfBadges.value++;
    return createNewBadge(email, numberOfBadges.value);
  }) as BadgesCustom;

  otherEmailBadges.value = data.others?.map<BadgeIDCustom>((email) => {
    numberOfBadges.value++;
    return createNewBadge(email, numberOfBadges.value);
  }) as BadgesCustom;

  loadingSendButton.value = false;
};

const sendTest = async () => {
  insertNewBadge();
  if (limitExceeded.value) {
    notify({
      title: t("sendTest.errorLimitExceededTitle"),
      text: t("sendTest.errorLimitExceededMessage"),
      theme: "error",
    });
    return;
  }

  if (badgesWithError.value) {
    notify({
      title: t("sendTest.errorParsingEmailTitle"),
      text: t("sendTest.errorParsingEmailMessage"),
      theme: "error",
    });
    return;
  }

  if (isEmpty(selectedTestEmails.value) || !selectedTestEmails.value) {
    notify({
      title: t("sendTest.errorTitle"),
      text: t("sendTest.errorMessage"),
      theme: "error",
    });
    return;
  }

  try {
    loadingSendButton.value = true;

    await TemplatesAPI.sendTestEmail({
      emails: selectedTestEmails.value,
      tpl_id: props.tplId,
      subject: selectedSubject.value?.value,
      use_autosave: props.useAutoSave,
      contact_id: props.contactId?.toString(),
      utm_campaign: props.utmCampaignName,
      sender_id: props.senderId,
    });
    trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_SENDTEST" });

    notify({
      title: t("sendTest.successTitle"),
      text: t("sendTest.successMessage"),
    });
    loadingSendButton.value = false;
    onClose();
  } catch (e) {
    loadingSendButton.value = false;
  }
};

const insertBadgeInList = (badges: BadgesCustom, badge: BadgeIDCustom) => {
  badges.push(badge);
};

const removeBadgeFromList = (badges: BadgesCustom, badge: BadgeIDCustom) => {
  const index = badges.findIndex((lastBadge) => lastBadge.id === badge.id);
  if (index === -1) {
    return;
  }
  badges.splice(index, 1);
};

const insertNewBadges = (emails: Array<string>) => {
  if (limitExceeded.value) return;
  const remaining = EMAILS_LIMIT - lastEmailBadges.value.length + 1;

  emails.slice(0, remaining).forEach((email) => {
    const duplicatedBadge = lastEmailBadges.value.find((badge) => badge.modelValue === email);

    if (duplicatedBadge) return;

    numberOfBadges.value++;
    const isValidEmail = Boolean(emailValidator.$validator(email, null, null));
    const badgeAnotherList = otherEmailBadges.value.find((badge) => badge.modelValue == email);
    const newBadge = createNewBadge(email, numberOfBadges.value, badgeAnotherList == undefined, !isValidEmail);

    insertBadgeInList(lastEmailBadges.value, newBadge);

    if (!badgeAnotherList) return;

    removeBadgeFromList(otherEmailBadges.value, badgeAnotherList);
  });
};

const onPaste = (event: ClipboardEvent) => {
  event.preventDefault();
  insertNewBadge();
  const text = event.clipboardData?.getData("text/plain");

  if (!text) return;

  const regexSeparators = new RegExp("[" + separators.join("") + "]+", "g");
  const emails = text.split(regexSeparators);

  //Limpieza de espacio vacio al final de la cadena
  const lastEmail = emails[emails.length - 1];

  if (lastEmail.length == 0) emails.pop();

  insertNewBadges(emails);
  clearValue();
};

//KeyDown Methods
const insertNewBadge = (event?: KeyboardEvent) => {
  event?.preventDefault();
  if (!value.value || limitExceeded.value) return;

  const duplicatedBadge = lastEmailBadges.value.find((badge) => badge.modelValue === value.value);

  if (duplicatedBadge) {
    clearValue();

    return;
  }

  numberOfBadges.value++;
  const isValidEmail = Boolean(emailValidator.$validator(value.value, null, null));

  const badgeAnotherList = otherEmailBadges.value.find((badge) => badge.modelValue == value.value);

  const newBadge = createNewBadge(value.value, numberOfBadges.value, badgeAnotherList == undefined, !isValidEmail);
  clearValue();

  insertBadgeInList(lastEmailBadges.value, newBadge);

  if (!badgeAnotherList) return;

  removeBadgeFromList(otherEmailBadges.value, badgeAnotherList);
};

const onBackspace = () => {
  if (value.value) return;

  const lastBadge = lastEmailBadges.value.pop();

  if (!lastBadge || lastBadge.custom) return;

  insertBadgeInList(otherEmailBadges.value, lastBadge);
};

const onKeyDown = (event: KeyboardEvent) => {
  const key = event.key;
  const keyMethods = {
    Enter: insertNewBadge,
    Backspace: onBackspace,
    ",": insertNewBadge,
    " ": insertNewBadge,
    ";": insertNewBadge,
  };

  keyMethods?.[key]?.(event);
};

const onBadgeKeyDown = (event: KeyboardEvent, badgeID: string | number) => {
  const key = event.key;
  const keyMethods = {
    Enter: onOtherBadgeClicked,
  };

  keyMethods?.[key]?.(badgeID);
};

//LifeCycle
onMounted(async () => {
  await handleSendTestEmail();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "testSendTitle": "Enviar email de prueba",
    "testSendMessage": "Prueba tu diseño en casillas de distintos proveedores para asegúrarte que se vea correctamente.",
    "usedPreviouslyMessage": "Agregar otros utilizados anteriormente",
    "sendButton": "Enviar prueba",
    "cancelButton": "Cancelar",
    "labelTextArea": "Enviar la prueba a",
    "labelSelectedSubject": "Agrega otros utilizados anteriormente",
    "hintTextArea": "Ingresa las direcciones de email a las que deseas enviarles la prueba",
    "invalidEmailMessage": "Verifica que las casillas de correo que ingresaste tengan un formato correcto",
    "emailLimitExceeded": "No se puede enviar una prueba a más de 15 destinatarios",
    "alertNoSender": "Debes seleccionar un remitente para poder hacer envíos de prueba. Edita el contenido para configurarlo.",
    "alertEmailSelected": "Recibirás el email de prueba como lo haría {email}",
    "sendTest": {
      "errorTitle": "Sin contactos",
      "errorMessage": "No hay contactos seleccionados para el envio",
      "errorParsingEmailTitle": "Error con el formato de email",
      "errorParsingEmailMessage": "Uno o más emails ingresados no son validos",
      "errorLimitExceededTitle": "Límite de envíos excedido",
      "errorLimitExceededMessage": "No se puede enviar una prueba a más de 15 destinatarios",
      "successTitle": "Envio realizado",
      "successMessage": "El email de prueba se ha enviado correctamente"
    }
  },
  "pt": {
    "testSendTitle": "Enviar email de teste",
    "testSendMessage": "Teste seu template em emails de diferentes servidores para garantir uma correta visualização",
    "usedPreviouslyMessage": "Adicionar outros utilizados anteriormente",
    "sendButton": "Enviar teste",
    "cancelButton": "Cancelar",
    "labelTextArea": "Enviar email de teste para",
    "labelSelectedSubject": "Adicionar outros utilizados anteriormente",
    "hintTextArea": "Insira os emails que receberão os envios de teste",
    "invalidEmailMessage": "Verifique que os emails adicionados tenham un formato correto",
    "emailLimitExceeded": "Não é possível fazer um envio de teste para mais de 15 destinatários",
    "alertNoSender": "Você deve selecionar um remetente para fazer envios de teste. Edite o conteúdo para configurá-lo.",
    "alertEmailSelected": "Você receberá o email de teste como se fosse {email}",
    "sendTest": {
      "errorTitle": "Sem contatos",
      "errorMessage": "Não há contatos selecionados para o envio",
      "errorParsingEmailTitle": "Erro com o formato do email",
      "errorParsingEmailMessage": "Um ou mais emails adicionados não são válidos",
      "successTitle": "Envio realizado",
      "successMessage": "O email de teste enviado corretamente"
    }
  }
}
</i18n>
