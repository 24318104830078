import { ref, computed, watch, toRaw } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";

import esMessages from "./i18n/es.notifications.json";
import ptMessages from "./i18n/pt.notifications.json";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Composables
import { useNotifications as useNotify } from "@composables/notifications";

// Services
import { useNotifications } from "@api/modules/notifications/notifications";

// Domain
import type { Notifications, Notification, Tasks, FixedTasks, FixedTask } from "@domain/notifications";

export const useNotificationStore = defineStore("notificationStore", () => {
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  const { t, mergeLocaleMessage } = useI18n();

  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", ptMessages);

  const notificationsAPI = useNotifications();
  const { notify } = useNotify();

  const fixedTasks = ref<FixedTasks>([]);

  const createFixedTask = (newTask: FixedTask) => {
    fixedTasks.value = [...fixedTasks.value, newTask];
  };

  const updateFixedTaskProgress = (taskId: string, newProgress: number) => {
    const foundTaskIndex = fixedTasks.value.findIndex((t) => t.id === taskId);
    if (foundTaskIndex === -1) return;

    const foundTask = fixedTasks.value[foundTaskIndex];
    foundTask.progress = newProgress;
    fixedTasks.value.splice(foundTaskIndex, 1, foundTask);
  };

  const fetchedTasks = ref<Tasks>([]);
  const tasks = computed<Tasks>(() => [...fixedTasks.value, ...fetchedTasks.value]);
  const someTaskIsProcessing = computed<boolean>(() => {
    return tasks.value.some((t) => t.state === "PROCESSING");
  });

  const someTaskIsQueued = computed<boolean>(() => {
    return tasks.value.some((t) => t.state === "QUEUED");
  });

  const deleteTask = (taskId: string) => {
    fixedTasks.value = toRaw(fixedTasks.value).filter((t) => t.id.toString() !== taskId);
    fetchedTasks.value = toRaw(fetchedTasks.value).filter((t) => t.id.toString() !== taskId);
  };

  const fetchTasks = async () => {
    const resTasks = await notificationsAPI.getTasks();
    fetchedTasks.value = [...resTasks];
  };

  const fetchTasksUntilFinish = async () => {
    await fetchTasks();
    if (someTaskIsProcessing.value || someTaskIsQueued.value) {
      setTimeout(() => {
        fetchTasksUntilFinish();
      }, 10000);
    }
  };

  watch(
    [someTaskIsProcessing, someTaskIsQueued],
    ([newSomeTaskIsProcessing, newSomeTaskIsQueued], [oldSomeTaskIsProcessing, oldSomeTaskIsQueued]) => {
      const taskWasProcessing = oldSomeTaskIsProcessing && !newSomeTaskIsProcessing;
      const taskWasQueued = oldSomeTaskIsQueued && !newSomeTaskIsQueued;

      if (taskWasProcessing || taskWasQueued) {
        refreshNotifications({ isMasterUser: session.value?.isMasterUser ?? false });
      }
      if (someTaskIsProcessing.value || someTaskIsQueued.value) {
        setTimeout(() => {
          fetchTasksUntilFinish();
        }, 10000);
      }
    },
  );

  const notifications = ref<Notifications>([]);
  const selectedNotification = ref<Notification>();

  const notificationNotReadCount = computed<number>(() => {
    return notifications.value.filter((n) => !n.read).length;
  });

  const refreshNotifications = async (params: { isMasterUser: boolean }) => {
    const canChangeAccount = sessionStore.session?.canChangeAccount;
    const parentAccount = sessionStore.session?.account?.parent;

    const latestNotifications = await notificationsAPI.getLatestNotifications({
      isMasterUser: params.isMasterUser,
      limit: notifications.value.length,
      account: canChangeAccount && parentAccount ? parentAccount : undefined,
    });

    const newNotifications = latestNotifications.filter((n) =>
      notifications.value.every((notification) => notification.id !== n.id),
    );

    newNotifications.forEach((notification) => {
      notify({
        title: notification.title,
        text: notification.message,
        theme: notification.type === "TASK_FAILED" ? "error" : "success",
        timeout: 15000,
        buttons: [
          {
            text: t("seeResults"),
            theme: "primary-clean",
            action: () => {
              selectedNotification.value = notification;
            },
          },
        ],
      });
    });

    notifications.value = [...newNotifications, ...notifications.value];
  };

  const fetchNotifications = async (params: { isMasterUser: boolean }) => {
    const canChangeAccount = sessionStore.session?.canChangeAccount;
    const parentAccount = sessionStore.session?.account?.parent;

    const resNotifications = await notificationsAPI.getNotifications({
      isMasterUser: params.isMasterUser,
      account: canChangeAccount && parentAccount ? parentAccount : undefined,
    });
    notifications.value = [...toRaw(notifications.value), ...resNotifications.value];
  };

  return {
    tasks,
    someTaskIsProcessing,
    notifications,
    selectedNotification,
    notificationNotReadCount,
    fetchTasks,
    deleteTask,
    fetchNotifications,
    createFixedTask,
    updateFixedTaskProgress,
  };
});
