import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";

import { Currencies } from "@/vue/locales/i18n";

//Types
import { AccountFeatures } from "@/vue/types/account";
import { Fonts } from "@/vue/types/fonts";

export type AccountConfigAttributionModelType = "clicks" | "opens" | undefined;

export interface IAccountConfig {
  currency?: Currencies | "" | undefined;
  use_custom_currency?: boolean | undefined;
  monthly_cost?: number | undefined;
  attribution_model?: AccountConfigAttributionModelType;
  fonts?: Fonts;
  defaultFonts?: Fonts;
}

export function useAccountConfig(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function get({
    account,
    loadingRef,
  }: {
    account?: string;
    loadingRef?: Ref<boolean>;
  } = {}): Promise<IAccountConfig> {
    const res = await request({
      url: "/config",
      account: account,
      method: "get",
      loadingRef,
    });

    return res.data?.data as IAccountConfig;
  }

  async function put({
    account,
    data,
    loadingRef,
  }: {
    account?: string;
    data?: IAccountConfig;
    loadingRef?: Ref<boolean>;
  }): Promise<IAccountConfig> {
    const res = await request({
      url: "/config",
      account: account,
      method: "put",
      data: data,
      loadingRef,
    });

    return res.data?.data as IAccountConfig;
  }

  return { get, put };
}

export function useAccountFeatures(requestor?: Requestor) {
  const request = requestor || useRequests();
  //TODO: cambiar por nuevo endpoint /features
  async function get({
    account,
    loadingRef,
  }: {
    account?: string;
    loadingRef?: Ref<boolean>;
  } = {}): Promise<AccountFeatures> {
    const res = await request({
      url: "",
      account: account,
      method: "get",
      loadingRef,
    });

    return res.data.features as AccountFeatures;
  }
  async function put({
    key,
    value,
    account,
    loadingRef,
  }: {
    key: string;
    value: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: "/features",
      account: account,
      method: "put",
      data: {
        [key]: value,
      },
      loadingRef,
    });

    return res;
  }

  return { get, put };
}

export interface IAccountEmailReport {
  account: string;
  context: { first_name: string };
  created: string; // Date
  email: string;
  id: string;
  type: "daily" | "weekly" | "monthly";
  user: string;
}

export type IAccountEmailReports = Array<IAccountEmailReport>;

export type GetFunctionEmailReportsType = ({
  account,
  userID,
  loadingRef,
}: {
  account?: string;
  userID: string;
  loadingRef?: Ref<boolean>;
}) => Promise<IAccountEmailReports>;

export type PostFunctionEmailReportsType = ({
  data,
  userID,
  account,
  loadingRef,
}: {
  data: {
    email: string;
    context: { first_name: string };
  };
  userID: string;
  account?: string | undefined;
  loadingRef?: Ref<boolean> | undefined;
}) => Promise<void>;

export type DeleteFunctionEmailReportsType = ({
  userID,
  account,
  loadingRef,
}: {
  userID: string;
  account?: string | undefined;
  loadingRef?: Ref<boolean> | undefined;
}) => Promise<void>;

interface UseAccountEmailReports {
  get: GetFunctionEmailReportsType;
  postDailyConfig: PostFunctionEmailReportsType;
  deleteDailyConfig: DeleteFunctionEmailReportsType;
  postWeeklyConfig: PostFunctionEmailReportsType;
  deleteWeeklyConfig: DeleteFunctionEmailReportsType;
  postMonthlyConfig: PostFunctionEmailReportsType;
  deleteMonthlyConfig: DeleteFunctionEmailReportsType;
}

export function useAccountEmailReports(requestor?: Requestor): UseAccountEmailReports {
  const request = requestor || useRequests();

  async function get({
    account,
    userID,
    loadingRef,
  }: {
    account?: string;
    userID: string;
    loadingRef?: Ref<boolean>;
  }): Promise<IAccountEmailReports> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email`,
      account: account,
      method: "get",
      loadingRef,
    });

    return res.data as IAccountEmailReports;
  }

  async function postDailyConfig({
    data,
    userID,
    account,
    loadingRef,
  }: {
    data: {
      email: string;
      context: { first_name: string };
    };
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/daily`,
      account: account,
      method: "post",
      data: data,
      loadingRef,
    });

    return res;
  }

  async function deleteDailyConfig({
    userID,
    account,
    loadingRef,
  }: {
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/daily`,
      account: account,
      method: "delete",
      loadingRef,
    });

    return res;
  }

  async function postWeeklyConfig({
    data,
    userID,
    account,
    loadingRef,
  }: {
    data: {
      email: string;
      context: { first_name: string };
    };
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/weekly`,
      account: account,
      method: "post",
      data: data,
      loadingRef,
    });

    return res;
  }

  async function deleteWeeklyConfig({
    userID,
    account,
    loadingRef,
  }: {
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/weekly`,
      account: account,
      method: "delete",
      loadingRef,
    });

    return res;
  }

  async function postMonthlyConfig({
    data,
    userID,
    account,
    loadingRef,
  }: {
    data: {
      email: string;
      context: { first_name: string };
    };
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/monthly`,
      account: account,
      method: "post",
      data: data,
      loadingRef,
    });

    return res;
  }

  async function deleteMonthlyConfig({
    userID,
    account,
    loadingRef,
  }: {
    userID: string;
    account?: string;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    const res = await request({
      url: `/accountmetrics/users/${userID}/report/email/monthly`,
      account: account,
      method: "delete",
      loadingRef,
    });

    return res;
  }
  return {
    get,
    postDailyConfig,
    deleteDailyConfig,
    postWeeklyConfig,
    deleteWeeklyConfig,
    postMonthlyConfig,
    deleteMonthlyConfig,
  };
}

export function useAccountDrips(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function post({
    account,
    loadingRef,
  }: {
    account?: string;
    loadingRef?: Ref<boolean>;
  } = {}): Promise<void> {
    const res = await request({
      url: "/drips/enable",
      account: account,
      method: "post",
      loadingRef,
    });

    return res;
  }

  return { post };
}
