<template>
  <div class="flex min-h-full flex-1 overflow-x-clip">
    <NotificationsContainer />
    <div v-if="showPage" class="flex flex-1 flex-col justify-center px-4 py-10 lg:flex-none lg:px-20 xl:px-24">
      <transition
        enter-active-class="duration-200 ease-out"
        leave-active-class="duration-200 ease-in"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-from-class="transform translate-x-0 opacity-100"
        :enter-from-class="
          'transform opacity-0 ' +
          (form === 'recoverPassword' || form === 'recoverPasswordSent' || form === 'signup'
            ? 'translate-x-20'
            : '-translate-x-20')
        "
        :leave-to-class="
          'transform opacity-0 ' +
          (form === 'recoverPassword' || form === 'recoverPasswordSent' || form === 'signup'
            ? '-translate-x-20'
            : 'translate-x-20')
        "
        mode="out-in"
      >
        <TiendanubeAuth
          v-if="form === 'tiendanubeAuth' && tiendanubeSignUp"
          :tiendanube-store-info="tiendanubeStoreInfo"
          @go-to-login="goToLogin"
          @go-to-waitlist="goToWaitlist"
          @help-article="openHelpArticle"
        />
        <LoginForm
          v-else-if="form === 'login'"
          v-model:email="email"
          @recover-password="goToRecoverPassword"
          @reset-password="resetPassword"
          @sign-up="goToSignUp"
          @help-article="openHelpArticle"
        />
        <RecoverPasswordForm
          v-else-if="form === 'recoverPassword'"
          v-model:email="email"
          @go-to-login="goToLogin"
          @sent="goToRecoverPasswordSent"
          @help-article="openHelpArticle"
        />
        <RecoverPasswordSent
          v-else-if="form === 'recoverPasswordSent'"
          @go-to-login="goToLogin"
          @help-article="openHelpArticle"
        />
        <UpdatePassword
          v-else-if="form === 'reset'"
          :reset-token="resetToken"
          :email="emailExpired"
          :password="passwordExpired"
          :account="accountExpired"
          @help-article="openHelpArticle"
        />
        <SignUp
          v-else-if="form === 'signup'"
          @go-to-waitlist="goToWaitlist"
          @go-to-login="goToLogin"
          @help-article="openHelpArticle"
        />
      </transition>
      <Waitlist v-if="form === 'waitlist'" @go-to-login="goToLogin" @help-article="openHelpArticle" />
    </div>
    <div
      v-if="showPage"
      class="tw-hidden sticky inset-0 max-h-screen w-0 flex-1 flex-col justify-center space-y-8 bg-sky-400 lg:flex"
    >
      <transition
        enter-active-class="transform ease-out duration-200 transition-all flex space-x-4"
        :enter-from-class="
          'transform opacity-0 ' +
          (form === 'recoverPassword' || form === 'recoverPasswordSent' || form === 'signup'
            ? 'translate-x-20'
            : '-translate-x-20')
        "
        enter-to-class="opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="opacity-100 translate-x-0"
        :leave-to-class="
          'transform opacity-0 ' +
          (form === 'recoverPassword' || form === 'recoverPasswordSent' || form === 'signup'
            ? '-translate-x-20'
            : 'translate-x-20')
        "
        mode="out-in"
      >
        <img
          :key="img.key"
          :src="img.src"
          :alt="img.alt"
          class="tw-hidden mx-auto max-h-[70%] object-cover px-10 hsm:block"
        />
      </transition>
      <div class="mx-auto max-w-[90%] space-y-2 text-center text-white xl:max-w-[70%]">
        <div class="flex justify-center space-x-2">
          <StarIcon v-for="index in 5" :key="index" class="h-5 w-5 text-yellow-300" />
        </div>
        <p class="text-sm">
          “{{ t(`reviews.${reviewId}.description`) }}”
          <span class="whitespace-nowrap font-semibold"> - {{ t(`reviews.${reviewId}.name`) }} </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, reactive, computed } from "vue";

import type { AuthenticationModes } from "./authentication.types";

// Components
import NotificationsContainer from "@molecules/NotificationsContainer.vue";
import LoginForm from "./components/LoginForm.vue";
import RecoverPasswordForm from "./components/RecoverPasswordForm.vue";
import RecoverPasswordSent from "./components/RecoverPasswordSent.vue";
import UpdatePassword from "./components/UpdatePasswordForm.vue";
import SignUp from "./components/SignUpForm.vue";
import TiendanubeAuth from "./components/TiendanubeAuth.vue";
import Waitlist from "./components/Waitlist.vue";

// Icons
import { StarIcon } from "@heroicons/vue/solid";

// Application
import { useRouterApp } from "@application";
import { useAuthenticationApp } from "@application";

//Notifications
import { useNotifications } from "@composables/notifications";

// Domain
import type { Industry } from "@domain/account";

// I18n
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

const { notify } = useNotifications();
const authApp = useAuthenticationApp();
const routerApp = useRouterApp();

const props = withDefaults(
  defineProps<{
    mode: AuthenticationModes;
    resetToken?: string;
    authCode?: string;
  }>(),
  {
    mode: "login",
    resetToken: undefined
  }
);

const img = computed(() => {
  if (form.value === "signup")
    return {
      key: "signupImg",
      src: locale.value.slice(0, 2) === "pt" ? "/img/authentication/signup_br.png" : "/img/authentication/signup.png",
      alt: "Sign up tiendanube"
    };

  if (form.value === "tiendanubeAuth")
    return {
      key: "signupTnImg",
      src:
        locale.value.slice(0, 2) === "pt" ? "/img/authentication/signupTN_br.png" : "/img/authentication/signupTN.png",
      alt: "Sign up"
    };

  return {
    key: "loginImg",
    src: locale.value.slice(0, 2) === "pt" ? "/img/authentication/Login_br.png" : "/img/authentication/Login.png",
    alt: "Log in"
  };
});

const reviewsCount = 11;
const reviewId = ref(Math.floor(Math.random() * reviewsCount));

const showPage = ref(false);

const form = ref<AuthenticationModes>(props.mode);
const goToRecoverPassword = () => {
  form.value = "recoverPassword";
};

const goToSignUp = () => {
  form.value = "signup";
  routerApp.navigate({
    path: "/integrations",
    trigger: false
  });
};

const email = ref<string>();
const emailExpired = ref<string>();
const passwordExpired = ref<string>();
const accountExpired = ref<string>();
const resetPassword = ({ email, password, account }: { email: string; password: string; account?: string }) => {
  form.value = "reset";
  routerApp.navigate({
    path: "/reset",
    trigger: false
  });

  emailExpired.value = email;
  passwordExpired.value = password;
  accountExpired.value = account;
};

watch([form], () => {
  if (form.value === "reset") return;

  emailExpired.value = undefined;
  passwordExpired.value = undefined;
  accountExpired.value = undefined;
});

const goToLogin = () => {
  showPage.value = true;
  form.value = "login";
  routerApp.navigate({
    path: "/login",
    trigger: false
  });
};

const goToWaitlist = () => {
  form.value = "waitlist";
};

const goToRecoverPasswordSent = () => {
  form.value = "recoverPasswordSent";
};

const openHelpArticle = () => {
  window.Intercom("showArticle", 1085);
};

const tiendanubeStoreInfo = reactive<{
  businessName: string;
  website: string;
  email: string;
  preinit: string;
  country: string;
  phone: string;
  name: string;
  address: string;
  industry: Industry | "";
  tnPlan: string;
  tnCreatedAt: string;
}>({
  businessName: "",
  website: "",
  email: "",
  preinit: "",
  country: "",
  name: "",
  address: "",
  phone: "",
  industry: "",
  tnPlan: "",
  tnCreatedAt: ""
});
const tiendanubeSignUp = ref(false);

const loginTiendanube = async ({ code }: { code: string }) => {
  const res = await authApp.loginTiendanube({
    code
  });

  if (res.isErr()) {
    notify({
      title: t("notifications.loginTiendanubeError.title"),
      text: t("notifications.loginTiendanubeError.text"),
      theme: "error",
      timeout: 5000
    });
    goToLogin();
    return;
  }

  if (res.value.type === "loggedIn") {
    routerApp.navigate({
      path: "home",
      trigger: false
    });
    location.reload();
    return;
  }

  tiendanubeStoreInfo.businessName = res.value.data.businessName;
  tiendanubeStoreInfo.website = res.value.data.website;
  tiendanubeStoreInfo.email = res.value.data.email;
  tiendanubeStoreInfo.preinit = res.value.data.preinit;
  tiendanubeStoreInfo.country = res.value.data.country;
  tiendanubeStoreInfo.name = res.value.data.name;
  tiendanubeStoreInfo.industry = res.value.data.industry;
  tiendanubeStoreInfo.address = res.value.data.address;
  tiendanubeStoreInfo.phone = res.value.data.phone;
  tiendanubeStoreInfo.tnPlan = res.value.data.tnPlan;
  tiendanubeStoreInfo.tnCreatedAt = res.value.data.tnCreatedAt;
  tiendanubeSignUp.value = true;
  showPage.value = true;
};

onMounted(async () => {
  if (props.authCode && props.mode === "tiendanubeAuth") {
    await loginTiendanube({ code: props.authCode });
    return;
  }

  if (!props.authCode && props.mode === "tiendanubeAuth") {
    goToLogin();
  }

  showPage.value = true;
});
</script>

<i18n lang="json">
{
  "es": {
    "notifications": {
      "loginTiendanubeError": {
        "title": "Autorización fallida",
        "text": "No fue posible autenticarse a través de Tiendanube."
      }
    },
    "reviews": {
      "0": {
        "name": "Quiosquito Virtual",
        "description": "AMO PERFIT! Veníamos de algunas experiencias con otros proveedores y la diferencia es sustancial. Más allá de la plataforma en sí, que es muy buena, el equipo hace (valga la redundancia), la diferencia. Ni lo duden."
      },
      "1": {
        "name": "Rompiendo Las Guindas",
        "description": "Quiero agradecer a la plataforma por su excelente soporte, siempre responden de forma amena y rápida. Perfit resultó MUY COMPLETA y entendible, sin contar que las automations son cada vez mejores. La recomiendo 100%!"
      },
      "2": {
        "name": "Footy Arg",
        "description": "El uso de la aplicación es muy simple e intuitivo. Además es super funcional y tienen un equipo de atención online que responden siempre, super rápido y con muy buen a onda, son muy resolutivos!"
      },
      "3": {
        "name": "Prussia",
        "description": "Recomiendo el uso de Perfit para Tiendanube, y ni decir los automation, son una genialidad! Pones todo en marcha en un par de clics, y si tenes una duda el soporte de Onboarding están permanente Precio-calidad al 100%"
      },
      "4": {
        "name": "Fichí Jewelry",
        "description": "LO MÁS!!! Estoy súper contenta con la aplicación y los resultados hasta ahora, y especialmente sorprendida con lo EXCELENTE que es la atención!es IMPECABLE."
      },
      "5": {
        "name": "Editorial Albatros",
        "description": "Una plataforma de email con muy buenos recursos para potenciar las ventas a través de los automations y también las herramientas de segmentación de listas. A su vez le agregan una atención cordial y eficiente que lo hacen de primera."
      },
      "6": {
        "name": "Indochina Designs",
        "description": "Me encanta Perfit. Funciona increíble, tanto mails de campaña como automatizaciones. Es muy versátil, podes comunicar a medida vía mail o automatizar mails para retener clientes, recuperar carritos abandonados, etc."
      },
      "7": {
        "name": "Siamo Fuori",
        "description": "Excelente atención del equipo de onboarding!! Muy atentos a nuestros primeros pasos y buena relación a nuevas ventas, 100% recomendable."
      },
      "8": {
        "name": "Espacio Mamás",
        "description": "Me sorprendió la herramienta, muy completa pero a la vez muy facil de utilizar y efectiva, destaco el acompañamiento que recibimos del equipo de onboarding te aclaran todas las dudas y muy amables. Muchas gracias!!"
      },
      "9": {
        "name": "Fapp",
        "description": "Una gran herramienta para incrementar ventas y el equipo de onboarding es super atencioso pendiente de todas las necesidades. La recomiendo."
      },
      "10": {
        "name": "La Fábrica",
        "description": "Siempre están atentos a contestar todas las dudas y son muy rápidos y eficientes en la atencion y resolucion de problemas, muy recomendable!"
      }
    }
  },
  "pt": {
    "notifications": {
      "loginTiendanubeError": {
        "title": "Autorização falhou",
        "text": "Não foi possível autenticar através da Nuvemshop."
      }
    },
    "reviews": {
      "0": {
        "name": "Inexis Denim",
        "description": "O app me surpreendeu com a dashboard intuitiva, a forma dimanica para automatizar campanhas, programar disparos de email e colocar tag para saber os interresses do meu publico. Estou tendo ótimas conversões!"
      },
      "1": {
        "name": "Soho Papelaria",
        "description": "Muito intuitiva de usar, rápido suporte e muito eficiente nos relatorios, aqui na Soho estamos tendo uma ótima experiência com a plataforma."
      },
      "2": {
        "name": "Boni Natural",
        "description": "Excelente!!! Melhor email marketing que já usamos em aaaanos. E o período de teste de 15 dias é acompanhado 24/7 pela equipe deles, te ensinando tudo e tirando todas as suas dúvidas. Nunca vi qualquer empresa com tanta atenção como a Perfit!"
      },
      "3": {
        "name": "Rennova",
        "description": "Perfit foi para a nossa empresa a melhor solução integrada nativa junto a Nuvemshop. Conseguimos disparar e-mails qualificados em pouco tempo, um serviço de atendimento full time impecável. Não é a toa que temos tanta assertividade no processo!"
      },
      "4": {
        "name": "Pedrazul Editora",
        "description": "Testei outros aplicativos de e-mail marketing, mas nenhum atendeu tão bem quanto o Perfit. O aplicativo é completo, fácil de usar e o atendimento é EXCELENTE! Recomendo esse app, melhor que qualquer outro."
      },
      "5": {
        "name": "Mimo Meu Semijoias",
        "description": "Aplicativo atende ABSURDAMENTE as necessidades de campanhas de e-mail. Perfit é a integração mais COMPLETA e simples em questão de automação de campanhas quando o assunto é Nuvemshop."
      },
      "6": {
        "name": "Dorinha Acessórios",
        "description": "Gostei bastante, a plataforma é simples e fácil de mexer, e os atendimentos são sempre rápidos. Já entrei em outras plataformas e essa foi a que mais me ajudou, em questão de preço ela oferece mais do que outras que já usei. "
      },
      "7": {
        "name": "Nerd Universe",
        "description": "A melhor ferramenta de E-mail Marketing da Nuvemshop, 100% integrada com tudo, além de ter o melhor atendimento de todos. Pode ir sem medo."
      },
      "8": {
        "name": "Foco Fitness",
        "description": "Sem dúvidas é uma excelente ferramenta para aumentar as vendas. Fora o suporte da equipe que é sensacional. Além de você falar com a equipe por WhatsApp tem vários vídeos explicativos no YouTube que facilitam muito. Recomendo :)"
      },
      "9": {
        "name": "Foco Fitness",
        "description": "Muito bom!!! De verdade, facilita horrores! A configuração é fácil e intuitiva, com automações prontas. É só deixar rodar, como um perpétuo. O Atendimento deles é nota 1000, respondem super rápido e ajudam muito! Gente, tem e-mail para tudo!!!"
      },
      "10": {
        "name": "Ozzystore",
        "description": "Estou aprendendo a usar e o suporte é maravilhoso! respondem rapidamente, solucionam os problemas e sao atenciosos, sem contar que o app é bem completo e intuitivo! gostei!"
      }
    }
  }
}
</i18n>
