import { useBreakpoints as useBp, breakpointsTailwind } from "@vueuse/core";

export function useBreakpoints() {
  const breakpoints = useBp(breakpointsTailwind);

  const smBp = breakpoints.greater("sm");
  const mdBp = breakpoints.greater("md");
  const lgBp = breakpoints.greater("lg");
  const xlBp = breakpoints.greater("xl");
  const xxlBp = breakpoints.greater("2xl");

  return {
    smBp,
    mdBp,
    lgBp,
    xlBp,
    xxlBp,
  };
}
