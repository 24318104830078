import { Collection } from "@/core/magnet";
import app from "@/app";

export default Collection.extend({
  url: function () {
    var self = this;
    self.type = "users";
    if (app.session.get("isMasterUser")) {
      self.type = "masterusers";
    }
    return self.type + "/me/notifications";
  },
  parse: function (response) {
    return response.data;
  },
});
