import type { ContactsList, ContactListItem } from "@domain/lists";
import type { Lists, ListItem } from "./listSelector.types";
import { getI18nInstance } from "@locales/i18n";
import { formatDate } from "@helpers/formatters";
import { Tag } from "@domain/tag";

export const listToItem = (list: ContactListItem): ListItem => {
  const { n } = getI18nInstance().global;
  return {
    key: list.id.toString(),
    value: list.name,
    content: {
      dateText: formatDate(new Date(list.created), "MMM dd, HH:mm"),
      contactsText: n(list.totalContacts, "decimal"),
      tags: list.tags.map<Tag>((t) => ({
        id: t,
        color: "#fff",
        name: t,
      })),
    },
    data: list,
  };
};

export const listsToItems = (
  lists: ContactsList,
  valueTexts: {
    list: string;
  }
): Lists => {
  return [
    {
      id: "lists",
      value: valueTexts.list,
      list: lists.map<ListItem>(listToItem),
    },
  ];
};
