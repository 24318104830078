<template>
  <div
    v-for="tooltip in tooltips"
    :id="'heatMapTooltip' + tooltip.id"
    ref="containerRef"
    :key="tooltip.id"
    class="absolute z-50 rounded-full"
    :style="{
      top: `${tooltip.top}px`,
      left: `${tooltip.left}px`,
      width: `${tooltip.width}px`,
      height: `${tooltip.height}px`,
    }"
  />
</template>
<script lang="ts" setup>
import { ref, computed, watchEffect } from "vue";

// Composables
import { useTooltip } from "@composables/tooltips";
import type { DataPoint } from "@composables/heatmap";

//I18n
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    dataPoints: Array<DataPoint>;
    radius: number;
  }>(),
  {}
);

const containerRef = ref<Array<HTMLDivElement>>();
const tooltips = computed<
  Array<{ id: number; top: number; left: number; width: number; height: number; value: number; url: string }>
>(() => {
  return props.dataPoints.map((dataPoint, index) => {
    return {
      id: index,
      url: dataPoint.url,
      left: Math.max(dataPoint.x - props.radius, 0),
      top: Math.max(dataPoint.y - props.radius, 0),
      width: Math.max(props.radius * 2, 0),
      height: Math.max(props.radius * 2, 0),
      value: dataPoint.value,
    };
  });
});

const clicksCount = computed(() => {
  return props.dataPoints.reduce((count, point) => {
    count += point.value;
    return count;
  }, 0);
});

// Format
const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

watchEffect(() => {
  containerRef.value?.forEach((element) => {
    const tooltip = tooltips.value.find((d) => `heatMapTooltip${d.id}` === element.id);

    if (!tooltip) return;

    const percentage = sn((tooltip.value * 100) / clicksCount.value, "decimal");

    useTooltip(
      element,
      t(
        "tooltip",
        { url: tooltip.url, clicks: tooltip.value.toString(), percentage: `${percentage}` },
        { plural: tooltip.value }
      ),
      {
        placement: "bottom",
        maxWidth: 450,
        popperOptions: {
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                altAxis: true,
                tether: false,
                boundary: "clippingParents",
              },
            },
            {
              name: "flip",
              options: {
                allowedAutoPlacements: ["bottom", "top", "bottom-end", "top-end"],
                fallbackPlacements: ["bottom", "top", "bottom-end", "top-end"],
                altBoundary: true,
              },
            },
          ],
        },
      }
    );
  });
});
</script>

<!-- No funciona -->
<!-- <style scoped>
.tippy-content {
  overflow-wrap: break-word;
}
</style> -->

<i18n lang="jsonc">
{
  "es": {
    "tooltip": "<a href='{url}' target='_blank' style='color: white;'>{url}</a><br/><br/>{clicks} Click ({percentage}%) | <a href='{url}' target='_blank' style='color: white;'>{url}</a><br/><br/>{clicks} clicks totales ({percentage}%)"
  },
  "pt": {
    "tooltip": "<a href='{url}' target='_blank' style='color: white;'>{url}</a><br/><br/>{clicks} Click ({percentage}%) | <a href='{url}' target='_blank' style='color: white;'>{url}</a><br/><br/>{clicks} clicks totales ({percentage}%)"
  }
}
</i18n>
