import { Model } from "@/core/magnet";
import { round } from "@/helpers/utils";
import app from "@/app";

export default Model.extend({
  url: "dashboard",
  parse: function (response) {
    var plan = response.data.plan;

    // Set contacts rating
    response.data.allContacts.rating =
      round(response.data.allContacts.quality, 1) * 100;

    // Set available percentege
    if (plan.emails !== undefined) {
      plan.emails.limited =
        plan.type === "EMAILS" ||
        plan.type === "CHILD_LIMITED" ||
        plan.emails.limit > 0;
      plan.emails.availableP =
        (plan.emails.available * 100) / plan.emails.limit;

      // Set excess percentage
      if (plan.emails.available < 0) {
        plan.emails.excess = -plan.emails.available;
        plan.emails.excessP =
          (plan.emails.excess * 100) / plan.emails.excessLimit;
      }
    }

    // Calculate contacts
    if (plan.contacts !== undefined) {
      plan.contacts.availableP =
        (plan.contacts.active * 100) / plan.contacts.limit;
      if (plan.contacts.availableP > 100) {
        plan.contacts.excess = true;
      }
    }

    // Check if the plan has renovation
    plan.renews =
      plan.type === "MONTHLY" || plan.type === "FREE" ? true : false;

    app.setTrial(plan.trialEnds);

    plan.trial = app.getTrial();

    // Return data
    return response.data;
  },
});
