import app from "@/app";
import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";
const moment = window.moment;

export default Model.extend({
  urlRoot: function () {
    return app.router.current.options.account + "/users";
  },

  relationships: {
    permissions: function () {
      return $.get("users/permissions");
    },
    timezone: function () {
      const countryTimezones = moment.tz.zonesForCountry(app.detectedCountry);
      const otherTimezones = moment.tz
        .names()
        .filter(
          (tz) =>
            !countryTimezones.includes(tz) &&
            !tz.includes("Africa/") &&
            !tz.includes("Asia/") &&
            !tz.includes("Pacific/") &&
            !tz.includes("Indian/") &&
            !tz.includes("Antarctica/") &&
            !tz.includes("Atlantic/") &&
            !tz.includes("Australia/") &&
            !tz.includes("Arctic/") &&
            !tz.includes("Etc/") &&
            tz.includes("/")
        );
      const tz = [...countryTimezones, "-", ...otherTimezones];

      return {
        success: function (callback) {
          callback({ data: tz });
        },
      };
    },
    role: [
      { id: "ADMIN", name: "admin" },
      { id: "MANAGER", name: "manager" },
      { id: "AUTHOR", name: "author" },
      { id: "VIEWER", name: "viewer" },
      { id: "CUSTOM", name: "custom" },
    ],
  },
  additionals: {
    account: function () {
      return $.ajax({
        url: app.router.current.options.account,
        headers: {
          "Account-Prefix": false,
        },
      });
    },
    emailReports: function (model) {
      return $.get(
        "accountmetrics/users/" + model.attributes.username + "/report/email"
      );
    },
  },
  parse: function (response) {
    var user = response.data;
    if (
      user.username === app.session.get("user") &&
      app.router.current.options.account === app.session.get("mainAccount")
    ) {
      user.myself = true;
    } else {
      user.myself = false;
    }
    return user;
  },
  parseRelationships: function (relationships) {
    var self = this,
      originalPermissions,
      permissions = {};

    // Manage permissions
    if (relationships.permissions !== undefined && relationships.permissions) {
      // Grab a copy of the permissions
      originalPermissions = $.extend({}, relationships.permissions);
      delete relationships.permissions;

      // Check permissions
      if (originalPermissions) {
        $.each(originalPermissions, function (key, group) {
          $.each(group, function (index, permission) {
            permissions[key] = permissions[key] || [];
            permissions[key].push({
              id: permission,
              checked:
                _.indexOf(self.attributes.permissions, permission) === -1
                  ? false
                  : true,
            });
          });
        });
      }

      // Check the other relationships
      relationships = this.checkRelationships(relationships);

      // Finally, add the checked permissions as a relationship
      relationships.permissions = permissions;
    } else {
      // Otherwise, just check the relationships
      relationships = this.checkRelationships(relationships);
    }

    // Return parsed additionals
    return relationships;
  },
  parseAdditionals: function (additionals) {
    this.attributes.emailReports = {
      daily: false,
      weekly: false,
      monthly: false,
    };
    if (_.isDefined(additionals.emailReports)) {
      $.each(
        additionals.emailReports,
        function (index, report) {
          if (report.type === "daily") {
            this.attributes.emailReports.daily = true;
          } else if (report.type === "weekly") {
            this.attributes.emailReports.weekly = true;
          } else if (report.type === "monthly") {
            this.attributes.emailReports.monthly = true;
          }
        }.bind(this)
      );
    }

    return additionals;
  },

  // Destroy override
  destroy: function (options) {
    var params = $.extend(
      true,
      {},
      { type: "DELETE", url: this.url() },
      options
    );
    this.updateEmailReports({ daily: 0, weekly: 0, monthly: 0 });
    return $.ajax(params);
  },

  updateEmailReports: function (reports, username, email, name) {
    var id = username || this.id;
    var data = {
      email: email,
      context: { first_name: name },
    };

    if (reports.daily) {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/daily",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    } else {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/daily",
        type: "DELETE",
      });
    }
    if (reports.weekly) {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/weekly",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    } else {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/weekly",
        type: "DELETE",
      });
    }
    if (reports.monthly) {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/monthly",
        type: "POST",
        data: JSON.stringify(data),
        contentType: "application/json",
      });
    } else {
      $.ajax({
        url: "accountmetrics/users/" + id + "/report/email/monthly",
        type: "DELETE",
      });
    }
  },
});
