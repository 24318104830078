var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "              <div class=\"clear-search\" data-action=\"remove-filter\" data-params='[\"q\"]'>\n                <i class=\"fa fa-close\"></i>\n              </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button\n        class=\"btn btn-default\"\n        data-action=\"open-export-modal\"\n        data-type=\"none\"\n        data-require-permission=\"contacts:export\"\n      >\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"export")), depth0))
    + "\n      </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button\n        class=\"btn btn-default hide\"\n        data-action=\"mass\"\n        data-method=\"reset\"\n        data-feedback=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massReset")), depth0))
    + "\"\n        data-type=\"one-plus\"\n        data-require-permission=\"contacts:export\"\n      >\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"reset")), depth0))
    + "\n      </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\n            <a data-action=\"order\" data-params='{\"column\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"column") : depth0), depth0))
    + "\", \"type\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"}'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + "\n              "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"sortOptions")),(depth0 != null ? lookupProperty(depth0,"label") : depth0),true,{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":134,"column":14},"end":{"line":134,"column":70}}}))
    + "\n            </a>\n          </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"contacts section\">\n  <div class=\"section-header\">\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":47}}}))
    + "\n    </h1>\n    <div class=\"filters\">\n      <ul class=\"nav nav-pills\" data-role=\"filters\">\n        <li class=\"search\">\n          <form data-role=\"search\" data-form=\"search\">\n            <button class=\"btn btn-default\" type=\"button\" data-action=\"open-search-form\">\n              <i class=\"filter-icon pi-filter-search\"></i>\n            </button>\n            <input\n              type=\"text\"\n              placeholder=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...\"\n              class=\"form-control\"\n              name=\"q\"\n              value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "\"\n            />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "          </form>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class=\"toolbar\" data-role=\"toolbar\">\n    <a data-navigate=\"contacts/new\" class=\"btn btn-primary\" data-type=\"none\" data-require-permission=\"contacts:create\">\n      <i class=\"fa fa-plus\"></i>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"create")), depth0))
    + "\n    </a>\n    <button\n      class=\"btn btn-default\"\n      data-action=\"open-lists-modal\"\n      data-type=\"none\"\n      data-require-permission=\"contacts:update\"\n      data-selector-view=\"lists\"\n      data-selected-items=\"[]\"\n      data-selector-type=\"checkbox\"\n      data-selector-description=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"selectLists")), depth0))
    + "\"\n      data-create-item=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"create")), depth0))
    + "\"\n      data-preview=\"1\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"addToLists")), depth0))
    + "\n    </button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"details"))) && lookupProperty(stack1,"account"))) && lookupProperty(stack1,"features"))) && lookupProperty(stack1,"EXPORT_CONTACTS_HIDDEN")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":58,"column":15}}})) != null ? stack1 : "")
    + "    <button\n      class=\"btn btn-default hide\"\n      data-action=\"open-lists-modal\"\n      data-type=\"one-plus\"\n      data-require-permission=\"contacts:update\"\n      data-selector-view=\"lists\"\n      data-selected-items=\"[]\"\n      data-selector-type=\"checkbox\"\n      data-selector-description=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"selectLists")), depth0))
    + "\"\n      data-create-item=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"create")), depth0))
    + "\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"addToLists")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-default hide\"\n      data-action=\"open-interests-modal\"\n      data-type=\"one-plus\"\n      data-require-permission=\"contacts:update\"\n      data-selector-view=\"interests\"\n      data-selected-items=\"[]\"\n      data-selector-type=\"checkbox\"\n      data-selector-description=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"selectInterests")), depth0))
    + "\"\n      data-create-item=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"interests"))) && lookupProperty(stack1,"create")), depth0))
    + "\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"addToInterests")), depth0))
    + "\n    </button>\n    <button\n      class=\"btn btn-default hide\"\n      data-action=\"remove-from-list\"\n      data-confirm=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmRemoveFromList")), depth0))
    + "\"\n      data-type=\"one-plus-list\"\n      data-require-permission=\"contacts:update\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"removeFromList")), depth0))
    + "\n    </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":4},"end":{"line":105,"column":11}}})) != null ? stack1 : "")
    + "    <button\n      class=\"btn btn-danger hide\"\n      data-action=\"mass\"\n      data-method=\"destroy\"\n      data-feedback=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyM")), depth0))
    + "\"\n      data-confirm=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "\"\n      data-type=\"one-plus\"\n      data-require-permission=\"contacts:delete\"\n    >\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n    <div class=\"paging-selected\" data-role=\"check-count\"></div>\n  </div>\n  <div class=\"list-controls\">\n    <div class=\"check-all\">\n      <input id=\"check-all\" type=\"checkbox\" data-action=\"check-all\" />\n      <label for=\"check-all\" class=\"iconic\"></label>\n    </div>\n    <div class=\"sort dropdown\">\n      <button class=\"btn btn-default dropdown-toggle\" type=\"button\" data-toggle=\"dropdown\" data-role=\"order-button\">\n        <span>"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + "</span>\n        <i class=\"drop fa fa-angle-down\"></i>\n      </button>\n      <ul class=\"dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sortOptions")),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":8},"end":{"line":137,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n    <button data-action=\"open-columns-modal\" class=\"columns-selector-button\">\n      <i class=\"fa fa-columns\"></i>\n    </button>\n    <div class=\"paging-total\" data-role=\"paging-total\"></div>\n  </div>\n  <div class=\"content\" data-role=\"content\">\n    <div class=\"list\">\n      <ul class=\"table\" data-role=\"rows\"></ul>\n      <div class=\"loading-box\" data-role=\"items-loader\"></div>\n      <button class=\"btn btn-primary load-more\" data-action=\"load-more\">\n        "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n      </button>\n    </div>\n  </div>\n</div>";
},"useData":true});