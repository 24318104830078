import { computed, ref } from "vue";

import { defineStore } from "pinia";

// Application
import { useIntegrationsApp } from "@application";

// Domain
import { isExternalIntegration, integrations as integrationsInfoData } from "@domain/integrations";
import type { Integration, IntegrationsInfo, ExternalIntegrationInfo } from "@domain/integrations";
import type { Ecommerce } from "@domain/ecommerce";
import { isEcommerceId } from "@domain/ecommerce";

export const useIntegrationsStore = defineStore("integrationsStore", () => {
  const integrationsApp = useIntegrationsApp();
  const integrations = ref<Integration[]>([]);
  const integrationsFetched = ref<boolean>(false);
  const integrationsInfo = ref<IntegrationsInfo[]>(integrationsInfoData);

  const isTiendanube = computed<boolean>(() => {
    return integrations.value.some((i) => i === "tiendanube");
  });

  const tiendanubeIntegrationData = computed<ExternalIntegrationInfo | undefined>(() => {
    const tnInfo = integrationsInfo.value.find((i) => i.id === "tiendanube");

    if (!tnInfo || !isExternalIntegration(tnInfo)) return;

    return tnInfo;
  });

  const getIntegrations = (): Integration[] => {
    return integrations.value;
  };

  const fetchIntegrations = async () => {
    const res = await integrationsApp.getIntegrations();
    if (res.isErr()) return;

    integrations.value = res.value;
    integrationsFetched.value = true;
  };

  const ecommerceIntegrations = computed<Ecommerce[]>(() => {
    return integrations.value.filter(isEcommerceId) as Ecommerce[];
  });

  return {
    integrations,
    integrationsInfo,
    isTiendanube,
    tiendanubeIntegrationData,
    integrationsFetched,
    ecommerceIntegrations,
    getIntegrations,
    fetchIntegrations,
  };
});
