<template>
  <div
    class="relative border-2 border-gray-300 bg-white hover:border-sky-400 hover:shadow-md hover:cursor-pointer rounded-md overflow-hidden"
    @click="selectTemplate()"
  >
    <div class="flex flex-col space-y-3 justify-center mx-2 h-[350px] text-gray-400">
      <PlusIcon class="h-6 w-6 mx-auto" />
      <SimpleButton theme="primary-light" class="pointer-events-none w-max mx-auto">{{
        t("newTemplateButton")
      }}</SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
//Icons
import { PlusIcon } from "@heroicons/vue/solid";

//Components
import SimpleButton from "@atoms/SimpleButton.vue";

//Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();

// Emits
const emit = defineEmits<{
  (e: "select"): void;
}>();

const selectTemplate = () => emit("select");
</script>

<i18n lang="jsonc">
{
  "es": {
    "newTemplateButton": "Plantilla vacía"
  },
  "pt": {
    "newTemplateButton": "Template vazio"
  }
}
</i18n>
