<template>
  <div class="space-y-2">
    <div v-for="(option, index) in options" :key="option.key" class="relative flex items-start">
      <div class="flex h-6 items-center">
        <input
          :id="option.key"
          :aria-describedby="`${option.key}-description`"
          name="plan"
          type="radio"
          :tabindex="index + 1"
          :checked="option.key === selected.key"
          class="h-4 w-4 border-gray-300 text-sky-500 outline-none focus:!outline-none focus:ring-sky-500 focus-visible:ring-sky-500"
          @change="() => updateSelected(option)"
          @keydown.enter="() => updateSelected(option)"
        />
      </div>
      <div class="ml-3 text-sm leading-6">
        <label :for="option.key" class="text-sm font-normal text-gray-500">{{ option.label }}</label>
        <p v-show="option.description" :id="`${option.key}-description`" class="text-gray-400">
          {{ option.description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Options, Option } from "./radioList.types";

//Props
withDefaults(
  defineProps<{
    options: Options;
    selected: Option;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: "update:selected", selected: Option): void;
}>();

//Emits
const updateSelected = (selected: Option) => {
  emit("update:selected", selected);
};
</script>
