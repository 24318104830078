<template>
  <div class="bg-gray-50">
    <div :class="[showChangeAccount ? 'mt-14' : 'pt-14', 'flex w-full flex-col divide-y overflow-y-auto px-5 pb-4']">
      <!-- <div v-if="featuredEvent" class="pb-4">
        <FeaturedEventCard
          :title="featuredEvent.title"
          :date="formatDate(featuredEvent.nextEventDate, 'EEEE dd, MMM · y')"
          :description="featuredEvent.description"
          :name="featuredEvent.owner.name"
          :avatar="featuredEvent.owner.avatar"
          :link="featuredEvent.link"
        />
      </div> -->
      <div v-if="events.length > 0" :class="[/*featuredEvent ? 'py-8' : 'pb-8',*/ 'flex flex-col space-y-2']">
        <div class="flex justify-between px-5 text-sky-400">
          <span class="text-lg font-semibold text-gray-500">{{ t("otherEvents") }}</span>
          <a :href="liveStormLink" target="_blank" class="flex items-center space-x-1 text-sm font-semibold">
            <span>{{ t("goToEvents") }}</span>
            <ArrowNarrowRightIcon class="h-4 w-4" />
          </a>
        </div>
        <PostCard
          v-for="event in events"
          :key="event.event_id"
          :date="formatDate(event.nextEventDate, 'EEEE dd, MMM · y')"
          :link="event.link"
          :title="event.title"
          :tag="event.tag"
          @click="onClickLink(event.link)"
        />
      </div>
      <div :class="[events.length > 0 ? 'py-8' : 'pb-8', 'flex flex-col space-y-2']">
        <div class="flex justify-between px-5 text-sky-400">
          <span class="text-lg font-semibold text-gray-500">{{ t("blogPosts") }}</span>
          <a :href="blogLink" target="_blank" class="flex items-center space-x-1 text-sm font-semibold">
            <span>{{ t("goToBlog") }}</span>
            <ArrowNarrowRightIcon class="h-4 w-4" />
          </a>
        </div>
        <PostCard
          v-for="post in posts"
          :key="post.id"
          :date="formatDate(new Date(post.publishedDate), 'EEEE dd, MMM · y')"
          :link="post.url"
          :title="post.title"
          :tag="post.tags?.[0]?.name"
          @click="onClickLink(post.url)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { /*computed,*/ onMounted, ref } from "vue";

// import { getComputedFeaturedEventId } from "../home.data";

// Components
// import FeaturedEventCard from "./FeaturedEventCard.vue";
import PostCard from "./PostCard.vue";

// Icon
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";

// Utils
import { formatDate } from "@helpers/formatters";

// Store
import { useGlobalStore } from "@store";
import { storeToRefs } from "pinia";

// Application
import { useLivestormApplication } from "@application";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useBlogService } from "@services";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { LiveStormEventSession } from "@domain/livestorm";
import type { BlogPost } from "@/vue/types/blog";

const { t, locale } = useI18n();

const livestormApp = useLivestormApplication();
const blogService = useBlogService();
const trackingEventsService = useTrackingEvents();

const globalStore = useGlobalStore();
const { showChangeAccount } = storeToRefs(globalStore);

const blogLink = locale.value.slice(0, 2) === "pt" ? "https://blog.myperfit.com.br/" : "https://blog.myperfit.com/";
const liveStormLink =
  locale.value.slice(0, 2) === "pt"
    ? "https://app.livestorm.co/perfit?upcoming_locale=pt-BR"
    : "https://app.livestorm.co/perfit?upcoming_locale=es";

// TODO: Definir eventos fijos según cuenta
// const featuredEventId = getComputedFeaturedEventId();
// const featuredEvent = computed(() => {
//   return events.value.find((event) => event.event_id === featuredEventId.value);
// });

const events = ref<LiveStormEventSession[]>([]);

const posts = ref<BlogPost[]>([]);

const fetchEvents = async () => {
  const lvEvents = await livestormApp.getEvents({
    status: "on_demand",
    public: true
  });
  if (lvEvents.isErr()) return;

  events.value = lvEvents.value;
};

const fetchBlogPosts = async () => {
  const blogPosts = await blogService.getPosts({
    lang: locale.value.slice(0, 2) === "pt" ? "pt" : "es"
  });

  if (blogPosts.isErr()) return;

  posts.value = blogPosts.value;
};

const onClickLink = (url: string) => {
  trackingEventsService.amplitude({
    name: "APP_HOME_URL_LINK",
    data: {
      url: url
    }
  });
};

onMounted(async () => {
  await Promise.all([fetchEvents(), fetchBlogPosts()]);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "otherEvents": "Eventos",
    "blogPosts": "Notas del blog",
    "goToEvents": "Ver todos",
    "goToBlog": "Ver todos"
  },
  "pt": {
    "otherEvents": "Eventos",
    "blogPosts": "Notas do blog",
    "goToEvents": "Ver todos",
    "goToBlog": "Ver todos"
  }
}
</i18n>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  @apply bg-neutral-100;
  @apply my-16;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-300;
  border-radius: 9999px;
}
</style>
