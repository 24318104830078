<script setup lang="ts">
import { ref, watch, onMounted, computed, markRaw, toRaw } from "vue";

// Components
import HiddenDashboardPDF from "./HiddenDashboardPDF.vue";
import PageHeader from "@templates/PageHeader.vue";
import TabbedPage, { TabItem } from "@templates/TabbedPage.vue";
import DashboardEcommerce from "@organisms/Dashboard/DashboardEcommerce.vue";
import DashboardContacts from "@organisms/Dashboard/DashboardContacts.vue";
import DashboardEmails from "@organisms/Dashboard/DashboardEmails.vue";
import DashboardRFM from "@organisms/Dashboard/RFM/DashboardRFM.vue";
import DashboardPlanUsage from "@organisms/Dashboard/DashboardPlanUsage.vue";
import PeriodicReportConfigModal from "@organisms/Account/PeriodicReport/PeriodicReportConfigModal.vue";
import CurrencyAndPlanCostConfigModal from "@organisms/Account/CurrencyAndPlanCost/CurrencyAndPlanCostConfigModal.vue";
import PageTitle from "@molecules/PageTitle.vue";
import PeriodSelectorMenu from "@molecules/PeriodSelectorMenu.vue";
import BatchTypeSelectorMenu from "@molecules/BatchTypeSelectorMenu.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItems, DataItem } from "@molecules/DropDownButton";

// Icons
import { ShoppingBagIcon, UsersIcon, MailIcon } from "@heroicons/vue/solid";
import { MailIcon as MailIconOutline, DocumentDownloadIcon, CurrencyDollarIcon } from "@heroicons/vue/outline";
import LifeCycleIcon from "@tokens/icons/LifeCycleIcon.vue";

//Store
import { storeToRefs } from "pinia";
import { useIntegrationsStore, useSessionStore, useUserConfigStore } from "@store";

// Utils
import { useI18n } from "vue-i18n";
import { differenceInHours, startOfDay, subDays, endOfDay } from "date-fns";
import { useNotifications } from "@/vue/composables/notifications";
import { useBreakpoints } from "@/vue/composables/breakpoints";
import { defaultNumberFormats, numberFormatFor } from "@/vue/locales/i18n";
import { isEmpty } from "lodash";

// Application
import { useDashboardApplication, useLocalStorageApp } from "@application";

// Services
import { useDashboard } from "@api/modules/dashboard";
import { useAccountPlan, IAccountPlan } from "@api/modules/accountplan";
import { useLifecycle } from "@api/modules/lifecycles/lifecycles";
import { useLists } from "@api/modules/lists/lists";
import { BatchType, DashboardData, IDashboardDataSet } from "@domain/dashboard/dashboard";
import { IAccountConfig, useAccountConfig } from "@/vue/api/modules/account";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Type
import type { Stats } from "@domain/lifecycle";
import type { Definitions } from "@domain/lifecycle";
import { getEmptyStats, getEmptyDefinition, getSumAllContacts } from "@domain/lifecycle";
import type { ContactsList } from "@domain/lists";

//Props
const props = withDefaults(
  defineProps<{
    initialTab?: "ecommerce" | "emails" | "contacts" | "buyers";
  }>(),
  {
    initialTab: undefined,
  },
);

// Emits
const emit = defineEmits<{
  (e: "planLoaded", plan: IAccountPlan): void;
  (e: "navigateTo", route: string): void;
  (e: "routeChanged", value: "ecommerce" | "emails" | "contacts" | "buyers"): void;
}>();

const { smBp } = useBreakpoints();

const { t, locale } = useI18n();
const $i18n = useI18n({ useScope: "global" });
const { notify } = useNotifications();
const Dashboard = useDashboard();
const integrationStore = useIntegrationsStore();
const AccountPlan = useAccountPlan();
const AccountConfig = useAccountConfig();
const lifecyclesAPI = useLifecycle();
const listsAPI = useLists();
const trackingEventsService = useTrackingEvents();
const dashboardApp = useDashboardApplication();
const localStorageApp = useLocalStorageApp();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const userConfigStore = useUserConfigStore();
const { dashBoardState } = storeToRefs(userConfigStore);
// const dashBoardStateRefs = toRefs(dashBoardState.value);

const updateEmailBatchType = (newBatchType: BatchType) => {
  dashBoardState.value = {
    ...toRaw(dashBoardState.value),
    emailBatchType: newBatchType,
  };
};

const isFreeAccount = session.value?.account?.plan?.type === "FREE";

const loading = ref(false);
const loadingTabs = ref(false);
const currentTab = ref<"ecommerce" | "emails" | "contacts" | "buyers" | undefined>(props.initialTab);
const dashboardData = ref<DashboardData>();
const periodFrom = ref<Date>();
const periodTo = ref<Date>();
const rawData = ref<IDashboardDataSet>();
const showSessions = ref(false);
const showROI = ref(false);
const accountPlan = ref<IAccountPlan>();
const accountConfig = ref<IAccountConfig>();
const tabs = computed<TabItem[]>(() => {
  const tabOptions: Array<TabItem> = [
    {
      id: "emails",
      label: t("tabs.emails"),
      icon: MailIcon,
    },
    {
      id: "contacts",
      label: t("tabs.contacts"),
      icon: UsersIcon,
    },
  ];

  if (isEcommerce.value) {
    tabOptions.unshift({
      id: "ecommerce",
      label: t("tabs.store"),
      icon: ShoppingBagIcon,
    });

    tabOptions.push({
      id: "buyers",
      label: t("tabs.buyers"),
      icon: markRaw(LifeCycleIcon),
    });
  }
  return tabOptions;
});

const updateRouteToDefault = () => {
  currentTab.value = "emails";
  emit("routeChanged", "emails");
};

const contactsLists = ref<ContactsList>([]);
const fetchLists = async () => {
  const lists = await listsAPI.get({
    tags: ["compradores"],
  });

  contactsLists.value = [...toRaw(contactsLists.value), ...lists.value];
};

const exportDashboard = () => {
  if (loading.value === true || loadingTabs.value === true || dashboardData.value === undefined) {
    notify({
      title: t("pdfExport.NotificationErrorInfoNotLoadedTitle"),
      theme: "error",
    });
  } else {
    toggleDashBoardToPDF(true);
  }
};

//Menu options
enum MenuOptionsKeys {
  configPeriodicReport,
  ConfigCurrencyAndPlanCost,
}
const menuOptions = computed<DataItems>(() => {
  const options: DataItems = [];

  if (isEcommerce.value) {
    options.push({
      key: MenuOptionsKeys.ConfigCurrencyAndPlanCost,
      icon: CurrencyDollarIcon,
      value: t("menuOptions.ConfigCurrencyAndPlanCost"),
    });
  }

  if (!window.config?.whiteLabel) {
    options.push({
      key: MenuOptionsKeys.configPeriodicReport,
      icon: MailIconOutline,
      value: t("menuOptions.PeriodicReports"),
    });
  }

  return options;
});

const showPeriodicReportConfigModal = ref(false);
const showCurrencyAndPlanCostConfigModal = ref(false);

const hasAdminRights = sessionStore.hasPermission("account:update");
const isEcommerce = ref(false);
const integrations = ref<Array<string>>([]);
const batchOptions = computed<Array<"all" | "bulk" | "automation" | "transactional">>(() => {
  const hasTransactional = integrations.value.find((i) => i === "transactional");
  if (hasTransactional !== undefined) return ["all", "bulk", "automation", "transactional"];

  return ["all", "bulk", "automation"];
});
const hadIntegration = ref(false);
const hasActiveIntegrations = ref(false);

const UpdateCurrentPeriodPresetID = (presetID: string) => {
  dashBoardState.value = {
    ...toRaw(dashBoardState.value),
    currentPeriodPresetID: presetID != "custom" ? presetID : undefined,
  };
};

const calculateDashboardData = async () => {
  if (periodFrom.value && periodTo.value && accountPlan.value && rawData.value) {
    dashboardData.value = dashboardApp.calculateDashboardData({
      data: rawData.value,
      periodFrom: periodFrom.value,
      periodTo: periodTo.value,
      planType: accountPlan.value?.type,
      planCost: accountConfig.value?.monthly_cost || accountPlan.value?.subscription?.price.total,
      batchType: dashBoardState.value.emailBatchType,
      attributionModel: accountConfig.value?.attribution_model ?? "opens",
    });

    hadIntegration.value =
      dashboardData.value?.store.total.current.count > 0 ||
      dashboardData.value?.store.total.previous.count > 0 ||
      dashboardData.value?.store.sessions.current.total > 0 ||
      dashboardData.value?.store.sessions.previous.total > 0;

    if (!isEcommerce.value && hasAdminRights && hadIntegration.value) {
      isEcommerce.value = true;

      window.Intercom("trackEvent", "visit-dashboard", {
        isEcommerce: isEcommerce.value,
        lang: locale.value.substr(0, 2),
      });
    }

    if (!isEcommerce.value && (!currentTab.value || ["ecommerce", "buyers"].includes(currentTab.value))) {
      updateRouteToDefault();
    }
  }
};

const HandleCloseCurrencyAndPlanCostConfig = async (newConfig) => {
  accountConfig.value = newConfig;
  showCurrencyAndPlanCostConfigModal.value = false;
  if (newConfig.currency) {
    $i18n.setNumberFormat($i18n.locale.value, numberFormatFor(newConfig.currency));
  } else {
    $i18n.setNumberFormat($i18n.locale.value, defaultNumberFormats[$i18n.locale.value]);
  }
  await calculateDashboardData();
};

const handlerMenuOptions: Record<MenuOptionsKeys, () => void> = {
  [MenuOptionsKeys.configPeriodicReport]: () => {
    setTimeout(() => (showPeriodicReportConfigModal.value = true), 100);
  },
  [MenuOptionsKeys.ConfigCurrencyAndPlanCost]: () => {
    setTimeout(() => (showCurrencyAndPlanCostConfigModal.value = true), 100);
  },
};

const HandleMenuOptionSelection = (option: DataItem) => handlerMenuOptions[option.key]?.();

//Hidden Dashboard PDF
const showHiddenDashboardPDF = ref(false);

const toggleDashBoardToPDF = (value: boolean) => {
  if (showHiddenDashboardPDF.value && value) {
    notify({
      title: t("pdfExport.NotificationExportInProgressTitle"),
      theme: "error",
    });
    return;
  }

  value &&
    notify({
      title: t("pdfExport.NotificationGeneratingPDFTitle"),
      text: t("pdfExport.NotificationGeneratingPDFText"),
      theme: "success",
      timeout: 10000,
    });

  showHiddenDashboardPDF.value = value;
};

const goToList = (listId: string) => {
  // emit("navigateTo", `lists/${listId}`);
  emit("navigateTo", `contacts?filter.list=${listId}`);
};

const hasRfmLifecycle = ref<boolean>(false);
const rfmCreating = ref<boolean>(false);
const lifeCycleStats = ref<Stats>(getEmptyStats());
const lifeCycleDefinition = ref<Definitions>(getEmptyDefinition());
const contactsCount = ref(0);
const loadingStats = ref(false);
const loadingActive = ref(false);
let lifecycleCreated = "";

watch(isEcommerce, async () => {
  localStorageApp.save({
    id: "isEcommerce",
    value: isEcommerce.value,
  });

  if (!isEcommerce.value) return;

  if (currentTab.value === undefined) currentTab.value = "ecommerce";

  if (!hasRfmLifecycle.value) {
    lifeCycleStats.value = getEmptyStats();
  }
});

watch(hasRfmLifecycle, async () => {
  if (!hasRfmLifecycle.value) return;

  const hoursLoadingStats = 1;
  rfmCreating.value = hasRfmLifecycle.value
    ? hoursLoadingStats > differenceInHours(new Date(), new Date(lifecycleCreated))
    : false;

  await getRFMStats();
});

const getRFMStats = async () => {
  loadingStats.value = true;
  lifeCycleStats.value = hasRfmLifecycle.value ? await lifecyclesAPI.stats("rfm") : getEmptyStats();

  contactsCount.value = getSumAllContacts(lifeCycleStats.value);
  loadingStats.value = false;
};

const activateRFM = async () => {
  if (isFreeAccount || hasRfmLifecycle.value) return;

  try {
    loadingActive.value = true;
    await lifecyclesAPI.activate("rfm");
    lifecycleCreated = new Date().toISOString();
    hasRfmLifecycle.value = true;
    rfmCreating.value = true;
    await getRFMStats();

    trackingEventsService.dispatchAll({
      name: "APP_LIFECYCLE_RFM_ENABLE",
      includeMasterUsers: true,
    });
  } catch (e) {
    notify({
      title: t("activateRFM.errorActivatingTitle"),
      text: t("activateRFM.errorActivatingDescription"),
      theme: "error",
    });
  } finally {
    loadingActive.value = false;
  }
};

onMounted(async () => {
  loadingTabs.value = true;

  //Si no es marca blanca agrega reportes periodicos al menu

  try {
    const lastDay = endOfDay(subDays(new Date(), 1));
    const calcFrom = (days: number) => startOfDay(subDays(lastDay, days - 1));

    loadingStats.value = true;

    await integrationStore.fetchIntegrations();
    integrations.value = integrationStore.integrations;

    hasActiveIntegrations.value = integrations.value.some((i) =>
      [
        "tiendanube",
        "vtex",
        "jumpseller",
        "woocommerce",
        "shopify",
        "mshops",
        "pow",
        "signos",
        "tray",
        "bootic",
        "wbuy",
        "vnda",
      ].includes(i),
    );

    if (integrations.value.some((i) => ["tiendanube", "tray", "vtex", "pow", "wbuy"].includes(i))) {
      showSessions.value = true;
    }

    if (!isEcommerce.value && hasAdminRights && hasActiveIntegrations.value) {
      isEcommerce.value = true;
    }

    await Promise.all([
      AccountPlan.get().then((res) => {
        accountPlan.value = res;
      }),
      AccountConfig.get().then((res) => {
        accountConfig.value = res;
      }),
      fetchLists(),
      lifecyclesAPI.definitions("rfm").then((res) => {
        if (isEmpty(res)) {
          loadingStats.value = false;
          return;
        }

        lifeCycleDefinition.value = res;
        hasRfmLifecycle.value = !!res.id;
        lifecycleCreated = res.created;
      }),
    ]);

    accountConfig.value?.currency &&
      $i18n.setNumberFormat($i18n.locale.value, numberFormatFor(accountConfig.value.currency));

    if (accountPlan.value) {
      emit("planLoaded", accountPlan.value);
    }

    showROI.value = accountPlan.value?.type === "MONTHLY";

    window.Intercom("trackEvent", "visit-dashboard", {
      isEcommerce: isEcommerce.value,
      lang: locale.value.substr(0, 2),
    });
  } finally {
    loadingTabs.value = false;
  }
});

watch([periodFrom, periodTo], async (newValues, prevValues) => {
  const [newFrom, newTo] = [...newValues];
  const [prevFrom, prevTo] = [...prevValues];

  if (
    periodFrom.value &&
    periodTo.value &&
    (newFrom?.getTime() != prevFrom?.getTime() || newTo?.getTime() != prevTo?.getTime())
  ) {
    rawData.value = await Dashboard.get(periodFrom.value, periodTo.value, loading);

    if (dashBoardState.value.currentPeriodPresetID === "last30d") {
      localStorageApp.save({
        id: "dashboardData",
        value: rawData.value,
      });
    }
  }
});

watch([rawData, accountPlan], async () => {
  await calculateDashboardData();
});

watch([dashBoardState], async ([newDashboardState], [oldDashboardState]) => {
  if (newDashboardState.emailBatchType === oldDashboardState.emailBatchType) return;

  await calculateDashboardData();
});

watch(currentTab, () => {
  if (!currentTab.value) return updateRouteToDefault();
  emit("routeChanged", currentTab.value);
});
</script>

<template>
  <TabbedPage v-model:selectedTabId="currentTab" :tabs="tabs" :loading="loadingTabs">
    <template #header>
      <PageHeader>
        <template #title>
          <PageTitle
            size="extraLarge"
            :links="{
              es: {
                helpId: 5901198,
                tourId: isEcommerce ? 286337 : 286642,
                // youTubeLink: 'playlist?list=PL4rs-NKD6E-eQ4Oq-pTaGda_Tmny4yxVJ',
              },
              pt: {
                helpId: 5901198,
                tourId: isEcommerce ? 286549 : 287112,
                // youTubeLink: 'playlist?list=PL4rs-NKD6E-eGSM3ct-c8z3BeL5O_o9-q',
              },
            }"
          >
            Dashboard
          </PageTitle>
        </template>
        <template #subtitle>
          <DashboardPlanUsage :loading="loadingTabs" :account-plan="accountPlan" class="min-h-[2rem] pt-2" />
        </template>
        <template #right>
          <div class="flex space-x-4 pt-2">
            <SimpleButton theme="green-lime" @click="exportDashboard">
              <template #leading>
                <DocumentDownloadIcon class="m-0.5 h-4 w-4" />
              </template>
              {{ t("exportDashboard") }}
            </SimpleButton>
            <DropDownButton
              v-if="smBp"
              data-intercom-target="DashboardDropdownMenu"
              :navigation="menuOptions"
              placement="bottom-end"
              theme="black"
              :auto-placements="['bottom-end']"
              @update:selected="HandleMenuOptionSelection"
            />
          </div>
        </template>
      </PageHeader>
    </template>
    <template #right-pane>
      <div
        v-if="!loadingTabs"
        class="flex flex-col-reverse space-y-3 space-y-reverse sm:flex-row sm:space-x-3 sm:space-y-0"
      >
        <BatchTypeSelectorMenu
          v-if="currentTab === 'emails'"
          :model-value="dashBoardState.emailBatchType"
          :options="batchOptions"
          class="w-full sm:w-min"
          @update:model-value="(value) => updateEmailBatchType(value as BatchType)"
        />
        <div v-show="currentTab !== 'buyers'">
          <PeriodSelectorMenu
            v-model:from="periodFrom"
            v-model:to="periodTo"
            :selected-preset-id="dashBoardState.currentPeriodPresetID"
            class="w-full sm:w-min"
            @update:selectedPresetID="UpdateCurrentPeriodPresetID"
          />
        </div>

        <PeriodicReportConfigModal v-if="smBp" v-model:is-open="showPeriodicReportConfigModal" />
        <CurrencyAndPlanCostConfigModal
          v-if="smBp && isEcommerce"
          v-model:is-open="showCurrencyAndPlanCostConfigModal"
          @saved="HandleCloseCurrencyAndPlanCostConfig"
        />
      </div>
    </template>

    <template #ecommerce>
      <DashboardEcommerce
        :data="dashboardData"
        :loading="loading || loadingTabs"
        :show-sessions="showSessions"
        :show-roi="showROI"
        :show-warning-integrations="hadIntegration && !hasActiveIntegrations"
        :engagement-by-clicks="accountConfig?.attribution_model == 'clicks'"
    /></template>
    <template #emails>
      <DashboardEmails
        :data="dashboardData"
        :batch-type="dashBoardState.emailBatchType"
        :loading="loading || loadingTabs"
      />
    </template>
    <template #contacts><DashboardContacts :data="dashboardData" :loading="loading || loadingTabs" /></template>

    <template #buyers>
      <DashboardRFM
        :contacts-lists="contactsLists"
        :definitions="lifeCycleDefinition"
        :stats="lifeCycleStats"
        :contacts-count="contactsCount"
        :active="hasRfmLifecycle"
        :creating="rfmCreating"
        :loading="loadingStats"
        :loading-active="loadingActive"
        @activate="activateRFM"
        @go-to-list="goToList"
      />
    </template>
  </TabbedPage>
  <HiddenDashboardPDF
    v-if="showHiddenDashboardPDF && dashboardData && smBp"
    :show-roi="showROI"
    :period-from="periodFrom"
    :period-to="periodTo"
    :batch-type="dashBoardState.emailBatchType"
    :dashboard-data="dashboardData"
    :has-active-integrations="hasActiveIntegrations"
    :had-integration="hadIntegration"
    :show-sessions="showSessions"
    :has-admin-rights="hasAdminRights"
    class="absolute right-full top-full w-full"
    @pdf-downloaded="toggleDashBoardToPDF(false)"
  />
</template>

<i18n lang="jsonc">
{
  "es": {
    "exportDashboard": "Exportar dashboard",
    "menuOptions": {
      "PeriodicReports": "Configurar informes periódicos",
      "ConfigCurrencyAndPlanCost": "Configurar conversiones"
    },
    "pdfExport": {
      "NotificationErrorInfoNotLoadedTitle": "Por favor aguarda a que se termine de procesar la información.",
      "NotificationExportInProgressTitle": "Ya hay un proceso en marcha, por favor espera a que finalice.",
      "NotificationGeneratingPDFTitle": "Exportando a PDF...",
      "NotificationGeneratingPDFText": "La descarga del archivo se iniciará automáticamente en unos segundos."
    },
    "tabs": {
      "store": "Tienda",
      "emails": "Emails",
      "contacts": "Contactos",
      "buyers": "Ciclo de vida"
    }
  },
  "pt": {
    "exportDashboard": "Exportar dashboard",
    "menuOptions": {
      "PeriodicReports": "Configurar relatórios periódicos",
      "ConfigCurrencyAndPlanCost": "Configurar conversões"
    },
    "pdfExport": {
      "NotificationErrorInfoNotLoadedTitle": "Por favor, aguarde enquanto a informação é processada.",
      "NotificationExportInProgressTitle": "Já existe um processo sendo executado, por favor aguarde a finalização.",
      "NotificationGeneratingPDFTitle": "Exportando PDF...",
      "NotificationGeneratingPDFText": "O download do arquivo será iniciado automáticamente em alguns segundos."
    },
    "tabs": {
      "store": "Loja",
      "emails": "Emails",
      "contacts": "Contatos",
      "buyers": "Ciclo de vida"
    }
  }
}
</i18n>
