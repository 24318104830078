<template>
  <div class="h-full">
    <Teleport to="#audience-menuOptions">
      <div class="flex space-x-4">
        <PermissionsButton href="/contacts/new" :permissions="['contacts:create']" theme="green-lime">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createContactButton") }}
        </PermissionsButton>
        <DropDownButton
          theme="black"
          :navigation="options"
          position="bottom-end"
          :auto-placements="['bottom-end']"
          @update:selected="HandleMenuOptionSelection"
        />
      </div>
    </Teleport>
    <Teleport to="#audience-stickyContent">
      <FiltersPanel v-show="!firstLoad && (rows.length > 0 || selectedSearch.length > 0 || loadingTable)" class="z-30">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div class="flex items-center space-x-2">
            <span v-show="!loadingTable" class="text-sm text-gray-500">{{
              t("fieldCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount })
            }}</span>
            <span v-show="loadingTable" class="animate-pulse rounded bg-gray-100 px-12 py-2 text-sm" />
            <CheckboxListButton
              v-model:selected="selectedColumns"
              :label="t('columnsCheckBoxListButton')"
              :tooltip="t('columnsButton')"
              :items="columnItems"
              :max-selected="maxColLimit"
            />
          </div>
        </div>
      </FiltersPanel>
    </Teleport>
    <Table
      v-show="!loadingTable && rows.length > 0"
      :sort-by="sortBy"
      :columns="columns"
      :rows="rows"
      :selected-rows="selectedRows"
      :loading="loadingNext"
      :skeleton="skeletonTable"
      :skeleton-count="skeletonCount"
      :selectable="contactsUpdate"
      class="ml-6 h-full overflow-x-hidden bg-white pb-10"
      @update:sort-by="updateSortBy"
      @update:selected-rows="updateSelectedRows"
      @scroll-bottom="fetchNextContacts"
    >
      <template #email="{ row }">
        <EmailCell
          v-if="row"
          :id="row.id"
          :quality="Math.max(Number(row.data?.quality) ?? 0, 0)"
          :status="row.data?.state ?? ''"
        >
          {{ row.content?.email }}
        </EmailCell>
      </template>
      <template #email-skeleton>
        <div class="flex gap-2">
          <div class="my-4 w-60 animate-pulse rounded-md bg-sky-50 py-2" />
          <div class="my-4 mr-auto flex animate-pulse justify-center pr-6">
            <StarIcon v-for="index in 3" :key="index" class="h-4 w-4 text-gray-100" />
          </div>
        </div>
      </template>
      <template #lastModified="{ row }">
        <span v-if="row" class="mr-auto flex justify-start text-sm text-gray-500">
          {{ row.content?.created ? formatDate(new Date(row.content?.created), "MMM dd, HH:mm") : "" }}
        </span>
      </template>
      <template #lastModified-skeleton>
        <div class="my-4 mr-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #created="{ row }">
        <span v-if="row" class="flex w-max min-w-[5rem] flex-nowrap text-right text-sm text-gray-500">
          {{ row.content?.created ? launchDateFormatter(row.content?.created) : "-" }}
        </span>
      </template>
      <template #created-skeleton>
        <div class="my-4 mr-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #defaultCell="{ row, item }">
        <span v-if="row" class="mr-auto flex justify-start text-sm text-gray-500">
          {{ item }}
        </span>
      </template>
      <template #defaultCell-skeleton>
        <div class="my-4 mr-auto w-10 animate-pulse rounded-md bg-gray-50 py-2" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="getListSearch ? listOptionsListSelected : listOptions"
            theme="transparent-white"
            position="bottom-end"
            :auto-placements="['bottom-end', 'left']"
            :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="flex justify-center">
          <div class="my-4 w-4 animate-pulse rounded-md bg-gray-50 py-2" />
        </div>
      </template>
    </Table>
    <EmptyStateSimple
      v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
      :title="t('emptyState.title')"
      :message="t('emptyState.message')"
      :article-id="8593204"
      :article-text="t('emptyState.cleanSearch')"
    >
      <template #icon>
        <UserIcon class="h-16 w-16 stroke-1 text-sky-400" />
      </template>
    </EmptyStateSimple>
    <EmptySearchState
      v-show="!loadingTable && selectedSearch.length > 0 && rows.length === 0"
      @cleanSearch="cleanSearch"
    />
    <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>

    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selectedContacts",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(totalCount) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
        </template>
        <IconButton
          v-if="selectedRows?.length < totalCount"
          :label="t('selectAllButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="selectAll"
        >
          <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
      <div class="flex space-x-4">
        <IconButton
          :label="t('addToListsButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openAddToListModal"
        >
          <UserGroupIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
        <IconButton
          :label="t('addInterests')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openAddInterestsModal"
        >
          <HeartIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
        <IconButton
          v-show="getListSearch"
          :label="t('removeFromList')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openRemoveFromListModal"
        >
          <UserRemoveIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
        <PermissionsIconButton
          :permissions="['contacts:delete']"
          :label="t('deleteSelectedButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </PermissionsIconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>

    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteSelectedAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteContact"
      @cancel="closeDeleteListAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="exportAlert"
      v-bind="exportAlertModal"
      @accept="closeAndExportContacts"
      @cancel="closeExportAlertModal"
      ><template #acceptButtonLeading>
        <DownloadIcon class="h-5 w-5 text-sky-200 hover:text-sky-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="removeFromList"
      v-bind="removeFromListModal"
      @accept="closeAndRemoveContacts"
      @cancel="closeRemoveFromListModal"
    />
    <AddToListsModal
      v-if="addToListModalIsOpen"
      v-model:open="addToListModalIsOpen"
      :contacts="groupedSelectedRows"
      @close="closeAddToListModal"
      @added-to-lists="clearSelected"
    />
    <SubscribeToListsModal
      v-if="subscribeToListModalIsOpen"
      v-model:open="subscribeToListModalIsOpen"
      :contacts="groupedSelectedRows"
      :query="getFieldSearch && !isSelectedValueTag(getFieldSearch) ? getFieldSearch.value : undefined"
      :interest-id="getInterestSearch?.id"
      :list-id="getListSearch?.id"
      :state="getStatusSearch?.id"
      @close="closeSubcribeToListModal"
      @added-to-lists="clearSelected"
    />
    <AddInterestsModal
      v-if="addInterestModalIsOpen"
      v-model:open="addInterestModalIsOpen"
      :contacts="groupedSelectedRows"
      @close="closeAddInterestsModal"
      @interest-added="clearSelected"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive, watch, toRaw, onMounted } from "vue";

import {
  getMenuOptions,
  getColumns,
  contactsToRows,
  getListOptions,
  getListOptionsListSelected,
  columnsToList,
  filterColumns,
} from "./audienceContacts.data";
import type {
  FiltersIds,
  ContactsFilterData,
  ColumnsIds,
  ContactRows,
  ListOptionsKeys,
  OptionsKeys,
  ContactRow,
  SelectedFilterValues,
  SelectedFilterState,
  SelectedFilterInterest,
  SelectedFilterList,
  HighlightSearchDataItems,
} from "./audienceContacts.types";

// Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyStateSimple from "@molecules/EmptyState/EmptyStateSimple.vue";
import EmailCell from "./components/EmailCell.vue";
import AddToListsModal from "./components/AddToListsModal.vue";
import SubscribeToListsModal from "./components/SubscribeToListModal.vue";
import AddInterestsModal from "./components/AddInterestsModal.vue";
import FiltersPanel from "@templates/FiltersPanel";
import Table from "@molecules/Table";
import type { Columns, SortColumn, SortingModes } from "@molecules/Table";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import FilterInput from "@molecules/FilterInput";
import type { SelectedValueText } from "@molecules/FilterInput";
import { isSelectedValueTag } from "@molecules/FilterInput";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";
import CheckboxListButton from "@molecules/CheckboxListButton";
import type { Items } from "@molecules/CheckboxListButton";
import type { ListColumn } from "@domain/lists";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import {
  PlusIcon,
  HeartIcon as HeartIconSolid,
  MailIcon,
  UserGroupIcon as UserGroupIconSolid,
  UserCircleIcon,
  StarIcon,
  DownloadIcon,
} from "@heroicons/vue/solid";

import {
  UserIcon,
  UserGroupIcon,
  HeartIcon,
  DocumentIcon,
  DocumentDuplicateIcon,
  UserRemoveIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";
import { useBreakpoints } from "@composables/breakpoints";

// Utils
import { formatDate } from "@helpers/formatters";
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";

// I18n
import { useI18n } from "vue-i18n";

// Service
import { useContacts } from "@api/modules/contacts/contacts";
import { useLists } from "@api/modules/lists/lists";
import { useInterests } from "@api/modules/interests/interests";

// Store
import { useNotificationStore, useSessionStore, useAlertStore } from "@store";

// Application
import { useLocalStorageApp } from "@application";

// Domain
import type { ContactsList, ListColumns } from "@domain/lists";
import type { Interests } from "@domain/interests";
import { getPrimaryHighlightList } from "@domain/filters";

const { t, n } = useI18n();
const contactsAPI = useContacts();
const listsAPI = useLists();
const interestsAPI = useInterests();
const { notify } = useNotifications();
const { xxlBp, xlBp, lgBp, mdBp } = useBreakpoints();
const localStorageApp = useLocalStorageApp();
const notificationStore = useNotificationStore();

const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const contactsUpdate = sessionStore.hasPermission("contacts:update");

// Table
const fetchlimit = 20;
const skeletonTable = ref(false);
const skeletonCount = ref(5);
const calculateSkeletonCount = () => {
  if (rows.value.length > fetchlimit) {
    skeletonCount.value = fetchlimit;
    return;
  }

  skeletonCount.value = rows.value.length;
  return;
};

const firstLoad = ref(false);
const loadingTable = ref(false);
const loadingNext = ref(false);
const lists = ref<ContactsList>([]);
const columnsOrder = ref<ListColumns>([]);
const maxColLimit = 5;
const colLimits = computed(() => {
  if (xxlBp.value) return 5;
  if (xlBp.value) return 4;
  if (lgBp.value) return 3;
  if (mdBp.value) return 2;

  return 1;
});
const columnItems = computed<Items<string, string, ListColumn>>(() => {
  return columnsToList(toRaw(columnsOrder.value));
});
const selectedColumns = ref<Items<string, string, ListColumn>>([]);
const interests = ref<Interests>([]);
const columns = computed<Columns<ColumnsIds | string | "options">>(() => {
  return getColumns(selectedColumns.value, colLimits.value);
});
const rows = ref<ContactRows>([]);
const totalCount = ref<number>(0);
const selectedRows = ref<ContactRows>([]);
const selectedRow = ref<ContactRow>();
const groupedSelectedRows = computed<ContactRows>(() => {
  if (selectedRows.value.length > 0) return selectedRows.value;

  if (selectedRow.value) return [selectedRow.value];

  return [];
});

const selectAll = () => {
  selectedRows.value = rows.value;
};

const fetchListslimit = 100000;
const fetchColumnslimit = 100;
const fetchInterestsLimit = 100000;
const clearSelected = () => {
  selectedRows.value = [];
};
const savedSortBy = localStorageApp.get<SortColumn>({ id: "audience_contacts_sort_by" });
const sortBy = ref<SortColumn>(
  savedSortBy ?? {
    columnId: "created",
    sort: "DESC",
  },
);
const updateSortBy = (newSortBy: SortColumn) => {
  sortBy.value = newSortBy;
  localStorageApp.save<SortColumn>({ id: "audience_contacts_sort_by", value: sortBy.value });
};

const updateSelectedRows = (newRows: ContactRows) => {
  selectedRows.value = newRows;
};
const removeRows = (rowsToDelete: ContactRows) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  rows.value = filteredRows;
};
const removeRow = (rowToDelete: ContactRow) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return row.id !== rowToDelete.id;
  });
  rows.value = filteredRows;
};

const launchDateFormatter = (dateString: DateString) => {
  const date = new Date(dateString);
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  if (isThisYear) {
    return formatDate(date, "MMM dd, HH:mm");
  }

  return formatDate(date, "MMM dd, y");
};

const fetchNextContacts = async () => {
  if (totalCount.value > 0 && totalCount.value <= rows.value.length) return;
  await fetchContacts();
};

const fetchContacts = async () => {
  loadingNext.value = true;

  const contacts = await contactsAPI.get({
    sortBy: sortBy.value?.columnId,
    sortDir: sortBy.value?.sort === "DESC" ? "desc" : "asc",
    search: getFieldSearch.value && !isSelectedValueTag(getFieldSearch.value) ? getFieldSearch.value.value : undefined,
    limit: fetchlimit,
    listId: getListSearch.value?.id,
    interestId: getInterestSearch.value?.id,
    state: getStatusSearch.value?.id,
  });

  if (contacts.isNewRequest) {
    rows.value = contactsToRows(contacts.value);
    totalCount.value = contacts.total ?? 0;
    loadingNext.value = false;
    return;
  }

  rows.value = [...rows.value, ...contactsToRows(contacts.value)];

  loadingNext.value = false;
};

const fetchLists = async () => {
  const results = await listsAPI.get({
    limit: fetchListslimit,
  });

  lists.value = results.value;
};
let firstColFetch = true;
const fetchColumns = async () => {
  const results = await listsAPI.getColumns({
    limit: fetchColumnslimit,
  });

  columnsOrder.value = filterColumns(results);
  selectedColumns.value = columnsToList(columnsOrder.value.filter((c) => c.shown));
};
const saveColumns = async () => {
  await listsAPI.saveColumns({
    ids: selectedColumns.value.reduce((ids, s) => {
      const columnId = s.data?.id.toString();
      if (!columnId) return ids;

      ids.push(columnId);
      return ids;
    }, [] as Array<string>),
  });
};
watch([selectedColumns], async () => {
  if (firstColFetch) {
    firstColFetch = false;
    return;
  }
  await saveColumns();
});

const fetchInterests = async () => {
  const results = await interestsAPI.list({
    limit: fetchInterestsLimit,
  });

  interests.value = results.value;
};

const exportAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("exportAlert.confirmationTitle"),
  message: t("exportAlert.confirmationMessage"),
  acceptText: t("exportAlert.confirmButton"),
  cancelText: t("exportAlert.cancelButton"),
  acceptLoading: false,
  severity: "info",
});

const closeExportAlertModal = () => {
  exportAlertModal.open = false;
};

const openExportAlertModal = () => {
  exportAlertModal.open = true;
};

const closeAndExportContacts = async () => {
  closeExportAlertModal();
  await exportContacts();
};

const exportContacts = async () => {
  await contactsAPI.export({
    query: getFieldSearch.value && !isSelectedValueTag(getFieldSearch.value) ? getFieldSearch.value.value : undefined,
    interestId: getInterestSearch.value?.id,
    listId: getListSearch.value?.id,
    state: getStatusSearch.value?.id,
  });
  await notificationStore.fetchTasks();

  notify({
    title: t("notifications.export.title"),
    text: t("notifications.export.text"),
  });
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.email ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.email ?? "" });
  deleteAlertModal.open = true;
};

const deleteContact = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await contactsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;
  } finally {
    totalCount.value = totalCount.value - 1;
    removeRow(rowToDelete);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchContacts();
    }
    closeDeleteListAlertModal();
    notify({
      title: t("notifications.delete.title"),
      text: t("notifications.delete.text"),
      theme: "success",
    });
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) => contactsAPI.delete({ id: selected.id }));
    await Promise.all(deletePromises);
  } finally {
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    sessionStore.fetchPlan();
    totalCount.value = totalCount.value - selectedRows.value.length;
    removeRows(selectedRows.value);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchContacts();
    }
    clearSelected();
  }
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

const removeFromListBulk = async () => {
  const listId = getListSearch.value?.id;
  if (selectedRows.value.length === 0 && selectedRow.value) {
    selectedRows.value = [toRaw(selectedRow.value)];
  }
  if (!selectedRows.value || !listId) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) =>
      contactsAPI.removeFromList({ contactId: selected.data.id.toString(), listId: listId.toString() }),
    );
    await Promise.all(deletePromises);
  } finally {
    notify({
      title: t("notifications.removeFromListBulk.title"),
      text: t("notifications.removeFromListBulk.text"),
      theme: "success",
    });
    removeRows(selectedRows.value);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchContacts();
    }
    clearSelected();
  }
};

const addToListModalIsOpen = ref(false);
const closeAddToListModal = () => {
  addToListModalIsOpen.value = false;
  selectedRow.value = undefined;
};
const openAddToListModal = () => {
  addToListModalIsOpen.value = true;
};

const subscribeToListModalIsOpen = ref(false);
const closeSubcribeToListModal = () => {
  subscribeToListModalIsOpen.value = false;
  selectedRow.value = undefined;
};
const openSubscribeToListModal = () => {
  subscribeToListModalIsOpen.value = true;
};

const addInterestModalIsOpen = ref(false);
const closeAddInterestsModal = () => {
  addInterestModalIsOpen.value = false;
  selectedRow.value = undefined;
};
const openAddInterestsModal = () => {
  addInterestModalIsOpen.value = true;
};

const removeFromListModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("removeFromListAlert.confirmationTitle"),
  message: t("removeFromListAlert.confirmationMessage"),
  acceptText: t("removeFromListAlert.confirmButton"),
  cancelText: t("removeFromListAlert.cancelButton"),
  acceptLoading: false,
  severity: "info",
});

const closeRemoveFromListModal = () => {
  removeFromListModal.open = false;
};

const openRemoveFromListModal = () => {
  const listName = getListSearch.value && !isSelectedValueTag(getListSearch.value) ? getListSearch.value.value : "";

  removeFromListModal.message = t(
    "removeFromListAlert.confirmationMessage",
    { list: listName ?? "", count: selectedRows.value.length },
    { plural: selectedRows.value.length },
  );
  removeFromListModal.open = true;
};

const closeAndRemoveContacts = async () => {
  closeRemoveFromListModal();
  await removeFromListBulk();
};

// Search
const selectedSearch = ref<SelectedFilterValues>([]);
const filterInputIsOpen = ref(false);
const highlightSearches = ref<HighlightSearchDataItems>([]);

const cleanSearch = () => {
  selectedSearch.value = [];
};

const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const getFieldSearch = computed(() => toRaw(selectedSearch.value).find((search) => search.filterId === "email"));
const getListSearch = computed<SelectedFilterList | undefined>(
  () => toRaw(selectedSearch.value).find((search) => search.filterId === "list") as SelectedFilterList | undefined,
);
const getInterestSearch = computed<SelectedFilterInterest | undefined>(
  () =>
    toRaw(selectedSearch.value).find((search) => search.filterId === "interest") as SelectedFilterInterest | undefined,
);
const getStatusSearch = computed<SelectedFilterState | undefined>(
  () => toRaw(selectedSearch.value).find((search) => search.filterId === "state") as SelectedFilterState | undefined,
);

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<HighlightSearchDataItems | undefined>({ id: "audience_contacts_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as HighlightSearchDataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<HighlightSearchDataItems>({ id: "audience_contacts_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedFilterValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "email");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "email");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const filterData = computed<ContactsFilterData>(() => {
  return {
    filters: [
      { id: "email", text: t("filters.email"), icon: MailIcon, highlightList: highlightSearches.value },
      {
        id: "list",
        text: t("filters.list"),
        icon: UserGroupIconSolid,
        list: lists.value.map((list) => ({
          key: list.id.toString(),
          value: list.name,
          content: {
            id: list.id.toString(),
          },
          data: list,
        })),
      },
      {
        id: "interest",
        text: t("filters.interest"),
        icon: HeartIconSolid,
        list: interests.value.map((interest) => ({
          key: interest.id.toString(),
          value: interest.name,
          content: {
            id: interest.id.toString(),
          },
          data: interest,
        })),
      },
      {
        id: "state",
        text: t("filters.state"),
        icon: UserCircleIcon,
        list: [
          {
            key: "ACTIVE",
            value: t("contactStates.ACTIVE"),
            content: {
              id: "ACTIVE",
            },
          },
          {
            key: "LIVE",
            value: t("contactStates.LIVE"),
            id: "LIVE",
          },
          {
            key: "NOT_LIVE",
            value: t("contactStates.NOT_LIVE"),
            id: "NOT_LIVE",
          },
          {
            key: "BOUNCED",
            value: t("contactStates.BOUNCED"),
            id: "BOUNCED",
          },
          {
            key: "UNSUBSCRIBED",
            value: t("contactStates.UNSUBSCRIBED"),
            id: "UNSUBSCRIBED",
          },
          {
            key: "COMPLAINED",
            value: t("contactStates.COMPLAINED"),
            id: "COMPLAINED",
          },
          {
            key: "INACTIVE",
            value: t("contactStates.INACTIVE"),
            id: "INACTIVE",
          },
        ],
      },
    ],
    filtersRoles: {
      primary: "email",
    },
  };
});

// Options
const options = getMenuOptions();
const handleSelectedMenuOption: Record<OptionsKeys, () => void> = {
  export: () => {
    openExportAlertModal();
  },
  subscribe: async () => {
    openSubscribeToListModal();
  },
};

const HandleMenuOptionSelection = async (option: DropDownDataItem) => {
  if (!contactsUpdate) {
    alertStore.showPermissionDenied(["contacts:update"]);
    return;
  }

  await handleSelectedMenuOption[option.key]?.();
};

const listOptions = getListOptions();
const listOptionsListSelected = getListOptionsListSelected();
const listOptionHandler: Record<ListOptionsKeys, (row: ContactRow) => void> = {
  addList: (row) => {
    selectedRow.value = row;
    openAddToListModal();
  },
  addInterest: (row) => {
    selectedRow.value = row;
    openAddInterestsModal();
  },
  removeFromList: (row) => {
    selectedRow.value = row;
    selectedRows.value = [toRaw(row)];
    openRemoveFromListModal();
  },
  delete: (row) => {
    if (!sessionStore.hasPermission("contacts:delete")) {
      alertStore.showPermissionDenied(["contacts:delete"]);
      return;
    }

    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: ContactRow) => {
  if (!contactsUpdate) {
    alertStore.showPermissionDenied(["contacts:update"]);
    return;
  }

  await listOptionHandler[option.key]?.(row);
};

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    email: "q",
    list: "filter.list",
    interest: "filter.interest",
    state: "filter.state",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

const updateQueryParams = () => {
  cleanQueryParams();

  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    if (isSelectedValueTag(selected)) {
      updateQueryParam({ key: paramName, value: selected.tag.id });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  if (sortBy.value) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  }
};

watch([selectedSearch], async () => {
  updateQueryParams();

  loadingTable.value = true;
  await fetchContacts();
  loadingTable.value = false;
});

watch([sortBy], async () => {
  calculateSkeletonCount();
  skeletonTable.value = true;

  updateQueryParams();

  await fetchContacts();
  skeletonTable.value = false;
});

const searchFilterText = (filterId: FiltersIds, id: string) => {
  if (filterId === "list") {
    return lists.value.find((l) => l.id.toString() === id)?.name;
  }
  if (filterId === "interest") {
    return interests.value.find((l) => l.id.toString() === id)?.name;
  }
  if (filterId === "state") {
    return t(`contactStates.${id}`);
  }
};

onMounted(async () => {
  firstLoad.value = true;
  loadingTable.value = true;

  await Promise.all([fetchLists(), fetchInterests(), fetchColumns()]);

  const queryParams = getQueryParams();

  const arraySearch: SelectedFilterValues = [];
  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key) as FiltersIds;

    if (!filterId) return;

    arraySearch.push({
      filterId,
      id: value,
      value: searchFilterText(filterId, value) ?? "",
    });
  });
  selectedSearch.value = arraySearch;

  const sortParams = queryParams?.get("sort")?.split(" ");

  if (!sortParams) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  } else {
    sortBy.value = {
      columnId: sortParams?.[0],
      sort: sortParams?.[1] as SortingModes,
    };
  }

  insertRecommmendedNames();

  await fetchContacts();

  loadingTable.value = false;
  firstLoad.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderSearch": "Buscar contactos",
    "columnsCheckBoxListButton": "Mostrar columnas",
    "columnsButton": "Columnas",
    "createContactButton": "Crear contacto",
    "addToListsButton": "Agregar a listas",
    "addInterests": "Agregar intereses",
    "removeFromList": "Quitar de la lista",
    "deleteSelectedButton": "Eliminar",
    "fieldCount": "0 contactos | 1 contacto | {count} contactos",
    "selectedContacts": "1 contacto seleccionado de {total} | {count} contactos seleccionados de {total}",
    "closeButton": "Cancelar",
    "selectAllButton": "Seleccionar todo",
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar contacto",
      "confirmationMessage": "Se eliminará 1 contacto seleccionado. ¿Deseas continuar? | Se eliminarán {count} contactos seleccionados. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "emptyState": {
      "title": "Aún no tienes contactos",
      "message": "Cuando integres tu tienda o importes una lista, aquí podrás ver y buscar todos tus contactos.",
      "cleanSearch": "Aprender más sobre audiencia"
    },
    "columns": {
      "email": "Email",
      "created": "Creado",
      "state": "Estado"
    },
    "menuOptions": {
      "export": "Exportar",
      "subscribe": "Agregar a listas"
    },
    "listOptions": {
      "addList": "Agregar a lista",
      "addInterest": "Asignar interés",
      "removeFromList": "Quitar de la lista",
      "delete": "Eliminar"
    },
    "filters": {
      "email": "Email",
      "list": "Lista",
      "interest": "Interés",
      "state": "Estado"
    },
    "contactStates": {
      "ACTIVE": "Habilitados",
      "LIVE": "Con actividad",
      "NOT_LIVE": "Sin actividad",
      "BOUNCED": "Inhabilitados (rebotes)",
      "UNSUBSCRIBED": "Inhabilitados (desuscriptos)",
      "COMPLAINED": "Inhabilitados (denuncias)",
      "INACTIVE": "Inhabilitados (todos)"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar contacto",
      "confirmationMessage": "Se eliminará el contacto {name}. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "exportAlert": {
      "confirmationTitle": "Exportar contactos",
      "confirmationMessage": "Los contactos se exportarán utilizando los filtros actuales. Se notificará cuando los resultados estén listos para descargar.",
      "confirmButton": "Exportar",
      "cancelButton": "Cancelar"
    },
    "removeFromListAlert": {
      "confirmationTitle": "Quitar contactos",
      "confirmationMessage": "Se removerá 1 contacto de la lista '{list}'. ¿Desea continuar? | Se removerán {count} contactos de la lista '{list}'. ¿Desea continuar?",
      "confirmButton": "Remover",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Contacto eliminado",
        "text": "El contacto fue eliminado correctamente."
      },
      "deleteBulk": {
        "title": "Contactos eliminados",
        "text": "Los contactos fueron eliminados correctamente."
      },
      "removeFromListBulk": {
        "title": "Contactos quitados",
        "text": "Los contactos han sido removidos de la lista."
      },
      "export": {
        "title": "Exportando contactos",
        "text": "Los contactos están siendo exportados."
      }
    }
  },
  "pt": {
    "placeholderSearch": "Pesquisar contatos",
    "columnsCheckBoxListButton": "Mostrar colunas",
    "columnsButton": "Colunas",
    "createContactButton": "Criar contato",
    "addToListsButton": "Adicionar às listas",
    "addInterests": "Adicionar interesses",
    "removeFromList": "Remover da lista",
    "deleteSelectedButton": "Excluir",
    "fieldCount": "0 contatos | 1 contato | {count} contatos",
    "selectedContacts": "1 contato selecionado de {total} | {count} contatos selecionados de {total}",
    "closeButton": "Cancelar",
    "selectAllButton": "Selecionar tudo",
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir contato",
      "confirmationMessage": "Será excluído 1 contato selecionado. Deseja continuar? | Serão excluídos {count} contatos selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "emptyState": {
      "title": "Ainda não tem contatos",
      "message": "Quando integrar a sua loja ou importar uma lista, poderá ver e procurar todos os seus contatos aqui.",
      "cleanSearch": "Aprender mais sobre audiência"
    },
    "columns": {
      "email": "E-mail",
      "created": "Criado",
      "state": "Estado"
    },
    "menuOptions": {
      "export": "Exportar",
      "subscribe": "Adicionar às listas"
    },
    "listOptions": {
      "addList": "Adicionar à lista",
      "addInterest": "Atribuir interesse",
      "delete": "Excluir",
      "removeFromList": "Remover da lista"
    },
    "filters": {
      "email": "E-mail",
      "list": "Lista",
      "interest": "Interesse",
      "state": "Estado"
    },
    "contactStates": {
      "ACTIVE": "Ativos",
      "LIVE": "Com atividade",
      "NOT_LIVE": "Sem atividade",
      "BOUNCED": "Inativos (rejeitados)",
      "UNSUBSCRIBED": "Inativos (descadastrados)",
      "COMPLAINED": "Inativos (denúncias)",
      "INACTIVE": "Inativos (todos)"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir contato",
      "confirmationMessage": "O contato {name} será excluído. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "exportAlert": {
      "confirmationTitle": "Exportar contatos",
      "confirmationMessage": "Os contatos serão exportados usando os filtros atuais. Você será notificado quando os resultados estiverem prontos para download.",
      "confirmButton": "Exportar",
      "cancelButton": "Cancelar"
    },
    "removeFromListAlert": {
      "confirmationTitle": "Remover contatos",
      "confirmationMessage": "Será removido 1 contato da lista '{list}'. Deseja continuar? | Serão removidos {count} contatos da lista '{list}'. Deseja continuar?",
      "confirmButton": "Remover",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Contato excluído",
        "text": "O contato foi excluído com sucesso."
      },
      "deleteBulk": {
        "title": "Contatos excluídos",
        "text": "Os contatos foram excluídos com sucesso."
      },
      "export": {
        "title": "Exportando contatos",
        "text": "Os contatos estão sendo exportados."
      },
      "removeFromListBulk": {
        "title": "Contatos removidos",
        "text": "Os contatos foram removidos da lista."
      }
    }
  }
}
</i18n>
