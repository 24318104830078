import { SelectorView } from "@/core/magnet";
import selectorTpl from "../templates/selector.hbs";
import selectorSnippet from "../templates/selectorSnippet.hbs";

export default SelectorView.extend({
  module: "lists",
  templates: {
    initial: selectorTpl,
    selectorSnippet,
  },
  cache: false,
  query: {
    order: "name asc",
  },
  checkboxInputName: "lists[]",
  radioInputName: "list",
});
