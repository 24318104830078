// Services
import { useGeoLocationService } from "@services";

// Utils
import { err, ok } from "neverthrow";

// Types
import { useLocalStorageApp } from "@application";
import type { GeoLocationApplication } from "./geoLocation.types";
import type { CountryCode } from "@domain/countries";

export const useGeoLocationApplication = (): GeoLocationApplication => {
  const localStorageApp = useLocalStorageApp();
  const geoLocationService = useGeoLocationService();

  const locationApp: GeoLocationApplication = {
    detectCountry: async () => {
      const savedCountry = localStorageApp.getGlobal<CountryCode>({ id: "detectedCountry" });
      if (savedCountry) return ok(savedCountry);

      const res = await geoLocationService.detectGeoLocation();

      if (res.isErr()) {
        return err({
          type: "NOT_DETECTED",
        });
      }

      const detectedCountry: CountryCode = res.value.country.toLocaleLowerCase() as CountryCode;

      localStorageApp.saveGlobal({ id: "detectedCountry", value: detectedCountry });

      return ok(detectedCountry);
    },
  };

  return locationApp;
};
