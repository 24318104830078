<template>
  <div class="max-w-xs" style="min-width: 15rem">
    <div v-if="loading" class="mb-1 animate-pulse">
      <div class="mb-3 h-1 w-full rounded-xl bg-gray-500"></div>
      <div class="mb-1.5 flex justify-between">
        <div class="h-1.5 w-16 rounded-xl bg-gray-500"></div>
        <div class="h-1.5 w-12 rounded-xl bg-gray-500"></div>
      </div>
    </div>

    <div v-if="!loading" ref="divRef">
      <div class="mb-1 h-1 w-full overflow-hidden rounded-lg bg-gray-500">
        <div
          :style="`width: ${filledPerc <= 0 ? 1 : filledPerc >= 100 ? 100 : filledPerc}%`"
          :class="[danger ? 'bg-red-300' : warning ? 'bg-yellow-200' : 'bg-white']"
          class="h-1 rounded-lg transition-all duration-300"
        ></div>
      </div>
      <div class="flex justify-between space-x-4 text-sm">
        <div class="flex-grow whitespace-nowrap font-normal text-gray-400">
          <slot name="title"></slot>
        </div>
        <div
          :class="[danger ? 'text-red-200' : warning ? 'text-yellow-100' : 'text-white']"
          class="flex-grow-0 whitespace-nowrap font-medium"
        >
          <slot name="description"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRef } from "vue";

//Utils
import { useTooltip } from "@composables/tooltips";

const props = withDefaults(
  defineProps<{
    filledPerc: number;
    warning: boolean;
    danger: boolean;
    loading: boolean;
    tooltip?: string;
  }>(),
  {
    filledPerc: 0,
    warning: false,
    danger: false,
    loading: false,
    tooltip: "",
  }
);

const divRef = ref();
const contentToRef = toRef(props, "tooltip");
contentToRef.value && useTooltip(divRef, contentToRef, { placement: "bottom-start", zIndex: 9999 });
</script>
