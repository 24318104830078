import { ref } from "vue";
import { defineStore } from "pinia";

// utils
import { getFetchDebounce } from "@store";

// Ports
import type { AccountUpdateParams } from "@application/ports";

// Services
import { useAccountConfig } from "@api/modules/account";
import { useAccountService } from "@services";

// Type
import type { IAccountConfig } from "@api/modules/account";
import type { Fonts } from "@domain/fonts";
import type { AccountData, AccountConfig } from "@domain/account";
import type { Ecommerce } from "@domain/ecommerce";

export const useAccountStore = defineStore("accountStore", () => {
  const accountService = useAccountService();
  const accountData = ref<AccountData>();
  const accountConfigData = ref<AccountConfig>();
  const accountDataFetched = ref<boolean>(false);
  const accountConfigDataFetched = ref<boolean>(false);

  const getAccountData = () => {
    return accountData.value;
  };

  const fetchAccountData = getFetchDebounce(async () => {
    const [res, resConfig] = await Promise.all([accountService.get(), accountService.getConfig()]);

    if (res.isErr()) return;

    accountData.value = res.value;
    accountDataFetched.value = true;

    if (resConfig.isErr()) return;

    accountConfigData.value = resConfig.value;
    accountConfigDataFetched.value = true;
  });

  const setAccountData = async (p: AccountUpdateParams) => {
    const res = await accountService.put({
      data: p.data,
    });

    if (res.isErr()) return;

    accountData.value = res.value;
  };

  const getStoreType = (): Ecommerce | undefined => {
    const store = accountConfigData.value?.store_type;
    return store;
  };

  // * -----Account Config
  const accountConfigService = useAccountConfig();
  const accountConfig = ref<IAccountConfig>();

  const getConfig = (): IAccountConfig | undefined => {
    return accountConfig.value;
  };

  const getFonts = (): Fonts | undefined => {
    return accountConfig.value?.fonts;
  };

  const getDefaultFonts = (): Fonts | undefined => {
    return accountConfig.value?.defaultFonts;
  };

  const fetchConfig = async () => {
    accountConfig.value = await accountConfigService.get();
  };

  return {
    accountData,
    accountConfigData,
    accountDataFetched,
    accountConfigDataFetched,
    getAccountData,
    fetchAccountData,
    setAccountData,
    getStoreType,
    getConfig,
    getFonts,
    getDefaultFonts,
    fetchConfig,
  };
});
