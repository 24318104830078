<template>
  <form class="space-y-6" @submit.prevent="save">
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
      <FormInput
        v-model="billingInfo.info.name"
        :label="t('legalNameLabel')"
        :placeholder="t('legalNamePlaceholder')"
        :error="v$.info.name.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.fiscal_id"
        :label="t('fiscalIdLabel')"
        :error="v$.info.fiscal_id.$error ? getFiscalIdErrorMessage(v$.info.fiscal_id.$errors) : undefined"
        placeholder="AAA000000A00"
      />
      <SelectMenuLabel
        :selected="selectedVat"
        :data-items="vats"
        label="Condición VAT"
        :rem-height="17"
        @update:selected="updateSelectedVat"
      />
      <FormInput
        v-model="billingInfo.email"
        :label="t('emailLabel')"
        placeholder="example@gmail.com"
        :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
      />

      <FormInput
        v-model="billingInfo.info.cp"
        label="Código postal"
        placeholder="01234"
        :error="v$.info.cp.$error ? getPostalCodeErrorMessage(v$.info.cp.$errors) : undefined"
      />
      <FormInput
        v-model="billingInfo.info.estado"
        label="Estado"
        :error="v$.info.estado.$error ? t('fieldRequired') : undefined"
        class="grow"
      />
      <FormInput
        v-model="billingInfo.info.municipio"
        label="Municipio"
        class="grow"
        :error="v$.info.municipio.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.colonia"
        label="Colonia"
        class="grow"
        :error="v$.info.colonia.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.calle"
        label="Calle"
        class="grow"
        :error="v$.info.calle.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.num_ext"
        label="Número exterior"
        class="grow"
        :error="v$.info.num_ext.$error ? t('fieldRequired') : undefined"
      />
      <FormInput v-model="billingInfo.info.num_int" label="Número interior" class="grow" />
    </div>
    <div class="flex justify-end space-x-2">
      <SimpleButton @click="close" theme="white">{{ t("close") }}</SimpleButton>
      <SimpleButton type="submit" :loading="loadingSave">
        <template #leading>
          <LockClosedIcon />
        </template>
        {{ t("save") }}
      </SimpleButton>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { reactive, watch, ref, computed } from "vue";

// Component
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItems, DataItem } from "@molecules/SelectMenuLabel.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Utils
import { cloneDeep } from "lodash";
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

// Composables
import { useNotifications } from "@composables/notifications";

// Service
import { useBillingInfoService } from "@services";
import type { MexicoBillingInfoData, MexicoVAT } from "@domain/billingInfo";

const { t } = useI18n();
const billingInfoService = useBillingInfoService();

const { notify } = useNotifications();

const props = withDefaults(
  defineProps<{
    billingData: MexicoBillingInfoData;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [];
  updateBillingData: [MexicoBillingInfoData];
}>();

const close = () => {
  emit("close");
  fillBillingInfo(props.billingData);
  v$.value.$reset();
};

const updateBillingData = (info: MexicoBillingInfoData) => {
  emit("updateBillingData", info);
};

const billingInfo = reactive<MexicoBillingInfoData>({
  country: "mx",
  email: "",
  info: {
    fiscal_id: "",
    fiscal_id_clean: "",
    name: "",
    estado: "",
    cp: "",
    calle: "",
    colonia: "",
    municipio: "",
    num_ext: "",
    vat_type_id: "601",
    vat_type: "601 - General de Ley Personas Morales",
    num_int: ""
  }
});
const testFiscalId = (value) => {
  const regex = new RegExp(/^[A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A]$/g);
  return regex.test(value);
};
const testPostalCode = (value) => {
  const regex = new RegExp(/^\d{5}(-\d{3})?$/);
  return regex.test(value);
};

const vats: DataItems<undefined, MexicoVAT> = [
  {
    key: "601",
    value: "601 - General de Ley Personas Morales"
  },
  {
    key: "603",
    value: "603 - Personas Morales con Fines no Lucrativos"
  },
  {
    key: "605",
    value: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios"
  },
  {
    key: "606",
    value: "606 - Arrendamiento"
  },
  {
    key: "607",
    value: "607 - Régimen de Enajenación o Adquisición de Bienes"
  },
  {
    key: "608",
    value: "608 - Demás ingresos"
  },
  {
    key: "610",
    value: "610 - Residentes en el Extranjero sin Establecimiento Permanente en México"
  },
  {
    key: "611",
    value: "611 - Ingresos por Dividendos (socios y accionistas)"
  },
  {
    key: "612",
    value: "612 - Personas Físicas con Actividades Empresariales y Profesionales"
  },
  {
    key: "614",
    value: "614 - Ingresos por intereses"
  },
  {
    key: "615",
    value: "615 - Régimen de los ingresos por obtención de premios"
  },
  {
    key: "616",
    value: "616 - Sin obligaciones fiscales"
  },
  {
    key: "620",
    value: "620 - Sociedades Cooperativas de Producción que Optan por Diferir sus Ingresos"
  },
  {
    key: "621",
    value: "621 - Incorporación Fiscal"
  },
  {
    key: "622",
    value: "622 - Actividades Agrícolas, Ganaderas, Silvícolas Y Pesqueras"
  },
  {
    key: "623",
    value: "623 - Opcional para Grupos de Sociedades"
  },
  {
    key: "624",
    value: "624 - Coordinados"
  },
  {
    key: "625",
    value: "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas"
  },
  {
    key: "626",
    value: "626 - Régimen Simplificado de Confianza"
  }
];

const selectedVat = computed(() => {
  const selected = vats.find((v) => v.key === billingInfo.info.vat_type_id);
  if (!selected)
    return {
      key: "601",
      value: "601 - General de Ley Personas Morales"
    };

  return selected;
});

const updateSelectedVat = (vat: DataItem<undefined, MexicoVAT>) => {
  billingInfo.info.vat_type_id = vat.key;
  billingInfo.info.vat_type = vat.value;
};

const validationRules = {
  country: { required },
  email: { required, email },
  info: {
    fiscal_id: {
      required,
      testFiscalId
    },
    name: { required },
    vat_type_id: { required },
    vat_type: { required },
    cp: { required, testPostalCode },
    calle: { required },
    num_ext: { required },
    estado: { required },
    municipio: { required },
    colonia: { required },
    num_int: { required }
  }
};
const v$ = useVuelidate(validationRules, billingInfo);

const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const getFiscalIdErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidFiscalId");
};

const getPostalCodeErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("InvalidPostalCode");
};

watch(
  () => billingInfo,
  (newValue, oldValue) => {
    for (const key in newValue) {
      if (newValue[key] !== oldValue[key]) {
        v$[key].$reset();
      }
    }
  },
  { deep: true }
);

watch(
  () => cloneDeep(billingInfo.info),
  (newInfo, oldInfo) => {
    for (const key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        v$.value.info?.[key]?.$reset();
      }
    }
  },
  { deep: true }
);

const loadingSave = ref(false);

const save = async () => {
  const validation = await v$.value.$validate();
  if (!validation || billingInfo.info.vat_type_id === "") return;

  loadingSave.value = true;

  const res = await billingInfoService.save({
    country: "mx",
    email: billingInfo.email,
    fiscal_id: billingInfo.info.fiscal_id ?? "",
    fiscal_id_clean: billingInfo.info.fiscal_id,
    name: billingInfo.info.name,
    vat_type_id: billingInfo.info.vat_type_id,
    vat_type: billingInfo.info.vat_type,
    cp: billingInfo.info.cp,
    estado: billingInfo.info.estado,
    municipio: billingInfo.info.municipio,
    colonia: billingInfo.info.colonia,
    calle: billingInfo.info.calle,
    num_ext: billingInfo.info.num_ext,
    num_int: billingInfo.info.num_int
  });

  loadingSave.value = false;

  if (res.isErr()) {
    notify({ title: t("saveError.title"), text: t("saveError.text"), theme: "error" });
    return;
  }

  notify({ title: t("notify.success") });
  updateBillingData(billingInfo);
  emit("close");
};

const fillBillingInfo = (data: MexicoBillingInfoData) => {
  billingInfo.email = data.email;

  if (data.country !== "mx") return;

  billingInfo.info.fiscal_id = data.info.fiscal_id ?? "";
  billingInfo.info.fiscal_id_clean = data.info.fiscal_id_clean ?? "";
  billingInfo.info.name = data.info.name ?? "";
  billingInfo.info.vat_type_id = data.info.vat_type_id ?? "";
  billingInfo.info.vat_type = data.info.vat_type ?? "";
  billingInfo.info.cp = data.info.cp ?? "";
  billingInfo.info.estado = data.info.estado ?? "";
  billingInfo.info.municipio = data.info.municipio ?? "";
  billingInfo.info.colonia = data.info.colonia ?? "";
  billingInfo.info.calle = data.info.calle ?? "";
  billingInfo.info.num_ext = data.info.num_ext ?? "";
  billingInfo.info.num_int = data.info.num_int ?? "";
};

watch(
  () => props.billingData,
  () => {
    fillBillingInfo(props.billingData);
  },
  { deep: true }
);
</script>

<i18n lang="json">
{
  "es": {
    "close": "Cancelar",
    "save": "Guardar cambios",
    "emailLabel": "Email administrativo",
    "legalNameLabel": "Nombre / Razón social",
    "legalNamePlaceholder": "Compañia S.R.L.",
    "fiscalIdLabel": "RFC",
    "fieldRequired": "Campo requerido.",
    "invalidFiscalId": "El RFC es inválido.",
    "invalidEmail": "El email ingresado es inválido.",
    "InvalidPostalCode": "El código postal es inválido.",
    "notify": {
      "success": "Información actualizada con éxito"
    },
    "saveError": {
      "title": "Error",
      "text": "No se pudieron actualizar los datos de facturación."
    }
  },
  "pt": {
    "close": "Cancelar",
    "save": "Salvar alterações",
    "emailLabel": "E-mail administrativo",
    "legalNameLabel": "Nome / Razão social",
    "legalNamePlaceholder": "Empresa S.A.",
    "fiscalIdLabel": "CPF",
    "fieldRequired": "Campo obrigatório.",
    "invalidFiscalId": "O CPF é inválido.",
    "invalidEmail": "O e-mail inserido é inválido.",
    "InvalidPostalCode": "O CEP é inválido.",
    "notify": {
      "success": "Informações atualizadas com sucesso"
    },
    "saveError": {
      "title": "Erro",
      "text": "Não foi possível atualizar os dados de faturamento."
    }
  }
}
</i18n>
