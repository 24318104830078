// API
import { useRequestsAPIV3Pub } from "@api/requests";

// Types
import type { GeoLocationService } from "@application/ports";
import type { DetectGeoLocationAPIResponse } from "./geolocation.types";

// Utils
import { ok, err } from "neverthrow";

export const useGeoLocationService = (): GeoLocationService => {
  const requestAPIV3Pub = useRequestsAPIV3Pub();

  const GeoLocationService: GeoLocationService = {
    async detectGeoLocation() {
      try {
        const res = await requestAPIV3Pub<DetectGeoLocationAPIResponse>({
          url: `geoiplocation/data`,
          method: "get",
        });

        return ok({
          city: res.data.data.geoip_data.city,
          country: res.data.data.geoip_data.country,
          ip: res.data.data.geoip_data.ip,
          user_agent: res.data.data.user_agent,
        });
      } catch (e) {
        return err({
          type: "NO_DATA",
        });
      }
    },
  };

  return GeoLocationService;
};
