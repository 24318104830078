<template>
  <PageTemplate
    :title="t('title')"
    :tabs="tabs"
    :selected="selectedTab"
    :links="{
      es: {
        helpId: 6671345,
      },
      pt: {
        helpId: 6671345,
      },
      en: {
        helpId: 6671345,
      },
    }"
    @update:selected="updateSelectedTab"
  >
    <template #options>
      <div class="flex space-x-4">
        <PermissionsButton theme="green-lime" :permissions="['campaigns:update']" @click="openAutomationCatalog">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createButton") }}
        </PermissionsButton>
      </div>
    </template>
    <template #content>
      <FiltersPanel v-show="!firstLoad && (rows.length > 0 || selectedSearch.length > 0 || loadingTable)">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div class="flex items-center space-x-2">
            <div class="my-auto">
              <span v-show="!skeleton" class="text-sm text-gray-500">
                {{ t("listsCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount }) }}
              </span>
              <span v-show="skeleton" class="animate-pulse rounded bg-gray-100 px-12 text-sm" />
            </div>
            <PeriodSelectorMenu
              v-model:from="periodFrom"
              v-model:to="periodTo"
              :selected-preset-id="selectedPresetID"
              :options="['lastDay', 'last7d', 'last30d', 'last90d']"
              class="w-full sm:w-min"
              @update:selectedPresetID="updatePresetID"
            />
            <CheckboxListButton
              v-show="session?.isMasterUser"
              v-model:selected="selectedColumns"
              :label="t('columnsCheckBoxListButton')"
              :tooltip="t('columnsButton')"
              :items="columnItems"
              :order="false"
            />
          </div>
        </div>
      </FiltersPanel>
    </template>
    <template #all>
      <div v-show="!loadingTable && rows.length > 0 && totalCount > 0" class="h-full">
        <AutomationsTable
          v-if="mounted"
          v-model:rows="rows"
          :count="totalCount"
          :sort-by="sortBy"
          :columns="columns"
          :search="selectedSearch"
          :from="periodFrom"
          :to="periodTo"
          :selected-preset-id="selectedPresetID"
          @update:count="updateCount"
          @update:skeleton="updateSkeleton"
          @update:sort-by="updateSortBy"
          @update:first-load="updateFirstLoad"
          @update:loading="updateLoadingTable"
        />
      </div>
      <EmptyState
        v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
        :title="t('emptyStateAll.title')"
        :article-id="6671345"
        :article-text="t('emptyStateAll.moreAbout')"
        :points="[t('emptyStateAll.first'), t('emptyStateAll.second'), t('emptyStateAll.third')]"
        class="mx-auto h-full w-[60rem]"
      >
        <template #image>
          <EmptyStateImage v-show="lgBp" />
        </template>
      </EmptyState>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && (rows.length === 0 || totalCount === 0)"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
    <template #active>
      <div v-show="!loadingTable && rows.length > 0 && totalCount > 0" class="h-full">
        <AutomationsTable
          v-if="mounted"
          v-model:rows="rows"
          :count="totalCount"
          :sort-by="sortBy"
          :columns="columns"
          state="active"
          :search="selectedSearch"
          :from="periodFrom"
          :to="periodTo"
          :selected-preset-id="selectedPresetID"
          @update:count="updateCount"
          @update:skeleton="updateSkeleton"
          @update:sort-by="updateSortBy"
          @update:first-load="updateFirstLoad"
          @update:loading="updateLoadingTable"
        />
      </div>
      <EmptyStateSimple
        v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
        :title="t('emptyStateActive.title')"
        :message="t('emptyStateActive.message')"
        :article-id="6671345"
        :article-text="t('emptyStateActive.cleanSearch')"
      >
        <template #icon>
          <LightningBoltIcon class="h-16 w-16 stroke-1 text-sky-400" />
        </template>
      </EmptyStateSimple>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && (rows.length === 0 || totalCount === 0)"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
    <template #inactive>
      <div v-show="!loadingTable && rows.length > 0 && totalCount > 0" class="h-full">
        <AutomationsTable
          v-if="mounted"
          v-model:rows="rows"
          :count="totalCount"
          :sort-by="sortBy"
          :columns="columns"
          state="inactive"
          :search="selectedSearch"
          :from="periodFrom"
          :to="periodTo"
          :selected-preset-id="selectedPresetID"
          @update:count="updateCount"
          @update:skeleton="updateSkeleton"
          @update:sort-by="updateSortBy"
          @update:first-load="updateFirstLoad"
          @update:loading="updateLoadingTable"
        />
      </div>
      <EmptyStateSimple
        v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
        :title="t('emptyStateInactive.title')"
        :message="t('emptyStateInactive.message')"
        :article-id="6671345"
        :article-text="t('emptyStateInactive.cleanSearch')"
      >
        <template #icon>
          <LightningBoltIcon class="h-16 w-16 stroke-1 text-sky-400" />
        </template>
      </EmptyStateSimple>
      <EmptySearchState
        v-show="!loadingTable && selectedSearch.length > 0 && (rows.length === 0 || totalCount === 0)"
        @cleanSearch="cleanSearch"
      />
      <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
        <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
      </div>
    </template>
  </PageTemplate>
  <AutomationsCatalogModal
    ref="automationCatalogModal"
    :default-selected-automation-id="selectedCreateAutomId"
    :default-selected-category="selectedAutomCategory"
    @create="onAutomationCreated"
    @close="closeAutomationCatalog"
    @goto-integrations="goToIntegrationsPage"
    @goto-lifecycle-r-f-m="goToLifecyclePage"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, computed, watch, toRaw } from "vue";

import { getColumns, getTabs, columnsToList } from "./automations.data";
import type { AutomationsTabs, ColumnsIds, AutomationsRows } from "./automations.types";

// Components
import EmptyStateImage from "./EmptyStateImage.vue";
import EmptyState from "@molecules/EmptyState/EmptyState.vue";
import EmptyStateSimple from "@molecules/EmptyState/EmptyStateSimple.vue";

import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import AutomationsTable from "./components/AutomationsTable/AutomationsTable.vue";
import PageTemplate from "@templates/PageTemplate";
import FiltersPanel from "@templates/FiltersPanel";
import AutomationsCatalogModal from "@organisms/Automations/AutomationsCatalogModal.vue";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import PeriodSelectorMenu from "@molecules/PeriodSelectorMenu.vue";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import CheckboxListButton from "@molecules/CheckboxListButton";
import type { Items as CheckboxListItems } from "@molecules/CheckboxListButton";
import type { SortColumn, SortingModes } from "@molecules/Table";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import { PencilIcon, PlusIcon } from "@heroicons/vue/solid";
import { LightningBoltIcon } from "@heroicons/vue/outline";

// Composableds
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useBreakpoints } from "@composables/breakpoints";

// Application
import { useLocalStorageApp } from "@application";

// Service
import { useAutomations } from "@api/modules/automations/automations";

// Utils
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useOnboardingStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { FilterData } from "@domain/filters";
import type { Item, Items } from "@templates/PageTemplate";
import type { AutomationCatalogItem } from "@domain/automations";
import type { DataItems } from "@domain/data";
import { getPrimaryHighlightList } from "@domain/filters";
import type { Column, Columns } from "@molecules/Table";

const { t, n } = useI18n();
const automationsAPI = useAutomations();
const trackingEventsService = useTrackingEvents();
const localStorageApp = useLocalStorageApp();
const onboardingStore = useOnboardingStore();
const { lgBp } = useBreakpoints();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const props = withDefaults(
  defineProps<{
    initialTab?: AutomationsTabs;
  }>(),
  {
    initialTab: undefined,
  },
);

const emit = defineEmits<{
  changeRoute: [route: string];
  changeNavigation: [route: string];
}>();

const periodFrom = ref<Date>();
const periodTo = ref<Date>();
const selectedPresetID = ref<"lastDay" | "last7d" | "last30d" | "last90d" | undefined>("last30d");
const updatePresetID = (presetID: "lastDay" | "last7d" | "last30d" | "last90d" | undefined) => {
  selectedPresetID.value = presetID;
};

const rows = ref<AutomationsRows>([]);

const skeleton = ref(false);
const loadingTable = ref(false);
const updateLoadingTable = (loading: boolean) => {
  loadingTable.value = loading;
};
const totalCount = ref(0);
const firstLoad = ref(false);
const updateFirstLoad = (value: boolean) => {
  firstLoad.value = value;
};

const updateSkeleton = (newValue: boolean) => {
  skeleton.value = newValue;
};
const updateCount = (newCount: number) => {
  totalCount.value = newCount;
};

const baseColumns = getColumns();
const columnItems = computed<
  CheckboxListItems<ColumnsIds | "options", string, Column<ColumnsIds | "options", ColumnsIds>>
>(() => {
  return columnsToList(baseColumns);
});

const selectedColumns = ref<
  CheckboxListItems<ColumnsIds | "options", string, Column<ColumnsIds | "options", ColumnsIds>>
>(columnsToList(baseColumns));

const columns = computed<Columns<ColumnsIds | "options", ColumnsIds>>(() => {
  const filteredColumns = baseColumns.filter((c) => {
    const columnIsFixed = columnItems.value.every((ci) => ci.key !== c.id);
    const selected = selectedColumns.value.some((sc) => sc.key === c.id);
    return columnIsFixed || selected;
  });
  return filteredColumns;
});

const tabs: Items<AutomationsTabs> = getTabs();
const selectedTab = ref<Item<AutomationsTabs>>(tabs.find((tab) => tab.key === props.initialTab) ?? tabs[0]);
const updateSelectedTab = (newTab: Item<AutomationsTabs>) => {
  selectedTab.value = newTab;
  emit("changeRoute", newTab.key);
};

const selectedSearch = ref<SelectedValues>([]);
const cleanSearch = () => {
  selectedSearch.value = [];
};
const highlightSearches = ref<DataItems>([]);

const savedSortBy = localStorageApp.get<SortColumn<ColumnsIds>>({ id: "automations_sort_by" });
const sortBy = ref<SortColumn<ColumnsIds>>(
  savedSortBy ?? {
    columnId: "name",
    sort: "ASC",
  },
);
const updateSortBy = (newSortBy: SortColumn<ColumnsIds>) => {
  sortBy.value = newSortBy;
  localStorageApp.save<SortColumn<ColumnsIds>>({ id: "automations_sort_by", value: sortBy.value });
};

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "automations_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "automations_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "automations");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "automations");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};
const filterInputIsOpen = ref(false);
const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

// const listTags = ref<Array<string>>([]);
const filterData = computed<FilterData>(() => ({
  filters: [
    {
      id: "automations",
      text: t("filters.automations"),
      icon: PencilIcon,
      highlightList: highlightSearches.value,
    },
    // {
    //   id: "tags",
    //   text: t("filters.tags"),
    //   icon: TagIcon,
    //   list: automationTagsToDataItems(listTags.value),
    // },
  ],
  filtersRoles: {
    primary: "automations",
  },
}));

// const fetchTags = async () => {
//   const tags = await automationsAPI.tags();

//   listTags.value = tags;
// };

const automationCatalogModal = ref();

const openAutomationCatalog = () => {
  automationCatalogModal.value.openModal();
};

const closeAutomationCatalog = () => {
  automationCatalogModal.value.closeModal();
};

const onAutomationCreated = async ({
  automation,
  selectedSource,
}: {
  automation: AutomationCatalogItem;
  selectedSource: {
    account: string;
    id: string;
  };
}) => {
  const newAutomation = await automationsAPI.copy({
    account: selectedSource.account,
    id: selectedSource.id,
  });

  closeAutomationCatalog();

  const eventPromises =
    newAutomation?.options?.types?.map((type) => {
      return onboardingStore.submitUserEvent({
        action: `automation.${type}.created`,
        data: {
          id: newAutomation.id,
          name: newAutomation.name,
        },
      });
    }) ?? [];

  await Promise.all([
    onboardingStore.submitUserEvent({
      action: "automation.created",
      data: {
        id: newAutomation.id,
        name: newAutomation.name,
      },
    }),
    ...eventPromises,
  ]);

  // TODO: Submit automation types (bulk api?)

  trackingEventsService.amplitude({
    name: "APP_AUTOMATION_CREATED",
    data: {
      source_id: selectedSource.id,
      source_account: selectedSource.account,
      name: automation.name,
    },
  });
  emit("changeNavigation", `automations/${newAutomation.id}`);
};

const goToIntegrationsPage = () => {
  emit("changeNavigation", `integrations`);
};

const goToLifecyclePage = () => {
  emit("changeNavigation", `dashboard/buyers`);
};

const mounted = ref(false);

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    automations: "q",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

watch([selectedSearch, sortBy], () => {
  cleanQueryParams();
  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);

    if (!paramName) return;

    if (isSelectedValueTag(selected)) {
      updateQueryParam({ key: paramName, value: selected.tag.id });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  if (sortBy.value) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  }
});

const selectedCreateAutomId = ref<string>();
const selectedAutomCategory = ref<string>();
onMounted(async () => {
  const queryParams = getQueryParams();
  selectedCreateAutomId.value = queryParams.get("autom_id");
  selectedAutomCategory.value = queryParams.get("autom_category");

  if (selectedCreateAutomId.value || selectedAutomCategory.value) {
    cleanQueryParams();
  }

  if (selectedCreateAutomId.value || selectedAutomCategory.value) {
    openAutomationCatalog();
  }

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      value,
      id: value,
    });
  });

  const sortParams = queryParams?.get("sort")?.split(" ");

  if (!sortParams) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  } else {
    sortBy.value = {
      columnId: sortParams?.[0] as ColumnsIds,
      sort: sortParams?.[1] as SortingModes,
    };
  }

  insertRecommmendedNames();

  mounted.value = true;
  if (!props.initialTab) emit("changeRoute", "all");
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Automations",
    "placeholderSearch": "Buscar automations",
    "createButton": "Crear automation",
    "automationsCount": "0 automations | 1 automation | {count} automations",
    "columnsCheckBoxListButton": "Mostrar columnas",
    "columnsButton": "Columnas",
    "listsCount": "0 automations | 1 automation | {count} automations",
    "emptyStateAll": {
      "title": "Automatiza la comunicación de tu tienda online",
      "moreAbout": "Saber más sobre Automations",
      "first": "Configúralos por única vez y observa como aumentan tus ventas. ¡Todo en piloto automático!",
      "second": "Aprovecha los automations del ciclo de vida para crear estrategias de fidelización y retención.",
      "third": "Convierte más ventas usando los automations de recupero de carrito abandonado y visitas."
    },
    "emptyStateActive": {
      "title": "Sin automations activos",
      "message": "Aquí verás los automations que se encuentren activos actualmente.",
      "cleanSearch": "Aprender más sobre automations"
    },
    "emptyStateInactive": {
      "title": "Sin automations inactivos",
      "message": "Aquí verás los automations que se encuentren pausados o no fueron activados aún.",
      "cleanSearch": "Aprender más sobre automations"
    },
    "columns": {
      "name": "Nombre",
      "state": "Estado",
      "triggered": "Iniciados",
      "active": "En proceso",
      "filtered": "Filtrados",
      "completed": "Finalizados",
      "failed": "Fallidos",
      "convertedAmount": "Ventas"
    },
    "filters": {
      "automations": "Nombre"
    },
    "tabs": {
      "all": "Todos",
      "active": "Activos",
      "inactive": "Inactivos"
    },
    "tooltip": {
      "triggered": "Indica la cantidad de automations iniciados, es decir, los que cumplieron la condición o evento configurado como disparador.",
      "active": "Son los automations que aún no han finalizado su ejecución, por lo general sucede cuando tienen configurado tiempos de espera.",
      "filtered": "Son los automations que no cumplieron con alguna de las condiciones definidas en los filtros, por lo que la ejecución fue detenida antes de finalizar el flujo.",
      "completed": "Indica los automations que finalizaron el flujo en su totalidad.",
      "failed": "Indica los automations que no pudieron finalizar el flujo en su totalidad, por algun error durante el proceso.",
      "convertedAmount": " Indica el monto y cantidad de ventas asistidas por el automation"
    }
  },
  "pt": {
    "title": "Automations",
    "placeholderSearch": "Pesquisar automations",
    "createButton": "Criar automation",
    "automationsCount": "0 automations | 1 automation | {count} automations",
    "columnsCheckBoxListButton": "Mostrar colunas",
    "columnsButton": "Colunas",
    "listsCount": "0 automations | 1 automation | {count} automations",
    "emptyStateAll": {
      "title": "Automatize a comunicação da sua loja online",
      "moreAbout": "Aprender mais sobre automations",
      "first": "Configure-os uma única vez e veja como suas vendas aumentam. Tudo no piloto automático!",
      "second": "Aproveite as automations do ciclo de vida para criar estratégias de fidelização e retenção.",
      "third": "Converta mais vendas usando as automations de recuperação de carrinho abandonado e visitas."
    },
    "emptyStateActive": {
      "title": "Sem automações ativas",
      "message": "Aqui você verá as automações que estão atualmente ativas.",
      "cleanSearch": "Saiba mais sobre automações"
    },
    "emptyStateInactive": {
      "title": "Sem automações inativas",
      "message": "Aqui você verá as automações que estão pausadas ou ainda não foram ativadas.",
      "cleanSearch": "Saiba mais sobre automações"
    },
    "columns": {
      "name": "Nome",
      "state": "Estado",
      "triggered": "Iniciados",
      "active": "Em espera",
      "filtered": "Filtrados",
      "completed": "Completos",
      "failed": "Falharam",
      "convertedAmount": "Vendas"
    },
    "filters": {
      "automations": "Nome"
    },
    "tabs": {
      "all": "Todos",
      "active": "Ativos",
      "inactive": "Inativos"
    },
    "tooltip": {
      "triggered": "Indica a quantidade de automations iniciadas, ou seja, aquelas que cumpriram a condição ou evento configurado como gatilho.",
      "active": "São os automations que ainda não concluíram sua execução, geralmente quando possuem tempos de espera configurados.",
      "filtered": "São os automations que não cumpriram alguma das condições definidas nos filtros, o que resultou na interrupção da execução antes do término do fluxo.",
      "completed": "Indica os automations que concluíram completamente o fluxo.",
      "failed": "Indica os automations que não conseguiram concluir completamente o fluxo devido a algum erro durante o processo.",
      "convertedAmount": "Indica a quantidade de conversões atribuídas aos automations"
    }
  }
}
</i18n>
