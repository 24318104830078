<template>
  <div class="flex flex-grow space-x-6 pb-4 pl-6">
    <CategoryMenu
      :loading="loadingTemplates && !selectedCategory"
      :categorized-items="templateCategories"
      :selected="selectedCategory"
      class="sticky top-0 mt-4 w-72 flex-shrink-0 overflow-auto pr-1"
      data-intercom-target="TemplateGalleryCategories"
      @update:selected="selectTemplateCategory"
    />
    <TemplateCatalog
      :key="selectedCategory?.id"
      :templates="categoryTemplates"
      :is-public="true"
      :as-link="allowLink"
      :add-empty-template="addEmptyTemplate"
      :loading="isTemplateAvailable && loadingTemplates"
      :skeleton="!isTemplateAvailable && loadingTemplates"
      class="w-full pt-4"
      @select="selectTemplate"
      @select-new-template="useEmptyTemplate"
      @bottom="() => selectedCategory && fetchNextTemplates(selectedCategory.id)"
    />
    <EmptySearch v-if="!loadingTemplates && !isTemplateAvailable" />
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, computed, onMounted, watchEffect, toRaw } from "vue";

//Components
import CategoryMenu from "@molecules/CategoryMenu/CategoryMenu.vue";
import TemplateCatalog from "@organisms/Templates/TemplateCatalog/TemplateCatalog.vue";
import EmptySearch from "@organisms/Templates/TemplateEmptySearch/TemplateEmptySearch.vue";

// Application
import { useTemplatesApp } from "@application";

// Helpers
import { findDeepObject } from "@helpers/data";

//API
import { useEmailTemplate } from "@api/modules/templates/templates";

//Types
import type { TemplateList, TemplateItem } from "@domain/Templates";
import type {
  GetPublicTemplatesAsyncGenerator,
  GetPublicTemplatesRequest,
} from "@api/modules/templates/templates.types";
import type { CategoryItem, Items } from "@molecules/CategoryMenu/CategoryMenu.types";
import type { TemplateCategories } from "@/vue/application/templates/templates.types";

const templatesApp = useTemplatesApp();

//TemplatesAPI
const templatesAPI = useEmailTemplate();
const templatesGens: Record<string, GetPublicTemplatesAsyncGenerator | undefined> = {};

//Props
const props = withDefaults(
  defineProps<{
    addEmptyTemplate?: boolean;
    selectedCategory?: string;
    allowLink?: boolean;
  }>(),
  {
    addEmptyTemplate: false,
    selectedCategory: undefined,
    allowLink: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "select", selectedTemplate: TemplateItem): void;
  (e: "selectNewTemplate"): void;
  (e: "update:selectedCategory", value: string): void;
}>();

const selectTemplate = (selectedTemplate: TemplateItem) => emit("select", selectedTemplate);
const useEmptyTemplate = () => emit("selectNewTemplate");

//Select Category
const templateCategories = ref<TemplateCategories>();
const selectedCategory = ref<
  CategoryItem<{
    tags: Array<string>;
  }>
>();

watchEffect(() => {
  if (!templateCategories.value || props.selectedCategory === undefined) return;

  const categoryFound: Items<{
    tags: Array<string>;
  }> = findDeepObject<TemplateCategories>(toRaw(templateCategories.value), (value) => value === props.selectedCategory);
  if (categoryFound[0]) {
    selectedCategory.value = categoryFound[0];
  }
});

const selectTemplateCategory = (
  newCategory: CategoryItem<{
    tags: Array<string>;
  }>
) => {
  selectedCategory.value = newCategory;
  emit("update:selectedCategory", newCategory.id);
};

onMounted(async () => {
  loadingTemplates.value = true;
  templateCategories.value = await templatesApp.getTemplateCategories();
  selectedCategory.value =
    templateCategories.value?.items?.[0] ??
    templateCategories.value?.items?.[1] ??
    templateCategories.value.categories[0].items[0];
  loadingTemplates.value = false;

  if (props.selectedCategory === undefined) {
    selectTemplateCategory(selectedCategory.value);
  }
});

//Templates
const loadingTemplates = ref(false);
const templates = reactive<Record<string, TemplateList>>({});
const categoryTemplates = computed<TemplateList>(() => {
  if (!selectedCategory.value) return [];
  return templates[selectedCategory.value.id] ?? [];
});

const clearTemplates = (categoryTemplatesId: string | number) => {
  if (!templates?.[categoryTemplatesId]) return;

  templates[categoryTemplatesId] = [];
};

const isTemplateAvailable = computed(() => {
  if (!selectedCategory.value) return false;
  const categoryTemplates = templates?.[selectedCategory.value.id];
  if (!categoryTemplates) return false;

  return categoryTemplates.length > 0;
});

const getTemplatesGen = async (
  category: CategoryItem<{
    tags: Array<string>;
  }>,
  search?: string
) => {
  const tags = category.data?.tags ?? [];

  const request: GetPublicTemplatesRequest = !search ? { tags_in: tags } : { name: search };

  const gen = await templatesAPI.getPublicTemplates(request);

  return gen;
};

const fetchNextTemplates = async (categoryTemplatesId: string | number) => {
  const generator = templatesGens[categoryTemplatesId];
  if (!generator) return;
  loadingTemplates.value = true;

  const value = await generator.next();

  if (value.done) {
    templatesGens[categoryTemplatesId] = undefined;
    loadingTemplates.value = false;
    return;
  }
  const categoryTemplates = templates?.[categoryTemplatesId] || [];

  templates[categoryTemplatesId] = [...categoryTemplates, ...value.value];
  loadingTemplates.value = false;
};

//Life cycle
watch(
  () => [selectedCategory.value],
  async (newValue, oldValue) => {
    if (!selectedCategory.value) return;

    const oldSelectedCategory = oldValue?.[0];
    const newSelectedCategory = newValue[0];

    loadingTemplates.value = true;
    const categoryTemplateId = selectedCategory.value.id;

    if (oldSelectedCategory != newSelectedCategory && templates?.[selectedCategory.value.id]?.length > 0) {
      loadingTemplates.value = false;
      return;
    }

    clearTemplates(categoryTemplateId);
    templatesGens[categoryTemplateId] = await getTemplatesGen(selectedCategory.value);
    await fetchNextTemplates(categoryTemplateId);
    loadingTemplates.value = false;
  },
  { deep: true }
);
</script>
