<template>
  <Combobox
    ref="filterInputRef"
    as="div"
    class="relative"
    data-intercom-target="FilterInput"
    @update:model-value="selectHandler"
    @click="openDropDown"
  >
    <ComboboxInput ref="searchInputRef" as="template" :type="undefined" tabindex="-1">
      <SearchInput
        v-model:search="search"
        :filter-data="filterData"
        :selected="selected"
        :placeholder="placeholder"
        @click="onClickInput"
        @delete="onDelete"
        @delete-all="onDeleteAll"
      />
    </ComboboxInput>
    <div
      v-show="selected.length > 0"
      class="pointer-events-none absolute right-10 top-[1px] z-10 h-9 w-20 rounded-r-md bg-gradient-to-l from-white via-90% to-transparent"
    />
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="dropdownIsOpen"
        :class="[isScrolling ? 'top-14' : 'top-11', 'absolute z-30 w-full rounded-md border bg-white shadow']"
      >
        <ComboboxOptions static class="mb-0">
          <DropDownInitial
            v-if="search.trim().length === 0 && !selectedFilter"
            :filter-data="filteredFilterData"
            :selected="selected"
          />
          <DropDownSearch
            v-else
            :filter-data="filteredFilterData"
            :selected="selected"
            :search="search"
            :filter="selectedFilter"
          />
        </ComboboxOptions>
      </div>
    </transition>
  </Combobox>
</template>
<script
  lang="ts"
  setup
  generic="
    FilterIdType extends string,
    IdType extends string,
    TextType extends string,
    ListKeyType extends string,
    ListValueType extends string,
    ListDataType = Record<string, unknown>,
    ListContentType extends Record<string, unknown> = Record<string, unknown>
  "
>
import { onMounted, computed, ref, watch, toRaw, nextTick } from "vue";

// Components
import { Combobox, ComboboxInput, ComboboxOptions } from "@headlessui/vue";
import SearchInput from "./components/SearchInput.vue";
import DropDownInitial from "./components/DropdownInitial.vue";
import DropDownSearch from "./components/DropdownSearch.vue";

// Utils
import { cloneDeep } from "lodash";
import { onClickOutside } from "@vueuse/core";

// Type
import { Filter, FilterData, getListItemFilter } from "@domain/filters";
import { getPrimaryFilter } from "@domain/filters";
import type { Tag } from "@domain/tag";
import { OptionValue, SelectedValue, SelectedValues, isSelectedValueTag, SelectedValueText } from "./FilterInput.types";
import { findIndexSelectedValue } from "./FilterInput.types";
import type { DataItem } from "@domain/data";

const props = withDefaults(
  defineProps<{
    filterData: FilterData<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>;
    selected: SelectedValues<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType>;
    placeholder?: string;
  }>(),
  {
    placeholder: undefined,
  },
);

const emit = defineEmits<{
  "update:selected": [SelectedValues<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType>];
  open: [];
  close: [];
}>();

const filteredFilterData = computed<
  FilterData<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>
>(() => {
  return {
    ...props.filterData,
    filters: props.filterData.filters.filter((f) => {
      if (!f.isAction) return true;

      return props.selected.every((s) => s.filterId !== f.id);
    }),
  };
});

const actionSelected = (
  filter: Filter<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>,
) => {
  emit("update:selected", [
    ...toRaw(props.selected),
    { filterId: filter.id, id: "" as ListKeyType, value: filter.badgeText ?? filter.text },
  ]);
  blockNextOpen.value = true;
  closeDropDown();
};

const onUpdateSelected = (
  values: SelectedValues<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType>,
) => {
  emit("update:selected", values);
  blockNextOpen.value = true;
  closeDropDown();
};

const selectHandler = (
  value: OptionValue<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>,
) => {
  if (value.filter?.isAction) return actionSelected(value.filter);
  if (value.filter) return selectFilter(value.filter);
  if (value.tag) return addTag(value.tag);
  if (value.text) return addTextSearch(value.text);
  if (value.item) return addSearch(value.item);
};

const onDelete = (item: SelectedValue<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType>) => {
  const itemIndex = findIndexSelectedValue(props.selected, item);

  if (itemIndex === -1) return;

  const clonedSelected: SelectedValues<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType> =
    cloneDeep(toRaw(props.selected));
  clonedSelected.splice(itemIndex, 1);

  onUpdateSelected(clonedSelected);
};

const onDeleteAll = () => {
  resetSearchValue();
  onUpdateSelected([]);
};

const deleteLastElement = () => {
  if (props.selected.length === 0) return;
  onUpdateSelected(toRaw(props.selected).slice(0, -1));
};

const addTag = (tag: Tag) => {
  const tagsFilterId = filteredFilterData.value.filtersRoles?.tags;
  resetSearchValue();
  clearSelectedFilter();
  if (!tagsFilterId) return;

  const existingTag = props.selected.find((item) => {
    if (!isSelectedValueTag(item)) return false;
    return item.tag?.id === tag?.id;
  });
  if (existingTag) return;

  onUpdateSelected([
    ...toRaw(props.selected),
    { filterId: tagsFilterId, id: toRaw(tag).id as ListKeyType, tag: toRaw(tag) },
  ]);
};

const addTextSearch = (text: TextType | string) => {
  const filter = selectedFilter.value ? selectedFilter.value : getPrimaryFilter(filteredFilterData.value);
  const selectedFilterFound = props.selected.findIndex((selected) => selected.filterId === filter.id);

  const newFilter: SelectedValueText<FilterIdType, ListKeyType, TextType | string, ListDataType> = {
    filterId: filter.id,
    id: text as ListKeyType,
    value: text,
  };
  resetSearchValue();
  clearSelectedFilter();
  if (selectedFilterFound === -1) return onUpdateSelected([...toRaw(props.selected), newFilter]);

  const clonedSelected: SelectedValues<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType> =
    cloneDeep(toRaw(props.selected));
  clonedSelected.splice(selectedFilterFound, 1, newFilter);

  onUpdateSelected(clonedSelected);
};

const addSearch = (value: DataItem<ListKeyType, ListValueType, ListDataType, ListContentType>) => {
  const filter =
    selectedFilter.value ??
    getListItemFilter(filteredFilterData.value, value) ??
    getPrimaryFilter<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>(
      filteredFilterData.value,
    );
  const selectedFilterFound = props.selected.findIndex((selected) => selected.filterId === filter.id);

  const newFilter: SelectedValue<FilterIdType, ListKeyType, TextType | string | ListValueType, ListDataType> = {
    filterId: filter.id,
    value: value.value,
    id: value.key,
    data: value.data,
  };
  resetSearchValue();
  clearSelectedFilter();
  if (selectedFilterFound === -1) return onUpdateSelected([...toRaw(props.selected), newFilter]);

  const clonedSelected = cloneDeep(toRaw(props.selected));
  clonedSelected.splice(selectedFilterFound, 1, newFilter);

  onUpdateSelected(clonedSelected);
};

// Search
const search = ref("");
const resetSearchValue = () => {
  search.value = "";
};

const selectedFilter =
  ref<Filter<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>>();
const selectFilter = (
  filter: Filter<FilterIdType, TextType | string, ListKeyType, ListValueType, ListDataType, ListContentType>,
) => {
  selectedFilter.value = filter;
};
const clearSelectedFilter = () => {
  selectedFilter.value = undefined;
};

// Dropdown
const onClickInput = () => {
  openDropDown();
};

const dropdownIsOpen = ref(false);
const blockNextOpen = ref(false);
const openDropDown = () => {
  if (dropdownIsOpen.value === true || blockNextOpen.value) {
    blockNextOpen.value = false;
    return;
  }

  dropdownIsOpen.value = true;
  emit("open");
};
const closeDropDown = () => {
  if (dropdownIsOpen.value === false) return;

  dropdownIsOpen.value = false;
  resetSearchValue();
  clearSelectedFilter();
  emit("close");
};

// Accessibility
const filterInputRef = ref();

const searchInputRef = ref();
const isScrolling = ref(false);

watch(
  () => props.selected,
  () => {
    if (!filterInputRef.value || !searchInputRef.value || !searchInputRef.value?.el?.scrollableInputRef) {
      isScrolling.value = false;
      return;
    }

    const scrollDivElement = searchInputRef.value.el.scrollableInputRef;

    nextTick(() => {
      isScrolling.value = scrollDivElement.scrollWidth > scrollDivElement.clientWidth;
    });
  },
);

onMounted(() => {
  if (!filterInputRef.value || !filterInputRef.value.$el.nextSibling) return;

  onClickOutside(filterInputRef.value.$el.nextSibling, closeDropDown);

  filterInputRef.value.$el.nextSibling.addEventListener("click", () => {
    searchInputRef?.value?.el?.focusInput();
  });

  filterInputRef.value.$el.nextSibling.addEventListener("keydown", (event: KeyboardEvent) => {
    if (event.code === "Backspace" && search.value === "") {
      deleteLastElement();
      return;
    }

    if (event.code === "Escape") {
      if (selectedFilter.value !== undefined) {
        clearSelectedFilter();
        return;
      }

      closeDropDown();
      return;
    }

    if (!dropdownIsOpen.value) {
      openDropDown();
      return;
    }
  });
});
</script>
