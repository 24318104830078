import app from "@/app";
import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Model.extend({
  url: "plan",
  parse: function (response) {
    var plan = response.data;

    app.setPlan(plan);

    // Set emails percentege
    if (plan.emails !== undefined) {
      plan.emails.limited = plan.type === "EMAILS";
      plan.emails.availableP = (plan.emails.available * 100) / plan.emails.limit;

      // Set excess percentage
      if (plan.emails.available < 0) {
        plan.emails.excess = -plan.emails.available;
        plan.emails.excessP = (plan.emails.excess * 100) / plan.emails.excessLimit;
      }
    }

    // Set contacts percentage
    if (plan.contacts !== undefined) {
      plan.contacts.availableP = (plan.contacts.active * 100) / plan.contacts.limit;
      if (plan.contacts.limit && plan.contacts.availableP > 100) {
        plan.contacts.excess = true;
      }
    }

    // Set default promocode
    if (!plan.promocode) {
      plan.promocode = null;
    }

    // Set default purchase permission
    plan.canPurchase = true;

    // Set permissions for master users
    if (app.session.get("isMasterUser")) {
      // Set permission to show unpaid block
      if (app.session.hasPermission("account:plan")) {
        plan.canEditUnpaidBlock = true;
      }

      // Set permission to increase excess limit
      if (app.session.hasPermission("account:plan") && plan.type === "FREE") {
        plan.canMakeOrg = true;
      }

      // Set permission to increase excess limit
      if (app.session.hasPermission("account:plan") && (plan.type === "FREE" || plan.type === "EMAILS")) {
        plan.canMakeTrial = true;
      }

      // Set permission to increase excess limit
      if (app.session.hasPermission("account:plan") && plan.type === "MONTHLY") {
        plan.canApplyDiscount = true;
      }

      // Set permission to increase excess limit
      if (app.session.hasPermission("account:plan") && _.contains(["FREE", "MONTHLY"], plan.type)) {
        plan.canIncreaseExcessLimit = true;
      }

      // Set permission to increase excess limit
      if (app.session.hasPermission("invoices:settle")) {
        plan.canChangeBalance = true;
      }

      // Set default purchase permission
      if (app.session.hasPermission("invoices:update")) {
        plan.canModify = true;
      }

      // Set default purchase permission
      if (app.session.hasPermission("invoices:extend")) {
        plan.canExtend = true;
      }

      // Settle permission
      if (app.session.hasPermission("invoices:settle")) {
        plan.canSettle = true;
      }

      // Delete permission
      if (app.session.hasPermission("invoices:delete")) {
        plan.canDelete = true;
      }

      // Contacts reset
      if (app.session.hasPermission("account:plan") && plan.type === "MONTHLY") {
        plan.canReset = true;
      }

      // Payment execute
      if (
        app.session.hasPermission("invoices:settle") &&
        app.session.hasPermission("account:plan") &&
        _.contains(["FREE", "MONTHLY"], plan.type)
      ) {
        plan.canExecute = true;
      }

      // Payment execute
      if (app.session.hasPermission("credits:admin")) {
        plan.canIncreaseEmails = true;
        plan.canDecreaseEmails = true;
      }

      // tokenize cards
      if (!app.session.attributes.details.account?.features?.USE_TOKENIZE) {
        plan.showEnableTokenize = true;
      }

      if (
        app.session.attributes.details.account?.features?.USE_TOKENIZE &&
        (!app.session.attributes.details.account?.features?.USE_TOKENIZE_MP ||
          app.session.attributes.details.account?.features?.USE_TOKENIZE_MP === "0")
      ) {
        plan.showEnableTokenizeMP = true;
      }
      if (
        app.session.attributes.details.account?.features?.USE_TOKENIZE &&
        app.session.attributes.details.account?.features?.USE_TOKENIZE_MP === "1"
      ) {
        plan.showDisableTokenizeMP = true;
      }

      if (plan.state === "BLOCKED_ABUSE") {
        plan.showUnblockAbuse = true;
        plan.showBlockAbuse = false;
      } else {
        plan.showUnblockAbuse = false;
        plan.showBlockAbuse = true;
      }

      plan.canMakeFree = plan.type === "EMAILS";
    }

    // Set default payment type
    plan.defaultPaymentType = "creditcard";
    if (plan.subscription !== undefined) {
      if (plan.subscription.payMethod.startsWith(["TRANSFER"])) {
        plan.defaultPaymentType = "transfer";
      }
    }

    plan.isMonthlyCreditCard = plan.subscription && !plan.subscription.payMethod.startsWith(["TRANSFER"]);

    app.setTrial(plan.trialEnds);
    plan.trial = app.getTrial();

    // Return data
    return plan;
  },
  additionals: {
    invoices: function (model) {
      return $.ajax({
        url: "invoices?limit=100",
        success: function (response) {
          $.each(response.data, function (index, invoice) {
            // Invoice is paid
            if (invoice.paid) {
              return (invoice.status = "paid");
            }

            // Invoice is to available to pay
            if (!invoice.paid && invoice.payMethod === "") {
              return (invoice.status = "pay");
            }

            // Invoice is not paid and can be notified
            if (!invoice.paid && invoice.payMethod.startsWith(["TRANSFER"])) {
              return (invoice.status = "notify");
            }

            if (!invoice.paid && invoice.payMethod === "STRIPETOKENBR_BRL" && invoice.currency === "BRL") {
              invoice.allowRetryUSD = true;
              return (invoice.status = "pending");
            }

            // Invoice is not paid and we are awaiting for external payment method notification
            if (!invoice.paid && !invoice.payMethod.startsWith(["TRANSFER"])) {
              return (invoice.status = "pending");
            }
          });

          return response;
        },
      });
    },
    paymethods: function () {
      return $.ajax({
        url: "paymethods",
        success: function (response) {
          if (
            !app.session.get("isMasterUser") &&
            response.data.transfer &&
            response.data.transfer.code == "TRANSFER_USD"
          ) {
            delete response.data.transfer;
          }
        },
      });
    },
    planAdmin: function () {
      if (app.session.get("isMasterUser")) {
        return $.ajax({
          url: "plan/admin",
        });
      } else {
        return app.helpers.emptyRelationship();
      }
    },
    accountBalance: function () {
      if (app.session.get("isMasterUser")) {
        return $.ajax({
          url: "invoicepayments/balance",
        });
      } else {
        return app.helpers.emptyRelationship();
      }
    },
  },
});
