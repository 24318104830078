// API
import { useRequests } from "@api/requests";

// Types
import type { BillingInfoService, GenericBillingInfo } from "@application/ports";
import type { GetBillingInfoAPIResponse, SaveBillingInfoAPIResponse } from "./billingInfo.types";
import type {
  BillingInfoData,
  ArgentinaBillingInfoData,
  BrazilBillingInfoData,
  MexicoBillingInfoData,
  GenericBillingInfoData
} from "@domain/billingInfo";
import { getCountryByCode } from "@domain/countries";

// Utils
import { ok, err } from "neverthrow";

export const useBillingInfoService = (): BillingInfoService => {
  const request = useRequests();

  const GeoLocationService: BillingInfoService = {
    async get() {
      try {
        const res = await request<GetBillingInfoAPIResponse>({
          url: `/invoicepayments/billinginfo`,
          method: "get",
          disableNotifyErrors: true
        });

        return ok(res.data);
      } catch (e) {
        return err({
          type: "NO_DATA"
        });
      }
    },
    async save(params) {
      let data: BillingInfoData | undefined = undefined;

      if (params.country === "ar") {
        data = {
          country: "ar",
          email: params.email,
          info: {
            country_name: getCountryByCode(params.country),
            fiscal_id: params.fiscal_id,
            fiscal_id_clean: params.fiscal_id_clean,
            name: params.name,
            vat_id: params.vat_id
          }
        } as ArgentinaBillingInfoData;
      }

      if (params.country === "br") {
        data = {
          country: params.country,
          email: params.email,
          info: {
            country_name: getCountryByCode(params.country),
            bairro: params.bairro,
            name: params.name,
            cidade: params.cidade,
            estado: params.estado,
            fiscal_id: params.fiscal_id,
            fiscal_id_clean: params.fiscal_id_clean,
            fiscal_id_type: params.fiscal_id_type,
            num: params.num,
            cp: params.cp,
            rua: params.rua
          }
        } as BrazilBillingInfoData;
      }

      if (params.country === "mx") {
        data = {
          country: params.country,
          email: params.email,
          info: {
            country_name: getCountryByCode(params.country),
            name: params.name,
            calle: params.calle,
            colonia: params.colonia,
            estado: params.estado,
            fiscal_id: params.fiscal_id,
            fiscal_id_clean: params.fiscal_id_clean,
            municipio: params.municipio,
            num_ext: params.num_ext,
            vat_type_id: params.vat_type_id,
            vat_type: params.vat_type,
            cp: params.cp,
            num_int: params.num_int
          }
        } as MexicoBillingInfoData;
      }

      if (!["ar", "br", "mx"].includes(params.country)) {
        const genericInfo = params as GenericBillingInfo;
        data = {
          country: params.country,
          email: params.email,
          info: {
            country_name: getCountryByCode(params.country),
            name: genericInfo.name,
            domicilio: genericInfo.domicilio,
            ciudad: genericInfo.ciudad,
            fiscal_id: params.fiscal_id,
            fiscal_id_clean: params.fiscal_id_clean,
            cp: genericInfo.cp
          }
        } as GenericBillingInfoData;
      }

      try {
        const res = await request<SaveBillingInfoAPIResponse, BillingInfoData>({
          url: `/invoicepayments/billinginfo`,
          method: "put",
          data,
          disableNotifyErrors: true
        });

        return ok(res.data);
      } catch (e) {
        return err({
          type: "NO_DATA"
        });
      }
    }
  };

  return GeoLocationService;
};
