import { parseISO, isAfter } from "date-fns";
import { Period, IDashboardDataSet, PeriodType, variation } from "./base";

interface Metric {
  metric: number;
  add(metric: number, date: Date): void;
}

class LastValueMetric implements Metric {
  public metric: number;
  public date: Date;

  constructor() {
    this.metric = 0;
    this.date = new Date(0);
  }

  public add(metric: number, date: Date) {
    if (isAfter(date, this.date)) {
      this.metric = metric;
      this.date = date;
    }
  }
}

class SumValueMetric implements Metric {
  metric = 0;

  public add(metric: number, date: Date) {
    this.metric += metric;
  }
}

abstract class MetricVar {
  abstract current: Metric;
  abstract previous: Metric;

  accumulate(value: number, date: Date, period: PeriodType): void {
    let metric: Metric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    metric.add(value, date);
  }

  get variation() {
    return variation(this.current.metric, this.previous.metric);
  }
}

class LastValueMetricVar extends MetricVar {
  readonly current = new LastValueMetric();
  readonly previous = new LastValueMetric();
}

class SumValueMetricVar extends MetricVar {
  readonly current = new SumValueMetric();
  readonly previous = new SumValueMetric();
}

export class ContactsDashboardData {
  readonly total: LastValueMetricVar;
  readonly engaged: LastValueMetricVar;
  readonly neverEngaged: LastValueMetricVar;
  readonly asleep: LastValueMetricVar;
  readonly enabled: LastValueMetricVar;
  readonly disabled: LastValueMetricVar;
  readonly new: SumValueMetricVar;
  currentDataPoints = 0;
  previousDataPoints = 0;

  constructor(dataSet: IDashboardDataSet, period: Period) {
    this.total = new LastValueMetricVar();
    this.engaged = new LastValueMetricVar();
    this.neverEngaged = new LastValueMetricVar();
    this.asleep = new LastValueMetricVar();
    this.enabled = new LastValueMetricVar();
    this.disabled = new LastValueMetricVar();
    this.new = new SumValueMetricVar();

    Object.entries(dataSet).forEach((entry) => {
      const date = parseISO(entry[0]);
      const dataPoint = entry[1];
      const periodType = period.type(date);

      this.total.accumulate(dataPoint.pem_api_contacts.total, date, periodType);
      this.engaged.accumulate(dataPoint.pem_api_contacts.engaged, date, periodType);
      this.neverEngaged.accumulate(dataPoint.pem_api_contacts.never_engaged, date, periodType);
      this.asleep.accumulate(dataPoint.pem_api_contacts.asleep, date, periodType);
      this.enabled.accumulate(dataPoint.pem_api_contacts.active, date, periodType);
      this.disabled.accumulate(
        dataPoint.pem_api_contacts.total - dataPoint.pem_api_contacts.active,
        date,
        periodType
      );
      this.new.accumulate(dataPoint.pem_api_contacts.recent, date, periodType);

      if (periodType === "current") this.currentDataPoints++;
      if (periodType === "previous") this.previousDataPoints++;
    });
  }

  get isPreviousIncomplete() {
    //faltan más de 2 dias de datos
    return this.previousDataPoints < this.currentDataPoints - 2;
  }

  get isPreviousEmpty() {
    return this.previousDataPoints === 0;
  }
}
