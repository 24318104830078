import { useRequests, useRequestsAPIV3 } from "@api/requests";

// Types
import {
  ScheduledCancelPlanActions,
  ScheduledCancelPlanAPIResponse,
  ScheduledCancelPlanActionsParams,
  ScheduleCancelPlanRequest,
  ScheduleCancelPlanData,
  ScheduleCancelPlanAPIResponse,
  ImmediateCancelPlanRequest,
  ImmediateCancelPlanData,
  ImmediateCancelPlanAPIResponse,
  CancelScheduleCancelPlanRequest,
  CancelScheduleCancelPlanAPIResponse,
  GetContactsPlanAPIResponse,
  SubscribePlanParams,
  SubscribePlanAPIResponse,
  SubscribePlanResponse,
} from "./plan.types";
import { PlanTier } from "@domain/plan";

export function usePlan() {
  const requestAPIV3 = useRequestsAPIV3();
  const request = useRequests();

  async function getScheduledCancelPlanActions(): Promise<ScheduledCancelPlanActions> {
    const res = await requestAPIV3<ScheduledCancelPlanAPIResponse, unknown, ScheduledCancelPlanActionsParams>({
      url: `/scheduledplanactions`,
      urlParams: {
        "filters.type": "cancel",
      },
      method: "get",
    });

    return res.data.data;
  }

  async function scheduleCancelPlan({
    executionDate,
    plan,
    reason,
    comments,
    assistedSales,
  }: ScheduleCancelPlanRequest): Promise<void> {
    await requestAPIV3<ScheduleCancelPlanAPIResponse, ScheduleCancelPlanData>({
      url: `/scheduledplanactions`,
      data: {
        type: "cancel",
        date_execution: executionDate,
        options: {
          comments: comments ?? "",
          plan,
          reason,
          assistedSales,
        },
      },
      method: "post",
    });
  }

  async function cancelScheduleCancelPlan({ id }: CancelScheduleCancelPlanRequest): Promise<void> {
    await requestAPIV3<CancelScheduleCancelPlanAPIResponse>({
      url: `/scheduledplanactions/${id}`,
      method: "delete",
    });
  }

  async function immediateCancelPlan({ id, reason, comments }: ImmediateCancelPlanRequest): Promise<void> {
    const requestPromises: Array<Promise<unknown>> = [
      request<ImmediateCancelPlanAPIResponse, ImmediateCancelPlanData>({
        url: `/plan/subscription/cancel`,
        method: "post",
        data: {
          reason,
          comments,
        },
      }),
    ];

    if (id) {
      requestPromises.unshift(cancelScheduleCancelPlan({ id }));
    }

    await Promise.all(requestPromises);
  }

  async function getContactsPlans(): Promise<PlanTier[]> {
    const res = await request<GetContactsPlanAPIResponse>({
      url: `/pricing/contacts`,
      method: "get",
    });

    return res.data;
  }

  async function subscribePlan(params: SubscribePlanParams): Promise<SubscribePlanResponse> {
    const res = await request<SubscribePlanAPIResponse, SubscribePlanParams>({
      url: `/plan/subscription`,
      method: "put",
      data: {
        contacts: params.contacts,
        payMethod: params.payMethod,
      },
    });

    return res.data;
  }

  return {
    subscribePlan,
    getContactsPlans,
    getScheduledCancelPlanActions,
    scheduleCancelPlan,
    immediateCancelPlan,
    cancelScheduleCancelPlan,
  };
}
