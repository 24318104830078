import { format, formatDistanceToNowStrict } from "date-fns";
import { es, ptBR } from "date-fns/locale";
import { getI18nInstance } from "@locales/i18n";

const currentDateFnsLocale = () => {
  switch (getI18nInstance().global.locale.value.substring(0, 2)) {
    case "es":
      return es;
    case "pt":
      return ptBR;
  }
};

export function formatDate(date, formatString, options = {}) {
  return format(date, formatString, { ...options, locale: currentDateFnsLocale() });
}

export function formatDateDistanceToNow(date, options = {}) {
  return formatDistanceToNowStrict(date, { ...options, locale: currentDateFnsLocale() });
}

export const formatTextToSearch = <T extends string = string>(text: T): string => {
  return text
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .replaceAll(/\s+/g, " ")
    .trim()
    .toLowerCase();
};

export const formatToAlphanumeric = (text: string): string => {
  const pattern: RegExp = /[^A-Za-z0-9]/g;

  const alphanumericString: string = text.replace(pattern, " ");
  return alphanumericString;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isValidURL = (input: string): boolean => {
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  const match = input.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
};

export const formatUrl = (input: string): string => {
  try {
    new URL(input);

    return input;
  } catch (error) {
    const formattedUrl = `https://${input}`;
    return formattedUrl;
  }
};

export const getUrlOrigin = (input: string) => {
  try {
    return new URL(input).origin;
  } catch (e) {
    return input;
  }
};

export const getIdentifierFromSocialNetworkUrl = (url: string) => {
  try {
    let socialUrl = new URL(url).pathname;

    if (socialUrl.startsWith("/") && socialUrl.endsWith("/")) {
      socialUrl = socialUrl.substring(1, socialUrl.length - 1);
    }

    if (socialUrl.includes("channel/") || socialUrl.includes("in/")) {
      return socialUrl.substring(socialUrl.lastIndexOf("/") + 1);
    }

    return socialUrl.slice(1);
  } catch (e) {
    return url;
  }
};

export const getDomainFromUrl = (url: string): string | undefined => {
  try {
    const formattedUrl = formatUrl(url);
    const urlObject = new URL(formattedUrl);

    const hostNameSplit = urlObject.hostname.split(".");
    const domain = hostNameSplit[0] === "www" ? hostNameSplit[1] : hostNameSplit[0];
    return domain;
  } catch (error) {
    return undefined;
  }
};

export const decodeHTMLEntities = (text: string): string => {
  const element = document.createElement("div");

  element.innerHTML = text;
  const decodedText = element.innerText;

  element.remove();

  return decodedText;
};
