<template>
  <ModalDialog without-overflow show-close-button :title="t('title')" :open="open" @close="close">
    <div class="w-[30rem]">
      <FormInput
        v-model="campaignName"
        :label="t('label')"
        :placeholder="t('placeholder')"
        :error="nameLengthError ? t('nameLengthError') : undefined"
      />
    </div>
    <template #buttons>
      <SimpleButton
        :loading="loadingAccept"
        :disabled="campaignName.length === 0 || nameLengthError"
        @click="duplicate"
      >
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, watchEffect, computed } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";
import { Campaign } from "@/vue/types/campaigns";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open: boolean;
    loadingAccept: boolean;
    campaign?: Campaign;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  "update:open": [boolean];
  duplicate: [string];
}>();

const close = () => {
  emit("update:open", false);
};

const campaignName = ref("");
const nameLengthError = computed(() => {
  return campaignName.value.length > 50;
});
watchEffect(() => {
  campaignName.value = t("textCopy", { name: props.campaign?.name ?? "" });
});
const duplicate = () => {
  emit("duplicate", campaignName.value);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Duplicar",
    "label": "Nombre",
    "placeholder": "Nombre de la campaña",
    "cancelButton": "Cancelar",
    "acceptButton": "Guardar",
    "textCopy": "Copia de {name}",
    "nameLengthError": "El nombre no puede superar los 50 caracteres"
  },
  "pt": {
    "title": "Duplicar",
    "label": "Nome",
    "placeholder": "Nome da campanha",
    "cancelButton": "Cancelar",
    "acceptButton": "Salvar",
    "textCopy": "Cópia de {name}",
    "nameLengthError": "O nome não pode exceder 50 caracteres"
  }
}
</i18n>
