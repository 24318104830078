<template>
  <component :is="href && permission ? 'a' : 'div'" :href="linkPermission">
    <SimpleButton v-bind="props" @click="onClick">
      <template v-for="(_, slot) of $slots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </SimpleButton>
  </component>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Store
import { useSessionStore, useAlertStore } from "@store";

// Domain
import type { TippyOptions } from "vue-tippy";
import type { Permissions } from "@domain/permissions";

const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    disabled?: boolean;
    loading?: boolean;
    tooltip?: string;
    showTooltip?: boolean;
    selected?: boolean;
    tooltipProps?: TippyOptions;
    size?: "min" | "small" | "base";
    theme?:
      | "primary"
      | "primary-light"
      | "primary-clean"
      | "secondary"
      | "secondary-light"
      | "secondary-text"
      | "white"
      | "yellow"
      | "yellow-clean"
      | "danger"
      | "danger-alter"
      | "header-primary"
      | "header-accent"
      | "green-lime"
      | "header-sky";
    permissions: Permissions;
    href?: string;
  }>(),
  {
    modelValue: undefined,
    disabled: false,
    loading: false,
    tooltip: undefined,
    showTooltip: true,
    selected: false,
    tooltipProps: undefined,
    size: "base",
    theme: "primary",
    href: undefined,
  },
);

const emit = defineEmits<{
  click: [MouseEvent];
}>();

const slots = defineSlots<{
  leading?: () => any;
  default?: () => any;
  trailing?: () => any;
}>();

const permission = computed<boolean>(() => {
  return props.permissions.every((p) => sessionStore.hasPermission(p));
});

const linkPermission = computed(() => {
  if (!permission.value) return "javascript:void(0)";
  return props.href;
});

const onClick = (event: MouseEvent) => {
  if (!permission.value) {
    alertStore.showPermissionDenied(props.permissions);

    return;
  }
  emit("click", event);
};
</script>
