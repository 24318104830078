import { SelectorView } from "@/core/magnet";

export default SelectorView.extend({
  module: "links",
  templates: {
    initial: require("../templates/selector.hbs"),
    selectorSnippet: require("../templates/selectorSnippet.hbs"),
  },
  cache: false,
  checkboxInputName: "linkId[]",
});
