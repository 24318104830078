var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h3>\n    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"transferMessageTitle")), depth0))
    + "\n</h3>\n<p>\n    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"transferMessageDescription")), depth0))
    + "\n</p>\n<a onclick=\"document.getElementById('confirm-transfer-btn').click();\" href='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"paymethods") : stack1)) != null ? lookupProperty(stack1,"transfer") : stack1)) != null ? lookupProperty(stack1,"accountInfo") : stack1), depth0))
    + "' target='_blank' class='btn btn-link'>\n    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"downloadTransferData")), depth0))
    + "\n</a>\n\n<div class='transfer-message-action'>\n    <button id=\"confirm-transfer-btn\" class='btn btn-primary pull-right' data-action='pay' data-params='"
    + alias2(((helper = (helper = lookupProperty(helpers,"params") || (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"params","hash":{},"data":data,"loc":{"start":{"line":12,"column":104},"end":{"line":12,"column":114}}}) : helper)))
    + "' data-product-type='"
    + alias2(((helper = (helper = lookupProperty(helpers,"productType") || (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"productType","hash":{},"data":data,"loc":{"start":{"line":12,"column":135},"end":{"line":12,"column":150}}}) : helper)))
    + "' data-payment-type='transfer'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirm")), depth0))
    + "\n    </button>\n</div>\n";
},"useData":true});