import { markRaw } from "vue";

// Icons
import { TrashIcon, DownloadIcon, ArchiveIcon, StopIcon } from "@heroicons/vue/outline";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { ColumnsIds, CampaignsRow, CampaignsRows, CampaignsTabs } from "./campaigns.types";
import type { Campaign, Campaigns, ListColumns, ListColumn } from "@domain/campaigns";
import type { Items } from "@templates/PageTemplate";
import type { Items as CheckBoxListItems } from "@molecules/CheckboxListButton";
import type { Columns, Column } from "@molecules/Table";

export const columnsToList = (columns: ListColumns): CheckBoxListItems<ColumnsIds | "options", string, ListColumn> => {
  const fixedColumnsIds = ["launchDate"];
  const filteredColumns = columns.filter((c) => !fixedColumnsIds.includes(c.id));
  return filteredColumns.map((col) => ({
    key: col.id.toString() as ColumnsIds | "options",
    value: col.name,
    data: col,
  }));
};

export const getOptions = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "export",
        value: t("options.export"),
        icon: DownloadIcon,
      },
    ],
  };
};

export const getTabs = (): Items<CampaignsTabs> => {
  const { t } = useI18n();
  return [
    {
      key: "all",
      value: t("tabs.all"),
    },
    {
      key: "sent",
      value: t("tabs.sent"),
    },
    {
      key: "scheduled",
      value: t("tabs.pending"),
    },
    {
      key: "draft",
      value: t("tabs.drafts"),
    },
  ];
};

export const getColumns = (
  columns: CheckBoxListItems<ColumnsIds | "options", string, ListColumn>,
  tab: CampaignsTabs,
  maxCols: number,
): Columns<ColumnsIds | "options"> => {
  const { t } = useI18n();
  const columnsBreakPoints: Record<string, "lg" | "md" | "sm"> = {
    [maxCols]: "lg",
    [maxCols - 1]: "md",
    [maxCols - 2]: "sm",
  };

  const cols: Columns<ColumnsIds | "options"> = [
    {
      id: "name",
      text: t("columns.name"),
      sortModes: ["ASC", "DESC"],
      headerTextAlign: "left",
      headerWidthRem: ["all", "sent"].includes(tab) ? 42 : undefined,
    },
    ...(["all", "sent", "scheduled"].includes(tab)
      ? ([
          {
            id: "launchDate",
            text: tab === "sent" ? t("columns.launchDate") : t("columns.date"),
            sortModes: ["DESC", "ASC"],
            textMaxWidth: true,
            headerTextAlign: "right",
            textAlign: "right",
          },
        ] as Columns<ColumnsIds | "options">)
      : ([] as Columns<ColumnsIds | "options">)),
    ...(["all", "sent"].includes(tab)
      ? (columns
          .map<Column>((col) => ({
            id: col.key,
            text: col.value,
            breakpoint: columnsBreakPoints?.[columns.length] ?? "xs",
            textMaxWidth: true,
            headerTextAlign: "right",
            textAlign: "right",
          }))
          .slice(0, maxCols) as Columns<ColumnsIds | "options">)
      : ([] as Columns<ColumnsIds | "options">)),
    {
      id: "options",
      headerWidthRem: 3,
    },
  ];

  return cols;
};

export const getListOptionsScheduled = (): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      {
        key: "stop",
        value: t("listOptions.stop"),
        icon: StopIcon,
      },
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "export",
        value: t("listOptions.export"),
        icon: DownloadIcon,
      },
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "export",
        value: t("listOptions.export"),
        icon: DownloadIcon,
      },
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red"
      }
    ]
  };
};


export const getListOptionsSending = (): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "export",
        value: t("listOptions.export"),
        icon: DownloadIcon,
      },
    ]
  };
};

export const getListOptionsArchived = (): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      {
        key: "unarchive",
        value: t("listOptions.unarchive"),
        icon: ArchiveIcon,
      },
    ],
  };
};

export const getListOptionsSent = (): Categories => {
  const { t } = useI18n();

  return {
    actions: [
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "export",
        value: t("listOptions.export"),
        icon: DownloadIcon,
      },
      {
        key: "archive",
        value: t("listOptions.archive"),
        icon: ArchiveIcon,
      },
    ],
  };
};

export const campaignToRow = (campaign: Campaign): CampaignsRow => {
  return {
    id: campaign.id.toString(),
    content: {
      name: campaign.name,
      launchDate: campaign.launchDate ?? "",
      sent: campaign?.metrics?.sent.toString() ?? "",
      openedP: campaign?.metrics?.openedP.toString() ?? "",
      clickedP: campaign?.metrics?.clickedP.toString() ?? "",
      bouncedP: campaign?.metrics?.bouncedP.toString() ?? "",
      conversionsAmount: campaign?.metrics?.conversionsAmount.toString() ?? "",
      state: campaign.state,
      options: "",
    },
    data: campaign,
  };
};

export const campaignsToRows = (campaigns: Campaigns): CampaignsRows => {
  return campaigns.map<CampaignsRow>(campaignToRow);
};
