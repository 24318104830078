import type { localesLanguages } from "@locales/i18n";

export interface Link {
  helpId?: number;
  tourId?: number;
  videosArticleId?: number;
}

export type Links = Partial<Record<localesLanguages, Link>>;

export const links: Links = {
  es: {
    helpId: 6720480,
    tourId: 402259,
    videosArticleId: 6980992,
  },
  pt: {
    helpId: 6720480,
    tourId: 402268,
    videosArticleId: 6980992,
  },
};
