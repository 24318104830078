import { AxiosError } from "axios";

export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = "[Unable to stringify the thrown value]";
  try {
    stringified = JSON.stringify(value);
  } catch {}

  const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`);
  return error;
}

export function ensureAxiosError(value: unknown): AxiosError {
  if (value instanceof AxiosError) return value;

  let stringified = "[Unable to stringify the thrown value]";
  try {
    stringified = JSON.stringify(value);
  } catch {}

  const error = new AxiosError(`This value was thrown as is, not through an Error: ${stringified}`);
  return error;
}
