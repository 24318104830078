import { Model } from "@/core/magnet";
import app from "@/app";

export default Model.extend({
  urlRoot: function () {
    var self = this;
    self.type = "users";
    if (app.session.get("isMasterUser")) {
      self.type = "masterusers";
    }
    return self.type + "/me/notifications";
  },
  parse: function (response) {
    return response.data;
  },
  read: function () {
    var self = this;
    return self.save(
      { unread: 0 },
      {
        dataType: "json",
        contentType: "application/json",
        type: "PUT",
        data: JSON.stringify({ read: true }),
      }
    );
  },
});
