import { EditView } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";
import AccountFeaturesConfigPanel from "@organisms/Account/AccountFeatures/AccountFeaturesConfigPanel";
import SendersPanel from "@organisms/Senders/SendersPanel.vue";
import { mountComponent } from "@/helpers/vue";
import app from "@/app";
import { useSessionStore } from "@/vue/stores";
const moment = window.moment;

export default EditView.extend({
  module: "accounts",
  gender: "F",
  templates: {
    initial: require("../templates/show.hbs"),
    show: require("../templates/show.hbs"),
    edit: require("../templates/edit.hbs"),
  },
  cache: false,
  fetchParams: {
    headers: {
      "Account-Prefix": false,
    },
  },
  skipMarketing: ["simultanews"],
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "submit [data-form=save]": "save",
      "change [data-input=account-logo]": "updateLogo",
      "click .image-container": "openFileSelection",
      "submit [data-form=marketing]": "saveMarketingDetails",
      "change [name=signup_uxp_level]": "updateOtherToolsVisibility",
      "keyup [name=name]": "autocompleteCode",
      "click [data-action=disable]": "disable",
      "click [data-action=activate-drip]": "activateDrip",
      "click [data-action=activate-sendersv2]": "activateSendersV2",
      "click [data-action=activate-ad]": "activateAd",
      "click [data-action=enable]": "enable",
      "click [data-action=reserve-demo]": "reserveDemo",
      "click [data-action=reserve-call]": "reserveCall",
      "click [data-action=activate-campaigns-approval]": "activateCampaignsApproval",
      "click [data-action=disable-campaigns-approval]": "disableCampaignsApproval",
      "click [data-action=export-users]": "exportUsers",
      "change [name=signup_current_lists_size]": "updateDemoButtonVisibility",
      "change [name=signup_goal]": "updateOtherGoalVisibility",
      "change [name=signup_attribution]": "updateAttributionDetailsVisibility",
      "change [name=timezone]": "showLocalTime",
      // "click [data-action=disallow-disable-exclude]": "disallowDisableExclude",
      // "click [data-action=allow-disable-exclude]": "allowDisableExclude",
      "click [data-action=block-import]": "blockImport",
      "click [data-action=unblock-import]": "unblockImport",
      "change [name=country]": "updateCountryDisclaimer",
      "click [data-action=unchild]": "unchild",
    });
  },

  // Performs an action before fetching the collection
  beforeRender: function (callback) {
    var self = this,
      details,
      hasDrip,
      showAdAction;
    self.buffer = {};

    // Check if the user is in the settings page
    if (app.router.isCurrentRoute("settings")) {
      self.item.set("inSettings", true);
      self.item.set("code", app.session.attributes.account);
      self.item.set("route", "settings");
    } else {
      self.item.set("inSettings", false);
      self.item.set("route", "accounts");
    }

    // Assign the default coountry based on parent
    if (self.item.isNew()) {
      self.item.set("country", app.detectedCountry || self.item.additionalsData.account.country);
      $(".mobile-splash").addClass("hidden");
    }

    // Check features
    details = app.session.get("details");

    // Drip
    hasDrip = false;
    if (details && objHas(details, "account", "features", "DRIP_ENABLED")) {
      hasDrip = true;
    }
    self.item.set("hasDrip", hasDrip);

    // Drip
    let hasSendersV2 = false;
    if (details && objHas(details, "account", "features", "SENDERSV2")) {
      hasSendersV2 = true;
    }
    self.item.set("hasSendersV2", hasSendersV2);

    // Ad
    showAdAction = app.session.get("isMasterUser");
    if (details && objHas(details, "account", "features", "PERFIT_AD") && details.account.features.PERFIT_AD === "1") {
      showAdAction = false;
    }
    self.item.set("showAdAction", showAdAction);

    // if (details?.account?.features?.ALLOW_DISABLE_EXCLUDE === "1") {
    //   self.item.set("allowDisableExclude", false);
    // } else {
    //   self.item.set("allowDisableExclude", true);
    // }

    if (details?.account?.features?.IMPORT_BLOCKED === "1") {
      self.item.set("showUnblockImport", true);
      self.item.set("showBlockImport", false);
    } else {
      self.item.set("showUnblockImport", false);
      self.item.set("showBlockImport", true);
    }
    
    let showUnchild = false;

    if (app.session.get("isMasterUser")) {
      const inSettings = self.item.get("inSettings");
      showUnchild = !inSettings || (inSettings && details.account.parent != null);
    }
    
    self.item.set("showUnchild", showUnchild);

    // Render
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    // Show the details screen if the account is incomplete
    if (window.shouldCompleteDetails) {
      self.completeAccountDetails();
      self.showLocalTime();
    }

    mountComponent(SendersPanel, {}, "#vue-senders-panel-config");

    if (app.session.get("isMasterUser"))
      mountComponent(AccountFeaturesConfigPanel, { account: this.options.id }, "#vue-account-features-config-modal");
  },

  // Saves the account data
  save: function (e) {
    var self = this,
      form = e.currentTarget,
      creating = self.item.isNew(),
      country,
      url,
      attributes,
      container = $("[name=container]"),
      accountsMenu = $("[data-section=accounts]").closest("li"),
      method;
    e.preventDefault();

    // Abort if the form has errors
    attributes = self.getFormFieldsObject(form, false);
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Add type
    if (!self.item.get("inSettings") && !app.router.isCurrentRoute("dashboard")) {
      attributes.plan = attributes.plan || {};
      if (objHas(attributes, "plan", "emails", "limit") && parseInt(attributes.plan.emails.limit, 10)) {
        attributes.plan.type = "CHILD_LIMITED";
      } else {
        attributes.plan = { type: "CHILD_UNCAPPED" };
      }
    }

    // Concatenate address and country
    if (attributes.address !== undefined) {
      country = _.findWhere(self.item.relationshipsData.countries, {
        id: attributes.country,
      });
      attributes.postalAddress = [attributes.address, country.name].join(", ");
    }

    // Set container
    if (container.length && container.data("changed")) {
      attributes.container = container.is(":checked");
    }

    // Set account ID for sign up process
    if (window.shouldCompleteDetails) {
      self.item.id = app.session.get("account");
      self.item.set("code", self.item.id);
      creating = false;
    }

    // Set URL
    if (creating) {
      url = app.session.attributes.account + "/accounts";
      method = "POST";
    } else {
      url = self.item.get("code");
      method = "PUT";
    }

    // update user timezone
    if (attributes.timezone) {
      $.ajax({
        url: "users/me",
        type: "PUT",
        data: JSON.stringify({ timezone: attributes.timezone }),
        contentType: "application/json",
      }).then(() => {
        app.session.setTimezone(attributes.timezone);
      });
    }

    // Save settings
    return self.item.save(attributes, {
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify(attributes),
      url: url,
      method: method,
      headers: {
        "Account-Prefix": false,
      },
      success: function (model, response) {
        var details;
        // Update account details in session
        if (self.item.get("inSettings") || window.shouldCompleteDetails) {
          const sessionAcccount = app.session.get("details").account;
          const newAccount = { ...sessionAcccount, ...response.data };
          details = $.extend({}, app.session.get("details"), {
            account: newAccount,
          });
          app.session.set("details", details);
          app.saveSessionCookie(app.session.toJSON());
        }

        const preinit = JSON.parse(window.localStorage.getItem("preinit"));

        // Show marketing details page
        if (window.shouldCompleteDetails) {
          if (window.utm_source !== undefined && _.contains(self.skipMarketing, window.utm_source)) {
            app.integrations.intercom.update({
              company: {
                id: app.session.get("account"),
                name: app.session.get("details").account.name,
                is_ecommerce: !!preinit,
              },
            });
            return self.closeMarketingModal();
          }
          var phone = self.intlTelInput.getNumber();

          self.buffer = {
            website: attributes.company.website,
            phone: phone,
            callme: attributes.callme,
            industry: attributes.industry,
          };

          app.integrations.intercom.update({
            company: {
              id: app.session.get("account"),
              name: app.session.get("details").account.name,
              is_ecommerce: !!preinit || attributes.industry === "eCommerce",
              ...self.buffer,
            },
          });

          return self.completeMarketingDetails();
        }

        // Show feedback
        if (creating) {
          self.afterCreate(attributes);
          $(".account-button").addClass("can-change");

          // Update ACLs
          if (!app.session.get("isMasterUser")) {
            $.ajax({
              url: "session",
              type: "GET",
              headers: {
                "Account-Prefix": false,
              },
              success: function (response) {
                localStorage.setItem("acl", JSON.stringify(response.data.acl));
                app.session.set("acl", response.data.acl);
                app.session.setPermissions(response.data.acl);
              },
            });
          }
        } else {
          // If the account has been promoted to container
          if (app.session.get("account") === model.id && attributes.container !== undefined) {
            if (attributes.container && app.session.hasPermission("accounts:list")) {
              accountsMenu.removeClass("hide");
            } else {
              accountsMenu.addClass("hide");
            }
          }

          // Complete feedback
          self.afterUpdate(attributes);
        }
      },
      error: function (item, xhr, error) {
        self.saveError(item, xhr, error, form);
      },
    });
  },

  // Return to account show page
  back: function () {
    var self = this;
    self.show();
  },

  // Runs after deleting a model
  afterDelete: function () {
    var self = this;
    self.showDeletedMessage();
    app.router.navigateTo("accounts");
  },

  // Enables an account
  enable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    // Perform API call
    return model.enable({
      success: function () {
        self.displayMessage(window.lang.accounts.enabledMessage);
        self.reload();
      },
    });
  },

  // Disables an account
  disable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    // Perform API call
    return model.disable({
      success: function () {
        self.displayMessage(window.lang.accounts.disabledMessage);
        self.reload();
      },
    });
  },

  activateDrip: function () {
    var confirmText = window.lang.accounts.confirmActivateDrip,
      button = null,
      titleText = window.lang.confirm,
      type = "info",
      flag = null;
    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return this.item.activateDrip().then(
          function () {
            this.displayMessage(window.lang.accounts.dripEnabledMessage);
            $('[data-feature="DRIP_ENABLED"]').closest("li").removeClass("hidden");
          }.bind(this),
        );
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  activateSendersV2: function () {
    var button = null,
      type = "info",
      flag = null;
    this.confirmModal(
      "Los remitentes actuales se van a migrar a la nueva modalidad.<br/><br/>El usuario deberá salir y volver a loguearse para ver los cambios.",
      button,
      "Activar nueva modalidad de remitentes?",
      type,
      flag,
      function () {
        return this.item.activateSendersV2().then(
          function () {
            app.session.attributes.details.account.features.SENDERSV2 = "1";
            app.saveSessionCookie(app.session.toJSON());
            this.displayMessage("Activado");
          }.bind(this),
        );
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  activateAd: function () {
    var confirmText = window.lang.accounts.confirmActivateAd,
      button = null,
      titleText = window.lang.confirm,
      type = "info",
      flag = null;
    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return this.item.activateAd().then(
          function () {
            app.session.attributes.details.account.features.PERFIT_AD = "1";
            this.displayMessage(window.lang.accounts.adEnabledMessage);
            this.reload();
          }.bind(this),
        );
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  // Open the file selector
  openFileSelection: function () {
    $("[data-input=account-logo]").trigger("click");
  },

  // Manage selected files
  updateLogo: function (e) {
    var self = this,
      file = e.currentTarget.files[0],
      form = $(e.currentTarget).closest("form"),
      formData = new FormData();

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Start loader
    self.loading(true);

    // Updates logo
    formData.append("logo", file);
    $.ajax({
      url: self.item.get("code") + "/logo",
      type: "PUT",
      headers: {
        "Account-Prefix": false,
      },
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      success: function () {
        self.loading(false);
        self.reloadLogo();
        form.get(0).reset();
      },
    });
  },

  // Reloads the logo
  reloadLogo: function () {
    var self = this;
    self.$("#logo-image").css(
      "background-image",
      // eslint-disable-next-line prettier/prettier
      `url(${window.config.api}accountlogo/${self.item.attributes.code}?time=${Date.now()})`,
    );
  },

  // Shows the page to complete the account details data
  completeAccountDetails: function () {
    var self = this,
      template = require("../templates/details.hbs");
    self.$(self.renderArea).empty();

    // Integration preinit data

    const preinit = JSON.parse(window.localStorage.getItem("preinit"));
    if (preinit) {
      // precarga de datos de cuenta desde la info de la integracion
      try {
        if (preinit.integration === "tiendanube") {
          this.item.attributes.name = preinit.store.name;
          this.item.attributes.company = {
            website: preinit.store.url_with_protocol,
          };
          this.item.attributes.phone = preinit.store.phone;
          this.item.attributes.address = preinit.store.business_address;
          this.item.attributes.industry = "eCommerce";
        } else if (preinit.integration === "jumpseller") {
          this.item.attributes.name = preinit.store.name;
          this.item.attributes.company = {
            website: preinit.store.url,
          };
          this.item.attributes.address = `${preinit.store.address.address || ""}, ${
            preinit.store.address.city || ""
          }, ${preinit.store.address.country}`;
          this.item.attributes.industry = "eCommerce";
        } else if (preinit.integration === "tray") {
          this.item.attributes.name = preinit.store.name;
          this.item.attributes.company = {
            website: preinit.store.secure_uri,
          };
          this.item.attributes.address = `${preinit.store.address || ""}, ${preinit.store.city || ""}, ${
            preinit.store.state
          }`;
          this.item.attributes.phone = preinit.store.phone_number_1;
          this.item.attributes.industry = "eCommerce";
        } else if (preinit.integration === "ecwid") {
          this.item.attributes.name = preinit.store.account.brandName;
          this.item.attributes.company = {
            website: preinit.store.general_info.storeUrl,
          };
          this.item.attributes.address = `${preinit.store.company.city || ""}, ${preinit.store.company.countryCode}`;
          this.item.attributes.industry = "eCommerce";
        }
      } catch (e) {
        console.error(e);
        app.integrations.bugsnag.notify(e);
      }
    }

    $("<div>")
      .appendTo(".app")
      .attr({
        id: "details",
        class: "login account-details-modal",
      })
      .html(template(self.renderData()));
    app.layout.removeCover();

    var telInput = document.querySelector("#phone");
    this.intlTelInput = window.intlTelInput(telInput, {
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js",
      preferredCountries: ["ar", "br", "bo", "cl", "co", "ec", "mx", "py", "pe", "uy", "ve"],
      separateDialCode: true,
      initialCountry: app.detectedCountry,
      nationalMode: false,
    });

    this.updateCountryDisclaimerBanner(app.detectedCountry);
  },

  // Shows the page to complete the marketing data
  completeMarketingDetails: function () {
    var self = this,
      template = require("../templates/marketing.hbs");
    self.$("#details").html(template(self.renderData()));

    app.integrations.gtm.event({ event: "signup.step2" });
  },

  // Saves the account details
  saveMarketingDetails: function (e) {
    var self = this,
      form = e.currentTarget,
      data = $(form).serializeObject();
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    //refresh intercom user and account data
    app.integrations.start();

    // Manage data
    if (data.signup_preferred_contact) {
      data.signup_preferred_contact = data.signup_preferred_contact.join(", ");
    }
    data.company = {
      id: app.session.get("account"),
      name: app.session.get("details").account.name,
      industry: app.session.get("details").account.industry,
      website: self.buffer.website,
    };

    data.phone = self.buffer.phone;
    data.signup_callme = self.buffer.callme;
    if (data.company.industry === "eCommerce") {
      data.company.is_ecommerce = true;
    }

    const preinit = JSON.parse(window.localStorage.getItem("preinit"));
    if (preinit) {
      data.company.is_ecommerce = true;
      try {
        if (preinit.integration === "tiendanube") {
          data.company.store_type = preinit.integration;
          data.company.store_name = preinit.store.name;
          data.company.store_description = preinit.store.description;
          data.company.store_url = preinit.store.domain;
          data.company.store_phone = preinit.store.phone;
          data.company.store_email = preinit.store.email;
          data.company.store_facebook = preinit.store.facebook;
          data.company.store_instagram = preinit.store.instagram;
        } else if (preinit.integration === "jumpseller") {
          data.company.store_type = preinit.integration;
          data.company.store_name = preinit.store.name;
          data.company.store_url = preinit.store.url;
          data.company.store_email = preinit.store.email;
        } else if (preinit.integration === "tray") {
          data.company.store_type = preinit.integration;
          data.company.store_name = preinit.store.name;
          data.company.store_url = preinit.store.secure_uri;
          data.company.store_email = preinit.store.email_1;
          data.company.store_phone = preinit.store.phone_number_1;
        } else if (preinit.integration === "ecwid") {
          data.company.store_type = preinit.integration;
          data.company.store_name = preinit.store.account.brandName;
          data.company.store_url = preinit.store.general_info.storeUrl;
          data.company.store_email = preinit.store.company.email;
        }
      } catch (e) {
        console.error(e);
        app.integrations.bugsnag.notify(e);
      }
    }

    // Turn off the flag
    window.shouldCompleteDetails = false;

    // Update Intercom
    app.integrations.intercom.update(data);

    // Broadcast event
    app.integrations.amplitude.event("APP_SIGNUP_CONFIRMED");
    app.integrations.intercom.event("APP_SIGNUP_CONFIRMED");
    app.integrations.gtm.event({ event: "signup.completed" });

    window.tap("trial", data.company.id);

    // Hide modal and show dashboard
    self.closeMarketingModal();
  },

  // Close the marketing screen
  closeMarketingModal: function () {
    var self = this;
    self.$("#details").fadeOut(400, $("#details").remove());
    $(".mobile-splash").removeClass("hidden");

    const preinit = JSON.parse(window.localStorage.getItem("preinit"));
    if (preinit) {
      // activar integracion
      try {
        var initData = { preinit_code: preinit.id };
        const integration = new app.models.integrations({
          id: preinit.integration,
        });

        if (preinit.integration === "tray") {
          initData.shopUrl = preinit.store.api_host;
        }

        integration
          .init(initData)
          .done(function () {
            self.displayMessage(window.lang.integrations.feedback.activatedOnSignup, 5000);
            self.reload();
          })
          .fail(function () {
            self.displayMessage(window.lang.integrations.failedOnSignup, 5000, "error");
            self.reload();
          });

        localStorage.removeItem("preinit");
      } catch (e) {
        console.error(e);
        app.integrations.bugsnag.notify(e);
        self.displayMessage(window.lang.integrations.failedOnSignup, 5000, "error");
        self.reload();
      }
    } else {
      self.reload();
    }
  },

  // Completes the username based on the email
  autocompleteCode: function (e) {
    var code,
      name = $(e.currentTarget).val(),
      destination = $("[name=code]");
    code = name
      .replace(/[^a-zA-Z0-9]/gi, "")
      .toLowerCase()
      .substring(0, 15);
    destination.val(code).attr("data-changed", 1);
  },

  // Fire Calendly plugin
  reserveDemo: function (e) {
    e.preventDefault();
    const name = app.session.get("details").user.name;
    const email = app.session.get("details").user.email;
    const phone = this.intlTelInput.getNumber();
    const calendar =
      app.lang === "pt"
        ? "https://calendly.com/treinamento-perfit/demo-pt"
        : "https://calendly.com/capacitacion-perfit/demo";

    window.Calendly.showPopupWidget(`${calendar}?name=${name}&email=${email}&a1=${phone}`);

    $("[name=signup_want_demo]").prop("checked", true);
  },

  // Fire Calendly plugin
  reserveCall: function (e) {
    e.preventDefault();
    const name = app.session.get("details").user.name;
    const email = app.session.get("details").user.email;
    const phone = this.intlTelInput.getNumber();
    const calendar =
      app.lang === "pt"
        ? "https://calendly.com/treinamento-perfit/chamada"
        : "https://calendly.com/capacitacion-perfit/llamado";

    window.Calendly.showPopupWidget(`${calendar}?name=${name}&email=${email}&a1=${phone}`);
    $("[name=callme]").prop("checked", true);
  },

  // Activates campaigns approval
  activateCampaignsApproval: function () {
    this.item.requireCampaignsApproval(true).done(
      function () {
        this.reload();
        this.showUpdatedMessage();
      }.bind(this),
    );
  },

  // Disable campaigns approval
  disableCampaignsApproval: function () {
    this.item.requireCampaignsApproval(false).done(
      function () {
        this.reload();
        this.showUpdatedMessage();
      }.bind(this),
    );
  },

  // Export users
  exportUsers: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    // Perform API call
    window.open(
      `${window.config.api}${model.attributes.code}/users/export?token=${app.session.attributes.token}`,
      "_blank",
    );
  },

  updateDemoButtonVisibility: function (e) {
    var value = $(e.currentTarget).val();

    if (value.includes("50.000") || value.includes("100.000")) {
      $("#want-demo").removeClass("hidden");
    } else {
      $("#want-demo").addClass("hidden");
    }
  },

  updateOtherToolsVisibility: function (e) {
    var value = $(e.currentTarget).val();
    if (["noob", "none"].includes(value)) {
      $("#tools").addClass("hidden");
    } else {
      $("#tools").removeClass("hidden");
    }
  },

  updateOtherGoalVisibility: function (e) {
    var value = $(e.currentTarget).val();
    if (value === "Otro") {
      $("#goal-other").removeClass("hidden");
    } else {
      $("#goal-other").addClass("hidden");
    }
  },

  updateAttributionDetailsVisibility: function (e) {
    var value = $(e.currentTarget).val();
    if (value === "other") {
      $("#attribution-details").removeClass("hidden");
      $("#attribution-details").attr("placeholder", window.lang.signUp.attributionDetailsOther);
    } else if (value === "recommendation") {
      $("#attribution-details").removeClass("hidden");
      $("#attribution-details").attr("placeholder", window.lang.signUp.attributionDetailsRecommendation);
    } else if (value === "agency") {
      $("#attribution-details").removeClass("hidden");
      $("#attribution-details").attr("placeholder", window.lang.signUp.attributionDetailsAgency);
    } else {
      $("#attribution-details").addClass("hidden");
    }
  },

  updateCountryDisclaimer: function (e) {
    var value = $(e.currentTarget).val();
    this.updateCountryDisclaimerBanner(value);
  },

  updateCountryDisclaimerBanner: function (country) {
    if (["es", "pt", "it", "fr"].includes(country)) {
      $("#gdrp-disclaimer").removeClass("hidden");
      $("#save-details-btn").prop("disabled", true);
    } else {
      $("#gdrp-disclaimer").addClass("hidden");
      $("#save-details-btn").removeAttr("disabled");
    }
  },

  showLocalTime: function () {
    const selectedTimezone = $("[name=timezone]").val();
    $("#local-time-sample").text(moment().tz(selectedTimezone).format("HH:mm"));
  },

  // allowDisableExclude: function () {
  //   var button = null,
  //     type = "info",
  //     self = this,
  //     flag = null;
  //   this.confirmModal(
  //     "Solo permitir en cuentas donde es estrictamente necesario, como por ejemplo para comunicación internta. El usuario deberá reiniciar sesión para ver los cambios.",
  //     button,
  //     "Habilitar no excluir sin interacción",
  //     type,
  //     flag,
  //     function () {
  //       return this.item.allowDisableExclude().then(
  //         function () {
  //           app.session.attributes.details.account.features.ALLOW_DISABLE_EXCLUDE = "1";
  //           // app.saveSessionCookie(app.session.toJSON()); // FIXME: no se porque pincha al carga campaña si pongo esto
  //           this.displayMessage("Listo");
  //           self.reload();
  //         }.bind(this)
  //       );
  //     }.bind(this),
  //     function () {
  //       this.getModal().modal("hide");
  //     }.bind(this)
  //   );
  // },

  // disallowDisableExclude: function () {
  //   var button = null,
  //     type = "info",
  //     self = this,
  //     flag = null;
  //   this.confirmModal(
  //     "Los usuarios no podrán desactivar la opción de excluir a los contactos sin interacción. El usuario deberá reiniciar sesión para ver los cambios.",
  //     button,
  //     "Bloquear excluir sin interacción",
  //     type,
  //     flag,
  //     function () {
  //       return this.item.disallowDisableExclude().then(
  //         function () {
  //           app.session.attributes.details.account.features.ALLOW_DISABLE_EXCLUDE = "0";
  //           // app.saveSessionCookie(app.session.toJSON());  //  FIXME: no se porque pincha al carga campaña si pongo esto
  //           this.displayMessage("Listo");
  //           self.reload();
  //         }.bind(this)
  //       );
  //     }.bind(this),
  //     function () {
  //       this.getModal().modal("hide");
  //     }.bind(this)
  //   );
  // },

  blockImport: function () {
    var button = null,
      type = "info",
      self = this,
      flag = null;
    this.confirmModal(
      "Bloquear a esta cuenta la posibilidad de importar nuevas listas. Este cambio tendrá efecto cuando el usuario vuelva a iniciar sesión.",
      button,
      "Bloquear import",
      type,
      flag,
      function () {
        return this.item.blockImport().then(
          function () {
            app.session.attributes.details.account.features.IMPORT_BLOCKED = "1";
            //            app.saveSessionCookie(app.session.toJSON());
            this.displayMessage("Import bloqueado");
            self.reload();
          }.bind(this),
        );
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  unblockImport: function () {
    var button = null,
      type = "info",
      self = this,
      flag = null;
    this.confirmModal(
      "Volver a permitir a esta cuenta la posibilidad de importar nuevas listas. Este cambio tendrá efecto cuando el usuario vuelva a iniciar sesión.",
      button,
      "Desbloquear import",
      type,
      flag,
      function () {
        return this.item.unblockImport().then(
          function () {
            app.session.attributes.details.account.features.IMPORT_BLOCKED = "0";
            //            app.saveSessionCookie(app.session.toJSON());
            this.displayMessage("Import desbloqueado");
            self.reload();
          }.bind(this),
        );
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  unchild: function (e) {
    const self = this;
    const model = self.item;
    e.preventDefault();

    this.confirmModal(
      `Vas a independizar la cuenta ${self.item.get("code")}, ¿estás seguro?`,
      null,
      "Independizar cuenta",
      "info",
      null,
      function () {
        return model.unchild({
          success: function () {
            self.displayMessage("Cuenta independizada con éxito");
            
            app.session.attributes.details.account.parent = null;

            const sessionStore = useSessionStore();
            sessionStore.setSessionWithLegacySession(app.session.attributes);

            if (!self.item.get("inSettings")) {
              app.router.navigateTo("accounts");
            }

            self.reload();
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },
});
