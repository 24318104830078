import script from "./QRTemplateSeven.vue?vue&type=script&lang=ts&setup=true"
export * from "./QRTemplateSeven.vue?vue&type=script&lang=ts&setup=true"

import "./QRTemplateSeven.vue?vue&type=style&index=0&id=584a930a&lang=css"
/* custom blocks */
import block0 from "./QRTemplateSeven.vue?vue&type=custom&index=0&blockType=i18n&lang=jsonc"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__