<template>
  <div class="flex flex-col">
    <div class="max-w-full overflow-x-auto">
      <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-visible">
          <table class="w-full min-w-full table-fixed divide-y divide-gray-200">
            <thead class="bg-white">
              <tr>
                <slot name="headers"></slot>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <slot name="rows"></slot>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleTable"
};
</script>
