<template>
  <div class="relative">
    <InputLabel for="phone-number">{{ label }}</InputLabel>
    <div class="absolute inset-y-0 left-0 z-10 flex items-center">
      <select
        id="country"
        name="country"
        :value="country"
        autocomplete="country"
        class="mb-1 max-w-[3.5rem] rounded-md border-0 bg-transparent py-2 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:text-sm"
        @change="updateSelectedCountry"
      >
        <option v-for="cnt in countries" :key="cnt" :value="cnt" :selected="country === cnt">
          {{ getFlag(cnt) }}
          {{ regionNames.of(cnt) }}
          {{ `+${getPhoneCode(cnt)}` }}
        </option>
      </select>
    </div>
    <SimpleInput
      :model-value="phone"
      type="text"
      name="phone-number"
      id="phone-number"
      autocomplete="tel"
      :placeholder="formattedPlaceholder"
      :class="[
        {
          'placeholder:text-red-300': error,
        },
        'pl-16',
      ]"
      @update:model-value="updatePhone"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter="onEnter"
      @input="onInput"
    />
    <InputHint v-if="!error" id="phone-number-hint">{{ hint }}</InputHint>
    <InputError v-if="error" id="phone-number-error">{{ error }}</InputError>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";

// Components
import SimpleInput from "@atoms/SimpleInput.vue";
import InputLabel from "@atoms/InputLabel.vue";
import InputHint from "@atoms/InputHint.vue";
import InputError from "@atoms/InputError.vue";

// Helpers
import { getPhoneCode, getExampleNumber, isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import type { CountryCode } from "libphonenumber-js";

// I18n
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const props = withDefaults(
  defineProps<{
    phone?: string;
    selectedCountry?: CountryCode;
    error?: string;
    hint?: string;
    label?: string;
    format?: "national" | "international";
  }>(),
  {
    phone: "",
    selectedCountry: "AR",
    label: undefined,
    error: undefined,
    hint: undefined,
    format: "international",
  },
);

const emit = defineEmits<{
  "update:phone": [string];
  "update:selectedCountry": [CountryCode];
  "update:validity": [boolean];
  input: [Event];
  blur: [Event];
  enter: [Event];
  focus: [Event];
}>();

const onBlur = (event: Event) => {
  emit("blur", event);
  validate();
};

const onEnter = (event: Event) => {
  emit("enter", event);
  validate();
};

const onFocus = (event: Event) => {
  emit("focus", event);
};

const onInput = (event: Event) => {
  emit("input", event);
};

const regionNames = new Intl.DisplayNames([locale.value], { type: "region" });
const countries: CountryCode[] = [
  "AR",
  "BO",
  "BR",
  "CL",
  "CO",
  "CR",
  "EC",
  "SV",
  "GT",
  "HN",
  "MX",
  "NI",
  "PA",
  "PY",
  "PE",
  "PR",
  "DO",
  "UY",
  "US",
  "VE",
];

const country = ref<CountryCode>(props.selectedCountry);
const updateSelectedCountry = (event: Event) => {
  const countryCode: CountryCode = (event.target as HTMLSelectElement).value as CountryCode;

  emit("update:selectedCountry", countryCode);
  country.value = countryCode;
};

watch([() => props.selectedCountry], () => {
  country.value = props.selectedCountry;
});

const validate = () => {
  const validity = isValidPhoneNumber(props.phone, country.value);
  emit("update:validity", validity);
};

const getFlag = (countryCode: CountryCode): string => {
  const codePoints = countryCode.split("").map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

const formattedPlaceholder = computed<string>(() => {
  if (props.format === "international") {
    return getExampleNumber(country.value, examples)?.formatInternational() ?? "";
  }
  return getExampleNumber(country.value, examples)?.formatNational() ?? "";
});

const updatePhone = (value: string) => {
  let formattedPhone = new AsYouType(country.value).input(value);

  emit("update:phone", formattedPhone);
};
</script>
