import app from "@/app";
import { EditView } from "@/core/magnet";
import { _, formatInteger } from "@/helpers/utils";
import CreditCardEditor from "@organisms/CreditCards/CreditCardEditor";
import CreditCardUpdatePanel from "@organisms/CreditCards/CreditCardUpdatePanel";
import BillingInfoPanel from "@organisms/BillingInfo/BillingInfoPanel.vue";
import ReceiptsList from "@organisms/Plan/ReceiptsList/ReceiptsList";
import PlanCancellationModal from "@organisms/Plan/PlanCancellationModal/PlanCancellationModal.vue";
import ScheduledPlanCancelPanel from "@organisms/Plan/ScheduledPlanCancelPanel/ScheduledPlanCancelPanel.vue";
import applyDiscountButton from "@organisms/Plan/ApplyPlanDiscountButton";
import PlanTNFactModal from "@organisms/Plan/PlanTNFactModal/PlanTNFactModal.vue";
import { mountComponent } from "@/helpers/vue";
import { useAlertStore } from "@store";

export default EditView.extend({
  module: "plan",
  templates: {
    initial: require("../templates/edit.hbs")
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "change #amount": "updateShownProducts",
      "blur #amount": "updateShownProducts",
      "focus #amount": "removeDotFromInput",
      "submit [data-form=validate-promocode]": "validatePromocode",
      "click [data-action=open-select-product-modal]": "openSelectProductModal",
      "click [data-action=select-product]": "selectProduct",
      "click [data-action=back-to-product-selection]": "backToProductSelection",
      "click [data-action=pay]": "pay",
      "click [data-action=delete-invoice]": "deleteInvoice",
      "click [data-action=open-pay-invoice-modal]": "openPayInvoiceModal",
      "click [data-action=open-settle-invoice-modal]": "openSettleInvoiceModal",
      "click [data-action=open-unpay-invoice-modal]": "openUnpayInvoiceModal",
      "click [data-action=show-promocode-input]": "showPromocodeInput",
      "click [data-action=open-cancel-subscription-modal]": "openCancelSubscriptionModal",
      "submit [data-form=cancel-subscription]": "cancelSubscription",
      "submit [data-form=update-billing]": "updateBilling",
      "click [data-action=open-custom-plan-chat]": "openCustomPlanChat",
      "click [data-action=open-help-chat]": "openHelpChat",
      "click [data-action=open-notify-payment-chat]": "notifyPaymentChat",
      "click [data-action=close-payment-modal]": "closePaymentModal",
      "click [data-action=show-transfer-message]": "showTransferMessage",
      "click [data-action=show-tokenized-cards]": "showTokenizedCards",
      "click [data-action=select-limit]": "selectLimit",
      "click [data-action=contact-to-pay]": "contactToPay",
      "click [data-action=execute]": "execute",
      "click [data-action=reset]": "reset",
      "click [data-action=extend]": "extend",
      "click [data-action=retry-usd]": "retryUSD",
      "click [data-action=remake-receipt]": "remakeReceipt",
      "click [data-action=process-odoo]": "processOdoo",
      "click [data-action=process-tn]": "processTN",
      "click [data-action=expire]": "expire",
      "click [data-action=open-modify-invoice-modal]": "openModifyInvoiceModal",
      "submit [data-form=modify]": "modify",
      "click [data-action=increase]": "increase",
      "click [data-action=open-increase-emails-modal]": "openIncreaseEmailsModal",
      "click [data-action=open-decrease-emails-modal]": "openDecreaseEmailsModal",
      "submit [data-form=increase-emails]": "increaseEmails",
      "submit [data-form=decrease-emails]": "decreaseEmails",
      "click [data-action=allow-unpaid-invoices]": "allowUnpaidInvoices",
      "click [data-action=disallow-unpaid-invoices]": "disallowUnpaidInvoices",
      // "click [data-action=open-apply-discount-modal]": "openApplyDiscountModal",
      // "submit [data-form=apply-discount]": "applyDiscount",
      "click [data-action=open-make-org-modal]": "openMakeOrgModal",
      "click [data-action=open-make-trial-modal]": "openMakeTrialModal",
      "submit [data-form=apply-trial]": "applyTrial",
      "change [name=cancelation-reason]": "onCancelationReasonChange",
      "keypress [name=cancelation-reason-other]": "onCancelationReasonChange",
      "click #button-talk-to-us": "talkToUs",
      "click #button-cancel-plan": "cancelPlan",
      "click [data-action=open-plan-admin-modal]": "openPlanAdminModal",
      "submit [data-form=save-plan-admin]": "savePlanAdmin",
      "change [name=subscription_discount]": "calcPlanAdminDiscount",
      "keyup [name=subscription_discount]": "calcPlanAdminDiscount",
      "change [name=subscription_regular_price]": "calcPlanAdminDiscount",
      "keyup [name=subscription_regular_price]": "calcPlanAdminDiscount",
      "click [data-action=open-balance-modal]": "openBalanceModal",
      "submit [data-form=balance]": "modifyBalance",
      "click [data-action=open-help-chat-mp]": "openHelpChatMPSubscription",
      "click [data-action=enable-tokenize]": "enableTokenize",
      "click [data-action=enable-tokenize-mp]": "enableTokenizeMP",
      "click [data-action=disable-tokenize-mp]": "disableTokenizeMP",
      "click [data-action=block-abuse]": "blockAbuse",
      "click [data-action=unblock-abuse]": "unblockAbuse",
      "click [data-action=make-free]": "makeFree"
    });
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      showChoosePlan: this.item.attributes.trial.inTrial || this.item.attributes.type === "FREE",
      data: this.item,
      lang: window.lang,
      config: window.config,
      session: app.session
    };
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;
    self.stripeFormSubmitted = false;

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    // Open congratulations modal after successful payment
    if (self.options.success !== undefined && self.options.success) {
      self.paymentSuccess(self.options.type);
    }

    // Open error modal after payment failure
    if (self.options.error !== undefined && self.options.error) {
      //alert('error')
      self.paymentError();
    } else {
      // Open purchase confirmation modal if needed
      self.checkQueryStringParameters();
    }

    // If there are unpaid invoices, do not allow subscription changes
    if (self.item.additionalsData.invoices !== undefined && $.isArray(self.item.additionalsData.invoices)) {
      $.each(self.item.additionalsData.invoices, function (index, value) {
        if (!value.paid) {
          self.item.set("canPurchase", false);
        }
      });
    }

    // // Load and configure Stripe
    // $.getScript("https://checkout.stripe.com/checkout.js", function () {
    //   self.handler = window.StripeCheckout.configure({
    //     key: window.config.stripeKey,
    //     locale: "auto",
    //     token: function (token) {
    //       self.stripeFormSubmitted = true;
    //       self.stripeCallback(token);
    //     },
    //   });
    // });

    $('[data-toggle="tooltip"]').tooltip({
      container: "body",
      placement: "right"
    });

    const creditCardMethod = self.item.additionalsData.paymethods.creditcard?.code;
    if (creditCardMethod && (creditCardMethod.includes("TOKEN") || creditCardMethod.includes("PAGOUNO"))) {
      if (creditCardMethod === "MPTOKEN_AR") {
        self.tokenizeGateway = "mp";
      } else if (creditCardMethod.includes("PAGOUNO_AR")) {
        self.tokenizeGateway = "tnpagouno";
      } else if (creditCardMethod.startsWith("STRIPETOKENUS")) {
        self.tokenizeGateway = "stripeus";
      } else if (creditCardMethod.startsWith("STRIPETOKENBR")) {
        self.tokenizeGateway = "stripebr";
      } else if (creditCardMethod.startsWith("STRIPETOKENMX")) {
        self.tokenizeGateway = "stripemx";
      }

      if (this.item.attributes.type === "MONTHLY" && !app.session.get("isMasterUser")) {
        self.disallowDeleteCard = true;
      }

      mountComponent(
        ScheduledPlanCancelPanel,
        {
          onCancelationScheduled: () => {
            this.cancelationScheduled = true;
            document.querySelector("#next-billing-info").innerText = "";
            document.querySelector("[data-action='open-select-product-modal']").remove();
          },
          onAbortCancelation: () => {
            this.reload();
          }
        },
        "#vue-scheduled-plan-cancel-panel"
      );

      mountComponent(
        CreditCardUpdatePanel,
        {
          gateway: self.tokenizeGateway,
          paymethod: creditCardMethod,
          disallowDelete: self.disallowDeleteCard || false
        },
        "#vue-update-credit-card"
      );

      mountComponent(BillingInfoPanel, undefined, "#vue-billing-info-panel");
    }

    mountComponent(
      applyDiscountButton,
      {
        defaultPromoCode: self.item.get("promocode"),
        onAppliedDiscount: () => self.reload()
      },
      "#vue-apply-discount-modal-button"
    );

    mountComponent(
      ReceiptsList,
      {
        onReceiptsLoaded: (receiptsQty) => {
          if (receiptsQty > 0) {
            $("#receipts-tabs").removeClass("hidden");
          }
        }
      },
      "#vue-receipts-list"
    );
  },

  // Checks and stores the query string parameters
  checkQueryStringParameters: function () {
    var self = this;
    if (self.options.type !== undefined && self.options.type) {
      self.preselected = {};
      if (self.options.contacts !== undefined) {
        self.preselected.limit = self.options.contacts;
        self.preselected.limitBy = "contacts";
      } else if (self.options.emails !== undefined) {
        self.preselected.limit = self.options.emails;
        self.preselected.limitBy = "emails";
      }
      self.preselected.productType = self.options.type;
      if (self.options.promocode) {
        $.ajax({
          url: "pricing/promocode/" + self.options.promocode,
          success: function (response) {
            if (response.data.valid) {
              self.item.attributes.promocode = self.options.promocode;
              self.preselected.promocode = self.options.promocode;
              self.openSelectProductModal();
            } else {
              self.displayMessage(window.lang.plans.invalidCoupon);
            }
          }
        });
      } else {
        self.openSelectProductModal();
      }
    }
  },

  // Opens a modal to select a product
  openSelectProductModal: function (e) {
    var self = this,
      button,
      promocode = false,
      currentType = self.item.get("type"),
      renderData = self.renderData(),
      productType,
      limit;

    // Gather parameters
    if (e !== undefined) {
      button = $(e.currentTarget);
      productType = button.data("product-type");
      limit = button.data("limit");
    } else {
      productType = self.preselected.productType;
    }

    // Current plan already has a promocode, use that one if changing to other monthly plan
    if (self.item.get("promocode") && self.item.get("type") === "MONTHLY") {
      promocode = self.item.get("promocode");
    }

    // Fill preselected promocode
    if (self.preselected !== undefined) {
      promocode = self.preselected.promocode !== undefined ? self.preselected.promocode : false;
    }

    // Set limit if preselected data is present
    if (self.preselected !== undefined && self.preselected.limitBy) {
      limit = self.preselected.limitBy;
    }

    // Show limit type selector
    if ((currentType === "FREE" || currentType === "EMAILS") && productType === "monthly" && !limit) {
      renderData.canChooseContactsPlan = !app.session.get("details").account.container;
      return self.openModal(require("../templates/modals/selectLimit.hbs"), renderData);
    }

    // Guess limit for monthly plans
    if (currentType === "MONTHLY" && !limit) {
      limit = self.item.get("contacts").limit === 0 ? "emails" : "contacts";
    }

    // Set the current plan usage
    if (productType === "monthly") {
      self.currentPlanActiveContacts = renderData.data.attributes.contacts.active;
      self.currentPlanValue = renderData.data.attributes.contacts.limit || renderData.data.attributes.emails.limit;
    } else {
      self.currentPlanValue = renderData.data.attributes.emails.limit;
    }

    // Add data to render data
    renderData.productType = productType;
    renderData.limit = limit;

    renderData.cancelationScheduled = this.cancelationScheduled;

    // Render and open the modal
    self.openModal(
      require("../templates/modals/selection.hbs"),
      renderData,
      function (modal) {
        // Update slider
        $("#amount").val(self.currentPlanActiveContacts || self.currentPlanValue);

        // Show products
        self.renderProducts(promocode, productType, limit);

        // Restart preselected options when modal is closed
        modal.on("hidden.bs.modal", function () {
          delete self.preselected;
          modal.off("hidden.bs.modal");
        });
      },
      false
    );
  },

  // Show the promocode input
  showPromocodeInput: function (e) {
    var self = this,
      formGroup = $(self.modal).find(".validate-promocode .form-group");
    e.preventDefault();
    formGroup.removeClass("hidden").find("input:visible").first().focus();
  },

  // Remove the dots from the limit input
  removeDotFromInput: function () {
    var self = this,
      input = self.$("#amount"),
      value = input.val().split(".").join("");
    input.val(value);
  },

  // Render the products list partial
  renderProducts: function (promocode, productType, limit) {
    var self = this,
      template = require("../templates/modals/products.hbs"),
      requestData = {},
      renderData,
      modal = $(self.modal),
      products = [];

    // Set product key
    if (productType === "pack") {
      limit = "emails";
    }

    // Add promocode to request parameters
    if (promocode) {
      requestData.filter = { promocode: promocode };
    }

    // Add limit and products for rendering
    self.product = {
      productType: productType,
      limit: limit,
      productLimitKey: limit === "contacts" ? "contactsTo" : "emails"
    };
    renderData = $.extend({}, self.renderData(), self.product);

    // Show loader and hide products list
    modal.find(".loading-box").show();
    modal.find(".product-selection").hide();

    let filter = "";
    if (!app.session.get("isMasterUser")) {
      if (limit === "contacts" && self.currentPlanActiveContacts < 90000) {
        filter = "?filter[max]=100000";
      } else {
        filter = "?filter[max]=5000000";
      }

      if (productType === "pack") {
        filter += "&filter[min]=50000";
      }
      if (productType === "monthly" && limit === "emails") {
        filter += "&filter[min]=50000";
      }
    }

    // Request products
    $.ajax({
      url: "pricing/" + limit + filter,
      data: requestData,
      success: function (response) {
        var currentType = self.item.get("type");
        // Iterate the plans to flag the current monthly plan
        if (productType === "monthly") {
          $.each(response.data, function (index, plan) {
            // Flag the current plan
            if (
              currentType === "MONTHLY" &&
              self.currentPlanValue > 0 &&
              (plan.contactsTo === self.currentPlanValue || plan.emails === self.currentPlanValue)
            ) {
              plan.current = true;
            }

            // Disable the plans with less contacts than the current plan
            if (plan.current /*|| plan.contactsTo < self.currentPlanActiveContacts*/) {
              plan.disabled = true;
            }

            // Show the monthly price for emails
            if (limit === "emails") {
              plan.price = plan.monthlyPrice;
              plan.mailPrice = plan.monthlyEmailPrice;
              plan.promoPrice = plan.monthlyPromoPrice;
            }
          });
        }

        // Save products in additional data
        products = [];
        $.each(response.data, function (index, value) {
          // only if its price is not 0
          if (
            ((productType == "pack" || value.contactsTo) && value.price) ||
            (productType == "monthly" && value.monthlyPrice)
          ) {
            products.push(value);
          }
        });

        self.item.additionalsData.products = products;

        // Save currency in additional data
        self.item.additionalsData.currency = response.filters.currency;
        renderData.currency = self.item.additionalsData.currency;

        // Render template
        renderData.products = products;
        $("#product-list").html(template(renderData));

        // Activate slider
        if ($("#slider").length) {
          self.slider = $("#slider").slider({
            value: parseInt($("#amount").val()),
            step: 1,
            min: 0,
            max: products.length - 1,
            tooltip: "hide"
          });
          self.slider.on("change", function (e) {
            var index = e.value.newValue,
              limit = products[index][self.product.productLimitKey];
            $("#amount").val(limit).trigger("change");
          });
          self.slider.on("slide", function (e) {
            var index = e.value,
              limit = products[index][self.product.productLimitKey];
            $("#amount").val(limit).trigger("change");
          });
        }

        // Trigger slider input
        $("#amount").trigger("change");

        // Hide loader and show products list
        modal.find(".loading-box").hide();
        modal.find(".product-selection").show();

        // If a plan is preselected, show the confirmation page
        if (self.preselected !== undefined && self.preselected) {
          return self.focusPurchaseConfirmation();
        }

        // Blur to format the integer
        setTimeout(function () {
          $("#amount").blur();
        }, 1);
      }
    });
  },

  // Validates the promocode
  validatePromocode: function (e) {
    var self = this,
      form = $(e.currentTarget),
      promocode = form.find("[name=promocode]").val(),
      limit = form.find("[name=limit]").val(),
      productType = form.find("[name=type]").val();
    e.preventDefault();

    // Remove previous errors
    self.cleanFormErrorMessages($(e.currentTarget));

    // Validate promocode via AJAX
    $.ajax({
      url: "pricing/promocode/" + promocode,
      success: function (response) {
        if (!response.success || !response.data.valid) {
          return self.showPromocodeError();
        }

        if (productType === "pack" && !app.session?.get("isMasterUser")) {
          return self.showPromocodeError(true);
        }

        // Process validated promocode
        self.applyPromocode(promocode, productType, limit);

        // Show success legend
        self.showPromocodeSuccess(response.data);
      },
      error: function () {
        self.showPromocodeError();
      }
    });
  },

  // Applies the promocode
  applyPromocode: function (promocode, productType, limit) {
    var self = this;

    // Save promocode
    self.item.attributes.promocode = promocode;

    // Render products with accepted promocode
    self.renderProducts(promocode, productType, limit);
  },

  // Shows the success message after a valid promocode is entered
  showPromocodeSuccess: function (coupon) {
    $(".validate-promocode").html("<p>" + window.lang.plans.validCoupon + coupon.discount + "%</p>");
  },

  // Shows the error message after an invalid promocode is entered
  showPromocodeError: function (monthlyOnly = false) {
    var self = this,
      form = $("[data-form=validate-promocode]");
    self.showValidationErrors(
      {
        promocode: monthlyOnly ? window.lang.plans.invalidCouponMonthlyOnly : window.lang.plans.invalidCoupon
      },
      form
    );
  },

  // Updates the shown products
  updateShownProducts: function () {
    var self = this,
      amountInput = self.$("#amount"),
      productIndex = 0,
      productLimitKey = self.product.productLimitKey,
      products = self.item.additionalsData.products,
      currentValue = parseInt(amountInput.val().replace(/\./g, ""), 10),
      maxReached = false,
      customProduct = self.$(".custom-product");

    // Format input
    amountInput.val(formatInteger(amountInput.val().replace(/\./g, "")));

    // Hide all shown products
    $("[data-product]").removeClass("selected").addClass("hidden");

    // Select a product based on the current limit
    while (productIndex < products.length && currentValue > products[productIndex][productLimitKey]) {
      ++productIndex;
    }

    // Highlight the middle product
    $("[data-product=" + productIndex + "]").addClass("selected");

    // If product is not the first one, show from the previous one
    if (productIndex > 0) {
      --productIndex;
    }

    // If maximum reached, step back a little
    if (productIndex >= products.length - 2) {
      productIndex - +(products.length - productIndex);
      maxReached = true;
    }

    // Update slider
    if (self.slider !== undefined && self.slider.slider) {
      self.slider.slider("setValue", productIndex);
    }

    // Show selected products
    $("[data-product]")
      .slice(productIndex, productIndex + 3)
      .removeClass("hidden");

    // Show custom product
    if (maxReached) {
      customProduct.removeClass("hidden");
    } else {
      customProduct.addClass("hidden");
    }
  },

  selectProduct: function (e) {
    var self = this,
      modal = $(self.modal),
      selected = $(e.currentTarget).closest("[data-product]"),
      productType = selected.data("product-type"),
      limit = selected.data("product-limit"),
      template = require("../templates/modals/confirmation.hbs"),
      renderData = self.renderData(),
      shouldSelectPayment = true,
      html;

    let product = selected.data("product");
    product = self.item.additionalsData.products[product];

    e.preventDefault();

    // Check if the payment method should be selected
    if (self.item.get("type") === "MONTHLY" && productType === "monthly") {
      shouldSelectPayment = false;
    }

    const availablePayMethods = { ...self.item.additionalsData.paymethods };
    if (
      !app.session.get("isMasterUser") &&
      availablePayMethods["transfer"] &&
      ((product.contactsTo && product.contactsTo < 5000) || (!product.contactsTo && product.emails < 10000))
    ) {
      delete availablePayMethods["transfer"];
    }

    // Add product and product type to render data
    renderData = $.extend(renderData, {
      productType: productType,
      limit: limit,
      product: product,
      shouldSelectPayment: shouldSelectPayment,
      currency: self.item.additionalsData.currency,
      columnOffset: _.size(availablePayMethods) === 1 ? 4 : 2,
      hasPaymentMethods: _.size(availablePayMethods),
      availablePayMethods,
      isUpgrade: product.contactsTo > self.currentPlanActiveContacts,
      showIOFDisclaimer: false //availablePayMethods["creditcard"]?.code?.includes("STRIPETOKENUS") || false
    });

    // Render confirmation page inside the opened modal
    html = template(renderData);
    modal.find(".selection").hide();
    modal.find(".confirmation").html(html).show();
  },

  // Renders the purchase confirmation modal
  focusPurchaseConfirmation: function () {
    var self = this,
      amountInput = self.$("#amount");
    amountInput.val(self.preselected.limit).trigger("change");
    self.$("[data-product].selected").find("button").click();
  },

  // Navigates back to product selection
  backToProductSelection: function (e) {
    var self = this,
      modal = $(self.modal);
    e.preventDefault();
    modal.find(".confirmation").html("").hide();
    modal.find(".selection").show();
  },

  // Opens a modal to pay an invoice
  openPayInvoiceModal: function (e) {
    var self = this,
      invoiceIndex = $(e.currentTarget).data("invoice-index"),
      renderData = self.renderData();

    // Get invoice data
    renderData.invoice = self.item.additionalsData.invoices[invoiceIndex];
    renderData.currency = renderData.invoice.currency;
    renderData.hasPaymentMethods = _.size(self.item.additionalsData.paymethods);

    // Set product type
    // renderData.productType = self.item.get("type") === "monthly" ? "contacts" : "emails";
    renderData.productType = "invoice";

    renderData.columnOffset = _.size(self.item.additionalsData.paymethods) === 1 ? 4 : 2;

    // Open invoice modal
    self.openModal(require("../templates/modals/invoice.hbs"), renderData);
  },

  // Opens a modal to change unpaid block options
  allowUnpaidInvoices: function () {
    var self = this;

    // Mark as paid via API
    $.ajax({
      url: "plan/allowunpaidinvoices",
      type: "POST",
      success: function () {
        self.displayMessage(window.lang.plans.blockSuccess, 3000, "success");
        self.reload();
      }
    });
  },

  // Opens a modal to change unpaid block options
  disallowUnpaidInvoices: function () {
    var self = this;

    // block account if has unpaid invoices
    $.ajax({
      url: "plan/disallowunpaidinvoices",
      type: "POST",
      success: function () {
        self.displayMessage(window.lang.plans.blockSuccess, 3000, "success");
        self.reload();
      }
    });
  },
  // Opens a modal to change unpaid block options
  // openApplyDiscountModal: function () {
  //   this.openModal(require("../templates/modals/applyDiscount.hbs"));
  // },

  // Opens a modal to change unpaid block options
  openPlanAdminModal: function () {
    const openModal = (planData) => {
      this.openModal(require("../templates/modals/planAdmin.hbs"), {
        ...this.item.additionalsData.planAdmin,
        ...planData
      });
    };

    if (this.item.attributes.contacts.limit) {
      const contacts = this.item.attributes.contacts.limit;
      $.get(
        "pricing/contacts",
        { "filter[currency]": "USD", "filter[max]": contacts + 1, "filter[min]": contacts },
        (res) => {
          const planData = res.data[0];
          const priceUSD = planData ? planData.price : "?";
          const exchRate = planData ? this.item.additionalsData.planAdmin.subscription_regular_price / priceUSD : "?";
          openModal({
            priceUSD,
            exchRate,
            contacts
          });
        }
      );
    } else if (this.item.attributes.emails.limit) {
      const emails = this.item.attributes.emails.limit;
      $.get(
        "pricing/emails",
        { "filter[currency]": "USD", "filter[max]": emails + 1, "filter[min]": emails },
        (res) => {
          const planData = res.data[0];
          const priceUSD = planData ? planData.monthlyPrice : "?";
          const exchRate = planData ? this.item.additionalsData.planAdmin.subscription_regular_price / priceUSD : "?";
          openModal({
            priceUSD,
            exchRate,
            emails
          });
        }
      );
    } else {
      openModal();
    }
  },

  openBalanceModal: function () {
    this.openModal(require("../templates/modals/balance.hbs"), this.item.additionalsData.accountBalance);
  },

  modifyBalance: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();

    $.ajax({
      url: "invoicepayments/balance",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
      success: function () {
        this.reload();
        this.displayMessage("Saldo modificado");
      }.bind(this)
    });
  },

  calcPlanAdminDiscount: function () {
    var discount = $("[name=subscription_discount]").val();
    $("[name=price]").val(($("[name=subscription_regular_price]").val() * (100 - discount)) / 100);
  },

  // Opens a modal to change unpaid block options
  savePlanAdmin: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();

    $.ajax({
      url: "plan/admin",
      type: "PUT",
      data: JSON.stringify(data),
      contentType: "application/json",
      success: function () {
        this.reload();
        this.displayMessage("Plan modificado");
      }.bind(this)
    });
  },

  // Opens a modal to pay an invoice
  openSettleInvoiceModal: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-settle")) {
      return self.confirmModal(
        window.lang.plans.confirmSettle,
        button,
        window.lang.plans.confirmSettleTitle,
        "info",
        "data-confirm-settle"
      );
    }

    // Mark as paid via API
    $.ajax({
      url: "invoices/" + button.data("invoiceId") + "/payments",
      type: "POST",
      success: function () {
        self.displayMessage(window.lang.plans.settleSuccess, 3000, "success");
        self.reload();
      }
    });
  },

  // Opens a modal to pay an invoice
  openUnpayInvoiceModal: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-unpay")) {
      return self.confirmModal(
        window.lang.plans.confirmUnpay,
        button,
        window.lang.plans.confirmUnpayTitle,
        "info",
        "data-confirm-unpay"
      );
    }

    // Mark as paid via API
    $.ajax({
      url: "invoices/" + button.data("invoiceId") + "/unpay",
      type: "POST",
      success: function () {
        self.displayMessage(window.lang.plans.unpaySuccess, 3000, "success");
        self.reload();
      }
    });
  },

  // Makes a payment using Stripe Checkout
  payWithStripe: function (e) {
    var self = this,
      button = $(e.currentTarget),
      data = button.data(),
      email = app.session.attributes.details.user.email;

    this.stripeProcess = data;

    this.handler.open({
      name: window.lang.plans.stripeModalTitle,
      description: window.lang.plans.stripeModalSubtitle,
      email: email,
      zipCode: false,
      currency: self.item.additionalsData.currency,
      amount: data.charge * (["CLP", "PYG"].includes(self.item.additionalsData.currency) ? 1 : 100),
      allowRememberMe: false,
      billingAddress: true,
      closed: function () {
        if (!self.stripeFormSubmitted) {
          self.getModal().find(".payment-options").removeClass("hide");
          self.getModal().find(".payment-loading").addClass("hide");
        }
      }
    });
  },

  // // Stripe callback
  // stripeCallback: function (token) {
  //   const payload = $.extend(true, {}, this.stripeProcess.params, {
  //     data: {
  //       token: token.id,
  //       email: token.email,
  //     },
  //   });
  //   let url, method;

  //   if (this.stripeProcess.invoiceId) {
  //     url = "invoices/" + this.stripeProcess.invoiceId + "/pay";
  //     method = "POST";
  //   } else if (this.stripeProcess.productType === "monthly") {
  //     url = "plan/subscription";
  //     method = "PUT";
  //   } else {
  //     url = "plan/emails/purchase";
  //     method = "POST";
  //   }

  //   // Add promo code
  //   if (this.item.get("promocode") && !this.stripeProcess.invoiceId) {
  //     payload.promoCode = this.item.get("promocode");
  //   }

  //   // Make API call
  //   $.ajax({
  //     url: url,
  //     type: method,
  //     dataType: "json",
  //     contentType: "application/json",
  //     data: JSON.stringify(payload),
  //     success: function (response) {
  //       this.paymentSuccess(
  //         this.stripeProcess.invoiceId ? "invoice" : this.stripeProcess.productType,
  //         this.stripeProcess.paymentType,
  //         response.data,
  //       );
  //     }.bind(this),
  //   });
  // },

  pay: function (e) {
    var self = this,
      button = $(e.currentTarget),
      data = button.data(),
      params = data.params;
    // productType = data.productType,
    // paymentType = data.paymentType,
    // invoiceId = data.invoiceId,
    // url,
    // method,
    // baseUrl;

    // Disable button
    self.disableButton(button);

    // If the pay method is Stripe
    if (params.payMethod.startsWith("STRIPE") && !params.isUpdate) {
      self.getModal().find(".payment-options").addClass("hide");
      self.getModal().find(".payment-loading").removeClass("hide");
      return self.payWithStripe(e);
    }

    self.doPay(data);
  },

  // Starts a payment transaction
  doPay: function (data) {
    const alertStore = useAlertStore();

    var self = this,
      //button = $(e.currentTarget),
      //data = button.data(),
      params = data.params,
      productType = data.productType,
      paymentType = data.paymentType,
      invoiceId = data.invoiceId,
      url,
      method,
      baseUrl;

    // // If the pay method is Stripe
    // if (params.payMethod.startsWith("STRIPE") && !params.isUpdate) {
    //   self.getModal().find(".payment-options").addClass("hide");
    //   self.getModal().find(".payment-loading").removeClass("hide");
    //   return self.payWithStripe(e);
    // }

    // // Disable button
    // self.disableButton(button);

    // Add return URL
    if (params.payMethod === "MP_AR") {
      baseUrl = window.location.protocol + "//" + window.location.host + "/plan";
      params.successUrl = baseUrl + "-payment-success-" + productType;
      params.errorUrl = baseUrl + "-payment-error-" + productType;
    }

    // Add promo code
    if (self.item.get("promocode")) {
      params.promocode = self.item.get("promocode");
    }

    const payCallback = (additionalParams = {}) => {
      // Make API call
      const res = $.ajax({
        url: url,
        type: method,
        data: { ...params, ...additionalParams },
        success: function (response) {
          if (response.data !== undefined && response.data.initPoint !== undefined) {
            window.location.href = response.data.initPoint;
          } else {
            try {
              setTimeout(() => {
                alertStore.update();
              }, 120000);
            } catch {
              //No Handler
            }
            self.paymentSuccess(productType, paymentType, response.data);
          }
        }
      });

      app.integrations.amplitude.event("APP_PLAN_PURCHASED", {
        payMethod: params.payMethod,
        productType: productType,
        paymentType: paymentType,
        currency: self.item.additionalsData.currency,
        amount: params.amount
      });

      return res;
    };

    // Set options to pay an invoice, change subscription or purchase an emails pack
    if (invoiceId) {
      url = "invoices/" + invoiceId + "/pay";
      method = "POST";
    } else {
      if (productType === "monthly") {
        url = "plan/subscription";
        method = "PUT";

        if (params.payMethod === "MP_AR" && !params.isUpdate) {
          this.showPayerEmailPrompt(payCallback);
          return;
        }
      } else {
        url = "plan/emails/purchase";
        method = "POST";
      }
    }

    payCallback();
  },

  showPayerEmailPrompt: function (payCallback) {
    const form = $("[data-role=prompt]");

    form.removeClass("hidden").find("input").val(app.session.attributes.details.user.email).first().focus();

    form.on(
      "submit",
      function (e) {
        e.preventDefault();

        if (!this.validateFormFields(form)) {
          return false;
        }

        const email = form.find("[name=email]").val();

        payCallback({ email });
      }.bind(this)
    );
  },

  // Show the payment success modal
  paymentSuccess: function (productType, paymentType, paymentInfo) {
    var self = this,
      renderData = self.renderData();

    paymentType = paymentType || "creditcard";
    productType = productType || "monthly";

    // Add payment data to render data
    renderData.paymentType = paymentType;
    renderData.paymentInfo = paymentInfo;
    renderData.productType = productType;

    // Request billing data
    $.ajax({
      url: "plan/billing",
      success: function (response) {
        if (response.data) {
          renderData.billing = response.data;
          // renderData.hasBilling = _.size(response.data);
        }

        // Render and open the modal
        self.openModal(require("../templates/modals/success.hbs"), renderData);

        // Reload session details
        if (!app.session.get("isMasterUser")) {
          app.session.reload(function () {
            var companyDetails = app.integrations.getCompanyDetails();
            app.integrations.intercom.update({
              user_id: app.session.get("user") + "@" + app.session.get("mainAccount"),
              company: companyDetails
            });
          });
        }
      }
    });
  },

  // Show the payment error modal
  paymentError: function (paymentType, paymentInfo) {
    var self = this,
      renderData = self.renderData();

    paymentType = paymentType || "creditcard";

    // Add payment data to render data
    renderData.paymentType = paymentType;
    renderData.paymentInfo = paymentInfo;

    // Render and open the modal
    self.openModal(require("../templates/modals/error.hbs"), renderData);
  },

  // Opens a modal to confirm the plan cancelation
  openCancelSubscriptionModal: function () {
    this.mountedPlanCancellationModal = mountComponent(
      PlanCancellationModal,
      {
        plan: { ...this.item.attributes.subscription, type: this.item.attributes.type },
        "onUpdate:open": () => {
          this.mountedPlanCancellationModal.closeModal();
        },
        onPlanCanceled: () => {
          this.reload();
        }
      },
      "#vue-schedule-cancel-plan-modal"
    );
    this.mountedPlanCancellationModal.openModal();
  },

  talkToUs: function (e) {
    var reason = $("#cancelation-reason").val(),
      comments = $("#cancelation-reason-other").val();
    e.preventDefault();

    var reasons = window.lang.plans.cancelSubscriptionReasons;
    var reasonDetails =
      reasons.find(function (r) {
        return r.key === reason;
      }) || {};

    if (reasonDetails.talkToUs) {
      var text = window.lang.plans.cancelIntercomMessage + "\n" + reasonDetails.text + "\n" + comments;
      text += "\n" + window.lang.plans.cancelIntercomMessageClosing;
      app.integrations.intercom.message(text);
    }
  },

  // Cancels a monthly plan subscription
  cancelSubscription: function (e) {
    var reason = $("#cancelation-reason").val(),
      comments = $("#cancelation-reason-other").val();
    e.preventDefault();

    var reasons = window.lang.plans.cancelSubscriptionReasons;
    var reasonDetails =
      reasons.find(function (r) {
        return r.key === reason;
      }) || {};

    if (reasonDetails.talkToUs) {
      $("#talk-to-us .question").text(reasonDetails.talkToUs);
      $("#talk-to-us").removeClass("hide");
    } else {
      this.confirmCancelSubscription(reason, comments);
    }
  },

  cancelPlan: function (e) {
    var reason = $("#cancelation-reason").val(),
      comments = $("#cancelation-reason-other").val();
    e.preventDefault();

    this.confirmCancelSubscription(reason, comments);
  },

  confirmCancelSubscription: function (reason, comments) {
    var self = this;

    // Make API call
    $.ajax({
      url: "plan/subscription/cancel",
      type: "POST",
      data: JSON.stringify({ reason: reason, comments: comments }),
      contentType: "application/json",
      success: function () {
        self.displayMessage(window.lang.plans.subscriptionCanceled);
        self.reload();
      }
    });
  },

  // Updates the billing info
  updateBilling: function (e) {
    var self = this,
      form = e.currentTarget,
      data = self.getFormData(form);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Submit only if there are inputs
    if (_.size(data)) {
      // Update billing data via API
      $.ajax({
        url: "plan/billing",
        type: "PUT",
        data: data,
        success: function (response) {
          // Send data to Intercom
          if (!app.session.get("isMasterUser")) {
            var intercomData = { company: response.data };
            intercomData.company.company_id = app.session.get("account");
            app.integrations.intercom.update(intercomData);
          }

          // Remove previos options
          self.options.success = false;
          self.options.error = false;

          // Show message
          self.displayMessage(window.lang.plans.billingUpdated);

          // Close modal
          self.closePaymentModal();
        }
      });
    } else {
      // Close modal
      self.closePaymentModal();
    }
  },

  // Dismiss a payment error modal
  closePaymentModal: function (e) {
    var self = this;
    if (e) {
      e.preventDefault();
    }

    // Close modal
    $(self.modal).modal("hide");

    // Reload
    if (!app.router.isCurrentRoute("plan")) {
      app.router.navigateTo("plan");
    } else {
      self.reload();
    }
  },

  // Opens a chat conversation to request a custom chat
  openCustomPlanChat: function (e) {
    var button = $(e.currentTarget),
      message = button.data("type") === "emails" ? "customPackRequest" : "customPlanRequest";
    e.preventDefault();
    app.integrations.intercom.message(window.lang.plans[message]);
  },

  // Opens a chat conversation to request help
  openHelpChat: function (e) {
    e.preventDefault();
    app.integrations.intercom.message(window.lang.plans.requestHelp);
  },

  // Opens a chat conversation to request help
  openHelpChatMPSubscription: function (e) {
    e.preventDefault();
    app.integrations.intercom.message("Hola! Tengos dudas sobre qué email debo usar para suscribirme por MercadoPago.");
  },

  // Opens a chat conversation to notify a payment
  notifyPaymentChat: function (e) {
    var button = $(e.currentTarget),
      message = window.lang.plans.notifyPaymentChat.replace("###", button.data("invoice-id"));
    e.preventDefault();
    app.integrations.intercom.message(message);
  },

  // Opens a chat to agree a payment method
  contactToPay: function (e) {
    var message = window.lang.plans.requirePaymentMethod;
    e.preventDefault();
    app.integrations.intercom.message(message);
  },

  // Reset plan
  reset: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-reset")) {
      return self.confirmModal(
        window.lang.plans.confirmReset,
        button,
        window.lang.plans.reset,
        "info",
        "data-confirm-reset"
      );
    }

    // Send request
    $.ajax({
      url: "plan/subscription/contacts/reset",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Execute plan
  execute: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-execute")) {
      return self.confirmModal(
        window.lang.plans.confirmExecute,
        button,
        window.lang.plans.execute,
        "info",
        "data-confirm-execute"
      );
    }

    // Send request
    $.ajax({
      url: "plan/subscription/execute",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Make ORG
  openMakeOrgModal: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-org")) {
      return self.confirmModal(
        window.lang.plans.makeOrgLegend,
        button,
        window.lang.plans.makeOrg,
        "info",
        "data-confirm-org"
      );
    }

    // Send request
    $.ajax({
      url: "plan/subscription/makeorg",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Make Trial
  applyTrial: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();
    //data.days = 15;

    // Send request
    $.ajax({
      url: "plan/subscription/maketrial",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
      success: function () {
        this.reload();
        this.displayMessage(window.lang.plans.feedback.appliedTrial);
      }.bind(this)
    });
  },

  openMakeTrialModal: function () {
    this.openModal(require("../templates/modals/applyTrial.hbs"));
  },

  // Execute plan
  increase: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-increase")) {
      return self.confirmModal(
        window.lang.plans.confirmIncreaseExcessLimit,
        button,
        window.lang.plans.increaseExcessLimit,
        "info",
        "data-confirm-increase"
      );
    }

    // Send request
    $.ajax({
      url: "plan/emails/excesslimit/increase",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Opens the modal
  openIncreaseEmailsModal: function () {
    this.openModal(require("../templates/modals/increaseEmails.hbs"));
  },

  // Opens the modal
  openDecreaseEmailsModal: function () {
    this.openModal(require("../templates/modals/decreaseEmails.hbs"));
  },

  // Increase emails
  increaseEmails: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();

    // Send request
    $.ajax({
      url: "plan/emails/available/add",
      type: "POST",
      data: data,
      success: function () {
        this.reload();
        this.displayMessage(window.lang.plans.feedback.increasedEmails);
      }.bind(this)
    });
  },

  // Apply discount
  // applyDiscount: function (e) {
  //   var data = $(e.currentTarget).serializeObject();
  //   e.preventDefault();

  //   // Send request
  //   $.ajax({
  //     url: "plan/subscription/discount",
  //     type: "POST",
  //     data: JSON.stringify(data),
  //     contentType: "application/json",
  //     success: function () {
  //       this.reload();
  //       this.displayMessage(window.lang.plans.feedback.discountApplied);
  //     }.bind(this),
  //   });
  // },

  // Increase emails
  decreaseEmails: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();

    // Send request
    $.ajax({
      url: "plan/emails/available/remove",
      type: "POST",
      data: data,
      success: function () {
        this.reload();
        this.displayMessage(window.lang.plans.feedback.decreasedEmails);
      }.bind(this)
    });
  },

  // Extend invoice
  extend: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-extend")) {
      return self.confirmModal(
        window.lang.plans.confirmExtend,
        button,
        window.lang.plans.extend,
        "info",
        "data-confirm-extend"
      );
    }

    // Send request
    $.ajax({
      url: "invoices/" + button.data("invoiceId") + "/extend",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Extend invoice
  retryUSD: function (e) {
    var self = this,
      button = $(e.currentTarget),
      invoiceId = button.data("invoiceId");

    // Show confirm modal
    if (button.attr("data-confirm-retry-usd")) {
      return self.confirmModal(
        "Se reintentará el pago de esta factura en USD. Continuar?",
        button,
        "Reintentar en USD",
        "info",
        "data-confirm-retry-usd"
      );
    }

    $.ajax({
      url: `invoicepayments/invoices/${invoiceId}/chargeusd`,
      type: "POST",
      contentType: "application/json",
      success: function () {
        this.reload();
        this.displayMessage("Procesando reintento en USD");
      }.bind(this),
      timeout: 60000
    });
  },

  remakeReceipt: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-remake")) {
      return self.confirmModal(
        "¿Volver a generar el recibo " + button.data("invoiceId") + "?",
        button,
        "Regenerar recibo",
        "info",
        "data-confirm-remake"
      );
    }

    // Send request
    $.ajax({
      url: "apiv3://receipts/" + button.data("invoiceId") + "/remake",
      type: "POST",
      success: function () {
        self.reload();
        self.displayMessage("Pedido enviado");
      },
      timeout: 60000
    });
  },

  processOdoo: function (e) {
    var self = this,
      button = $(e.currentTarget),
      invoiceId = button.data("invoiceId");

    // Show confirm modal
    if (button.attr("data-confirm-odoo")) {
      return self.confirmModal(
        `Facturar en odoo el invoice ${invoiceId}?`,
        button,
        "Facturar Odoo",
        "info",
        "data-confirm-odoo"
      );
    }

    $.ajax({
      url: `invoicepayments/invoices/${invoiceId}/remake`,
      type: "POST",
      contentType: "application/json",
      success: function () {
        this.reload();
        this.displayMessage("Pedido enviado");
      }.bind(this),
      timeout: 60000
    });
  },
  processTN: function (e) {
    var button = $(e.currentTarget);
    var invoiceId = button.data("invoiceId");
    var amount = button.data("amount");
    var currency = button.data("currency");

    let modalRef = undefined;

    modalRef = mountComponent(
      PlanTNFactModal,
      {
        invoiceId: typeof invoiceId === "number" ? invoiceId.toString() : invoiceId,
        amount: typeof amount === "number" ? amount.toString() : amount,
        currency: currency,
        close: () => {
          if (!modalRef) return;
          modalRef.unmount();
        }
      },
      "#vue-fact-tn-modal"
    );

    if (modalRef) {
      modalRef.openModal();
    }
  },

  // Expire invoice
  expire: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-expire")) {
      return self.confirmModal(
        window.lang.plans.confirmExpire,
        button,
        window.lang.plans.expire,
        "info",
        "data-confirm-expire"
      );
    }

    // Send request
    $.ajax({
      url: "invoices/" + button.data("invoiceId") + "/expire",
      type: "POST",
      success: function () {
        self.reload();
      }
    });
  },

  // Show transfer message before executing
  showTransferMessage: function (e) {
    var self = this,
      renderData = self.renderData(),
      template = require("../templates/modals/transferMessage.hbs");
    renderData.params = JSON.stringify($(e.currentTarget).data("params"));
    renderData.productType = $(e.currentTarget).data("productType");

    // Render message
    $(self.modal).find(".payment-disclaimer").remove();
    $(self.modal).find(".payment-options").html(template(renderData));
  },

  // Show transfer message before executing
  showTokenizedCards: function (e) {
    var self = this,
      renderData = self.renderData(),
      template = require("../templates/modals/tokenizedCards.hbs");
    renderData.params = $(e.currentTarget).data("params");
    renderData.productType = $(e.currentTarget).data("productType");
    renderData.invoiceId = $(e.currentTarget).data("invoiceId");

    // Render message
    $(self.modal).find(".payment-disclaimer").remove();
    $(self.modal).find(".payment-options").html(template(renderData));

    const data = {
      params: renderData.params,
      productType: renderData.productType,
      paymentType: "creditcard",
      invoiceId: renderData.invoiceId
    };

    mountComponent(
      CreditCardEditor,
      {
        gateway: self.tokenizeGateway,
        paymethod: self.item.additionalsData.paymethods.creditcard?.code,
        onConfirm() {
          self.doPay(data);
        }
      },
      "#vue-credit-card-confirmation"
    );
  },

  // Deletes an invoice
  deleteInvoice: function (e) {
    var button = $(e.currentTarget);

    // Show confirm modal
    if (button.attr("data-confirm-delete-invoice")) {
      return this.confirmModal(
        window.lang.plans.deleteInvoiceLegend,
        button,
        window.lang.plans.deleteInvoice,
        "warning",
        "data-confirm-delete-invoice"
      );
    }

    // Send request
    $.ajax({
      url: "invoices/" + button.data("invoiceId"),
      type: "DELETE",
      success: function () {
        this.reload();
      }.bind(this)
    });
  },

  // Opens the modal
  openModifyInvoiceModal: function (e) {
    var data = $(e.currentTarget).data();
    this.openModal(require("../templates/modals/modifyInvoice.hbs"), {
      invoice: {
        value: data.value,
        id: data.invoiceId,
        description: data.description,
        payMethod: data.payMethod,
        currency: data.currency
      }
    });
  },

  modify: function (e) {
    var form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();
    $.ajax({
      type: "PUT",
      url: "invoices/" + data.id,
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        description: data.description,
        total: data.total,
        payMethod: data.payMethod.trim(),
        currency: data.currency
      }),
      success: function () {
        this.reload();
      }.bind(this)
    });
  },

  onCancelationReasonChange: function () {
    var selection = $("#cancelation-reason").val(),
      comments = $("#cancelation-reason-other").val(),
      reasons = window.lang.plans.cancelSubscriptionReasons;

    var reason =
      reasons.find(function (r) {
        return r.key === selection;
      }) || {};

    if (selection === "" || (reason.askForComments && comments === "")) {
      $("#button-confirm-cancelation").attr("disabled", "");
    } else {
      $("#button-confirm-cancelation").removeAttr("disabled");
    }

    if (reason.askForComments) {
      $("#cancelation-reason-other").removeClass("hidden");
      $("#cancelation-reason-other").attr("placeholder", reason.askForComments);
    } else {
      $("#cancelation-reason-other").addClass("hidden");
    }

    if (!reason.talkToUs) {
      $("#talk-to-us").addClass("hide");
    }
  },

  enableTokenize: function () {
    var button = null,
      type = "info",
      self = this,
      flag = null;
    this.confirmModal(
      "El usuario deberá cerrar sesión y volver a ingresar para ver los cambios.<br/><br/>En caso de tener una suscripción por débito automático, primero cancelarla para evitar pagos duplicados.",
      button,
      "Activar tokenizacion de tarjetas?",
      type,
      flag,
      function () {
        return $.ajax({
          type: "PUT",
          url: "features",
          data: { USE_TOKENIZE: "1" }
        }).done(() => {
          app.session.attributes.details.account.features.USE_TOKENIZE = "1";
          app.saveSessionCookie(app.session.toJSON());
          self.displayMessage("Tokenización activada");
          self.reload();
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  enableTokenizeMP: function () {
    var button = null,
      type = "info",
      self = this,
      flag = null;
    this.confirmModal(
      "Al activar esto, se pasará a usar MercadoPago en vez de PagoUno. El usuario deberá refrescar la página para ver los cambios.",
      button,
      "Activar tokenizacion con MercadoPago?",
      type,
      flag,
      function () {
        return $.ajax({
          type: "PUT",
          url: "features",
          data: { USE_TOKENIZE_MP: "1" }
        }).done(() => {
          app.session.attributes.details.account.features.USE_TOKENIZE_MP = "1";
          app.saveSessionCookie(app.session.toJSON());
          self.displayMessage("Tokenización con MercadoPago activada");
          self.reload();
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  disableTokenizeMP: function () {
    var button = null,
      type = "info",
      self = this,
      flag = null;
    this.confirmModal(
      "Al activar esto, se pasará a usar PagoUno en vez de MercadoPago para la tokenización de las tarjetas. El usuario deberá refrescar la página para ver los cambios.",
      button,
      "Activar tokenizacion con PagoUno?",
      type,
      flag,
      function () {
        return $.ajax({
          type: "PUT",
          url: "features",
          data: { USE_TOKENIZE_MP: "0" }
        }).done(() => {
          app.session.attributes.details.account.features.USE_TOKENIZE_MP = "0";
          app.saveSessionCookie(app.session.toJSON());
          self.displayMessage("Tokenización con PagoUno activada");
          self.reload();
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  // bloquear cuenta por abuse
  blockAbuse: function () {
    var self = this;

    this.confirmModal(
      "Estás a punto de suspender esta cuenta. Al hacerlo se le enviará un mensaje al cliente notificando sobre la situacion.<br/><br/>Si no estás seguro de los que estás haciendo no avances.",
      null,
      "Bloquear cuenta por actividad sospechosa?",
      "warning",
      null,
      function () {
        return $.ajax({
          url: "plan/blockabuse",
          type: "POST",
          success: function () {
            self.displayMessage("Envíos bloqueados", 3000, "success");
            self.reload();
          }
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  // desbloquear cuenta por abuse
  unblockAbuse: function () {
    var self = this;
    $.ajax({
      url: "plan/unblockabuse",
      type: "POST",
      success: function () {
        self.displayMessage("Envíos desbloqueados", 3000, "success");
        self.reload();
      }
    });
  },

  makeFree: function () {
    var self = this;

    this.confirmModal(
      "Estás a punto de activar el plan FREE para esta cuenta, ¿estás seguro?",
      null,
      "Convertir a plan free",
      "info",
      null,
      function () {
        $.ajax({
          url: "plan/subscription/makefree",
          type: "POST",
          data: JSON.stringify({}),
          success: function () {
            self.displayMessage("El plan fue activado con éxito", 3000, "success");
            self.reload();
          }
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  }
});
