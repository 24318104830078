<template>
  <button
    :class="[
      font.disabled && 'border-gray-100/50',
      'relative group flex items-center justify-center px-2 border-2 border-gray-100 focus:outline-none focus:border-gray-200 hover:border-gray-300 rounded-lg h-36 w-48 cursor-default',
    ]"
    @click="onDisable"
  >
    <div
      ref="buttonsRef"
      :class="[
        font.disabled && 'opacity-100',
        'absolute right-2 top-2 space-x-1 opacity-0 group-focus:opacity-100 focus-within:opacity-100 group-hover:opacity-100',
      ]"
    >
      <IconButton
        v-if="allowEdit"
        tabindex="0"
        :label="t('editButtonTooltip')"
        :show-focus="false"
        class="opacity-0 focus:opacity-100 group-focus:opacity-100 group-hover:opacity-100"
        @click.stop="onEdit"
        ><PencilAltIcon
      /></IconButton>
      <IconButton tabindex="0" :label="disableTooltip" :show-focus="false" @click.stop="onDisable">
        <EyeOffIcon v-if="font.disabled" />
        <EyeIcon v-else />
      </IconButton>
    </div>
    <div :class="[font.disabled && 'opacity-30', 'pointer-events-none']">
      <span
        :style="{
          fontFamily: fontFamily,
        }"
      >
        Abcdefg
      </span>
      <TextParagraph class="mb-0"> {{ font.name }} </TextParagraph>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue";

// Components
import TextParagraph from "@atoms/TextParagraph.vue";
import IconButton from "@/vue/components/atoms/IconButton.vue";

// Icons
import { EyeIcon, EyeOffIcon, PencilAltIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";
import { addStyleSheetURL } from "@/vue/helpers/DOM";

// Types
import type { FontWithAlter } from "@/vue/types/fonts";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    font: FontWithAlter;
    allowEdit?: boolean;
  }>(),
  {
    allowEdit: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "disable"): void;
  (e: "edit"): void;
}>();

const onDisable = () => emit("disable");
const onEdit = () => emit("edit");

// Font family
const fontFamily = computed(() => (props.font.url ? `'${props.font.value}'` : props.font.value));

// Tooltips
const disableTooltip = computed(() => (props.font.disabled ? t("enableButtonTooltip") : t("disableButtonTooltip")));

onMounted(() => {
  props.font.url && addStyleSheetURL(props.font.url);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "editButtonTooltip": "Editar",
    "disableButtonTooltip": "Desactivar",
    "enableButtonTooltip": "Activar"
  },
  "pt": {
    "editButtonTooltip": "Editar",
    "disableButtonTooltip": "Desativar",
    "enableButtonTooltip": "Ativar"
  }
}
</i18n>
