<template>
  <svg width="12" height="12" viewBox="0 0 12 12" class="p-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.49012e-08 1.83333C1.49012e-08 0.820811 0.820811 1.49012e-08 1.83333 1.49012e-08C2.84586 1.49012e-08 3.66667 0.820811 3.66667 1.83333C3.66667 2.84586 2.84586 3.66667 1.83333 3.66667C0.820811 3.66667 1.49012e-08 2.84586 1.49012e-08 1.83333ZM11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893ZM8.33333 10.1667C8.33333 9.15414 9.15414 8.33333 10.1667 8.33333C11.1792 8.33333 12 9.15414 12 10.1667C12 11.1792 11.1792 12 10.1667 12C9.15414 12 8.33333 11.1792 8.33333 10.1667Z"
      fill="currentColor"
    />
  </svg>
</template>
