<template>
  <span class="flex justify-start text-sm text-gray-500">
    {{ t(props.fieldType) }}
  </span>
</template>

<script lang="ts" setup>
// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  fieldType: string;
}>();
</script>

<i18n lang="jsonc">
{
  "es": {
    "TEXT": "Texto",
    "INT": "Número",
    "DATE": "Fecha"
  },
  "pt": {
    "TEXT": "Texto",
    "INT": "Número",
    "DATE": "Fecha"
  }
}
</i18n>
