<template>
  <svg
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin: 0.5rem 1rem"
  >
    <rect x="1" y="1" width="178" height="118" rx="4" stroke="#00ADE8" stroke-width="2" />
    <rect x="19" y="64" width="143" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="19" y="39" width="143" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="18" y="14" width="145" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="18" y="26" width="54" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="18" y="88" width="145" height="18" rx="2" fill="#00ADE8" />
  </svg>
</template>

<script setup></script>
