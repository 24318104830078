<template>
  <div class="relative rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-3">
      <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
    <input
      v-bind="$attrs"
      class="block w-full rounded-md border-gray-300 pl-10 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
      :class="{
        'border-gray-300 focus-within:border-sky-500 focus-within:ring-1 focus-within:ring-sky-500 focus:bg-white':
          !modelValue,
      }"
      type="text"
      :value="modelValue"
      :placeholder="placeholder"
      @input="onInput"
      @keydown.escape="emitClear"
    />
    <div v-if="!loading && modelValue" class="absolute inset-y-0 right-0 flex items-center pr-3">
      <XIcon class="h-5 w-5 cursor-pointer text-gray-400 hover:text-gray-500" aria-hidden="true" @click="emitClear" />
    </div>
    <div v-if="loading" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

//Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

//Icons
import { SearchIcon, XIcon } from "@heroicons/vue/solid";

defineProps<{
  modelValue?: string;
  placeholder?: string;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: string): void;
  (e: "finishTyping", modelValue: string): void;
}>();

const updateValue = (e: Event) => {
  emit("update:modelValue", (e.target as HTMLInputElement).value as string);
};

const emitClear = () => {
  emit("update:modelValue", "");
};

//Finish typing
const timer = ref<ReturnType<typeof setTimeout> | null>(null);
const onInput = (ev: Event) => {
  updateValue(ev);
  timer.value && clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    emit("finishTyping", (ev.target as HTMLInputElement).value as string);
  }, 400);
};
</script>
