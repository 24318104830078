import { Collection } from "@/core/magnet";
import app from "@/app";

export default Collection.extend({
  url: "integrations",
  parse: function (response) {
    // Estas son las integraciones activas
    return response.data.map(function (item) {
      return { id: item.name };
    });
  },
  additionals: {
    integrations: function () {
      return $.get(`/data/integrations-${app.lang}.json?_=${Date.now()}`);
    },
  },
  parseAdditionals: function (additionals) {
    const account = app.session.get("account");

    if (additionals.integrations) {
      additionals.integrations = additionals.integrations
        .filter(function (item) {
          return !(item.private && !item.private.includes(account));
        })
        .filter(function (item) {
          return !(item.hideOnWhiteLabel && window.config.whiteLabel);
        });
    }

    return additionals;
  },
});
