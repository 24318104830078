<template>
  <div ref="containerRef" class="relative min-w-0 bg-gray-600">
    <input
      v-if="editing"
      ref="inputRef"
      v-model.trim="editedValue"
      class="w-full rounded border-2 bg-gray-600 px-[13px] text-2xl font-bold text-white focus:outline-none active:outline-none"
      :class="[editable && 'focus:bg-gray-700', error ? 'border-red-400' : 'border-transparent']"
      :model-modifiers="inputModifiers"
      :placeholder="placeholder"
      @input="emitValue"
      @keydown.stop.enter="commit"
      @keydown.stop.esc="cancel"
    />
    <p
      v-else
      class="truncate rounded border-2 border-transparent bg-gray-600 px-[13px] text-2xl font-bold text-white transition-colors duration-100"
      :class="editable ? 'hover:text-gray-300 ' : 'cursor-default'"
      @click="startEdit"
    >
      {{ content || placeholder }}
    </p>
    <ExclamationCircleIcon
      v-if="editing && error"
      class="absolute bottom-0 right-0 top-0 my-auto mr-2 h-5 w-5 text-red-400"
    />
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref } from "vue";

//Icons
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

//Util
import { onClickOutside } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    content: string;
    inputModifiers?: Record<string, unknown>;
    editable: boolean;
    placeholder?: string;
    error?: boolean;
  }>(),
  {
    content: "",
    inputModifiers: undefined,
    editable: true,
    placeholder: "",
    error: false,
  }
);

const editing = ref(false);
const editedValue = ref(props.content);

const inputRef = ref<HTMLInputElement>();
const containerRef = ref();

// Emits
const emit = defineEmits<{
  (e: "update", value: string): void;
  (e: "commit", value: string): void;
  (e: "cancel"): void;
  (e: "edit"): void;
}>();

const emitValue = (e) => {
  let value = e.target.value;
  emit("update", value);
};

const cancel = () => {
  emit("cancel");
  editing.value = false;
  editedValue.value = props.content;
};

const startEdit = () => {
  if (!props.editable) return;
  editedValue.value = props.content;
  editing.value = true;
  nextTick().then(() => {
    if (!inputRef.value) return;
    inputRef.value.focus();
  });
  emit("edit");
};

const commit = () => {
  if (!editing.value) return;
  editing.value = false;
  if (editedValue.value === props.content || editedValue.value === "") {
    return cancel();
  }
  emit("commit", editedValue.value);
};

onClickOutside(containerRef, commit);
defineExpose({
  containerRef,
  inputRef,
  startEdit,
});
</script>
