<!--
TODO:
- i18n

-->
<template>
  <IconButton :label="copied ? 'Copiado!' : 'Copiar'" @click="copy()">
    <ClipboardCopyIcon v-if="!copied" />
    <ClipboardCheckIcon v-else class="transform rotate-6 text-green-600" />
  </IconButton>
</template>

<script>
import IconButton from "@atoms/IconButton";
import { ClipboardCopyIcon, ClipboardCheckIcon } from "@heroicons/vue/outline";
import { useClipboard } from "@vueuse/core";

export default {
  name: "ClipboardButton",
  components: { IconButton, ClipboardCopyIcon, ClipboardCheckIcon },
  props: {
    text: { type: String, required: true },
  },
  setup(props) {
    const { copy, copied } = useClipboard({ source: props.text });

    return { copy, copied };
  },
};
</script>
