import { Ref } from "vue";

import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import { adjacencyGraphs } from "@zxcvbn-ts/language-common";
import { dictionary as esDictionary } from "@zxcvbn-ts/language-es-es";
import { dictionary as brDictionary } from "@zxcvbn-ts/language-pt-br";

import * as validators from "@vuelidate/validators";

export const required = (message: string | (() => string)) => {
  return validators.helpers.withMessage(message, validators.required);
};

export const email = (message: string | (() => string)) => {
  return validators.helpers.withMessage(message, validators.email);
};

export const maxLength = (length: number | Ref<number>, message: string | (() => string)) => {
  return validators.helpers.withMessage(message, validators.maxLength(length));
};

export const minLength = (length: number | Ref<number>, message: string | (() => string)) => {
  return validators.helpers.withMessage(message, validators.minLength(length));
};

export const isHexColor = (message: string | (() => string)) => {
  return validators.helpers.withMessage(
    message,
    (value) => !validators.helpers.req(value) || /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(value as string),
  );
};

export const hasUpperAndNumber = (inputString: string): boolean => {
  const hasUpper = /[A-Z]/.test(inputString);
  const hasNumber = /\d/.test(inputString);

  return hasUpper && hasNumber;
};

export const analyzePasswordStrength = (value: string) => {
  zxcvbnOptions;
  zxcvbnOptions.setOptions({
    graphs: adjacencyGraphs,
    dictionary: {
      ...esDictionary,
      ...brDictionary,
    },
  });
  return zxcvbn(value);
};
