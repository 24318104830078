<template>
  <ModalDialog :open="open" :title="t('title')" without-overflow @close="close">
    <AlertBox
      v-if="
        !predicatesValidation.valid &&
        ((predicatesValidation.invalidFields && predicatesValidation.invalidFields?.length > 0) ||
          (predicatesValidation.invalidInterests && predicatesValidation.invalidInterests?.length > 0))
      "
      theme="danger"
    >
      {{ t("errorMissingFieldsOrAttributes") }}</AlertBox
    >
    <div class="w-[40vw] space-y-4">
      <template v-if="inputMode === 'default'">
        <TextParagraph v-if="allowEdit">{{ t("conditionDescription") }}</TextParagraph>
        <ConditionSelector
          v-model:predicates="predicates"
          :disabled="!allowEdit"
          :fields="fields"
          :interests="interests"
        />
        <AlertBox v-if="!allowEdit" theme="info">
          <span class="block font-bold"> {{ t("alertTitleCannotEdit") }} </span>
          {{ t("alertDescriptionCannotEdit") }}
        </AlertBox>
      </template>
      <template v-else-if="isMasterUser">
        <AlertBox theme="info">
          <span class="block font-bold"> Modo avanzado para master users. </span>
          Las condiciones creadas en modo avanzado no son editables por el usuario.
        </AlertBox>
        <FormInput
          v-model="name"
          :label="t('conditionNameInputLabel')"
          :placeholder="t('conditionNameInputPlaceholder')"
          :error="nameHasError ? t('fieldRequired') : undefined"
        />
        <FormInput
          v-if="isMasterUser"
          v-model="description"
          :label="t('inputDescriptionConditionLabel')"
          :placeholder="t('inputDescriptionConditionPlaceholder')"
        />
        <FormInput
          v-model:model-value="beforeInput"
          placeholder="[#if contact.first_name == 'Jose']"
          :label="t('inputBeforeConditionLabel')"
          :error="beforeInputHasError ? t('fieldRequired') : undefined"
        />
        <FormInput
          v-model:model-value="afterInput"
          placeholder="[/#if]"
          :label="t('inputAfterConditionLabel')"
          :error="afterInputHasError ? t('fieldRequired') : undefined"
        />
      </template>
    </div>
    <div v-if="isMasterUser">
      <FormSwitch
        :model-value="inputMode === 'manual'"
        label="Modo avanzado"
        description="Activar edición avanzada. Sólo para master users."
        class="flex flex-row-reverse justify-start"
        @update:model-value="updateInputMode"
      />
    </div>
    <template #buttons>
      <div :class="[allowDelete ? 'justify-between' : 'justify-end', 'flex w-full']">
        <SimpleButton v-if="allowDelete" theme="danger-alter" @click="deleteCondition">{{
          t("buttonDelete")
        }}</SimpleButton>

        <div class="space-x-4">
          <SimpleButton theme="white" @click="close">{{ t("buttonCancel") }}</SimpleButton>
          <SimpleButton v-if="allowEdit" :disabled="errors" @click="apply">{{ t("buttonApply") }}</SimpleButton>
        </div>
      </div>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, computed, watch, toRaw } from "vue";

// Components
import AlertBox from "@atoms/AlertBox.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormSwitch from "@/vue/components/molecules/FormSwitch.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FormInput from "@molecules/FormInput.vue";
import TextParagraph from "@/vue/components/atoms/TextParagraph.vue";
import ConditionSelector from "@organisms/ConditionSelector/ConditionSelector.vue";

// Utils
import { isEmpty } from "lodash";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Applications
import { useConditionsApp } from "@application/conditions/conditions.app";

//I18n
import { useI18n } from "vue-i18n";

// Domain
import type { Condition, ConditionsPredicateTypes } from "@domain/conditions";
import type { PredicatesWithId } from "@domain/predicates";
import { addIdsToPredicates, removeIdsFromPredicates } from "@domain/predicates";
import type { Fields } from "@domain/fields";
import type { Interests } from "@domain/interests";

// Session
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value?.isMasterUser;

const { t } = useI18n();
const conditionsApp = useConditionsApp();

const props = withDefaults(
  defineProps<{
    open: boolean;
    condition: Condition | undefined;
    fields: Fields | undefined;
    interests: Interests | undefined;
  }>(),
  {
    open: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "close"): void;
  (e: "delete"): void;
  (e: "apply", displayConditions: Condition): void;
}>();

const close = () => {
  emit("close");
};

const deleteCondition = () => {
  emit("delete");
  close();
};

// input mode
const getDefaultInputMode = () => {
  return isMasterUser && !props.condition?.predicates && (props.condition?.after || props.condition?.before)
    ? "manual"
    : "default";
};

const inputMode = ref<"manual" | "default">(getDefaultInputMode());
const updateInputMode = (mode: boolean) => {
  clearErrors();
  inputMode.value = mode ? "manual" : "default";
};

// Data
const name = ref("");
const nameHasError = ref(false);
watch(
  () => name.value,
  () => {
    if (!props.open) return;
    nameHasError.value = name.value === "";
  }
);
const setName = () => {
  name.value = props.condition?.label ?? "";
};
const clearName = () => {
  name.value = "";
};

const description = ref("");
const setDescription = () => {
  description.value = props.condition?.description ?? "";
};
const clearDescription = () => {
  description.value = "";
};

// Manual Data
const beforeInput = ref("");
const beforeInputHasError = ref(false);
watch(
  () => beforeInput.value,
  () => {
    if (!props.open) return;
    beforeInputHasError.value = beforeInput.value === "";
  }
);

const afterInput = ref("");
const afterInputHasError = ref(false);
watch(
  () => afterInput.value,
  () => {
    if (!props.open) return;
    afterInputHasError.value = afterInput.value === "";
  }
);

const clearManualInputs = () => {
  beforeInput.value = "";
  afterInput.value = "";
};

const setManualInputs = () => {
  if (props.condition?.after && props.condition?.before) {
    const condition = props.condition;
    beforeInput.value = condition.before;
    afterInput.value = condition.after;
    return;
  }

  if (props.condition?.predicates) {
    const condition = conditionsApp.predicatesToCondition({
      name: name.value,
      predicates: toRaw(props.condition.predicates),
      fields: toRaw(props.fields),
      interests: toRaw(props.interests),
    });
    beforeInput.value = condition.before;
    afterInput.value = condition.after;
    return;
  }
};

// Predicates
const predicates = ref<PredicatesWithId<string, ConditionsPredicateTypes>>({
  attributes: [],
  operator: "and",
});

const setPredicates = () => {
  predicates.value = props.condition?.predicates
    ? addIdsToPredicates<string, ConditionsPredicateTypes>(props.condition?.predicates)
    : {
        attributes: [],
        operator: "and",
      };
};

const clearPredicates = () => {
  predicates.value = {
    attributes: [],
    operator: "and",
  };
};

const errors = computed(() => {
  if (inputMode.value === "default") {
    return isEmpty(predicates.value.attributes) || !predicatesValidation.value.valid;
  }

  return nameHasError.value || beforeInputHasError.value || afterInputHasError.value;
});

const clearErrors = () => {
  nameHasError.value = false;
  beforeInputHasError.value = false;
  afterInputHasError.value = false;
};

const allowEdit = computed(() => {
  return (
    isMasterUser ||
    isEmpty(props.condition) ||
    (!isMasterUser && props.condition.predicates) ||
    (!isMasterUser && props.condition.before === "" && props.condition.after === "")
  );
});

const allowDelete = computed(() => {
  return (
    !isEmpty(props.condition) &&
    (props.condition.predicates || (isMasterUser && props.condition.before !== "" && props.condition.after !== ""))
  );
});

const applyPredicates = () => {
  const condition = conditionsApp.predicatesToCondition({
    name: name.value,
    predicates: removeIdsFromPredicates<string, ConditionsPredicateTypes>(toRaw(predicates.value)),
    fields: toRaw(props.fields),
    interests: toRaw(props.interests),
  });
  emit("apply", condition);
};

const applyManualCondition = () => {
  emit("apply", {
    label: name.value,
    description: description.value,
    before: beforeInput.value,
    after: afterInput.value,
  });
};

const validateFields = () => {
  if (inputMode.value === "default") return;

  if (!name.value) nameHasError.value = true;
  if (!beforeInput.value) beforeInputHasError.value = true;
  if (!afterInput.value) afterInputHasError.value = true;
};

const apply = () => {
  validateFields();
  if (errors.value) return;

  if (isMasterUser && inputMode.value === "manual") {
    applyManualCondition();
  } else {
    applyPredicates();
  }
  close();
};

watch(
  () => props.open,
  () => {
    if (props.open) {
      setPredicates();
      setName();
      setDescription();
      setManualInputs();
      inputMode.value = getDefaultInputMode();
      clearErrors();
    } else {
      clearPredicates();
      clearName();
      clearDescription();
      clearManualInputs();
      clearErrors();
    }
  }
);

const predicatesValidation = computed(() =>
  conditionsApp.predicatesIsValid({ predicates: predicates.value, fields: props.fields, interests: props.interests })
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Condiciones de visualización",
    "conditionNameInputLabel": "Nombre de la condición",
    "conditionNameInputPlaceholder": "Usa un nombre que defina las reglas de tu condición",
    "conditionDescription": "Sólo se mostrará este elemento a los contactos que cumplan las siguientes condiciones:",
    "errorMissingFieldsOrAttributes": "Se borraron uno o más campos o intereses de tu cuenta.",
    "fieldRequired": "Campo requerido",
    "alertTitleCannotEdit": "No puedes editar esta condición",
    "alertDescriptionCannotEdit": "Esta condición no es editable ya que fue creada por el sistema.",
    "buttonDelete": "Borrar condición",
    "buttonApply": "Aplicar condiciones",
    "buttonCancel": "Cancelar",
    "inputBeforeConditionLabel": "Before",
    "inputAfterConditionLabel": "After",
    "inputDescriptionConditionLabel": "Descripción",
    "inputDescriptionConditionPlaceholder": "Descripción de la condición"
  },
  "pt": {
    "title": "Condições de exibição",
    "conditionNameInputLabel": "Nome da condição",
    "conditionNameInputPlaceholder": "Use um nome que defina as regras da sua condição",
    "conditionDescription": "Este item será exibido apenas para contatos que atenderem às seguintes condições:",
    "errorMissingFieldsOrAttributes": "Um ou mais campos ou interesses foram excluídos de sua conta.",
    "fieldRequired": "Campo obrigatório",
    "alertTitleCannotEdit": "Você não pode editar esta condição",
    "alertDescriptionCannotEdit": "Esta condição não é editável, pois foi criada pelo sistema.",
    "buttonDelete": "Excluir condição",
    "buttonApply": "Aplicar condição",
    "buttonCancel": "Cancelar",
    "inputBeforeConditionLabel": "Before",
    "inputAfterConditionLabel": "After",
    "inputDescriptionConditionLabel": "Descrição",
    "inputDescriptionConditionPlaceholder": "Descrição da condição"
  }
}
</i18n>
