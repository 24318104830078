<template>
        <ul>
      <li>
        <div class="relative animate-pulse pb-8">
          <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 ring-8 ring-white"> </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div class="space-y-2">
                <div class="h-4 w-72 rounded-xl bg-gray-200"></div>
              </div>
              <div>
                <div class="h-4 w-8 rounded-xl bg-gray-200"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="relative animate-pulse pb-8">
          <span class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 ring-8 ring-white"> </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div class="space-y-2">
                <div class="h-4 w-72 rounded-xl bg-gray-200"></div>
              </div>
              <div>
                <div class="h-4 w-8 rounded-xl bg-gray-200"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="relative animate-pulse pb-8">
          <div class="relative flex space-x-3">
            <div>
              <span class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 ring-8 ring-white"> </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div class="space-y-2">
                <div class="h-4 w-72 rounded-xl bg-gray-200"></div>
              </div>
              <div>
                <div class="h-4 w-8 rounded-xl bg-gray-200"></div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
</template>