var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"displayName") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <input type='checkbox' name='required' value='1' checked class=\"hidden\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class='form-group'>\n                        <div class='graphic checkbox'>\n                            <input id='required-field' type='checkbox' name='required' value='1' "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"required") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":97},"end":{"line":33,"column":47}}})) != null ? stack1 : "")
    + ">\n                            <label for='required-field'>\n                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"requiredField")), depth0))
    + "\n                            </label>\n                        </div>\n                    </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n                                checked ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n    <form data-form='update-option'>\n        <input type='hidden' name='type' value='fields'>\n        <input type='hidden' name='id' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n        <input type='hidden' name='name' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"editField")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"displayName")), depth0))
    + "\n                        </label>\n                        <input class='form-control' name='displayName' type='text'\n                            value='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"displayName") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":107}}})) != null ? stack1 : "")
    + "'>\n                    </div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),3,{"name":"is","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});