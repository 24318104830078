import type { PlanState, PlanType } from "./plan";
import type { Permissions } from "./permissions";
import type { UserType } from "@domain/session";
import type { Timezone } from "./timezones";

export interface LegacyAuthenticationObject {
  token: string;
  user: string;
  userType: UserType;
  account: string;
  tokenExpiration: number;
  acl: Record<string, Permissions>;
  details: {
    user: {
      username: string;
      email: string;
      name: string;
      language: "es"; // TODO: Lenguajes?
      created: DateString;
      lastLogin: DateString;
      passLastChanged: DateString;
      passNeverExpires: boolean;
      disabled: boolean;
      permissions: null; // TODO: Tipar
      timezone: Timezone;
      role: "ADMIN" | ""; // TODO: Roles list
    };
    account: {
      code: string;
      name: string;
      parent: string | null;
      container: boolean;
      created: string;
      lastLogin: string;
      lastUsage: string;
      disabled: boolean;
      country: "ar"; // TODO: listado de paises? "ar"
      language: "es"; // Todo: Intl "es"
      postalAddress: string;
      industry: string;
      features: Record<string, "1" | "0">; // TODO: Features DOMAIN
    } | null; // TODO: Tipar
    plan: {
      type: PlanType;
      state: PlanState;
      emails: {
        limit: number;
        available: number;
        excessLimit: number;
      };
      contacts: {
        limit: number;
        active: number;
      };
      blockUnpaid: boolean;
    } | null;
    stats: null; // TODO: Tipar
  };
}
export interface AuthenticationObject {
  token: string;
  tokenExpiration: number;
  legacyResponse: LegacyAuthenticationObject; // Para quitar luego
}

export const isAuthenticationObject = (a: any): a is AuthenticationObject => {
  return (a as AuthenticationObject).token !== undefined && (a as AuthenticationObject).tokenExpiration !== undefined;
};

export const islegacyAuthenticationObject = (a: any): a is LegacyAuthenticationObject => {
  return (
    (a as LegacyAuthenticationObject)?.token !== undefined && (a as LegacyAuthenticationObject)?.details !== undefined
  );
};
