import { useRequests, usePagingRequests } from "@api/requests";

import { isEqual } from "lodash";

// Types
import type {
  GetParams,
  GetNotificationsAsyncGenerator,
  NotificationsService,
  GetTasksAPIResponse,
  MarkNotificationAsReadAPIResponse,
  GetLatestAPIResponse,
} from "./notifications.types";
import type { SuccessAPILegacyBase } from "../../models/requestWrappers";
import type { Notifications } from "@domain/notifications";

export function useNotifications(): NotificationsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let getNotificationsGenerator: GetNotificationsAsyncGenerator | undefined = undefined;
  let getApiParams: GetParams | undefined = undefined;

  return {
    async getLatestNotifications(params) {
      const res = await request<GetLatestAPIResponse>({
        url: params?.isMasterUser ? `/masterusers/me/notifications` : `/users/me/notifications`,
        method: "get",
        disableAccountPrefix: params?.isMasterUser,
        account: !getApiParams?.isMasterUser ? getApiParams?.account : undefined,
        urlParams: {
          "filters.read": "all",
          "filters.limit": params?.limit ?? 100,
        },
      });

      return res.data;
    },
    async getNotifications(options) {
      if (!isEqual(getApiParams, options) || !getNotificationsGenerator) {
        getApiParams = options;
        getNotificationsGenerator = requestPaging<Notifications, unknown>(
          {
            url: getApiParams?.isMasterUser ? `/masterusers/me/notifications` : `/users/me/notifications`,
            method: "get",
            disableAccountPrefix: getApiParams?.isMasterUser,
            account: !getApiParams?.isMasterUser ? getApiParams?.account : undefined,
            urlParams: {
              "filters.read": "all",
            },
          },
          { limit: getApiParams?.limit ?? 20, offset: getApiParams?.offset ?? 0 },
          true,
        );
      }

      if (!getNotificationsGenerator || getNotificationsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
        };

      const data = (await getNotificationsGenerator.next()) as IteratorResult<SuccessAPILegacyBase<Notifications>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
      };
    },
    async cancelTask(params) {
      await request<GetTasksAPIResponse>({
        url: `/tasks/${params.id}/cancel`,
        method: "post",
      });
    },
    async markNotificationAsRead(params) {
      const res = await request<MarkNotificationAsReadAPIResponse>({
        url: params?.isMasterUser
          ? `/masterusers/me/notifications/${params.id}`
          : `/users/me/notifications/${params.id}`,
        method: "put",
        data: {
          read: true,
        },
        disableAccountPrefix: params?.isMasterUser,
      });

      return res.data;
    },
    async getTasks() {
      const res = await request<GetTasksAPIResponse>({
        url: "/tasks",
        method: "get",
        urlParams: {
          "filters.state": "active",
        },
      });

      return res.data;
    },
  };
}
