var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class='dropdown'>\n    <a data-toggle='dropdown' data-action='load-filter' data-params='{\"filter\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"}'>\n        <i class='filter-icon "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "'></i>\n        <span data-filter-name='filter."
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "'>\n            "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":31}}}))
    + "\n        </span>\n        <i class='drop fa fa-angle-down'></i>\n        <i class='remove fa fa-close' data-action='remove-filter' data-params='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"remove") : depth0), depth0))
    + "'></i>\n    </a>\n    <ul class='dropdown-menu scrollbar'>\n        <li class=\"spinner\">\n            <i class=\"fa fa-spinner fa-spin\"></i>\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"loading") : depth0), depth0))
    + "\n        </li>\n    </ul>\n</li>";
},"useData":true});