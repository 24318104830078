<script setup lang="ts">
import { PropType, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@composables/breakpoints";
import { DashboardData } from "@domain/dashboard/dashboard";

import AlertBox from "@atoms/AlertBox.vue";
import SectionHeader from "@atoms/SectionHeader.vue";
import MetricCard from "@molecules/MetricCard.vue";
import DashboardChartEmails from "@organisms/Dashboard/DashboardChartEmails.vue";

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  batchType: {
    type: String as PropType<"all" | "bulk" | "automation" | "transactional">,
    default: "all",
    validator: (value: string) => {
      return ["all", "bulk", "automation", "transactional"].includes(value);
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const { t, n, d } = useI18n();
const { smBp } = useBreakpoints();

//TODO: esto se repite entre dashboards, reutilizarlo
const sn = (value: number | undefined, format: string): string => n(value || 0, format);
const prevPeriod = computed(() =>
  props.data ? `${d(props.data.period.prevFrom, "short")} - ${d(props.data.period.prevTo, "short")}` : ""
);
</script>

<template>
  <div :class="!pdfMode && 'mb-8'" class="mx-auto max-w-7xl">
    <AlertBox v-if="data?.emails.currentDataPoints === 0" :data-html2canvas-ignore="pdfMode" theme="info" class="mb-4">
      {{ data?.period.isUntilLastDay() ? t("emptyPeriodNewAccount") : t("emptyPeriod") }}
    </AlertBox>
    <SectionHeader>{{ t("mainMetrics.header") }}</SectionHeader>
    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <MetricCard
        :variation="data?.emails.sent.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.sent.variationTooltip', {
                amount: sn(data?.emails.sent.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :info-tooltip="t('mainMetrics.sent.tooltip')"
        :bar-perc="data?.emails.delivered.current.percentage100"
        :loading="loading"
        bar-color="sky"
      >
        <template #title> {{ t("mainMetrics.sent.title") }} </template>
        <template #value> {{ n(data?.emails.sent.current.metric || 0, "decimal") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.sent.comment" :plural="data?.emails.sent.current.metric || 0">
            <template #perc>
              {{ n(data?.emails.delivered.current.percentage || 0, "percent") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.emails.opened.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.opened.variationTooltip', {
                amount: sn(data?.emails.opened.previous.percentage, 'percent'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :bar-perc="data?.emails.opened.current.percentage100"
        :info-tooltip="t('mainMetrics.opened.tooltip')"
        :loading="loading"
        bar-color="sky"
        data-intercom-target="MetricCard-opened"
      >
        <template #title>{{ t("mainMetrics.opened.title") }}</template>
        <template #value>
          {{ n(data?.emails.opened.current.percentage || 0, "percent") }}
        </template>
        <template #description>
          <i18n-t keypath="mainMetrics.opened.comment" :plural="data?.emails.opened.current.metric || 0">
            <template #opened>
              {{ n(data?.emails.opened.current.metric || 0, "decimal") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.emails.clicked.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.clicked.variationTooltip', {
                amount: sn(data?.emails.clicked.previous.percentage, 'percent'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :bar-perc="data?.emails.clicked.current.percentage100"
        :loading="loading"
        :info-tooltip="t('mainMetrics.clicked.tooltip')"
        bar-color="sky"
      >
        <template #title>{{ t("mainMetrics.clicked.title") }}</template>
        <template #value>
          {{ n(data?.emails.clicked.current.percentage || 0, "percent") }}
        </template>

        <template #description>
          <i18n-t keypath="mainMetrics.clicked.comment" :plural="data?.emails.clicked.current.metric || 0">
            <template #clicked>
              {{ n(data?.emails.clicked.current.metric || 0, "decimal") }}
            </template>
            <template #ctor>
              {{
                sn(
                  data?.emails.delivered.current.metric
                    ? (data?.emails.clicked.current.metric || 0) / (data?.emails.delivered.current.metric || 0)
                    : 0,
                  "percent"
                )
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.emails.bounced.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.bounced.variationTooltip', {
                amount: sn(data?.emails.bounced.previous.percentage, 'percent'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :variation-inverse="true"
        :loading="loading"
        :info-tooltip="t('mainMetrics.bounced.tooltip')"
      >
        <template #title>{{ t("mainMetrics.bounced.title") }}</template>
        <template #value>
          {{ n(data?.emails.bounced.current.percentage || 0, "percent") }}
        </template>

        <template #description>
          <i18n-t keypath="mainMetrics.bounced.comment" :plural="data?.emails.bounced.current.metric || 0">
            <template #bounced>
              {{ n(data?.emails.bounced.current.metric || 0, "decimal") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.emails.unsubscribed.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.unsubscribed.variationTooltip', {
                amount: sn(data?.emails.unsubscribed.previous.percentage, 'percent'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :variation-inverse="true"
        :loading="loading"
        :info-tooltip="t('mainMetrics.unsubscribed.tooltip')"
      >
        <template #title>{{ t("mainMetrics.unsubscribed.title") }}</template>
        <template #value>
          {{ n(data?.emails.unsubscribed.current.percentage || 0, "percent") }}
        </template>
        <template #description>
          <i18n-t keypath="mainMetrics.unsubscribed.comment" :plural="data?.emails.unsubscribed.current.metric || 0">
            <template #unsubscribed>
              {{ n(data?.emails.unsubscribed.current.metric || 0, "decimal") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.emails.complained.variation"
        :variation-tooltip="
          data?.emails.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.complained.variationTooltip', {
                amount: sn(data?.emails.complained.previous.percentage, 'percent'),
                period: prevPeriod,
              }) + (data?.emails.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.emails.isPreviousIncomplete"
        :variation-inverse="true"
        :loading="loading"
        :info-tooltip="t('mainMetrics.complained.tooltip')"
      >
        <template #title>{{ t("mainMetrics.complained.title") }}</template>
        <template #value>
          {{ n(data?.emails.complained.current.percentage || 0, "percent") }}
        </template>
        <template #description>
          <i18n-t keypath="mainMetrics.complained.comment" :plural="data?.emails.complained.current.metric || 0">
            <template #complained>
              {{ n(data?.emails.complained.current.metric || 0, "decimal") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
    </div>
    <DashboardChartEmails v-if="smBp" class="mt-4" :data="data" :batch-type="batchType" :loading="loading" />
    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"></div>
    <!-- <SectionHeader class="mt-8">{{ t("resultsByBatch.header") }}</SectionHeader> -->
  </div>
  <!-- <pre>batchType: {{ batchType }}</pre> -->
  <!-- <pre>{{ data?.emails }}</pre> -->
</template>

<i18n lang="jsonc">
{
  "es": {
    "emptyPeriod": "No contamos con datos sobre la actividad de tus envíos en el período indicado. Intenta seleccionado un rango mas reciente.",
    "emptyPeriodNewAccount": "Aún no contamos con información sobre la actividad de tus envíos. En cuanto comiences a enviar campañas o automations, aquí podrás analizar los resultados, comparar con el período anterior y estudiar cambios en las tendencias.",
    "emptyPreviousPeriod": "No se dispone de datos sobre el período anterior.",
    "partialPreviousPeriod": "<br/><br/>*No se dispone de datos completos sobre el período anterior. Es posible que la comparación no sea precisa.",
    "tryUpdatingFilters": "Intenta modificando los filtros.",
    "mainMetrics": {
      "header": "Métricas principales de tus emails",
      "delivered": {
        "title": "Entregados",
        "tooltip": "Emails que fueron aceptados correctamente por el servidor del destinatario.",
        "comment": "{perc} de los {sent} emails enviados fueron entregados con éxito.",
        "variationTooltip": "En comparación con los {amount} emails entregados en el período anterior ({period})."
      },
      "sent": {
        "title": "Envíos",
        "tooltip": "Emails que fueron enviados mediante anuncios masivos y automations.",
        "commentOneOrMany": "{perc} de los emails enviados fueron entregados con éxito a sus destinatarios.",
        "comment": " No se enviaron emails en este período. @:tryUpdatingFilters | @:mainMetrics.sent.commentOneOrMany | @:mainMetrics.sent.commentOneOrMany",
        "variationTooltip": "En comparación con los {amount} emails enviados en el período anterior ({period})."
      },
      "opened": {
        "title": "Aperturas",
        "tooltip": "Emails que fueron vistos al menos una vez por el destinatario, en relación con los emails entregados.",
        "commentOneOrMany": "{opened} emails fueron abiertos al menos una vez por su destinatario.",
        "comment": " No se registraron aperturas en este período. @:tryUpdatingFilters | @:mainMetrics.opened.commentOneOrMany | @:mainMetrics.opened.commentOneOrMany",
        "variationTooltip": "En comparación con el {amount} registrado en el período anterior ({period})."
      },
      "clicked": {
        "title": "Clicks",
        "tooltip": "Emails que recibieron al menos un click en alguno de sus links monitoreados, en relación con los emails abiertos. Esta métrica se la suele definir como CTOR o click to open ratio.",
        "commentOneOrMany": "{clicked} de los emails abiertos recibieron al menos un click, el {ctor} de los entregados.",
        "comment": " No se registraron clicks en este período. @:tryUpdatingFilters | @:mainMetrics.clicked.commentOneOrMany | @:mainMetrics.clicked.commentOneOrMany",
        "variationTooltip": "En comparación con el {amount} registrado en el período anterior ({period})."
      },
      "bounced": {
        "title": "Rebotes",
        "tooltip": "Emails que no fueron aceptados por el servidor destinatario.",
        "commentOne": "1 email fue rechazado por el servidor destinatario.",
        "commentMany": "{bounced} emails fueron rechazados por el servidor destinatario.",
        "comment": " No se registraron rebotes en este período. @:tryUpdatingFilters | @:mainMetrics.bounced.commentOne | @:mainMetrics.bounced.commentMany",
        "variationTooltip": "En comparación con el {amount} registrado en el período anterior ({period})."
      },
      "unsubscribed": {
        "title": "Desuscripciones",
        "tooltip": "Contactos que optaron por dejar de recibir tus comunicaciones.",
        "commentOne": "1 destinatario indicó que ya no quiere recibir tus emails.",
        "commentMany": "{unsubscribed} destinatarios indicaron que ya no quieren recibir tus emails.",
        "comment": " No se registraron desuscripciones en este período. @:tryUpdatingFilters | @:mainMetrics.unsubscribed.commentOne | @:mainMetrics.unsubscribed.commentMany",
        "variationTooltip": "En comparación con el {amount} registrado en el período anterior ({period})."
      },
      "complained": {
        "title": "Marcados como spam",
        "tooltip": "Contactos que reportaron a tus envíos como no solicitados, engañosos o invasivos.",
        "commentOne": "1 destinatario reportó a tus emails como no deseados.",
        "commentMany": "{complained} destinatarios reportaron a tus emails como no deseados.",
        "comment": " No se registraron reportes de spam en este período. @:tryUpdatingFilters | @:mainMetrics.complained.commentOne | @:mainMetrics.complained.commentMany",
        "variationTooltip": "En comparación con el {amount} registrado en el período anterior ({period})."
      }
    },
    "resultsByBatch": {
      "header": "Resultados por campaña"
    }
  },
  "pt": {
    "emptyPeriod": "Não contamos com dados sobre a atividade dos seus envios no período indicado. Tente selecionar um período mais recente.",
    "emptyPeriodNewAccount": "Ainda não contamos com informação sobre a atividade dos seus envios. Quando começar a enviar campanhas ou automations, aqui você poderá analizar os resultados, comparar com o período anterior e estudar mudanças nas tendências.",
    "emptyPreviousPeriod": "Não há dados disponíveis do período anterior.",
    "partialPreviousPeriod": "<br/><br/>*Não temos dados completos sobre o período anterior. É possível que a comparação não seja precisa.",
    "tryUpdatingFilters": "Tente modificar os filtros.",
    "mainMetrics": {
      "header": "Principais métricas dos seus emails",
      "delivered": {
        "title": "Entregues",
        "tooltip": "Emails que foram aceitos corretamente pelo servidor de destino.",
        "comment": "{perc} dos {sent} enviados foram entregues com sucesso.",
        "variationTooltip": "Em comparação com os {amount} emails entregues no período anterior ({period})."
      },
      "sent": {
        "title": "Envios",
        "tooltip": "Emails que foram enviados mediante anúncios massivos e automations.",
        "commentOneOrMany": "{perc} dos emails enviados foram entregues com êxito a seus destinatários.",
        "comment": "Não se enviaram emails nesse período. @:tryUpdatingFilters | @:mainMetrics.sent.commentOneOrMany | @:mainMetrics.sent.commentOneOrMany",
        "variationTooltip": "Em comparação com {amount} emails enviados no período anterior ({period})."
      },
      "opened": {
        "title": "Aberturas",
        "tooltip": "Emails que foram vistos ao menos uma vez pelo destinatário, em relação aos emails entregues.",
        "commentOneOrMany": "{opened} emails foram abertos ao menos uma vez pelo destinatário.",
        "comment": "Não se registraram aberturas nesse período. @:tryUpdatingFilters | @:mainMetrics.opened.commentOneOrMany | @:mainMetrics.opened.commentOneOrMany",
        "variationTooltip": "Em comparação com {amount} registrado no período anterior ({period})."
      },
      "clicked": {
        "title": "Cliques",
        "tooltip": "Emails que receberam ao menos um clique em um de seus links monitorados, em relação a emails abertos.",
        "commentOneOrMany": "{clicked} dos emails abertos receberam ao menos um clique, o {ctor} dos entregados.",
        "comment": "Não foram registrados cliques nesse período. @:tryUpdatingFilters | @:mainMetrics.clicked.commentOneOrMany | @:mainMetrics.clicked.commentOneOrMany",
        "variationTooltip": "Em comparação com {amount} registrado no período anterior ({period})."
      },
      "bounced": {
        "title": "Rejeições",
        "tooltip": "Emails que não foram aceitos pelo servidor de destino",
        "commentOne": "1 e-mail foi recusado pelo servidor destinatário.",
        "commentMany": "{bounced} emails foram recusados pelo servidor destinatário.",
        "comment": "Não se registraram rejeições nesse período. @:tryUpdatingFilters | @:mainMetrics.bounced.commentOne | @:mainMetrics.bounced.commentMany",
        "variationTooltip": "Em comparação com {amount} registrado no período anterior ({period})."
      },
      "unsubscribed": {
        "title": "Descadastrados",
        "tooltip": "Contatos que optaram por parar de receber suas comunicações.",
        "commentOne": "1 destinatário indicou que já não quer receber seus emails.",
        "commentMany": "{unsubscribed} destinatários indicaram que já não querem receber seus emails.",
        "comment": "Não se registraram desinscrições nesse período. @:tryUpdatingFilters | @:mainMetrics.unsubscribed.commentOne | @:mainMetrics.unsubscribed.commentMany",
        "variationTooltip": "Em comparação com {amount} registrado no período anterior ({period})."
      },
      "complained": {
        "title": "Marcado como spam",
        "tooltip": "Contatos que marcaram seus envios como não solicitados, enganosos ou invasivos.",
        "commentOne": "1 destinatário reportou seus emails como não desejados.",
        "commentMany": "{complained} destinatários reportaram seus emails como não desejados.",
        "comment": "Não se registraram reportes de spam nesse período. @:tryUpdatingFilters | @:mainMetrics.complained.commentOne | @:mainMetrics.complained.commentMany",
        "variationTooltip": "Em comparação com {amount} registrados no período anterior ({period})."
      }
    },
    "resultsByBatch": {
      "header": "Resultados por campaña"
    }
  }
}
</i18n>
