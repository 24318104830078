var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "selected";
},"3":function(container,depth0,helpers,partials,data) {
    return " checked='checked'\n                                ";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked='checked' ";
},"7":function(container,depth0,helpers,partials,data) {
    return " checked='checked'\n                                            ";
},"9":function(container,depth0,helpers,partials,data) {
    return "selected='selected' ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog'>\n    <form data-form='save-delay'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"editDelay")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayTime")), depth0))
    + "\n                        </label>\n                        <div class=\"row\">\n                            <div class='col-md-6'><input class='form-control' name='amount' type='number' min=\"0\"\n                                    value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "'></div>\n                            <div class='col-md-6'>\n                                <select class='form-control' name='unit'>\n                                    <option value=\"MINUTES\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"MINUTES",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":60},"end":{"line":25,"column":98}}})) != null ? stack1 : "")
    + ">\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"MINUTES")), depth0))
    + "</option>\n                                    <option value=\"HOURS\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"HOURS",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":58},"end":{"line":27,"column":94}}})) != null ? stack1 : "")
    + ">\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"HOURS")), depth0))
    + "</option>\n                                    <option value=\"DAYS\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"unit") : depth0),"DAYS",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":57},"end":{"line":29,"column":92}}})) != null ? stack1 : "")
    + ">\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayUnits"))) && lookupProperty(stack1,"plural"))) && lookupProperty(stack1,"DAYS")), depth0))
    + "</option>\n                                </select>\n                            </div>\n                        </div>\n                    </div>\n                    <div class='form-group'>\n                        <div class='graphic checkbox'>\n                            <input id='enable-window' name='windowEnabled' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"windowEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":75},"end":{"line":38,"column":39}}})) != null ? stack1 : "")
    + " type='checkbox' value='1' data-action=\"toggle-window-details\">\n                            <label for='enable-window'>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindow")), depth0))
    + "\n                            </label>\n                        </div>\n                        <div id=\"window-details\">\n                            <div class=\"row\">\n                                <div class='col-md-12'>\n                                    <p class=\"input-legend\">\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowLegend")), depth0))
    + "\n                                    </p>\n                                </div>\n                            </div>\n                            <div class=\"row\">\n                                <div class='col-md-12 form-group'>\n                                    <div class='graphic checkbox inline'>\n                                        <input id='monday' name='monday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"monday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":73},"end":{"line":54,"column":113}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label for='monday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"monday")), depth0))
    + "</label>\n                                    </div>\n                                    <div class='graphic checkbox inline'>\n                                        <input id='tuesday' name='tuesday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"tuesday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":75},"end":{"line":59,"column":116}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label for='tuesday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"tuesday")), depth0))
    + "</label>\n                                    </div>\n\n                                    <div class='graphic checkbox inline'>\n                                        <input id='wednesday' name='wednesday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"wednesday") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":79},"end":{"line":66,"column":51}}})) != null ? stack1 : "")
    + " type='checkbox' value='1'>\n                                        <label\n                                            for='wednesday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"wednesday")), depth0))
    + "</label>\n                                    </div>\n\n                                    <div class='graphic checkbox inline'>\n                                        <input id='thursday' name='thursday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"thursday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":77},"end":{"line":72,"column":119}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label\n                                            for='thursday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"thursday")), depth0))
    + "</label>\n                                    </div>\n\n                                    <div class='graphic checkbox inline'>\n                                        <input id='friday' name='friday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"friday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":73},"end":{"line":79,"column":113}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label for='friday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"friday")), depth0))
    + "</label>\n                                    </div>\n\n                                    <div class='graphic checkbox inline'>\n                                        <input id='saturday' name='saturday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"saturday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":77},"end":{"line":85,"column":119}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label\n                                            for='saturday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"saturday")), depth0))
    + "</label>\n                                    </div>\n\n                                    <div class='graphic checkbox inline'>\n                                        <input id='sunday' name='sunday' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"sunday") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":73},"end":{"line":92,"column":113}}})) != null ? stack1 : "")
    + "\n                                            type='checkbox' value='1'>\n                                        <label for='sunday'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"daysOfWeekShort"))) && lookupProperty(stack1,"sunday")), depth0))
    + "</label>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class=\"row\">\n                                <div class='col-md-12'>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowBetweenFrom")), depth0))
    + "\n                                    <select class='form-control input-mini' name='from'>\n                                        <option value=\"00:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"00:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":62},"end":{"line":102,"column":110}}})) != null ? stack1 : "")
    + ">00:00\n                                        </option>\n                                        <option value=\"01:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"01:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":62},"end":{"line":104,"column":110}}})) != null ? stack1 : "")
    + ">01:00\n                                        </option>\n                                        <option value=\"02:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"02:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":62},"end":{"line":106,"column":110}}})) != null ? stack1 : "")
    + ">02:00\n                                        </option>\n                                        <option value=\"03:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"03:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":62},"end":{"line":108,"column":110}}})) != null ? stack1 : "")
    + ">03:00\n                                        </option>\n                                        <option value=\"04:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"04:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":62},"end":{"line":110,"column":110}}})) != null ? stack1 : "")
    + ">04:00\n                                        </option>\n                                        <option value=\"05:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"05:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":62},"end":{"line":112,"column":110}}})) != null ? stack1 : "")
    + ">05:00\n                                        </option>\n                                        <option value=\"06:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"06:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":62},"end":{"line":114,"column":110}}})) != null ? stack1 : "")
    + ">06:00\n                                        </option>\n                                        <option value=\"07:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"07:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":62},"end":{"line":116,"column":110}}})) != null ? stack1 : "")
    + ">07:00\n                                        </option>\n                                        <option value=\"08:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"08:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":62},"end":{"line":118,"column":110}}})) != null ? stack1 : "")
    + ">08:00\n                                        </option>\n                                        <option value=\"09:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"09:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":62},"end":{"line":120,"column":110}}})) != null ? stack1 : "")
    + ">09:00\n                                        </option>\n                                        <option value=\"10:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"10:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":62},"end":{"line":122,"column":110}}})) != null ? stack1 : "")
    + ">10:00\n                                        </option>\n                                        <option value=\"11:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"11:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":62},"end":{"line":124,"column":110}}})) != null ? stack1 : "")
    + ">11:00\n                                        </option>\n                                        <option value=\"12:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"12:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":62},"end":{"line":126,"column":110}}})) != null ? stack1 : "")
    + ">12:00\n                                        </option>\n                                        <option value=\"13:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"13:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":62},"end":{"line":128,"column":110}}})) != null ? stack1 : "")
    + ">13:00\n                                        </option>\n                                        <option value=\"14:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"14:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":62},"end":{"line":130,"column":110}}})) != null ? stack1 : "")
    + ">14:00\n                                        </option>\n                                        <option value=\"15:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"15:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":62},"end":{"line":132,"column":110}}})) != null ? stack1 : "")
    + ">15:00\n                                        </option>\n                                        <option value=\"16:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"16:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":62},"end":{"line":134,"column":110}}})) != null ? stack1 : "")
    + ">16:00\n                                        </option>\n                                        <option value=\"17:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"17:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":62},"end":{"line":136,"column":110}}})) != null ? stack1 : "")
    + ">17:00\n                                        </option>\n                                        <option value=\"18:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"18:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":62},"end":{"line":138,"column":110}}})) != null ? stack1 : "")
    + ">18:00\n                                        </option>\n                                        <option value=\"19:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"19:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":62},"end":{"line":140,"column":110}}})) != null ? stack1 : "")
    + ">19:00\n                                        </option>\n                                        <option value=\"20:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"20:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":62},"end":{"line":142,"column":110}}})) != null ? stack1 : "")
    + ">20:00\n                                        </option>\n                                        <option value=\"21:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"21:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":62},"end":{"line":144,"column":110}}})) != null ? stack1 : "")
    + ">21:00\n                                        </option>\n                                        <option value=\"22:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"22:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":62},"end":{"line":146,"column":110}}})) != null ? stack1 : "")
    + ">22:00\n                                        </option>\n                                        <option value=\"23:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"from") : depth0),"23:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":62},"end":{"line":148,"column":110}}})) != null ? stack1 : "")
    + ">23:00\n                                        </option>\n                                    </select>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"delayWindowBetweenTo")), depth0))
    + "\n                                    <select class='form-control input-mini' name='to'>\n                                        <option value=\"00:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"00:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":62},"end":{"line":153,"column":108}}})) != null ? stack1 : "")
    + ">00:00\n                                        </option>\n                                        <option value=\"01:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"01:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":62},"end":{"line":155,"column":108}}})) != null ? stack1 : "")
    + ">01:00\n                                        </option>\n                                        <option value=\"02:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"02:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":62},"end":{"line":157,"column":108}}})) != null ? stack1 : "")
    + ">02:00\n                                        </option>\n                                        <option value=\"03:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"03:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":62},"end":{"line":159,"column":108}}})) != null ? stack1 : "")
    + ">03:00\n                                        </option>\n                                        <option value=\"04:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"04:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":62},"end":{"line":161,"column":108}}})) != null ? stack1 : "")
    + ">04:00\n                                        </option>\n                                        <option value=\"05:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"05:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":62},"end":{"line":163,"column":108}}})) != null ? stack1 : "")
    + ">05:00\n                                        </option>\n                                        <option value=\"06:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"06:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":62},"end":{"line":165,"column":108}}})) != null ? stack1 : "")
    + ">06:00\n                                        </option>\n                                        <option value=\"07:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"07:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":62},"end":{"line":167,"column":108}}})) != null ? stack1 : "")
    + ">07:00\n                                        </option>\n                                        <option value=\"08:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"08:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":169,"column":62},"end":{"line":169,"column":108}}})) != null ? stack1 : "")
    + ">08:00\n                                        </option>\n                                        <option value=\"09:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"09:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":62},"end":{"line":171,"column":108}}})) != null ? stack1 : "")
    + ">09:00\n                                        </option>\n                                        <option value=\"10:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"10:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":62},"end":{"line":173,"column":108}}})) != null ? stack1 : "")
    + ">10:00\n                                        </option>\n                                        <option value=\"11:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"11:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":62},"end":{"line":175,"column":108}}})) != null ? stack1 : "")
    + ">11:00\n                                        </option>\n                                        <option value=\"12:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"12:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":62},"end":{"line":177,"column":108}}})) != null ? stack1 : "")
    + ">12:00\n                                        </option>\n                                        <option value=\"13:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"13:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":62},"end":{"line":179,"column":108}}})) != null ? stack1 : "")
    + ">13:00\n                                        </option>\n                                        <option value=\"14:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"14:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":62},"end":{"line":181,"column":108}}})) != null ? stack1 : "")
    + ">14:00\n                                        </option>\n                                        <option value=\"15:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"15:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":62},"end":{"line":183,"column":108}}})) != null ? stack1 : "")
    + ">15:00\n                                        </option>\n                                        <option value=\"16:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"16:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":62},"end":{"line":185,"column":108}}})) != null ? stack1 : "")
    + ">16:00\n                                        </option>\n                                        <option value=\"17:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"17:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":187,"column":62},"end":{"line":187,"column":108}}})) != null ? stack1 : "")
    + ">17:00\n                                        </option>\n                                        <option value=\"18:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"18:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":62},"end":{"line":189,"column":108}}})) != null ? stack1 : "")
    + ">18:00\n                                        </option>\n                                        <option value=\"19:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"19:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":62},"end":{"line":191,"column":108}}})) != null ? stack1 : "")
    + ">19:00\n                                        </option>\n                                        <option value=\"20:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"20:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":62},"end":{"line":193,"column":108}}})) != null ? stack1 : "")
    + ">20:00\n                                        </option>\n                                        <option value=\"21:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"21:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":62},"end":{"line":195,"column":108}}})) != null ? stack1 : "")
    + ">21:00\n                                        </option>\n                                        <option value=\"22:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"22:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":62},"end":{"line":197,"column":108}}})) != null ? stack1 : "")
    + ">22:00\n                                        </option>\n                                        <option value=\"23:00\" "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"to") : depth0),"23:00",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":62},"end":{"line":199,"column":108}}})) != null ? stack1 : "")
    + ">23:00\n                                        </option>\n                                    </select>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});