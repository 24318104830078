<template>
  <div ref="templateCatalogRef" class="h-full w-full overflow-y-auto py-4">
    <div class="flex justify-center">
      <div
        v-show="!skeleton"
        class="mx-auto grid grid-cols-1 gap-8 gap-x-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
      >
        <Card
          v-for="campaign in campaigns"
          :key="campaign.id"
          :campaign="campaign"
          :selected="isSelected(campaign)"
          :allow="{
            link: campaignsUpdate && selected.length === 0,
            select: campaignsUpdate,
          }"
          :show="{
            info: isSelected(campaign),
          }"
          @select="toggleCampaign"
          @toggle-selected="() => toggleCampaign(campaign)"
        />
      </div>
      <div
        v-show="skeleton"
        class="mx-auto grid grid-cols-1 gap-8 gap-x-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
      >
        <div v-for="index in 12" :key="index" class="h-[404px] w-[250px] animate-pulse rounded-xl bg-gray-100 py-4" />
      </div>
    </div>
    <LoadingSpinner v-show="!skeleton && loading" class="mx-auto my-24 h-5 w-5 text-sky-400" />
  </div>
</template>

<script lang="ts" setup>
import { ref, toRaw } from "vue";

// Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import Card from "./components/Card.vue";

//Utils
import { useInfiniteScroll } from "@vueuse/core";
import { useMagicKeys } from "@vueuse/core";

// Store
import { useSessionStore } from "@store";

// Types
import { Campaigns, Campaign } from "@/vue/types/campaigns";

const { shift } = useMagicKeys();

const sessionStore = useSessionStore();
const campaignsUpdate = sessionStore.hasPermission("campaigns:update");

const props = withDefaults(
  defineProps<{
    campaigns: Campaigns;
    selected: Campaigns;
    loading?: boolean;
    skeleton?: boolean;
  }>(),
  {
    campaigns: () => [],
    loading: false,
    skeleton: false,
  },
);

const emit = defineEmits<{
  selectCampaign: [Campaign];
  removeSelectedCampaign: [Campaign];
  updateSelected: [Campaigns];
  fetchCampaigns: [void];
}>();

const fetchCampaigns = () => emit("fetchCampaigns");

const isSelected = (campaign: Campaign) => {
  return props.selected?.some((s) => s.id === campaign.id);
};

const lastCampaignSelected = ref<Campaign>();
const handleSelectCampaign = (campaign: Campaign) => {
  if (isSelected(campaign)) {
    return emit("removeSelectedCampaign", campaign);
  }
  emit("selectCampaign", campaign);
};

const toggleCampaign = (campaign: Campaign) => {
  const lastSelected = lastCampaignSelected.value;
  lastCampaignSelected.value = campaign;

  if (shift.value && lastSelected) {
    const lastRowIndex = props.campaigns.findIndex((c) => c.id === lastSelected.id);
    const currentRowIndex = props.campaigns.findIndex((c) => c.id === campaign.id);
    if (lastRowIndex === -1 || currentRowIndex === -1 || currentRowIndex === lastRowIndex) {
      handleSelectCampaign(campaign);
      return;
    }

    const uniqueIds = new Set<string>();

    const [start, end] = [lastRowIndex, currentRowIndex].sort((a, b) => a - b);
    const campaignsBetween = props.campaigns.slice(start, end + 1);

    const selectedCampaigns = [...toRaw(props.selected), ...toRaw(campaignsBetween)];

    const filteredSelected = selectedCampaigns.filter((c) => {
      if (!uniqueIds.has(c.id.toString())) {
        uniqueIds.add(c.id.toString());
        return true;
      }
      return false;
    });

    emit("updateSelected", filteredSelected);
  } else {
    handleSelectCampaign(campaign);
  }
};

const templateCatalogRef = ref();
useInfiniteScroll(templateCatalogRef, fetchCampaigns, { distance: 900 });
</script>
