<template>
  <DropDownButton
    :navigation="categories"
    theme="transparent-white"
    position="bottom-end"
    :auto-placements="['bottom-end', 'left']"
    @update:selected="select"
  />
</template>
<script lang="ts" setup>
import { ref, markRaw } from "vue";

// Components
import DropDownButton from "@molecules/DropDownButton";

// Icons
import { TrashIcon } from "@heroicons/vue/outline";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// Utils
import { useI18n } from "vue-i18n";

// Type
import type { Categories, DataItem } from "@molecules/DropDownButton";
import type { TemplateItem } from "@domain/Templates";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    template: TemplateItem;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: "duplicate", template: TemplateItem): void;
  (e: "editTags", template: TemplateItem): void;
  (e: "delete", template: TemplateItem): void;
}>();

const categories = ref<Categories>({
  1: [
    {
      key: "duplicate",
      icon: markRaw(ClipboardDocumentListIcon),
      value: t("categories.duplicate"),
    },
    // {
    //   key: "editTags",
    //   icon: TagIcon,
    //   value: t("categories.editTags"),
    // },
  ],
  2: [
    {
      key: "delete",
      icon: TrashIcon,
      value: t("categories.delete"),
      theme: "red",
    },
  ],
});

const select = (option: DataItem) => {
  if (option.key === "duplicate") {
    emit("duplicate", props.template);
    return;
  }
  if (option.key === "editTags") {
    emit("editTags", props.template);
    return;
  }
  if (option.key === "delete") {
    emit("delete", props.template);
    return;
  }
};
</script>
<i18n lang="json">
{
  "es": {
    "categories": {
      "duplicate": "Duplicar",
      "editTags": "Editar etiquetas",
      "delete": "Eliminar"
    }
  },
  "pt": {
    "categories": {
      "duplicate": "Duplicar",
      "editTags": "Editar etiqueta",
      "delete": "Excluir"
    }
  }
}
</i18n>
