import { reactive, ref, watch, toRaw, computed } from "vue";
import { defineStore } from "pinia";

import { isEqual } from "lodash";

import { useDebounceFn } from "@vueuse/core";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Application
import { useLocalStorageApp } from "@application";

// Types
import { BatchType } from "@domain/dashboard/dashboard";

//API
import { useUserConfig } from "@api/modules/accountuser";

export interface DashboardState {
  currentPeriodPresetID?: string | undefined;
  emailBatchType: BatchType;
  chartSales: {
    selection?: "amount" | "count" | undefined;
    chartType?: "column" | "spline" | undefined;
    compare?: boolean | undefined;
  };
  chartEmails: {
    selection?: "sent" | "opened" | "clicked" | "openedP" | "clickedP" | undefined;
    selectionP?: "number" | "percent" | undefined;
    chartType?: "column" | "spline" | undefined;
    compare?: boolean | undefined;
  };
  chartContacts: {
    selection?: "all" | "new" | undefined;
  };
}

export interface CustomField {
  id: number;
  name: string;
  format: string;
  value: string; //Boolean en Mayúscula
  tag: string;
}

export interface TemplatesEditorConfig {
  monitoringOptions: {
    monitorOpenings?: boolean;
    monitorClicks?: boolean;
    trackAnalytics?: boolean;
  };
}

export const useUserConfigStore = defineStore("userConfig", () => {
  const localStorageApp = useLocalStorageApp();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);

  //Const
  const MAX_RECENT_CONTACTS = 5;

  //State
  const dashBoardState = ref<DashboardState>({
    currentPeriodPresetID: undefined,
    emailBatchType: "all",
    chartSales: {},
    chartEmails: {},
    chartContacts: {},
  });
  const recentContacts = reactive<Set<number>>(new Set<number>());
  const templatesEditorConfig = ref<TemplatesEditorConfig>({
    monitoringOptions: {
      monitorClicks: true,
      monitorOpenings: true,
      trackAnalytics: true,
    },
  });

  //Flags
  const firstLoadReady = ref(false);
  const firstLoad = ref(true);

  const AccountConfig = useUserConfig();
  const actualUserID = computed(() => session?.value?.account?.username ?? "");

  const getUserConfig = async () => {
    if (session.value?.isMasterUser) return;

    const savedState = await AccountConfig.get();

    localStorageApp.save({
      id: "userConfig",
      value: savedState,
    });

    if (savedState?.[actualUserID.value]) {
      dashBoardState.value = savedState?.[actualUserID.value]?.dashboardState ?? dashBoardState.value;

      templatesEditorConfig.value ??=
        savedState?.[actualUserID.value]?.templatesEditorConfig ?? templatesEditorConfig.value;
      savedState?.[actualUserID.value]?.recentContacts?.forEach((contact) => {
        recentContacts.add(contact);
      });
    }
    firstLoadReady.value = true;
  };

  const saveConfigFn = useDebounceFn(
    async ({
      dashboardState,
      templatesEditorConfig,
    }: {
      dashboardState: DashboardState;
      templatesEditorConfig: TemplatesEditorConfig;
    }) => {
      const newUserConfig = await AccountConfig.put({
        data: {
          [actualUserID.value]: {
            dashboardState: dashboardState,
            templatesEditorConfig: templatesEditorConfig,
            recentContacts: [...recentContacts],
          },
        },
      });

      localStorageApp.save({
        id: "userConfig",
        value: newUserConfig,
      });
    },
    1000,
    { maxWait: 3000 },
  );

  const saveRecentContacts = async (newRecentContacts: Array<number>) => {
    await AccountConfig.put({
      data: {
        [actualUserID.value]: {
          dashboardState: dashBoardState.value,
          templatesEditorConfig: templatesEditorConfig.value,
          recentContacts: newRecentContacts,
        },
      },
    });
  };

  watch(
    [dashBoardState, templatesEditorConfig],
    ([dashboardConfig, templatesConfig], [oldDashboardConfig, oldTempltesConfig]) => {
      const areChanges =
        !isEqual(toRaw(dashboardConfig), toRaw(oldDashboardConfig)) ||
        !isEqual(toRaw(templatesConfig), toRaw(oldTempltesConfig));

      !firstLoad.value &&
        areChanges &&
        !session.value?.isMasterUser &&
        saveConfigFn({ dashboardState: dashboardConfig, templatesEditorConfig: templatesConfig });

      if (firstLoadReady.value) {
        firstLoad.value = false;
      }
    },
  );

  watch(
    recentContacts,
    (value) => {
      const values = [...value].slice(-MAX_RECENT_CONTACTS);

      !firstLoad.value && !session.value?.isMasterUser && saveRecentContacts(values);
    },
    { deep: true },
  );

  return { dashBoardState, templatesEditorConfig, recentContacts, getUserConfig };
});
