import { useRequestsAPIV3 } from "@api/requests";

// Types
import {
  LifecycleListRequest,
  LifecycleStatsListAPIResponse,
  LifecycleDefinitionListAPIResponse,
} from "./lifecycles.types";

import type { Stats, Definitions } from "@/vue/types/lifecycle";

export function useLifecycle() {
  const request = useRequestsAPIV3();

  async function stats(type: LifecycleListRequest): Promise<Stats> {
    const res = await request<LifecycleStatsListAPIResponse>({
      url: `/lifecycles/${type}/stats`,
      method: "get",
    });

    return res.data.data;
  }

  async function definitions(type: LifecycleListRequest): Promise<Definitions> {
    const res = await request<LifecycleDefinitionListAPIResponse>({
      url: `/lifecycles/${type}`,
      method: "get",
    });
    return res.data.data;
  }

  async function activate(type: LifecycleListRequest): Promise<void> {
    await request<LifecycleDefinitionListAPIResponse>({
      url: `/lifecycles/${type}/create`,
      method: "post",
    });
  }

  return { stats, definitions, activate };
}
