<template>
  <SubjectInput v-model="internalSubject" v-bind="$attrs" :fields="fields" />
</template>

<script>
import SubjectInput from "@/components/vue/SubjectInput";

export default {
  components: { SubjectInput },
  props: {
    subject: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      internalSubject: this.subject,
    };
  },
};
</script>
