import type { Tasks, RewardId, TaskIds } from "@domain/onboarding";
import type { AutomationType } from "@domain/automations";

export type UserActivityAction =
  | "automation.created"
  | "automation.enabled"
  | `automation.${AutomationType}.created`
  | `automation.${AutomationType}.enabled`
  | "campaign.created"
  | "campaign.sent"
  | "list.imported"
  | "optin.enabled"
  | `onboarding.${TaskIds}.omitted`
  | "onboarding.first.state"
  | `reward.${RewardId}.offered`
  | `reward.${RewardId}.claimed`
  | `account.created`;

export const isOmittedEvent = (action: UserActivityAction): action is `onboarding.${TaskIds}.omitted` => {
  return action.includes(".omitted");
};

export const getTaskIdFromOmmittedEvent = (action: `onboarding.${TaskIds}.omitted`): TaskIds => {
  const startIndex = action.indexOf("onboarding.") + "onboarding.".length;
  const dotIndex = action.indexOf(".", startIndex);
  return action.substring(startIndex, dotIndex) as TaskIds;
};

export const isRewardEvent = (
  action: UserActivityAction,
): action is `reward.${RewardId}.offered` | `reward.${RewardId}.claimed` => {
  return action.includes("reward");
};

export const getRewardIdFromEventAction = (
  action: `reward.${RewardId}.offered` | `reward.${RewardId}.claimed`,
): RewardId => {
  const startIndex = action.indexOf("reward.") + "reward.".length;
  const dotIndex = action.indexOf(".", startIndex);
  return action.substring(startIndex, dotIndex) as RewardId;
};

export const isRewardClaimedEvent = (
  action: `reward.${RewardId}.offered` | `reward.${RewardId}.claimed`,
): action is `reward.${RewardId}.claimed` => {
  return isRewardEvent(action) && action.includes("claimed");
};

export const isRewardOfferedEvent = (
  action: `reward.${RewardId}.offered` | `reward.${RewardId}.claimed`,
): action is `reward.${RewardId}.offered` => {
  return isRewardEvent(action) && action.includes("offered");
};

export interface UserActivityData {
  id: string;
  name: string;
}

export type UserActivityOnboardingData = {
  tasks: Tasks;
};

export interface UserActivityBase {
  id: `usract_${string}`;
  account: string; // "cuenta",
  user: string;
  action: UserActivityAction;
  created: DateString; // Instant
}

export interface UserActivityDefault extends UserActivityBase {
  id: `usract_${string}`;
  account: string; // "cuenta",
  user: string;
  action: UserActivityAction;
  created: DateString; // Instant
  data: UserActivityData;
}

export interface UserActivityOnboardingState extends UserActivityBase {
  action: "onboarding.first.state";
  data: UserActivityOnboardingData;
}

export type UserActivity = UserActivityDefault | UserActivityOnboardingState;

export const isUserActivityOnboardingState = (activity: UserActivity): activity is UserActivityOnboardingState => {
  return activity.action === "onboarding.first.state";
};
