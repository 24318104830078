var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog big'>\n    <div class='modal-content'>\n        <div class='modal-header'>\n            <button type='button' class='close' data-dismiss='modal'>\n                <span aria-hidden='true'>\n                    &times;\n                </span>\n            </button>\n            <h4 class='modal-title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"createContent")), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentTemplate")), depth0))
    + "\n            </h4>\n            <form data-form='search-folder' class='modal-search'>\n                <input type='hidden' name='location'>\n                <input type='hidden' name='type' value=\"\">\n                <input class='form-control' type='text' name='client' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "'\n                    data-input=\"search-folder\">\n            </form>\n        </div>\n        <div class='modal-body scrollbar'>\n            <div class='modal-body-padded'>\n                <div data-role='folder-list'></div>\n            </div>\n        </div>\n        <div class='modal-footer'>\n            <button type='button' class='btn btn-link' data-dismiss='modal'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});