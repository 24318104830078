<template>
  <TextParagraph>
    <i18n-t keypath="instructions">
      <template #email>
        <strong>{{ sender.email }}</strong>
      </template>
    </i18n-t>
  </TextParagraph>
  <TextParagraph>{{ t("example") }} </TextParagraph>
  <AlertBox>
    <i18n-t keypath="usage">
      <template #codesample1>
        <code>${sender.{{ t("sampleKey1") }}}</code>
      </template>
      <template #codesample2>
        <code>${sender.{{ t("sampleKey2") }}}</code>
      </template>
      <template #defaultkeys>
        <code>name</code>, <code>email</code> y
        <code>reply_to</code>
      </template>
    </i18n-t>

    <template #actions>
      <i18n-t keypath="moreInfo">
        <template #helpcenter>
          <a href="https://docs.myperfit.com/articles/5386059" target="_blank">
            {{ t("helpCenter") }}
          </a>
        </template>
      </i18n-t>
    </template>
  </AlertBox>

  <div class="mt-6 grid w-full grid-cols-4 gap-4">
    <template v-for="(entry, index) in details" :key="entry">
      <div class="">
        <SimpleInput
          v-model.trim.lowercase="entry[0]"
          maxlength="30"
          :placeholder="t('keyPlaceholder')"
          :has-error="
            duplicatedKeys.includes(entry[0]) || invalidKeys.includes(entry[0]) || reservedKeys.includes(entry[0])
          "
        />
      </div>
      <div class="col-span-3">
        <div class="flex w-full items-center">
          <div class="flex-grow">
            <SimpleInput v-model.trim="entry[1]" maxlength="500" :placeholder="t('valuePlaceholder')" />
          </div>
          <IconButton theme="danger" class="ml-2" :label="t('delete')" @click="remove(index)">
            <TrashIcon />
          </IconButton>
        </div>
      </div>
    </template>
  </div>
  <AlertBox v-if="duplicatedKeys.length > 0" theme="danger" class="mt-4">
    {{ t("errors.duplicatedKeys") }}
  </AlertBox>
  <AlertBox v-else-if="invalidKeys.length > 0" theme="danger" class="mt-4">
    {{ t("errors.invalidKeys") }}
  </AlertBox>
  <AlertBox v-else-if="reservedKeys.length > 0" theme="danger" class="mt-4">
    {{ t("errors.reservedKeys") }}
  </AlertBox>

  <div class="mt-6 flex justify-between space-x-4">
    <SimpleButton
      theme="white"
      :loading="isSaving"
      :disabled="duplicatedKeys.length > 0 || invalidKeys.length > 0 || reservedKeys.length > 0"
      @click="save"
    >
      <template #leading><ChevronLeftIcon /></template>
      {{ t("saveAndBack") }}
    </SimpleButton>
    <SimpleButton theme="secondary" @click="details.push(['', ''])">
      <template #leading><PlusCircleIcon /></template>
      {{ t("addNew") }}
    </SimpleButton>
  </div>
</template>

<script lang="ts">
import { reactive, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { TrashIcon } from "@heroicons/vue/outline";
import { PlusCircleIcon, ChevronLeftIcon } from "@heroicons/vue/solid";

import { useNotifications } from "@composables/notifications";
import { useSenders } from "@api/modules/senders/senders";
import { useAlertStore } from "@store";
import { SenderDetails } from "@/vue/types/Senders";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import AlertBox from "@atoms/AlertBox.vue";
import IconButton from "@atoms/IconButton.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

export default {
  name: "SenderDetails",
  components: {
    TextParagraph,
    SimpleInput,
    AlertBox,
    IconButton,
    SimpleButton,
    TrashIcon,
    PlusCircleIcon,
    ChevronLeftIcon,
  },
  props: {
    sender: {
      type: Object,
      required: true,
    },
  },
  emits: ["back"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { notify } = useNotifications();

    const details: [string, string][] = reactive(Object.entries(props.sender.details || {}));

    const isObject = (obj: any) => obj === Object(obj);

    for (let i = 0; i < details.length; i++) {
      if (isObject(details[i][1])) {
        details[i][1] = JSON.stringify(details[i][1]);
      }
    }
    if (details.length === 0) {
      details.push(["", ""]);
    }

    const isSaving = ref(false);
    const sendersAPI = useSenders();
    const alertStore = useAlertStore();

    const save = async () => {
      const modifiedDetails: SenderDetails = {};
      for (const entry of details) {
        if (entry[0].trim() !== "") {
          let value = entry[1];
          try {
            value = JSON.parse(value);
            // eslint-disable-next-line no-empty
          } catch (err) {}
          modifiedDetails[entry[0]] = value;
        }
      }

      if (JSON.stringify(modifiedDetails) !== JSON.stringify(props.sender.details)) {
        try {
          isSaving.value = true;
          const updatedSender = await sendersAPI.modify({
            id: props.sender.id,
            details: modifiedDetails,
          });
          await alertStore.update();
          isSaving.value = false;

          notify({ title: t("detailsUpdated") });
          emit("back", updatedSender.details);
        } catch (err) {
          console.error(err);
        }
      } else {
        emit("back");
      }
    };

    const remove = (index: number) => {
      details.splice(index, 1);
    };

    const duplicatedKeys = computed(() => {
      const dups: string[] = [];
      const keySet = new Set();
      details
        .map((e) => e[0])
        .filter((k) => !!k)
        .forEach((k) => {
          if (keySet.has(k)) dups.push(k);
          keySet.add(k);
        });
      return dups;
    });

    const invalidKeys = computed(() => {
      return details
        .map((e) => e[0])
        .filter((k) => !!k)
        .filter((k) => k !== k.replaceAll(/[^A-Za-z0-9_]/g, "") || !k.charAt(0).match(/[A-Za-z]/));
    });

    const reservedKeys = computed(() => {
      return details
        .map((e) => e[0])
        .filter((k) => !!k)
        .filter((k) => ["name", "email", "reply_to"].includes(k));
    });

    return { t, save, details, remove, isSaving, duplicatedKeys, invalidKeys, reservedKeys };
  },
};
</script>

<style scoped>
code {
  @apply font-semibold;
}
</style>

<i18n>
{
  "es":{
    "instructions": "Puedes definir un conjunto de datos asociados al remitente {email}, y utilizarlos dentro del contenido de tus emails.",
    "example": "Utiliza estas variables para personalizar el contenido de tus emails según el remitente seleccionado. Por ejemplo, podrías personalizar los datos de una firma o el pie de tus contenidos.",
    "usage": "Para insertarlos usa este código: {codesample1}. Por ejemplo, si creas la variable ”telefono”, puedes insertarla con el código {codesample2}. Además, cada remitente ya cuenta por defecto con las variables {defaultkeys}.",
    "sampleKey1": "nombre_variable",
    "sampleKey2": "telefono",
    "moreInfo": "Para conocer más sobre las variables de remitentes visita el {helpcenter}.",
    "helpCenter": "centro de ayuda",
    "keyPlaceholder": "nombre_de_variable",
    "valuePlaceholder": "valor para la variable",
    "addNew": "Agregar nueva variable",
    "saveAndBack": "Guardar y volver",
    "errors": {
      "duplicatedKeys": "Los nombres de variables deben ser únicos. Por favor corrige los nombres duplicados para poder guardar los cambios.",
      "invalidKeys": "Utiliza sólo letras, números y _ en los nombres de las variables. Además, siempre debe comenzar con una letra.",
      "reservedKeys": "Los nombres 'email', 'name' y 'reply_to' están reservadas para los detalles por defecto del remitente, por favor usa otros nombres."
    },
    "detailsUpdated": "Detalles del remitente actualizados"
  },
  "pt":{
    "instructions": "Você pode definir um conjunto de informações associadas ao remetente {email}, e usá-las no conteúdo dos seus emails.",
    "example": "Uses essas informações para personalizar o conteúdo dos teus emails dependendo do remetente selecionado. Por exemplo, você pode personalizar as informações de uma assinatura para usar no final dos seus emails.",
    "usage": "Para inserir essas informações use esse código: {codesample1}. Por exemplo, se você criar a variável telefone, pode inserir o código {codesample2}. Além disso, cada remetente já esta pré-configurado com as variáveis {defaultkeys}.",
    "sampleKey1": "nome_variável",
    "sampleKey2": "telefono",
    "moreInfo": "Para conhecer mais sobre as variáveis de cada remetente visite nosso {helpcenter}.",
    "helpCenter": "centro de ajuda",
    "keyPlaceholder": "nome_da_variavel",
    "valuePlaceholder": "valor para a variável",
    "addNew": "Adicionar uma nova variável",
    "saveAndBack": "Salvar e voltar",
    "errors": {
      "duplicatedKeys": "Os nomes das variáveis devem ser únicos. Por favor, modifique os nomes duplicados para poder salvas as alterações.",
      "invalidKeys": "Utilize somente letras, números e _ nos nomes das variáveis. Lembre-se que sempre devem começar com uma letra.",
      "reservedKeys": "Os nomes 'email', 'name' e 'reply_to' estão reservados para os detalhes pré-definidos, por favor use outros nomes."
    },
    "detailsUpdated": "Informações do remetente atualizadas"
  }
}
</i18n>
