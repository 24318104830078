/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { _ } from '@/helpers/utils'
import app from '@/app';
import Handlebars from 'handlebars'
// Editor

function Eddie(options) {

  "use strict";

  var self = this,
    defaults = {

      // User's token
      token: false,

      // Plugin path
      pluginPath: '/plugins/eddie',

      // Language
      lang: 'es',

      // Lowercase tags
      useLowercaseTags: false,

      // Show button to add conditional regions
      conditionalRegions: false,

      // Default content
      content: false,

      // Show or hide tutorial
      tutorial: true,

      // Title
      title: '',

      // API connections
      api: {
        components: (lang) => `/plugins/eddie/components/${lang}.json?_=${Date.now()}`,
        fields: 'fields',
        templates: 'shared/templates',
        templateInfo: 'shared/templates',
        imageList: 'images',
        publicImageList: 'shared/images',
        imageImporter: 'images',
        imageUploader: 'images',
        productsList: 'products',
        designsList: 'templates',
        interestsList: 'interests',
        videoThumbnail: 'https://www.myperfit.com/play.php?video='
      },

      // Path for public backgounds
      publicBackgroundsPath: '/',

      // Show dynamicProducts regions
      showDynamicProducts: false,

      // Allow upload of files
      allowFileUpload: false,

      // Callback to be executed when done
      callback: function () {
        return true;
      },

      // Callback to be executed when initiliazed
      initCallback: function () {
        return true;
      },

      // Callback to be executed in intervals
      intervalCallback: function () {
        return true;
      },

      // Callback to be executed when cancelled
      cancelCallback: function () {
        return true;
      }
    },
    defaultContent = {
      regions: [],
      settings: {
        width: 660,
        emailBackgroundColor: '#DDD',
        backgroundImage: 'none'
      }
    };

  this.options = $.extend({}, defaults, options);

  this.editingSettings = false;

  this.editingRegion = false;

  this.backupContent = [];

  this.backupStep = 0;

  this.autosaveHash = '';

  this.shuldClose = false;

  this.cleanUp = [
    'data-ref',
    'data-region',
    'data-cell',
    'data-element',
    'data-type',
    'data-editable',
    'link',
    'linkcolor',
    'html',
    'id',
    'contenteditable',
    '_moz_dirty'
  ];

  /* Defaults for all regions and elements */

  this.defaults = {
    table: {
      attr: {
        width: '100%',
        cellspacing: 0,
        cellpadding: 0,
        border: 0
      },
      style: {
        tableLayout: 'fixed'
      }
    },
    region: {
      style: {
        backgroundColor: '#FFFFFF'
      },
      attr: {
      }
    },
    cell: {
      attr: {
        width: '100%',
        valign: 'top'
      },
      style: {
        overflow: 'hidden'
      }
    },
    element: {
      attr: {
        linkcolor: '#0000EE'
      },
      style: {
        borderColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 0,
        color: '#000000',
        display: 'block',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 1.35,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        maxHeight: 'none',
        maxWidth: 'none',
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        textDecoration: 'none'
      }
    }
  };

  /* Deferreds */

  this.deferreds = {
    getComponents: function () {
      return $.ajax({
        cache: false,
        url: self.options.api.components(self.options.lang),
        data: {
          token: self.options.token
        },
        success: function (response) {
          self.components = response;
        },
        timeout: 20000
      });
    },
    getLanguage: function () {
      return $.ajax({
        cache: false,
        url: self.options.pluginPath + '/lang/' + self.options.lang + '.json',
        success: function (response) {
          self.lang = response;
        }
      });
    },
    getEditorHtml: function () {
      return $.ajax({
        cache: false,
        url: self.options.pluginPath + '/eddie.html',
        dataType: 'text',
        success: function (response) {
          self.editorHtml = response;
        }
      });
    },
    getFields: function () {
      return $.ajax({
        cache: false,
        url: self.options.api.fields,
        data: {
          token: self.options.token,
          limit: 1000
        },
        success: function (response) {
          self.fields = response.data;
        },
        error: function () {
          self.fields = [];
        }
      });
    },
    getProducts: function () {
      return $.ajax({
        cache: false,
        url: self.options.api.productsList,
        data: {
          token: self.options.token,
          limit: 30
        },
        success: function (response) {
          self.initialProducts = response;
          self.hasProducts = response.paging.total;
        },
        error: function () {
          self.hasProducts = false;
        }
      });
    },
    getInterests: function () {
      return $.ajax({
        cache: false,
        url: self.options.api.interestsList,
        data: {
          token: self.options.token,
          limit: 500
        },
        success: function (response) {
          self.interests = response.data;
          self.hasInterests = response.paging.total;
          self.interestsMap = {};
          $.each(self.interests, function (index, interest) {
            self.interestsMap[interest.id] = interest.name;
          });
        },
        error: function () {
          self.hasInterests = false;
        }
      });
    },
    getTemplates: function () {
      return $.ajax({
        cache: false,
        url: self.options.api.templates,
        data: {
          token: self.options.token,
          limit: 500,
          filter: {
            type: 'eddie'
          }
        },
        success: function (response) {
          var emptyIndex = -1,
            emptyTemplate;
          $.each(response.data, function (index, template) {
            if (template.id === '/empty') {
              emptyIndex = index;
            }
          });
          if (emptyIndex > -1) {
            emptyTemplate = $.extend(true, {}, response.data[emptyIndex]);
            response.data.splice(emptyIndex, 1);
            response.data.unshift(emptyTemplate);
          }
          self.templates = response.data;
        }
      });
    }
  };

  /**
   * Initializes the plugin
   */
  this.init = function () {
    var deferreds = [
      self.deferreds.getComponents(),
      self.deferreds.getLanguage(),
      self.deferreds.getEditorHtml(),
      self.deferreds.getFields(),
      self.deferreds.getTemplates(),
      self.deferreds.getInterests(),
    ];

    // Process all deferreds
    self.deferreds.getProducts().always(function () {
      $.when.apply(null, deferreds).done(function () {
        self.renderEditor();

        // Start autosave
        self.autosave();
      });
    });

    // Set initial configuration
    self.importing = 'template';
  };

  /**
   * Renders the editor
   */
  this.renderEditor = function () {
    var toolbar,
      height,
      regionToolbar,
      regionToolbarHeight,
      html,
      editor;
    try {
      document.execCommand('styleWithCSS', false, false);
    } catch (e) {
      document.execCommand('useCSS', false, false);
    }
    self.target = $('<div>').appendTo('body');
    html = self.template(self.editorHtml, self.lang);
    self.target.html(html);
    self.applyProductButton = self.findByData('action', 'openProductsModal');
    self.removeProductButton = self.findByData('action', 'removeProduct');
    if (!self.hasProducts) {
      self.applyProductButton.remove();
      self.removeProductButton.remove();
      self.findByData('tag', 'products').remove();
    }
    if (!self.options.showDynamicProducts) {
      self.findByData('tag', 'productsDynamic').remove();
    }
    toolbar = self.target.find('.main-toolbar');
    height = toolbar.find(['data-control']).outerHeight() + 20;
    toolbar.css('height', height);
    regionToolbar = self.findByData('toolbar', 'region');
    if (!self.options.conditionalRegions) {
      regionToolbar.find('[data-action=setCondition]').remove();
    }
    regionToolbarHeight = regionToolbar.height();
    self.details = {
      toolbarHeight: height,
      regionToolbarHeight: regionToolbarHeight
    };
    self.closed = false;
    self.isFetching = false;
    self.content = $.extend(true, {}, defaultContent, options.content);
    self.render();
    self.bind();
    self.bindShortcuts();
    self.unselectElement();
    self.createColorPalette();
    self.createKeywordsList();
    self.createdesignsList();
    self.createTemplateTagsList();
    self.createElementsLists();
    self.createRegionsLists();
    self.preventNavigation();
    //self.bindEmojiPicker();
    self.options.initCallback();
    if (self.content.regions.length) {
      self.target.find('.eddie').removeClass('tutorial');
      self.saveBackup();
    } else {
      if (self.options.tutorial) {
        self.showTutorialStep(1);
      } else {
        self.target.find('.eddie').removeClass('tutorial');
        self.openTemplatesModal();
      }
    }
  };

  /**
   * Bind emoji picker
   */
  this.bindEmojiPicker = function () {
    var self = this;
    this.emojipicker = new window.EmojiPicker({
      template: document.getElementById('emojipicker-template').innerHTML,
      trigger: function () {
        return document.getElementById('emoji-picker-eddie');
      },
      target: function () {
        return self.findElement(self.selected);
      },
      callback: function () {
        self.findSelectedElement().trigger('keyup');
        self.saveBackup();
      }
    });
  };

  /**
   * Autosave
   */
  this.autosave = function () {
    this.timer = setTimeout(function () {
      var hash = window.md5(this.html());
      if (this.autosaveHash !== hash) {
        this.options.intervalCallback(this, this.html(), this.json());
        this.autosaveHash = hash;
      }
      this.autosave();
    }.bind(this), 10 * 1000);
  };

  /**
   * Prevents the navigation when the editor is open
   * @return {Object}
   */
  this.preventNavigation = function () {
    window.onbeforeunload = function (e) {
      return '';
    };
  };

  /**
   * Finds an element in the target scope
   * @param {String} data
   * @param {String} value
   * @return {Object}
   */
  this.findByData = function (data, value) {
    var search = data;
    if (value !== undefined) {
      search = search + '=' + value;
    }
    return self.target.find('[data-' + search + ']');
  };

  /**
   * Binds events to the target scope
   */
  this.bind = function () {
    var events = [
      {
        // Prevent undesired navigation
        action: 'click',
        element: 'a:not(#help-center-migrate-link)',
        callback: function (e) {
          var href = $(this).attr('href');
          if (href && !self.stringStartsWith(href, ['blob'])) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      },
      {
        // Prevent undesired drag and drop
        action: 'dragstart dragover dragenter',
        element: '*',
        callback: function (e) {
          e.stopPropagation();
          e.preventDefault();
          return false;
        }
      },
      {
        // Opens a designs folder
        action: 'mousedown',
        element: '[data-action=explore-folders]',
        callback: function (e) {
          var path = $(this).data('folder-path') || null,
            name = $(this).data('name') || null;
          e.preventDefault();
          e.stopPropagation();
          self.exploreFolders(path, name);
        }
      },
      {
        // Opens a designs folder
        action: 'mousedown',
        element: '[data-action=template-selected]',
        callback: function (e) {
          var attributes = $(this).data('attributes');
          e.preventDefault();
          e.stopPropagation();
          self.insertDesign(attributes);
        }
      },
      {
        // Perform action
        action: 'mousedown',
        element: '[data-action]',
        callback: function (e) {
          var action = $(this).data('action'),
            param = $(this).data('param') || null;
          e.preventDefault();
          e.stopPropagation();
          self.action(action, param);
        }
      },
      {
        // Select clicked element
        action: 'mousedown',
        element: '[data-element]',
        callback: function (e) {
          var ref = $(this).data('ref');
          e.stopPropagation();
          self.selectElement(ref);
        }
      },
      {
        // Select clicked row
        action: 'mousedown',
        element: '.eddie-page tr',
        callback: function (e) {
          var region = $(this).data('region');
          e.stopPropagation();
          self.selectElement({ region: region });
        }
      },
      {
        // Show link toolbar
        action: 'mouseenter',
        element: '.eddie-page a',
        callback: function (e) {
          $(this).addClass('selected-link');
          self.showLinkToolbar();
        }
      },
      {
        // Close link toolbar
        action: 'mouseleave',
        element: '.eddie-page a',
        callback: function (e) {
          self.closeLinkToolbar();
        }
      },
      {
        // Show image toolbar
        action: 'mouseenter',
        element: '.eddie-page img',
        callback: function (e) {
          $(this).addClass('selected-image');
          self.showImageToolbar();
        }
      },
      {
        // Close image toolbar
        action: 'mouseleave',
        element: '.eddie-page .selected-image-container',
        callback: function (e) {
          self.closeImageToolbar();
        }
      },
      {
        // Selects an image and opens the image modal
        action: 'click',
        element: '[data-change=image]',
        callback: function (e) {
          var ref = $(this).data('image');
          e.preventDefault();
          e.stopPropagation();
          self.selectElement(ref);
          self.openImageModal();
        }
      },
      {
        // Opens the products modal
        action: 'click',
        element: '[data-change=product]',
        callback: function (e) {
          var ref = $(this).data('image');
          e.preventDefault();
          e.stopPropagation();
          self.selectElement(ref);
          self.openProductsModal();
        }
      },
      {
        // Selects an image and opens the image modal
        action: 'click',
        element: '[data-select=image]',
        callback: function (e) {
          e.preventDefault();
          self.selectImage();
        }
      },
      {
        // Activate tabs
        action: 'click',
        element: '[data-tab-group] a',
        callback: function (e) {
          e.preventDefault();
          self.searchValue = false;
          self.findByData('role', 'searchValue').val('');
          $(this).tab('show');
        }
      },
      {
        // Activate selector
        action: 'click',
        element: '.selector',
        callback: function (e) {
          $(this).addClass('selected').siblings().removeClass('selected');
        }
      },
      {
        // Activate first tab on modal open
        action: 'show.bs.modal',
        element: '[data-modal]',
        callback: function () {
          $(this).find('[data-toggle=tab]').first().tab('show');
          $(this).find('[data-tag]').first().trigger('click');
        }
      },
      {
        // Reset the modal scroll and clean up
        action: 'hide.bs.modal',
        element: '[data-modal=product]',
        callback: function () {
          self.resetProducts();
        }
      },
      {
        // Reset the modal scroll and clean up
        action: 'hidden.bs.modal',
        element: '[data-modal]',
        callback: function () {
          var modal = $(this),
            element = self.findSelectedElement();

          // Scroll up
          $('.modal').scrollTop(0);
          $('.modal-body').scrollTop(0);

          // Clean up
          self.cleanTemps();
          modal.off('submit', 'form');

          // Save for undo
          if (element.data('editable')) {
            self.updateNode(self.selected, 'attr', 'html', element.html());
          }

          // Reset colorpicker
          self.resetColorPickerPreview();

          // Reset image uploader
          self.unbindUploadButton();

          // Remove waypoint
          if (_.isDefined(self.waypoint)) {
            self.waypoint.destroy();
          }
        }
      },
      {
        // Hide tooltips when a modal is shown, and focus on first input
        action: 'shown.bs.modal',
        element: '[data-modal]',
        callback: function () {
          $(this).find('input[type=text]:visible, input[type=number]:visible').first().focus();
          $('.tooltip').hide();
        }
      },
      {
        // Bind image album trigger
        action: 'click',
        element: '[data-tab=album]',
        callback: function () {
          var mode = $(this).data('mode'),
            path = $(this).data('path');
          self.showImageAlbum(mode, path);
        }
      },
      {
        // Change album folder
        action: 'click',
        element: '[data-path]',
        callback: function () {
          var mode = $(this).data('mode'),
            path = $(this).data('path');
          self.searchValue = false;
          self.findByData('role', 'searchValue').val('');
          self.showImageAlbum(mode, path);
        }
      },
      {
        // Add drop area active class
        action: 'dragenter',
        element: '[data-role=dropzone]',
        callback: function () {
          $(this).addClass('over');
        }
      },
      {
        // Remove drop area active class
        action: 'dragleave',
        element: '[data-role=dropzone]',
        callback: function (e) {
          if (!$(e.currentTarget).closest('.dropzone').length) {
            $('.dropzone').removeClass('over');
          }
        }
      },
      {
        // Remove drop area active class
        action: 'drop',
        element: '[data-role=dropzone]',
        callback: function (e) {
          var formData = new FormData(),
            files = e.originalEvent.dataTransfer.files,
            file = files[0];
          e.preventDefault();
          e.stopPropagation();
          $(this).removeClass('over');

          // Validate image
          if (file.name === undefined || !self.validateImage(file.name)) {
            alert(self.lang.invalidImage);
            return false;
          }

          // Validate image size
          if (!self.validateImageSize(file.size)) {
            alert(self.getInvalidImageSizeMessage());
            return false;
          }

          // Preview image
          self.showImagePreview(file);

          // Bind import button
          self.bindUploadButton(file);
        }
      },
      {
        // Activate content editable zones
        action: 'click',
        element: '[data-editable]',
        callback: function (e) {
          e.stopPropagation();
          $(this).attr('contenteditable', true).focus();
          self.oldText = $(this).html();
        }
      },
      {
        // Update node on content editable changes
        action: 'keyup',
        element: '[data-editable]',
        callback: function () {
          var element = $(this),
            ref = element.data('ref'),
            html = element.html();
          self.updateNode(ref, 'attr', 'html', html);
        }
      },
      {
        // Save a backup when editable loses focus
        action: 'blur',
        element: '[data-editable]',
        callback: function () {
          var newText = $(this).html(),
            temp = self.target.find('a[href=temp]').length,
            linkToolbar = self.target.find('.link-toolbar').length;
          if (!temp && !linkToolbar && (self.oldText !== newText)) {
            self.saveBackup();
          }
        }
      },
      {
        // Activate special button classes on hover
        action: 'mouseenter mouseleave',
        element: '[data-hover]',
        callback: function () {
          var hover = $(this).data('hover');
          $(this).toggleClass(hover);
        }
      },
      {
        // Perform command on selected element
        action: 'click',
        element: '[data-command]',
        callback: function (e) {
          var data = $(this).data();
          e.preventDefault();
          e.stopPropagation();
          self.command(data.command, data.param);
        }
      },
      {
        // Unselect when the wrapper is clicked
        action: 'mousedown',
        element: '[data-role=wrapper]',
        callback: function (e) {
          $('.selected-element').removeClass('selected-element');
          $('.selected-region').removeClass('selected-region');
          self.unselectElement();
        }
      },
      {
        // Do not unselect when the wrapper children are clicked
        action: 'click',
        element: '.eddie-page',
        callback: function (e) {
          e.stopPropagation();
        }
      },
      {
        // Undo
        action: 'click',
        element: '[data-role=undo]',
        callback: function (e) {
          e.preventDefault();
          e.stopPropagation();
          self.undo();
        }
      },
      {
        // Redo
        action: 'click',
        element: '[data-role=redo]',
        callback: function (e) {
          e.preventDefault();
          e.stopPropagation();
          self.redo();
        }
      },
      {
        // Import image when file input changes
        action: 'input',
        element: '[data-import=url]',
        callback: function (e) {
          var url = $(this).val();
          var lowres = $('[data-import=lowres]').is(':checked');
          e.stopPropagation();
          self.previewImageToImport(url, lowres);
        }
      },
      {
        // Import image when file input changes
        action: 'change',
        element: '[data-import=lowres]',
        callback: function (e) {
          e.stopPropagation();
          $('[data-import=url]').trigger('input');
        }
      },
      {
        // Select an image from the album
        action: 'click',
        element: '[data-album=image] li',
        callback: function () {
          var url = $(this).children('img').data('url');
          self.insertImage(url);
        }
      },
      {
        // Insert a product
        action: 'click',
        element: '[data-product=explorer] li',
        callback: function () {
          var id = $(this).data('id');
          self.insertProduct(id);
        }
      },
      {
        // Load more images
        action: 'click',
        element: '[data-album=load-more]',
        callback: function () {
          self.loadMoreImages();
        }
      },
      {
        // Load more products
        action: 'click',
        element: '[data-product=load-more]',
        callback: function () {
          self.loadMoreProducts();
        }
      },
      {
        // Select color from color palette
        action: 'click',
        element: '[data-color=list] span',
        callback: function (e) {
          var picker = $(e.currentTarget),
            color = picker.data('hex'),
            input = picker.closest('form').find('input');
          input.val(color).trigger('keyup');
        }
      },
      {
        // Show color preview
        action: 'keyup',
        element: '[data-color=input]',
        callback: function () {
          var color = $(this).val(),
            preview = self.target.find('[data-color=preview]'),
            selected = self.target.find('.selected-color');
          selected.removeClass('selected-color');
          self.target.find('[data-hex="' + color + '"]').addClass('selected-color');
          if (color === 'transparent') {
            preview.css('background', 'url(' + self.options.pluginPath + '/img/transparent.png)');
          } else {
            preview.css('background', color);
          }
        }
      },
      {
        // Upload image when file input changes
        action: 'change',
        element: '[data-role=uploader]',
        callback: function () {
          var input = this,
            file = input.files[0],
            name = $(this).val();

          // Validate image
          if (!self.validateImage(name)) {
            alert(self.lang.invalidImage);
            return false;
          }

          // Validate image size
          if (!self.validateImageSize(file.size)) {
            alert(self.getInvalidImageSizeMessage());
            return false;
          }

          // Show image preview
          self.showImagePreview(file);

          // Bind import button
          self.bindUploadButton(file);
        }
      },
      {
        // Selects a JSON file
        action: 'click',
        element: '[data-select=json]',
        callback: function (e) {
          e.preventDefault();
          self.importing = $(e.currentTarget).data('type');
          self.selectJson();
        }
      },
      {
        // Upload JSON file when file input changes
        action: 'change',
        element: '[data-role=opener]',
        callback: function () {
          var input = this,
            file = input.files[0],
            name = $(this).val();

          // Get JSON content
          self.importJson(file);
        }
      },
      {
        // Show regions by tag
        action: 'click',
        element: '[data-tag]',
        callback: function () {
          var tag = $(this).data('tag'),
            pill = $(this).closest('li'),
            target = $(this).closest('a'),
            modal = pill.closest('[data-modal]'),
            list = modal.find('[data-list]'),
            items = list.find('[data-tags]'),
            selected = items.filter('[data-tags~=' + tag + ']'),
            unselected = items.not('[data-tags~=' + tag + ']');
          selected.removeClass('hide');
          unselected.addClass('hide');
          pill.addClass('active').siblings().removeClass('active');
        }
      },
      {
        // Add region
        action: 'click',
        element: '[data-delegate] li',
        callback: function () {
          var param = $(this).data('param'),
            action = $(this).closest('[data-delegate]').data('delegate');
          if (self[action] !== undefined) {
            self[action](param);
          }
        }
      },
      {
        // Go to a given tutorial step
        action: 'click',
        element: '[data-go-step]',
        callback: function () {
          var step = $(this).data('go-step');
          self.showTutorialStep(step);
        }
      },
      {
        // Go to a given tutorial step
        action: 'click',
        element: '[data-restart-tutorial]',
        callback: function () {
          $('.eddie').removeClass().addClass('eddie tutorial');
          self.showTutorialStep(1);
        }
      },
      {
        // Toggle tutorial option
        action: 'click',
        element: '[name=tutorial]',
        callback: function (e) {
          var option = $(e.currentTarget).is(':checked');
          self.saveTutorialOption(option);
        }
      },
      {
        // Open image modal on double click
        action: 'dblclick',
        element: '[data-type=image]',
        callback: function (e) {
          var ref = $(this).data('ref');
          e.preventDefault();
          e.stopPropagation();
          self.selectElement(ref);
          self.openImageModal();
        }
      },
      {
        // Open text and link on double click
        action: 'dblclick',
        element: '[data-type=button]',
        callback: function (e) {
          var ref = $(this).data('ref');
          e.preventDefault();
          e.stopPropagation();
          self.selectElement(ref);
          self.defineTextAndLink();
        }
      },
      {
        // Toggles the aspect ratio
        action: 'change',
        element: '[data-toggle=aspect-ratio]',
        callback: function () {
          self.toggleAspectRatio();
        }
      },
      {
        // Searches a design
        action: 'submit',
        element: '[data-form=searchDesign]',
        callback: function (e) {
          e.preventDefault();
          self.searchDesignValue = $(e.currentTarget).find('input').val();
          self.exploreFolders('/');
        }
      },
      {
        // Searches an image
        action: 'submit',
        element: '[data-form=searchImage]',
        callback: function (e) {
          e.preventDefault();
          self.searchValue = $(e.currentTarget).find('input').val();
          self.showImageAlbum(self.currentImageAlbumMode, self.currentImageAlbumPath);
          app.integrations.amplitude.event("APP_EDDIE_IMAGES_SEARCH");
        }
      },
      {
        // Searches a product
        action: 'submit',
        element: '[data-form=searchProduct]',
        callback: function (e) {
          e.preventDefault();
          self.searchProducts($(e.currentTarget).find('input').val());
        }
      },
      {
        action: 'click',
        element: '[data-action=upload]',
        callback: function (e) {
          e.preventDefault();
          if (self.options.allowFileUpload) $('input#file-upload').trigger('click');
        }
      },
      {
        action: 'change',
        element: 'input#file-upload',
        callback: self.uploadFileChange,
      },
    ];

    // Bind each event
    $.each(events, function () {
      self.target.on(this.action, this.element, this.callback);
    });

    // Relocate toolbar on resize
    $(window).resize(function () {
      var height = $(this).height() - self.details.toolbarHeight;
      self.findByData('role', 'wrapper').height(height);
      self.findByData('toolbar', 'region').css(
        'left',
        self.target.find('.eddie-page').position().left - 70
      );
    }).resize();

    // Show tooltips
    self.target.find('[data-tooltip]').tooltip({
      container: 'body',
      trigger: 'hover',
      delay: 200,
      animation: false
    });

    // Hide tooltips when a dropdown is opened
    $(document).on('click', '.dropdown-toggle', function () {
      $('.tooltip').hide();
    });

    // Paste plain text only
    $(document).on('paste', '[data-editable]', function (e) {
      //Excepcion para cuenta que estaba enojada...
      if (!['pbacompublica', 'adimra', 'politicamon', 'bravojeans', 'investigbcra', 'jmoribe', 'melisalipnizky', 'presidencia', 'prensabsas', 'prueba1234', 'usina'].includes(app.session.attributes.account)) {
        e.preventDefault();
        const text = e.originalEvent.clipboardData.getData('text/plain')
        document.execCommand('inserttext', false, text);
      }
    });
  };

  /**
   * Binds keyboard shortcuts to actions
   */
  this.bindShortcuts = function () {
    var node;
    $(document).on('keydown', function (e) {
      var inInput = $(e.target).is(':input'),
        inConfirm = self.findByData('modal', 'confirm').is(':visible');
      if (self.selected) {
        node = self.getNode(self.selected);
        switch (e.keyCode) {
          case 13:
            if (inConfirm) {
              e.preventDefault();
              self.findByData('modal', 'confirm').find('form').submit();
            }
            break;
          case 27:
            if (inConfirm) {
              e.preventDefault();
              self.findByData('modal', 'confirm').modal('hide');
            }
            break;
          case 46:
            if (node !== undefined && node.type !== undefined && node.type === 'image' && !inInput) {
              e.preventDefault();
              self.action('destroyElement', false);
            }
            break;
        }
      }
      if (e.ctrlKey) {
        switch (e.keyCode) {
          case 66:
            e.preventDefault();
            self.command('bold');
            break;
          case 73:
            e.preventDefault();
            self.command('italic');
            break;
          case 75:
            e.preventDefault();
            self.command('createLink');
            break;
          case 89:
            e.preventDefault();
            self.redo();
            break;
          case 90:
            e.preventDefault();
            self.undo();
            break;
        }
      }
    });
  };

  /**
   * Helper to render a mini template
   * @param {String} string
   * @param {String} values
   * @return {String}
   */
  this.template = function (string, values) {
    var value,
      regex;
    for (value in values) {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty(value)) {
        regex = new RegExp('{{' + value + '}}', 'g');
        string = string.replace(regex, values[value]);
      }
    }
    return string;
  };

  /**
   * Helper to get a HEX color code from an RGB string
   * @param {String} string
   * @return {String}
   */
  this.rgbToHex = function (string) {
    var parts = string.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/),
      color = 1 << 24;
    if (parts) {
      color += parseInt(parts[1], 10) << 16;
      color += parseInt(parts[2], 10) << 8;
      color += parseInt(parts[3], 10);
      return '#' + color.toString(16).slice(1);
    } else {
      return '#FFFFFF';
    }
  };

  /**
   * Helper to get a HEX color code from an HSL string
   * @param {String} string
   * @return {String}
   */
  this.hslToHex = function (string) {
    var parts = string.split(', ');
    var h = parts[0] / 360;
    var s = parts[1].replace('%', '') / 100;
    var l = parts[2].replace('%', '') / 100;
    var r, g, b;
    if (s === 0) {
      r = g = b = l;
    } else {
      var hue2rgb = function (p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    var toHex = function (x) {
      var hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return '#' + toHex(r) + toHex(g) + toHex(b);
  };

  /**
   * Renders a color table
   */
  this.createColorPalette = function () {
    var template = '<span style="background: hsl({{code}})" data-hex="{{hex}}"></span>',
      html = '',
      colors = [[], [], [], [], []],
      i;
    for (i = 0; i <= 100; i = i + 2) {
      colors[0].push('360, 0%, ' + i + '%');
    }
    for (i = 0; i <= 300; i = i + 6) {
      colors[1].push(i + ', 100%, 80%');
      colors[2].push(i + ', 100%, 60%');
      colors[3].push(i + ', 100%, 40%');
      colors[4].push(i + ', 100%, 20%');
    }
    colors = $.map(colors, function (row) {
      row = $.map(row, function (cell) {
        return self.template(template, {
          code: cell,
          hex: self.hslToHex(cell)
        });
      });
      return row.join('');
    });
    html = '<div>' + colors.join('</div><div>') + '</div>';
    self.findByData('color', 'list').html(html);
  };

  /**
   * Prepares the components lists
   */
  this.createRegionsLists = function () {
    var template = '<li data-param="{{name}}" data-tags="{{tags}}">{{html}}</li>',
      items,
      html;
    items = $.map(self.components.regions, function (region, name) {
      var tagList = [],
        container = $('<div>'),
        html;
      $.each(region.tags, function (index, tag) {
        tagList.push(tag.replace(/ /g, ''));
      });
      self.renderRegions([region], container, false);
      $(container).find('a').removeAttr('href');
      return self.template(template, {
        name: name,
        tags: tagList.join(' '),
        html: container.html()
      });
    });
    html = items.join('');
    self.findByData('list', 'regions').html(html);
  };

  /**
   * Prepares the components lists
   */
  this.createElementsLists = function () {
    var template = '<li data-param="{{name}}" data-tags="{{tags}}" style="background-image: url({{pluginPath}}/img/elements_{{name}}.png)"><h4><small>{{category}}</small>{{title}}</h4></li>',
      items,
      html;
    $.each(['elements'], function () {
      var type = this;
      items = $.map(self.components[type], function (component, name) {
        var tagList = [];
        $.each(component.tags, function (index, tag) {
          tagList.push(tag.replace(/ /g, ''));
        });
        return self.template(template, {
          name: name,
          title: self.lang[name],
          category: self.lang[component.tags[0]],
          tags: tagList.join(' '),
          pluginPath: self.options.pluginPath
        });
      });
      html = items.join('');
      self.findByData('list', [type]).html(html);
    });
  };

  /**
   * Prepares the templates lists
   */
  this.createdesignsList = function () {
    var template = '<li data-param="{{id}}" data-tags="{{tags}}"><div class="preview" style="background-image: url({{thumbnailUrl}})"></div><h4>{{name}}</h4></li>',
      items,
      html;
    items = $.map(self.templates, function (item, key) {
      var tagList = [];
      $.each(item.tags, function (index, tag) {
        tagList.push(tag.replace(/ /g, ''));
      });
      return self.template(template, {
        id: item.id,
        name: item.name,
        tags: tagList.join(' ') + ' Todo',
        thumbnailUrl: item.thumbnail
      });
    });
    html = items.join('');
    self.findByData('list', 'templates').html(html);
  };

  /**
   * Prepares the template tags lists
   */
  this.createTemplateTagsList = function () {
    var template = '<li><a class="{{class}}" data-tag="{{slug}}">{{name}}</a></li>',
      items,
      html,
      tags = Object.keys(self.lang.tags);

    items = $.map(tags, function (item, key) {
      var slug = item.replace(/ /g, '');
      return self.template(template, {
        name: self.lang.tags[item],
        class: self.lang.tags[item] == "Black Friday" || self.lang.tags[item] == "Navidad y Año Nuevo" || self.lang.tags[item] == "Natal e Ano Novo" ? "featured" : "",
        slug: slug
      });
      //class: self.lang.tags[item] == "Carnaval" || self.lang.tags[item] == "San Valentín" || self.lang.tags[item] == "Dia dos namorados" ? "featured" : "",
      //class: self.lang.tags[item] == "Carnaval" || self.lang.tags[item] == "Dia do Consumidor" ? "featured" : "",
      //class: self.lang.tags[item]=="Eventos"  || self.lang.tags[item]=="Natal e Ano Novo" ? "featured" : "",
      //class: self.lang.tags[item]== "eCommerce" ? "featured" : "",
    });
    html = items.join('');
    self.findByData('list', 'template-tags').html(html);
  };

  /**
   * Prepares the keywords dropdown
   */
  this.createKeywordsList = function () {
    var template = '<li><a href="#" data-action="insertKeyword" data-param="{{tag}}">{{name}}</a></li>',
      keywordsList = self.findByData('role', 'keywordsList'),
      data = self.fields;
    $.each(data, function () {
      if (!this.custom) {
        keywordsList.append(self.template(template, this));
      }
    });
    keywordsList.append('<li class="divider"></li>');
    $.each(data, function () {
      if (this.custom) {
        keywordsList.append(self.template(template, this));
      }
    });
  };

  /**
   * Opens the templates modal
   */
  this.openTemplatesModal = function () {
    self.findByData('modal', 'templates').modal('show');
  };

  /**
   * Opens the templates modal
   */
  this.openRegionsModal = function () {
    self.findByData('modal', 'regions').modal('show');
  };

  /**
   * Sets the email content from a template
   */
  this.chooseTemplate = function (id) {
    $.ajax({
      url: self.options.api.templateInfo + id + '.json',
      cache: false,
      dataType: 'json',
      success: function (response) {
        self.cleanBackups();
        self.content = response;
        self.render();
        self.findByData('modal', 'templates').modal('hide');
        self.saveBackup();
      }
    });
  };

  /**
   * Resets the product modal
   */
  this.resetProducts = function () {
    var self = this;
    self.productsData = { offset: 0, limit: 30 };
    self.searchProduct = false;
    self.productsItems = [];
    self.products = [];
    if (_.isDefined(self.waypoint)) {
      self.waypoint.destroy();
    }
    self.findByData('product', 'load-more').removeClass('hide');
    self.findByData('product', 'explorer').find('ul').html('');
  };

  /**
   * Opens the product modal
   */
  this.searchProducts = function (search) {
    var self = this;
    self.resetProducts();
    self.productsData = { offset: 0, limit: 30, q: search };
    self.searchProduct = search;
    self.loadProducts();
  };

  /**
   * Opens the product modal
   */
  this.renderSelectedProductsList = function () {
    var html,
      selectedRegion = self.getRegion(self.selected);
    html = _.template("<% if (count > 1) { %><% for(var i = 0; i < count; ++i) { %><li><% if (products[i]) { %><%=products[i].title%><% } else { %><%=unselected%><% }}} %>")({
      products: selectedRegion.products || [],
      count: selectedRegion.productsCount,
      unselected: self.lang.unselected
    });
    self.findByData('products', 'selected').html(html);
  };

  /**
   * Opens the product modal
   */
  this.renderProductsModalTitle = function (count, current) {
    var titleTemplate = '<%=title%><% if (count > 1) { %> <%=current%> <%=of%> <%=count%> <% } %>',
      data = {
        title: self.lang.applyProduct,
        of: self.lang.of,
        count: count,
        current: current
      },
      template = _.template(titleTemplate);
    self.renderSelectedProductsList();
    self.findByData('products', 'title').text(template(data));
  };

  /**
   * Opens the product modal
   */
  this.openProductsModal = function () {
    var self = this,
      selectedRegion = self.getRegion(self.selected);
    self.resetProducts();
    self.renderProductsModalTitle(selectedRegion.productsCount, 1);
    self.findByData('modal', 'product').modal('show');
    self.loadProducts();
  };

  /**
   * Opens the product modal
   */
  this.loadProducts = function () {
    var self = this;

    // Show initial products
    if (self.initialProducts) {
      self.renderProductsRows(self.initialProducts);
      self.initialProducts = false;
      return this;
    }

    // Add search term
    if (self.searchProduct) {
      self.findByData('action', 'clearProductSearch').show();
    } else {
      self.findByData('form', 'searchProduct').get(0).reset();
      self.findByData('action', 'clearProductSearch').hide();
    }

    // Request images via AJAX
    self.isFetching = true;
    $.ajax({
      url: self.options.api.productsList,
      data: self.productsData,
      success: function (response) {
        self.isFetching = false;
        if (response.success !== undefined && response.success) {
          if (response.data !== undefined) {
            if (response.data.length) {
              self.renderProductsRows(response);
            } else {
              self.findByData('product', 'load-more').addClass('hide');
            }
          }
        }
      }
    });
  };

  /**
   * Renders the images rows
   */
  this.renderProductsRows = function (response) {
    var context,
      box = self.findByData('product', 'explorer').find('ul'),
      template = '<li class="image" data-id="{{id}}"><img src="{{image}}"><br><span>{{shortTitle}}</span></li>';
    $.each(response.data, function (index, product) {
      var item;
      product.shortTitle = product.title.length > 35 ? product.title.substring(0, 35) + '...' : product.title;
      item = self.template(template, product);
      self.productsItems.push(item);
      self.products.push(product);
    });
    if (self.productsItems.length) {
      box.html(self.productsItems.join(''));
    } else {
      box.hide();
    }
    if (!response.paging.next) {
      self.findByData('product', 'load-more').addClass('hide');
      if (_.isDefined(self.waypoint)) {
        self.waypoint.destroy();
      }
    } else {
      context = this.findByData('product', 'explorer').get(0);
      self.bindLoadMoreScroll(context, '[data-product=load-more]', self.loadMoreProducts);
    }
  };

  /**
   * Opens the image modal
   */
  this.openImageModal = function () {
    var emailOptions = self.findByData('action', 'removeEmailBackgroundImage'),
      regionOptions = self.findByData('action', 'removeRegionBackgroundImage'),
      uploadOptions = self.findByData('check', 'resizeUpload'),
      importOptions = self.findByData('check', 'resizeImport'),
      insertVideoTab = self.findByData('tab', 'video');
    self.searchValue = false;
    self.findByData('role', 'searchValue').val('');
    self.findByData('album', 'load-more').removeClass('hide');
    emailOptions.addClass('hide');
    regionOptions.addClass('hide');
    uploadOptions.addClass('hide');
    importOptions.addClass('hide');
    insertVideoTab.addClass('hide');
    self.findByData('modal', 'image').modal('show');
    if (self.editingSettings) {
      emailOptions.removeClass('hide');
    } else if (self.editingRegion) {
      regionOptions.removeClass('hide');
    } else {
      insertVideoTab.removeClass('hide');
      uploadOptions.removeClass('hide');
      importOptions.removeClass('hide');
    }
  };

  /**
   * Closes the image modal
   */
  this.closeImageModal = function () {
    self.findByData('modal', 'image').modal('hide');
  };

  /**
   * Renders an image album
   */
  this.showImageAlbum = function (mode, path) {
    var templates = {
      breadcrumb: '<li><a data-path="{{path}}" data-mode="{{mode}}"><span>{{name}}</span></a></li>'
    },
      breadcrumbsUrl = (mode === 'public') ? 'shared/images/breadcrumb' : 'images/breadcrumb';
    self.currentImageAlbumMode = mode || 'private';
    self.currentImageAlbumPath = path || '/';
    self.albumItems = {
      image: [],
      folder: []
    };

    // Clear waypoint
    if (_.isDefined(self.waypoint)) {
      self.waypoint.destroy();
    }

    // Render breadcrumbs
    if (self.searchValue) {
      self.findByData('album', 'breadcrumb').hide();
    } else {
      $.get(breadcrumbsUrl, { q: self.currentImageAlbumPath }, function (response) {
        var breadcrumbs = response.data,
          splats = (self.currentImageAlbumPath === '/') ? ['/'] : self.currentImageAlbumPath.split('/'),
          items = [],
          html = '';

        // Add home to the beginning
        breadcrumbs.unshift('home');

        // Create breadcrumbs items based on path splat and server data
        $.each(splats, function (index, value) {
          var path = $.extend([], splats);
          items.push({
            name: breadcrumbs[index],
            path: path.splice(0, index + 1).join('/'),
            mode: mode
          });
        });

        // Correct the home path
        items[0].path = '/';

        // Render HTML
        $.each(items, function (index, item) {
          html = html + self.template(templates.breadcrumb, item);
        });

        // Insert HTML
        self.findByData('album', 'breadcrumb').show().html(html);
      });
    }

    // Clean list
    $.each(['folder', 'image'], function () {
      var box = self.findByData('album', this);
      box.find('ul').html('');
    });

    // Update interface
    self.findByData('mode', mode).tab('show');
    self.findByData('album', 'wait').show();
    self.findByData('album', 'empty').hide();
    self.findByData('album', 'folder').hide();
    self.findByData('album', 'image').hide();

    // Set data
    self.albumData = { offset: 0, limit: 32 };
    self.loadImages();
  };

  /**
   * Load more images
   */
  this.loadMoreImages = function () {
    self.albumData.offset = self.albumData.offset + 32;
    self.loadImages();
  };

  /**
   * Load more products
   */
  this.loadMoreProducts = function () {
    self.productsData.offset = self.productsData.offset + 30;
    self.loadProducts();
  };

  /**
   * Load
   */
  this.loadImages = function () {
    var url;

    // Add search term
    if (self.searchValue) {
      self.albumData.q = '!*' + self.searchValue + '*';
      self.findByData('action', 'clearImageSearch').show();
      self.currentImageAlbumPath = '/';
    } else {
      self.findByData('action', 'clearImageSearch').hide();
    }

    // Set URL
    url = self.options.api.imageList + self.currentImageAlbumPath;
    if (self.currentImageAlbumMode === 'public') {
      url = self.options.api.publicImageList + self.currentImageAlbumPath;
    }

    // Request images via AJAX
    self.isFetching = true;
    self.findByData('album', 'load-more').removeClass('hide');
    $.ajax({
      url: url,
      data: self.albumData,
      success: function (response) {
        self.isFetching = false;
        self.findByData('album', 'load-more').text(self.lang.loadMore).removeAttr('disabled');
        if (response.success !== undefined && response.success) {
          self.findByData('album', 'wait').hide();
          self.findByData('album', 'empty').hide();
          self.closeImageAlbumSearch();
          if (response.data !== undefined) {
            if (response.data.length) {
              self.renderImagesRows(response);
            } else {
              self.findByData('album', 'load-more').addClass('hide');
              self.findByData('album', 'empty').show();
            }
          }
        }
      }
    });
  };

  /**
   * Renders the images rows
   */
  this.renderImagesRows = function (response) {
    var templates = {
      image: '<li class="image"><img src="{{thumbnail}}" data-url="{{public}}"><br><span>{{name}}</span></li>',
      folder: '<li class="folder"><a data-path="{{id}}" data-mode="{{mode}}"><br><span>{{name}}</span></a></li>'
    },
      context;
    $.each(response.data, function () {
      var item,
        type = this.type.toLowerCase();
      this.mode = self.currentImageAlbumMode;
      item = self.template(templates[type], this);
      self.albumItems[type].push(item);
    });
    $.each(['folder', 'image'], function () {
      var box = self.findByData('album', this);
      if (self.albumItems[this].length) {
        box.show().find('ul').html(self.albumItems[this].join(''));
      } else {
        box.hide();
      }
    });
    if (!response.paging.next) {
      self.findByData('album', 'load-more').addClass('hide');
      if (_.isDefined(self.waypoint)) {
        self.waypoint.destroy();
      }
    } else {
      context = this.findByData('pane', 'album').find('.tab-pane-body').get(0);
      self.bindLoadMoreScroll(context, '[data-album=load-more]', self.loadMoreImages);
    }
  };

  /**
   * Automatic load more on scroll
   */
  this.bindLoadMoreScroll = function (context, selector, callback) {
    var options;

    // Set scroll plugin options
    options = {
      element: $(selector).get(0),
      handler: function (direction) {
        if (direction === 'down' && !self.isFetching) {
          $(selector).text(self.lang.loading).attr('disabled', true);
          callback();
        }
      },
      context: context,
      offset: 'bottom-in-view'
    };

    // Destroy the plugin active instance
    if (_.isDefined(self.waypoint)) {
      self.waypoint.destroy();
    }

    // Listen to scroll if the load more button is present
    if (options.element) {
      self.waypoint = new window.Waypoint(options);
    }
  };

  /**
   * Opens the image album search
   */
  this.openImageAlbumSearch = function () {
    self.findByData('role', 'imageFinder').addClass('focus').focus();
  };

  /**
   * Closes the image album search
   */
  this.closeImageAlbumSearch = function () {
    self.findByData('album', 'explorer').removeClass('searching');
    self.findByData('role', 'imageFinder').removeClass('focus').val('');
  };

  /**
   * Bind the button to upload an image to the server
   * @param {File} file
   */
  this.bindUploadButton = function (file) {
    var uploadButton = self.findByData('role', 'uploadImage'),
      dropzone = self.findByData('role', 'dropzone'),
      previewer = $('#previewer');
    dropzone.addClass('hide');
    uploadButton.removeAttr('disabled').on('click', function (e) {
      e.preventDefault();
      self.uploadImage(file);
      self.unbindUploadButton();
    });
  };

  /**
   * Unbinds the button to upload an image to the server
   */
  this.unbindUploadButton = function () {
    var uploadButton = self.findByData('role', 'uploadImage'),
      dropzone = self.findByData('role', 'dropzone'),
      uploader = self.findByData('role', 'uploader'),
      previewer = $('#previewer');
    dropzone.removeClass('hide');
    uploader.closest('form').get(0).reset();
    previewer.removeAttr('src').addClass('hide');
    uploadButton.attr('disabled', true).off('click');
  };

  /**
   * Uploads an image to the server
   * @param {File} file
   */
  this.uploadImage = function (file) {
    var setWidth = self.findByData('check', 'resizeUpload').find('input').is(':checked'),
      selectedElement = self.findSelectedElement(),
      selectedNode = self.getNode(self.selected),
      maxWidth,
      formData = new FormData();
    formData.append('image', file);
    if (!self.editingSettings && !self.editingRegion && setWidth) {
      maxWidth = parseInt(selectedElement.closest('[data-cell]').css('width'), 10);
      if (selectedNode.style !== undefined) {
        if (selectedNode.style.marginLeft !== undefined) {
          maxWidth = maxWidth - selectedNode.style.marginLeft;
        }
        if (selectedNode.style.marginRight !== undefined) {
          maxWidth = maxWidth - selectedNode.style.marginRight;
        }
      }
      formData.append('maxWidth', maxWidth);
    }
    formData.append('type', 'IMAGE');
    self.findByData('pane', 'upload').find('.upload-loader').show().siblings().hide();
    $.ajax({
      type: 'POST',
      url: self.options.api.imageUploader,
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      headers: {
        'X-Auth-Token': self.options.token
      },
      timeout: 60 * 1000,
      success: function (response) {
        var image = response.data;
        self.insertImage(image['public']);
      },
      error: function () {
        alert(self.lang.uploadImageError);
        self.findByData('pane', 'upload').find('.upload-loader').hide().siblings().show();
      }
    });
  };

  /**
   * Tells the server to import an image by URL
   * @param {String} url
   */
  this.previewImageToImport = function (url, lowres) {
    var error = false,
      preview = self.findByData('import', 'preview'),
      importButton = self.findByData('action', 'importImage'),
      protocols = ['http://', 'https://'],
      extensions = ['.jpg', '.jpeg', '.png', '.gif'],
      template = '<img class="img-responsive" src="{{url}}">',
      html = '',
      videoId = self.getYouTubeVideoId(url),
      errorBox;
    if (videoId) {
      url = self.getVideoThumbnailUrl(videoId, lowres);
    } else {
      if (self.stringIsEmpty(url)) {
        error = 'emptyError';
      } else if (!self.stringStartsWith(url.toLowerCase(), protocols)) {
        error = 'urlError';
      } else if (!self.stringEndsWith(url.toLowerCase(), extensions)) {
        error = 'extensionError';
      }
      if (error) {
        errorBox = self.findByData('import', error);
        errorBox.show().siblings().hide();
        importButton.attr('disabled', true);
        return self;
      }
    }
    html = self.template(template, { url: url });
    preview.html(html).show().siblings().hide();
    importButton.removeAttr('disabled');
  };

  /**
   * Imports an image
   * @param {Object} file
   */
  this.showImagePreview = function (file) {
    var previewer = document.getElementById('previewer'),
      reader = new FileReader();
    reader.onload = (function (img) {
      return function (e) {
        img.src = e.target.result;
      };
    })(previewer);
    $(previewer).removeClass('hide');
    reader.readAsDataURL(file);
  };

  /**
   * Imports an image
   */
  this.importImage = function () {
    var region = self.selected.region,
      content = self.content,
      selectedRegion = content.regions[region],
      selectedElement = self.findSelectedElement(),
      selectedNode = self.getNode(self.selected),
      setWidth = self.findByData('check', 'resizeImport').find('input').is(':checked'),
      lowres = $('[data-import=lowres]').is(':checked'),
      importInput = self.findByData('import', 'url').filter(':visible'),
      importEmpty = self.findByData('import', 'emptyError'),
      importButton = self.findByData('action', 'importImage'),
      preview = self.findByData('import', 'preview'),
      url = importInput.val(),
      videoId = self.getYouTubeVideoId(url),
      imageUrl = videoId ? self.getVideoThumbnailUrl(videoId, lowres) : url,
      data = {
        imageUrl: imageUrl,
        type: 'IMAGE',
        token: self.options.token
      };
    if (!self.editingSettings && !self.editingRegion && setWidth) {
      data.maxWidth = parseInt(selectedElement.closest('[data-cell]').css('width'), 10);
      if (selectedNode.style.marginLeft !== undefined) {
        data.maxWidth = data.maxWidth - selectedNode.style.marginLeft;
      }
      if (selectedNode.style.marginRight !== undefined) {
        data.maxWidth = data.maxWidth - selectedNode.style.marginRight;
      }
    }
    self.findByData('pane', 'video').find('.upload-loader').show().siblings().hide();
    self.findByData('pane', 'url').find('.upload-loader').show().siblings().hide();
    self.insertingVideo = videoId ? url : false;
    $.ajax({
      type: 'POST',
      url: self.options.api.imageImporter,
      dataType: 'json',
      data: data,
      timeout: 60 * 1000,
      success: function (response) {
        var image = response.data;
        importInput.val('');
        preview.html('');
        importButton.attr('disabled', true);
        importEmpty.show();
        self.insertImage(image['public']);
      },
      error: function () {
        alert(self.lang.invalidImage);
        self.findByData('pane', 'url').find('.upload-loader').hide().siblings().show();
      }
    });
  };

  /**
   * Clears the image search box
   */
  this.clearImageSearch = function () {
    self.searchValue = false;
    self.findByData('role', 'searchValue').val('');
    self.showImageAlbum(self.currentImageAlbumMode, self.currentImageAlbumPath);
  };

  /**
   * Clears the design search box
   */
  this.clearDesignSearch = function () {
    self.searchDesignValue = false;
    self.findByData('role', 'searchDesignValue').val('');
    self.exploreFolders('/');
  };

  /**
   * Clears the product search box
   */
  this.clearProductSearch = function () {
    self.searchProduct = false;
    self.findByData('form', 'searchProduct').get(0).reset();
    self.openProductsModal();
  };

  /**
   * Replaces the selected image by one from the album
   * @param {String} url
   */
  this.insertImage = function (url) {
    var region = self.selected.region,
      content = self.content,
      selectedRegion = content.regions[region];
    self.findByData('pane', 'video').find('.upload-loader').hide().siblings().show();
    self.findByData('pane', 'url').find('.upload-loader').hide().siblings().show();
    self.findByData('pane', 'upload').find('.upload-loader').hide().siblings().show();
    self.closeImageAlbumSearch();
    if (self.editingSettings) {
      self.content.settings.backgroundImage = 'url(' + url + ')';
      self.editingSettings = false;
    } else if (self.editingRegion) {
      if (selectedRegion === undefined) {
        return self;
      }
      if (selectedRegion.attr === undefined) {
        selectedRegion.attr = {};
      }
      if (selectedRegion.style === undefined) {
        selectedRegion.style = {};
      }
      selectedRegion.attr.background = url;
      selectedRegion.style.backgroundImage = 'url(' + url + ')';
      self.editingRegion = false;
    } else {
      self.updateNode(self.selected, 'attr', 'src', url);
      self.updateNode(self.selected, 'attr', 'width', 'auto');
      self.updateNode(self.selected, 'style', 'width', 'auto');
      self.updateNode(self.selected, 'attr', 'height', 'auto');
      self.updateNode(self.selected, 'style', 'height', 'auto');
      if (self.insertingVideo) {
        self.updateContent(self.selected, 'attr', 'link', self.insertingVideo);
      }
    }
    self.findByData('modal').modal('hide');
    self.render();
    self.saveBackup();
  };

  /**
   * Inserts an special field keyword
   * @param {String} keyword
   */
  this.insertProduct = function (id) {
    var modal = this.findByData('modal', 'product'),
      product = _.findWhere(this.products, { id: String(id) }),
      selectedRegion = this.getRegion(this.selected),
      template,
      source,
      json;

    app.integrations.amplitude.event("APP_EDDIE_INSERT_PRODUCT", { integration: product.source });
    app.integrations.intercom.event("APP_EDDIE_INSERT_PRODUCT", { integration: product.source });

    selectedRegion.backupCells = $.extend(true, {}, selectedRegion.cells);
    selectedRegion.hasProductsApplied = true;
    if (!selectedRegion.products) selectedRegion.products = [];
    if (!selectedRegion.selectedProducts) selectedRegion.selectedProducts = 0;
    selectedRegion.selectedProducts++;
    //To avoid json parsing errors
    product.title = product.title.replace(/"/g, '\\"');
    product.title = product.title.replace(/[\t\n]/g, ' ');
    product.shortTitle = product.shortTitle.replace(/"/g, '\\"');

    const thousandSeparator = (price) => {
      return price.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const roundPrice = (price) => {
      if (typeof price !== "number")
        return price;

      if (price !== Math.round(price)) {
        // 10.3 -> 10.30
        return thousandSeparator(price.toFixed(2).replace('.', ','));
      }

      // 10 -> 10
      return thousandSeparator(price.toFixed(0));
    }

    product.price = roundPrice(product.price);
    product.discountPrice = roundPrice(product.discountPrice);

    selectedRegion.products.push($.extend(true, {}, product));
    if (selectedRegion.products.length < selectedRegion.productsCount) {
      self.renderProductsModalTitle(
        selectedRegion.productsCount,
        selectedRegion.products.length + 1
      );
      self.renderSelectedProductsList();
    }
    if (selectedRegion.products.length === selectedRegion.productsCount) {
      selectedRegion.products.unshift({});
      source = JSON.stringify(selectedRegion.cells);
      template = Handlebars.compile(source, { noEscape: true });
      try {
        json = template({ product: selectedRegion.products });
      } catch (error) {
        modal.modal('hide');
        self.findByData('modal', 'alert')
          .find('p')
          .html(self.lang.insertProductError)
          .end()
          .modal('show');
        return false;
      }
      selectedRegion.cells = JSON.parse(json);
      modal.modal('hide');
      self.searchProduct = false;
      self.saveBackup();
      this.render();
    }
  };

  /**
   * Inserts an special field keyword
   * @param {String} keyword
   */
  this.removeProduct = function (id) {
    var selectedRegion = this.getRegion(this.selected);
    if (!selectedRegion.backupCells) {
      return false;
    }
    selectedRegion.cells = $.extend(true, [], selectedRegion.backupCells);
    delete selectedRegion.selectedProducts;
    delete selectedRegion.backupCells;
    delete selectedRegion.products;
    selectedRegion.hasProductsApplied = false;
    self.saveBackup();
    this.render();
  };

  /**
   * Inserts an special field keyword
   * @param {String} keyword
   */
  this.insertKeyword = function (keyword) {
    var prefix = 'CONTACT:';
    if (this.options.useLowercaseTags) {
      prefix = 'contact.';
      keyword = keyword.toLowerCase();
      if (keyword === 'lname') keyword = 'last_name';
      if (keyword === 'fname') keyword = 'first_name';
    }
    document.execCommand('insertText', false, '${' + prefix + keyword + '}');
    self.findSelectedElement().trigger('keyup');
    self.saveBackup();
  };

  /**
   * Opens the link toolbar
   */
  this.showLinkToolbar = function () {
    var link = self.target.find('.selected-link'),
      templates = {
        linked: '<div class="link-toolbar"><i class="fa fa-link"></i> <span data-action="testLink"><%=href%></span> <br>&bull; <span data-action="changeLink"><%=change%></span> <br>&bull; <span data-action="removeLink"><%=remove%></span> <br>&bull; <span data-action="changeLinkColor"><%=changeColor%></span><br>&bull; <span data-action="changeLinkInterests"><%=changeLinkInterests%></span></div>',
        linkedImage: '<div class="link-toolbar"><i class="fa fa-image"></i> <%=width%> &times; <%=height%> <br>&bull; <span data-change="image" data-image=<%=ref%>><%=replace%></span> <hr><i class="fa fa-link"></i> <span data-action="testLink"><%=href%></span> <br>&bull; <span data-action="changeLink"><%=change%></span> <br>&bull; <span data-action="removeLink"><%=remove%></span><br>&bull; <span data-action="changeLinkInterests"><%=changeLinkInterests%></span></div>',
        linkedButton: '<div class="link-toolbar"><i class="fa fa-link"></i> <span data-action="testLink"><%=href%></span> <br>&bull; <span data-action="changeLink"><%=change%></span><br>&bull; <span data-action="changeLinkInterests"><%=changeLinkInterests%></span></div>',
        unlinked: '<div class="link-toolbar"><i class="fa fa-link"></i> <%=noLink%> <br>&bull; <span data-action="changeLink"><%=defineLink%></span></div>'
      },
      params = {
        href: link.attr('href'),
        change: self.lang.changeLink,
        remove: self.lang.removeLink,
        noLink: self.lang.noLink,
        defineLink: self.lang.defineLink,
        changeColor: self.lang.changeLinkColor,
        changeLinkInterests: self.lang.changeLinkInterests + ' (' + (self.interestsMap[link.attr('interests')] || self.lang.none) + ')'
      },
      position = link.position(),
      height = link.outerHeight(),
      marginTop = parseInt(link.css('margin-top'), 10),
      wrapper = self.findByData('role', 'wrapper'),
      template,
      image = link.find('img');
    if ((link.attr('href') === undefined) || self.stringIsEmpty(link.attr('href'))) {
      template = _.template(templates.unlinked);
    } else {
      if (link.data('type') === 'button') {
        params.changeLinkInterests = self.lang.changeLinkInterests + ' (' + (self.interestsMap[link.attr('interests')] || self.lang.none) + ')';
        template = _.template(templates.linkedButton);
      } else {
        template = _.template(templates.linked);
        if (image.length) {
          template = _.template(templates.linkedImage);
          position = image.position();
          height = image.outerHeight();
          marginTop = parseInt(image.css('margin-top'), 10);
          params.replace = self.lang.replaceImage;
          params.applyProduct = self.lang.applyProduct;
          params.ref = JSON.stringify(image.data('ref'));
          params.width = image.width();
          params.height = image.height();
          params.changeLinkInterests = self.lang.changeLinkInterests + ' (' + (self.interestsMap[image.attr('interests')] || self.lang.none) + ')';
        }
      }
    }
    $(template(params)).appendTo(link).css({
      top: position.top + height + wrapper.scrollTop() + marginTop,
      left: position.left
    });
  };

  /**
   * Opens the link toolbar
   */
  this.showImageToolbar = function () {
    var image = self.target.find('.selected-image'),
      template = '<i class="fa fa-image"></i> {{width}} &times; {{height}} <br>&bull; <span data-change="image" data-image={{ref}}>{{replace}}</span> <br>&bull; <span data-action="addImageLink">{{defineLink}}</span>',
      params = {
        replace: self.lang.replaceImage,
        applyProduct: self.lang.applyProduct,
        ref: JSON.stringify(image.data('ref')),
        width: image.width(),
        height: image.height(),
        defineLink: self.lang.defineLink
      },
      position = image.position(),
      height = image.outerHeight(),
      marginTop = parseInt(image.css('margin-top'), 10),
      marginLeft = parseInt(image.css('margin-left'), 10),
      wrapper = self.findByData('role', 'wrapper');
    if (image.closest('.selected-image-container').length || image.closest('a').length) {
      return false;
    }
    image.wrap('<div class="selected-image-container"></div>');
    $('<div class="image-toolbar">' + self.template(template, params) + '</div>').insertAfter(image).css({
      top: position.top + height + wrapper.scrollTop() + parseInt(image.css('margin-top'), 10),
      left: position.left + marginLeft
    });
  };


  this.addImageLink = function () {
    var image = self.target.find('.selected-image');
    self.selectElement(image.data('ref'));
    self.setElementLink();
  }

  /**
   * Changes the URL of a link inside an content editable
   */
  this.changeLink = function () {
    var link = self.target.find('.selected-link'),
      element = link.closest('[data-element]');
    if (!element.length) {
      element = link.find('img');
    }
    if (element.data('editable')) {
      self.selectElement(element.data('ref'));
      self.prompt('url', self.lang.url, link.attr('href'), self.lang.urlExample, false, function (value, propagate, prompt) {
        link.attr('href', value.trim());
        element.trigger('keyup');
        self.saveBackup();
        prompt.modal('hide');
      });
    } else if (element.data('type') === 'button') {
      self.selectElement(element.data('ref'));
      self.defineTextAndLink();
    } else {
      self.selectElement(element.data('ref'));
      self.setElementLink();
    }
  };

  /**
   * Removes the URL of a link inside an content editable
   */
  this.removeLink = function () {
    var link = self.target.find('.selected-link'),
      element = link.closest('[data-element]'),
      ref;
    if (!element.length) {
      element = link.find('img');
    }
    if (element.data('editable')) {
      link.contents().unwrap();
      self.closeLinkToolbar();
      element.trigger('keyup');
      self.saveBackup();
    } else {
      ref = element.data('ref');
      self.updateNode(ref, 'attr', 'href', '');
      self.updateNode(ref, 'attr', 'link', '');
      self.render();
      self.saveBackup();
    }
  };

  /**
   * Opens the URL of a link inside an content editable in a new tab
   */
  this.testLink = function () {
    var link = self.target.find('.selected-link');
    try {
      window.open(link.attr('href'));
    } catch (e) {
      console.log('invalid link');
    }
  };

  /**
   * Changes the URL of a link inside an content editable
   */
  this.changeLinkColor = function () {
    var link = self.target.find('.selected-link'),
      element = link.closest('[data-element]'),
      current = link.css('color') || element.attr.linkcolor,
      legend = self.lang.linksColor,
      propagate = self.lang.applyToAllLinks,
      transparent = false;
    if (element.data('editable')) {
      self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
        link.find('font[color]').contents().unwrap();
        link.css('color', color);
        element.trigger('keyup');
        self.updateNode(self.selected, 'attr', 'linkcolor', color);
        self.render();
        if (propagate) {
          self.target.find('[data-editable]').find('a').css('color', color);
          self.target.find('[data-editable]:has(a)').each(function () {
            self.updateNode($(this).data('ref'), 'attr', 'html', $(this).html());
          });
          self.propagateElementSettings('attr', 'linkcolor', true);
          self.render();
        }
        self.saveBackup();
        colorPicker.modal('hide');
      });
    }
  };

  /**
   * Changes the interests of the selected link
   */
  this.changeLinkInterests = function () {
    var link = self.target.find('.selected-link'),
      element = link.closest('[data-element]');
    if (!element.length) {
      element = link.find('img');
    }
    if (element.data('editable')) {
      self.selectElement(element.data('ref'));
      self.defineLinkInterests(link, element);
    } else if (element.data('type') === 'button') {
      self.selectElement(element.data('ref'));
      self.defineLinkInterests();
    } else {
      self.selectElement(element.data('ref'));
      self.defineLinkInterests();
    }
  };

  /**
   * Closes the link toolbar
   */
  this.closeLinkToolbar = function () {
    self.target.find('.selected-image').removeClass('selected-image');
    self.target.find('.selected-link').removeClass('selected-link');
    self.target.find('.link-toolbar').remove();
  };

  /**
   * Closes the image toolbar
   */
  this.closeImageToolbar = function () {
    self.target.find('.selected-image').removeClass('selected-image');
    self.target.find('.image-toolbar').remove();
    self.target.find('.selected-image-container').find('img').unwrap();
  };

  /**
   * Closes all opened dropdowns
   */
  this.closeDropdowns = function () {
    var opened = self.target.find('.btn-group.open');
    opened.find('button').dropdown('toggle');
  };

  /**
   * Returns a region from the email preview
   * @param {Object} ref
   * @return {Object} jQuery Object
   */
  this.findRegion = function (ref) {
    return self.target.find('[data-region=' + ref.region + ']');
  };

  /**
   * Returns a cell from the email preview
   * @param {Object} ref
   * @return {Object} jQuery Object
   */
  this.findCell = function (ref) {
    return self.findRegion(ref).find('[data-cell=' + ref.cell + ']');
  };

  /**
   * Returns an element from the email preview
   * @param {Object} ref
   * @return {Object} jQuery Object
   */
  this.findElement = function (ref) {
    return self.findCell(ref).find('[data-element=' + ref.element + ']');
  };

  /**
   * Returns the selected element from the email preview
   * @param {Object} ref
   * @return {Object} jQuery Object
   */
  this.findSelectedElement = function () {
    return self.findElement(self.selected);
  };

  /**
   * Returns the selected region from the email preview
   * @param {Object} ref
   * @return {Object} jQuery Object
   */
  this.findSelectedRegion = function () {
    return self.findRegion(self.selected);
  };

  /**
   * Returns a node from the email content
   * @param {Object} ref
   * @return {Object}
   */
  this.getNode = function (ref) {
    var cell = self.getCell(ref).contents;
    if (cell) {
      return cell[ref.element];
    }
  };

  /**
   * Returns a region from the email content
   * @param {Object} ref
   * @return {Object}
   */
  this.getRegion = function (ref) {
    var content = self.content,
      defaultRef = {
        region: 0,
        cell: 0,
        element: 0
      };
    ref = $.extend(true, {}, defaultRef, ref);
    if (content.regions !== undefined && content.regions[ref.region] !== undefined) {
      return content.regions[ref.region];
    } else {
      return false;
    }
  };

  /**
   * Returns a cell from the email content
   * @param {Object} ref
   * @return {Object}
   */
  this.getCell = function (ref) {
    var content = self.content,
      defaultRef = {
        region: 0,
        cell: 0,
        element: 0
      };
    ref = $.extend(true, {}, defaultRef, ref);
    if (content.regions !== undefined && content.regions[ref.region] !== undefined && content.regions[ref.region].cells !== undefined && content.regions[ref.region].cells[ref.cell] !== undefined) {
      return content.regions[ref.region].cells[ref.cell];
    } else {
      return false;
    }
  };

  /**
   * Shortcut to update both the email preview and content
   * @param {Object} ref
   * @param {String} type
   * @param {String} property
   * @param {String} value
   * @param {String} preset
   */
  this.updateContent = function (ref, type, property, value, preset) {
    self.updateElement(ref, type, property, value, preset);
    self.updateNode(ref, type, property, value, preset);
    self.render();
    self.saveBackup();
  };

  /**
   * Updates a property of an element in the email preview
   * @param {Object} ref
   * @param {String} type
   * @param {String} property
   * @param {String} value
   * @param {String} preset
   */
  this.updateElement = function (ref, type, property, value, preset) {
    var element = self.findElement(ref);
    if (preset && self.stringIsEmpty(value)) {
      value = preset;
    }
    if (type === 'attr') {
      switch (property) {
        case 'text':
          element.text(value);
          break;
        case 'html':
          element.html(value);
          break;
        case 'linkcolor':
          element.find('a').css('color', value);
          break;
        default:
          element.attr(property, value);
      }
    }
    if (type === 'style') {
      if (($.inArray(property, self.integers)) > -1) {
        value = parseInt(value, 10);
      }
      element.css(property, value);
    }
  };

  /**
   * Updates a property of an element in the email content
   * @param {Object} ref
   * @param {String} type
   * @param {String} property
   * @param {String} value
   * @param {String} preset
   */
  this.updateNode = function (ref, type, property, value, preset) {
    var node = self.getNode(ref);
    if (!ref || !node) {
      return false;
    }
    if (preset && self.stringIsEmpty(value)) {
      value = preset;
    }
    node[type][property] = value;
  };

  /**
   * Triggers the file input to choose a JSON file
   */
  this.selectJson = function () {
    self.findByData('role', 'opener').trigger('click');
  };

  /**
   * Imports a JSON file and renders
   */
  this.importJson = function (file) {
    var reader = new FileReader();

    // Define a closure to capture the file content
    reader.onload = (function () {
      return function (e) {
        try {
          var json = JSON.parse(e.target.result);
        } catch (e) {
          alert(self.lang.invalidJson);
          return false;
        }
        if (self.importing === 'template') {
          self.content = json;
        }
        if (self.importing === 'region') {
          if (!json.regions) {
            alert(self.lang.invalidJson);
            return false;
          }
          self.importRegions(json.regions);
        }
        self.importing = 'template';
        self.render();
      };
    })(file);

    // Read the image file
    reader.readAsText(file);
  };

  /**
   * Triggers the file input to choose an image
   */
  this.selectImage = function () {
    self.findByData('role', 'uploader').trigger('click');
  };

  /**
   * Creates a reference string
   * @param {String} region
   * @param {String} cell
   * @param {String} element
   * @return {String}
   */
  this.setElementReference = function (region, cell, element) {
    return JSON.stringify({
      region: region || 0,
      cell: cell || 0,
      element: element || 0
    });
  };

  /**
   * Sets the link for an element
   */
  this.setElementLink = function () {
    var node = self.getNode(self.selected),
      current,
      placeholder = self.lang.urlExample,
      attr;
    if (node.type === 'image') {
      attr = 'link';
    } else {
      attr = 'href';
    }
    current = node.attr[attr] || '';
    self.prompt('url', self.lang.url, current, placeholder, false, function (value, propagate, prompt) {
      self.updateContent(self.selected, 'attr', attr, value.trim());
      prompt.modal('hide');
    });
  };

  /**
   * Sets the link for an element
   */
  this.setElementHtml = function () {
    var node = self.getNode(self.selected),
      current = node.attr.html;
    self.prompt('html', self.lang.freeCode, current, self.lang.freeCode, false, function (value, propagate, prompt) {
      self.updateContent(self.selected, 'attr', 'html', value);
      prompt.modal('hide');
    });
  };

  /**
   * Sets the text for an element
   */
  this.setElementText = function () {
    var node = self.getNode(self.selected),
      legend = self.lang.buttonText,
      current = node.attr.text || '',
      placeholder = self.lang.buttonTextPlaceholder;
    self.prompt('text', legend, current, placeholder, false, function (value, propagate, prompt) {
      self.updateContent(self.selected, 'attr', 'text', value);
      prompt.modal('hide');
    });
  };

  /**
   * Sets the font for an element
   */
  this.setElementFont = function (font) {
    self.updateContent(self.selected, 'style', 'fontFamily', font);
  };

  /**
   * Sets the font size for an element
   */
  this.setElementFontSize = function (size) {
    self.updateContent(self.selected, 'style', 'fontSize', size);
  };

  /**
   * Sets the color style for an element
   */
  this.setElementColor = function () {
    var node = self.getNode(self.selected),
      legend = self.lang.fontColor,
      current = node.style.color || '',
      propagate = false,
      transparent = false;
    self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
      self.updateContent(self.selected, 'style', 'color', color);
      colorPicker.modal('hide');
    });
  };

  this.lineHeight = function (value) {
    self.updateContent(self.selected, 'style', 'lineHeight', value);
  };

  /**
   * Sets the line height of a text
   */
  this.setLineHeight = function () {
    var modal = self.findByData('modal', 'lineHeight'),
      radios = modal.find('input'),
      value = self.getNode(self.selected).style.lineHeight || '1.35';
    self.closeDropdowns();
    radios.each(function () {
      if (value === $(this).attr('value')) {
        $(this).trigger('click');
      } else {
        $(this).removeAttr('checked');
      }
    });
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      var form = $(e.currentTarget),
        value = form.find('input[type=radio]:checked').val();
      e.preventDefault();
      self.updateContent(self.selected, 'style', 'lineHeight', value);
      modal.modal('hide');
      modal.off('submit', 'form');
    });
  };

  /**
   * Sets the background color style for an element
   */
  this.setElementBackgroundColor = function () {
    var node = self.getNode(self.selected),
      legend = self.lang.backgroundColor,
      current = node.style.backgroundColor || '',
      transparent = false,
      propagate = self.lang.applyToAllButtons;
    if (node.type === 'separator') {
      propagate = self.lang.applyToAllSeparators;
    }
    self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
      self.updateNode(self.selected, 'style', 'backgroundColor', color);
      if (propagate) {
        self.propagateElementSettings('style', 'backgroundColor');
      } else {
        self.render();
        self.saveBackup();
      }
      colorPicker.modal('hide');
    });
  };

  /**
   * Sets the alt and src attributes for an element
   */
  this.setElementAlt = function () {
    var modal = self.findByData('modal', 'source'),
      node = self.getNode(self.selected),
      src = modal.find('[name=src]'),
      alt = modal.find('[name=alt]');
    src.attr('value', node.attr.src).val(node.attr.src);
    alt.attr('value', node.attr.alt).val(node.attr.alt);
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      e.preventDefault();
      self.updateContent(self.selected, 'attr', 'src', src.val());
      self.updateContent(self.selected, 'attr', 'alt', alt.val());
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('submit', 'form');
    });
  };

  /**
   * Sets the border radius style for an element
   */
  this.setElementBorderRadius = function () {
    var node = self.getNode(self.selected),
      legend = self.lang.borderRadius,
      current = node.style.borderRadius || 0,
      placeholder = self.lang.borderRadiusPlaceholder,
      propagate = self.lang.applyToAllButtons;
    self.prompt('number', legend, current, placeholder, propagate, function (value, propagate, prompt) {
      self.updateNode(self.selected, 'style', 'borderRadius', parseInt(value, 10));
      if (propagate) {
        self.propagateElementSettings('style', 'borderRadius');
      } else {
        self.render();
        self.saveBackup();
      }
      prompt.modal('hide');
    });
  };

  /**
   * Sets the height style for an element
   */
  this.setElementHeight = function () {
    var node = self.getNode(self.selected),
      legend = self.lang.separatorHeight,
      current = node.style.height || false,
      placeholder = self.lang.separatorHeightPlaceholder;
    self.prompt('number', legend, current, placeholder, false, function (value, propagate, prompt) {
      self.updateContent(self.selected, 'style', 'height', parseInt(value, 10));
      prompt.modal('hide');
    });
  };

  /**
   * Copy an element property to other elements
   * @param {String} type
   * @param {String} property
   * @param {Boolean} all
   */
  this.propagateElementSettings = function (type, property, all) {
    var regions = self.content.regions,
      node = self.getNode(self.selected);
    $.each(regions, function (index, region) {
      $.each(region.cells, function (index, cell) {
        $.each(cell.contents, function (index, element) {
          if (all || element.type === node.type) {
            if (element[type] !== undefined && node !== undefined && node[type] !== undefined) {
              element[type][property] = node[type][property];
            }
          }
        });
      });
    });
    self.render();
    self.saveBackup();
  };

  /**
   * Adds an element to the email preview and content
   * @param {String} type
   */
  this.addElement = function (type) {
    var node = $.extend(true, {}, self.components.elements[type]),
      siblings = self.getCell(self.selected).contents;
    if (siblings && $.isArray(siblings)) {
      $.each(siblings, function () {
        if (this.type === type) {
          node.style = this.style;
        }
      });
      siblings.splice(self.selected.element + 1, 0, node);
    }
    self.render();
    self.selectElement({
      region: self.selected.region,
      cell: self.selected.cell,
      element: self.selected.element + 1
    });
    self.findByData('modal', 'elements').modal('hide');
    self.saveBackup();
  };

  /**
   * Moves the selected element down
   */
  this.moveElementDown = function () {
    var elements = self.getCell(self.selected).contents,
      element = self.selected.element,
      node = self.getNode(self.selected),
      next = element + 1;
    if (next >= elements.length) {
      return false;
    }
    elements.splice(element, 1);
    elements.splice(next, 0, node);
    self.selected = {
      region: self.selected.region,
      cell: self.selected.cell,
      element: next
    };
    self.render();
    self.saveBackup();
  };

  /**
   * Moves the selected element down
   */
  this.moveElementUp = function () {
    var elements = self.getCell(self.selected).contents,
      element = self.selected.element,
      node = self.getNode(self.selected),
      prev = element - 1;
    if (prev < 0) {
      return false;
    }
    elements.splice(element, 1);
    elements.splice(prev, 0, node);
    self.selected = {
      region: self.selected.region,
      cell: self.selected.cell,
      element: prev
    };
    self.render();
    self.saveBackup();
  };

  /**
   * Destroys a single element
   */
  this.destroyElement = function (confirm) {
    var selected = self.selected,
      regions = self.content.regions,
      cells = regions[selected.region].cells,
      elements = cells[selected.cell].contents,
      question = self.lang.destroyElementConfirmation,
      callback = function () {
        elements.splice(selected.element, 1);
        if (!elements.length) {
          cells.splice(selected.cell, 1);
        }
        if (!cells.length) {
          self.destroyRegion();
        } else {
          self.render();
          self.saveBackup();
        }
      };
    if (confirm) {
      self.confirm(question, callback);
    } else {
      callback();
    }
  };

  /**
   * Sets the email max width
   */
  this.setEmailWidth = function () {
    var legend = self.lang.emailWidth,
      current = self.content.settings.width || false,
      placeholder = self.lang.widthPlaceholder;
    self.editingSettings = true;
    self.prompt('number', legend, current === '100%' ? 660 : current, placeholder, self.lang.emailFullWidth, function (value, fullWidth, prompt) {
      var width = parseInt(value, 10);
      if (fullWidth) {
        width = '100%'
      }
      self.content.settings.width = width;
      self.render();
      self.editingSettings = false;
      self.target.find('img').each(function () {
        if ($(this).width() > width) {
          alert(self.template(self.lang.emailWidthAlert, {
            width: width
          }));
          return false;
        }
      });
      $(window).resize();
      self.saveBackup();
      prompt.modal('hide');
    }, self.lang.emailWidthInstructions, current === '100%');
  };

  /**
   * Sets the email max width
   */
  this.setEmailBackgroundColor = function () {
    var legend = self.lang.emailBackgroundColor,
      current = self.content.settings.backgroundColor || false,
      transparent = false,
      propagate = false;
    self.editingSettings = true;
    self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
      self.content.settings.backgroundColor = color;
      self.render();
      self.editingSettings = false;
      self.saveBackup();
      colorPicker.modal('hide');
    });
  };

  /**
   * Sets the email background image
   */
  this.setEmailBackgroundImage = function () {
    self.editingSettings = true;
    self.openImageModal();
    self.showImageAlbum('public', self.options.publicBackgroundsPath);
  };

  /**
   * Removes the email background image
   */
  this.removeEmailBackgroundImage = function () {
    self.content.settings.backgroundImage = '';
    self.closeImageModal();
    self.render();
  };

  /**
   * Sets the region background image
   */
  this.setRegionBackgroundImage = function () {
    self.editingRegion = true;
    self.openImageModal();
  };

  /**
   * Removes the region background image
   */
  this.removeRegionBackgroundImage = function () {
    var region = self.selected.region,
      content = self.content,
      selectedRegion = content.regions[region];
    selectedRegion.attr = selectedRegion.attr || {};
    selectedRegion.style = selectedRegion.style || $.extend(true, {}, self.defaults.region.style);
    delete selectedRegion.attr.background;
    delete selectedRegion.style.backgroundImage;
    self.closeImageModal();
    self.render();
  };

  /**
   * Performs an action
   * @param {String} command
   * @param {String} param
   */
  this.action = function (action, param) {
    self.closeDropdowns();
    if (self[action] !== undefined) {
      self[action](param);
    }
  };

  /**
   * Cancels the edition
   */
  this.cancel = function () {
    if (self.isDirty) {
      self.confirm(self.lang.cancelConfirmation, function () {
        var timeout;
        self.isDirty = false;
        timeout = window.setTimeout(function () {
          if (self.options.cancelCallback(self, self.html(), self.json())) {
            self.close();
          }
        }, 500);
      });
    } else {
      if (self.options.cancelCallback(self, self.html(), self.json())) {
        self.close();
      }
    }
  };

  /**
   * Applies a content editable command to the selected text
   * @param {String} command
   * @param {String} param
   */
  this.command = function (command, param) {
    var legend,
      element = self.findSelectedElement(),
      text,
      current,
      placeholder,
      propagate,
      links,
      temp,
      spanString,
      requireSelectedText = [
        'fontName',
        'fontSize',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'createLink'
      ],
      selection = self.getSelectionText(),
      defaultLink = self.stringContains(selection, ['.']) ? selection : '';
    self.closeDropdowns();
    if (($.inArray(command, requireSelectedText) > -1) && (!selection)) {
      alert(self.lang.pleaseSelectSomething);
      return false;
    }
    if (command === 'createLink') {
      document.execCommand('createLink', false, 'temp');
      temp = element.find('[href=temp]');
      if (!temp.length || !temp.text() || temp.text() === 'temp') {
        temp.remove();
        return false;
      }
      self.prompt('url', self.lang.url, defaultLink, self.lang.urlExample, false, function (value, propagate, prompt) {
        temp.attr('href', value.trim()).css({
          color: element.attr('linkcolor'),
          textDecoration: 'none'
        });
        element.trigger('keyup');
        self.saveBackup();
        prompt.modal('hide');
      });
    } else {
      document.execCommand(command, false, param);
      element.trigger('keyup');
      self.oldText = element.html();
      self.saveBackup();
    }
  };

  /**
   * Wraps the selected text in a selected span
   */
  this.getSelectedText = function () {
    var element = self.findSelectedElement(),
      sub,
      selection,
      style;
    document.execCommand('subscript', false);
    sub = element.find('sub');
    if (!sub.length || !sub.text()) {
      sub = element.find('[style*="vertical-align: sub"]');
    }
    if (!sub.length || !sub.text()) {
      return false;
    }
    sub.wrap('<span class="selection">');
    selection = element.find('.selection');
    selection.find('sub').contents().unwrap();
    if (selection.find('[style]').length) {
      selection.find('[style]').each(function () {
        style = $(this).attr('style');
        $(this).attr('style', style.replace('vertical-align: sub;', ''));
      });
    }
    return selection;
  };

  /**
   * Changes the font color of the selected text
   */
  this.changeFontColor = function () {
    var element = self.findSelectedElement(),
      legend = self.lang.fontColor,
      current = '',
      propagate = false,
      transparent = false,
      selection;
    self.closeDropdowns();
    selection = self.getSelectedText();
    if (!selection || !selection.length || !selection.text()) {
      alert(self.lang.pleaseSelectSomething);
      return false;
    }
    selection.find('font[color]').contents().unwrap();
    selection.find('[style]').each(function () {
      if (!$(this).is('a')) {
        $(this).css('color', '');
      }
    });
    self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
      selection.css('color', color).removeAttr('class');
      element.trigger('keyup');
      self.saveBackup();
      colorPicker.modal('hide');
    });
  };

  /**
   * Changes the font size of the selected text
   */
  this.changeFontSize = function (param) {
    var element = self.findSelectedElement(),
      selection;
    self.closeDropdowns();
    selection = self.getSelectedText();
    if (!selection || !selection.length || !selection.text()) {
      alert(self.lang.pleaseSelectSomething);
      return false;
    }
    selection.find('font[size]').contents().unwrap();
    selection.find('[style]').each(function () {
      var style = $(this).attr('style');
      style = style.replace(/font-size: [0-9]+(px)?;?/g, '');
      if (style) {
        $(this).attr('style', style);
      } else {
        $(this).removeAttr('style');
      }
    });
    selection.css('font-size', param).removeAttr('class');
    element.trigger('keyup');
    self.saveBackup();
  };

  /**
   * Selects an element and shows its toolbar
   * @param {Object} ref
   */
  this.selectElement = function (ref) {
    var element,
      region,
      cell,
      selectedRegion,
      wrapper = self.findByData('role', 'wrapper'),
      regionToolbar = self.findByData('toolbar', 'region'),
      moveElementUp = self.findByData('action', 'moveElementUp'),
      moveElementDown = self.findByData('action', 'moveElementDown'),
      elementSorter = self.findByData('role', 'elementSorter'),
      page = self.target.find('.eddie-page'),
      top = 0,
      maxTop;

    ref = $.extend({}, {
      region: 0,
      cell: 0,
      element: 0
    }, ref);

    self.closeDropdowns();
    self.unselectElement();
    self.findByData('template-title').addClass('hide');
    self.selected = ref;
    self.selectedRegion = ref.region;
    element = self.findSelectedElement();
    region = self.findSelectedRegion();
    selectedRegion = self.getRegion(self.selected);

    if (self.hasProducts && selectedRegion && selectedRegion.productsCount) {
      if (selectedRegion.hasProductsApplied) {
        self.applyProductButton.hide();
        self.removeProductButton.show();
      } else {
        self.applyProductButton.show();
        self.removeProductButton.hide();
      }
    } else {
      self.applyProductButton.hide();
      self.removeProductButton.hide();
    }

    if (element.data('type') !== 'html') {
      element.addClass('selected-element');
    }
    region.addClass('selected-region');
    if (region.position()) {
      top = region.position().top;
    }
    top = top + wrapper.scrollTop();
    maxTop = page.height() - self.details.regionToolbarHeight;
    if (top > maxTop) {
      top = maxTop + 25;
    }
    if (top < 25) {
      top = 25;
    }
    regionToolbar.css('top', top).removeClass('hide');
    self.target.find('[data-control~=' + element.data('type') + ']').removeClass('hide');
    if (self.selected.element === 0) {
      moveElementUp.addClass('hide');
    } else {
      moveElementUp.removeClass('hide');
    }
    cell = self.getCell(self.selected);
    if (cell !== undefined && cell && cell.contents !== undefined && cell.contents && (self.selected.element === cell.contents.length - 1)) {
      moveElementDown.addClass('hide');
    } else {
      moveElementDown.removeClass('hide');
    }
    elementSorter
      .find('button')
      .filter(':visible')
      .first()
      .addClass('first')
      .siblings()
      .removeClass('first');
    $(window).resize();
  };

  /**
   * Unselects all elements
   */
  this.unselectElement = function () {
    var self = this,
      selected = $.extend(true, {}, {
        region: 0,
        cell: 0,
        element: 0
      }, self.selected),
      element = self.findElement(selected);
    // IE
    if ((navigator.appVersion.indexOf("MSIE") > -1) && element.data('editable')) {
      self.updateNode(selected, 'attr', 'html', element.html());
    }
    self.selected = false;
    window.getSelection().removeAllRanges();
    self.target.find('.selected-region').removeClass('selected-region');
    self.target.find('.selected-element').removeClass('selected-element');
    self.findByData('toolbar', 'region').addClass('hide');
    self.findByData('control').addClass('hide');
    self.findByData('template-title').removeClass('hide').text(self.options.title);
  };

  /**
   * Adds a region to the email preview and content
   * @param {String} type
   */
  this.addRegion = function (type) {
    var selectedRegion,
      insertedRegion,
      node = $.extend(true, {}, self.components.regions[type]);
    if (self.selected) {
      selectedRegion = self.selected.region || 0;
      insertedRegion = selectedRegion + 1;
    } else {
      insertedRegion = 0;
    }
    self.content.regions.splice(insertedRegion, 0, node);
    self.render();
    self.selectElement({ region: insertedRegion });
    self.findByData('modal', 'regions').modal('hide');
    self.saveBackup();
  };

  /**
   * Adds a region to the email preview and content
   * @param {String} type
   */
  this.importRegions = function (regions) {
    var selectedRegion,
      placing;
    if (self.selected) {
      selectedRegion = self.selected.region || 0;
      placing = selectedRegion + 1;
    } else {
      placing = 0;
    }
    self.content.regions.splice(placing, 0, regions);
    self.render();
    self.selectElement({ region: placing });
    self.saveBackup();
  };

  /**
   * Removes a region from the email preview and content
   */
  this.destroyRegion = function (confirm) {
    var content = self.content,
      region = self.selected.region,
      element = self.findSelectedRegion(),
      question = self.lang.destroyRegionConfirmation,
      callback = function () {
        content.regions.splice(region, 1);
        if ($.isPlainObject(self.selected)) {
          element.animate({
            opacity: 0
          }, 200, function () {
            var height = element.height();
            element.css('height', height).find('td').empty();
            element.animate({
              height: 0
            }, 200, function () {
              element.remove();
              self.unselectElement();
              self.render();
              self.saveBackup();
              $('.tooltip').hide();
            });
          });
        }
      };
    if (confirm) {
      self.confirm(question, callback);
    } else {
      callback();
    }
  };

  /**
   * Duplicates the selected region
   */
  this.cloneRegion = function () {
    var region = self.selected.region,
      content = self.content,
      clone = $.extend(true, {}, content.regions[region]);
    if (clone.backupCells) {
      clone.cells = $.extend(true, [], clone.backupCells);
      delete clone.selectedProducts;
      delete clone.backupCells;
      delete clone.products;
      clone.hasProductsApplied = false;
    }
    content.regions.splice(region, 0, clone);
    self.render();
    self.saveBackup();
    self.selectElement({ region: region });
  };

  /**
   * Moves the selected region down
   */
  this.moveRegionDown = function () {
    var selected = self.selected,
      region = self.selected.region,
      content = self.content,
      node = $.extend(true, {}, content.regions[region]),
      next = region + 1;
    if (next >= content.regions.length) {
      return false;
    }
    content.regions.splice(region, 1);
    content.regions.splice(next, 0, node);
    self.selected = {
      region: next,
      cell: self.selected.cell,
      element: self.selected.element
    };
    self.render();
    self.saveBackup();
  };

  /**
   * Moves the selected region up
   */
  this.moveRegionUp = function () {
    var region = self.selected.region,
      content = self.content,
      node = $.extend(true, {}, content.regions[region]),
      prev = region - 1;
    if (prev < 0) {
      return false;
    }
    content.regions.splice(region, 1);
    content.regions.splice(prev, 0, node);
    self.selected = {
      region: prev,
      cell: self.selected.cell,
      element: self.selected.element
    };
    self.render();
    self.saveBackup();
  };

  /**
   * Sets the selected region background color
   */
  this.paintRegion = function () {
    var region = self.selected.region,
      content = self.content,
      selectedRegion = content.regions[region],
      legend = self.lang.regionBackground,
      current = false,
      transparent = true,
      propagate = self.lang.applyToAllRegions;
    selectedRegion.style = selectedRegion.style || {};
    current = selectedRegion.style.backgroundColor || false;
    self.colorPicker(legend, current, propagate, transparent, function (color, propagate, colorPicker) {
      selectedRegion.style.backgroundColor = color;
      if (propagate) {
        $.each(self.content.regions, function (index, region) {
          if (region.style === undefined) {
            region.style = {};
          }
          region.style.backgroundColor = color;
        });
      }
      self.render();
      self.saveBackup();
      colorPicker.modal('hide');
    });
  };

  this.removeCommentsAndStyleTags = function (string) {
    string = string.replace(/(\\n)+/gm, ' ');
    string = string.replace(/<!--[\s\S]*?-->/gm, '');
    string = string.replace(/<(o:p|style|xml)>[\s\S]*?<\/(o:p|style|xml)>/gm, '');
    //fix broken freemarker code
    string = string.replace(/(\$\{[^\{\}]+\})/gm, function (match) {
      return match.replace(/<[^<>]+>/gm, '');
    });
    //remove empty a/span tags
    string = string.replace(/<(span|a)[^>]*>\s*<\/[^>]+>/gm, '');
    return string;
  };

  this.removeUnreplacedProductLinks = function (string) {
    //remove {product.*.link}
    string = string.replace(/{{product\.\d\.link}}/gm, '');
    return string;
  }

  /**
   * Returns the email content JSON as an string
   * @return {String}
   */
  this.json = function () {
    return this.removeCommentsAndStyleTags(JSON.stringify(self.content));
  };

  /**
   * Returns the email preview HTML
   * @return {String}
   */
  this.html = function () {
    var wrapper,
      html;

    // Clone wrapper
    wrapper = self.findByData('role', 'preview').clone();

    // Remove toolbars
    wrapper.find('.selected-image').removeClass('selected-image');
    wrapper.find('.selected-link').removeClass('selected-link');
    wrapper.find('.link-toolbar').remove();

    // Apply email attributes
    wrapper.children('table').css({
      backgroundColor: self.content.settings.backgroundColor,
      backgroundImage: self.content.settings.backgroundImage
    });

    // Wrap images and links with margins in DIVs
    wrapper.find('img, a[data-type=button]').each(function () {
      var element = $(this),
        margin = $(this).css('margin'),
        toWrap = $(this);

      // If the element parent is a link
      if (element.is('img') && element.parent().is('a')) {
        toWrap = element.parent();
      }

      // If the element has margin, wrap it in a DIV
      if (margin !== '0px') {
        element.css('margin', 0);
        element.attr('data-margin', margin);
        toWrap.wrap('<div class="eddie-wrapper" style="display: inline-block; margin: ' + margin + '">');
      }
    });

    // Change element type of wrappers from TDs with only wrapped elements of a unique type
    wrapper.find('td').each(function (index, td) {
      var children = $(td).children(),
        childrenCount = children.length,
        wrappersCount = 0,
        region = $(td).closest('[data-region]').data('region');

      let cell = $(td).data('cell');
      cell = self.getCell({ region: region, cell: cell });

      if (_.uniq(_.pluck(cell.contents, 'type')).length === 1) {
        children.each(function () {
          if ($(this).is('.eddie-wrapper')) {
            wrappersCount++;
          }
        });
        if (wrappersCount > 1 && childrenCount > 1 && wrappersCount === childrenCount) {
          children.changeElementType('span');
        }
      }
    });

    // Use border instead of padding in buttons (Outlook bug)
    wrapper.find('a[data-type=button]').each(function () {
      var button = $(this),
        paddingTop = button.css('paddingTop'),
        paddingLeft = button.css('paddingLeft'),
        backgroundColor = button.css('backgroundColor');
      button.css({
        'border-width': paddingTop + ' ' + paddingLeft,
        'border-color': backgroundColor,
        'padding': 0
      });
    });

    // Parse inline style
    wrapper.find('[style]').each(function (index, el) {

      // Replace 'margin' by 'Margin' (Outlook)
      var style = $(el).attr('style');
      style = style.replace(new RegExp('margin', 'g'), 'Margin');

      // Replace text-decoration-line by text-decoration
      style = style.replace(new RegExp('text-decoration-line', 'g'), 'text-decoration');

      // Merge border styles
      if (self.stringContains(style, ['border-width: 0px'])) {
        style = 'border: 0px solid transparent;' + style.replace(new RegExp('border-(width|color|style): [a-zA-Z0-9,\(\)\# ]+;? ?', 'g'), '');
      }

      //Replace background-color: initial
      style = style.replace(new RegExp('background-color: initial;?\s?', 'g'), '');

      // Assign style
      if (style.trim() !== '') {
        $(el).attr('style', style);
      } else {
        //Remove if empty
        $(el).removeAttr('style');
      }
    });

    // Remove variable images placeholders
    wrapper.find('[data-src]').each(function () {
      $(this).attr('src', $(this).data('src'));
    });

    // Clean up
    $.each(self.cleanUp, function () {
      wrapper.find('[' + this + ']').removeAttr(this);
    });

    // Wrap conditional components in DIVs
    wrapper.find('[data-if]').each(function () {
      var region = $(this),
        toWrap = region.closest('table'),
        condition = region.data('if');
      toWrap.wrap('<div data-if="' + condition + '">');
      region.removeAttr('data-if');
    });

    // Remove comments and style
    html = this.removeUnreplacedProductLinks(this.removeCommentsAndStyleTags(wrapper.html()));

    // Add conditionals
    var regex = /<div data-if="([^"]*)">(.+?)(<\/table><\/div>)/gm;
    var subst = `[#if $1]$2</table>[/#if]`;
    html = html.replace(regex, subst);

    wrapper.remove();
    return html;
  };

  /**
   * Executes the provided save callback
   */
  this.save = function () {
    app.integrations.amplitude.event("APP_EDDIE_SAVE");
    app.integrations.intercom.event("APP_EDDIE_SAVE");
    self.closeImageToolbar();
    self.closeLinkToolbar();
    self.shouldClose = true;
    self.options.callback(self, self.html(), self.json());
  };

  /**
   * Closes the editor
   */
  this.close = function () {
    if (self.target !== undefined && self.target.length) {
      self.target.remove();
    }
    //self.emojipicker.remove();

    //unbind paste event
    $(document).off('paste', '[data-editable]');

    self.closed = true;
    window.onbeforeunload = null;
    if (self.timer) {
      clearTimeout(self.timer);
    }
  };

  /**
   * Opens a modal to input a value that is sent to a callback
   * @param {String} type
   * @param {String} legend
   * @param {String} value
   * @param {String} placeholder
   * @param {Boolean} propagate
   * @param {Function} callback
   */
  this.prompt = function (type, legend, value, placeholder, propagate, callback, instructions = '', defaultCheckboxValue = false) {
    var prompt = self.findByData('modal', 'prompter'),
      label = self.findByData('role', 'prompterLabel'),
      checkbox = self.findByData('role', 'prompterCheckbox'),
      response = false,
      input,
      inactive,
      instructionsTag;
    if (type === 'html') {
      input = self.findByData('role', 'prompterTextarea');
      inactive = self.findByData('role', 'prompterInput');
    } else {
      input = self.findByData('role', 'prompterInput');
      inactive = self.findByData('role', 'prompterTextarea');
    }
    input.removeClass('hide');
    inactive.addClass('hide');
    label.text(legend);
    input.attr({
      placeholder: placeholder
    }).val(value || '');
    if (type === 'number') {
      input.attr('type', 'number');
    } else {
      input.attr('type', 'text');
    }
    if (propagate) {
      checkbox.removeClass('hide').find('span').text(propagate);
      checkbox.find('input')[0].checked = defaultCheckboxValue;
    } else {
      checkbox.addClass('hide');
    }
    instructionsTag = self.findByData('role', 'prompterInstructions');
    instructionsTag.html(instructions);
    prompt.modal('show');
    prompt.on('submit', 'form', function (e) {
      var propagate;
      e.preventDefault();
      response = input.val();
      response = self.validateField(type, response);
      if (!response && type !== 'condition') {
        return false;
      }
      propagate = checkbox.find('input').is(':checked');
      prompt.off('submit reset', 'form');
      checkbox.find('input').removeAttr('checked');
      callback(response, propagate, prompt);
    });
  };

  /**
   * Opens a modal to confirm an action
   * @param {String} question
   * @param {Function} callback
   */
  this.confirm = function (question, callback, titleText, acceptText, cancelText, cancelCallback) {
    var confirm = self.findByData('modal', 'confirm'),
      questionBox = confirm.find('.confirmation-text'),
      modalTitle = confirm.find('.modal-title'),
      acceptButton = confirm.find('button[type=submit]'),
      cancelButton = confirm.find('button[type=reset]'),
      accepted = false,
      response = false;

    titleText = titleText || self.lang.confirm;
    acceptText = acceptText || self.lang.accept;
    cancelText = cancelText || self.lang.cancel;

    questionBox.text(question + '.');
    modalTitle.text(titleText);
    acceptButton.text(acceptText);
    cancelButton.text(cancelText);
    confirm.modal('show');
    confirm.on('submit', 'form', function (e) {
      e.preventDefault();
      accepted = true;
      confirm.modal('hide');
    }).on('hidden.bs.modal', function (e) {
      confirm.off('submit reset', 'form');
      confirm.off('hidden.bs.modal');
      if (!accepted && cancelCallback) {
        cancelCallback();
      }
      if (accepted && callback) {
        callback();
      }
    });
  };

  /**
   * Validates a value
   * @param {String} type
   * @param {String} value
   * @return {String|boolean} Valid string or false
   */
  this.validateField = function (type, value) {
    var mailtoRegex = /^mailto:\S+@\S+\.\S+/,
      colorRegex = /^#?([0-9a-f]{3}){1,2}$/i;
    switch (type) {
      case 'video':
        if (!self.getYouTubeVideoId(value)) {
          alert(self.lang.invalidVideo);
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      case 'url':
        if (!self.stringStartsWith(value.trim(), ['http://', 'https://', 'mailto:', 'tel:', '$', 'whatsapp:', 'skype:', '${', '{{'])) {
          value = 'http://' + value.trim();
        }
        break;
      case 'number':
        if (!$.isNumeric(value)) {
          alert(self.lang.invalidNumber);
          return false;
        }
        break;
      case 'color':
        if (value !== 'transparent') {
          if (!colorRegex.test(value)) {
            alert(self.lang.invalidColor);
            return false;
          }
          if (!self.stringStartsWith(value, ['#'])) {
            value = '#' + value;
          }
        }
        break;
    }
    return value;
  };

  /**
   * Validates an image extension
   */
  this.validateImage = function (name) {
    return self.stringEndsWith(name.toLowerCase(), ['.jpg', '.png', '.gif', '.jpeg']);
  };

  /**
   * Validates an image size
   */
  this.validateImageSize = function (size) {
    return (size < window.config.imageMaxSize * 1000 * 1000) ? true : false;
  };

  /**
   * Validates an image size
   */
  this.getInvalidImageSizeMessage = function () {
    return [
      self.lang.invalidImageSize,
      window.config.imageMaxSize,
      'MB'
    ].join(' ');
  };

  /**
   * Opens a modal to update the margins of an element
   */
  this.defineMargins = function () {
    var modal = self.findByData('modal', 'margin'),
      inputs = modal.find('input'),
      node = self.getNode(self.selected);
    inputs.each(function () {
      var name = $(this).attr('name'),
        current = node.style[name] || 0;
      $(this).val(current);
    });
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      e.preventDefault();
      inputs.each(function () {
        var name = $(this).attr('name'),
          value = $(this).val();
        self.updateNode(self.selected, 'style', name, parseInt(value, 10));
      });
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('submit', 'form');
    });
  };

  /**
   * Opens a modal to update the size of an element
   */
  this.defineSize = function () {
    var modal = self.findByData('modal', 'resize'),
      inputs = modal.find('input'),
      checkbox = modal.find('[name=ratio]'),
      keepAspectRatio = checkbox.is(':checked'),
      heightInput = modal.find('[name=height]'),
      node = self.getNode(self.selected),
      values = {};
    inputs.each(function () {
      var name = $(this).attr('name'),
        current = node.style[name] || 1;
      if (current && current !== 'auto' && self.stringContains(current, ['px'])) {
        current = current.replace('px', '');
      }
      $(this).val(current);
    });
    if (node.style.height === undefined || node.style.height === 'auto') {
      checkbox.prop('checked', 'checked');
      heightInput.attr('disabled', 'disabled');
    }
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      var keepAspectRatio = modal.find('[name=ratio]').is(':checked');
      e.preventDefault();
      values.width = modal.find('[name=width]').val();
      values.height = modal.find('[name=height]').val();
      if (keepAspectRatio) {
        values.height = 'auto';
      }
      $.each(values, function (key, value) {
        if (value !== 'auto') {
          value = parseInt(value, 10) + 'px';
        }
        self.updateNode(self.selected, 'style', key, value);
        self.updateNode(self.selected, 'attr', key, value.replace('px', ''));
      });
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('submit', 'form');
    });
  };

  /**
   * Opens a modal to update the alignment of a cell
   */
  this.toggleAspectRatio = function () {
    var modal = self.findByData('modal', 'resize'),
      checkbox = modal.find(':checkbox');
    if (checkbox.is(':checked')) {
      modal.find('[name=height]').val('auto').attr('disabled', 'disabled');
    } else {
      modal.find('[name=height]').val(1).removeAttr('disabled');
    }
  };

  /**
   * Opens a modal to update the alignment of a cell
   */
  this.defineAlignment = function () {
    var modal = self.findByData('modal', 'alignment'),
      radios = modal.find('input'),
      selectedCell = self.getCell(self.selected),
      value = selectedCell.attr.align || 'left';
    radios.each(function () {
      if (value === $(this).attr('value')) {
        $(this).trigger('click');
      } else {
        $(this).removeAttr('checked');
      }
    });
    modal.modal('show');
    modal.on('click', 'label', function (e) {
      selectedCell.attr.align = $(this).find('input').val();
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('click', 'label');
    });
  };

  /**
   * Opens a modal to define the text and link of an element
   */
  this.defineTextAndLink = function () {
    var modal = self.findByData('modal', 'textLink'),
      inputs = modal.find('input'),
      node = self.getNode(self.selected);
    inputs.each(function () {
      var name = $(this).attr('name'),
        current = node.attr[name] || '';
      $(this).val(current);
    });
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      var form = $(this),
        href = self.validateField('url', form.find('[name=href]').val()),
        text = self.validateField('text', form.find('[name=text]').val());
      e.preventDefault();
      if (!href || !text) {
        return false;
      }
      self.updateNode(self.selected, 'attr', 'href', href);
      self.updateNode(self.selected, 'attr', 'text', text);
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('submit', 'form');
    });


    if (!self.options.allowFileUpload) {
      $('a[data-action=upload]').attr('title', self.lang.featureNotAvailable);

      // Show tooltips
      $('a[data-action=upload]').tooltip({
        container: 'body',
        trigger: 'hover',
        delay: 200,
        animation: false,
        placement: "bottom"
      });
    }

  };

  this.uploadFileChange = function (e) {
    const modal = self.findByData('modal', 'textLink');

    const files = e.currentTarget.files;

    if (files.length > 0) {
      const fd = new FormData();
      const file = files[0]

      if (file.name && !['.xls', '.xslx', '.doc', '.docx', '.pdf', '.png', '.gif', 'jpg', '.jpeg'].some(ext => file.name.includes(ext))) {
        alert(self.lang.uploadAllowedTypes);
        return;
      }
      if (file.size && file.size > 20 * 1024 * 1024) {
        alert(self.lang.uploadMaxSize);
        return;
      }

      fd.append('file', file);

      modal.find('#upload-instructions').hide();
      modal.find('#uploading').show();

      $.ajax({
        url: 'accountfiles/upload?filters.folder=/files',
        type: 'POST',
        data: fd,
        contentType: false,
        processData: false,
        headers: {
          'X-Auth-Token': self.options.token
        },
        timeout: 60 * 1000,
        success: function (response) {
          if (response) {
            modal.find('[name=href]').val(response.data.location.absolute);
          }
          modal.find('#upload-instructions').show();
          modal.find('#uploading').hide();
        },
        error: function () {
          //TODO: manejo de errores
          modal.find('#upload-instructions').show();
          modal.find('#uploading').hide();
          alert(self.lang.uploadError);
        }
      });
    }
  };

  /**
   * Opens a modal to define link interests
   * @param selectedLink reference to selected link in content editable cells
   */
  this.defineLinkInterests = function (selectedLink = false, selectedElement = false) {
    var modal = self.findByData('modal', 'linkInterests'),
      template = '<option value="{{id}}">{{name}}</option>',
      templateSelected = '<option selected value="{{id}}">{{name}}</option>',
      options = [],
      select = modal.find('select'),
      node = self.getNode(self.selected),
      currentInterests;

    options.push(
      self.template(template, {
        id: '',
        name: '(' + self.lang.none + ')'
      })
    );
    if (self.hasInterests) {
      if (!selectedLink) {
        //Buttons & images
        currentInterests = node.attr.interests;
      } else {
        //Content Editables
        currentInterests = selectedLink.attr('interests');
      }
      $.each(self.interests, function (indexInterest, interest) {
        options.push(
          self.template(((currentInterests == interest.id) ? templateSelected : template), {
            id: interest.id,
            name: interest.name
          })
        );
      });
    }
    select.html(options.join(''));
    modal.modal('show');
    modal.on('submit', 'form', function (e) {
      var form = $(this),
        interestId = form.find('[name=interest]').val();
      e.preventDefault();
      if (!selectedLink) {
        //Buttons & images
        self.updateNode(self.selected, 'attr', 'interests', interestId);
      } else {
        //Content Editables
        if (interestId) {
          selectedLink.attr('interests', interestId);
          selectedLink.attr('data-interests', 'int_' + interestId);
        } else {
          selectedLink.attr('interests', '');
          selectedLink.attr('data-interests', '');
        }
        self.updateNode(self.selected, 'attr', 'html', selectedElement.html());
      }
      self.render();
      self.saveBackup();
      modal.modal('hide');
      modal.off('submit', 'form');
    });
  };

  /**
   * Opens a modal to choose a color value that is sent to a callback
   * @param {String} legend
   * @param {String} value
   * @param {String} propagate
   * @param {Function} callback
   */
  this.colorPicker = function (legend, value, propagate, transparent, callback) {
    var colorPicker = self.findByData('modal', 'color'),
      title = colorPicker.find('.modal-title'),
      input = colorPicker.find('input:first'),
      checkbox = colorPicker.find('.checkbox'),
      colorPreview = colorPicker.find('.color-preview'),
      firstPicker = colorPicker.find('[data-hex]').first(),
      firstPickerValue = '#000000',
      color = false;

    value = value || '';

    if (self.stringStartsWith(value, 'rgb')) {
      value = self.rgbToHex(value);
    }
    title.text(legend);
    input.attr({ placeholder: self.lang.colorPlaceholder }).val(value);
    if (value === 'transparent') {
      colorPreview.css('background', 'url(' + self.options.pluginPath + '/img/transparent.png)');
    } else {
      colorPreview.css('background', value);
    }
    self.target.find('.selected-color').removeClass('selected-color');
    colorPicker.find('[data-hex="' + value + '"]').addClass('selected-color');
    if (transparent) {
      firstPickerValue = 'transparent';
      firstPicker.addClass('transparent');
    } else {
      firstPicker.removeClass('transparent');
    }
    firstPicker.css('background', firstPickerValue).attr('data-hex', firstPickerValue);
    if (propagate) {
      checkbox.show().find('span').text(propagate);
    } else {
      checkbox.hide();
    }
    colorPicker.modal('show');
    colorPicker.on('submit', 'form', function (e) {
      var propagate;
      e.preventDefault();
      color = input.val();
      color = self.validateField('color', color);
      if (!color) {
        return false;
      }
      propagate = checkbox.find('input').is(':checked');
      checkbox.find('input').removeAttr('checked');
      callback(color, propagate, colorPicker);
      colorPicker.off('submit', 'form');
    });
  };

  /**
   * Resets the color picker preview
   */
  this.resetColorPickerPreview = function () {
    var colorPicker = self.findByData('modal', 'color'),
      input = colorPicker.find('input:first'),
      colorPreview = colorPicker.find('.color-preview');
    input.val('');
    colorPreview.css('background', '#FFF');
  };

  /**
   * Creates the file for each export option
   */
  this.prepareExport = function () {
    var self = this;
    if (window.File && window.FileReader && window.FileList && window.Blob && window.app.router.current !== undefined && window.app.router.current.item !== undefined) {
      self.findByData('export').each(function () {
        var blob = new window.Blob([self.json()], { type: 'json' });
        $(this).attr('href', window.URL.createObjectURL(blob)).attr('download', window.app.router.current.item.get('name') + '.eddie');
      });
    }
  };

  /**
   * Saves a new backup content
   */
  this.saveBackup = function () {
    var backup = $.extend(true, {}, self.content);
    self.backupContent.splice(0, self.backupStep);
    self.backupStep = 0;
    self.backupContent.unshift(backup);
    if (self.backupContent.length > 1) {
      self.isDirty = true;
      self.findByData('role', 'undo').removeAttr('disabled');
      self.findByData('role', 'redo').attr('disabled', true);
    } else {
      self.isDirty = false;
    }
  };

  /**
   * Restores a backup content
   */
  this.restoreBackup = function () {
    var redoButton = self.findByData('role', 'redo'),
      undoButton = self.findByData('role', 'undo'),
      last = self.backupContent.length - 1,
      backup = self.backupContent[self.backupStep];
    self.content = $.extend(true, {}, backup);
    self.unselectElement();
    self.render();
    if (self.backupStep === 0) {
      redoButton.attr('disabled', true);
    } else {
      redoButton.removeAttr('disabled');
    }
    if (self.backupStep === last) {
      undoButton.attr('disabled', true);
    } else {
      undoButton.removeAttr('disabled');
    }
  };

  /**
   * Cleans all backups
   */
  this.cleanBackups = function () {
    self.findByData('role', 'redo').attr('disabled', true);
    self.findByData('role', 'undo').attr('disabled', true);
    self.backupContent = [];
  };

  /**
   * Revert the last change
   */
  this.undo = function () {
    var step = self.backupStep + 1;
    if (self.backupContent[step] !== undefined) {
      self.backupStep = step;
      self.restoreBackup();
    }
  };

  /**
   * Redoes the last change
   */
  this.redo = function () {
    var step = self.backupStep - 1;
    if (self.backupContent[step] !== undefined) {
      self.backupStep = step;
      self.restoreBackup();
    }
  };

  /**
   * Cleans the temporal links
   */
  this.cleanTemps = function () {
    self.target.find('sub').contents().unwrap();
    self.target.find('[href=temp]').each(function () {
      if ($(this).text() === 'temp') {
        $(this).remove();
      } else {
        $(this).contents().unwrap();
      }
    });
    self.target.find('[href=""]').contents().unwrap();
    self.target.find('.selection').removeClass('selection');
    self.findByData('role', 'uploader').closest('form').get(0).reset();
    self.findByData('role', 'opener').closest('form').get(0).reset();
    self.editingSettings = false;
    self.editingRegion = false;
  };

  /**
   * Gets the selected text
   */
  this.getSelectionText = function () {
    var text = "";
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type != "Control") {
      text = document.selection.createRange().text;
    }
    return text;
  };

  /**
   * Shows the tutorial
   */
  this.showTutorialStep = function (step) {
    var tutorial = self.findByData('role', 'tutorial'),
      arrow = tutorial.find('.arrow'),
      editor = self.target.find('.eddie');
    editor.addClass('tutorial').addClass('step' + step).removeClass('step' + (step + 1));
    self.findByData('step', step).removeClass('hide').siblings().addClass('hide');
    arrow.css({
      height: 0,
      marginTop: 57
    }).animate({
      marginTop: 0,
      height: 57
    });
    switch (step) {
      case 1:
        tutorial.show();
        if (self.content.regions.length > 2) {
          self.unselectElement();
          self.addRegion('heading');
          self.addRegion('postRight');
        } else {
          self.content = {
            regions: [
              'heading',
              'postRight'
            ], settings: {}
          };
        }

        self.render();
        break;
      case 2:
        self.saveBackup();
        self.selectElement({ "region": 1, "cell": 1, "element": 0 });
        break;
      case 3:
        if (!self.selected) {
          self.selectElement();
        }
        break;
      // case 12:
      //     tutorial.remove();
      //     editor.attr('class', 'eddie');
      //     break;
      case 10:
        if (self.content.regions.length > 2) {
          tutorial.hide();
          editor.attr('class', 'eddie');
          self.selectElement({ "region": 0 });
          self.destroyRegion();
          self.selectElement({ "region": 0 });
          self.destroyRegion();
        } else {
          self.unselectElement();
          self.content = $.extend(true, {}, defaultContent);
          self.selected = false;
          self.render();
          tutorial.hide();
          editor.attr('class', 'eddie');
          self.openTemplatesModal();
        }
        this.saveTutorialOption();
        break;
    }
  };

  /**
   * Skips the tutorial and starts again
   */
  this.saveTutorialOption = function (option) {
    $.ajax({ type: 'PUT', url: 'users/me/context', data: { eddieTutorial: '0' } });
    app.session.attributes.context.eddieTutorial = 0;
  };

  /**
   * Skips the tutorial and starts again
   */
  this.skipTutorial = function () {
    self.findByData('role', 'tutorial').addClass('hidden');
    self.options.tutorial = false;
    self.init();
  };

  /**
   * Shows the help on a new tab
   */
  this.showHelp = function () {
    var help = self.findByData('action', 'showHelp');
    window.open(help.attr('href'));
  };

  /**
   * Starts again
   */
  this.restartTemplate = function () {
    self.confirm(self.lang.restartTemplateConfirmation, function () {
      var timeout = window.setTimeout(function () {
        self.openTemplatesModal();
      }, 500);
    });
  };

  /**
   * Opens a modal to select the design to import
   */
  this.openDesignsModal = function () {
    self.parentDesignsFolder = false;
    self.currentDesignsPath = '/';
    self.searchDesignValue = false;
    self.findByData('role', 'searchDesignValue').val('');
    self.requestDesigns();
  };

  /**
   * Explores a designs folder
   */
  this.exploreFolders = function (path) {
    var folders = path.split('/');
    self.currentDesignsPath = path;
    folders.pop();
    self.parentDesignsFolder = folders.join('/');
    if (!self.parentDesignsFolder) self.parentDesignsFolder = '/';
    self.requestDesigns();
  };

  /**
   * Requests the designs
   */
  this.requestDesigns = function () {
    var url = self.options.api.designsList + self.currentDesignsPath,
      data = { limit: 1000, 'filters.type': 'eddie' };
    self.isFetching = true;
    if (self.searchDesignValue) {
      data.q = '!*' + self.searchDesignValue + '*';
    }
    $.ajax({
      url: url,
      data: data,
      success: function (response) {
        self.isFetching = false;
        if (response.success !== undefined && response.success) {
          if (response.data !== undefined) {
            self.renderDesigns(response);
            self.findByData('modal', 'designs').modal('show');
          }
        }
      }
    });
  };

  /**
   * Renders the designs
   */
  this.renderDesigns = function (response) {
    var template = require('@/modules/campaigns/templates/modals/folders.hbs'),
      clear = self.findByData('action', 'clearDesignSearch');
    if (self.searchDesignValue) {
      clear.show();
    } else {
      clear.hide();
    }
    self.designs = response.data;
    self.findByData('designs', 'list').html(template({
      parentFolder: (self.currentDesignsPath === '/') ? false : self.parentDesignsFolder,
      data: self.designs,
      lang: window.lang
    }));
  };

  /**
   * Inserts a design
   */
  this.insertDesign = function (attributes) {
    var design = _.findWhere(this.designs, { id: attributes.id });
    $.getJSON(self.options.api.designsList + design.id + '.json', function (response) {
      self.importRegions(response.regions);
      self.findByData('modal', 'designs').modal('hide');
    });
  };

  /**
   * Inserts a condition in a region
   */
  this.setCondition = function () {
    var region = self.getRegion(self.selected),
      currentCondition = region.attr && region.attr['data-if'] ? region.attr['data-if'] : '';
    self.prompt('condition', self.lang.setCondition, currentCondition, self.lang.conditionSample, false, function (value, propagate, prompt) {
      if (!region.attr) region.attr = {};
      if (value) {
        region.attr['data-if'] = value;
      } else {
        delete region.attr['data-if'];
      }
      self.render();
      self.saveBackup();
      prompt.modal('hide');
    },
      self.lang.conditionInstructions);
  };

  /**
   * Renders the email preview based on the email content
   */
  this.render = function () {
    var container = $('<div>'),
      emailWidth = self.content.settings.width,
      button = self.findByData('action', 'removeEmailBackgroundImage');
    self.findByData('role', 'wrapper').css({
      backgroundColor: self.content.settings.backgroundColor,
      backgroundImage: self.content.settings.backgroundImage || ''
    });
    if (self.content.settings.backgroundImage !== 'none') {
      button.removeClass('hide');
    } else {
      button.addClass('hide');
    }
    self.content.regions = $.map(self.content.regions, function (region) {
      if (typeof region === 'string') {
        return $.extend(true, {}, self.components.regions[region]);
      } else {
        return region;
      }
    });
    self.renderRegions(self.content.regions, container, true);
    self.target.find('.eddie-page').empty().html(container.html());
    self.cleanTemps();
    self.target.find('.eddie-page').closest('table').attr('width', emailWidth);
    if (self.selected) {
      self.selectElement(self.selected);
    }
    self.prepareExport();
    $('.tooltip').hide();
  };

  /**
   * Renders regions
   */
  this.renderRegions = function (regions, container, addData) {
    var borderAttributes = ['border', 'borderTop', 'borderBottom', 'borderLeft', 'borderRight'];
    $.each(regions, function (indexRegion, region) {
      var table = $('<table>', self.defaults.table.attr),
        tr = $('<tr>', region.attr)
          .css($.extend(
            true,
            {},
            self.defaults.region.style,
            region.style
          ));
      if (addData) {
        tr.attr('data-region', indexRegion);
      }
      if (region.style !== undefined && region.style.backgroundImage !== undefined) {
        table
          .attr('background', region.style.backgroundImage)
          .css('background-image', region.style.backgroundImage);
        tr.removeAttr('background').css({ 'background-image': 'none', 'background-color': 'transparent' });
      }
      _.each(borderAttributes, function (attribute) {
        if (region.style && region.style[attribute]) {
          table.css(attribute, region.style[attribute]);
          tr.css(attribute, 'none');
        }
      });
      if (region.cells.length) {
        $.each(region.cells, function (indexCell, cell) {
          var cellAttr,
            stackOnMobile = true,
            stackClass = 'stack-column',
            td;
          cellAttr = $.extend(true, {}, self.defaults.cell.attr, cell.attr);
          if (cellAttr.stack !== undefined && !cellAttr.stack) {
            stackOnMobile = false;
          }
          if (cellAttr.stackClass !== undefined) {
            stackClass = cellAttr.stackClass;
          }
          td = $('<td>', cellAttr)
            .css($.extend(
              true,
              {},
              self.defaults.cell.style,
              cell.style
            ))
            .addClass(stackOnMobile ? stackClass : '')
            .appendTo(tr);
          if (addData) {
            td.attr('data-cell', indexCell);
          }
          $.each(cell.contents, function (indexContent, content) {
            if (content.type === 'html') {
              const element = $(content.el).html(content.attr.html);
              const ref = self.setElementReference(indexRegion, indexCell, indexContent);
              if (addData) {
                element.attr('data-element', indexContent)
                  .attr('data-ref', ref)
                  .attr('data-is-html', 1)
                  .attr('data-type', content.type);
              }
              element.appendTo(td);
              return true;
            }

            var style = $.extend(
              true,
              {},
              self.defaults.element.style,
              content.style
            ),
              attr = $.extend(
                true,
                {},
                self.defaults.element.attr,
                content.attr
              ),
              ref = self.setElementReference(indexRegion, indexCell, indexContent),
              link = content.attr.link || false,
              interests = content.attr.interests || false,
              type = content.type,
              element;
            var isVariableImage = type === 'image' &&
              content.attr &&
              content.attr.src &&
              (self.stringContains(content.attr.src, ['{{']) || self.stringContains(content.attr.src, ['${']));
            if (isVariableImage) {
              attr['data-src'] = attr.src;
              attr.src = '/img/variable_image.png';
            }
            element = $(content.el, attr)
              .attr(attr)
              .css(style)
              .removeAttr('html');
            if (addData) {
              element.attr('data-element', indexContent)
                .attr('data-ref', ref)
                .attr('data-type', type);
            }
            element.find('a').css({
              textDecoration: 'none'
            });
            if (type === 'image') {
              element.addClass('fluid');
            }
            if (addData && (type === 'heading' || type === 'paragraph')) {
              element.attr('data-editable', true);
            }
            if (addData && link) {
              $('<a>', {
                href: link,
                'data-interests': (interests) ? ('int_' + interests) : ''
              })
                .css({
                  textDecoration: 'none'
                }).append(element).appendTo(td);
            } else {
              if (addData && interests) {
                element.attr('data-interests', 'int_' + interests);
              }
              element.appendTo(td);
            }
          });
        });
        table.appendTo(container);
        tr.appendTo(table);
      }
    });
  };

  /**
   * Helper to check if a string starts with any of the received strings
   */
  this.stringStartsWith = function (string, strings) {
    var result = false,
      i;
    for (i = strings.length - 1; i >= 0; i = i - 1) {
      if (string.indexOf(strings[i]) === 0) {
        result = true;
      }
    }
    return result;
  };

  /**
   * Helper to check if a string ends with any of the received strings
   */
  this.stringEndsWith = function (string, strings) {
    var result = false,
      i;
    for (i = strings.length - 1; i >= 0; i = i - 1) {
      if (string.indexOf(strings[i], this.length - strings[i].length) !== -1) {
        result = true;
      }
    }
    return result;
  };

  /**
   * Helper to check if a string is empty or full of spaces
   */
  this.stringIsEmpty = function (string) {
    return (string.length === 0 || !string.trim());
  };

  /**
   * Helper to check if a string contains another
   */
  this.stringContains = function (string, strings) {
    return String.prototype.indexOf.apply(string, strings) !== -1;
  };

  /**
   * Helper to get the video ID from a YouTube URL
   */
  this.getYouTubeVideoId = function (url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : false;
  };

  /**
   * Helper to get the video thumbnail image URL from a YouTube video ID
   */
  this.getVideoThumbnailUrl = function (videoId, lowres) {
    return self.options.api.videoThumbnail + videoId + (lowres ? '&res=low' : '')
  };

  // Initialize the editor
  this.init();
}

$.fn.changeElementType = function (newType) {
  var attrs = {};
  if (this[0] !== undefined) {
    $.each(this[0].attributes, function (idx, attr) {
      attrs[attr.nodeName] = attr.nodeValue;
    });
    this.replaceWith(function () {
      return $("<" + newType + "/>", attrs).append($(this).contents());
    });
  }
}

export default Eddie;
