<template>
  <div class="flex flex-wrap gap-y-2">
    <RuleOptionsList
      v-show="!disabled && predicates.attributes.length === 0"
      :rule-options="ruleOptions"
      :loading="loadingRules"
      @select="createNewAttribute"
    >
      <template #button>
        <button
          class="focus-visible:outline-none my-auto flex cursor-pointer select-none items-center space-x-2 bg-gray-50 py-2 px-3 text-sm font-medium text-gray-500 first:rounded-l last:rounded-r hover:bg-sky-100 hover:text-sky-400 focus-visible:bg-sky-100 focus-visible:text-sky-400 focus-visible:ring-2 focus-visible:ring-sky-500"
        >
          <span>
            {{ t("newRuleButton") }}
          </span>
          <PlusIcon class="h-4 w-4" />
        </button>
      </template>
    </RuleOptionsList>
    <PredicateGroup :predicates="predicates" :disabled="disabled" @update:predicates="updatePredicates" />
    <RuleOptionsList
      v-show="predicates.attributes.length > 0"
      v-if="!disabled"
      :rule-options="ruleOptions"
      :loading="loadingRules"
      class="my-auto pl-3"
      @select="createNewAttribute"
    >
      <template #button>
        <IconButton
          :label="t('newRuleButton')"
          theme="none"
          size="min"
          class="focus-visible:outline-none my-auto flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-sky-200 hover:bg-sky-300 focus-visible:bg-sky-300"
        >
          <PlusIcon class="h-4 w-4 text-sky-700" />
        </IconButton>
      </template>
    </RuleOptionsList>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, toRaw, provide, computed } from "vue";
import type { ComputedRef, Ref } from "vue";

// Components
import PredicateGroup from "./components/PredicateGroup.vue";
import RuleOptionsList from "./components/RuleOptionsList.vue";
import IconButton from "@atoms/IconButton.vue";

// Icons
import { PlusIcon } from "@heroicons/vue/solid";

// Utils
import { cloneDeep } from "lodash";
import { useI18n } from "vue-i18n";

// Application
import { useConditionsApp } from "@application";

// Store
import { useContactsStore } from "@store";

// Domain
import type { AttributeOption, AttributeOptions, PredicatesWithId } from "@domain/predicates";
import { attributeOptionToAttributeWithId } from "@domain/predicates";
import type { Fields } from "@domain/fields";
import type { Interests } from "@domain/interests";

// Store
const contactsStore = useContactsStore();

const { t } = useI18n();
const conditionsApp = useConditionsApp();

const props = withDefaults(
  defineProps<{
    predicates: PredicatesWithId;
    fields: Fields | undefined;
    interests: Interests | undefined;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: "update:predicates", predicates: PredicatesWithId): void;
}>();

const updatePredicates = (newPredicates: PredicatesWithId) => {
  emit("update:predicates", newPredicates);
};

// Data
const fields = ref<Fields | undefined>(props.fields);
const interests = ref<Interests | undefined>(props.interests);

// Account
const ruleOptions = ref<AttributeOptions>([]);
const interestsComputed = computed(() => interests.value ?? props.interests);

// Handle conditions
const createNewAttribute = (option: AttributeOption) => {
  const newAttribute = attributeOptionToAttributeWithId(option);

  emit("update:predicates", {
    ...props.predicates,
    attributes: [...cloneDeep(toRaw(props.predicates.attributes)), newAttribute],
  });
};

const loadingRules = ref(false);
onMounted(async () => {
  loadingRules.value = true;
  if (!props.fields) {
    fields.value = contactsStore.getFields();
  }
  if (!props.interests) {
    interests.value = contactsStore.getInterests();
  }
  ruleOptions.value = await conditionsApp.getAttributeOptions({ fields: fields.value });
  loadingRules.value = false;
});

// Provides
provide<ComputedRef<Interests | undefined>>("interests", interestsComputed);
provide<Ref<Fields | undefined>>("fields", fields);
</script>

<i18n lang="jsonc">
{
  "es": {
    "newRuleButton": "Nueva regla"
  },
  "pt": {
    "newRuleButton": "Nova regra"
  }
}
</i18n>
