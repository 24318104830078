<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem]">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="http://docs.myperfit.com/es/articles/1427859" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <SelectMenuLabel
        v-model:selected="selectedRow"
        :data-items="rowOptions"
        :label="t('listSelectorLabel')"
        :rem-height="17"
      />
      <div class="mt-8">
        <Label>{{ t("listsLabel") }}</Label>
        <ul class="max-h-52 space-y-1 overflow-y-auto py-4">
          <li v-for="row in filteredRows" :key="row.id" class="text-sm text-gray-500">
            {{ row.data?.name ?? "" }}
          </li>
        </ul>
      </div>
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" @click="subtract">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

import type { ListRows } from "../audienceLists.types";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItems, DataItem } from "@molecules/SelectMenuLabel.vue";
import Label from "@atoms/Label.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    rows: ListRows;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const rowOptions = computed(() => {
  return props.rows.reduce((items, row) => {
    if (!row.data) return items;

    items.push({
      key: row.data.id.toString(),
      value: row.data.name,
    });

    return items;
  }, [] as DataItems);
});

const loading = ref(false);
const selectedRow = ref<DataItem | undefined>(
  props.rows[0].data
    ? {
        key: props.rows[0].data?.id.toString(),
        value: props.rows[0].data?.name,
      }
    : undefined,
);
const filteredRows = computed(() => {
  return props.rows.filter((row) => row.data?.id.toString() !== selectedRow.value?.key.toString());
});

const subtract = async () => {
  if (!props.rows || !selectedRow.value) return;

  try {
    loading.value = true;

    const ids = filteredRows.value.reduce((ids, row) => {
      if (row.data?.id) {
        ids.push(row.data.id.toString());
      }
      return ids;
    }, [] as Array<string>);

    await listAPI.subtract({
      id: selectedRow.value.key.toString(),
      ids: ids,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.subtract.title"),
      text: t("notifications.subtract.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Sustraer",
    "description": "Se removerán de la lista seleccionada todos los contactos que estén en alguna de las otras listas. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "listSelectorLabel": "Sustraer de la lista",
    "listsLabel": "Los contactos que esten en alguna de estas listas",
    "notifications": {
      "subtract": {
        "title": "Sustrayendo listas",
        "text": "Sustrayendo contactos de la lista."
      }
    }
  },
  "pt": {
    "title": "Subtrair",
    "description": "Serão removidos da lista selecionada todos os contatos que estejam em qualquer uma das outras listas. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "listSelectorLabel": "Subtrair da lista",
    "listsLabel": "Os contatos que estejam em alguma destas listas",
    "notifications": {
      "subtract": {
        "title": "Subtraindo listas",
        "text": "Subtraindo contatos da lista."
      }
    }
  }
}
</i18n>
