<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flow-root">
    <ul role="list" class="-mb-8">
      <li v-for="(item, index) in items" :key="index">
        <div class="relative pb-12">
          <span
            v-if="index !== items.length - 1"
            class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="[
                  bgColor[item.color],
                  'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                ]"
              >
                <component :is="item.icon" class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <p class="text-sm">
                  <span class="mr-2 font-medium text-gray-700">{{ item.title }}</span
                  ><span class="text-gray-400">{{ item.description }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { Component } from "vue";

export type colors = "gray" | "green" | "red" | "sky" | "yellow";
export interface FeedItem {
  icon: Component;
  color: colors;
  title: string;
  description: string;
}
export type FeedItems = Array<FeedItem>;

// Props
withDefaults(
  defineProps<{
    items: FeedItems;
  }>(),
  {
    items: () => [],
  }
);

const bgColor = {
  gray: "bg-gray-400",
  green: "bg-green-400",
  red: "bg-red-400",
  sky: "bg-sky-400",
  yellow: "bg-yellow-400",
};
</script>
