<template>
  <div :class="[row.data.tags.length === 0 ? 'py-5' : 'py-3', 'space-y-1']">
    <p class="flex gap-2">
      <a :href="`/automations/${row.data.id}`" class="line-clamp-1 text-sm font-medium text-sky-400">
        {{ row.data.name }}
      </a>
      <SimpleBadge
        v-if="getTriggerId(row.data.trigger)"
        ref="triggerRef"
        theme="light-sky"
        square
        class="mb-auto shrink-0 cursor-default select-none"
      >
        <template #icon>
          <component :is="getTriggerIcon(row.data.trigger)" class="h-4 w-4" />
        </template>
        <span v-show="xlBp">
          {{ t(`triggersTypes.${getTriggerId(row.data.trigger)}`) }}
        </span>
      </SimpleBadge>
      <SimpleBadge :theme="row.data.enabled ? 'green' : 'gray'" square>
        {{ t(`${row.data.enabled ? "enabled" : "disabled"}`) }}
      </SimpleBadge>
    </p>
    <p :class="[row.data.comments ? 'text-gray-500' : 'text-gray-300', 'mb-2 line-clamp-1 text-xs ']">
      {{ row.data.comments || t("placeholderDescription") }}
    </p>
    <div class="flex space-x-2">
      <SimpleBadge v-for="tag in row.data.tags" :key="tag" theme="gray" size="small" square class="shrink-0">
        {{ tag }}
      </SimpleBadge>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, markRaw } from "vue";

// components
import SimpleBadge from "@atoms/SimpleBadge";

// Icons
import {
  PencilIcon,
  UserAddIcon,
  UserIcon,
  UserRemoveIcon,
  HeartIcon,
  CakeIcon,
  ShoppingCartIcon,
  ShoppingBagIcon,
  EyeIcon,
  ChatIcon,
  CogIcon,
} from "@heroicons/vue/solid";
import LifeCycleIcon from "@tokens/icons/LifeCycleIcon.vue";

// Composables
import { useTooltip } from "@composables/tooltips";
import { useBreakpoints } from "@composables/breakpoints";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore } from "@store";

// Types
import type { AutomationsRow } from "../../../automations.types";

const { t } = useI18n();
const { xlBp } = useBreakpoints();

const sessionStore = useSessionStore();
const campaignsUpdate = sessionStore.hasPermission("campaigns:update");

const props = defineProps<{
  row: AutomationsRow;
}>();

const getTriggerIcon = (trigger: string) => {
  const formattedTrigger = trigger.replace(/\d+/g, "").replaceAll(".", "");

  const customTrigger = "custom";

  const subscription = [
    "optinconfirmed",
    "tiendanubenewslettersubscribed",
    "vtexnewslettersubscribed",
    "fbleadscontactsubscribed",
    "pownewslettersubscribed",
  ];
  const contact = ["vtexusercreated"];
  const contactAdded = ["contactlistadded"];
  const contactRemoved = ["contactlistremoved"];
  const interest = ["contactinterestadded", "contactinterestexpired", "contactinterestremoved"];
  const birthday = ["recurringbirthday"];
  const cart = [
    "tiendanubecartcreated",
    "vtexcartabandoned",
    "woocommercecartcreated",
    "signoscartcreated",
    "jumpsellerorderabandoned",
    "traycartcreated",
    "wbuycartabandoned",
    "vndacartabandoned",
  ];
  const order = [
    "tiendanubeordercancelled",
    "tiendanubeordercreated",
    "tiendanubeordercompleted",
    "tiendanubeorderpacked",
    "tiendanubeordershipped",
    "vtexordercompleted",
    "woocommerceordercompleted",
    "signosordercompleted",
    "powordercompleted",
    "poworderpacked",
    "powordershipped",
    "powordercreated",
    "shopifyordercreated",
    "shopifyordercompleted",
    "shopifyordershipped",
    "jumpsellerorderupdated",
    "jumpsellerordercompleted",
    "jumpsellerorderpending_payment",
    "jumpsellerordercanceled",
    "jumpsellerordershipped",
    "mshopsorderpaid",
    "trayorderpaid",
    "ecwidorderpaid",
    "ecwidorderready_for_pickup",
    "ecwidordershipped",
    "booticordercompleted",
    "booticorderpaid",
    "booticorderdelivered",
    "booticorderready",
    "booticorderpreparing",
    "wbuyordercreated",
    "wbuyorderpaid",
    "wbuyorderem_transporte",
    "wbuyorderem_expedicao",
    "wbuyorderready_to_pickup",
    "wbuyordercompleted",
    "vndaorderpaid",
    "vndaordershipped",
    "vndaordercancelled",
  ];
  const chat = ["jivochatchatfinished"];
  const lifecycle = [
    "lifecyclesrfm",
    "lifecyclesrfmchampionin",
    "lifecyclesrfmchampionout",
    "lifecyclesrfmloyalistin",
    "lifecyclesrfmloyalistout",
    "lifecyclesrfmrecentin",
    "lifecyclesrfmrecentout",
    "lifecyclesrfmhighpotentialin",
    "lifecyclesrfmhighpotentialout",
    "lifecyclesrfmneednutringin",
    "lifecyclesrfmneednutringout",
    "lifecyclesrfmcantlosein",
    "lifecyclesrfmcantloseout",
    "lifecyclesrfmatriskin",
    "lifecyclesrfmatriskout",
    "lifecyclesrfmabouttolosein",
    "lifecyclesrfmabouttoloseout",
  ];

  const visit = [
    "tiendanubesessionfinished",
    "vtexsessionfinished",
    "powsessionfinished",
    "traysessionfinished",
    "wbuysessionfinished",
  ];

  if (trigger.includes(customTrigger)) return CogIcon;
  if (subscription.some((t) => formattedTrigger === t)) return PencilIcon;
  if (contact.some((t) => formattedTrigger === t)) return UserIcon;
  if (contactAdded.some((t) => formattedTrigger === t)) return UserAddIcon;
  if (contactRemoved.some((t) => formattedTrigger === t)) return UserRemoveIcon;
  if (interest.some((t) => formattedTrigger === t)) return HeartIcon;
  if (birthday.some((t) => formattedTrigger === t)) return CakeIcon;
  if (cart.some((t) => formattedTrigger === t)) return ShoppingCartIcon;
  if (order.some((t) => formattedTrigger === t)) return ShoppingBagIcon;
  if (birthday.some((t) => formattedTrigger === t)) return CakeIcon;
  if (chat.some((t) => formattedTrigger === t)) return ChatIcon;
  if (visit.some((t) => formattedTrigger === t)) return EyeIcon;
  if (lifecycle.some((t) => formattedTrigger === t)) return markRaw(LifeCycleIcon);
};

const getTriggerId = (trigger: string) => {
  const formattedTrigger = trigger.replace(/\d+/g, "").replaceAll(".", "");

  const customTrigger = "custom";

  const triggers = [
    "optinconfirmed",
    "tiendanubenewslettersubscribed",
    "vtexnewslettersubscribed",
    "fbleadscontactsubscribed",
    "pownewslettersubscribed",
    "vtexusercreated",
    "contactlistadded",
    "contactlistremoved",
    "contactinterestadded",
    "contactinterestexpired",
    "contactinterestremoved",
    "recurringbirthday",
    "tiendanubecartcreated",
    "vtexcartabandoned",
    "woocommercecartcreated",
    "signoscartcreated",
    "jumpsellerorderabandoned",
    "traycartcreated",
    "wbuycartabandoned",
    "vndacartabandoned",
    "tiendanubeordercancelled",
    "tiendanubeordercreated",
    "tiendanubeordercompleted",
    "tiendanubeorderpacked",
    "tiendanubeordershipped",
    "vtexordercompleted",
    "woocommerceordercompleted",
    "signosordercompleted",
    "powordercompleted",
    "poworderpacked",
    "powordershipped",
    "powordercreated",
    "shopifyordercreated",
    "shopifyordercompleted",
    "shopifyordershipped",
    "jumpsellerorderupdated",
    "jumpsellerordercompleted",
    "jumpsellerorderpending_payment",
    "jumpsellerordercanceled",
    "jumpsellerordershipped",
    "mshopsorderpaid",
    "trayorderpaid",
    "ecwidorderpaid",
    "ecwidorderready_for_pickup",
    "ecwidordershipped",
    "booticordercompleted",
    "booticorderpaid",
    "booticorderdelivered",
    "booticorderready",
    "booticorderpreparing",
    "wbuyordercreated",
    "wbuyorderpaid",
    "wbuyorderem_transporte",
    "wbuyorderem_expedicao",
    "wbuyorderready_to_pickup",
    "wbuyordercompleted",
    "vndaorderpaid",
    "vndaordershipped",
    "vndaordercancelled",
    "jivochatchatfinished",
    "lifecyclesrfm",
    "lifecyclesrfmchampionin",
    "lifecyclesrfmchampionout",
    "lifecyclesrfmloyalistin",
    "lifecyclesrfmloyalistout",
    "lifecyclesrfmrecentin",
    "lifecyclesrfmrecentout",
    "lifecyclesrfmhighpotentialin",
    "lifecyclesrfmhighpotentialout",
    "lifecyclesrfmneednutringin",
    "lifecyclesrfmneednutringout",
    "lifecyclesrfmcantlosein",
    "lifecyclesrfmcantloseout",
    "lifecyclesrfmatriskin",
    "lifecyclesrfmatriskout",
    "lifecyclesrfmabouttolosein",
    "lifecyclesrfmabouttoloseout",
    "tiendanubesessionfinished",
    "vtexsessionfinished",
    "powsessionfinished",
    "traysessionfinished",
    "wbuysessionfinished",
  ];

  if (trigger.includes(customTrigger)) return "custom";
  const triggerId = triggers.find((t) => formattedTrigger === t);

  return triggerId;
};

const triggerRef = ref();
onMounted(() => {
  if (!triggerRef.value?.badgeRef) return;

  useTooltip(
    triggerRef.value.badgeRef,
    `${t(`trigger`)}: ${t(`triggersTypesComplete.${getTriggerId(props.row.data.trigger)}`)}</br></br>${t(
      `triggersComments.${getTriggerId(props.row.data.trigger)}`,
    )}`,
    {
      placement: "bottom",
      popperOptions: {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              tether: false,
              boundary: "clippingParents",
            },
          },
          {
            name: "flip",
            options: {
              allowedAutoPlacements: ["bottom", "top"],
              fallbackPlacements: ["bottom", "top"],
              altBoundary: true,
            },
          },
        ],
      },
    },
  );
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderDescription": "Sin descripción",
    "enabled": "Activo",
    "disabled": "Pausado",
    "trigger": "Disparador",
    "triggersTypes": {
      "contactlistadded": "Contacto",
      "contactlistremoved": "Contacto",
      "contactinterestadded": "Interés",
      "contactinterestexpired": "Interés",
      "contactinterestremoved": "Interés",
      "optinconfirmed": "Suscripción",
      "tiendanubecartcreated": "Carrito",
      "tiendanubenewslettersubscribed": "Suscripción",
      "tiendanubeordercancelled": "Compra",
      "tiendanubeordercreated": "Compra",
      "tiendanubeordercompleted": "Compra",
      "tiendanubeorderpacked": "Compra",
      "tiendanubeordershipped": "Compra",
      "tiendanubesessionfinished": "Visita",
      "vtexcartabandoned": "Carrito",
      "vtexordercompleted": "Compra",
      "vtexusercreated": "Contacto",
      "vtexnewslettersubscribed": "Suscripción",
      "vtexsessionfinished": "Visita",
      "fbleadscontactsubscribed": "Suscripción",
      "woocommerceordercompleted": "Compra",
      "woocommercecartcreated": "Carrito",
      "signosordercompleted": "Compra",
      "signoscartcreated": "Carrito",
      "recurringbirthday": "Cumpleaños",
      "jivochatchatfinished": "Chat",
      "pownewslettersubscribed": "Suscripción",
      "powordercompleted": "Compra",
      "poworderpacked": "Compra",
      "powordershipped": "Compra",
      "powordercreated": "Compra",
      "powsessionfinished": "Visita",
      "shopifyordercreated": "Compra",
      "shopifyordercompleted": "Compra",
      "shopifyordershipped": "Compra",
      "jumpsellerorderupdated": "Compra",
      "jumpsellerordercompleted": "Compra",
      "jumpsellerorderpending_payment": "Compra",
      "jumpsellerordercanceled": "Compra",
      "jumpsellerorderabandoned": "Carrito",
      "jumpsellerordershipped": "Compra",
      "mshopsorderpaid": "Compra",
      "traycartcreated": "Carrito",
      "trayorderpaid": "Compra",
      "traysessionfinished": "Visita",
      "ecwidorderpaid": "Compra",
      "ecwidorderready_for_pickup": "Compra",
      "ecwidordershipped": "Compra",
      "booticordercompleted": "Compra",
      "booticorderpaid": "Compra",
      "booticorderdelivered": "Compra",
      "booticorderready": "Compra",
      "booticorderpreparing": "Compra",
      "wbuycartabandoned": "Carrito",
      "wbuyordercreated": "Compra",
      "wbuyorderpaid": "Compra",
      "wbuyorderem_transporte": "Compra",
      "wbuyorderem_expedicao": "Compra",
      "wbuyorderready_to_pickup": "Compra",
      "wbuyordercompleted": "Compra",
      "wbuysessionfinished": "Visita",
      "vndaorderpaid": "Compra",
      "vndacartabandoned": "Carrito",
      "vndaordershipped": "Compra",
      "vndaordercancelled": "Compra",
      "lifecyclesrfm": "Ciclo de vida",
      "lifecyclesrfmchampionin": "Ciclo de vida",
      "lifecyclesrfmchampionout": "Ciclo de vida",
      "lifecyclesrfmloyalistin": "Ciclo de vida",
      "lifecyclesrfmloyalistout": "Ciclo de vida",
      "lifecyclesrfmrecentin": "Ciclo de vida",
      "lifecyclesrfmrecentout": "Ciclo de vida",
      "lifecyclesrfmhighpotentialin": "Ciclo de vida",
      "lifecyclesrfmhighpotentialout": "Ciclo de vida",
      "lifecyclesrfmneednutringin": "Ciclo de vida",
      "lifecyclesrfmneednutringout": "Ciclo de vida",
      "lifecyclesrfmcantlosein": "Ciclo de vida",
      "lifecyclesrfmcantloseout": "Ciclo de vida",
      "lifecyclesrfmatriskin": "Ciclo de vida",
      "lifecyclesrfmatriskout": "Ciclo de vida",
      "lifecyclesrfmabouttolosein": "Ciclo de vida",
      "lifecyclesrfmabouttoloseout": "Ciclo de vida",
      "custom": "Personalizado"
    },
    "triggersTypesComplete": {
      "optinconfirmed": "Suscripción a formulario",
      "contactlistadded": "Contacto añadido a lista",
      "contactlistremoved": "Contacto removido de lista",
      "contactinterestadded": "Contacto recibió interés",
      "contactinterestexpired": "Contacto perdió interés (expiró)",
      "contactinterestremoved": "Contacto perdió interés",
      "tiendanubecartcreated": "Carrito iniciado",
      "tiendanubenewslettersubscribed": "Suscripción a newsletter",
      "tiendanubeordercancelled": "Compra cancelada",
      "tiendanubeordercreated": "Compra iniciada",
      "tiendanubeordercompleted": "Compra completada",
      "tiendanubeorderpacked": "Compra empaquetada",
      "tiendanubeordershipped": "Compra enviada",
      "tiendanubesessionfinished": "Visita finalizada",
      "vtexcartabandoned": "Carrito abandonado",
      "vtexordercompleted": "Compra completada",
      "vtexusercreated": "Nuevo usuario creado",
      "vtexnewslettersubscribed": "Suscripción a newsletter",
      "vtexsessionfinished": "Visita finalizada",
      "fbleadscontactsubscribed": "Formulario completado",
      "woocommerceordercompleted": "Compra completada",
      "woocommercecartcreated": "Carrito iniciado",
      "signosordercompleted": "Compra completada",
      "signoscartcreated": "Carrito iniciado",
      "recurringbirthday": "Fecha de cumpleaños",
      "jivochatchatfinished": "Conversación finalizada",
      "pownewslettersubscribed": "Suscripción a newsletter",
      "powordercompleted": "Compra completada",
      "poworderpacked": "Pedido empaquetado",
      "powordershipped": "Pedido enviado",
      "powordercreated": "Compra iniciada",
      "powsessionfinished": "Visita finalizada",
      "shopifyordercreated": "Compra iniciada",
      "shopifyordercompleted": "Compra completada",
      "shopifyordershipped": "Compra enviada",
      "jumpsellerorderupdated": "Estado de compra actualizado",
      "jumpsellerordercompleted": "Compra completada",
      "jumpsellerorderpending_payment": "Compra pendiente de pago",
      "jumpsellerordercanceled": "Compra cancelada",
      "jumpsellerorderabandoned": "Carrito abandonado",
      "jumpsellerordershipped": "Compra enviada",
      "mshopsorderpaid": "Compra pagada",
      "traycartcreated": "Carrito iniciado",
      "trayorderpaid": "Compra pagada",
      "traysessionfinished": "Visita finalizada",
      "ecwidorderpaid": "Compra pagada",
      "ecwidorderready_for_pickup": "Compra lista para retirar",
      "ecwidordershipped": "Compra enviada",
      "booticordercompleted": "Compra completada",
      "booticorderpaid": "Compra pagada",
      "booticorderdelivered": "Compra entregada",
      "booticorderready": "Compra lista para retirar",
      "booticorderpreparing": "Compra en preparación de envío",
      "wbuycartabandoned": "Carrito abandonado",
      "wbuyordercreated": "Compra iniciada",
      "wbuyorderpaid": "Compra pagada",
      "wbuyorderem_transporte": "Pedido recibido por el envío",
      "wbuyorderem_expedicao": "Pedido enviado",
      "wbuyorderready_to_pickup": "Pedido listo para retirar",
      "wbuyordercompleted": "Pedido entregado",
      "wbuysessionfinished": "Visita finalizada",
      "vndaorderpaid": "Compra finalizada",
      "vndacartabandoned": "Carrito abandonado",
      "vndaordershipped": "Compra enviada",
      "vndaordercancelled": "Compra cancelada",
      "lifecyclesrfm": "Está pendiente la selección del disparador",
      "lifecyclesrfmchampionin": "Ingresó a la etapa Campeones",
      "lifecyclesrfmchampionout": "Salió de la etapa Campeones",
      "lifecyclesrfmloyalistin": "Ingresó a la etapa Leales",
      "lifecyclesrfmloyalistout": "Salió de la etapa Leales",
      "lifecyclesrfmrecentin": "Ingresó a la etapa Recientes",
      "lifecyclesrfmrecentout": "Salió de la etapa Recientes",
      "lifecyclesrfmhighpotentialin": "Ingresó a la etapa Alto potencial",
      "lifecyclesrfmhighpotentialout": "Salió de la etapa Alto potencial",
      "lifecyclesrfmneednutringin": "Ingresó a la etapa Necesitan incentivo",
      "lifecyclesrfmneednutringout": "Salió de la etapa Necesitan incentivo",
      "lifecyclesrfmcantlosein": "Ingresó a la etapa No se pueden perder",
      "lifecyclesrfmcantloseout": "Salió de la etapa No se pueden perder",
      "lifecyclesrfmatriskin": "Ingresó a la etapa En riesgo",
      "lifecyclesrfmatriskout": "Salió de la etapa En riesgo",
      "lifecyclesrfmabouttolosein": "Ingresó a la etapa Por perder",
      "lifecyclesrfmabouttoloseout": "Salió de la etapa Por perder",
      "custom": "Personalizado"
    },
    "triggersComments": {
      "optinconfirmed": "Ocurre cuando se recibe una nueva suscripción a través de un formulario de Perfit",
      "contactlistadded": "Ocurre cuando un contacto pasa a pertenecer a una lista<br/>Los contactos creados o modificados mediante una importación no son incluídos",
      "contactlistremoved": "Ocurre cuando un contacto deja de pertenecer a una lista",
      "contactinterestadded": "Ocurre cuando un contacto recibe un nuevo interés<br/>Los contactos creados o modificados mediante una importación no son incluídos",
      "contactinterestexpired": "Ocurre cuando un contacto deja de tener un interés porque expiró",
      "contactinterestremoved": "Ocurre cuando un contacto deja de tener un interés",
      "tiendanubecartcreated": "Ocurre cuando un visitante identificado agrega un producto a su carrito vacío en Tiendanube",
      "tiendanubenewslettersubscribed": "Ocurre cuando un visitante completa el formulario de contacto o suscripción a newsletter de Tiendanube (popup, pie de página o sección de contacto)",
      "tiendanubeordercancelled": "Ocurre cuando se cancela una orden de compra en Tiendanube",
      "tiendanubeordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito en Tiendanube",
      "tiendanubeordercreated": "Ocurre cuando se completa el proceso de checkout El pago puede aún estar pendiente",
      "tiendanubeorderpacked": "Ocurre cuando el pedido se marca como empaquetado en tu Tiendanube",
      "tiendanubeordershipped": "Ocurre cuando el pedido se marca como enviado en tu Tiendanube",
      "tiendanubesessionfinished": "Ocurre luego de 15 minutos de inactividad de un visitante identificado",
      "vtexcartabandoned": "Ocurre cuando un usuario identificado y con productos en su carrito abandona su sesión de VTEX",
      "vtexordercompleted": "Ocurre cuando una compra es finalizada con éxito en VTEX",
      "vtexusercreated": "Ocurre cuando se registra un nuevo usuario en VTEX",
      "vtexnewslettersubscribed": "Ocurre cuando un visitante completa el formulario de suscripción al newsletter en VTEX",
      "vtexsessionfinished": "Ocurre luego de 15 minutos de inactividad de un visitante identificado",
      "fbleadscontactsubscribed": "Ocurre cuando se completa un nuevo formulario de Facebook Lead Ads",
      "woocommerceordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito en WooCommerce",
      "woocommercecartcreated": "Ocurre cuando un visitante identificado agrega un producto a su carrito en WooCommerce",
      "signosordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito en Billowshop",
      "signoscartcreated": "Ocurre cuando un visitante identificado agrega un producto a su carrito vacío en Billowshop",
      "recurringbirthday": "Ocurre cuando la fecha de cumpleaños de un contacto es el día actual",
      "jivochatchatfinished": "Ocurre cuando una conversación de JivoChat es cerrada por el agente",
      "pownewslettersubscribed": "Ocurre cuando un visitante completa el formulario de contacto o suscripción a newsletter",
      "powordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "poworderpacked": "Ocurre cuando el pedido se marca como empaquetado",
      "powordershipped": "Ocurre cuando el pedido se marca como enviado",
      "powordercreated": "Ocurre cuando se inicial el proceso de compra",
      "powsessionfinished": "Ocurre luego de 15 minutos de inactividad de un visitante identificado",
      "shopifyordercreated": "Ocurre cuando se completa el proceso de checkout El pago puede aún estar pendiente",
      "shopifyordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito en Shopify",
      "shopifyordershipped": "Ocurre cuando el pedido se marca como enviado en tu Shopify",
      "jumpsellerorderupdated": "Ocurre cuando el estado de una compra es modificado",
      "jumpsellerordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "jumpsellerorderpending_payment": "Ocurre cuando una compra es creada y está pendiente de pago",
      "jumpsellerordercanceled": "Ocurre cuando una compra es cancelada",
      "jumpsellerorderabandoned": "Ocurre cuando se abandona una compra en curso con productos en el carrito",
      "jumpsellerordershipped": "Ocurre cuando una compra es marcada como despachada",
      "mshopsorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "traycartcreated": "Ocurre cuando se crea un carrito en tray",
      "trayorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "traysessionfinished": "Ocurre cuando se finaliza una visita en Tray",
      "ecwidorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "ecwidorderready_for_pickup": "Ocurre cuando la orden se marca como lista para retirar",
      "ecwidordershipped": "Ocurre cuando la orden se marca como enviada",
      "ecwidorderpacked": "Ocurre cuando el pedido se marca como empaquetado",
      "ecwidorderfullfiled": "Ocurre cuando una compra es marcada como despachada",
      "booticordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "booticorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "booticorderdelivered": "Ocurre cuando una compra es marcada como entregada",
      "booticorderready": "Ocurre cuando el pedido se marca como lista para retirar",
      "booticorderpreparing": "Ocurre cuando el pedido se marca como empaquetado",
      "wbuycartabandoned": "Ocurre cuando un visitante identificado agrega un producto a su carrito vacío en Wbuy",
      "wbuyordercreated": "Ocurre cuando se completa el proceso de checkout El pago puede aún estar pendiente",
      "wbuyorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "wbuyorderem_transporte": "Ocurre cuando una compra es recibida por el encargado del envío",
      "wbuyorderem_expedicao": "Ocurre cuando una compra está en envío",
      "wbuyorderready_to_pickup": "Ocurre cuando el pedido se marca como lista para retirar",
      "wbuyordercompleted": "Ocurre cuando una compra es entregada con éxito",
      "wbuysessionfinished": "Ocurre luego de 15 minutos de inactividad de un visitante identificado",
      "vndaorderpaid": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "vndacartabandoned": "Ocurre cuando un visitante identificado agrega un producto a su carrito vacío en Vnda",
      "vndaordershipped": "Ocurre cuando una compra es marcada como despachada en Vnda",
      "vndaordercancelled": "Ocurre cuando se cancela una orden de compra en Vnda",
      "lifecyclesrfm": "Aún no se configuró el disparador",
      "lifecyclesrfmchampionin": "Ocurre cuando un comprador ingresa a la etapa Campeones del ciclo de vida",
      "lifecyclesrfmchampionout": "Ocurre cuando un comprador sale de la etapa Campeones del ciclo de vida",
      "lifecyclesrfmloyalistin": "Ocurre cuando un comprador ingresa a la etapa Leales del ciclo de vida",
      "lifecyclesrfmloyalistout": "Ocurre cuando un comprador sale de la etapa Leales del ciclo de vida",
      "lifecyclesrfmrecentin": "Ocurre cuando un comprador ingresa a la etapa Recientes del ciclo de vida",
      "lifecyclesrfmrecentout": "Ocurre cuando un comprador sale de la etapa Recientes del ciclo de vida",
      "lifecyclesrfmhighpotentialin": "Ocurre cuando un comprador ingresa a la etapa Alto potencial del ciclo de vida",
      "lifecyclesrfmhighpotentialout": "Ocurre cuando un comprador sale de la etapa Alto potencial del ciclo de vida",
      "lifecyclesrfmneednutringin": "Ocurre cuando un comprador ingresa a la etapa Necesitan incentivo del ciclo de vida",
      "lifecyclesrfmneednutringout": "Ocurre cuando un comprador sale de la etapa Necesitan incentivo del ciclo de vida",
      "lifecyclesrfmcantlosein": "Ocurre cuando un comprador ingresa a la etapa No se pueden perder del ciclo de vida",
      "lifecyclesrfmcantloseout": "Ocurre cuando un comprador sale de la etapa No se pueden perder del ciclo de vida",
      "lifecyclesrfmatriskin": "Ocurre cuando un comprador ingresa a la etapa En riesgo del ciclo de vida",
      "lifecyclesrfmatriskout": "Ocurre cuando un comprador sale de la etapa En riesgo del ciclo de vida",
      "lifecyclesrfmabouttolosein": "Ocurre cuando un comprador ingresa a la etapa Por perder del ciclo de vida",
      "lifecyclesrfmabouttoloseout": "Ocurre cuando un comprador sale de la etapa Por perder del ciclo de vida",
      "custom": "Ocurre cuando se recibe un evento a través de la integración custom triggers."
    }
  },
  "pt": {
    "placeholderDescription": "Sem descrição",
    "enabled": "Ativo",
    "disabled": "Pausado",
    "trigger": "Disparador",
    "triggersTypes": {
      "contactlistadded": "Contato",
      "contactlistremoved": "Contato",
      "contactinterestadded": "Interesse",
      "contactinterestexpired": "Interesse",
      "contactinterestremoved": "Interesse",
      "optinconfirmed": "Inscrição",
      "tiendanubecartcreated": "Carrinho",
      "tiendanubenewslettersubscribed": "Inscrição",
      "tiendanubeordercancelled": "Compra",
      "tiendanubeordercreated": "Compra",
      "tiendanubeordercompleted": "Compra",
      "tiendanubeorderpacked": "Compra",
      "tiendanubeordershipped": "Compra",
      "tiendanubesessionfinished": "Visita",
      "vtexcartabandoned": "Carrinho",
      "vtexordercompleted": "Compra",
      "vtexusercreated": "Contato",
      "vtexnewslettersubscribed": "Inscrição",
      "vtexsessionfinished": "Visita",
      "fbleadscontactsubscribed": "Inscrição",
      "woocommerceordercompleted": "Compra",
      "woocommercecartcreated": "Carrinho",
      "signosordercompleted": "Compra",
      "signoscartcreated": "Carrinho",
      "recurringbirthday": "Aniversário",
      "jivochatchatfinished": "Chat",
      "pownewslettersubscribed": "Inscrição",
      "powordercompleted": "Compra",
      "poworderpacked": "Compra",
      "powordershipped": "Compra",
      "powordercreated": "Compra",
      "powsessionfinished": "Visita",
      "shopifyordercreated": "Compra",
      "shopifyordercompleted": "Compra",
      "shopifyordershipped": "Compra",
      "jumpsellerorderupdated": "Compra",
      "jumpsellerordercompleted": "Compra",
      "jumpsellerorderpending_payment": "Compra",
      "jumpsellerordercanceled": "Compra",
      "jumpsellerorderabandoned": "Carrinho",
      "jumpsellerordershipped": "Compra",
      "mshopsorderpaid": "Compra",
      "traycartcreated": "Carrinho",
      "trayorderpaid": "Compra",
      "traysessionfinished": "Visita",
      "ecwidorderpaid": "Compra",
      "ecwidorderready_for_pickup": "Compra",
      "ecwidordershipped": "Compra",
      "booticordercompleted": "Compra",
      "booticorderpaid": "Compra",
      "booticorderdelivered": "Compra",
      "booticorderready": "Compra",
      "booticorderpreparing": "Compra",
      "wbuycartabandoned": "Carrinho",
      "wbuyordercreated": "Compra",
      "wbuyorderpaid": "Compra",
      "wbuyorderem_transporte": "Compra",
      "wbuyorderem_expedicao": "Compra",
      "wbuyorderready_to_pickup": "Compra",
      "wbuyordercompleted": "Compra",
      "wbuysessionfinished": "Visita",
      "vndaorderpaid": "Compra",
      "vndacartabandoned": "Carrinho",
      "vndaordershipped": "Compra",
      "vndaordercancelled": "Compra",
      "lifecyclesrfm": "Ciclo de vida",
      "lifecyclesrfmchampionin": "Ciclo de vida",
      "lifecyclesrfmchampionout": "Ciclo de vida",
      "lifecyclesrfmloyalistin": "Ciclo de vida",
      "lifecyclesrfmloyalistout": "Ciclo de vida",
      "lifecyclesrfmrecentin": "Ciclo de vida",
      "lifecyclesrfmrecentout": "Ciclo de vida",
      "lifecyclesrfmhighpotentialin": "Ciclo de vida",
      "lifecyclesrfmhighpotentialout": "Ciclo de vida",
      "lifecyclesrfmneednutringin": "Ciclo de vida",
      "lifecyclesrfmneednutringout": "Ciclo de vida",
      "lifecyclesrfmcantlosein": "Ciclo de vida",
      "lifecyclesrfmcantloseout": "Ciclo de vida",
      "lifecyclesrfmatriskin": "Ciclo de vida",
      "lifecyclesrfmatriskout": "Ciclo de vida",
      "lifecyclesrfmabouttolosein": "Ciclo de vida",
      "lifecyclesrfmabouttoloseout": "Ciclo de vida",
      "custom": "Personalizado"
    },
    "triggersTypesComplete": {
      "optinconfirmed": "Inscrição confirmada",
      "contactlistadded": "Contato adicionado à lista",
      "contactlistremoved": "Contato removido da lista",
      "contactinterestadded": "Contato recebeu interesse",
      "contactinterestexpired": "Contato perdeu interesse (expirou)",
      "contactinterestremoved": "Contato perdeu interesse",
      "tiendanubecartcreated": "Carrinho criado",
      "tiendanubenewslettersubscribed": "Inscrição no boletim informativo",
      "tiendanubeordercancelled": "Compra cancelada",
      "tiendanubeordercreated": "Compra iniciada",
      "tiendanubeordercompleted": "Compra concluída",
      "tiendanubeorderpacked": "Compra embalada",
      "tiendanubeordershipped": "Compra enviada",
      "tiendanubesessionfinished": "Visita finalizada",
      "vtexcartabandoned": "Carrinho abandonado",
      "vtexordercompleted": "Compra concluída",
      "vtexusercreated": "Novo usuário criado",
      "vtexnewslettersubscribed": "Inscrição no boletim informativo",
      "vtexsessionfinished": "Visita finalizada",
      "fbleadscontactsubscribed": "Contato inscrito",
      "woocommerceordercompleted": "Compra concluída",
      "woocommercecartcreated": "Carrinho criado",
      "signosordercompleted": "Compra concluída",
      "signoscartcreated": "Carrinho criado",
      "recurringbirthday": "Data de aniversário",
      "jivochatchatfinished": "Conversa finalizada",
      "pownewslettersubscribed": "Inscrição no boletim informativo",
      "powordercompleted": "Compra concluída",
      "poworderpacked": "Pedido embalado",
      "powordershipped": "Pedido enviado",
      "powordercreated": "Compra iniciada",
      "powsessionfinished": "Visita finalizada",
      "shopifyordercreated": "Compra iniciada",
      "shopifyordercompleted": "Compra concluída",
      "shopifyordershipped": "Compra enviada",
      "jumpsellerorderupdated": "Status do pedido atualizado",
      "jumpsellerordercompleted": "Compra concluída",
      "jumpsellerorderpending_payment": "Compra pendente de pagamento",
      "jumpsellerordercanceled": "Compra cancelada",
      "jumpsellerorderabandoned": "Carrinho abandonado",
      "jumpsellerordershipped": "Compra enviada",
      "mshopsorderpaid": "Compra paga",
      "traycartcreated": "Carrinho criado",
      "trayorderpaid": "Compra paga",
      "traysessionfinished": "Visita finalizada",
      "ecwidorderpaid": "Compra paga",
      "ecwidorderready_for_pickup": "Compra pronta para retirada",
      "ecwidordershipped": "Compra enviada",
      "booticordercompleted": "Compra concluída",
      "booticorderpaid": "Compra paga",
      "booticorderdelivered": "Compra entregue",
      "booticorderready": "Compra pronta para retirada",
      "booticorderpreparing": "Compra em preparação para envio",
      "wbuycartabandoned": "Carrinho abandonado",
      "wbuyordercreated": "Compra iniciada",
      "wbuyorderpaid": "Compra paga",
      "wbuyorderem_transporte": "Pedido recebido para envio",
      "wbuyorderem_expedicao": "Pedido enviado",
      "wbuyorderready_to_pickup": "Pedido pronto para retirada",
      "wbuyordercompleted": "Pedido entregue",
      "wbuysessionfinished": "Visita finalizada",
      "vndaorderpaid": "Compra paga",
      "vndacartabandoned": "Carrinho abandonado",
      "vndaordershipped": "Compra enviada",
      "vndaordercancelled": "Compra cancelada",
      "lifecyclesrfm": "Seleção pendente do gatilho",
      "lifecyclesrfmchampionin": "Entrou na fase Campeões",
      "lifecyclesrfmchampionout": "Saiu da fase Campeões",
      "lifecyclesrfmloyalistin": "Entrou na fase Leais",
      "lifecyclesrfmloyalistout": "Saiu da fase Leais",
      "lifecyclesrfmrecentin": "Entrou na fase Recentes",
      "lifecyclesrfmrecentout": "Saiu da fase Recentes",
      "lifecyclesrfmhighpotentialin": "Entrou na fase Alto Potencial",
      "lifecyclesrfmhighpotentialout": "Saiu da fase Alto Potencial",
      "lifecyclesrfmneednutringin": "Entrou na fase Necessitam Incentivo",
      "lifecyclesrfmneednutringout": "Saiu da fase Necessitam Incentivo",
      "lifecyclesrfmcantlosein": "Entrou na fase Não Podem Perder",
      "lifecyclesrfmcantloseout": "Saiu da fase Não Podem Perder",
      "lifecyclesrfmatriskin": "Entrou na fase Em Risco",
      "lifecyclesrfmatriskout": "Saiu da fase Em Risco",
      "lifecyclesrfmabouttolosein": "Entrou na fase Prestes a Perder",
      "lifecyclesrfmabouttoloseout": "Saiu da fase Prestes a Perder",
      "custom": "Personalizado"
    },
    "triggersComments": {
      "contactlistadded": "Ocorre quando um contato pasa a pertencer à uma lista<br/>Os contatos criados ou modificados por meio de uma importação não serão incluídos",
      "contactlistremoved": "Ocorre quando um contato deixa de pertencer à uma lista",
      "contactinterestadded": "Ocorre quando um contato recebe um novo interesse<br/>Os contatos criados ou modificados por meio de uma importação não serão incluídos",
      "contactinterestexpired": "Ocorre quando um contato deixa de ter um interesse porque expirou",
      "contactinterestremoved": "Ocorre quando um contato deixa de ter um interesse",
      "optinconfirmed": "Ocorre quando se recebe uma nova inscrição por meio de um formulário de Perfit",
      "tiendanubecartcreated": "Ocorre quando um visitante identificado adiciona um produto ao seu carrinho vazio em Nuvemshop",
      "tiendanubenewslettersubscribed": "Ocorre quando um visitante completa o formulário de contato ou inscrição ao newsletter de Nuvemshop (popup, rodapé ou área de contato)",
      "tiendanubeordercancelled": "Ocorre quando uma compra é cancelado na sua Nuvemshop",
      "tiendanubeordercompleted": "Ocorre quando uma compra é finalizada e paga com sucesso na Nuvemshop",
      "tiendanubeordercreated": "Ocorre quando é finalizado o processo de checkout O pagamento pode ainda estar pendente",
      "tiendanubeorderpacked": "Ocorre quando o pedido é marcado como empacotado na sua Nuvemshop",
      "tiendanubeordershipped": "Ocorre quando o pedido é marcado como enviado na sua Nuvemshop",
      "tiendanubesessionfinished": "Ocorre após 15 minutos de inatividade de um visitante identificado",
      "vtexcartabandoned": "Ocorre quando um usuário identificado e com produtos no carrinho abandona sua sessão em VTEX",
      "vtexordercompleted": "Ocorre quando uma compra é finalizada com sucesso em VTEX",
      "vtexusercreated": "Ocorre quando um novo usuário é registrado em VTEX",
      "vtexnewslettersubscribed": "Ocorre quando um visitante completa o formulário de inscrição ao newsletter em VTEX",
      "vtexsessionfinished": "Ocorre após 15 minutos de inatividade de um visitante identificado",
      "fbleadscontactsubscribed": "Ocorre quando um novo formulário de Facebook Lead Ads é preenchido",
      "woocommerceordercompleted": "Ocorre quando uma compra é finalizada e paga com sucesso em WooCommerce",
      "woocommercecartcreated": "Ocorre quando um visitante identificado adiciona um produto ao seu carrinho em WooCommerce",
      "signosordercompleted": "Ocorre quando uma compra uma compra é finalizada e paga com sucesso em Billowshop",
      "signoscartcreated": "Ocorre quando um visitante identificado adiciona um produto ao seu carrinho vazio em Billowshop",
      "recurringbirthday": "Ocorre quando a data de aniversário de um contato é o dia atual",
      "jivochatchatfinished": "Ocorre quando uma conversa em JivoChat é encerrada pelo agente",
      "pownewslettersubscribed": "Ocurre cuando un visitante completa el formulario de contacto o suscripción a newsletter",
      "powordercompleted": "Ocurre cuando una compra es finalizada y pagada con éxito",
      "poworderpacked": "Ocurre cuando el pedido se marca como empaquetado",
      "powordershipped": "Ocurre cuando el pedido se marca como enviado",
      "powordercreated": "Ocurre cuando se inicial el proceso de compra",
      "powsessionfinished": "Ocorre após 15 minutos de inatividade de um visitante identificado",
      "shopifyordercreated": "Ocorre quando é finalizado o processo de checkout O pagamento pode ainda estar pendente",
      "shopifyordercompleted": "Ocorre quando uma compra é finalizada e paga com sucesso na Shopify",
      "shopifyordershipped": "Ocorre quando o pedido é marcado como enviado na sua Shopify",
      "jumpsellerorderupdated": "Ocorre quando o status de uma compra é modificado",
      "jumpsellerordercompleted": "Ocorre quando uma compra é finalizada com sucesso",
      "jumpsellerorderpending_payment": "Ocorre quando uma compra é criada e está pendente de pagamento",
      "jumpsellerordercanceled": "Ocorre quando uma compra é cancelada",
      "jumpsellerorderabandoned": "Ocorre quando um usuário identificado e com produtos no carrinho abandona sua sessão",
      "jumpsellerordershipped": "Ocorre quando o pedido é marcado como enviado",
      "mshopsorderpaid": "Ocorre quando uma compra é finalizada e paga com sucesso",
      "traycartcreated": "Ocurre cuando se crea un carrito en tray",
      "trayorderpaid": "Ocorre quando uma compra é finalizada e paga com sucesso",
      "traysessionfinished": "Ocurre cuando se finaliza una visita en Tray",
      "ecwidorderpaid": "Ocorre quando uma compra é finalizada com sucesso",
      "ecwidorderready_for_pickup": "Ocurre cuando la orden se marca como lista para retirar",
      "ecwidordershipped": "Ocurre cuando la orden se marca como enviada",
      "ecwidorderpacked": "Ocorre quando o pedido é marcado como empacotado",
      "ecwidorderfullfiled": "Ocorre quando o pedido é marcado como enviado",
      "wbuycartabandoned": "Ocorre quando um usuário identificado e com produtos no carrinho abandona sua sessão em Wbuy",
      "wbuyordercreated": "Ocorre quando é finalizado o processo de checkout O pagamento pode ainda estar pendente",
      "wbuyorderpaid": "Ocorre quando uma compra é finalizada e paga com sucesso",
      "wbuyorderem_transporte": "Ocorre quando uma compra é recebida pelo frete",
      "wbuyorderem_expedicao": "Ocorre quando uma compra é enviada",
      "wbuyorderready_to_pickup": "Ocorre quando uma compra está pronta para retirada",
      "wbuyordercompleted": "Ocorre quando uma compra é finalizada com sucesso em Wbuy",
      "wbuysessionfinished": "Ocorre após 15 minutos de inatividade de um visitante identificado",
      "vndaorderpaid": "Ocorre quando uma compra é finalizada e paga com sucesso",
      "vndacartabandoned": "Ocorre quando um usuário identificado e com produtos no carrinho abandona sua sessão em Vnda",
      "vndaordershipped": "Ocorre quando o pedido é marcado como enviado em Vnda",
      "vndaordercancelled": "Ocorre quando uma compra é cancelado na sua Vnda",
      "lifecyclesrfm": "Ainda não foi configurado o acionador",
      "lifecyclesrfmchampionin": "Ocorre quando um comprador entra no estágio Campeões do ciclo de vida",
      "lifecyclesrfmchampionout": "Ocorre quando um comprador sai do estágio Campeões do ciclo de vida",
      "lifecyclesrfmloyalistin": "Ocorre quando um comprador entra no estágio Leais do ciclo de vida",
      "lifecyclesrfmloyalistout": "Ocorre quando um comprador sai do estágio Leais do ciclo de vida",
      "lifecyclesrfmrecentin": "Ocorre quando um comprador entra no estágio Recentes do ciclo de vida",
      "lifecyclesrfmrecentout": "Ocorre quando um comprador sai do estágio Recentes do ciclo de vida",
      "lifecyclesrfmhighpotentialin": "Ocorre quando um comprador entra no estágio Alto potencial do ciclo de vida",
      "lifecyclesrfmhighpotentialout": "Ocorre quando um comprador sai do estágio Alto potencial do ciclo de vida",
      "lifecyclesrfmneednutringin": "Ocorre quando um comprador entra no estágio Precisam de incentivo do ciclo de vida",
      "lifecyclesrfmneednutringout": "Ocorre quando um comprador sai do estágio Precisam de incentivo do ciclo de vida",
      "lifecyclesrfmcantlosein": "Ocorre quando um comprador entra no estágio Não se podem perder do ciclo de vida",
      "lifecyclesrfmcantloseout": "Ocorre quando um comprador sai do estágio Não se podem perder do ciclo de vida",
      "lifecyclesrfmatriskin": "Ocorre quando um comprador entra no estágio Em risco do ciclo de vida",
      "lifecyclesrfmatriskout": "Ocorre quando um comprador sai do estágio Em risco do ciclo de vida",
      "lifecyclesrfmabouttolosein": "Ocorre quando um comprador entra no estágio Por perder do ciclo de vida",
      "lifecyclesrfmabouttoloseout": "Ocorre quando um comprador sai do estágio Por perder do ciclo de vida",
      "custom": "Ocorre quando um evento é recebido por meio da integração de custom triggers"
    }
  }
}
</i18n>
