var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class='circle-logo'></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='login-panel'>\n    <form method='POST' data-form='recover'>\n        <div class='login-panel-header'>\n            <h1>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"reset")), depth0))
    + "\n            </h1>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"config") : depth0)) != null ? lookupProperty(stack1,"whiteLabel") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='login-panel-body'>\n            <h3 class='recover-message'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"resetMessage")), depth0))
    + "\n            </h3>\n        </div>\n        <div class='login-panel-footer'>\n            <button class='btn btn-primary btn-outline' type='button' data-action='render-login'>\n                <i class='fa fa-arrow-left'></i>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"back")), depth0))
    + "\n            </button>\n        </div>\n    </form>\n</div>\n<div class='login-help'>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"help")), depth0)) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});