import { defineComponent, watch } from "vue";

export const OpenStatus = defineComponent({
  props: {
    open: Boolean,
  },
  emits: ["open", "close"],
  setup: (attrs, { emit }) => {
    watch(attrs, () => {
      if (attrs?.open) {
        emit("open");
      } else {
        emit("close");
      }
    });

    return () => null;
  },
});
