var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <table class=\"table\">\n                    <thead>\n                        <tr>\n                            <th>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"start")), depth0))
    + "\n                            </th>\n                            <th>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"end")), depth0))
    + "\n                            </th>\n                            <th>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"consumed")), depth0))
    + "\n                            </th>\n                        </tr>\n                    </thead>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"usage") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":39,"column":29}}})) != null ? stack1 : "")
    + "                </table>\n                <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"consumedLegend")), depth0))
    + "\n                </p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>"
    + alias2(__default(require("../../../../helpers/handlebars/moment.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"periodStart") : depth0),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"shortDateList")),{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":81}}}))
    + "</td>\n                        <td>"
    + alias2(__default(require("../../../../helpers/handlebars/moment.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"periodEnd") : depth0),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"shortDateList")),{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":79}}}))
    + "</td>\n                        <td>"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"recipients") : depth0), depth0))
    + "</td>\n                    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog modal-lg'>\n    <div class='modal-content'>\n        <div class='modal-header'>\n            <button type='button' class='close' data-dismiss='modal'>\n                <span aria-hidden='true'>\n                    &times;\n                </span>\n            </button>\n            <h4 class='modal-title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"usage")), depth0))
    + "\n            </h4>\n        </div>\n        <div class='modal-body'>\n            <div class='modal-body-padded'>\n                <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"available")), depth0))
    + ": "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"available") : depth0)) != null ? lookupProperty(stack1,"credits") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"credits")), depth0))
    + "\n                </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"usage") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":44,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class='modal-footer'>\n            <button type='button' class='btn btn-primary' data-dismiss='modal'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"close")), depth0))
    + "\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});