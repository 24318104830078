import { DashboardApplication } from "./dashboard.types";
import { DashboardData, Period } from "@domain/dashboard/dashboard";

export const useDashboardApplication = (): DashboardApplication => {
  const dashboardApp: DashboardApplication = {
    calculateDashboardData: ({ data, periodFrom, periodTo, planType, planCost, batchType, attributionModel }) => {
      return new DashboardData(
        data,
        new Period(periodFrom, periodTo),
        {
          planType,
          planCost,
        },
        batchType,
        attributionModel
      );
    },
  };
  return dashboardApp;
};
