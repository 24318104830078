import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { useSessionStore } from "@store";

export const useGlobalStore = defineStore("globalStore", () => {
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);

  const isHomePage = ref<boolean>(false);
  const updateState = () => {
    const isHome = window.location.pathname.includes("home");

    isHomePage.value = isHome;
  };

  const showChangeAccount = computed<boolean>(() => {
    const hasPermissions = Object.entries(session?.value?.acl).length > 1;
    const isMasterUser = session?.value?.isMasterUser && session?.value?.account?.code !== "";

    if (hasPermissions || isMasterUser) return true;

    return false;
  });

  return { isHomePage, showChangeAccount, updateState };
});
