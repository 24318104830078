<template>
  <svg
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin: 0.5rem 1rem"
  >
    <rect x="1" y="21" width="178" height="83" rx="4" stroke="#00ADE8" stroke-width="2" />
    <rect x="17" y="42" width="145" height="5" rx="2.5" fill="#E3F5FC" />
    <path
      d="M17 54.5C17 53.1193 18.1193 52 19.5 52H63.8451C65.2258 52 66.3451 53.1193 66.3451 54.5C66.3451 55.8807 65.2258 57 63.8451 57H19.5C18.1193 57 17 55.8807 17 54.5Z"
      fill="#E3F5FC"
    />
    <rect x="120.945" y="65" width="41.0619" height="18" rx="2" fill="#00ADE8" />
    <rect x="18" y="66" width="92" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
  </svg>
</template>

<script setup></script>
