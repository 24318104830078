<template>
  <ModalDialog :title="t('PeriodicReportConfigTitle')" :open="isOpen" @close="HandleClose">
    <PeriodicReportConfig />
  </ModalDialog>
</template>

<script lang="ts" setup>
//Components
import PeriodicReportConfig from "./PeriodicReportConfig.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import { useI18n } from "vue-i18n";

//Props
withDefaults(
  defineProps<{
    isOpen?: boolean;
  }>(),
  {
    isOpen: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:isOpen", value: boolean): void;
  (e: "close"): void;
}>();

//I18n
const { t } = useI18n();

const HandleClose = () => {
  emit("update:isOpen", false);
  emit("close");
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "PeriodicReportConfigTitle": "Configurar informes periódicos"
  },
  "pt": {
    "PeriodicReportConfigTitle": "Configurar relatórios periódicos"
  }
}
</i18n>
