<template>
  <div class="mx-auto flex h-full w-full items-center lg:w-[62rem]">
    <div class="flex px-10 lg:space-x-20">
      <div class="flex flex-col justify-center">
        <h1 class="text-3xl font-bold text-gray-700">{{ props.title }}</h1>
        <ul class="mt-4 space-y-2 text-base font-normal text-gray-500">
          <li v-for="(point, index) in props.points" :key="index" class="flex space-x-2">
            <CheckIcon class="mt-0.5 h-5 w-5 shrink-0 text-sky-500" aria-hidden="true" />
            <span>{{ point }}</span>
          </li>
        </ul>
        <div v-if="props.actionText" class="mt-4">
          <SimpleButton theme="primary-clean-2" @click="onAction">
            <template #leading>
              <slot name="buttonIcon" />
            </template>
            {{ props.actionText }}
          </SimpleButton>
        </div>
        <div class="mt-8">
          <button
            v-if="props.articleText"
            class="text-sm font-semibold text-gray-600 hover:text-gray-700"
            @click="openArticle"
          >
            <div class="flex space-x-2">
              <span>
                {{ props.articleText }}
              </span>
              <ArrowRightIcon class="my-auto h-3 w-3" />
            </div>
          </button>
        </div>
      </div>
      <slot name="image" />
    </div>
  </div>
</template>

<script lang="ts" setup>
// Components
import SimpleButton from "@atoms/SimpleButton.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";
import { ArrowRightIcon } from "@heroicons/vue/outline";

const props = withDefaults(
  defineProps<{
    articleId: number;
    articleText: string;
    title: string;
    actionText?: string;
    points: string[];
  }>(),
  {
    points: () => [],
  },
);

const emit = defineEmits<{
  action: [];
}>();

const onAction = () => {
  emit("action");
};

const openArticle = () => {
  window.Intercom("showArticle", props.articleId);
};
</script>
