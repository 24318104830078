import { Model } from "@/core/magnet";
import app from "@/app";

export default Model.extend({
  urlRoot: "optins",
  remoteDefaults: () => `/data/optin-default-${app.lang}.json`,
  parse: function (response) {
    return response.data;
  },
  relationships: {
    interests: function () {
      if (!app.session.hasPermission("interests:list")) {
        return app.helpers.emptyRelationship();
      }
      return $.get("interests", { limit: 1000 });
    },
    lists: function () {
      if (!app.session.hasPermission("lists:list")) {
        return app.helpers.emptyRelationship();
      }
      return $.get("lists", { limit: 1000 });
    },
    fields: function () {
      if (!app.session.hasPermission("fields:list")) {
        return app.helpers.emptyRelationship();
      }
      return $.get("fields", { limit: 300 });
    },
  },
  additionals: {
    froms: function () {
      return $.get("froms", { limit: 100 });
    },
  },

  // Parses the fetched results of the relationships
  parseRelationships: function (relationships) {
    var self = this,
      type,
      formData = self.get("form");

    relationships = self.checkRelationships(relationships);

    // Add values to fields and interests elements
    $.each(["fields", "interests"], function () {
      type = this;
      if (formData !== undefined && $.isArray(formData[type])) {
        $.each(formData[type], function (key, current) {
          $.each(relationships[type], function (index, value) {
            // Set as selector
            if (self.fieldIsSelect(value.id)) {
              value.isSelect = true;
            }

            // Mark field as checked and add custom data
            if (value.id === current.id) {
              current.checked = true;
              formData[type][key] = $.extend(value, current);
              relationships[type][index] = $.extend(value, current);
            }
          });
        });
      }
    });
    relationships = self.sortRelationships(relationships);
    return relationships;
  },

  // Defines if a field is a selector
  fieldIsSelect: function (id) {
    var selects = [4, 5, 7];
    return $.inArray(id, selects) > -1;
  },
});
