<template>
  <a
    :href="link"
    target="_blank"
    class="flex flex-col space-y-2 rounded-lg px-5 py-5 text-left text-gray-700 hover:bg-gray-100"
  >
    <div class="flex items-center space-x-2">
      <SimpleBadge v-if="tag" square theme="sky-solid">{{ tag ?? t("event") }}</SimpleBadge>
      <p class="truncate text-xs font-normal text-gray-500">{{ date }}</p>
    </div>
    <span class="text-sm font-medium text-gray-700"> {{ title }} </span>
  </a>
</template>

<script lang="ts" setup>
// Components
import SimpleBadge from "@atoms/SimpleBadge/SimpleBadge.vue";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

withDefaults(
  defineProps<{
    title: string;
    date: DateString;
    link: UrlString;
    tag?: string;
  }>(),
  {},
);
</script>
<i18n lang="jsonc">
{
  "es": {
    "event": "Evento"
  },
  "pt": {
    "event": "Evento"
  }
}
</i18n>
