<template>
  <tbody class="divide-y divide-gray-200 bg-white">
    <template v-if="skeleton">
      <tr v-for="index in skeletonCount" :key="index">
        <td v-if="selectable" class="w-12 pl-2 pr-4 pt-1">
          <div class="w-2 animate-pulse rounded bg-gray-100 p-2" />
        </td>
        <td
          v-for="column in columns"
          :key="column.id"
          :class="[
            {
              hidden:
                column.breakpoint !== undefined &&
                ((column.breakpoint === 'sm' && !smBp) ||
                  (column.breakpoint === 'md' && !mdBp) ||
                  (column.breakpoint === 'lg' && !lgBp) ||
                  (column.breakpoint === 'xl' && !xlBp) ||
                  (column.breakpoint === '2xl' && !xxlBp)),
            },
          ]"
          :style="{
            textAlign: `${column.textAlign ?? 'left'}`,
          }"
        >
          <slot
            v-if="$slots?.[`${column.id as string}-skeleton`]"
            :name="`${column.id as string}-skeleton`"
            :item="''"
            :row="{} as Row<RowId, ColId, RowData>"
            :rows="[] as Rows<RowId, ColId, RowData>"
            :column="{} as Column<ColId, SortIdType, ColData>"
            :columns="{} as Columns<ColId, SortIdType, ColData>"
          />
          <component :is="column.customCellSkeleton" v-else-if="column.customCellSkeleton" />
          <slot
            v-else-if="$slots?.['defaultCell-skeleton']"
            name="defaultCell-skeleton"
            :item="''"
            :row="{} as Row<RowId, ColId, RowData>"
            :rows="[] as Rows<RowId, ColId, RowData>"
            :column="{} as Column<ColId, SortIdType, ColData>"
            :columns="{} as Columns<ColId, SortIdType, ColData>"
          />
          <div v-else class="mx-4 my-4 h-4 rounded-xl bg-gray-100 px-4" />
        </td>
      </tr>
    </template>
    <template v-else>
      <TransitionGroup
        leave-active-class="transition duration-100 ease"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 transform translate-x-3"
        enter-active-class="transition duration-100 ease"
        enter-to-class="opacity-100"
        enter-from-class="opacity-0 transform -translate-x-3"
      >
        <tr
          v-for="row in rows"
          v-show="rows.length > 0"
          :key="row.id"
          :class="[
            {
              'bg-sky-50': rowIsSelected(row),
              'hover:bg-gray-50': !rowIsSelected(row),
              'cursor-pointer': selectedRows.length > 0,
            },
          ]"
          @click="() => handleClickRow(row)"
        >
          <td
            v-if="selectable"
            :class="[row.options?.selectable === false && 'pointer-events-none', 'w-12 pl-2 pr-4 pt-1']"
          >
            <CheckBox
              :model-value="rowIsSelected(row)"
              :disabled="row.options?.selectable === false"
              :class="[{ 'pointer-events-none': selectedRows.length > 0 }, 'disabled:bg-gray-200']"
              @update:model-value="() => onSelect(row)"
            />
          </td>
          <td
            v-for="column in columns"
            :key="column.id"
            :class="[
              {
                hidden:
                  column.breakpoint !== undefined &&
                  ((column.breakpoint === 'sm' && !smBp) ||
                    (column.breakpoint === 'md' && !mdBp) ||
                    (column.breakpoint === 'lg' && !lgBp) ||
                    (column.breakpoint === 'xl' && !xlBp) ||
                    (column.breakpoint === '2xl' && !xxlBp)),
              },
            ]"
            :style="{
              textAlign: `${column.textAlign ?? 'left'}`,
            }"
          >
            <slot
              v-if="$slots?.[column.id]"
              :name="column.id"
              :item="row.content?.[column.id]"
              :row="row"
              :rows="rows"
              :column="column"
              :columns="columns"
            />
            <component :is="column.customCell" v-else-if="column.customCell" :row="row" :column="column" />
            <slot
              v-else-if="$slots?.['defaultCell']"
              name="defaultCell"
              :item="row.content?.[column.id]"
              :row="row"
              :rows="rows"
              :column="column"
              :columns="columns"
            />
            <span v-else>
              {{ getRowContentByColumn(row, column) }}
            </span>
          </td>
        </tr>
        <tr v-show="rows.length === 0" key="table-tbody-no-rows">
          <td v-if="selectable" class="pointer-events-none w-12 pl-2 pr-4 pt-1" />
          <td
            v-for="column in columns"
            :key="column.id"
            :class="[
              {
                hidden:
                  column.breakpoint !== undefined &&
                  ((column.breakpoint === 'sm' && !smBp) ||
                    (column.breakpoint === 'md' && !mdBp) ||
                    (column.breakpoint === 'lg' && !lgBp) ||
                    (column.breakpoint === 'xl' && !xlBp) ||
                    (column.breakpoint === '2xl' && !xxlBp)),
              },
            ]"
          />
        </tr>
      </TransitionGroup>
      <tr v-show="loading" key="table-tbody-loading-spinner-key">
        <td :colspan="columns.length" class="pt-4">
          <LoadingSpinner class="mx-auto h-5 w-5 text-sky-500" aria-hidden="true" />
        </td>
      </tr>
    </template>
  </tbody>
</template>
<script
  lang="ts"
  setup
  generic="ColId extends string, SortIdType extends string, ColData, RowId extends string, RowData"
>
import { ref } from "vue";

// Components
import CheckBox from "@atoms/CheckBox.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Composables
import { useBreakpoints } from "@composables/breakpoints";
import { useMagicKeys } from "@vueuse/core";

// Types
import type { Columns, Column, Rows, Row } from "../table.type";
import { getRowContentByColumn, getRowById } from "../table.type";

const { smBp, mdBp, lgBp, xlBp, xxlBp } = useBreakpoints();
const { shift } = useMagicKeys();

//Props
const props = withDefaults(
  defineProps<{
    columns: Columns<ColId, SortIdType, ColData>;
    rows: Rows<RowId, ColId, RowData>;
    selectable?: boolean;
    selectedRows?: Rows<RowId, ColId, RowData>;
    skeleton?: boolean;
    loading?: boolean;
    skeletonCount?: number;
  }>(),
  {
    selectable: false,
    skeleton: false,
    loading: false,
    skeletonCount: 4,
    selectedRows: () => [],
  },
);

const emit = defineEmits<{
  select: [Row<RowId, ColId, RowData>];
  selects: [Rows<RowId, ColId, RowData>];
  deselect: [Row<RowId, ColId, RowData>];
}>();

const selectRow = (row: Row<RowId, ColId, RowData>) => {
  emit("select", row);
};

const selectRows = (rows: Rows<RowId, ColId, RowData>) => {
  emit("selects", rows);
};

const deselectRow = (row: Row<RowId, ColId, RowData>) => {
  emit("deselect", row);
};

const lastSelectedRow = ref<Row<RowId, ColId, RowData>>();
const handleSelectRow = (row: Row<RowId, ColId, RowData>) => {
  const foundRow = getRowById<RowId, ColId, RowData>(props.selectedRows, row.id);

  if (foundRow) {
    deselectRow(row);
    return;
  }
  selectRow(row);
  return;
};

const onSelect = (row: Row<RowId, ColId, RowData>) => {
  const lastSelected = lastSelectedRow.value;
  lastSelectedRow.value = row;

  if (shift.value && lastSelected) {
    const lastRowIndex = props.rows.findIndex((r) => r.id === lastSelected.id);
    const currentRowIndex = props.rows.findIndex((r) => r.id === row.id);
    if (lastRowIndex === -1 || currentRowIndex === -1 || currentRowIndex === lastRowIndex) {
      handleSelectRow(row);
      return;
    }
    const [start, end] = [lastRowIndex, currentRowIndex].sort((a, b) => a - b);
    const rowsBetween = props.rows.slice(start, end + 1);
    selectRows(rowsBetween);
  } else {
    handleSelectRow(row);
  }
};

const handleClickRow = (row: Row<RowId, ColId, RowData>) => {
  if (props.selectedRows.length === 0) return;
  onSelect(row);
};

const rowIsSelected = (row: Row<RowId, ColId, RowData>): boolean => {
  const foundRow = getRowById<RowId, ColId, RowData>(props.selectedRows, row.id);
  return !!foundRow;
};
</script>
