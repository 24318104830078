<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div class="mt-6">
      <Chart :options="chartOptions" :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, toRef, inject } from "vue";
import Chart from "@molecules/Chart.vue";
import { useI18n } from "vue-i18n";

import { DashboardData } from "@domain/dashboard/dashboard";
import {
  useCharts,
  CategoryResolvers,
  Accumulator,
  ChartPresets,
  sumIfCurrent,
  sumIfPrevious,
  CategoryResolver,
} from "@composables/charts";

interface IChartPoint {
  current: {
    automation: { count: number; amount: number };
    bulk: { count: number; amount: number };
  };
  previous: {
    automation: { count: number; amount: number };
    bulk: { count: number; amount: number };
  };
}

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const { t, n, d } = useI18n();

const newChartPoint = (): IChartPoint => ({
  current: {
    automation: { count: 0, amount: 0 },
    bulk: { count: 0, amount: 0 },
  },
  previous: {
    automation: { count: 0, amount: 0 },
    bulk: { count: 0, amount: 0 },
  },
});

const accumulate: Accumulator<IChartPoint> = (acc, newData, periodType) => {
  let source = newData.order.source;

  if (props.data?.attributionModel === "clicks") source = newData.order.source_click;

  return {
    current: {
      bulk: {
        count: sumIfCurrent(
          periodType,
          acc.current.bulk.count,
          source
            .filter((i) => i.group === "bulk")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfCurrent(
          periodType,
          acc.current.bulk.amount,
          source
            .filter((i) => i.group === "bulk")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
      automation: {
        count: sumIfCurrent(
          periodType,
          acc.current.automation.count,
          source
            .filter((i) => i.group === "automation")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfCurrent(
          periodType,
          acc.current.automation.amount,
          source
            .filter((i) => i.group === "automation")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
    },
    previous: {
      bulk: {
        count: sumIfPrevious(
          periodType,
          acc.previous.bulk.count,
          source
            .filter((i) => i.group === "bulk")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfPrevious(
          periodType,
          acc.previous.bulk.amount,
          source
            .filter((i) => i.group === "bulk")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
      automation: {
        count: sumIfPrevious(
          periodType,
          acc.previous.automation.count,
          source
            .filter((i) => i.group === "automation")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfPrevious(
          periodType,
          acc.previous.automation.amount,
          source
            .filter((i) => i.group === "automation")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
    },
  };
};

const categoryResolver = computed<CategoryResolver>(() => {
  if (props.data && props.data.period.days <= 7) return CategoryResolvers.daily;
  if (props.data && props.data.period.days > 35) return CategoryResolvers.monthly;
  return CategoryResolvers.weekly;
});

const { chartData } = useCharts(toRef(props, "data"), categoryResolver, accumulate, newChartPoint, false, (date) =>
  props.data?.period.isWithinSameYear() ? d(date, "short") : d(date, "shortWithYear")
);

const plotOptions = pdfMode
  ? {
      ...ChartPresets.plotOptions,
      chart: {
        animation: false,
      },
      series: {
        ...ChartPresets.plotOptions.series,
        animation: false,
      },
      drilldown: {
        animation: false,
      },
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
    }
  : {
      ...ChartPresets.plotOptions,
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
    };

const chartOptions = computed(() => {
  const options: any = {
    chart: {
      height: 280,
      width: pdfMode ? 800 : null,
    },
    title: "",
    tooltip: ChartPresets.tooltip((val) => n(val, "currency")),
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      ...ChartPresets.xAxis,
      categories: chartData.value && Object.keys(chartData.value),
    },
    credits: false,
    plotOptions: plotOptions,
    legend: {
      ...ChartPresets.legend,
    },
    series: [
      {
        name: t("bulk"),
        id: "bulk",
        index: 1,
        color: ChartPresets.colors.darkBlue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.bulk.amount),
        type: "column",
        stack: 0,
      },
      {
        name: t("automations"),
        id: "automation",
        index: 3,
        color: ChartPresets.colors.blue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.automation.amount),
        type: "column",
        stack: 0,
      },
    ],
  };

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "bulk": "Campañas",
    "automations": "Automations"
  },
  "pt": {
    "bulk": "Campanhas",
    "automations": "Automations"
  }
}
</i18n>
