<template>
  <div
    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <CheckCircleIcon v-if="notification.theme === 'success'" class="h-6 w-6 text-green-400" aria-hidden="true" />
          <ExclamationCircleIcon
            v-else-if="notification.theme === 'error'"
            class="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
          <InformationCircleIcon
            v-else-if="notification.theme === 'info'"
            class="h-6 w-6 text-sky-500"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{ notification.title }}
          </p>
          <p v-if="notification.text" class="mt-1 text-sm text-gray-500" v-html="notification.text"></p>
          <SimpleButton
            v-for="(button, index) in notification.buttons"
            :key="index"
            :theme="button?.theme ?? 'primary-clean'"
            size="min"
            class="mt-2"
            @click="onButtonClicked(button, notification)"
          >
            {{ button.text }}
          </SimpleButton>
        </div>
        <div class="ml-4 flex flex-shrink-0">
          <button
            v-if="notification.closable"
            class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2"
            @click="$emit('close')"
          >
            <span class="sr-only">Close</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Components
import SimpleButton from "@atoms/SimpleButton.vue";

// Icons
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/vue/outline";
import { XIcon } from "@heroicons/vue/solid";

// Composables
import { Notification, NotificationButton } from "@/vue/composables/notifications";

const props = withDefaults(
  defineProps<{
    notification: Notification;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [];
}>();

const onButtonClicked = (buttonConfig: NotificationButton, notification: Notification) => {
  buttonConfig.action({ notification });
  emit("close");
};
</script>
