<template>
  <a
    :href="tiendanubeUrl"
    class="flex w-full items-center justify-center gap-3 rounded-md border border-gray-300 bg-white px-4 py-2.5 text-[#0050C3] hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0050C3]"
    @click="setLoading"
  >
    <img v-if="!loading && lang === 'pt'" class="h-4 w-full" src="/img/nuvemshop-logo.svg" alt="Nuvemshop" />
    <img v-else-if="!loading" class="h-4 w-full" src="/img/tiendanube-logo.svg" alt="Tiendanube" />

    <LoadingSpinner v-if="loading" class="h-5 w-5" aria-hidden="true" />
  </a>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

// Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Application
import { useIntegrationsApp } from "@application";

const integrationsApp = useIntegrationsApp();

const lang = window.app.lang;

const tiendanubeUrl = ref("/");
const loading = ref(false);
const setLoading = () => {
  loading.value = true;
};

onMounted(async () => {
  const res = await integrationsApp.getExternalIntegrationUrl({
    integration: "tiendanube",
    lang,
  });

  if (res.isErr()) return;

  const url = new URL(res.value);

  tiendanubeUrl.value = `${url.origin}${url.pathname}` ?? "/";
});
</script>
