var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <div class='clear-search' data-action='remove-filter' data-params='[\"q\"]'>\n                            <i class='fa fa-close'></i>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button class='btn btn-primary' data-type='none' data-action='open-credits-modal'>\n            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"assignCredits")), depth0))
    + "\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='drips section'>\n    <div class='section-header'>\n        <h1>\n            "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":50}}}))
    + "\n        </h1>\n        <div class='filters'>\n            <ul class='nav nav-pills' data-role='filters'>\n                <li class='search'>\n                    <form data-role='search' data-form='search'>\n                        <button class='btn btn-default' type='button' data-action='open-search-form'>\n                            <i class='filter-icon pi-filter-search'></i>\n                        </button>\n                        <input type='text' placeholder='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' class='form-control' name='q'\n                            value='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + "                    </form>\n                </li>\n            </ul>\n        </div>\n    </div>\n    <div class='toolbar' data-role='toolbar'>\n        <a class='btn btn-primary' data-type='none' data-action='open-select-flow-modal'\n            data-require-permission='campaigns:create'>\n            <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"create")), depth0))
    + "\n        </a>\n        <a class='btn btn-default hide' data-action='mass' data-method='archive'\n            data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmArchive")), depth0))
    + "' data-confirm-title='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"archive")), depth0))
    + "'\n            data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massArchive")), depth0))
    + "'>\n            "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"archive")), depth0))
    + "\n        </a>\n        <a class='btn btn-default hide' data-action='mass' data-method='unarchive'\n            data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmUnarchive")), depth0))
    + "' data-confirm-title='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "'\n            data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massUnarchive")), depth0))
    + "'>\n            "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\n        </a>\n        <button class='btn btn-danger hide' data-action='mass' data-method='destroy'\n            data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massDestroy")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'>\n            "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n        </button>\n        <button class='btn btn-primary' data-type='none' data-action='open-usage-modal'>\n            "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"seeUsage")), depth0))
    + "\n        </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "        <div class='paging-selected' data-role='check-count'></div>\n    </div>\n    <div class='list-controls'>\n        <div class='paging-total' data-role='paging-total'></div>\n    </div>\n\n    <div class='content' data-role='content'>\n        <div class='list'>\n            <ul class='table' data-role='rows'></ul>\n            <div class='loading-box' data-role='items-loader'></div>\n            <button class='btn btn-primary load-more' data-action='load-more'>\n                "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true});