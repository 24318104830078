import type { Permission, Permissions } from "@domain/permissions";
import type { AccountFeatures } from "@domain/account";
import type { CountryCode } from "@domain/countries";
import type { PlanType, PlanState } from "@domain/plan";

export type UserType = "MASTER" | "GUEST" | "PARENT" | "ACCOUNT";

export interface Session {
  account: {
    code: string;
    name: string;
    parent: string | null;
    created: DateString;
    lastLogin: DateString;
    lastUsage: DateString;
    country: CountryCode;
    username: string;
    postalAddress: string;
    container: boolean;
    industry: string;
    plan?: {
      state: PlanState;
      type: PlanType;
      subscription?: {
        price: {
          total: number;
        };
        periodEnds: string;
      };
      emails: {
        available: number;
        excessLimit: number;
        limit: number;
      };
      contacts: {
        active: number;
        limit: number;
      };
    };
  };
  canChangeAccount: boolean;
  isMasterUser: boolean;
  acl: Record<string, string[]>;
  token: string;
  lang: string;
  user: {
    name: string;
    email: string;
  };
}

export interface LegacySessionDetails {
  account: {
    code: string;
    name: string;
    parent: null;
    container: boolean;
    created: DateString;
    lastLogin: DateString;
    lastUsage: DateString;
    disabled: boolean;
    country: "ar";
    language: "es" | "pt";
    postalAddress: string;
    industry: string;
    features: AccountFeatures;
    plan: {
      type: PlanType;
      state: PlanState;
      emails: {
        limit: number;
        available: number;
        excessLimit: number;
      };
      contacts: {
        limit: number;
        active: number;
      };
      blockUnpaid: boolean;
    };
  } | null;
  plan: {
    type: PlanType;
    state: PlanState;
    emails: {
      limit: number;
      available: number;
      excessLimit: number;
    };

    contacts: {
      limit: number;
      active: number;
    };
    blockUnpaid: boolean;
  } | null;
  stats: null;
  user?: {
    username: string;
    email: string;
    name: string;
    language: "es";
    created: DateString;
    lastLogin: DateString;
    passLastChanged: DateString;
    passNeverExpires: boolean;
    disabled: boolean;
    permissions: null;
    timezone: string; //"America/Buenos_Aires" - Replace with type list
  } | null;
}

export interface LegacySession {
  account: string;
  acl: Record<string, Permissions>;
  canChangeAccount: boolean;
  details: LegacySessionDetails;
  inAccount: boolean;
  isGuest: boolean;
  isMasterUser: boolean;
  lang: "es" | "pt";
  lastActivity: number;
  mainAccount: string;
  permissions: Record<string, Record<string, Array<string>>>;
  started: boolean;
  token: string;
  tokenExpiration: number;
  user: string;
  userType: UserType;
}

export const legacySessionToSession = (legacySession: LegacySession): Session => {
  return {
    account: {
      country: legacySession.details?.account?.country ?? "ar",
      username: legacySession.details?.user?.username ?? "",
      code: legacySession.details?.account?.code ?? "",
      name: legacySession.details?.account?.name ?? "",
      parent: legacySession.details?.account?.parent ?? "",
      created: legacySession.details?.account?.created ?? "",
      plan: legacySession.details?.plan || legacySession.details?.account?.plan,
      postalAddress: legacySession.details?.account?.postalAddress ?? "",
      container: legacySession?.details?.account?.container ?? false,
      industry: legacySession?.details?.account?.industry ?? "",
      lastLogin: legacySession?.details?.account?.lastLogin ?? "",
      lastUsage: legacySession?.details?.account?.lastUsage ?? "",
    },
    canChangeAccount: legacySession.canChangeAccount,
    isMasterUser: legacySession.isMasterUser,
    token: legacySession.token,
    lang: legacySession.lang,
    acl: legacySession.acl,
    user: {
      name: legacySession.details?.user?.name ?? "",
      email: legacySession.details?.user?.email ?? "",
    },
  };
};

export const updateSession = (session: Session, partialSession: DeepPartial<Session>): Session => {
  const updatedSession: Session = { ...session };

  for (const key in partialSession) {
    if (Object.prototype.hasOwnProperty.call(partialSession, key)) {
      if (typeof partialSession[key] === "object" && partialSession[key] !== null) {
        updatedSession[key] = updateSession(updatedSession[key], partialSession[key]);
      } else {
        updatedSession[key] = partialSession[key];
      }
    }
  }

  return updatedSession;
};

export const sessionHasPermission = (session: Session, permission: Permission) => {
  const account = session.isMasterUser ? "*" : session.account.code;
  return session.acl[account].includes(permission);
};
