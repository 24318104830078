import { ref, watchEffect, Ref, ComputedRef, unref } from "vue";
import { TooltipFormatterContextObject } from "highcharts";
import {
  parseISO,
  startOfWeek,
  endOfWeek,
  // format,
  startOfMonth,
  endOfMonth,
  isAfter,
  addDays,
} from "date-fns";
import { DashboardData, Period, IDashboardDataPoint, PeriodType } from "@domain/dashboard/dashboard";

type DateFormatter = (date: Date) => string;

export type CategoryResolver = (
  date: Date,
  period: Period,
  formatter: DateFormatter,
  compare?: boolean
) => string | undefined;
export type Accumulator<T> = (acc: T, data: IDashboardDataPoint, periodType: PeriodType, date: Date) => T;

export function useCharts<T>(
  dashboardData: Ref<DashboardData | undefined>,
  categoryResolver: ComputedRef<CategoryResolver> | CategoryResolver,
  accumulator: Accumulator<T>,
  newChartPoint: () => T,
  compare: Ref<boolean> | boolean,
  formatter: DateFormatter
) {
  const chartData = ref<Record<string, T>>();

  //TODO: tener en cuenta períodos y separar current de previous
  watchEffect(() => {
    chartData.value = {};

    if (!dashboardData || !dashboardData.value) return;

    //genero todas las categorias de antemano, tenga o no datos para cada dia.
    let d = dashboardData.value.period.prevFrom;
    const to = dashboardData.value.period.to;
    while (!isAfter(d, to)) {
      const category = unref(categoryResolver)(d, dashboardData.value.period, formatter, unref(compare));

      if (category) chartData.value[category] = newChartPoint();
      d = addDays(d, 1);
    }

    Object.entries(dashboardData.value.dataSet).forEach(([d, data]) => {
      const date = parseISO(d);
      const category = unref(categoryResolver)(date, dashboardData.value!.period, formatter, unref(compare));

      if (!category || !chartData.value) return;

      if (!chartData.value[category]) chartData.value[category] = newChartPoint();
      chartData.value[category] = unref(accumulator)(
        chartData.value[category],
        data,
        dashboardData.value!.period.type(date),
        date
      );
    });
  });

  return { chartData };
}

export const CategoryResolvers: Record<string, CategoryResolver> = {
  daily(date, period, format: DateFormatter) {
    if (period.isCurrent(date)) return format(date);
    else if (period.isPrevious(date)) return format(period.addPeriod(date));
  },

  weekly(date, period, format, compare?: boolean) {
    let category;
    if (period.isCurrent(date))
      category = format(startOfWeek(date, { weekStartsOn: 1 })) + " - " + format(endOfWeek(date, { weekStartsOn: 1 }));
    else if (period.isPrevious(date))
      category =
        format(startOfWeek(period.addPeriod(date), { weekStartsOn: 1 })) +
        " - " +
        format(endOfWeek(period.addPeriod(date), { weekStartsOn: 1 }));

    return category;
  },

  weekLastDay(date, period, format, compare?: boolean) {
    let category;
    if (period.isCurrent(date)) category = format(endOfWeek(date, { weekStartsOn: 1 }));
    else if (period.isPrevious(date)) category = format(endOfWeek(period.addPeriod(date), { weekStartsOn: 1 }));

    return category;
  },

  monthly(date, period, format: DateFormatter, compare?: boolean) {
    let category;
    if (period.isCurrent(date)) category = format(startOfMonth(date)) + " - " + format(endOfMonth(date));
    else if (period.isPrevious(date))
      category = format(startOfMonth(period.addPeriod(date))) + " - " + format(endOfMonth(period.addPeriod(date)));

    return category;
  },

  monthLastDay(date, period, format, compare?: boolean) {
    let category;
    if (period.isCurrent(date)) category = format(endOfMonth(date));
    else if (period.isPrevious(date)) category = format(endOfMonth(period.addPeriod(date)));

    return category;
  },
};

export const ChartPresets = {
  tooltip: (n: (val: number) => string) => ({
    shared: true,
    backgroundColor: "#333333",
    borderColor: "#333333",
    padding: 10,
    borderRadius: 9,
    shadow: {
      offsetX: 0,
      offsetY: 1,
      opacity: 0.1,
      width: 2,
    },
    style: {
      color: "#fafafa",
      fontSize: "0.75rem",
    },
    formatter: function (this: TooltipFormatterContextObject) {
      return this.points?.reduce(function (s, point) {
        return s + `<br/><span style="color:${point.color}">•</span> ${point.series.name}: ${n(point.y)}`;
      }, `<b>${this.x}</b>`);
    },
  }),
  plotOptions: {
    column: {
      borderWidth: 0,
    },
    series: {
      states: {
        hover: {
          brightness: 0.01,
          lineWidthPlus: 2,
        },
        inactive: {
          enabled: false,
        },
      },
      marker: { symbol: "circle" },
    },
  },
  colors: {
    // lightBlue: "rgba(176, 225, 239, 0.9)",
    lightBlue: "rgba(228, 245, 252, 0.9)",
    blue: "rgba(177, 225, 238, 0.9)",
    darkBlue: "rgba(90, 201, 238, 0.9)",
    lightGreen: "rgba(237, 244, 212, 0.9)",
    green: "rgba(222, 234, 180, 0.9)",
    emerald: "rgba(110, 231, 183, 0.9)",
    lightEmerald: "rgba(167, 243, 208, 0.9)",
    orange: "rgba(253, 186, 116, 0.9)",
    lightOrange: "rgba(254, 215, 170, 0.9)",
    purple: "rgba(216, 180, 254, 0.9)",
    rose: "rgba(253, 164, 175, 0.9)",
  },
  xAxis: {
    crosshair: { color: "rgba(243, 252, 255, 0.6)" },
  },
  legend: {
    itemStyle: {
      color: "#555555",
      cursor: "pointer",
      fontSize: "12px",
      fontWeight: "semibold",
      textOverflow: "ellipsis",
    },
    itemHoverStyle: { color: "#333333" },
  },
  tickIntervaler: (len: number) => (len < 20 ? 1 : Math.round(len / 10)),
};

export const sumIf = (condition: boolean, ...values: number[]): number => {
  if (condition) return values.reduce((acc, val) => acc + val);
  else return values[0]; //si condition es false, solo me quedo con el primer valor (no se acumula nada mas)
};

export const sumIfCurrent = (periodType: PeriodType, ...values: number[]) => sumIf(periodType === "current", ...values);

export const sumIfPrevious = (periodType: PeriodType, ...values: number[]) =>
  sumIf(periodType === "previous", ...values);
