var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":64,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='row no-content'>\n  <div class='content-options'>\n    <div class='row'>\n      <div class='col-md-4'>\n        <a class='eddie-content' data-action='open-eddie-content-modal'>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentEddie")), depth0))
    + "\n          <p class='text-muted'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"contentEddieLegend")), depth0))
    + "\n          </p>\n        </a>\n      </div>\n      <div class='col-md-4'>\n        <a class='template-content' data-action='create-from-template'>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"chooseExistingDesign")), depth0))
    + "\n          <p class='text-muted'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"chooseExistingDesignLegend")), depth0))
    + "\n          </p>\n        </a>\n      </div>\n      <div class='col-md-4'>\n        <a class='html-content' data-action='open-create-html-content-modal'>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"importHTML")), depth0))
    + "\n          <p class='text-muted'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"importHTMLLegend")), depth0))
    + "\n          </p>\n        </a>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='preview' data-role='preview'>\n  <div class='content-preview'>\n    <div class='head'>\n      <div class='actions'>\n        <a data-action='open-eddie-content-modal'>\n          <i class='fa fa-pencil'></i>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"editContent")), depth0))
    + "\n        </a>\n        <a data-action=\"open-social-header-modal\">\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"preheader")), depth0))
    + "\n        </a>\n      </div>\n      <div class='device-switch'>\n        <button type='button' class='desktop active' data-action='change-preview' data-device='desktop'>\n          <i class='fa fa-desktop'></i>\n        </button>\n        <button type='button' class='mobile' data-action='change-preview' data-device='mobile'>\n          <i class='fa fa-mobile'></i>\n        </button>\n      </div>\n    </div>\n    <div class='loading-box hidden'></div>\n    <div class='frame'>\n      <iframe id='content-preview' src='' frameborder='0'></iframe>\n    </div>\n    <div id='content-error' class=\"hint-box hint-warning\" style=\"display: none;\">\n      "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contentPreviewError")), depth0)) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"template"))) && lookupProperty(stack1,"attributes")),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":65,"column":9}}})) != null ? stack1 : "");
},"useData":true});