<template>
  <div v-if="!readonly" class="flex justify-between">
    <PermissionsButton
      theme="primary-light"
      :permissions="['campaigns:update']"
      :disabled="!templateWasFetched"
      @click="openEditor"
    >
      <template #leading><PencilAltIcon /> </template>
      {{ t("editTemplateButton") }}
    </PermissionsButton>
    <div class="flex space-x-4">
      <Button
        size="min"
        theme="white"
        class="mb-auto mt-0.5 rounded-md p-1.5"
        :model-value="xlBp ? t('testSendButton') : undefined"
        :show-tooltip="!xlBp"
        :tooltip="!xlBp ? t('testSendButton') : undefined"
        :class="xlBp ? 'px-3' : 'px-2'"
        :disabled="disableButtons"
        @click="openTestSend"
      >
        <template #leading> <MailIcon class="text-gray-500" /></template>
      </Button>
      <Button
        size="min"
        theme="white"
        class="mb-auto mt-0.5 rounded-md p-1.5"
        :model-value="xlBp ? t('getShareLinkButton') : undefined"
        :show-tooltip="!xlBp"
        :tooltip="!xlBp ? t('getShareLinkButton') : undefined"
        :loading="loadingShareLink"
        :class="xlBp ? 'px-3' : 'px-2'"
        :disabled="disableButtons"
        @click="onOpenlinkShareModal"
      >
        <template #leading> <LinkIcon class="text-gray-500" /></template>
      </Button>
      <Button
        size="min"
        theme="white"
        class="mb-auto mt-0.5 rounded-md p-1.5"
        :show-tooltip="true"
        :tooltip="t('previewAsContact')"
        :disabled="disableButtons"
        @click="openContactSelector"
      >
        <template #leading> <UserIcon class="text-gray-500" /></template>
      </Button>
      <ToggleButtons
        :model-value="previewFormat"
        :buttons="[
          {
            id: 'desktop',
            label: 'desktop',
            icon: DesktopComputerIcon,
            tooltip: t('tooltipPreviewFormat.desktop'),
            disabled: disableButtons,
          },
          {
            id: 'mobile',
            label: 'mobile',
            icon: DeviceMobileIcon,
            tooltip: t('tooltipPreviewFormat.mobile'),
            disabled: disableButtons,
          },
        ]"
        @update:model-value="onTogglePreviewFormat"
      />
      <!-- <SwitchButton class="my-auto" /> -->
    </div>
  </div>
  <div v-if="!templateWasFetched" class="flex-1 space-y-4 pt-4">
    <div class="h-3 w-1/2 animate-pulse rounded-lg bg-gray-100"></div>
    <div class="h-3 w-1/3 animate-pulse rounded-lg bg-gray-100"></div>
    <div class="flex h-screen w-full items-center justify-center pt-3">
      <div class="h-full w-[700px] animate-pulse rounded-2xl bg-gray-50"></div>
    </div>
  </div>
  <TemplateContentPreview
    v-if="templateWasFetched"
    ref="vueTemplateContentPreview"
    :contact="contactSelected"
    :template-preview-id="templatePreviewId"
    :tpl-id="tplId"
    :relation="relation"
    :template-preview="templatePreview"
    :template="template"
    :subject="subject"
    :subjects="subjects"
    :preheader="preheader"
    :sender="sender"
    :preview-format="previewFormat"
    :metrics="metrics"
    :show="{
      showSubject: true,
      showPreHeader: true,
      showSender: true,
      showShare: readonly,
      showFormatToggle: readonly,
      showMonitoringOptions: true,
      showAudit: true,
      showheatMapToggle: metrics && metrics?.length > 0,
    }"
    @update:preview-format="onTogglePreviewFormat"
    @update:contact="SelectContact"
  />
  <ModalTemplateEditor
    v-if="editorIsOpen"
    v-model:contact="contactSelected"
    :tpl-id="tplId"
    :relation="relation"
    :audit-mode="auditMode"
    :not-allow-errors="notAllowErrors"
    @close="closeEditor"
    @saved="onSavedTemplate"
    @update:contact="SelectContact"
  />
  <TemplateTestSendModal
    v-if="testSendIsOpen"
    v-model:is-open="testSendIsOpen"
    :sender-id="sender?.id"
    :email="contactSelected?.email"
    :contact-id="contactSelected?.id"
    :subjects="template?.options.subjects"
    :utm-campaign-name="template?.tracking.utm.campaign"
    :relation="relation"
    :tpl-id="tplId"
  />
  <ContactSelector
    v-if="contactSelectorIsOpen"
    :open="contactSelectorIsOpen"
    @contact-selected="SelectContact"
    @close="closeContactSelector"
  />
  <TemplatePreviewShareLinkModal
    :open="linkShareIsOpen"
    :email="contactSelected?.email"
    :url="URL_SHARE_LINK + hashShareLink"
    @close="closeSendTestModal"
  />
</template>
<script lang="ts" setup>
import { onMounted, ref, computed } from "vue";

//Components
import Button from "@atoms/SimpleButton.vue";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import ModalTemplateEditor from "@organisms/Templates/TemplateEditor/ModalTemplateEditor.vue";
import TemplateContentPreview from "./TemplateContentPreview.vue";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import TemplateTestSendModal from "@organisms/Templates/TemplateTestSend/TemplateTestSendModal.vue";
import ContactSelector from "@organisms/Contacts/ContactSelector/ContactSelector.vue";
import TemplatePreviewShareLinkModal from "@molecules/TemplatePreviewShareLinkModal.vue";

//Icons
import {
  MailIcon,
  LinkIcon,
  PencilAltIcon,
  DeviceMobileIcon,
  DesktopComputerIcon,
  UserIcon,
} from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

//API
import { useEmailTemplate } from "@/vue/api/modules/templates/templates";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

//Utils
import { useBreakpoints } from "@composables/breakpoints";
// import { useNotifications } from "@composables/notifications";

//Types
import { TemplatePreview, Template, TemplatePreviewTestAB } from "@/vue/types/Templates";

import { Sender } from "@/vue/types/Senders";
import { previewWidthFormats } from "./TemplateContentPreview.vue";
import { Relation } from "../TemplateEditor/TemplateEditor.vue";
import type { AuditModeType } from "@domain/Templates";
import type { SavedEmit } from "@/vue/components/organisms/Templates/TemplateEditor/TemplateEditor.vue";
import { ContactType as Contact } from "@organisms/Contacts/ContactSelector/ContactSelector.vue";

export type TemplateUpdatedEmit = SavedEmit;

//Props
const props = withDefaults(
  defineProps<{
    tplId: string;
    relation?: Relation | undefined;
    auditMode: AuditModeType;
    readonly?: boolean;
    notAllowErrors?: boolean;
    metrics?: Array<{
      clicks: number;
      url: string;
      linkId: string;
    }>;
  }>(),
  {
    relation: undefined,
    auditMode: undefined,
    readonly: false,
    notAllowErrors: false,
    metrics: undefined,
  },
);

//Emits
const emit = defineEmits<{
  (e: "templateUpdated", templateUpdate: TemplateUpdatedEmit): void;
}>();

const { t } = useI18n();
// const { notify } = useNotifications();

//Const
const TemplatesAPI = useEmailTemplate();
const trackingEventsService = useTrackingEvents();
const templatePreviewId = "vue-template-preview-html";
const { xlBp } = useBreakpoints();

//Flags
const templateError = ref(false);
const setTemplateError = () => (templateError.value = true);
const clearTemplateError = () => (templateError.value = false);

const templateWasFetched = ref(false);

const editorIsOpen = ref(false);
const openEditor = () => (editorIsOpen.value = true);
const closeEditor = () => (editorIsOpen.value = false);

const testSendIsOpen = ref(false);
const openTestSend = () => (testSendIsOpen.value = true);

const linkShareIsOpen = ref(false);
const loadingShareLink = ref(false);
const closeSendTestModal = () => (linkShareIsOpen.value = false);

const URL_SHARE_LINK = "https://sm.pemres.net/preview/link/";
const hashShareLink = ref("");

const contactSelectorIsOpen = ref(false);
const openContactSelector = () => (contactSelectorIsOpen.value = true);
const closeContactSelector = () => (contactSelectorIsOpen.value = false);

const contactSelected = ref<Contact>();
const SelectContact = async (selected: Contact | undefined) => {
  selected && trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_PREVIEW_AS" });
  contactSelected.value = selected;
  await initWithTemplateID(props.tplId);
};

//State
const template = ref<Template>();

const subject = ref<string>();
const subjects = ref<Array<string>>();
const preheader = ref<string>();
const sender = ref<Sender>();
const templatePreview = ref<TemplatePreview | TemplatePreviewTestAB>();
const previewFormat = ref<previewWidthFormats>("desktop");

//Refs
const vueTemplateContentPreview = ref();

//Computed
const emptyTemplate = computed(() => {
  if (!template.value) return true;
  if (template.value.type === "html") return template.value?.contents?.html === "";
  return template.value?.contents?.json === "";
});
const disableButtons = computed(() => !templateWasFetched.value || templateError.value || emptyTemplate.value);

//Methods
const getShareLink = async () => {
  try {
    loadingShareLink.value = true;
    const utmCampaign = template.value?.tracking.utm.enabled ? template.value?.tracking.utm.campaign : undefined;

    const res = await TemplatesAPI.getShareLink({
      tpl_id: props.tplId,
      use_autosave: false,
      contact_id: contactSelected.value?.id.toString(),
      utm_campaign: utmCampaign,
    });
    return res;
  } finally {
    loadingShareLink.value = false;
  }
};

const getShareLinkTestAB = async () => {
  try {
    loadingShareLink.value = true;
    const utmCampaign = template.value?.tracking.utm.enabled ? template.value?.tracking.utm.campaign : undefined;

    const res = await TemplatesAPI.getShareLinkTestAB({
      tpl_id: props.tplId,
      use_autosave: false,
      subjects: subjects.value || [],
      contact_id: contactSelected.value?.id.toString(),
      utm_campaign: utmCampaign,
    });
    return res;
  } finally {
    loadingShareLink.value = false;
  }
};

const onOpenlinkShareModal = async () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_SHARELINK" });
  let resLink;
  if (props.relation?.relationType == "campaign_testab") {
    resLink = await getShareLinkTestAB();
  } else {
    resLink = await getShareLink();
  }

  hashShareLink.value = resLink.hash;
  linkShareIsOpen.value = true;
};

const useTemplate = async (id: string): Promise<Template | undefined> => {
  try {
    const tpl = await TemplatesAPI.getTemplate({ tpl_id: id });

    return tpl;
  } catch (e) {
    setTemplateError();
    return undefined;
  }
};

const useTemplatePreview = async (id: string): Promise<TemplatePreview | undefined> => {
  try {
    const utmCampaign = template.value?.tracking.utm.enabled ? template.value?.tracking.utm.campaign : undefined;

    const tpl = await TemplatesAPI.getTemplatePreview({
      tpl_id: id,
      use_autosave: false,
      contact_id: contactSelected.value?.id.toString(),
      utm_campaign: utmCampaign,
    });

    return tpl;
  } catch (e) {
    setTemplateError();
    return undefined;
  }
};

const useTemplateTestAB = async (id: string): Promise<TemplatePreviewTestAB | undefined> => {
  try {
    const utmCampaign = template.value?.tracking.utm.enabled ? template.value?.tracking.utm.campaign : undefined;

    const tpl = await TemplatesAPI.getTemplatePreviewTestAB({
      tpl_id: id,
      subjects: [],
      contact_id: contactSelected.value?.id.toString(),
      utm_campaign: utmCampaign,
      use_autosave: false,
    });

    return tpl;
  } catch (e) {
    setTemplateError();
    return undefined;
  }
};

const updateTemplate = async () => {
  await initWithTemplateID(props.tplId);
};

const onSavedTemplate = async (savedObject: TemplateUpdatedEmit) => {
  emit("templateUpdated", savedObject);
  await updateTemplate();
};

const refreshHeight = () => vueTemplateContentPreview.value?.refreshHeight?.();

const onTogglePreviewFormat = (value: previewWidthFormats) => {
  previewFormat.value = value;
  refreshHeight();
};

const initWithTemplateID = async (tplId: string) => {
  templateWasFetched.value = false;
  clearTemplateError();
  try {
    template.value = await useTemplate(tplId);
    if (props.relation?.relationType === "campaign_testab") {
      templatePreview.value = await useTemplateTestAB(tplId);
      subjects.value = (templatePreview.value as TemplatePreviewTestAB)?.subjects;
    } else {
      templatePreview.value = await useTemplatePreview(tplId);
    }
    subject.value = templatePreview.value?.subject;
    preheader.value = templatePreview.value?.preheader;
    sender.value = templatePreview.value?.sender ? new Sender(templatePreview.value?.sender) : undefined;
  } finally {
    templateWasFetched.value = true;
  }
};

defineExpose({ refreshHeight });

//LifeCycle
onMounted(async () => {
  await updateTemplate();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "editTemplateButton": "Editar contenido",
    "testSendButton": "Enviar prueba",
    "getShareLinkButton": "Compartir enlace",
    "previewAsContact": "Ver como un contacto",
    "tooltipPreviewFormat": {
      "desktop": "escritorio",
      "mobile": "móvil"
    },
    "getSenders": {
      "errorTitle": "Error al cargar los remitentes",
      "errorMessage": "Hubo un problema al obtener los remitentes"
    }
  },
  "pt": {
    "editTemplateButton": "Editar conteúdo",
    "testSendButton": "Enviar teste",
    "getShareLinkButton": "Obter link",
    "previewAsContact": "Ver como um contato",
    "tooltipPreviewFormat": {
      "desktop": "computador",
      "mobile": "móvel"
    },
    "getSenders": {
      "errorTitle": "Falha ao carregar remetentes",
      "errorMessage": "Ocorreu um problema ao obter remetentes"
    }
  }
}
</i18n>
