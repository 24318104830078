var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class='clear-search' data-action='remove-filter' data-params='[\"q\"]'>\n              <i class='fa fa-close'></i>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-action='order' data-params='{\"column\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"column") : depth0), depth0))
    + "\", \"type\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"}'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang")),(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":78,"column":34},"end":{"line":78,"column":64}}}))
    + "\n          </a>\n        </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "class='active' ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='campaigns section'>\n  <div class='section-header'>\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":48}}}))
    + "\n    </h1>\n    <div class='filters'>\n      <ul class='nav nav-pills' data-role='filters'>\n        <li class='search'>\n          <form data-role='search' data-form='search'>\n            <button class='btn btn-default' type='button' data-action='open-search-form'>\n              <i class='filter-icon pi-filter-search'></i>\n            </button>\n            <input type='text' placeholder='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' class='form-control' name='q'\n              value='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "          </form>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class='toolbar' data-role='toolbar'>\n\n    <button class='btn btn-primary' data-action='open-create-modal' data-require-permission='campaigns:create'>\n      <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"create")), depth0))
    + "\n    </button>\n\n\n    <button type='button' class='btn btn-danger hide' data-action='mass' data-method='stop'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massStop")), depth0))
    + "'\n      data-confirm=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"confirmStopCampaign")), depth0))
    + "\" data-confirm-title=\""
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\"\n      data-require-permission='campaigns:launch'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"stop")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default hide' data-action='open-duplicate-modal' data-type='just-one'\n      data-require-permission='campaigns:create'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default hide' data-action='mass' data-method='archive'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massArchive")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmArchive")), depth0))
    + "'\n      data-confirm-title='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"archive")), depth0))
    + "' data-require-permission='campaigns:update'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"archive")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default hide' data-action='mass' data-method='unarchive'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massUnarchive")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmUnarchive")), depth0))
    + "'\n      data-confirm-title='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "' data-require-permission='campaigns:update'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\n    </button>\n    <button class='btn btn-danger hide' data-action='mass' data-method='destroy'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"massDestroy")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n      data-require-permission='campaigns:delete'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default' data-type='none' data-action='export-campaigns'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"export")), depth0))
    + "\n    </button>\n    <div class='paging-selected' data-role='check-count'></div>\n  </div>\n  <div class='list-controls'>\n    <div class='check-all'>\n      <input id='check-all' type='checkbox' data-action='check-all'>\n      <label for='check-all' class='iconic'></label>\n    </div>\n    <div class='sort dropdown'>\n      <button class='btn btn-default dropdown-toggle' type='button' data-toggle='dropdown' data-role='order-button'>\n        <span>\n          "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + "\n        </span>\n        <i class='drop fa fa-angle-down'></i>\n      </button>\n      <ul class='dropdown-menu'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sortOptions")),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":81,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n    <div class='display-toggler'>\n      <a "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"displayMode") : stack1),"grid",{"name":"is","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":9},"end":{"line":85,"column":64}}})) != null ? stack1 : "")
    + " data-action='toggle-display' data-display='grid'>\n        <i class='fa fa-th-large'></i>\n      </a>\n      <a "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"extra") : depth0)) != null ? lookupProperty(stack1,"displayMode") : stack1),"list",{"name":"is","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":9},"end":{"line":88,"column":64}}})) != null ? stack1 : "")
    + " data-action='toggle-display' data-display='list'>\n        <i class='fa fa-list'></i>\n      </a>\n    </div>\n    <div class='paging-total' data-role='paging-total'></div>\n  </div>\n  <div class='content' data-role='content'>\n    <div class='list'>\n      <ul class='grid' data-role='rows'></ul>\n      <div class='loading-box' data-role='items-loader'></div>\n      <button class='btn btn-primary load-more' data-action='load-more'>\n        "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n      </button>\n    </div>\n  </div>\n  <div id=\"vue-campaign-selector\"></div>\n</div>";
},"useData":true});