<template>
  <transition
    leave-active-class="transition duration-100 ease-in-out"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 transform translate-x-5"
    enter-active-class="transition duration-100 ease-in-out"
    enter-to-class="opacity-100"
    enter-from-class="opacity-0 transform translate-x-5"
  >
    <div v-if="show" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
    </div>
  </transition>
</template>

<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  name: "InputErrorIcon",
  components: { ExclamationCircleIcon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
