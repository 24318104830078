<template>
  <component
    :is="linkCard ? 'a' : 'div'"
    :href="!blockLink ? `/gallery/${isPublic ? 'public' : 'private'}/${template.id}` : 'javascript:void(0)'"
    class="group relative max-h-[600px] overflow-hidden rounded-md border-2 border-gray-300 bg-gray-100 px-10 hover:cursor-pointer hover:shadow-md"
    :class="{ 'border-sky-500': selected, 'hover:border-sky-400': !selected }"
    @click="onClick"
  >
    <CheckBox
      v-if="selectable"
      :model-value="selected"
      :class="[
        {
          'invisible group-hover:visible': !showCheckBox,
        },
        'absolute left-3 top-3',
      ]"
      @click.stop
      @update:model-value="toggleSelected"
    />
    <img
      v-show="showImage"
      :src="template.thumb"
      class="mx-auto min-h-[200px] object-contain"
      @load="ImageLoaded"
      @error="ImageError"
    />
    <div
      v-show="!showImage && !noImage"
      :class="[index % 2 === 0 ? 'h-[400px]' : 'h-[300px]', 'mx-auto flex w-6 flex-col justify-center text-gray-400']"
    >
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>
    <div
      v-show="noImage"
      :class="[index % 2 === 0 ? 'h-[400px]' : 'h-[300px]', 'mx-auto flex w-6 flex-col justify-center text-gray-400']"
    >
      <PhotographIcon class="h-6 w-6" />
    </div>
    <template v-if="template.type === 'html' || (isPublic && (isPremium || isNewTemplate(template)))">
      <div class="absolute bottom-12 z-10 h-6 w-full bg-gradient-to-t from-white" />
      <div class="absolute bottom-0 z-10 h-12 w-full bg-white" />
      <div class="absolute bottom-3 left-3 z-20">
        <SimpleBadge v-if="isPublic && isPremium" model-value="premium" :icon="StarIcon" theme="sky" class="mx-2" />
        <SimpleBadge v-if="isPublic && isNewTemplate(template)" :model-value="t('newTemplate')" dot theme="green" />
        <SimpleBadge v-if="template.type === 'html'" square theme="gray">
          <span>{{ t("HTMLTemplate") }}</span>
        </SimpleBadge>
      </div>
    </template>
  </component>
</template>

<script lang="ts" setup>
import { ref } from "vue";

//Components
import SimpleBadge from "@atoms/SimpleBadge";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import CheckBox from "@/vue/components/atoms/CheckBox.vue";

//Icons
import { StarIcon } from "@heroicons/vue/solid";
import { PhotographIcon } from "@heroicons/vue/outline";

//Utils
import { differenceInDays } from "date-fns";
import { useI18n } from "vue-i18n";

//Types
import type { TemplateItem } from "@/vue/types/Templates";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    template: TemplateItem;
    isPublic: boolean;
    index?: number;
    selected?: boolean;
    selectable?: boolean;
    showCheckBox?: boolean;
    linkCard?: boolean;
    blockLink?: boolean;
  }>(),
  {
    isPublic: false,
    index: 0,
    selected: false,
    selectable: false,
    showCheckBox: false,
    linkCard: false,
    blockLink: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "select", selectedTemplate: TemplateItem): void;
  (e: "toggleSelected", selected: boolean): void;
  (e: "loaded"): void;
}>();

const selectTemplate = (selectedTemplate: TemplateItem) => emit("select", selectedTemplate);
const toggleSelected = (value: boolean) => {
  emit("toggleSelected", value);
};

const onClick = (ev: PointerEvent) => {
  if (ev.ctrlKey || ev.metaKey) {
    ev.stopPropagation();
    return;
  }
  selectTemplate(props.template);
};

//Premium
const premiumTagID = "premium";
const isPremium = props.template.tags.find((tag) => tag.id === premiumTagID);

//Image State
const showImage = ref(false);
const noImage = ref(false);
const ImageLoaded = () => {
  showImage.value = true;
  emit("loaded");
};

const ImageError = () => {
  noImage.value = true;
  emit("loaded");
};

//Template Date
const isNewTemplate = (template: TemplateItem) => {
  if (!template.created) return;

  return differenceInDays(new Date(), new Date(template.created)) <= 30;
};
</script>
<i18n lang="jsonc">
{
  "es": {
    "newTemplate": "Nuevo",
    "HTMLTemplate": "HTML"
  },
  "pt": {
    "newTemplate": "Novo",
    "HTMLTemplate": "HTML"
  }
}
</i18n>
