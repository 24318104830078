<template>
  <svg width="14" height="14" viewBox="0 0 18 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.17157 1.17157C2.73367 -0.390524 5.26633 -0.390524 6.82842 1.17157L7.99999 2.34315L9.17157 1.17157C10.7337 -0.390524 13.2663 -0.390524 14.8284 1.17157C16.3905 2.73367 16.3905 5.26633 14.8284 6.82843L7.99999 13.6569L1.17157 6.82843C-0.390524 5.26633 -0.390524 2.73367 1.17157 1.17157Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289C13.0976 3.68342 13.0976 4.31658 12.7071 4.70711L8.70711 8.70711C8.31658 9.09763 7.68342 9.09763 7.29289 8.70711L5 6.41421L2.87868 8.53553C2.5 8.15686 2.5621 8.21895 2.17157 7.82843C1.78105 7.4379 1.84314 7.5 1.46447 7.12132L4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L8 6.58579L11.2929 3.29289Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
