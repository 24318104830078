import { ref, toRaw } from "vue";
import { defineStore } from "pinia";

// Services
import { useFields } from "@api/modules/fields/fields";
import { useInterests } from "@api/modules/interests/interests";
import { useContacts } from "@api/modules/contacts/contacts";

// Types
import type { Fields } from "@domain/fields";
import type { Interests } from "@domain/interests";

export const useContactsStore = defineStore("contactsStore", () => {
  // Contacts
  const contactsCount = ref<number>(0);
  const contactsService = useContacts();
  const fetchContactsLength = async (): Promise<number> => {
    const contactsRes = await contactsService.get({ limit: 1 });
    contactsCount.value = contactsRes.total ?? 0;

    return contactsCount.value;
  };

  // Interests
  const interests = ref<Interests>([]);
  const getInterests = (): Interests => {
    return toRaw(interests.value);
  };

  const fetchInterests = async () => {
    const interestsAPI = useInterests();
    const interestsRes = await interestsAPI.list({ limit: 500 });
    interests.value = interestsRes.value;
  };

  // Fields
  const fields = ref<Fields>([]);
  const getFields = (): Fields => {
    return toRaw(fields.value);
  };

  const fetchFields = async () => {
    const fieldsAPI = useFields();
    const data = await fieldsAPI.getFields();
    fields.value = data.value;
  };

  const fetchAll = async () => {
    const fieldsAPI = useFields();
    const interestsAPI = useInterests();
    const getFieldsPromise = fieldsAPI.getFields();

    const getInterestsPromise = interestsAPI.list({ limit: 500 });

    const [fieldsRes, interestsRes] = await Promise.all([getFieldsPromise, getInterestsPromise]);

    fields.value = fieldsRes.value;

    interests.value = interestsRes.value;
  };

  return { contactsCount, fetchContactsLength, getFields, fetchFields, getInterests, fetchInterests, fetchAll };
});
