var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='list-controls'>\n    <div class='check-all'>\n      <input id='check-all' type='checkbox' data-action='check-all'>\n      <label for='check-all' class='iconic'></label>\n    </div>\n    <div class='sort dropdown'>\n      <button class='btn btn-default dropdown-toggle' type='button' data-toggle='dropdown' data-role='order-button'>\n        <span>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + "</span>\n        <i class='drop fa fa-angle-down'></i>\n      </button>\n      <ul class='dropdown-menu'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sortOptions")),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n    <div class='paging-total'>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"paging") : depth0)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":48,"column":36}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"plural")), depth0))
    + "\n    </div>\n  </div>\n  <div class='content list scrollbar'>\n    <ul class='table'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":6},"end":{"line":131,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-action='order' data-params='{\"column\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"column") : depth0), depth0))
    + "\", \"type\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"}'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"sortOptions")),(depth0 != null ? lookupProperty(depth0,"label") : depth0),true,{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":34},"end":{"line":41,"column":90}}}))
    + "\n          </a>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":6},"end":{"line":130,"column":13}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n        <div class='check-cell'>\n          <input id='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='checkbox' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-action='check' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":86},"end":{"line":58,"column":58}}})) != null ? stack1 : "")
    + ">\n          <label for='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='iconic'>\n            <span class='mini-avatar'\n              style='background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"config"))) && lookupProperty(stack1,"api")), depth0))
    + "accountlogo/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "?time="
    + alias2(__default(require("../../../helpers/handlebars/now.js")).call(alias3,{"name":"now","hash":{},"data":data,"loc":{"start":{"line":61,"column":100},"end":{"line":61,"column":107}}}))
    + ")'></span>\n          </label>\n        </div>\n        <div class='main-cell'>\n          <div class='name'>\n            <a href='/accounts/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </a>\n            <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"canChangeAccount")),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":70,"column":14},"end":{"line":75,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"disabled") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":14},"end":{"line":78,"column":21}}})) != null ? stack1 : "")
    + "            </p>\n          </div>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"contacts") : stack1)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":84,"column":12},"end":{"line":84,"column":61}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"contacts")), depth0))
    + "\n          </small>\n        </div>\n        <div class='info-cell'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"CHILD_LIMITED",{"name":"is","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":10},"end":{"line":99,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"CHILD_UNCAPPED",{"name":"is","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":10},"end":{"line":107,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='info-cell'>\n          <strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"lastUsage") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":115,"column":19}}})) != null ? stack1 : "")
    + "          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"lastUsage")), depth0))
    + "\n          </small>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"created") : stack1),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"timeFormat")),{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":123,"column":12},"end":{"line":123,"column":72}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"createdAt")), depth0))
    + "\n          </small>\n        </div>\n      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "data-item-disabled";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class='change-account-link' data-action='goto-account' data-code='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'> "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + " <i\n                  class=\"fa fa-sign-in\" aria-hidden=\"true\"></i> </span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              ("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"disabled")), depth0))
    + ")\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"emails") : stack1)) != null ? lookupProperty(stack1,"available") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":93,"column":12},"end":{"line":93,"column":62}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"of")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"emails") : stack1)) != null ? lookupProperty(stack1,"limit") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":93,"column":81},"end":{"line":94,"column":42}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"availableEmails")), depth0))
    + "\n          </small>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <strong>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"unlimited")), depth0))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"availableEmails")), depth0))
    + "\n          </small>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/timeago.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"lastUsage") : stack1),{"name":"timeago","hash":{},"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":112,"column":44}}}))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"lastUsageNever")), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='content'>\n    <div class='padder'>\n      <div class='empty-state'>\n        <div class='row'>\n          <div class='col-md-5'>\n            <img class='illustration'\n              src='/img/empty-states/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":141,"column":37},"end":{"line":141,"column":97}}})) != null ? stack1 : "")
    + "'>\n          </div>\n          <div class='col-md-7'>\n            <div class='info'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":145,"column":14},"end":{"line":156,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "search.svg";
},"25":function(container,depth0,helpers,partials,data) {
    return "accounts.svg";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsTitle")), depth0))
    + "</h2>\n              <p>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsLegend")), depth0))
    + "</p>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"noResultsTitle")), depth0))
    + "</h2>\n              <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"noResultsSubtitle")), depth0))
    + "</h3>\n              <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"noResultsLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n              <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"noResultsMore")), depth0))
    + "</h3>\n              <div class='links-list'>\n                "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"noResultsLinks")), depth0)) != null ? stack1 : "")
    + "\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='accounts section'>\n  <div class='section-header'>\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":47}}}))
    + "\n    </h1>\n  </div>\n  <div class='toolbar' data-role='toolbar'>\n    <a data-navigate='accounts/new' class='btn btn-primary' data-type='none' data-require-permission='accounts:create'>\n      <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"create")), depth0))
    + "\n    </a>\n    <button type='button' class='btn btn-default hide' data-action='mass' data-method='disable'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"massDisable")), depth0))
    + "' data-type='one-plus'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"disable")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default hide' data-action='mass' data-method='enable'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"massEnable")), depth0))
    + "'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n    </button>\n    <button class='btn btn-danger hide' data-action='mass' data-method='destroy'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyF")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "' data-type='one-plus'\n      >\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":26,"column":2},"end":{"line":163,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});