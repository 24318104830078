var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='messenger' data-messenger='main'>\n  <span></span>\n</div>\n<!-- Edited -->\n<div class='login-panel'>\n  <form method='POST' data-form='signup' autocomplete='off'>\n    <div class='login-panel-header'>\n      <h1 style=\"margin-bottom: 3rem;\">\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"startNow")), depth0))
    + "\n      </h1>\n      <div class='logo'></div>\n    </div>\n    <div class='login-panel-body'>\n      <fieldset>\n        <div class='form-group'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"name")), depth0))
    + "\n          </label>\n          <input type='text' class='form-control input-lg user-name-input' name='name' data-validate='required'\n            placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"namePlaceholder")), depth0))
    + "\">\n        </div>\n        <div class='form-group'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"email")), depth0))
    + "\n          </label>\n          <input type='text' class='form-control input-lg user-input' name='email' data-validate='required'\n            value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"emailPlaceholder")), depth0))
    + "\">\n        </div>\n        <div class='form-group'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"password")), depth0))
    + "\n          </label>\n          <div class='input-group'>\n            <input type='password' class='form-control input-lg password-input' name='password'\n              autocomplete='new-password' data-validate='required' placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"passPlaceholder")), depth0))
    + "\">\n            <span class='input-group-addon' data-action='reveal-password'>\n              <i class='fa fa-eye'></i>\n              <i class='fa fa-eye-slash'></i>\n            </span>\n          </div>\n        </div>\n        <div class='form-group form-group-last'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"account")), depth0))
    + "\n          </label>\n          <input type='text' class='form-control input-lg account-name-input' name='code' data-validate='required'\n            value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"code") : stack1), depth0))
    + "\" placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"accountPlaceholder")), depth0))
    + "\">\n          <p class='input-legend'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"accountLegend")), depth0))
    + "\n          </p>\n        </div>\n\n        <div class='graphic checkbox user-agreement' data-action=\"accept-tos\">\n          <input id='accept-tos' type='checkbox' name='tos' data-action='accept-tos'>\n          <label for='accept-tos'>\n            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"terms")), depth0)) != null ? stack1 : "")
    + "\n          </label>\n        </div>\n\n        <div class='graphic checkbox user-agreement' data-action=\"accept-tos-eu\">\n          <input id='accept-tos-eu' type='checkbox' name='tos-eu' data-action='accept-tos-eu'>\n          <label for='accept-tos-eu'>\n            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"notInEu")), depth0)) != null ? stack1 : "")
    + "\n          </label>\n        </div>        \n\n      </fieldset>\n      <div class='signup-error tos-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"tosError")), depth0))
    + "\n        </p>\n      </div>\n      <div class='signup-error ip-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"serverError")), depth0))
    + "\n        </p>\n      </div>\n      <div class='signup-error server-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"serverError")), depth0))
    + "\n        </p>\n      </div>\n      <div id=\"recaptcha\"></div>\n    </div>\n    <div class='login-panel-footer'>\n      <button class='btn btn-primary' type='submit' disabled>\n        <i class='fa fa-arrow-right'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"continue")), depth0))
    + "\n      </button>\n      <a href='/' class='btn btn-primary btn-outline'>\n        <i class='fa fa-arrow-left'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"back")), depth0))
    + "\n      </a>\n    </div>\n  </form>\n</div>\n<div class='login-help'>\n  "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"help")), depth0)) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});