<template>
  <div class="group relative">
    <div class="relative font-medium">
      <button
        :class="[
          { 'bg-red-100 text-red-500': hasErrorAudit, 'bg-gray-100 text-gray-400': !hasErrorAudit },
          'cursor-default rounded-md px-2.5  py-2 focus:outline-none  group-hover:bg-sky-100 group-hover:text-sky-400 sm:text-sm',
        ]"
      >
        <InformationCircleIcon class="h-5 w-5" />
      </button>
      <div
        v-if="!hasErrorAudit && hasWarningAudit"
        class="absolute -right-1 -top-1 h-3 w-3 rounded-full border-2 border-white bg-yellow-400"
      />
    </div>
    <div
      class="border-1 bg-white-500 invisible absolute right-0 z-10 mt-0 w-max rounded-lg border-gray-50 bg-white p-6 text-sm text-gray-500 opacity-0 shadow-md transition-opacity duration-100 group-hover:visible group-hover:opacity-100"
    >
      <span class="mb-2 block font-medium text-gray-900">{{ t("auditoryTitle") }}</span>
      <TemplateAuditoryList :auditory="auditory" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Components
import TemplateAuditoryList from "./TemplateAuditoryList.vue";

//Icons
import { InformationCircleIcon } from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

// Type
import { AuditResults, AuditErrors } from "@domain/Templates";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    auditory?: AuditResults;
  }>(),
  {
    auditory: undefined,
  }
);

const errors = computed<AuditErrors>(() => {
  if (!props.auditory || !props.auditory.errors) return [];

  const filteredErrors = props.auditory?.errors.reduce<AuditErrors>((errors, error) => {
    const duplicatedErrorMsg = errors.some((errorItem) => errorItem.title === error.title);
    if (duplicatedErrorMsg) return errors;

    errors.push(error);
    return errors;
  }, []);
  return filteredErrors.sort((a, b) => {
    if (a.severity === "ERROR" && b.severity === "WARNING") return -1;
    if (a.severity === "WARNING" && b.severity === "ERROR") return 1;
    return 0;
  });
});

const hasErrorAudit = computed(() => errors.value.some((error) => error.severity === "ERROR"));
const hasWarningAudit = computed(() => errors.value.some((error) => error.severity === "WARNING"));
</script>

<i18n lang="jsonc">
{
  "es": {
    "auditoryTitle": "Auditoría"
  },
  "pt": {
    "auditoryTitle": "Auditoria"
  }
}
</i18n>
