<template>
  <button
    type="button"
    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-400"
    :class="{
      'shadow-sm text-white bg-sky-500 hover:bg-sky-700 focus:ring-blue':
        type === 'primary' && !disabled,
      'text-white bg-gray-300': type === 'primary' && disabled,
      'text-blue bg-white hover:text-blue-dark focus:ring-blue': type === 'secondary',
      'shadow-sm text-white bg-red-500 hover:bg-red-700 focus:ring-red-500': type === 'danger',
    }"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
      validator: function (value) {
        return ["primary", "secondary", "danger"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
