<template>
  <TemplateEditorModal
    v-if="editorIsOpen"
    :tpl-id="selectedTemplateId"
    :contact="selectedContact"
    @update:contact="selectContact"
    @saved="onSaveTemplate"
    @close="closeEditor"
  />
  <template v-else>
    <TemplateGallery
      v-show="!selectedTemplateId"
      :key="galleryKey"
      :initial-tab="isPublic ? 'templateGallery' : 'myTemplates'"
      :selected-category="selectedCategoryId"
      class="flex h-full flex-col"
      @createNewTemplate="openEditor"
      @select="selectTemplate"
      @tab-changed="tabChanged"
      @category-change="categoryChanged"
    />
    <!-- @editTags="editTags" -->
    <TemplatePreview
      v-if="selectedTemplateId"
      :key="selectedTemplateId"
      :template-id="selectedTemplateId"
      :is-public="isPublic"
      class="flex h-full flex-col"
      @select="onEditTemplate"
      @createCampaign="createNewCampaign"
      @template-deleted="onTemplateDeleted"
      @template-duplicated="onTemplateDuplicated"
      @tags-changed="onTagsChanged"
      @close="closePreview"
    />
  </template>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect, provide } from "vue";

//Components
import TemplateGallery from "./TemplateGallery.vue";
import TemplatePreview from "./TemplatePreview.vue";
import TemplateEditorModal from "@organisms/Templates/TemplateEditor/ModalTemplateEditor.vue";

// Application
import { useLocalStorageApp } from "@application";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useEmailTemplate } from "@api/modules/templates/templates";

//Types
import type { Template, TemplateItem } from "@/vue/types/Templates";
import { ContactType as Contact } from "@organisms/Contacts/ContactSelector/ContactSelector.vue";
type TabMode = "grid" | "list";

const trackingEventsService = useTrackingEvents();
const templatesAPI = useEmailTemplate();
const localStorageApp = useLocalStorageApp();

//Props
const props = defineProps<{
  public?: boolean;
  id?: string;
  category?: string;
}>();

// Emits
const emit = defineEmits<{
  (e: "routeChanged", value: string): void;
}>();

const tabSelectedSaved = localStorageApp.get<TabMode>({ id: "private_gallery_tab" });

const tabMode = ref<TabMode>(tabSelectedSaved ?? "grid");
provide("ownCatalogTabMode", tabMode);

//Selected template
const selectedTemplateId = ref<string | undefined>(props.id ?? undefined);
const isPublic = ref<boolean>(props.public ?? true);
const selectedCategoryId = ref<string | undefined>(props.category ?? undefined);

watchEffect(() => {
  selectedCategoryId.value = props.category ?? undefined;
});

const selectedContact = ref<Contact>();
const selectContact = async (selected: Contact | undefined) => {
  selected && trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_PREVIEW_AS" });
  selectedContact.value = selected;
};

// Force update to refetch Templates
const galleryKey = ref(0);
const refreshGallery = () => {
  galleryKey.value++;
};

const onTagsChanged = () => {
  needRefresh.value = true;
};

const onTemplateDuplicated = ({ name, id }) => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLGALLERY_PRIVATE_DUPLICATE",
    data: {
      name,
      id,
    },
    includeMasterUsers: true,
  });
  selectedTemplateId.value = id;
  needRefresh.value = true;
};

const onTemplateDeleted = ({ name, id }) => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLGALLERY_PRIVATE_DELETE",
    data: {
      name,
      id,
    },
    includeMasterUsers: true,
  });
  refreshGallery();
};

const onSaveTemplate = ({ template }: { template: Template }) => {
  if (selectedTemplateId.value !== template.id) {
    trackingEventsService.dispatchAll({
      name: "APP_TPLGALLERY_PRIVATE_CREATE",
      data: {
        name: template.name,
        id: template.id,
      },
      includeMasterUsers: true,
    });
  }
  isPublic.value = false;
  selectedTemplateId.value = template.id;
};

const copyPublicTemplate = async ({ publicTplId }: { publicTplId: string }): Promise<Template | undefined> => {
  try {
    const tpl = await templatesAPI.copyPublicTemplate({
      tpl_id: publicTplId,
    });
    return tpl;
  } catch (e) {
    return undefined;
  }
};

const onEditTemplate = async ({ name, id }) => {
  if (isPublic.value) {
    trackingEventsService.dispatchAll({
      name: "APP_TPLGALLERY_PUBLIC_USE",
      data: {
        name,
        id,
      },
      includeMasterUsers: true,
    });
    const newTemplate = await copyPublicTemplate({ publicTplId: id });
    if (!newTemplate) return;

    isPublic.value = false;
    selectedTemplateId.value = newTemplate?.id;
    needRefresh.value = true;
    openEditor();
  } else {
    trackingEventsService.dispatchAll({
      name: "APP_TPLGALLERY_PRIVATE_EDIT",
      data: {
        name,
        id,
      },
      includeMasterUsers: true,
    });
    openEditor();
  }
};

//Select template
const selectTemplate = ({
  templateItem,
  isPublicTemplate,
}: {
  templateItem: TemplateItem;
  isPublicTemplate: boolean;
}) => {
  isPublic.value = isPublicTemplate;
  selectedTemplateId.value = templateItem.id;
};

const tabChanged = (tab) => {
  isPublic.value = tab === "templateGallery";
};

const categoryChanged = (categoryId: string) => {
  selectedCategoryId.value = categoryId;
};

//Preview
const needRefresh = ref(true);
const closePreview = () => {
  selectedTemplateId.value = undefined;
};

watch(
  [selectedTemplateId, isPublic],
  () => {
    if (selectedTemplateId.value) return;

    if (needRefresh.value) {
      refreshGallery();
      needRefresh.value = false;
    }

    if (isPublic.value) {
      trackingEventsService.dispatchAll({
        name: "APP_TPLGALLERY_PUBLIC_VISIT",
      });
      return;
    }

    const tabSelected = localStorageApp.get<"grid" | "list">({ id: "private_gallery_tab" });
    trackingEventsService.dispatchAll({
      name: "APP_TPLGALLERY_PRIVATE_VISIT",
      data: {
        view: tabSelected ?? tabMode.value ?? "grid",
      },
    });
  },
  { immediate: true }
);

watchEffect(() => {
  let route = isPublic.value ? "public" : "private";
  if (selectedTemplateId.value) {
    route += "/" + selectedTemplateId.value;
    emit("routeChanged", route);
    return;
  }

  const categoryName = selectedCategoryId.value;
  if (categoryName === undefined || route === "private") {
    emit("routeChanged", route);
    return;
  }
  route += "/category/" + categoryName;
  emit("routeChanged", route);
  return;
});

//Campaigns
const createNewCampaign = () => {
  //TODO: Manejar crear campaña
};

//Template Editor
const editorIsOpen = ref(false);

const openEditor = () => {
  editorIsOpen.value = true;
};

const closeEditor = () => {
  editorIsOpen.value = false;
  needRefresh.value = true;
};
</script>
