<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div>
      <div class="flex items-center justify-between">
        <h1 v-if="pdfMode" class="mx-1 whitespace-nowrap text-base font-medium">
          {{ selection == "all" ? t("allContacts") : t("newContacts") }}
        </h1>
        <div v-else>
          <ToggleButtons
            v-model="selection"
            :buttons="[
              {
                id: 'all',
                label: t('allContacts'),
              },
              {
                id: 'new',
                label: t('newContacts'),
              },
            ]"
          />
        </div>
        <div class="ml-4 flex flex-shrink items-center space-x-4"></div>
      </div>
    </div>
    <div class="mt-6">
      <Chart :options="chartOptions" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, PropType, toRef, inject, watch, toRaw } from "vue";
import { Chart } from "highcharts-vue";
import { useI18n } from "vue-i18n";
import { isAfter } from "date-fns";
import { DashboardData } from "@domain/dashboard/dashboard";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import {
  useCharts,
  CategoryResolvers,
  Accumulator,
  CategoryResolver,
  ChartPresets,
  sumIfCurrent,
  sumIfPrevious,
} from "@composables/charts";

//Store
import { storeToRefs } from "pinia";
import { useUserConfigStore } from "@/vue/stores/userConfigStore/userConfigStore";

interface IChartPoint {
  current: {
    total: number;
    new: number;
    engaged: number;
    asleep: number;
    neverEngaged: number;
    disabled: number;
    lastDate: Date;
  };
  previous: {
    total: number;
    new: number;
    engaged: number;
    asleep: number;
    neverEngaged: number;
    disabled: number;
    lastDate: Date;
  };
}

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
});

const pdfMode = inject("pdfMode", false);

const userConfigStore = useUserConfigStore();

const { dashBoardState } = storeToRefs(userConfigStore);

const { t, n, d } = useI18n();
const selection = ref<"all" | "new">(dashBoardState.value.chartContacts.selection || "all");
const compare = ref(false);

watch([selection], () => {
  dashBoardState.value = {
    ...toRaw(dashBoardState.value),
    chartContacts: {
      ...toRaw(dashBoardState.value.chartContacts),
      selection: selection.value,
    },
  };
});

const newChartPoint = (): IChartPoint => ({
  current: {
    total: 0,
    new: 0,
    engaged: 0,
    asleep: 0,
    neverEngaged: 0,
    disabled: 0,
    lastDate: new Date(0),
  },
  previous: {
    total: 0,
    new: 0,
    engaged: 0,
    asleep: 0,
    neverEngaged: 0,
    disabled: 0,
    lastDate: new Date(0),
  },
});

const accumulate: Accumulator<IChartPoint> = (acc, newData, periodType, date) => {
  const current = periodType === "current";
  const previous = periodType === "previous";
  const after = current ? isAfter(date, acc.current.lastDate) : isAfter(date, acc.previous.lastDate);

  return {
    current: {
      new: sumIfCurrent(periodType, acc.current.new, newData.pem_api_contacts.recent),
      total: current && after ? newData.pem_api_contacts.total : acc.current.total,
      engaged: current && after ? newData.pem_api_contacts.engaged : acc.current.engaged,
      asleep: current && after ? newData.pem_api_contacts.asleep : acc.current.asleep,
      neverEngaged: current && after ? newData.pem_api_contacts.never_engaged : acc.current.neverEngaged,
      disabled:
        current && after
          ? Math.max(0, newData.pem_api_contacts.total - newData.pem_api_contacts.active)
          : acc.current.disabled,
      lastDate: current && after ? date : acc.current.lastDate,
    },
    previous: {
      new: sumIfPrevious(periodType, acc.previous.new, newData.pem_api_contacts.recent),
      total: previous && after ? newData.pem_api_contacts.total : acc.previous.total,
      engaged: previous && after ? newData.pem_api_contacts.engaged : acc.previous.engaged,
      asleep: previous && after ? newData.pem_api_contacts.asleep : acc.previous.asleep,
      neverEngaged: previous && after ? newData.pem_api_contacts.never_engaged : acc.previous.neverEngaged,
      disabled:
        previous && after
          ? Math.max(0, newData.pem_api_contacts.total - newData.pem_api_contacts.active)
          : acc.previous.disabled,
      lastDate: previous && after ? date : acc.previous.lastDate,
    },
  };
};

const categoryResolver = computed<CategoryResolver>(() => {
  if (props.data && props.data.period.days >= 90)
    return selection.value === "all" ? CategoryResolvers.monthLastDay : CategoryResolvers.monthly;
  if (props.data && props.data.period.days < 30) return CategoryResolvers.daily;
  return selection.value === "all" ? CategoryResolvers.weekLastDay : CategoryResolvers.weekly;
});

const { chartData } = useCharts(toRef(props, "data"), categoryResolver, accumulate, newChartPoint, compare, (date) =>
  props.data?.period.isWithinSameYear() ? d(date, "short") : d(date, "shortWithYear")
);

const plotOptions = pdfMode
  ? {
      ...ChartPresets.plotOptions,
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
      series: {
        ...ChartPresets.plotOptions.series,
        animation: false,
      },
    }
  : {
      ...ChartPresets.plotOptions,
      column: {
        stacking: "normal",
        borderWidth: 0,
      },
    };

const chartOptions = computed(() => {
  const options: any = {
    title: "",
    tooltip: ChartPresets.tooltip((val) => n(val, "decimal")),
    chart: pdfMode ? { width: 1266 } : {},
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      ...ChartPresets.xAxis,
      categories: chartData.value && Object.keys(chartData.value),
      tickInterval: chartData.value && ChartPresets.tickIntervaler(Object.keys(chartData.value).length),
    },
    credits: false,
    plotOptions: plotOptions,
    legend: {
      ...ChartPresets.legend,
    },
    series: [],
  };

  if (selection.value === "new") {
    options.series = [
      {
        name: t("new"),
        id: "new",
        index: 1,
        color: ChartPresets.colors.green,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.new),
        type: "column",
      },
    ];
  } else {
    options.series = [
      {
        name: t("engaged"),
        id: "engaged",
        index: 1,
        color: ChartPresets.colors.darkBlue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.engaged),
        type: "column",
        stack: 0,
      },
      {
        name: t("asleep"),
        id: "asleep",
        index: 3,
        color: ChartPresets.colors.blue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.asleep),
        type: "column",
        stack: 0,
      },
      {
        name: t("neverEngaged"),
        id: "neverEngaged",
        index: 5,
        color: ChartPresets.colors.lightBlue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.neverEngaged),
        type: "column",
        stack: 0,
      },
      {
        name: t("disabled"),
        id: "disabled",
        index: 7,
        color: ChartPresets.colors.lightOrange,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.disabled),
        type: "column",
        stack: 0,
      },
    ];
  }

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "engaged": "Activos",
    "asleep": "Dormidos",
    "neverEngaged": "Sin actividad",
    "disabled": "Inhabilitados",
    "total": "Totales",
    "new": "Nuevos",
    "engagedPrev": "Activos (ant.)",
    "asleepPrev": "Dormidos (ant.)",
    "neverEngagedPrev": "Sin actividad (ant.)",
    "disabledPrev": "Inhabilitados (ant.)",
    "totalPrev": "Totales (ant.)",
    "newPrev": "Nuevos (ant.)",
    "allContacts": "Todos los contactos",
    "newContacts": "Sólo contactos nuevos"
  },
  "pt": {
    "engaged": "Ativos",
    "asleep": "Inativos",
    "neverEngaged": "Sem interação",
    "disabled": "Desativados",
    "total": "Totais",
    "new": "Novos",
    "engagedPrev": "Ativos (ant.)",
    "asleepPrev": "Inativos (ant.)",
    "neverEngagedPrev": "Sem interação (ant.)",
    "disabledPrev": "Desativados (ant.)",
    "totalPrev": "Totais (ant.)",
    "newPrev": "Novo (ant.)",
    "allContacts": "Todos os contatos",
    "newContacts": "Apenas novos contatos"
  }
}
</i18n>
