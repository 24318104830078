<template>
  <div class="flex flex-col overflow-y-auto">
    <div class="flex justify-between border-b p-5">
      <div class="my-auto pt-3">
        <div class="flex space-x-3">
          <h1 class="m-0 text-xl font-medium text-gray-700">{{ template.name }}</h1>
          <div class="my-auto flex space-x-2">
            <template v-if="isPublicTemplate">
              <SimpleBadge v-if="isPremium" model-value="premium" :icon="StarIcon" theme="sky" class="mx-2" />
              <SimpleBadge v-if="isNewTemplate(template)" :model-value="t('newTemplate')" dot theme="green" />
            </template>
          </div>
        </div>
        <div class="mt-4 space-x-2">
          <TagsGroup readonly :show-tag-colors="!isPublicTemplate" :selected-tags="filteredTags" :maxVisibleTags=8 />
        </div>
      </div>
      <button
        type="button"
        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2"
        @click="close"
      >
        <XIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <div class="flex flex-grow flex-col overflow-y-auto pt-5">
      <div class="flex justify-between">
        <div>
          <SimpleButton theme="white" class="ml-5" @click="close">
            <template #leading>
              <ChevronLeftIcon class="text-gray-700" />
            </template>
            {{ t("goBackButton") }}
          </SimpleButton>
        </div>
        <div v-if="!isPublicTemplate" class="w-full space-y-1 px-9 text-sm">
          <span
            class="block font-semibold text-gray-500"
            :class="[templatePreview?.subject ? 'first-line:text-gray-500' : 'text-gray-400']"
            >{{ templatePreview?.subject || t("subjectPlaceholder") }}</span
          >
          <span class="block font-normal text-gray-400">{{
            templatePreview?.preheader || t("preheaderPlaceholder")
          }}</span>
        </div>
        <div class="my-0 mr-4 flex flex-shrink-0 space-x-2 py-0">
          <ToggleButtons
            :disabled="loading"
            :model-value="previewMode"
            :buttons="[
              {
                id: 'desktop',
                label: 'desktop',
                icon: DesktopComputerIcon,
                tooltip: t('tooltipPreviewFormat.desktop'),
              },
              { id: 'mobile', label: 'mobile', icon: DeviceMobileIcon, tooltip: t('tooltipPreviewFormat.mobile') },
            ]"
            class="my-0 py-0"
            @update:model-value="togglePreviewMode"
          />
          <div>
            <SimpleButton :disabled="loading" theme="primary-light" @click="() => selectTemplate(template)">
              <template #leading>
                <CheckIcon />
              </template>
              {{ t("useTemplateButton") }}
            </SimpleButton>
          </div>
        </div>
      </div>
      <div
        :class="[
          !loading && 'overflow-y-auto',
          previewMode === 'mobile' ? 'w-[360px]' : 'w-full',
          'mx-auto mt-5 h-auto flex-grow',
        ]"
      >
        <div v-if="loading" class="mx-auto h-[80vh] w-[50%] animate-pulse bg-gray-100" />
        <iframe
          ref="templatePreviewRef"
          src=""
          scrolling="no"
          frameborder="0"
          :class="[loading && 'invisible', 'h-full']"
          style="width: 100%"
          data-preview="form"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, computed } from "vue";

//Components
import ToggleButtons from "@atoms/ToggleButtons.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import { TagsGroup } from "@organisms/Tags";

//Icons
import { DesktopComputerIcon, DeviceMobileIcon, XIcon } from "@heroicons/vue/outline";
import { ChevronLeftIcon, CheckIcon, StarIcon } from "@heroicons/vue/solid";

//Utils
import { attachToNewIframe, refreshHeight } from "@helpers/iframe";
import { useI18n } from "vue-i18n";
import { differenceInDays } from "date-fns";

//API
import { useEmailTemplate } from "@api/modules/templates/templates";

//Types
import { TemplatePreview, PublicTemplatePreview, TemplateItem } from "@/vue/types/Templates";
import { Tags } from "@/vue/types/tag";

export type PreviewModes = "mobile" | "desktop";

const templatesAPI = useEmailTemplate();

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    template: TemplateItem;
    isPublicTemplate: boolean;
  }>(),
  {}
);

// Emits
const emit = defineEmits<{
  (e: "close"): void;
  (e: "select", templateSelected: TemplateItem): void;
}>();
const close = () => emit("close");
const selectTemplate = (templateSelected: TemplateItem) => emit("select", templateSelected);

const premiumTagID = "premium";
const publishedTag = "tag_clacrfpti001i0b32qoxl7xyb";
const suggested = "tag_clinjqe7l00y40851kjx0kfhx";
const suggestedAr = "tag_clinjry3400yd0b58jggwreaq";
const suggestedBr = "tag_clinjst5f00y90851tpchw7y3";
const suggestedMx = "tag_clinjtsmi00yb0851xkcbh9ue";
const tagsToFilter = [premiumTagID, publishedTag, suggested, suggestedAr, suggestedBr, suggestedMx];

const isPremium = props.template.tags.find((template) => template.id === premiumTagID);
const filteredTags = computed<Tags>(() =>
  props.template.tags.filter((tag) => !tag.id || !tagsToFilter.includes(tag.id))
);

//Preview
const loading = ref(true);
const templatePreviewRef = ref<HTMLIFrameElement>();
const templatePreview = ref<TemplatePreview | PublicTemplatePreview | undefined>();

const getTemplatePreview = async (id: string): Promise<TemplatePreview | undefined> => {
  try {
    const tpl = await templatesAPI.getTemplatePreview({
      tpl_id: id,
      use_autosave: false,
    });

    return tpl;
  } catch (e) {
    //TODO: Emit close event
    //TODO: AlertBox
    return undefined;
  }
};

const getPublicTemplatePreview = async (id: string): Promise<PublicTemplatePreview | undefined> => {
  try {
    const tpl = await templatesAPI.getPublicTemplatePreview({
      tpl_id: id,
    });

    return tpl;
  } catch (e) {
    //TODO: Emit close event
    //TODO: AlertBox
    return undefined;
  }
};

watchEffect(async () => {
  if (!props.template || !props.template.id) return;

  loading.value = true;

  templatePreview.value = props.isPublicTemplate
    ? await getPublicTemplatePreview(props.template.id)
    : await getTemplatePreview(props.template.id);

  loading.value = false;

  if (!templatePreview.value?.html) return;
  attachToNewIframe({ html: templatePreview.value?.html, iframeRef: templatePreviewRef.value });
});

//Preview Mode
const previewMode = ref<PreviewModes>("desktop");
const togglePreviewMode = (value: PreviewModes) => {
  previewMode.value = value;
  refreshHeight({ iframeRef: templatePreviewRef.value });
};

//Template Date
const isNewTemplate = (template: TemplateItem) => {
  if (!template.created) return;

  return differenceInDays(new Date(), new Date(template.created)) <= 30;
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "newTemplate": "Nuevo",
    "goBackButton": "Volver",
    "useTemplateButton": "Usar esta plantilla",
    "subjectPlaceholder": "No se ha definido un asunto para esta plantilla",
    "preheaderPlaceholder": "No se ha definido un preheader para esta plantilla",
    "tooltipPreviewFormat": {
      "desktop": "escritorio",
      "mobile": "móvil"
    }
  },
  "pt": {
    "newTemplate": "Novo",
    "goBackButton": "Volver",
    "useTemplateButton": "Usar esse template",
    "subjectPlaceholder": "Não foi definido um assunto para este template",
    "preheaderPlaceholder": "Não foi definido um pré-header para este modelo",
    "tooltipPreviewFormat": {
      "desktop": "computador",
      "mobile": "móvel"
    }
  }
}
</i18n>
