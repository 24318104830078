<template>
  <div class="mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
        <button type="button" class="font-semibold text-sky-400 hover:text-sky-500" @click="goToSignUp">
          {{ t("createAccount") }}
        </button>
      </p>
    </div>

    <div class="mt-5">
      <div>
        <div class="mt-6">
          <label class="block text-sm font-medium leading-6 text-gray-900">{{ t("sessionWith") }}</label>
          <TiendanubeButton />
        </div>
      </div>
      <div class="relative mt-5">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-200" />
        </div>
        <div class="relative flex justify-center text-sm font-medium leading-6">
          <span class="bg-white px-6 text-gray-500">{{ t("continueWith") }}</span>
        </div>
      </div>
      <div class="mt-5">
        <AlertBox v-show="invalidCredentialsError" theme="danger" class="mb-4">
          {{ t("invalidCredentialsError") }}
        </AlertBox>
        <AlertBox v-show="accountDisabledError" theme="warning" class="mb-4">
          {{ t("accountDisabledError") }}
        </AlertBox>

        <form @submit.prevent="login">
          <div class="mt-2 flex space-x-2">
            <div class="w-full">
              <FormInput
                :model-value="loginInfo.email"
                :label="t('emailLabel')"
                :placeholder="t('emailPlaceholder')"
                :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
                autocomplete="email"
                @update:model-value="updateEmail"
                @focus="clearEmailValidation"
                @input="clearEmailValidation"
              />
            </div>
            <div v-if="isMasterUserEmail">
              <FormInput v-model="MUtoken" :label="t('MUtokenLabel')" :placeholder="t('MUtokenPlaceholder')" />
            </div>
          </div>

          <div class="relative mt-6">
            <FormInput
              v-model="loginInfo.password"
              :label="t('passwordLabel')"
              :placeholder="t('passwordPlaceholder')"
              :type="showPassword ? 'text' : 'password'"
              :error="v$.password.$error ? t('fieldRequired') : undefined"
              autocomplete="current-password"
              @focus="clearPasswordValidation"
              @input="clearPasswordValidation"
            />
            <button
              v-show="!v$.password.$error"
              type="button"
              class="absolute right-3 top-8 text-gray-400 focus:outline-sky-400"
              @click="togglePasswordVisibility"
            >
              <EyeIcon class="h-5 w-5" v-show="showPassword" />
              <EyeOffIcon class="h-5 w-5" v-show="!showPassword" />
            </button>
          </div>

          <SelectMenuLabel
            v-if="accounts.length > 0"
            v-model:selected="selectedAccount"
            :label="t('accountsLabel')"
            :data-items="accounts"
            :rem-height="11"
            class="mt-6"
          />

          <div class="mt-4 text-sm leading-6">
            <button type="button" class="font-semibold text-sky-400 hover:text-sky-500" @click="goToRecoverPassword">
              {{ t("forgotPassword") }}
            </button>
          </div>

          <SimpleButton
            type="submit"
            :loading="submitting"
            :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
          >
            <template #leading>
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </template>
            {{ t("login") }}
          </SimpleButton>

          <div class="mt-5 text-center text-sm leading-6">
            <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
              t("loginProblems")
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from "vue";

import { validateUserFormat } from "../authentication";

// components
import TiendanubeButton from "./TiendanubeButton.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItem, DataItems } from "@molecules/SelectMenuLabel.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Icons
import { CheckIcon, EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import type { ErrorObject } from "@vuelidate/core";

// Application
import { useAuthenticationApp, useRouterApp } from "@application";

// I18n
import { useI18n } from "vue-i18n";

const accountsToDataItems = (accounts: string[]): DataItems<unknown, string, string> => {
  return accounts.map((account) => ({
    key: account,
    value: account,
  }));
};

const authApp = useAuthenticationApp();
const routerApp = useRouterApp();

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    email: string;
  }>(),
  {
    email: "",
  },
);

const emit = defineEmits<{
  recoverPassword: [void];
  signUp: [void];
  helpArticle: [void];
  "update:email": [string];
  resetPassword: [
    {
      email: string;
      password: string;
      account?: string;
    },
  ];
}>();

const goToRecoverPassword = () => {
  emit("recoverPassword");
};

const goToSignUp = () => {
  emit("signUp");
};

// Data
const isMasterUserEmail = computed(() =>
  ["@perfit.com.ar", "@myperfit.com", "@tiendanube.com", "@nuvemshop.com.br"].some((e) => loginInfo.email.toLowerCase().includes(e)),
);
const MUtoken = ref<string>();

const loginInfo = reactive({
  email: props.email,
  password: "",
});
const accounts = ref<DataItems<unknown, string, string>>([]);
const selectedAccount = ref<DataItem>();

const updateEmail = (value: string) => {
  loginInfo.email = value;
  emit("update:email", value);
};

// Validation
const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const emailForceInvalid = ref(false);
const invalidCredentialsError = ref(false);
const accountDisabledError = ref(false);

const customEmailValidation = (value: string) => {
  const valid = validateUserFormat(value);

  return !emailForceInvalid.value && valid;
};
const clearEmailValidation = () => {
  emailForceInvalid.value = false;
  v$.value.email.$reset();
};
const setEmailInvalid = () => {
  emailForceInvalid.value = true;
  v$.value.email.$touch();
};
const clearPasswordValidation = () => {
  v$.value.password.$reset();
};

const validationRules = {
  email: { required, customEmailValidation },
  password: { required },
};

const v$ = useVuelidate(validationRules, loginInfo);

const showPassword = ref(false);
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

// Login
const submitting = ref(false);
const login = async () => {
  try {
    invalidCredentialsError.value = false;
    accountDisabledError.value = false;

    // Fields validation
    const validation = await v$.value.$validate();

    if (!validation) return;

    submitting.value = true;

    const res = await authApp.login({
      user: isMasterUserEmail.value && MUtoken.value ? `${loginInfo.email}!${MUtoken.value}` : loginInfo.email,
      password: loginInfo.password,
      account: selectedAccount.value?.key,
    });

    // Logged in
    if (res.isOk() && !res.value) {
      routerApp.navigate({
        path: "home",
        trigger: false,
      });
      location.reload();
      return;
    }

    // Select account
    if (res.isOk() && res.value) {
      accounts.value = accountsToDataItems(res.value.accounts);
      selectedAccount.value = accounts.value[0];
      return;
    }

    // Invalid fields
    if (res.isErr() && res.error.type === "VALIDATION_ERROR") {
      setEmailInvalid();
      return;
    }

    if (res.isErr() && res.error.type === "UNAUTHORIZED") {
      invalidCredentialsError.value = true;
      return;
    }

    if (res.isErr() && res.error.type === "ACCOUNT_DISABLED") {
      accountDisabledError.value = true;
      return;
    }

    if (res.isErr() && res.error.type === "PASSWORD_EXPIRED") {
      emit("resetPassword", {
        email: loginInfo.email,
        password: loginInfo.password,
        account: selectedAccount.value?.key,
      });
      return;
    }
  } catch (e) {
    // No handler
  } finally {
    submitting.value = false;
  }
};

const openHelpArticle = () => {
  emit("helpArticle");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Iniciar sesión",
    "subtitle": "¿No tienes cuenta?",
    "createAccount": "¡Crea una ahora!",
    "continueWith": "O continúa con",
    "sessionWith": "Iniciar sesión con",
    "forgotPassword": "¿Olvidaste tu contraseña?",
    "login": "Ingresar",
    "rememberMe": "Recordarme",
    "loginProblems": "¿Problemas para ingresar?",
    "emailLabel": "Email",
    "emailPlaceholder": "Ingresa tu email",
    "MUtokenLabel": "Masteruser",
    "MUtokenPlaceholder": "Token",
    "passwordLabel": "Contraseña",
    "accountsLabel": "Cuenta",
    "passwordPlaceholder": "Ingresa una contraseña",
    "fieldRequired": "Campo requerido.",
    "invalidEmail": "El email ingresado es inválido.",
    "invalidCredentialsError": "El email o la contraseña ingresados son incorrectos. Revísalos y vuelve a intentar.",
    "accountDisabledError": "La cuenta a la que intentas acceder fue deshabilitada. Contactate con el equipo de soporte."
  },
  "pt": {
    "title": "Iniciar sessão",
    "subtitle": "Ainda não tem conta?",
    "createAccount": "Crie uma agora!",
    "continueWith": "Ou continue com",
    "sessionWith": "Entrar com",
    "forgotPassword": "Esqueceu sua senha?",
    "login": "Entrar",
    "rememberMe": "Lembrar-me",
    "loginProblems": "Problemas ao entrar?",
    "emailLabel": "E-mail",
    "emailPlaceholder": "Insira seu e-mail",
    "MUtokenLabel": "Masteruser",
    "MUtokenPlaceholder": "Token",
    "passwordLabel": "Senha",
    "accountsLabel": "Conta",
    "passwordPlaceholder": "Insira sua senha",
    "fieldRequired": "Campo obrigatório.",
    "invalidEmail": "O e-mail inserido é inválido.",
    "invalidCredentialsError": "O e-mail ou senha inseridos estão incorretos. Verifique e tente novamente.",
    "accountDisabledError": "A conta que você está tentando acessar foi desativada. Entre em contato com a equipe de suporte."
  }
}
</i18n>
