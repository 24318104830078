<template>
  <div ref="switchRef">
    <Switch
      :model-value="modelValue"
      :class="[
        disabled
          ? modelValue
            ? 'cursor-default bg-gray-200'
            : 'cursor-default bg-gray-100'
          : modelValue
          ? 'cursor-pointer bg-sky-400'
          : 'cursor-pointer bg-gray-200',
        small ? 'h-4 w-7' : 'h-6 w-11',
        'relative inline-flex flex-shrink-0  rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-offset-2',
      ]"
      :disabled="disabled"
      @update:modelValue="!disabled && $emit('update:modelValue', $event)"
    >
      <span
        aria-hidden="true"
        :class="[
          modelValue ? (small ? 'translate-x-3' : 'translate-x-5') : 'translate-x-0',
          small ? 'h-3 w-3' : 'h-5 w-5',
          disabled ? 'bg-gray-100' : 'bg-white',
          'pointer-events-none inline-block  transform  rounded-full shadow ring-0 transition duration-200 ease-in-out',
        ]"
      >
        <LoadingSpinner v-if="loading" class="m-0.5 text-sky-500" aria-hidden="true" />
        <span v-else-if="$slots.icon" :class="[modelValue && 'text-sky-500']">
          <slot name="icon" />
        </span>
      </span>
    </Switch>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

// Components
import { Switch } from "@headlessui/vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Composables
import { useTooltip } from "@composables/tooltips";

//Types
import type { Placement } from "@popperjs/core";

//Props
const props = withDefaults(
  defineProps<{
    small?: boolean;
    disabled?: boolean;
    modelValue?: boolean;
    loading?: boolean;
    tooltip?: string;
    tooltipPlacement?: Placement;
  }>(),
  {
    small: false,
    disabled: false,
    modelValue: false,
    loading: false,
    tooltip: "",
    tooltipPlacement: "auto",
  }
);

defineEmits<{
  (e: "update:modelValue", modelValue: boolean): void;
}>();

const switchRef = ref();
onMounted(() => {
  props.tooltip &&
    useTooltip(switchRef, props.tooltip, {
      placement: props.tooltipPlacement,
      popperOptions: {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              tether: false,
              boundary: "clippingParents",
            },
          },
          {
            name: "flip",
            options: {
              allowedAutoPlacements: ["bottom", "top", "bottom-end", "top-end"],
              fallbackPlacements: ["bottom", "top", "bottom-end", "top-end"],
              altBoundary: true,
            },
          },
        ],
      },
    });
});
</script>
