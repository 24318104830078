<template>
  <ModalDialog :open="open" :show-close-button="false" @close="close">
    <div class="max-w-md">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
        <LockClosedIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
      </div>
      <h1 class="m-0 mt-4 text-center text-lg font-medium text-gray-800">{{ t("title") }}</h1>
      <div class="mt-1 text-left">
        <TextParagraph>
          {{ message ?? t("defaultMessage") }}
        </TextParagraph>
        <div v-show="permissions" class="mt-2 flex flex-col items-start">
          <h3 class="m-0 mb-1 text-sm font-medium text-gray-800">
            {{ permissions && permissions?.length > 1 ? t("permissionsNeeded") : t("permissionNeeded") }}:
          </h3>
          <ul>
            <li v-for="permission in permissions" :key="permission" class="py-0.5 text-sm font-normal text-gray-500">
              {{ t(`permissions.${permission}`) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template #buttons>
      <button
        class="w-full justify-center rounded border-transparent bg-sky-100 px-4 py-2 text-base font-medium text-sky-700 hover:bg-sky-200 hover:text-sky-800 focus:outline-none focus:ring-2 focus:ring-sky-400 focus:ring-offset-2 sm:text-sm"
        @click="close"
      >
        {{ t("close") }}
      </button>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
// Components
import TextParagraph from "@atoms/TextParagraph.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

// Icon
import { LockClosedIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { Permissions } from "@domain/permissions";

const { t } = useI18n();

withDefaults(
  defineProps<{
    open?: boolean;
    message?: string;
    permissions?: Permissions;
  }>(),
  {
    open: false,
    message: undefined,
    permissions: undefined,
  },
);

const emit = defineEmits<{
  close: [];
}>();

const close = () => {
  emit("close");
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Acción restringida",
    "close": "Volver",
    "defaultMessage": "No tienes los permisos suficientes para realizar esta acción. Ponte en contacto con el administrador de tu cuenta para solucionarlo.",
    "permissionNeeded": "Necesitas el siguiente permiso",
    "permissionsNeeded": "Necesitas los siguientes permisos",
    "permissions": {
      "campaigns:list": "Ver campañas",
      "campaigns:create": "Crear campañas",
      "campaigns:update": "Modificar campañas",
      "campaigns:delete": "Eliminar campañas",
      "campaigns:launch": "Enviar campañas",
      "campaigns:metrics": "Ver reportes de campañas",
      "lists:list": "Ver listas",
      "lists:create": "Crear listas",
      "lists:update": "Modificar listas",
      "lists:delete": "Eliminar listas",
      "contacts:list": "Ver contactos",
      "contacts:create": "Crear contactos",
      "contacts:update": "Modificar contactos",
      "contacts:delete": "Eliminar contactos",
      "contacts:import": "Importar contactos",
      "contacts:export": "Exportar contactos",
      "fields:list": "Ver campos",
      "fields:create": "Crear campos",
      "fields:update": "Modificar campos",
      "fields:delete": "Eliminar campos",
      "interests:list": "Ver intereses",
      "interests:create": "Crear intereses",
      "interests:update": "Modificar intereses",
      "interests:delete": "Eliminar intereses",
      "optins:list": "Ver formularios",
      "optins:create": "Crear formularios",
      "optins:update": "Modificar formularios",
      "optins:delete": "Eliminar formularios",
      "templates:list": "Ver diseños",
      "templates:create": "Crear diseños",
      "templates:update": "Modificar diseños",
      "templates:delete": "Eliminar diseños",
      "images:list": "Ver imágenes",
      "images:create": "Crear imágenes",
      "images:update": "Modificar imágenes",
      "images:delete": "Eliminar  imágenes",
      "account:plan": "Modificar plan contratado",
      "account:update": "Modificar cuenta",
      "apps:list": "Listar integraciones",
      "apps:create": "Crear integraciones",
      "apps:delete": "Eliminar integraciones",
      "tasks:cancel": "Cancelar tareas"
    }
  },
  "pt": {
    "title": "Ação restrita",
    "close": "Voltar",
    "defaultMessage": "Você não tem permissões suficientes para realizar esta ação. Entre em contato com o administrador de sua conta para resolver isso.",
    "permissionNeeded": "Você precisa da seguinte permissão",
    "permissionsNeeded": "Você precisa das seguintes permissões",
    "permissions": {
      "campaigns:list": "Ver campanhas",
      "campaigns:create": "Criar campanhas",
      "campaigns:update": "Modificar campanhas",
      "campaigns:delete": "Eliminar campanhas",
      "campaigns:launch": "Enviar campanhas",
      "campaigns:metrics": "Ver relatórios de campanhas",
      "lists:list": "Ver listas",
      "lists:create": "Criar listas",
      "lists:update": "Modificar listas",
      "lists:delete": "Eliminar listas",
      "contacts:list": "Ver contatos",
      "contacts:create": "Criar contatos",
      "contacts:update": "Modificar contatos",
      "contacts:delete": "Eliminar contatos",
      "contacts:import": "Importar contatos",
      "contacts:export": "Exportar contatos",
      "fields:list": "Ver campos",
      "fields:create": "Criar campos",
      "fields:update": "Modificar campos",
      "fields:delete": "Eliminar campos",
      "interests:list": "Ver interesses",
      "interests:create": "Criar interesses",
      "interests:update": "Modificar interesses",
      "interests:delete": "Eliminar interesses",
      "optins:list": "Ver formulários",
      "optins:create": "Criar formulários",
      "optins:update": "Modificar formulários",
      "optins:delete": "Eliminar formulários",
      "templates:list": "Ver modelos",
      "templates:create": "Criar modelos",
      "templates:update": "Modificar modelos",
      "templates:delete": "Eliminar modelos",
      "images:list": "Ver imagens",
      "images:create": "Criar imagens",
      "images:update": "Modificar imagens",
      "images:delete": "Eliminar  imagens",
      "account:plan": "Modificar plano contratado",
      "account:update": "Modificar conta",
      "apps:list": "Listar integrações",
      "apps:create": "Criar integrações",
      "apps:delete": "Eliminar integrações",
      "tasks:cancel": "Cancelar tarefas"
    }
  }
}
</i18n>
