<template>
  <div :class="pdfMode && 'mt-6'" class="flex items-baseline justify-between" data-intercom-target="MetricChangeBadge">
    <div
      ref="batchRef"
      class="group relative inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium"
      :class="[containerClass, pdfMode && 'relative']"
    >
      <ArrowSmUpIcon
        v-if="direction > 0"
        class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center"
        aria-hidden="true"
        :class="arrowClass"
      />
      <ArrowSmDownIcon
        v-if="direction < 0"
        class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center"
        aria-hidden="true"
        :class="arrowClass"
      />
      <div
        v-if="direction == 0"
        class="ml-0.5 h-5 w-4 flex-shrink-0 self-center font-black"
        aria-hidden="true"
        :class="[arrowClass, pdfMode && 'invisible']"
      >
        −
      </div>
      <div
        v-if="direction == 0 && pdfMode"
        class="ml-0.5 h-5 w-4 flex-shrink-0 self-center font-black"
        aria-hidden="true"
        :class="[arrowClass, pdfMode && 'absolute inset-y-0']"
        :style="{
          top: pdfMode ? '-5px' : '0',
        }"
      >
        −
      </div>
      <span :class="pdfMode && 'invisible'">
        <slot></slot>
      </span>
      <span
        v-if="pdfMode"
        class="absolute right-0 overflow-visible"
        :style="{
          top: '-5px',
        }"
      >
        <div class="mr-2">
          <slot></slot>
        </div>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRef, inject } from "vue";
import { useTooltip } from "@composables/tooltips";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";

const props = defineProps({
  direction: {
    type: Number,
    required: true,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: null,
  },
});

const pdfMode = inject("pdfMode", false);

const containerClass = computed(() => {
  if (props.direction === 0) return "text-gray-400" + (props.tooltip ? " hover:text-gray-500" : "") + " bg-gray-100";
  if (props.direction > 0 !== props.inverted)
    return "text-green-700" + (props.tooltip ? " hover:text-green-900" : "") + " bg-green-100";
  return "text-red-700" + (props.tooltip ? " hover:text-red-900" : "") + " bg-red-100";
});

const arrowClass = computed(() => {
  if (props.direction === 0) return "text-gray-400" + (props.tooltip ? " group-hover:text-gray-500" : "");
  if (props.direction > 0 !== props.inverted)
    return "text-green-500" + (props.tooltip ? " group-hover:text-green-600" : "");
  return "text-red-500" + (props.tooltip ? " group-hover:text-red-600" : "");
});

const batchRef = ref();
if (props.tooltip) useTooltip(batchRef, toRef(props, "tooltip"), { placement: "right" });
</script>
