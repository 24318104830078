<template>
  <ModalDialog :title="t('planCancelTitle')" :open="open" without-overflow @close="closeModal">
    <div class="w-[38rem]">
      <PlanCancellationReasons
        v-if="step === 'reasons'"
        v-model:selected-reason="selectedReason"
        v-model:reason-comment="selectedReasonComment"
        @continue="goToSummary"
        @close="closeModal"
      />
      <PlanCancellationSummary
        v-else
        :plan="plan"
        :reason="selectedReason"
        :comment="selectedReasonComment"
        :dashboard-data="dashboardData"
        @scheduleCancelPlan="scheduleCancelPlan"
        @immediateCancelPlan="immediateCancelPlan"
        @close="closeModal"
      />
    </div>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

import type { Reason } from "./planCancellation.types";

//Components
import ModalDialog from "@molecules/ModalDialog.vue";
import PlanCancellationReasons from "./components/PlanCancellationReasons.vue";
import PlanCancellationSummary from "./components/PlanCancellationSummary.vue";

// Utils
import { startOfDay, subDays, endOfDay } from "date-fns";

// Application
import { useDashboardApplication, useLocalStorageApp } from "@application";

// Stores
import { useAccountStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { usePlan } from "@/vue/api/modules/plan/plan";

// Domain
import { Plan } from "@domain/plan";
import type { IDashboardDataSet } from "@domain/dashboard/base";
import { DashboardData } from "@domain/dashboard/dashboard";

const { t, n } = useI18n();
const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

const dashboardApp = useDashboardApplication();
const localStorageApp = useLocalStorageApp();

const accountStore = useAccountStore();

const planAPI = usePlan();

//Props
const props = withDefaults(
  defineProps<{
    plan: Plan;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: "planCanceled"): void;
}>();

const open = ref(false);
const resetValues = () => {
  step.value = "reasons";
  selectedReason.value = getDefaultReason();
  selectedReasonComment.value = "";
};

const openModal = () => {
  resetValues();
  open.value = true;
};
const closeModal = () => {
  open.value = false;
};

defineExpose({
  openModal,
  closeModal,
});

const getDefaultReason = (): Reason => {
  return {
    key: "empty",
    value: "‎",
    data: {
      showComment: false,
      showContactSupport: false,
      showWarning: false,
      validOption: false,
    },
  };
};
const selectedReason = ref<Reason>(getDefaultReason());
const selectedReasonComment = ref<string>("");

const step = ref<"reasons" | "summary">("reasons");

const goToSummary = () => {
  step.value = "summary";
};

const scheduleCancelPlan = async () => {
  await planAPI.scheduleCancelPlan({
    reason: selectedReason.value.key,
    comments: selectedReasonComment.value !== "" ? selectedReasonComment.value : undefined,
    executionDate: subDays(new Date(props.plan.periodEnds), 1).toISOString(),
    plan: props.plan,
    assistedSales: {
      count: dashboardData.value?.store.assisted.current.count || 0,
      amount: sn(dashboardData.value?.store.assisted.current.amount || 0, "currency"),
    },
  });
  closeModal();
  emit("planCanceled");
};
const immediateCancelPlan = async () => {
  await planAPI.immediateCancelPlan({
    reason: selectedReason.value.key,
    comments: selectedReasonComment.value !== "" ? selectedReasonComment.value : undefined,
  });
  closeModal();
  emit("planCanceled");
};

const dashboardData = ref<DashboardData>();

const lastDay = endOfDay(subDays(new Date(), 1));
const calcFrom = (days: number) => startOfDay(subDays(lastDay, days - 1));
const calculateDashboardData = () => {
  const data = localStorageApp.get<IDashboardDataSet>({
    id: "dashboardData",
  });
  if (!data) return;

  dashboardData.value = dashboardApp.calculateDashboardData({
    data: data,
    periodFrom: calcFrom(30),
    periodTo: lastDay,
    planType: props.plan.type,
    planCost: accountStore.getConfig()?.monthly_cost || props.plan.price.total,
    batchType: "all",
    attributionModel: accountStore.getConfig()?.attribution_model ?? "opens",
  });
};

onMounted(() => {
  calculateDashboardData();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "planCancelTitle": "Cancelar suscripción"
  },
  "pt": {
    "planCancelTitle": "Cancelar assinatura"
  }
}
</i18n>
