<template>
  <Listbox
    :key="refreshListBoxKey"
    as="div"
    class="relative"
    :default-value="selectedTags"
    multiple
    @update:model-value="onSelectValue"
  >
    <ListboxButton
      @keydown.stop.space="() => onToggle()"
      @keydown.stop.enter="() => onToggle()"
      @click.stop.prevent="() => onToggle()"
    >
      <slot name="button-content" />
    </ListboxButton>
    <ListboxOptions
      v-show="open"
      static
      class="absolute z-10 mt-1.5 overflow-hidden rounded-md shadow focus:outline-none"
      @keydown.stop.escape="() => onToggle()"
    >
      <TagsOptions
        :open="!colorSelectionIsOpen && open"
        :selected-tags="selectedTags"
        :tags="tags"
        class="min-w-[20rem] max-w-[20rem] divide-y bg-white"
        @create-tag="createNewTag"
        @toggle-tag="onToggleTag"
        @toggle="onToggle"
      />
      <ColorsOptions
        :open="colorSelectionIsOpen && open"
        class="min-w-[20rem] max-w-[20rem] divide-y bg-white"
        @select="selectTagColor"
        @toggle="onToggle"
      />
    </ListboxOptions>
  </Listbox>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

// Utils
import { v4 as uuidv4 } from "uuid";

// Components
import { Listbox, ListboxButton, ListboxOptions } from "@headlessui/vue";
import TagsOptions from "./components/TagsOptions.vue";
import ColorsOptions from "./components/ColorsOptions.vue";

// Types
import type { Color, ColorByHex } from "@/vue/types/colors";
import type { Tags, Tag } from "@/vue/types/tag";

// Props
interface Props {
  selectedTags: Tags;
  tags: Tags;
  open: boolean;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selectedTags: () => [],
  tags: () => [],
  open: false,
  loading: false,
});

// Emits
const emit = defineEmits<{
  (e: "toggleTag", tag: Tag): void;
  (e: "createTag", tag: Tag): void;
  (e: "toggle"): void;
}>();

const onToggleTag = (tag: Tag) => emit("toggleTag", tag);
const onCreateTag = (tag: Tag) => emit("createTag", tag);

// Refresh listbox
const refreshListBoxKey = ref(0);
const refreshListBox = () => refreshListBoxKey.value++;

watch(
  () => props.open,
  () => !props.open && refreshListBox()
);

// Options handlers
const colorSelectionIsOpen = ref(false);
const openColorSelection = () => (colorSelectionIsOpen.value = true);
const closeColorSelection = () => (colorSelectionIsOpen.value = false);

const onToggle = () => {
  closeColorSelection();
  emit("toggle");
};

// Create Tag
const newTag = ref<Tag>();

const createNewTag = (name: string) => {
  // trim, maxLength 50, remove extra spaces
  const formattedName = name.replaceAll(/\s+/g, " ").trim().slice(0, 50);

  newTag.value = {
    id: uuidv4(),
    name: formattedName,
    color: "#000000",
  };
  openColorSelection();
};

const selectTagColor = (color: Color) => {
  onToggle();
  if (!newTag.value) return;
  newTag.value.color = (color as ColorByHex).hexValue;
  onCreateTag(newTag.value);
  newTag.value = undefined;
};

const onSelectValue = (values: [{ createTag: string } | Tag | Color]) => {
  const value = values[0];
  if ((value as { createTag: string })?.createTag) {
    createNewTag((value as { createTag: string }).createTag);
    return;
  }

  if (!colorSelectionIsOpen.value) {
    onToggleTag(value as Tag);
    onToggle();
    return;
  }

  selectTagColor(value as Color);
  onToggle();
};
</script>
