<template>
  <div
    class="mt-1 -space-y-px rounded-md border bg-white shadow-sm focus-within:outline-none focus-within:ring-1"
    :class="
      numberInvalid || expInvalid || cvvInvalid
        ? 'border-red-300  focus-within:border-red-500 focus-within:ring-red-500'
        : 'border-gray-300 placeholder-gray-400 focus-within:border-sky-500 focus-within:ring-sky-500'
    "
  >
    <div class="relative flex -space-x-px">
      <transition
        enter-active-class="duration-300 ease-out transform"
        enter-from-class="opacity-0 scale-x-0"
        enter-to-class="opacity-100 scale-x-100"
      >
        <CreditCardIcon v-if="cardValidation.card?.type" :brand="cardValidation.card?.type" class="mt-0.5 h-8" />
      </transition>
      <div class="flex-0 relative w-1/2 min-w-0">
        <label for="card-number" class="sr-only">{{ t("number.label") }}</label>
        <input
          id="card-number"
          ref="numberInputRef"
          type="text"
          name="card-number"
          inputmode="numeric"
          autocomplete="cc-number"
          autocorrect="off"
          spellcheck="false"
          class="relative block w-full overflow-visible rounded-none rounded-l-md border-0 bg-transparent focus:z-20 focus:ring-0 sm:text-sm"
          :placeholder="t('number.placeholder')"
          :aria-label="t('number.label')"
          :aria-invalid="numberInvalid"
          :class="{
            'z-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500':
              numberInvalid,
            'border-gray-300 placeholder-gray-400': !numberInvalid,
            'pl-1': cardValidation.card?.type,
          }"
          :value="number"
          @input="emitNumber"
          @blur="$emit('blur:number', $event)"
        />
      </div>

      <div class="flex-0 relative w-1/4 min-w-0">
        <label for="card-expiration-date" class="sr-only">{{ t("expiration.label") }}</label>
        <input
          id="card-expiration-date"
          ref="expInputRef"
          type="text"
          name="card-expiration-date"
          inputmode="numeric"
          autocomplete="cc-exp"
          autocorrect="off"
          spellcheck="false"
          class="relative block w-full rounded-none border-0 bg-transparent focus:z-20 focus:ring-0 sm:text-sm"
          :placeholder="t('expiration.placeholder')"
          :aria-label="t('expiration.label')"
          :aria-invalid="expInvalid"
          :class="{
            'z-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500':
              expInvalid,
            'border-gray-300 placeholder-gray-400': !expInvalid,
          }"
          :value="expiration"
          @input="emitExpiration"
          @blur="$emit('blur:expiration', $event)"
          @keydown="expKeyDown"
        />
      </div>

      <div class="flex-0 relative w-1/4 min-w-0">
        <label for="card-cvc" class="sr-only">{{ t("cvv.label") }}</label>
        <input
          id="card-cvv"
          ref="cvvInputRef"
          type="text"
          name="card-cvv"
          autocomplete="cc-csc"
          autocorrect="off"
          spellcheck="false"
          inputmode="numeric"
          class="relative block w-full rounded-none rounded-r-md border-0 bg-transparent focus:z-20 focus:ring-0 sm:text-sm"
          :aria-label="t('cvv.label')"
          :placeholder="cardValidation?.card?.code?.name || 'CVV'"
          :aria-invalid="cvvInvalid"
          :class="{
            'z-10 border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500':
              cvvInvalid,
            'border-gray-300 placeholder-gray-400': !cvvInvalid,
          }"
          :value="cvv"
          @input="emitCVV"
          @blur="$emit('blur:cvv', $event)"
          @keydown="cvvKeyDown"
        />
      </div>
      <InputErrorIcon :show="numberInvalid || expInvalid || cvvInvalid" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import validateCard from "card-validator";

import InputErrorIcon from "@atoms/InputErrorIcon.vue";
import CreditCardIcon from "@atoms/CreditCardIcon.vue";

export default defineComponent({
  name: "CreditCardInput",
  components: { InputErrorIcon, CreditCardIcon },
  props: {
    number: { type: String, default: "" },
    expiration: { type: String, default: "" },
    cvv: { type: String, default: "" },
    numberError: { type: Boolean, default: false },
    expError: { type: Boolean, default: false },
    cvvError: { type: Boolean, default: false },
  },
  emits: ["update:number", "update:expiration", "update:cvv", "blur:number", "blur:expiration", "blur:cvv"],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      cardValidation: {
        isValid: false,
        isPotentiallyValid: true,
        card: { type: "", code: { name: "CVV" } },
      },
      expValidation: { isValid: false, isPotentiallyValid: true },
      cvvValidation: { isValid: false, isPotentiallyValid: true },
    };
  },
  computed: {
    error() {
      return this.numberError || this.expError || this.cvvError;
    },
    numberInvalid() {
      return !!this.numberError || !this.cardValidation?.isPotentiallyValid;
    },
    expInvalid() {
      return !!this.expError || !this.expValidation?.isPotentiallyValid;
    },
    cvvInvalid() {
      return !!this.cvvError || !this.cvvValidation?.isPotentiallyValid;
    },
  },
  mounted() {
    validateCard.creditCardType.addCard({
      niceType: "CABAL",
      type: "cabal",
      patterns: [5896, 6042, 6502, 6035, 604201],
      gaps: [4, 8, 12],
      lengths: [16],
      code: {
        name: "CVV",
        size: 3,
      },
    });
  },
  methods: {
    cleanExpDate() {
      this.emitExpiration({
        target: { value: this.$refs.expInputRef.value },
        inputType: "insertFromPaste",
      });
    },
    emitNumber(e) {
      let cleanedNumber = e.target.value.replaceAll(/[^\d]/g, "");

      const cardValidation = validateCard.number(cleanedNumber, { maxLength: 16 });
      this.cardValidation = cardValidation;

      if (cardValidation.card && cardValidation.card.gaps) {
        let formattedNumber = "";
        const gaps = cardValidation.card.gaps;

        const numberLength = Math.min(16, Math.max(...cardValidation.card.lengths));
        cleanedNumber = cleanedNumber.substr(0, numberLength);

        if (cleanedNumber.length === numberLength) {
          this.$refs.expInputRef.focus();
        }

        for (let c = 0; c < cleanedNumber.length; c++) {
          formattedNumber += cleanedNumber.charAt(c);
          if (gaps.includes(c + 1)) formattedNumber += " ";
        }
        formattedNumber = formattedNumber.trim();

        this.cardValidation = validateCard.number(formattedNumber, { maxLength: 16 });

        const codeLength = this.cardValidation.card?.code?.size || 4;
        this.cvvValidation = validateCard.cvv(this.cvv, codeLength);

        this.$emit("update:number", formattedNumber);
      } else {
        this.$emit("update:number", cleanedNumber);
      }
    },
    emitExpiration(e) {
      let cleanedExp = e.target.value.replaceAll(/[^\d]/g, "");

      // soporte pata autofill/pase formato mm/yyyy
      if (e.target.value.match(/^\d{2}\/\d{4}$/)) {
        const parts = e.target.value.split("/");
        cleanedExp = parts[0] + parts[1].substr(2, 2);
      }
      if (!["insertText", "insertFromPaste", "manual"].includes(e.inputType)) {
        this.$emit("update:expiration", e.target.value);
        return;
      }

      if (cleanedExp.substr(0, 2) === "00") {
        cleanedExp = "0";
      }

      if (cleanedExp.substr(0, 2) > 12 || cleanedExp.substr(0, 1) > 1) {
        cleanedExp = "0" + cleanedExp;
      }

      cleanedExp = cleanedExp.substr(0, 4);

      if (cleanedExp.length === 4) {
        this.$refs.cvvInputRef.focus();
      }

      if (cleanedExp.length > 1) {
        cleanedExp = cleanedExp.substr(0, 2) + " / " + cleanedExp.substr(2, 2);
      }

      this.expValidation = validateCard.expirationDate(cleanedExp);

      this.$emit("update:expiration", cleanedExp);
    },
    emitCVV(e) {
      let cleanedCVV = e.target.value.replaceAll(/[^\d]/g, "");

      const maxLength = this.cardValidation?.card?.code?.size || 4;
      cleanedCVV = cleanedCVV.substr(0, maxLength);

      this.cvvValidation = validateCard.cvv(cleanedCVV, maxLength);

      this.$emit("update:cvv", cleanedCVV);
    },
    expKeyDown(e) {
      if (e.key === "Backspace" && this.expiration.length === 0) {
        this.$refs.numberInputRef.focus();
      }
    },
    cvvKeyDown(e) {
      if (e.key === "Backspace" && this.cvv.length === 0) {
        this.$refs.expInputRef.focus();
      }
    },
  },
});
</script>

<i18n lang="json">
{
  "es": {
    "number": {
      "label": "Número de tarjeta",
      "placeholder": "Número de tarjeta"
    },
    "expiration": {
      "label": "Fecha de vencimiento",
      "placeholder": "MM / AA"
    },
    "cvv": {
      "label": "Código de seguridad"
    }
  },
  "pt": {
    "number": {
      "label": "Número de cartão",
      "placeholder": "Número de cartão"
    },
    "expiration": {
      "label": "Data de validade",
      "placeholder": "MM / AA"
    },
    "cvv": {
      "label": "Código de segurança"
    }
  }
}
</i18n>
