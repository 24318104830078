import { computed, ref } from "vue";
import { defineStore } from "pinia";

// Services
import { useCookiesService, useSessionService } from "@services";
import { useAccountPlan } from "@api/modules/accountplan";

// Domain
import type { Session, LegacySession } from "@domain/session";
import { legacySessionToSession } from "@domain/session";
import type { Plan } from "@domain/plan";
import { updateSession as updateSessionDomain, sessionHasPermission } from "@domain/session";
import type { Permission } from "@domain/permissions";

let legacyParseSession: Session;

export const setLegacyParseSession = (session: LegacySession) => {
  legacyParseSession = legacySessionToSession(session);
};

export const getLegacyParseSession = () => {
  return legacyParseSession;
};

export const useSessionStore = defineStore("sessionStore", () => {
  const accountPlanService = useAccountPlan();
  const sessionService = useSessionService();
  const cookiesService = useCookiesService();

  const session = ref<Session>(legacyParseSession);

  const setSessionWithLegacySession = (legacySession: LegacySession) => {
    const newSession = legacySessionToSession(legacySession);
    session.value = newSession;

    cookiesService.setCookie({
      cname: "session",
      exDays: 365,
      value: encodeURIComponent(
        JSON.stringify({
          mainAccount: legacySession.account,
          expires: legacySession.tokenExpiration,
          account: legacySession.account,
          details: legacySession.details,
          token: legacySession.token,
          tokenExpiration: legacySession.tokenExpiration,
          user: legacySession.user,
          userType: legacySession.userType,
        }),
      ),
    });
  };

  const setSession = (newSession: Session) => {
    session.value = newSession;
  };

  const updateSession = (partialSession: DeepPartial<Session>): Session | undefined => {
    if (!session.value) return;

    const newSession = updateSessionDomain(session.value, partialSession);
    session.value = newSession;

    return session.value;
  };

  const fetchSession = async () => {
    const res = await sessionService.get();

    if (res.isErr()) return;

    setSessionWithLegacySession(res.value);
  };

  const hasPermission = (permission: Permission): boolean => {
    if (!session.value) return false;

    return sessionHasPermission(session.value, permission);
  };

  const plan = ref<Plan>();
  const trialStatus = computed<"active" | "expired" | "none">(() => {
    if (!!plan.value?.trialEnds) {
      return "active";
    }

    if (plan.value?.trialExpired) {
      return "expired";
    }

    return "none";
  });
  const hasPlan = computed(() => {
    return plan.value?.type !== "FREE";
  });

  const isInTrial = computed<Boolean>(() => {
    return !!plan.value?.trialEnds && !plan.value.trialExpired;
  });

  const fetchPlan = async () => {
    try {
      plan.value = await accountPlanService.get();
    } catch {
      // No Handler
    }
  };

  // Esto deberia ir en algún onMounted principal
  if (!["", "*"].includes(session.value.account.code)) {
    fetchPlan();
  }

  return {
    session,
    plan,
    trialStatus,
    hasPlan,
    isInTrial,
    fetchPlan,
    fetchSession,
    setSessionWithLegacySession,
    setSession,
    updateSession,
    hasPermission,
  };
});
