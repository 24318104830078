import { parseISO } from "date-fns";

import {
  Accumulator,
  IDashboardDataPoint,
  IDashboardDataSet,
  IWebTrackSessions,
  Period,
  PeriodType,
  variation,
  PlanInfo,
} from "./base";

class SalesMetric {
  amount = 0;
  count = 0;
  opens = 0;
  // dataPoints = 0;

  constructor(readonly costOfPeriod: number) {}

  public addSales(amount: number, count: number) {
    this.amount += amount;
    this.count += count;
  }

  public addOpens(opens: number) {
    this.opens += opens || 0;
  }

  // public incrementDataPoints() {
  //   this.dataPoints++;
  // }

  get roi() {
    const roi = (this.amount - this.costOfPeriod) / this.costOfPeriod;
    return roi;
  }
  get conversion() {
    return this.count / this.opens;
  }
}

class SalesMetricVar implements Accumulator {
  readonly current: SalesMetric;
  readonly previous: SalesMetric;

  constructor(
    readonly type: "total" | "assisted" | "bulk" | "automation",
    readonly costOfPeriod: number,
    readonly attributionModel: "clicks" | "opens"
  ) {
    this.current = new SalesMetric(costOfPeriod);
    this.previous = new SalesMetric(costOfPeriod);
  }

  get amountVar() {
    return variation(this.current.amount, this.previous.amount);
  }
  get countVar() {
    return variation(this.current.count, this.previous.count);
  }
  get roiVar() {
    return variation(this.current.roi, this.previous.roi);
  }
  get conversionVar() {
    return variation(this.current.conversion, this.previous.conversion);
  }
  // get isPreviousIncomplete() {
  //   return this.previous.dataPoints < this.current.dataPoints;
  // }
  // get isPreviousEmpty() {
  //   return this.previous.dataPoints === 0;
  // }

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType) {
    let salesMetric: SalesMetric;
    if (period === "current") salesMetric = this.current;
    else if (period === "previous") salesMetric = this.previous;
    else return;

    // salesMetric.incrementDataPoints();
    if (["bulk", "assisted"].includes(this.type)) {
      salesMetric.addOpens(dataPoint.mail_metrics?.bulk.first_opened);
    }
    if (["automation", "assisted"].includes(this.type)) {
      salesMetric.addOpens(dataPoint.mail_metrics?.automation.first_opened);
    }

    let dataPointSource = dataPoint.order.source;

    if (this.attributionModel === "clicks") dataPointSource = dataPoint.order.source_click;

    for (const source of dataPointSource) {
      if (
        (source.group === "automation" &&
          ["automation", "assisted", "total"].includes(this.type)) ||
        (source.group === "bulk" && ["bulk", "assisted", "total"].includes(this.type)) ||
        (source.group === "unattributed" && this.type === "total")
      ) {
        salesMetric.addSales(source.sum, source.count);
      }
    }
  }
}

class SessionsMetric {
  total = 0;
  identified = 0;
  totalVisitors = 0;
  identifiedVisitors = 0;

  constructor() {}

  add(sessions: IWebTrackSessions) {
    this.total += sessions.visits_total;
    this.identified += sessions.visits_identified;
    this.totalVisitors += sessions.visitors_total;
    this.identifiedVisitors += sessions.visitors_identified;
  }

  get vistsPerVisitor() {
    return this.total / this.totalVisitors;
  }

  get identifiedVisitsRatio() {
    return this.identified / this.total;
  }
}

class SessionsMetricVar implements Accumulator {
  readonly current = new SessionsMetric();
  readonly previous = new SessionsMetric();

  constructor() {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType) {
    let metric: SessionsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    metric.add(dataPoint.web_track_sessions);
  }

  get totalVar() {
    return variation(this.current.total, this.previous.total);
  }
  get totalVisitorsVar() {
    return variation(this.current.totalVisitors, this.previous.totalVisitors);
  }
  get identifiedVar() {
    return variation(this.current.identified, this.previous.identified);
  }
  get identifiedVisitorsVar() {
    return variation(this.current.identifiedVisitors, this.previous.identifiedVisitors);
  }
}

export class StoreDashboardData {
  readonly assisted: SalesMetricVar;
  readonly total: SalesMetricVar;
  readonly assistedBulk: SalesMetricVar;
  readonly assistedAutomation: SalesMetricVar;
  readonly sessions: SessionsMetricVar;
  currentDataPoints = 0;
  previousDataPoints = 0;

  constructor(
    dataSet: IDashboardDataSet,
    period: Period,
    readonly planInfo: PlanInfo,
    readonly attributionModel: "clicks" | "opens"
  ) {
    //solo aplica para planes mensuales
    const costOfPeriod = ((planInfo.planCost || 0) / 30) * period.days;
    this.total = new SalesMetricVar("total", costOfPeriod, "opens");
    this.assisted = new SalesMetricVar("assisted", costOfPeriod, attributionModel);
    this.assistedBulk = new SalesMetricVar("bulk", costOfPeriod, attributionModel);
    this.assistedAutomation = new SalesMetricVar("automation", costOfPeriod, attributionModel);
    this.sessions = new SessionsMetricVar();

    Object.entries(dataSet).forEach((entry) => {
      const date = parseISO(entry[0]);
      const dataPoint = entry[1];

      const periodType = period.type(date);
      this.total.accumulate(dataPoint, periodType);
      this.assisted.accumulate(dataPoint, periodType);
      this.assistedBulk.accumulate(dataPoint, periodType);
      this.assistedAutomation.accumulate(dataPoint, periodType);
      this.sessions.accumulate(dataPoint, periodType);

      if (periodType === "current") this.currentDataPoints++;
      if (periodType === "previous") this.previousDataPoints++;
    });
  }

  get isPreviousIncomplete() {
    //faltan más de 2 dias de datos
    return this.previousDataPoints < this.currentDataPoints - 2;
  }

  get isPreviousEmpty() {
    return this.previousDataPoints === 0;
  }
}
