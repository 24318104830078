// Utils
import { v4 as uuid } from "uuid";

// Domain
import { DataItems } from "./data";

// * interest - Opción especial account interest
// * gender - Opción especial account field GENDER
// * email - Opción especial account field EMAIL
export type PredicateTypes = "string" | "number" | "date" | "interest" | "gender" | "email";

// * attribute selection
export interface AttributeOption {
  id: string;
  name: string;
  type: PredicateTypes;
}

export type AttributeOptions = Array<AttributeOption>;

// * Predicates
export type ComparisonOperator =
  | "eq"
  | "ne"
  | "lt"
  | "lte"
  | "ltrel"
  | "gt"
  | "gte"
  | "gtrel"
  | "contains"
  | "notContains"
  | "exists"
  | "in"
  | "nin";

export interface AttributeComparisonOption {
  id: string;
  operator: ComparisonOperator;
  text: string;
  value?: string | number | boolean | undefined;
  name?: string;
}
export interface AttributeComparisonOptions {
  options: Array<AttributeComparisonOption>;
  valueOptions?: DataItems<string | number>;
}

export interface Attribute<A = string, PT = PredicateTypes> {
  attribute: A;
  operator: ComparisonOperator;
  type: PT;
  name?: string;
  value: string | number | boolean;
}

export type Attributes<A = string, PT = PredicateTypes> = Array<Attribute<A, PT>>;

export interface AttributeWithId<A = string, PT = PredicateTypes> extends Attribute<A, PT> {
  id: string;
}
export type AttributesWithId<A = string, PT = PredicateTypes> = Array<AttributeWithId<A, PT>>;

export const attributeOptionToAttributeWithId = (attributeOption: AttributeOption): AttributeWithId => ({
  id: uuid(),
  attribute: attributeOption.id,
  operator: attributeOption.type === "interest" ? "in" : "eq",
  type: attributeOption.type,
  value: "",
});

export type Operator = "and" | "or";

export type Predicates<A = string, PT = PredicateTypes> = {
  attributes: Attributes<A, PT>;
  predicates?: Predicates<A, PT>;
  operator: Operator;
};

export type PredicatesWithId<A = string, PT = PredicateTypes> = {
  attributes: AttributesWithId<A, PT>;
  predicates?: PredicatesWithId<A, PT>;
  operator: Operator;
};

export const addIdsToPredicates = <A = string, PT = PredicateTypes>(
  predicates: Predicates<A, PT>
): PredicatesWithId<A, PT> => {
  return {
    operator: predicates.operator,
    attributes: predicates.attributes.map((attribute) => ({
      ...attribute,
      id: uuid(),
    })),
    predicates: predicates.predicates ? addIdsToPredicates(predicates.predicates) : undefined,
  };
};

export const removeIdsFromPredicates = <A = string, PT = PredicateTypes>(
  predicates: PredicatesWithId<A, PT>
): Predicates<A, PT> => {
  return {
    operator: predicates.operator,
    attributes: predicates.attributes.map(({ id, ...attribute }) => {
      return attribute;
    }),
    predicates: predicates.predicates ? removeIdsFromPredicates(predicates.predicates) : undefined,
  };
};

export const isEmptyAttributeValue = <A = string, PT = PredicateTypes>(attribute: Attribute<A, PT>) => {
  const emptyValues: Array<string | boolean | number | undefined> = [undefined, ""];
  return emptyValues.includes(attribute.value);
};
