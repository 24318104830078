<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { MailIcon } from "@heroicons/vue/outline";

type Options = Array<"all" | "bulk" | "automation" | "transactional">;

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    options?: Options;
  }>(),
  {
    options: () => ["all", "bulk", "automation"],
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const { t } = useI18n();

const selected = ref(props.modelValue ?? props.options[0]);

const emitUpdates = () => {
  emit("update:modelValue", selected.value);
};

emitUpdates();
watch(selected, emitUpdates);
</script>

<template>
  <Listbox v-model="selected" as="div" data-intercom-target="BatchTypeSelectorMenu">
    <!-- <ListboxLabel class="block text-sm font-medium text-gray-700"> Assigned to </ListboxLabel> -->
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
      >
        <span class="flex w-full items-center truncate text-gray-500">
          <span><MailIcon class="mr-2 h-5 w-5" /></span>
          <span class="truncate">{{ t(selected) }}</span>
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          data-intercom-target="BatchTypeSelectorOptions"
        >
          <ListboxOption
            v-for="option in options"
            :key="option"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
          >
            <li
              :class="[
                active ? 'bg-sky-500 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'truncate']">
                  {{ t(option) }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-sky-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<i18n lang="jsonc">
{
  "es": {
    "all": "Todos los emails",
    "bulk": "Campañas",
    "automation": "Automations",
    "transactional": "Transactional"
  },
  "pt": {
    "all": "Todos os emails",
    "bulk": "Campanhas",
    "automation": "Automations",
    "transactional": "Transacional"
  }
}
</i18n>
