var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"email-preview\">\n    <dl class='dl-horizontal'>\n        <dt>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"from")), depth0))
    + "\n        </dt>\n        <dd>\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"fromName") || (depth0 != null ? lookupProperty(depth0,"fromName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"fromName","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":24}}}) : helper)))
    + " &lt;"
    + alias2(((helper = (helper = lookupProperty(helpers,"fromAddress") || (depth0 != null ? lookupProperty(depth0,"fromAddress") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"fromAddress","hash":{},"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":44}}}) : helper)))
    + "&gt;\n        </dd>\n        <dt>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n        </dt>\n        <dd>\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"subject") || (depth0 != null ? lookupProperty(depth0,"subject") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"subject","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":23}}}) : helper)))
    + "\n        </dd>\n    </dl>\n    <div class='email-preview-wrapper'>\n        <div class=\"email-preview-main\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "")
    + "            <dl class='dl-horizontal'>\n                <dt>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"email")), depth0))
    + ":\n                </dt>\n                <dd>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"exampleMail")), depth0))
    + "\n                </dd>\n                <dt>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"name")), depth0))
    + ":\n                </dt>\n                <dd>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"exampleName")), depth0))
    + "\n                </dd>\n                <dt>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"surname")), depth0))
    + ":\n                </dt>\n                <dd>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"exampleSurname")), depth0))
    + "\n                </dd>\n            </dl>\n            <p class=\"email-preview-header\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":46,"column":26}}}) : helper)))
    + "\n            </p>\n            <p>\n                <a class='email-preview-button'>\n                    "
    + alias2(((helper = (helper = lookupProperty(helpers,"linkText") || (depth0 != null ? lookupProperty(depth0,"linkText") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"linkText","hash":{},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":32}}}) : helper)))
    + "\n                </a>\n            </p>\n            <p class=\"email-preview-footer\">\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"footer") || (depth0 != null ? lookupProperty(depth0,"footer") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"footer","hash":{},"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":54,"column":26}}}) : helper)))
    + "\n            </p>\n        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"email-preview-title\">\n                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":25}}}) : helper)))
    + "\n            </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"confirmation") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":59,"column":9}}})) != null ? stack1 : "");
},"useData":true});