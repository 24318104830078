<template>
  <div :id="HTMLGridID" :class="['mx-auto animate-pulse']">
    <div
      v-for="index in 12"
      :key="index"
      :class="[index % 2 === 0 ? 'h-[40rem]' : 'h-[30rem]', ' w-[250px] rounded-xl bg-gray-100']"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, nextTick } from "vue";
//Utils
import { useMasonryGrid } from "@helpers/masonry";

//Masonry
const HTMLGridID = `vue-template-catalog-magic-grid-skeleton${new Date().getTime()}`;

onMounted(() => {
  nextTick(() => {
    useMasonryGrid({
      container: `#${HTMLGridID}`,
      itemCount: 12,
    });
  });
});
</script>
