var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-action='pause' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"confirmPause")), depth0))
    + "'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"pause")), depth0))
    + "\n    </button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-action='pause' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"confirmPause")), depth0))
    + "'\n      data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"pause")), depth0))
    + "\" data-confirm-type=\"info\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"pause")), depth0))
    + "\n    </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-action='resume' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"confirmResume")), depth0))
    + "'\n      data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"resume")), depth0))
    + "\" data-confirm-type=\"info\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"resume")), depth0))
    + "\n    </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"archived") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-default' data-action='unarchive' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmUnarchive")), depth0))
    + "'\n      data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\" data-confirm-type=\"info\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\n    </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-default' data-action='archive' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmArchive")), depth0))
    + "'\n      data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"archive")), depth0))
    + "\" data-confirm-type=\"info\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"archive")), depth0))
    + "\n    </button>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='row'>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"state")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"filters"))) && lookupProperty(stack1,"state")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":57,"column":66}}}))
    + "\n              </p>\n            </div>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"startDate")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"launchDate") : depth0),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"shortDate")),{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":65,"column":64}}}))
    + "\n              </p>\n            </div>\n          </div>\n          <div class=\"row\">\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"sendTo")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":16},"end":{"line":77,"column":25}}})) != null ? stack1 : "")
    + "              </p>\n            </div>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"exclude")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":91,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n            </div>\n          </div>\n          <div class=\"row hidden\">\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"from")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"fromName") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"from") : stack1), depth0))
    + ")\n              </p>\n            </div>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replyTo")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyToName") : stack1), depth0))
    + " ("
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyTo") : stack1), depth0))
    + ")\n              </p>\n            </div>\n          </div>\n          <div class='row'>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"recipients")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"recipients") : depth0), depth0))
    + "\n              </p>\n            </div>\n            <div class=\"col-md-6\">\n              <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"totalReplies")), depth0))
    + "\n              </p>\n              <p class='field-data'>\n                <a data-action='open-replies-modal' data-type='drip'>\n                  "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"replies") : depth0)) != null ? lookupProperty(stack1,"positive") : stack1), depth0))
    + "\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"positiveReplies")), depth0))
    + ",\n                  "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"replies") : depth0)) != null ? lookupProperty(stack1,"negative") : stack1), depth0))
    + "\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"negativeReplies")), depth0))
    + "\n                </a>\n              </p>\n            </div>\n          </div>\n\n          <div class='row'>\n            <div class=\"col-md-12\">\n              <div class='flow-preview' style='background-image: url(img/flows/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"flow") : depth0), depth0))
    + ".svg)'></div>\n            </div>\n          </div>\n          <div class=\"row\">\n            <div class=\"col-md-12\">\n              <ul class='nav nav-tabs'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":145,"column":16},"end":{"line":171,"column":25}}})) != null ? stack1 : "")
    + "              </ul>\n              <div class='tab-content'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":16},"end":{"line":226,"column":25}}})) != null ? stack1 : "")
    + "              </div>\n            </div>\n          </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class='label label-outline'>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":88,"column":25}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "                -\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":20},"end":{"line":146,"column":56}}})) != null ? stack1 : "")
    + ">\n                  <a data-toggle='tab' data-target='#step"
    + alias3(alias2((data && lookupProperty(data,"index")), depth0))
    + "'>\n                    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"SCHEDULED",{"name":"is","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":20},"end":{"line":152,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"SENT",{"name":"is","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":20},"end":{"line":156,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"PAUSED",{"name":"is","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":20},"end":{"line":160,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"SKIPED",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":20},"end":{"line":164,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"FAILED",{"name":"is","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":20},"end":{"line":168,"column":27}}})) != null ? stack1 : "")
    + "                  </a>\n                </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "class='active' ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"node-state-icon fa fa-circle-o\" style=\"color: #aacc42\"\n                      title=\""
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":151,"column":29},"end":{"line":151,"column":76}}}))
    + "\"></i>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"node-state-icon fa fa-check-circle\" style=\"color: #aacc42\"\n                      title=\""
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":155,"column":29},"end":{"line":155,"column":76}}}))
    + "\"></i>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"node-state-icon fa fa-pause-circle-o\" style=\"color: #8b99d8\"\n                      title=\""
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":159,"column":29},"end":{"line":159,"column":76}}}))
    + "\"></i>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"node-state-icon fa fa-check-circle-o\" style=\"color: #aacc42\"\n                      title=\""
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":163,"column":29},"end":{"line":163,"column":76}}}))
    + "\"></i>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\"node-state-icon fa fa-times-circle\" style=\"color: #ff5722\"\n                      title=\""
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":167,"column":29},"end":{"line":167,"column":76}}}))
    + "\"></i>\n";
},"32":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='tab-pane "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":37},"end":{"line":175,"column":64}}})) != null ? stack1 : "")
    + "' id='step"
    + alias3(alias2((data && lookupProperty(data,"index")), depth0))
    + "'>\n                  <h3>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nodeStatus")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":177,"column":20},"end":{"line":177,"column":67}}}))
    + "\n                    <br>\n                    <small>\n                      "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"descriptionWithDate") : depth0), depth0)) != null ? stack1 : "")
    + ".\n                    </small>\n                  </h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canBeRescheduled") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":18},"end":{"line":190,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"SENT",{"name":"is","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":18},"end":{"line":207,"column":25}}})) != null ? stack1 : "")
    + "                  <div class='form-group'>\n                    <label>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n                    </label>\n                    <div>\n                      "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0)) != null ? stack1 : "")
    + "\n                    </div>\n                  </div>\n                  <div class='form-group'>\n                    <label>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"content")), depth0))
    + "\n                    </label>\n                    <div>\n                      "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"html") : stack1), depth0)) != null ? stack1 : "")
    + "\n                    </div>\n                  </div>\n\n                </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "active";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class='form-group'>\n                    <button class='btn btn-primary' data-action='open-reschedule-modal' data-date='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"launchDate") : depth0), depth0))
    + "'\n                      data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"reschedule")), depth0))
    + "\n                    </button>\n                  </div>\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"metrics") : depth0),{"name":"with","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":18},"end":{"line":206,"column":27}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p>\n                    <a href=\"/metrics/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"campaignId") : depths[1]), depth0))
    + "/activity\">\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"sendings")), depth0))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sent") : depth0), depth0))
    + "<br>\n                    </a>\n                    <a href=\"/metrics/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"campaignId") : depths[1]), depth0))
    + "/activity?filter.type=OPEN\">\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"opens")), depth0))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"opened") : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"openedP") : depth0), depth0))
    + "%)<br>\n                    </a>\n                    <a data-action='open-replies-modal' data-type='node' data-id='"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replies")), depth0))
    + ":\n                      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"replies") : depth0)) != null ? lookupProperty(stack1,"positive") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"positiveReplies")), depth0))
    + ",\n                      "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"replies") : depth0)) != null ? lookupProperty(stack1,"negative") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"negativeReplies")), depth0))
    + "\n                    </a>\n                  </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='drips section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'><i class='icon icon-back'></i></a>\n      "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n      <small class='lift'>\n        "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"status")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":67}}}))
    + "\n      </small>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"SENDING",{"name":"is","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"SCHEDULED",{"name":"is","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"PAUSED",{"name":"is","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"canBeArchived") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "    <button type='button' class='btn btn-default' data-action='duplicate'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n    </button>\n  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <div class='panel panel-default'>\n        <div class='panel-body panel-body-padded'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":230,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true,"useDepths":true});