<template>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      v-show="open"
      ref="notificationsMenuRef"
      class="absolute z-60 flex h-[30rem] max-h-[95vh] w-[28rem] flex-col overflow-hidden rounded bg-white shadow-xl ring-1 ring-black ring-opacity-10"
    >
      <transition
        enter-active-class="duration-100 ease-out"
        leave-active-class="duration-100 ease-in"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-from-class="transform translate-x-0 opacity-100"
        :enter-from-class="'transform opacity-0 ' + (selectedNotification ? 'translate-x-20' : '-translate-x-20')"
        :leave-to-class="'transform opacity-0 ' + (selectedNotification ? '-translate-x-20' : 'translate-x-20')"
        mode="out-in"
      >
        <div v-if="!selectedNotification" class="flex h-full flex-col divide-y">
          <div class="flex items-center justify-between py-4 pl-8 pr-6">
            <h1 class="m-0 text-base font-semibold text-gray-500">{{ t("title") }}</h1>
            <IconButton
              :label="t('markAllAsRead')"
              :disabled="allNotificationsRead"
              placement="bottom"
              size="min"
              :auto-placements="['bottom']"
              @click="markAllAsRead"
            >
              <CheckIcon
                :class="[
                  {
                    'cursor-pointer hover:text-sky-500': !allNotificationsRead,
                    'opacity-25': allNotificationsRead,
                  },
                  'h-7 w-7 stroke-[1.5] text-gray-500',
                ]"
              />
            </IconButton>
          </div>
          <div
            v-if="notifications?.length === 0 && tasks.length === 0 && !loadingNotifications"
            class="my-auto flex h-full justify-center space-x-2"
          >
            <CheckCircleIcon class="my-auto h-6 w-6 text-gray-400" />
            <span class="my-auto text-sm font-medium text-gray-500">
              {{ t("noNotifications") }}
            </span>
          </div>
          <div ref="scrollElement" class="grow overflow-y-auto">
            <div
              v-for="task in tasks"
              :key="task.id"
              class="w-full space-y-1 border-b-2 border-gray-200 px-8 py-2 text-left"
            >
              <div class="flex justify-between">
                <div class="space-y-1">
                  <span class="text-sm text-sky-600">{{ task.name }}</span>
                  <div class="flex space-x-2 text-sm text-gray-500">
                    <RefreshIcon class="h-4 w-4 text-gray-400" />
                    <span>{{ task.message }}</span>
                  </div>
                </div>
                <button
                  v-if="!isFixedTask(task) && task?.cancellable"
                  class="text-xs font-medium text-yellow-600 hover:text-yellow-500"
                  @click="() => cancelTask(task)"
                >
                  {{ t("cancelTask") }}
                </button>
              </div>
              <div v-if="!isFixedTask(task) && task?.eta > 0" class="w-full rounded bg-sky-200 shadow">
                <div class="rounded bg-sky-400 py-0.5" :style="{ width: `${task?.completed * 100}%` }" />
              </div>
              <div v-if="isFixedTask(task) && task?.progress" class="w-full rounded bg-sky-200 shadow">
                <div class="rounded bg-sky-400 py-0.5" :style="{ width: `${task.progress}%` }" />
              </div>
              <span v-if="!isFixedTask(task) && task?.eta > 0" class="text-xs text-gray-500">{{
                t("timeLeft", { minutes: Math.ceil(task?.eta / 60) })
              }}</span>
            </div>
            <button
              v-for="notification in orderedNotifications"
              :key="notification.id"
              :class="[
                'flex w-full justify-between space-x-4 border-b border-gray-200 py-3 pl-8 pr-4 text-left hover:bg-gray-100',
              ]"
              @click="() => openNotification(notification)"
            >
              <div class="space-y-1">
                <p
                  :class="[
                    {
                      'font-medium text-red-600': !notification.read && notification.type === 'TASK_FAILED',
                      'text-sky-600': !notification.read && notification.type !== 'TASK_FAILED',
                      'text-gray-400': notification.read,
                    },
                    'text-sm',
                  ]"
                >
                  {{ notification.title }}
                </p>
                <div :class="[notification.read ? 'text-gray-400' : 'text-gray-500', 'flex space-x-2 text-sm']">
                  <ExclamationIcon v-if="notification.type === 'TASK_FAILED'" class="h-5 w-5 text-gray-400" />
                  <CalendarIcon v-else class="h-5 w-5 text-gray-400" />
                  <span>{{ t("timeAgo", { time: formatDateDistanceToNow(new Date(notification.created)) }) }}</span>
                </div>
              </div>
              <ChevronRightIcon class="my-auto h-6 w-6 text-gray-400" />
            </button>
            <LoadingSpinner v-show="loadingNotifications" class="mx-auto my-4 h-5 w-5 text-sky-500" />
          </div>
        </div>
        <div v-else class="flex h-full flex-col divide-y">
          <div class="flex h-[4.25rem] items-center space-x-2 px-2">
            <div class="flex h-full flex-col py-2">
              <button class="w-5 grow rounded-full hover:bg-gray-100" @click="closeNotification">
                <ChevronLeftIcon class="h-6 w-5 text-gray-500" />
              </button>
            </div>
            <h1 class="m-0 text-base font-semibold text-gray-500">{{ selectedNotification.title }}</h1>
          </div>
          <div class="grow space-y-6 p-6 text-sm text-gray-500">
            <p>{{ selectedNotification.message }}</p>
            <div class="flex space-x-2">
              <ClockIcon class="h-5 w-5 shrink-0 text-gray-400" />
              <span class="block">
                {{ t("timeAgo", { time: formatDateDistanceToNow(new Date(selectedNotification.created)) }) }}
              </span>
            </div>

            <div v-for="(detail, index) in selectedNotification.details" :key="index" class="flex flex-col">
              <div class="flex justify-between space-x-4">
                <div class="flex shrink space-x-2">
                  <DocumentTextIcon v-if="detail.linkUrl" class="h-5 w-5 shrink-0 text-gray-400" />
                  <UserGroupIcon v-else-if="detail.linkId" class="h-5 w-5 shrink-0 text-gray-400" />
                  <InformationCircleIcon v-else class="h-5 w-5 shrink-0 text-gray-400" />
                  <span class="m-0">{{ detail.text }}</span>
                </div>
                <a
                  v-show="detail.linkUrl && differenceInDays(new Date(), new Date(selectedNotification.created)) < 7"
                  :href="detail.linkUrl"
                  class="shrink-0 text-sky-500 hover:text-sky-600"
                >
                  {{ t("downloadButton") }}
                </a>
                <p
                  v-show="detail.linkUrl && differenceInDays(new Date(), new Date(selectedNotification.created)) >= 7"
                  class="text-gray-400"
                >
                  {{ t("downloadButtonNotAvailable") }}
                </p>
                <a
                  v-show="detail.linkId && detail.linkModule === 'lists'"
                  :href="`/lists/${detail.linkId}`"
                  class="shrink-0 text-sky-500 hover:text-sky-600"
                  >{{ t("linkButton") }}</a
                >
              </div>
              <span
                v-show="detail.linkUrl && differenceInDays(new Date(), new Date(selectedNotification.created)) < 7"
                class="ml-7 mt-1 shrink-0 text-gray-400"
              >
                {{ t("availableTime", { days: 7 }, { plural: 7 }) }}
              </span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { onMounted, ref, toRaw, computed, watch, watchEffect } from "vue";

import { differenceInDays, compareDesc } from "date-fns";

// Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import IconButton from "@atoms/IconButton.vue";

// Icons
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CalendarIcon,
  ClockIcon,
  RefreshIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  InformationCircleIcon,
  UserGroupIcon,
  ExclamationIcon,
  CheckIcon,
} from "@heroicons/vue/solid";

// Utils
import { useI18n } from "vue-i18n";
import { useInfiniteScroll } from "@vueuse/core";
import { onClickOutside } from "@vueuse/core";
import { formatDateDistanceToNow } from "@helpers/formatters";

// Store
import { storeToRefs } from "pinia";
import { useNotificationStore, useSessionStore } from "@store";

// Services
import { useNotifications } from "@api/modules/notifications/notifications";

// Domain
import { isFixedTask } from "@domain/notifications";
import type { Notification, Notifications, Task } from "@domain/notifications";

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const { t } = useI18n();

const notificationsAPI = useNotifications();
const notificationStore = useNotificationStore();
const { notifications, selectedNotification, tasks, notificationNotReadCount } = storeToRefs(notificationStore);

const props = withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

const emit = defineEmits<{
  "update:notificationCount": [number];
  "update:open": [boolean];
  tasksFinished: [void];
  taskInProcess: [void];
}>();

watchEffect(() => {
  if (tasks?.value?.length > 0) {
    emit("taskInProcess");
    return;
  }
  emit("tasksFinished");
});

const orderedNotifications = computed<Notifications>(() => {
  const notificationsCopy = [...toRaw(notifications.value)];

  notificationsCopy.sort((a, b) => {
    if (a.read && !b.read) return 1;

    if (!a.read && b.read) return -1;

    return compareDesc(new Date(a.created), new Date(b.created));
  });

  return notificationsCopy;
});

const unreadNotifications = computed<Notifications>(() =>
  notifications.value.reduce<Notifications>((unread, notification) => {
    if (!notification.read) unread.push(notification);
    return unread;
  }, []),
);

const allNotificationsRead = computed(() => {
  return notifications.value.every((n) => n.read);
});

const loadingNotifications = ref(false);

const openNotification = (notification: Notification) => {
  const notificationCopy: Notification = notification;

  notificationCopy.details.sort((a, b) => {
    if ((a.linkId || a.linkUrl) && (b.linkId || b.linkUrl)) return 0;
    if ((a.linkId || a.linkUrl) && !(b.linkId || b.linkUrl)) return 1;
    return -1;
  });

  selectedNotification.value = notificationCopy;
  notificationsAPI.markNotificationAsRead({
    id: notification.id.toString(),
    isMasterUser: session.value?.isMasterUser,
  });
  selectedNotification.value.read = true;
};

const closeNotification = () => {
  selectedNotification.value = undefined;
};

watch([() => selectedNotification.value], () => {
  if (!props.open && selectedNotification.value) {
    notificationsAPI.markNotificationAsRead({
      id: selectedNotification.value.id.toString(),
      isMasterUser: session.value?.isMasterUser,
    });
    selectedNotification.value.read = true;
    emit("update:open", true);
  }
});

const markAllAsRead = async () => {
  const promises = unreadNotifications.value.map((notification) => {
    notification.read = true;
    return notificationsAPI.markNotificationAsRead({
      id: notification.id.toString(),
      isMasterUser: session.value?.isMasterUser,
    });
  });

  emit("update:open", false);
  await Promise.all(promises);
};

const fetchNotifications = async () => {
  loadingNotifications.value = true;
  await notificationStore.fetchNotifications({
    isMasterUser: session.value?.isMasterUser ?? false,
  });
  loadingNotifications.value = false;
};

watchEffect(() => {
  emit("update:notificationCount", notificationNotReadCount.value + tasks.value.length);
});

const cancelTask = async (task: Task) => {
  await notificationsAPI.cancelTask({ id: task.id.toString() });
  notificationStore.deleteTask(task.id.toString());
};

const scrollElement = ref();
useInfiniteScroll(scrollElement, fetchNotifications, { distance: 100 });

const notificationsMenuRef = ref();
onClickOutside(notificationsMenuRef, () => {
  closeNotification();
  emit("update:open", false);
});

onMounted(async () => {
  await Promise.all([fetchNotifications(), notificationStore.fetchTasks()]);
});
</script>
<i18n lang="jsonc">
{
  "es": {
    "title": "Notificaciones",
    "noNotifications": "No hay tareas para mostrar",
    "timeLeft": "Tiempo restante: {minutes} minutos",
    "timeAgo": "Hace {time}",
    "cancelTask": "Cancelar",
    "downloadButton": "Descargar",
    "downloadButtonNotAvailable": "Archivo no disponible",
    "availableTime": "Archivo disponible durante {days} dia | Archivo disponible durante {days} dias",
    "linkButton": "ver más",
    "markAllAsRead": "Marcar todas leidas"
  },
  "pt": {
    "title": "Notificações",
    "noNotifications": "Sem notificações para exibir",
    "timeLeft": "Tempo restante: {minutes} minutos",
    "timeAgo": "Há {time}",
    "cancelTask": "Cancelar",
    "downloadButton": "Baixar",
    "downloadButtonNotAvailable": "Arquivo não disponível",
    "availableTime": "Arquivo disponível por {days} dia | Arquivo disponível por {days} dias",
    "linkButton": "ver mais",
    "markAllAsRead": "Marcar todas como lidas"
  }
}
</i18n>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
