<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem]">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="https://docs.myperfit.com/articles/872" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <FormInput v-model="name" :label="t('name.label')" :error="nameError" maxlength="50" class="w-full" />
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" @click="duplicate">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

// Type
import { AxiosError } from "axios";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    id: string;
    listName: string;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const name = ref(t("name.defaultName", { name: props.listName }).slice(0, 50));
const loading = ref(false);
const nameError = ref<string | undefined>();
const duplicate = async () => {
  try {
    loading.value = true;

    await listAPI.duplicate({
      id: props.id,
      name: name.value,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.duplicate.title"),
      text: t("notifications.duplicate.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } catch (e) {
    nameError.value = (e as AxiosError).response?.data.error.validationErrors.newListName;
  } finally {
    loading.value = false;
  }
};
watch([name], () => {
  nameError.value = undefined;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Duplicar",
    "description": "Se creará una copia de la lista seleccionada. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "name": {
      "label": "Nombre de la nueva lista",
      "defaultName": "Copia de {name}"
    },
    "notifications": {
      "duplicate": {
        "title": "Duplicando lista",
        "text": "La lista se está duplicando."
      }
    }
  },
  "pt": {
    "title": "Duplicar",
    "description": "Será criada uma cópia da lista selecionada. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "name": {
      "label": "Nome da nova lista",
      "defaultName": "Cópia de {name}"
    },
    "notifications": {
      "duplicate": {
        "title": "Duplicando lista",
        "text": "A lista está sendo duplicada."
      }
    }
  }
}
</i18n>
