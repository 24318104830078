<template>
  <ModalDialog :open="open" :show-close-button="false" :without-overflow="withoutOverflow" @close="cancel">
    <div :class="[{ 'pointer-events-none': acceptLoading }, 'max-w-2xl sm:flex sm:items-start']">
      <div
        :class="{
          'bg-sky-100': severity === 'info',
          'bg-red-100': severity === 'warning',
          'bg-red-400': severity === 'critical',
        }"
        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
      >
        <InformationCircleIcon v-if="severity == 'info'" class="h-6 w-6 text-sky-500" aria-hidden="true" />
        <ExclamationIcon v-if="severity == 'warning'" class="h-6 w-6 text-red-600" aria-hidden="true" />
        <ExclamationCircleIcon v-if="severity == 'critical'" class="h-6 w-6 text-red-100" aria-hidden="true" />
      </div>
      <div class="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
        <h1 class="mt-1 text-lg font-medium text-gray-800">{{ title }}</h1>
        <p class="mt-3 text-sm text-gray-500">{{ message }}</p>
        <slot name="body" />
        <FormCheckBox
          v-if="dismissText"
          :model-value="dismissValue"
          :disabled="false"
          :label="dismissText"
          class="mt-3"
          @update:model-value="onToggleDismissValue"
        />
      </div>
    </div>
    <template #buttons>
      <SimpleButton
        v-if="acceptText"
        :disabled="acceptLoading || (checkRequired && !dismissValue)"
        :loading="acceptLoading"
        :theme="themeConfirmButton"
        @click="accept"
      >
        <template v-if="!!$slots.acceptButtonLeading" #leading>
          <slot name="acceptButtonLeading" />
        </template>

        {{ acceptText }}
      </SimpleButton>
      <SimpleButton :disabled="acceptLoading" theme="white" class="focus:ring-gray-300" @click="cancel">
        {{ cancelText }}
      </SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import SimpleButton from "@atoms/SimpleButton.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";
import { InformationCircleIcon, ExclamationIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";

export type SeverityType = "info" | "warning" | "critical";
export interface AcceptDetails {
  dismissValue?: boolean;
}

export interface ConfirmationModal {
  open: boolean;
  severity: SeverityType;
  title: string;
  message?: string;
  dismissText?: string;
  dismissValue?: boolean;
  checkRequired?: boolean;
  acceptText?: string;
  acceptLoading?: boolean;
  cancelText: string;
  withoutOverflow?: boolean;
}

const props = withDefaults(defineProps<ConfirmationModal>(), {
  open: false,
  severity: "info",
  title: "",
  message: "",
  dismissText: "",
  dismissValue: false,
  checkRequired: false,
  acceptText: "",
  acceptLoading: false,
  cancelText: "",
  withoutOverflow: false,
});

// Emits
const emit = defineEmits<{
  (e: "update:dismissValue", value: boolean): void;
  (e: "cancel"): void;
  (e: "accept", value: AcceptDetails): void;
}>();

const onToggleDismissValue = (value: boolean) => emit("update:dismissValue", value);

const themeConfirmButton = computed<string>(() => {
  if (props.severity === "info" && !(props.checkRequired && !props.dismissValue)) return "primary";
  if ((props.severity === "warning" || props.severity === "critical") && !(props.checkRequired && !props.dismissValue))
    return "danger";
  return "";
});

const cancel = () => {
  if (props.acceptLoading) return;
  emit("cancel");
};
const accept = () => {
  if (props.acceptLoading) return;
  const acceptDetails: AcceptDetails = {};
  if (props.dismissText) acceptDetails.dismissValue = props.dismissValue;
  emit("accept", acceptDetails);
};
</script>
