<template>
  <a
    ref="perfitIconLinkElement"
    href="/home"
    :class="[
      current ? 'bg-gray-200 text-sky-400' : 'text-gray-500 hover:text-sky-400',
      'group flex w-fit justify-center rounded-md px-1 py-1 hxl:px-2 hxl:py-2'
    ]"
    data-intercom-target="nav-home"
  >
    <Perfit
      :default-colors="!perfitIconIsHovered"
      class="h-6 w-6 shrink-0 transform duration-100 group-hover:scale-110 group-hover:text-sky-400"
    />
  </a>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import Perfit from "@tokens/icons/Perfit.vue";

import { useTooltip } from "@composables/tooltips";
import { useElementHover } from "@vueuse/core";

withDefaults(
  defineProps<{
    current?: boolean;
  }>(),
  {
    current: false
  }
);

const perfitIconLinkElement = ref();
const perfitIconIsHovered = useElementHover(perfitIconLinkElement);

const setTooltip = () => {
  useTooltip(perfitIconLinkElement, "Home", {
    placement: "right",
    zIndex: 9999,
    theme: "light",
    arrow: false,
    offset: [0, 17],
    delay: [0, 0]
  });
};
onMounted(() => {
  setTooltip();
});
</script>
