export type FieldType = "TEXT" | "INT" | "DATE";

export interface Field {
  custom: boolean;
  format: FieldType;
  id: number;
  name: string;
  readOnly: boolean;
  tag: string;
  replaceCode: string;
}

export type Fields = Array<Field>;

export const getFieldName = (fields: Fields, search: AtLeastOne<Pick<Field, "id" | "tag">>) =>
  fields.find((field) => field.id === search?.id || field.tag === search?.tag)?.name;
