import { Collection } from "@/core/magnet";
import DripsModel from "./model";

export default Collection.extend({
  url: "drips",
  model: DripsModel,
  parse: function (response) {
    return response.data;
  },
  additionals: {
    state: [
      { id: "SCHEDULED" },
      { id: "PAUSED" },
      { id: "SENT" },
      { id: "SENDING" },
    ],
    flows: function () {
      return $.get("drips/flows");
    },
  },
  filters: {
    tags: {
      key: "tags",
      icon: "pi-filter-tag",
      name: function () {
        return window.lang.tags.plural;
      },
      source: function () {
        return $.get("drips/tags", { limit: 1000 });
      },
      parser: function (item) {
        return {
          id: item,
          title: item,
          keywords: item,
          params: {
            key: "filter.tags",
            value: item,
          },
        };
      },
    },
    state: {
      key: "state",
      icon: "pi-filter-state",
      remove: ["filter.state", "filter.archived"],
      name: function () {
        return window.lang.status;
      },
      source: function () {
        var data = [
          { id: "DRAFT" },
          { id: "SENT" },
          { id: "SCHEDULED" },
          { id: "SENDING" },
          { id: "PAUSED" },
          { id: "ARCHIVED" },
        ];
        return Promise.resolve({ data: data });
      },
      parser: function (item) {
        var translated = window.lang.drips.filters.state[item.id];
        return {
          id: item.id,
          title: translated,
          keywords: translated,
          params: {
            key: item.id === "ARCHIVED" ? "filter.archived" : "filter.state",
            value: item.id === "ARCHIVED" ? true : item.id,
            remove: item.id === "ARCHIVED" ? "filter.state" : "filter.archived",
          },
        };
      },
    },
  },

  // Exports drips replies
  replies: function (data) {
    return $.get("drips/replies", data);
  },

  // Gets drip preview
  create: function (data) {
    return $.ajax({
      url: "drips",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },
});
