var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\n                            <input type='hidden' name='listIds[]' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":101},"end":{"line":33,"column":131}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog'>\n    <form data-form='create-task' action='lists/intersect' data-feedback='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"intersected")), depth0))
    + "'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"intersect")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <p>\n                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"intersectLegend")), depth0)) != null ? stack1 : "")
    + "\n                    </p>\n                    <ul class='default-list' style=\"max-height: 200px; overflow-y: auto;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":25,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                    <hr />\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"nameOfNewList")), depth0))
    + "\n                        </label>\n                        <input class='form-control' name='newListName' type='text'\n                            value='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"intersectOf")), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":68},"end":{"line":33,"column":140}}})) != null ? stack1 : "")
    + "'\n                            data-validate='required'>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});