<template>
  <div class="max-w- mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
      </p>
    </div>

    <AlertBox v-show="passwordForceTiendanubeInvalid" theme="info" class="mt-4">
      {{ t("mustLoginWithTiendanube") }}
    </AlertBox>

    <div v-if="passwordForceTiendanubeInvalid" class="mt-4 flex space-x-4">
      <SimpleButton
        type="button"
        theme="white"
        :class="[{ 'pointer-events-none select-none': submitting }, 'my-auto w-full']"
        @click="goToLogin"
      >
        <template #leading>
          <ChevronLeftIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        {{ t("goBack") }}
      </SimpleButton>
      <TiendanubeButton />
    </div>
    <div v-else class="mt-5">
      <form @submit.prevent="recoverPassword">
        <div class="mt-6">
          <FormInput
            :model-value="recoverEmail"
            :label="t('emailLabel')"
            :placeholder="t('emailPlaceholder')"
            :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
            autocomplete="email"
            @update:model-value="updateRecoverEmail"
            @focus="clearEmailValidation"
            @input="clearEmailValidation"
          />
        </div>

        <div class="mt-6">
          <FormInput
            v-show="showAccountRequired"
            v-model="recoverAccount"
            :label="t('accountLabel')"
            :placeholder="t('accountPlaceholder')"
            :error="v$.account.$error ? getAccountErrorMessage(v$.account.$errors) : undefined"
            autocomplete="email"
            @focus="clearAccountValidation"
            @input="clearAccountValidation"
          />
        </div>

        <div class="mt-6 flex flex-col-reverse gap-4 sm:flex-row">
          <SimpleButton
            type="button"
            theme="white"
            :class="[{ 'pointer-events-none select-none': submitting }, 'my-auto w-full']"
            @click="goToLogin"
          >
            <template #leading>
              <ChevronLeftIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </template>
            {{ t("goBack") }}
          </SimpleButton>
          <SimpleButton
            type="submit"
            :loading="submitting"
            :class="[{ 'pointer-events-none select-none': submitting }, 'my-auto w-full']"
          >
            <template #leading>
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </template>
            {{ t("recoverPassword") }}
          </SimpleButton>
        </div>

        <div class="mt-5 text-center text-sm leading-6">
          <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
            t("loginProblems")
          }}</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { validateUserFormat } from "../authentication";

// components
import TiendanubeButton from "./TiendanubeButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icons
import { CheckIcon, ChevronLeftIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import type { ErrorObject } from "@vuelidate/core";

// Application
import { useAuthenticationApp } from "@application";

// I18n
import { useI18n } from "vue-i18n";

const authApp = useAuthenticationApp();

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    email: string;
  }>(),
  {
    email: "",
  },
);

const emit = defineEmits<{
  goToLogin: [void];
  sent: [void];
  helpArticle: [void];
  "update:email": [string];
}>();

const goToLogin = () => {
  emit("goToLogin");
};

const goToSent = () => {
  emit("sent");
};

const showAccountRequired = ref(false);

const recoverEmail = ref(props.email);
const updateRecoverEmail = (value: string) => {
  recoverEmail.value = value;
  emit("update:email", value);
  showAccountRequired.value = false;
  accountForceInvalid.value = false;
};
const recoverAccount = ref();
const fields = computed(() => ({
  email: recoverEmail.value,
  account: recoverAccount.value,
}));

// Validation
const emailForceInvalid = ref(false);
const emailNotFoundError = ref(false);
const accountForceInvalid = ref(false);

const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  if (emailNotFoundError.value) return t("notFoundEmail");
  return t("invalidEmail");
};

const getAccountErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidAccount");
};

const customEmailValidation = (value: string) => {
  const valid = validateUserFormat(value);

  return valid && !emailNotFoundError.value && !emailForceInvalid.value;
};

const customRequiredAccountValidation = () => {
  if (accountForceInvalid.value) return false;

  if (showAccountRequired.value) return recoverAccount.value.length > 0;

  return true;
};

const passwordForceTiendanubeInvalid = ref(false);
const setPasswordTiendanubeInvalid = () => {
  passwordForceTiendanubeInvalid.value = true;
};

const setEmailInvalid = () => {
  emailForceInvalid.value = true;
  v$.value.email.$touch();
};

const setEmailNotFound = () => {
  emailNotFoundError.value = true;
  v$.value.email.$touch();
};

const setAccountInvalid = () => {
  accountForceInvalid.value = true;
  v$.value.account.$touch();
};

const clearEmailValidation = () => {
  emailForceInvalid.value = false;
  emailNotFoundError.value = false;
  v$.value.email.$reset();
};
const clearAccountValidation = () => {
  accountForceInvalid.value = false;
  v$.value.account.$reset();
};

const validationRules = {
  email: { required, customEmailValidation },
  account: { customRequiredAccountValidation },
};

const v$ = useVuelidate(validationRules, fields);

const submitting = ref(false);
const recoverPassword = async () => {
  try {
    // Fields validation
    const validation = await v$.value.$validate();
    if (!validation) return;

    submitting.value = true;

    const res = await authApp.resetPassword({
      user: recoverEmail.value,
      account: recoverAccount.value,
    });

    if (res.isErr() && res.error.type === "VALIDATION_ERROR") {
      if (res.error.invalidFields?.email === "storeadmin") {
        return setPasswordTiendanubeInvalid();
      }
      showAccountRequired.value = false;
      setEmailInvalid();
      return;
    }

    if (res.isErr() && res.error.type === "ACCOUNT_REQUIRED") {
      clearAccountValidation();
      showAccountRequired.value = true;
      return;
    }

    if (res.isErr() && res.error.type === "UNAUTHORIZED" && !showAccountRequired.value) {
      setEmailNotFound();
      return;
    }

    if (res.isErr() && res.error.type === "UNAUTHORIZED" && showAccountRequired.value) {
      setAccountInvalid();
      return;
    }

    if (res.isOk()) {
      goToSent();
      return;
    }
  } catch (e) {
    // No handler
  } finally {
    submitting.value = false;
  }
};

const openHelpArticle = () => {
  emit("helpArticle");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Recuperar contraseña",
    "subtitle": "Ingresa tu email y te enviaremos un enlace para actualizar la contraseña de tu cuenta.",
    "loginProblems": "¿Problemas para ingresar?",
    "fieldRequired": "Este campo es requerido",
    "emailLabel": "Email",
    "emailPlaceholder": "Ingresa tu email",
    "accountLabel": "Cuenta",
    "accountPlaceholder": "Ingresa el nombre de tu cuenta",
    "goBack": "Volver",
    "recoverPassword": "Recuperar contraseña",
    "invalidEmail": "El email ingresado es inválido",
    "notFoundEmail": "El email ingresado no existe",
    "invalidAccount": "La cuenta ingresada es inválida",
    "mustLoginWithTiendanube": "El email ingresado está asociado a una cuenta vinculada con Tiendanube. Inicia sesión a través de Tiendanube para continuar."
  },
  "pt": {
    "title": "Recuperar senha",
    "subtitle": "Insira seu e-mail e enviaremos um link para atualizar a senha da sua conta.",
    "loginProblems": "Problemas ao entrar?",
    "fieldRequired": "Este campo é obrigatório",
    "emailLabel": "E-mail",
    "emailPlaceholder": "Insira seu e-mail",
    "accountLabel": "Conta",
    "accountPlaceholder": "Insira o nome de sua conta",
    "goBack": "Voltar",
    "recoverPassword": "Recuperar senha",
    "invalidEmail": "O e-mail inserido é inválido",
    "notFoundEmail": "O e-mail inserido não existe",
    "invalidAccount": "A conta inserida é inválida",
    "mustLoginWithTiendanube": "O e-mail inserido está associado a uma conta vinculada ao Nuvemshop. Faça login através do Nuvemshop para continuar."
  }
}
</i18n>
