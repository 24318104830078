<template>
  <ModalDialog :title="t('title')" :open="isOpen" @close="close">
    <div class="w-[36rem]">
      <form class="space-y-6" @submit.prevent="submit">
        <FormInput
          id="email"
          v-model.lowercase.trim="email"
          :label="t('email.label')"
          :placeholder="t('email.placeholder')"
          :hint="t('email.hint')"
          :error="v$.email.$errors[0]?.$message"
        />

        <FormInput
          id="subject"
          v-model.trim="subject"
          :label="t('subject.label')"
          :placeholder="t('subject.placeholder')"
          :error="v$.subject.$errors[0]?.$message"
        />

        <FormTextArea
          id="message"
          v-model="message"
          rows="10"
          :label="t('message.label')"
          :error="v$.message.$errors[0]?.$message"
        />
        <input type="submit" class="hidden" />
      </form>
      <AlertBox v-if="cc" class="mt-4">{{ t("sendCC", { cc }) }}</AlertBox>
    </div>
    <template #buttons>
      <SimpleButton :loading="isSending" @click="submit">
        {{ t("sendInstructions") }}
        <template #leading><MailIcon /></template
      ></SimpleButton>

      <SimpleButton theme="white" @click="close">{{ t("cancel") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { PropType, reactive, ref, toRefs, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { MailIcon } from "@heroicons/vue/solid";

import { required, email, maxLength } from "@helpers/validators";
import { useNotifications } from "@composables/notifications";
import { useSenders } from "@api/modules/senders/senders";
import { Sender, DNSRecord } from "@/vue/types/Senders";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FormInput from "@molecules/FormInput.vue";
import FormTextArea from "@molecules/FormTextArea.vue";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Helpers
import Intercom from "@helpers/intercom";

export default {
  name: "SenderEmailInstructionsModal",
  components: { SimpleButton, ModalDialog, FormInput, FormTextArea, MailIcon, AlertBox },

  props: {
    sender: {
      type: Object as PropType<Sender>,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  emits: ["close", "instructionsSent"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { notify } = useNotifications();

    let records = "";
    const recordText = (record: DNSRecord) =>
      `Type: ${record.type.toUpperCase()}\nName: ${record.name}\nValue: ${record.value}\n\n`;

    if (props.sender.auth.dkim.result !== "PASS") {
      records += recordText(props.sender.auth.dkim.record);
    }
    if (props.sender.auth.return_path.result !== "PASS") {
      records += recordText(props.sender.auth.return_path.record);
    }
    if (props.sender.auth.dmarc.result === "UNSET") {
      records += recordText(props.sender.auth.dmarc.record);
    }

    const sessionStore = useSessionStore();
    const { session } = storeToRefs(sessionStore);

    const initialState = {
      email: "",
      cc: session.value?.isMasterUser ? "" : session.value?.user.email,
      subject: t("emailSubject", { domain: props.sender.domain }),
      message: t("emailMessage", { domain: props.sender.domain, records })
    };
    const state = reactive({ ...initialState });

    const rules = {
      subject: {
        required: required(t("subject.errors.required")),
        maxLength: maxLength(150, t("subject.errors.maxLength"))
      },
      email: {
        required: required(t("email.errors.required")),
        email: email(t("email.errors.email"))
      },
      message: {
        required: required(t("message.errors.required")),
        maxLength: maxLength(1000, t("message.errors.maxLength"))
      }
    };
    const v$ = useVuelidate(rules, state); // v$ es un Ref

    const resetForm = () => {
      Object.assign(state, initialState);
      v$.value.$reset();
    };

    watch(
      () => props.isOpen,
      (value, prev) => {
        if (value && !prev) {
          resetForm();
        }
      }
    );

    const isSending = ref(false);
    const sendersAPI = useSenders();

    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;

      if (state.email.includes("@tiendanube") || state.email.includes("@nuvemshop")) {
        Intercom.showNewMessage(state.message);

        emit("instructionsSent");
        return;
      }

      try {
        isSending.value = true;
        await sendersAPI.sendInstructions({
          id: props.sender.id,
          to: state.email,
          cc: state.cc || undefined,
          subject: state.subject,
          message: state.message
        });
        isSending.value = false;

        notify({
          title: t("notification.title"),
          text: t("notification.text", { email: state.email })
        });
        emit("instructionsSent");
      } catch (err) {
        console.log(err);
      }
    };

    const close = () => {
      emit("close");
    };

    return { ...toRefs(state), t, v$, close, submit, isSending };
  }
};
</script>

<i18n>
{
  "es": {
    "title":"Enviar instrucciones",
    "sendInstructions": "Enviar instrucciones",
    "email": {
      "label": "Email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "La dirección de email de la persona que tenga acceso a modificar el dominio.",
      "errors": {
        "required": "Debes indicar la dirección de email.",
        "email": "Debes indicar una dirección de email válida."
      }
    },
    "subject": {
      "label": "Asunto",
      "placeholder": "Escribe el asunto aquí",
      "errors": {
        "required": "Debes indicar un asunto.",
        "maxLength": "La longitud máxima para el asunto es de 150 caracteres."
      }
    },
    "message": {
      "label": "Mensaje",
      "errors": {
        "required": "Debes indicar un mensaje.",
        "maxLength": "La longitud máxima para el mensaje es de 1000 caracteres."
      }
    },
    "emailSubject": "Cambio requerido en el dominio {domain} para envíar con Perfit",
    "emailMessage": "Hola!\n\nQuisiéramos configurar el dominio {domain} para poder enviar nuestras comunicaciones a través de Perfit.\n\nNecesitamos de tu ayuda para crear estos nuevos registros en el DNS:\n\n{records}\nSi tienes alguna duda, por favor contacta a soporte{'@'}myperfit.com, respondiendo a este correo.\n\nTambién puedes visitar el centro de ayuda en docs.myperfit.com para obtener más información.\n\nMuchas gracias!\n",
    "notification": {
      "title": "Email enviado con éxito",
      "text": "Las instrucciones fueron enviadas a {email}."
    },
    "sendCC": "Enviaremos una copia del correo a {cc}."
  },
  "pt": {
    "title": "Enviar instruções",
    "sendInstructions": "Enviar instruções",
    "email": {
      "label": "Email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Endereço de email da pessoa que tenha acesso para modificar o domínio.",
      "errors": {
        "required": "Você deve indicar um endereço de email.",
        "email": "Você deve indicar um endereço de email válido."
      }
    },
    "subject": {
      "label": "Assunto",
      "placeholder": "Escreva aquí o assunto",
      "errors": {
        "required": "Você deve indicar um assunto.",
        "maxLength": "O tamanho máximo para o assunto é de 150 caracteres."
      }
    },
    "message": {
      "label": "Mensagem",
      "errors": {
        "required": "Você deve indicar uma mensagem.",
        "maxLength": "O tamanho máximo para a mensagem é de 1000 caracteres."
      }
    },
    "emailSubject": "Modificação necessária no domínio {domain} para envíar com Perfit",
    "emailMessage": "Oi!\n\nPrecisamos configurar o domínio {domain} para poder enviar através da Perfit.\n\nNecessitamos da tua ajuda para criar estes novos registros no DNS:\n\n{records}\nSe tiver alguma dúvida, por favor entre em contato com o suporte{'@'}myperfit.com, respondendo a esse email.\n\nVocê também pode visitar nosso centro de ajuda em docs.myperfit.com para obter mais informações.\n\nMuito obrigado!\n",
    "notification": {
      "title": "Email enviado com sucesso",
      "text": "As instruções foram enviadas para {email}."
    },
    "sendCC": "Enviaremos uma cópia do email para {cc}."
  }
}
</i18n>
