import { useI18n } from "vue-i18n";

// Types
import type { Columns, Rows, Row } from "@molecules/Table";
import type { TemplateList, TemplateItem } from "@domain/Templates";

// I18n
import { getI18nInstance } from "@/vue/locales/i18n";
import esMessages from "./i18n/templateCatalogTable.es.json";
import ptMessages from "./i18n/templateCatalogTable.pt.json";

const { mergeLocaleMessage } = getI18nInstance().global;

mergeLocaleMessage("es", esMessages);
mergeLocaleMessage("pt", ptMessages);

export const getColumns = <DataType = unknown>(
  show: { rowOptions: boolean } = {
    rowOptions: true,
  }
): Columns<string, string, DataType> => {
  const { t } = useI18n();
  const columns: Columns<string, string, DataType> = [
    {
      id: "templateName",
      text: t("columns.templateName"),
      headerTextAlign: "left",
    },
    {
      id: "templateUpdatedDate",
      text: t("columns.templateUpdatedDate"),
      // sortModes: ["ASC"],
      headerTextAlign: "right",
      textAlign: "right",
    },
  ];

  if (show.rowOptions) {
    columns.push({
      id: "rowOptions",
      headerWidthRem: 3,
    });
  }
  return columns;
};

export const templateItemToRow = (template: TemplateItem): Row<string, string, TemplateItem> => {
  return {
    id: template.id,
    data: template,
  };
};

export const templatesListToRows = (templates: TemplateList): Rows<string, string, TemplateItem> => {
  return templates.map((template) => ({
    id: template.id,
    data: template,
  }));
};
