// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Items } from "@templates/PageTemplate";
import type { ExternalTemplateGalleryTabs } from "./externalTemplateGallery.types";

export const getTabs = (): Items<ExternalTemplateGalleryTabs> => {
  const { t } = useI18n();
  return [
    {
      key: "templateGallery",
      value: t("tabs.publicGallery"),
      content: {
        dataIntercomTarget: "TemplateGalleryNavPublic",
      },
    },
    {
      key: "myTemplates",
      value: t("tabs.myTemplates"),
      content: {
        dataIntercomTarget: "TemplateGalleryNavPrivate",
      },
    },
  ];
};
