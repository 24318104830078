<template>
  <div class="space-y-5">
    <AlertBox>En algunos casos el usuario deberá cerrar sesión y volver a ingresar para ver los cambios.</AlertBox>
    <div class="max-h-[70vh] space-y-10 overflow-y-auto px-4">
      <FormSwitch
        v-model="featuresConfig.USE_TIENDANUBE_THEME.value"
        label="Tiendanube theme"
        description="Utilizar el theme de colores de tiendanube."
        :loading="featuresConfig.USE_TIENDANUBE_THEME.loading"
        :disabled="featuresConfig.USE_TIENDANUBE_THEME.loading"
      />
      <hr />

      <FormSwitch
        v-model="featuresConfig.ALLOW_USE_GENERIC_DOMAIN.value"
        label="Remitentes"
        description="Permitir envío de campañas con remitentes no OK o PUBLIC."
        :loading="featuresConfig.ALLOW_USE_GENERIC_DOMAIN.loading"
        :disabled="featuresConfig.ALLOW_USE_GENERIC_DOMAIN.loading"
      />
      <hr />
      <FormSwitch
        v-model="featuresConfig.ALLOW_HOME_ACCESS.value"
        label="Home"
        description="Dar permiso a esta cuenta para acceder a la nueva home"
        :loading="featuresConfig.ALLOW_HOME_ACCESS.loading"
        :disabled="featuresConfig.ALLOW_HOME_ACCESS.loading"
      />
      <hr />
      <FormSwitch
        v-model="featuresConfig.USE_NEW_LAYOUT.value"
        label="Nueva interfaz"
        description="Habilitar la nueva interfaz. (Es necesario cerrar sesión y volver a ingresar)"
        :loading="featuresConfig.USE_NEW_LAYOUT.loading"
        :disabled="featuresConfig.USE_NEW_LAYOUT.loading"
      />
      <FormSwitch
        v-model="featuresConfig.ALLOW_TOGGLE_LAYOUT.value"
        label="Permitir cambio de interfaz"
        description="Habilitar el botón de cambio de interfaz."
        :loading="featuresConfig.ALLOW_TOGGLE_LAYOUT.loading"
        :disabled="featuresConfig.ALLOW_TOGGLE_LAYOUT.loading"
      />
      <hr />
      <FormSwitch
        v-model="featuresConfig.USE_TOKENIZE.value"
        label="Tokenizar tarjetas"
        description="Cobrar en forma automática mediante tarjetas de crédito tokenizadas."
        :loading="featuresConfig.USE_TOKENIZE.loading"
        :disabled="featuresConfig.USE_TOKENIZE.loading"
      />
      <FormSwitch
        v-model="featuresConfig.USE_TOKENIZE_MP.value"
        label="Tokenizar tarjetas MP"
        description="Cobrar en forma automática usando MercadoPago. Desactivar opción para usar PagoUno."
        :disabled="featuresConfig.USE_TOKENIZE_MP.loading || !featuresConfig.USE_TOKENIZE.value"
        :loading="featuresConfig.USE_TOKENIZE_MP.loading"
      />
      <FormSwitch
        v-show="false"
        v-model="featuresConfig.DRIP_ENABLED.value"
        label="Módulo Drips"
        description="Activar acceso a módulo de campañas Drip."
        :loading="featuresConfig.DRIP_ENABLED.loading"
        :disabled="featuresConfig.DRIP_ENABLED.loading"
      />
      <FormSwitch
        v-model="featuresConfig.ALWAYS_SHOW_OPTIN_RULE_CONFIG.value"
        label="Siempre mostrar la configuración de reglas de formularios optin"
        description="Útil para casos en los que la cuenta implemente en forma manual el script js en su sitio."
        :loading="featuresConfig.ALWAYS_SHOW_OPTIN_RULE_CONFIG.loading"
        :disabled="featuresConfig.ALWAYS_SHOW_OPTIN_RULE_CONFIG.loading"
      />
      <!-- <FormSwitch
        v-model="featuresConfig.ALLOW_DISABLE_EXCLUDE.value"
        label="Envío a sin interacción"
        description="Permitir que el usuario pueda desactivar la opción de 'excluir contactos sin interacción'."
        :loading="featuresConfig.ALLOW_DISABLE_EXCLUDE.loading"
        :disabled="featuresConfig.ALLOW_DISABLE_EXCLUDE.loading"
      /> -->
      <!-- <FormSwitch
        v-model="featuresConfig.PERFIT_AD.value"
        label="Certificado por Perfit (Eddie)"
        description="Mostrar 'Certificado por Perfit' en el footer de las campañas."
        :loading="featuresConfig.PERFIT_AD.loading"
        :disabled="featuresConfig.PERFIT_AD.loading"
      /> -->

      <hr />
      <FormSwitch
        v-model="featuresConfig.UNLAYER_OPTIONAL.value"
        label="Selección de editor"
        description="Preguntar qué editor se quiere usar al crear una nueva campañas."
        :loading="featuresConfig.UNLAYER_OPTIONAL.loading"
        :disabled="featuresConfig.UNLAYER_OPTIONAL.loading"
      />
      <FormSwitch
        v-model="featuresConfig.UNLAYER_DEFAULT.value"
        label="Nuevo editor por defecto"
        description="Usar siempre el nuevo editor drag & drop por defecto al crear una nueva campaña."
        :loading="featuresConfig.UNLAYER_DEFAULT.loading"
        :disabled="featuresConfig.UNLAYER_DEFAULT.loading"
      />
      <FormSwitch
        v-model="featuresConfig.USE_NEXT_UNLAYER_VERSION.value"
        label="Unlayer next"
        description="Utilizar la versión next de Unlayer (Ultimas features)"
        :loading="featuresConfig.USE_NEXT_UNLAYER_VERSION.loading"
        :disabled="featuresConfig.USE_NEXT_UNLAYER_VERSION.loading"
      />
      <FormSwitch
        v-model="featuresConfig.PERFIT_FOOTER_AD.value"
        label="Enviado usando Perfit"
        description="Mostrar por defecto esta leyenda en los nuevos footers (cuentas pagas pueden desactivarlo)."
        :loading="featuresConfig.PERFIT_FOOTER_AD.loading"
        :disabled="featuresConfig.PERFIT_FOOTER_AD.loading"
      />
      <FormSwitch
        v-model="featuresConfig.NO_FORCE_CAMPAIGN_FOOTER.value"
        label="No forzar footer en editor"
        description="Permitir no incluir el footer de desuscripción estandar para poder usar un footer personalizado."
        :loading="featuresConfig.NO_FORCE_CAMPAIGN_FOOTER.loading"
        :disabled="featuresConfig.NO_FORCE_CAMPAIGN_FOOTER.loading"
      />
      <FormSwitch
        v-model="featuresConfig.UNLAYER_ALLOW_HTML.value"
        label="Importar HTML"
        description="Permitir subir un template en formato HTML desde archivo."
        :loading="featuresConfig.UNLAYER_ALLOW_HTML.loading"
        :disabled="featuresConfig.UNLAYER_ALLOW_HTML.loading"
      />
      <FormSwitch
        v-model="featuresConfig.ALLOW_IMPORT_EXPORT_UNLAYER.value"
        label="Permitir importar/exportar plantillas en JSON"
        description="Habilita las opciones de importar/exportar en formato editable de unlayer."
        :loading="featuresConfig.ALLOW_IMPORT_EXPORT_UNLAYER.loading"
        :disabled="featuresConfig.ALLOW_IMPORT_EXPORT_UNLAYER.loading"
      />
      <FormSwitch
        v-model="featuresConfig.HIDE_LEGACY_TEMPLATES.value"
        label="Ocultar diseños Eddie"
        description="Ocultar secciones de Diseños e Imágenes usados por Eddie"
        :loading="featuresConfig.HIDE_LEGACY_TEMPLATES.loading"
        :disabled="featuresConfig.HIDE_LEGACY_TEMPLATES.loading"
      />
      <hr />
      <FormSwitch
        v-model="featuresConfig.EXPORT_CONTACTS_HIDDEN.value"
        label="Ocultar export"
        description="No permitir exportación de contactos a todos los usuarios de esta cuenta."
        :loading="featuresConfig.EXPORT_CONTACTS_HIDDEN.loading"
        :disabled="featuresConfig.EXPORT_CONTACTS_HIDDEN.loading"
      />
      <FormSwitch
        v-model="featuresConfig.IMPORT_BLOCKED.value"
        label="Bloquear import"
        description="No permitir importación de contactos a todos los usuarios de esta cuenta."
        :loading="featuresConfig.IMPORT_BLOCKED.loading"
        :disabled="featuresConfig.IMPORT_BLOCKED.loading"
      />
      <hr />
      <FormSwitch
        v-model="featuresConfig.FIRST_LOGIN.value"
        label="Modal primer login"
        description="Mostrar el modal de primer log in para completar datos de cuenta"
        :loading="featuresConfig.FIRST_LOGIN.loading"
        :disabled="featuresConfig.FIRST_LOGIN.loading"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, Ref, ref, watch } from "vue";

//lodash
import { cloneDeep } from "lodash";

//Components
import FormSwitch from "@molecules/FormSwitch.vue";
import AlertBox from "@atoms/AlertBox.vue";

//API
import { useAccountFeatures, useAccountDrips } from "@api/modules/account";
import type { AccountFeatures } from "@/vue/types/account";

//Services
import { useNotifications } from "@composables/notifications";

//Props
const props = withDefaults(
  defineProps<{
    account?: string;
  }>(),
  {
    account: undefined
  }
);

const { notify } = useNotifications();

const putFeature = useAccountFeatures().put;
const postDrips = useAccountDrips().post;

const accountFeatures: Ref<AccountFeatures | undefined> = ref(undefined);

interface IFeaturesConfig {
  USE_TIENDANUBE_THEME: {
    value: boolean | undefined;
    loading: boolean;
  };

  USE_NEXT_UNLAYER_VERSION: {
    value: boolean | undefined;
    loading: boolean;
  };
  ALLOW_HOME_ACCESS: {
    value: boolean | undefined;
    loading: boolean;
  };
  USE_NEW_LAYOUT: {
    value: boolean | undefined;
    loading: boolean;
  };
  ALLOW_TOGGLE_LAYOUT: {
    value: boolean | undefined;
    loading: boolean;
  };
  USE_TOKENIZE: {
    value: boolean | undefined;
    loading: boolean;
  };
  USE_TOKENIZE_MP: {
    value: boolean | undefined;
    loading: boolean;
  };
  DRIP_ENABLED: {
    value: boolean | undefined;
    loading: boolean;
  };
  // ALLOW_DISABLE_EXCLUDE: {
  //   value: boolean | undefined;
  //   loading: boolean;
  // };
  PERFIT_AD: {
    value: boolean | undefined;
    loading: boolean;
  };
  PERFIT_FOOTER_AD: {
    value: boolean | undefined;
    loading: boolean;
  };
  EXPORT_CONTACTS_HIDDEN: {
    value: boolean | undefined;
    loading: boolean;
  };
  IMPORT_BLOCKED: {
    value: boolean | undefined;
    loading: boolean;
  };
  UNLAYER_OPTIONAL: {
    value: boolean | undefined;
    loading: boolean;
  };
  UNLAYER_DEFAULT: {
    value: boolean | undefined;
    loading: boolean;
  };
  NO_FORCE_CAMPAIGN_FOOTER: {
    value: boolean | undefined;
    loading: boolean;
  };
  ALWAYS_SHOW_OPTIN_RULE_CONFIG: {
    value: boolean | undefined;
    loading: boolean;
  };
  ALLOW_IMPORT_EXPORT_UNLAYER: {
    value: boolean | undefined;
    loading: boolean;
  };
  HIDE_LEGACY_TEMPLATES: {
    value: boolean | undefined;
    loading: boolean;
  };
  UNLAYER_ALLOW_HTML: {
    value: boolean | undefined;
    loading: boolean;
  };
  FIRST_LOGIN: {
    value: boolean | undefined;
    loading: boolean;
  };
  ALLOW_USE_GENERIC_DOMAIN: {
    value: boolean | undefined;
    loading: boolean;
  };
}

const featuresConfig: IFeaturesConfig = reactive({
  USE_TIENDANUBE_THEME: {
    value: undefined,
    loading: false
  },
  USE_NEXT_UNLAYER_VERSION: {
    value: undefined,
    loading: false
  },
  ALLOW_HOME_ACCESS: {
    value: undefined,
    loading: false
  },
  USE_NEW_LAYOUT: {
    value: undefined,
    loading: false
  },
  ALLOW_TOGGLE_LAYOUT: {
    value: undefined,
    loading: false
  },
  USE_TOKENIZE: {
    value: undefined,
    loading: false
  },
  USE_TOKENIZE_MP: {
    value: undefined,
    loading: false
  },
  DRIP_ENABLED: {
    value: undefined,
    loading: false
  },
  // ALLOW_DISABLE_EXCLUDE: {
  //   value: undefined,
  //   loading: false,
  // },
  PERFIT_AD: {
    value: undefined,
    loading: false
  },
  PERFIT_FOOTER_AD: {
    value: undefined,
    loading: false
  },
  EXPORT_CONTACTS_HIDDEN: {
    value: undefined,
    loading: false
  },
  IMPORT_BLOCKED: {
    value: undefined,
    loading: false
  },
  UNLAYER_OPTIONAL: {
    value: undefined,
    loading: false
  },
  UNLAYER_DEFAULT: {
    value: undefined,
    loading: false
  },
  NO_FORCE_CAMPAIGN_FOOTER: {
    value: undefined,
    loading: false
  },
  ALWAYS_SHOW_OPTIN_RULE_CONFIG: {
    value: undefined,
    loading: false
  },
  ALLOW_IMPORT_EXPORT_UNLAYER: {
    value: undefined,
    loading: false
  },
  HIDE_LEGACY_TEMPLATES: {
    value: undefined,
    loading: false
  },
  UNLAYER_ALLOW_HTML: {
    value: undefined,
    loading: false
  },
  FIRST_LOGIN: {
    value: undefined,
    loading: false
  },
  ALLOW_USE_GENERIC_DOMAIN: {
    value: undefined,
    loading: false
  }
});

interface IExtraRulesFeaturesConfig {
  DRIP_ENABLED: (value: boolean) => void;
}

const extraRules: IExtraRulesFeaturesConfig = reactive({
  DRIP_ENABLED: (value: boolean) => {
    value &&
      postDrips({
        account: props.account
      });
  }
});

watch(
  () => featuresConfig.USE_TOKENIZE,
  () => {
    if (!featuresConfig.USE_TOKENIZE.value) featuresConfig.USE_TOKENIZE_MP.value = false;
  },
  { deep: true }
);

watch(
  () => cloneDeep(featuresConfig),
  async (newFeatures, oldFeatures) => {
    const changedFeatureKey = Object.keys(newFeatures).find((key) => {
      return (
        newFeatures[key].value != undefined &&
        oldFeatures[key].value != undefined &&
        newFeatures[key].value != oldFeatures[key].value
      );
    });

    if (changedFeatureKey) {
      const booleanParsedToString = newFeatures[changedFeatureKey]?.value ? "1" : "0";

      featuresConfig[changedFeatureKey].loading = true;

      await putFeature({
        key: changedFeatureKey,
        value: booleanParsedToString,
        account: props.account
      });

      await extraRules[changedFeatureKey]?.(newFeatures[changedFeatureKey].value);

      notify({
        title: "Configuración actualizada",
        text: `Se modificó ${changedFeatureKey}`
      });

      featuresConfig[changedFeatureKey].loading = false;
    }
  },
  { deep: true }
);

onMounted(async () => {
  accountFeatures.value = await useAccountFeatures().get({ account: props.account });

  if (
    accountFeatures.value &&
    !(Object.keys(accountFeatures.value).length === 0) &&
    Object.getPrototypeOf(accountFeatures.value) === Object.prototype
  ) {
    featuresConfig.USE_TIENDANUBE_THEME.value = accountFeatures.value?.USE_TIENDANUBE_THEME == "1" || false;

    featuresConfig.USE_NEXT_UNLAYER_VERSION.value = accountFeatures.value?.USE_NEXT_UNLAYER_VERSION == "1" || false;

    featuresConfig.ALLOW_HOME_ACCESS.value = accountFeatures.value?.ALLOW_HOME_ACCESS == "1" || false;

    featuresConfig.USE_NEW_LAYOUT.value = accountFeatures.value?.USE_NEW_LAYOUT == "1" || false;
    featuresConfig.ALLOW_TOGGLE_LAYOUT.value = accountFeatures.value?.ALLOW_TOGGLE_LAYOUT == "1" || false;

    featuresConfig.USE_TOKENIZE.value = accountFeatures.value?.USE_TOKENIZE == "1" || false;
    featuresConfig.USE_TOKENIZE_MP.value = accountFeatures.value?.USE_TOKENIZE_MP == "1" || false;
    featuresConfig.DRIP_ENABLED.value = accountFeatures.value?.DRIP_ENABLED == "1" || false;
    // featuresConfig.ALLOW_DISABLE_EXCLUDE.value =
    // accountFeatures.value?.ALLOW_DISABLE_EXCLUDE == "1" || false;
    featuresConfig.PERFIT_AD.value = accountFeatures.value?.PERFIT_AD == "1" || false;
    featuresConfig.PERFIT_FOOTER_AD.value = accountFeatures.value?.PERFIT_FOOTER_AD == "1" || false;
    featuresConfig.EXPORT_CONTACTS_HIDDEN.value = accountFeatures.value?.EXPORT_CONTACTS_HIDDEN == "1" || false;
    featuresConfig.IMPORT_BLOCKED.value = accountFeatures.value?.IMPORT_BLOCKED == "1" || false;

    featuresConfig.UNLAYER_OPTIONAL.value = accountFeatures.value?.UNLAYER_OPTIONAL == "1" || false;
    featuresConfig.UNLAYER_DEFAULT.value = accountFeatures.value?.UNLAYER_DEFAULT == "1" || false;
    featuresConfig.NO_FORCE_CAMPAIGN_FOOTER.value = accountFeatures.value?.NO_FORCE_CAMPAIGN_FOOTER == "1" || false;

    featuresConfig.ALWAYS_SHOW_OPTIN_RULE_CONFIG.value =
      accountFeatures.value?.ALWAYS_SHOW_OPTIN_RULE_CONFIG == "1" || false;

    featuresConfig.ALLOW_IMPORT_EXPORT_UNLAYER.value =
      accountFeatures.value?.ALLOW_IMPORT_EXPORT_UNLAYER == "1" || false;

    featuresConfig.HIDE_LEGACY_TEMPLATES.value = accountFeatures.value?.HIDE_LEGACY_TEMPLATES == "1" || false;
    featuresConfig.UNLAYER_ALLOW_HTML.value = accountFeatures.value?.UNLAYER_ALLOW_HTML == "1" || false;

    featuresConfig.FIRST_LOGIN.value = accountFeatures.value?.FIRST_LOGIN == "1" || false;

    featuresConfig.ALLOW_USE_GENERIC_DOMAIN.value = accountFeatures.value?.ALLOW_USE_GENERIC_DOMAIN == "1" || false;
  }
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
