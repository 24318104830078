var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"create")), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class='tab-content'>\n                                <div class='tab-pane active' id='details'>\n                                    <div class='form-group'>\n                                        <label>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"name")), depth0))
    + "\n                                        </label>\n                                        <input class='form-control' name='name' type='text' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "' data-validate='required' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"namePlaceholder")), depth0))
    + "'>\n                                    </div>\n                                    <div class='form-group'>\n                                        <label>\n                                            "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"tags"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":54,"column":44},"end":{"line":54,"column":81}}}))
    + "\n                                        </label>\n                                        <select class='tags-input' name='tags[]' multiple='multiple' data-role='tagsinput' data-options='"
    + alias2(__default(require("../../../helpers/handlebars/stringify.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"relationshipsData"))) && lookupProperty(stack1,"tags")),{"name":"stringify","hash":{},"data":data,"loc":{"start":{"line":56,"column":137},"end":{"line":56,"column":184}}}))
    + "' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"tagsPlaceholder")), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"relationshipsData"))) && lookupProperty(stack1,"tags")),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":44},"end":{"line":63,"column":53}}})) != null ? stack1 : "")
    + "                                        </select>\n                                        <p class='input-legend'>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"tagsLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                                    </div>\n                                    <div class='form-group'>\n                                        <label>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"sendTo")), depth0))
    + "\n                                        </label>\n                                        <div id='included-lists' data-role='items-selector' class='lists-selector'>\n                                            <div data-role='items-names' class='selected-items-list'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":73,"column":48},"end":{"line":84,"column":55}}})) != null ? stack1 : "")
    + "                                            </div>\n                                            <p class='help-block error-message error-hidden' data-validation-message='lists'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"required")), depth0))
    + "\n                                            </p>\n                                            <button class='btn btn-primary' data-selector-view='lists' data-selected-items='["
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":125},"end":{"line":89,"column":192}}})) != null ? stack1 : "")
    + "]' data-selector-type='checkbox' data-selector-description='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"select")), depth0))
    + "' data-checkbox-input-name='include[lists][]'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"select")), depth0))
    + "\n                                            </button>\n                                        </div>\n                                    </div>\n                                    <div class='form-group'>\n                                        <label>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"exclude")), depth0))
    + "\n                                        </label>\n                                        <div id='excluded-lists' data-role='items-selector' class='lists-selector'>\n                                            <div data-role='items-names' class='selected-items-list'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":100,"column":48},"end":{"line":111,"column":55}}})) != null ? stack1 : "")
    + "                                            </div>\n                                            <button class='btn btn-primary' data-selector-view='lists' data-selected-items='["
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":125},"end":{"line":113,"column":192}}})) != null ? stack1 : "")
    + "]' data-selector-type='checkbox' data-selector-description='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"select")), depth0))
    + "' data-checkbox-input-name='exclude[lists][]'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"select")), depth0))
    + "\n                                            </button>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class='tab-pane' id='recipients'>\n                                    <div data-role='from-selector'>\n                                        <div class='row'>\n                                            <div class='col-md-6'>\n                                                <div class='form-group'>\n                                                    <label>\n                                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"from")), depth0))
    + "\n                                                    </label>\n                                                    <select name='from[from]' class='form-control' data-target='[name=\"from[fromName]\"]' data-validate='required'>\n                                                        <option value=''>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"select")), depth0))
    + "\n                                                        </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"froms")),{"name":"each","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":56},"end":{"line":135,"column":65}}})) != null ? stack1 : "")
    + "                                                        <option value='new' class='bold'>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"add")), depth0))
    + "\n                                                        </option>\n                                                    </select>\n                                                    <p class='input-legend' data-role='from-status'>\n                                                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"legend")), depth0)) != null ? stack1 : "")
    + "\n                                                    </p>\n                                                </div>\n                                            </div>\n                                            <div class='col-md-6'>\n                                                <div class='form-group'>\n                                                    <label>\n                                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fromName")), depth0))
    + "\n                                                    </label>\n                                                    <input class='form-control' name='from[fromName]' type='text' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"fromName") : stack1), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"fromName") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":140},"end":{"line":150,"column":205}}})) != null ? stack1 : "")
    + " placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"namePlaceholder")), depth0))
    + "' data-validate='required'>\n                                                    <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"nameLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                                                </div>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <div class='form-group'>\n                                        <div class='graphic checkbox'>\n                                            <input id='custom-reply' name='customReply' value='1' type='checkbox' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"customReply") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":114},"end":{"line":158,"column":149}}})) != null ? stack1 : "")
    + ">\n                                            <label for='custom-reply'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"customReply")), depth0))
    + "\n                                            </label>\n                                        </div>\n                                    </div>\n                                    <div id='custom-reply-wrapper' data-role='from-selector' class='"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"customReply") : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":100},"end":{"line":164,"column":140}}})) != null ? stack1 : "")
    + "'>\n                                        <div class='row'>\n                                            <div class='col-md-6'>\n                                                <div class='form-group'>\n                                                    <label>\n                                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"replyTo")), depth0))
    + "\n                                                    </label>\n                                                    <select name='from[replyTo]' class='form-control' data-target='[name=\"from[replyToName]\"]' "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"customReply") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":143},"end":{"line":171,"column":187}}})) != null ? stack1 : "")
    + ">\n                                                        <option value=''>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"select")), depth0))
    + "\n                                                        </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"froms")),{"name":"each","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":56},"end":{"line":179,"column":65}}})) != null ? stack1 : "")
    + "                                                        <option value='new' class='bold'>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"add")), depth0))
    + "\n                                                        </option>\n                                                    </select>\n                                                    <p class='input-legend' data-role='from-status'>\n                                                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"legend")), depth0)) != null ? stack1 : "")
    + "\n                                                    </p>\n                                                </div>\n                                            </div>\n                                            <div class='col-md-6'>\n                                                <div class='form-group'>\n                                                    <label>\n                                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replyToName")), depth0))
    + "\n                                                    </label>\n                                                    <input class='form-control' name='from[replyToName]' type='text' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyToName") : stack1), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyToName") : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":146},"end":{"line":194,"column":217}}})) != null ? stack1 : "")
    + " placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replyToNamePlaceholder")), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"customReply") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":276},"end":{"line":194,"column":320}}})) != null ? stack1 : "")
    + ">\n                                                    <p class='input-legend'>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replyToNameLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                                                </div>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <div class='form-group'>\n                                        <div class='graphic checkbox'>\n                                            <input id='nest-contents' type='checkbox' name='nestContents' value='1' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"nestContents") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":116},"end":{"line":202,"column":152}}})) != null ? stack1 : "")
    + " data-toggle='optional' data-target='#nest-contents-lang'>\n                                            <label for='nest-contents'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nestContents")), depth0))
    + "\n                                            </label>\n                                            <p class='input-legend'>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nestContentsLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                                            <div id='nest-contents-lang' class='optional "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"nestContents") : depth0),{"name":"unless","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":89},"end":{"line":207,"column":132}}})) != null ? stack1 : "")
    + "'>\n                                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"nestContentsLang")), depth0))
    + "\n                                                <select name='language' class='form-control input-mini'>\n                                                    <option value='es' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"language") : depth0),"es",{"name":"is","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":210,"column":71},"end":{"line":210,"column":109}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"es")), depth0))
    + "</option>\n                                                    <option value='en' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"language") : depth0),"en",{"name":"is","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":71},"end":{"line":211,"column":109}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"en")), depth0))
    + "</option>\n                                                </select>\n                                            </div>\n                                        </div>\n                                    </div>\n                                    <div>\n                                        <ul class='nav nav-tabs'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":44},"end":{"line":224,"column":53}}})) != null ? stack1 : "")
    + "                                        </ul>\n                                        <div class='tab-content'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":44},"end":{"line":267,"column":53}}})) != null ? stack1 : "")
    + "                                        </div>\n                                    </div>\n                                </div>\n                                <div class='tab-pane' id='summary'>\n                                    <div class='loading-box'></div>\n                                    <div data-role='content'></div>\n                                </div>\n                            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":48},"end":{"line":62,"column":55}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' selected='selected'>\n                                                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\n                                                    </option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":56},"end":{"line":80,"column":65}}})) != null ? stack1 : "")
    + "                                                    </ul>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <li>\n                                                                <input type='checkbox' name='include[lists][]' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' checked='checked' class='hidden'>\n                                                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                                                            </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <p>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"noneSelected")), depth0))
    + "</p>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":154},"end":{"line":89,"column":183}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return ",";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":56},"end":{"line":107,"column":65}}})) != null ? stack1 : "")
    + "                                                    </ul>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <li>\n                                                                <input type='checkbox' name='exclude[lists][]' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' checked='checked' class='hidden'>\n                                                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                                                            </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"from"))) && lookupProperty(stack1,"from")),{"name":"is","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":86},"end":{"line":132,"column":159}}})) != null ? stack1 : "")
    + " data-name='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "'>\n                                                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n                                                            </option>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " selected='selected' ";
},"23":function(container,depth0,helpers,partials,data) {
    return " data-original-value='from.fromName' ";
},"25":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"27":function(container,depth0,helpers,partials,data) {
    return " hide ";
},"29":function(container,depth0,helpers,partials,data) {
    return " disabled ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                            <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"from"))) && lookupProperty(stack1,"replyTo")),{"name":"is","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":176,"column":86},"end":{"line":176,"column":162}}})) != null ? stack1 : "")
    + ">\n                                                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n                                                            </option>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return " data-original-value='from.replyToName' ";
},"35":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"37":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <li "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":52},"end":{"line":219,"column":87}}})) != null ? stack1 : "")
    + ">\n                                                    <a data-toggle='tab' data-target='#step"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "'>\n                                                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n                                                    </a>\n                                                </li>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "class='active'";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                <input type='hidden' name='nodes["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][id]' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n                                                <div class='tab-pane "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":229,"column":69},"end":{"line":229,"column":96}}})) != null ? stack1 : "")
    + "' id='step"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "'>\n                                                    <p id='description-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "'>\n                                                        "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"descriptionWithDate") : depth0), depth0)) != null ? stack1 : "")
    + "\n                                                    </p>\n                                                    <div class='form-group'>\n                                                        <label id='node-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"launchDate")), depth0))
    + "\n                                                        </label>\n                                                        <div style='position: relative'>\n                                                            <input class='form-control' data-role='datetimepicker' name='nodes["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][launchDate]' type='text' data-value='"
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"launchDate") : depth0),"YYYY-MM-DD HH:mm",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":238,"column":176},"end":{"line":238,"column":216}}}))
    + "' value='"
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"launchDate") : depth0),"YYYY-MM-DD HH:mm",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":238,"column":225},"end":{"line":238,"column":265}}}))
    + "' data-validate='required' data-node='"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "'>\n                                                        </div>\n                                                    </div>\n                                                    <div class='form-group'>\n                                                        <label>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n                                                        </label>\n                                                        <div class=\"input-group\">\n                                                            <input type='text' name='nodes["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][content][subject]' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"subject")), depth0))
    + "' class='form-control' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "' data-validate='required'>\n                                                            <div class='input-group-btn'>\n                                                               <button type='button' class='btn btn-default' data-toggle='dropdown' title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"insertField")), depth0))
    + "\">\n                                                                   <span>{…}</span>\n                                                               </button>\n                                                               <ul class='dropdown-menu'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"fields")),{"name":"each","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":68},"end":{"line":254,"column":77}}})) != null ? stack1 : "")
    + "                                                               </ul>\n                                                           </div>\n                                                       </div>\n                                                        <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"subjectLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                                                    </div>\n                                                    <div class='form-group'>\n                                                        <label>\n                                                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"content")), depth0))
    + "\n                                                        </label>\n                                                        <textarea id='html"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' name='nodes["
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "][content][html]' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"content")), depth0))
    + "' class='form-control' data-prepend-error='true'>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"html") : stack1), depth0))
    + "</textarea>\n                                                    </div>\n                                                </div>\n";
},"43":function(container,depth0,helpers,partials,data) {
    return "active";
},"45":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                                                        <li><a data-action='insert-field' data-tag='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tag") : depth0), depth0))
    + "'>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='drips section show'>\n    <div class='section-header'>\n        <h1>\n            <a data-action='back'><i class='icon icon-back'></i></a>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"DRAFT",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "        </h1>\n    </div>\n    <form class='save' data-form='save'>\n        <div class='toolbar edit-mode'>\n            <button type='submit' class='btn btn-primary'>\n                <i class='fa fa-check'></i>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"saveDraft")), depth0))
    + "\n            </button>\n        </div>\n        <div class='content edit scrollbar'>\n            <div class='padder'>\n                <ul class='nav nav-pills'>\n                    <li class='active'>\n                        <a data-toggle='tab' data-target='#details'>\n                            <span class='pill-icon'>1</span>\n                            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"details")), depth0))
    + "\n                        </a>\n                    </li>\n                    <li>\n                        <a data-toggle='tab' data-target='#recipients'>\n                            <span class='pill-icon'>2</span>\n                            "
    + alias3(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"contents")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":31,"column":68}}}))
    + "\n                        </a>\n                    </li>\n                    <li>\n                        <a data-toggle='tab' data-target='#summary'>\n                            <span class='pill-icon'>3</span>\n                            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"summary")), depth0))
    + "\n                        </a>\n                    </li>\n                </ul>\n                <div class='panel panel-default'>\n                    <div class='panel-body panel-body-padded'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":24},"end":{"line":276,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});