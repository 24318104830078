var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-activate='1'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"activate")), depth0))
    + "\n    </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " enabled ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"form-group\">\n            <label>API key</label>\n            <div class=\"input-group\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"transactionalApiKey") : stack1), depth0))
    + "\">\n              <div class=\"input-group-btn\">\n                <button type=\"button\" class=\"btn btn-primary\" data-action=\"copy-api-key\">\n                  <span>Copiar</span>\n                </button>\n              </div>\n            </div>\n          </div>\n          <div class=\"transactional-plan\">\n            <div>\n              <div class='consumed'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"transactional"))) && lookupProperty(stack1,"emailsUsed")), depth0))
    + ": "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"consumed") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":59,"column":70},"end":{"line":60,"column":47}}}))
    + " / "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"limit_monthly") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":60,"column":50},"end":{"line":60,"column":102}}}))
    + "\n              </div>\n              <div class='progress'>\n                <div class='progress-bar' style='width: "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"availablePerc") : stack1), depth0))
    + "%'></div>\n              </div>\n              <div class='legend'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"transactional"))) && lookupProperty(stack1,"emailsUsedLengend")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"plan") : stack1)) != null ? lookupProperty(stack1,"limit_hourly") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":66,"column":76},"end":{"line":67,"column":51}}}))
    + ".\n              </div>\n            </div>\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='integrations section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-navigate='integrations'><i class='icon icon-back'></i></a>\n      <span>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n      </span>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <div class='row'>\n        <div class='col-md-4'>\n          <div class='thumbnail integration "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":44},"end":{"line":30,"column":91}}})) != null ? stack1 : "")
    + "'>\n            <div class='preview' style='background-image: url(/img/integrations/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + ".png)'>\n            </div>\n          </div>\n        </div>\n        <div class='col-md-8'>\n          <h2>"
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"titles")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":14},"end":{"line":36,"column":77}}}))
    + "</h2>\n          <p>"
    + ((stack1 = __default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"descriptions")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":37,"column":13},"end":{"line":37,"column":84}}})) != null ? stack1 : "")
    + "</p>\n          <p>\n          <div class=\"hint-box hint-info\" style=\"margin: 1rem 0;\">\n            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"transactionalsHint")), depth0)) != null ? stack1 : "")
    + "\n          </div>\n          </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"transactionalApiKey") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":71,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});