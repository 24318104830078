<template>
  <div class="grid grid-cols-2 gap-4 lg:grid-cols-4">
    <ResourceCard
      v-for="(card, index) in cards"
      :key="index"
      :title="card.title"
      :description="card.description"
      :icon="card.icon"
      :url="card.url"
      :theme="card.theme"
      @click="onClickLink(card.url)"
    />
  </div>
</template>

<script lang="ts" setup>
import type { FunctionalComponent } from "vue";

// Components
import ResourceCard from "./ResourceCard.vue";

// Icon
import { SupportIcon, CalendarIcon, BookmarkAltIcon, LightBulbIcon } from "@heroicons/vue/outline";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const trackingEventsService = useTrackingEvents();

const cards: Array<{
  title: string;
  description: string;
  icon: FunctionalComponent;
  url: string;
  theme: "teal" | "orange" | "rose" | "indigo";
}> = [
  {
    title: t("cards.help.title"),
    description: t("cards.help.description"),
    icon: SupportIcon,
    url: "https://docs.myperfit.com/",
    theme: "teal",
  },
  {
    title: t("cards.event.title"),
    description: t("cards.event.description"),
    icon: CalendarIcon,
    url: "https://app.livestorm.co/perfit",
    theme: "orange",
  },
  {
    title: t("cards.specialist.title"),
    description: t("cards.specialist.description"),
    icon: BookmarkAltIcon,
    url: "https://directory.myperfit.com/",
    theme: "rose",
  },
  {
    title: t("cards.suggestions.title"),
    description: t("cards.suggestions.description"),
    icon: LightBulbIcon,
    url: "https://perfit.canny.io/",
    theme: "indigo",
  },
];

const onClickLink = (url: string) => {
  trackingEventsService.amplitude({
    name: "APP_HOME_URL_LINK",
    data: {
      url: url,
    },
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "cards": {
      "help": {
        "title": "Centro de ayuda",
        "description": "Recursos esenciales para potenciar tu uso de la plataforma"
      },
      "event": {
        "title": "Eventos y webinars",
        "description": "Domina la plataforma con eventos educativos y aplica estrategias efectivas."
      },
      "specialist": {
        "title": "Especialistas",
        "description": "Potencia tus estrategias con la ayuda de agencias recomendadas."
      },
      "suggestions": {
        "title": "Sugerencias",
        "description": "Ayudanos a mejorar para seguir potenciando los resultados de tu negocio."
      }
    }
  },
  "pt": {
    "cards": {
      "help": {
        "title": "Centro de ajuda",
        "description": "Recursos essenciais para potencializar o uso da plataforma"
      },
      "event": {
        "title": "Eventos e webinars",
        "description": "Domine a plataforma com eventos educativos e aplique estratégias eficazes."
      },
      "specialist": {
        "title": "Especialistas",
        "description": "Potencialize suas estratégias com a ajuda de agências recomendadas."
      },
      "suggestions": {
        "title": "Sugestões",
        "description": "Ajude-nos a melhorar para continuar potencializando os resultados do seu negócio."
      }
    }
  }
}
</i18n>
