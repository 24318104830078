// Services
import { useUserActivityService } from "@services";

// Types
import type { UserActivityApplication } from "./userActivity.types";

import { ok, err } from "neverthrow";
import { useSessionStore } from "@store";

export const useUserActivityApplication = (): UserActivityApplication => {
  const userActivityService = useUserActivityService();
  const sessionStore = useSessionStore();

  const userActivityApp: UserActivityApplication = {
    async submit(params) {
      if (!sessionStore.session) {
        return err({
          type: "ERROR",
        });
      }

      const userActivityCreated = await userActivityService.submit({
        account: sessionStore.session.account.code,
        user: sessionStore.session.account.username,
        data: params.data,
        action: params.action,
      });

      if (userActivityCreated.isErr())
        return err({
          type: "ERROR",
        });

      return ok(userActivityCreated.value);
    },
    async getFirstEvents() {
      const userActivity = await userActivityService.getFirstEvents();

      if (userActivity.isErr())
        return err({
          type: "ERROR",
        });

      return ok(userActivity.value);
    },
  };

  return userActivityApp;
};
