<template>
  <div class="relative flex h-full flex-col">
    <TabsMenu v-if="mounted" :tabs="tabsMenu" teleport-content="#teleport-template-gallery-tag-content">
      <template #templateGallery>
        <TemplateGalleryTab
          add-empty-template
          class="h-[calc(100%-8rem)] p-0.5"
          @select="selectPublicTemplate"
          @select-new-template="useEmptyTemplate"
        />
      </template>
      <template #myTemplates>
        <div id="gallery-content-filters" />
        <MyTemplatesTab
          v-if="mounted"
          :tpl-id="tplId"
          :show="{
            tableOptionsMenu: false,
            selection: false,
            tagsEditor: false,
          }"
          class="h-[calc(100%-12rem)] p-0.5 pr-4"
          @select="selectTemplate"
        />
      </template>
      <template #myCampaigns>
        <div id="gallery-content-campaigns-filters" />
        <TabCampaigns v-if="mounted" class="ml-6 h-[calc(100%-12rem)] p-0.5 pr-4" @select="selectTemplate" />
      </template>
      <template v-if="accountFeatures?.UNLAYER_ALLOW_HTML === '1'" #importHTML>
        <TabInsertHTML @useHTMLTemplate="selectHTMLTemplate" />
      </template>
    </TabsMenu>
    <div id="teleport-template-gallery-tag-content" class="h-full pb-8" />
    <div v-if="false" class="absolute right-5 top-2 z-10">
      <SimpleButton theme="secondary-light">
        <template #leading>
          <SparklesIcon />
        </template>
        {{ t("customTemplateButton") }}
      </SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue";

//Components
import TabsMenu from "@molecules/TabsMenu.vue";
import type { TabItem } from "@molecules/TabsMenu.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import TemplateGalleryTab from "./components/TemplateGalleryCatalog/TemplateGalleryCatalog.vue";
import MyTemplatesTab from "./components/TabOwnCatalog/TemplateOwnCatalog.vue";
import TabCampaigns from "./components/TabCampaigns/TabCampaigns.vue";
import TabInsertHTML from "./components/TabInsertHTML/TabInsertHTML.vue";

//Icons
import { SparklesIcon } from "@heroicons/vue/outline";

// Store
import { useFeaturesStore } from "@store";

//Utils
import { useI18n } from "vue-i18n";

//Types
import type { TemplateItem } from "@/vue/types/Templates";
import type { AccountFeatures } from "@domain/account";

const { t } = useI18n();

// Store
const featuresStore = useFeaturesStore();

//Props
const props = withDefaults(
  defineProps<{
    tplId?: string;
    show?: {
      templateGallery?: boolean;
      myTemplates?: boolean;
      myCampaigns?: boolean;
    };
  }>(),
  {
    tplId: undefined,
    show: () => ({
      templateGallery: true,
      myTemplates: true,
      myCampaigns: true,
    }),
  },
);

//Emits
const emit = defineEmits<{
  (e: "select", selectedData: { template: TemplateItem; isPublicTemplate: boolean }): void;
  (e: "selectHTML", template: string): void;
  (e: "selectNewTemplate"): void;
}>();

const selectPublicTemplate = (selectedTemplate: TemplateItem) => {
  emit("select", { template: selectedTemplate, isPublicTemplate: true });
};

const selectTemplate = (selectedTemplate: TemplateItem) => {
  emit("select", { template: selectedTemplate, isPublicTemplate: false });
};

const selectHTMLTemplate = (selectedTemplate: string) => {
  emit("selectHTML", selectedTemplate);
};

const useEmptyTemplate = () => emit("selectNewTemplate");

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
});

const accountFeatures = ref<AccountFeatures>();
accountFeatures.value = featuresStore.getFeatures();

//Const
const tabsMenu = computed<Array<TabItem>>(() => {
  const options: Array<TabItem> = [];

  if (props.show.templateGallery) {
    options.push({
      id: "templateGallery",
      label: t("tabs.templateGallery"),
    });
  }

  if (props.show.myTemplates) {
    options.push({
      id: "myTemplates",
      label: t("tabs.myTemplates"),
    });
  }

  if (props.show.myCampaigns) {
    options.push({
      id: "myCampaigns",
      label: t("tabs.myCampaigns"),
    });
  }

  if (accountFeatures.value?.UNLAYER_ALLOW_HTML === "1") {
    options.push({
      id: "importHTML",
      label: t("tabs.importHTML"),
    });
  }

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "customTemplateButton": "¡Consulta por una plantilla personalizada!",
    "tabs": {
      "templateGallery": "Galería pública",
      "myTemplates": "Mis plantillas",
      "myCampaigns": "Mis campañas",
      "importHTML": "Importar html"
    }
  },
  "pt": {
    "customTemplateButton": "¡Consulta por una plantilla personalizada!",
    "tabs": {
      "templateGallery": "Galeria pública",
      "myTemplates": "Meus templates",
      "myCampaigns": "Minhas campanhas",
      "importHTML": "Importar html"
    }
  }
}
</i18n>
