<template>
  <p class="mt-2 text-sm text-gray-500">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "InputHint",
};
</script>
