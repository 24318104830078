<template>
  <div class="mb-6 bg-white border border-gray-200 sm:rounded-lg">
    <div class="flex justify-between px-4 py-4 sm:p-6">
      <div>
        <h3 class="m-0 text-base leading-6 font-medium text-gray-900">{{ t("featuresTitle") }}</h3>
        <TextParagraph>
          {{ t("featuresDescription") }}
        </TextParagraph>
      </div>
      <div class="flex flex-col justify-center">
        <SimpleButton theme="primary" @click="changeModalState(true)">
          {{ t("openFeaturesConfig") }}
        </SimpleButton>
        <AccountFeaturesConfigModal
          :open="modalState"
          :account="account"
          @close="changeModalState(false)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
//Components
import SimpleButton from "@atoms/SimpleButton.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import AccountFeaturesConfigModal from "@organisms/Account/AccountFeatures/AccountFeaturesConfigModal.vue";

//I18n
import { useI18n } from "vue-i18n";

//Props
withDefaults(
  defineProps<{
    account?: string;
  }>(),
  {
    account: undefined,
  }
);

const { t } = useI18n();

const modalState = ref(false);

const changeModalState = (value: boolean) => (modalState.value = value);
</script>

<i18n lang="jsonc">
{
  "es": {
    "featuresTitle": "Features de cuenta [sólo master users]",
    "featuresDescription": "Modifica las funcionalidades adicionales activadas para esta cuenta.",
    "openFeaturesConfig": "Configurar"
  },
  "pt": {
    "featuresTitle": "Recursos da conta [sólo master users]",
    "featuresDescription": "Modifique as funcionalidades adicionais ativadas para esta conta.",
    "openFeaturesConfig": "Configurar"
  }
}
</i18n>
