<template>
  <ApplyPlanDiscountModal
    v-model:is-open="isOpen"
    :default-promo-code="defaultPromoCode"
    @AppliedDiscount="AppliedDiscount"
  />
  <a @click="OpenModal">{{ t("applyDiscount") }} </a>
</template>
<script lang="ts" setup>
import { ref } from "vue";

//Components
import ApplyPlanDiscountModal from "./ApplyPlanDiscountModal.vue";

//I18n
import { useI18n } from "vue-i18n";

withDefaults(
  defineProps<{
    defaultPromoCode?: string;
  }>(),
  {
    defaultPromoCode: undefined,
  }
);

const emit = defineEmits<{
  (e: "AppliedDiscount"): void;
}>();

const AppliedDiscount = () => {
  emit("AppliedDiscount");
};

const { t } = useI18n();

const isOpen = ref(false);

const OpenModal = () => (isOpen.value = true);
</script>

<i18n lang="jsonc">
{
  "es": {
    "applyDiscount": "Aplicar descuento"
  },
  "pt": {
    "applyDiscount": "Aplicar descuento"
  }
}
</i18n>
