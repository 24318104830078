import { Model } from "@/core/magnet";

export default Model.extend({
  urlRoot: "fields",
  parse: function (response) {
    return response.data;
  },
  relationships: {
    format: [{ id: "TEXT" }, { id: "INT" }, { id: "DATE" }],
  },
});
