<template>
  <GlobalComponents />
  <div class="flex h-full">
    <!-- <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" :class="[{ 'mt-12': alertStore.showAlert }, 'relative z-50 lg:hidden']" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <SidebarNavigation class="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2 ring-1 ring-white/10" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot> -->

    <StatusBar v-if="!isMasterUser || accountSelected" />

    <!-- shadow-[2px_0px_2px_0_rgba(0,0,0,0.1)] -->
    <SidebarNavigation
      v-if="smBp && (!isMasterUser || accountSelected)"
      :class="[{ 'mt-12': alertStore.message }, 'flex w-16 flex-col']"
    />
    <!-- class tw-hidden SidebarNavigation -->
    <!-- <div
      :class="[
        { 'mt-12': alertStore.showAlert },
        alertStore.showAlert ? 'top-12' : 'top-0',
        'sticky  z-40 flex items-center gap-x-6 bg-gray-100 px-4 py-4 shadow-sm sm:px-6 lg:hidden',
      ]"
    >
      <button type="button" class="-m-2.5 p-2.5 text-gray-500 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <BriefcaseIcon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
      <a href="#">
        <span class="sr-only">Your profile</span>
        <img
          class="h-8 w-8 rounded-full bg-gray-800"
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
        />
      </a>
    </div> -->

    <!-- Content here -->
    <main id="perfitPageUniqueID" :class="[{ 'pt-12': alertStore.message }, 'page h-full w-full']" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Components
import SidebarNavigation from "@organisms/SidebarNavigation";
import GlobalComponents from "@organisms/GlobalComponents/GlobalComponents.vue";
import StatusBar from "@organisms/StatusBar/statusBar.vue";

// Composables
import { useBreakpoints } from "@composables/breakpoints";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Domain
import { useAlertStore } from "@store/alertStore/alert.store";

const { smBp } = useBreakpoints();

const alertStore = useAlertStore();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const isMasterUser = computed(() => session?.value?.isMasterUser ?? false);
const accountSelected = computed(() => session?.value?.account?.code !== "" ?? false);
</script>
