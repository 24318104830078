import heatmap from "heatmap.js";

export interface DataPoint {
  x: number;
  y: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
  value: number;
  url: string;
}

export interface CreateHeatMapParams {
  element: HTMLElement;
  onExtremaChange?: (param: { min: number; max: number; gradient: Record<string, string> }) => void;
  radius?: number;
  opacity?: number;
  maxOpacity?: number;
  minOpacity?: number;
  blur?: number;
  data?: {
    min: number;
    max: number;
    data: Array<DataPoint>;
  };
}

export const createHeatMap = ({
  element,
  onExtremaChange,
  radius,
  opacity,
  maxOpacity,
  minOpacity,
  blur,
  data,
}: CreateHeatMapParams) => {
  const heatmapInstance = heatmap.create({
    container: element,
    onExtremaChange,
    radius: radius,
    maxOpacity: maxOpacity,
    minOpacity: minOpacity,
    blur: blur,
    opacity: opacity,
  });

  heatmapInstance.setData({
    min: data?.min ?? 0,
    max: data?.max ?? 0,
    data: data?.data ?? [],
  });

  return heatmapInstance;
};

export const destroyHeatMapInstance = (heatMapInstance) => {
  heatMapInstance?._renderer?.canvas?.remove();
  heatMapInstance = undefined;
};
