<template>
  <ListSelector :open="open" allow-create @close="close" @select-lists="subscribeToLists" />
</template>

<script lang="ts" setup>
import type { ListItems } from "@organisms/Lists/ListSelector";

// Components
import ListSelector from "@organisms/Lists/ListSelector";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { useContacts } from "@api/modules/contacts/contacts";

// Domain
import type { ContactRows } from "../audienceContacts.types";

const contactsAPI = useContacts();
const { notify } = useNotifications();
const { t } = useI18n();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    contacts: ContactRows;
    listId?: string;
    interestId?: string;
    query?: string;
    state?: "ACTIVE" | "LIVE" | "NOT_LIVE" | "BOUNCED" | "UNSUBSCRIBED" | "COMPLAINED" | "INACTIVE";
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  addedToLists: [void];
}>();

const close = () => {
  emit("close");
};

const subscribeToLists = async (selectedList: ListItems) => {
  contactsAPI.subscribeToLists({
    listId: props.listId,
    state: props.state,
    interestId: props.interestId,
    query: props.query,
    subscribeToLists: selectedList.reduce<number[]>((subscribeList, list) => {
      const listId = list.data?.id;
      if (!listId) return subscribeList;

      subscribeList.push(listId);
      return subscribeList;
    }, []),
  });

  notificationStore.fetchTasks();

  close();
  notify({
    title: t(`notifications.subscribeToLists.title`),
    text: t(`notifications.subscribeToLists.text`),
    theme: "success",
    timeout: 8000,
  });

  emit("addedToLists");
};
</script>
<i18n lang="json">
{
  "es": {
    "notifications": {
      "subscribeToLists": {
        "title": "Suscripción a listas",
        "text": "Los contactos serán suscriptos a las listas seleccionadas."
      }
    }
  },
  "pt": {
    "notifications": {
      "subscribeToLists": {
        "title": "Inscrição em listas",
        "text": "Os contatos serão inscritos nas listas selecionadas."
      }
    }
  }
}
</i18n>
