/* eslint-disable vue/component-definition-name-casing */
/* eslint-disable vue/one-component-per-file */
import app from "@/app";
import ContentMixin from "@/mixins/content";
import FromsMixin from "@/mixins/froms";
import { EditView } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";
import SimpleSubjectInput from "@/components/vue/SimpleSubjectInput";
import SubjectVersions from "@/components/vue/SubjectVersions";
import SmartScheduler from "@/components/vue/SmartScheduler";
import SenderSelector from "@/components/vue/SenderSelector";
import { mountComponent } from "@/helpers/vue";
import TemplatePreview from "@organisms/Templates/TemplatePreview/TemplatePreview";

import { useOnboardingStore } from "@store";

const moment = window.moment;

export default EditView.mix(ContentMixin, FromsMixin).extend({
  module: "campaigns",
  templates: {
    initial: require("../templates/edit.hbs"),
    edit: require("../templates/edit.hbs"),
    preview: require("../templates/preview.hbs"),
    summary: require("../templates/summary.hbs"),
  },
  gender: "F",
  cache: false,
  tabs: {
    details: '[data-target="#details"]',
    contacts: '[data-target="#contacts"]',
    contents: '[data-target="#contents"]',
    summary: '[data-target="#summary"]',
  },
  socialHeaderOptions: [
    "SHARE",
    "USE_HEADER",
    "SHARE_FACEBOOK",
    "SHARE_TWITTER",
    "SHARE_LINKEDIN",
    "SHARE_FORWARD",
    "VIEW_ONLINE",
    "GOOGLE_ANALYTICS",
    "TRACK_LINKS",
    "USE_FOOTER",
  ],
  extraFormField: [
    "includeInterests",
    "excludeInterests",
    "includeAction",
    "excludeContacts",
    "excludeLists",
    "excludeCampaigns",
    "excludeDays",
    "excludeAction",
    "includeType",
    "excludeLink",
    "includeLink",
  ],
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "hide.bs.tab [data-toggle=tab]": "beforeTabChange",
      "shown.bs.tab [data-toggle=tab]": "afterTabChange",
      "change #track-links": "toggleTracking",
      "change [name=includeType]": "toggleRecipientOptions",
      "change [name=excludeLists]": "toggleExcludeOptions",
      "change [name=excludeCampaigns]": "toggleExcludeOptions",
      "change [data-recipients] input": "setRecipientsFormAsDirty",
      "change [data-recipients] select": "setRecipientsFormAsDirty",
      "click [data-selector-view]": "setRecipientsFormAsDirty",
      "submit [data-form=save-contents]": "saveContents",
      "click [data-action=choose-template]": "chooseTemplate",
      "click [data-action=change-preview]": "changePreview",
      "click [data-action=open-eddie-content-modal]": "openEddieModal",
      "click [data-action=open-html-content-edit-modal]": "openTinyModal",
      "submit [data-form=save-tiny-content]": "saveTinyContent",
      "click [data-action=create-from-template]": "openSelectTemplateModal",
      "click [data-action=explore-folders]": "exploreFolders",
      "submit [data-form=search-folder]": "searchFolders",
      "click [data-action=template-selected]": "createFromTemplate",
      "click [data-action=open-social-header-modal]": "openSocialHeaderModal",
      "submit [data-form=social-header]": "saveSocialHeader",
      "click [data-action=open-save-content-as-template-modal]": "openSaveContentAsTemplateModal",
      "submit [data-form=save-content-as-template]": "saveContentAsTemplate",
      "change [name=USE_HEADER]": "toogleHeaderOptions",
      "click [data-action=open-content-advanced-options-modal]": "openContentAdvancedOptionsModal",
      "submit [data-form=content-advanced-options]": "saveContentAdvancedOptions",
      "click [data-action=open-send-test-modal]": "openSendTestModal",
      "submit [data-form=send-test]": "sendTest",
      "click [data-action=open-schedule-modal]": "openScheduleModal",
      "click [data-action=open-smart-schedule-modal]": "openSmartScheduleModal",
      "submit [data-form=schedule]": "schedule",
      "click [data-action=launch-now]": "launchNow",
      "click [data-action=delete-content]": "deleteContent",
      "click [data-action=open-create-html-content-modal]": "openCreateHtmlContentModal",
      "dragover [data-role=dropzone]": "prevent",
      "dragenter [data-role=dropzone]": "addDropHighlight",
      "dragleave [data-role=dropzone]": "removeDropHighlight",
      "drop [data-role=dropzone]": "getDroppedFiles",
      "click [data-role=uploader]": "openFileSelection",
      "change [data-input-type=upload-file]": "getSelectedFiles",
      "submit [data-form=create-html-template]": "createHtmlContent",
      "click [data-action=next]": "nextStep",
      "click [data-action=prev]": "prevStep",
      "change [name=from]": "changeFrom",
      "submit [data-form=add-from]": "addFrom",
      "submit [data-form=verify-from]": "verifyFrom",
      "click [data-action=open-update-from-modal]": "openUpdateFromModal",
      "submit [data-form=update-from]": "updateFrom",
      "click [data-action=open-send-instructions-modal]": "openSendInstructionsModal",
      "submit [data-form=send-instructions]": "sendInstructions",
      "click [data-action=toggle-exclusion-types]": "toggleExclusionTypes",
      "change #google-analytics": "toggleAnalytics",
      "click [data-action=save-draft]": "saveDraft",
      "change [name=includeAction]": "changedAction",
      "change [name=excludeAction]": "changedAction",
      "change [name=includeLink]": "changedLink",
      "change [name=excludeLink]": "changedLink",
      "click [data-action=insert-field]": "insertField",
      "click [data-action=insert-field-resend]": "insertFieldResend",
      "change #exclude-inactive": "warnExcludeInactiveUncheck",
    });
  },

  // Prevents undesired events
  prevent: function (e) {
    e.preventDefault();
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    if (this.item.isNew()) {
      this.item.set("type", this.options.type || "SIMPLE");
    }

    if (this.item.attributes.options?.UNLAYER_TPL_ID || this.options.useUnlayer) {
      this.useUnlayer = true;
      this.unlayerTemplateId = this.item.attributes.options?.UNLAYER_TPL_ID;
    }

    // Redirect if the campaign is not a draft
    var status = this.item.get("state");
    if (!this.item.isNew() && status !== "DRAFT") {
      switch (status) {
        case "SCHEDULED":
        case "PENDING_APPROVAL":
        case "SENDING":
          app.router.navigate(`metrics/${this.item.id}/contents`, { trigger: true, replace: true });
          return;
        default:
          app.router.navigate(`metrics/${this.item.id}`, { trigger: true, replace: true });
          return;
      }
    }

    // Manage data
    this.manageRecipients();
    this.manageOptions();
    this.shouldReloadSummary = true;

    // Continue with rendering
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    // Renders the tags
    this.renderTags();

    // Set return and callback flag
    this.returnToListAfterSave = false;
    this.afterUpdateCallback = false;

    // Set target tab
    if (_.isDefined(window.target)) {
      this.changeToTab(window.target);
      delete window.target;
    }

    if (this.useUnlayer) {
      this.mountVueComponents();
    } else {
      this.mountVueComponentsLegacy();
    }

    this.mountVueComponentsResend();
  },

  mountVueComponents: function () {
    if (this.templatePreviewComponent || !this.item.get("id") || !this.unlayerTemplateId) return;

    let relationType = "campaign_simple";
    if (this.item.attributes.type === "SPLIT_AB") {
      relationType = "campaign_testab";
    } else if (this.item.attributes.type === "SMART") {
      relationType = "campaign_smart";
    }

    const props = {
      tplId: this.unlayerTemplateId,
      relation: {
        relationId: this.item.get("id"),
        relationName: this.item.get("name"),
        relationType,
      },
      auditMode: "campaign",
      onTemplateUpdated: (content) => this.updateLegacyContent(content),
    };
    this.templatePreviewComponent = mountComponent(TemplatePreview, props, "#vue-template-preview");
  },

  updateLegacyContent: function (content) {
    const sender = content.sender;
    const template = content.template;
    const subjects = content.subjects;

    $.ajax({
      type: "PUT",
      data: JSON.stringify({ html: template.contents.html }),
      contentType: "application/json",
      url: `campaigns/${this.item.id}/contents/${this.item.defaultContent().id}/body`,
    });

    $.ajax({
      type: "PUT",
      data: JSON.stringify({
        from: sender?.email,
        fromName: sender?.name,
        replyTo: sender?.reply_to ? sender.reply_to : sender?.email,
        subject: template.subject,
      }),
      contentType: "application/json",
      url: `campaigns/${this.item.id}/contents/${this.item.defaultContent().id}`,
    });

    this.item.attributes.contents[0].subject = template.subject;
    this.item.attributes.contents[0].from = sender?.email;
    this.item.attributes.contents[0].fromName = sender?.name;
    this.item.attributes.contents[0].replyTo = sender?.reply_to;

    const tplValid = template.audit_results.status === "FAIL" ? "0" : "1";
    this.item.attributes.options.UNLAYER_TPL_VALID = tplValid;
    this.shouldReloadSummary = true;

    let testABSubjects = {};
    if (this.item.attributes.type === "SPLIT_AB") {
      testABSubjects.VERSION_1 = subjects[0] || "";
      testABSubjects.VERSION_2 = subjects[1] || "";
      testABSubjects.VERSION_3 = subjects[2] || "";
      testABSubjects.VERSION_4 = subjects[3] || "";

      this.item.attributes.options = { ...this.item.attributes.options, ...testABSubjects };
    }

    $.ajax({
      type: "PUT",
      data: JSON.stringify({
        options: {
          SENDER_ID: sender?.id,
          UNLAYER_TPL_VALID: tplValid,
          ...testABSubjects,
        },
      }),
      contentType: "application/json",
      url: `campaigns/${this.item.id}`,
    });
    this.item.attributes.options.SENDER_ID = sender?.id;
    this.item.attributes.options.UNLAYER_TPL_VALID = tplValid;
  },

  mountVueComponentsLegacy: function () {
    if (this.item.attributes.type === "SPLIT_AB") {
      this.subjectVersionsVueInstance = mountComponent(
        SubjectVersions,
        {
          fields: this.item.additionalsData.fields,
          subjects: [
            this.item.attributes.contents ? this.item.attributes.contents[0].subject : "",
            this.item.attributes.options ? this.item.attributes.options["VERSION_2"] : "",
            this.item.attributes.options ? this.item.attributes.options["VERSION_3"] : "",
            this.item.attributes.options ? this.item.attributes.options["VERSION_4"] : "",
          ],
          placeholder: window.lang.campaigns.subjectPlaceholder,
        },
        "#vue-subject-main",
      );
    } else {
      mountComponent(
        SimpleSubjectInput,
        {
          name: "subject",
          subject: this.item.attributes.contents ? this.item.attributes.contents[0].subject : "",
          fields: this.item.additionalsData.fields,
          placeholder: window.lang.campaigns.subjectPlaceholder,
        },
        "#vue-subject-main",
      );
    }

    if (app.session.attributes.details.account.features.SENDERSV2) {
      mountComponent(
        SenderSelector,
        {
          senderId: this.item.attributes.contents?.[0].from && this.item.attributes.options?.SENDER_ID,
        },
        "#vue-sender-selector",
      );
    }
  },

  mountVueComponentsResend: function () {
    if (this.item.attributes.type === "SIMPLE") {
      mountComponent(
        SimpleSubjectInput,
        {
          name: "options[RESEND_SUBJECT]",
          subject: this.item.attributes.options?.RESEND_SUBJECT ? this.item.attributes.options.RESEND_SUBJECT : "",
          fields: this.item.additionalsData.fields,
          placeholder: window.lang.campaigns.resendSubjectPlaceholder,
          dropdownsUpwards: true,
        },
        "#vue-subject-resend",
      );
    }
  },

  // After change from hook
  afterChangeFrom: function (from, fromNameField) {
    if (app.session.attributes.details.account.features.SENDERSV2) return;

    var fromName;
    if (_.isDefined(this.item.attributes.contents)) {
      this.item.attributes.contents[0].from = from.email;
      this.item.attributes.defaultContent.from = from.email;
    }
    fromName = this.item.defaultContent().fromName || from.name;
    if (fromNameField.data("original-value")) {
      fromNameField.removeAttr("original-value").data("original-value", false);
    } else {
      fromNameField.val(fromName).attr("data-changed", 1);
    }
    return this;
  },

  // Runs after verifying a from
  verifiedCallback: function (response) {
    var from = response.data,
      html = this.renderFromOption(from);
    this.currentSelect.children().last().before(html);
    this.currentSelect.val(from.email).trigger("change");
  },

  // Manage recipients
  manageRecipients: function () {
    var view = this,
      include = this.item.get("include"),
      exclude = this.item.get("exclude");
    this.item.set("includeAllContacts", false);
    this.item.set("includeAllContactsByDefault", false);
    this.item.set("dontExcludeContacts", false);

    // Skip if the item is new
    if (this.item.isNew()) {
      return false;
    }

    // Remove list zero from include lists
    if (_.isDefined(include.lists) && _.pluck(include.lists, "id").pop() === 0) {
      this.item.set("includeAllContacts", true);
      delete this.item.attributes.include.lists;
    }

    // Flag campaigns with no exclusions
    if ($.isPlainObject(exclude) && _.size(exclude) === 0 && !this.item.get("ignoreLastMailedDays")) {
      this.item.set("dontExcludeContacts", true);
    }

    // Parse campaigns
    ["include", "exclude"].forEach(function (key) {
      if (objHas(view.item.get(key), "campaigns")) {
        var campaigns = view.item.get(key).campaigns,
          action;

        // Get include action
        action = _.first(_.pluck(campaigns, "action"));
        view.item.set(key + "Action", action);

        // Get links
        if (action === "CLICKED" && _.compact(_.pluck(campaigns, "linkId")).length) {
          view.item.attributes[key].links = campaigns.map(function (campaign) {
            return {
              id: campaign.linkId,
              name: campaign.linkName,
              campaignName: campaign.name,
              campaignId: campaign.id,
            };
          });
        }

        // Make campaigns unique
        view.item.attributes[key].campaigns = _.uniq(campaigns, function (item) {
          return item.id.toString();
        });
      }
    });
  },

  // Casts the advanced options as booleans
  manageOptions: function () {
    var view = this;
    if (this.item.isNew()) {
      return false;
    }
    _.each(this.socialHeaderOptions, function (option) {
      view.item.attributes.options[option] = view.item.attributes.options[option] === "1" ? true : false;
    });
  },

  // Render the tags
  renderTags: function () {
    var view = this,
      tagsInput = $(".tags-input"),
      options = tagsInput.data("options"),
      tags = new window.Bloodhound({
        datumTokenizer: window.Bloodhound.tokenizers.obj.whitespace("id"),
        queryTokenizer: window.Bloodhound.tokenizers.whitespace,
        local: options,
      }),
      lastRemoved = false;

    // Setups the typeahead tags component
    tags.initialize();
    tagsInput.tagsinput({
      tagClass: "label label-outline",
      addOnBlur: false,
      typeaheadjs: {
        name: "tags",
        displayKey: "id",
        valueKey: "id",
        source: tags.ttAdapter(),
      },
    });

    // Manage focus and blur
    this.$(".tt-input")
      .on("focus", function (e) {
        var target = $(e.currentTarget);
        target.addClass("tt-editing").val("").removeAttr("value");
        lastRemoved = false;
      })
      .on("blur", function (e) {
        var target = $(e.currentTarget),
          value = target.val(),
          internal = target.data("internal"),
          list = tagsInput.tagsinput("items");
        if ($.inArray(value, list) === -1 && lastRemoved !== internal) {
          tagsInput.tagsinput("add", value);
          tagsInput.attr("data-changed", 1);
        }
        target.removeClass("tt-editing").val("").removeAttr("value");
      });

    // Check last removed item
    tagsInput.on("itemRemoved", function (e) {
      lastRemoved = e.item;
      tagsInput.attr("data-changed", 1);
      view.$(".tt-input").data("internal", lastRemoved);
    });
  },

  // Prevent returning to the previous page
  back: function () {
    return app.router.navigateToSectionRoot();
  },

  // Gets a form data
  getFormData: function (form) {
    form = form || $("[data-form]:visible").get(0);

    var savingRecipients = $(form).data("recipients") ? true : false,
      isDirty = $(form).data("dirty") ? true : false,
      tagsChanged = this.$(".tags-input").data("changed"),
      data = this.getFormFieldsObject(form, !savingRecipients);

    // Add unchecked values to the options data
    ["TRACK_LINKS", "GOOGLE_ANALYTICS", "RESEND_ENABLED", "EXCLUDE_INACTIVE"].forEach(function (field) {
      var checkbox = $(form).find('[name="options[' + field + ']"]');
      if (checkbox.attr("data-changed") === "1") {
        data.options = data.options || {};
        data.options[field] = checkbox.is(":checked") ? 1 : 0;
      }
    });

    // Add extra check for recipients
    if (savingRecipients) {
      data = isDirty ? this.getRecipientsData(data, form) : [];
    }

    // Make tags unique
    if (_.isDefined(data.tags)) {
      data.tags = _.uniq(data.tags);
    } else {
      if (tagsChanged && this.item.has("tags") && this.item.get("tags").length) {
        data.tags = [];
      }
    }

    // Return complete data
    return data;
  },

  // Get data from recipients
  getRecipientsData: function (data, form) {
    var links,
      errors = {};

    // Set inclusion
    if (_.isDefined(data.includeType) || _.isDefined(data.include)) {
      if (data.includeType === "all") {
        data.include = { lists: [0] };
      }
      if (data.includeType === "lists") {
        if (!objHas(data, "include", "lists") || !data.include.lists.length) {
          errors["include-lists"] = true;
        }
      }
      if (data.includeType === "campaigns") {
        if (!objHas(data, "include", "campaigns") || !data.include.campaigns.length) {
          errors["include-campaigns"] = true;
        } else {
          if (_.isDefined(data.include.links)) {
            links = $.extend({}, data.include.links);
            delete data.include.links;
          }
          if (data.includeAction === "CLICKED" && data.includeLink === "selected" && !_.size(links)) {
            errors["include-links"] = true;
          }
          data.include = {
            campaigns: this.resolveCampaignRecipients(data.includeAction || false, data.include.campaigns || []),
          };
          data.include.campaigns = this.resolveCampaignClicks("include", data, _.values(links));
          if (!data.include.campaigns) {
            errors["include-links"] = true;
          }
        }
      }
    }

    // Set exclusion
    if (_.isDefined(data.excludeContacts) && data.excludeContacts) {
      if (_.isDefined(data.excludeLists)) {
        if (!objHas(data, "exclude", "lists") || !data.exclude.lists.length) {
          errors["exclude-lists"] = true;
        }
      }
      if (_.isDefined(data.excludeCampaigns)) {
        if (!objHas(data, "exclude", "campaigns") || !data.exclude.campaigns.length) {
          errors["exclude-campaigns"] = true;
        } else {
          data.exclude = data.exclude || {};
          if (_.isDefined(data.exclude.links)) {
            links = $.extend({}, data.exclude.links);
            delete data.exclude.links;
          }
          data.exclude.campaigns = this.resolveCampaignRecipients(
            data.excludeAction || false,
            data.exclude.campaigns || [],
          );
          data.exclude.campaigns = this.resolveCampaignClicks("exclude", data, _.values(links));
          if (!data.exclude.campaigns) {
            errors["exclude-links"] = true;
          }
        }
      }
      if (_.isUndefined(data.excludeLists) && _.isUndefined(data.excludeCampaigns) && _.isUndefined(data.excludeDays)) {
        data.exclude = {};
        data.ignoreLastMailedDays = 0;
      }
    } else {
      data.exclude = {};
      data.ignoreLastMailedDays = 0;
    }

    // Remove interests if the checkbox is unchecked
    ["include", "exclude"].forEach(function (type) {
      if (
        !$("[name=" + type + "Interests]").is(":checked") &&
        objHas(data, type, "interests") &&
        _.size(data[type].interests)
      ) {
        delete data[type].interests;
      }
    });

    // Remove ignored days if the checkbox is unchecked
    if (_.isUndefined(data.ignoreLastMailedDays) && this.item.get("ignoreLastMailedDays")) {
      data.ignoreLastMailedDays = 0;
    }

    // Remove extra data
    this.extraFormField.forEach(function (field) {
      if (_.isDefined(data[field])) {
        delete data[field];
      }
    });

    // Validate
    if (_.size(errors)) {
      this.cleanFormErrorMessages(form);
      this.showValidationErrors(errors, $(form));
      return false;
    }

    // Return
    return data;
  },

  // Resolves campaigns recipients
  resolveCampaignRecipients: function (action, list) {
    if (!action) {
      action = "SENT";
    }
    if (_.isDefined(list) && _.isArray(list)) {
      return list.map(function (value) {
        return { id: parseInt(value, 10), action: action };
      });
    }
    return [];
  },

  // Returns an object with campaigns and links
  resolveCampaignClicks: function (type, data, links) {
    // Return unaltered if there are no specific links selected
    if (!links.length || data[type + "Action"] !== "CLICKED" || data[type + "Link"] !== "selected") {
      return data[type].campaigns;
    }

    // Create object with campaign and links IDs
    data[type].campaigns = [];
    links.forEach(function (linkId) {
      var campaignId = $("." + type + 'd [data-link-checkbox][value="' + linkId + '"]').data("campaign");
      data[type].campaigns.push({
        id: campaignId,
        action: "CLICKED",
        linkId: parseInt(linkId, 10),
      });
    });

    // Return campaigns with links
    return data[type].campaigns;
  },

  // Runs before updating a model
  beforeUpdate: function (attributes, form) {
    var errors = this.validateRecipients(attributes);

    // If errors where found, show errors and cancel the submission
    if (_.size(errors)) {
      this.showValidationErrors(errors, $(form));
      return false;
    }

    // Otherwise, continue the update success
    return true;
  },

  // Validates the recipients
  validateRecipients: function (data) {
    var errors = {};

    // Check each rule
    ["include", "exclude"].forEach(function (index, type) {
      if (data[type + "Type"] === "lists" && (objHas(data, type, "lists") || !data[type].lists.length)) {
        errors[type + "-lists"] = true;
      }
      if (data[type + "Interests"] && (objHas(data, type, "interests") || !data[type].interests.length)) {
        errors[type + "-interests"] = true;
      }
      if (data[type + "Type"] === "campaigns" && (objHas(data, type, "campaigns") || !_.size(data[type].campaigns))) {
        errors[type + "-campaigns"] = true;
      }
    });

    // Return
    return errors;
  },

  // Changes the tracking links
  toggleTracking: function (e) {
    var title = window.lang.confirm,
      body = window.lang.campaigns.confirmTrackingOff,
      tracking = $(e.currentTarget),
      analytics = $("#google-analytics");
    if (tracking.is(":checked")) {
      analytics.attr("disabled", false).trigger("change");
    } else {
      this.confirmModal(
        body,
        tracking,
        title,
        "warning",
        "data-confirm",
        function () {
          analytics.prop("checked", false).trigger("change").attr("disabled", true);
          tracking.prop("checked", false);
        },
        function () {
          tracking.prop("checked", true).removeAttr("data-changed");
        },
      );
    }
  },

  // Toggles the recipients options
  toggleRecipientOptions: function (e) {
    var radio = $(e.currentTarget),
      wrapper = radio.closest(".option"),
      options = wrapper.find(".collapsable.disabled");
    wrapper.siblings().find(".collapsable").removeClass("in").find("input").attr("disabled", "disabled");
    if (radio.is(":checked")) {
      options.addClass("in").find("[disabled]").removeAttr("disabled");
    }
  },

  // Toggles the exclusion options
  toggleExcludeOptions: function (e) {
    var checkbox = $(e.currentTarget),
      options = checkbox.closest(".option").find(".collapsable.disabled");
    if (checkbox.is(":checked")) {
      options.addClass("in").find("[disabled]").removeAttr("disabled");
    } else {
      options.removeClass("in").find("input").attr("disabled", "disabled");
    }
  },

  // Runs after updating a model
  afterUpdate: function (attributes) {
    // Show updated message
    if (_.size(attributes)) {
      this.showUpdatedMessage(attributes);
      this.shouldReloadSummary = true;
    }

    if (this.useUnlayer && attributes.name) {
      $.ajax({
        type: "PUT",
        url: "apiv3://templates/" + this.unlayerTemplateId,
        data: JSON.stringify({
          name: attributes.name,
        }),
        dataType: "json",
        contentType: "application/json",
      });
    }

    // Remove changed flag from inputs
    $("[data-changed]").removeAttr("data-changed").removeData("changed");

    // Perform callback
    if (this.afterUpdateCallback) {
      return this.afterUpdateCallback();
    }

    // Return to list
    if (this.returnToListAfterSave) {
      return app.router.navigateToSectionRoot();
    }

    // Show target tab
    this.changeToTab($(this.targetTab).data("target"));
  },

  // Shows a tab without triggering Bootstrap events
  changeToTab: function (target) {
    var targetTab = $("[data-target=" + target + "]").get(0);
    $(targetTab).parent("li").addClass("active").siblings().removeClass("active");
    $(target).addClass("active").siblings().removeClass("active");
    this.afterTabChange({ currentTarget: targetTab });
  },

  // Runs before the tab change
  beforeTabChange: function (e) {
    var form = $("[data-form]:visible").first().get(0);

    // Get current form fields
    var attributes = this.getFormData();

    // Prevent tab change if the current form is invalid
    if (!attributes || !this.validateFormFields(form)) {
      return false;
    }

    // Save the target tab
    if (e) {
      this.targetTab = e.relatedTarget;
    }

    // If there are valid changes, submit the change
    if (_.size(attributes)) {
      $(form).submit();
      return false;
    }

    // Change otherwise
    return true;
  },

  // Runs after the tab change
  afterTabChange: function (e) {
    $("[data-form=save]").removeAttr("data-dirty");
    this.$(".content").scrollTop(0);

    // Summary
    if ($(e.currentTarget).data("target") === "#summary") {
      this.showSummary();
    }

    // Contents
    if ($(e.currentTarget).data("target") === "#content") {
      if (this.useUnlayer) {
        this.mountVueComponents();
        this.templatePreviewComponent?.refreshHeight();
      } else {
        var from = this.$("[name=from]");
        //fromName = this.$("[name=fromName]");

        // Show from legend
        if (from.val() && from.val() !== "new") {
          from.trigger("change").removeAttr("data-changed");
          //fromName.removeAttr('data-changed');
        }

        // Show HTML content in iframe
        if (_.isUndefined(this.item.attributes.previewLoaded)) {
          this.item.attributes.previewLoaded = true;
          this.renderPreview();
          if (_.isDefined(this.item.additionalsData.html) && _.isUndefined(this.item.attributes.iframeLoaded)) {
            this.item.attributes.iframeLoaded = true;
            this.renderIframePreviewWithTimeout();
          }
        }
      }
    }
  },

  // Opens a modal to save the current content as a template
  openSaveContentAsTemplateModal: function (e) {
    e.preventDefault();
    this.openModal(require("../templates/modals/saveContentAsTemplate.hbs"), this.renderData());
  },

  // Save content as a template
  saveContentAsTemplate: function (e) {
    var form = $(e.currentTarget),
      name = form.serializeObject().name,
      type = this.item.defaultContent().type,
      html = this.item.additionalsData.html,
      json = this.item.additionalsData.json,
      view = this;
    e.preventDefault();
    $.ajax({
      type: "POST",
      url: "templates",
      data: { name: name, type: type },
      success: function (response) {
        var url = "templates" + response.data.id;
        if (type === "EDDIE") {
          $.ajax({
            type: "PUT",
            data: JSON.stringify(json),
            url: url + ".json",
            dataType: "json",
            contentType: "application/json",
            success: function () {
              $.ajax({
                type: "PUT",
                dataType: "html",
                data: html,
                url: url + ".html",
                success: function () {
                  view.displayMessage(window.lang.campaigns.feedback.contentSavedAsTemplate);
                  view.getModal().modal("hide");
                },
              });
            },
          });
        } else {
          $.ajax({
            type: "PUT",
            dataType: "html",
            data: html,
            url: url + ".html",
            success: function () {
              view.displayMessage(window.lang.campaigns.feedback.contentSavedAsTemplate);
              view.getModal().modal("hide");
            },
          });
        }
      },
    });
  },

  // Opens a modal to set social header information
  openSocialHeaderModal: function (e) {
    e.preventDefault();
    this.openModal(require("../templates/modals/socialHeader.hbs"), this.renderData());
  },

  // Set recipients form as dirty
  setRecipientsFormAsDirty: function (e) {
    $(e.currentTarget).closest("form").attr("data-dirty", 1);
  },

  // Disables or enables the header options based on the use header option
  toogleHeaderOptions: function () {
    var modal = $(this.modal),
      useHeader = modal.find("[name=USE_HEADER]").is(":checked"),
      checkboxes = modal.find(".social-options input");
    if (!useHeader) {
      checkboxes.attr("disabled", true);
    } else {
      checkboxes.removeAttr("disabled");
    }
  },

  // Deletes the current content
  deleteContent: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Save the changes via API
    this.item.deleteContents().done(
      function () {
        this.shouldReloadSummary = true;
        this.item.attributes.contents[0] = $.extend({}, this.item.defaultContent(), { type: null });
        this.item.attributes.defaultContent = this.item.attributes.contents[0];
        this.item.additionalsData.html = false;
        this.item.additionalsData.json = false;
        this.renderPreview();
      }.bind(this),
    );
  },

  // Save advanced options
  saveSocialHeader: function (e) {
    var form = $(e.currentTarget),
      options = {},
      modal = $(this.modal);
    e.preventDefault();

    // Get options
    form.find("input").each(function () {
      if ($(this).attr("type") === "checkbox") {
        options[$(this).attr("name")] = $(this).is(":checked") ? 1 : 0;
      } else {
        options[$(this).attr("name")] = $(this).val();
      }
    });

    // Update content advanced options
    this.item.update({ options: options }).done(
      function () {
        this.item.set("options", options);
        modal.modal("hide");
        this.renderIframePreviewWithTimeout();
      }.bind(this),
    );
  },

  // Opens the advanced options modal
  openContentAdvancedOptionsModal: function () {
    this.openModal(require("../templates/modals/contentAdvancedOptions.hbs"), this.renderData());
  },

  // Save advanced options
  saveContentAdvancedOptions: function (e) {
    var form = e.currentTarget,
      data = this.getFormData(form),
      modal = $(this.modal);
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Abort if there are no changes
    if (!_.size(data)) {
      return modal.modal("hide");
    }

    // Save the changes via API
    this.item.updateContents(data).done(
      function () {
        this.item.attributes.contents[0] = $.extend({}, this.item.defaultContent(), data);
        this.item.attributes.defaultContent = this.item.attributes.contents[0];
        this.renderIframePreviewWithTimeout();
        modal.modal("hide");
      }.bind(this),
    );
  },

  // Opens the send test modal
  openSendTestModal: function () {
    var form = $("[data-form=save-contents]");
    if (form.find("[data-changed]").length) {
      this.afterUpdateCallback = function () {
        this.renderTestModal();
      }.bind(this);
      form.submit();
    } else {
      this.renderTestModal();
    }
  },

  // Shows the send test modal
  renderTestModal: function () {
    var view = this;
    $.getJSON(
      "campaigns/testcontacts",
      function (response) {
        this.openModal(
          require("../templates/modals/sendTest.hbs"),
          $.extend({}, this.renderData(), { testRecipients: response.data }),
          function (modal) {
            modal.on("hidden.bs.modal", function () {
              delete view.afterUpdateCallback;
            });
          },
        );
      }.bind(this),
    );
  },

  // Send recipients test
  sendTest: function (e) {
    var view = this,
      form = e.currentTarget,
      emails = $(form).find("textarea").val(),
      errors = {};
    e.preventDefault();

    // Replace spaces, semicolons and new lines by commas and split
    emails = _.compact(
      emails
        .replace(/[ |;]/g, ",")
        .replace(/\r?\n|\r/g, ",")
        .split(","),
    );

    // Remove previos errors
    this.cleanFormErrorMessages(form);

    // Validate emails
    emails.forEach(function (email) {
      if (!view.rules.email.test(email)) {
        errors.emails = window.lang.campaigns.invalidEmails;
      }
    });
    if (_.size(errors)) {
      return this.showValidationErrors(errors, $(form));
    }

    // Send test
    this.item.sendTest({ emails: emails }).done(
      function () {
        view.displayMessage(window.lang.campaigns.feedback.testCampaignSent);
        $(view.modal).modal("hide");
      }.bind(this),
    );
  },

  // Opens a modal to move items
  openSelectTemplateModal: function (e) {
    e.preventDefault();
    this.openModal(require("../templates/modals/fromTemplate.hbs"), this.renderData());
    this.exploreFolders();
  },

  // Explores the shared folders
  exploreFolders: function (e) {
    var view = this,
      template = require("../templates/modals/folders.hbs"),
      target = this.$("[data-role=folder-list]"),
      path = "",
      parentFolder = false,
      targetName = window.lang.root,
      targetLocation = "/",
      locationInput = $(this.modal).find("[name=location]");

    // If needed, prevent the event default
    if (e && _.isDefined(e.preventDefault)) {
      e.preventDefault();

      // Get path parameter
      path = $(e.currentTarget).data("folder-path");

      // Get parent folder path
      if (path !== "/") {
        if (path.substr(-1) === "/") {
          path = path.substr(0, path.length - 1);
        }
        parentFolder = path.substring(0, path.lastIndexOf("/") + 1);
      }

      // Get folder name
      targetName = $(e.currentTarget).data("name");

      // Get folder ID
      targetLocation = $(e.currentTarget).data("folder-path");
    }

    // Turn on loader
    this.loading(true);

    // Request folder list
    $.get(
      "templates" + path,
      {
        limit: 1000,
        sortBy: "created",
        sortDir: "desc",
      },
      function (response) {
        // Render explorer
        target.html(
          template({
            data: response.data,
            parentFolder: parentFolder,
            targetName: targetName,
            lang: window.lang,
          }),
        );

        // Set value of location input
        locationInput.val(targetLocation);

        // Turn off loader
        view.loading(false);
      },
    );
  },

  // Explores the shared folders
  searchFolders: function (e) {
    var view = this,
      template = require("../templates/modals/folders.hbs"),
      target = this.$("[data-role=folder-list]"),
      searchInput = $("[data-input=search-folder]"),
      targetName = window.lang.root,
      locationInput = $(this.modal).find("[name=location]");
    e.preventDefault();

    // Set value of location input
    locationInput.val("");

    // Turn on loader
    this.loading(true);

    // Request folder list
    $.ajax({
      url: "templates",
      data: {
        q: "!*" + searchInput.val() + "*",
        limit: 1000,
      },
      success: function (response) {
        // Render explorer
        target.html(
          template({
            data: response.data,
            parentFolder: "/",
            searchTerm: searchInput.val(),
            targetName: targetName,
            lang: window.lang,
          }),
        );

        // Turn off loader
        view.loading(false);
      },
    });
  },

  // Update content creation
  createFromTemplate: function (e) {
    var view = this,
      modal = $(this.modal),
      data = $(e.currentTarget).data("attributes"),
      feedback = window.lang.campaigns.feedback.contentSaved;
    e.preventDefault();

    // Update campaign contents type
    this.item.updateContents({ type: data.type }).done(function () {
      // Get and save HTML
      $.get("templates" + data.id + ".html").always(function (response) {
        if (response && _.isDefined(response.responseText)) {
          view.saving = { html: response.responseText };

          // Get and save JSON
          if (data.type == "EDDIE") {
            $.get("templates" + data.id + ".json").done(function (response) {
              view.saving.json = response;
              view.item
                .saveBody({
                  html: view.saving.html,
                  json: JSON.stringify(response),
                })
                .done(function () {
                  modal.modal("hide");
                  view.displayMessage(feedback);
                  view.afterContentSave();
                });
            });
          } else {
            view.item.saveBody(view.saving).done(function () {
              modal.modal("hide");
              view.displayMessage(feedback);
              view.afterContentSave();
            });
          }
        }
      });
    });
  },

  // Opens a modal to create an HTML content
  openCreateHtmlContentModal: function (e) {
    var view = this;
    e.preventDefault();

    // Save references to common elements
    this.uploader = {
      progressBar: ".upload-progress",
      loading: ".loading",
      container: ".upload-container",
      dropzone: ".dropzone",
    };

    // Open modal
    this.openModal(require("../templates/modals/htmlTemplate.hbs"), this.renderData(), function () {
      var textarea = $("[name=htmlRaw]").get(0);

      // Start CodeMirror editor
      view.codeMirror = window.CodeMirror.fromTextArea(textarea, {
        lineNumbers: true,
        autofocus: false,
        mode: {
          name: "htmlmixed",
          scriptTypes: [
            { matches: /\/x-handlebars-template|\/x-mustache/i, mode: null },
            {
              matches: /(text|application)\/(x-)?vb(a|script)/i,
              mode: "vbscript",
            },
          ],
        },
      });
      view.codeMirror.refresh();
    });
  },

  // Add active class to hovered dropzone
  addDropHighlight: function () {
    $(this.uploader.dropzone).addClass("active");
  },

  // Remove active class from hovered dropzone
  removeDropHighlight: function () {
    $(this.uploader.dropzone).removeClass("active");
  },

  // Manages dropped files
  getDroppedFiles: function (e) {
    var files = e.originalEvent.dataTransfer.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    this.previewFile(files[0]);
  },

  // Open the file selector
  openFileSelection: function () {
    $("[data-input-type=upload-file]").trigger("click");
  },

  // Manage selected files
  getSelectedFiles: function (e) {
    var files = e.currentTarget.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    this.previewFile(files[0]);
  },

  // Show selected file name
  previewFile: function (file) {
    // Check file type
    if (_.isUndefined(file.type) || file.type !== "text/html") {
      return alert("Incorrect type");
    }

    // Update inteface
    $(this.uploader.dropzone).hide();
    $(this.modal).find(".filename").text(file.name);
    $(this.modal).find('[data-validation-message="htmlFile"]').addClass("error-hidden");

    // Save file reference
    this.file = file;
  },

  // Creates an HTML template
  createHtmlContent: function (e) {
    var form = $(e.currentTarget),
      fields = form.serializeObject(),
      modal = $(this.modal),
      validationErrors = {};
    if (e) {
      e.preventDefault();
    }

    // Check if a file is selected on file source
    if (fields.source === "file") {
      if (!this.file) {
        validationErrors.htmlFile = window.lang.fileRequired;
      } else {
        this.getFileContent(this.file, "saveContent");
      }
    }

    // Check if a file is selected on file source
    if (fields.source === "raw") {
      if (_.isUndefined(fields.htmlRaw) || fields.htmlRaw.trim() === "") {
        validationErrors.htmlRaw = window.lang.invalidRequired;
      } else {
        this.saveContent(fields.htmlRaw);
      }
    }

    // Show errors
    if (_.size(validationErrors)) {
      return this.showValidationErrors(validationErrors, modal);
    }
  },

  // Get the HTML from a uploaded file
  getFileContent: function (file, callback) {
    var view = this,
      reader;
    if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
      alert("The File APIs are not fully supported in this browser.");
      return;
    }
    reader = new FileReader();
    reader.onloadend = function () {
      if (callback) {
        return view[callback](reader.result);
      } else {
        return reader.result;
      }
    };
    reader.readAsText(file);
  },

  // Saves the content fields
  saveContents: function (e) {
    var data = this.getFormData(e.currentTarget);
    e.preventDefault();

    // Remove previous errors
    this.cleanFormErrorMessages(e.currentTarget);

    // If there's nothing to send, just change the tab or return to the list
    if (!_.size(data)) {
      if (this.returnToListAfterSave) {
        return app.router.navigateToSectionRoot();
      } else {
        return this.afterUpdate(data);
      }
    }

    const campaignOptions = {};

    if (this.item.attributes.type === "SPLIT_AB") {
      campaignOptions.VERSION_1 = "";
      campaignOptions.VERSION_2 = "";
      campaignOptions.VERSION_3 = "";
      campaignOptions.VERSION_4 = "";

      let index = 1;
      this.subjectVersionsVueInstance.versions.forEach((version) => {
        campaignOptions[`VERSION_${index}`] = version.subject;
        if (index === 1) data.subject = version.subject;
        index++;
      });
    }

    if (app.session.attributes.details.account.features.SENDERSV2) {
      if (data.senderId) {
        campaignOptions.SENDER_ID = data.senderId;
        this.item.attributes.options.SENDER_ID = data.senderId;
        delete data.senderId;
      }
      if (data.replyTo === "" && data.from !== "") data.replyTo = data.from;
      if (data.email === "") delete data.email;
      if (data.name === "") delete data.name;
      if (data.replyTo === "") delete data.replyTo;
    }

    if (_.size(campaignOptions)) {
      this.item.update({ options: campaignOptions });
    }

    if (_.size(data)) {
      // si no esta vacio data
      // Save the changes via API
      this.item.updateContents(data).done(
        function () {
          this.item.attributes.contents[0] = $.extend({}, this.item.defaultContent(), data);
          this.afterUpdate(data);
        }.bind(this),
      );
    } else {
      this.afterUpdate(data);
    }
  },

  // Shows the summary
  showSummary: function () {
    var template = this.templates.summary,
      renderData = this.renderData(),
      tab = $("#summary"),
      box = tab.find("[data-role=content]"),
      loader = tab.find(".loading-box");

    // If there were no changes, do not request the validation
    if (_.isUndefined(this.shouldReloadSummary) || !this.shouldReloadSummary) {
      return false;
    }

    // Show loader
    loader.removeClass("hidden");
    box.addClass("hidden");

    // If the item is saving, try again later
    if ($.active > 0) {
      return window.setTimeout(
        function () {
          this.showSummary();
        }.bind(this),
        1000,
      );
    }

    // Manage recipients
    this.manageRecipients();

    // Update default content
    renderData.data.attributes.defaultContent = this.item.defaultContent();

    if (this.item.attributes.type === "SPLIT_AB") {
      const subjects = [];

      if (!this.useUnlayer) {
        this.subjectVersionsVueInstance.versions.forEach((version) => {
          subjects.push(version.subject);
        });
      } else {
        [
          this.item.attributes.options.VERSION_1,
          this.item.attributes.options.VERSION_2,
          this.item.attributes.options.VERSION_3,
          this.item.attributes.options.VERSION_4,
        ].forEach((version) => {
          if (version) subjects.push(version);
        });
      }

      renderData.subjects = subjects;
    }

    this.validation = null;
    this.count = { available: "TBD", canSend: true, required: "TBD" };

    this.item.validateData().then(
      function (validationResponse) {
        this.validation = validationResponse.data;
        this.item
          .countRecipients()
          .then(
            function (countResponse) {
              this.count = countResponse.data;
            }.bind(this),
          )
          .then(() => {
            return this.item.validateSender();
          })
          .always(
            function (senderOk) {
              console.log("senderOk", senderOk);

              var validation = $.extend({}, this.validation, {
                count: this.count,
              });
              if (!validation.count.required) {
                validation.valid = false;
                validation.tests.recipients.valid = false;
              }
              if (!validation.count.canSend) {
                validation.valid = false;
                validation.tests.recipients.valid = false;
              }

              if (!senderOk) {
                validation.valid = false;
                validation.tests.from.valid = false;
                validation.tests.from.errors = [{ message: window.lang.campaigns.fromAuthError }];
              }
              //por ahora solo impido enviar cuando el from está en CHANGE_REQUIRED a las free y trial
              var planType = app.session.attributes.details.plan
                ? app.session.attributes.details.plan.type
                : app.session.attributes.details.account.plan.type;
              if (
                app.session.attributes.details.account.features &&
                !app.session.attributes.details.account.features.ALLOW_FAILED_FROM &&
                ((app.getTrial() && app.getTrial().inTrial) || planType === "FREE")
              ) {
                if (
                  validation.tests.from.warnings &&
                  validation.tests.from.warnings.length &&
                  validation.tests.from.warnings[0].code === "FROM_CHANGE_REQUIRED"
                ) {
                  validation.valid = false;
                  validation.tests.from.valid = false;
                  validation.tests.from.message = "";
                }
              }

              if (
                (validation.tests.from.warnings && validation.tests.from.warnings.length) ||
                (validation.tests.from.errors && validation.tests.from.errors.length)
              ) {
                validation.tests.from.valid = false;
              }

              this.item.set("validation", validation);
              renderData.validation = validation;

              loader.addClass("hidden");
              box.html(template(renderData)).removeClass("hidden");
              app.layout.hideForbiddenElements();
              this.shouldReloadSummary = false;

              if (this.item.attributes.type === "SMART") {
                mountComponent(
                  SmartScheduler,
                  {
                    disabled: !validation.valid,
                    view: this,
                  },
                  "#vue-smart-scheduler",
                );
              }
            }.bind(this),
          );
      }.bind(this),
    );
  },

  launchSmartCampaign: function (options) {
    const view = this;
    const recipients = this.item.get("validation").count.required;
    const data = {
      launchDate: options.from,
      options: { JSON: JSON.stringify(options) },
    };

    let message;
    if (recipients === "TBD") {
      message = window.lang.campaigns.confirmScheduleUndefinedRecipients;
    } else {
      message = window.lang.campaigns.confirmSchedule.replace("###", recipients);
    }

    // Show confirm modal
    return this.confirmModal(
      message,
      false,
      window.lang.campaigns.scheduleLaunch,
      "info",
      "data-confirm-launch",
      function () {
        view.item.update(data).done(
          function () {
            view.launch({
              launchMode: "SCHEDULED",
              launchDate: options.from,
            });
          }.bind(view),
        );
      },
      function () {
        view.getModal().modal("hide");
      },
    );
  },

  // Runs after creating a model
  afterCreate: function (attributes) {
    const self = this;

    const doAfterCreate = () => {
      this.showCreatedMessage(attributes);
      localStorage.removeItem("tags");
      if (this.returnToListAfterSave) {
        return app.router.navigateToSectionRoot();
      }
      app.router.navigateTo(`campaigns/${this.item.id}`);
      window.target = $(this.targetTab).data("target");
    };

    if (!this.options.useUnlayer) {
      doAfterCreate();
    } else {
      let options,
        relation_type = "campaign_simple";

      if (this.options.type === "SPLIT_AB") {
        options = { subjects: ["", ""] };
        relation_type = "campaign_testab";
      } else if (this.options.type === "SMART") {
        relation_type = "campaign_smart";
      }

      const account = window.app.session?.attributes?.account;
      const user = window.app.session?.attributes?.user;
      const accountUserConfigs = JSON.parse(window.localStorage.getItem("perfitUserConfig"));

      const userConfig = accountUserConfigs?.[account]?.userConfig?.[user];

      const monitoringOptions = userConfig?.templatesEditorConfig?.monitoringOptions;

      const onboardingStore = useOnboardingStore();
      onboardingStore.submitUserEvent({
        action: "campaign.created",
        timestamp: this.item.get("created"),
        data: {
          id: this.item.get("id"),
          name: this.item.get("name"),
        },
      });

      //TODO manejar errores
      $.ajax({
        type: "POST",
        url: "apiv3://templates",
        data: JSON.stringify({
          name: this.item.get("name"),
          type: "unlayer",
          relation_type,
          relation_id: this.item.get("id"),
          options,
          tracking: monitoringOptions
            ? {
                click: monitoringOptions.monitorClicks,
                open: monitoringOptions.monitorOpenings,
                utm: {
                  enabled: monitoringOptions.trackAnalytics,
                  campaign: "",
                  source: "perfit",
                  medium: "email",
                  content: "",
                },
              }
            : {
                click: true,
                open: true,
                utm: {
                  enabled: true,
                  campaign: "",
                  source: "perfit",
                  medium: "email",
                  content: "",
                },
              },
        }),
        dataType: "json",
        contentType: "application/json",
      }).done((response) => {
        self.unlayerTemplateId = response.data.id;
        self.useUnlayer = true;
        self.item.attributes.options.UNLAYER_TPL_ID = self.unlayerTemplateId;

        $.ajax({
          type: "PUT",
          url: "campaigns/" + this.item.id,
          data: JSON.stringify({ options: { UNLAYER_TPL_ID: this.unlayerTemplateId } }),
          dataType: "json",
          contentType: "application/json",
        }).done(() => {
          doAfterCreate();
        });
      });
    }
  },

  // Shows the datetime picker to schedule a campaign
  openScheduleModal: function () {
    const renderData = {
      ...this.renderData(),
      timezone: window.config.timezone,
      localTime: moment().tz(window.config.timezone).format("HH:mm"),
    };

    return this.openModal(require("../templates/modals/schedule.hbs"), renderData, function () {
      $("#datetimepicker").datetimepicker({
        inline: true,
        locale: app.lang,
        sideBySide: true,
        format: "YYYY-MM-DD HH:mm",
        minDate: moment().tz(window.config.timezone),
        timeZone: window.config.timezone,
      });
    });
  },

  // Launchs the campaign inmediatly
  launchNow: function (e) {
    var data = { launchMode: "NOW" },
      recipients = this.item.get("validation").count.required,
      button = $(e.currentTarget),
      message;
    e.preventDefault();

    if (recipients === "TBD") {
      message = window.lang.campaigns.confirmLaunchNowUndefinedRecipients;
    } else {
      message = window.lang.campaigns.confirmLaunchNow.replace("###", recipients);
    }

    // Show confirm modal
    if (button.attr("data-confirm-launch")) {
      return this.confirmModal(message, button, window.lang.campaigns.launchNow, "info", "data-confirm-launch");
    }

    // Update campaign and send launch action
    this.item.update(data).done(
      function () {
        this.item.set(data);
        this.launch();
      }.bind(this),
    );
  },

  // Schedules the campaign
  schedule: function (e) {
    var data = $(e.currentTarget).serializeObject(),
      recipients = this.item.get("validation").count.required,
      view = this,
      message;

    e.preventDefault();
    data.launchDate = moment.tz(data.launchDate, window.config.timezone).format();
    view.getModal().modal("hide");

    if (recipients === "TBD") {
      message = window.lang.campaigns.confirmScheduleUndefinedRecipients;
    } else {
      message = window.lang.campaigns.confirmSchedule.replace("###", recipients);
    }

    // Show confirm modal
    return this.confirmModal(
      message,
      false,
      window.lang.campaigns.scheduleLaunch,
      "info",
      "data-confirm-launch",
      function () {
        view.item.update(data).done(
          function () {
            view.item.set(data);
            view.launch(data);
          }.bind(view),
        );
      },
      function () {
        view.getModal().modal("hide");
      },
    );
  },

  // Send launch action
  launch: function (data) {
    this.item.launch(data).done(
      function () {
        const data = {
          id: this.item.id,
          name: this.item.attributes.name,
          type: this.item.attributes.type,
          mode: this.item.attributes.launchMode,
          date: this.item.attributes.launchMode !== "NOW" ? this.item.attributes.launchDate : undefined,
        };

        // Broadcast events
        app.integrations.amplitude.event("APP_CAMPAIGN_SENT_" + this.item.attributes.type, data, true);
        app.integrations.amplitude.event("APP_CAMPAIGN_SENT", data, true);
        app.integrations.intercom.event("APP_CAMPAIGN_SENT_" + this.item.attributes.type, data);
        app.integrations.intercom.event("APP_CAMPAIGN_SENT", data);

        // Update the tasks indicator
        app.instances.singleton("tasks", "list").render();

        // Display feedback
        if (this.item.get("launchMode") === "NOW") {
          this.displayMessage(window.lang.campaigns.feedback.sent);
        } else {
          this.displayMessage(window.lang.campaigns.feedback.scheduled);
        }

        const onboardingStore = useOnboardingStore();
        onboardingStore.submitUserEvent({
          action: "campaign.sent",
          data: {
            id: this.item.attributes.id,
            name: this.item.attributes.name,
          },
        });

        // Return to list
        app.router.navigateTo("campaigns");
      }.bind(this),
    );
  },

  // Returns the default HTML for Tiny MCE
  getHtmlContent: function () {
    return this.item.additionalsData.html;
  },

  // Returns the JSON content
  getJsonContent: function () {
    return this.item.additionalsData.json;
  },

  // Returns the title
  getTitle: function () {
    return this.item.attributes.name;
  },

  // Returns the Tiny MCE selector
  getTinySelector: function () {
    return "#html-content";
  },

  // Returns the saving content message
  getSavingMessage: function () {
    return window.lang.campaigns.feedback.savingContent;
  },

  // Returns the saving content message
  getSavedMessage: function () {
    return window.lang.campaigns.feedback.contentSaved;
  },

  // Returns the save HTML endpoint
  saveContentEndpoint: function () {
    if (!objHas(this, "item", "id")) {
      return false;
    }
    return "campaigns/" + this.item.id + "/contents/" + this.item.defaultContent().id + "/body";
  },

  // After save hook
  afterContentSave: function () {
    // Save new content in the item additional data
    if (objHas(this, "saving", "html")) {
      this.item.attributes.contents[0].type = "HTML";
      this.item.additionalsData.html = this.saving.html;
    }

    // Save new content in the item additional data
    if (objHas(this, "saving", "json")) {
      this.item.attributes.contents[0].type = "EDDIE";
      this.item.additionalsData.json = this.saving.json;
    }

    // Flag summary
    this.shouldReloadSummary = true;

    // Render preview
    this.item.attributes.defaultContent = _.first(this.item.get("contents"));
    this.renderPreview();
    this.renderIframePreviewWithTimeout();

    // Return
    return this;
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    if (this.item) {
      this.item.set("defaultContent", this.item.defaultContent());
    }
    var planType = app.session.attributes.details.plan
      ? app.session.attributes.details.plan.type
      : app.session.attributes.details.account.plan.type;

    return {
      data: this.item,
      lang: window.lang,
      config: window.config,
      session: app.session,
      defaultTemplate: "default_" + app.lang,
      inTrial: app?.getTrial().inTrial || planType === "FREE",
      useUnlayer: this.useUnlayer,
      // allowDisableExclude:
      //   app.session.attributes.details?.account?.features.ALLOW_DISABLE_EXCLUDE === "1",
    };
  },

  // Renders the preview box
  renderPreview: function () {
    this.$(".preview-wrapper").html(this.templates.preview(this.renderData()));
  },

  // Render iframe with timeout
  renderIframePreviewWithTimeout: function () {
    if (this.getPreviewIframe()) {
      this.renderIframePreview();
    } else {
      setTimeout(
        function () {
          this.renderIframePreviewWithTimeout();
        }.bind(this),
        3000,
      );
    }
  },

  // Gets the iframe URL
  getPreviewIframeUrl: function () {
    return this.item.defaultContent().preview;
  },

  // Moves to the next tab
  nextStep: function () {
    this.$("#campaign-tabs .active").next().find("a").click();
  },

  // Moves to the next tab
  prevStep: function () {
    this.$("#campaign-tabs .active").prev().find("a").click();
  },

  // Toggles the exclusion types options
  toggleExclusionTypes: function (e) {
    var checkbox = $(e.currentTarget);
    if (checkbox.is(":checked")) {
      $("#exclusion-types").removeClass("hide");
    } else {
      $("#exclusion-types").addClass("hide");
    }
  },

  // Complete the analytics campaign name
  toggleAnalytics: function () {
    var target = this.$('[name="options[GA_CAMPAIGN_NAME]"]'),
      name = this.$('[name="name"]');
    if (!target.val()) {
      target.val(name.val());
    }
  },

  // Save and return to list
  saveDraft: function () {
    const form = $("[data-form=save], [data-form=save-contents]").filter(":visible").first();
    const changes = form.find("[data-changed]").length;
    if (!form.length) {
      return this.back();
    }
    if (!changes) {
      return this.back();
    }
    this.returnToListAfterSave = true;
    this.beforeTabChange();
  },

  // Control the change of the included action
  changedAction: function (e) {
    var select = $(e.currentTarget),
      type = select.data("type"),
      value = select.val();
    if (value === "CLICKED") {
      select.next().removeClass("hidden").removeAttr("disabled").val("any");
      this.updateLinkSelectorQuery(type);
    } else {
      select.next().addClass("hidden").attr("disabled", true).next().addClass("hidden");
      this.removeLinkSelectorItems(type);
    }
  },

  // Control the change of the link option
  changedLink: function (e) {
    var select = $(e.currentTarget),
      type = select.data("type"),
      value = select.val();
    if (value === "selected") {
      select.next().removeClass("hidden");
      this.updateLinkSelectorQuery(type);
      this.removeLinkSelectorItems(type);
    } else {
      select.next().addClass("hidden");
    }
  },

  // Callback for include campaigns changes
  updateIncludeLinkSelectorQuery: function () {
    this.updateLinkSelectorQuery("include");
  },

  // Callback for exclude campaigns changes
  updateExcludeLinkSelectorQuery: function () {
    this.updateLinkSelectorQuery("exclude");
  },

  // Updates the link selector query
  updateLinkSelectorQuery: function (type) {
    var data = this.$("[data-recipients]").serializeObject(),
      manager = this.$("[data-campaign-manager=" + type + "]"),
      context,
      query;
    if (_.isUndefined(data[type]) || _.isUndefined(data[type].campaigns)) {
      manager.addClass("hidden");
      return false;
    }
    manager.removeClass("hidden");
    query = { q: _.values(data[type].campaigns).join(",") };
    context = this.$(".link-selector[data-type=" + type + "]");
    context.find("[data-selector-view=links]").data("query", query);
    context.find(".selected-items-list").html(
      require("@/modules/links/templates/selectorSnippet.hbs")({
        lang: window.lang,
      }),
    );
  },

  // Remove selected items from selector
  removeLinkSelectorItems: function (type) {
    var context = this.$(".link-selector[data-type=" + type + "]"),
      selector = context.find("[data-selector-view=links]");
    selector.attr("data-selected-items", "[]").data("selected-items", []);
  },

  // Inserts a field in ths subject input
  insertField: function (e) {
    var tag = $(e.currentTarget).data("tag"),
      subject = $("[name=subject]"),
      prefix = "CONTACT:";
    e.preventDefault();

    // Translate tag to composer v2
    prefix = "contact.";
    tag = tag.toLowerCase();
    if (tag === "lname") tag = "last_name";
    if (tag === "fname") tag = "first_name";

    window.insertText("${" + prefix + tag + "}", subject);
  },

  // Inserts a field in ths subject input
  insertFieldResend: function (e) {
    var tag = $(e.currentTarget).data("tag"),
      subject = $("#subject-resend"),
      prefix = "CONTACT:";
    e.preventDefault();

    // Translate tag to composer v2
    prefix = "contact.";
    tag = tag.toLowerCase();
    if (tag === "lname") tag = "last_name";
    if (tag === "fname") tag = "first_name";

    window.insertText("${" + prefix + tag + "}", subject);
  },

  warnExcludeInactiveUncheck: function (e) {
    var checkbox = $(e.currentTarget);

    // if (app.session.attributes.details?.account?.features.ALLOW_DISABLE_EXCLUDE !== "1") {
    //   if (!checkbox.is(":checked")) {
    //     checkbox.prop("checked", true).attr("data-changed", "1");
    //   }
    // } else {

    // const message =
    //   app.session.attributes.details?.account?.features.ALLOW_DISABLE_EXCLUDE === "1"
    //     ? window.lang.campaigns.excludeInactiveWarningMessage
    //     : window.lang.campaigns.excludeInactiveWarningMessageDisabled;
    const message = window.lang.campaigns.excludeInactiveWarningMessage;

    if (!checkbox.is(":checked")) {
      $("#exclude-alert").removeClass("hide");
      $("#exclude-info").addClass("hide");
      this.confirmModal(
        message,
        false,
        window.lang.campaigns.excludeInactiveWarningTitle,
        "warning",
        "data-confirm",
        function () {
          // acceptCallback
          checkbox.prop("checked", true).removeAttr("data-changed");
          $("#exclude-alert").addClass("hide");
          $("#exclude-info").removeClass("hide");
        },
        function () {
          // cancelCallback
          // if (app.session.attributes.details?.account?.features.ALLOW_DISABLE_EXCLUDE !== "1") {
          //   checkbox.prop("checked", true).removeAttr("data-changed");
          //   $("#exclude-alert").addClass("hide");
          //   $("#exclude-info").removeClass("hide");
          // }
        },
        window.lang.campaigns.excludeInactiveAcceptButton,
        window.lang.campaigns.excludeInactiveCancelButton,
        false, //app.session.attributes.details?.account?.features.ALLOW_DISABLE_EXCLUDE !== "1" //hideCancelButton
      );
    } else {
      $("#exclude-alert").addClass("hide");
      $("#exclude-info").removeClass("hide");
    }
    // }
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // If the window is open, close it
    if (window.editor !== undefined) {
      // Save eddie content first
      if (!window.editor.closed && window.editor.isDirty) {
        window.editor.save();
      }

      // Close editor
      window.editor.close();
      delete window.editor;
    }

    // Reset model
    //self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Restore original template
    if (self.originalTemplate !== undefined && self.originalTemplate !== self.template) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },
});
