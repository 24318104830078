<template>
  <div class="space-y-1 py-3">
    <div class="flex space-x-4">
      <component
        :is="asLink ? 'a' : 'div'"
        :href="!blockLink ? `/gallery/private/${template.id}` : 'javascript:void(0)'"
        @click="onClick"
      >
        <button class="text-left text-sm font-medium text-sky-400 hover:text-sky-500">
          {{ template.name }}
        </button>
      </component>
      <SimpleBadge v-if="template.relation_type" square theme="gray">
        <span>{{ RelationTypeText(template.relation_type) }}</span>
      </SimpleBadge>
      <SimpleBadge v-if="template.type === 'html'" square theme="gray">
        <span>{{ t("HTMLTemplate") }}</span>
      </SimpleBadge>
    </div>
    <p :class="[template.subject.length === 0 ? 'text-gray-300' : ' text-gray-500', 'block text-xs']">
      {{ template.subject || t("placeholderEmptySubject") }}
    </p>
    <TagsGroup
      v-if="!template.relation_type"
      readonly
      clickable
      show-tag-colors
      tag-size="small"
      :selected-tags="template.tags"
      :max-visible-tags="6"
      @click="selectTag"
    />
  </div>
</template>
<script lang="ts" setup>
// Components
import SimpleBadge from "@atoms/SimpleBadge";
import { TagsGroup } from "@organisms/Tags";

// Utils
import { useI18n } from "vue-i18n";

// Domain
import type { TemplateItem } from "@domain/Templates";
import type { Tag } from "@domain/tag";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    template: TemplateItem;
    asLink?: boolean;
    blockLink?: boolean;
  }>(),
  {
    asLink: false,
    blockLink: false,
  },
);

// Emits
const emit = defineEmits<{
  (e: "select", selectedTemplate: TemplateItem): void;
  (e: "selectTag", tag: Tag): void;
}>();

const selectTemplate = (selectedTemplate: TemplateItem) => {
  emit("select", selectedTemplate);
};

const selectTag = (tag: Tag) => {
  emit("selectTag", tag);
};

const onClick = (ev: PointerEvent) => {
  if (ev.ctrlKey || ev.metaKey) {
    ev.stopPropagation();
    return;
  }
  selectTemplate(props.template);
};

// Relation map
const RelationTypeText = (relationType: string): string => {
  return t(`${relationType}`);
};
</script>
<i18n lang="jsonc">
{
  "es": {
    "automation": "Automation",
    "campaign_simple": "Campaña simple",
    "campaign_testab": "Campaña test A/B",
    "campaign_smart": "Campaña inteligente",
    "transactional": "Transaccional",
    "placeholderEmptySubject": "Sin asunto",
    "HTMLTemplate": "HTML"
  },
  "pt": {
    "automation": "automation",
    "campaign_simple": "campanha simples",
    "campaign_testab": "campanha teste A/B",
    "campaign_smart": "campanha inteligente",
    "transactional": "transaccional",
    "placeholderEmptySubject": "Sem assunto",
    "HTMLTemplate": "HTML"
  }
}
</i18n>
