import script from "./QRTemplateFour.vue?vue&type=script&lang=ts&setup=true"
export * from "./QRTemplateFour.vue?vue&type=script&lang=ts&setup=true"

import "./QRTemplateFour.vue?vue&type=style&index=0&id=7e72129c&lang=css"
/* custom blocks */
import block0 from "./QRTemplateFour.vue?vue&type=custom&index=0&blockType=i18n&lang=jsonc"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__