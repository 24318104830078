<template>
  <div>
    <div v-if="!loading">
      <Menu class="focus:outline-none">
        <MenuItems static class="h-full w-full space-y-1 overflow-auto rounded bg-white">
          <template v-for="item in categorizedItems?.items" :key="item.id">
            <Item :item="item" :selected="selected?.id === item.id" @select="selectItem(item)" />
          </template>
          <template v-for="category in categorizedItems?.categories" :key="category.id">
            <div class="hover:cursor-pointer">
              <div
                class="flex justify-between pr-5 text-sky-500 hover:text-sky-600 active:text-sky-600"
                @click="toggleCategory(category.id)"
              >
                <h6 class="m-0 px-3 pb-2 pt-3 text-xs font-medium uppercase">
                  {{ category.name }}
                </h6>
                <ChevronDownIcon
                  :class="[selectedCategory === category.id && 'rotate-180', 'my-auto h-5 w-5']"
                  aria-hidden="true"
                />
              </div>

              <!-- <transition
                leave-active-class="transition-all transform origin-top ease-out duration-200"
                leave-from-class="opacity-100 scale-y-100 h-full"
                leave-to-class="opacity-0 scale-y-0 h-0"
                enter-active-class="transition-all transform origin-top ease-out duration-200"
                enter-to-class="opacity-100 scale-y-100 h-full"
                enter-from-class="opacity-0 scale-y-0 h-0"
                mode="in-out"
              > -->
              <div v-if="selectedCategory === category.id">
                <Item
                  v-for="item in category.items"
                  :key="item.id"
                  :item="item"
                  :selected="selected?.id === item.id"
                  class="mb-1"
                  @select="selectItem(item)"
                />
              </div>
              <!-- </transition> -->
            </div>
          </template>
        </MenuItems>
      </Menu>
    </div>
    <CategoryMenuSkeleton v-else />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";

//Components
import { Menu, MenuItems } from "@headlessui/vue";
import Item from "./components/Item.vue";
import CategoryMenuSkeleton from "./components/CategoryMenuSkeleton.vue";

//Icons
import { ChevronDownIcon } from "@heroicons/vue/solid";

//Types
import type { CategoryItem, CategorizedItems, CategoryWithItems } from "./CategoryMenu.types";

//Props
const props = withDefaults(
  defineProps<{
    categorizedItems?: CategorizedItems;
    // categories?: Categories; //TODO: alternativa del componente
    // items?: Items; //TODO: alternativa del componente
    selected?: CategoryItem;
    loading?: boolean;
  }>(),
  {
    categorizedItems: () => ({} as CategorizedItems),
    selected: undefined,
    // categories: () => [] as Categories,
    // items: () => [] as Items,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:selected", selectedItem: CategoryItem): void;
}>();

const selectItem = (item: CategoryItem) => emit("update:selected", item);

//open dropdown
const getCategoryFromSelectedItem = (): CategoryWithItems | undefined => {
  return props.categorizedItems?.categories.find((category) =>
    category.items.some((item) => item.id === props.selected?.id)
  );
};

const selectedCategory = ref<string | number | undefined>(props.categorizedItems?.categories?.[0]?.id ?? undefined);
const toggleCategory = (categoryID: string | number | undefined) =>
  (selectedCategory.value = selectedCategory.value !== categoryID ? categoryID : undefined);

watch(
  () => props.categorizedItems,
  () => {
    if (selectedCategory.value) return;
    selectedCategory.value =
      getCategoryFromSelectedItem()?.id ?? props.categorizedItems?.categories?.[0]?.id ?? undefined;
  }
);
//Categorize items
//TODO: alternativa de props -> lógica de categorización de items sueltos en categorias
//TODO: Cambiar categorizedItems por data
// const data = props.categorizedItems;
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-300;
  border-radius: 9999px;
}
</style>
