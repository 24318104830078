import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";

export default Model.extend({
  url: function () {
    if (this.isNew()) {
      return "templates";
    }
    return "templates" + this.get("id");
  },
  parse: function (design) {
    if (_.isDefined(design.data)) {
      design = design.data;
    }
    return design;
  },
  additionals: {
    html: function (model) {
      return $.ajax({
        dataType: "html",
        url: "templates" + model.id + ".html",
      });
    },
    json: function (model) {
      if (model.get("type") === "EDDIE") {
        return $.ajax({
          url: "templates" + model.id + ".json",
        });
      } else {
        return app.helpers.emptyRelationship();
      }
    },
  },
  rename: function (data) {
    return $.ajax({ type: "PUT", url: "templates" + this.id, data: data });
  },
});
