import { parseISO } from "date-fns";

import {
  BatchType,
  Period,
  IDashboardDataSet,
  Accumulator,
  IDashboardDataPoint,
  PeriodType,
  variation,
} from "./base";

class EmailsMetric {
  metric: number;
  base: number;

  constructor() {
    this.metric = 0;
    this.base = 0;
  }

  public add(metric: number, base: number) {
    this.metric += metric;
    this.base += base;
  }

  get percentage() {
    if (this.base === 0) return 0;
    let val = this.metric / this.base;
    if (val >= 0.999 && val < 1) val = 0.999; // sino al mostar se redondea a 100% y queda raro
    return val;
  }

  get percentage100() {
    return this.percentage * 100;
  }
}

class SentMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(dataPoint.mail_metrics.automation.sent, dataPoint.mail_metrics.automation.sent);
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(dataPoint.mail_metrics.bulk.sent, dataPoint.mail_metrics.bulk.sent);
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        dataPoint.mail_metrics.transactional.sent,
        dataPoint.mail_metrics.transactional.sent
      );
  }

  get variation() {
    return variation(this.current.metric, this.previous.metric);
  }
}

class DeliveredMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        dataPoint.mail_metrics.automation.sent -
          dataPoint.mail_metrics.automation.bounced.hard -
          dataPoint.mail_metrics.automation.bounced.soft,
        dataPoint.mail_metrics.automation.sent
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        dataPoint.mail_metrics.bulk.sent -
          dataPoint.mail_metrics.bulk.bounced.hard -
          dataPoint.mail_metrics.bulk.bounced.soft,
        dataPoint.mail_metrics.bulk.sent
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        dataPoint.mail_metrics.transactional.sent -
          dataPoint.mail_metrics.transactional.bounced.hard -
          dataPoint.mail_metrics.transactional.bounced.soft,
        dataPoint.mail_metrics.transactional.sent
      );
  }

  get variation() {
    return variation(this.current.metric, this.previous.metric);
  }
}

class OpenedMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        dataPoint.mail_metrics.automation.first_opened,
        dataPoint.mail_metrics.automation.sent -
          dataPoint.mail_metrics.automation.bounced.hard -
          dataPoint.mail_metrics.automation.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        dataPoint.mail_metrics.bulk.first_opened,
        dataPoint.mail_metrics.bulk.sent -
          dataPoint.mail_metrics.bulk.bounced.hard -
          dataPoint.mail_metrics.bulk.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        dataPoint.mail_metrics.transactional.first_opened,
        dataPoint.mail_metrics.transactional.sent -
          dataPoint.mail_metrics.transactional.bounced.hard -
          dataPoint.mail_metrics.transactional.bounced.soft
      );
  }

  get variation() {
    return variation(this.current.percentage, this.previous.percentage);
  }
}

class ClickedMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        dataPoint.mail_metrics.automation.first_clicked,
        dataPoint.mail_metrics.automation.first_opened
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        dataPoint.mail_metrics.bulk.first_clicked,
        dataPoint.mail_metrics.bulk.first_opened
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        dataPoint.mail_metrics.transactional.first_clicked,
        dataPoint.mail_metrics.transactional.first_opened
      );
  }

  get variation() {
    return variation(this.current.percentage, this.previous.percentage);
  }
}

class BouncedMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        dataPoint.mail_metrics.automation.bounced.hard +
          dataPoint.mail_metrics.automation.bounced.soft,
        dataPoint.mail_metrics.automation.sent
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        dataPoint.mail_metrics.bulk.bounced.hard + dataPoint.mail_metrics.bulk.bounced.soft,
        dataPoint.mail_metrics.bulk.sent
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        dataPoint.mail_metrics.transactional.bounced.hard +
          dataPoint.mail_metrics.transactional.bounced.soft,
        dataPoint.mail_metrics.transactional.sent
      );
  }

  get variation() {
    return variation(this.current.percentage, this.previous.percentage);
  }
}

class UnsubscribedMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        (dataPoint.mail_metrics.automation.unsubscribed.not_interested || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.not_specified || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.oneclick || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.other || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.too_frequent || 0),
        dataPoint.mail_metrics.automation.sent -
          dataPoint.mail_metrics.automation.bounced.hard -
          dataPoint.mail_metrics.automation.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        (dataPoint.mail_metrics.bulk.unsubscribed.not_interested || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.not_specified || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.oneclick || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.other || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.too_frequent || 0),
        dataPoint.mail_metrics.bulk.sent -
          dataPoint.mail_metrics.bulk.bounced.hard -
          dataPoint.mail_metrics.bulk.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        (dataPoint.mail_metrics.transactional.unsubscribed.not_interested || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.not_specified || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.oneclick || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.other || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.too_frequent || 0),
        dataPoint.mail_metrics.transactional.sent -
          dataPoint.mail_metrics.transactional.bounced.hard -
          dataPoint.mail_metrics.transactional.bounced.soft
      );
  }

  get variation() {
    return variation(this.current.percentage, this.previous.percentage);
  }
}

class ComplaineddMetric implements Accumulator {
  readonly current = new EmailsMetric();
  readonly previous = new EmailsMetric();

  constructor(readonly batchType: BatchType) {}

  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void {
    let metric: EmailsMetric;
    if (period === "current") metric = this.current;
    else if (period === "previous") metric = this.previous;
    else return;

    if (this.batchType === "all" || this.batchType === "automation")
      metric.add(
        (dataPoint.mail_metrics.automation.unsubscribed.spam_fbl || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.spam_never_subscribed || 0) +
          (dataPoint.mail_metrics.automation.unsubscribed.spam_offensive || 0),
        dataPoint.mail_metrics.automation.sent -
          dataPoint.mail_metrics.automation.bounced.hard -
          dataPoint.mail_metrics.automation.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "bulk")
      metric.add(
        (dataPoint.mail_metrics.bulk.unsubscribed.spam_fbl || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.spam_never_subscribed || 0) +
          (dataPoint.mail_metrics.bulk.unsubscribed.spam_offensive || 0),
        dataPoint.mail_metrics.bulk.sent -
          dataPoint.mail_metrics.bulk.bounced.hard -
          dataPoint.mail_metrics.bulk.bounced.soft
      );
    if (this.batchType === "all" || this.batchType === "transactional")
      metric.add(
        (dataPoint.mail_metrics.transactional.unsubscribed.spam_fbl || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.spam_never_subscribed || 0) +
          (dataPoint.mail_metrics.transactional.unsubscribed.spam_offensive || 0),
        dataPoint.mail_metrics.transactional.sent -
          dataPoint.mail_metrics.transactional.bounced.hard -
          dataPoint.mail_metrics.transactional.bounced.soft
      );
  }

  get variation() {
    return variation(this.current.percentage, this.previous.percentage);
  }
}

export class EmailsDashboardData {
  readonly sent: SentMetric;
  readonly delivered: DeliveredMetric;
  readonly opened: OpenedMetric;
  readonly clicked: ClickedMetric;
  readonly bounced: BouncedMetric;
  readonly unsubscribed: UnsubscribedMetric;
  readonly complained: ComplaineddMetric;
  currentDataPoints = 0;
  previousDataPoints = 0;

  constructor(dataSet: IDashboardDataSet, period: Period, readonly batchType: BatchType) {
    this.sent = new SentMetric(batchType);
    this.delivered = new DeliveredMetric(batchType);
    this.opened = new OpenedMetric(batchType);
    this.clicked = new ClickedMetric(batchType);
    this.bounced = new BouncedMetric(batchType);
    this.unsubscribed = new UnsubscribedMetric(batchType);
    this.complained = new ComplaineddMetric(batchType);

    Object.entries(dataSet).forEach((entry) => {
      const date = parseISO(entry[0]);
      const dataPoint = entry[1];
      const periodType = period.type(date);

      this.sent.accumulate(dataPoint, periodType);
      this.delivered.accumulate(dataPoint, periodType);
      this.opened.accumulate(dataPoint, periodType);
      this.clicked.accumulate(dataPoint, periodType);
      this.bounced.accumulate(dataPoint, periodType);
      this.unsubscribed.accumulate(dataPoint, periodType);
      this.complained.accumulate(dataPoint, periodType);

      if (periodType === "current") this.currentDataPoints++;
      if (periodType === "previous") this.previousDataPoints++;
    });
  }

  get isPreviousIncomplete() {
    //faltan más de 2 dias de datos
    return this.previousDataPoints < this.currentDataPoints - 2;
  }

  get isPreviousEmpty() {
    return this.previousDataPoints === 0;
  }
}
