var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog modal-lg payment-success "
    + alias4(((helper = (helper = lookupProperty(helpers,"productType") || (depth0 != null ? lookupProperty(depth0,"productType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"productType","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":65}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentType") || (depth0 != null ? lookupProperty(depth0,"paymentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentType","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":81}}}) : helper)))
    + "'>\n    <div class='modal-content'>\n        <div class='modal-header'>\n            <button type='button' class='close' data-action='close-payment-modal'>\n                <span aria-hidden='true'>\n                    &times;\n                </span>\n            </button>\n            <h4 class='modal-title'>\n                <span class='warning'>\n                    "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"paymentError")), depth0))
    + "\n                </span>\n            </h4>\n        </div>\n        <div class='modal-body'>\n            <div class='modal-body-padded'>\n                "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"paymentErrorLegend")), depth0))
    + "\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-primary' data-action='close-payment-modal'>\n                    "
    + alias4(alias5(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"close")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});