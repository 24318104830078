<template>
  <a
    :href="props.allow.link ? `/campaigns/${campaign?.id}` : 'javascript:void(0)'"
    :class="[
      { 'border-sky-500': selected, 'hover:border-sky-400': !selected && props.allow.link },
      props.allow.link ? 'cursor-pointer' : '!cursor-default',
      'group relative flex h-[404px] w-[250px] flex-col justify-center overflow-hidden rounded-md border-2 border-neutral-300 bg-neutral-100 px-10 hover:cursor-pointer ',
    ]"
    @click="onClick"
  >
    <div class="absolute left-3 top-4 z-20 flex space-x-2">
      <SimpleBadge theme="sky" square size="small" class="shrink-0">
        <template #icon>
          <BeakerIcon v-if="campaign.type === 'SPLIT_AB'" class="h-4 w-4 text-sky-400" />
          <CalendarIcon v-else-if="campaign.type === 'SMART'" class="h-4 w-4 text-sky-400" />
          <MailIcon v-else class="h-4 w-4 text-sky-400" />
        </template>
        {{ t(`type.${campaign.type}`) }}
      </SimpleBadge>
      <SimpleBadge :theme="getBadgeTheme()" square>
        {{ t(`states.${campaign.archived ? "ARCHIVED" : campaign.state}`) }}
      </SimpleBadge>
    </div>
    <CheckBox
      v-if="allow.select"
      :model-value="selected"
      :class="[
        {
          'invisible group-hover:visible': !show.select && !show.info,
        },
        'absolute right-3 top-4 z-20',
      ]"
      @click.stop
      @update:model-value="toggleSelected"
    />
    <div class="absolute left-3 top-12 z-20 flex max-w-full flex-col space-y-2 text-sm font-medium text-neutral-500">
      <h1
        class="group-hover:whitespace-norma m-0 truncate break-all pr-6 text-sm font-medium text-sky-400 group-hover:text-sky-500"
      >
        {{ campaign.name }}
      </h1>
      <div v-if="campaign.launchDate" :class="['flex space-x-2']">
        <CalendarIcon class="h-5 w-5 text-neutral-400" />
        <span>{{ formatDate(new Date(campaign.launchDate), "MMM d, HH:mm", {}) }}</span>
      </div>

      <div v-if="campaign.metrics" :class="[{ invisible: !show.info }, 'flex space-x-2 group-hover:visible']">
        <div class="flex space-x-2">
          <MailIcon class="h-5 w-5 text-neutral-400" />
          <span> {{ n(Number(campaign.metrics?.sent) ?? 0, "decimal") }}</span>
        </div>
        <div class="flex space-x-2">
          <MailOpenIcon class="h-5 w-5 text-neutral-400" />
          <span> {{ campaign.metrics?.openedP ?? 0 }}%</span>
        </div>
      </div>
      <div v-if="campaign.metrics" :class="[{ invisible: !show.info }, 'flex space-x-2 group-hover:visible']">
        <CurrencyDollarIcon class="h-5 w-5 text-neutral-400" />
        <span>
          {{
            n(campaign.metrics?.conversionsAmount ? campaign.metrics?.conversionsAmount : 0, "currencyNoCents")
          }}</span
        >
      </div>
    </div>
    <div class="h-20" />
    <img
      v-show="showImage"
      :src="campaign?.thumbnail"
      class="bg-neutral-300 object-contain"
      @load="imageLoaded"
      @error="imageError"
    />
    <div
      :class="[
        {
          'h-full bg-white opacity-80': show.info,
          'h-40 group-hover:h-full group-hover:bg-white group-hover:opacity-80': !show.info,
        },
        'absolute inset-0 w-full bg-gradient-to-b from-white via-white via-50% transition-all duration-75',
      ]"
    />
    <div :class="['absolute inset-0 h-40 w-full bg-gradient-to-b from-white via-white via-50%']" />
    <div v-show="!showImage && !noImage" :class="['mx-auto flex w-6 flex-col justify-center text-neutral-400']">
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>
    <div v-show="noImage" :class="['mx-auto flex h-[404px] w-6 flex-col justify-center text-neutral-400']">
      <PhotographIcon class="h-6 w-6" />
    </div>
  </a>
</template>

<script lang="ts" setup>
import { ref } from "vue";

//Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import CheckBox from "@/vue/components/atoms/CheckBox.vue";
import SimpleBadge from "@atoms/SimpleBadge";

// Helpers
import { formatDate } from "@helpers/formatters";

// Domain
import { Campaign } from "@/vue/types/campaigns";

// Icons
import { PhotographIcon } from "@heroicons/vue/outline";
import { BeakerIcon, CalendarIcon, MailIcon, MailOpenIcon, CurrencyDollarIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

const { t, n } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    campaign: Campaign;
    selected?: boolean;
    show?: {
      select?: boolean;
      skeleton?: boolean;
      emptyCard?: boolean;
      info?: boolean;
    };
    allow?: {
      link?: boolean;
      select?: boolean;
    };
  }>(),
  {
    selected: false,
    show: () => ({
      select: false,
      skeleton: false,
      emptyCard: false,
      info: false,
    }),
    allow: () => ({
      link: false,
      select: true,
    }),
  },
);

const emit = defineEmits<{
  select: [Campaign];
  toggleSelected: [boolean];
  loaded: [];
}>();

const selectItem = (campaign: Campaign) => emit("select", campaign);
const toggleSelected = (value: boolean) => {
  emit("toggleSelected", value);
};
const onClick = (ev: MouseEvent) => {
  if (props.allow.link || ev.ctrlKey || ev.metaKey) {
    ev.stopPropagation();
    return;
  }

  if (!props.allow.select) return;
  selectItem(props.campaign);
};

const getBadgeTheme = () => {
  if (props.campaign.archived) return "gray";
  if (props.campaign.state === "SCHEDULED") return "blue";
  if (props.campaign.state === "SENT") return "green";
  if (props.campaign.state === "SENDING") return "light-green";
  if (props.campaign.state === "CANCELLED") return "red";
  if (props.campaign.state === "PENDING_APPROVAL") return "yellow";
  return "gray";
};

//Image State
const showImage = ref(false);
const noImage = ref(false);
const imageLoaded = () => {
  showImage.value = true;
  emit("loaded");
};

const imageError = () => {
  noImage.value = true;
  emit("loaded");
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "states": {
      "SCHEDULED": "Programada",
      "SENDING": "Enviando",
      "CANCELLED": "Cancelada",
      "DRAFT": "Borrador",
      "SENT": "Enviada",
      "PENDING_APPROVAL": "En revisión",
      "ARCHIVED": "Archivada"
    },
    "type": {
      "SIMPLE": "Simple",
      "SPLIT_AB": "Test A/B",
      "SMART": "Inteligente"
    }
  },
  "pt": {
    "states": {
      "SCHEDULED": "Programada",
      "SENDING": "Enviando",
      "CANCELLED": "Cancelada",
      "DRAFT": "Rascunho",
      "SENT": "Enviada",
      "PENDING_APPROVAL": "Em revisão",
      "ARCHIVED": "Arquivada"
    },
    "type": {
      "SIMPLE": "Simples",
      "SPLIT_AB": "Teste A/B",
      "SMART": "Inteligente"
    }
  }
}
</i18n>
