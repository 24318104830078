<template>
  <div class="flex flex-col bg-white">
    <div class="border-bottom flex h-20 justify-between border-b border-gray-300 p-5 pl-6">
      <h1 class="my-auto text-xl font-medium">{{ t("historySelectorTitle") }}</h1>
      <Button theme="white" @click="close">{{ t("exitWithoutRestoringButton") }}</Button>
    </div>
    <div class="grid min-h-0 flex-grow grid-cols-12" :style="{ gridTemplateRows: 'min-content' }">
      <div
        class="col-span-4 row-span-1 border border-t-0 border-gray-300 pb-8 pl-6 pt-8 sm:col-span-5 lg:col-span-4 2xl:col-span-3"
      >
        <Label class="mb-1">{{ t("selectTypeLabel") }}</Label>
        <SelectMenu
          v-model:selected="selectedHistoryType"
          :data-items="SelectTypeOptions"
          class="pr-6 xl:w-72 xl:pr-0"
        ></SelectMenu>
      </div>
      <div
        v-if="selectedHistoryGhost"
        class="col-span-8 row-span-1 w-full bg-white px-4 py-10 text-sm text-gray-600 sm:col-span-7 lg:col-span-8 lg:mx-auto xl:max-w-[80%] xl:px-0 2xl:col-span-9"
      >
        <span class="block font-medium" :class="!previewTemplate?.subject && 'text-gray-300'">
          {{ previewTemplate?.subject || t("subjectPlaceholder") }}
        </span>
        <span class="mt-1 block text-gray-500" :class="!previewTemplate?.preheader && 'text-gray-300'">
          {{ previewTemplate?.preheader || t("preheaderPlaceholder") }}
        </span>
      </div>
      <div v-else class="row-span-1 w-full px-4 sm:col-span-7 lg:col-span-8 lg:mx-auto xl:max-w-[80%] 2xl:col-span-9">
        <div class="h-full px-4 py-8 sm:px-0">
          <div class="flex h-full flex-col justify-center rounded-2xl bg-gray-50 text-center">
            <PhotographIcon class="mx-auto h-20 w-20 stroke-1 text-gray-200" aria-hidden="true" />
            <p class="text-sm text-gray-400 lg:mx-[30%]">{{ t("emptyState.description") }}</p>
          </div>
        </div>
      </div>
      <VersionHistory
        :items="historyItems"
        :selected="selectedHistoryGhost"
        class="col-span-4 row-span-5 overflow-auto border-r sm:col-span-5 lg:col-span-4 2xl:col-span-3"
        @update:selected="updateSelectedHistory"
        @restore="onRestoreSelected"
      />
      <div
        :class="!selectedHistoryGhost && 'invisible'"
        class="relative row-span-5 h-[calc(100%-1rem)] w-full overflow-y-auto px-4 sm:col-span-7 lg:col-span-8 lg:mx-auto lg:px-0 xl:max-w-[80%] 2xl:col-span-9"
      >
        <AlertBox v-if="templateError" theme="warning" class="absolute inset-x-0 w-full lg:mx-auto">
          <template #title>{{ t("templateErrorTitle") }}</template>
          {{ t("templateErrorDescription") }}<br />
          {{ t("templateErrorHint") }}
        </AlertBox>
        <iframe v-else :id="TEMPLATE_HISTORY_IFRAME_ID" frameborder="0" data-preview="form" class="h-full w-full" />
      </div>
    </div>
    <RestoreVersionModal v-model:open="restoreVersionModalIsOpen" :accept-loading="loadingRestore" @accept="restore" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect, computed, nextTick } from "vue";

//Components
import VersionHistory from "@organisms/Templates/VersionHistory/VersionHistory.vue";
import SelectMenu from "@/vue/components/molecules/SelectMenu.vue";
import Label from "@/vue/components/atoms/Label.vue";
import Button from "@atoms/SimpleButton.vue";
import RestoreVersionModal from "./components/RestoreVersionModal.vue";
import AlertBox from "@/vue/components/atoms/AlertBox.vue";

//Icon
import { PhotographIcon } from "@heroicons/vue/outline";

//Utils
import { compareDesc } from "date-fns";
import { isEmpty } from "lodash";
import { attachToNewIframe } from "@helpers/iframe";

//I18n
import { useI18n } from "vue-i18n";

//API
import { useEmailTemplate } from "@/vue/api/modules/templates/templates";

//Types
import {
  TemplateHistories,
  TemplateAutoSaveHistories,
  TemplateAutoSave,
  TemplateHistoryPreview,
} from "@/vue/types/Templates";

import type { HistoryItem, HistoryItems } from "@organisms/Templates/VersionHistory/VersionHistory.types";
import type { DataItems, DataItem } from "@/vue/components/molecules/SelectMenu.vue";
import type { AcceptDetails } from "./components/RestoreVersionModal.vue";

export interface RestoreOptions {
  useContent: boolean;
  useSubject: boolean;
  usePreheader: boolean;
}

export interface RestoreData {
  historyitem: HistoryItem;
  options: RestoreOptions;
}

enum HistoryOptionsTypes {
  all = "all",
  autosave = "autosave",
  history_manual = "history_manual",
  history_auto = "history_auto",
}

const { t } = useI18n();
const TemplatesAPI = useEmailTemplate();

//Props
const props = withDefaults(
  defineProps<{
    autosave?: TemplateAutoSave | undefined;
    history: TemplateHistories;
    autosaveHistory: TemplateAutoSaveHistories;
    tplId: string;
    loadingRestore?: boolean;
  }>(),
  {
    autosave: undefined,
  },
);

//Emits
const emit = defineEmits<{
  (e: "restore", restore: RestoreData): void;
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

const restore = (details: AcceptDetails) => {
  if (!selectedHistory.value) return;
  emit("restore", {
    historyitem: selectedHistory.value,
    options: details,
  });
};

//Flags
const templateError = ref(false);
const setTemplateNoError = () => (templateError.value = false);
const setTemplateError = () => (templateError.value = true);

//Const
const TEMPLATE_HISTORY_IFRAME_ID = "templateHistorySelector";
const SelectTypeOptions: DataItems<unknown, HistoryOptionsTypes> = [
  {
    key: HistoryOptionsTypes.all,
    value: t("selectTypeOptions.all"),
  },
  {
    key: HistoryOptionsTypes.history_manual,
    value: t("selectTypeOptions.history_manual"),
  },
  {
    key: HistoryOptionsTypes.history_auto,
    value: t("selectTypeOptions.history_auto"),
  },
];

//State
const previewTemplate = ref<TemplateHistoryPreview>();

const restoreVersionModalIsOpen = ref(false);

const selectedHistoryType = ref<DataItem<unknown, HistoryOptionsTypes>>(SelectTypeOptions[0]);

const selectedHistory = ref<HistoryItem>();

const selectedHistoryGhost = ref<HistoryItem>();

const updateSelectedHistory = (selected: HistoryItem) => {
  setHistoryLoading(historyItems.value, selected, true);
  selectedHistory.value = selected;
};

const formatUserId = (userId: string) => {
  if (userId.includes("@*")) {
    return userId.replace("@*", "@perfit");
  }
  return userId.replace(/@(.+)/, "");
};

const historyItems = computed<HistoryItems>(() => {
  const autoSave = props.autosave;
  const history = props.history;
  const autosaveHistory = props.autosaveHistory;

  const mappedAutosave: HistoryItem | undefined = autoSave
    ? {
        id: HistoryOptionsTypes.autosave,
        type: HistoryOptionsTypes.autosave,
        date: new Date(autoSave?.timestamp),
        user: formatUserId(autoSave?.user_id),
      }
    : undefined;

  const mappedHistory: HistoryItems = history.map((item) => ({
    id: item.id,
    type: HistoryOptionsTypes.history_manual,
    date: new Date(item.timestamp),
    user: formatUserId(item.user_id),
  }));

  const mappedAutosaveHistory: HistoryItems = autosaveHistory.map((item) => ({
    id: item.id,
    type: HistoryOptionsTypes.history_auto,
    date: new Date(item.timestamp),
    user: formatUserId(item.user_id),
  }));

  let newHistoryItems: HistoryItems = [];

  if (selectedHistoryType.value.key === HistoryOptionsTypes.history_auto) {
    newHistoryItems = [...mappedAutosaveHistory];
  } else if (selectedHistoryType.value.key === HistoryOptionsTypes.history_manual) {
    newHistoryItems = [...mappedHistory];
  } else {
    newHistoryItems = [...mappedHistory, ...mappedAutosaveHistory];
  }

  newHistoryItems.sort((a, b) => {
    return compareDesc(a.date, b.date);
  });

  if (selectedHistoryType.value.key === HistoryOptionsTypes.all && mappedAutosave)
    newHistoryItems.unshift(mappedAutosave);

  return newHistoryItems;
});

//Methods
const onRestoreSelected = () => (restoreVersionModalIsOpen.value = true);

const setHistoryLoading = (items: HistoryItems, item: HistoryItem, value = true) => {
  const foundHistory = items.find((history) => history.id === item.id);
  if (!foundHistory) return;
  foundHistory.loading = value;
};

const useTemplate = async (tplh_id: string): Promise<TemplateHistoryPreview | undefined> => {
  try {
    const tpl = await TemplatesAPI.getTemplateHistoryPreview({
      tpl_id: props.tplId,
      tplh_id: tplh_id,
    });
    return tpl;
  } catch (e) {
    //TODO: Emit close event
    setTemplateError();
    return undefined;
  }
};

const clearIframeContent = (elementID: string) => {
  const iframeHost = document.getElementById(elementID) as HTMLIFrameElement;

  if (!iframeHost) return;

  iframeHost.contentDocument?.open();
  iframeHost.contentDocument?.write("");
  iframeHost.contentDocument?.close();
};

const initWithTemplateAutosave = (templateAutosave: TemplateAutoSave) => {
  if (!templateAutosave.html) return;
  previewTemplate.value = {
    html: templateAutosave.html,
    subject: templateAutosave.subject,
    preheader: templateAutosave.preheader,
  };
  attachToNewIframe({ html: templateAutosave.html, iframeID: TEMPLATE_HISTORY_IFRAME_ID });
};

const initWithTemplateID = async (tplId: string) => {
  previewTemplate.value = await useTemplate(tplId);

  if (!previewTemplate.value) return;

  if (previewTemplate.value.html)
    attachToNewIframe({ html: previewTemplate.value.html, iframeID: TEMPLATE_HISTORY_IFRAME_ID });
};

watchEffect(async () => {
  if (!selectedHistory.value) return;
  if (selectedHistory.value.type === HistoryOptionsTypes.autosave) {
    //TODO: Revisar esto para sacarlo y esperar que esté el iframe
    nextTick(() => {
      if (!selectedHistory.value) return;
      initWithTemplateAutosave(props.autosave);
      selectedHistoryGhost.value = selectedHistory.value;
      setHistoryLoading(historyItems.value, selectedHistory.value, false);
    });
    return;
  }

  setTemplateNoError();

  await initWithTemplateID(selectedHistory.value.id);
  selectedHistoryGhost.value = selectedHistory.value;
  setHistoryLoading(historyItems.value, selectedHistory.value, false);
});

watchEffect(() => {
  if (!selectedHistory.value) {
    selectedHistory.value = historyItems.value[0];
    selectedHistoryGhost.value = historyItems.value[0];
  }
});

watchEffect(() => {
  if (!isEmpty(historyItems.value)) return;
  clearIframeContent(TEMPLATE_HISTORY_IFRAME_ID);
  previewTemplate.value = undefined;
  selectedHistoryGhost.value = undefined;
  selectedHistory.value = undefined;
});

watch(
  () => selectedHistoryType.value,
  () => {
    if (isEmpty(historyItems.value)) return;
    const first = historyItems.value[0];
    updateSelectedHistory(first);
  },
);
</script>
<i18n lang="jsonc">
{
  "es": {
    "historySelectorTitle": "Historial de versiones",
    "exitWithoutRestoringButton": "Salir sin restaurar",
    "subjectPlaceholder": "Esta versión no tiene un asunto definido.",
    "preheaderPlaceholder": "Esta versión no tiene un preheader definido.",
    "selectTypeLabel": "Mostrar",

    "templateErrorTitle": "No fue posible generar la vista previa del contenido.",
    "templateErrorDescription": "Probablemente esto se deba a errores de sintaxis en los códigos de reemplazo o regiones condicionales.",
    "templateErrorHint": "Verifica si existen problemas en el contenido, o contáctanos si necesitas ayuda.",

    "emptyState": {
      "description": "Selecciona una versión del historial para visulizar aquí su contenido."
    },
    "selectTypeOptions": {
      "all": "Todas las versiones",
      "history_manual": "Guardadas manualmente",
      "history_auto": "Guardadas automáticamente"
    },
    "useTemplate": {
      "errorTitle": "Error al cargar la plantilla",
      "errorMessage": "Hubo un problema al obtener la plantilla guardada"
    }
  },
  "pt": {
    "historySelectorTitle": "Histórico de versões",
    "exitWithoutRestoringButton": "Sair sem restaurar",
    "subjectPlaceholder": "Esta versão não possui um assunto definido.",
    "preheaderPlaceholder": "Esta versão não possui um preheader definido.",
    "selectTypeLabel": "Mostrar",

    "templateErrorTitle": "Não foi possível gerar a visualização prévia do conteúdo.",
    "templateErrorDescription": "Provavelmente isto se deva a erros de sintaxis nos códigos de substituição ou regiões condicionais.",
    "templateErrorHint": "Verifique se existem problemas no conteúdo, ou entre em contato com a gente se precisar de ajuda.",

    "emptyState": {
      "description": "Selecione uma versão do histórico para visualizar aqui seu conteúdo."
    },
    "selectTypeOptions": {
      "all": "Todas as versões",
      "history_manual": "Salvas manualmente",
      "history_auto": "Salvas automáticamente"
    },
    "useTemplate": {
      "errorTitle": "Erro ao carregar o template",
      "errorMessage": "Houve um problema ao obter o template salvo"
    }
  }
}
</i18n>
