<template>
  <div ref="dropDownRef" class="select-none divide-y divide-gray-100">
    <div class="space-y-2 py-3">
      <span class="block px-3 text-xs text-gray-300">{{ t("searchBy") }}</span>
      <div>
        <ComboboxOption
          v-for="filter in filteredFilterData.filters"
          v-slot="{ active }"
          :key="filter.id"
          as="template"
          :value="{ filter }"
        >
          <div :class="[active && 'cursor-pointer bg-sky-100', 'flex space-x-2 p-2 pl-3']">
            <component
              :is="filter.icon"
              v-if="filter.icon"
              :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']"
            />
            <span :class="[active && 'text-sky-700', 'text-sm text-gray-700']">
              {{ filter.text }}
            </span>
          </div>
        </ComboboxOption>
      </div>
    </div>
    <div v-if="recommendedTags.length > 0" class="space-y-2 py-3">
      <span class="block px-3 text-xs text-gray-300">{{ t("recommendedTags") }}</span>
      <div class="flex space-x-2 px-3">
        <ComboboxOption v-for="tag in recommendedTags" :key="tag.id" v-slot="{ active }" as="template" :value="{ tag }">
          <SimpleBadge
            square
            size="large"
            dot
            :dot-color="tag.color"
            clickable
            theme="gray-secondary"
            :class="[active && 'ring-2 ring-sky-400']"
          >
            <span :id="tag.id" class="max-w-[8rem] truncate">{{ tag.name }}</span>
          </SimpleBadge>
        </ComboboxOption>
      </div>
    </div>
    <div v-if="highlightList.length > 0" class="space-y-2 py-3">
      <span class="block px-3 text-xs text-gray-300">{{ t("highlightList") }}</span>
      <div>
        <ComboboxOption
          v-for="option in highlightList"
          :key="option.key"
          v-slot="{ active }"
          as="template"
          :value="{ item: option }"
        >
          <div :class="[active && 'cursor-pointer bg-sky-100', 'flex space-x-2 p-2 pl-3']">
            <ClockIcon :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']" />
            <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
              {{ option.value }}
            </span>
          </div>
        </ComboboxOption>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="FilterIdType extends string, IdType extends string,TextType extends string,ListKeyType extends string,ListValueType extends string,ListDataType = Record<string, unknown>"
>
import { ref, computed } from "vue";

// Components
import { ComboboxOption } from "@headlessui/vue";
import SimpleBadge from "@atoms/SimpleBadge";

// Icon
import { ClockIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { FilterData, Filters } from "@domain/filters";
import type { SelectedValues } from "@molecules/FilterInput";
import { getPrimaryHighlightList, getHighlightTags } from "@domain/filters";
import type { Tags } from "@domain/tag";
import { isTags } from "@domain/tag";
import type { DataItems } from "@domain/data";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    filterData: FilterData<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>;
    selected: SelectedValues<FilterIdType, IdType, TextType, ListDataType>;
  }>(),
  {}
);

const filteredFilterData = computed<FilterData<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>>(
  () => {
    return {
      filters: props.filterData.filters.reduce<
        Filters<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>
      >((filters, filter) => {
        const actionSelected = props.selected.some((s) => (filter.isAction ? s.filterId === filter.id : false));
        if (!actionSelected) {
          filters.push(filter);
        }

        return filters;
      }, []),
      filtersRoles: props.filterData.filtersRoles,
    };
  }
);

// Recent Searches
const recommendedTags = computed<Tags>(() => getHighlightTags(props.filterData));

const highlightList = computed<DataItems<ListKeyType, ListValueType, ListDataType>>(() => {
  if (props.filterData.filtersRoles?.primary === props.filterData.filtersRoles?.tags) return [];

  const list = getPrimaryHighlightList<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>(
    props.filterData
  );

  if (isTags(list)) return [];

  return list;
});

// Accessibility
const dropDownRef = ref<HTMLDivElement>();
</script>
<i18n lang="jsonc">
{
  "es": {
    "searchBy": "Buscar por...",
    "recommendedTags": "Etiquetas recomendadas",
    "highlightList": "Búsquedas recientes"
  },
  "pt": {
    "searchBy": "Pesquisar por...",
    "recommendedTags": "Etiquetas recomendadas",
    "highlightList": "Pesquisas recentes"
  }
}
</i18n>
