import { useI18n } from "vue-i18n";
import type { Timezone } from "@domain/timezones";
import type { Industry } from "@domain/account";
import type { Referral } from "@domain/referrals";

interface DataItem<DataType = unknown, keyType = string, valueType = string> {
  key: keyType;
  keyText?: string;
  value: valueType;
  data?: DataType;
}

export const getTimezones = (): DataItem<unknown, Timezone>[] => {
  return [
    { key: "America/Buenos_Aires", value: "America/Buenos_Aires" },
    { key: "America/Argentina/Buenos_Aires", value: "America/Argentina/Buenos_Aires" },
    { key: "America/Argentina/Catamarca", value: "America/Argentina/Catamarca" },
    { key: "America/Argentina/Cordoba", value: "America/Argentina/Cordoba" },
    { key: "America/Argentina/Jujuy", value: "America/Argentina/Jujuy" },
    { key: "America/Argentina/La_Rioja", value: "America/Argentina/La_Rioja" },
    { key: "America/Argentina/Mendoza", value: "America/Argentina/Mendoza" },
    { key: "America/Argentina/Rio_Gallegos", value: "America/Argentina/Rio_Gallegos" },
    { key: "America/Argentina/Salta", value: "America/Argentina/Salta" },
    { key: "America/Argentina/San_Juan", value: "America/Argentina/San_Juan" },
    { key: "America/Argentina/San_Luis", value: "America/Argentina/San_Luis" },
    { key: "America/Argentina/Tucuman", value: "America/Argentina/Tucuman" },
    { key: "America/Argentina/Ushuaia", value: "America/Argentina/Ushuaia" },

    { key: "America/Adak", value: "America/Adak" },
    { key: "America/Anchorage", value: "America/Anchorage" },
    { key: "America/Anguilla", value: "America/Anguilla" },
    { key: "America/Antigua", value: "America/Antigua" },
    { key: "America/Araguaina", value: "America/Araguaina" },
    { key: "America/Aruba", value: "America/Aruba" },
    { key: "America/Asuncion", value: "America/Asuncion" },
    { key: "America/Atikokan", value: "America/Atikokan" },
    { key: "America/Bahia", value: "America/Bahia" },
    { key: "America/Bahia_Banderas", value: "America/Bahia_Banderas" },
    { key: "America/Barbados", value: "America/Barbados" },
    { key: "America/Belem", value: "America/Belem" },
    { key: "America/Belize", value: "America/Belize" },
    { key: "America/Blanc-Sablon", value: "America/Blanc-Sablon" },
    { key: "America/Boa_Vista", value: "America/Boa_Vista" },
    { key: "America/Bogota", value: "America/Bogota" },
    { key: "America/Boise", value: "America/Boise" },
    { key: "America/Cambridge_Bay", value: "America/Cambridge_Bay" },
    { key: "America/Campo_Grande", value: "America/Campo_Grande" },
    { key: "America/Cancun", value: "America/Cancun" },
    { key: "America/Caracas", value: "America/Caracas" },
    { key: "America/Cayenne", value: "America/Cayenne" },
    { key: "America/Cayman", value: "America/Cayman" },
    { key: "America/Chicago", value: "America/Chicago" },
    { key: "America/Chihuahua", value: "America/Chihuahua" },
    { key: "America/Costa_Rica", value: "America/Costa_Rica" },
    { key: "America/Creston", value: "America/Creston" },
    { key: "America/Cuiaba", value: "America/Cuiaba" },
    { key: "America/Curacao", value: "America/Curacao" },
    { key: "America/Danmarkshavn", value: "America/Danmarkshavn" },
    { key: "America/Dawson", value: "America/Dawson" },
    { key: "America/Dawson_Creek", value: "America/Dawson_Creek" },
    { key: "America/Denver", value: "America/Denver" },
    { key: "America/Detroit", value: "America/Detroit" },
    { key: "America/Dominica", value: "America/Dominica" },
    { key: "America/Edmonton", value: "America/Edmonton" },
    { key: "America/Eirunepe", value: "America/Eirunepe" },
    { key: "America/El_Salvador", value: "America/El_Salvador" },
    { key: "America/Fort_Nelson", value: "America/Fort_Nelson" },
    { key: "America/Fortaleza", value: "America/Fortaleza" },
    { key: "America/Glace_Bay", value: "America/Glace_Bay" },
    { key: "America/Godthab", value: "America/Godthab" },
    { key: "America/Goose_Bay", value: "America/Goose_Bay" },
    { key: "America/Grand_Turk", value: "America/Grand_Turk" },
    { key: "America/Grenada", value: "America/Grenada" },
    { key: "America/Guadeloupe", value: "America/Guadeloupe" },
    { key: "America/Guatemala", value: "America/Guatemala" },
    { key: "America/Guayaquil", value: "America/Guayaquil" },
    { key: "America/Guyana", value: "America/Guyana" },
    { key: "America/Halifax", value: "America/Halifax" },
    { key: "America/Havana", value: "America/Havana" },
    { key: "America/Hermosillo", value: "America/Hermosillo" },
    { key: "America/Indiana/Indianapolis", value: "America/Indiana/Indianapolis" },
    { key: "America/Indiana/Knox", value: "America/Indiana/Knox" },
    { key: "America/Indiana/Marengo", value: "America/Indiana/Marengo" },
    { key: "America/Indiana/Petersburg", value: "America/Indiana/Petersburg" },
    { key: "America/Indiana/Tell_City", value: "America/Indiana/Tell_City" },
    { key: "America/Indiana/Vevay", value: "America/Indiana/Vevay" },
    { key: "America/Indiana/Vincennes", value: "America/Indiana/Vincennes" },
    { key: "America/Indiana/Winamac", value: "America/Indiana/Winamac" },
    { key: "America/Inuvik", value: "America/Inuvik" },
    { key: "America/Iqaluit", value: "America/Iqaluit" },
    { key: "America/Jamaica", value: "America/Jamaica" },
    { key: "America/Juneau", value: "America/Juneau" },
    { key: "America/Kentucky/Louisville", value: "America/Kentucky/Louisville" },
    { key: "America/Kentucky/Monticello", value: "America/Kentucky/Monticello" },
    { key: "America/Kralendijk", value: "America/Kralendijk" },
    { key: "America/La_Paz", value: "America/La_Paz" },
    { key: "America/Lima", value: "America/Lima" },
    { key: "America/Los_Angeles", value: "America/Los_Angeles" },
    { key: "America/Lower_Princes", value: "America/Lower_Princes" },
    { key: "America/Maceio", value: "America/Maceio" },
    { key: "America/Managua", value: "America/Managua" },
    { key: "America/Manaus", value: "America/Manaus" },
    { key: "America/Marigot", value: "America/Marigot" },
    { key: "America/Martinique", value: "America/Martinique" },
    { key: "America/Matamoros", value: "America/Matamoros" },
    { key: "America/Mazatlan", value: "America/Mazatlan" },
    { key: "America/Menominee", value: "America/Menominee" },
    { key: "America/Merida", value: "America/Merida" },
    { key: "America/Metlakatla", value: "America/Metlakatla" },
    { key: "America/Mexico_City", value: "America/Mexico_City" },
    { key: "America/Miquelon", value: "America/Miquelon" },
    { key: "America/Moncton", value: "America/Moncton" },
    { key: "America/Monterrey", value: "America/Monterrey" },
    { key: "America/Montevideo", value: "America/Montevideo" },
    { key: "America/Montserrat", value: "America/Montserrat" },
    { key: "America/Nassau", value: "America/Nassau" },
    { key: "America/New_York", value: "America/New_York" },
    { key: "America/Nipigon", value: "America/Nipigon" },
    { key: "America/Nome", value: "America/Nome" },
    { key: "America/Noronha", value: "America/Noronha" },
    { key: "America/North_Dakota/Beulah", value: "America/North_Dakota/Beulah" },
    { key: "America/North_Dakota/Center", value: "America/North_Dakota/Center" },
    { key: "America/North_Dakota/New_Salem", value: "America/North_Dakota/New_Salem" },
    { key: "America/Ojinaga", value: "America/Ojinaga" },
    { key: "America/Panama", value: "America/Panama" },
    { key: "America/Pangnirtung", value: "America/Pangnirtung" },
    { key: "America/Paramaribo", value: "America/Paramaribo" },
    { key: "America/Phoenix", value: "America/Phoenix" },
    { key: "America/Port-au-Prince", value: "America/Port-au-Prince" },
    { key: "America/Port_of_Spain", value: "America/Port_of_Spain" },
    { key: "America/Porto_Velho", value: "America/Porto_Velho" },
    { key: "America/Puerto_Rico", value: "America/Puerto_Rico" },
    { key: "America/Punta_Arenas", value: "America/Punta_Arenas" },
    { key: "America/Rainy_River", value: "America/Rainy_River" },
    { key: "America/Rankin_Inlet", value: "America/Rankin_Inlet" },
    { key: "America/Recife", value: "America/Recife" },
    { key: "America/Regina", value: "America/Regina" },
    { key: "America/Resolute", value: "America/Resolute" },
    { key: "America/Rio_Branco", value: "America/Rio_Branco" },
    { key: "America/Santarem", value: "America/Santarem" },
    { key: "America/Santiago", value: "America/Santiago" },
    { key: "America/Santo_Domingo", value: "America/Santo_Domingo" },
    { key: "America/Sao_Paulo", value: "America/Sao_Paulo" },
    { key: "America/Scoresbysund", value: "America/Scoresbysund" },
    { key: "America/Sitka", value: "America/Sitka" },
    { key: "America/St_Barthelemy", value: "America/St_Barthelemy" },
    { key: "America/St_Johns", value: "America/St_Johns" },
    { key: "America/St_Kitts", value: "America/St_Kitts" },
    { key: "America/St_Lucia", value: "America/St_Lucia" },
    { key: "America/St_Thomas", value: "America/St_Thomas" },
    { key: "America/St_Vincent", value: "America/St_Vincent" },
    { key: "America/Swift_Current", value: "America/Swift_Current" },
    { key: "America/Tegucigalpa", value: "America/Tegucigalpa" },
    { key: "America/Thule", value: "America/Thule" },
    { key: "America/Thunder_Bay", value: "America/Thunder_Bay" },
    { key: "America/Tijuana", value: "America/Tijuana" },
    { key: "America/Toronto", value: "America/Toronto" },
    { key: "America/Tortola", value: "America/Tortola" },
    { key: "America/Vancouver", value: "America/Vancouver" },
    { key: "America/Whitehorse", value: "America/Whitehorse" },
    { key: "America/Winnipeg", value: "America/Winnipeg" },
    { key: "America/Yakutat", value: "America/Yakutat" },
    { key: "America/Yellowknife", value: "America/Yellowknife" },

    { key: "Brazil/Acre", value: "Brazil/Acre" },
    { key: "Brazil/DeNoronha", value: "Brazil/Acre" },
    { key: "Brazil/East", value: "Brazil/Acre" },
    { key: "Brazil/West", value: "Brazil/Acre" },

    { key: "Canada/Atlantic", value: "Canada/Atlantic" },
    { key: "Canada/Central", value: "Canada/Central" },
    { key: "Canada/Eastern", value: "Canada/Eastern" },
    { key: "Canada/Mountain", value: "Canada/Mountain" },
    { key: "Canada/Newfoundland", value: "Canada/Newfoundland" },
    { key: "Canada/Pacific", value: "Canada/Pacific" },
    { key: "Canada/Saskatchewan", value: "Canada/Saskatchewan" },
    { key: "Canada/Yukon", value: "Canada/Yukon" },

    { key: "Chile/Continental", value: "Chile/Continental" },
    { key: "Chile/EasterIsland", value: "Chile/EasterIsland" },

    { key: "Mexico/BajaNorte", value: "Mexico/BajaNorte" },
    { key: "Mexico/BajaSur", value: "Mexico/BajaSur" },
    { key: "Mexico/General", value: "Mexico/General" },

    { key: "US/Alaska", value: "US/Alaska" },
    { key: "US/Aleutian", value: "US/Aleutian" },
    { key: "US/Arizona", value: "US/Arizona" },
    { key: "US/Central", value: "US/Central" },
    { key: "US/East-Indiana", value: "US/East-Indiana" },
    { key: "US/Eastern", value: "US/Eastern" },
    { key: "US/Hawaii", value: "US/Hawaii" },
    { key: "US/Indiana-Starke", value: "US/Indiana-Starke" },
    { key: "US/Michigan", value: "US/Michigan" },
    { key: "US/Mountain", value: "US/Mountain" },
    { key: "US/Pacific", value: "US/Pacific" },
    { key: "US/Pacific-New", value: "US/Pacific-New" },
    { key: "US/Samoa", value: "US/Samoa" },

    { key: "Europe/Amsterdam", value: "Europe/Amsterdam" },
    { key: "Europe/Andorra", value: "Europe/Andorra" },
    { key: "Europe/Astrakhan", value: "Europe/Astrakhan" },
    { key: "Europe/Athens", value: "Europe/Athens" },
    { key: "Europe/Belgrade", value: "Europe/Belgrade" },
    { key: "Europe/Berlin", value: "Europe/Berlin" },
    { key: "Europe/Bratislava", value: "Europe/Bratislava" },
    { key: "Europe/Brussels", value: "Europe/Brussels" },
    { key: "Europe/Bucharest", value: "Europe/Bucharest" },
    { key: "Europe/Budapest", value: "Europe/Budapest" },
    { key: "Europe/Busingen", value: "Europe/Busingen" },
    { key: "Europe/Chisinau", value: "Europe/Chisinau" },
    { key: "Europe/Copenhagen", value: "Europe/Copenhagen" },
    { key: "Europe/Dublin", value: "Europe/Dublin" },
    { key: "Europe/Gibraltar", value: "Europe/Gibraltar" },
    { key: "Europe/Guernsey", value: "Europe/Guernsey" },
    { key: "Europe/Helsinki", value: "Europe/Helsinki" },
    { key: "Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
    { key: "Europe/Istanbul", value: "Europe/Istanbul" },
    { key: "Europe/Jersey", value: "Europe/Jersey" },
    { key: "Europe/Kaliningrad", value: "Europe/Kaliningrad" },
    { key: "Europe/Kiev", value: "Europe/Kiev" },
    { key: "Europe/Kirov", value: "Europe/Kirov" },
    { key: "Europe/Lisbon", value: "Europe/Lisbon" },
    { key: "Europe/Ljubljana", value: "Europe/Ljubljana" },
    { key: "Europe/London", value: "Europe/London" },
    { key: "Europe/Luxembourg", value: "Europe/Luxembourg" },
    { key: "Europe/Madrid", value: "Europe/Madrid" },
    { key: "Europe/Malta", value: "Europe/Malta" },
    { key: "Europe/Mariehamn", value: "Europe/Mariehamn" },
    { key: "Europe/Minsk", value: "Europe/Minsk" },
    { key: "Europe/Monaco", value: "Europe/Monaco" },
    { key: "Europe/Moscow", value: "Europe/Moscow" },
    { key: "Europe/Oslo", value: "Europe/Oslo" },
    { key: "Europe/Paris", value: "Europe/Paris" },
    { key: "Europe/Podgorica", value: "Europe/Podgorica" },
    { key: "Europe/Prague", value: "Europe/Prague" },
    { key: "Europe/Riga", value: "Europe/Riga" },
    { key: "Europe/Rome", value: "Europe/Rome" },
    { key: "Europe/Samara", value: "Europe/Samara" },
    { key: "Europe/San_Marino", value: "Europe/San_Marino" },
    { key: "Europe/Sarajevo", value: "Europe/Sarajevo" },
    { key: "Europe/Saratov", value: "Europe/Saratov" },
    { key: "Europe/Simferopol", value: "Europe/Simferopol" },
    { key: "Europe/Skopje", value: "Europe/Skopje" },
    { key: "Europe/Sofia", value: "Europe/Sofia" },
    { key: "Europe/Stockholm", value: "Europe/Stockholm" },
    { key: "Europe/Tallinn", value: "Europe/Tallinn" },
    { key: "Europe/Tirane", value: "Europe/Tirane" },
    { key: "Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
    { key: "Europe/Uzhgorod", value: "Europe/Uzhgorod" },
    { key: "Europe/Vaduz", value: "Europe/Vaduz" },
    { key: "Europe/Vatican", value: "Europe/Vatican" },
    { key: "Europe/Vienna", value: "Europe/Vienna" },
    { key: "Europe/Vilnius", value: "Europe/Vilnius" },
    { key: "Europe/Volgograd", value: "Europe/Volgograd" },
    { key: "Europe/Warsaw", value: "Europe/Warsaw" },
    { key: "Europe/Zagreb", value: "Europe/Zagreb" },
    { key: "Europe/Zaporozhye", value: "Europe/Zaporozhye" },
    { key: "Europe/Zurich", value: "Europe/Zurich" },
  ];
};

export const getIndustries = (): DataItem<unknown, Industry | "other">[] => {
  const { t } = useI18n();

  return [
    { key: "clothing_accesories", value: t("industries.clothing_accesories") },
    { key: "antiques", value: t("industries.antiques") },
    { key: "art", value: t("industries.art") },
    { key: "cars", value: t("industries.cars") },
    { key: "home_garden", value: t("industries.home_garden") },
    { key: "food_drinks", value: t("industries.food_drinks") },
    { key: "industrial", value: t("industries.industrial") },
    { key: "sports", value: t("industries.sports") },
    { key: "digital", value: t("industries.digital") },
    { key: "education", value: t("industries.education") },
    { key: "electronics_it", value: t("industries.electronics_it") },
    { key: "clothing", value: t("industries.clothing") },
    { key: "office_supplies", value: t("industries.office_supplies") },
    { key: "jewelry", value: t("industries.jewelry") },
    { key: "toys", value: t("industries.toys") },
    { key: "books", value: t("industries.books") },
    { key: "pets", value: t("industries.pets") },
    { key: "music_movies", value: t("industries.music_movies") },
    { key: "gifts", value: t("industries.gifts") },
    { key: "health_beauty", value: t("industries.health_beauty") },
    { key: "services", value: t("industries.services") },
    { key: "travel", value: t("industries.travel") },
    { key: "erotics", value: t("industries.erotics") },
    { key: "bookstore_graphic", value: t("industries.bookstore_graphic") },
    { key: "equipment_machines", value: t("industries.equipment_machines") },
    { key: "other", value: t("industries.other") },
  ];
};

export const getReferrals = (): DataItem<unknown, Referral | "other">[] => {
  const { t } = useI18n();

  return [
    { key: "appstore", value: t("referral.appstore") },
    { key: "agency", value: t("referral.agency") },
    { key: "user", value: t("referral.user") },
    { key: "instagram", value: t("referral.instagram") },
    { key: "youtube", value: t("referral.youtube") },
    { key: "tiktok", value: t("referral.tiktok") },
    { key: "footer", value: t("referral.footer") },
    { key: "search", value: t("referral.search") },
    { key: "other", value: t("referral.other") },
  ];
};
