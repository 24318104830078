var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option name='primary' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":77},"end":{"line":26,"column":106}}})) != null ? stack1 : "")
    + ">\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                            </option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class='"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":39},"end":{"line":38,"column":64}}})) != null ? stack1 : "")
    + "' data-id='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n                                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                            </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n    <form data-form='substract'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"substract")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <p>\n                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"substractLegend")), depth0)) != null ? stack1 : "")
    + "\n                    </p>\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"substractTo")), depth0))
    + "\n                        </label>\n                        <select class='form-control' data-action='change-substraction-list'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":29,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"substractFrom")), depth0))
    + "\n                        </label>\n                        <ul class='default-list' style=\"max-height: 200px; overflow-y: auto;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":28},"end":{"line":41,"column":37}}})) != null ? stack1 : "")
    + "                        </ul>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});