<template>
  <div class="flex flex-grow flex-col">
    <Teleport :disabled="noTemplatesCreated" to="#gallery-content-filters">
      <FiltersPanel
        v-show="!firstLoad && (templates.length > 0 || selectedSearch.length > 0 || loadingTemplates)"
        class="z-30 shadow-none"
      >
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8 py-2">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpen"
            @close="onClose"
          />
          <div class="flex space-x-2">
            <div class="flex space-x-2">
              <div class="my-auto">
                <span v-show="!skeleton" class="text-sm text-gray-500">
                  {{ t("listsCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount }) }}
                </span>
                <span v-show="skeleton" class="animate-pulse rounded bg-gray-100 px-12 text-sm" />
              </div>
              <ToggleButtons
                :model-value="tabMode"
                :buttons="[
                  { id: 'grid', label: t('toggleButtons.gridTab'), icon: ViewGridIcon },
                  { id: 'list', label: t('toggleButtons.listTab'), icon: ViewListIcon },
                ]"
                full
                @update:modelValue="toggleTab"
              />
            </div>
            <TagsManagerButton
              v-if="show.tagsEditor"
              :permissions="['templates:update']"
              :stats="tagStats"
              class="py-2"
              @tagUpdated="onTagUpdated"
              @tagDeleted="onTagDeleted"
              @tagCreated="onTagCreated"
            />
          </div>
        </div>
      </FiltersPanel>
    </Teleport>
    <div
      v-show="(loadingTemplates || isTemplateAvailable) && tabMode === 'grid' && templates.length > 0"
      class="w-full overflow-y-auto"
    >
      <TemplateCatalog
        :key="tabMode"
        :templates="templates"
        :selected-templates="selectedTemplates"
        :selectable="templatesUpdate && show.selection"
        :as-link="allowLink"
        :loading="isTemplateAvailable && loadingTemplates"
        :skeleton="!isTemplateAvailable && loadingTemplates"
        class="pt-4"
        @select="onSelectTemplate"
        @toggle-selected="toggleSelected"
        @update-selected="updateSelectedRows"
        @bottom="fetchNextTemplates"
      />
    </div>
    <div
      v-show="(loadingTemplates || isTemplateAvailable) && tabMode === 'list' && templates.length > 0"
      class="ml-6 h-full"
    >
      <TemplateCatalogTable
        :key="Number(needUpdateSearch)"
        v-model:selected-rows="selectedRows"
        :templates="templates"
        :skeleton="!isTemplateAvailable && loadingTemplates"
        :skeleton-count="lastTemplatesCount"
        :loading="isTemplateAvailable && loadingTemplates"
        :as-link="allowLink"
        :show="{
          renderOptionsMenu: selectedTemplates.length === 0,
          optionsMenu: show.tableOptionsMenu,
          selection: show.selection,
        }"
        @select="onSelectTemplate"
        @selectTag="addTagSelected"
        @duplicate="duplicateTemplate"
        @editTags="editTags"
        @delete="deleteTemplate"
        @bottom-reached="fetchNextTemplates"
      />
    </div>
    <EmptySearchState
      v-show="!loadingTemplates && selectedSearch.length > 0 && (templates.length === 0 || totalCount === 0)"
      @cleanSearch="cleanSearch"
    />
    <div v-show="!isTemplateAvailable && loadingTemplates" class="flex h-full w-full items-center justify-center">
      <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
    </div>
    <EmptyState
      v-show="!loadingTemplates && !isTemplateAvailable && emptySearch"
      :article-id="7150769"
      :article-text="t('emptyState.moreAbout')"
      :title="t('emptyState.title')"
      :points="[t('emptyState.first'), t('emptyState.second'), t('emptyState.third')]"
    >
      <template #image>
        <EmptyStateImage v-show="lgBp" />
      </template>
    </EmptyState>
    <transition
      leave-active-class="transition duration-200 ease"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-y-6 "
      enter-active-class="transition duration-200 ease"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-y-6"
    >
      <TemplateSelectionBar
        v-show="selectedTemplates.length > 0"
        class="absolute inset-x-0 bottom-10 mx-auto mt-2 shadow"
        :count="selectedTemplates.length"
        :total="templatesTotal"
        :tags="tags"
        :selected-tags="selectedTemplateTags"
        @toggle-tags-selection="toggleTagsSelection"
        @select-all="selectAllTemplates"
        @delete-selected="deleteTemplates"
        @duplicate="() => duplicateTemplate(selectedTemplates[0])"
        @close="cleanSelectedTemplates"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRaw, onMounted, watch, inject, watchEffect } from "vue";
import type { Ref } from "vue";

//Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyState from "@molecules/EmptyState/EmptyState.vue";
import EmptyStateImage from "./EmptyStateImage.vue";
import TemplateSelectionBar from "../TemplateSelectionBar.vue";
import FiltersPanel from "@templates/FiltersPanel";
import FilterInput from "@molecules/FilterInput";
import type { SelectedValueTag } from "@molecules/FilterInput";
import { isSelectedValueTag } from "@molecules/FilterInput";
import TemplateCatalog from "@organisms/Templates/TemplateCatalog/TemplateCatalog.vue";
import ToggleButtons from "@/vue/components/atoms/ToggleButtons.vue";
import TagsManagerButton from "@organisms/Tags/TagsManager/TagsManagerButton.vue";
import TemplateCatalogTable from "@organisms/Templates/TemplateCatalogTable/TemplateCatalogTable.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import { PencilIcon, TagIcon } from "@heroicons/vue/solid";
import { ViewListIcon, ViewGridIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";
import { useNotifications } from "@composables/notifications";
import {
  templatesListToRows,
  templateItemToRow,
} from "@organisms/Templates/TemplateCatalogTable/templateCatalogTable.data";
import { useBreakpoints } from "@composables/breakpoints";

// Application
import { useLocalStorageApp } from "@application";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useEmailTemplate } from "@api/modules/templates/templates";

// Store
import { useSessionStore } from "@store";

//Types
import { getPrimaryHighlightList } from "@domain/filters";
import type { GetTemplatesAsyncGenerator, StatsByTag } from "@api/modules/templates/templates.types";
import type { TemplateItem, TemplateList } from "@domain/Templates";
import type { FilterData } from "@domain/filters";
import type { Tag, Tags } from "@domain/tag";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import type { Rows } from "@molecules/Table";
import { DataItems } from "@/vue/types/data";

type TabMode = "grid" | "list";

const templatesAPI = useEmailTemplate();
const localStorageApp = useLocalStorageApp();
const sessionStore = useSessionStore();
let templatesGen: GetTemplatesAsyncGenerator | undefined = undefined;

const { t, n } = useI18n();
const { notify } = useNotifications();
const trackingEventsService = useTrackingEvents();
const { lgBp } = useBreakpoints();

const templatesUpdate = sessionStore.hasPermission("templates:update");

//Props
const props = withDefaults(
  defineProps<{
    tplId?: string;
    allowLink?: boolean;
    show?: {
      tableOptionsMenu?: boolean;
      selection?: boolean;
      tagsEditor?: boolean;
    };
  }>(),
  {
    tplId: undefined,
    allowLink: false,
    show: () => ({
      tableOptionsMenu: true,
      selection: true,
      tagsEditor: true,
    }),
  },
);

//Emits
const emit = defineEmits<{
  (e: "select", selectedTemplate: TemplateItem): void;
  (e: "duplicated", template: TemplateItem): void;
  (e: "editTags", template: TemplateItem): void;
  (e: "deleted", template: TemplateItem): void;
}>();

const openTemplate = (selectedTemplate: TemplateItem) => emit("select", selectedTemplate);

const deleteTemplate = async (templateToDelete: TemplateItem) => {
  try {
    await templatesAPI.deleteTemplate({ tpl_id: templateToDelete.id });

    templates.value = toRaw(templates.value).filter((template) => template.id !== templateToDelete.id);

    trackingEventsService.dispatchAll({
      name: "APP_TPLGALLERY_PRIVATE_DELETE",
      data: {
        name: templateToDelete.name,
        id: templateToDelete.id,
      },
      includeMasterUsers: true,
    });

    notify({
      title: t("notifications.deleteTemplate.title"),
      text: t("notifications.deleteTemplate.text"),
      theme: "success",
    });
    emit("deleted", templateToDelete);
  } finally {
    if (templates.value.length < 20 && templatesTotal.value >= 20) {
      await fetchNextTemplates();
    }
    templatesTotal.value = templatesTotal.value - 1;
  }
};

const deleteTemplates = async () => {
  try {
    await templatesAPI.templatesBulkDelete({
      tpl_ids: selectedTemplatesIds.value,
    });

    const filteredTemplates = toRaw(templates.value).filter((template) => {
      return !selectedTemplatesIds.value.includes(template.id);
    });

    templates.value = filteredTemplates;
    notify({
      title: t("notifications.deleteTemplates.title"),
      text: t("notifications.deleteTemplates.text"),
      theme: "success",
    });
  } finally {
    cleanSelectedTemplates();
    if (templates.value.length < 20 && templatesTotal.value >= 20) {
      await fetchNextTemplates();
    }
    templatesTotal.value = templatesTotal.value - selectedTemplatesIds.value.length;
  }
};

const duplicateTemplate = async (template: TemplateItem) => {
  const templateCopy = await templatesAPI.duplicateTemplate({
    tpl_id: template.id,
    relation_id: template.relation_id,
    relation_type: template.relation_type,
  });

  templates.value = [templateCopy, ...toRaw(templates.value)];

  trackingEventsService.dispatchAll({
    name: "APP_TPLGALLERY_PRIVATE_DUPLICATE",
    data: {
      name: templateCopy.name,
      id: templateCopy.id,
    },
    includeMasterUsers: true,
  });

  notify({
    title: t("notifications.duplicateTemplate.title"),
    text: t("notifications.duplicateTemplate.text"),
    theme: "success",
  });

  emit("duplicated", template);
  cleanSelectedTemplates();
};

const editTags = (template: TemplateItem) => {
  emit("editTags", template);
};

const onSelectTemplate = (selectedTemplate: TemplateItem) => {
  if (selectedTemplates.value.length === 0) {
    openTemplate(selectedTemplate);
    return;
  }
  setTimeout(() => {
    toggleSelected(selectedTemplate);
  });
};

// Toggle
const tabSelectedSaved = localStorageApp.get<TabMode>({ id: "private_gallery_tab" });
const tabMode = inject<Ref<TabMode>>("ownCatalogTabMode") ?? ref<TabMode>(tabSelectedSaved ?? "grid");
const toggleTab = () => {
  tabMode.value = tabMode.value === "grid" ? "list" : "grid";
  localStorageApp.save<TabMode>({ id: "private_gallery_tab", value: tabMode.value });
};

// Search
const selectedSearch = ref<SelectedValues>([]);
const cleanSearch = () => {
  selectedSearch.value = [];
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "private_gallery_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  cleanSelectedTemplates();

  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === templateNameFilterId);

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === templateNameFilterId);

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
  await insertTagsFilter();
};

const addTagSelected = async (tag: Tag) => {
  const foundTag = selectedSearch.value.find((select) => isSelectedValueTag(select) && select.tag.id === tag.id);
  if (foundTag) return;

  await updateSelected([
    ...toRaw(selectedSearch.value),
    {
      filterId: templateTagsFilterId,
      tag: toRaw(tag),
    },
  ]);
};

const filterInputIsOpen = ref(false);
const onOpen = () => {
  filterInputIsOpen.value = true;
};
const onClose = () => {
  filterInputIsOpen.value = false;
  getNewGenerator();
};

const templateNameFilterId = "templateName";
const templateTagsFilterId = "tags";
const emptySearch = computed(() => selectedSearch.value.length === 0);
const tags = ref<Tags>([]);
const tagStats = ref<StatsByTag>();

const filterData = ref<FilterData>({
  filters: [
    { id: templateNameFilterId, text: t("filters.templateName"), icon: PencilIcon },
    {
      id: templateTagsFilterId,
      customSearch: false,
      text: t("filters.templateTag"),
      icon: TagIcon,
    },
  ],
  filtersRoles: {
    primary: templateNameFilterId,
    tags: templateTagsFilterId,
  },
});

const onTagUpdated = (tag: Tag) => {
  const modifiedTag = tags.value.find((t) => t.id === tag.id);
  if (!modifiedTag) return;
  modifiedTag.color = tag.color;
  modifiedTag.name = tag.name;

  selectedSearch.value = selectedSearch.value.map((s) => {
    if (!isSelectedValueTag(s)) return s;

    if (s.tag.id === tag.id) {
      s.tag.color = tag.color;
      s.tag.name = tag.name;
    }
    return s;
  });

  templates.value = templates.value.map<TemplateItem>((t) => {
    const hasTag = t.tags.some((tt) => tt.id === tag.id);
    if (!hasTag) return t;

    const modifiedTag = t.tags.find((t) => t.id === tag.id);
    if (!modifiedTag) return t;
    modifiedTag.color = tag.color;
    modifiedTag.name = tag.name;

    return t;
  });
};

const onTagDeleted = (tag: Tag) => {
  const filteredTags = toRaw(tags.value).filter((t) => t.id !== tag.id);
  tags.value = filteredTags;

  selectedSearch.value = selectedSearch.value.filter((s) => {
    if (!isSelectedValueTag(s)) return true;

    if (s.tag.id !== tag.id) return true;

    return false;
  });

  templates.value = templates.value.map<TemplateItem>((t) => {
    const hasTag = t.tags.some((tt) => tt.id === tag.id);
    if (!hasTag) return t;

    const filteredTags = t.tags.filter((t) => t.id !== tag.id);
    t.tags = filteredTags;

    return t;
  });
};

const onTagCreated = (tag: Tag) => {
  tags.value.push(tag);
};

const insertTagsList = (tags: Tags) => {
  const tagsFilter = filterData.value.filters.find((filter) => filter.id === templateTagsFilterId);
  if (!tagsFilter) return;
  tagsFilter.list = tags;
};

const insertRecommendedTags = (tags: Tags) => {
  const tagsFilter = filterData.value.filters.find((filter) => filter.id === templateTagsFilterId);
  if (!tagsFilter) return;
  tagsFilter.highlightList = tags;
};

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "private_gallery_searches" });
  const nameFilter = filterData.value.filters.find((filter) => filter.id === templateNameFilterId);
  if (!nameFilter) return;
  nameFilter.highlightList = searches;
};

const insertTagsFilter = async () => {
  if (!tagStats.value) return;
  tags.value = await templatesAPI.getTemplateTags();

  const filteredUsedTags = Object.entries(tagStats.value.tags).filter(([tagKey]) =>
    selectedSearch.value.every((selected) => (isSelectedValueTag(selected) ? selected.tag.id !== tagKey : true)),
  );

  const orderTags = filteredUsedTags.sort(([_, valueA], [__, valueB]) => valueB - valueA);

  const firstFive = orderTags.slice(0, 5);

  const usedTags: Tags = tags.value.filter((tag) => firstFive.some(([tagKey]) => tag.id === tagKey));

  const sortedUsedTags = usedTags.sort((tagA, tagB) => {
    const orderA = firstFive.findIndex(([tagKey]) => tagA.id === tagKey);
    const orderB = firstFive.findIndex(([tagKey]) => tagB.id === tagKey);

    return orderA - orderB;
  });

  insertRecommendedTags(sortedUsedTags);
  insertTagsList(tags.value);
};

//Templates
const loadingTemplates = ref(true);
const firstLoad = ref(true);
const skeleton = ref(true);
const noTemplatesCreated = ref(true);
const templates = ref<TemplateList>([]);
const templatesTotal = ref(0);
const lastTemplatesCount = ref(3);

const selectedTemplates = ref<TemplateList>([]);
const selectedTemplatesIds = computed<Array<string>>(() => selectedTemplates.value.map((t) => t.id));
const selectedRows = ref<Rows<string, string, TemplateItem>>([]);
const selectedTemplateTags = computed<Tags>(() => {
  return selectedTemplates.value.reduce((tags, template, index) => {
    const templateTags = template?.tags ?? [];

    if (index === 0) {
      tags = [...templateTags];
      return tags;
    }

    tags = tags.filter((tag) => templateTags.some((templateTag) => templateTag.id === tag.id));

    return tags;
  }, [] as Tags);
});

watchEffect(() => {
  selectedTemplates.value = selectedRows.value.reduce((templatesArray, selected) => {
    if (!selected.data) return templatesArray;

    templatesArray.push(toRaw(selected.data));

    return templatesArray;
  }, [] as TemplateList);
});

const toggleSelected = (template: TemplateItem) => {
  const rowFoundIndex = selectedRows.value.findIndex((selected) => selected.id === template.id);
  if (rowFoundIndex !== -1) {
    selectedRows.value.splice(rowFoundIndex, 1);
  } else {
    selectedRows.value = [...selectedRows.value, templateItemToRow(toRaw(template))];
  }
};
const updateSelectedRows = (selected: TemplateList) => {
  selectedRows.value = templatesListToRows(toRaw(selected));
};

const toggleTagsSelection = async (tag: Tag) => {
  const isApplied = selectedTemplateTags.value.some((selectedTag) => selectedTag.id === tag.id);

  if (isApplied) {
    const modifyTags = (template: TemplateItem) => {
      const isSelectedTemplate = selectedTemplatesIds.value.includes(template.id);
      if (!isSelectedTemplate) return template;

      template.tags = template.tags.filter((templateTag) => templateTag.id !== tag.id);

      return template;
    };

    const templatesModified = toRaw(selectedTemplates.value).filter((t) => {
      const isSelectedTemplate = selectedTemplatesIds.value.includes(t.id);

      if (!isSelectedTemplate) return false;

      const containsTag = t.tags.some((templateTag) => templateTag.id === tag.id);
      if (containsTag) return true;

      return false;
    });

    const modifiedSelectedTemplates = toRaw(selectedTemplates.value).map(modifyTags);
    selectedTemplates.value = modifiedSelectedTemplates;

    const modifiedTemplates = toRaw(templates.value).map(modifyTags);
    templates.value = modifiedTemplates;

    if (tagStats.value?.tags !== undefined) {
      tagStats.value.tags[tag.id] = tagStats.value.tags[tag.id]
        ? tagStats.value.tags[tag.id] - templatesModified.length
        : templatesModified.length;
    }

    await templatesAPI.templatesBulkRemoveTag({
      tpl_ids: selectedTemplatesIds.value,
      tag,
    });
  } else {
    const modifyTags = (template: TemplateItem) => {
      const isSelectedTemplate = selectedTemplatesIds.value.includes(template.id);

      if (!isSelectedTemplate) return template;

      const containsTag = template.tags.some((templateTag) => templateTag.id === tag.id);
      if (containsTag) return template;

      template.tags = [...template.tags, tag];

      return template;
    };

    const templatesModified = toRaw(selectedTemplates.value).filter((t) => {
      const isSelectedTemplate = selectedTemplatesIds.value.includes(t.id);

      if (!isSelectedTemplate) return false;

      const containsTag = t.tags.some((templateTag) => templateTag.id === tag.id);
      if (!containsTag) return true;

      return false;
    });

    const modifiedSelectedTemplates = toRaw(selectedTemplates.value).map(modifyTags);
    selectedTemplates.value = modifiedSelectedTemplates;

    const modifiedTemplates = toRaw(templates.value).map(modifyTags);
    templates.value = modifiedTemplates;

    if (tagStats.value?.tags !== undefined) {
      tagStats.value.tags[tag.id] = tagStats.value.tags[tag.id]
        ? tagStats.value.tags[tag.id] + templatesModified.length
        : templatesModified.length;
    }

    await templatesAPI.templatesBulkAssignTag({
      tpl_ids: selectedTemplatesIds.value,
      tag,
    });
  }
};

const selectAllTemplates = () => {
  const rows = templatesListToRows(toRaw(templates.value));

  selectedRows.value = rows;
};

const cleanSelectedTemplates = () => {
  selectedRows.value = [];
};

const clearTemplates = () => (templates.value = []);

const isTemplateAvailable = computed(() => {
  if (!templates.value) return false;

  return templates.value.length > 0;
});

const getTemplatesGen = async ({ search, tags }: { search?: string; tags?: Tags }) => {
  const gen = await templatesAPI.getTemplates({ name: search, relation: "empty", tags: tags });

  return gen;
};

const totalCount = ref<number>(0);

const fetchNextTemplates = async () => {
  if (!templatesGen) return;
  loadingTemplates.value = true;

  const value = await templatesGen.next();
  if (value.done) {
    templatesGen = undefined;
    loadingTemplates.value = false;
    firstLoad.value = false;
    return;
  }

  templatesTotal.value = value.value.total;

  const TemplatesfilteredById = value.value.data.filter((template) => template.id !== props.tplId);
  templates.value = [...toRaw(templates.value), ...TemplatesfilteredById];
  lastTemplatesCount.value = templates.value.length;
  totalCount.value = value.value.total ?? 0;

  noTemplatesCreated.value = false;
  if (templates.value.length === 0 && emptySearch.value) {
    noTemplatesCreated.value = true;
  }
  loadingTemplates.value = false;
  firstLoad.value = false;
};

watch(
  () => selectedSearch.value,
  () => {
    needUpdateSearch.value = true;

    if (filterInputIsOpen.value === false) {
      getNewGenerator();
    }
  },
);

const needUpdateSearch = ref(true);
const getNewGenerator = async () => {
  skeleton.value = true;
  if (!needUpdateSearch.value) return;

  needUpdateSearch.value = false;

  loadingTemplates.value = true;

  clearTemplates();
  const rawSelectedItems = toRaw(selectedSearch.value);
  const searchName = rawSelectedItems.find(
    (search) => search.filterId === templateNameFilterId && !isSelectedValueTag(search),
  ) as SelectedValueText;

  const searchTagsFilter = rawSelectedItems.filter((search) => isSelectedValueTag(search)) as Array<SelectedValueTag>;

  const searchTags = searchTagsFilter?.map((tagFilter) => tagFilter.tag);

  templatesGen = await getTemplatesGen({ search: searchName?.value, tags: searchTags });
  await fetchNextTemplates();
  loadingTemplates.value = false;
  skeleton.value = false;
};

//Life cycle
const initCatalog = async () => {
  await getNewGenerator();

  await insertTagsFilter();
};

onMounted(async () => {
  firstLoad.value = true;
  tagStats.value = await templatesAPI.getPrivateStats();
  insertRecommmendedNames();
  await initCatalog();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderSearch": "Busca entre tus plantillas por nombre o etiquetas",
    "listsCount": "0 plantillas | 1 plantilla | {count} plantillas",
    "emptyState": {
      "title": "Dale vida a tus ideas y libera tu imaginación",
      "moreAbout": "Saber más sobre plantillas",
      "first": "Escoge entre una gran variedad de plantillas de diseño profesional y modifícalas con el editor drag & drop.",
      "second": "Organiza tu contenido fácilmente mediante etiquetas. Adapta las plantillas a tu marca sin necesidad de habilidades de diseño.",
      "third": "Busca inspiración usando el asistente con inteligencia artificial para crear imágenes y asuntos atractivos."
    },
    "notifications": {
      "duplicateTemplate": {
        "title": "Plantilla duplicada",
        "text": "La template se duplicó correctamente"
      },
      "deleteTemplate": {
        "title": "Plantilla eliminada",
        "text": "La plantilla se eliminó correctamente"
      },
      "deleteTemplates": {
        "title": "Plantillas eliminadas",
        "text": "Las plantillas se eliminaron correctamente"
      }
    },
    "filters": {
      "templateName": "Nombre",
      "templateTag": "Etiqueta"
    },
    "toggleButtons": {
      "gridTab": "Grilla",
      "listTab": "Listado"
    }
  },
  "pt": {
    "placeholderSearch": "Procure seus templates por nome ou etiquetas",
    "listsCount": "0 plantillas | 1 plantilla | {count} plantillas",
    "emptyState": {
      "title": "Dê vida às suas ideias e libere sua imaginação",
      "moreAbout": "Aprender mais sobre templates",
      "first": "Escolha entre uma grande variedade de templates profissionais e modifique-os com o editor Drag & Drop.",
      "second": "Organize seu conteúdo facilmente através das etiquetas. Personalize os modelos para a sua marca sem necessidade de habilidades em design.",
      "third": "Encontre inspiração usando o assistente com inteligência artificial para criar imagens e assuntos atrativos."
    },
    "notifications": {
      "duplicateTemplate": {
        "title": "Template duplicado",
        "text": "O template foi duplicado com sucesso"
      },
      "deleteTemplate": {
        "title": "Template excluído",
        "text": "O template foi excluído com sucesso"
      },
      "deleteTemplates": {
        "title": "Plantillas eliminadas",
        "text": "Las plantillas se eliminaron correctamente"
      }
    },
    "filters": {
      "templateName": "Nome",
      "templateTag": "Etiqueta"
    },
    "toggleButtons": {
      "gridTab": "Grilla",
      "listTab": "Listado"
    }
  }
}
</i18n>
