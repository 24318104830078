// I18n
import { useI18n } from "vue-i18n";

// Type
import type { AudienceTabs } from "./audience.types";
import type { Items } from "@templates/PageTemplate";

export const getTabs = (): Items<AudienceTabs> => {
  const { t } = useI18n();
  return [
    {
      key: "lists",
      value: t("tabs.lists"),
      content: {
        permission: "lists:list",
      },
    },
    {
      key: "contacts",
      value: t("tabs.contacts"),
      content: {
        permission: "contacts:list",
      },
    },
    {
      key: "interests",
      value: t("tabs.interests"),
      content: {
        permission: "interests:list",
      },
    },
    {
      key: "fields",
      value: t("tabs.fields"),
      content: {
        permission: "fields:list",
      },
    },
  ];
};
