var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog modal-lg'>\n    <form data-form='schedule'>\n        <input type='hidden' name='launchMode' value='SCHEDULED'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"scheduleLaunch")), depth0))
    + "\n                </h4>\n            </div>\n\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='scheduler'>\n                        <div class=\"form-group\">\n                            <p class=\"error-message error-hidden\"></p>\n                        </div>\n                        <div class=\"form-group\">\n                            <div class='date' id='datetimepicker'>\n                                <input class=\"form-control\" name='launchDate' type='hidden'\n                                    placeholder=\"YYYY-MM-DD HH:MM:SS\" value='"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"launchDate") : stack1), depth0))
    + "'\n                                    data-validate='required' />\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"form-group\">\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"scheduleLocalTimeOf")), depth0))
    + " "
    + alias2(((helper = (helper = lookupProperty(helpers,"timezone") || (depth0 != null ? lookupProperty(depth0,"timezone") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"timezone","hash":{},"data":data,"loc":{"start":{"line":32,"column":69},"end":{"line":32,"column":81}}}) : helper)))
    + "\n                        ("
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"scheduleCurrentTime")), depth0))
    + ": "
    + alias2(((helper = (helper = lookupProperty(helpers,"localTime") || (depth0 != null ? lookupProperty(depth0,"localTime") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"localTime","hash":{},"data":data,"loc":{"start":{"line":33,"column":71},"end":{"line":33,"column":84}}}) : helper)))
    + ")\n                    </div>\n                </div>\n            </div>\n\n\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});