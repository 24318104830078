import { EditView } from "@/core/magnet";
import app from "@/app";
import showTpl from "../templates/show.hbs";
import { mountComponent } from "@/helpers/vue";
import ModalTemplateEditor from "@organisms/Templates/TemplateEditor/ModalTemplateEditor.vue";

export default EditView.extend({
  module: "templateeditor",
  templates: {
    initial: showTpl,
    show: showTpl,
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    mountComponent(
      ModalTemplateEditor,
      {
        tplId: this.options.id,
        auditMode: "campaign",
        relation: {},
      },
      "#vue-template-editor"
    );
  },
});
