export interface ArgentinaBillingInfoData {
  country: "ar";
  email: string;
  info: {
    fiscal_id: string;
    fiscal_id_clean: string;
    name: string; // Nombre o razón social
    vat_id: string;
  };
}

export interface BrazilBillingInfoData {
  country: "br";
  email: string;
  info: {
    fiscal_id: string;
    fiscal_id_clean: string;
    fiscal_id_type: string;
    name: string; // Nombre o razón social
    cp: string; // CEP
    estado: string;
    cidade: string;
    bairro: string;
    rua: string;
    num: string;
  };
}

export type MexicoVAT =
  | "601"
  | "603"
  | "605"
  | "606"
  | "607"
  | "608"
  | "610"
  | "611"
  | "612"
  | "614"
  | "615"
  | "616"
  | "620"
  | "621"
  | "622"
  | "623"
  | "624"
  | "625"
  | "626"
  | "";

export interface MexicoBillingInfoData {
  country: "mx";
  email: string;
  info: {
    name: string; // Nombre o razón social
    fiscal_id: string; // RFC
    fiscal_id_clean: string;
    cp?: string;
    estado: string;
    municipio: string;
    colonia: string;
    calle: string;
    num_ext: string;
    num_int?: string;
    vat_type_id: MexicoVAT;
    vat_type: string;
  };
}

export type GenericCountries = "cl" | "pr" | "es" | "co" | "uy" | "us" | "ec" | "py" | "pe" | "cr" | "ve" | "gt" | "bo";

export interface GenericBillingInfoData {
  country: GenericCountries;
  email: string;
  info: {
    name: string; // Nombre o razón social
    fiscal_id: string;
    fiscal_id_clean: string;
    cp?: string;
    domicilio?: string;
    ciudad?: string;
  };
}

export interface EmptyBillingInfoData {
  country: "";
  email: "";
  info: Record<string, unknown>;
}

export type BillingInfoData =
  | ArgentinaBillingInfoData
  | BrazilBillingInfoData
  | MexicoBillingInfoData
  | GenericBillingInfoData;

export const isEmptyBillingInfo = (r: BillingInfoData | EmptyBillingInfoData): r is EmptyBillingInfoData => {
  return r.country === "" && r.email === "" && Object.values(r.info).every((v) => v === undefined);
};

export interface BillingInfoShowPanel {
  email: string;
  fiscal_id?: string;
  name: string; // Nombre o razón social
}
