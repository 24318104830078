import { ConditionsApplication } from "./conditions.types";
import { INTERESTS_ATTRIBUTE_OPTION_ID } from "./conditions.const";

// Application
import { getI18nInstance } from "@/vue/locales/i18n";

// Utils
import { cloneDeep } from "lodash";

// Services
import { useFields } from "@api/modules/fields/fields";
import { useFreeMarkerService } from "@services";

// Domain
import type { AttributeOptions, AttributeComparisonOptions, Attribute } from "@domain/predicates";
import type { ConditionsPredicateTypes } from "@domain/conditions";
import type { Fields, FieldType } from "@domain/fields";
import { getFieldName } from "@domain/fields";
import type { DataItem } from "@domain/data";

export const useConditionsApp = (): ConditionsApplication => {
  const { t } = getI18nInstance().global;

  const freeMarkerService = useFreeMarkerService();

  const conditionsApp: ConditionsApplication = {
    getAttributeComparisonOptions: ({ type, interests }) => {
      const optionsByType: Record<ConditionsPredicateTypes, AttributeComparisonOptions> = {
        string: {
          options: [
            { id: "eq", operator: "eq", text: t("comparisonOperator.eq") },
            { id: "ne", operator: "ne", text: t("comparisonOperator.ne") },
            { id: "contains", operator: "contains", text: t("comparisonOperator.contains") },
            { id: "notcontains", operator: "notContains", text: t("comparisonOperator.notContains") },
            { id: "def", operator: "exists", text: t("comparisonOperator.def"), value: true },
            { id: "ndef", operator: "exists", text: t("comparisonOperator.ndef"), value: false },
          ],
        },
        number: {
          options: [
            { id: "eq", operator: "eq", text: t("comparisonOperator.eq") },
            { id: "ne", operator: "ne", text: t("comparisonOperator.ne") },
            { id: "gt", operator: "gt", text: t("comparisonOperator.gt") },
            { id: "lt", operator: "lt", text: t("comparisonOperator.lt") },
            { id: "def", operator: "exists", text: t("comparisonOperator.def"), value: true },
            { id: "ndef", operator: "exists", text: t("comparisonOperator.ndef"), value: false },
          ],
        },
        interest: {
          options: [
            { id: "in", operator: "in", text: t("comparisonOperator.in") },
            { id: "nin", operator: "nin", text: t("comparisonOperator.nin") },
          ],
          valueOptions: interests?.map<DataItem<number>>((interest) => ({
            key: interest.id,
            value: interest.name,
          })),
        },
        email: {
          options: [
            { id: "eq", operator: "eq", text: t("comparisonOperator.eq") },
            { id: "ne", operator: "ne", text: t("comparisonOperator.ne") },
            { id: "contains", operator: "contains", text: t("comparisonOperator.contains") },
            { id: "notcontains", operator: "notContains", text: t("comparisonOperator.notContains") },
          ],
        },
        gender: {
          options: [
            { id: "male", operator: "eq", text: t("gender.male"), name: t("gender.male"), value: "M" },
            { id: "female", operator: "eq", text: t("gender.female"), name: t("gender.female"), value: "F" },
          ],
        },
      };

      return optionsByType[type];
    },
    getAttributeName: ({ attribute, fields }) => {
      if (attribute === INTERESTS_ATTRIBUTE_OPTION_ID) return t("account.interests");
      if (!fields) return attribute;

      return getFieldName(fields, { tag: attribute }) ?? attribute;
    },
    getAttributeOptions: async ({ fields } = {}) => {
      const FieldsAPI = useFields();
      const fieldTypeToConditionType = (fieldType: FieldType) => {
        if (fieldType === "INT") return "number";
        return "string";
      };

      const accountFields = fields
        ? {
            done: true,
            value: fields,
          }
        : await FieldsAPI.getFields();

      // Filtering - Campos no soportados
      const filteredFields = accountFields.value.filter((field) => {
        const filters = [
          field.format !== "DATE",
          field.tag !== "LANGUAGE",
          field.tag !== "SOURCE",
          field.tag !== "GENDER", // special case
          field.tag !== "EMAIL", // special case
        ];

        return filters.every(Boolean);
      });

      const options: AttributeOptions = filteredFields.map((field) => ({
        id: field.tag.toString(),
        name: field.name,
        type: fieldTypeToConditionType(field.format),
      }));

      // Special cases account fields
      options.push({
        id: "GENDER",
        name: t("account.gender"),
        type: "gender",
      });
      options.push({
        id: "EMAIL",
        name: "email",
        type: "email",
      });

      // Add account Interests
      options.push({
        id: INTERESTS_ATTRIBUTE_OPTION_ID,
        name: t("account.interests"),
        type: "interest",
      });
      return options;
    },
    predicatesToDescription: ({ predicates, fields }) => {
      let text = "";

      const getAttributeFieldName = (attribute: Attribute, fields: Fields | undefined): string => {
        if (!attribute.attribute || !fields) return attribute.attribute;

        const fixedAttributes: Record<string, string> = {
          [INTERESTS_ATTRIBUTE_OPTION_ID]: t("account.interests"),
        };

        const fieldName = Object.keys(fixedAttributes).includes(attribute.attribute)
          ? fixedAttributes[attribute.attribute]
          : getFieldName(fields, { tag: attribute.attribute });

        return fieldName ?? attribute.attribute;
      };

      const getOperatorText = (attribute: Attribute) => {
        if (attribute.operator === "exists") {
          return attribute.value ? t("comparisonOperator.def") : t("comparisonOperator.ndef");
        }
        return t(`comparisonOperator.${attribute.operator}`);
      };

      const getAttributeValueText = (attribute: Attribute) => {
        if (typeof attribute.value === "boolean") return "";

        return attribute.name ?? attribute.value;
      };

      predicates.attributes.forEach((attribute, index, predicatesArr) => {
        const fieldName = getAttributeFieldName(attribute, fields);
        const operatorText = getOperatorText(attribute);
        const attributeValueText = getAttributeValueText(attribute);

        text = text + ` ${fieldName} ${operatorText} ${attributeValueText}`;

        if (predicatesArr.length > index + 1) text = text + ` ${t(`logicalOperator.${predicates.operator}`)} `;
      });
      return text;
    },
    predicatesToCondition: ({ predicates, name, fields, interests }) => {
      const freemarker = freeMarkerService.predicateToConditional({
        predicates,
        fields,
        interests,
        attributeIds: {
          interestsId: INTERESTS_ATTRIBUTE_OPTION_ID,
        },
      });

      return {
        label: name,
        description: conditionsApp.predicatesToDescription({ predicates, fields }),
        before: freemarker.before,
        after: freemarker.after,
        predicates: cloneDeep(predicates),
      };
    },
    attributeIsValid: ({ attribute, fields, interests }) => {
      if (!fields)
        return {
          valid: false,
        };

      if (interests && attribute.attribute === INTERESTS_ATTRIBUTE_OPTION_ID && attribute.value !== "") {
        const interestFound = interests?.find((interest) => interest.id === attribute.value);
        if (!interestFound)
          return {
            valid: false,
            invalidInterest: true,
          };
      }

      const foundField = fields?.find((field) => field.tag === attribute.attribute);
      if (!foundField && attribute.attribute !== INTERESTS_ATTRIBUTE_OPTION_ID)
        return {
          valid: false,
          invalidField: true,
        };

      const conditions: Record<ConditionsPredicateTypes, Array<(p: Attribute) => boolean>> = {
        number: [(a: Attribute) => a.value !== ""],
        string: [(a: Attribute) => a.value !== ""],
        interest: [(a: Attribute) => a.value !== ""],
        gender: [(a: Attribute) => a.value !== ""],
        email: [(a: Attribute) => a.value !== ""],
      };

      return {
        valid: conditions[attribute.type].every((fn) => fn(attribute)),
      };
    },
    predicatesIsValid: ({ predicates, fields, interests }) => {
      if (!fields)
        return {
          valid: false,
        };

      let invalidFields: Array<string> = [];
      let invalidInterests: Array<string> = [];
      let isValid = true;

      predicates.attributes.forEach((attribute) => {
        const res = conditionsApp.attributeIsValid({ attribute, fields, interests });
        if (res.valid) return;
        isValid = false;

        if (res.invalidField) {
          invalidFields = [...invalidFields, attribute.attribute];
          return;
        }

        if (res.invalidInterest) {
          invalidInterests = [...invalidInterests, attribute.attribute];
          return;
        }
      });

      if (!predicates.predicates)
        return {
          valid: isValid,
          invalidFields,
          invalidInterests,
        };

      const res = conditionsApp.predicatesIsValid({
        predicates: predicates.predicates,
        fields,
        interests,
      });

      if (res.valid)
        return {
          valid: isValid,
          invalidFields,
          invalidInterests,
        };

      isValid = false;

      if (res.invalidFields) {
        invalidFields = [...invalidFields, ...res.invalidFields];
      }

      if (res.invalidInterests) {
        invalidInterests = [...invalidInterests, ...res.invalidInterests];
      }

      return {
        valid: isValid,
        invalidFields,
        invalidInterests,
      };
    },
  };
  return conditionsApp;
};
