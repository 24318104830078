<template>
  <div class="flex justify-between">
    <h2 class="m-0 text-base font-normal leading-6 sm:text-lg sm:font-medium text-gray-600 truncate">
      {{ title }}
    </h2>
    <button
      v-if="actionLabel"
      class="ml-2 text-sm font-medium text-sky-300 hover:text-sky-400 whitespace-nowrap"
      @click="emitValue"
    >
      {{ actionLabel }}
    </button>
  </div>
</template>

<script lang="ts" setup>
// Props
withDefaults(
  defineProps<{
    title: string;
    actionLabel?: string;
  }>(),
  {
    title: "",
    actionLabel: "",
  }
);

// Emits
const emit = defineEmits<{
  (e: "actionClick"): void;
}>();

const emitValue = () => {
  emit("actionClick");
};
</script>
