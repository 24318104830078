import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";

export interface IStripeSetupIntentRequest {
  email: string;
  locale: string;
}

export interface IStripeSetupIntent {
  id: string;
  client_secret: string;
}

export function useStripe(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function setupIntent(
    setupIntentData: IStripeSetupIntentRequest,
    country: string,
    loadingRef?: Ref<boolean>
  ): Promise<IStripeSetupIntent> {
    const res = await request({
      url: `/invoicepayments/${country === "br" ? "stripebr" : country === "mx" ? "stripemx" : "stripeus"}/setupintent`,
      method: "post",
      data: setupIntentData,
      loadingRef,
    });

    return res;
  }

  return { setupIntent };
}
