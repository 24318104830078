<template>
  <div
    class="relative flex cursor-text items-start rounded-lg border p-1 shadow-sm focus-within:outline-none sm:text-sm"
    :class="{
      'ring-red-400': error,
      'ring-gray-200 focus-within:ring-sky-500': !error,
      'bg-gray-100': disabled,
      'focus-within:ring-2': !disabled,
    }"
    tabindex="-1"
    @input="updateModelValue"
    @paste="onPaste"
    @keydown="onKeyDown"
    @focus.prevent="transferFocus"
    @click.prevent="transferFocus"
  >
    <div v-if="!disabled" :key="refreshContentEditable" class="max-w-full p-1">
      <SimpleBadge
        v-for="badge in badges"
        :key="badge.id.toString()"
        :model-value="badge.modelValue"
        :clickable="true"
        class="mx-1 mb-2.5 cursor-pointer select-none"
        v-bind="badge"
        @click="onBadgeClick(badge.id)"
        @delete="deleteBadge(badge.id)"
      />
      <span
        v-once
        ref="contentEditableElement"
        tabindex="0"
        contenteditable
        :data-placeholder="placeholder"
        class="max-w-full break-words focus:outline-none"
        >{{ modelValue }}</span
      >
    </div>
    <ExclamationCircleIcon v-if="error" class="absolute bottom-3 right-4 h-5 w-5 text-red-400" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, nextTick } from "vue";

//Components
import SimpleBadge, { Badge } from "@atoms/SimpleBadge";

//Icons
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export interface BadgeID extends Badge {
  id: string;
}

export type Badges = Array<BadgeID>;
//Props
const props = withDefaults(
  defineProps<{
    modelValue?: string;
    placeholder?: string;
    badges?: Badges;
    error?: boolean;
    disabled?: boolean;
    separators?: Array<string>;
  }>(),
  {
    modelValue: "",
    placeholder: "",
    badges: undefined,
    error: false,
    disabled: false,
    separators: undefined,
  },
);
//Emits
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "badgeClicked", id: string | number): void;
  (e: "delete", badge: number | string): void;
  (e: "paste", event: ClipboardEvent): void;
  (e: "keydown", event: KeyboardEvent): void;
}>();

//State
const contentEditableElement = ref<HTMLDivElement>();
const refreshContentEditable = ref(0);

watchEffect(
  () => {
    if (props.modelValue.length > 0) return;

    refreshContentEditable.value++;
    nextTick(() => {
      transferFocus();
    });
  },
  { flush: "post" },
);
//Methods
const updateModelValue = () => {
  if (!contentEditableElement.value) return;

  const text = contentEditableElement.value.textContent;

  if (!text) {
    emit("update:modelValue", "");
    return;
  }

  emit("update:modelValue", text || "");
};

const onBadgeClick = (id: string | number) => {
  emit("badgeClicked", id);
};

const deleteBadge = (id: string | number) => {
  emit("delete", id);
};

const onKeyDown = (event: KeyboardEvent) => {
  emit("keydown", event);
};

const onPaste = (event: ClipboardEvent) => {
  emit("paste", event);
};

const transferFocus = () => {
  contentEditableElement.value?.focus();
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
span {
  display: inline-block;
}
span[contenteditable]:empty::before {
  content: attr(data-placeholder);
  display: inline-block;
  color: rgb(180, 180, 180);
  opacity: 70%;
}
</style>
