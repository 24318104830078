export interface GenerateExpirationDateParams {
  exDays?: number;
  exHours?: number;
  exMinutes?: number;
  exSeconds?: number;
  exMiliseconds?: number;
}

export const generateExpirationDate = ({
  exDays = 0,
  exHours = 0,
  exMinutes = 0,
  exSeconds = 0,
  exMiliseconds = 0,
}: GenerateExpirationDateParams): DateString => {
  const date = new Date();
  date.setDate(date.getDate() + exDays);
  date.setHours(date.getHours() + exHours);
  date.setMinutes(date.getMinutes() + exMinutes);
  date.setSeconds(date.getSeconds() + exSeconds);
  date.setMilliseconds(date.getMilliseconds() + exMiliseconds);
  return date.toUTCString();
};
