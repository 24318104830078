import { getI18nInstance } from "@/vue/locales/i18n";

// Types
import type { ColorsApplication } from "./colors.types";

import esMessages from "./i18n/colors.es.json";
import ptMessages from "./i18n/colors.pt.json";

const { mergeLocaleMessage } = getI18nInstance().global;

mergeLocaleMessage("es", esMessages);
mergeLocaleMessage("pt", ptMessages);

export const useColorsApplication = (): ColorsApplication => {
  const { t } = getI18nInstance().global;
  const colorsApp: ColorsApplication = {
    getTagsColors: () => {
      return [
        {
          id: "yellow300",
          name: t("colors.yellow"),
          hexValue: "#fcd34d",
        },
        {
          id: "blue500",
          name: t("colors.blue"),
          hexValue: "#3b82f6",
        },
        {
          id: "sky400",
          name: t("colors.sky"),
          hexValue: "#38bdf8",
        },
        {
          id: "gray300",
          name: t("colors.lightGray"),
          hexValue: "#d1d5db",
        },
        {
          id: "gray500",
          name: t("colors.darkGray"),
          hexValue: "#6b7280",
        },
        {
          id: "lime400",
          name: t("colors.lime"),
          hexValue: "#a3e635",
        },
        {
          id: "orange400",
          name: t("colors.orange"),
          hexValue: "#fb923c",
        },
        {
          id: "yellow800",
          name: t("colors.brown"),
          hexValue: "#854d0e",
        },
        {
          id: "red600",
          name: t("colors.red"),
          hexValue: "#dc2626",
        },
        {
          id: "pink400",
          name: t("colors.pink"),
          hexValue: "#f472b6",
        },
        {
          id: "emerald500",
          name: t("colors.green"),
          hexValue: "#10b981",
        },
        {
          id: "purple600",
          name: t("colors.purple"),
          hexValue: "#9333ea",
        },
      ];
    },
  };

  return colorsApp;
};
