import { TemplateBlockAPI } from "@/vue/api/modules/templates/blocks.types";
import { TemplateBlock } from "@/vue/types/Templates";
import { useTemplateBlocks } from "@api/modules/templates/blocks";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

import { getI18nInstance } from "@locales/i18n";
import { SetCustomBlocksHandlersModule } from "../unlayer.types";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

export const setCustomBlocksHandlers: SetCustomBlocksHandlersModule = ({ unlayerInstance }) => {
  const TemplateBlocks = useTemplateBlocks();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  const isBlocksRepoAccount = session.value?.account.code.startsWith("blockspublic");
  const trackingEventsService = useTrackingEvents();

  let publicBlocks: TemplateBlock[] = [];
  const sortedCategories = ["Headers", "Posts", "Footers", "Posts", "Ecommerce", "Banners", "Varios"];

  unlayerInstance.registerCallback("block:added", async function (block: TemplateBlock, done) {
    const newApiBlock = await TemplateBlocks.createBlock(unlayer2Api(block));
    trackingEventsService.dispatchAll({
      name: "APP_TPLEDITOR_BLOCK_SAVED",
    });
    done(api2Unlayer(newApiBlock));
  });

  unlayerInstance.registerCallback("block:modified", async function (block: TemplateBlock, done) {
    if (!block.id) return;
    const apiBlock = await TemplateBlocks.modifyBlock(unlayer2Api(block));
    done(api2Unlayer(apiBlock));
  });

  unlayerInstance.registerCallback("block:removed", async function (block: TemplateBlock, done) {
    if (!block.id) return;
    await TemplateBlocks.deleteBlock(block.id);
    done(block);
  });

  unlayerInstance.registerProvider("blocks", async function (params, done) {
    if (!publicBlocks.length && !isBlocksRepoAccount) {
      const lang = getI18nInstance().global.locale.value.slice(0, 2);
      publicBlocks = (await TemplateBlocks.getPublicBlocks(lang)).map(api2Unlayer);
      publicBlocks.sort((a, b) => sortedCategories.indexOf(a.category) - sortedCategories.indexOf(b.category));
    }

    const res = await TemplateBlocks.getBlocks();
    const blocks: TemplateBlock[] = res.map(api2Unlayer);

    done([...blocks, ...publicBlocks]);
  });

  const unlayer2Api = (block: TemplateBlock): TemplateBlockAPI => {
    const apiBlock: TemplateBlockAPI = {
      id: block.id,
      category: block.category,
      tags: [...block.tags],
      json: JSON.stringify(block.data),
    };

    return apiBlock;
  };

  const api2Unlayer = (apiBlock: TemplateBlockAPI): TemplateBlock => {
    const isCustom = isBlocksRepoAccount || !apiBlock.account?.startsWith("blockspublic");

    const block: TemplateBlock = {
      id: apiBlock.id,
      userId: apiBlock.account || "",
      displayMode: "email",
      category: apiBlock.category,
      tags: [...apiBlock.tags],
      custom: isCustom,
      data: JSON.parse(apiBlock.json),
    };

    return block;
  };
};
