<template>
  <Menu
    v-slot="{ open }"
    as="div"
    :class="[disabled && 'pointer-events-none opacity-50', 'relative inline-block text-left']"
  >
    <MenuButton
      ref="buttonRef"
      :class="[small ? 'p-1' : 'p-2', GetBtnBackgroundClasses(theme)]"
      class="group flex items-center rounded text-white ring-2 hover:text-neutral-100 focus:outline-none"
    >
      <span class="sr-only">{{ t("menuBtnAccessibility") }}</span>
      <DotsVerticalIcon :class="GetIconBtnClasses(theme)" class="h-5 w-5" aria-hidden="true" />
    </MenuButton>
    <OpenStatus :open="open" @open="openDropDown" @close="closeDropDown" />
    <transition
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <MenuItems
        v-show="open"
        ref="listRef"
        static
        :class="[
          {
            'bg-white': ['black', 'white', 'transparent-white'].includes(theme),
            'bg-neutral-700': ['transparent-black'].includes(theme),
          },
          'absolute isolate z-50 rounded-md shadow-xl ring-1 ring-sky-500 ring-opacity-5 focus:outline-none',
        ]"
        data-intercom-target="DropdownMenuItems"
      >
        <div v-if="isDataItems(navigation)" class="py-1">
          <MenuItem v-for="dataItem in navigation" :key="dataItem.key" v-slot="{ active }" class="group">
            <button
              :class="[
                active && 'bg-neutral-200',
                GetTextColor(dataItem.theme || 'black'),
                {
                  'hover:bg-neutral-100': ['black', 'white', 'transparent-white'].includes(theme),
                  'hover:bg-neutral-600': ['transparent-black'].includes(theme),
                },
                'flex w-full items-center whitespace-nowrap px-4 py-2 text-left text-sm  focus:outline-none',
              ]"
              @click="UpdateSelected(dataItem)"
            >
              <component :is="dataItem?.icon" class="mr-2 h-5 w-5" aria-hidden="true" />
              <span class="truncate">{{ dataItem.value }}</span>
            </button>
          </MenuItem>
        </div>
        <div v-else class="divide-y divide-neutral-100 py-1">
          <div v-for="(dataItems, key) in navigation" :key="key">
            <MenuItem v-for="dataItem in dataItems" :key="dataItem.key" v-slot="{ active }" class="group">
              <button
                :class="[
                  active && 'bg-neutral-100',
                  GetTextColor(dataItem.theme),
                  {
                    'hover:bg-neutral-100': ['black', 'white', 'transparent-white'].includes(theme),
                    'hover:bg-neutral-600': ['transparent-black'].includes(theme),
                  },
                  'flex w-full whitespace-nowrap px-4 py-3 text-left text-sm font-medium focus:outline-none',
                ]"
                @click="UpdateSelected(dataItem)"
              >
                <component :is="dataItem?.icon" :class="['-ml-1 mr-2 h-5 w-5 flex-shrink-0']" aria-hidden="true" />
                <span>{{ dataItem.value }}</span>
              </button>
            </MenuItem>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script lang="ts" setup>
import { nextTick, ref } from "vue";

import type { Categories, DataItem, DataItems, Themes, DataItemTheme } from "./dropDownButton.types";

//Components
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

//Icons
import { DotsVerticalIcon } from "@heroicons/vue/solid";

// Utils
import { isArray } from "lodash";
import { Placement, createPopper } from "@popperjs/core";
import { OpenStatus } from "@helpers/headlessUI";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { Modifier } from "@popperjs/core";

//TypeGuards
const isDataItems = (options: DataItems | Categories): options is DataItems => isArray(options as DataItems);

//I18n
const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    navigation: DataItems | Categories;
    disabled?: boolean;
    selected?: DataItem;
    theme?: Themes;
    small?: boolean;
    placement?: Placement;
    autoPlacements?: Array<Placement>;
    modifiers?: Array<Partial<Modifier<any, any>>>;
  }>(),
  {
    navigation: () => [] as DataItems,
    selected: () => ({} as DataItem),
    disabled: false,
    theme: "white",
    small: false,
    placement: "bottom",
    autoPlacements: () => ["bottom"],
    modifiers: undefined,
  }
);

const emit = defineEmits<{
  (e: "update:selected", selected: DataItem): void;
}>();

//Emits
const UpdateSelected = (selected: DataItem) => {
  emit("update:selected", selected);
};

const buttonRef = ref();
const listRef = ref();
let popperInstance: ReturnType<typeof createPopper> | undefined = undefined;
let timeoutInstance: ReturnType<typeof setTimeout> | undefined = undefined;

const openDropDown = () => {
  if (popperInstance) {
    clearTimeout(timeoutInstance);
    timeoutInstance = undefined;
    return;
  }

  popperInstance = createPopper(buttonRef?.value?.el, listRef?.value?.el, {
    placement: props.placement,
    modifiers: [
      {
        name: "flip",
        options: {
          allowedAutoPlacements: props.autoPlacements,
          fallbackPlacements: props.autoPlacements,
          altBoundary: true,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      ...(props.modifiers ?? []),
    ],
  });
  nextTick(() => {
    popperInstance?.forceUpdate();
  });
};

const closeDropDown = () => {
  timeoutInstance = setTimeout(() => {
    if (!popperInstance) return;
    popperInstance.destroy();
    popperInstance = undefined;
  }, 100);
};

//Theme
const GetTextColor = (theme?: DataItemTheme) => {
  if (theme == "red") return "text-red-500";

  if (["transparent-black"].includes(props.theme)) return "text-neutral-400 hover:text-neutral-300";

  return "text-neutral-500";
};

const GetBtnBackgroundClasses = (theme: Themes) => {
  if (theme == "white") return "bg-white ring-white focus:ring-sky-400";
  if (["transparent-white", "transparent-black"].includes(theme))
    return "bg-transparent ring-transparent focus:ring-sky-400";

  return "bg-neutral-600 ring-neutral-500 focus:ring-sky-400";
};

const GetIconBtnClasses = (theme: Themes) => {
  if (theme === "white") return "text-neutral-600 group-hover:text-neutral-500";
  if (theme === "transparent-white") return "text-neutral-600 group-hover:text-neutral-700";
  if (theme === "transparent-black") return "text-neutral-400 group-hover:text-neutral-200";

  return "text-white group-hover:text-neutral-200";
};
</script>

<i18n lang="json">
{
  "es": {
    "menuBtnAccessibility": "Abrir menu"
  },
  "pt": {
    "menuBtnAccessibility": "Abra o menu"
  }
}
</i18n>
