export interface ColorByValue {
  id: string;
  name?: string;
  value: string;
}

export const isColorByValue = (color: Color | ColorByValue): color is ColorByValue =>
  (color as ColorByValue).value !== undefined;

export interface ColorByHex {
  id: string;
  name?: string;
  hexValue: HexColor;
}

export const isColorByHex = (color: Color | ColorByHex): color is ColorByHex =>
  (color as ColorByHex).hexValue !== undefined;

export interface ColorByRGB {
  id: string;
  name?: string;
  rgbValue: RGBColor;
}

export const isColorByRGB = (color: Color | ColorByRGB): color is ColorByRGB =>
  (color as ColorByRGB).rgbValue !== undefined;

export type Color = ColorByValue | ColorByHex | ColorByRGB;

export type Colors = Array<Color>;

// Todo: Revisar esto que pinchaba al usarlo para revisar Tags
// export const isHexString = (c: HexColor | string): c is HexColor => {
//   const pattern = /^#[0-9a-fA-F]{6}$/;
//   return pattern.test(c);
// };
