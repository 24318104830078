<template>
  <div ref="elementRef" class="relative w-max">
    <IconButton
      :disabled="disabled"
      :label="tooltip"
      :show-tooltip="!menuIsOpen"
      :class="[{ '!bg-gray-200': disabled }, 'border border-gray-300']"
      @click.stop="toggleMenu"
      @keydown.escape="closeMenu"
    >
      <ViewBoardsIcon class="ml-0.5 mt-0.5 h-5 w-5 text-gray-500" />
    </IconButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="menuIsOpen"
        class="absolute right-0 top-10 isolate z-50 w-max rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <label class="w-full px-4 pt-4 text-xs font-medium text-gray-400">{{ label }}</label>
        <div class="max-h-96 space-y-4 overflow-y-auto px-4 pb-4 pt-3">
          <div
            v-for="item in orderedItems"
            :key="item.key"
            :class="['flex items-center']"
            @click.stop="() => toggleItem(item)"
          >
            <Checkbox
              :model-value="selected.some((s) => s.key === item.key)"
              :class="[
                {
                  'bg-gray-200':
                    item.content?.disabled || (selected.every((s) => s.key !== item.key) && maxLimitSelected),
                },
              ]"
              :disabled="item.content?.disabled || (selected.every((s) => s.key !== item.key) && maxLimitSelected)"
              class="pointer-events-none"
            />
            <label
              :class="[
                {
                  'opacity-50':
                    item.content?.disabled || (selected.every((s) => s.key !== item.key) && maxLimitSelected),
                },
                'my-auto ml-3 text-sm font-medium text-gray-500',
              ]"
            >
              {{ item.value }}
            </label>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup generic="DataType">
import { ref, toRaw, watch, computed } from "vue";

import { Items, Item } from "./checkboxListButton.types";

// Components
import IconButton from "@atoms/IconButton.vue";
import Checkbox from "@atoms/CheckBox.vue";

// Icons
import { ViewBoardsIcon } from "@heroicons/vue/outline";

// Utils
import { onClickOutside } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    label: string;
    tooltip: string;
    maxSelected?: number;
    items: Items<string, string, DataType>;
    selected: Items<string, string, DataType>;
    disabled?: boolean;
    order?: boolean;
  }>(),
  {
    label: "",
    placement: "bottom",
    maxSelected: undefined,
    autoPlacements: () => ["bottom"],
    disabled: false,
    order: true,
  }
);

const emit = defineEmits<{
  "update:selected": [Items<string, string, DataType>];
}>();

const menuIsOpen = ref(false);
const toggleMenu = () => {
  menuIsOpen.value = !menuIsOpen.value;
};
const closeMenu = () => {
  menuIsOpen.value = false;
};

const orderedItems = ref<Items<string, string, DataType>>();

const orderItems = () => {
  const notSelectedItems: Items<string, string, DataType> = props.items.filter((i) => {
    return props.selected.every((s) => s.key !== i.key);
  });

  orderedItems.value = [...toRaw(props.selected), ...notSelectedItems];
};

watch([() => menuIsOpen.value], () => {
  if (!menuIsOpen.value) return;

  if (props.order) return orderItems();
  orderedItems.value = props.items;
});

const maxLimitSelected = computed(() => {
  if (props.maxSelected === undefined) return false;

  return props.selected.length >= props.maxSelected;
});

const toggleItem = (item: Item<string, string, DataType>) => {
  if (item.content?.disabled) return;

  const selectedIndex = props.selected.findIndex((i) => i.key === item.key);
  if (selectedIndex === -1 && !maxLimitSelected.value) {
    emit("update:selected", [...toRaw(props.selected), toRaw(item)]);
    return;
  }
  const filteredSelected = toRaw(props.selected).filter((i) => i.key !== item.key);
  emit("update:selected", [...filteredSelected]);
};

const elementRef = ref();
onClickOutside(elementRef, () => {
  closeMenu();
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
