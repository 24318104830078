<template>
  <td
    class="whitespace-nowrap text-sm text-gray-600"
    :class="{ 'text-right': rightAligned }"
  >
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "SimpleTableCell",
  props: {
    rightAligned: { type: Boolean, default: false },
  },
};
</script>
