<template>
  <div>
    <InputLabel :for="id">{{ label }}</InputLabel>
    <div class="mt-1">
      <slot :described-by="error ? `${id}-error` : `${id}-hint`" :hasError="!!error"></slot>
    </div>
    <InputHint v-if="!error" :id="`${id}-hint`"><span v-html="hint"></span></InputHint>
    <InputError v-if="error" :id="`${id}-error`">{{ error }}</InputError>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import InputLabel from "@atoms/InputLabel.vue";
import InputHint from "@atoms/InputHint.vue";
import InputError from "@atoms/InputError.vue";

export default defineComponent({
  name: "FormGroup",
  components: { InputLabel, InputHint, InputError },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
});
</script>
