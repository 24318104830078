<template>
  <template v-for="(attribute, index) in predicates.attributes" :key="attribute.id">
    <div v-if="attribute" class="flex flex-shrink-0 space-x-0.5 pr-0.5">
      <AttributeButton
        :attribute="attribute"
        :disabled="disabled"
        :mount-open="openAttribute(index)"
        @update:attribute="(attribute) => updateAttribute(attribute)"
        @delete="() => deleteAttribute(index)"
      />
      <ConcatenatorButton
        v-if="predicates.attributes.length > index + 1"
        :operator="predicates.operator"
        :disabled="disabled"
        @update:concatenator="updateOperator"
      />
    </div>
  </template>
</template>

<script lang="ts" setup>
import { toRaw, ref, onMounted } from "vue";

// Components
import AttributeButton from "./AttributeButton.vue";
import ConcatenatorButton from "./ConcatenatorButton.vue";

// Utils
import { cloneDeep } from "lodash";
import { replaceElementInArrayById } from "@/vue/helpers/data";

// Types
import type { Operator, PredicatesWithId, AttributeWithId, AttributesWithId } from "@domain/predicates";
import { ConditionsPredicateTypes } from "@/vue/types/conditions";

const props = withDefaults(
  defineProps<{
    predicates: PredicatesWithId<string, ConditionsPredicateTypes>;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: "update:predicates", predicates: PredicatesWithId): void;
}>();

const attributesCount = ref(props.predicates.attributes.length);
const updateAttributeCount = () => {
  attributesCount.value = props.predicates.attributes.length;
};

const openAttribute = (index: number) => {
  if (attributesCount.value <= index) {
    updateAttributeCount();
    return true;
  }
  return false;
};

onMounted(() => {
  updateAttributeCount();
});

const updateAttribute = (newAttribute: AttributeWithId<string, ConditionsPredicateTypes>) => {
  const foundAttribute = props.predicates.attributes.find((attribute) => attribute.id === newAttribute.id);

  if (!foundAttribute) return;

  let newAttributes: AttributesWithId = cloneDeep(toRaw(props.predicates.attributes));
  const cloneAttribute = { ...foundAttribute };
  cloneAttribute.attribute = newAttribute.attribute;
  cloneAttribute.operator = newAttribute.operator;
  cloneAttribute.type = newAttribute.type;
  cloneAttribute.value = newAttribute.value ?? "";
  cloneAttribute.name = newAttribute.name;
  newAttributes = replaceElementInArrayById<AttributeWithId>(newAttributes, cloneAttribute);

  emit("update:predicates", { ...toRaw(props.predicates), attributes: newAttributes });
};

const deleteAttribute = (index: number) => {
  const newAttributes = toRaw(props.predicates.attributes);

  newAttributes.splice(index, 1);

  attributesCount.value--;

  emit("update:predicates", { ...toRaw(props.predicates), attributes: newAttributes });
};

const updateOperator = (operator: Operator) => {
  emit("update:predicates", { ...toRaw(props.predicates), operator });
};
</script>
