var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n    <div class='modal-content'>\n        <div class='modal-header'>\n            <button type='button' class='close' data-dismiss='modal'>\n                <span aria-hidden='true'>&times;</span>\n            </button>\n            <h4 class='modal-title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"newHTMLContent")), depth0))
    + "\n            </h4>\n        </div>\n        <div class='modal-body'>\n            <div class='modal-body-padded'>\n                <ul class='nav nav-tabs'>\n                    <li class='active'>\n                        <a data-toggle='tab' data-target='#htmlFile'>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"addFromComputer")), depth0))
    + "\n                        </a>\n                    </li>\n                    <li>\n                        <a data-toggle='tab' data-target='#htmlRaw'>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"addFromClipboard")), depth0))
    + "\n                        </a>\n                    </li>\n                </ul>\n                <div class='tab-content'>\n                    <div id='htmlFile' class='tab-pane active'>\n                        <form data-form='create-html-template'>\n                            <input type='hidden' name='source' value='file'>\n                            <div class='upload-container'>\n                                <div class='form-group'>\n                                    <div>\n                                        <span class='filename'>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noFileSelected")), depth0))
    + "\n                                        </span>\n                                    </div>\n                                    <p class='help-block error-message error-hidden' data-validation-message='htmlFile'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fileRequired")), depth0))
    + "\n                                    </p>\n                                    <div class='dropzone dropper' data-role='dropzone'>\n                                        <h3>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dropFilesHere")), depth0))
    + "\n                                        </h3>\n                                        <p>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"ifYouPrefer")), depth0))
    + "\n                                        </p>\n                                        <button type='button' data-role='uploader' class='btn btn-default'>\n                                            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"uploadFile")), depth0))
    + "\n                                        </button>\n                                    </div>\n                                </div>\n                            </div>\n                            <div class='progress hidden'>\n                                <div class='progress-bar progress-bar-striped active upload-progress' style='width: 0%'>\n                                    0%\n                                </div>\n                            </div>\n                            <div class='modal-mini-footer'>\n                                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                                </button>\n                                <button type='submit' class='btn btn-primary'>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                                </button>\n                            </div>\n                        </form>\n                    </div>\n                    <div id='htmlRaw' class='tab-pane'>\n                        <form data-form='create-html-template'>\n                            <input type='hidden' name='source' value='raw'>\n                            <div class='form-group'>\n                                <label>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"pasteHTML")), depth0))
    + ":\n                                </label>\n                                <textarea class='form-control' name='htmlRaw' maxlength='50000'\n                                    placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"pasteYourHTMLHere")), depth0))
    + "'></textarea>\n                            </div>\n                            <div class='modal-mini-footer'>\n                                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                                </button>\n                                <button type='submit' class='btn btn-primary'>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                                </button>\n                            </div>\n                        </form>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n<form data-form='upload-file' action='contacts/import/upload' class='hidden' method='POST'>\n    <input type='file' name='fileToUpload' data-input-type='upload-file'>\n</form>";
},"useData":true});