import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";
import { IDashboardDataSet, IDashboardDataPoint } from "@domain/dashboard/base";
import { formatISO, differenceInDays, startOfDay, subDays } from "date-fns";

export function useDashboard(requestor?: Requestor) {
  const request = useRequests();

  async function get(from: Date, to: Date, loadingRef?: Ref<boolean>): Promise<IDashboardDataSet> {
    const days = differenceInDays(to, from) + 1;
    const prevFrom = startOfDay(subDays(from, days));

    const res = await request({
      url: "/accountmetrics/dashboard",
      method: "get",
      urlParams: {
        "filters.timestamp.gte": formatISO(prevFrom).substr(0, 19) + "Z",
        "filters.timestamp.lte": formatISO(to).substr(0, 19) + "Z",
      },
      loadingRef,
    });

    const data = {};

    Object.entries(res.data).forEach((e) => {
      //TODO: usar el huso horario del usuario

      const point: IDashboardDataPoint = e[1] as IDashboardDataPoint;

      for (const type of ["automation", "bulk", "transactional"]) {
        if (point.mail_metrics[type]) {
          point.mail_metrics[type].bounced_total =
            point.mail_metrics[type].bounced.hard + point.mail_metrics[type].bounced.soft;

          point.mail_metrics[type].delivered = point.mail_metrics[type].sent - point.mail_metrics[type].bounced_total;

          point.mail_metrics[type].unsubscribed_total =
            (point.mail_metrics[type].unsubscribed.not_interested || 0) +
            (point.mail_metrics[type].unsubscribed.not_specified || 0) +
            (point.mail_metrics[type].unsubscribed.oneclick || 0) +
            (point.mail_metrics[type].unsubscribed.other || 0) +
            (point.mail_metrics[type].unsubscribed.too_frequent || 0);

          point.mail_metrics[type].complained_total =
            (point.mail_metrics[type].unsubscribed.spam_fbl || 0) +
            (point.mail_metrics[type].unsubscribed.spam_never_subscribed || 0) +
            (point.mail_metrics[type].unsubscribed.spam_offensive || 0);
        }
      }

      data[e[0].replace("Z", "-03:00")] = point;
    });

    // console.log(data);

    return data;
  }

  return { get };
}
