import { EditView } from "@/core/magnet";
import app from "@/app";
import signupTpl from "../templates/index.hbs";
import accountCreationTpl from "../templates/accountCreation.hbs";
import accountCreationErrorTpl from "../templates/accountCreationError.hbs";
import checkInboxTpl from "../templates/checkInbox.hbs";

export default EditView.extend({
  module: "signup",
  templates: {
    initial: signupTpl,
  },
  createInstance: false,
  renderArea: ".app",
  events: {
    "submit [data-form=signup]": "signup",
    "blur [name=email]": "autocompleteAccount",
    "click [data-action=render-form]": "renderForm",
    "click [data-action=accept-tos]": "tosChanged",
    "click [data-action=accept-tos-eu]": "tosEuChanged",
  },

  beforeRender(callback) {
    try {
      const preinit = JSON.parse(window.localStorage.getItem("preinit"));
      if (preinit) {
        if (preinit.integration === "tiendanube") {
          this.item = {
            email: preinit.store.email,
            code: preinit.store.original_domain?.split(".")[0],
          };
        } else if (preinit.integration === "jumpseller") {
          this.item = {
            email: preinit.store.email,
            code: preinit.store.code,
          };
        } else if (preinit.integration === "tray") {
          let code;
          if (preinit.store.uri?.match(/https?:\/\/([^.]+).*/).length > 1) {
            code = preinit.store.uri.match(/https?:\/\/([^.]+).*/)[1].slice(0, 15);
          }
          this.item = {
            email: preinit.store.email_1,
            code: code,
          };
        } else if (preinit.integration === "ecwid") {
          this.item = {
            email: preinit.store.account.accountEmail,
            code: preinit.store.general_info.starterSite.ecwidSubdomain
              .toLowerCase()
              .replace(/[^a-z0-9]/g, "")
              .slice(0, 15),
          };
        }
      }
    } catch (err) {
      console.error(err);
    }

    callback();
  },

  // Performs the needed bindings after renderization
  afterRender: function () {
    var self = this;
    self.$("input:visible").first().focus();
    $(".app").addClass("login overflow-auto");
    $("html").removeAttr("style").addClass("has-pattern");
    // var script = document.createElement('script');
    // script.type = 'text/javascript';
    // script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaReady&render=' + window.config.recaptcha;
    // document.head.appendChild(script);

    $("[data-form=signup]").find("button[type=submit]").removeAttr("disabled");

    if (window.location.search) {
      var regex = /email=(([A-Za-z0-9_.\-+]+)(@|%40)([\dA-Za-z.-]+)\.([A-Za-z.]{2,63}))/gi;
      var match = regex.exec(window.location.search);
      if (match) {
        var email = decodeURIComponent(match[1]);
        $("[name=email]").val(email);
        $("[name=email]").blur();
      }
    }

    // app.integrations.amplitude.event("APP_VISIT_SIGNUP");
    app.integrations.gtm.event({ event: "signup.visit" });
  },

  tosChanged: function () {
    if ($("#accept-tos").is(":checked")) {
      $(".tos-error").addClass("hide");
    }
  },

  tosEuChanged: function () {
    if ($("#accept-tos-eu").is(":checked")) {
      $(".tos-error").addClass("hide");
    }
  },

  // Attemps a signup
  signup: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Disable submit button to prevent multiple submits
    self.disableSubmitButton(e);

    // Validate values
    if (!self.validateFormFields(form)) {
      return false;
    }

    if (!$("#accept-tos").is(":checked") || !$("#accept-tos-eu").is(":checked")) {
      $(".tos-error").removeClass("hide");
      self.enableSubmitButton(form);
      return false;
    }

    // Clean previous validation error messages
    self.cleanFormErrorMessages(form);

    // Add UTM Source data
    if (window.utm_source) {
      data.utmSource = window.utm_source;
    }

    // grecaptcha
    //     .execute(window.config.recaptcha, {action: 'signup'})
    //     .then(function(token) {
    //         data.captcha = token;
    data.captcha = "disabled";

    //trim fields
    data.email = data.email.trim();
    data.code = data.code.trim();

    // Attempts a signup
    $.ajax({
      type: "POST",
      url: "signup",
      data: data,
      success: function (response) {
        if (!response.data) {
          self.enableSubmitButton(form);
          self.showGenericErrorMessage();
          return false;
        }
        self.confirmAccountCreation({
          signup_token: response.data,
        });

        //app.integrations.amplitude.event("APP_SIGNUP_INIT");
        app.integrations.gtm.event({ event: "signup.init" }); //Step1GA4
      },
      error: function (xhr) {
        var response = xhr.responseJSON || false;

        // Restore submit button
        self.enableSubmitButton(form);

        // Check if we received a response
        if (!response) {
          self.showGenericErrorMessage();
          return false;
        }

        // Show invalid request error
        if (response.error.type === "INVALID_REQUEST") {
          $(".ip-error").removeClass("hide").find("p").text(response.error.userMessage);
          return false;
        }

        // Show validation errors
        if (response.error.type === "VALIDATION_ERROR") {
          self.showValidationErrors(response.error.validationErrors);
          return false;
        }
      },
    });
    //            });
  },

  // Show a generic error message
  showGenericErrorMessage: function () {
    var self = this;
    self.$(".server-error").removeClass("hide");
  },

  // Cleans a form
  cleanFormErrorMessages: function (form) {
    $(form).find(".ip-error").addClass("hide");
    $(form).find(".server-error").addClass("hide");
    $(form).find(".error-message").addClass("error-hidden");
    $(form).find(".has-error").removeClass("has-error");
  },

  // Shows the message to check the inbox after successful sign up
  renderCheckInbox: function () {
    var self = this;
    self.changeTemplate(checkInboxTpl).render();
  },

  // Shows the form to create an account
  renderForm: function (e) {
    var self = this;
    e.preventDefault();
    self.changeTemplate(signupTpl).render();
  },

  // Shows the account creation page
  confirmAccountCreation: function (args) {
    var self = this;

    // Abort if the token parameter is not present
    if (args.signup_token === undefined && !args.signup_token) {
      window.location.href = "/";
    }

    // Save UTM
    if (args.utm_source !== undefined) {
      window.utm_source = args.utm_source;
    }

    // Render account creation page
    self.changeTemplate(accountCreationTpl).render();

    // Attempts an account creation confirmation
    $.ajax({
      type: "POST",
      url: "signup/confirm",
      timeout: 30 * 1000,
      data: {
        signupToken: args.signup_token,
      },
      success: function (response) {
        $(".grecaptcha-badge").remove();

        // Save cookie
        app.saveSessionCookie(response.data);

        // Redirect to dashboard
        window.location.href = "/dashboard";
      },
      error: function (xhr, textstatus) {
        var response = xhr.responseJSON || false,
          template = accountCreationErrorTpl,
          data = self.renderData();

        // Reload on timeouts
        if (textstatus === "timeout") {
          window.location.reload();
          return false;
        }

        // Check if we received a response
        if (!response) {
          self.showGenericErrorMessage();
          return false;
        }

        // Show validation errors
        if (!response.success) {
          switch (response.error.status) {
            case 409:
              data.errorMessage = window.lang.signUp.errors.duplicate;
              break;
            case 400:
              data.errorMessage = window.lang.signUp.errors.invalid;
              break;
            default:
              data.errorMessage = window.lang.signUp.errors.default;
              break;
          }
          self.$el.html(template(data));
        }
      },
    });
  },

  // Autocompletes the account name based in the email
  autocompleteAccount: function (e) {
    var email = $(e.currentTarget).val(),
      emailParts = email.split("@"),
      destination = $("[name=code]"),
      publicDomains = ["gmail", "hotmail", "live", "outlook", "yahoo", "arnet", "ciudad", "fibertel", "speedy"];
    if (emailParts[1]) {
      var account = emailParts[1]
        .split(".")[0]
        .replace(/[^a-zA-Z0-9]/gi, "")
        .toLowerCase();
      account = account.substring(0, Math.min(15, account.length));
      if (destination.val() == "" && $.inArray(account, publicDomains) == -1) {
        destination.val(account);
      }
    }
  },
});

// var captchaReady = function () {
//   $("[data-form=signup]").find("button[type=submit]").removeAttr("disabled");
//   $(".grecaptcha-badge").css("bottom", "100px");
// };
