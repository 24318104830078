import { Ref } from "vue";
import {
  CreditCard,
  ICreditCardMercadoPagoCreate,
  ICreditCardPagoUnoCreate,
  ICreditCardStripeCreate,
  AFIPData
} from "@api/models/CreditCard";
import { Requestor, useRequests } from "@api/requests";

export function useCreditCards(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function getAFIPData(fiscalId: string): Promise<AFIPData> {
    const res = await request({
      url: `/invoicepayments/afipdata/${fiscalId}`,
      method: "get"
    });

    return res.data;
  }

  async function createMercadoPago(
    tokenizeData: ICreditCardMercadoPagoCreate,
    loadingRef?: Ref<boolean>
  ): Promise<CreditCard> {
    const res = await request({
      url: "/invoicepayments/cards/mercadopago",
      method: "post",
      data: tokenizeData,
      loadingRef,
      disableNotify400: true
    });

    await request({
      url: `/plan/card/MPTOKEN_AR/add`,
      method: "post",
      loadingRef
    });

    return new CreditCard(res.data);
  }

  async function createTnPagoUno(
    tokenizeData: ICreditCardPagoUnoCreate,
    loadingRef?: Ref<boolean>
  ): Promise<CreditCard> {
    const res = await request({
      url: "/invoicepayments/cards/tnpagouno",
      method: "post",
      data: tokenizeData,
      loadingRef,
      disableNotify400: true
    });

    await request({
      url: `/plan/card/TNPAGOUNO_AR/add`,
      method: "post",
      loadingRef
    });

    return new CreditCard(res.data);
  }

  async function createStripe(
    tokenizeData: ICreditCardStripeCreate,
    country: string,
    paymethod: string,
    loadingRef?: Ref<boolean>
  ): Promise<CreditCard> {
    const res = await request({
      url: "/invoicepayments/cards/stripe" + country,
      method: "post",
      data: tokenizeData,
      loadingRef,
      disableNotify400: true
    });

    await request({
      url: `/plan/card/${paymethod}/add`,
      method: "post",
      loadingRef
    });

    return new CreditCard(res.data);
  }

  async function list(loadingRef?: Ref<boolean>): Promise<CreditCard[]> {
    const res = await request({
      url: "/invoicepayments/cards",
      method: "get",
      loadingRef
    });

    return res.data.map((c: any) => new CreditCard(c));
  }

  async function remove(id: string, loadingRef?: Ref<boolean>): Promise<void> {
    await request({
      url: `/invoicepayments/cards/${id}`,
      method: "delete",
      loadingRef
    });

    await request({
      url: `/plan/card/remove`,
      method: "post",
      loadingRef
    });
  }

  return { getAFIPData, createTnPagoUno, createMercadoPago, createStripe, list, remove };
}
