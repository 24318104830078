import { Model } from "@/core/magnet";
import app from "@/app";
import Handlebars from "handlebars";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

export default Model.extend({
  urlRoot: "integrations",
  parse: function (response) {
    if (response.data === "") response.data = {};
    response.data.enabled = response.data.created ? true : false;
    return response.data;
  },
  additionals: {
    integrations: function () {
      return $.get(`/data/integrations-${app.lang}.json?_=${Date.now()}`);
    },
  },

  // Returns the full activation URL with redirect URI
  getActivationUrl: function () {
    const template = Handlebars.compile(this.get("redirect")[window.config.environment]);
    const url = template({
      appUrl: window.location.origin,
      account: app.session.get("account"),
      fields: this.get("activationData"),
      random: Math.random().toString(36).substring(2, 10),
    });
    return url;
  },

  // Sends the authorization data via API to finish the integration setup
  init: function (data) {
    const trackingEventsService = useTrackingEvents();
    trackingEventsService.amplitude({
      name: "APP_INTEGRATION_INIT",
      data: {
        id: this.id,
        data: data,
      },
    });
    return $.post("integrations/" + this.id + "/init", data);
  },

  // Sends the authorization data via API to finish the integration setup
  preinit: function (data) {
    return $.ajax({
      url: `integrations/${this.id}/preinit`,
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },

  // Deactivates the integration
  deactivate: function () {
    return $.post("integrations/" + this.id + "/cancel");
  },

  refreshProducts: function () {
    return $.post("integrations/" + this.id + "/refresh/products");
  },

  refresh: function () {
    return $.post("integrations/" + this.id + "/refresh");
  },
});
