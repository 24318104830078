<template>
  <svg
    v-if="isLoading"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48px"
    height="48px"
    class="animate-pulse text-gray-100"
  >
    <path fill="currentColor" d="M45,35c0,2.2-1.8,4-4,4H7c-2.2,0-4-1.8-4-4V13c0-2.2,1.8-4,4-4h34c2.2,0,4,1.8,4,4V35z" />
  </svg>
  <svg
    v-else-if="brand === 'amex' || brand === 'american-express'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48px"
    height="48px"
  >
    <path
      fill="#1976D2"
      d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
    />
    <path
      fill="#FFF"
      d="M22.255 20l-2.113 4.683L18.039 20h-2.695v6.726L12.341 20h-2.274L7 26.981h1.815l.671-1.558h3.432l.682 1.558h3.465v-5.185l2.299 5.185h1.563l2.351-5.095v5.095H25V20H22.255zM10.135 23.915l1.026-2.44 1.066 2.44H10.135zM37.883 23.413L41 20.018h-2.217l-1.994 2.164L34.86 20H28v6.982h6.635l2.092-2.311L38.767 27h2.21L37.883 23.413zM33.728 25.516h-4.011v-1.381h3.838v-1.323h-3.838v-1.308l4.234.012 1.693 1.897L33.728 25.516z"
    />
  </svg>
  <svg v-else-if="brand === 'diners'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
    <path fill="#CFD8DC" d="M45,35c0,2.2-1.8,4-4,4H7c-2.2,0-4-1.8-4-4V13c0-2.2,1.8-4,4-4h34c2.2,0,4,1.8,4,4V35z" />
    <path fill="#1565C0" d="M29,16H19c-4.4,0-8,3.6-8,8c0,4.4,3.6,8,8,8h10c4.4,0,8-3.6,8-8C37,19.6,33.4,16,29,16z" />
    <path
      fill="#FFF"
      d="M19,18c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S22.3,18,19,18z M15,24c0-1.9,1.3-3.4,3-3.9v7.7C16.3,27.4,15,25.9,15,24z M20,27.9v-7.7c1.7,0.4,3,2,3,3.9C23,25.9,21.7,27.4,20,27.9z"
    />
  </svg>
  <svg
    v-else-if="brand === 'master' || brand === 'mastercard'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48px"
    height="48px"
  >
    <path
      fill="#3F51B5"
      d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
    />
    <path fill="#FFC107" d="M30 14A10 10 0 1 0 30 34A10 10 0 1 0 30 14Z" />
    <path
      fill="#FF3D00"
      d="M22.014,30c-0.464-0.617-0.863-1.284-1.176-2h5.325c0.278-0.636,0.496-1.304,0.637-2h-6.598C20.07,25.354,20,24.686,20,24h7c0-0.686-0.07-1.354-0.201-2h-6.598c0.142-0.696,0.359-1.364,0.637-2h5.325c-0.313-0.716-0.711-1.383-1.176-2h-2.973c0.437-0.58,0.93-1.122,1.481-1.595C21.747,14.909,19.481,14,17,14c-5.523,0-10,4.477-10,10s4.477,10,10,10c3.269,0,6.162-1.575,7.986-4H22.014z"
    />
  </svg>
  <svg
    v-else-if="brand === 'visa' || brand === 'debvisa'"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48px"
    height="48px"
  >
    <path
      fill="#1565C0"
      d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
    />
    <path
      fill="#FFF"
      d="M15.186 19l-2.626 7.832c0 0-.667-3.313-.733-3.729-1.495-3.411-3.701-3.221-3.701-3.221L10.726 30v-.002h3.161L18.258 19H15.186zM17.689 30L20.56 30 22.296 19 19.389 19zM38.008 19h-3.021l-4.71 11h2.852l.588-1.571h3.596L37.619 30h2.613L38.008 19zM34.513 26.328l1.563-4.157.818 4.157H34.513zM26.369 22.206c0-.606.498-1.057 1.926-1.057.928 0 1.991.674 1.991.674l.466-2.309c0 0-1.358-.515-2.691-.515-3.019 0-4.576 1.444-4.576 3.272 0 3.306 3.979 2.853 3.979 4.551 0 .291-.231.964-1.888.964-1.662 0-2.759-.609-2.759-.609l-.495 2.216c0 0 1.063.606 3.117.606 2.059 0 4.915-1.54 4.915-3.752C30.354 23.586 26.369 23.394 26.369 22.206z"
    />
    <path
      fill="#FFC107"
      d="M12.212,24.945l-0.966-4.748c0,0-0.437-1.029-1.573-1.029c-1.136,0-4.44,0-4.44,0S10.894,20.84,12.212,24.945z"
    />
  </svg>
  <svg
    v-else-if="brand === 'cabal'"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 35C45 37.209 43.209 39 41 39H7C4.791 39 3 37.209 3 35V13C3 10.791 4.791 9 7 9H41C43.209 9 45 10.791 45 13V35Z"
      fill="#00619B"
    />
    <g clip-path="url(#clip0_2806_20)">
      <path
        d="M24.8131 14C24.8131 15.5092 24.8264 16.9012 24.8048 18.2915C24.7982 18.6801 24.9977 18.9045 25.2372 19.1424C26.4014 20.2948 27.5672 21.4472 28.723 22.608C29.3649 23.2529 29.3666 23.6667 28.7313 24.3049C27.5738 25.4657 26.408 26.6147 25.2405 27.7672C24.9994 28.005 24.8015 28.2295 24.8081 28.6181C24.8314 30.0101 24.8165 31.402 24.8165 32.7839C25.0959 32.9246 25.1857 32.6868 25.3071 32.5678C28.0162 29.8961 30.722 27.2228 33.4211 24.541C34.1679 23.799 34.1945 23.1591 33.4794 22.4456C30.7054 19.6817 27.9164 16.933 25.1291 14.1826C25.0759 14.1307 24.9961 14.1039 24.8131 14Z"
        fill="#FEFEFE"
      />
      <path
        d="M23.1785 32.9999C23.1785 31.36 23.1735 29.9162 23.1818 28.4739C23.1834 28.1105 22.9273 27.9346 22.7161 27.7235C21.5753 26.5845 20.4261 25.4538 19.2902 24.3115C18.63 23.6481 18.6267 23.278 19.2786 22.618C20.4128 21.4723 21.5487 20.3282 22.7095 19.211C23.0604 18.8743 23.2034 18.5275 23.1934 18.0418C23.1652 16.7352 23.1834 15.427 23.1834 14.1239C22.8558 14.0418 22.7594 14.2964 22.618 14.4354C19.9654 17.0485 17.3195 19.6682 14.6752 22.2897C13.7805 23.1775 13.7772 23.7403 14.6619 24.6163C17.3062 27.2394 19.9538 29.8558 22.6014 32.4739C22.7361 32.6063 22.8824 32.7285 23.1801 32.9982L23.1785 32.9999Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_2806_20">
        <rect width="20" height="19" fill="white" transform="matrix(-1 0 0 1 34 14)" />
      </clipPath>
    </defs>
  </svg>
  <svg
    v-else
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48px"
    height="48px"
  >
    <path
      fill="#64B5F6"
      d="M45,35c0,2.208-1.791,4-4,4H7c-2.209,0-4-1.792-4-4V13c0-2.21,1.791-4,4-4h34c2.209,0,4,1.79,4,4V35z"
    ></path>
    <path fill="#37474F" d="M3 14H45V20H3z"></path>
    <path fill="#E3F2FD" d="M5 22H43V27H5z"></path>
  </svg>
</template>

<script>
export default {
  props: {
    brand: { type: String, default: null },
    isLoading: { type: Boolean, default: false }
  }
};
</script>
