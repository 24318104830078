<template>
  <div class="relative">
    <SearchIcon class="pointer-events-none absolute left-4 top-[0.7rem] z-20 h-4 w-4" />
    <div
      class="overflow-hidden rounded-md border border-gray-300 bg-white px-10 shadow-sm focus-within:border-sky-500 focus-within:ring-1 focus-within:ring-sky-500"
      tabindex="-1"
      @click="focusInput"
    >
      <div ref="scrollingRef" class="scrollbar flex space-x-2 overflow-auto rounded-md">
        <div v-if="selected.length > 0" class="my-auto flex space-x-1">
          <div
            v-for="item in selected"
            :key="item.filterId + (isSelectedValueTag(item) ? item.tag.name : item.value)"
            ref="selectedRef"
          >
            <SimpleBadge
              :id="item.filterId"
              theme="gray-secondary"
              size="large"
              square
              deletable
              :icon="!isSelectedValueTag(item) && getIconByFilter(item.filterId)?.icon"
              :dot="isSelectedValueTag(item) ? true : false"
              :dot-color="isSelectedValueTag(item) ? item.tag.color : undefined"
              class="select-none font-semibold focus-visible:ring-2 focus-visible:ring-sky-400"
              :tooltip="
                (el) =>
                  handleTooltip({
                    id: item.filterId,
                    text: isSelectedValueTag(item) ? item.tag.name : item.value,
                  })
              "
              @delete="() => onDelete(item)"
            >
              <!-- <component :is="getIconByFilter(item.filterId)?.icon" v-if="getIconByFilter(item.filterId)?.icon" /> -->
              <span :id="item.filterId" class="max-w-[10rem] truncate">
                {{ isSelectedValueTag(item) ? item.tag.name : item.value }}</span
              >
            </SimpleBadge>
          </div>
        </div>
        <input
          ref="inputRef"
          :value="search"
          :placeholder="selected.length === 0 ? placeholder : undefined"
          class="w-full min-w-max max-w-full rounded-md py-2 focus:outline-none sm:text-sm"
          @focus="onInputFocused"
          @blur="onInputBlur"
          @input="onUpdateSearch"
        />
      </div>
    </div>
    <IconButton
      v-if="selected.length > 0"
      size="min"
      :label="t('buttonDelete')"
      class="absolute right-2 top-1.5 z-20"
      @click.stop="onDeleteAll"
    >
      <XCircleIcon class="h-5 w-5" />
    </IconButton>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="
    FilterIdType extends string,
    IdType extends string = string,
    TextType extends string = string,
    ListValueType extends string = string,
    ListKeyType extends string = string,
    ListDataType = Record<string, string>
  "
>
import { ref, onMounted } from "vue";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import IconButton from "@atoms/IconButton.vue";

// Icons
import { SearchIcon } from "@heroicons/vue/outline";
import { XCircleIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Type
import { Filter, FilterData } from "@domain/filters";
import type { SelectedValues, SelectedValue } from "../FilterInput.types";
import { isSelectedValueTag } from "../FilterInput.types";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    filterData: FilterData<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>;
    selected: SelectedValues<FilterIdType, IdType, ListValueType, ListDataType>;
    search: string;
    placeholder?: string;
  }>(),
  {
    placeholder: undefined,
  },
);

const emit = defineEmits<{
  (e: "update:search", value: string): void;
  (e: "delete", item: SelectedValue<FilterIdType, IdType, ListValueType, ListDataType>): void;
  (e: "deleteAll"): void;
  (e: "focus", event: FocusEvent): void;
  (e: "blur", event: FocusEvent): void;
}>();

const onUpdateSearch = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  emit("update:search", value);
};

const onDelete = (item: SelectedValue<FilterIdType, IdType, ListValueType, ListDataType>) => {
  emit("delete", item);
};

const onDeleteAll = () => {
  emit("deleteAll");
};

const onInputFocused = (event: FocusEvent) => {
  emit("focus", event);
};

const onInputBlur = (event: FocusEvent) => {
  emit("blur", event);
  scrollingRef.value?.scrollTo(0, 0);
};

const getIconByFilter = (
  filterId: string,
): Filter<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType> | undefined => {
  const filter = props.filterData.filters.find((f) => f.id === filterId);

  return filter;
};

// Focus
const inputRef = ref<HTMLInputElement>();
const focusInput = () => {
  if (!inputRef.value) return;
  const elementFocused = document.activeElement;

  if (elementFocused === inputRef.value) return;
  inputRef.value.focus();
};

// Tooltip Handlers
const selectedRef = ref<Array<HTMLElement>>();

const handleTooltip = ({ id, text }: { id: string; text: string }) => {
  const foundElement = selectedRef.value?.find(
    (tagElement) => (tagElement.firstChild as HTMLSpanElement)?.id.toString() === id.toString(),
  );

  if (!foundElement) return;

  const firstChildElement = (foundElement.firstChild as HTMLSpanElement).children.namedItem(id);

  if (!firstChildElement) return;

  return firstChildElement.scrollWidth > firstChildElement.clientWidth ? text : undefined;
};

// Accessibility
const scrollingRef = ref();

const setHorizontalScrollEvent = () => {
  scrollingRef.value.addEventListener(
    "wheel",
    (evt) => {
      scrollingRef.value.scrollLeft += evt.deltaY;
    },
    { passive: true },
  );
};

defineExpose({
  scrollingRef,
  focusInput,
});

onMounted(() => {
  setHorizontalScrollEvent();
});
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  height: 0;
}
</style>

<i18n lang="jsonc">
{
  "es": {
    "buttonDelete": "Limpiar búsqueda"
  },
  "pt": {
    "buttonDelete": "Limpar pesquisa"
  }
}
</i18n>
