<template>
  <ModalDialog :open="open" :show-close-button="false" @close="close">
    <div class="mx-auto max-w-3xl">
      <transition
        enter-active-class="duration-300 ease-out"
        leave-active-class="duration-300 ease-in"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-from-class="transform translate-x-0 opacity-100"
        :enter-from-class="'transform opacity-0 ' + (modalState !== 'initial' ? 'translate-x-20' : '-translate-x-20')"
        :leave-to-class="'transform opacity-0 ' + (modalState !== 'initial' ? '-translate-x-20' : 'translate-x-20')"
        mode="out-in"
      >
        <div v-if="modalState === 'initial'" class="px-8 py-6 text-center">
          <h2 class="m-0 text-base font-semibold leading-6 text-sky-400">{{ t("header.subtitle") }}</h2>
          <p class="mt-4 text-3xl font-bold leading-10 tracking-tight text-gray-900 sm:text-4xl">
            {{ t("header.title.initial") }}
          </p>
          <p class="mt-4 text-sm font-medium leading-5 text-gray-500">
            {{ t("header.description") }}
          </p>
          <div
            class="isolate mx-auto mt-8 grid max-w-md grid-cols-1 gap-8 text-left lg:mx-0 lg:max-w-none lg:grid-cols-2"
          >
            <div
              v-for="tier in tiers"
              :key="tier.id"
              :class="[(isAdmin || tier.id === 'current') && 'hover:ring-sky-500']"
              class="max-w-80 grid w-full content-between rounded-2xl px-12 py-10 ring-2 ring-gray-200"
            >
              <div>
                <h3 :id="tier.id" class="m-0 text-xl font-semibold leading-8 text-gray-900">
                  {{ tier.name }}
                </h3>
                <p class="mt-2 text-sm leading-6 text-gray-500">
                  {{ tier.description }}
                </p>
              </div>

              <div>
                <p class="mt-4 flex items-baseline gap-x-1">
                  <span class="text-4xl font-extrabold leading-10 tracking-tight text-gray-900"
                    >${{ sn(tier.price.value, "decimal") }}</span
                  ><span class="text-base font-semibold tracking-tight text-gray-500">/{{ tier.price.period }}</span>
                </p>
                <ul role="list" class="mt-4 space-y-2 text-sm font-normal leading-6 text-gray-600">
                  <li v-for="feature in tier.features" :key="feature.description" class="flex gap-x-2">
                    <component
                      :is="feature.icon"
                      :class="[
                        feature.icon === ExclamationIcon ? 'text-yellow-400' : 'text-sky-500',
                        'h-5 w-5 flex-none self-center',
                      ]"
                      aria-hidden="true"
                    >
                    </component>
                    {{ feature.description }}
                  </li>
                </ul>
                <SimpleButton
                  v-if="tier.id === 'current'"
                  theme="white"
                  class="mt-6 w-full"
                  @click="changeModalState(tier.id)"
                >
                  {{ tier.cta }}
                </SimpleButton>
                <SimpleButton
                  v-if="tier.id === 'recommended'"
                  class="mt-6 w-full"
                  :disabled="!isAdmin"
                  theme="primary"
                  @click="changeModalState(tier.id)"
                >
                  <template #leading>
                    <CreditCardIcon class="h-5 w-5 text-white" />
                  </template>
                  {{ tier.cta }}
                </SimpleButton>
              </div>
            </div>
          </div>
          <AlertBox theme="info" class="mt-6">
            <div class="flex">
              <span v-if="!isAdmin">
                {{ t("alertBox.notAdmin.text") }}
              </span>
              <span v-if="isAdmin">
                {{ t(`alertBox.${modalState}.text`) }}
                <a href="" class="font-semibold" @click.prevent="contactIntercom(modalState)">
                  <span class="mr-1 underline">{{ t("alertBox.cta") }}</span> →</a
                >
              </span>
            </div>
          </AlertBox>
        </div>
        <div
          v-else-if="recommendedPlanTier && (modalState === 'recommended' || modalState === 'current')"
          class="max-w-2xl text-left"
        >
          <div class="flex items-start">
            <div class="mt-[0.4rem] flex-shrink-0">
              <div class="flex h-6 w-6 items-center justify-center">
                <ExclamationOutlineIcon
                  v-if="modalState === 'current'"
                  class="h-6 w-6 text-yellow-400"
                  aria-hidden="true"
                />
                <CreditCardOutlineIcon
                  v-if="modalState === 'recommended'"
                  class="h-6 w-6 text-sky-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div class="w-full text-left sm:ml-4">
              <p class="mt-[0.5rem] text-lg font-medium leading-6 text-gray-900">
                {{ t(`header.title.${modalState}`) }}
              </p>
              <div class="mt-2">
                <div v-if="modalState === 'recommended'" class="mt-4 rounded-2xl p-6 ring-2 ring-gray-200">
                  <h3 :id="recommendedPlanTier.id" class="m-0 text-lg font-semibold leading-8 text-gray-900">
                    {{ recommendedPlanTier.name }}
                  </h3>
                  <p class="mt-2 flex items-baseline gap-x-1">
                    <span class="text-4xl font-extrabold tracking-tight text-gray-900"
                      >${{ sn(recommendedPlanTier.price.value, "decimal") }}</span
                    ><span class="text-base font-semibold tracking-tight text-gray-500"
                      >/{{ recommendedPlanTier.price.period }}</span
                    >
                  </p>
                  <p class="mt-2 text-sm font-normal leading-5 text-gray-500">
                    {{ recommendedPlanTier.why }}
                  </p>
                  <ul role="list" class="mt-2 space-y-2 text-sm leading-6 text-gray-600">
                    <li v-for="feature in recommendedPlanTier.features" :key="feature.description" class="flex gap-x-2">
                      <component
                        :is="feature.icon"
                        class="h-5 w-5 flex-none self-center text-sky-500"
                        aria-hidden="true"
                      >
                      </component>
                      {{ feature.description }}
                    </li>
                  </ul>
                </div>
                <div v-for="tier in tiers" :key="tier.id" class="mt-4">
                  <div v-if="tier.id === modalState">
                    <p class="text-sm font-semibold leading-5 text-gray-700">
                      {{ tier.keep?.title }}
                    </p>
                    <ul class="mt-2 list-disc space-y-2 pl-[1.3rem] text-sm leading-6 text-gray-600">
                      <li v-for="feature in tier.keep?.features" :key="feature.description" role="list" class="gap-x-2">
                        <span> {{ feature.description }} </span
                        ><a v-if="feature.link" @click.prevent="goToHelp(0)" class="text-sky-500"
                          ><span class="mr-1 underline"> {{ feature.link }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <AlertBox theme="info" class="mt-4">
                  <div class="flex">
                    <span class="mr-2">
                      {{ t(`alertBox.${modalState}.text`) }}
                    </span>
                    <a href="" class="font-semibold" @click.prevent="contactIntercom(modalState)">
                      <span class="mr-1 underline">{{ t("alertBox.cta") }}</span> →</a
                    >
                  </div>
                </AlertBox>
                <div
                  v-if="modalState === 'recommended' || modalState === 'current'"
                  class="relative mt-6 flex flex-col gap-4 sm:flex-row-reverse"
                >
                  <SimpleButton v-if="modalState === 'current'" @click="close">
                    <template #leading><CheckIcon /></template>
                    {{ t(`buttons.${modalState}`) }}
                  </SimpleButton>
                  <SimpleButton v-else @click="buyPlan">
                    <template #leading><CheckIcon /></template>
                    {{ t(`buttons.${modalState}`) }}
                  </SimpleButton>
                  <SimpleButton theme="white" @click="changeModalState('initial')">
                    <template #leading><ChevronLeftOutlineIcon class="text-gray-500" /></template>
                    {{ t("buttons.back") }}</SimpleButton
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

import type { ModalStateType, TierType } from "./planUpgradeModal.types";

// Components
import SimpleButton from "@atoms/SimpleButton.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import AlertBox from "@atoms/AlertBox.vue";
import { CheckIcon, ExclamationIcon, CreditCardIcon } from "@heroicons/vue/solid";
import {
  ChevronLeftIcon as ChevronLeftOutlineIcon,
  CreditCardIcon as CreditCardOutlineIcon,
  ExclamationIcon as ExclamationOutlineIcon,
} from "@heroicons/vue/outline";

// Store
import { useSessionStore } from "@store";

// Utils
import Intercom from "@helpers/intercom";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { usePlan } from "@api/modules/plan/plan";

// Domain
import type { PlanTier } from "@domain/plan";

const { n, t } = useI18n();
const sessionStore = useSessionStore();
const planService = usePlan();

withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

const isAdmin = computed(() => {
  // Todo: Cómo se define el administrador de una cuenta?
  console.log("session: ", sessionStore);
  return true;
});

// Emits
const emit = defineEmits<{
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

// Formats
const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

// States
const plans = ref<PlanTier[]>([]);
const modalState = ref<ModalStateType>("initial");
const changeModalState = (planId: ModalStateType) => {
  modalState.value = planId;
};

const currentPlanTier = computed<TierType | undefined>(() => {
  if (!sessionStore.plan || sessionStore.plan.subscription?.price.total === undefined) return undefined;

  const exceededContacts = sessionStore.plan?.contacts.active - sessionStore.plan?.contacts.limit;

  return {
    id: "current",
    name: t("plans.current.name"),
    description: t("plans.current.description", { count: n(exceededContacts) }),
    price: { value: sessionStore.plan.subscription?.price.total, period: t("plans.current.price.period") },
    features: [
      {
        icon: CheckIcon,
        description: t("plans.current.features.contacts", { count: n(sessionStore.plan.contacts.limit) }),
      },
      {
        icon: CheckIcon,
        description: t("plans.current.features.sends"),
      },
      {
        icon: ExclamationIcon,
        description: t("plans.current.features.delete", { count: n(exceededContacts) }),
      },
    ],
    keep: {
      title: t("plans.current.keep.title"),
      features: [
        {
          description: t("plans.current.keep.bullets.contacts"),
        },
        {
          description: t("plans.current.keep.bullets.emails"),
        },
        {
          description: t("plans.current.keep.bullets.doc"),
          link: t("plans.current.keep.bullets.helpCenter"),
        },
      ],
    },
    cta: t("plans.current.cta"),
  };
});

const recommendedPlan = computed<PlanTier | undefined>(() => {
  const contactsLimit = sessionStore.plan?.contacts.limit;
  if (contactsLimit === undefined || plans.value.length === 0) {
    return;
  }
  const recommendedPlan = plans.value?.find((plan) => plan.price > 0 && plan.contactsTo > contactsLimit);
  return recommendedPlan;
});

const recommendedPlanTier = computed<TierType | undefined>(() => {
  const contactsLimit = sessionStore.plan?.contacts.limit;
  if (contactsLimit === undefined || plans.value.length === 0) {
    return;
  }
  if (!recommendedPlan.value) return;

  const currentDiscount = sessionStore.plan?.subscription?.price.discount || 0;
  const newPlanPrice = (recommendedPlan.value.price * (100 - currentDiscount)) / 100;

  return {
    id: "recommended",
    name: t("plans.recommended.name"),
    description: t("plans.recommended.description"),
    why: t("plans.current.why"),
    price: { value: newPlanPrice, period: t("plans.recommended.price.period") },
    features: [
      {
        icon: CheckIcon,
        description: t("plans.recommended.features.contacts", { count: n(recommendedPlan.value.contactsTo) }),
      },
      {
        icon: CheckIcon,
        description: t("plans.recommended.features.sends"),
      },
      {
        icon: CheckIcon,
        description: t("plans.recommended.features.delete"),
      },
    ],
    keep: {
      title: t("plans.recommended.keep.title"),
      features: [
        {
          description: t("plans.recommended.keep.bullets.effective"),
        },
        {
          description: t("plans.recommended.keep.bullets.newPlan"),
        },
        {
          description: t("plans.recommended.keep.bullets.oldPlan"),
        },
      ],
    },
    cta: t("plans.recommended.cta"),
  };
});
const tiers = computed<TierType[]>(() => {
  if (recommendedPlanTier.value && currentPlanTier.value) return [currentPlanTier.value, recommendedPlanTier.value];

  return [];
});

const contactIntercom = (modalState: ModalStateType) => {
  Intercom.showNewMessage(t(`alertBox.${modalState}.intercomMessage`));
  close();
};

const goToHelp = (id: number) => {
  window.Intercom("showArticle", id);
};

const buyPlan = async () => {
  const payMethod = sessionStore.plan?.subscription?.payMethod;
  const contactsCount = recommendedPlan.value?.contactsTo;
  if (!contactsCount || !payMethod) return close();

  await planService.subscribePlan({
    contacts: contactsCount,
    payMethod: payMethod,
  });
  close();
};

onMounted(async () => {
  if (!["", "*"].includes(sessionStore.session.account.code)) {
    plans.value = await planService.getContactsPlans();
  }
});
</script>

<i18n lang="json">
{
  "es": {
    "header": {
      "title": {
        "initial": "Tenemos un nuevo plan ideal",
        "recommended": "Estas a punto de adquirir el plan especial. ¡Y seguir creciendo!",
        "current": "Elimina contactos y sigue enviando"
      },
      "subtitle": "Se ha excedido el límite de contactos de tu plan",
      "description": "Para continuar con tus envíos, puedes aumentar de plan o eliminar los contactos excedidos."
    },
    "plans": {
      "current": {
        "name": "Plan actual",
        "description": "Puedes continuar en este plan, pero primero deberás eliminar los contactos excedidos.",
        "why": "Pasate a este plan para aumentar el limite de contactos y continuar con tus envíos.",
        "price": {
          "period": "mes"
        },
        "features": {
          "contacts": "Hasta {count} contactos",
          "sends": "Envíos ilimitados",
          "delete": "Elimina {count} contactos"
        },
        "keep": {
          "title": "Continúa con tu plan actual y libera los envíos eliminando contactos:",
          "bullets": {
            "contacts": "Elimina 120 contactos excedidos desde la sección de listas.",
            "emails": "Los envíos se liberan automáticamente cuando eliminas los contactos excedidos.",
            "doc": "Para saber más puedes leer el siguiente artículo del ",
            "helpCenter": "centro de ayuda"
          }
        },
        "cta": "Mantener plan actual"
      },
      "recommended": {
        "name": "Plan recomendado",
        "description": "Selecciona este plan para aumentar el limite de contactos y continuar con tus envíos.",
        "price": {
          "period": "mes"
        },
        "features": {
          "contacts": "Hasta {count} contactos",
          "sends": "Envíos ilimitados",
          "delete": "Habilitación inmediata"
        },
        "keep": {
          "title": "Ten en cuenta lo siguiente:",
          "bullets": {
            "effective": "El plan se hará efectivo cuando hagas click en ‘’Comprar plan’’.",
            "newPlan": "El saldo del nuevo plan se cobrará de la tarjeta asociada a tu cuenta de Perfit.",
            "oldPlan": "Podrás volver a tu plan anterior cuando lo desees."
          }
        },
        "cta": "Aumentar plan"
      }
    },
    "alertBox": {
      "initial": {
        "text": "Si necesitas ayuda para elegir un nuevo plane, habla con un especialista.",
        "intercomMessage": "Hola! Quisiera saber más sobre la elección de mi nuevo plan para poder seguir usando Perfit."
      },
      "current": {
        "text": "Si precisas ayuda para limpiar tus listas habla con un especialista.",
        "intercomMessage": "Hola! Necesito ayuda para limpiar mis lista y así poder seguir usando Perfit."
      },
      "recommended": {
        "text": "Si necesitas ayuda para elegir un nuevo plane, habla con un especialista.",
        "intercomMessage": "Hola! Quisiera saber más sobre la elección de mi nuevo plan para poder seguir usando Perfit."
      },
      "notAdmin": {
        "text": "No tienes permisos para hacer el cambio de plan. Contacta al administrador."
      },
      "cta": "Quiero hablar"
    },
    "buttons": {
      "back": "Volver",
      "current": "Listo",
      "recommended": "Comprar ahora"
    }
  },
  "pt": {
    "header": {
      "title": {
        "initial": "Temos um novo plano ideal para você",
        "recommended": "Você está prestes a adquirir o plano especial e continuar crescendo!",
        "current": "Exclua contatos e continue enviando"
      },
      "subtitle": "O seu negócio está crescendo e o seu plano atual ficou pequeno?",
      "description": "Seu negócio cresceu muito e você ultrapassou o número de contatos, para continuar com seus envios você pode aumentar o plano contratado ou eliminar alguns de suas listas."
    },
    "plans": {
      "current": {
        "name": "Plano atual",
        "description": "Elimine 120 contactos para continuar com os seus envios mantendo este plano.",
        "why": "Mude para este plano para aumentar o limite de contactos e continuar com os seus envios.",
        "price": {
          "period": "mês"
        },
        "features": {
          "contacts": "{count} contatos",
          "sends": "Envios ilimitados",
          "delete": "Você terá que excluir contatos"
        },
        "keep": {
          "title": "Continue com seu plano atual e libere envios excluindo contatos:",
          "bullets": {
            "contacts": "Exclua 120 contatos excedidos da seção de listas.",
            "emails": "Os envios são liberados automaticamente quando você remove os contatos excedidos.",
            "doc": "Para saber mais, você pode ler o seguinte artigo do nosso ",
            "helpCenter": "centro de ajuda"
          }
        },
        "cta": "Manter o plano"
      },
      "recommended": {
        "name": "Plano especial",
        "description": "Selecione este plano para aumentar o limite de contactos e continuar com os seus envios.",
        "price": {
          "period": "mês"
        },
        "features": {
          "contacts": "{count} contatos",
          "sends": "Envios ilimitados",
          "delete": "Habilitação instantânea"
        },
        "keep": {
          "title": "Leve em consideração o seguinte:",
          "bullets": {
            "effective": "O plano entrará em vigor quando você clicar em ‘’Comprar plano’’.",
            "newPlan": "O saldo do novo plano será debitado no cartão associado à sua conta Perfit.",
            "oldPlan": "Você pode retornar ao plano anterior quando quiser."
          }
        },
        "cta": "Aumentar o plano"
      }
    },
    "alertBox": {
      "initial": {
        "text": "Se precisar de ajuda com planos, fale com um especialista.",
        "intercomMessage": "Olá! Gostaria de saber mais sobre como escolher meu novo plano para poder continuar usando a Perfit."
      },
      "current": {
        "text": "Se precisar de ajuda para limpar suas listas, fale com um especialista.",
        "intercomMessage": "Olá! Preciso de ajuda para limpar minha lista e poder continuar usando a Perfit."
      },
      "recommended": {
        "text": "Se precisar de ajuda com planos, fale com um especialista.",
        "intercomMessage": "Olá! Gostaria de saber mais sobre como escolher meu novo plano para poder continuar usando a Perfit."
      },
      "notAdmin": {
        "text": "Você não tem permissão para fazer a alteração do plano. Entre em contato com o administrador."
      },
      "cta": "Quero falar"
    },
    "buttons": {
      "back": "Voltar",
      "current": "Pronto",
      "recommended": "Comprar agora"
    }
  }
}
</i18n>
