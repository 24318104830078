var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='automations section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'><i class='icon icon-back'></i></a>\n      <span class='edit-inline' data-action=\"edit-inline\" data-field=\"name\" style=\"display: inline;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n      <input autocomplete=\"off\" maxlength=\"80\" class='edit-inline' data-action=\"edit-inline\" name=\"name\"\n        value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" style=\"display: none;\" required />\n      <small class='lift'>\n        <span class='edit-inline "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":69}}})) != null ? stack1 : "")
    + "' data-action=\"edit-inline\" data-field=\"comments\"\n          style=\"display: inline;\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":12,"column":93}}})) != null ? stack1 : "")
    + "</span>\n        <input autocomplete=\"off\" maxlength=\"200\" class='edit-inline' data-action=\"edit-inline\" name=\"comments\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" style=\"display: none;\"\n          placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0))
    + "\" />\n      </small>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"lifecycles.rfm",{"name":"is","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"triggerDetails") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"lifecycles.rfm",{"name":"isNot","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":44,"column":14}}})) != null ? stack1 : "")
    + "\n    <div class=\"btn-group\">\n      <button type='button' class='btn btn-default' data-action='cancel' data-require-permission='campaigns:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='duplicate' data-require-permission='campaigns:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-danger' data-action='delete' data-class='btn-danger'\n        data-params='{\"id\": \""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"}' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n        data-require-permission='campaigns:delete'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n      </button>\n    </div>\n\n  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <ul id='automation-tabs' class='nav nav-pills'>\n        <li>\n          <a data-navigate='automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"rules")), depth0))
    + "\n          </a>\n        </li>\n        <li class='active'>\n          <a>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n          </a>\n        </li>\n        <li>\n          <a data-navigate='automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/activity?filter.type=triggered&filter.created.gtrel=now-1M'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activity")), depth0))
    + "\n          </a>\n        </li>\n      </ul>\n      <div class='panel panel-default'>\n        <div class='panel-body panel-body-padded'>\n          <div class='tab-content'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"multiContent")),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":12},"end":{"line":107,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"template"))) && lookupProperty(stack1,"attributes")),{"name":"with","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":12},"end":{"line":190,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "empty";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-disabled' data-action=\"cantActivateLifeCycle\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n      </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":37,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":6},"end":{"line":43,"column":17}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='disable'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"disable")), depth0))
    + "\n      </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='enable'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"enableRestricted")),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":90}}})) != null ? stack1 : "")
    + "\n      </button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-lock\" aria-hidden=\"true\"></i>";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class='form-group'>\n              <label>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"editingContent")), depth0))
    + "\n              </label>\n              <select name=\"selected-template\" class=\"form-control\" data-action=\"switch-template\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"automationTemplates")),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":103,"column":25}}})) != null ? stack1 : "")
    + "              </select>\n            </div>\n            <hr />\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attributes") : depth0),{"name":"with","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":16},"end":{"line":102,"column":25}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"id") : depth0),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"template"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"id")),{"name":"is","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":39},"end":{"line":98,"column":106}}})) != null ? stack1 : "")
    + ">\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"content")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/inc.js")).call(alias3,(data && lookupProperty(data,"index")),{"name":"inc","hash":{},"data":data,"loc":{"start":{"line":99,"column":53},"end":{"line":99,"column":67}}}))
    + " - "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subject") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":99,"column":70},"end":{"line":100,"column":89}}})) != null ? stack1 : "")
    + "\n                </option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return " selected='selected' ";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subject") : depth0), depth0));
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"noSubject")), depth0));
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <form class='save' data-form='save-contents'>\n              <div class='tab-pane active' id='content'>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"details"))) && lookupProperty(stack1,"account"))) && lookupProperty(stack1,"features"))) && lookupProperty(stack1,"SENDERSV2")),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":113,"column":16},"end":{"line":154,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class='subject-group form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n                  </label>\n                  <div id=\"vue-subject\"></div>\n                  <p class=\"input-legend\">"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"subjectLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n                <input id=\"preheader\" name=\"preheader\" type=\"text\" style=\"display: none\" />\n\n                <div>\n                  <button type='button' class='btn btn-primary' data-action='save'>\n                    <i class='fa fa-check'></i>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"save")), depth0))
    + "\n                  </button>\n\n                  <button type='button' class='btn btn-primary "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":63},"end":{"line":171,"column":98}}})) != null ? stack1 : "")
    + "'\n                    data-action='open-send-test-modal' data-require-permission='campaigns:update'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"testRecipients")), depth0))
    + "\n                  </button>\n                </div>\n                <div class='preview-wrapper'></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":16},"end":{"line":187,"column":23}}})) != null ? stack1 : "")
    + "              </div>\n            </form>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "                  <div id=\"vue-sender-selector\" class=\"form-group\"/>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div data-role='from-selector'>\n                  <div class='row'>\n                    <div class='col-md-6'>\n                      <div class='form-group'>\n                        <label>\n                          "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"from")), depth0))
    + "\n                        </label>\n                        <select name=\"from\" class=\"form-control\" data-target='[name=fromName]'>\n                          <option value=''>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"select")), depth0))
    + "\n                          </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"froms")),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":26},"end":{"line":132,"column":35}}})) != null ? stack1 : "")
    + "                          <option value='new' class='bold'>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"add")), depth0))
    + "\n                          </option>\n                        </select>\n                        <p class='input-legend' data-role='from-status'>\n                          "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"legend")), depth0)) != null ? stack1 : "")
    + "\n                        </p>\n                      </div>\n                    </div>\n                    <div class='col-md-6'>\n                      <div class='form-group'>\n                        <label>\n                          "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fromName")), depth0))
    + "\n                        </label>\n                        <input class='form-control' name='fromName' type='text' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":102},"end":{"line":148,"column":65}}})) != null ? stack1 : "")
    + " placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"namePlaceholder")), depth0))
    + "'>\n                        <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"froms"))) && lookupProperty(stack1,"nameLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                      </div>\n                    </div>\n                  </div>\n                </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"email") : depth0),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"template"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"from"))) && lookupProperty(stack1,"email")),{"name":"is","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":52},"end":{"line":129,"column":55}}})) != null ? stack1 : "")
    + " data-name='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "' data-status='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "'>\n                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n                          </option>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "\n                            selected='selected' ";
},"32":function(container,depth0,helpers,partials,data) {
    return "\n                          data-original-value='from.name' ";
},"34":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='form-group'>\n                  <label>\n                    URL de sustitución para previsualización [sólo master users]\n                  </label>\n                  <input class='form-control' name='substitution_data_url' type='text' value='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"substitution_data_url") : depth0), depth0))
    + "'\n                    placeholder='http://...'>\n                  <p class=\"input-legend\">Esta es la url desde donde se obtienen los datos para\n                    sustituir en la previzualización y envío de prueba</p>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":197,"column":9}}})) != null ? stack1 : "");
},"useData":true});