import { Model } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";
const moment = window.moment;

export default Model.extend({
  urlRoot: "drips",
  parse: function (drip) {
    // Get data from API direct call
    if (_.isDefined(drip.data)) {
      drip = drip.data;
    }

    // Remove extra drip from include and exclude
    ["include", "exclude"].forEach(function (type) {
      ["detailedLists", "detailedInterests"].forEach(function (key) {
        if (objHas(drip, type, key)) {
          var newKey = key.replace("detailed", "").toLowerCase();
          drip[type][newKey] = $.extend({}, drip[type][key]);
          delete drip[type][key];
        }
      });
    });
    if (objHas(drip, "exclude", "lists") && !_.size(drip.exclude.lists)) {
      drip.exclude.lists = false;
    }

    // Check if the drip can be archived
    if (_.contains(["SENT", "PAUSED"], drip.state)) {
      drip.canBeArchived = true;
    }

    // Set custom reply
    drip.customReply = false;
    if (objHas(drip, "from", "from") && objHas(drip, "from", "replyTo")) {
      if (drip.from.from !== drip.from.replyTo) {
        drip.customReply = true;
      }
    }

    // Return parsed
    return drip;
  },
  additionals: {
    froms: function () {
      return $.get("froms", { limit: 100 });
    },
    fields: function () {
      return $.get("fields", { limit: 100 });
    },
  },
  relationships: {
    tags: function () {
      return $.get("drips/tags", { limit: 100 });
    },
  },

  // Gets drip preview
  preview: function () {
    return $.ajax({
      url: "drips/" + this.id + "/preview",
      type: "POST",
      contentType: "application/json",
    });
  },

  // Sends a test
  sendTest: function (data) {
    return $.ajax({
      url: "drips/" + this.id + "/sendtest",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },

  // Pauses drip
  pause: function () {
    return $.post("drips/" + this.id + "/pause");
  },

  // Resumes drip
  resume: function () {
    return $.post("drips/" + this.id + "/resume");
  },

  // Launches drip
  launch: function () {
    return $.post("drips/" + this.id + "/launch");
  },

  // Duplicates drip
  duplicate: function () {
    return $.post("drips/" + this.id + "/duplicate");
  },

  // Archives drip
  archive: function () {
    return $.post("drips/" + this.id + "/archive");
  },

  // Unarchives drip
  unarchive: function () {
    return $.post("drips/" + this.id + "/unarchive");
  },

  // Resumes drip
  saveDraft: function (data) {
    return $.ajax({
      url: "drips/" + this.id,
      type: "PUT",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },

  // Resumes drip
  rescheduleNode: function (nodeId, data) {
    return $.ajax({
      url: "drips/" + this.id + "/nodes/" + nodeId + "/changedate",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },

  // Add title and description for each node
  parseNodes: function (drip) {
    var lang = window.lang.drips,
      lastDate,
      firstDate;

    drip = drip || this;

    if (
      _.isUndefined(drip.attributes) ||
      _.isUndefined(drip.attributes.nodes)
    ) {
      return false;
    }
    (lastDate = parseInt(
      moment(drip.attributes.nodes[0].launchDate).format("x")
    )),
      (firstDate = lastDate);
    drip.attributes.nodes.forEach(function (node, index) {
      var description = "",
        descriptions = [],
        date = moment(node.launchDate).tz(window.config.timezone),
        formattedDate = date.format(window.lang.drips.shortDate),
        dayOfTheWeek = parseInt(date.format("d"), 10),
        timestamp = parseInt(moment(node.launchDate).format("x")),
        phrase =
          node.state === "SENT"
            ? window.lang.drips.wasSent
            : window.lang.drips.willSend;
      node.title = lang.nodeTitles[index];
      if (!index) {
        description = lang.firstNodeDescription;
      } else {
        node.relation.forEach(function (relation) {
          descriptions.push(
            [
              lang.nodeActions[relation.action],
              lang.nodeTitles[relation.id - 1].toLowerCase(),
            ].join(" ")
          );
        });
        description =
          lang.nodeRecipients + " " + descriptions.join(lang.nodeOr);
      }
      node.description = phrase + " " + description;
      node.descriptionWithDate =
        phrase +
        " " +
        window.lang.drips.the +
        " " +
        formattedDate +
        " " +
        description;
      node.canBeRescheduled =
        _.contains(["PAUSED", "SCHEDULED"], node.state) &&
        moment(node.launchDate).diff(Date.now()) > 3600000;
      node.isWeekend = dayOfTheWeek === 0 || dayOfTheWeek === 6;
      if (node.isWeekend) {
        node.descriptionWithDate +=
          ". <i class='fa fa-exclamation-triangle text-warning'></i> " +
          window.lang.drips.weekend;
      }
      node.timeDifference = {
        previousNode: timestamp - lastDate,
        firstNode: timestamp - firstDate,
      };
      lastDate = timestamp;
    });
    return drip.attributes;
  },
});
