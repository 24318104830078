<template>
  <span
    :id="id"
    ref="spanRef"
    :tabindex="focusable ? '0' : '-1'"
    class="inline-flex items-center gap-1 focus:outline-none"
    :class="[
      badgeClass,
      textColorClass,
      (tooltip || clickable) && hoverBgColorClass,
      clickable && 'cursor-pointer',
      square ? 'rounded' : 'rounded-full',
    ]"
    @click="onClick"
  >
    <div v-if="dot || icon" class="w-3" :class="{ '-ml-0.5': modelValue }">
      <svg
        v-if="dot"
        :class="[!dotColor && dotColorClass]"
        :style="{
          color: `${dotColor}`,
        }"
        class="mt-0.5 h-2 w-2"
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx="4" cy="4" r="3" />
      </svg>
      <component :is="icon" :class="[dotColorClass, modelValue ? '-ml-0.5' : '']" class="h-3 w-3" aria-hidden="true" />
    </div>
    <slot name="icon" />
    <slot> {{ modelValue }} </slot>
    <button
      v-if="deletable"
      tabindex="-1"
      class="inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none"
      :class="[crossStyleClass, crossClasss]"
      @click.stop="onDelete"
    >
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
    </button>
  </span>
</template>

<script setup lang="ts">
import { computed, ref, toRef } from "vue";

//Utils
import { useTooltip } from "@composables/tooltips";

//Types
import type { Component, RenderFunction } from "vue";
import type { Placement } from "@popperjs/core";
import type { Theme, Size } from "./simpleBadge.types";

export interface Props {
  id?: string;
  modelValue?: string;
  theme?: Theme;
  size?: Size;
  square?: boolean;
  deletable?: boolean;
  clickable?: boolean;
  dot?: boolean;
  dotColor?: HexColor;
  icon?: RenderFunction | Component;
  tooltip?: string | ((el: HTMLDivElement) => string);
  tooltipPlacement?: Placement;
  focusable?: boolean;
}

// Props
const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  modelValue: "",
  theme: "sky",
  size: "small",
  square: false,
  dot: false,
  dotColor: undefined,
  deletable: false,
  clickable: false,
  icon: undefined,
  tooltip: "",
  tooltipPlacement: "bottom",
  focusable: false,
});

// Emits
const emit = defineEmits<{
  (e: "delete"): void;
  (e: "click"): void;
}>();

const onDelete = () => {
  emit("delete");
};

const onClick = () => {
  if (!props.clickable) return;
  emit("click");
};

const spanRef = ref();
const contentToRef = toRef(props, "tooltip");
contentToRef.value && useTooltip(spanRef, contentToRef, { placement: props.tooltipPlacement });

// Styles
const paddings: Record<Size, string> = {
  small: `px-2.5 py-0.5 text-xs`,
  large: `px-3 py-0.5 text-sm`,
  xlarge: `px-3 py-1 text-sm`,
};
const deletableClasses: Record<Size, string> = {
  small: "pr-0.5",
  large: "pr-1",
  xlarge: "pr-1",
};

const themes: Record<Theme, string> = {
  green: "bg-green-100",
  "light-green": "bg-green-50",
  yellow: "bg-yellow-100",
  blue: "bg-blue-100",
  red: "bg-red-100",
  sky: "bg-sky-100",
  "sky-solid": "bg-sky-400",
  "light-sky": "bg-sky-100",
  gray: "bg-gray-100",
  "gray-secondary": "bg-[#f5f5f5]",
  white: "bg-white border focus:border-gray-400 focus:text-gray-600 hover:border-gray-400 hover:text-gray-600",
  "black-alter": "border border-[#737373]",
};

const defaultClasses = "inline-flex items-center font-medium";

const textColors: Record<Theme, string> = {
  green: "text-green-700",
  "light-green": "text-green-700",
  yellow: "text-yellow-700",
  blue: "text-blue-800",
  red: "text-red-700",
  sky: "text-sky-600",
  "sky-solid": "text-white",
  "light-sky": "text-sky-400",
  gray: "text-gray-500",
  "gray-secondary": "text-gray-700",
  white: "text-gray-500",
  "black-alter": "text-white",
};

const bgColors: Record<Theme, string> = {
  green: "hover:bg-green-200",
  "light-green": "hover:bg-green-100",
  yellow: "hover:bg-yellow-200",
  blue: "hover:bg-blue-200",
  red: "hover:bg-red-200",
  sky: "hover:bg-sky-200",
  "sky-solid": "hover:bg-sky-500",
  "light-sky": "hover:bg-sky-200",
  gray: "hover:bg-gray-200",
  "gray-secondary": "hover:bg-gray-200",
  white: "hover:bg-gray-100",
  "black-alter": "hover:border-[#a3a3a3]",
};

const dotColors: Record<Theme, string> = {
  green: "text-green-400",
  "light-green": "text-green-400",
  yellow: "text-yellow-400",
  blue: "text-blue-400",
  red: "text-red-400",
  sky: "text-sky-400",
  "sky-solid": "text-white",
  "light-sky": "text-sky-400",
  gray: "text-gray-400",
  "gray-secondary": "text-gray-500",
  white: "text-gray-400",
  "black-alter": "text-white",
};

const crossStyles: Record<Theme, string> = {
  green: "text-green-400 hover:bg-green-200",
  "light-green": "text-green-400 hover:bg-green-200",
  yellow: "text-yellow-400 hover:bg-yellow-200",
  blue: "text-blue-400 hover:bg-blue-200",
  red: "text-red-400 hover:bg-red-200",
  sky: "text-sky-400 hover:bg-sky-200",
  "sky-solid": "text-white hover:bg-sky-400",
  "light-sky": "text-sky-400 hover:bg-sky-200",
  gray: "text-gray-400 hover:bg-gray-200",
  "gray-secondary": "text-gray-400 hover:bg-gray-200",
  white: "text-gray-400 hover:bg-gray-100",
  "black-alter": "text-white",
};

const crossClasses: Record<Size, string> = {
  small: "",
  large: "",
  xlarge: "mt-0.5",
};

const badgeClass = computed(() => {
  const classes: Array<string> = [defaultClasses];

  classes.push(paddings[props.size]);

  props.deletable && classes.push(deletableClasses[props.size]);

  classes.push(themes[props.theme]);

  return classes.join(" ");
});

const textColorClass = computed(() => textColors[props.theme]);

const hoverBgColorClass = computed(() => bgColors[props.theme]);

const dotColorClass = computed(() => dotColors[props.theme]);

const crossStyleClass = computed(() => crossStyles[props.theme]);

const crossClasss = computed(() => crossClasses[props.size]);

defineExpose({ badgeRef: spanRef });
</script>
