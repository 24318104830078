<template>
  <div class="relative rounded-md shadow-sm">
    <div class="absolute inset-y-0 left-0 flex items-center">
      <label for="country" class="sr-only">{{ optionsLabel }}</label>
      <select id="country" name="country" :class="selectClass" @change="emitUpdateOption">
        <option v-for="opt in options" :key="opt.id" :value="opt.id" :selected="opt.id === option">
          {{ opt.name }}
        </option>
      </select>
    </div>
    <input
      ref="inputRef"
      type="text"
      v-bind="$attrs"
      :class="inputClass"
      :value="value"
      :aria-invalid="hasError"
      @input="emitUpdateValue"
    />
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-5"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-5"
    >
      <div v-if="loading" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
      </div>
      <div v-else-if="hasError" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, nextTick } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

export default defineComponent({
  name: "InputWithDropdown",
  components: { ExclamationCircleIcon, LoadingSpinner },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
    valueModifiers: {
      type: Object,
      default: () => ({}),
    },
    option: {
      type: String,
      default: "",
    },
    options: {
      type: Array as PropType<{ id: string; name: string }[]>,
      default: () => [],
    },
    optionsLabel: {
      type: String,
      default: "",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:value", "update:option"],

  setup(props, { emit }) {
    const inputRef = ref<InstanceType<typeof HTMLInputElement>>();

    if (props.option === "" && props.options.length > 0) {
      emit("update:option", props.options[0].id);
    }

    const emitUpdateValue = (e) => {
      let value = e.target.value;
      let updateCursor = false;
      const cursorPos = inputRef.value?.selectionStart;

      if (props.valueModifiers.numbersOnly && value.match(/[^\d]/)) {
        value = value.replaceAll(/[^\d]/g, "");
        inputRef.value && (inputRef.value.value = value);
        updateCursor = true;
      }

      emit("update:value", value);

      if (updateCursor && cursorPos !== undefined) {
        // Esto es para mantener la posicion del cursor y no se vaya al final
        nextTick().then(() => {
          inputRef.value?.setSelectionRange(cursorPos, cursorPos);
        });
      }
    };

    const focus = () => {
      inputRef.value?.focus();
    };

    return { inputRef, emitUpdateValue, focus };
  },

  computed: {
    inputClass() {
      const classes: string[] = [];
      const { hasError } = this;
      classes.push("block w-full pl-24 focus:outline-none sm:text-sm rounded-md");

      if (hasError) {
        classes.push("pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ");
      } else {
        classes.push("border-gray-300 focus:ring-sky-500 focus:border-sky-500");
      }

      return classes.join(" ");
    },
    selectClass() {
      const classes: string[] = [];
      const { hasError } = this;
      classes.push("h-full py-0 pl-3 pr-7 border-transparent bg-transparent sm:text-sm rounded-md");

      if (hasError) {
        classes.push("text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ");
      } else {
        classes.push("text-gray-500 focus:ring-sky-500 focus:border-sky-500");
      }

      return classes.join(" ");
    },
  },
  methods: {
    // emitUpdateValue(e) {
    //   let value = e.target.value;
    //   let updateCursor = false;
    //   const cursorPos = this.$refs.inputRef.value?.selectionStart;

    //   if (this.modelModifiers.numbersOnly && value !== value.toLowerCase()) {
    //     value = value.toLowerCase();
    //     updateCursor = true;
    //   }

    //   this.$emit("update:modelValue", e.target.value);
    // },
    emitUpdateOption(e) {
      this.$emit("update:option", e.target.value);
    },
  },
});
</script>
