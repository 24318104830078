import { Collection } from "@/core/magnet";

// Magnet Model
const Model = window.Backbone.Model.extend({
  // Destroy override
  destroy: function (options) {
    var params = $.extend(true, {}, { type: "DELETE", url: this.url() }, options);

    return $.ajax(params).done(() => {
      return $.ajax({
        type: "DELETE",
        url: "apiv3://optinrules?filters.name=" + this.attributes.pubId,
        dataType: "json",
        contentType: "application/json",
      });
    });
  },
});

export default Collection.extend({
  url: "optins",
  model: Model,
  parse: function (response) {
    return response.data;
  },
});
