// Icons
import { DownloadIcon, UserGroupIcon, UserRemoveIcon, HeartIcon, TrashIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore } from "@store";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { Columns, Column } from "@molecules/Table";
import type { ColumnsIds, ContactRows, ContactRow } from "./audienceContacts.types";
import type { Contacts } from "@domain/contacts";
import type { Items } from "@molecules/CheckboxListButton";
import type { ListColumns, ListColumn } from "@domain/lists";

export const getMenuOptions = (): Categories => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const canExport = sessionStore.hasPermission("contacts:export");

  return {
    user: [
      ...(canExport
        ? [
            {
              key: "export",
              value: t("menuOptions.export"),
              icon: DownloadIcon,
            },
          ]
        : []),
      {
        key: "subscribe",
        value: t("menuOptions.subscribe"),
        icon: UserGroupIcon,
      },
    ],
  };
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "addList",
        value: t("listOptions.addList"),
        icon: UserGroupIcon,
      },
      {
        key: "addInterest",
        value: t("listOptions.addInterest"),
        icon: HeartIcon,
      },
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const getListOptionsListSelected = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "addList",
        value: t("listOptions.addList"),
        icon: UserGroupIcon,
      },
      {
        key: "addInterest",
        value: t("listOptions.addInterest"),
        icon: HeartIcon,
      },
      {
        key: "removeFromList",
        value: t("listOptions.removeFromList"),
        icon: UserRemoveIcon,
      },
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const columnsToList = (columns: ListColumns): Items<string, string, ListColumn> => {
  return columns.map((col) => ({
    key: col.prop ? col.prop : col.tag,
    value: col.name,
    data: col,
  }));
};

export const filterColumns = (columns: ListColumns) => {
  const filteredColumns = columns.filter((col) => {
    const keys: Array<ColumnsIds> = ["email", "state", "created", "lastModified"];

    return !keys.includes(col.prop as ColumnsIds);
  });
  return filteredColumns;
};

export const getColumns = (
  columns: Items<string, string, ListColumn>,
  maxCols: number,
): Columns<ColumnsIds | string | "options"> => {
  const { t } = useI18n();

  const columnsBreakPoints: Record<string, "lg" | "md"> = {
    [maxCols]: "lg",
    [maxCols - 1]: "md",
  };

  const cols: Columns<ColumnsIds | string | "options"> = [
    {
      id: "email",
      text: t("columns.email"),
      sortModes: ["ASC", "DESC"],
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "created",
      sortKey: "created",
      text: t("columns.created"),
      sortModes: ["DESC", "ASC"],
      headerTextAlign: "left",
      textAlign: "left",
    },
    ...columns
      .map<Column>((col, index) => ({
        id: col.data?.custom ? col.data?.id?.toString() : col.key,
        text: col.value,
        breakpoint: columnsBreakPoints?.[columns.length] ?? "sm",
        headerTextAlign: "left",
        textAlign: "left",
      }))
      .slice(0, maxCols),

    {
      id: "options",
      headerWidthRem: 2,
    },
  ];

  return cols;
};

export const contactsToRows = (contacts: Contacts): ContactRows => {
  return contacts.map<ContactRow>((contact) => ({
    id: contact.id.toString(),
    content: {
      email: contact.email,
      lastModified: contact.lastModified, // ?
      firstName: contact.firstName,
      lastName: contact.lastName,
      gender: contact.gender,
      language: contact.language,
      preferredFormat: contact.preferredFormat,
      source: contact.source,
      quality: contact.quality,
      lastMailed: contact.lastMailed,
      lastAction: contact.lastAction,
      created: contact.created,
      inactivated: contact.inactivated,
      state: contact.state,
      options: "",
      ...contact.customFields.reduce((fields, item) => {
        fields[item.id] = item.value;
        return fields;
      }, {}),
    },
    data: contact,
  }));
};
