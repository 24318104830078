// I18n
import { useI18n } from "vue-i18n";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Types
import type { Reasons } from "./planCancellation.types";

export const getReasons = (): Reasons => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  const isMasterUser = session.value?.isMasterUser;

  let reasons: Reasons = [
    // {
    //   key: "empty",
    //   value: "‎", // El componente selector necesita un caracter para no colapsar (height)
    //   data: {
    //     validOption: false,
    //     showComment: false,
    //     showContactSupport: false,
    //     showWarning: false,
    //   },
    // },
    {
      key: "temporary",
      value: t("reasons.temporary.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "too_expensive",
      value: t("reasons.too_expensive.label"),
      data: {
        validOption: true,
        showComment: false,
        showContactSupport: true,
        showWarning: false,
      },
    },
    {
      key: "bad_results",
      value: t("reasons.bad_results.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: true,
        showWarning: false,
      },
    },
    {
      key: "missing_features",
      value: t("reasons.missing_features.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "too_busy",
      value: t("reasons.too_busy.label"),
      data: {
        validOption: true,
        showComment: false,
        showContactSupport: true,
        showWarning: false,
      },
    },
    {
      key: "business_close",
      value: t("reasons.business_close.label"),
      data: {
        validOption: true,
        showComment: false,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "complexity",
      value: t("reasons.complexity.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: true,
        showWarning: false,
      },
    },
    {
      key: "duplicate_accounts",
      value: t("reasons.duplicate_accounts.label"),
      data: {
        validOption: true,
        showComment: false,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "missing_integrations",
      value: t("reasons.missing_integrations.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "bad_experience",
      value: t("reasons.bad_experience.label"),
      data: {
        validOption: true,
        showComment: true,
        showContactSupport: false,
        showWarning: false,
      },
    },
    {
      key: "plan_change",
      value: t("reasons.plan_change.label"),
      data: {
        validOption: true,
        showComment: false,
        showContactSupport: true,
        showWarning: true,
      },
    },
  ];

  // Random function
  const mathRandom = () => Math.random() - 0.5;

  // Ranom reasons
  reasons = reasons.sort(mathRandom);


  if (isMasterUser) {
    reasons = [
      {
        key: "defaulter",
        value: t("reasons.defaulter.label"),
        data: {
          validOption: true,
          showComment: true,
          showContactSupport: false,
          showWarning: false,
        },
      },
      {
        key: "audited",
        value: t("reasons.audited.label"),
        data: {
          validOption: true,
          showComment: true,
          showContactSupport: false,
          showWarning: false,
        },
      },
      // {
      //   key: "other",
      //   value: t("reasons.other.label"),
      //   data: {
      //     validOption: true,
      //     showComment: true,
      //     showContactSupport: false,
      //     showWarning: false,
      //   },
      // },
      ...reasons,
    ];
    
  }
  return reasons;
};
