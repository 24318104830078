var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n    <form data-form='delete-list' data-feedback='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"feedback"))) && lookupProperty(stack1,"duplicated")), depth0))
    + "'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    <span class=\"warning\">"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "</span>\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <p>\n                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"deleteLegend")), depth0)) != null ? stack1 : "")
    + "\n                    </p>\n                    <div class='form-group'>\n                        <div class='graphic radio'>\n                            <input type='radio' name='deleteContacts' value='' id=\"radio-delete-list-only\">\n                            <label for='radio-delete-list-only'>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"deleteListOnly")), depth0))
    + "\n                            </label>\n                        </div>\n                        <div class='graphic radio'>\n                            <input type='radio' name='deleteContacts' value='1' id=\"radio-delete-list-and-contacts\">\n                            <label for='radio-delete-list-and-contacts'>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"deleteListAndContacts")), depth0))
    + "\n                            </label>\n                            </p>\n                        </div>\n                    </div>\n                    <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"deleteListHelp")), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-danger' id=\"button-delete-list\" disabled>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});