<template>
  <ModalDialog :open="isOpen" :title="t('header.title')" @close="close">
    <div class="w-[36rem] space-y-6">
      <ToggleButtons
        :model-value="suggestionMode"
        :buttons="[
          { id: 'email', label: t('toggleButtons.email'), icon: TemplateIcon, showLabel: true },
          { id: 'text', label: t('toggleButtons.text'), icon: PencilIcon, showLabel: true },
        ]"
        full
        @update:modelValue="toggleSuggestionMode"
      />
      <TextParagraph>
        {{ suggestionMode === "email" ? t("description.email") : t("description.text") }}
      </TextParagraph>
      <FormTextArea
        v-if="suggestionMode === 'text'"
        id="text-area"
        v-model="keywordsToSuggest"
        :placeholder="t('textArea.placeholder')"
        :error="showErrorKeywords ? t('textArea.error') : ''"
        :disabled="loadingSubjects"
        rows="3"
      />
      <SimpleButton
        :disabled="isDisabled"
        :loading="loadingSubjects"
        class="w-full"
        theme="secondary"
        @click="suggest()"
      >
        <template #leading>
          <SparklesIcon />
        </template>
        <span v-if="suggestions.length === 0">
          {{ t("suggestion.button") }}
        </span>
        <span v-else>
          {{ t("newSuggestion.button") }}
        </span>
      </SimpleButton>
      <div v-if="suggestions.length > 0" class="bg-gray-50 p-6">
        <div class="mb-6 flex items-baseline justify-between">
          <span class="text-sm font-medium leading-5 text-gray-900">{{ t("suggestion.title") }}</span>
          <SelectMenu
            v-model:selected="selectedEmotion"
            :data-items="emotionsDataItems"
            :disabled="loadingSubjects"
            class="w-48"
          />
        </div>
        <SimpleButton
          v-for="suggestion in suggestions"
          :key="suggestion"
          theme="white"
          class="mb-4 w-full"
          @click="selectSubject(suggestion)"
          >{{ suggestion }}</SimpleButton
        >
        <div class="mt-2 text-xs text-gray-400">
          {{ t("disclaimer") }}
        </div>
      </div>
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { computed, ref, reactive, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

// Components
import { TemplateIcon, PencilIcon, SparklesIcon } from "@heroicons/vue/outline";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormTextArea from "@molecules/FormTextArea.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import SelectMenu from "@molecules/SelectMenu.vue";

// API
import { useSubjectsSuggester } from "@/vue/api/modules/ai/subjectsSuggester";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Types
import type { DataItem } from "@/vue/components/molecules/SelectMenuLabel.vue";
import type { Subjects } from "@/vue/api/modules/ai/subjectsSuggester.types";
type SuggestionModes = "email" | "text";
type SuggestionEmotions = "formal" | "friendly" | "bold";
type SuggestionDataItem = DataItem<undefined, SuggestionEmotions, SuggestionEmotions>;
type SuggestionDataItems = Array<SuggestionDataItem>;

const SubjectsSuggesterAPI = useSubjectsSuggester();
const { t, locale } = useI18n();
const trackingEventsService = useTrackingEvents();

// Props
const props = withDefaults(
  defineProps<{
    isOpen: boolean;
    keywords?: string;
    emailContent?: string;
  }>(),
  {
    isOpen: false,
    keywords: "",
    emailContent: "",
  }
);

// Emits
const emit = defineEmits<{
  (e: "close"): void;
  (e: "subjectSelected", value: string): void;
}>();

const close = () => {
  toggleSuggestionMode("email");
  updateSuggestions();
  emit("close");
};

// Flags
const loadingSubjects = ref(false);

// Emotions
const emotionsDataItems: SuggestionDataItems = [
  {
    key: "friendly",
    value: t("emotions.friendly"),
  },
  {
    key: "formal",
    value: t("emotions.formal"),
  },
  {
    key: "bold",
    value: t("emotions.bold"),
  },
] as SuggestionDataItems;
const selectedEmotion = ref<SuggestionDataItem>(emotionsDataItems[0]);

watch(selectedEmotion, () => {
  clearSuggestions();
  suggest();
});

// States
const keywordsToSuggest = ref("");

const trimedKeywordsToSuggest = computed(() => keywordsToSuggest.value.replaceAll(/\s+/g, " ").trim().toLowerCase());
const suggestionMode = ref<SuggestionModes>("email");

const templateSuggestions = ref<Record<SuggestionEmotions, Array<string>>>({
  bold: [],
  formal: [],
  friendly: [],
});
const keyWordSuggestions = reactive<Map<string, Record<SuggestionEmotions, Array<string>>>>(
  new Map<string, Record<SuggestionEmotions, Array<string>>>()
);
const setKeywordSuggestins = (key: string, emotion: SuggestionEmotions, values: Array<string>) => {
  const suggestionGroup = keyWordSuggestions.get(trimedKeywordsToSuggest.value);
  if (suggestionGroup) {
    suggestionGroup[emotion] = values;
  } else {
    const newSuggestionsGroup: Record<SuggestionEmotions, Array<string>> = {
      bold: [],
      formal: [],
      friendly: [],
    };
    newSuggestionsGroup[emotion] = values;
    keyWordSuggestions.set(trimedKeywordsToSuggest.value, newSuggestionsGroup);
  }
};

const atLeastThreeKeywords = computed(() => {
  const text = trimedKeywordsToSuggest.value;
  const words = text.split(/[ ]+/);
  const numberOfWords = words.length;
  return numberOfWords >= 3;
});

const showErrorKeywords = ref(false);

watchEffect(() => {
  if (atLeastThreeKeywords.value && showErrorKeywords.value) {
    showErrorKeywords.value = false;
  }
});

const isDisabled = computed(() => {
  return !props.emailContent && suggestionMode.value === "email";
});

const suggestions = ref<Array<string>>([]);
const clearSuggestions = () => {
  suggestions.value = [];
};
const getSuggestions = (): Array<string> | undefined => {
  if (suggestionMode.value === "email") {
    return templateSuggestions.value[selectedEmotion.value.key];
  } else {
    const suggestionsGroup = keyWordSuggestions.get(trimedKeywordsToSuggest.value);
    return suggestionsGroup?.[selectedEmotion.value.key];
  }
};

const updateSuggestions = () => {
  clearSuggestions();
  const suggestionsLoaded = getSuggestions();

  if (!suggestionsLoaded || suggestionsLoaded.length === 0) return;

  suggestions.value = suggestionsLoaded;
};

watchEffect(() => {
  keywordsToSuggest.value = props.keywords;
});

// SuggestionMode
const toggleSuggestionMode = (value: SuggestionModes) => {
  suggestionMode.value = value;
  if (value === "email") {
    updateSuggestions();
  } else {
    clearSuggestions();
  }
};

// Methods
const selectSubject = (subject: string) => {
  emit("subjectSelected", subject);
  close();
};

const fetchSuggestions = async (text: string): Promise<Subjects> => {
  return await SubjectsSuggesterAPI.list({
    type: suggestionMode.value,
    emotion: selectedEmotion.value.key,
    text: suggestionMode.value === "text" ? keywordsToSuggest.value : props.emailContent,
    language: locale.value?.slice(0, 2) || "es",
  });
};

const suggest = async () => {
  if (!atLeastThreeKeywords.value && suggestionMode.value === "text") {
    showErrorKeywords.value = true;
    return;
  }

  try {
    if (suggestions.value.length === 0) {
      updateSuggestions();
      if (suggestions.value.length > 0) return;
    }

    if (props.emailContent === "" && keywordsToSuggest.value === "") return;
    loadingSubjects.value = true;

    if (suggestionMode.value === "email") {
      const subjects = await fetchSuggestions(props.emailContent);
      templateSuggestions.value[selectedEmotion.value.key] = subjects;
    } else {
      const subjects = await fetchSuggestions(trimedKeywordsToSuggest.value);
      setKeywordSuggestins(trimedKeywordsToSuggest.value, selectedEmotion.value.key, subjects);
    }
    trackingEventsService.dispatchAll({
      name: "APP_TPLEDITOR_AI_SUBJECT_GENERATE",
    });

    updateSuggestions();
  } catch (e) {
    console.log(e);
  } finally {
    loadingSubjects.value = false;
  }
};
</script>

<i18n lang="json">
{
  "es": {
    "header": {
      "title": "Asistente inteligente"
    },
    "toggleButtons": {
      "email": "Sugerir desde el contenido",
      "text": "Sugerir ingresando un texto"
    },
    "description": {
      "email": "El asistente de Inteligencia Artificial generará sugerencias tomando como referencia el contenido de tu plantilla.",
      "text": "El asistente de Inteligencia Artificial generará sugerencias a partir del texto o palabras clave ingresadas a continuación:"
    },
    "textArea": {
      "placeholder": "Ingresa una frase para recibir sugerencias.",
      "error": "Ingresa al menos 3 palabras."
    },
    "suggestion": {
      "button": "Obtener sugerencias",
      "title": "Sugerencias"
    },
    "newSuggestion": {
      "button": "Obtener más sugerencias"
    },
    "emotions": {
      "formal": "💼 Formal",
      "friendly": "😊 Amigable",
      "bold": "😎 Atrevido"
    },
    "disclaimer": "Las sugerencias son generadas utilizando los servicios de OpenAI de generación de textos. Su relevancia dependerá del texto utilizado como punto de partida. Los textos generados sirven como una guia creativa y no garantizan optimizar los resultados de las campañas."
  },
  "pt": {
    "header": {
      "title": "Assistente inteligente"
    },
    "toggleButtons": {
      "email": "Sugerir desde o conteúdo",
      "text": "Sugerir inserindo um texto"
    },
    "description": {
      "email": "O assistente de Inteligência Artificial fará sugestões tomando como referência o conteúdo do seu template.",
      "text": "O assistente de Inteligência Artificial fará sugestões a partir do texto ou palavras-chave inseridas a seguir:"
    },
    "textArea": {
      "placeholder": "Insira uma frase para receber sugestões.",
      "error": "Insira ao menos 3 palavras."
    },
    "suggestion": {
      "button": "Obter sugestões",
      "title": "Sugestões"
    },
    "newSuggestion": {
      "button": "Obter mais sugestões"
    },
    "emotions": {
      "formal": "💼 Formal",
      "friendly": "😊 Amigável",
      "bold": "😎 Atrevido"
    },
    "disclaimer": "As sugestões são geradas pelos serviços de geração de texto da OpenAI, cuja relevância dependerá do texto utilizado como ponto de partida. Os textos gerados servem como um guia criativo e não garantem a otimização dos resultados das campanhas."
  }
}
</i18n>
