import { Component } from "vue";

// Icons
import {
  ClipboardListIcon,
  DesktopComputerIcon,
  CreditCardIcon,
  CursorClickIcon,
  EyeIcon,
  HeartIcon,
  LightningBoltIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MailOpenIcon,
  ShareIcon,
  HomeIcon,
} from "@heroicons/vue/solid";

import {
  DeviceTabletIcon,
  DeviceMobileIcon,
  ExclamationIcon,
  //HeartIcon as HeartOutlineIcon,
  LocationMarkerIcon,
  MailIcon as MailOutlineIcon,
  ShoppingCartIcon,
  UserRemoveIcon,
} from "@heroicons/vue/outline";

// Lodash
import { isEmpty } from "lodash";

// i18n
import { useI18n } from "vue-i18n";

// Front Types
import { Activity } from "@domain/contacts";
import type { DeviceActivity } from "@/vue/types/contacts";

// Types
export interface Device {
  format?: string | undefined;
  icon?: Component;
}

export interface ActivityItem {
  id: string;
  bgColor?: string;
  iconColor?: string;
  icon: Component | undefined;
  createdShort: string;
  createdFull: string;
  device?: Device;
  href?: {
    link?: string;
    text?: string;
    target?: string;
  };
  href2?: {
    link?: string;
    text?: string;
  };
  location?: {
    country?: string;
    city?: string;
    tooltip?: string;
    icon?: Component;
  };
  preferredCategories: Array<{ name?: string; id?: string; url?: string }>;
  preferredProducts: Array<{ name?: string; id?: string; url?: string }>;
  attributionOrder?: {
    link?: string;
    text?: string;
    type?: string;
  };
  title?: string;
  title2?: string;
  viewOnline?: {
    link?: string;
    icon?: Component;
  };
}

export const useActivityContactFeed = () => {
  const { d, n, t } = useI18n();

  // Filters
  const isAttributionOrder = (activityItemsParams: Activity) => {
    return !isEmpty(activityItemsParams.details.attribution);
  };

  const isAutomation = (activityItemsParams: Activity) => {
    return (
      (activityItemsParams.type?.includes("mail") || activityItemsParams.type?.includes("automation")) &&
      activityItemsParams.source === "automation"
    );
  };

  const isAutomationTriggered = (activityItemsParams: Activity) => {
    return activityItemsParams.type === "automation.triggered";
  };

  const isBounced = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("bounced");
  };

  const isCampaign = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("mail") && activityItemsParams?.source === "bulk";
  };

  const isEcommerce = (activityItemsParams: Activity) => {
    return ["cart", "optin", "order", "session"].some((element) => activityItemsParams.type?.includes(element));
  };

  const isFbleads = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("fbleads");
  };

  const isInUse = (activityItemsParams: Activity) => {
    return !["first_opened", "first_clicked", ".out"].some((element) => activityItemsParams.type?.includes(element));
  };

  const isInterest = (activityItemsParams: Activity) => {
    return (
      ["contact.interest"].some((element) => activityItemsParams.type?.includes(element)) &&
      activityItemsParams.source === "app"
    );
  };

  const isJivochat = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("jivochat");
  };

  const isList = (activityItemsParams: Activity) => {
    return (
      ["contact.list"].some((element) => activityItemsParams.type?.includes(element)) &&
      activityItemsParams.source === "app"
    );
  };

  const isLinkClicked = (activityItemsParams: Activity) => {
    return activityItemsParams.details.link_name;
  };

  const isMailClicked = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("clicked") && !isWhatsApp(activityItemsParams);
  };

  const isMailOpened = (activityItemsParams: Activity) => {
    return activityItemsParams.type === "mail.opened";
  };

  const isMailSent = (activityItemsParams: Activity) => {
    return activityItemsParams.type === "mail.sent";
  };

  const isOptin = (activityItemsParams: Activity) => {
    return activityItemsParams.type === "optin.confirmed";
  };

  const isRfm = (activityItemsParams: Activity) => {
    return activityItemsParams.source === "lifecycles" && activityItemsParams.type?.includes(".in");
  };

  const isShared = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("shared");
  };

  const isUnsubscribed = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("unsubscribed");
  };

  const isWhatsApp = (activityItemsParams: Activity) => {
    return activityItemsParams.type?.includes("whatsapp");
  };

  // Formatting
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

  const formatBatchId = (str: string, type: "automation" | "campaign") => {
    const firstUnderscore = str.indexOf("_");
    const lastUnderscore = str.lastIndexOf("_");

    if (type === "automation") {
      if (firstUnderscore !== -1 && firstUnderscore !== lastUnderscore) {
        return str.substring(firstUnderscore + 1, lastUnderscore);
      }
    }

    if (type === "campaign") {
      if (lastUnderscore !== -1) {
        return str.substring(lastUnderscore + 1);
      }
    }

    return "";
  };

  // Functions
  const bgColor = (activityItemsParams: Activity) => {
    if (
      isMailSent(activityItemsParams) ||
      isAutomationTriggered(activityItemsParams) ||
      isList(activityItemsParams) ||
      isInterest(activityItemsParams)
    )
      return "#FFF";
    if (isAutomation(activityItemsParams)) return "#72D3F3";
    if (isBounced(activityItemsParams) || isUnsubscribed(activityItemsParams)) return "#FB7185";
    if (isCampaign(activityItemsParams)) return "#86EFAC";
    if (isEcommerce(activityItemsParams)) return "#FCA5A5";
    if (isRfm(activityItemsParams)) return "#A5B4FC";
    if (isFbleads(activityItemsParams)) return "#93C5FD";
    if (isJivochat(activityItemsParams)) return "#5EEAD4";
    if (isWhatsApp(activityItemsParams)) return "#25D366";
  };

  const iconColor = (activityItemsParams: Activity) => {
    if (
      isAutomation(activityItemsParams) &&
      (isAutomationTriggered(activityItemsParams) || isMailSent(activityItemsParams))
    )
      return "#72D3F3";
    if (isCampaign(activityItemsParams) && isMailSent(activityItemsParams)) return "#86EFAC";
    if (isList(activityItemsParams) || isInterest(activityItemsParams)) return "#facc15";
    if (isWhatsApp(activityItemsParams)) return "#fff";
  };

  const hrefLink = (activityItemsParams: Activity) => {
    if (
      (isAutomation(activityItemsParams) && !isLinkClicked(activityItemsParams)) ||
      (isAutomation(activityItemsParams) && isBounced(activityItemsParams)) ||
      (isAutomation(activityItemsParams) && isShared(activityItemsParams))
    ) {
      return {
        link: `/automations/${activityItemsParams.details?.automation_id}`,
        text: activityItemsParams.details?.automation_name,
      };
    }
    if (
      (isCampaign(activityItemsParams) && !isLinkClicked(activityItemsParams)) ||
      (isCampaign(activityItemsParams) && isBounced(activityItemsParams)) ||
      (isCampaign(activityItemsParams) && isUnsubscribed(activityItemsParams)) ||
      (isCampaign(activityItemsParams) && isShared(activityItemsParams))
    ) {
      return {
        link: `/metrics/${activityItemsParams.details?.campaign_id}`,
        text: activityItemsParams.details?.campaign_name,
      };
    }
    if (isInterest(activityItemsParams)) {
      return {
        link: `/interests/${activityItemsParams.details?.interest_id}`,
        text: activityItemsParams.details?.interest_name,
      };
    }
    if (isList(activityItemsParams)) {
      return {
        link: `/lists/${activityItemsParams.details?.list_id}`,
        text: activityItemsParams.details?.list_name,
      };
    }
    if (isLinkClicked(activityItemsParams)) {
      return {
        link: `${activityItemsParams.details?.url}`,
        text: `${activityItemsParams.details?.link_name}`,
        target: "_blank",
      };
    }
    if (isOptin(activityItemsParams)) {
      return {
        link: `/optins/${activityItemsParams.details?.optin_id}`,
        text: activityItemsParams.details?.optin_name,
      };
    }
    if (isRfm(activityItemsParams)) {
      return {
        link: `/lists/${activityItemsParams.details?.list_id}`,
        text: rfmNameList(activityItemsParams),
      };
    }
  };

  const hrefLink2 = (activityItemsParams: Activity) => {
    if (isAutomation(activityItemsParams)) {
      return {
        link: `/automations/${activityItemsParams.details?.automation_id}`,
        text: activityItemsParams.details?.automation_name,
      };
    }
    if (isCampaign(activityItemsParams)) {
      return {
        link: `/metrics/${activityItemsParams.details?.campaign_id}`,
        text: activityItemsParams.details?.campaign_name,
      };
    }
  };

  const iconType = (activityItemsParams: Activity) => {
    if (isAutomationTriggered(activityItemsParams)) return LightningBoltIcon;

    if (isBounced(activityItemsParams)) return ExclamationIcon;

    if (activityItemsParams.type === "order.completed") return CreditCardIcon;
    if (activityItemsParams.type === "session.finished") return HomeIcon;
    if (activityItemsParams.type === "cart.created") return ShoppingCartIcon;
    if (isOptin(activityItemsParams)) return ClipboardListIcon;

    if (["contact.list.added", "contact.interest.added"].some((element) => activityItemsParams.type === element))
      return LoginIcon;
    if (
      ["contact.list.removed", "contact.interest.removed", "contact.interest.expired"].some(
        (element) => activityItemsParams.type === element,
      )
    )
      return LogoutIcon;

    if (isMailOpened(activityItemsParams)) return MailOpenIcon;
    if (activityItemsParams.type === "mail.sent") return MailIcon;
    if (activityItemsParams.type?.includes("viewed_online")) return EyeIcon;
    if (isMailClicked(activityItemsParams)) return CursorClickIcon;
    if (isShared(activityItemsParams)) return ShareIcon;

    if (isRfm(activityItemsParams)) return HeartIcon;

    if (isUnsubscribed(activityItemsParams)) return UserRemoveIcon;
    if (isWhatsApp(activityItemsParams)) return undefined;
  };

  const rfmNameList = (activityItemsParams: Activity) => {
    if (activityItemsParams.type?.includes("abouttolose")) return t("title.rfm.abouttolose");
    if (activityItemsParams.type?.includes("atrisk")) return t("title.rfm.atrisk");
    if (activityItemsParams.type?.includes("cantlose")) return t("title.rfm.cantlose");
    if (activityItemsParams.type?.includes("champion")) return t("title.rfm.champion");
    if (activityItemsParams.type?.includes("highpotential")) return t("title.rfm.highpotential");
    if (activityItemsParams.type?.includes("loyalist")) return t("title.rfm.loyalist");
    if (activityItemsParams.type?.includes("neednutring")) return t("title.rfm.neednutring");
    if (activityItemsParams.type?.includes("recent")) return t("title.rfm.recent");
  };

  const titleText = (activityItemsParams: Activity) => {
    // Automations
    if (isMailOpened(activityItemsParams) && isAutomation(activityItemsParams)) return t("title.automation.open");
    if (activityItemsParams.type === "mail.sent" && isAutomation(activityItemsParams))
      return t("title.automation.sent");
    if (activityItemsParams.type === "mail.viewed_online" && isAutomation(activityItemsParams))
      return t("title.automation.viewOnline");
    if (isAutomationTriggered(activityItemsParams)) return t("title.automation.triggered");

    // Bounced
    if (isBounced(activityItemsParams) && activityItemsParams.details.bounce_type === "hard")
      return t("title.bounced.hard");
    if (isBounced(activityItemsParams) && activityItemsParams.details.bounce_type === "soft")
      return t("title.bounced.soft");

    // Campaign
    if (activityItemsParams.type === "mail.opened" && isCampaign(activityItemsParams)) return t("title.campaign.open");
    if (activityItemsParams.type === "mail.sent" && isCampaign(activityItemsParams)) return t("title.campaign.sent");
    if (activityItemsParams.type === "mail.viewed_online" && isCampaign(activityItemsParams))
      return t("title.campaign.viewOnline");

    // Ecommerce
    if (activityItemsParams.type === "order.completed") {
      const amount = activityItemsParams.details.amount;
      return (
        t("title.ecommerce.order.completed") +
        capitalizeFirstLetter(activityItemsParams.source) +
        t("title.ecommerce.order.total") +
        "$" +
        sn(amount, "decimal") +
        "."
      );
    }
    if (activityItemsParams.type === "cart.created")
      return t("title.ecommerce.cart.created") + capitalizeFirstLetter(activityItemsParams.source) + ".";
    if (activityItemsParams.type === "session.finished") {
      return (
        t("title.ecommerce.session.finished") +
        capitalizeFirstLetter(activityItemsParams.source) +
        t("title.ecommerce.session.during") +
        activityItemsParams.details.session_duration +
        t("title.ecommerce.session.seconds") +
        t("title.ecommerce.session.seen")
      );
    }
    if (isOptin(activityItemsParams)) {
      if (activityItemsParams.source === "app") return t("title.ecommerce.optin.confirmedApp");
      return t("title.ecommerce.optin.confirmedSource") + capitalizeFirstLetter(activityItemsParams.source) + ".";
    }

    // Integrations
    if (activityItemsParams.type === "fbleads.contact.subscribed") return t("title.integrations.fbleads.subscribed");
    if (activityItemsParams.type === "jivochat.chat.finished") return t("title.integrations.jivochat.subscribed");

    // List or Interests
    if (activityItemsParams.type === "contact.list.added") return t("title.list.added");
    if (activityItemsParams.type === "contact.list.removed") return t("title.list.removed");
    if (activityItemsParams.type === "contact.interest.added") return t("title.interest.added");
    if (activityItemsParams.type === "contact.interest.expired") return t("title.interest.expired");
    if (activityItemsParams.type === "contact.interest.removed") return t("title.interest.removed");

    // Mails
    if (isMailClicked(activityItemsParams)) return t("title.click.done");

    if (isShared(activityItemsParams)) {
      const shareOn = activityItemsParams.details.share_on;
      let shareOnText = t("title.shared.to");
      if (shareOn) shareOnText += capitalizeFirstLetter(shareOn) + t("title.shared.contain");
      if (isAutomation(activityItemsParams)) return shareOnText + t("title.shared.automation");
      if (isCampaign(activityItemsParams)) return shareOnText + t("title.shared.campaign");
    }

    // RFM
    if (isRfm(activityItemsParams)) return t("title.rfm.in");

    // Unsubscribed
    if (isUnsubscribed(activityItemsParams)) {
      const reason = activityItemsParams.details.reason;
      let unsubscribedText = t("title.unsubscribed.indicate");
      if (reason === "not_interested") unsubscribedText += t("title.unsubscribed.not_interested");
      if (reason === "too_frequent") unsubscribedText += t("title.unsubscribed.too_frequent");
      if (reason === "spam_never_subscribed") unsubscribedText += t("title.unsubscribed.spam_never_subscribed");
      if (reason === "spam_offensive") unsubscribedText += t("title.unsubscribed.spam_offensive");
      if (reason === "spam_fbl") unsubscribedText += t("title.unsubscribed.spam_fbl");
      if (reason === "oneclick") unsubscribedText += t("title.unsubscribed.one_click");
      if (reason === "oneclick_email") unsubscribedText += t("title.unsubscribed.one_click_email");
      if (isAutomation(activityItemsParams)) return (unsubscribedText += t("title.unsubscribed.automation"));
      if (isCampaign(activityItemsParams)) return (unsubscribedText += t("title.unsubscribed.campaign"));
    }

    // WhatsApp
    if (isWhatsApp(activityItemsParams)) {
      if (activityItemsParams.type.includes("clicked")) return t("title.whatsapp.clicked");
      if (activityItemsParams.type.includes("opened")) return t("title.whatsapp.opened");
      if (activityItemsParams.type.includes("sent")) return t("title.whatsapp.sent");
    }
  };

  const titleText2 = (activityItemsParams: Activity) => {
    // Clicks
    if (isMailClicked(activityItemsParams)) {
      if (isCampaign(activityItemsParams)) return t("title.click.campaign");
      if (isAutomation(activityItemsParams)) return t("title.click.automation");
    }
  };

  const viewInfo = (activityItemsParams: Activity) => {
    if (activityItemsParams.details.link_view_online)
      return {
        link: `https://ov.pemres.net${activityItemsParams.details.link_view_online}`,
        icon: MailOutlineIcon,
      };
  };

  const deviceInfo = (activityItemsParams: Activity): Device | undefined => {
    const device = activityItemsParams.details.device;
    const os = activityItemsParams.details.os;

    const getFormat = ({ device, os }: { device?: DeviceActivity; os?: string }): string | undefined => {
      if (device === "Tablet" && os) return device + ", " + os;
      if (device === "Mobile" && os) return device + ", " + os;
      if (device === "Desktop" && os) return device + ", " + os;
      if (device) return device;
      if (os) return os;
      return undefined;
    };

    if (device || os) {
      const item: Device = {
        format: getFormat({ device, os }),
        icon:
          device === "Tablet"
            ? DeviceTabletIcon
            : device === "Desktop"
            ? DesktopComputerIcon
            : device === "Mobile" || os
            ? DeviceMobileIcon
            : undefined,
      };
      return item;
    }
  };

  const locationInfo = (activityItemsParams: Activity) => {
    const country = activityItemsParams.details.country;
    const city = activityItemsParams.details.city;
    if (country && city) {
      return {
        country: country,
        city: city,
        tooltip: city + ", " + country,
        // tooltip: country && city ? city + ", " + country : country || city,
        icon: LocationMarkerIcon,
      };
    }
  };

  const preferredCategoriesInfo = (activityItemsParams: Activity) => {
    if (activityItemsParams.details.preferred_categories) {
      return activityItemsParams.details.preferred_categories;
    }
    return [];
  };

  const preferredProductsInfo = (activityItemsParams: Activity) => {
    if (activityItemsParams.details.preferred_products) {
      return activityItemsParams.details.preferred_products;
    }
    return [];
  };

  const attributionOrderInfo = (activityItemsParams: Activity) => {
    if (isAttributionOrder(activityItemsParams)) {
      let attributionTypeText = t("title.ecommerce.order.attribution.title");
      let attributionBatchId = "";
      const attribution = activityItemsParams.details.attribution;

      if (attribution?.type === "click") attributionTypeText += t("title.ecommerce.order.attribution.click");
      if (attribution?.type === "open") attributionTypeText += t("title.ecommerce.order.attribution.open");

      if (attribution?.source === "automation") {
        if (attribution?.batch_id) attributionBatchId = formatBatchId(attribution.batch_id, "automation");
        return {
          link: `/automations/${attributionBatchId}`,
          text: attributionTypeText,
          type: t("title.ecommerce.order.attribution.automation"),
        };
      }
      if (attribution?.source === "bulk") {
        if (attribution?.batch_id) attributionBatchId = formatBatchId(attribution.batch_id, "campaign");
        return {
          link: `/metrics/${attributionBatchId}`,
          text: attributionTypeText,
          type: t("title.ecommerce.order.attribution.campaign"),
        };
      }
      return {};
    }
  };

  const translateEvent = (activity: Activity): ActivityItem => ({
    id: activity.id,
    bgColor: bgColor(activity),
    iconColor: iconColor(activity),
    createdShort: d(activity.created, "shortWithTime"),
    createdFull: d(activity.created, "longWithTimeAndWeekday"),
    device: deviceInfo(activity),
    href: hrefLink(activity),
    href2: hrefLink2(activity),
    icon: iconType(activity),
    location: locationInfo(activity),
    preferredCategories: preferredCategoriesInfo(activity),
    preferredProducts: preferredProductsInfo(activity),
    attributionOrder: attributionOrderInfo(activity),
    title: titleText(activity),
    title2: titleText2(activity),
    viewOnline: viewInfo(activity),
  });

  return { isInUse, translateEvent };
};
