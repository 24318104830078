import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";

//Types
import type { GetParams, GetAPIParams, GetOptinsAsyncGenerator, OptinsService } from "./optins.types";
import type { SuccessAPIOffsetPaging } from "../../models/requestWrappers";
import type { Optins } from "@domain/optins";

export function useOptins(): OptinsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let GetOptinsGenerator: GetOptinsAsyncGenerator | undefined = undefined;
  let apiParams: GetParams | undefined = undefined;

  return {
    async get(options) {
      let isNewRequest = false;
      if (!isEqual(apiParams, options) || !GetOptinsGenerator) {
        isNewRequest = true;
        apiParams = options;
        GetOptinsGenerator = requestPaging<Optins, unknown, GetAPIParams>(
          {
            url: `/optins`,
            method: "get",
            urlParams: {
              q: options?.search ? `*${options?.search}*` : undefined,
              fields: "subscriptions",
              sortBy: apiParams?.sortBy ?? "name",
              sortDir: apiParams?.sortDir ?? "asc",
            },
          },
          { limit: apiParams?.limit ?? 20, offset: apiParams?.offset ?? 0 },
          true,
        );
      }

      if (!GetOptinsGenerator || GetOptinsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };

      const data = (await GetOptinsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<Optins>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
        isNewRequest,
      };
    },
    async delete(params) {
      await request({
        url: `/optins/${params.id}`,
        method: "delete",
      });
    },
  };
}
