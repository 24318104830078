// API
import { useRequestsAPIV3 } from "@api/requests";

// Types
import type { UserActivityService } from "@application/ports";
import type { GetFirstEventsAPIResponse } from "./userActivity.types";

// Utils
import { ok } from "neverthrow";
import axios from "axios";

export const useUserActivityService = (): UserActivityService => {
  const requestAPIV3 = useRequestsAPIV3();

  const userActivityService: UserActivityService = {
    async submit(params) {
      const instance = axios.create({
        timeout: 20000,
        baseURL: "https://webhooks.myperfit.net/events/useractivity/generic/ui/3a2e8954/0fa97837",
      });

      await instance.request({
        method: "post",
        data: {
          account: params.account,
          user: params.user,
          timestamp: params.timestamp ?? new Date().toISOString(),
          action: params.action,
          data: params.data ?? {},
        },
      });

      return ok(undefined);
    },
    async getFirstEvents() {
      const res = await requestAPIV3<GetFirstEventsAPIResponse>({
        url: `/useractivity/onboarding/events`,
        method: "get",
      });

      return ok(res.data.data);
    },
  };

  return userActivityService;
};
