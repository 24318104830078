import { useRequestsAPIV3 } from "@api/requests";
import { getI18nInstance } from "@locales/i18n";
import { useNotifications } from "@composables/notifications";

//Types
import { SuccessAPIBase } from "@api/models/requestWrappers";
import { GetReceiptsResponse, DeleteReceiptRequest } from "./receipts.types";

//I18N
import esMessages from "./i18n/receipts.es.json";
import prMessages from "./i18n/receipts.pt.json";

export function useReceipts() {
  const request = useRequestsAPIV3();
  const { notify } = useNotifications();
  const { t, mergeLocaleMessage } = getI18nInstance().global;

  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", prMessages);

  async function getReceipts(): Promise<GetReceiptsResponse> {
    try {
      const res = await request<SuccessAPIBase<GetReceiptsResponse>>({
        url: `/receipts`,
        method: "get",
      });
      return res.data.data;
    } catch (e) {
      notify({
        title: t("getReceiptsAPI.errorTitle"),
        text: t("getReceiptsAPI.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  async function deleteReceipts({ id }: DeleteReceiptRequest): Promise<unknown> {
    try {
      const res = await request<SuccessAPIBase<unknown>>({
        url: `/receipts/${id}`,
        method: "delete",
      });
      return res.data.data;
    } catch (e) {
      notify({
        title: t("deleteReceiptsAPI.errorTitle"),
        text: t("deleteReceiptsAPI.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  return { getReceipts, deleteReceipts };
}
