<template>
  <ListSelector
    v-model:selected="selectedLists"
    :lists="interestLists"
    :open="open"
    :loading-accept="loadingAccept"
    :loading-create="loadingCreate"
    :allow-create="allowCreate"
    :skeleton="loadingLists"
    @close="close"
    @accept="accept"
    @createList="createList"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

import type { ListItems, Lists } from "./listSelector.types";
import { listToItem, listsToItems } from "./listSelector.data";

// Components
import ListSelector from "@molecules/ListSelector/ListSelector.vue";

// Icons
import { UserGroupIcon } from "@heroicons/vue/outline";

// Utils
import { isBefore } from "date-fns";

// Services
import { useLists } from "@api/modules/lists/lists";
import { useI18n } from "vue-i18n";

const listsAPI = useLists();

const { t } = useI18n();

withDefaults(
  defineProps<{
    open: boolean;
    loadingAccept?: boolean;
    allowCreate?: boolean;
  }>(),
  {
    loadingAccept: false,
    allowCreate: false,
  },
);

// Emits
const emit = defineEmits<{
  close: [void];
  selectLists: [ListItems];
}>();

const close = () => {
  emit("close");
};

const accept = () => {
  emit("selectLists", selectedLists.value);
};

const interestLists = ref<Lists>([]);
const selectedLists = ref<ListItems>([]);

const loadingLists = ref(false);
const loadingCreate = ref(false);
const fetchlimit = 100000;
const fetchLists = async () => {
  loadingLists.value = true;

  const results = await listsAPI.get({
    limit: fetchlimit,
  });

  if (results.done) {
    loadingLists.value = false;
    return;
  }

  const orderedResults = results.value.sort((listA, listB) => {
    if (isBefore(new Date(listA.created), new Date(listB.created))) return 1;
    return -1;
  });

  interestLists.value = listsToItems(orderedResults, {
    list: t("listTitle"),
  });

  loadingLists.value = false;
};

const createList = async (interestName: string) => {
  loadingCreate.value = true;
  const newList = await listsAPI.create({ name: interestName });

  const allList = interestLists.value.find((l) => l.id === "lists");
  if (!allList) return;

  allList.list.unshift(listToItem(newList));
  selectedLists.value = [...selectedLists.value, listToItem(newList)];
  loadingCreate.value = false;
};

onMounted(async () => {
  await fetchLists();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "listTitle": "Listas"
  },
  "pt": {
    "listTitle": "Listas"
  }
}
</i18n>
