<template>
  <form class="space-y-6" @submit.prevent="save">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
      <FormInput
        v-model="billingInfo.info.name"
        :label="t('legalNameLabel')"
        :placeholder="t('legalNamePlaceholder')"
        :error="v$.info.name.$error ? t('fieldRequired') : undefined"
      />
      <FormInput v-model="billingInfo.info.fiscal_id" :label="t('fiscalIdLabel')" />
      <FormInput
        v-model="billingInfo.email"
        :label="t('emailLabel')"
        placeholder="example@gmail.com"
        :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
      />

      <FormInput v-model="billingInfo.info.cp" :label="t('postalCodeLabel')" />
      <FormInput v-model="billingInfo.info.domicilio" :label="t('addressLabel')" />
      <FormInput v-model="billingInfo.info.ciudad" :label="t('cityLabel')" />
    </div>

    <div class="flex justify-end space-x-2">
      <SimpleButton @click="close" theme="white">{{ t("close") }}</SimpleButton>
      <SimpleButton type="submit" :loading="loadingSave">
        <template #leading>
          <LockClosedIcon />
        </template>
        {{ t("save") }}
      </SimpleButton>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { reactive, ref, watch } from "vue";

// Component
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Utils
import { cloneDeep } from "lodash";
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

// Composables
import { useNotifications } from "@composables/notifications";

// Service
import { useBillingInfoService } from "@services";
import type { GenericBillingInfoData } from "@domain/billingInfo";

const { t } = useI18n();
const billingInfoService = useBillingInfoService();

const { notify } = useNotifications();

const props = withDefaults(
  defineProps<{
    country: "cl" | "pr" | "es" | "co" | "uy" | "us" | "ec" | "py" | "pe" | "cr" | "ve" | "gt" | "bo";
    billingData: GenericBillingInfoData;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [];
  updateBillingData: [GenericBillingInfoData];
}>();

const close = () => {
  emit("close");
  fillBillingInfo(props.billingData);
  v$.value.$reset();
};

const updateBillingData = (info: GenericBillingInfoData) => {
  emit("updateBillingData", info);
};

const billingInfo = reactive<GenericBillingInfoData>({
  country: props.country,
  email: "",
  info: {
    fiscal_id: "",
    fiscal_id_clean: "",
    name: "",
    domicilio: "",
    ciudad: "",
    cp: ""
  }
});

const validationRules = {
  country: { required },
  email: { required, email },
  info: {
    fiscal_id: {
      required
    },
    name: {
      required
    },
    domicilio: {
      required
    },
    ciudad: {
      required
    },
    cp: {
      required
    },
  }
};
const v$ = useVuelidate(validationRules, billingInfo);

const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

watch(
  () => billingInfo,
  (newValue, oldValue) => {
    for (const key in newValue) {
      if (newValue[key] !== oldValue[key]) {
        v$[key].$reset();
      }
    }
  },
  { deep: true }
);

watch(
  () => cloneDeep(billingInfo.info),
  (newInfo, oldInfo) => {
    for (const key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        v$.value.info?.[key]?.$reset();
      }
    }
  },
  { deep: true }
);

const loadingSave = ref(false);

const save = async () => {
  const validation = await v$.value.$validate();
  if (!validation) return;

  loadingSave.value = true;

  const res = await billingInfoService.save({
    country: billingInfo.country,
    email: billingInfo.email,
    fiscal_id: billingInfo.info.fiscal_id ?? "",
    fiscal_id_clean: billingInfo.info.fiscal_id,
    name: billingInfo.info.name,
    domicilio: billingInfo.info.domicilio,
    ciudad: billingInfo.info.ciudad,
    cp: billingInfo.info.cp
  });
  loadingSave.value = false;

  if (res.isErr()) {
    notify({ title: t("saveError.title"), text: t("saveError.text"), theme: "error" });
    return;
  }
  notify({ title: t("notify.success") });
  updateBillingData(billingInfo);
  emit("close");
};

const fillBillingInfo = (data: GenericBillingInfoData) => {
  billingInfo.email = data.email;

  if (["ar", "br", "mx"].includes(data.country)) return;

  billingInfo.info.name = data.info.name ?? "";
  billingInfo.info.fiscal_id = data.info.fiscal_id ?? "";
  billingInfo.info.fiscal_id_clean = data.info.fiscal_id_clean ?? "";
  billingInfo.info.domicilio = data.info.domicilio ?? "";
  billingInfo.info.ciudad = data.info.ciudad ?? "";
  billingInfo.info.cp = data.info.cp ?? "";
};

watch(
  () => props.billingData,
  () => {
    fillBillingInfo(props.billingData);
  },
  { deep: true }
);
</script>

<i18n lang="json">
{
  "es": {
    "close": "Cancelar",
    "save": "Guardar cambios",
    "emailLabel": "Email administrativo",
    "legalNameLabel": "Nombre / Razón social",
    "legalNamePlaceholder": "Compañia S.R.L.",
    "fiscalIdLabel": "ID Fiscal / VAT / IVA",
    "fieldRequired": "Campo requerido.",
    "invalidEmail": "El email ingresado es inválido.",
    "addressLabel": "Domicilio",
    "cityLabel": "Ciudad",
    "postalCodeLabel": "Código postal",
    "notify": {
      "success": "Información actualizada con éxito"
    },
    "saveError": {
      "title": "Error",
      "text": "No se pudieron actualizar los datos de facturación."
    }
  },
  "pt": {
    "close": "Cancelar",
    "save": "Salvar alterações",
    "emailLabel": "E-mail administrativo",
    "legalNameLabel": "Nome / Razão social",
    "legalNamePlaceholder": "Empresa S.A.",
    "fiscalIdLabel": "ID Fiscal / VAT / IVA",
    "fieldRequired": "Campo obrigatório.",
    "invalidEmail": "O e-mail inserido é inválido.",
    "addressLabel": "Endereço",
    "cityLabel": "Cidade",
    "postalCodeLabel": "Código postal",
    "notify": {
      "success": "Informações atualizadas com sucesso"
    },
    "saveError": {
      "title": "Erro",
      "text": "Não foi possível atualizar os dados de faturamento."
    }
  }
}
</i18n>
