import MetricBaseView from "./base";
import { EditView } from "@/core/magnet";
import app from "@/app";
import { h } from "vue";
import { mountComponent } from "@/helpers/vue";
import AlertBox from "@atoms/AlertBox.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import { ArrowSmRightIcon } from "@heroicons/vue/outline";

export default MetricBaseView.extend({
  module: "metrics",
  templates: {
    initial: require("../templates/links.hbs"),
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=archive]": "archive",
      "click [data-action=stop]": "stop",
      "click [data-action=unarchive]": "unarchive",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-share-modal]": "openShareModal",
      "click [data-action=copy-share-url]": "copyShareUrl",
      "click [data-link]": "linkTo",
    });
  },

  // Performs the needed bindings after renderization
  afterRender: function () {
    // Redirect to contents if the campaign is pending
    this.redirectToContents(this.item.attributes);

    if (this.useUnlayer) {
      const message = window.lang.metrics.alertUnlayerHeatmap;
      const buttonMessage = window.lang.metrics.goToContent;

      const planType = app.session.attributes.details.plan
        ? app.session.attributes.details.plan.type
        : app.session.attributes.details.account.plan.type;

      const isFreePlan = planType === "FREE";

      const haveDataId = this.item?.additionalsData?.links?.some((link) => {
        const linkId = link?.link_id?.split("_")?.[2];
        return linkId !== undefined;
      });

      if (!isFreePlan && haveDataId) {
        this.heatmapAlert = mountComponent(
          h(AlertBox, null, {
            default: () =>
              h(
                "div",
                {
                  class: "flex justify-between space-x-8 w-full",
                },
                [
                  h("span", null, message),
                  h(
                    SimpleButton,
                    {
                      theme: "primary-clean",
                      class: "flex-shrink-0",
                      size: "min",
                      onClick: () => {
                        app.router.navigateTo(`metrics/${this.item.attributes.id}/contents`);
                      },
                    },
                    {
                      default: () => buttonMessage,
                      trailing: () => h(ArrowSmRightIcon),
                    }
                  ),
                ]
              ),
          }),
          {
            class: "mb-4",
          },
          "#vue-links-heatmap-alertbox"
        );
      }
    } else {
      const message = window.lang.metrics.alertEddieHeatmap;
      this.heatmapAlert = mountComponent(
        h(AlertBox, null, message),
        {
          class: "mb-4",
        },
        "#vue-links-heatmap-alertbox"
      );
    }

    // Force resize
    $(window).resize();
  },

  // Links
  linkTo: function (e) {
    e.preventDefault();
    const link = $(e.currentTarget).data("link");
    app.router.navigateTo(`metrics/${this.item.get("id")}/${link}`);
  },
});
