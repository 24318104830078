<template>
  <svg
    viewBox="6 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin: 0.5rem 1rem"
  >
    <path
      d="M96 1H11C8.23858 1 6 3.23858 6 6V115C6 117.761 8.23858 120 11 120H96V1Z"
      fill="#E3F5FC"
    />
    <path
      opacity="0.675"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.5564 94.5005C47.509 96.4531 50.6749 96.4531 52.6275 94.5005L74.5924 72.5355C76.545 70.5829 79.7109 70.5829 81.6635 72.5355L96 86.8721V120.053H71.1089H27.075H12.0711C7.61655 120.053 5.3857 114.667 8.53553 111.517L32.0189 88.034C33.9715 86.0814 37.1374 86.0814 39.09 88.034L45.5564 94.5005Z"
      fill="#00ADE8"
    />
    <path
      opacity="0.675"
      d="M43.8337 46.8266C49.346 46.8266 53.8146 42.358 53.8146 36.8457C53.8146 31.3334 49.346 26.8649 43.8337 26.8649C38.3215 26.8649 33.8529 31.3334 33.8529 36.8457C33.8529 42.358 38.3215 46.8266 43.8337 46.8266Z"
      fill="#00ADE8"
    />
    <rect x="107" y="65" width="63" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="107" y="40" width="63" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="106" y="14" width="65" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="124" y="24" width="29" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="106" y="89" width="65" height="18" rx="2" fill="#00ADE8" />
    <rect x="7" y="1" width="178" height="118" rx="4" stroke="#00ADE8" stroke-width="2" />
  </svg>
</template>

<script setup></script>
