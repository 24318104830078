<template>
  <Menu as="div" class="relative">
    <MenuButton as="template"><slot name="button" /></MenuButton>
    <MenuItems
      as="ul"
      class="absolute top-11 z-20 flex max-h-80 w-64 flex-col overflow-hidden rounded-md border bg-white shadow focus:outline-none"
    >
      <input
        :ref="handleVisibility"
        v-model="search"
        tabindex="-1"
        :placeholder="t('searchPlaceholder')"
        class="border-b border-gray-100 px-4 py-3 text-sm text-gray-600 focus:outline-none"
      />
      <LoadingSpinner v-if="loading" class="mx-auto mt-4 h-6 w-6 text-sky-400" />
      <template v-else>
        <div v-if="filteredRuleOptions.length > 0" class="overflow-y-auto">
          <MenuItem v-for="option in filteredRuleOptions" :key="option.id" v-slot="{ active }" as="template">
            <li
              :class="[{ 'bg-sky-100': active }, 'cursor-pointer select-none px-4 py-2 text-sm text-gray-500']"
              @click="() => onSelect(option)"
            >
              <span>{{ option.name }}</span>
            </li>
          </MenuItem>
        </div>
        <p v-else class="select-none px-4 py-2 text-center text-sm text-gray-300">{{ t("noResults") }}</p>
      </template>
    </MenuItems>
  </Menu>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

// Components
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import LoadingSpinner from "@/vue/components/atoms/LoadingSpinner.vue";

// Utils
import { useI18n } from "vue-i18n";

// Domain
import type { AttributeOption, AttributeOptions } from "@domain/predicates";
import { nextTick } from "process";
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    ruleOptions: AttributeOptions;
    loading: boolean;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: "select", option: AttributeOption);
}>();

const onSelect = (option: AttributeOption) => {
  emit("select", option);
};

// Filter Fields
const search = ref("");

const filteredRuleOptions = computed(() => {
  if (search.value.length === 0) return props.ruleOptions;

  return props.ruleOptions.filter((field) => {
    const filters = [field.name.toString().toLowerCase().includes(search.value.toLowerCase())];

    return filters.some(Boolean);
  });
});

// Pseudo Open/Close handler
const isOpen = ref(false);
const handleVisibility = (element) => {
  if (!element) {
    search.value = "";
    isOpen.value = false;
    return;
  }
  if (isOpen.value) return;
  isOpen.value = true;
  nextTick(() => {
    element.focus();
  });
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "newRuleButton": "Nueva regla",
    "searchPlaceholder": "Busca una regla para aplicar",
    "noResults": "No hay resultados"
  },
  "pt": {
    "newRuleButton": "Nova regra",
    "searchPlaceholder": "Buscar uma regra para aplicar",
    "noResults": "Sem resultados"
  }
}
</i18n>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
