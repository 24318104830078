interface SetCookieParamsBase {
  cname: string;
  value: string;
}

interface SetCookieByDateParams extends SetCookieParamsBase {
  exDate: DateString;
}

interface SetCookieByTimeParams extends SetCookieParamsBase {
  exDays?: number;
  exHours?: number;
  exMinutes?: number;
  exSeconds?: number;
  exMiliseconds?: number;
}

export const isSetCookieByDate = (
  param: SetCookieByDateParams | SetCookieByTimeParams,
): param is SetCookieByDateParams => {
  return (param as SetCookieByDateParams).exDate !== undefined;
};

export type SetCookieParams = SetCookieByDateParams | SetCookieByTimeParams;

export interface CookiesService {
  setCookie(params: SetCookieParams): void;
  deleteCookie(cookieName: string): void;
  getCookie(cookieName: string): string;
  getParsedCookie<V = string>(cookieName: string): V | undefined;
}
