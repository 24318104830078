<template>
  <svg
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin: 0.5rem 1rem"
  >
    <path
      d="M89 0H174C176.761 0 179 2.23858 179 5V114C179 116.761 176.761 119 174 119H89V0Z"
      fill="#E3F5FC"
    />
    <path
      opacity="0.675"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M139.444 93.5005C137.491 95.4531 134.325 95.4531 132.373 93.5005L110.408 71.5355C108.455 69.5829 105.289 69.5829 103.337 71.5355L89 85.8721V119.053H113.891H157.925H173.688C178.176 119.053 180.392 113.598 177.174 110.469L152.98 86.9367C151.02 85.0302 147.892 85.0518 145.959 86.9853L139.444 93.5005Z"
      fill="#00ADE8"
    />
    <path
      opacity="0.675"
      d="M141.166 45.8266C135.654 45.8266 131.185 41.358 131.185 35.8457C131.185 30.3334 135.654 25.8649 141.166 25.8649C146.679 25.8649 151.147 30.3334 151.147 35.8457C151.147 41.358 146.679 45.8266 141.166 45.8266Z"
      fill="#00ADE8"
    />
    <rect x="16" y="65" width="63" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="16" y="40" width="63" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="15" y="14" width="65" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="33" y="24" width="29" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="15" y="89" width="65" height="18" rx="2" fill="#00ADE8" />
    <rect x="1" y="1" width="178" height="118" rx="4" stroke="#00ADE8" stroke-width="2" />
  </svg>
</template>

<script setup></script>
