<template>
  <form class="space-y-6" @submit.prevent="save">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
      <FormInput
        v-model="billingInfo.info.fiscal_id"
        :label="t('fiscalIdLabel')"
        :error="v$.info.fiscal_id.$error ? getFiscalIdErrorMessage(v$.info.fiscal_id.$errors) : undefined"
        placeholder="00-00000000-0"
        :loading="loadingData"
        @blur="() => completeData(billingInfo.info.fiscal_id)"
      />
      <FormInput
        v-model="billingInfo.info.name"
        :label="t('legalNameLabel')"
        :placeholder="t('legalNamePlaceholder')"
        :disabled="loadingData || dataAutocompleted"
        :class="[{ 'bg-gray-50': dataAutocompleted }]"
        :error="v$.info.name.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.email"
        :label="t('emailLabel')"
        placeholder="example@gmail.com"
        :disabled="loadingData"
        :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
      />
      <SelectMenuLabel
        v-model:selected="selectedvatId"
        :label="t('vatIdLabel')"
        :data-items="vatIdsCategories"
        :disabled="loadingData || dataAutocompleted"
        :rem-height="11"
      />
    </div>
    <AlertBox v-if="selectedvatId?.key === 'consumidor_final'" theme="info" class="my-4">
      <div class="flex flex-col">
        <h3 class="m-0 text-sm font-semibold">
          {{ t("vatIds.consumidor_final") }}
        </h3>
        <p>
          {{ t("vatIdAlerts.consumidor_final") }}
        </p>
      </div>
    </AlertBox>
    <div class="flex justify-end space-x-2">
      <SimpleButton @click="close" theme="white">{{ t("close") }}</SimpleButton>
      <SimpleButton type="submit" :loading="loadingSave" :disabled="loadingData">
        <template #leading>
          <LockClosedIcon />
        </template>
        {{ t("save") }}</SimpleButton
      >
    </div>
  </form>
</template>
<script lang="ts" setup>
import { reactive, watch, ref, watchEffect } from "vue";

// Component
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";
import AlertBox from "@atoms/AlertBox.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItem, DataItems } from "@molecules/SelectMenuLabel.vue";

// I18n
import { useI18n } from "vue-i18n";

// Utils
import { cloneDeep } from "lodash";
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Intercom from "@helpers/intercom";

// Composables
import { useNotifications } from "@composables/notifications";

// Service
import { useBillingInfoService } from "@services";
import type { ArgentinaBillingInfoData } from "@domain/billingInfo";
import { useCreditCards } from "@api/modules/creditcards";

const { t } = useI18n();
const billingInfoService = useBillingInfoService();
const creditCardsService = useCreditCards();

const { notify } = useNotifications();

const props = withDefaults(
  defineProps<{
    billingData: ArgentinaBillingInfoData;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [];
  updateBillingData: [ArgentinaBillingInfoData];
}>();

const close = () => {
  emit("close");
  fillBillingInfo(props.billingData);
  v$.value.$reset();
};

const updateBillingData = (info: ArgentinaBillingInfoData) => {
  emit("updateBillingData", info);
};

const selectedvatId = ref<DataItem>();
const billingInfo = reactive<ArgentinaBillingInfoData>({
  country: "ar",
  email: "",
  info: {
    fiscal_id: "",
    fiscal_id_clean: "",
    name: "",
    vat_id: "consumidor_final"
  }
});

const clearBillingInfoData = () => {
  billingInfo.info.name = "";
  billingInfo.info.vat_id = "";
};

watchEffect(() => {
  billingInfo.info.vat_id = selectedvatId.value?.key.toString() ?? "";
});

const vatIdsCategories = ref<DataItems<unknown, string, string>>([
  {
    key: "responsable_monotributo",
    value: t("vatIds.responsable_monotributo")
  },
  {
    key: "consumidor_final",
    value: t("vatIds.consumidor_final")
  },
  {
    key: "iva_exento",
    value: t("vatIds.iva_exento")
  },
  {
    key: "responsable_inscripto",
    value: t("vatIds.responsable_inscripto")
  }
]);

const loadingData = ref(false);
const dataAutocompleted = ref<boolean>(false);
const completeData = async (fiscalId: string) => {
  if (fiscalId.length === 0) {
    clearBillingInfoData();
    dataAutocompleted.value = false;
    return;
  }

  try {
    loadingData.value = true;

    v$.value.info.fiscal_id.$validate();

    if (v$.value.info.fiscal_id.$error) return;

    const data = await creditCardsService.getAFIPData(billingInfo.info.fiscal_id.replaceAll("-", ""));

    billingInfo.info.name = data.full_response.datosGenerales?.razonSocial ?? data?.name ?? "";

    billingInfo.info.vat_id = data.vat_id;

    selectedvatId.value = vatIdsCategories.value.find((item) => item.key === data.vat_id);
    dataAutocompleted.value = true;
  } catch (e) {
    dataAutocompleted.value = false;
  } finally {
    loadingData.value = false;
  }
};

// Test formato CUIT / CUIL
const testFiscalId = (value: string) => {
  const regex = new RegExp(/\b(20|23|24|27|30|33|34)-[0-9]{8}-[0-9]/g);
  return regex.test(value);
};

// Test CUIT / CUIL real
const testCuitVerifDigit = (cuitFormatted: string) => {
  const cuit_nro = cuitFormatted.replaceAll("-", "");
  let rv = false;
  let resultado = 0;
  const codes = "6789456789";
  let verificador = parseInt(cuit_nro[cuit_nro.length - 1]);
  let x = 0;

  while (x < 10) {
    let digitoValidador = parseInt(codes.substring(x, x + 1));
    if (isNaN(digitoValidador)) digitoValidador = 0;
    let digito = parseInt(cuit_nro.substring(x, x + 1));
    if (isNaN(digito)) digito = 0;
    let digitoValidacion = digitoValidador * digito;
    resultado += digitoValidacion;
    x++;
  }
  resultado = resultado % 11;
  rv = resultado == verificador;
  return rv;
};

const validationRules = {
  country: { required },
  email: { required, email },
  info: {
    fiscal_id: {
      required,
      testFiscalId,
      testCuitVerifDigit
    },
    vat_id: {
      required
    },
    name: {
      required
    }
  }
};

const v$ = useVuelidate(validationRules, billingInfo);

const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const getFiscalIdErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidFiscalId");
};

const openChat = () => {
  Intercom.showNewMessage(t("helpMessage.consumidor_final"));
};

// Reset de validation vuelidate
watch(
  () => billingInfo,
  (newValue, oldValue) => {
    for (const key in newValue) {
      if (newValue[key] !== oldValue[key]) {
        v$[key].$reset();
      }
    }
  },
  { deep: true }
);

// Reset de validation vuelidate
watch(
  () => cloneDeep(billingInfo.info),
  (newInfo, oldInfo) => {
    for (const key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        v$.value.info?.[key]?.$reset();
      }
    }
  },
  { deep: true }
);

const loadingSave = ref(false);

const save = async () => {
  const validation = await v$.value.$validate();
  if (!validation) return;

  loadingSave.value = true;

  const res = await billingInfoService.save({
    country: "ar",
    email: billingInfo.email,
    fiscal_id: billingInfo.info.fiscal_id ?? "",
    fiscal_id_clean: billingInfo.info.fiscal_id.replaceAll("-", ""),
    name: billingInfo.info.name,
    vat_id: billingInfo.info.vat_id
  });

  loadingSave.value = false;

  if (res.isErr()) {
    notify({ title: t("saveError.title"), text: t("saveError.text"), theme: "error" });
    return;
  }

  notify({ title: t("notify.success") });
  updateBillingData(billingInfo);
  emit("close");
};

const fillBillingInfo = (data: ArgentinaBillingInfoData) => {
  billingInfo.email = data.email;
  if (data.country !== "ar") return;

  billingInfo.info.fiscal_id = data.info.fiscal_id ?? "";
  billingInfo.info.fiscal_id_clean = data.info.fiscal_id_clean ?? "";
  billingInfo.info.name = data.info.name ?? "";
  selectedvatId.value = vatIdsCategories.value.find((item) => item.key === data.info.vat_id);
};

watch(
  () => props.billingData,
  () => {
    dataAutocompleted.value =
      !!props.billingData?.info?.fiscal_id && !!props.billingData?.info?.name && !!props.billingData?.info?.vat_id;
    fillBillingInfo(props.billingData);
  },
  { deep: true }
);
</script>

<i18n lang="json">
{
  "es": {
    "close": "Cancelar",
    "save": "Guardar cambios",
    "emailLabel": "Email administrativo",
    "fiscalIdLabel": "CUIT o CUIL",
    "legalNameLabel": "Nombre / Razón social",
    "legalNamePlaceholder": "Compañia S.R.L.",
    "fieldRequired": "Campo requerido.",
    "invalidFiscalId": "El CUIT o CUIL es inválido. Utiliza formato 00-00000000-0",
    "invalidEmail": "El email ingresado es inválido.",
    "vatIds": {
      "responsable_monotributo": "Responsable monotributo",
      "consumidor_final": "Consumidor final",
      "iva_exento": "IVA exento",
      "responsable_inscripto": "Responsable inscripto"
    },
    "vatIdLabel": "Condición fiscal",
    "vatIdAlerts": {
      "consumidor_final": "De acuerdo con la RG AFIP N° 2126/2006, al ser sujeto no categorizado, debemos realizar una Percepción de IVA del 10,5% adicional al monto de tu plan. Contactanos por chat para mayor información"
    },
    "notify": {
      "success": "Información actualizada con éxito"
    },
    "saveError": {
      "title": "Error",
      "text": "No se pudieron actualizar los datos de facturación."
    }
  },
  "pt": {
    "close": "Cancelar",
    "save": "Salvar alterações",
    "emailLabel": "E-mail administrativo",
    "fiscalIdLabel": "CUIT ou CUIL",
    "legalNameLabel": "Nome / Razão social",
    "legalNamePlaceholder": "Empresa S.A.",
    "fieldRequired": "Campo obrigatório",
    "invalidFiscalId": "O CUIT ou CUIL é inválido. Utiliza formato 00-00000000-0",
    "invalidEmail": "O e-mail inserido é inválido",
    "vatIds": {
      "responsable_monotributo": "Responsável pelo monotributo",
      "consumidor_final": "Consumidor final",
      "iva_exento": "Isento de IVA",
      "responsable_inscripto": "Responsável inscrito"
    },
    "vatIdLabel": "Condição fiscal",
    "vatIdAlerts": {
      "consumidor_final": "De acordo com a RG AFIP Nº 2126/2006, sendo um sujeito não categorizado, devemos realizar uma Percepção de IVA de 10,5% adicional ao valor do seu plano. Contate-nos pelo chat para mais informações."
    },
    "notify": {
      "success": "Informações atualizadas com sucesso"
    },
    "saveError": {
      "title": "Erro",
      "text": "Não foi possível atualizar os dados de faturamento."
    }
  }
}
</i18n>
