<template>
  <div class="group relative">
    <div class="font-medium">
      <button
        class="focus:outline-none cursor-default rounded-md bg-gray-100 px-2.5 py-2 text-gray-400 group-hover:bg-sky-100 group-hover:text-sky-400 sm:text-sm"
      >
        <CursorClickIcon class="h-5 w-5" />
      </button>
    </div>
    <div
      class="border-1 bg-white-500 invisible absolute right-0 z-10 mt-0 w-max space-y-3 rounded-lg border-gray-50 bg-white p-6 text-sm text-gray-500 opacity-0 shadow-md transition-opacity duration-100 group-hover:visible group-hover:opacity-100"
    >
      <span class="font-medium text-gray-900">{{ t("monitoringTitle") }}</span>
      <div class="flex w-full items-center space-x-2">
        <CheckCircleIcon v-if="monitorOpenings" class="h-5 w-5 text-green-400" />
        <XCircleIcon v-else class="h-5 w-5 text-gray-300" />
        <span :class="monitorOpenings ? 'font-medium' : 'font-normal'">{{
          monitorOpenings ? t("monitorOpeningsActive") : t("monitorOpeningsActive")
        }}</span>
      </div>
      <div class="flex w-full items-center space-x-2">
        <CheckCircleIcon v-if="monitorClicks" class="h-5 w-5 text-green-400" />
        <XCircleIcon v-else class="h-5 w-5 text-gray-300" />
        <span :class="monitorClicks ? 'font-medium' : 'font-normal'">{{
          monitorClicks ? t("monitorClicksActive") : t("monitorClicksInactive")
        }}</span>
      </div>
      <div>
        <div class="flex w-full items-start space-x-2">
          <CheckCircleIcon v-if="trackAnalytics" class="h-5 w-5 text-green-400" />
          <XCircleIcon v-else class="h-5 w-5 text-gray-300" />
          <div class="flex flex-col">
            <span :class="trackAnalytics ? 'font-medium' : 'font-normal'">{{
              trackAnalytics ? t("trackAnalyticsActive") : t("trackAnalyticsInactive")
            }}</span>
            <span v-if="trackAnalytics && campaignName" class="font-normal text-gray-400">{{ campaignName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
//Icons
import { CursorClickIcon } from "@heroicons/vue/outline";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";

//I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

//Props
withDefaults(
  defineProps<{
    monitorOpenings?: boolean;
    monitorClicks?: boolean;
    trackAnalytics?: boolean;
    campaignName?: string;
  }>(),
  {
    monitorOpenings: undefined,
    monitorClicks: undefined,
    trackAnalytics: undefined,
    campaignName: undefined,
  }
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "monitoringTitle": "Monitoreo",
    "monitorOpeningsActive": "Monitoreo de aperturas activado",
    "monitorOpeningsInactive": "Monitoreo de aperturas desactivado",
    "monitorClicksActive": "Monitoreo de clicks activado",
    "monitorClicksInactive": "Monitoreo de clicks desactivado",
    "trackAnalyticsActive": "Google Analytics activado",
    "trackAnalyticsInactive": "Google Analytics desactivado"
  },
  "pt": {
    "monitoringTitle": "Monitoramento",
    "monitorOpeningsActive": "Monitoramento de aberturas ativado",
    "monitorOpeningsInactive": "Monitoramento de aberturas desativado",
    "monitorClicksActive": "Monitoramento de cliques ativado",
    "monitorClicksInactive": "Monitoramento de cliques desativado",
    "trackAnalyticsActive": "Google Analytics ativado",
    "trackAnalyticsInactive": "Google Analytics desativado"
  }
}
</i18n>
