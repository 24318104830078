<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div class="mt-6">
      <Chart :options="chartOptions" :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, toRef, inject } from "vue";
import Chart from "@molecules/Chart.vue";
import { useI18n } from "vue-i18n";

import { DashboardData } from "@domain/dashboard/dashboard";
import {
  useCharts,
  CategoryResolvers,
  Accumulator,
  ChartPresets,
  sumIfCurrent,
  sumIfPrevious,
} from "@composables/charts";

interface IChartPoint {
  current: {
    total: { visits: number; visitors: number };
    identified: { visits: number; visitors: number };
  };
  previous: {
    total: { visits: number; visitors: number };
    identified: { visits: number; visitors: number };
  };
}

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const { t, n, d } = useI18n();

const newChartPoint = (): IChartPoint => ({
  current: {
    total: { visits: 0, visitors: 0 },
    identified: { visits: 0, visitors: 0 },
  },
  previous: {
    total: { visits: 0, visitors: 0 },
    identified: { visits: 0, visitors: 0 },
  },
});

const accumulate: Accumulator<IChartPoint> = (acc, newData, periodType) => {
  return {
    current: {
      total: {
        visits: sumIfCurrent(periodType, acc.current.total.visits, newData.web_track_sessions.visits_total),
        visitors: sumIfCurrent(periodType, acc.current.total.visitors, newData.web_track_sessions.visitors_total),
      },
      identified: {
        visits: sumIfCurrent(periodType, acc.current.identified.visits, newData.web_track_sessions.visits_identified),
        visitors: sumIfCurrent(
          periodType,
          acc.current.identified.visitors,
          newData.web_track_sessions.visitors_identified
        ),
      },
    },
    previous: {
      total: {
        visits: sumIfPrevious(periodType, acc.previous.total.visits, newData.web_track_sessions.visits_total),
        visitors: sumIfPrevious(periodType, acc.previous.total.visitors, newData.web_track_sessions.visitors_total),
      },
      identified: {
        visits: sumIfPrevious(periodType, acc.previous.identified.visits, newData.web_track_sessions.visits_identified),
        visitors: sumIfPrevious(
          periodType,
          acc.previous.identified.visitors,
          newData.web_track_sessions.visitors_identified
        ),
      },
    },
  };
};

const { chartData } = useCharts(
  toRef(props, "data"),
  CategoryResolvers.daily,
  accumulate,
  newChartPoint,
  false,
  (date) => (props.data?.period.isWithinSameYear() ? d(date, "short") : d(date, "shortWithYear"))
);

const plotOptions = pdfMode
  ? {
      ...ChartPresets.plotOptions,
      chart: {
        animation: false,
      },
      series: {
        ...ChartPresets.plotOptions.series,
        animation: false,
        marker: { symbol: "circle", radius: 2 },
      },
      drilldown: {
        animation: false,
      },
    }
  : {
      ...ChartPresets.plotOptions,
      series: {
        ...ChartPresets.plotOptions.series,
        marker: {
          symbol: "circle",
          radius: 2,
        },
      },
    };

const chartOptions = computed(() => {
  const options: any = {
    chart: {
      height: 280,
      width: pdfMode ? 1000 : null,
    },
    title: "",
    tooltip: ChartPresets.tooltip((val) => n(val, "decimal")),
    plotOptions: plotOptions,
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      ...ChartPresets.xAxis,
      categories: chartData.value && Object.keys(chartData.value),
    },
    credits: false,
    legend: {
      ...ChartPresets.legend,
    },
    series: [
      {
        name: t("totalVisits"),
        id: "total",
        index: 1,
        color: ChartPresets.colors.blue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.total.visits),
        type: "spline",
      },
      {
        name: t("identifiedVisits"),
        id: "identified",
        index: 2,
        color: ChartPresets.colors.darkBlue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.current.identified.visits),
        type: "spline",
      },
    ],
  };

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "totalVisits": "Totales",
    "identifiedVisits": "Identificadas"
  },
  "pt": {
    "totalVisits": "Totais",
    "identifiedVisits": "Identificadas"
  }
}
</i18n>
