<template>
  <div class="w-full">
    <FormGroup :id="id" :label="label" :hint="error ?? hint" :error="error">
      <SelectMenu
        :id="id"
        :selected="selected"
        :data-items="dataItems"
        :show-key-value="showKeyValue"
        :disabled="disabled"
        :loading="loading"
        :rem-height="remHeight"
        :error="!!error"
        @update:selected="UpdateSelected"
      />
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import type { RenderFunction } from "vue";
import { v4 as uuid } from "uuid";

//Components
import SelectMenu from "./SelectMenu.vue";
import FormGroup from "@molecules/FormGroup.vue";

//Type
export interface DataItem<DataType = unknown, keyType = string, valueType = string> {
  key: keyType;
  keyText?: string;
  value: valueType;
  data?: DataType;
}

export type DataItems<DataType = unknown, keyType = string, valueType = string> = Array<
  DataItem<DataType, keyType, valueType>
>;

//Props
withDefaults(
  defineProps<{
    id?: string;
    selected?: DataItem;
    dataItems: DataItems;
    label?: string;
    hint?: string;
    showKeyValue?: boolean;
    disabled?: boolean;
    remHeight?: number;
    loading?: boolean;
    icon?: RenderFunction;
    error?: string;
  }>(),
  {
    id: uuid(),
    selected: undefined,
    dataItems: () => [],
    label: "",
    hint: "",
    showKeyValue: false,
    disabled: false,
    remHeight: undefined,
    loading: false,
    icon: undefined,
    error: undefined,
  },
);

//Emits
const emit = defineEmits<{
  "update:selected": [DataItem];
}>();

const UpdateSelected = (value: DataItem) => emit("update:selected", value);
</script>
