import { useRequestsAPIV3, usePagingRequestsAPIV3 } from "@api/requests";
import { getI18nInstance } from "@locales/i18n";
import { useNotifications } from "@composables/notifications";
import {
  Rule,
  GetRulesRequest,
  GetRulesAsyncGenerator,
  GetRulesResponse,
  GetRulesParams,
  DeleteRuleRequest,
  DeleteRuleResponse,
  CreateRuleRequest,
  CreateRuleResponse,
  UpdateRuleRequest,
  UpdateRuleResponse,
} from "./optinrules.types";
import esMessages from "./i18n/optinrules.es.json";
import ptMessages from "./i18n/optinrules.pt.json";

export function useOptinRules() {
  const request = useRequestsAPIV3();
  const requestPaging = usePagingRequestsAPIV3();
  const { notify } = useNotifications();
  const { t, mergeLocaleMessage } = getI18nInstance().global;

  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", ptMessages);

  async function* getRules(options?: GetRulesRequest): GetRulesAsyncGenerator {
    try {
      const requestGen = await requestPaging<GetRulesResponse, unknown, GetRulesParams>(
        {
          url: `/optinrules`,
          urlScroll: `/optinrules/scroll`,
          method: "get",
          urlParams: {
            "filters.name": options?.name !== undefined ? options?.name : undefined,
            "filters.optin_id": options?.optin_id !== undefined ? options?.optin_id : undefined,
          },
        },
        { limit: 50, offset: 0 }
      );

      yield* requestGen as GetRulesAsyncGenerator;
    } catch (e) {
      notify({
        title: t("getRules.errorTitle"),
        text: t("getRules.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  async function deleteRule({ id }: DeleteRuleRequest): Promise<unknown> {
    try {
      const res = await request<DeleteRuleResponse>({
        url: `/optinrules/${id}`,
        method: "delete",
      });

      return res.data.data;
    } catch (e) {
      notify({
        title: t("deleteRule.errorTitle"),
        text: t("deleteRule.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  async function createRule(rule: CreateRuleRequest): Promise<Rule> {
    try {
      const res = await request<CreateRuleResponse>({
        url: `/optinrules`,
        method: "post",
        data: rule,
      });

      return res.data.data;
    } catch (e) {
      notify({
        title: t("createRule.errorTitle"),
        text: t("createRule.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  async function updateRule({ id, rule }: UpdateRuleRequest): Promise<Rule> {
    try {
      const res = await request<UpdateRuleResponse>({
        url: `/optinrules/${id}`,
        method: "put",
        data: rule,
      });

      return res.data.data;
    } catch (e) {
      notify({
        title: t("updateRule.errorTitle"),
        text: t("updateRule.errorMessage"),
        theme: "error",
      });
      throw e;
    }
  }

  return { getRules, deleteRule, createRule, updateRule };
}
