// Modules
import { getInitConfig, audit, applyAuditValidations, setCustomBlocksHandlers, setupToolValidators, setupTemplateValidator } from "./modules";

// Utils
import { ok, err } from "neverthrow";
import type { Result, Err } from "neverthrow";

//Types
import type { ExportTemplateErrors, ExportTemplateResponse, UnlayerApplication } from "./unlayer.types";

//I18n
import { getI18nInstance } from "@locales/i18n";
import esMessages from "./i18n/unlayer.es.json";
import ptMessages from "./i18n/unlayer.pt.json";

const { mergeLocaleMessage } = getI18nInstance().global;

mergeLocaleMessage("es", esMessages);
mergeLocaleMessage("pt", ptMessages);

// TODO: Mover a application y terminar de refactorizar según clean architecture
// ? Cada useUnlayer podria ser una instancia del editor

export const useUnlayer = (): UnlayerApplication => {
  const unlayerApplication: UnlayerApplication = {
    getGlobalInstance: () => window.unlayer,
    getInitConfig,
    setCustomBlocksHandlers,
    createInstance: async ({ editorId, templateFonts }) => {
      try {
        const unlayer = unlayerApplication.getGlobalInstance();
        const formattedEditorId = editorId.slice(1); // Removes #
        const unlayerConfig = await unlayerApplication.getInitConfig({ editorId: formattedEditorId, templateFonts });
        const instance = await unlayer.createEditor(unlayerConfig);

        unlayerApplication.setCustomBlocksHandlers({ unlayerInstance: instance });
        
        console.log('createInstance')
        // setupToolValidators({ unlayerInstance: instance });
        // setupTemplateValidator({ unlayerInstance: instance });

        return instance;
      } catch (e) {
        throw new Error("Error initializing the editor", { cause: e });
      }
    },
    loadTemplate: ({ unlayerInstance, templateJSON }) => {
      unlayerInstance.loadDesign(templateJSON);
    },
    switchToContentTab: ({ editorId }) => {
      const unlayerIframe = document.querySelector(`${editorId} > iframe`) as HTMLIFrameElement;
      if (!unlayerIframe) return;

      unlayerIframe.contentWindow?.postMessage(
        {
          action: "switchToContentTab",
        },
        "*",
      );
    },
    setBodyValues: ({ unlayerInstance, values }) => {
      const preheaderText = (values.preheader || "") + " ${open.track}";

      const bodyValues = {
        preheaderText: preheaderText,
        ...(values.contentWidth !== undefined ? { contentWidth: values.contentWidth } : null),
      };

      unlayerInstance.setBodyValues(bodyValues);
    },
    setBlank: ({ unlayerInstance, backgroundColor }) => {
      if (!backgroundColor) return unlayerInstance.loadBlank();
      unlayerInstance.loadBlank({ backgroundColor });
    },
    setDisplayConditions: ({ unlayerInstance, conditions }) => {
      unlayerInstance.setDisplayConditions(conditions);
    },
    exportHTML: ({ unlayerInstance, options }): Promise<UnlayerExportHTMLDataParams> =>
      new Promise((resolve, reject) => {
        unlayerInstance.exportHtml(
          (data) => {
            if (!data.design && !data.html) reject(data);

            resolve(data);
          },
          {
            ...options,
            minify: options?.minify ?? true,
          },
        );
      }),
    exportText: ({ unlayerInstance, options }): Promise<UnlayerExportTextDataParams> =>
      new Promise((resolve, reject) => {
        unlayerInstance.exportPlainText(
          (data) => {
            if (data?.text === undefined) reject(data);

            resolve(data);
          },
          {
            ...options,
            ignoreLinks: options?.ignoreLinks ?? true,
            ignoreImages: options?.ignoreImages ?? true,
            ignorePreheader: options?.ignorePreheader ?? true,
          },
        );
      }),
    exportTemplate: async ({ unlayerInstance, options }) => {
      let timeExportHTMLStart = 0;
      let timeExportHTMLEnd = 0;
      let timeExportTextStart = 0;
      let timeExportTextEnd = 0;

      const exportTemplateFn = async (): Promise<Result<ExportTemplateResponse, ExportTemplateErrors>> => {
        try {
          timeExportHTMLStart = performance.now();
          const exportHTML = await unlayerApplication.exportHTML({ unlayerInstance, options: options?.hmtl });
          timeExportHTMLEnd = performance.now();

          let exportText: UnlayerExportTextDataParams | undefined = undefined;
          if (options?.exportText !== false) {
            timeExportTextStart = performance.now();
            exportText = await unlayerApplication.exportText({ unlayerInstance, options: options?.text });
            timeExportTextEnd = performance.now();
          }

          return ok({
            html: exportHTML?.html ?? "",
            json: exportHTML?.design ? JSON.stringify(exportHTML.design) : "",
            text: exportText?.text ?? "",
            timeExportHTMLStart: timeExportHTMLStart,
            timeExportHTMLEnd: timeExportHTMLEnd,
            timeExportTextStart: timeExportTextStart,
            timeExportTextEnd: timeExportTextEnd,
          });
        } catch (e) {
          return err({
            type: "ERROR",
          });
        }
      };
      if (!options?.timeOutTime) {
        return await exportTemplateFn();
      }

      let exportTimeout;

      const timeOutExport = options?.timeOutTime;

      const exportTemplatePromise = new Promise<Err<ExportTemplateResponse, ExportTemplateErrors>>((resolve) => {
        exportTimeout = setTimeout(() => {
          resolve(
            err({
              type: "TIMEOUT",
              times: {
                timeExportHTMLStart,
                timeExportHTMLEnd,
                timeExportTextStart,
                timeExportTextEnd,
              },
            }),
          );
        }, timeOutExport);
      });

      const response = await Promise.race([exportTemplateFn(), exportTemplatePromise]);
      if (exportTimeout) {
        clearTimeout(exportTimeout);
      }

      return response;
    },
    audit,
    applyAuditValidations,
    setupToolValidators,
    setupTemplateValidator,
    downloadExportedTemplate: async ({ unlayerInstance, fileName }) => {
      const exportHTML = await unlayerApplication.exportHTML({ unlayerInstance: unlayerInstance });
      const jsonTemplate = exportHTML.design;

      const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(jsonTemplate));
      const link = document.createElement("a");
      link.setAttribute("href", dataStr);
      link.setAttribute("download", `${fileName}.json`);
      link.click();
      link.remove();
    },
    sendCloseModalsEvent: ({ editorId }) => {
      const unlayerIframe = document.querySelector(`${editorId} > iframe`) as HTMLIFrameElement;

      if (!unlayerIframe) return;
      unlayerIframe.contentWindow?.postMessage(
        {
          action: "closeModal",
        },
        "*",
      );
    },
    replaceBrokenImagesUrls: ({ editorId }) => {
      const unlayerIframe = document.querySelector(`${editorId} > iframe`) as HTMLIFrameElement;

      if (!unlayerIframe) return;
      unlayerIframe.contentWindow?.postMessage(
        {
          action: "replaceBrokenImagesUrls",
        },
        "*",
      );
    },
    sendData: ({ session, accountFeatures }) => {
      const unlayerIframe = document.querySelector("#editor > iframe") as HTMLIFrameElement;
      unlayerIframe.contentWindow?.postMessage(
        {
          action: "setAppData",
          appData: {
            features: accountFeatures,
            sessionData: session,
            config: window.config,
          },
        },
        "*",
      );
    },
  };
  return unlayerApplication;
};
