import { Ref } from "vue";
import axios from "axios";
import type { AxiosHeaders, GenericAbortSignal } from "axios";

// import { getI18nInstance, locales } from "@locales/i18n";
import { getI18nInstance } from "@locales/i18n";
import { useNotifications } from "@composables/notifications";

//Types
import type {
  SuccessAPIOffsetPaging,
  SuccessAPIBaseIDPaging,
  SuccessAPILegacyPaging,
  SuccessAPIBase,
} from "@api/models/requestWrappers";
import type { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";

/**
TODO:
- requests sin auth (hace falta? que todos tengan auth si esta seteado y sino no)
- cancel request? en onmmount?
DONE - agarrar error timeout y notificar
DONE - poder agarrar errores 400 opcionalmente si al componente no le interesa
- report a bugsnag
- Unificar el formato de los requesters para generar instancias con la misma configuración
 */

const instance = axios.create({
  timeout: 20000,
});

export function setBaseURL(url: string) {
  instance.defaults.baseURL = url;
}

export function setLang(lang: string) {
  instance.defaults.headers.common["Accept-Language"] = lang;
}

export function setAuth(token: string) {
  instance.defaults.headers.common["X-Auth-Token"] = token;
}

let currentAccount = "";
export function setAccount(account: string) {
  currentAccount = account;
}

let error401 = false;

type RequestMethod = "get" | "post" | "put" | "delete";

export interface RequestOptions<dataType = unknown, urlParamsType = unknown> {
  url: string;
  urlScroll?: string;
  account?: string;
  method: RequestMethod;
  data?: dataType;
  urlParams?: urlParamsType & Record<string, unknown>;
  loadingRef?: Ref<boolean>;
  disableAccountPrefix?: boolean; //TODO: invertir asi el default es falsy
  disableNotify400?: boolean;
  disableNotifyErrors?: boolean;
  allow401Error?: boolean;
  axiosRequestConfig?: AxiosRequestConfig;
  headers?: Record<string, unknown>;
  signal?: GenericAbortSignal;
}

export type Requestor = <returnType = any, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptions<dataType, urlParamsType>,
) => Promise<returnType>;

export function useRequests<returnType = unknown, dataType = unknown, urlParamsType = unknown>(): Requestor {
  const { notify } = useNotifications();
  const { t } = getI18nInstance().global; // TODO: esto no puede ir aca!

  return async function request<returnType = any, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptions<dataType, urlParamsType>,
  ): Promise<any> {
    if (options.loadingRef) {
      options.loadingRef.value = true;
    }

    const notifyError = (type: string) => {
      if (options.disableNotifyErrors) return;
      notify({
        title: t(`errors.${type}.title`),
        text: t(`errors.${type}.text`),
        theme: "error",
        timeout: 5000,
      });
    };

    try {
      const res = await instance.request<returnType>({
        ...options.axiosRequestConfig,
        method: options.method ?? options.axiosRequestConfig?.method,
        url:
          currentAccount && !options.disableAccountPrefix
            ? `/${options?.account || currentAccount}${options.url}`
            : options.url,
        data: options.data,
        params: options.urlParams,
        headers: {
          "x-account": currentAccount,
          ...((options.headers ?? options.axiosRequestConfig?.headers) as AxiosHeaders),
        },
        signal: options.signal,
      });
      return res.data;
    } catch (error: any) {
      if (error401) {
        error401 = false;
        if (options.allow401Error) {
          throw error;
        } else {
          return;
        }
      }

      if (error.code === "ERR_CANCELED") throw error;

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data); //  {succes: false, error: {...}}
        // console.log(error.response.status); // 400
        // console.log(error.response.headers);

        if (error.response.status == 400) {
          if (!options.disableNotify400) {
            notifyError("server");
          }
        } else if (error.response.status == 403) {
          notifyError("forbidden");
        } else if (error.response.status == 429) {
          notifyError("tooManyRequests");
        } else if (error.response.status === 401) {
          error401 = true;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // console.log("Code", error.code); // timout=> 'ECONNABORTED',
        // console.log("Error", error); // offline => 'Network Error'
        // console.log('Request', error.request);

        notifyError("network");
      } else {
        // Something happened in setting up the request that triggered an Error
        // console.log("Code", error.code);
        // console.log("Error", error); // Network Error

        notifyError("network");
      }

      throw error;
    } finally {
      if (options.loadingRef) {
        options.loadingRef.value = false;
      }
    }
  };
}

//---------- APIV3 ----------//

const instanceAPIV3 = axios.create({
  timeout: 20000,
});

export function setBaseURLAPIV3(url: string) {
  instanceAPIV3.defaults.baseURL = url;
}

export function setLangAPIV3(lang: string) {
  instanceAPIV3.defaults.headers.common["Accept-Language"] = lang;
}

export function setAuthAPIV3(token: string) {
  instanceAPIV3.defaults.headers.common["X-Auth-Token"] = token;
}

export function setAccountAPIV3(account: string) {
  instanceAPIV3.defaults.headers.common["X-Account"] = account;
}

export function setAuthKeyAPIV3(apiKey: string) {
  instanceAPIV3.defaults.headers.common["Authorization"] = `Bearer ${apiKey}`;
}

export interface RequestOptionsAPIV3<dataType = unknown, urlParamsType = unknown> {
  url: string;
  urlScroll?: string;
  method: RequestMethod;
  data?: dataType;
  urlParams?: urlParamsType & Record<string, unknown>;
  axiosRequestConfig?: AxiosRequestConfig;
  disableNotifyErrors?: boolean;
}

export interface RequestPagingParamsV1 {
  limit: number;
  offset?: number;
}

export interface RequestPagingParamsV2 {
  "filters.limit": number;
  "filters.offset"?: number;
}

export type RequestPagingParams = RequestPagingParamsV1 | RequestPagingParamsV2;

export const isRequestPagingParamsV2APIV3 = (
  options: RequestPagingParamsV1 | RequestPagingParamsV2,
): options is RequestPagingParamsV2 => {
  return (options as RequestPagingParamsV2)?.["filters.limit"] !== undefined;
};

export type RequestorAPIV3 = <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptionsAPIV3<dataType, urlParamsType>,
) => Promise<AxiosResponse<returnType>>;

export function useRequestsAPIV3(): RequestorAPIV3 {
  const { notify } = useNotifications();
  const { t } = getI18nInstance().global;

  return async function request<returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptionsAPIV3<dataType, urlParamsType>,
  ): Promise<AxiosResponse<returnType>> {
    const notifyError = (type: string) => {
      if (options.disableNotifyErrors) return;
      notify({
        title: t(`errors.${type}.title`),
        text: t(`errors.${type}.text`),
        theme: "error",
        timeout: 5000,
      });
    };

    try {
      const res = await instanceAPIV3.request<returnType>({
        ...options.axiosRequestConfig,
        method: options.method,
        url: options.url,
        data: options.data,
        params: options.urlParams,
      });
      return res;
    } catch (error: any) {
      const axiosError = error as AxiosError;
      const axiosErrorResponse = (error as AxiosError).response;

      if (!axiosErrorResponse) {
        notifyError("network");
        throw axiosError;
      }

      if (axiosErrorResponse.status == 401) {
        //TODO: ver mejora de esto
        error401 = true;
        // window.location.reload();
        throw axiosError;
      }

      if (axiosErrorResponse.status == 403) {
        notifyError("forbidden");
        throw axiosError;
      }

      if (axiosErrorResponse.status == 429) {
        notifyError("tooManyRequests");
        throw axiosError;
      }

      if (axiosErrorResponse.status >= 500) {
        notifyError("server");
        throw axiosError;
      }

      throw axiosError;
    }
  };
}

export function usePagingRequests(): <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptions<dataType, urlParamsType>,
  paging: RequestPagingParams,
  returnComplete?: boolean,
) => AsyncGenerator<
  Awaited<returnType> | SuccessAPIOffsetPaging<returnType> | SuccessAPIBaseIDPaging<returnType>,
  void,
  unknown
> {
  const request = useRequests();
  return <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptions<dataType, urlParamsType>,
    paging: RequestPagingParams,
    returnComplete?: boolean,
  ) => requestPagingGenerator<returnType>(request, options, paging, returnComplete);
}

export function usePagingRequestsDataWrapper(): <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptions<dataType, urlParamsType>,
  paging: RequestPagingParams,
) => AsyncGenerator<Awaited<SuccessAPILegacyPaging<returnType>>, void, unknown> {
  const request = useRequests();
  return <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptions<dataType, urlParamsType>,
    paging: RequestPagingParams,
  ) => requestPagingGeneratorDataWrapper<returnType>(request, options, paging);
}

export function usePagingRequestsAPIV3(): <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptionsAPIV3<dataType, urlParamsType>,
  paging: RequestPagingParams,
  returnComplete?: boolean,
) => AsyncGenerator<
  Awaited<returnType> | SuccessAPIOffsetPaging<returnType> | SuccessAPIBaseIDPaging<returnType>,
  void,
  unknown
> {
  const request = useRequestsAPIV3();
  return <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptionsAPIV3<dataType, urlParamsType>,
    paging: RequestPagingParams,
    returnComplete?: boolean,
  ) => requestPagingGenerator<returnType>(request, options, paging, returnComplete);
}

//---------- APIV3Pub ----------//
const instanceAPIV3Pub = axios.create({
  timeout: 20000,
});

export function setBaseURLAPIV3Pub(url: string) {
  instanceAPIV3Pub.defaults.baseURL = url;
}

export function useRequestsAPIV3Pub(): RequestorAPIV3 {
  const { notify } = useNotifications();
  const { t } = getI18nInstance().global;

  return async function request<returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptionsAPIV3<dataType, urlParamsType>,
  ): Promise<AxiosResponse<returnType>> {
    const notifyError = (type: string) => {
      if (options.disableNotifyErrors) return;
      notify({
        title: t(`errors.${type}.title`),
        text: t(`errors.${type}.text`),
        theme: "error",
        timeout: 5000,
      });
    };

    try {
      const res = await instanceAPIV3Pub.request<returnType>({
        ...options.axiosRequestConfig,
        method: options.method,
        url: options.url,
        data: options.data,
        params: options.urlParams,
      });
      return res;
    } catch (error: any) {
      const axiosError = error as AxiosError;
      const axiosErrorResponse = (error as AxiosError).response;

      if (!axiosErrorResponse) {
        notifyError("network");
        throw axiosError;
      }

      if (axiosErrorResponse.status == 401) {
        //TODO: ver mejora de esto
        error401 = true;
        // window.location.reload();
        throw axiosError;
      }

      if (axiosErrorResponse.status == 403) {
        notifyError("forbidden");
        throw axiosError;
      }

      if (axiosErrorResponse.status == 429) {
        notifyError("tooManyRequests");
        throw axiosError;
      }

      if (axiosErrorResponse.status >= 500) {
        notifyError("server");
        throw axiosError;
      }

      throw axiosError;
    }
  };
}

export function usePagingRequestsAPIV3Pub(): <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
  options: RequestOptionsAPIV3<dataType, urlParamsType>,
  paging: RequestPagingParams,
  returnComplete?: boolean,
) => AsyncGenerator<
  Awaited<returnType> | SuccessAPIOffsetPaging<returnType> | SuccessAPIBaseIDPaging<returnType>,
  void,
  unknown
> {
  const request = useRequestsAPIV3Pub();
  return <returnType = unknown, dataType = unknown, urlParamsType = unknown>(
    options: RequestOptionsAPIV3<dataType, urlParamsType>,
    paging: RequestPagingParams,
    returnComplete?: boolean,
  ) => requestPagingGenerator<returnType>(request, options, paging, returnComplete);
}

//---------- Helpers ----------//
export type RequestPagingOptions = Omit<RequestOptions, "urlParams"> & {
  urlParams:
    | ({ limit: number; offset: number } & Record<string, unknown>)
    | ({ "filters.limit": number; "filters.offset": number } & Record<string, unknown>);
};

export type RequestPagingOptionsAPIV3 = Omit<RequestOptionsAPIV3, "urlParams"> & {
  urlParams:
    | ({ limit: number; offset: number } & Record<string, unknown>)
    | ({ "filters.limit": number; "filters.offset": number } & Record<string, unknown>);
};

export type RequestPagingFnParam<argsType = unknown, returnType = unknown> = (
  ...args: Array<argsType>
) => Promise<SuccessAPIOffsetPaging<returnType> | SuccessAPIBaseIDPaging<returnType>>;

export const isSuccessAPIOffsetPaging = <returnType = unknown>(
  res: SuccessAPIOffsetPaging<returnType> | SuccessAPIBaseIDPaging<returnType>,
): res is SuccessAPIOffsetPaging<returnType> => {
  return (res as SuccessAPIOffsetPaging<returnType>).paging !== undefined;
};

export const isSuccessAPIOffsetPagingDataWrapper = <returnType = unknown>(
  res: SuccessAPILegacyPaging<returnType>,
): res is SuccessAPILegacyPaging<returnType> => {
  return (res as SuccessAPILegacyPaging<returnType>).data.data !== undefined;
};

export const isAxiosResponse = (
  res:
    | SuccessAPIOffsetPaging<unknown>
    | SuccessAPIBaseIDPaging<unknown>
    | SuccessAPIBase<unknown>
    | AxiosResponse<unknown>,
): res is AxiosResponse<unknown> => {
  const axiosResponseAttribute = [
    (res as AxiosResponse<unknown>).status !== undefined,
    (res as AxiosResponse<unknown>).statusText !== undefined,
    (res as AxiosResponse<unknown>).config !== undefined,
    (res as AxiosResponse<unknown>).headers !== undefined,
  ];
  return axiosResponseAttribute.every(Boolean);
};

export async function* requestPagingGenerator<returnType = unknown>(
  request: Requestor | RequestorAPIV3,
  reqOptions: RequestOptions | RequestOptionsAPIV3,
  pagingOptions: RequestPagingParams,
  returnComplete?: boolean,
) {
  const DEFAULT_OFFSET = 0;
  let finish = false;

  let options: RequestPagingOptions | RequestPagingOptionsAPIV3 | RequestOptions | RequestOptionsAPIV3 = {
    ...reqOptions,
    urlParams: reqOptions.urlParams,
  };

  const pagingVersion = isRequestPagingParamsV2APIV3(pagingOptions);

  if (pagingVersion) {
    options.urlParams = {
      ...options.urlParams,
      "filters.limit": pagingOptions["filters.limit"],
      "filters.offset": pagingOptions["filters.offset"] ?? DEFAULT_OFFSET,
    };
  } else {
    options.urlParams = {
      ...options.urlParams,
      limit: pagingOptions.limit,
      offset: pagingOptions?.offset ?? DEFAULT_OFFSET,
    };
  }

  while (pagingVersion ? options.urlParams?.["filters.offset"] === 0 : options.urlParams?.offset === 0 || !finish) {
    const response = (await request(options)) as
      | SuccessAPIOffsetPaging<returnType>
      | SuccessAPIBaseIDPaging<returnType>
      | AxiosResponse<SuccessAPIOffsetPaging<returnType>>
      | AxiosResponse<SuccessAPIBaseIDPaging<returnType>>;

    const data = isAxiosResponse(response) ? response.data : response;
    if (isSuccessAPIOffsetPaging<returnType>(data)) {
      finish = !data.paging.next;
      options.urlParams = {
        ...options.urlParams,
        offset: data.paging.offset + data.paging.limit,
      };

      if (isRequestPagingParamsV2APIV3(data.paging)) {
        options.urlParams = {
          ...options.urlParams,
          "filters.offset": data.paging["filters.offset"],
        };
      } else {
        options.urlParams = {
          ...options.urlParams,
          offset: data.paging.offset + data.paging.limit,
        };
      }
    } else {
      finish = !data.id;

      options.url = `${reqOptions.urlScroll || reqOptions.url}/${data.id}`;
      options = {
        ...options,
        urlParams: {
          ...options.urlParams,
          limit: undefined,
          offset: undefined,
          "filters.limit": undefined,
          "filters.offset": undefined,
        },
      } as RequestOptions | RequestOptionsAPIV3;
    }

    if (returnComplete) {
      yield data;
    } else {
      yield data.data;
    }
  }
}

export async function* requestPagingGeneratorDataWrapper<returnType = unknown>(
  request: Requestor,
  reqOptions: RequestOptions,
  pagingOptions: RequestPagingParams,
) {
  const DEFAULT_OFFSET = 0;
  let finish = false;

  let options: RequestPagingOptions | RequestOptions = {
    ...reqOptions,
    urlParams: reqOptions.urlParams,
  };

  const pagingVersion = isRequestPagingParamsV2APIV3(pagingOptions);

  if (pagingVersion) {
    options.urlParams = {
      ...options.urlParams,
      "filters.limit": pagingOptions["filters.limit"],
      "filters.offset": pagingOptions["filters.offset"] ?? DEFAULT_OFFSET,
    };
  } else {
    options.urlParams = {
      ...options.urlParams,
      limit: pagingOptions.limit,
      offset: pagingOptions?.offset ?? DEFAULT_OFFSET,
    };
  }

  while ((pagingVersion ? options.urlParams?.["filters.offset"] === 0 : options.urlParams?.offset === 0) || !finish) {
    const response = (await request(options)) as
      | SuccessAPILegacyPaging<returnType>
      | SuccessAPILegacyPaging<returnType>
      | AxiosResponse<SuccessAPILegacyPaging<returnType>>
      | AxiosResponse<SuccessAPILegacyPaging<returnType>>;

    const data = isAxiosResponse(response) ? response.data : response;
    finish = !data.data.id;

    options.url = `${reqOptions.urlScroll || reqOptions.url}/${data.data.id}`;
    options = {
      ...options,
      urlParams: {
        ...options.urlParams,
        limit: undefined,
        offset: undefined,
        "filters.limit": undefined,
        "filters.offset": undefined,
      },
    } as RequestOptions;

    yield data;
  }
}
