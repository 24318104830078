export type SocialNetwork = "facebook" | "tiktok" | "instagram" | "twitter" | "linkedin" | "youtube";

export const socialNetworks: SocialNetwork[] = [
  "facebook",
  "tiktok",
  "instagram",
  "twitter",
  "linkedin",
  "youtube",
];

export const isSocialNetworkUrl = (url: string) => socialNetworks.some((o) => url.includes(o));
