<template>
  <div class="space-y-6 p-6 pr-10">
    <FormGroup
      id="subject"
      :label="relation?.relationType != 'campaign_testab' ? t('subjectLabel') : t('subjectTestABLabel')"
      :hint="t('subjectHint')"
    >
      <div class="flex w-full space-x-4">
        <SubjectInput
          v-if="relation?.relationType != 'campaign_testab'"
          :model-value="subject"
          :fields="mappedFields"
          :placeholder="t('subjectPlaceholder')"
          class="w-full"
          @update:model-value="updateSubject"
        />
        <SubjectInputs
          v-else
          :model-value="subjects"
          :fields="mappedFields"
          :placeholder="t('subjectPlaceholder')"
          class="w-full"
          @update:model-value="updateSubjects"
        >
          <template v-if="allowAI" #icon-buttons="{ index }">
            <div>
              <IconButton
                :label="t('suggestSubject')"
                type="button"
                tabindex="-1"
                class="p-0"
                @click="() => suggestSubjects(index)"
              >
                <SparklesIcon />
              </IconButton>
            </div>
          </template>
        </SubjectInputs>
        <SimpleButton
          v-if="allowAI && relation?.relationType != 'campaign_testab'"
          theme="white"
          :label="t('suggest')"
          @click="suggestSubject"
        >
          <template #leading>
            <SparklesIcon class="h-5 w-5 text-gray-500" />
          </template>
          {{ t("suggest") }}
        </SimpleButton>
      </div>
    </FormGroup>
    <FormGroup v-show="templateType === 'unlayer'" id="subject" :label="t('preheaderLabel')" :hint="t('preheaderHint')">
      <div class="flex w-full space-x-4">
        <SubjectInput
          :model-value="preHeader"
          :fields="mappedFields"
          :placeholder="t('preheaderPlaceholder')"
          class="w-full"
          @update:model-value="updatePreHeader"
        />
        <SimpleButton v-if="allowAI" theme="white" :label="t('suggest')" @click="suggestPreheader">
          <template #leading>
            <SparklesIcon class="h-5 w-5 text-gray-500" />
          </template>
          {{ t("suggest") }}
        </SimpleButton>
      </div>
    </FormGroup>
    <div class="lg:mr-52 xl:mr-96 2xl:mr-[30rem]">
      <SenderSelectorMenu
        :model-value="senderId"
        @update:modelValue="updateSenderId"
        @sender-updated="updateSender"
        @sender-manager-open="onSenderManagerOpen"
        @sender-manager-close="onSenderManagerClose"
      />
    </div>
    <AISubjectsSuggester
      v-if="allowAI"
      :is-open="suggestionsModalIsOpen"
      :keywords="keywords"
      :email-content="text"
      @subjectSelected="selectSuggestion"
      @close="closeSuggestionsModal"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, toRaw } from "vue";

//Components
import IconButton from "@atoms/IconButton.vue";
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import FormGroup from "@molecules/FormGroup.vue";
import SubjectInput from "@molecules/SubjectInput.vue";
import SubjectInputs from "@molecules/SubjectInputs.vue";
import SenderSelectorMenu from "@organisms/Senders/SenderSelectorMenu.vue";
import AISubjectsSuggester from "@organisms/AISubjectsSuggester/AISubjectsSuggesterModal.vue";

// Icons
import { SparklesIcon } from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

// Application
import { useUnlayer } from "@application/unlayer/unlayer.app";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Utils
import { cloneDeep } from "lodash";

//Types
import type { Sender } from "@/vue/types/Senders";
import type { Fields } from "@/vue/api/modules/fields";
import { Relation } from "../../../../TemplateEditor.vue";
import type { AccountFeatures } from "@/vue/types/account";
import type { EditorType } from "@/vue/types/Templates";

const { t } = useI18n();
const unlayerApp = useUnlayer();
const trackingEventsService = useTrackingEvents();

//Props
const props = withDefaults(
  defineProps<{
    templateType: EditorType;
    editorInstance?: UnlayerInstance;
    senderId?: string;
    selectedSender?: Sender;
    relation?: Relation | undefined;
    subject: string;
    subjects?: Array<string>;
    preHeader: string;
    fields?: Fields;
    senderManagerIsOpen: boolean;
    accountFeatures?: AccountFeatures;
  }>(),
  {
    senderId: undefined,
    editorInstance: undefined,
    selectedSender: undefined,
    relation: undefined,
    subject: "",
    subjects: undefined,
    preHeader: "",
    fields: () => [],
    senderManagerIsOpen: false,
    accountFeatures: undefined,
  }
);
const allowAI = true;

//Emits
const emit = defineEmits<{
  (e: "update:senderId", value: string): void;
  (e: "update:selectedSender", value: Sender): void;
  (e: "update:subject", value: string): void;
  (e: "update:subjects", value: Array<string>): void;
  (e: "update:preHeader", value: string): void;
  (e: "update:senderManagerIsOpen", value: boolean): void;
  (e: "suggesterToggle", value: boolean): void;
}>();

const updateSenderId = (newSenderId) => emit("update:senderId", newSenderId);
const updateSender = (newSender) => emit("update:selectedSender", newSender);
const updateSubject = (newSubject: string) => emit("update:subject", newSubject);
const updateSubjects = (newSubjects: Array<string>) => emit("update:subjects", newSubjects);
const updatePreHeader = (newPreHeader: string) => emit("update:preHeader", newPreHeader);

const onSenderManagerOpen = () => emit("update:senderManagerIsOpen", true);
const onSenderManagerClose = () => emit("update:senderManagerIsOpen", false);

//State
const mappedFields = computed<Fields>(() => {
  const obligatoryFieldsIds = [1, 2, 3];
  const filteredFields: Fields = props.fields.filter((field) => field.custom || obligatoryFieldsIds.includes(field.id));
  const customFields: Fields = filteredFields.map((field) => {
    const newField = cloneDeep(toRaw(field));
    if (field.tag.toLowerCase() == "fname") {
      newField.tag = "first_name";
      return newField;
    }
    if (field.tag.toLowerCase() == "lname") {
      newField.tag = "last_name";
      return newField;
    }
    return field;
  });

  return customFields;
});

// AI suggestions
const inputIndex = ref(-1);
const updateInputIndex = (index: number) => (inputIndex.value = index);
const resetInputIndex = () => (inputIndex.value = -1);

const selector = ref<"subject" | "subjects" | "preheader">("subject");

const suggestionsModalIsOpen = ref(false);
const openSuggester = () => {
  emit("suggesterToggle", true);
  suggestionsModalIsOpen.value = true;
};
const closeSuggestionsModal = () => {
  emit("suggesterToggle", false);
  suggestionsModalIsOpen.value = false;
};

const keywords = ref("");
const text = ref("");

const getTemplateText = async () => {
  if (!props.editorInstance) return;
  const templateExport = await unlayerApp.exportText({ unlayerInstance: props.editorInstance });
  text.value = templateExport.text;
};

const selectSuggestion = (suggestion: string) => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLEDITOR_AI_SUBJECT_APPLY",
  });
  if (selector.value === "subject") {
    updateSubject(suggestion);
    return;
  }

  if (selector.value === "subjects") {
    const subjects = cloneDeep(toRaw(props.subjects)) as Array<string>;

    subjects.splice(inputIndex.value, 1, suggestion);
    updateSubjects(subjects);
    resetInputIndex();
    return;
  }

  updatePreHeader(suggestion);
};

const suggestSubjects = async (index: number) => {
  if (!props.subjects) return;

  updateInputIndex(index);

  selector.value = "subjects";
  keywords.value = props.subjects[index];
  await getTemplateText();
  openSuggester();
};

const suggestSubject = async () => {
  selector.value = "subject";
  keywords.value = props.subject;
  await getTemplateText();
  openSuggester();
};

const suggestPreheader = async () => {
  selector.value = "preheader";
  keywords.value = props.preHeader;
  await getTemplateText();
  openSuggester();
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "subjectLabel": "Asunto",
    "subjectTestABLabel": "Asuntos",
    "preheaderLabel": "Preheader",
    "subjectPlaceholder": "Completa el asunto con una frase llamativa que incite a abrir el email.",
    "subjectHint": "Puedes personalizar el asunto para mejorar los resultados, por ejemplo usando el nombre de tus contactos.",
    "preheaderPlaceholder": "Puedes utilizar un preheader para detallar más información, como complemento del asunto.",
    "preheaderHint": "Por lo general se muestra a continuación del asunto, en la bandeja de entrada.",
    "suggestSubject": "Obtener sugerencias",
    "suggest": "Sugerir"
  },
  "pt": {
    "subjectLabel": "Assunto",
    "subjectTestABLabel": "Assuntos",
    "preheaderLabel": "Pré-header",
    "subjectPlaceholder": "Complete o assunto com uma frase que chame a atenção e que estimule a abertura do email.",
    "subjectHint": "Você pode personalizar o assunto para melhorar os resultados usando o nome dos seus contatos, por exemplo.",
    "preheaderPlaceholder": "Você pode utilizar um pré-header para dar mais informações.",
    "preheaderHint": "Geralmente é apresentado a continuação do assunto, na caixa de entrada do contato.",
    "suggestSubject": "Obter sugestões",
    "suggest": "Sugerir"
  }
}
</i18n>
