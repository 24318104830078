<template>
  <div v-if="open">
    <input
      ref="inputRef"
      :placeholder="t('selectColor')"
      :value="filterText"
      class="focus:outline-none w-full border-b p-3 text-sm text-gray-700 placeholder:text-gray-200"
      @input="updateColorFilter"
      @keydown.stop.escape="() => onToggle()"
      @keydown.stop.space
    />
    <div v-if="filteredColors.length > 0" class="max-h-[40vh] overflow-y-auto">
      <ListboxOption
        v-for="color in filteredColors"
        :key="color.id"
        v-slot="{ active }"
        :value="color"
        as="template"
        @click="() => selectColor(color)"
      >
        <div :class="[active && 'bg-sky-100', 'flex cursor-pointer items-center space-x-3 py-2 pl-2']">
          <svg
            :style="{
              color: getColorStyle(color),
            }"
            class="h-3 w-3"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="text-sm font-medium text-gray-700">{{ color.name }}</span>
        </div>
      </ListboxOption>
    </div>
    <p v-if="filteredColors.length === 0" class="py-2 text-center text-sm font-medium text-gray-300">
      {{ t("textImproveYourSearch") }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, nextTick } from "vue";

// Utils
import { getColorStyle } from "@helpers/colors";
import { useI18n } from "vue-i18n";

// Components
import { ListboxOption } from "@headlessui/vue";

// Application
import { useColorsApplication } from "@application";

// Types
import type { Color } from "@/vue/types/colors";

const { t } = useI18n();
const colorsApp = useColorsApplication();

interface Props {
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
});

// Emits
const emit = defineEmits<{
  (e: "select", color: Color): void;
  (e: "toggle"): void;
}>();

const onToggle = () => emit("toggle");
const selectColor = (color: Color) => emit("select", color);

// Filter
const filterText = ref("");
const clearFilterText = () => (filterText.value = "");
const updateColorFilter = (ev: Event) => (filterText.value = (ev.target as HTMLInputElement).value);

const i18nColors = colorsApp.getTagsColors();
const filteredColors = computed(() =>
  i18nColors.filter((color: Color) => color.name?.toLowerCase().includes(filterText.value.toLowerCase()))
);

// Focus Handle
const inputRef = ref();

watchEffect(() => {
  if (props.open) {
    nextTick(() => {
      inputRef.value?.focus();
    });
  }
  clearFilterText();
});
</script>

<i18n lang="json">
{
  "es": {
    "textImproveYourSearch": "No hay resultados",
    "selectColor": "Selecciona un color para tu etiqueta"
  },
  "pt": {
    "textImproveYourSearch": "Sem resultados",
    "selectColor": "Selecione uma cor para sua etiqueta"
  }
}
</i18n>
