import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import RecurringTriggerModel from "./recurringtriggermodel";
import app from "@/app";

export default Model.extend({
  urlRoot: "automations",
  parse: function (automation) {
    if (_.isDefined(automation.data)) {
      var json = JSON.stringify(automation.data, null, 2);

      automation = automation.data;

      automation.json = json;

      automation.triggerDetails = {};

      /**
       * contact.interest.added.123
       * contact.interest.removed.123
       * contact.list.added.123
       * contact.list.removed.123
       * optin.confirmed
       * optin.confirmed.1
       * recurring.birthday.1
       *
       * fbleadads.contact.subscribed
       * tiendanube.order.completed
       * tiendanube.order.created
       * tiendanube.cart.created
       * tiendanube.newsletter.subscribed
       * vtex.cart.abandoned
       * vtex.order.completed
       * vtex.user.created
       * vtex.newsletter.subscribed
       */

      if (automation.trigger.includes("contact.interest") || automation.trigger.includes("contact.list")) {
        const parts = automation.trigger.split(".");
        automation.triggerDetails.entity = parts[1];
        automation.triggerDetails.type = parts[0] + "." + parts[1] + "." + parts[2];
        //automation.triggerDetails.entityType = parts[0] + '.' + parts[1] + '.' + parts[2];
        automation.triggerDetails.param = parts[3];
      } else if (automation.trigger.includes("optin.confirmed")) {
        const parts = automation.trigger.split(".");
        automation.triggerDetails.entity = "optin";
        automation.triggerDetails.type = "optin.confirmed";
        automation.triggerDetails.param = parts[2];
      } else if (automation.trigger.includes("recurring.birthday")) {
        automation.triggerDetails.type = "recurring.birthday";
        automation.triggerDetails.recurring = true;
        //automation.triggerDetails.type = automation.trigger;
        //automation.triggerDetails.param = automation.trigger.substr(automation.trigger.indexOf('.'));
        //automation.triggerDetails.readonly = true;
      } else if (automation.trigger.includes("recurring")) {
        automation.triggerDetails.type = automation.trigger;
        automation.triggerDetails.readonly = true;
        automation.triggerDetails.recurring = true;
      } else if (automation.trigger.includes("lifecycles.rfm")) {
        automation.triggerDetails.type = automation.trigger;
        automation.triggerDetails.rfm = true;
      } else if (automation.trigger.includes("custom.")) {
        automation.triggerDetails.type = 'custom';
        automation.triggerDetails.custom = true;
        automation.triggerDetails.customKey = automation.trigger.split('.')[1];
        automation.triggerDetails.readonly = true;
      } else {
        automation.triggerDetails.type = automation.trigger;
        automation.triggerDetails.readonly = true;
      }

      automation.triggerDetails.execLimit = { enabled: false };
      if (automation.options.exec_limit) {
        automation.triggerDetails.execLimit.enabled = true;
        automation.triggerDetails.execLimit.amount = parseInt(automation.options.exec_limit.every);
        if (automation.options.exec_limit.every.includes("d")) {
          automation.triggerDetails.execLimit.unit = "DAYS";
        } else if (automation.options.exec_limit.every.includes("h")) {
          automation.triggerDetails.execLimit.unit = "HOURS";
        } else if (automation.options.exec_limit.every.includes("m")) {
          automation.triggerDetails.execLimit.unit = "MINUTES";
        }
      }
    }
    return automation;
  },
  additionals: {
    froms: function () {
      return $.get("froms", { limit: 100 });
    },
    fields: function () {
      return $.get("fields", { limit: 100, "filters.custom": "1" });
    },
    plan: function () {
      return $.get("plan");
    },
    lists: function () {
      return $.get("lists", { limit: 500 });
    },
    interests: function () {
      return $.get("interests", { limit: 500 });
    },
    optins: function () {
      return $.get("optins", { limit: 500 });
    },
    integrations: function () {
      return $.get("integrations", { limit: 500 });
    },
  },
  parseAdditionals: function (additionals) {
    this.attributes.triggerDetails.paramName = this.getTriggerParamName(additionals);
    return additionals;
  },

  eventData: function () {
    return {
      id: this.id,
      name: this.attributes.name,
      trigger: this.attributes.trigger,
    };
  },

  // Enables automation
  enable: function (options) {
    return $.ajax(
      $.extend(true, options, {
        type: "PUT",
        url: "automations/" + this.id,
        contentType: "application/json",
        data: JSON.stringify({ enabled: true }),
      })
    ).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("APP_AUTOMATION_ENABLED", data, true);
      app.integrations.intercom.event("APP_AUTOMATION_ENABLED", data);
    });
  },
  // Disables automation
  pause: function (options) {
    return $.ajax(
      $.extend(true, options, {
        type: "PUT",
        url: "automations/" + this.id,
        contentType: "application/json",
        data: JSON.stringify({ enabled: false }),
      })
    ).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("APP_AUTOMATION_PAUSED", data, true);
      app.integrations.intercom.event("APP_AUTOMATION_PAUSED", data);
    });
  },

  // Disables automation
  cancel: function (options) {
    return $.ajax(
      $.extend(true, options, {
        type: "POST",
        url: "automations/" + this.id + "/cancel",
        contentType: "application/json",
      })
    ).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("APP_AUTOMATION_PURGED", data, true);
      app.integrations.intercom.event("APP_AUTOMATION_PURGED", data);
    });
  },

  updateStepData: function (index, data, comments) {
    var steps = this.attributes.steps;

    var params = steps[index].data.params;

    steps[index].data = data;

    if (params) {
      steps[index].data.params = params;
    }

    if (comments) {
      steps[index].comments = comments;
    }

    //limpio params[i].value que se le agrega para mostrar la ui de edición de parametros
    for (var i = 0; i < steps.length; i++) {
      if (steps[i].data && steps[i].data.params) {
        for (var j = 0; j < steps[i].data.params.length; j++) {
          steps[i].data.params[j].value = undefined;
        }
      }
    }

    return $.ajax({
      url: "automations/" + this.id,
      type: "PUT",
      data: JSON.stringify({ steps: steps }),
      contentType: "application/json",
    }).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("AUTOMATION_UPDATED", data, true);
      app.integrations.intercom.event("AUTOMATION_UPDATED", data);
    });
  },

  getStepData: function (index) {
    return this.attributes.steps[parseInt(index)].data;
  },

  getStepComments: function (index) {
    return this.attributes.steps[parseInt(index)].comments;
  },

  getMetrics: function () {
    return $.ajax({
      url: "automations/" + this.id + "/metrics",
      type: "GET",
    });
  },

  updateTrigger: function (trigger, exec_limit) {
    var options = this.attributes.options;
    if (exec_limit) {
      options.exec_limit = exec_limit;
    } else {
      options.exec_limit = undefined;
    }

    return $.ajax({
      url: "automations/" + this.id,
      type: "PUT",
      data: JSON.stringify({ trigger: trigger, options: options }),
      contentType: "application/json",
    }).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("AUTOMATION_UPDATED", data, true);
      app.integrations.intercom.event("AUTOMATION_UPDATED", data);
    });
  },

  updateJson: function (json, onError) {
    return $.ajax({
      url: "automations/" + this.id,
      type: "PUT",
      data: json,
      contentType: "application/json",
      error: onError,
    }).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("AUTOMATION_UPDATED", data, true);
      app.integrations.intercom.event("AUTOMATION_UPDATED", data);
    });
  },

  updateDefaultContextParams: function (params) {
    var defaultContext = this.attributes.default_context;

    defaultContext.params = defaultContext.params || {};

    for (var param in params) {
      defaultContext.params[param] = params[param];
    }

    return $.ajax({
      url: "automations/" + this.id,
      type: "PUT",
      data: JSON.stringify({ default_context: defaultContext }),
      contentType: "application/json",
    }).then(() => {
      const data = this.eventData();
      app.integrations.amplitude.event("AUTOMATION_UPDATED", data, true);
      app.integrations.intercom.event("AUTOMATION_UPDATED", data);
    });
  },

  getTriggerParamName: function (additionals) {
    var entity = this.attributes.triggerDetails.entity;
    var paramId = this.attributes.triggerDetails.param;
    var collection, item;
    if (entity === "list") {
      collection = additionals.lists;
    } else if (entity === "interest") {
      collection = additionals.interests;
    } else if (entity === "optin") {
      collection = additionals.optins;
    }
    if (collection) {
      item = collection.find(function (i) {
        return i.id == paramId;
      });
    }
    if (item) {
      return item.name;
    }
  },

  duplicate: function () {
    return $.post("automations/" + this.id + "/duplicate");
  },

  // Destroy override
  destroy: function (options) {
    var params = $.extend(true, {}, { type: "DELETE", url: this.url() }, options);

    const notifyEvent = () => {
      const data = this.eventData();
      app.integrations.amplitude.event("AUTOMATION_DELETED", data, true);
      app.integrations.intercom.event("AUTOMATION_DELETED", data);
    };

    if (this.attributes.options.recurring_trigger_id) {
      var recurringTrigger = new RecurringTriggerModel({
        id: this.attributes.options.recurring_trigger_id,
      });
      return recurringTrigger.destroy().then($.ajax(params)).then(notifyEvent);
    } else {
      return $.ajax(params).then(notifyEvent);
    }
  },
});
