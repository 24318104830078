import axios from "axios";

// Types
import type { PostalCodesService, CEPData, ViacepAPIError } from "./postalCodes.ports";

const viacepAPI = axios.create({
  timeout: 20000,
  baseURL: "https://viacep.com.br/ws/",
});

const isViacepGetCEPDataError = (res: CEPData | ViacepAPIError): res is ViacepAPIError => {
  return (res as ViacepAPIError).erro !== undefined;
};

export const usePostalCodesService = (): PostalCodesService => {
  const postalCodesService: PostalCodesService = {
    async getCEPData(params) {
      const res = await viacepAPI.request<CEPData | ViacepAPIError>({
        url: `/${params.cep}/json/`,
        method: "get",
      });

      if (isViacepGetCEPDataError(res.data)) {
        throw new Error("API Error");
      }

      return res.data;
    },
  };

  return postalCodesService;
};
