<template>
  <div class="select-none divide-y divide-gray-100">
    <div v-if="allowCustomSearch && formatSearch.length > 0" class="space-y-2 py-3">
      <span class="block px-3 text-xs text-gray-300">{{
        filter
          ? t("searchFilterListLabel", { filter: filter.text })
          : t("searchFilterListLabel", { filter: primaryFilter.text })
      }}</span>
      <ComboboxOption v-slot="{ active }" as="template" :value="{ text: formatSearch }">
        <div :class="[active && 'cursor-pointer bg-sky-100', 'flex space-x-2 p-2 pl-3']">
          <DocumentSearchIcon :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']" />
          <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
            {{ t("searchText", { text: formatSearch }) }}
          </span>
        </div>
      </ComboboxOption>
    </div>
    <div v-else-if="allowCustomSearch && formatSearch.length === 0">
      <div class="space-y-2 pb-1 pt-3">
        <span class="block px-3 text-xs text-gray-300">
          {{ filter ? t("searchFilterListLabel", { filter: filter.text }) : t("listSearchLabel") }}
        </span>
        <div class="flex select-none space-x-2 p-2 pl-3">
          <PencilIcon class="my-auto h-4 w-4 text-gray-500" />
          <span class="text-sm text-gray-800">
            {{ t("minCharSearchText") }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="showSearchList" class="max-h-80 space-y-2 overflow-auto py-3">
      <span class="block px-3 text-xs text-gray-300">{{
        filter && !allowCustomSearch ? t("searchFilterListLabel", { filter: filter.text }) : t("listSearchLabel")
      }}</span>
      <div>
        <template v-if="!filter">
          <ComboboxOption v-for="tag in filteredTags" v-slot="{ active }" :key="tag.id" as="template" :value="{ tag }">
            <div :class="[active && 'cursor-pointer bg-sky-100', 'flex items-center space-x-2 p-2 pl-3']">
              <svg
                v-if="tag.color"
                :style="{
                  color: `${tag.color}`,
                }"
                class="h-2 w-2"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>
              <TagIcon :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']" />
              <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
                {{ tag.name }}
              </span>
            </div>
          </ComboboxOption>
          <template v-for="f in filteredLists" :key="f.id">
            <ComboboxOption
              v-for="item in (f.list as DataItems)"
              v-slot="{ active }"
              :key="item.key"
              as="template"
              :value="{ item: item }"
            >
              <div :class="[active && 'cursor-pointer bg-sky-100', 'flex space-x-2 p-2 pl-3']">
                <component
                  :is="f.icon"
                  v-if="f.icon"
                  :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']"
                />
                <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
                  {{ item.value }}
                </span>
              </div>
            </ComboboxOption>
          </template>
        </template>
        <template v-else-if="filter.id === filterData.filtersRoles?.tags">
          <ComboboxOption v-for="tag in filteredTags" v-slot="{ active }" :key="tag.id" as="template" :value="{ tag }">
            <div :class="[active && 'cursor-pointer bg-sky-100', 'flex items-center space-x-2 p-2 pl-3']">
              <svg
                v-if="tag.color"
                :style="{
                  color: `${tag.color}`,
                }"
                class="h-2 w-2"
                fill="currentColor"
                viewBox="0 0 8 8"
              >
                <circle cx="4" cy="4" r="3" />
              </svg>
              <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
                {{ tag.name }}
              </span>
            </div>
          </ComboboxOption>
        </template>
        <template v-else>
          <ComboboxOption
            v-for="item in filteredFilterList"
            v-slot="{ active }"
            :key="item.key"
            as="template"
            :value="{ item: item }"
          >
            <div :class="[active && 'cursor-pointer bg-sky-100', 'flex space-x-2 p-2 pl-3']">
              <component
                :is="filter.icon"
                v-if="filter.icon"
                :class="[active && 'text-sky-700', 'my-auto h-4 w-4 text-gray-400']"
              />
              <span :class="[active && 'text-sky-700', 'truncate text-sm text-gray-700']">
                {{ item.value }}
              </span>
            </div>
          </ComboboxOption>
        </template>
      </div>
    </div>
    <div v-else-if="!allowCustomSearch">
      <div class="space-y-2 pb-1 pt-3">
        <span class="block px-3 text-xs text-gray-300">{{
          filter ? t("searchFilterListLabel", { filter: filter.text }) : t("searchLabel")
        }}</span>
        <div class="flex select-none space-x-2 p-2 pl-3">
          <ExclamationIcon class="my-auto h-4 w-4 text-gray-500" />
          <span class="text-sm text-gray-800">
            {{ t("emptySearch") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  lang="ts"
  setup
  generic="FilterIdType extends string, IdType extends string,TextType extends string,ListKeyType extends string,ListValueType extends string,ListDataType  = Record<string, unknown>"
>
import { computed, toRaw } from "vue";

// Components
import { ComboboxOption } from "@headlessui/vue";

// Icon
import { DocumentSearchIcon, ExclamationIcon, PencilIcon, TagIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Filter, FilterData, Filters } from "@domain/filters";
import { getPrimaryFilter } from "@domain/filters";
import { getFiltersListsByText, getTagsByText } from "@domain/filters";
import type { Tags } from "@domain/tag";
import { isTags } from "@domain/tag";
import type { SelectedValues } from "../FilterInput.types";
import { isSelectedValueTag } from "../FilterInput.types";
import { formatTextToSearch } from "@helpers/formatters";
import type { DataItems } from "@domain/data";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    filterData: FilterData<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>;
    selected: SelectedValues<FilterIdType, IdType, TextType, ListDataType>;
    search: string;
    filter?: Filter<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>;
  }>(),
  {
    filter: undefined,
  }
);

const formatSearch = computed(() => formatTextToSearch(props.search));

const allowCustomSearch = computed<boolean>(() => {
  if (!props.filter) {
    const primaryFilter = getPrimaryFilter(props.filterData);
    return primaryFilter.customSearch ?? !primaryFilter.list;
  }

  return props.filter.customSearch ?? !props.filter.list;
});

const primaryFilter = computed(() => getPrimaryFilter(props.filterData));

// Lists
const filteredTags = computed<Tags>(() => {
  const tags = getTagsByText(props.filterData, formatSearch.value);

  return tags.filter(
    (tag) =>
      !props.selected.some((item) => {
        if (!isSelectedValueTag(item)) return false;
        return item.tag.id === tag.id;
      })
  );
});

const filteredLists = computed<Filters<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>>(() =>
  getFiltersListsByText<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType>(
    toRaw(props.filterData),
    formatSearch.value
  )
);

const filteredFilterList = computed<DataItems<ListKeyType, ListValueType, ListDataType>>(() => {
  const list = props.filter && props.filter.list ? props.filter.list : [];
  if (isTags(list)) return [];
  const filteredList = list.filter((item) => formatTextToSearch(item.value).includes(formatSearch.value));
  return filteredList;
});

const showSearchList = computed<boolean>(() => {
  if (!props.filter) return filteredTags.value.length > 0 || filteredLists.value.length > 0;

  if (props.filter.id === props.filterData.filtersRoles?.tags) return filteredTags.value.length > 0;

  return filteredFilterList.value.length > 0;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "searchLabel": "definir busqueda",
    "searchFilterListLabel": "Buscar por {filter}",
    "listSearchLabel": "resultados",
    "searchText": "Buscar \"{text}\"",
    "emptySearch": "No hay resultados para esta búsqueda",
    "minCharSearchText": "Escribe algo para realizar una búsqueda"
  },
  "pt": {
    "searchLabel": "definir pesquisa",
    "searchFilterListLabel": "Pesquisar por {filter}",
    "listSearchLabel": "resultados",
    "searchText": "Pesquisar \"{text}\"",
    "emptySearch": "Não existem resultados para essa pesquisa",
    "minCharSearchText": "Escreva algo para pesquisar"
  }
}
</i18n>
