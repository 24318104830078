<template>
  <PageTemplate
    :title="t('title')"
    :tabs="tabs"
    :selected="selectedTab"
    :links="{
      es: {
        helpId: 8593204,
      },
      pt: {
        helpId: 8593204,
      },
      en: {
        helpId: 8593204,
      },
    }"
    @update:selected="updateSelectedTab"
  >
    <template #options>
      <div id="audience-menuOptions" />
    </template>
    <template #content>
      <div id="audience-stickyContent" />
    </template>
    <template #lists>
      <AudienceLists v-if="mounted" />
    </template>
    <template #contacts>
      <AudienceContacts v-if="mounted" />
    </template>
    <template #interests>
      <AudienceInterests v-if="mounted" />
    </template>
    <template #fields>
      <AudienceFields v-if="mounted" />
    </template>
  </PageTemplate>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

import { getTabs } from "./audience.data";
import type { AudienceTabs } from "./audience.types";

// Components
import PageTemplate from "@templates/PageTemplate";
import AudienceLists from "./components/AudienceLists";
import AudienceContacts from "./components/AudienceContacts";
import AudienceInterests from "./components/AudienceInterests";
import AudienceFields from "./components/AudienceFields";

// Type
import type { Item, Items } from "@templates/PageTemplate";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    initialTab?: AudienceTabs;
  }>(),
  {
    initialTab: "lists",
  },
);

const emit = defineEmits<{
  changeRoute: [route: string];
}>();

const tabs: Items<AudienceTabs> = getTabs();
const selectedTab = ref<Item<AudienceTabs>>(tabs.find((tab) => tab.key === props.initialTab) ?? tabs[0]);
const updateSelectedTab = (newTab: Item<AudienceTabs>) => {
  selectedTab.value = newTab;
  emit("changeRoute", newTab.key);
};
const mounted = ref(false);
onMounted(() => {
  const pathName = location.pathname;

  const tab = tabs.find((t) => pathName.includes(t.key));
  if (!tab) return;

  selectedTab.value = tab;
  mounted.value = true;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Audiencia",
    "tabs": {
      "lists": "Listas",
      "contacts": "Contactos",
      "interests": "Intereses",
      "fields": "Campos"
    }
  },
  "pt": {
    "title": "Audiência",
    "tabs": {
      "lists": "Listas",
      "contacts": "Contatos",
      "interests": "Interesses",
      "fields": "Campos"
    }
  }
}
</i18n>
