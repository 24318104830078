<template>
  <div v-if="open">
    <input
      ref="inputRef"
      :placeholder="t('placeholderInput')"
      maxlength="50"
      :value="filterText"
      :disabled="loading"
      class="w-full border-b border-gray-100 p-3 text-sm text-gray-700 placeholder:text-gray-200 focus:outline-none"
      @input="updateFilterText"
      @keydown.stop.escape="() => onToggle()"
      @keydown.stop.space
      @blur="focusInput"
    />
    <div v-if="!loading && filteredTags.length > 0" class="max-h-[40vh] overflow-y-auto">
      <ListboxOption v-for="tag in filteredSelectedTags" :key="tag.id" v-slot="{ active }" :value="tag" as="template">
        <div :class="[active && 'bg-sky-100', 'flex cursor-pointer items-center space-x-3 py-2 pl-2']">
          <CheckBox
            class="pointer-events-none focus:outline-none focus:ring-0 focus:ring-offset-0"
            :model-value="selectedTags.some((selected) => selected.id === tag.id)"
          />
          <svg
            :style="{
              color: `${tag.color}`,
            }"
            class="h-3 w-3 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="truncate text-sm font-medium text-gray-700">{{ tag.name }}</span>
        </div>
      </ListboxOption>
      <ListboxOption v-for="tag in notSelectedTags" :key="tag.id" v-slot="{ active }" :value="tag" as="template">
        <div :class="[active && 'bg-sky-100', 'flex cursor-pointer items-center space-x-3 py-2 pl-2']">
          <CheckBox
            class="pointer-events-none focus:outline-none focus:ring-0 focus:ring-offset-0"
            :model-value="selectedTags.some((selected) => selected.id === tag.id)"
          />
          <svg
            :style="{
              color: `${tag.color}`,
            }"
            class="h-3 w-3 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="3" />
          </svg>
          <span class="truncate text-sm font-medium text-gray-700">{{ tag.name }}</span>
        </div>
      </ListboxOption>
    </div>
    <SkeletonOptions v-else-if="loading" />
    <ListboxOption
      v-else-if="filterText.length < 2 || !allowCreate"
      disabled
      class="py-2 text-center text-sm font-medium text-gray-400"
    >
      {{ t("noTag") }}
    </ListboxOption>
    <ListboxOption
      v-if="allowCreate && filterText.length > 1 && !perfectMatchFilterText"
      v-slot="{ active }"
      :value="{ createTag: filterText }"
      class="p-3"
    >
      <Button theme="white" class="w-full" :selected="active">
        <template #leading> <PlusSmIcon class="h-5 w-5 text-gray-500" /> </template>
        <span class="truncate">{{ t("buttonCreateTag", { filterText }) }} </span>
      </Button>
    </ListboxOption>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect, watch } from "vue";

// Components
import SkeletonOptions from "./TagsOptionsSkeleton.vue";
import Button from "@atoms/SimpleButton.vue";
import CheckBox from "@atoms/CheckBox.vue";
import { ListboxOption } from "@headlessui/vue";

// Icons
import { PlusSmIcon } from "@heroicons/vue/solid";

// Utils
import { formatTextToSearch } from "@helpers/formatters";

// I18n
import { useI18n } from "vue-i18n";

// Types
import type { Tags, Tag } from "@/vue/types/tag";

const { t } = useI18n();

// Props
interface Props {
  selectedTags: Tags;
  tags: Tags;
  open: boolean;
  loading?: boolean;
  allowCreate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  selectedTags: () => [],
  tags: () => [],
  open: false,
  loading: false,
  allowCreate: true,
});

// Emits
const emit = defineEmits<{
  (e: "toggleTag", tag: Tag): void;
  (e: "createTag", name: string): void;
  (e: "toggle"): void;
}>();

// Filter Tags
const filterText = ref("");
const clearFilterText = () => (filterText.value = "");
const updateFilterText = (ev: Event) => {
  filterText.value = (ev.target as HTMLInputElement).value;
};

const onToggle = () => {
  clearFilterText();
  emit("toggle");
};

// Filtered Tags
const filteredTags = computed<Tags>(() => {
  if (filterText.value.length === 0) return props.tags;
  return props.tags.filter((tag) => formatTextToSearch(tag.name).includes(formatTextToSearch(filterText.value)));
});

const perfectMatchFilterText = computed(() => {
  return filteredTags.value.some(
    (tag) =>
      tag.name.toLowerCase().replaceAll(/\s+/g, " ").trim() ===
      filterText.value.toLowerCase().replaceAll(/\s+/g, " ").trim(),
  );
});

const notSelectedTags = ref<Tags>();

const filteredSelectedTags = ref<Tags>();

const calculateNotSelectedTags = () => {
  notSelectedTags.value = filteredTags.value.filter((tag) =>
    props.selectedTags.every((selected) => selected.id !== tag.id),
  );
};

const calculateSelectedTags = () => {
  filteredSelectedTags.value = filteredTags.value.filter((tag) =>
    props.selectedTags.some((selected) => selected.id === tag.id),
  );
};

watch(filteredTags, () => {
  calculateNotSelectedTags();
  calculateSelectedTags();
});

// Focus Handle
const inputRef = ref();

watch(
  () => props.open,
  () => {
    clearFilterText();
    if (!props.open) return;
    calculateNotSelectedTags();
    calculateSelectedTags();
  },
);

const focusInput = () => {
  if (!inputRef.value || !props.open) return;
  inputRef.value?.focus();
};

watchEffect(() => {
  focusInput();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderInput": "Buscar etiquetas...",
    "buttonCreateTag": "Crear etiqueta: \"{ filterText }\"",
    "noTag": "Sin etiquetas"
  },
  "pt": {
    "placeholderInput": "Pesquisar etiqueta...",
    "buttonCreateTag": "Criar etiqueta: \"{ filterText }\"",
    "noTag": "Sem tags"
  }
}
</i18n>
