import _ from "underscore";

_.mixin({
  isDefined: function (value) {
    return !_.isUndefined(value);
  },
});

/*

General Helpers

****************************************************************************/

/**
 * Helper to format an integer
 * @param {Number} number
 * @return {String}
 */
const formatInteger = function (value) {
  var separator = ".";
  if (value === null || value === undefined) {
    return 0;
  }
  if (value % 1 != 0) {
    value = value.toString().replace(".", ",");
  }
  return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
};

/**
 * Helper to round an integer
 * @param {Number} number
 * @param {Number} decimals
 * @return {String}
 */
const round = function (value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

/**
 * Helper to check a nested value in an object
 */

const objHas = function (obj /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);
  for (var i = 0; i < args.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
};

/**
 * Helper to create a pagination list
 * @param {Object} options
 * @return {String}
 */
const paginate = function (options) {
  var lastPage,
    firstItem,
    lastItem,
    center,
    beginning,
    end,
    paginator = [],
    html = "",
    i;

  // Set options
  options = $.extend(
    {
      url: "",
      itemsPerPage: 10,
      itemsTotal: 10,
      page: 1,
      offset: false,
      edges: 0,
      className: "pagination",
      addInfo: false,
      lang: {
        first: "Inicio",
        previous: "Fin",
        next: "Siguiente",
        last: "Anterior",
        to: "a",
        of: "de",
      },
    },
    options
  );

  // Calculate current page
  if (options.offset) {
    options.page = options.offset / options.itemsPerPage + 1;
  }

  // Calculate last page
  lastPage =
    options.itemsPerPage == 0
      ? 0
      : Math.ceil(options.itemsTotal / options.itemsPerPage);

  // Set center
  center = options.page;

  // Calculate last item
  lastItem = options.itemsPerPage * options.page;
  if (options.itemsTotal < options.itemsPerPage || options.page == lastPage) {
    lastItem = options.itemsTotal;
  }

  // Calculate first item
  firstItem = options.offset + 1;

  // Fix center on edges
  if (options.page < options.edges) {
    center = options.edges + 1;
  } else if (center + options.edges > lastPage) {
    center = lastPage;
  }

  // Set iterator beginning
  beginning = center - options.edges;
  if (options.page == options.edges) {
    beginning = beginning + 1;
  }

  // Set iterator end
  end = lastPage;
  if (lastPage > options.edges * 2) {
    end = center + options.edges;
  }
  if (end > lastPage) {
    end = lastPage;
    beginning = beginning - options.edges;
  }

  // First page and previous page
  if (options.page != 1) {
    paginator.push({ text: options.lang.first, number: 1, className: "first" });
    paginator.push({
      text: options.lang.previous,
      number: options.page - 1,
      className: "prev",
    });
  }

  // Numeric buttons
  for (i = beginning; i <= end; i++) {
    paginator.push({ text: i, number: i });
  }

  // Next page and last page
  if (options.page != lastPage) {
    paginator.push({
      text: options.lang.next,
      number: options.page + 1,
      className: "next",
    });
    paginator.push({
      text: options.lang.last,
      number: lastPage,
      className: "last",
    });
  }

  // Create paginator HTML
  html = '<ul class="' + options.className + '">';
  $.each(paginator, function () {
    if (options.page === this.number) {
      html =
        html +
        '<li class="active"><span>' +
        formatInteger(this.text) +
        " " +
        options.lang.of +
        " " +
        formatInteger(lastPage) +
        "</span></li>";
    } else {
      html =
        html +
        '<li class="' +
        this.className +
        '"><a href="' +
        options.url +
        this.number +
        '">' +
        this.text +
        "</a></li>";
    }
  });
  html = html + "</ul>";

  // Add paging info
  if (options.addInfo) {
    html =
      html +
      '<div class="info">' +
      formatInteger(firstItem) +
      " " +
      options.lang.to +
      " " +
      formatInteger(lastItem) +
      " " +
      options.lang.of +
      " " +
      options.itemsTotal +
      "</div>";
  }

  // Return paginator HTML
  return html;
};

const isNumeric = function (num) {
  return !isNaN(num);
};

const Sequence = function (options) {
  this.calls = options.calls;
  this.index = 0;
  this.total = options.calls.length;
  this.completed = options.completed;

  this.execute = function () {
    var call = this.calls[this.index];
    call.item[call.method](call.params).then(
      function (response) {
        call.success(response, call, this);
        this.index += 1;
        if (this.index < this.total) {
          this.execute();
        } else {
          this.completed();
        }
      }.bind(this)
    );
  };
};

const convertMS = function (ms) {
  var d, h, m, s;
  s = Math.floor(ms / 1000);
  m = Math.floor(s / 60);
  s = s % 60;
  h = Math.floor(m / 60);
  m = m % 60;
  d = Math.floor(h / 24);
  h = h % 24;
  return { d: d, h: h, m: m, s: s };
};

/**
 * Helper to remove unused code in HTML strings
 * @param {String} string
 * @return {String}
 */
const cleanHTML = function (string) {
  string = string.replace(/(\\n)+/gm, " ");
  string = string.replace(/<!--[\s\S]*?-->/gm, "");
  string = string.replace(/<(o:p|style|xml)>[\s\S]*?<\/(o:p|style|xml)>/gm, "");
  return string;
};

const isEmptyString = function (str) {
  return !str || str.trim().length <= 0;
};

export {
  _,
  formatInteger,
  round,
  objHas,
  paginate,
  isNumeric,
  Sequence,
  convertMS,
  cleanHTML,
  isEmptyString,
};
