import { ListView } from "@/core/magnet";
import app from "@/app";

export default ListView.extend({
  module: "optins",
  templates: {
    initial: require("../templates/list.hbs"),
  },
  limit: 1000,
  query: {
    order: "name asc",
    fields: "subscriptions",
  },
  sortOptions: [
    { column: "name", type: "asc", label: "name" },
    { column: "totalsubscriptions", type: "desc", label: "subscriptions" },
    {
      column: "lastmonthsubscriptions",
      type: "desc",
      label: "lastMonthSubscriptions",
    },
    {
      column: "lastweeksubscriptions",
      type: "desc",
      label: "lastWeekSubscriptions",
    },
  ],

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Send events
    self.broadcast();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Emmit events
  broadcast: function () {
    var self = this;
    if (self.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_OPTINS");
    }
  },
});
