<template>
  <div :class="[{ 'py-4': openConfirmDelete }, 'relative flex justify-between']">
    <div class="flex items-center space-x-2">
      <svg
        :style="{
          color: `${tag.color}`,
        }"
        class="h-3 w-3"
        fill="currentColor"
        viewBox="0 0 8 8"
      >
        <circle cx="4" cy="4" r="3" />
      </svg>
      <span class="text-sm text-gray-600">{{ tag.name }}</span>
      <span class="mt-1 text-xs text-gray-400">{{
        t("templatesCount", { count: templatesCount }, { plural: templatesCount })
      }}</span>
    </div>
    <div class="invisible flex space-x-1 group-hover:visible">
      <IconButton :label="t('editTagButton')" size="min" type="button" show-tooltip @click="editTag">
        <PencilIcon class="h-5 w-5" />
      </IconButton>
      <IconButton :label="t('deleteTabButton')" size="min" type="button" show-tooltip @click="confirmDelete">
        <TrashIcon class="h-5 w-5" />
      </IconButton>
    </div>
    <transition
      enter-active-class="duration-200 ease-out transform"
      enter-from-class="opacity-0 translate-x-4"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="openConfirmDelete"
        ref="confirmRef"
        class="absolute inset-0 -inset-x-2 flex justify-center bg-opacity-50 backdrop-blur-sm backdrop-filter"
      >
        <div class="flex space-x-2 self-center">
          <SimpleButton
            ref="confirmDeleteButtonRef"
            theme="danger"
            size="small"
            :loading="loadingDelete"
            @click="deleteTag()"
            >{{ t("confirmDelete", { tag: tag.name }) }}
            <template #leading>
              <TrashIcon />
            </template>
          </SimpleButton>
          <SimpleButton theme="white" size="small" @click="closeConfirmDelete">{{ t("betterNot") }}</SimpleButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from "@vueuse/core";

// Components
import IconButton from "@/vue/components/atoms/IconButton.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Icon
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Types
import type { Tag } from "@domain/tag";
import { ref } from "vue";

const { t } = useI18n();

// Props
withDefaults(
  defineProps<{
    tag: Tag;
    templatesCount?: number;
    loadingDelete?: boolean;
  }>(),
  {
    loadingDelete: false,
    templatesCount: 0,
  }
);

// Emits
const emit = defineEmits<{
  (e: "deleteTag"): void;
  (e: "editTag"): void;
  (e: "openDelete"): void;
}>();

const openConfirmDelete = ref(false);
const confirmRef = ref();

const openDelete = () => {
  emit("openDelete");
};

const confirmDelete = () => {
  openConfirmDelete.value = true;
  openDelete();
};

const closeConfirmDelete = () => {
  openConfirmDelete.value = false;
};

onClickOutside(confirmRef, closeConfirmDelete);

const deleteTag = () => {
  emit("deleteTag");
};

const editTag = () => {
  emit("editTag");
};
</script>

<i18n lang="json">
{
  "es": {
    "deleteTabButton": "Eliminar",
    "editTagButton": "Editar",
    "confirmDelete": "¿Eliminar la etiqueta \"{tag}\"?",
    "betterNot": "Mejor no",
    "templatesCount": "1 plantilla | {count} plantillas"
  },
  "pt": {
    "deleteTabButton": "Excluir",
    "editTagButton": "Editar",
    "confirmDelete": "Excluir a etiqueta \"{tag}\"?",
    "betterNot": "Melhor nã0",
    "templatesCount": "1 template | {count} templates"
  }
}
</i18n>
