var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activityOf"))) && lookupProperty(stack1,"lastQuarter")), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activityOf"))) && lookupProperty(stack1,"lastMonth")), depth0));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activityOf"))) && lookupProperty(stack1,"lastWeek")), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activityOf"))) && lookupProperty(stack1,"lastDay")), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='automations section'>\n    <div class='section-header'>\n        <h1>\n            "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":56}}}))
    + "\n        </h1>\n    </div>\n    <div class='toolbar' data-role='toolbar'>\n        <a class='btn btn-primary' data-type='none' data-action='open-create-modal'\n            data-require-permission='campaigns:create' data-intercom-target=\"CreateAutomationButton\">\n            <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"create")), depth0))
    + "\n        </a>\n        <button type='button' class='btn btn-default hide' data-action='duplicate' data-type='just-one'\n            data-require-permission='campaigns:create'>\n            "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n        </button>\n        <div class=\"btn-group\">\n            <button type='button' class='btn btn-default hide' data-action='mass' data-method='pause' data-type='one-plus'\n                data-require-permission='campaigns:create' data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massPausedM")), depth0))
    + "'>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"pause")), depth0))
    + "\n            </button>\n            <button type='button' class='btn btn-default hide' data-action='mass' data-method='cancel'  data-type='one-plus' \n                data-require-permission='campaigns:create' data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massStoppedM")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"confirmCancelMass")), depth0))
    + "'>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n            </button>\n            <button type='button' class='btn btn-danger hide' data-action='mass' data-method='destroy'\n                data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyM")), depth0))
    + "' data-type='one-plus' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n                data-require-permission='campaigns:delete'>\n                "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n            </button>\n        </div>\n        <div class='paging-selected' data-role='check-count'></div>\n    </div>\n    <div class='list-controls'>\n        <div class='check-all'>\n            <input id='check-all' type='checkbox' data-action='check-all'>\n            <label for='check-all' class='iconic'></label>\n        </div>\n        <div class='sort dropdown'>\n            <button class='btn btn-default dropdown-toggle' type='button' data-toggle='dropdown'>\n                <span>\n                    "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"timestamp") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-3M",{"name":"is","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":42,"column":86}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"timestamp") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1M",{"name":"is","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":120}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"timestamp") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1w",{"name":"is","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":44,"column":119}}})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"timestamp") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1d",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":45,"column":118}}})) != null ? stack1 : "")
    + "\n                </span>\n                <i class='drop fa fa-angle-down'></i>\n            </button>\n            <ul class='dropdown-menu'>\n                <li>\n                    <a data-action='filter' data-params='{\"key\": \"filter.timestamp.gtrel\", \"value\": \"now-3M\"}'>\n                        "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastQuarter")), depth0))
    + "\n                    </a>\n                    <a data-action='filter' data-params='{\"key\": \"filter.timestamp.gtrel\", \"value\": \"now-1M\"}'>\n                        "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastMonth")), depth0))
    + "\n                    </a>\n                    <a data-action='filter' data-params='{\"key\": \"filter.timestamp.gtrel\", \"value\": \"now-1w\"}'>\n                        "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastWeek")), depth0))
    + "\n                    </a>\n                    <a data-action='filter' data-params='{\"key\": \"filter.timestamp.gtrel\", \"value\": \"now-1d\"}'>\n                        "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastDay")), depth0))
    + "\n                    </a>\n                </li>\n            </ul>\n        </div>\n        <div class='paging-total' data-role='paging-total'></div>\n    </div>\n    <div class='content' data-role='content'>\n        <div class='list'>\n            <ul class='table' data-role='rows'></ul>\n            <div class='loading-box' data-role='items-loader'></div>\n            <button class='btn btn-primary load-more' data-action='load-more'>\n                "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n            </button>\n        </div>\n    </div>\n    <div id=\"vue-automations-catalog-modal\"></div>\n</div>";
},"useData":true});