<template>
  <div>
    <TextParagraph class="space-y-2">
      <span class="block font-medium text-gray-700">{{ t("summary.description") }}</span>
    </TextParagraph>
    <AlertBox v-show="showStats" class="mt-4">
      <template #title>
        {{ t("summary.accountInfo.label") }}
      </template>
      <ul class="ml-4 list-disc space-y-2">
        <li>
          <i18n-t keypath="summary.accountInfo.costBenefit">
            <span class="font-semibold">
              {{
                t("summary.accountInfo.benefit", {
                  salesCount: dashboardData?.store.assisted.current.count,
                  benefit: sn(dashboardData?.store.assisted.current.amount, "currency"),
                })
              }}
            </span>
          </i18n-t>
        </li>
        <li v-show="showDiscount">
          <i18n-t keypath="summary.accountInfo.discountLoss">
            <span class="font-semibold">
              {{ t("summary.accountInfo.discount", { percentage: plan.price.discount }) }}
            </span>
          </i18n-t>
        </li>
      </ul>
    </AlertBox>
    <TextParagraph v-if="showScheduleCancellation" class="mt-4 space-y-2">
      <span class="font-medium text-gray-700">{{ t("summary.toConsider.label") }}</span>
      <ul class="ml-5 list-disc space-y-1">
        <li>
          <i18n-t
            :keypath="
              showImmediateCancellation
                ? 'summary.toConsider.scheduledCancelDateImmediate'
                : 'summary.toConsider.scheduledCancelDate'
            "
          >
            <span class="font-semibold">
              {{ formatDate(subDays(new Date(plan.periodEnds), 1), "PPP") }}
            </span>
          </i18n-t>
        </li>
        <li>
          {{ t("summary.toConsider.canBeUsedUntil") }}
        </li>
        <li>
          <i18n-t keypath="summary.toConsider.ifRegret">
            <span class="font-semibold">
              {{ t("summary.toConsider.ifRequestCanCancel") }}
            </span>
          </i18n-t>
        </li>
        <li>
          {{ t("summary.toConsider.contactUsForImmedateCancelation") }}
        </li>
      </ul>
    </TextParagraph>
    <TextParagraph class="mt-4 space-y-2">
      <span class="font-medium text-gray-700">{{ t("summary.afterCancellation.label") }}</span>
      <ul class="ml-5 list-disc space-y-1">
        <li>
          <i18n-t keypath="summary.afterCancellation.noCampaigns">
            <span class="font-semibold">
              {{ t("summary.afterCancellation.campaigns") }}
            </span>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="summary.afterCancellation.noAutomations">
            <span class="font-semibold">
              {{ t("summary.afterCancellation.automations") }}
            </span>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="summary.afterCancellation.noForms">
            <span class="font-semibold">
              {{ t("summary.afterCancellation.forms") }}
            </span>
          </i18n-t>
        </li>
        <li>
          <i18n-t keypath="summary.afterCancellation.noIntegrations">
            <span class="font-semibold">
              {{ t("summary.afterCancellation.integrations") }}
            </span>
          </i18n-t>
        </li>
        <li>
          {{ t("summary.afterCancellation.accountDeletion") }}
        </li>
      </ul>
    </TextParagraph>
    <div class="mt-8 flex justify-between">
      <SimpleButton theme="white" @click="close">{{ t("buttonClose") }}</SimpleButton>
      <div class="space-x-4">
        <SimpleButton
          v-if="showImmediateCancellation || (isMasterUser && showScheduleCancellation)"
          theme="danger-alter"
          @click="immediateCancelPlan"
          ><template #leading>
            <ExclamationCircleIcon /> </template
          >{{ t("immediateCancelPlanMasterUser") }}</SimpleButton
        >
        <SimpleButton v-if="showScheduleCancellation" theme="danger" @click="scheduleCancelPlan">
          <template #leading>
            <CheckIcon />
          </template>
          {{ t("buttonCancelPlan") }}
        </SimpleButton>
        <SimpleButton v-if="!showScheduleCancellation" theme="danger" @click="immediateCancelPlan"
          ><template #leading>
            <ExclamationCircleIcon /> </template
          >{{ t("immediateCancelPlan") }}</SimpleButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { Reason } from "../planCancellation.types";

// Icons
import { CheckIcon, ExclamationCircleIcon } from "@heroicons/vue/solid";

// Components
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Utils
import { subDays, differenceInHours } from "date-fns";
import { formatDate } from "@helpers/formatters";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { DashboardData } from "@domain/dashboard/dashboard";
import { Plan } from "@domain/plan";

const { t, n } = useI18n();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value?.isMasterUser;

//Props
const props = withDefaults(
  defineProps<{
    plan: Plan;
    dashboardData?: DashboardData;
    reason: Reason;
    comment?: string;
  }>(),
  {
    comment: undefined,
    dashboardData: undefined,
  }
);

//Emits
const emit = defineEmits<{
  (e: "scheduleCancelPlan"): void;
  (e: "immediateCancelPlan"): void;
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

const scheduleCancelPlan = () => {
  emit("scheduleCancelPlan");
};
const immediateCancelPlan = () => {
  emit("immediateCancelPlan");
};

const sn = (value: number | undefined, format: string): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  n(isFinite(value!) && value ? value : 0, format);

const showStats = computed<boolean>(() => {
  // const reasonKeys: Array<ReasonKeys> = ["too_expensive", "bad_results"];
  const planCost = props.plan.price.total;
  const assistedAmount = props.dashboardData?.store.assisted.current.amount;
  const positiveIncome = assistedAmount ? Number(planCost) < Number(assistedAmount) : false;

  // return reasonKeys.includes(props.reason.key) && positiveIncome;
  return positiveIncome;
});

const showImmediateCancellation = computed(() => {
  return props.reason.key === "plan_change";
});
const showScheduleCancellation = computed(() => {
  return Math.abs(differenceInHours(new Date(), subDays(new Date(props.plan.periodEnds), 1)) / 24) > 2;
});

const showDiscount = computed<boolean>(() => {
  return props.plan.price.discount > 0 && props.reason.key !== "plan_change";
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "summary": {
      "description": "Estás a punto de cancelar tu suscripción.",
      "accountInfo": {
        "label": "¿Sabías que?",
        "costBenefit": "En los últimos 30 días, con la ayuda de Perfit, lograste {0}.",
        "benefit": "{salesCount} ventas por {benefit}",
        "discountLoss": "Actualmente tienes un {0}, al cancelar tu suscripción lamentablemente no podrás aplicarlo nuevamente si decides volver.",
        "discount": "descuento del {percentage}%"
      },
      "toConsider": {
        "label": "Ten en cuenta que:",
        "scheduledCancelDate": "La baja se efectivizará el día {0}.",
        "scheduledCancelDateImmediate": "La baja se efectivizará el día {0}, excepto que decidas cancelar en forma inmediata.",
        "canBeUsedUntil": "Podrás seguir usando Perfit hasta ese día.",
        "ifRegret": "Si te arrepientes antes, {0}.",
        "ifRequestCanCancel": "puedes cancelar la baja",
        "contactUsForImmedateCancelation": "Si precisas realizar la baja en forma inmediata contáctanos."
      },
      "afterCancellation": {
        "label": "Una vez efectivizada la baja:",
        "noCampaigns": "No podrás enviar nuevas {0}.",
        "campaigns": "Campañas",
        "noAutomations": "Los {0} activos serán pausados.",
        "automations": "Automations",
        "noForms": "Los {0} sin código serán apagados.",
        "forms": "Formularios",
        "noIntegrations": "Todas las {0} serán desactivadas.",
        "integrations": "Integraciones",
        "accountDeletion": "Luego de 6 meses de inactividad, todos los datos serán eliminados."
      }
    },
    "immediateCancelPlanMasterUser": "Cancelar en forma inmediata",
    "immediateCancelPlan": "Cancelar en forma inmediata",
    "buttonCancelPlan": "Cancelar suscripción",
    "buttonClose": "Mejor no"
  },
  "pt": {
    "summary": {
      "description": "Você está prestes a cancelar sua assinatura.",
      "accountInfo": {
        "label": "Você sabia que?",
        "costBenefit": "Nos últimos 30 dias, com a ajuda da Perfit, você alcançou {0}.",
        "benefit": "{salesCount} vendas por {benefit}",
        "discountLoss": "Atualmente você tem um {0}, ao cancelar sua assinatura, infelizmente não poderá aplicá-lo novamente se decidir voltar.",
        "discount": "desconto de {percentage}%"
      },
      "toConsider": {
        "label": "Tenha em mente que",
        "scheduledCancelDate": "O cancelamento será efetivado no dia {0}",
        "scheduledCancelDateImmediate": "La baja se efectivizará el día {0}, a menos que você decida cancelar imediatamente.",
        "canBeUsedUntil": "Você poderá continuar usando a Perfit até esse dia.",
        "ifRegret": "Se você se arrepender antes, {0}.",
        "ifRequestCanCancel": "pode anular o cancelamento",
        "contactUsForImmedateCancelation": "Se precisares fazer o cancelamento imediatamente, entre em contato conosco."
      },
      "afterCancellation": {
        "label": "Após o cancelamento:",
        "noCampaigns": "Você não poderá enviar novas {0}.",
        "campaigns": "Campanhas",
        "noAutomations": "Os {0} ativos serão pausados.",
        "automations": "Automations",
        "noForms": "Os {0} sem código serão desativados.",
        "forms": "Formulários",
        "noIntegrations": "Todas as {0} serão desativadas.",
        "integrations": "Integrações",
        "accountDeletion": "Após 6 meses de inatividade, todos os dados serão excluídos."
      }
    },
    "immediateCancelPlanMasterUser": "Cancelar assinatura imediatamente",
    "immediateCancelPlan": "Cancelar assinatura imediatamente",
    "buttonCancelPlan": "Cancelar assinatura",
    "buttonClose": "Melhor não"
  }
}
</i18n>
