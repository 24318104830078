import type { Tag } from "@domain/tag";
import type { Filter } from "@domain/filters";
import type { DataItem } from "@/vue/types/data";

export type SelectedValueTag<FilterId extends string = string, IdType extends string = string, DataType = unknown> = {
  filterId: FilterId;
  id: IdType;
  tag: Tag;
  data?: DataType;
};

export type SelectedValueText<
  FilterId extends string = string,
  IdType extends string = string,
  ValueType extends string = string,
  DataType = unknown
> = {
  filterId: FilterId;
  id: IdType;
  value: ValueType;
  data?: DataType;
};

export type SelectedValue<
  FilterId extends string = string,
  IdType extends string = string,
  ValueType extends string = string,
  DataType = unknown
> = SelectedValueTag<FilterId, IdType, DataType> | SelectedValueText<FilterId, IdType, ValueType, DataType>;

export type SelectedValues<
  FilterId extends string = string,
  IdType extends string = string,
  ValueType extends string = string,
  DataType = unknown
> = Array<SelectedValue<FilterId, IdType, ValueType, DataType>>;

export const isSelectedValueTag = (s: SelectedValueTag | SelectedValueText): s is SelectedValueTag => {
  return (s as SelectedValueTag).tag !== undefined;
};

export interface OptionValue<
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
> {
  filter?: Filter<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>;
  tag?: Tag;
  text?: TextType;
  item?: DataItem<ListKeyType, ListValueType, ListDataType, ListContentType>;
}

export const findIndexSelectedValue = (selected: SelectedValues, item: SelectedValue): number => {
  return selected.findIndex((selectedItem) => {
    if (selectedItem.filterId !== item.filterId) return false;

    if (isSelectedValueTag(selectedItem) && isSelectedValueTag(item)) {
      return selectedItem.tag.id === item.tag.id;
    }

    if (!isSelectedValueTag(selectedItem) && !isSelectedValueTag(item)) {
      return selectedItem.value === item.value;
    }
    return false;
  });
};

export const findSelectedValue = (selected: SelectedValues, item: SelectedValue): SelectedValue | undefined => {
  return selected.find((selectedItem) => {
    if (selectedItem.filterId !== item.filterId) return false;

    if (isSelectedValueTag(selectedItem) && isSelectedValueTag(item)) {
      return selectedItem.tag.id === item.tag.id;
    }

    if (!isSelectedValueTag(selectedItem) && !isSelectedValueTag(item)) {
      return selectedItem.value === item.value;
    }
    return false;
  });
};
