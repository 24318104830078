<template>
  <div class="relative">
    <Menu as="div" class="relative">
      <MenuButton
        :disabled="disabled"
        class="focus-visible:outline-none w-10 cursor-pointer select-none space-x-1 rounded-sm bg-gray-50 py-2 px-3 text-center text-sm font-medium text-gray-500 hover:bg-sky-100 hover:text-sky-400 focus-visible:bg-sky-100 focus-visible:text-sky-400 focus-visible:ring-2 focus-visible:ring-sky-500"
      >
        {{ t(`logicalOperator.${operator}`) }}</MenuButton
      >
      <MenuItems
        as="ul"
        class="focus:outline-none absolute top-11 z-20 flex w-11 flex-col rounded-md border bg-white shadow"
      >
        <MenuItem v-slot="{ active }" as="template">
          <li
            :class="[{ 'bg-sky-100': active }, 'cursor-pointer select-none py-2 px-4 text-sm text-gray-500']"
            @click="updateOperator('and')"
          >
            <span>{{ t("logicalOperator.and") }}</span>
          </li>
        </MenuItem>
        <MenuItem v-slot="{ active }" as="template">
          <li
            :class="[{ 'bg-sky-100': active }, 'cursor-pointer select-none py-2 px-4 text-sm text-gray-500']"
            @click="updateOperator('or')"
          >
            <span>{{ t("logicalOperator.or") }}</span>
          </li>
        </MenuItem>
      </MenuItems>
    </Menu>
  </div>
</template>

<script lang="ts" setup>
// Components
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

// Utils
import { useI18n } from "vue-i18n";

// Types
import type { Operator } from "@/vue/types/predicates";

const { t } = useI18n();

withDefaults(
  defineProps<{
    operator: Operator;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: "update:concatenator", operator: Operator): void;
}>();

const updateOperator = (operator: Operator) => {
  emit("update:concatenator", operator);
};
</script>
