import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";

// Types
import { AccountsService, GetAccountsParams, GetAccountsAsyncGenerator, GetAccountResponse } from "./accounts.types";
import { Accounts } from "@domain/account";

export function useAccounts(): AccountsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let getAccountsGenerator: GetAccountsAsyncGenerator | undefined = undefined;
  let apiParams: GetAccountsParams | undefined = undefined;

  return {
    async getAccounts(params) {
      if (!isEqual(apiParams, params) || !getAccountsGenerator) {
        apiParams = params;
        getAccountsGenerator = await requestPaging<Accounts, unknown>(
          {
            url: `/accounts`,
            method: "get",
            urlParams: {
              q: apiParams.q ? `*${apiParams.q}*` : undefined,
            },
            disableAccountPrefix: true,
          },
          { limit: apiParams?.limit ?? 20 }
        );
      }

      if (!getAccountsGenerator || getAccountsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
        };

      const data = (await getAccountsGenerator.next()) as IteratorResult<Accounts>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
        };
      }

      return {
        done: false,
        total: 10,
        value: data.value,
      };
    },
    async getAccount(params) {
      const res = await request<GetAccountResponse>({
        url: `/accounts`,
        method: "get",
        urlParams: {
          q: `${params.account}`,
          limit: 1,
          offset: 0,
        },
        disableAccountPrefix: true,
      });

      return res.data?.[0];
    },
  };
}
