import { Collection } from "@/core/magnet";
import AutomationsModel from "./model";
import app from "@/app";

export default Collection.extend({
  url: "automations",
  model: AutomationsModel,
  parse: function (response) {
    for (var i = 0; i < response.data.length; i++) {
      response.data[i].stats.filtered =
        response.data[i].stats.aborted + response.data[i].stats.exited;
    }
    return response.data;
  },
  additionals: {
    automationTemplates: function () {
      return $.get(`/data/automations-${app.lang}.json?_=${Date.now()}`);
    },
    integrations: function () {
      return $.get("integrations", { limit: 500 });
    },
    plan: function () {
      return $.get("plan");
    },
  },
  parseAdditionals: function (additionals) {
    return additionals;
  },

  filters: {},

  create: function (data) {
    return $.ajax({
      url: "automations",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },

  copy: function (data) {
    return $.ajax({
      url: "automations/copy",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },
});
