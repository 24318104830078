import { Collection } from "@/core/magnet";
import ImagesModel from "./model";
import app from "@/app";

export default Collection.extend({
  model: ImagesModel,
  parse: function (response) {
    return response.data;
  },

  // The URL of this collection is dinamic
  url: function () {
    var query = this.query || {};
    this.currentUrl = "images";

    // Return full path with location
    if (query.filter !== undefined && query.filter.location !== undefined) {
      this.currentUrl += query.filter.location;
    }

    // Return basic path
    return this.currentUrl;
  },

  // Additionals
  additionals: {
    breadcrumb: function (collection) {
      if (
        collection.query === undefined ||
        collection.query.filter === undefined ||
        collection.query.filter.location === undefined
      ) {
        return app.helpers.emptyRelationship();
      }
      return $.get("images/breadcrumb", {
        q: collection.query.filter.location,
      });
    },
  },

  // Parse additionals
  parseAdditionals: function (additionals) {
    var self = this,
      breadcrumbs;
    $.each(additionals, function (name, values) {
      // Add folder key to breadcrumbs data
      if (name === "breadcrumb") {
        breadcrumbs = [];
        if (
          self.query !== undefined &&
          self.query.filter !== undefined &&
          self.query.filter.location !== undefined
        ) {
          $.each(values, function (index, folder) {
            var splats = self.query.filter.location.split("/");
            splats.pop();
            breadcrumbs.push({
              name: folder,
              key: splats.join("/"),
            });
          });
          additionals.breadcrumb = breadcrumbs;
        }
      }
    });
    return additionals;
  },
});
