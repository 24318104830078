import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Model.extend({
  url: function () {
    if (this.isNew()) {
      return "images";
    }
    return "images" + this.get("id");
  },
  parse: function (image) {
    if (_.isDefined(image.data)) {
      image = image.data;
    }
    return image;
  },
});
