<template>
  <div class="space-y-3">
    <template v-if="hasCriticalErrors" class="space-y-2">
      <div>
        <span class="font-medium text-gray-500">{{ t("errorInfoMessage") }}</span>
      </div>
      <div v-for="audit in criticalErrors" :key="audit.id" class="flex w-full items-center space-x-2">
        <IconBullet :icon="ExclamationCircleIcon" :title="audit.title" :tooltip="audit.description" color="red" />
      </div>
    </template>
    <template v-if="hasWarningsErrors" class="space-y-2">
      <div>
        <span class="font-medium text-gray-500">{{ t("warningInfoMessage") }}</span>
      </div>
      <div v-for="audit in warningsErrors" :key="audit.id" class="flex w-full items-center space-x-2">
        <IconBullet :icon="ExclamationCircleIcon" :title="audit.title" :tooltip="audit.description" color="yellow" />
      </div>
    </template>

    <div v-if="errors.length === 0" class="flex w-full items-center space-x-2">
      <IconBullet :icon="CheckCircleIcon" :title="t('messageNoErrors')" color="green" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

//Components
import IconBullet from "@atoms/IconBullet.vue";

//Icons
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";

//I18n
import { useI18n } from "vue-i18n";

// Type
import { AuditResults, AuditErrors } from "@domain/Templates";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    auditory?: AuditResults;
  }>(),
  {
    auditory: undefined,
  }
);

const errors = computed<AuditErrors>(() => {
  if (!props.auditory || !props.auditory.errors) return [];

  const filteredErrors = props.auditory?.errors.reduce<AuditErrors>((errors, error) => {
    const duplicatedErrorMsg = errors.some((errorItem) => errorItem.title === error.title);
    if (duplicatedErrorMsg) return errors;

    errors.push(error);
    return errors;
  }, []);
  return filteredErrors.sort((a, b) => {
    if (a.severity === "ERROR" && b.severity === "WARNING") return -1;
    if (a.severity === "WARNING" && b.severity === "ERROR") return 1;
    return 0;
  });
});

const criticalErrors = computed(() => errors.value.filter((e) => e.severity === "ERROR"));
const hasCriticalErrors = computed(() => criticalErrors.value.length > 0);
const warningsErrors = computed(() => errors.value.filter((e) => e.severity === "WARNING"));
const hasWarningsErrors = computed(() => warningsErrors.value.length > 0);
</script>

<i18n lang="jsonc">
{
  "es": {
    "messageNoErrors": "Tu email puede ser enviado 🎉",
    "warningInfoMessage": "Sugerencias que no impiden el envío:",
    "errorInfoMessage": "Es necesario corregir estos problemas:"
  },
  "pt": {
    "messageNoErrors": "Seu e-mail pode ser enviado 🎉",
    "warningInfoMessage": "Sugestões que não impedem o envio:",
    "errorInfoMessage": "Esses problemas precisam ser corrigidos:"
  }
}
</i18n>
