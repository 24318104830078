<template>
  <div>
    <img :src="getUrl()" class="w-[30rem] xl:w-[40rem] 2xl:w-[50rem]" alt="Empty" />
  </div>
</template>
<script lang="ts" setup>
// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const getUrl = () => {
  if (session.value.lang === "pt") return "/img/AutomationsEmptyImagePT.svg";
  return "/img/AutomationsEmptyImage.svg";
};
</script>
