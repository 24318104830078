<template>
  <ModalDialog :title="title" :open="open" @close="closeModal">
    <div class="max-w-7xl" style="min-width: 15rem" data-intercom-target="AutomationsCatalogModal">
      <AutomationsCatalog
        :default-selected-automation-id="defaultSelectedAutomationId"
        :default-selected-category="defaultSelectedCategory"
        @selected="automationSelected"
        @create="createAutomation"
        @goto-integrations="gotoIntegrations"
        @goto-lifecycle-r-f-m="gotoLifecycleRFM"
        @close="closeModal"
        @selecting-editor="selectingEditor = true"
      />
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import type { Automation } from "./catalog/AutomationsCatalog.types";
import type { CreateEvent } from "./AutomationsCatalog.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import AutomationsCatalog from "./AutomationsCatalog.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

const props = withDefaults(
  defineProps<{
    defaultSelectedAutomationId?: string;
    defaultSelectedCategory?: string;
  }>(),
  {
    defaultSelectedAutomationId: undefined,
    defaultSelectedCategory: undefined,
  },
);

// Emits
const emit = defineEmits<{
  (e: "close"): void;
  (e: "create", a: CreateEvent): void;
  (e: "gotoIntegrations"): void;
  (e: "gotoLifecycleRFM"): void;
}>();

const { t } = useI18n();

const title = ref(t("title"));
const open = ref(false);
const selectingEditor = ref(false);

const openModal = () => {
  selectingEditor.value = false;
  title.value = t("title");
  open.value = true;
};

const closeModal = () => {
  open.value = false;
};

defineExpose({
  openModal,
  closeModal,
});

const automationSelected = (a: Automation | null) => {
  if (selectingEditor.value) {
    title.value = t("titleSelectEditor");
  } else if (a) {
    title.value = a.name;
  } else {
    title.value = t("title");
  }
};

const createAutomation = (a: CreateEvent) => {
  emit("create", a);
};
const gotoIntegrations = () => {
  emit("gotoIntegrations");
};
const gotoLifecycleRFM = () => {
  emit("gotoLifecycleRFM");
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Catálogo de automations",
    "titleSelectEditor": "¿Con cuál editor de plantillas deseas trabajar?"
  },
  "pt": {
    "title": "Catálogo de automations",
    "titleSelectEditor": "Com qual editor de template você deseja trabalhar?"
  }
}
</i18n>
