<template>
  <div class="relative rounded-md shadow-sm">
    <input
      v-bind="$attrs"
      ref="inputRef"
      :value="modelValue"
      type="text"
      class="block w-full rounded-md border-gray-300 pr-16 placeholder-gray-400 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
      maxlength="200"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div ref="emojiContainerRef" class="absolute inset-y-0 right-0 flex items-center pr-10">
      <div class="relative inline-block text-left">
        <div>
          <button
            type="button"
            class="flex items-center rounded-full text-gray-400 ring-1 ring-black ring-opacity-5 hover:text-gray-600 focus:outline-none focus:ring-0"
            tabindex="-1"
            @click="showEmojiDropdown = !showEmojiDropdown"
          >
            <span class="sr-only">Insertar emoji</span>
            <HeroIcon icon="emoji-happy" class="h-6 w-6" />
          </button>
        </div>

        <transition
          leave-active-class="transition duration-100 ease"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0 transform translate-y-6 "
          enter-active-class="transition duration-100 ease"
          enter-to-class="opacity-100"
          enter-from-class="opacity-0 transform translate-y-6"
        >
          <div
            v-show="showEmojiDropdown"
            class="absolute right-0 z-10 mt-3 origin-top-right rounded-md shadow-lg focus:outline-none"
            :class="{ 'bottom-9': dropdownsUpwards }"
            role="menu"
            aria-orientation="vertical"
          >
            <Picker
              :native="true"
              :data="emojiIndex"
              :emoji-tooltip="true"
              :i18n="emojiI18n"
              emoji="point_right"
              color="#00aee8"
              set="apple"
              title="Selecciona un emoji"
              @select="insertEmoji"
            />
          </div>
        </transition>
      </div>
    </div>

    <div ref="fieldsContainerRef" class="absolute inset-y-0 right-0 flex items-center pr-3">
      <div class="relative inline-block text-left">
        <div>
          <button
            type="button"
            class="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0"
            tabindex="-1"
            @click="showFieldsDropdown = !showFieldsDropdown"
          >
            <span class="sr-only">Insertar campo</span>
            <HeroIcon icon="dots-circle-horizontal" class="h-6 w-6" />
          </button>
        </div>

        <transition
          leave-active-class="transition duration-100 ease"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0 transform translate-y-6 "
          enter-active-class="transition duration-100 ease"
          enter-to-class="opacity-100"
          enter-from-class="opacity-0 transform translate-y-6"
        >
          <div
            v-show="showFieldsDropdown"
            class="absolute right-0 z-10 mt-3 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            :class="{ 'bottom-9': dropdownsUpwards }"
            role="menu"
            aria-orientation="vertical"
          >
            <ul
              tabindex="-1"
              role="listbox"
              class="my-0 max-h-80 overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <li
                v-for="field in fields"
                :key="field.name"
                role="option"
                class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-800 hover:bg-sky-200"
                @click="insertFieldTag(field)"
              >
                <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                <span class="block truncate font-normal">
                  {{ field.name }}
                </span>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import HeroIcon from "@/components/vue/HeroIcon";
import data from "emoji-mart-vue-fast/data/apple.json";

//TODO: hacer componente con el picker
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";

import "emoji-mart-vue-fast/css/emoji-mart.css";

const emojiIndex = new EmojiIndex(data);

export default {
  components: {
    HeroIcon,
    Picker,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    dropdownsUpwards: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const inputRef = ref(null);
    const emojiContainerRef = ref(null);
    const fieldsContainerRef = ref(null);
    const showEmojiDropdown = ref(false);
    const showFieldsDropdown = ref(false);
    const emojiI18n = window.lang.emojiPicker;

    onClickOutside(emojiContainerRef, () => {
      showEmojiDropdown.value = false;
    });

    onClickOutside(fieldsContainerRef, () => {
      showFieldsDropdown.value = false;
    });

    //TODO: pasar esta función a un composable
    const insertAtCursor = (text) => {
      const inputEl = inputRef.value;
      // get cursor's position:
      var startPos = inputEl.selectionStart,
        endPos = inputEl.selectionEnd,
        cursorPos = startPos,
        tmpStr = inputEl.value;

      // mark dirty
      inputEl.setAttribute("data-changed", "1");

      // insert:

      const newStr = tmpStr.substring(0, startPos) + text + tmpStr.substring(endPos, tmpStr.length);

      emit("update:modelValue", newStr);

      // move cursor:
      setTimeout(() => {
        cursorPos += text.length;
        inputEl.selectionStart = inputEl.selectionEnd = cursorPos;
      }, 10);
    };

    const insertEmoji = (emoji) => {
      if (emoji.native) insertAtCursor(emoji.native);
    };

    const insertFieldTag = (field) => {
      let tag = field.tag.toLowerCase();
      tag = tag === "fname" ? "first_name" : tag === "lname" ? "last_name" : tag;

      insertAtCursor(" ${contact." + tag + "} ");
    };

    return {
      emojiContainerRef,
      fieldsContainerRef,
      inputRef,
      insertEmoji,
      insertFieldTag,
      emojiIndex,
      showEmojiDropdown,
      showFieldsDropdown,
      emojiI18n,
    };
  },
};
</script>

<style>
.emoji-mart {
  user-select: none;
}

.emoji-mart-search {
  padding: 2px 6px;
  margin-bottom: 6px;
}

.emoji-mart-search input {
  font-size: 0.9rem;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer;
}

.emoji-mart-category-label span {
  font-size: 0.8rem;
}

.has-error input {
  @apply border-red-400 placeholder-red-300 focus:border-red-500 focus:ring-red-500;
}
</style>
