import { Ref } from "vue";
import { useTippy, TippyOptions } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-toward-subtle.css";

export const useTooltip = (
  elementRef: Element | Ref<Element> | Ref<Element | undefined>,
  content: string | Ref<string> | Ref<string | ((el: HTMLDivElement) => string) | undefined>,
  opts?: TippyOptions
) =>
  useTippy(elementRef, {
    content,
    delay: [200, 200],
    hideOnClick: false,
    interactive: true,
    allowHTML: true,
    animation: "shift-toward-subtle",
    maxWidth: 400,
    ...opts,
  });
