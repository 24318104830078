<template>
  <Table
    :columns="columns"
    :rows="rows"
    :selected-rows="selectedRows"
    :skeleton="skeleton"
    :skeleton-count="skeletonCount"
    :loading="loading"
    :selectable="templatesUpdate && show.selection"
    class="h-full overflow-x-hidden pb-12"
    @update:selected-rows="updateSelectedRows"
    @scrollBottom="bottomReached"
  >
    <template #templateName="{ row }">
      <TemplateNameCell
        v-if="row.data"
        :template="row.data"
        :as-link="asLink"
        :block-link="selectedRows && selectedRows.length > 0"
        @select="selectTemplate"
        @selectTag="selectTag"
      />
    </template>
    <template #templateName-skeleton>
      <div class="my-4 animate-pulse space-y-2">
        <div class="w-72 rounded-xl bg-sky-50 py-2" />
        <div class="w-80 rounded-xl bg-gray-50 py-2" />
      </div>
    </template>
    <template #templateUpdatedDate="{ row }">
      <TemplateUpdateDateCell v-if="row.data" :template="row.data" />
    </template>
    <template #templateUpdatedDate-skeleton>
      <div class="my-5 flex justify-end">
        <div class="animate-pulse space-y-2">
          <div class="w-40 rounded-xl bg-gray-50 py-2" />
          <div class="w-40 rounded-xl bg-gray-50 py-2" />
        </div>
      </div>
    </template>
    <template v-if="show.optionsMenu" #rowOptions="{ row }">
      <div class="flex justify-center">
        <RowOptions
          v-if="row.data"
          :template="row.data"
          :class="[{ invisible: !show.renderOptionsMenu }, 'mx-auto']"
          @duplicate="duplicateTemplate"
          @editTags="editTags"
          @delete="openDeleteFontAlertModal"
        />
      </div>
    </template>
    <template v-if="show.optionsMenu" #rowOptions-skeleton>
      <div class="mx-auto my-2 w-6 animate-pulse rounded bg-gray-50 py-3" />
    </template>
  </Table>
  <ConfirmationModal
    id="deleteTemplateAlertModal"
    v-bind="deleteTemplateAlertModal"
    @accept="deleteTemplate"
    @cancel="closeDeleteFontAlertModal"
  />
</template>
<script lang="ts" setup>
import { computed, toRaw, ref, reactive } from "vue";

import { getColumns, templatesListToRows } from "./templateCatalogTable.data";

// Components
import Table from "@molecules/Table";
import TemplateNameCell from "./components/TemplateNameCell.vue";
import TemplateUpdateDateCell from "./components/TemplateUpdateDateCell.vue";
import RowOptions from "./components/RowOptions.vue";
import ConfirmationModal from "@/vue/components/molecules/ConfirmationModal.vue";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore, useAlertStore } from "@store";

//Types
import type { Rows } from "@molecules/Table";
import type { TemplateList, TemplateItem } from "@domain/Templates";
import type { Tag } from "@domain/tag";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t } = useI18n();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const templatesUpdate = sessionStore.hasPermission("templates:update");

//Props
const props = withDefaults(
  defineProps<{
    class?: string;
    templates: TemplateList;
    selectedRows?: Rows<string, string, TemplateItem>;
    skeleton?: boolean;
    loading?: boolean;
    skeletonCount?: number;
    asLink?: boolean;
    show?: {
      renderOptionsMenu?: boolean;
      optionsMenu?: boolean;
      selection?: boolean;
    };
  }>(),
  {
    class: "",
    skeleton: false,
    loading: false,
    skeletonCount: 4,
    selectedRows: undefined,
    asLink: false,
    show: () => ({
      renderOptionsMenu: true,
      optionsMenu: true,
      selection: true,
    }),
  },
);

// Emits
const emit = defineEmits<{
  select: [TemplateItem];
  "update:selectedRows": [Rows<string, string, TemplateItem>];
  selectTag: [Tag];
  duplicate: [TemplateItem];
  editTags: [TemplateItem];
  delete: [TemplateItem];
  bottomReached: [void];
}>();

const bottomReached = () => emit("bottomReached");

const selectTemplate = (selectedTemplate: TemplateItem) => {
  emit("select", selectedTemplate);
};

const updateSelectedRows = (value: Rows<string, string, TemplateItem>) => {
  emit("update:selectedRows", value);
};

const selectTag = (tag: Tag) => {
  emit("selectTag", tag);
};

const duplicateTemplate = (template: TemplateItem) => {
  if (!templatesUpdate) {
    alertStore.showPermissionDenied(["templates:update"]);
    return;
  }
  emit("duplicate", template);
};

const editTags = (template: TemplateItem) => {
  if (!templatesUpdate) {
    alertStore.showPermissionDenied(["templates:update"]);
    return;
  }
  emit("editTags", template);
};

// Delete font
const templateToDelete = ref<TemplateItem>();

const deleteTemplateAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteTemplateAlert.confirmationTitle"),
  message: t("deleteTemplateAlert.confirmationMessage"),
  acceptText: t("deleteTemplateAlert.confirmButton"),
  cancelText: t("deleteTemplateAlert.cancelButton"),
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  templateToDelete.value = undefined;

  deleteTemplateAlertModal.open = false;
};
const openDeleteFontAlertModal = (template: TemplateItem) => {
  if (!sessionStore.hasPermission("templates:delete")) {
    alertStore.showPermissionDenied(["templates:delete"]);
    return;
  }

  templateToDelete.value = template;

  deleteTemplateAlertModal.message = t("deleteTemplateAlert.confirmationMessage", { name: template.name });

  deleteTemplateAlertModal.open = true;
};

const deleteTemplate = () => {
  if (templateToDelete.value) {
    emit("delete", templateToDelete.value);
  }
  closeDeleteFontAlertModal();
};

const columns = getColumns({
  rowOptions: props.show.optionsMenu ?? true,
});

const formatUserId = (userId: string) => {
  if (userId.includes("@*")) {
    return userId.replace("@*", "@perfit");
  }
  return userId.replace(/@(.+)/, "");
};

const rows = computed<Rows<string, string, TemplateItem>>(() =>
  templatesListToRows(toRaw(props.templates)).map((row) => {
    if (!row.data?.updated_by) return row;

    return {
      ...row,
      data: {
        ...row.data,
        updated_by: formatUserId(row.data?.updated_by),
      },
    };
  }),
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "deleteTemplateAlert": {
      "confirmationTitle": "Eliminar plantilla",
      "confirmationMessage": "La plantilla \"{name}\" será eliminada. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "deleteTemplateAlert": {
      "confirmationTitle": "Excluir template",
      "confirmationMessage": "O template \"{name}\" será excluído. Você quer continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
