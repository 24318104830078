<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem]">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="https://docs.myperfit.com/articles/879" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <FormInput v-model="size" :label="t('sizeLabel')" type="number" class="w-full" />
      <FormInput
        v-model="name"
        :label="t('nameLabel')"
        :error="nameLengthError ? t('nameLengthError') : undefined"
        class="w-full"
      />
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" :disabled="nameLengthError" @click="sample">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    id: string;
    listName: string;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const size = ref("2");
const name = ref(t("defaultName", { name: props.listName }).slice(0, 50));
const nameLengthError = computed(() => name.value.length > 50);

const loading = ref(false);
const sample = async () => {
  try {
    loading.value = true;

    await listAPI.sample({
      id: props.id,
      size: size.value,
      name: name.value,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.sample.title"),
      text: t("notifications.sample.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Muestrear",
    "description": "Se creará una nueva lista tomando una muestra aleatoria de la lista seleccionada. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "sizeLabel": "Cantidad de contactos de la muestra",
    "nameLabel": "Nombre de la nueva lista",
    "defaultName": "Muestra de {name}",
    "nameLengthError": "El nombre no puede superar los 50 caracteres",
    "notifications": {
      "sample": {
        "title": "Muestreando lista",
        "text": "La lista se está muestrando."
      }
    }
  },
  "pt": {
    "title": "Amostrar",
    "description": "Será criada uma nova lista com uma amostra aleatória da lista selecionada. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "sizeLabel": "Número de contatos na amostra",
    "nameLabel": "Nome da nova lista",
    "defaultName": "Amostra de {name}",
    "nameLengthError": "O nome não pode exceder 50 caracteres",
    "notifications": {
      "sample": {
        "title": "Amostrando lista",
        "text": "A lista está sendo amostrada."
      }
    }
  }
}
</i18n>
