<template>
  <button class="flex items-center space-x-2 text-sm text-gray-400">
    <span v-show="parentCode">
      {{ parentCode }}
    </span>
    <ChevronRightIcon v-show="parentCode" class="h-5 w-5" />
    <span :class="[isHomePage ? 'text-gray-500' : 'text-gray-100']">
      {{ session.account.code }}
    </span>
    <SwitchHorizontalIcon class="h-5 w-5" />
  </button>
</template>

<script lang="ts" setup>
// Icons
import { SwitchHorizontalIcon, ChevronRightIcon } from "@heroicons/vue/solid";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useGlobalStore } from "@store";
import { computed } from "vue";

const sessionStore = useSessionStore();
const globalStore = useGlobalStore();
const { session } = storeToRefs(sessionStore);
const { isHomePage } = storeToRefs(globalStore);

const parentCode = computed(() => session?.value?.account?.parent);
</script>
