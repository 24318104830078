<template>
  <div class="flex flex-col space-y-8 py-2">
    <h3 class="m-0 text-base font-medium text-gray-700">{{ t("title") }}</h3>
    <div class="flex space-x-10">
      <div class="flex w-5/12 flex-col space-y-2">
        <h4 class="m-0 text-base font-medium text-gray-700">
          {{ t("content.title") }}
        </h4>
        <p class="text-xs font-normal leading-5 text-gray-600">
          <i18n-t keypath="content.description">
            <template #link>
              <a href="" target="_blank" class="text-sky-400" @click.stop>{{ t("content.descriptionLink") }}</a>
            </template>
          </i18n-t>
        </p>
      </div>
      <div class="flex w-7/12 flex-col space-y-2">
        <h4 class="m-0 text-base font-medium text-gray-700">
          {{ integrated ? t("integratedWith") : t("integrateWith") }}
        </h4>
        <LoadingSpinner v-if="loading" class="h-5 w-5" aria-hidden="true" />
        <div v-else-if="recommendedStore">
          <button
            :disabled="integrated"
            :class="[
              integrated ? 'bg-gray-100' : 'border-2 border-gray-300 bg-white  hover:bg-gray-50',
              'flex w-full items-center justify-center gap-3 rounded-lg  px-4 py-4 shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-400'
            ]"
            @click="() => storeHandlers(recommendedStore)"
          >
            <img
              v-if="!integrating && recommendedStore"
              :src="getStoreImage(recommendedStore).link"
              :alt="getStoreImage(recommendedStore).alt"
              class="aspect-auto h-6 object-scale-down"
            />

            <LoadingSpinner v-else="integrating" class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div v-else class="grid grid-cols-3 grid-rows-2 gap-4">
          <button
            v-for="integration in integrations"
            :disabled="integrating !== undefined"
            :key="integration"
            :class="[
              { 'bg-gray-100': integrating !== undefined },
              'flex aspect-video w-full items-center justify-center gap-3 rounded-lg border-2 border-gray-300 bg-white px-4 py-2.5 shadow-lg hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-400'
            ]"
            @click="() => storeHandlers(integration)"
          >
            <img
              v-show="integrating !== integration"
              :src="getStoreImage(integration).link"
              :alt="getStoreImage(integration).alt"
              class="aspect-video object-scale-down lg:h-16"
            />

            <LoadingSpinner
              v-if="integrating === integration"
              class="my-5 aspect-video h-6 w-6 text-sky-500"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

// Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Utils
import { useI18n } from "vue-i18n";

// Application
import { useRouterApp } from "@application";

// Domain
import type { Ecommerce } from "@domain/ecommerce";

const { t, locale } = useI18n();

const routerApp = useRouterApp();

const props = withDefaults(
  defineProps<{
    recommendedStore?: Ecommerce;
    integrated?: boolean;
  }>(),
  {}
);

const loading = ref(false);
const integrations = computed<Ecommerce[]>(() => {
  if (locale.value.slice(0, 2) === "pt") {
    return ["tiendanube", "mshops", "vtex", "shopify", "wbuy", "tray"];
  }
  return ["tiendanube", "mshops", "vtex", "shopify", "jumpseller", "woocommerce"];
});

const getStoreImage = (store: Ecommerce): { link: string; alt: string } => {
  if (!store)
    return {
      link: "",
      alt: ""
    };
  if (store === "tiendup") {
    return {
      link: "/img/integrations/tiendup.png",
      alt: "tiendup"
    };
  }
  if (store === "jumpseller") {
    return {
      link: "/img/integrations/jumpseller.png",
      alt: "jumpseller"
    };
  }
  if (store === "mshops") {
    return {
      link: "/img/integrations/mshops.png",
      alt: "mshops"
    };
  }
  if (store === "shopify") {
    return {
      link: "/img/integrations/shopify.png",
      alt: "shopify"
    };
  }
  if (store === "tiendanube") {
    if (locale.value.slice(0, 2) === "pt") {
      return {
        link: "/img/integrations/tiendanube_br.png",
        alt: "tiendanube"
      };
    }
    return {
      link: "/img/integrations/tiendanube.png",
      alt: "tiendanube"
    };
  }
  if (store === "vtex") {
    return {
      link: "/img/integrations/vtex.png",
      alt: "vtex"
    };
  }
  if (store === "woocommerce") {
    return {
      link: "/img/integrations/woocommerce.png",
      alt: "woocommerce"
    };
  }
  if (store === "wbuy") {
    return {
      link: "/img/integrations/wbuy.png",
      alt: "wbuy"
    };
  }
  if (store === "tray") {
    return {
      link: "/img/integrations/tray.png",
      alt: "tray"
    };
  }
  return {
    link: "",
    alt: ""
  };
};

const integrating = ref<Ecommerce>();

const storeHandlers = async (storeId: Ecommerce | undefined) => {
  if (!storeId) return;

  integrating.value = storeId;

  if (storeId === "tiendanube") {
    routerApp.navigate({
      path: "/integrations/tiendanube"
    });
  }
  if (storeId === "mshops") {
    routerApp.navigate({
      path: "/integrations/mshops"
    });
  }
  if (storeId === "vtex") {
    routerApp.navigate({
      path: "/integrations/vtex"
    });
  }
  if (storeId === "shopify") {
    routerApp.navigate({
      path: "/integrations/shopify"
    });
  }
  if (storeId === "jumpseller") {
    routerApp.navigate({
      path: "/integrations/jumpseller"
    });
  }
  if (storeId === "woocommerce") {
    routerApp.navigate({
      path: "/integrations/woocommerce"
    });
  }
  integrating.value = undefined;
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Selecciona una integración para conectar tu tienda y sincronizar tus productos.",
    "integrateWith": "Integrar con:",
    "integratedWith": "Integrado con:",
    "recommendedIntegration": "Integración recomendada según tu url",
    "content": {
      "title": "¿Para qué sirve una integración?",
      "description": "Integra tu tienda para sincronizar tus contactos, compras y productos en tiempo real, poder enviar mensajes automáticos y medir los resultados de tus acciones de marketing automation. {link}",
      "descriptionLink": "Leer más"
    }
  },
  "pt": {
    "title": "Selecione uma integração para conectar sua loja e sincronizar seus produtos.",
    "integrateWith": "Integrar com:",
    "integratedWith": "Integrado com:",
    "recommendedIntegration": "Integração recomendada de acordo com sua URL",
    "content": {
      "title": "Para que serve uma integração?",
      "description": "Integre sua loja para sincronizar seus contatos, compras e produtos em tempo real, enviar mensagens automáticas e medir os resultados de suas ações de marketing automation. {link}",
      "descriptionLink": "Leia mais"
    }
  }
}
</i18n>
