const moment = window.moment;

// Returns timegroup for time agrupation
export default function (date) {
  // Get timestamp difference
  const diff =
    moment().tz(window.config.timezone).unix() -
    moment(date).tz(window.config.timezone).unix();
  const days = Math.ceil(diff / (3600 * 24));

  if (date == "" || date === null) {
    return 0;
  }

  if (days <= 7) {
    return 1;
  } else if (days <= 30) {
    return 2;
  }

  return 3;
}
