// Icons
import { TrashIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { ColumnsIds, FieldRows, FieldRow } from "./audienceFields.types";
import type { Columns } from "@molecules/Table";
import type { Fields } from "@/vue/types/fields";

export const getColumns = (): Columns<ColumnsIds | "options", ColumnsIds> => {
  const { t } = useI18n();
  return [
    {
      id: "name",
      text: t("columns.name"),
      // sortModes: ["ASC"],
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "fieldType",
      text: t("columns.fieldType"),
      textMaxWidth: true,
      headerWidthRem: 15,
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "replaceCode",
      text: t("columns.replaceCode"),
      textMaxWidth: true,
      headerWidthRem: 15,
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "options",
      headerWidthRem: 2,
    },
  ];
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const fieldsToRows = (fields: Fields): FieldRows => {
  return fields.map<FieldRow>((field) => ({
    id: field.id.toString(),
    content: {
      name: field.name,
      fieldType: field.format,
      replaceCode: field.replaceCode,
    },
    data: {
      isCustom: field.custom ?? false,
    },
    options: {
      selectable: field.custom,
    },
  }));
};
