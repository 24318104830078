import app from "@/app";
import Handlebars from "handlebars";
const moment = window.moment;

// Gets a contact column HTML
export default function (field, model) {
  var columnValue;
  model = app.getModel("contacts", model.attributes);

  // Get the column value
  if (field.custom) {
    columnValue = model.getCustomField(field.id);
  } else {
    columnValue = model.getTranslatedCommonField(field.prop);
  }

  // If empty return an empty string
  if (!columnValue) {
    return "";
  }

  // Format date columns
  if (field.format === "DATE") {
    columnValue = moment(columnValue).tz(window.config.timezone).format("L");
    columnValue = new Handlebars.SafeString(columnValue);
  }

  // Return column HTML
  return (
    "<strong>" + columnValue + "</strong><small>" + field.name + "</small>"
  );
}
