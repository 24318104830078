import app from "@/app";
import { Model } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";

export default Model.extend({
  urlRoot: "campaigns",
  parse: function (campaign) {
    // Get data from API direct call
    if (_.isDefined(campaign.data)) {
      campaign = campaign.data;
    }

    // Set first content as default
    campaign.defaultContent = [];
    if (campaign.contents && campaign.contents.length) {
      campaign.defaultContent = campaign.contents[0];
    }

    // Remove extra campaign from include and exclude
    ["include", "exclude"].forEach(function (type) {
      ["detailedLists", "detailedInterests"].forEach(function (key) {
        if (objHas(campaign, type, key)) {
          var newKey = key.replace("detailed", "").toLowerCase();
          campaign[type][newKey] = $.extend({}, campaign[type][key]);
          delete campaign[type][key];
        }
      });
    });

    // Return parsed campaign
    return campaign;
  },
  additionals: {
    json: function (model) {
      if (_.size(model.defaultContent()) && model.defaultContent().type === "EDDIE") {
        return $.ajax({
          url: "campaigns/" + model.id + "/contents/" + model.defaultContent().id + ".json",
        });
      } else {
        return app.helpers.emptyRelationship();
      }
    },
    html: function (model) {
      if (!model.get("options")?.UNLAYER_TPL_ID && _.size(model.defaultContent())) {
        return $.ajax({
          dataType: "html",
          url: "campaigns/" + model.id + "/contents/" + model.defaultContent().id + ".html",
        });
      } else {
        return app.helpers.emptyRelationship();
      }
    },
    froms: function () {
      return $.get("froms", { limit: 100 });
    },
    fields: function () {
      return $.get("fields", { limit: 100 });
    },
  },
  relationships: {
    tags: function () {
      return $.get("campaigns/tags", { limit: 100 });
    },
    includeActions: [{ id: "SENT" }, { id: "OPENED" }, { id: "CLICKED" }, { id: "NOT_OPENED" }, { id: "NOT_CLICKED" }],
    excludeActions: [{ id: "SENT" }, { id: "OPENED" }, { id: "CLICKED" }],
  },

  // Returns the default content
  defaultContent: function () {
    return this.get("contents") ? _.first(this.get("contents")) : false;
  },

  // Stops campaigns
  stop: function () {
    return $.post("campaigns/" + this.id + "/stop");
  },

  // Launch campaign
  launch: function (data) {
    data = data || {};
    return $.ajax({
      type: "POST",
      url: "campaigns/" + this.id + "/launch",
      data: data,
      timeout: 0,
    });
  },

  // Archives campaigns
  archive: function () {
    return $.post("campaigns/" + this.id + "/archive");
  },

  // Archives campaigns
  unarchive: function () {
    return $.post("campaigns/" + this.id + "/unarchive");
  },

  // Duplicate campaigns
  duplicate: function (data) {
    return $.post("campaigns/" + this.id + "/duplicate", data);
  },

  // Validates the campaign
  validateData: function () {
    return $.ajax({ url: "campaigns/" + this.id + "/validate", timeout: 0 });
  },

  // Counts the recipients
  countRecipients: function () {
    return $.ajax({
      url: "campaigns/" + this.id + "/recipients/count",
      timeout: 10 * 1000,
      error: function (error) {
        console.log(error);
      },
    });
  },

  validateSender: function () {
    //get features[ALLOW_USE_GENERIC_DOMAIN]
    return $.ajax({
      url: app.session.get("account"),
      headers: {
        "Account-Prefix": false,
      },
    }).then((res) => {
      const allowUseGeneric = res.data.features.ALLOW_USE_GENERIC_DOMAIN === "1";
      console.log("ALLOW_USE_GENERIC_DOMAIN", allowUseGeneric);

      if (allowUseGeneric || (app.getTrial() && app.getTrial().inTrial) || !this.attributes.options?.SENDER_ID ) return true;

      //get sender[options.sender_id]
      return $.ajax({
        url: "senders/" + this.attributes.options.SENDER_ID,
      }).then((res) => {
        const statusOk = res.data.auth.status === "OK";
        console.log("sender status Ok", statusOk);
        return statusOk;
      });
    });
  },

  // Updates the campaign
  update: function (data) {
    return $.ajax({
      type: "PUT",
      data: data,
      url: "campaigns/" + this.id,
    });
  },

  // Updates the contents fields
  updateContents: function (data) {
    return $.ajax({
      type: "PUT",
      data: data,
      url: "campaigns/" + this.id + "/contents/" + this.defaultContent().id,
    });
  },

  // Updates the contents fields
  deleteContents: function () {
    return $.ajax({
      type: "DELETE",
      url: "campaigns/" + this.id + "/contents/" + this.defaultContent().id,
    });
  },

  // Saves the HTML contents
  saveBody: function (data) {
    return $.ajax({
      type: "PUT",
      data: JSON.stringify(data),
      dataType: "json",
      contentType: "application/json",
      url: "campaigns/" + this.id + "/contents/" + this.defaultContent().id + "/body",
    });
  },

  // Sends a campaign test
  sendTest: function (data) {
    var url = "campaigns/" + this.id + "/contents/" + this.defaultContent().id + "/sendtest";
    return $.post(url, data);
  },

  // Destroy override
  destroy: function (options) {
    var params = $.extend(true, {}, { type: "DELETE", url: this.url() }, options);

    const eventData = {
      id: this.id,
      name: this.attributes.name,
    };

    return $.ajax(params).then(() => {
      app.integrations.amplitude.event("CAMPAIGN_DELETED", eventData, true);
      app.integrations.intercom.event("CAMPAIGN_DELETED", eventData);
    });
  },
});
