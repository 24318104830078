<template>
  <div class="flex flex-col py-2">
    <h3 class="m-0 text-base font-medium text-gray-700">{{ t("title") }}</h3>
    <div class="mt-8 space-y-2">
      <UserInputs v-model:users="userItems" />
      <span class="text-sm font-normal text-gray-500">{{ t("description") }}</span>
    </div>
    <div class="mt-6 flex justify-between border-t pb-2 pt-7">
      <a @click="moreAction" class="flex items-center space-x-1 text-sm font-semibold text-sky-400">
        <span>
          {{ t("moreText") }}
        </span>
        <ArrowRightIcon class="h-3.5 w-3.5" />
      </a>
      <div class="flex space-x-2">
        <SimpleButton theme="white" :loading="loadingOmit" @click="omit">
          <template #leading>
            <CheckCircleIcon class="text-gray-500" />
          </template>
          {{ t("omitText") }}
        </SimpleButton>
        <SimpleButton :disabled="validUserItems.length === 0" :loading="loadingSend" @click="action">
          <template #leading>
            <UserAddIcon />
          </template>
          {{ t("actionText") }}
        </SimpleButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";

// Components
import UserInputs from "@molecules/UserInputs";
import type { UserItem, UserItems } from "@molecules/userInputs";
import SimpleButton from "@atoms/SimpleButton.vue";

// Icon
import { ArrowRightIcon } from "@heroicons/vue/outline";
import { CheckCircleIcon, UserAddIcon } from "@heroicons/vue/solid";

// Notifications
import { useNotifications } from "@composables/notifications";

//utils
import { email as emailValidator } from "@vuelidate/validators";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { useUserService } from "@services";

// Store
import { useOnboardingStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

const onboardingStore = useOnboardingStore();
const userService = useUserService();
const trackingEventsService = useTrackingEvents();
const { notify } = useNotifications();

const { t } = useI18n();

const userItems = ref<UserItems>([]);
const validUserItems = computed(() => {
  return userItems.value.filter((user) => user.email !== "" && isValidEmail(user.email));
});

const moreAction = () => {
  window.Intercom("showArticle", 596581);
};

const getUsername = (email: string) => {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return email;
  }
  return email.slice(0, atIndex);
};

const isValidEmail = (email: string) => Boolean(emailValidator.$validator(email, null, null));

const loadingSend = ref(false);
const action = async () => {
  if (validUserItems.value.length === 0) return;

  const promises = validUserItems.value.map(
    async (user: UserItem) =>
      await userService.create({
        email: user.email,
        name: user.email.split("@")[0],
        role: user.roleItem.key,
        username: getUsername(user.email)
      })
  );

  loadingSend.value = true;
  const res = await Promise.all(promises);
  loadingSend.value = false;

  const amplitudePromises = validUserItems.value.map(async (user: UserItem) => {
    await trackingEventsService.amplitude({
      name: "APP_USER_INVITED",
      data: {
        email: user.email,
        role: user.roleItem.key,
        username: getUsername(user.email)
      }
    });
  });

  await Promise.all(amplitudePromises);

  const someError = res.some((r) => r.isErr());
  const someOk = res.some((r) => r.isOk());

  if (someError && !someOk) {
    notify({
      title: t("invitation_sent_error"),
      text: t("invitation_sent_error_description", {}, { plural: promises.length }),
      theme: "error",
      timeout: 8000
    });
    return;
  }

  if (someError && someOk) {
    notify({
      title: t("invitations_sent", {}, { plural: promises.length }),
      text: t("invitations_sent_errors_description", {}, { plural: promises.length }),
      timeout: 8000
    });
    return;
  }

  onboardingStore.setTaskCompleted({
    id: "collaboration"
  });

  userItems.value = [];

  notify({
    title: t("invitations_sent", {}, { plural: promises.length }),
    text: t("invitations_sent_description", {}, { plural: promises.length })
  });
};

const loadingOmit = ref(false);
const omit = async () => {
  loadingOmit.value = true;

  onboardingStore.setTaskOmitted({
    id: "collaboration"
  });

  loadingOmit.value = false;
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Trabaja con colaboradores de tu cuenta, asígnales permisos y envíales un email de confirmación.",
    "description": "Selecciona un permiso según las tareas que realiza y recibirá un email de confirmación para configurar su perfil.",
    "moreText": "Leer más sobre roles de usuarios",
    "actionText": "Enviar invitación",
    "omitText": "Omitir invitación",
    "invitation_sent_error": "Error al invitar",
    "invitation_sent_error_description": "El email ingresado ya es un usuario de la cuenta | Los emails ingresados ya son usuarios de la cuenta",
    "invitations_sent": "Invitacion enviada | invitaciones enviadas",
    "invitations_sent_description": "Se ha enviado el email de invitación | Se han enviado los emails de invitación",
    "invitations_sent_errors_description": "Uno o más emails ya son usuarios de la cuenta por lo que no se les enviara un email"
  },
  "pt": {
    "title": "Trabalhe com colaboradores da sua conta, atribua permissões e envie um e-mail de confirmação.",
    "description": "Selecione uma permissão de acordo com as tarefas que realiza e receberá um e-mail de confirmação para configurar seu perfil.",
    "moreText": "Leia mais sobre permissões do usuários",
    "actionText": "Enviar convite",
    "omitText": "Omitir convite",
    "invitation_sent_error": "Erro ao convidar",
    "invitation_sent_error_description": "O e-mail inserido já é um usuário da conta",
    "invitations_sent": "Convite enviado | convites enviados",
    "invitations_sent_description": "O e-mail de convite foi enviado | Os e-mails de convite foram enviados",
    "invitations_sent_errors_description": "Um ou mais e-mails já são usuários da conta, por isso não receberão um e-mail"
  }
}
</i18n>
