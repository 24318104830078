<!--
TODO:
- asignar id random si no se indica y que no sea requerido
-->
<template>
  <div>
    <InputLabel :for="id">{{ label }}</InputLabel>
    <SimpleTextArea
      :id="id"
      ref="inputRef"
      class="mt-1"
      v-bind="$attrs"
      :model-value="modelValue"
      :placeholder="placeholder"
      :has-error="!!error"
      :aria-describedby="error ? `${id}-error` : `${id}-hint`"
      @update:model-value="$emit('update:modelValue', $event)"
    />
    <InputHint v-if="!error" :id="`${id}-hint`">{{ hint }}</InputHint>
    <InputError v-if="error" :id="`${id}-error`">{{ error }}</InputError>
  </div>
</template>

<script>
import InputLabel from "@atoms/InputLabel";
import InputHint from "@atoms/InputHint";
import InputError from "@atoms/InputError";
import SimpleTextArea from "@atoms/SimpleTextArea";

export default {
  name: "FormInput",
  components: { InputLabel, InputHint, InputError, SimpleTextArea },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    focus() {
      this.$refs.inputRef.focus();
    },
  },
};
</script>
