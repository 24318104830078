<template>
  <form class="space-y-6" @submit.prevent="save">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
      <FormInput
        v-model="billingInfo.info.name"
        :label="t('legalNameLabel')"
        :placeholder="t('legalNamePlaceholder')"
        :error="v$.info.name.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.fiscal_id"
        :label="t('fiscalIdLabel')"
        :error="v$.info.fiscal_id.$error ? getFiscalIdErrorMessage(v$.info.fiscal_id.$errors) : undefined"
        placeholder="00.000.000/0000-00"
      />
      <FormInput
        v-model="billingInfo.email"
        :label="t('emailLabel')"
        placeholder="example@gmail.com"
        :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
      />

      <FormInput
        v-model="billingInfo.info.cp"
        label="CEP"
        placeholder="00000-000"
        :disabled="loadingCEPData"
        :loading="loadingCEPData"
        :error="v$.info.cp.$error ? getPostalCodeErrorMessage(v$.info.cp.$errors) : undefined"
        :class="[{ 'bg-gray-50': loadingCEPData }]"
        @blur="() => completeCEPData(billingInfo.info.cp)"
      />
      <FormInput
        v-model="billingInfo.info.estado"
        label="Estado"
        :disabled="!CEPDataError && !billingInfoEditableFields.estado"
        :class="[{ 'bg-gray-50': !CEPDataError && !billingInfoEditableFields.estado }, 'grow']"
        :error="CEPDataError && v$.info.estado.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.cidade"
        label="Cidade"
        :disabled="!CEPDataError && !billingInfoEditableFields.cidade"
        :class="[{ 'bg-gray-50': !CEPDataError && !billingInfoEditableFields.cidade }, 'grow']"
        :error="CEPDataError && v$.info.cidade.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.bairro"
        label="Bairro"
        :disabled="!CEPDataError && !billingInfoEditableFields.bairro"
        :class="[{ 'bg-gray-50': !CEPDataError && !billingInfoEditableFields.bairro }, 'grow']"
        :error="CEPDataError && v$.info.bairro.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.rua"
        label="Rua"
        :disabled="!CEPDataError && !billingInfoEditableFields.rua"
        :class="[{ 'bg-gray-50': !CEPDataError && !billingInfoEditableFields.rua }, 'grow']"
        :error="CEPDataError && v$.info.rua.$error ? t('fieldRequired') : undefined"
      />
      <FormInput
        v-model="billingInfo.info.num"
        label="Número"
        placeholder="1234"
        :error="v$.info.num.$error ? t('fieldRequired') : undefined"
      />
    </div>
    <div class="flex justify-end space-x-2">
      <SimpleButton @click="close" theme="white">{{ t("close") }}</SimpleButton>
      <SimpleButton type="submit" :loading="loadingSave">
        <template #leading>
          <LockClosedIcon />
        </template>
        {{ t("save") }}
      </SimpleButton>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { reactive, watch, ref } from "vue";

// Component
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import { LockClosedIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Utils
import { cloneDeep } from "lodash";
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

// Composables
import { useNotifications } from "@composables/notifications";

// Service
import type { BrazilBillingInfoData } from "@domain/billingInfo";
import { usePostalCodesService, useBillingInfoService } from "@services";
import type { CEPData } from "@services";

const { t } = useI18n();
const billingInfoService = useBillingInfoService();
const postalCodesService = usePostalCodesService();

const { notify } = useNotifications();

const props = withDefaults(
  defineProps<{
    billingData: BrazilBillingInfoData;
  }>(),
  {}
);

const emit = defineEmits<{
  close: [];
  updateBillingData: [BrazilBillingInfoData];
}>();

const close = () => {
  emit("close");
  fillBillingInfo(props.billingData);
  v$.value.$reset();
};

const updateBillingData = (info: BrazilBillingInfoData) => {
  emit("updateBillingData", info);
};

const billingInfo = reactive<BrazilBillingInfoData>({
  country: "br",
  email: "",
  info: {
    fiscal_id: "",
    fiscal_id_clean: "",
    fiscal_id_type: "",
    bairro: "",
    name: "",
    cidade: "",
    estado: "",
    num: "",
    cp: "",
    rua: ""
  }
});

const billingInfoEditableFields = reactive({
  bairro: false,
  rua: false,
  cidade: false,
  estado: false
});

const updateBillingInfoEditableFields = () => {
  v$.value.info.bairro.$validate();
  v$.value.info.cidade.$validate();
  v$.value.info.estado.$validate();
  v$.value.info.rua.$validate();

  billingInfoEditableFields.bairro = v$.value.info.bairro.$error;
  billingInfoEditableFields.cidade = v$.value.info.cidade.$error;
  billingInfoEditableFields.estado = v$.value.info.estado.$error;
  billingInfoEditableFields.rua = v$.value.info.rua.$error;
};

const getFiscalIdType = (fiscalId: string) => {
  const cleanFiscalId = fiscalId.replace(/[^\d]/g, "");
  if (cleanFiscalId.length === 11) {
    return "CPF";
  }

  if (cleanFiscalId.length === 14) {
    return "CNPJ";
  }

  return "";
};

const updateFiscalIdType = () => {
  billingInfo.info.fiscal_id_type = getFiscalIdType(billingInfo.info.fiscal_id);
};

const testFiscalId = (value) => {
  const regex = new RegExp(/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/);
  return regex.test(value);
};

const testCEP = (value) => {
  const regex = new RegExp(/^\d{2}\d{3}[-]\d{3}$/);
  return regex.test(value);
};

const validationRules = {
  country: { required },
  email: { required, email },

  info: {
    fiscal_id: {
      required,
      testFiscalId
    },
    bairro: { required },
    name: { required },
    cidade: { required },
    estado: { required },
    num: { required },
    cp: {
      required,
      testCEP
    },
    rua: { required }
  }
};
const v$ = useVuelidate(validationRules, billingInfo);

const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const getFiscalIdErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidFiscalId");
};

const getPostalCodeErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidCEP");
};

watch(
  () => billingInfo,
  (newValue, oldValue) => {
    for (const key in newValue) {
      if (newValue[key] !== oldValue[key]) {
        v$[key].$reset();
      }
    }
  },
  { deep: true }
);

watch(
  () => cloneDeep(billingInfo.info),
  (newInfo, oldInfo) => {
    for (const key in newInfo) {
      if (newInfo[key] !== oldInfo[key]) {
        v$.value.info?.[key]?.$reset();
      }
    }
  },
  { deep: true }
);

const loadingSave = ref(false);

const allowEmptyCPValues = ref(false);
const cpFields = ["bairro", "rua", "cidade", "estado"];
const save = async () => {
  const validation = await v$.value.$validate();
  const nonCPFieldsError = v$.value.$errors.some((error) => !cpFields.includes(error.$property));

  if (!validation && (!allowEmptyCPValues.value || nonCPFieldsError)) return;

  updateFiscalIdType();

  loadingSave.value = true;

  const res = await billingInfoService.save({
    country: "br",
    email: billingInfo.email,
    fiscal_id: billingInfo.info.fiscal_id ?? "",
    fiscal_id_clean: billingInfo.info.fiscal_id.replaceAll(/[^\d]/g, "") ?? "",
    fiscal_id_type: billingInfo.info.fiscal_id_type,
    bairro: billingInfo.info.bairro,
    name: billingInfo.info.name,
    cidade: billingInfo.info.cidade,
    estado: billingInfo.info.estado,
    num: billingInfo.info.num,
    cp: billingInfo.info.cp,
    rua: billingInfo.info.rua
  });

  loadingSave.value = false;

  if (res.isErr()) {
    notify({ title: t("saveError.title"), text: t("saveError.text"), theme: "error" });
    return;
  }
  notify({ title: t("notify.success") });
  updateBillingData(billingInfo);
  emit("close");
};

const fillBillingInfo = (data: BrazilBillingInfoData) => {
  billingInfo.email = data.email;

  if (data.country !== "br") return;

  billingInfo.info.fiscal_id = data.info.fiscal_id ?? "";
  billingInfo.info.fiscal_id_clean = data.info.fiscal_id_clean ?? "";
  billingInfo.info.bairro = data.info.bairro ?? "";
  billingInfo.info.name = data.info.name ?? "";
  billingInfo.info.cidade = data.info.cidade ?? "";
  billingInfo.info.estado = data.info.estado ?? "";
  billingInfo.info.num = data.info.num ?? "";
  billingInfo.info.cp = data.info.cp ?? "";
  billingInfo.info.rua = data.info.rua ?? "";
};

watch(
  () => props.billingData,
  () => {
    fillBillingInfo(props.billingData);
  },
  { deep: true }
);

const loadingCEPData = ref(false);
const CEPDataError = ref(false);

const getCEPData = async (cep: string) => {
  return await postalCodesService.getCEPData({
    cep: cep
  });
};

const resetBillingInfoCEPData = () => {
  v$.value.info.bairro.$reset();
  v$.value.info.cidade.$reset();
  v$.value.info.estado.$reset();
  v$.value.info.rua.$reset();
};

const clearBillingInfoCEPData = () => {
  billingInfo.info.bairro = "";
  billingInfo.info.cidade = "";
  billingInfo.info.estado = "";
  billingInfo.info.rua = "";
};

const fillBillingInfoCEPData = (CEPData: CEPData) => {
  billingInfo.info.cp = CEPData.cep;
  billingInfo.info.bairro = CEPData.bairro;
  billingInfo.info.cidade = CEPData.localidade;
  billingInfo.info.estado = CEPData.uf;
  billingInfo.info.rua = CEPData.logradouro;
};

const completeCEPData = async (cep: string) => {
  try {
    allowEmptyCPValues.value = false;

    CEPDataError.value = false;
    loadingCEPData.value = true;

    v$.value.info.cp.$validate();

    if (v$.value.info.cp.$error) return;

    clearBillingInfoCEPData();
    resetBillingInfoCEPData();

    const CEPData = await getCEPData(cep);

    fillBillingInfoCEPData(CEPData);
    allowEmptyCPValues.value = true;
    updateBillingInfoEditableFields();
  } catch (e) {
    allowEmptyCPValues.value = false;

    notify({ title: t("notify.errorPostalCode"), theme: "info" });
    CEPDataError.value = true;
  } finally {
    loadingCEPData.value = false;
  }
};
</script>

<i18n lang="json">
{
  "es": {
    "close": "Cancelar",
    "save": "Guardar cambios",
    "emailLabel": "Email administrativo",
    "legalNameLabel": "Razón social o Nombre",
    "legalNamePlaceholder": "Compañia S.R.L.",
    "fiscalIdLabel": "CNPJ o CPF",
    "fieldRequired": "Campo requerido.",
    "invalidFiscalId": "Utilice el formato 00.000.000/0000-00 o 000.000.000-00",
    "invalidEmail": "El email ingresado es inválido.",
    "invalidCEP": "El CEP es inválido.",
    "notify": {
      "success": "Información actualizada con éxito",
      "errorPostalCode": "No pudimos completar los datos con el código postal ingresado"
    },
    "saveError": {
      "title": "Error",
      "text": "No se pudieron actualizar los datos de facturación."
    }
  },
  "pt": {
    "close": "Cancelar",
    "save": "Salvar alterações",
    "emailLabel": "E-mail administrativo",
    "legalNameLabel": "Razão social ou Nome",
    "legalNamePlaceholder": "Empresa S.A.",
    "fiscalIdLabel": "CNPJ ou CPF",
    "fieldRequired": "Campo obrigatório.",
    "invalidFiscalId": "Utilice o formato 00.000.000/0000-00 ou 000.000.000-00",
    "invalidEmail": "O e-mail inserido é inválido.",
    "invalidCEP": "O CEP é inválido.",
    "notify": {
      "success": "Informações atualizadas com sucesso",
      "errorPostalCode": "Não foi possível completar os dados com o CEP inserido"
    },
    "saveError": {
      "title": "Erro",
      "text": "Não foi possível atualizar os dados de faturamento."
    }
  }
}
</i18n>
