<template>
  <div :class="[{ 'border border-gray-200 sm:rounded-lg': panelMode }, 'bg-white']">
    <div :class="[{ 'px-4 py-4 sm:p-6': panelMode }]">
      <h3 class="m-0 text-base font-medium leading-6 text-gray-900">{{ t("billingInfo") }}</h3>
      <div class="mt-5">
        <BillingInfoForm v-show="formOpen" @update-info="updateInfo" @no-info="noInfo" @close="closeForm" />
        <div v-if="billingInfoIsEmpty" v-show="!formOpen" class="mt-1 sm:flex sm:items-center sm:justify-between">
          <AlertBox theme="warning" class="w-full">
            {{ t("emptyBillingInfo") }}
          </AlertBox>
          <div class="sm:mt-x0 mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
            <SimpleButton @click="openForm">{{ t("openForm") }}</SimpleButton>
          </div>
        </div>
        <div
          v-else
          v-show="!formOpen"
          class="mt-1 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-center sm:justify-between"
        >
          <div class="max-w-xl">
            <div class="sm:flex sm:items-center">
              <IdentificationIcon class="h-8 w-auto text-gray-400 sm:h-8 sm:flex-shrink-0" />
              <div class="mt-3 sm:ml-4 sm:mt-0">
                <div v-if="!isLoading" class="text-sm font-medium text-gray-900">
                  {{ billingInfo.name }}
                </div>
                <div v-else class="h-4 w-32 animate-pulse rounded-full bg-gray-100" />

                <div v-if="!isLoading" class="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                  <div>
                    {{ billingInfo.fiscal_id }}
                  </div>
                  <span class="sm:mx-2 sm:inline" aria-hidden="true"> &middot; </span>
                  <div class="mt-1 text-gray-400 sm:mt-0">{{ billingInfo.email }}</div>
                </div>
                <div v-else class="mt-3 h-4 w-60 animate-pulse rounded-full bg-gray-100" />
              </div>
            </div>
          </div>
          <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <SimpleButton :theme="billingInfoIsEmpty ? 'primary' : 'white'" @click="openForm">{{
              t("openForm")
            }}</SimpleButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { IdentificationIcon } from "@heroicons/vue/outline";
// Components
import BillingInfoForm from "./BillingInfoForm.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Type
import type { BillingInfoShowPanel } from "@/vue/types/billingInfo";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const isLoading = false;

const props = withDefaults(
  defineProps<{
    open?: boolean;
    panelMode?: boolean;
  }>(),
  {
    open: false,
    panelMode: true,
  },
);

const emit = defineEmits<{
  updateInfo: [BillingInfoShowPanel];
  noInfo: [void];
  "update:open": [boolean];
}>();

const billingInfo = ref<BillingInfoShowPanel>({
  email: "",
  name: "",
  fiscal_id: "",
});

const billingInfoIsEmpty = computed(() => {
  return Object.values(billingInfo.value).every((value) => value === "");
});

const noInfo = () => {
  emit("noInfo");
};

const updateInfo = (info: BillingInfoShowPanel) => {
  billingInfo.value = info;
  emit("updateInfo", info);
};

const formOpen = ref(props.open);
const openForm = () => {
  emit("update:open", true);
  formOpen.value = true;
};

const closeForm = () => {
  emit("update:open", false);
  formOpen.value = false;
};

watch([() => props.open], () => {
  formOpen.value = props.open;
});

defineExpose({ formOpen });
</script>

<i18n lang="json">
{
  "es": {
    "billingInfo": "Datos de facturación",
    "emptyBillingInfo": "Completa la información fiscal para que podamos enviarte la factura por el plan contratado.",
    "openForm": "Modificar"
  },
  "pt": {
    "billingInfo": "Informações de faturamento",
    "emptyBillingInfo": "Preencha as informações fiscais para que possamos enviar a fatura do plano contratado.",
    "openForm": "Modificar"
  }
}
</i18n>
