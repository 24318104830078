<template>
  <div
    class="flex w-full items-center justify-between space-x-10 rounded-md bg-gray-600 px-4 py-3 md:w-[60%] xl:w-[45%] xl:space-x-0 2xl:w-[40%]"
  >
    <div class="flex items-center space-x-2">
      <template v-if="count > 0">
        <span class="text-sm text-white">{{ t("templatesSelected", { count, total }, { plural: count }) }}</span>
      </template>
      <IconButton
        v-if="count < total"
        :label="t('selectAllButton')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="selectAll"
      >
        <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
      </IconButton>
    </div>
    <div class="flex space-x-4">
      <IconButton
        v-show="count === 1"
        :label="t('duplicateButton')"
        size="min"
        theme="none"
        class="bg-gray-600 !py-0"
        @click="duplicate"
      >
        <ClipboardDocumentListIcon class="h-6 w-6" />
      </IconButton>
      <TagsSelectorMenu
        :open="tagSelectorOpen"
        :tags="tags"
        :selected-tags="selectedTags"
        @toggle-tag="toggleTagsSelection"
        @open="openTags"
        @close="closeTags"
      >
        <template #button-content>
          <IconButton :label="t('editTagsButton')" size="min" theme="none" class="bg-gray-600">
            <TagIcon class="h-5 w-5 text-gray-400 hover:text-white" />
          </IconButton>
        </template>
      </TagsSelectorMenu>
      <PermissionsIconButton
        :permissions="['templates:delete']"
        :label="t('deleteTemplatesButton')"
        size="min"
        theme="none"
        class="bg-gray-600"
        @click="openDeleteFontAlertModal"
      >
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </PermissionsIconButton>
      <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="close">
        <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
      </IconButton>
    </div>
    <ConfirmationModal
      id="deleteTemplatesAlertModal"
      v-bind="deleteTemplatesAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteFontAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, watchEffect } from "vue";

// Components
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import TagsSelectorMenu from "./TagsSelectorMenu.vue";
import ConfirmationModal from "@/vue/components/molecules/ConfirmationModal.vue";

// Icon
import { DocumentIcon, DocumentDuplicateIcon, TagIcon, TrashIcon, XIcon } from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// I18n
import { useI18n } from "vue-i18n";
import { Tag, Tags } from "@domain/tag";

// Types
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    count: number;
    total: number;
    tags: Tags;
    selectedTags: Tags;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: "selectAll"): void;
  (e: "toggleTagsSelection", tag: Tag): void;
  (e: "deleteSelected"): void;
  (e: "close"): void;
  (e: "duplicate"): void;
}>();

const selectAll = () => {
  emit("selectAll");
};

const toggleTagsSelection = (tag: Tag) => {
  emit("toggleTagsSelection", tag);
};

const close = () => {
  emit("close");
};

const duplicate = () => {
  emit("duplicate");
};

// Delete font
const deleteTemplatesAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteTemplatesAlert.confirmationTitle"),
  message: t("deleteTemplatesAlert.confirmationMessage", { count: props.count }, { plural: props.count }),
  acceptText: t("deleteTemplatesAlert.confirmButton"),
  cancelText: t("deleteTemplatesAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteTemplatesAlertModal.open = false;
};
const openDeleteFontAlertModal = () => {
  deleteTemplatesAlertModal.message = t(
    "deleteTemplatesAlert.confirmationMessage",
    { count: props.count },
    { plural: props.count },
  );
  deleteTemplatesAlertModal.open = true;
};

const deleteSelected = () => {
  deleteTemplatesAlertModal.acceptLoading = true;
  emit("deleteSelected");
};

const tagSelectorOpen = ref(false);
const openTags = () => {
  tagSelectorOpen.value = true;
};
const closeTags = () => {
  tagSelectorOpen.value = false;
};

watchEffect(() => {
  if (props.count === 0) {
    deleteTemplatesAlertModal.acceptLoading = false;
    tagSelectorOpen.value = false;
    closeDeleteFontAlertModal();
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "templatesSelected": "1 plantilla seleccionada de {total} | {count} plantillas seleccionadas de {total}",
    "selectAllButton": "Seleccionar todo",
    "editTagsButton": "Etiquetas",
    "deleteTemplatesButton": "Eliminar",
    "duplicateButton": "Duplicar",
    "closeButton": "Cancelar",
    "deleteTemplatesAlert": {
      "confirmationTitle": "Eliminar plantillas",
      "confirmationMessage": "Se eliminará {count} plantilla seleccionada. ¿Deseas continuar? | Se eliminaran {count} plantillas seleccionadas. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "templatesSelected": "1 template de {total} | {count} templates de {total}",
    "selectAllButton": "Seleccionar tudo",
    "editTagsButton": "Etiquetas",
    "deleteTemplatesButton": "Excluir",
    "duplicateButton": "Duplicar",
    "closeButton": "Cancelar",
    "deleteTemplatesAlert": {
      "confirmationTitle": "Excluir templates",
      "confirmationMessage": "Será excluído {count} template selecionado. Deseja continuar? | Serão excluídos {count} templates selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
