<template>
  <div class="flex rounded-md shadow-sm">
    <div class="relative flex items-stretch flex-grow focus-within:z-10">
      <input
        type="text"
        class="focus:ring-sky-500 focus:border-sky-500 block w-full rounded-none rounded-l-md pl-4 sm:text-sm border-gray-300"
        :value="fileName"
        :placeholder="t('placeholder')"
        readonly
      />
      <input ref="inputFileRef" class="hidden" :accept="fileTypes" type="file" @change="onChange" />
    </div>

    <button
      v-if="fileName"
      class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium whitespace-nowrap rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
      @click.prevent="clearFile"
    >
      <TrashIcon class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
      {{ t("delete") }}
    </button>

    <button
      v-else
      class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium whitespace-nowrap rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-sky-500 focus:border-sky-500"
      :disabled="loading"
      :loading="loading"
      @click.prevent="uploadFile"
    >
      <UploadIcon v-if="!loading" class="h-5 w-5 mr-2 text-gray-400" aria-hidden="true" />
      <span v-if="!loading" class="text-gray-700">{{ t("upload") }}</span>
      <LoadingSpinner v-if="loading" class="h-5 w-5 mr-2 text-sky-500" aria-hidden="true" />
      <span v-if="loading" class="text-gray-400">{{ t("uploading") }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { UploadIcon, TrashIcon } from "@heroicons/vue/solid";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Props
withDefaults(
  defineProps<{
    modelValue: File;
    fileName: string;
    fileTypes: string;
    loading: boolean;
  }>(),
  {
    modelValue: () => ({} as File),
    fileName: "",
    fileTypes: "",
    loading: false,
  }
);

// Language
const { t } = useI18n();

// Emits
const emit = defineEmits<{
  (e: "update:modelValue", value: File): void;
  (e: "clear"): void;
}>();

// Events
const inputFileRef = ref<HTMLInputElement | null>(null);

// Methods
const onChange = (e: Event) => {
  const eventFiles = (e.target as HTMLInputElement).files;
  const file = eventFiles?.item(0);
  if (file) {
    emit("update:modelValue", file);
  }
};

const uploadFile = () => {
  if (inputFileRef.value) {
    inputFileRef.value.click();
  }
};

const clearFile = () => {
  emit("clear");
};
</script>

<i18n lang="json">
{
  "es": {
    "upload": "Subir",
    "uploading": "Subiendo...",
    "delete": "Eliminar",
    "placeholder": ".png, .gif, .jpeg"
  },
  "pt": {
    "upload": "Enviar",
    "uploading": "Enviando...",
    "delete": "Excluir",
    "placeholder": ".png, .gif, .jpeg"
  }
}
</i18n>
