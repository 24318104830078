<template>
  <div>
    <ArgentinaForm
      v-if="country === 'ar'"
      :billing-data="(billingData as ArgentinaBillingInfoData)"
      @update-billing-data="updateBillingInfo"
      @close="close"
    />
    <BrazilForm
      v-else-if="country === 'br'"
      :billing-data="(billingData as BrazilBillingInfoData)"
      @update-billing-data="updateBillingInfo"
      @close="close"
    />
    <MexicoForm
      v-else-if="country === 'mx'"
      :billing-data="(billingData as MexicoBillingInfoData)"
      @update-billing-data="updateBillingInfo"
      @close="close"
    />
    <GenericForm
      v-else
      :country="country"
      :billing-data="(billingData as GenericBillingInfoData)"
      @update-billing-data="updateBillingInfo"
      @close="close"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, onMounted } from "vue";

// Components
import ArgentinaForm from "./BillingInfoForms/ArgentinaForm.vue";
import BrazilForm from "./BillingInfoForms/BrazilForm.vue";
import MexicoForm from "./BillingInfoForms/MexicoForm.vue";
import GenericForm from "./BillingInfoForms/GenericForm.vue";

// Utils
import { cloneDeep } from "lodash";

// Store
import { useSessionStore } from "@store";

// Service
import { useBillingInfoService } from "@services";

// Store
import { useAlertStore } from "@store";

// Domain
import {
  type ArgentinaBillingInfoData,
  type BrazilBillingInfoData,
  type MexicoBillingInfoData,
  type GenericBillingInfoData,
  type BillingInfoData,
  type BillingInfoShowPanel,
  isEmptyBillingInfo,
} from "@domain/billingInfo";

const alertStore = useAlertStore();
const sessionStore = useSessionStore();
const billingInfoService = useBillingInfoService();

const emit = defineEmits<{
  close: [void];
  updateInfo: [BillingInfoShowPanel];
  noInfo: [void];
}>();

const close = () => {
  emit("close");
};

const country = computed(() => sessionStore.session.account.country);

const billingData = ref<BillingInfoData>({
  country: "ar",
  email: "",
  info: {
    name: "",
    fiscal_id: "",
    fiscal_id_clean: "",
    vat_id: ""
  },
});

const updateBillingInfo = async (info: BillingInfoData) => {
  await alertStore.update();
  billingData.value = cloneDeep(info);
  emit("updateInfo", {
    email: info.email,
    name: info.info.name,
    fiscal_id: info.info.fiscal_id,
  });
};

onMounted(async () => {
  try {
    const res = await billingInfoService.get();
    if (res.isErr() || isEmptyBillingInfo(res.value)) {
      emit("noInfo");
      return;
    }

    billingData.value = res.value;

    emit("updateInfo", {
      email: billingData.value.email,
      name: billingData.value.info.name,
      fiscal_id: billingData.value.info.fiscal_id,
    });
  } catch {
    emit("noInfo");
  }
});
</script>
