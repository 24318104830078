<template>
  <div class="flex flex-col space-y-8 py-2">
    <h3 class="m-0 text-base font-medium text-gray-700">{{ t("title") }}</h3>
    <SendersManager showArticle />
  </div>
</template>

<script lang="ts" setup>
// Components
import SendersManager from "@organisms/Senders/SendersManager.vue";

// Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Para comenzar a hacer envíos primero debes registrar y configurar un remitente."
  },
  "pt": {
    "title": "Para começar a enviar, primeiro você precisa registrar e configurar um remetente."
  }
}
</i18n>
