import { Collection } from "@/core/magnet";
import AccountsModel from "./model";

export default Collection.extend({
  url: "accounts",
  model: AccountsModel,
  parse: function (response) {
    return response.data.map(function (account) {
      account.id = account.code;
      return account;
    });
  },
});
