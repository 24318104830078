import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Model.extend({
  urlRoot: "emailtemplates",
  parse: function (emailTemplate) {
    if (_.isDefined(emailTemplate.data)) {
      emailTemplate = emailTemplate.data;
    }
    return emailTemplate;
  },

  // Save automation template
  loadJsonContent: function () {
    return $.ajax({
      url: "emailtemplates/" + this.get("id") + "/contents/json",
      type: "GET",
    });
  },

  // Save automation template
  saveJsonContent: function (json) {
    return $.ajax({
      url: "emailtemplates/" + this.get("id") + "/contents/json",
      type: "PUT",
      data: JSON.stringify({ content: json }),
      contentType: "application/json",
    });
  },

  // Save automation template
  saveHtmlContent: function (html) {
    return $.ajax({
      url: "emailtemplates/" + this.get("id") + "/contents/html",
      type: "PUT",
      data: JSON.stringify({ content: html }),
      contentType: "application/json",
    });
  },

  // Send test emails
  sendTest: function (data) {
    return $.ajax({
      url: "emailtemplates/" + this.get("id") + "/sendtest",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    });
  },
});
