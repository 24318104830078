<template>
  <ModalDialog title="Administrar remitentes" :open="isOpen" @close="$emit('close')">
    <div class="w-min max-w-full" style="min-width: 65rem">
      <SendersManager
        @sender-created="$emit('senderCreated', $event)"
        @sender-updated="$emit('senderUpdated', $event)"
        @sender-deleted="$emit('senderDeleted', $event)"
      />
    </div>
  </ModalDialog>
</template>

<script>
import SendersManager from "@organisms/Senders/SendersManager.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

export default {
  name: "SendersManagerModal",
  components: { SendersManager, ModalDialog },
  props: {
    isOpen: { type: Boolean, default: false },
  },
  emits: ["close", "senderCreated", "senderUpdated", "senderDeleted"],
};
</script>
