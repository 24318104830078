<template>
  <div
    class="px-4 py-5 sm:p-6 grid grid-cols-1 rounded-lg place-content-start overflow-hidden cursor-pointer content-between"
    :class="
      readonly
        ? 'bg-gray-50 hover:bg-gray-100 hover:border-1 hover:border-gray-400'
        : 'bg-white border-2 border-gray-100 border-solid  hover:border-sky-200 hover:shadow '
    "
    @click="emitValue"
  >
    <div>
      <div
        :class="readonly ? 'text-gray-400' : 'text-sky-400'"
        class="text-base font-medium leading-6"
        v-html="highlightedTitle"
      />

      <div class="mt-2 text-gray-400 text-sm leading-5 font-normal" v-html="highlightedDescription" />
    </div>

    <div class="mt-6">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { toRegex } from "diacritic-regex";

// Props
const props = withDefaults(
  defineProps<{
    title: string;
    description: string;
    highlightKeyword?: string;
    readonly?: boolean;
  }>(),
  {
    title: "",
    description: "",
    highlightKeyword: "",
    readonly: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "actionClick"): void;
}>();

const emitValue = () => {
  emit("actionClick");
};

const highlight = (text: string, keyword: string) =>
  text.replace(toRegex({ flags: "gi" })(keyword), (match: string) => {
    return '<span class="bg-yellow-100">' + match + "</span>";
  });

const highlightedTitle = computed(() =>
  props.highlightKeyword ? highlight(props.title, props.highlightKeyword) : props.title
);

const highlightedDescription = computed(() =>
  props.highlightKeyword ? highlight(props.description, props.highlightKeyword) : props.description
);
</script>
