// Domain
import { getBannedDomains } from "@domain/unlayer";
import type { UnlayerValidator } from "@domain/unlayer";
import { useTemplatesApp } from "@application";
import type { AuditApplication } from "./audit.type";

// Modules
import { footerContrast } from "./modules/footerAudits";

export const useTemplateAuditApp = (): AuditApplication => {
  const templatesApp = useTemplatesApp();

  const auditApp: AuditApplication = {
    footerContrast,
    isValidUrl: ({ url }) => {
      // replacement code: ${...}
      const replacementCodeRegex = /\$\{[^\}]+\}/;

      try {
        if (replacementCodeRegex.test(url)) {
          return [];
        }

        new URL(url);

        return [];
      } catch (_) {
        return [
          {
            id: "INVALID_URL",
            icon: "fa-link",
            severity: "ERROR",
            title: "invalid_url_title",
            description: "invalid_url_description",
          },
        ];
      }
    },
    unsubscribeLink: ({ html }) => {
      const hasUnsubscribeLink = html.includes("${urls.unsubscribe}");

      if (hasUnsubscribeLink) return [] as Array<UnlayerValidator>;

      return [
        {
          id: "MISSING_UNSUBSCRIBE_LINK",
          icon: "fa-link",
          severity: "ERROR",
          title: "missing_unsubscribe_link_title",
          description: "missing_unsubscribe_link_description",
        },
      ];
    },
    gmailSizeLimit: ({ html }) => {
      const sizeLimitExceededGmail = () => html.length / 1024 > 108;

      if (!sizeLimitExceededGmail()) return [] as Array<UnlayerValidator>;

      return [
        {
          id: "LIMIT_SIZE_HTML_GMAIL",
          icon: "fa-envelope",
          severity: "WARNING",
          title: "limit_size_html_gmail",
          description: "limit_size_html_gmail_description",
        },
      ];
    },
    minSubjects: () => {
      const subjectIsEmpty = window?.unlayerPerfitKit.templateData.template?.subject === "" ?? true;
      if (!subjectIsEmpty) return [] as Array<UnlayerValidator>;

      return [
        {
          id: "MISSING_SUBJECT",
          severity: "ERROR",
          icon: "fa-envelope",
          title: "missing_subject",
          description: "missing_subject_description",
        },
      ];
    },
    minSubjectsTestAB: () => {
      const notEmptySubjects =
        window?.unlayerPerfitKit.templateData.template?.options?.subjects?.filter((subject) => subject !== "") ?? [];

      if (notEmptySubjects?.length >= 2) return [] as Array<UnlayerValidator>;

      return [
        {
          id: "MISSING_SUBJECTS_TESTAB",
          severity: "ERROR",
          icon: "fa-envelope",
          title: "missing_subjects_testab",
          description: "missing_subjects_testab_description",
        },
      ];
    },
    senderSelected: () => {
      const senderSelected = window?.unlayerPerfitKit.templateData.template?.sender_id ?? false;
      if (senderSelected) return [] as Array<UnlayerValidator>;

      return [
        {
          id: "MISSING_SENDER",
          severity: "ERROR",
          icon: "fa-envelope",
          title: "missing_sender",
          description: "missing_sender_description",
        },
      ];
    },
    bannedDomains: ({ values }) => {
      const bannedDomains = getBannedDomains();

      const includesDomain = bannedDomains.some((domain) => values.href?.values?.href?.includes(domain));

      const includesActionDomain = bannedDomains.some((domain) => values.action?.values?.href?.includes(domain));

      const includesMenuDomain = bannedDomains.some((domain) =>
        values.menu?.items.some((item) => item?.link?.values?.href?.includes(domain)),
      );

      const includesNestedDomain = bannedDomains.some((domain) => {
        const links = values.html?.children;
        if (!links) return false;

        for (let i = 0; i < links.length; i++) {
          const linkTags = links.item(i)?.getElementsByTagName("a");

          if (!linkTags) continue;

          for (let j = 0; j < linkTags?.length; j++) {
            const link = linkTags.item(j);
            if (!link || !link.href) continue;

            const includesDomain = link.href.includes(domain);
            if (includesDomain) return true;
          }
        }
      });
      const includesIconDomains = bannedDomains.some((domain) =>
        values.icons?.icons.some((icon) => icon?.url?.includes(domain)),
      );

      if (
        !includesDomain &&
        !includesNestedDomain &&
        !includesIconDomains &&
        !includesActionDomain &&
        !includesMenuDomain
      )
        return [] as Array<UnlayerValidator>;

      return [
        {
          id: "SHORTENER_LINK_ERROR",
          severity: "ERROR",
          icon: "fa-link",
          title: "shortener_link_error_title",
          description: "shortener_link_error_description",
        },
      ];
    },
    utmActive: ({ values }) => {
      const utmEnabled = window?.unlayerPerfitKit.templateData.template?.tracking?.utm.enabled ?? false;

      if (!utmEnabled) return [] as Array<UnlayerValidator>;

      const isOldButton = typeof values.href === "string";

      const includesUTM = !isOldButton && values.href?.values.href.includes("utm_");

      const includesActionUTM = !isOldButton && values.action?.values.href.includes("utm_");

      const includesMenuUTM =
        !isOldButton && values.menu?.items.some((item) => item?.link?.values?.href?.includes("utm_"));

      const includesNestedUTM = (() => {
        const links = values.html?.children;
        if (!links) return false;
        for (let i = 0; i < links.length; i++) {
          const linkTags = links.item(i)?.getElementsByTagName("a");

          if (!linkTags) continue;

          for (let j = 0; j < linkTags?.length; j++) {
            const link = linkTags.item(j);
            if (!link || !link.href) continue;

            const includesDomain = link.href.includes("utm_");
            if (includesDomain) return true;
          }
        }
      })();

      const includesIconsUTM = values.icons?.icons.some((icon) => icon?.url?.includes("utm_"));

      if (!includesUTM && !includesNestedUTM && !includesIconsUTM && !includesActionUTM && !includesMenuUTM)
        return [] as Array<UnlayerValidator>;
      return [
        {
          id: "UTM_LINK",
          icon: "fa-link",
          severity: "WARNING",
          title: "utm_link_error_title",
          description: "utm_link_error_description",
        },
      ];
    },
    footerDisplayCondition: ({ design }) => {
      if (!design) return [] as Array<UnlayerValidator>;

      const footerContents = {
        slug: "footer",
        type: "custom",
      };

      const rowIndex = templatesApp.getRowIndexByContentUnlayer({ design, content: footerContents });
      if (rowIndex === -1) return [] as Array<UnlayerValidator>;

      const footerRow = design.body.rows[rowIndex];

      if (footerRow && footerRow.values?.displayCondition)
        return [
          {
            id: "FOOTER_DISPLAY_CONDITION",
            severity: "ERROR",
            icon: "fa-eye-slash",
            title: "footer_display_condition",
            description: "footer_display_condition_description",
          },
        ];

      const columnIndex = templatesApp.getColumnIndexByContentUnlayer({
        design,
        rowIndex: rowIndex,
        content: footerContents,
      });
      if (columnIndex === -1) return [] as Array<UnlayerValidator>;
      const contentIndex = templatesApp.getContentIndexUnlayer({
        design,
        rowIndex,
        columnIndex,
        content: footerContents,
      });
      if (contentIndex === -1) return [] as Array<UnlayerValidator>;

      const footer = templatesApp.getContentUnlayer({ design, rowIndex, columnIndex, contentIndex });

      if (footer && footer.values?.displayCondition)
        return [
          {
            id: "FOOTER_DISPLAY_CONDITION",
            severity: "ERROR",
            icon: "fa-eye-slash",
            title: "footer_display_condition",
            description: "footer_display_condition_description",
          },
        ];

      return [] as Array<UnlayerValidator>;
    },
  };

  return auditApp;
};
