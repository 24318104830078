<template>
  <div class="relative rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
    <h4 class="sr-only">{{ card?.issuer }}</h4>
    <div class="sm:flex sm:items-start">
      <CreditCardIcon :is-loading="isLoading" :brand="card?.issuer" class="h-12 w-auto sm:h-10 sm:flex-shrink-0" />
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <div v-if="!isLoading" class="text-sm font-medium text-gray-900">
          {{ t("endingWith") }} {{ card?.lastDigits }}
        </div>
        <div v-else class="h-4 w-32 animate-pulse rounded-full bg-gray-100" />

        <div
          v-if="!isLoading"
          class="mt-1 text-sm text-gray-600 sm:flex sm:items-center"
          :class="card?.isExpired || card?.isExpiring ? 'text-red-600' : 'text-gray-600'"
        >
          <div>
            {{ card?.isExpired ? t("expiredOn") : t("expiresOn") }}
            {{ card?.expirationMonth }}/{{ card?.expirationYear }}
          </div>
          <span class="sm:mx-2 sm:inline" aria-hidden="true"> &middot; </span>
          <div class="mt-1 text-gray-400 sm:mt-0">{{ t("registered") }} {{ formattedDate }}</div>
        </div>
        <div v-else class="mt-3 h-4 w-60 animate-pulse rounded-full bg-gray-100" />
      </div>
    </div>
    <div v-if="!isLoading" class="mt-4 space-x-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
      <SimpleButton v-if="!disallowDelete" theme="white" :disabled="disabled" @click="startDelete">{{
        isUpdate ? (updatable ? t("delete") : t("deleteOrReplace")) : t("replace")
      }}</SimpleButton>
      <SimpleButton v-if="updatable && isUpdate" theme="white" :disabled="disabled" @click="$emit('updateCard')">
        {{ t("replace") }}
      </SimpleButton>
    </div>

    <transition
      enter-active-class="duration-200 ease-out transform"
      enter-from-class="opacity-0 translate-x-4"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="confirmingDelete"
        class="absolute inset-0 flex justify-center bg-opacity-50 backdrop-blur-sm backdrop-filter"
      >
        <div class="flex space-x-2 self-center">
          <SimpleButton ref="confirmDeleteButtonRef" theme="danger" size="small" @click="deleteCard">
            {{ t("deleteCardEndingWith", { lastDigits: card?.lastDigits }) }}
            <template #leading>
              <TrashIcon />
            </template>
          </SimpleButton>
          <SimpleButton theme="white" size="small">{{ t("betterNot") }}</SimpleButton>
        </div>
      </div>
    </transition>
  </div>
  <div v-if="!isLoading">
    <AlertBox v-if="card?.status === 'invalid'" theme="danger" class="mt-4">
      {{ t("alerts.invalid") }}
    </AlertBox>
    <AlertBox v-else-if="card?.isExpired" theme="danger" class="mt-4">
      {{ t("alerts.expired") }} {{ t("alerts.replaceCard") }}
    </AlertBox>
    <AlertBox v-else-if="card?.isExpiring" theme="warning" class="mt-4">
      {{ t("alerts.expiring") }} {{ t("alerts.replaceCard") }}
    </AlertBox>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { onClickOutside } from "@vueuse/core";
import { TrashIcon } from "@heroicons/vue/outline";

import { formatDate } from "@helpers/formatters";
import { CreditCard } from "@api/models/CreditCard";
import CreditCardIcon from "@atoms/CreditCardIcon.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

export default defineComponent({
  name: "CreditCardPanel",
  components: { CreditCardIcon, SimpleButton, AlertBox, TrashIcon },
  props: {
    card: { type: Object as PropType<CreditCard>, default: null },
    isUpdate: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    updatable: { type: Boolean, default: false },
    disallowDelete: { type: Boolean, default: false },
  },
  emits: ["deleteCard", "updateCard"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formattedDate = computed(() => {
      if (!props.card) return "";
      return formatDate(props.card.created, "PP", { locale: "es" });
    });

    const confirmingDelete = ref(false);
    const deleteCard = () => {
      emit("deleteCard");
    };

    const confirmDeleteButtonRef = ref(null);
    const startDelete = () => {
      confirmingDelete.value = true;
      onClickOutside(confirmDeleteButtonRef, () => {
        confirmingDelete.value = false;
      });
    };

    return {
      formattedDate,
      t,
      confirmingDelete,
      deleteCard,
      startDelete,
      confirmDeleteButtonRef,
    };
  },
});
</script>

<i18n lang="json">
{
  "es": {
    "endingWith": "Terminada en",
    "expiresOn": "Vence el",
    "registered": "Registrada el",
    "replace": "Modificar",
    "delete": "Eliminar",
    "deleteOrReplace": "Eliminar o modificar",
    "deleteCardEndingWith": "¿Eliminar tarjeta terminada en {lastDigits}?",
    "expiredOn": "Venció el",
    "alerts": {
      "expiring": "Se acerca la fecha de vencimiento de tu tarjeta.",
      "expired": "Parece que tu tarjeta ha vencido.",
      "invalid": "No hemos podido realizar cobros a la tarjeta indicada. Por favor, ingresa una tarjeta válida para evitar la suspensión del servicio.",
      "replaceCard": "Por favor, ingresa una tarjeta válida para evitar rechazos en los pagos y la posible suspensión del servicio."
    }
  },
  "pt": {
    "endingWith": "Termina em",
    "expiresOn": "Validade",
    "registered": "Cadastrada em",
    "replace": "Modificar",
    "delete": "Eliminar",
    "deleteOrReplace": "Eliminar ou modificar",
    "deleteCardEndingWith": "Eliminar cartão terminado em {lastDigits}?",
    "expiredOn": "Venceu em",
    "alerts": {
      "expiring": "A data de validade do seu cartão está próxima.",
      "expired": "Parece que seu cartão está vencido.",
      "invalid": "Não foi possível realizar o pagamento com o cartão indicado. Por favor, cadastre um cartão válido para evitar a suspensão do serviço.",
      "replaceCard": "Por favor, cadastre um cartão válido para evitar o não pagamento e a possível suspensão do serviço."
    }
  }
}
</i18n>
