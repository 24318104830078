import { ListView } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";

export default ListView.extend({
  module: "images",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  gender: "F",
  cache: false,
  paginate: true,
  limit: 32,
  query: {
    order: "created desc",
  },
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "dragover [data-role=dropzone]": "prevent",
      "click [data-action=open]": "open",
      "click [data-action=open-create-folder-modal]": "openCreateFolderModal",
      "submit [data-form=create-folder]": "createFolder",
      "click [data-action=open-rename-modal]": "openRenameModal",
      "submit [data-form=rename]": "rename",
      "click [data-action=open-move-modal]": "openMoveModal",
      "click [data-action=explore-folders]": "exploreFolders",
      "submit [data-form=move]": "move",
      "click [data-action=open-create-image-modal]": "openCreateImageModal",
      "dragenter [data-role=dropzone]": "addDropHighlight",
      "dragleave [data-role=dropzone]": "removeDropHighlight",
      "drop [data-role=dropzone]": "getDroppedFiles",
      "click [data-role=uploader]": "openFileSelection",
      "change [data-input-type=upload-file]": "getSelectedFiles",
      "submit [data-form=import-image]": "importImage",
    });
  },

  renderData: function () {
    const unlayerEnabled =
      app.session.attributes.details?.account?.features?.UNLAYER_OPTIONAL === "1" ||
      app.session.attributes.details?.account?.features?.UNLAYER_DEFAULT === "1";
    return { ...ListView.prototype.renderData.call(this), unlayerEnabled };
  },

  // Runs custom actions after renderization
  afterRenderPage: function () {
    var self = this;

    // Save references to common elements
    self.uploader = {
      progressBar: ".upload-progress",
      loading: ".loading",
      container: ".upload-container",
      dropzone: ".dropzone",
    };

    // Send events
    self.broadcast();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Adds dividers
  afterRenderItems: function () {
    var area = $(this.renderArea),
      lastFolder = area.find(".folder").last().parent();
    area.find(".divider").remove();
    $('<li class="divider">').insertAfter(lastFolder);
  },

  // Emmit events
  broadcast: function () {
    var self = this;
    if (self.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_IMAGES");
    }
    app.integrations.amplitude.event("APP_IMAGES_VISIT");
  },

  // Adds a filter parameter to the URL
  filter: function (e) {
    var self = this,
      hash,
      params = $(e.currentTarget).data("params");
    e.preventDefault();

    // If the filter is a location, erase the search
    if (params.key === "filter.location") {
      hash = self.unfilter({ key: "q" });
      self.urlFilter(params, hash);
      return self;
    }

    // Add filter
    self.urlFilter(params);
  },

  // Shows action buttons according to the checked elements
  toggleActions: function () {
    var self = this,
      toolbar = self.$("[data-role=toolbar]"),
      none = self.$("[data-type=none]"),
      justOne = self.$("[data-type=just-one]"),
      onePlus = self.$("[data-type=one-plus]"),
      twoPlus = self.$("[data-type=two-plus]"),
      checkboxes = self.$("[data-action=check]"),
      count = checkboxes.filter(":checked").length;

    // Hide buttons when no items are checked
    if (count === 0) {
      none.removeClass("hide");
      justOne.addClass("hide");
      onePlus.addClass("hide");
      twoPlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Show actions when just one item is checked
    if (count === 1) {
      none.addClass("hide");
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      twoPlus.addClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Show actions when two or more items are checked
    if (count > 1) {
      none.addClass("hide");
      justOne.addClass("hide");
      onePlus.removeClass("hide");
      twoPlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Hide move action if a folder is selected
    if (checkboxes.filter("[data-item-type=FOLDER]").is(":checked")) {
      self.$("[data-action=open-move-modal]").addClass("hide");
    }
  },

  // Prevents unwanted events
  prevent: function (e) {
    e.preventDefault();
  },

  // Opens the public image in a new tab
  open: function (e) {
    var publicUrl = $(e.currentTarget).data("public-url");
    e.preventDefault();
    window.open(publicUrl);
  },

  // Opens a folder modal
  openCreateFolderModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/folder.hbs"), self.renderData());
  },

  // Creates a folder based on the modal options
  createFolder: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = self.collection.currentUrl,
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      success: function (response) {
        // Close modal
        modal.modal("hide");

        // Display feedback
        self.displayMessage(window.lang.images.feedback.folderCreated);

        // Reload current section
        self.urlFilter({
          key: "filter.location",
          value: response.data.id,
        });
      },
      error: function (xhr) {
        var error = xhr.responseJSON.error;

        // Show remote validation errors
        if (error.validationErrors !== undefined) {
          return self.showValidationErrors(error.validationErrors);
        }
      },
    });

    app.integrations.amplitude.event("APP_IMAGES_CREATE_FOLDER");
  },

  // Opens a rename file modal
  openRenameModal: function () {
    var self = this,
      data = {
        lang: window.lang,
        data: self.getFirstChecked(),
      };
    self.openModal(require("../templates/modals/rename.hbs"), data);
  },

  // Renames a folder or file
  rename: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = "images" + self.getFirstChecked().id,
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "PUT",
      url: url,
      data: data,
      success: function () {
        // Reload current section
        self.reload();

        // Close modal
        modal.modal("hide");
      },
      error: function (xhr) {
        var error = xhr.responseJSON.error;

        // Show remote validation errors
        if (error.validationErrors !== undefined) {
          return self.showValidationErrors(error.validationErrors);
        }
      },
    });
  },

  // Opens a modal to move items
  openMoveModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/move.hbs"), self.renderData());
    self.exploreFolders();
  },

  // Explores the shared folders
  exploreFolders: function (e) {
    var self = this,
      template = require("../templates/modals/folders.hbs"),
      target = self.$("[data-role=folder-list]"),
      path = "",
      parentFolder = false,
      targetName = window.lang.root,
      targetLocation = "/",
      moveButtonText = $(self.modal).find("[type=submit]").find("span"),
      locationInput = $(self.modal).find("[name=location]");

    // If needed, prevent the event default
    if (e && e.preventDefault !== undefined) {
      e.preventDefault();

      // Get path parameter
      path = $(e.currentTarget).data("path");

      // Get parent folder path
      if (path !== "/") {
        parentFolder = path.substring(0, path.lastIndexOf("/") + 1);
      }

      // Get folder name
      targetName = $(e.currentTarget).data("name");

      // Get folder ID
      targetLocation = $(e.currentTarget).data("path");
    }

    // Turn on loader
    self.loading(true);

    // Request folder list
    $.ajax({
      url: "images" + path,
      data: {
        filter: {
          type: "folder",
        },
        limit: 1000,
      },
      success: function (response) {
        // Render explorer
        target.html(
          template({
            data: response.data,
            parentFolder: parentFolder,
            targetName: targetName,
            lang: window.lang,
          })
        );

        // Write target folder name
        moveButtonText.text(targetName);

        // Set value of location input
        locationInput.val(targetLocation);

        // Turn off loader
        self.loading(false);
      },
    });
  },

  // Move templates
  move: function (e) {
    var self = this,
      form = $(e.currentTarget),
      items = self.getChecked(),
      data = form.serializeObject(),
      deferreds = [];
    e.preventDefault();

    // Create array of deferreds
    $.each(items, function (key, item) {
      deferreds.push(
        $.ajax({
          type: "PUT",
          data: data,
          url: "images" + item.id,
        })
      );
    });

    // Process deferreds calls and render
    $.when.apply(null, deferreds).always(function () {
      // Hide modal
      $(self.modal).modal("hide");

      // Refresh the list when done
      self.reload();
    });

    app.integrations.amplitude.event("APP_IMAGES_MOVE");
  },

  // Opens an image modal
  openCreateImageModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/file.hbs"), self.renderData());
  },

  // Add active class to hovered dropzone
  addDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).addClass("active");
  },

  // Remove active class from hovered dropzone
  removeDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).removeClass("active");
  },

  // Manages dropped files
  getDroppedFiles: function (e) {
    var self = this,
      files = e.originalEvent.dataTransfer.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.uploadImages(files);
  },

  // Open the file selector
  openFileSelection: function () {
    $("[data-input-type=upload-file]").trigger("click");
  },

  // Manage selected files
  getSelectedFiles: function (e) {
    var self = this,
      files = e.currentTarget.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.uploadImages(files);
  },

  // Uploads an image
  uploadImages: function (files) {
    var self = this,
      deferreds = [],
      template = require("../templates/modals/preview.hbs"),
      previewBox = $(".upload-preview"),
      html = "";

    // Hide dropzone
    $(self.uploader.dropzone).addClass("hidden");

    _.each(files, function (file) {
      file.hasValidSize = self.isValidSize(file.size);
    });

    // Render preview zone
    html = template({
      files: files,
      lang: window.lang,
      maxSize: window.config.imageMaxSize,
    });
    previewBox.html(html);

    // When the user closes the modal, reload
    self.getModal().on("hidden.bs.modal", function () {
      self.getModal().off("hidden.bs.modal");
      self.reload();
    });

    // Create deferreds
    $.each(files, function (index, file) {
      var formData = new FormData();

      // Skip if the size is invalid
      if (!file.hasValidSize) {
        return true;
      }

      // Add file to form data
      formData.append("image", file);
      formData.append("type", "IMAGE");

      // Upload file via AJAX
      deferreds.push(
        $.ajax({
          type: "POST",
          url: self.collection.currentUrl,
          dataType: "json",
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: false,
          xhr: function () {
            var xhr = $.ajaxSettings.xhr();
            if (xhr.upload) {
              xhr.upload.addEventListener(
                "progress",
                function (e) {
                  var percent = 0,
                    position = e.loaded || e.position,
                    total = e.total;
                  if (e.lengthComputable) {
                    percent = Math.ceil((position / total) * 100);
                  }
                  self.uploadImageProgress(index, percent);
                },
                false
              );
            }
            return xhr;
          },
          success: function (response) {
            self.uploadImageSuccess(index, response);
          },
        })
      );
    });

    // Process deferreds calls and render
    $.when.apply(null, deferreds);

    app.integrations.amplitude.event("APP_IMAGES_UPLOAD");
  },

  // Updates progress bar
  uploadImageProgress: function (index, percent) {
    $("#progress-" + index)
      .css("width", percent + "%")
      .text(percent + "%");
  },

  // Shows uploaded image
  uploadImageSuccess: function (index) {
    $("#icon-" + index).removeClass("hidden");
  },

  // Import image
  importImage: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Make import call
    $.ajax({
      type: "POST",
      url: self.collection.currentUrl,
      data: data,
      success: function (response) {
        if (response.success) {
          // Close modal
          $(self.modal).modal("hide");

          // Display feedback
          self.displayMessage(window.lang.images.feedback.imageImported);

          // Reload
          self.reload();
        }
      },
    });

    app.integrations.amplitude.event("APP_IMAGES_UPLOAD_URL");
  },

  // Check if the file size is valid
  isValidSize: function (size) {
    return size < window.config.imageMaxSize * 1000 * 1000 ? true : false;
  },
});
