export type AlertTypes =
  | "FREE_ACCOUNT"
  | "BLOCKED_CONTACTS_EXCEEDED"
  | "BLOCKED_CONTACTS_EXCEEDED_FREE"
  | "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE"
  | "BLOCKED_EXPIRED_INVOICES"
  | "BLOCKED_ACCOUNT_DISABLED"
  | "BLOCKED_ABUSE"
  | "TRIAL_EXPIRED"
  | "TRIAL_ACTIVE"
  | "PAYMENT_CARD_REQUIRED"
  | "PAYMENT_CARD_EXPIRED"
  | "BILLING_INFO_REQUIRED"
  | "ALL_SENDERS_STATUS_NOT_OK"
  | "INVOICE_ABOUT_TO_EXPIRE"
  | "SCHEDULED_CANCEL_PLAN";

export type DismissableAlertTypes =
  | "TRIAL_EXPIRED"
  | "TRIAL_ACTIVE"
  | "FREE_ACCOUNT"
  | "ALL_SENDERS_STATUS_NOT_OK"
  | "PAYMENT_CARD_REQUIRED"
  | "PAYMENT_CARD_EXPIRED"
  | "BILLING_INFO_REQUIRED"
  | "INVOICE_ABOUT_TO_EXPIRE";

type AddDismissed<T extends string> = `${T}_DISMISSED`;

export type DismissedAlertTypes = AddDismissed<DismissableAlertTypes>;

export const isDismissableAlertType = (type: AlertTypes | DismissableAlertTypes): type is DismissableAlertTypes => {
  const dismissableTypes = [
    "TRIAL_EXPIRED",
    "TRIAL_ACTIVE",
    "PAYMENT_CARD_REQUIRED",
    "PAYMENT_CARD_EXPIRED",
    "BILLING_INFO_REQUIRED",
    "FREE_ACCOUNT",
    "ALL_SENDERS_STATUS_NOT_OK",
    "INVOICE_ABOUT_TO_EXPIRE",
  ];
  return dismissableTypes.some((dt) => dt === type);
};

export interface DismissObject {
  expirationDate: DateString;
}

export interface Message {
  type: AlertTypes;
  dismissable: boolean;
  payload?: unknown;
}
