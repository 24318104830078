import { Component } from "vue";

export type SortingModes = "ASC" | "DESC";

export interface Row<IdType = string, ColumnIdType extends string = string, DataType = undefined> {
  id: IdType;
  content?: Record<ColumnIdType, string>; // ColumnId / text
  data: DataType;
  options?: {
    selectable: boolean;
  };
}

export type Rows<IdType = string, ColumnIdType extends string = string, DataType = undefined> = Array<
  Row<IdType, ColumnIdType, DataType>
>;

export interface Column<IdType extends string = string, SortIdType extends string = string, DataType = undefined> {
  id: IdType;
  sortKey?: SortIdType;
  text?: string;
  sortModes?: Array<SortingModes>;
  textAlign?: "right" | "center" | "left" | "justify";
  headerTextAlign?: "right" | "center" | "left" | "justify";
  headerWidthRem?: number;
  breakpoint?: "sm" | "md" | "lg" | "xl" | "2xl";
  textMaxWidth?: boolean;
  tooltip?: string;
  customCell?: Component<{ row: Row<string, IdType, DataType>; column: Columns<IdType, SortIdType, DataType> }>;
  customCellSkeleton?: Component;
}

export type Columns<IdType extends string = string, SortIdType extends string = string, DataType = undefined> = Array<
  Column<IdType, SortIdType, DataType>
>;

export interface SortColumn<ColumnIdType extends string = string> {
  columnId: ColumnIdType;
  sort: SortingModes;
}

export interface OptionsProps {
  stickyTop: number;
}

export const getRowContentByColumn = <
  RowIdType extends string = string,
  ColIdType extends string = string,
  ColSortKeyType extends string = string,
  RowData = unknown,
  ColData = unknown
>(
  row: Row<RowIdType, ColIdType, RowData>,
  column: Column<ColIdType, ColSortKeyType, ColData>
): string => {
  return row.content?.[column.id] ?? "";
};

export const getRowById = <RowId = string, ColId extends string = string, RowData = unknown>(
  rows: Rows<RowId, ColId, RowData>,
  id: RowId
): Row<RowId, ColId, RowData> | undefined => {
  return rows.find((row) => row.id === id);
};
