<template>
  <div ref="templateCatalogRef" class="max-h-full overflow-y-auto">
    <div v-show="!skeleton" :id="HTMLGridID" class="mx-auto">
      <TemplateCardEmptyTemplate v-if="addEmptyTemplate" class="mb-4 w-[250px]" @select="useNewTemplate" />
      <TemplateCard
        v-for="(template, index) in templates"
        :key="template.id"
        :index="index"
        :template="template"
        :is-public="isPublic"
        :selected="isSelected(template)"
        :selectable="selectable"
        :link-card="asLink"
        :block-link="selectedTemplates.length > 0"
        :show-check-box="selectedTemplates.length > 0"
        class="mb-4 w-[250px]"
        :data-intercom-target="index === 0 ? 'TemplateGalleryFirstThumbnail' : undefined"
        @select="selectTemplate"
        @loaded="ElementLoaded"
        @toggle-selected="() => toggleSelected(template)"
      />
    </div>
    <LoadingSpinner v-show="loading" class="mx-auto my-24 h-8 w-8 text-sky-400" />
    <Skeleton v-show="skeleton" class="w-full pr-1" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect, nextTick, toRaw } from "vue";

//Components
import TemplateCard from "./components/TemplateCard.vue";
import TemplateCardEmptyTemplate from "./components/TemplateCardEmptyTemplate.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import Skeleton from "./TemplateCatalogSkeleton.vue";

//Utils
import { useInfiniteScroll } from "@vueuse/core";
import { useMasonryGrid } from "@helpers/masonry";
import { useMagicKeys } from "@vueuse/core";

//Types
import { TemplateList, TemplateItem } from "@/vue/types/Templates";
import MagicGrid from "magic-grid";

const { shift } = useMagicKeys();

//Props
const props = withDefaults(
  defineProps<{
    templates: TemplateList;
    selectedTemplates?: TemplateList;
    selectable?: boolean;
    addEmptyTemplate?: boolean;
    isPublic?: boolean;
    loading?: boolean;
    skeleton?: boolean;
    asLink?: boolean;
  }>(),
  {
    addEmptyTemplate: false,
    selectedTemplates: () => [],
    selectable: false,
    isPublic: false,
    loading: false,
    skeleton: false,
    asLink: false,
  }
);

// Emits
const emit = defineEmits<{
  select: [TemplateItem];
  selectNewTemplate: [void];
  toggleSelected: [TemplateItem];
  updateSelected: [TemplateList];
  bottom: [void];
}>();

const selectTemplate = (selectedTemplate: TemplateItem) => {
  if (props.selectedTemplates.length > 0) {
    setTimeout(() => {
      toggleSelected(selectedTemplate);
    });
    return;
  }
  emit("select", selectedTemplate);
};

const useNewTemplate = () => {
  emit("selectNewTemplate");
};

const lastTemplateSelected = ref<TemplateItem>();

const toggleSelected = (template: TemplateItem) => {
  const lastSelected = lastTemplateSelected.value;
  lastTemplateSelected.value = template;

  if (shift.value && lastSelected) {
    const lastRowIndex = props.templates.findIndex((t) => t.id === lastSelected.id);
    const currentRowIndex = props.templates.findIndex((t) => t.id === template.id);
    if (lastRowIndex === -1 || currentRowIndex === -1 || currentRowIndex === lastRowIndex) {
      emit("toggleSelected", template);
      return;
    }

    const uniqueIds = new Set<string>();

    const [start, end] = [lastRowIndex, currentRowIndex].sort((a, b) => a - b);
    const campaignsBetween = props.templates.slice(start, end + 1);

    const selectedCampaigns = [...toRaw(props.selectedTemplates), ...toRaw(campaignsBetween)];

    const filteredSelected = selectedCampaigns.filter((c) => {
      if (!uniqueIds.has(c.id.toString())) {
        uniqueIds.add(c.id.toString());
        return true;
      }
      return false;
    });

    emit("updateSelected", filteredSelected);
  } else {
    emit("toggleSelected", template);
  }
};

const bottomReached = () => emit("bottom");

// Selection
const isSelected = (template: TemplateItem) => {
  return props.selectedTemplates.some((templateSelected) => templateSelected.id === template.id);
};

//Masonry
const HTMLGridID = `vue-template-catalog-magic-grid${new Date().getTime()}`;
const elementLoadedCount = ref(0);

let magicGrid: MagicGrid;

watchEffect(() => {
  if (!props.templates || !props.templates.length) return;

  if (props.templates.length == 0) elementLoadedCount.value = 0;

  nextTick(() => {
    magicGrid = useMasonryGrid({
      container: `#${HTMLGridID}`,
      itemCount: props.templates.length + (props.addEmptyTemplate ? 1 : 0),
    });
  });
});

const ElementLoaded = () => {
  elementLoadedCount.value += 1;
  nextTick(() => {
    if (!magicGrid || !magicGrid.positionItems) return;
    magicGrid.positionItems();
  });
};

//Scroll
const templateCatalogRef = ref<HTMLDivElement>();
useInfiniteScroll(templateCatalogRef, bottomReached, { distance: 900 });
</script>
