import { createI18n, I18n } from "vue-i18n";
import es from "@locales/es.json";
import pt from "@locales/pt.json";

type MyI18n = I18n<{ es: unknown; pt: unknown }, any, any, any, false>;

export type localesLanguages = "es" | "pt" | "en";
export enum Languages {
  es = "es",
  pt = "pt",
}

export enum Locales {
  es = "es",
  pt = "pt",
  en = "en",
  "es-AR" = "es-AR",
  "es-BO" = "es-BO",
  "es-BR" = "es-BR",
  "es-CA" = "es-CA",
  "es-CL" = "es-CL",
  "es-CO" = "es-CO",
  "es-CR" = "es-CR",
  "es-ES" = "es-ES",
  "es-EC" = "es-EC",
  "es-GT" = "es-GT",
  "es-HN" = "es-HN",
  "es-MX" = "es-MX",
  "es-PA" = "es-PA",
  "es-PE" = "es-PE",
  "es-PY" = "es-PY",
  "es-UY" = "es-UY",
  "es-PT" = "es-PT",
  "es-SV" = "es-SV",
  "es-US" = "es-US",
  "es-VE" = "es-VE",
  "pt-AR" = "pt-AR",
  "pt-BR" = "pt-BR",
  "pt-PT" = "pt-PT",
  "en-AR" = "en-AR",
  "en-US" = "en-US",
  "en-BR" = "en-BR",
  "en-MX" = "en-MX",
}

export enum Currencies {
  ARS = "ARS",
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
  MXP = "MXP",
  PEN = "PEN",
  PYG = "PYG",
  UYU = "UYU",
  GTQ = "GTQ",
  HNL = "HNL",
  SVC = "SVC",
  CLP = "CLP",
  COP = "COP",
  CRC = "CRC",
  BOB = "BOB",
  CAD = "CAD",
  VEF = "VEF",
}

const BaseNumberFormats = {
  currency: {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: Currencies.ARS,
  },
  currencyNoCents: {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: Currencies.ARS,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  decimal: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
  },
  decimal0FD: {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  },
  decimal1FD: {
    style: "decimal",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    useGrouping: true,
  },
  percent: {
    style: "percent",
    useGrouping: true,
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 2,
  },
};

export const numberFormatFor = (currency: Currencies) => {
  return {
    ...BaseNumberFormats,
    currency: {
      ...BaseNumberFormats.currency,
      currency: currency,
    },
  };
};

export const defaultNumberFormats = {
  "es-AR": numberFormatFor(Currencies.ARS),
  "es-BO": numberFormatFor(Currencies.BOB),
  "es-BR": numberFormatFor(Currencies.BRL),
  "es-CA": numberFormatFor(Currencies.CAD),
  "es-CL": numberFormatFor(Currencies.CLP),
  "es-CO": numberFormatFor(Currencies.COP),
  "es-CR": numberFormatFor(Currencies.CRC),
  "es-ES": numberFormatFor(Currencies.EUR),
  "es-EC": numberFormatFor(Currencies.USD),
  "es-GT": numberFormatFor(Currencies.GTQ),
  "es-HN": numberFormatFor(Currencies.HNL),
  "es-MX": numberFormatFor(Currencies.MXP),
  "es-PA": numberFormatFor(Currencies.USD),
  "es-PE": numberFormatFor(Currencies.PEN),
  "es-PY": numberFormatFor(Currencies.PYG),
  "es-UY": numberFormatFor(Currencies.UYU),
  "es-PT": numberFormatFor(Currencies.EUR),
  "es-SV": numberFormatFor(Currencies.SVC),
  "es-US": numberFormatFor(Currencies.USD),
  "es-VE": numberFormatFor(Currencies.VEF),
  "pt-AR": numberFormatFor(Currencies.ARS),
  "pt-BR": numberFormatFor(Currencies.BRL),
  "pt-PT": numberFormatFor(Currencies.EUR),
  "en-AR": numberFormatFor(Currencies.ARS),
  "en-US": numberFormatFor(Currencies.USD),
  "en-BR": numberFormatFor(Currencies.BRL),
  "en-MX": numberFormatFor(Currencies.MXP),
  es: numberFormatFor(Currencies.ARS),
  pt: numberFormatFor(Currencies.BRL),
  en: numberFormatFor(Currencies.USD),
};

let i18nInstance: MyI18n;
let currentLocale: Locales = Locales.es;

const newInstance = (): MyI18n => {
  i18nInstance = createI18n({
    legacy: false, // Composition API mode
    locale: currentLocale,
    messages: { es, pt },
    datetimeFormats: {
      es: {
        short: {
          month: "short",
          day: "numeric",
        },
        shortNumeric: {
          month: "numeric",
          day: "numeric",
        },
        shortWithTime: {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        shortWithYear: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        long: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        longWithTime: {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        longWithTimeAndWeekday: {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          weekday: "long",
        },
      },
      pt: {
        short: {
          month: "short",
          day: "numeric",
        },
        shortNumeric: {
          month: "numeric",
          day: "numeric",
        },
        shortWithTime: {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        shortWithYear: {
          year: "numeric",
          month: "short",
          day: "numeric",
        },
        long: {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        longWithTime: {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        },
        longWithTimeAndWeekday: {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          weekday: "long",
        },
      },
    },
    numberFormats: { ...defaultNumberFormats },
    fallbackWarn: false,
    missingWarn: false,
  });
  return i18nInstance;
};

export const setI18nLocale = (locale: Locales): void => {
  currentLocale = locale;
  newInstance();
};

export const getCurrentLocale = () => currentLocale;

export const getI18nInstance = (): MyI18n => {
  if (i18nInstance) {
    return i18nInstance;
  } else {
    return newInstance();
  }
};
