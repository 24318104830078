<template>
  <div class="space-y-5">
    <div class="relative flex items-start">
      <div class="flex h-6 items-center">
        <CheckBox
          ref="FormCheckBoxComponent"
          :class="[disabled ? 'bg-gray-100' : 'cursor-pointer']"
          :disabled="disabled"
          :model-value="modelValue"
          @update:model-value="updateModelValue"
          @change="updateChange"
        />
      </div>
      <div class="ml-3 space-y-2 text-sm leading-6">
        <div class="flex flex-wrap">
          <Label
            :disabled="disabled"
            :class="disabled ? 'cursor-auto' : 'cursor-pointer'"
            class="mr-4 mt-0.5 select-none"
            @click="clickUpdateModelValue"
          >
            {{ label }}
          </Label>
          <TagsGroup v-if="tags" readonly :show-tag-colors="false" tag-size="small" :selected-tags="tags" />
        </div>
        <p v-show="description" class="text-gray-500">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import { TagsGroup } from "@organisms/Tags";
import CheckBox from "@atoms/CheckBox.vue";
import Label from "@atoms/Label.vue";

// Types
import type { Tags } from "@domain/tag";

//Props
withDefaults(
  defineProps<{
    modelValue?: boolean;
    label?: string;
    description?: string;
    tags?: Tags;
    disabled?: boolean;
  }>(),
  {
    modelValue: false,
    label: undefined,
    description: undefined,
    tags: undefined,
    disabled: false,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "update:id", id: string): void;
}>();

const updateModelValue = (value: boolean) => {
  console.log("update: ", value);
  emit("update:modelValue", value);
};

const updateChange = (ev: Event) => {
  emit("update:modelValue", (ev.target as HTMLInputElement).checked);
};

const FormCheckBoxComponent = ref<InstanceType<typeof CheckBox> | null>(null);

const clickUpdateModelValue = () => FormCheckBoxComponent.value?.CheckBoxComponent?.click();
</script>
