import { Collection } from "@/core/magnet";
import app from "@/app";

export default Collection.extend({
  url: function () {
    return this.query?.filter.type === "CONVERSION"
      ? "contactevents?filters.type=order.completed&filters.details-attribution-batch_id=" +
          app.session.attributes.account +
          "_bulk_" +
          this.campaignId()
      : "campaigns/" + this.campaignId() + "/actions";
  },
  parse: function (response) {
    return response.data.data || response.data;
  },
  additionals: {
    bounceTypes: ["BOUNCE", "HARD_BOUNCE", "SOFT_BOUNCE"],
    campaign: function (collection) {
      return $.get("campaigns/" + collection.campaignId());
    },
    metrics: function (collection) {
      let filter = "";
      if (collection.query?.filter?.version) {
        filter = "?filters.version=" + collection.query.filter.version.toLowerCase();
      }
      return $.get("campaigns/" + collection.campaignId() + "/metrics" + filter);
    },
    links: function (collection) {
      return $.get("campaigns/" + collection.campaignId() + "/links/metrics");
    },
  },
  campaignId: function () {
    return this.query.id;
  },
});
