import { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { Requestor, useRequests } from "@api/requests";
import { useNotifications } from "@composables/notifications";

export function useAccountFiles(requestor?: Requestor) {
  const request = requestor || useRequests();
  const { notify } = useNotifications();
  const { t } = useI18n();

  async function postFiles({
    file,
    folder,
    loadingRef,
  }: {
    folder: string;
    file: File;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    if (
      file.name &&
      ![".xls", ".xslx", ".doc", ".docx", ".pdf", ".png", ".gif", "jpg", ".jpeg"].some((ext) =>
        file.name.includes(ext)
      )
    ) {
      notify({
        title: t("errors.files.generic.invalidType.title"),
        text: t("errors.files.generic.invalidType.text"),
        theme: "error",
        timeout: 5000,
      });
      return;
    }
    if (file.size && file.size > 20 * 1024 * 1024) {
      notify({
        title: t("errors.files.generic.invalidSize.title"),
        text: t("errors.files.generic.invalidSize.text") + " 20MB.",
        theme: "error",
        timeout: 5000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    const res = await request({
      url: "/accountfiles/upload",
      urlParams: { "filters.folder": folder },
      data: formData,
      method: "post",
      loadingRef,
      axiosRequestConfig: {
        headers: { "Content-Type": "multipart/form-data" },
      },
    });

    return res;
  }

  async function postImages({
    file,
    folder,
    loadingRef,
  }: {
    folder: string;
    file: File;
    loadingRef?: Ref<boolean>;
  }): Promise<void> {
    if (file.name && ![".png", "jpg", ".jpeg", ".gif"].some((ext) => file.name.includes(ext))) {
      notify({
        title: t("errors.files.image.invalidType.title"),
        text: t("errors.files.image.invalidType.text"),
        theme: "error",
        timeout: 5000,
      });
      return;
    }
    if (file.size && file.size > 5 * 1024 * 1024) {
      notify({
        title: t("errors.files.image.invalidSize.title"),
        text: t("errors.files.image.invalidSize.text") + " 5MB.",
        theme: "error",
        timeout: 5000,
      });
      return;
    }

    const res = await postFiles({ file, folder, loadingRef });
    return res;
  }

  return { postFiles, postImages };
}
