<template>
  <div v-if="!emptyTemplate" class="flex justify-between">
    <div v-if="!loading" :class="[{ 'my-auto': show.showAudit || show.showMonitoringOptions }, 'flex-1 space-y-2']">
      <div class="inline text-sm">
        <div class="inline space-x-3">
          <template v-if="show.showSubject">
            <span
              v-if="relation?.relationType != 'campaign_testab'"
              class="font-medium"
              :class="{ 'text-gray-400': !subject && !previewTemplate?.subject }"
              >{{ subject || previewTemplate?.subject || t("subjectPlaceholder") }}</span
            >
            <div v-else class="group relative inline cursor-default">
              <div class="inline-flex align-middle group-hover:text-sky-400">
                <BeakerIcon class="mr-1 h-5 w-5" />
                <span ref="vueSubjectsRef" class="font-medium">{{ t("subjectsPlaceholder") }}</span>
              </div>
              <div
                ref="vueSubjectsPopperRef"
                class="invisible absolute left-0 top-5 z-10 box-content min-w-full max-w-4xl cursor-auto rounded-md border border-gray-50 bg-white px-5 py-2 shadow-md group-hover:visible"
              >
                <div v-if="subjects?.length > 0">
                  <span v-for="(subjectAB, index) in subjects" :key="index" class="block w-max max-w-full py-2">{{
                    subjectAB
                  }}</span>
                </div>
                <TextParagraph v-else class="mx-1 my-4 text-gray-400">
                  {{ t("subjectsContentPlaceholder") }}
                </TextParagraph>
              </div>
            </div>
          </template>
          <span v-if="subject && preheader" class="ml-4 mr-4 font-medium text-gray-400">-</span>
          <span v-if="show.showPreHeader" :class="{ 'text-gray-400': !preheader && !previewTemplate?.preheader }">{{
            preheader || previewTemplate?.preheader || t("preheaderPlaceholder")
          }}</span>
        </div>
      </div>
      <div
        v-if="show.showSender"
        class="flex flex-wrap items-center gap-2 text-sm"
        :class="!templateSender?.email && 'text-gray-400'"
      >
        <div class="flex items-center space-x-1 whitespace-nowrap">
          <span>
            <span v-if="templateSender?.email" class="font-medium">{{ t("from") }}: </span>
            {{
              templateSender?.name && templateSender?.email
                ? `${templateSender?.name} &lt;${templateSender?.email}>`
                : t("senderPlaceholder")
            }}
          </span>
          <IconButton
            v-if="templateSender?.auth?.status === 'OK'"
            :label="t('authResult.OK')"
            theme="success"
            colored
            class="m-0 p-0"
          >
            <ShieldCheckIcon />
          </IconButton>
          <IconButton
            v-else-if="templateSender?.auth?.status === 'CHANGE_REQUIRED'"
            :label="t('authResult.CHANGE_REQUIRED')"
            theme="danger"
            colored
            class="m-0 p-0"
          >
            <ShieldExclamationIcon />
          </IconButton>
          <IconButton
            v-else-if="templateSender?.auth?.status === 'CHANGE_SUGGESTED'"
            :label="t('authResult.CHANGE_SUGGESTED')"
            theme="warning"
            colored
            class="m-0 p-0"
          >
            <ShieldExclamationIcon />
          </IconButton>
          <IconButton
            v-else-if="templateSender?.auth?.status === 'PUBLIC_DOMAIN'"
            :label="t('authResult.PUBLIC_DOMAIN')"
            theme="warning"
            colored
            class="m-0 p-0"
          >
            <InformationCircleIcon />
          </IconButton>
        </div>
        <span class="whitespace-nowrap" :class="!templateSender?.reply_to && 'invisible'">
          <span class="font-medium">{{ t("replyTo") }}: </span>
          {{ templateSender?.reply_to }}
        </span>
      </div>
      <div v-if="template?.options.override_to || contact || template?.options.bcc" class="flex space-x-4 text-sm">
        <div v-if="template?.options.override_to || contact" class="flex items-center space-x-1">
          <span>
            <span class="font-medium">{{ t("for") }}: </span>
            {{ template?.options.override_to ? `${template?.options.override_to}` : `${contact?.email}` }}
          </span>
          <IconButton
            :label="
              template?.options.override_to
                ? t('contactSelectedOverrideIconButton', { email: template?.options.override_to })
                : t('contactSelectedIconButton', { email: contact?.email })
            "
            theme="info"
            colored
            class="m-0 p-0"
          >
            <InformationCircleIcon />
          </IconButton>
        </div>
        <div
          v-if="template?.options.bcc"
          class="flex items-center space-x-1"
          :class="!template?.options.bcc && 'invisible'"
        >
          <span class="font-medium opacity-100">{{ t("bcc") }}: </span>
          <span>
            {{ template?.options.bcc }}
          </span>
          <IconButton :label="t('BccIconButton', { email: template?.options.bcc })" theme="info" colored class="m-0 p-0"
            ><InformationCircleIcon
          /></IconButton>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex-1 space-y-4 pt-2">
      <div class="h-3 w-1/2 animate-pulse rounded-lg bg-gray-100"></div>
      <div class="h-3 w-1/3 animate-pulse rounded-lg bg-gray-100"></div>
    </div>
    <div class="mt-1">
      <div class="ml-4 flex space-x-4">
        <SimpleButton
          v-if="show.showSendTest"
          :model-value="xlBp ? t('testSendButton') : undefined"
          :show-tooltip="!xlBp"
          :tooltip="!xlBp ? t('testSendButton') : undefined"
          theme="white"
          size="min"
          :class="xlBp ? 'px-3' : 'px-2'"
          :disabled="disabled"
          class="mb-auto mt-0.5 rounded-md p-1.5"
          data-intercom-target="EditorPreviewSendTestButton"
          @click="openSendTestModal"
        >
          <template #leading> <MailIcon class="text-gray-500" /></template>
        </SimpleButton>
        <SimpleButton
          v-if="show.showShare"
          :model-value="xlBp ? t('getShareLinkButton') : undefined"
          :show-tooltip="!xlBp"
          :tooltip="!xlBp ? t('getShareLinkButton') : undefined"
          theme="white"
          size="min"
          :loading="loadingShareLink"
          :class="xlBp ? 'px-3' : 'px-2'"
          :disabled="disabled"
          class="mb-auto mt-0.5 rounded-md p-1.5"
          data-intercom-target="EditorPreviewShareLinkButton"
          @click="onOpenlinkShareModal"
        >
          <template #leading> <LinkIcon class="text-gray-500" /></template>
        </SimpleButton>
        <SimpleButton
          v-if="show.showPreviewContact"
          :model-value="false && xlBp ? t('previewAsContact') : undefined"
          :show-tooltip="true || !xlBp"
          :tooltip="true || !xlBp ? t('previewAsContact') : undefined"
          :class="false && xlBp ? 'px-3' : 'px-2'"
          :disabled="disabled"
          size="min"
          theme="white"
          class="mb-auto mt-0.5 rounded-md p-1.5"
          data-intercom-target="EditorPreviewPreviewAsButton"
          @click="openContactSelector"
        >
          <template #leading> <UserIcon class="text-gray-500" /></template>
        </SimpleButton>
        <ToggleButtons
          v-if="previewFormat && show?.showFormatToggle"
          :model-value="previewFormat"
          :buttons="[
            {
              id: 'desktop',
              label: 'desktop',
              icon: DesktopComputerIcon,
              tooltip: t('tooltipPreviewFormat.desktop'),
              disabled: disabled,
            },
            {
              id: 'mobile',
              label: 'mobile',
              icon: DeviceMobileIcon,
              tooltip: t('tooltipPreviewFormat.mobile'),
              disabled: disabled,
            },
          ]"
          data-intercom-target="EditorPreviewToggleDevice"
          @update:model-value="onTogglePreviewFormat"
        />
        <!-- <SwitchButton class="my-auto" /> -->
      </div>
      <div
        :class="[
          {
            'justify-end':
              show.showAudit || (show.showMonitoringOptions && !(show.showAudit && show.showMonitoringOptions)),
            'space-x-2': show.showAudit && show.showMonitoringOptions,
          },
          show?.showSendTest || show?.showShare || show?.showPreviewContact || show?.showFormatToggle
            ? 'ml-auto mt-4'
            : 'ml-6 md:ml-10',
          'relative flex',
        ]"
      >
        <SimpleSwitch
          v-if="show?.showheatMapToggle"
          :loading="loadingHeatMap"
          :model-value="showHeatMap"
          :disabled="!allow.heatMapDataId || isFreeAccount"
          :tooltip="!isFreeAccount ? heatMapTooltip : undefined"
          class="peer"
          data-intercom-target="EditorPreviewHeatMapButton"
          @update:model-value="updateShowHeatMap"
        >
          <template #icon>
            <FireIcon :class="[{ 'opacity-50': !allow.heatMapDataId || isFreeAccount }, 'm-0.5 h-4 w-4']" />
          </template>
        </SimpleSwitch>
        <div
          v-if="isFreeAccount && isMasterUser"
          class="invisible absolute right-2 top-10 z-10 min-w-max transition duration-150 ease-in-out hover:visible hover:-translate-y-1 hover:scale-105 peer-hover:visible peer-hover:-translate-y-1 peer-hover:scale-105"
        >
          <PlanUpgradePopover feature="heatmap" />
        </div>
        <TemplateMonitoringButton
          v-if="show?.showMonitoringOptions"
          :monitor-openings="template?.tracking.open"
          :monitor-clicks="template?.tracking.click"
          :track-analytics="template?.tracking.utm.enabled"
          :campaign-name="template?.tracking.utm.campaign"
        />
        <TemplateAuditoryButton v-if="props.show.showAudit" :auditory="template?.audit_results" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from "vue";

//Components
import TemplateMonitoringButton from "@organisms/Templates/TemplateMonitoringButton/TemplateMonitoringButton.vue";
import TemplateAuditoryButton from "@organisms/Templates/TemplateAuditory/TemplateAuditoryButton.vue";
import SimpleSwitch from "@molecules/SimpleSwitch.vue";
import PlanUpgradePopover from "@molecules/PlanUpgradePopover.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import ToggleButtons from "@/vue/components/atoms/ToggleButtons.vue";

import IconButton from "@atoms/IconButton.vue";
import TextParagraph from "@atoms/TextParagraph.vue";

//Icons
import { FireIcon } from "@heroicons/vue/solid";
import {
  DeviceMobileIcon,
  DesktopComputerIcon,
  MailIcon,
  BeakerIcon,
  LinkIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

//Utils
import { useBreakpoints } from "@composables/breakpoints";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

//Types
import type { PublicTemplatePreview, Template, TemplatePreview, TemplatePreviewTestAB } from "@domain/Templates";
import { isTemplatePreview } from "@domain/Templates";

import type { ISender, Sender } from "@domain/Senders";
import type { Relation } from "../../TemplateEditor/TemplateEditor.vue";
import type { ContactType as Contact } from "@organisms/Contacts/ContactSelector/ContactSelector.vue";

export type previewWidthFormats = "mobile" | "desktop";

export interface ShowElements {
  showSendTest?: boolean;
  showShare?: boolean;
  showPreviewContact?: boolean;
  showFormatToggle?: boolean;
  showSubject?: boolean;
  showPreHeader?: boolean;
  showSender?: boolean;
  showMonitoringOptions?: boolean;
  showAudit?: boolean;
  showheatMapToggle?: boolean;
}

export interface AllowElements {
  heatMapDataId?: boolean;
}

const { xlBp } = useBreakpoints();

const { t } = useI18n();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Props
const props = withDefaults(
  defineProps<{
    template?: Template;
    previewTemplate?: TemplatePreview | PublicTemplatePreview | TemplatePreviewTestAB;
    relation?: Relation | undefined;
    sender?: Sender;
    preheader?: string;
    subject?: string;
    subjects?: Array<string>;
    contact?: Contact;
    previewFormat?: previewWidthFormats;
    disabled?: boolean;
    loading?: boolean;
    loadingShareLink?: boolean;
    showHeatMap?: boolean;
    loadingHeatMap?: boolean;
    allow?: AllowElements;
    show?: ShowElements;
  }>(),
  {
    template: undefined,
    previewTemplate: undefined,
    relation: undefined,
    sender: undefined,
    preheader: undefined,
    subject: undefined,
    subjects: () => [],
    contact: undefined,
    previewFormat: "desktop",
    disabled: false,
    loading: false,
    loadingShareLink: false,
    showHeatMap: false,
    loadingHeatMap: false,
    allow: () => ({
      heatMapDataId: false,
    }),
    show: () => ({
      showSendTest: false,
      showShare: false,
      showPreviewContact: false,
      showFormatToggle: false,
      showSubject: false,
      showPreHeader: false,
      showSender: false,
      showMonitoringOptions: false,
      showAudit: false,
      showheatMapToggle: false,
    }),
  }
);

const emit = defineEmits<{
  (e: "togglePreviewFormat", value: previewWidthFormats): void;
  (e: "openSendTest"): void;
  (e: "openContactSelector"): void;
  (e: "openlinkShareModal"): void;
  (e: "update:showHeatMap", value: boolean): void;
}>();

const updateShowHeatMap = (value: boolean) => {
  if (isFreeAccount) return;
  emit("update:showHeatMap", value);
};

const onTogglePreviewFormat = (value: previewWidthFormats) => {
  emit("togglePreviewFormat", value);
};

const openSendTestModal = () => {
  emit("openSendTest");
};

const openContactSelector = () => {
  emit("openContactSelector");
};

const onOpenlinkShareModal = () => {
  emit("openlinkShareModal");
};

const isFreeAccount = session.value?.account?.plan?.type === "FREE";
const isMasterUser = session.value?.isMasterUser;

const heatMapTooltip = computed<string>(() => {
  const trackingEnabled = props.template && props.template.tracking.click;
  if (!props.allow.heatMapDataId && trackingEnabled) return t("heatmap.noDataId");

  if (!trackingEnabled) return t("heatmap.trackingDisabled");

  return t("heatmap.enabled");
});

const templateSender = computed<Sender | ISender | undefined>(() => {
  if (props.sender) return props.sender;
  if (props.previewTemplate && isTemplatePreview(props.previewTemplate)) return props.previewTemplate.sender;
  return undefined;
});

const emptyTemplate = computed(() => {
  if (props.template && props.template.type === "unlayer") return props.template?.contents?.json === "";
  return props.previewTemplate?.html === "";
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "subjectPlaceholder": "Edita el contenido para configurar el asunto y preheader de tu email.",
    "subjectsPlaceholder": "Campaña Test A/B con múltiples asuntos",
    "subjectsContentPlaceholder": "Aún no se han definido los asuntos",
    "preheaderPlaceholder": "",
    "senderPlaceholder": "Aún no has seleccionado un remitente. Edita la plantilla para configurarlo.",
    "testSendButton": "Enviar prueba",
    "getShareLinkButton": "Compartir enlace",
    "previewAsContact": "Ver como un contacto",
    "from": "De",
    "replyTo": "Responder a",
    "for": "Para",
    "bcc": "Cco",
    "contactSelectedIconButton": "Estás viendo este email como lo recibirá {email}.",
    "contactSelectedOverrideIconButton": "Este email será enviado únicamente a {email}. El contacto que inicia el automation no lo recibirá.",
    "BccIconButton": "{email} recibirá una copia oculta de este email.",
    "heatmap": {
      "enabled": "Mapa de calor de clicks",
      "noDataId": "Mapa de calor de clicks disponible en tus próximas campañas.",
      "trackingDisabled": "Mapa de calor de clicks sólo disponible cuando el monitoreo de clicks está activo."
    },
    "tooltipPreviewFormat": {
      "desktop": "escritorio",
      "mobile": "móvil"
    },
    "authResult": {
      "OK": "El remitente está autenticado correctamente.",
      "CHANGE_REQUIRED": "Es necesario hacer algunos ajustes para autenticar correctamente el remitente.  Mira los detalles en la sección Ajustes de tu cuenta.",
      "CHANGE_SUGGESTED": "Es recomendable realizar cambios para mejorar la autenticación del remitente. Mira los detalles en la sección Ajustes de tu cuenta.",
      "PUBLIC_DOMAIN": "Los dominios públicos no pueden ser autenticados correctamente. Utilizaremos un dominio alternativo."
    }
  },
  "pt": {
    "subjectPlaceholder": "Edite o conteúdo para configurar o assunto e o pré-header do seu e-mail.",
    "subjectsPlaceholder": "Campanha Teste A/B com vários assuntos",
    "subjectsContentPlaceholder": "Os assuntos ainda não foram definidos.",
    "preheaderPlaceholder": "",
    "senderPlaceholder": "Ainda não foi selecionado um remetente.",
    "testSendButton": "Enviar teste",
    "getShareLinkButton": "Obter link",
    "previewAsContact": "Ver como um contato",
    "from": "De",
    "replyTo": "Responder a",
    "for": "Para",
    "bcc": "Cco",
    "contactSelectedIconButton": "Você está vendo este e-mail como {email} o receberá.",
    "contactSelectedOverrideIconButton": "Este e-mail será enviado somente a {email}. O contato que inicia o automation não o receberá.",
    "BccIconButton": "{email} receberá uma cópia oculta deste e-mail.",
    "heatmap": {
      "enabled": "Mapa de calor de cliques ativado.",
      "noDataId": "Mapa de calor de cliques disponível em suas próximas campanhas.",
      "trackingDisabled": "Ative o rastreamento de cliques para usar o mapa de calor de cliques."
    },
    "tooltipPreviewFormat": {
      "desktop": "computador",
      "mobile": "móvel"
    },
    "authResult": {
      "OK": "O remetente está autenticado corretamente.",
      "CHANGE_REQUIRED": "Você precisa fazer alguns ajustes para autenticar corretamente o seu remetente. Veja os detalhes na seção de Configurações da sua conta.",
      "CHANGE_SUGGESTED": "Recomendamos fazer alguns ajustes para melhorar a autenticação do remetente. Veja os detalhes na seção de Configurações da sua conta.",
      "PUBLIC_DOMAIN": "Os domínios públicos não podem ser autenticados corretamente. Utilizaremos um domínio alternativo."
    }
  }
}
</i18n>
