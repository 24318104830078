import { useDebounceFn } from "@vueuse/core";

export const getFetchDebounce = (fetch: () => Promise<unknown>) => {
  return useDebounceFn(
    async () => {
      await fetch();
    },
    1000,
    {
      maxWait: 5000,
    },
  );
};
