import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Model.extend({
  urlRoot: "apiv3://templates",
  parse: function (emailTemplate) {
    if (_.isDefined(emailTemplate.data)) {
      emailTemplate = emailTemplate.data;
    }
    return emailTemplate;
  },
});
