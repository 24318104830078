//I18n
import { getI18nInstance } from "@locales/i18n";

export const getFooterHTML = (): string => {
  const { t } = getI18nInstance().global;

  return `<div
  id="footer-html"
  style="
  text-align: center;
  font-size: 12px;
  font-family: inherit;
  word-wrap: break-word;
  background-color: #FFFFFF;
  color: #212427"
  >
    <p style="line-height: 180%; margin: 0;"
      >${t("footerHTML.unsubscribeText")}
      <a style="white-space: nowrap; color: #0000EE" target="_blank" href="\${urls.unsubscribe}" rel="noopener">${t(
        "footerHTML.unsubscribeLink"
      )}</a></p
    >
    <p style="line-height: 180%; margin: 0;">${t("footerHTML.unsubscribeMessageSendBy")}</p>
    <p style="line-height: 180%; margin: 0;">\${account.postal_address}</p>
  </div>`;
};
