export interface Notification {
  id: number;
  type: "TASK_OK" | "TASK_FAILED";
  priority: string;
  read: boolean;
  created: DateString;
  title: string;
  message: string;
  details: Array<{
    text: string;
    linkId?: string;
    linkModule?: "lists";
    linkUrl?: UrlString;
    authRequired?: boolean;
  }>;
}

export type Notifications = Array<Notification>;

export interface Task {
  id: number;
  type: "LIST_ANALYZE" | "METRICS_EXPORT_TASK" | string;
  name: string;
  state: "PROCESSING" | "QUEUED" | string;
  cancellable: boolean;
  message: string;
  owner: string;
  created: DateString;
  init: DateString | null;
  end: DateString | null;
  completed: number;
  eta: number; // Minutes
  request: string;
  parameters: {
    listId?: number;
    tags?: Array<string>;
    query?: string;
    fileName?: string;
    campaigns?: Array<number>;
    activeListName?: string;
    bouncedListName?: string;
    unknownListName?: string;
    suspiciusListName?: string;
  };
  result: null;
}

export interface FixedTask {
  id: string;
  type: "CUSTOM";
  state: "PROCESSING" | "QUEUED" | string;
  name: string;
  message: string;
  progress: number; // 0 - 100
}

export const isFixedTask = (t: Task | FixedTask): t is FixedTask => {
  return (t as FixedTask).progress !== undefined;
};

export type FixedTasks = Array<FixedTask>;
export type Tasks = Array<Task | FixedTask>;
