import { capitalizeFirstLetter } from "@helpers/formatters";

// Types
import type { ContactsList } from "@domain/lists";
import { Definitions, StageNames, Stats } from "@domain/lifecycle";

// I18n
import { getI18nInstance } from "@/vue/locales/i18n";
import esMessages from "./i18n/dashboardLifeCycle.es.json";
import ptMessages from "./i18n/dashboardLifeCycle.pt.json";

export type DescriptionDefinitions = Record<StageNames, string>;
export interface StageContactsCount {
  activeContacts: number;
  totalContacts: number;
  differenceCount;
}
export type StagesContactsCount = Record<StageNames, StageContactsCount>;

const { mergeLocaleMessage } = getI18nInstance().global;

mergeLocaleMessage("es", esMessages);
mergeLocaleMessage("pt", ptMessages);

function* orderTextGenerator(initialOrder: string) {
  const { t } = getI18nInstance().global;
  let order: number | undefined = undefined;
  let value = Math.abs(Number(initialOrder));

  while (value) {
    let type: "value" | "range" = "value";

    const lastValue = order ? order - 1 : undefined;

    if (order && value !== order && value < order) {
      type = "range";
    }
    order = value;

    value = Math.abs(
      Number(
        yield `${t(`orders.${type}`, {
          count: value,
          count_min: value,
          count_max: lastValue,
        })}`
      )
    );
  }
}

function* lastOrderTextGenerator(initialLastOrder: string) {
  const { t } = getI18nInstance().global;
  let lastOrder: number | undefined = undefined;
  let lastOrderTransition: number | undefined = undefined;

  let value = Math.abs(Number(initialLastOrder));
  while (value) {
    let type: "value" | "range" = "value";
    if (lastOrderTransition !== value) {
      lastOrder = lastOrderTransition;
    }
    let lastValue: number | undefined = lastOrder;

    if (lastOrder && value !== lastOrder) {
      type = "range";
    }

    lastOrderTransition = value;

    if (lastOrder && lastValue) {
      const aux = value;
      value = lastValue;
      lastValue = aux;
    }

    value = Math.abs(
      Number(
        yield `${t(`last_order.${type}`, {
          count: value,
          count_min: value,
          count_max: lastValue,
        })}`
      )
    );
  }
}

export const getStagesDescriptions = (definitions: Definitions): DescriptionDefinitions => {
  const { t } = getI18nInstance().global;
  let orderGen: Generator;
  let lastOrderGen: Generator;

  const descriptions: DescriptionDefinitions = definitions.stages.reduce((obj, stage) => {
    const text = stage.predicate.attributes.reduce((text, attribute, index) => {
      if (attribute.attribute === "orders" && !orderGen) {
        orderGen = orderTextGenerator(attribute.value.toString());
      }

      if (attribute.attribute === "last_order" && !lastOrderGen) {
        lastOrderGen = lastOrderTextGenerator(attribute.value.toString());
      }

      const values = {
        orders: () => orderGen.next(attribute.value.toString()).value,
        last_order: () => lastOrderGen.next(attribute.value.toString()).value,
      };

      if (index === 0) {
        text = `${values[attribute.attribute]()}`;
        return text;
      }

      text = `${text} ${t(`logicalOperator.${stage.predicate.operator}`)} ${values[attribute.attribute]()}`;

      return text;
    }, "");

    obj[stage.name] = capitalizeFirstLetter(text);

    return obj;
  }, {} as DescriptionDefinitions);

  return descriptions;
};

export const getStagesContactsCount = (
  definitions: Definitions,
  stats: Stats,
  contactsLists: ContactsList
): StagesContactsCount => {
  return definitions.stages.reduce((counts, stage) => {
    const stageList = contactsLists.find((list) => list.id.toString() === stage.listId);
    if (!stageList) {
      counts[stage.name] = {
        activeContacts: 0,
        totalContacts: 0,
        differenceCount: 0,
      };
      return counts;
    }

    const contactsCount = stats[stage.name].contacts;
    counts[stage.name] = {
      activeContacts: stageList.activeContacts,
      totalContacts: contactsCount,
      differenceCount: contactsCount - stageList.activeContacts,
    };
    return counts;
  }, {} as StagesContactsCount);
};
