<template>
  <div class="space-y-6 p-6 pr-16 xl:pr-40 2xl:pr-80">
    <div class="space-y-2">
      <FormSwitch
        :model-value="toRecipientIsActive"
        :label="t('TO.title')"
        :description="t('TO.description')"
        reverse
        @update:model-value="updateToRecipientIsActive"
      />
      <div v-if="toRecipientIsActive" class="ml-20 max-w-xl">
        <FormInput
          :model-value="toRecipient"
          :error="toRecipientState.toRecipientShowError ? toRecipientState.toRecipientError : ''"
          :placeholder="t('TO.emailLabel')"
          @update:model-value="updateToRecipient"
          @blur="onBlurToRecipient"
        ></FormInput>
      </div>
    </div>
    <div class="space-y-2">
      <FormSwitch
        :model-value="bccRecipientIsActive"
        :label="t('BCC.title')"
        :description="t('BCC.description')"
        reverse
        @update:model-value="updateBccRecipientIsActive"
      />
      <div v-if="bccRecipientIsActive" class="ml-20 max-w-xl">
        <FormInput
          :model-value="bccRecipient"
          :error="bccRecipientState.bccRecipientShowError ? bccRecipientState.bccRecipientError : ''"
          :placeholder="t('BCC.emailLabel')"
          @update:model-value="updateBCCRecipient"
          @blur="onBlurBccRecipient"
        ></FormInput>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref } from "vue";

//Components
import FormSwitch from "@molecules/FormSwitch.vue";
import FormInput from "@molecules/FormInput.vue";

//I18n
import { useI18n } from "vue-i18n";

//utils
import { email as emailValidator } from "@vuelidate/validators";

const { t } = useI18n();

// Props
const props = withDefaults(
  defineProps<{
    toRecipient?: string;
    bccRecipient?: string;
  }>(),
  {
    toRecipient: "",
    bccRecipient: "",
  }
);

// Emits
const emit = defineEmits<{
  (e: "update:toRecipient", value: string): void;
  (e: "update:bccRecipient", value: string): void;
}>();

const updateToRecipient = (value: string) => emit("update:toRecipient", value);
const updateBCCRecipient = (value: string) => emit("update:bccRecipient", value);

//State
const toRecipientIsActive = ref(false);
const updateToRecipientIsActive = () => {
  toRecipientIsActive.value = !toRecipientIsActive.value;
  if (toRecipientIsActive.value) return;

  updateToRecipient("");
  toRecipientState.toRecipientShowError = false;
};
const toRecipientState = reactive({
  toRecipientShowError: false,
  toRecipientError: t("emailValidationError"),
});
const onBlurToRecipient = () => {
  toRecipientState.toRecipientShowError = !props.toRecipient || !isValidEmail(props.toRecipient);
};

const bccRecipientIsActive = ref(false);
const updateBccRecipientIsActive = () => {
  bccRecipientIsActive.value = !bccRecipientIsActive.value;
  if (bccRecipientIsActive.value) return;

  updateBCCRecipient("");
  bccRecipientState.bccRecipientShowError = false;
};
const bccRecipientState = reactive({
  bccRecipientShowError: false,
  bccRecipientError: t("emailValidationError"),
});
const onBlurBccRecipient = () => {
  bccRecipientState.bccRecipientShowError = !props.bccRecipient || !isValidEmail(props.bccRecipient);
};

//Methods
const isValidEmail = (email: string) => Boolean(emailValidator.$validator(email, null, null));

onMounted(() => {
  toRecipientIsActive.value = !!props.toRecipient && isValidEmail(props.toRecipient);
  bccRecipientIsActive.value = !!props.bccRecipient && isValidEmail(props.bccRecipient);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "emailValidationError": "Ingresa una dirección de email válida.",
    "TO": {
      "title": "Destinatario personalizado (Para)",
      "description": "Enviar el email a la dirección indicada a continuación, en lugar de la asociada al contacto.",
      "emailLabel": "para{'@'}email.com"
    },
    "BCC": {
      "title": "Copia oculta (Cco)",
      "description": "Enviar una copia oculta del email a la dirección indicada a continuación.",
      "emailLabel": "cco{'@'}email.com"
    }
  },
  "pt": {
    "emailValidationError": "Insira um endereço de e-mail válido.",
    "TO": {
      "title": "Destinatário personalizado (Para)",
      "description": "Enviar o e-mail para o endereço indicado abaixo, em vez do associado ao contato.",
      "emailLabel": "para{'@'}email.com"
    },
    "BCC": {
      "title": "Cópia oculta (Cco)",
      "description": "Enviar uma cópia oculta do e-mail para o endereço indicado abaixo.",
      "emailLabel": "cco{'@'}email.com"
    }
  }
}
</i18n>
