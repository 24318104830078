import { ref } from "vue";
import { defineStore } from "pinia";

// Ports
import type { UserUpdateParams } from "@application/ports";

// Services
import { useUserService } from "@services";

// Type
import type { userData } from "@domain/user";

export const useUserStore = defineStore("userStore", () => {
  const userService = useUserService();
  const userData = ref<userData>();
  const users = ref<userData[]>();
  const usersFetched = ref<boolean>(false);

  const getUserData = () => {
    return userData.value;
  };

  const fetchUsers = async () => {
    const res = await userService.getUsers();
    if (res.isErr()) return;

    users.value = res.value;
    usersFetched.value = true;
  };

  const fetchUserData = async () => {
    const res = await userService.get();
    if (res.isErr()) return;

    userData.value = res.value;
  };

  const setUserData = async (data: UserUpdateParams) => {
    const res = await userService.put(data);

    if (res.isErr() || !userData.value) return;

    userData.value.language = res.value?.language ?? userData.value?.language;
    userData.value.name = res.value?.name ?? userData.value.name;
    userData.value.timezone = res.value?.timezone ?? userData.value.timezone;
  };
  return {
    userData,
    users,
    usersFetched,
    getUserData,
    fetchUserData,
    fetchUsers,
    setUserData,
  };
});
