<template>
  <div class="space-y-6">
    <TextParagraph>
      {{ t("description") }}
    </TextParagraph>
    <SelectMenuLabel
      :selected="selectedReason"
      :data-items="reasons"
      :label="t('reasonLabel')"
      :rem-height="17"
      @update:selected="(item) => selectReason(item as Reason)"
    />

    <div v-show="selectedReason.data?.showComment">
      <FormTextArea
        id="vue-plan-cancel-comments-textarea"
        :model-value="reasonComment"
        :placeholder="t(`reasons.${props.selectedReason.key}.commentPlaceholder`)"
        :label="t(`reasons.${props.selectedReason.key}.commentLabel`)"
        :rows="3"
        :maxlength="200"
        :error="showCommentError ? t('commentError') : undefined"
        @update:model-value="updateReasonComment"
      />
    </div>
    <AlertBox v-show="selectedReason.data?.showWarning" theme="warning">
      <span class="font-medium">{{ t(`reasons.${props.selectedReason.key}.contactSupportDescription`) }}</span>
    </AlertBox>
    <AlertBox v-show="selectedReason.data?.showContactSupport" theme="info">
      <template #icon>
        <ChatAltIcon />
      </template>
      <span class="font-medium">
        {{ t(`reasons.${props.selectedReason.key}.contactSupportText`) }}
      </span>
      <template #actions>
        <SimpleButton theme="primary-clean" class="-ml-4" @click="contactIntercom"
          >{{ t("buttonContact") }}
          <template #trailing>
            <ArrowSmRightIcon />
          </template>
        </SimpleButton>
      </template>
    </AlertBox>
    <div class="flex justify-between">
      <SimpleButton theme="white" @click="close">{{ t("buttonClose") }}</SimpleButton>
      <SimpleButton theme="danger" :disabled="!allowContinue || showCommentError" @click="onContinue"
        >{{ t("buttonContinue") }}<template #trailing><ChevronRightIcon /> </template>
      </SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";

import type { Reason } from "../planCancellation.types";
import { getReasons } from "../reasons";

// Components
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import FormTextArea from "@molecules/FormTextArea.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Icon
import { ChatAltIcon, ChevronRightIcon } from "@heroicons/vue/solid";
import { ArrowSmRightIcon } from "@heroicons/vue/outline";

// Utils
import Intercom from "@helpers/intercom";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    selectedReason: Reason;
    reasonComment: string;
  }>(),
  {}
);

//Emits
const emit = defineEmits<{
  (e: "update:selectedReason", value: Reason): void;
  (e: "update:reasonComment", value: string): void;
  (e: "continue"): void;
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

const selectReason = (reason: Reason) => {
  emit("update:selectedReason", reason);
};

const reasons = getReasons();

const showCommentError = ref(false);
const allowContinue = ref<boolean>(false);
const onContinue = () => {
  if (props.selectedReason.data?.showComment && props.reasonComment.length === 0) {
    showCommentError.value = true;
    return;
  }
  emit("continue");
};

const contactIntercom = () => {
  Intercom.showNewMessage(t(`reasons.${props.selectedReason.key}.intercomMessage`));
  close();
};

const updateReasonComment = (comment: string) => {
  showCommentError.value = false;
  emit("update:reasonComment", comment);
};

watchEffect(() => {
  allowContinue.value = !props.selectedReason.data || props.selectedReason.data.validOption;
  showCommentError.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "description": "Lamentamos que quieras cancelar tu suscripción. Valoramos mucho tus comentarios y sugerencias para seguir mejorando nuestro producto 🙂",
    "reasonLabel": "Motivo de cancelación",
    "reasons": {
      "temporary": {
        "label": "Es sólo una baja temporal",
        "commentLabel": "¿En cuánto tiempo piensas volver?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando."
      },
      "too_expensive": {
        "label": "Me resulta costoso mantener el servicio",
        "intercomMessage": "Hola! Quisiera encontrar alguna forma de reducir mis costos para poder seguir usando Perfit.",
        "contactSupportText": "¿Te gustaría hablar con un representante para analizar cómo reducir los costos?"
      },
      "plan_change": {
        "label": "Quiero cambiar el tipo de plan contratado",
        "contactSupportText": "¿Te gustaría hablar con un representante para entender mejor las diferencias entre los distintos tipos de plan?",
        "intercomMessage": "Hola! Quisiera conocer mejor las diferencias entre los distintos tipos de plan.",
        "contactSupportDescription": "Ten en cuenta que cada plan tiene características diferentes y puedes perder funcionalidades con el cambio."
      },
      "bad_results": {
        "label": "Los resultados que obtuve no son los que esperaba",
        "commentLabel": "¿Cuáles eran tus expectativas?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando.",
        "intercomMessage": "Hola! Quisiera recibir asesoramiento para intentar mejorar los resultados.",
        "contactSupportText": "¿Quieres hablar con un representante para entender cómo mejorar tus resultados?"
      },
      "missing_features": {
        "label": "Faltan funcionalidades que necesito",
        "commentLabel": "¿Cuáles son esas funcionalidades?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando."
      },
      "too_busy": {
        "label": "Falta de tiempo y/o equipo para gestionar mi cuenta",
        "intercomMessage": "Hola! Quisiera recibir más información sobre los servicios de gestión de cuenta.",
        "contactSupportText": "Contamos con especialistas que pueden ayudarte a implementar estrategias y gestionar tu cuenta. ¿Te interesa recibir más información?"
      },
      "business_close": {
        "label": "Cese de actividades, cierra la empresa"
      },
      "complexity": {
        "label": "Me resultó complejo usar la aplicación",
        "commentLabel": "¿Qué cosa en particular te resultó más complicado?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando.",
        "intercomMessage": "Hola! Quisiera recibir asistencia para aprender cómo utilizar Perfit.",
        "contactSupportText": "¿Quieres recibir asistencia y capacitación por parte de nuestro equipo?"
      },
      "duplicate_accounts": {
        "label": "Tengo otra cuenta en Perfit y seguiré usando esa"
      },
      "missing_integrations": {
        "label": "No se integra con la plataforma que utilizo",
        "commentLabel": "¿Qué integraciones necesitarías?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando."
      },
      "bad_experience": {
        "label": "No estoy conforme con la atención recibida",
        "commentLabel": "¿Alguna situación en particular para mencionar?",
        "commentPlaceholder": "Cuéntanos un poco más. Tus comentarios nos ayudan a seguir mejorando."
      },
      "defaulter": {
        "label": "Moroso incontactable",
        "commentLabel": "Comentarios",
        "commentPlaceholder": "Detalle de la baja"
      },
      "other": {
        "label": "Otra razón",
        "commentLabel": "Comentarios",
        "commentPlaceholder": "Detalle de la baja"
      },
      "audited": {
        "label": "Deshabilitada por auditoría",
        "commentLabel": "Comentarios",
        "commentPlaceholder": "Detalle de la baja"
      }
    },
    "commentError": "Por favor completa estos detalles para ayudarnos a mejorar.",
    "buttonContinue": "Siguiente",
    "buttonClose": "Mejor no",
    "buttonContact": "Sí, quiero charlar"
  },
  "pt": {
    "description": "Lamentamos que você queira cancelar sua assinatura. Valorizamos muito seus comentários e sugestões para continuarmos melhorando nosso produto 🙂",
    "reasonLabel": "Motivo do cancelamento",
    "reasons": {
      "temporary": {
        "label": "É uma pausa temporária",
        "commentLabel": "Quanto tempo você pretende voltar?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando."
      },
      "too_expensive": {
        "label": "Acho caro manter o serviço",
        "intercomMessage": "Oi! Gostaria de encontrar uma forma de reduzir meus custos para poder continuar usando a Perfit.",
        "contactSupportText": "Gostaria de falar com um representante para discutir como reduzir os custos?"
      },
      "plan_change": {
        "label": "Quero mudar o tipo de plano contratado",
        "contactSupportText": "Gostaria de falar com um representante para entender melhor as diferenças entre os distintos tipos de plano?",
        "intercomMessage": "Oi! Gostaria de saber mais sobre as diferenças entre os distintos tipos de plano.",
        "contactSupportDescription": "Tenha em mente que cada plano possui diferentes características e você pode perder funcionalidades com a mudança."
      },
      "bad_results": {
        "label": "Os resultados que obtive não são os esperados",
        "commentLabel": "Quais eram suas expectativas?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando.",
        "intercomMessage": "Oi! Gostaria de receber orientação para tentar melhorar os resultados.",
        "contactSupportText": "Deseja falar com um representante para entender como melhorar seus resultados?"
      },
      "missing_features": {
        "label": "Faltam funcionalidades que eu preciso",
        "commentLabel": "Quais são essas funcionalidades?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando."
      },
      "too_busy": {
        "label": "Falta de tempo e/ou equipe para gerenciar minha conta",
        "intercomMessage": "Oi! Gostaria de receber mais informações sobre os serviços de gerenciamento de conta.",
        "contactSupportText": "Contamos com especialistas que podem ajudá-lo a implementar estratégias e gerenciar sua conta. Você gostaria de receber mais informações?"
      },
      "business_close": {
        "label": "Encerramento de atividades, feche a empresa."
      },
      "complexity": {
        "label": "Achei complexo usar a aplicação",
        "commentLabel": "O que em particular foi mais complicado para você?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando.",
        "intercomMessage": "Oi! Gostaria de receber assistência para aprender como usar a Perfit.",
        "contactSupportText": "Deseja receber assistência e treinamento de nossa equipe?"
      },
      "duplicate_accounts": {
        "label": "Tenho outra conta na Perfit e continuarei usando essa"
      },
      "missing_integrations": {
        "label": "Não integra com a plataforma que eu uso",
        "commentLabel": "Quais integrações você precisaria?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando."
      },
      "bad_experience": {
        "label": "Não estou satisfeito com o atendimento recebido",
        "commentLabel": "Alguma situação específica para mencionar?",
        "commentPlaceholder": "Conte-nos um pouco mais. Seus comentários nos ajudam a continuar melhorando."
      },
      "defaulter": {
        "label": "Inadimplente e incontactável",
        "commentLabel": "Comentários",
        "commentPlaceholder": "Detalhes do cancelamento"
      },
      "other": {
        "label": "Outro motivo",
        "commentLabel": "Comentários",
        "commentPlaceholder": "Detalhes do cancelamento"
      },
      "audited": {
        "label": "Desabilitado por auditoria",
        "commentLabel": "Comentários",
        "commentPlaceholder": "Detalhes do cancelamento"
      }
    },
    "commentError": "Por favor, preencha esses detalhes para nos ajudar a melhorar.",
    "buttonContinue": "Continuar",
    "buttonClose": "Melhor não",
    "buttonContact": "Sim, quero conversar"
  }
}
</i18n>
