/**
 * Main application container
 * All application parts should use this namespace
 *
 * @module App
 */
import Router from "@/router";
import { _ } from "@/helpers/utils";

import "@assets/css/tailwind.css";
import "@assets/css/bootstrap.min.css";
import "@assets/css/font-awesome.min.css";
import "@assets/css/perfit-icons.css";
import "@assets/css/bootstrap-datepicker.css";
import "@assets/css/bootstrap-slider.css";
import "@assets/css/theme.css";
import "@assets/css/components/list.css";
import "@assets/plugins/eddie/css/eddie.css";
import "@assets/css/nprogress.css";
import "@assets/css/intlTelInput.min.css";
import "@assets/sass/styles.scss";
import "@assets/css/codemirror.css";

//CONFIG se define en webpack.config.js dependiendo del build
// eslint-disable-next-line no-undef
window.config = CONFIG;

//Probamos con un % de los clientes la nueva UI
let random = window.localStorage.getItem("api-lb-test-rand");
if (random === null) {
  random = Math.floor(Math.random() * 10);
  window.localStorage.setItem("api-lb-test-rand", random);
  console.log("api-lb-test-rand", random);
}
const sampleCut = 11; //disabled
if (parseInt(random) < sampleCut) {
  console.log("API LB test enabled");
  window.config.api = window.config.api_test;
}

const customConfig = window.localStorage.getItem("customConfig");
if (customConfig) {
  // Para poder definir configs en localStorage y debuggear
  window.config = { ...window.config, ...JSON.parse(customConfig) };
}

import { setBaseURL, setBaseURLAPIV3, setBaseURLAPIV3Pub, setLang, setLangAPIV3 } from "@api/requests";

setBaseURL(window.config.api);
setBaseURLAPIV3(window.config.apiv3);
setBaseURLAPIV3Pub(window.config.apiv3pub);

const moment = window.moment;

const app = app || {};
export default app;
window.app = app;

// Integrated apps helpers (Intercom, Bugsnag, Amplitude, ...)
import integrations from "@/integrations";
app.integrations = integrations;

// Models
import AccountsModel from "@/modules/accounts/model";
import AutomationsModel from "@/modules/automations/model";
import CampaignsModel from "@/modules/campaigns/model";
import ContactsModel from "@/modules/contacts/model";
import DashboardModel from "@/modules/dashboard/model";
import DesignsModel from "@/modules/designs/model";
import DripsModel from "@/modules/drips/model";
import EmailTemplatesModel from "@/modules/emailtemplates/model";
import FieldsModel from "@/modules/fields/model";
import ImagesModel from "@/modules/images/model";
import IntegrationsModel from "@/modules/integrations/model";
import InterestsModel from "@/modules/interests/model";
import ListsModel from "@/modules/lists/model";
import MetricsModel from "@/modules/metrics/model";
import NotificationsModel from "@/modules/notifications/model";
import OptinsModel from "@/modules/optins/model";
import PlanModel from "@/modules/plan/model";
import UsersModel from "@/modules/users/model";
import TemplateEditorModel from "@/modules/templateeditor/model";
// Set model constructors container
app.models = {
  accounts: AccountsModel,
  automations: AutomationsModel,
  campaigns: CampaignsModel,
  contacts: ContactsModel,
  dashboard: DashboardModel,
  designs: DesignsModel,
  drips: DripsModel,
  emailtemplates: EmailTemplatesModel,
  fields: FieldsModel,
  images: ImagesModel,
  integrations: IntegrationsModel,
  interests: InterestsModel,
  lists: ListsModel,
  metrics: MetricsModel,
  notifications: NotificationsModel,
  optins: OptinsModel,
  plan: PlanModel,
  users: UsersModel,
  templateeditor: TemplateEditorModel,
};

// Collections
import AccountsCollection from "@/modules/accounts/collection";
import AutomationsCollection from "@/modules/automations/collection";
import AutomationsActivityCollection from "@/modules/automationsactivity/collection";
import CampaignsCollection from "@/modules/campaigns/collection";
import ContactsCollection from "@/modules/contacts/collection";
import DesignsCollection from "@/modules/designs/collection";
import DripsCollection from "@/modules/drips/collection";
import FieldsCollection from "@/modules/fields/collection";
import ImagesCollection from "@/modules/images/collection";
import InterestsCollection from "@/modules/interests/collection";
import IntegrationsCollection from "@/modules/integrations/collection";
import LinksCollection from "@/modules/links/collection";
import ListsCollection from "@/modules/lists/collection";
import MetricsActivityCollection from "@/modules/activity/collection";
import NotificationsCollection from "@/modules/notifications/collection";
import OptinsCollection from "@/modules/optins/collection";
import TasksCollection from "@/modules/tasks/collection";
// Set collection constructors container
app.collections = {
  accounts: AccountsCollection,
  activity: MetricsActivityCollection,
  automations: AutomationsCollection,
  automationsactivity: AutomationsActivityCollection,
  campaigns: CampaignsCollection,
  contacts: ContactsCollection,
  designs: DesignsCollection,
  drips: DripsCollection,
  fields: FieldsCollection,
  images: ImagesCollection,
  integrations: IntegrationsCollection,
  interests: InterestsCollection,
  lists: ListsCollection,
  links: LinksCollection,
  notifications: NotificationsCollection,
  optins: OptinsCollection,
  tasks: TasksCollection,
};

// Views
import AccountsEditView from "@/modules/accounts/views/item";
import AccountsListView from "@/modules/accounts/views/list";
import AutomationsListView from "@/modules/automations/views/list";
import AutomationsEditView from "@/modules/automations/views/item";
import AutomationsContentView from "@/modules/automations/views/content";
import AutomationsTemplateView from "@/modules/automations/views/template";
import AutomationsActivityView from "@/modules/automationsactivity/views/index";
import CampaignsListView from "@/modules/campaigns/views/list";
import CampaignsItemView from "@/modules/campaigns/views/item";
import CampaignsSelectorView from "@/modules/campaigns/views/selector";
import ContactsItemView from "@/modules/contacts/views/item";
import ContactsListView from "@/modules/contacts/views/list";
import ContactsImporterView from "@/modules/importer/views/item";
import DashboardIndexView from "@/modules/dashboard/views/index";
import DesignsListView from "@/modules/designs/views/list";
import DesignsItemView from "@/modules/designs/views/item";
import DripsListView from "@/modules/drips/views/list";
import DripsEditView from "@/modules/drips/views/item";
import FieldsItemView from "@/modules/fields/views/item";
import FieldsListView from "@/modules/fields/views/list";
import ImagesListView from "@/modules/images/views/list";
import IntegrationsListView from "@/modules/integrations/views/list";
import IntegrationsItemView from "@/modules/integrations/views/item";
import IntegrationsAuthView from "@/modules/integrations/views/auth";
import InterestsItemView from "@/modules/interests/views/item";
import InterestsListView from "@/modules/interests/views/list";
import InterestsSelectorView from "@/modules/interests/views/selector";
import LayoutIndexView from "@/modules/layout/views/index";
import LinksSelectorView from "@/modules/links/views/selector";
import ListsItemView from "@/modules/lists/views/item";
import ListsListView from "@/modules/lists/views/list";
import ListsSelectorView from "@/modules/lists/views/selector";
import LoginIndexView from "@/modules/login/views/index";
import MetricsBaseView from "@/modules/metrics/views/base";
import MetricsContentsView from "@/modules/metrics/views/contents";
import MetricsDetailsView from "@/modules/metrics/views/details";
import MetricsLinksView from "@/modules/metrics/views/links";
import MetricsActivityView from "@/modules/activity/views/index";
import NotificationsListView from "@/modules/notifications/views/list";
import OptinsListView from "@/modules/optins/views/list";
import OptinsItemView from "@/modules/optins/views/item";
import PlanEditView from "@/modules/plan/views/item";
import SessionIndexView from "@/modules/session/views/index";
import SignupIndexView from "@/modules/signup/views/index";
import TrialIndexView from "@/modules/trial/views/index";
import TasksListView from "@/modules/tasks/views/list";
import UsersEditView from "@/modules/users/views/item";
import TemplateEditorView from "@/modules/templateeditor/views/item";

// Set view constructors container
app.views = {
  layout: { index: LayoutIndexView },
  login: { index: LoginIndexView },
  signup: { index: SignupIndexView },
  session: { index: SessionIndexView },
  trial: { index: TrialIndexView },
  tasks: { list: TasksListView },
  notifications: { list: NotificationsListView },
  dashboard: { index: DashboardIndexView },
  fields: { list: FieldsListView, edit: FieldsItemView },
  interests: {
    list: InterestsListView,
    edit: InterestsItemView,
    selector: InterestsSelectorView,
  },
  lists: {
    list: ListsListView,
    edit: ListsItemView,
    selector: ListsSelectorView,
  },
  contacts: {
    list: ContactsListView,
    edit: ContactsItemView,
    importer: ContactsImporterView,
  },
  optins: { list: OptinsListView, edit: OptinsItemView },
  designs: { list: DesignsListView, edit: DesignsItemView },
  images: { list: ImagesListView },
  campaigns: {
    list: CampaignsListView,
    edit: CampaignsItemView,
    selector: CampaignsSelectorView,
  },
  links: { selector: LinksSelectorView },
  metrics: {
    base: MetricsBaseView,
    contents: MetricsContentsView,
    details: MetricsDetailsView,
    links: MetricsLinksView,
    activity: MetricsActivityView,
  },
  integrations: {
    list: IntegrationsListView,
    edit: IntegrationsItemView,
    auth: IntegrationsAuthView,
  },
  plan: { edit: PlanEditView },
  users: { edit: UsersEditView },
  accounts: { list: AccountsListView, edit: AccountsEditView },
  automations: {
    list: AutomationsListView,
    edit: AutomationsEditView,
    content: AutomationsContentView,
    template: AutomationsTemplateView,
    activity: AutomationsActivityView,
  },
  drips: { edit: DripsEditView, list: DripsListView },
  templateeditor: { edit: TemplateEditorView },
};

//TODO: FIXME
//window.tinyMCE.baseURL = 'plugins/tinymce';

// Set view instances container
app.instances = {
  storage: {},
  isValid: function (module, view) {
    return app.views[module] !== undefined && app.views[module][view] !== undefined;
  },
  get: function (module, view) {
    if (!this.isValid(module, view)) {
      return console.warn("Undefined view: " + module + " " + view);
    }
    return new app.views[module][view]();
  },
  singleton: function (module, view) {
    var instance, events;
    this.storage[module] = this.storage[module] || {};
    if (!this.isValid(module, view)) {
      return console.warn("Undefined view: " + module + " " + view);
    }

    // Create a new instance or return from instance storage
    if (this.storage[module][view] === undefined) {
      instance = this.get(module, view);
      this.storage[module][view] = instance;
    } else {
      instance = this.storage[module][view];
      if (typeof instance.events === "function") {
        events = instance.events();
      } else {
        events = instance.events;
      }
      instance.delegateEvents(events);
    }
    return this.storage[module][view];
  },
};

// Set helpers container
app.helpers = {
  emptyRelationship: function () {
    return {
      success: function () {
        return false;
      },
    };
  },
};

// Set model instance creator
app.getModel = function (name, attributes) {
  return new this.models[name](attributes);
};

// Saves a session cookie
app.saveSessionCookie = function (data, mode) {
  var expires, encoded;

  mode = mode || "session";
  document.cookie = mode + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  // Set session data and expiration date far in the future
  expires = moment().add(365, "days").toDate().toUTCString();
  data.expires = expires;

  // Set the main account
  data.mainAccount = data.account;

  // Save permissions array in localStorage
  localStorage.setItem("acl", JSON.stringify(data.acl));

  //Max cookie size = 4096, hay que borrar cosas para no excederlo
  delete data.acl;
  delete data.currentAccount;
  if (data?.details?.account?.plan) {
    delete data.details.account.plan;
  }
  if (data?.permission) {
    delete data.permissions;
  }

  // Write session cookie
  encoded = encodeURIComponent(JSON.stringify(data));
  document.cookie = mode + "=" + encoded + "; expires=" + expires + "; path=/";
  return this.readSessionCookie(mode);
};

// Reads a session cookie
app.readSessionCookie = function (mode) {
  mode = mode || "session";

  if (!$.cookie(mode)) {
    return false;
  }

  // Get session data from cookie and local storage
  return $.extend({}, JSON.parse(decodeURIComponent($.cookie(mode))), {
    acl: JSON.parse(localStorage.getItem("acl")),
  });
};

app.setTrial = function (trialEnds) {
  const trial = {};

  if (trialEnds) {
    trial.ends = trialEnds;
    var trialEndsDate = moment(trialEnds);
    var todaysDate = moment();
    trial.daysLeft = trialEndsDate.diff(todaysDate, "days");
    trial.hoursLeft = trialEndsDate.diff(todaysDate, "hours");
    trial.inTrial = trial.hoursLeft >= 0;
  } else {
    trial.inTrial = false;
  }

  localStorage.setItem("trial", JSON.stringify(trial));

  app.instances.singleton("trial", "index").render();
};

app.getTrial = function () {
  let trial = { inTrial: false };
  try {
    if (localStorage.getItem("trial")) {
      trial = JSON.parse(localStorage.getItem("trial"));
    }
  } catch (e) {
    app.integrations.bugsnag.notify(e);
  }
  return trial;
};

app.setPlan = function (plan) {
  localStorage.setItem("plan", JSON.stringify(plan));
  app.instances.singleton("trial", "index").render();
};

app.getPlan = function () {
  let plan;
  try {
    if (localStorage.getItem("plan")) {
      plan = JSON.parse(localStorage.getItem("plan"));
    }
  } catch (e) {
    app.integrations.bugsnag.notify(e);
  }
  return plan;
};

app.initLang = function () {
  const session = this.readSessionCookie();

  let navLang = navigator.language && navigator.language.substr(0, 2);
  const country = app.detectedCountry;

  if (country === "br") {
    //override del lang por si en brasil tienen navegador en ingles
    navLang = "pt";
  }

  let lang =
    (session && session.userType !== "MASTER" && session.details?.user?.language) ||
    (navLang && ["es", "pt"].includes(navLang) ? navLang : "es");

  try {
    if (!session && localStorage.getItem("lang")) {
      lang = JSON.parse(localStorage.getItem("lang"));
    }
  } catch (e) {
    app.integrations.bugsnag.notify(e);
  }

  this.lang = lang;
};

app.updateLang = function (lang) {
  this.lang = lang;
  localStorage.setItem("lang", JSON.stringify(lang));
  return this.setupLang();
};

app.setupLang = function () {
  const self = this;

  // TODO esto deberia refactorearse?
  // set lang a la api nueva
  setLang(self.lang);
  setLangAPIV3(self.lang);

  return $.ajax({
    url: `/lang/${self.lang}.json`,
    cache: false,
    success: function (languageStrings) {
      // Set language as a global variable
      window.lang = languageStrings;
      //window.lang.campaigns2 = window.lang.campaigns;

      // Set time language
      moment.locale(self.lang);

      // Set datepicker format
      $.fn.datepicker.defaults.format = "yyyy-mm-dd";

      // Set language AJAX header
      $.ajaxSetup({
        headers: {
          "Accept-Language": self.lang,
        },
      });
    },
    error: function () {
      alert("Error al intentar configurar i18n.");
    },
  });
};

// Setup method
app.init = function () {
  var self = this,
    tasks = [];

  app.initLang();

  // Define tasks
  tasks.push(
    // Set localization options
    app.setupLang(),
  );

  // When locatization is done, continue setup
  $.when.apply(null, tasks).done(function () {
    // Send data as application/x-www-form-urlencoded
    window.Backbone.emulateJSON = false;

    // Set AJAX URL root based on API configuration and account name
    $.ajaxPrefilter("json text html", function (options) {
      var account = "",
        reserved = ["login", "signup", "signup/confirm", "logout"],
        localFolders = ["/data/", "/lang/", "/plugins/"],
        local = false,
        addAccount = true,
        api,
        addApi = true;

      // Abort if the call is not sent to local folders
      $.each(localFolders, function (index, value) {
        if (options.url.indexOf(value) > -1) {
          local = true;
        }
      });
      if (local) {
        return true;
      }

      // If the session is not initialized, ommit the account name
      if (app.session === undefined) {
        addAccount = false;
      }

      // If the call uses reserved namespaces, ommit account name
      if ($.inArray(options.url, reserved) > -1 || options.url.startsWith(reserved)) {
        addAccount = false;
      }

      // If the header is present, ommit the account name
      if (options.headers["Account-Prefix"] !== undefined && !options.headers["Account-Prefix"]) {
        delete options.headers["Account-Prefix"];
        addAccount = false;
      }

      // For guests, ommit the account name
      if (app.session !== undefined && app.session.get("isGuest")) {
        addAccount = false;
      }

      // If the header is present, ommit the API root and account name
      if (options.headers["Api-Prefix"] !== undefined && !options.headers["Api-Prefix"]) {
        delete options.headers["Api-Prefix"];
        addApi = false;
      }

      // For master users, ommit the account in the notifications
      if (options.url.indexOf("masterusers/me/notifications") === 0) {
        addAccount = false;
      }

      if (options.url.startsWith("apiv3://")) {
        options.url = options.url.replace("apiv3://", window.config.apiv3);
        options.headers["x-account"] = app.session.get("account");
        addApi = false;
        addAccount = false;
      }

      // Otherwise, get the account to be always added to the request URL
      if (addAccount) {
        account = app.session.get("account") + "/";
        options.headers["x-account"] = app.session.get("account");
      }

      // For notifications or context in child accounts, use the main account instead
      if (
        (options.url.indexOf("users/me/notifications") === 0 || options.url.indexOf("users/me/context") === 0) &&
        app.session.get("canChangeAccount")
      ) {
        account = app.session.get("mainAccount") + "/";
      }

      // Return full URL
      api = addApi ? window.config.api : "";
      options.url = api + account + options.url;
    });

    // Set global Ajax settings
    $.ajaxSetup({
      // JSON type by default for all requests
      dataType: "json",

      // Timeout set in 10 seconds
      timeout: 10 * 1000,

      // Generic error handler
      error: function (xhr, status) {
        var error,
          message,
          magnetInstance = false;

        // Determine if a magnet view is instantiated
        if (self.router?.current) {
          magnetInstance = true;

          // Turn off loader
          self.router.current.loading(false);
        }

        // Parse responseText if responseJSON is undefined
        if (xhr.responseJSON === undefined && xhr.getResponseHeader("Content-Type") === "application/json") {
          xhr.responseJSON = JSON.parse(xhr.responseText);
        }

        // JSON error
        if (xhr.responseJSON?.error) {
          error = xhr.responseJSON.error;
          message = error.userMessage;
          if (error.incidentCode !== undefined && error.incidentCode) {
            message += " (" + error.incidentCode + ")";
          }

          // Show validation errors
          if (magnetInstance && error.validationErrors !== undefined && _.size(error.validationErrors)) {
            return self.router.current.showValidationErrors(error.validationErrors);
          }

          // Logout on unauthorized responses
          if (error.type === "UNAUTHORIZED") {
            if (window.loggingOut === undefined) {
              window.loggingOut = true;
              return app.session.logout(true);
            } else {
              return false;
            }
          }

          // Show user message on invalid requests
          if (_.contains(["FORBIDDEN", "VALIDATION_ERROR", "INVALID_REQUEST", "INVALID_OPERATION"], error.type)) {
            if (magnetInstance) {
              $(self.router.current.modal).modal("hide");
              self.router.current.displayMessage(message, 10000, "error");
            } else {
              alert(message);
            }
            return false;
          }

          // Show not found page
          if (error.status === 404) {
            return app.layout.notFound();
          }

          // Alert server internal errors
          if (error.status === 500 || error.status === 503) {
            if (magnetInstance) {
              $(self.router.current.modal).modal("hide");
              self.router.current.displayMessage(message, 10000, "error");
            } else {
              alert(message);
            }
            return false;
          }
        }

        // Timeout error
        if (status === "timeout") {
          if (magnetInstance) {
            $(self.router.current.modal).modal("hide");
            self.router.current.displayMessage(window.lang.requestTimeout, 10000, "error");
          } else {
            console.error(window.lang.requestTimeout);
          }
          return false;
        }

        // Show offline network error
        if (!navigator.onLine) {
          if (magnetInstance) {
            $(self.router.current.modal).modal("hide");
            self.router.current.displayMessage(window.lang.offlineNetwork, 10000, "error");
          } else {
            console.error(window.lang.offlineNetwork);
          }
          return false;
        }

        // Show generic error
        if (status === "error") {
          if (magnetInstance) {
            $(self.router.current.modal).modal("hide");
            self.router.current.displayMessage(window.lang.serverError, 10000, "error");
          } else {
            console.error(window.lang.serverError);
          }
          return false;
        }
      },
    });

    // Set flag to prevent or allow the view changes
    window.preventViewChange = false;

    // Set flag to indicate that the account details are missing
    window.shouldCompleteDetails = false;

    // Check if the app is in maintenance mode
    if (window.config.maintenance !== undefined && window.config.maintenance) {
      return app.maintenance();
    }

    // Start integrations
    self.integrations.intercom.boot();
    self.integrations.amplitude.boot();

    // Instance the route
    self.router = new Router();

    // Start listening
    window.Backbone.history.start({ pushState: true });

    // Setup NProgress
    window.NProgress.configure({
      easing: "ease",
      speed: 100,
      showSpinner: false,
      minimum: 0.2,
      trickleSpeed: 50,
      parent: ".page",
    });

    // If the session is over, reload
    document.addEventListener("visibilitychange", function () {
      if (!$(".app.login").length && !$.cookie("session") && !app.session.get("isGuest")) {
        window.location.reload();
      }
    });
  });
};

// Display the maintenance mode message
app.maintenance = function () {
  $(".app").html(require("@/modules/layout/templates/maintenance.hbs")({ lang: window.lang }));
  $("html").removeAttr("style").addClass("has-pattern");
};

// Fix Tiny MCE modal focus bug
$(document).on("focusin", function (e) {
  if ($(e.target).closest(".mce-window").length) {
    e.stopImmediatePropagation();
  }
});

app.init();
