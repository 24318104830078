var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"welcome")), depth0))
    + "\n      </h1>\n      <div class='circle-logo'></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"welcomeWhiteLabel")), depth0))
    + "\n      </h1>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='login-panel'>\n  <form method='POST' data-form='login'>\n    <div class='login-panel-header'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"config") : depth0)) != null ? lookupProperty(stack1,"whiteLabel") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class='login-panel-body'>\n      <fieldset>\n        <div class='form-group'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"email")), depth0))
    + "\n          </label>\n          <input type='text' class='form-control input-lg user-input' name='user'\n            placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"userPlaceholder")), depth0))
    + "\">\n        </div>\n        <div class='form-group form-group-last'>\n          <label>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"password")), depth0))
    + "\n          </label>\n          <div class='input-group'>\n            <input type='password' class='form-control input-lg password-input' name='password' autocomplete='off'\n              placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"passPlaceholder")), depth0))
    + "\">\n            <span class='input-group-addon' data-action='reveal-password'>\n              <i class='fa fa-eye'></i>\n              <i class='fa fa-eye-slash'></i>\n            </span>\n          </div>\n        </div>\n        <div class='accounts'></div>\n      </fieldset>\n      <a href='#' class='password-recovery-link' data-action='render-reset-password'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"recoverPassword")), depth0))
    + "\n      </a>\n      <div class='login-error auth-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"invalidCredentials")), depth0))
    + "\n        </p>\n        <a href='#' data-action='render-reset-password'>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"forgotPassword")), depth0))
    + "\n        </a>\n      </div>\n      <div class='login-error server-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"serverError")), depth0))
    + "\n        </p>\n      </div>\n      <div class='login-error disabled-account-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"disabledAccountError")), depth0))
    + "\n        </p>\n      </div>\n      <div class='login-error expired-session-error hide'>\n        <p>\n          "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"expiredSessionError")), depth0))
    + "\n        </p>\n      </div>\n    </div>\n    <div class='login-panel-footer'>\n      <button class='btn btn-primary' type='submit'>\n        <i class='fa fa-arrow-right'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"enter")), depth0))
    + "\n      </button>\n      <a href='/signup' class='btn btn-primary btn-outline'>\n        <i class='fa fa-plus'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"createAccount")), depth0))
    + "\n      </a>\n    </div>\n  </form>\n</div>\n<div class='login-help'>\n  "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"help")), depth0)) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});