<template>
  <div :class="[stringTags?.length === 0 ? 'py-5' : 'py-3', 'space-y-1']">
    <p class="flex gap-2">
      <a
        v-if="campaignsUpdate"
        :href="`/campaigns/${row.data.id}`"
        class="line-clamp-1 text-sm font-medium text-sky-400"
      >
        {{ row.data.name }}
      </a>
      <span v-else class="line-clamp-1 text-sm font-medium text-sky-400">
        {{ row.data.name }}
      </span>
      <SimpleBadge theme="sky" square size="small" class="shrink-0">
        <template #icon>
          <div ref="tooltipRef">
            <BeakerIcon v-if="row.data.type === 'SPLIT_AB'" class="h-4 w-4 text-sky-400" />
            <CalendarIcon v-else-if="row.data.type === 'SMART'" class="h-4 w-4 text-sky-400" />
            <MailIcon v-else class="h-4 w-4 text-sky-400" />
          </div>
        </template>
        <span v-show="xlBp">
          {{ t(`type.${row.data.type}`) }}
        </span>
      </SimpleBadge>
      <SimpleBadge :theme="stateTheme" square>
        {{ t(`state.${row.data.archived ? "ARCHIVED" : row.data.state}`, {}, { default: t("state.SCHEDULED") }) }}
      </SimpleBadge>
    </p>

    <p :class="[row.data.description ? 'text-gray-500' : 'text-gray-300', 'mb-2 line-clamp-1 text-xs ']">
      {{ row.data.description || t("placeholderDescription") }}
    </p>
    <TagsGroup
      readonly
      clickable
      :show-tag-colors="false"
      :selected-tags="stringTags"
      tag-size="small"
      @click="selectTag"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from "vue";

import type { CampaignsRow } from "../../../campaigns.types";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import type { Theme as BadgeTheme } from "@atoms/SimpleBadge";
import { TagsGroup } from "@organisms/Tags";

// Icons
import { BeakerIcon, MailIcon, CalendarIcon } from "@heroicons/vue/solid";

// Composables
import { useTooltip } from "@composables/tooltips";
import { useBreakpoints } from "@composables/breakpoints";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore } from "@store";

// Domain
import type { Tags, Tag } from "@domain/tag";

const sessionStore = useSessionStore();
const campaignsUpdate = sessionStore.hasPermission("campaigns:update");

const { t } = useI18n();
const { xlBp } = useBreakpoints();

const props = defineProps<{
  row: CampaignsRow;
}>();

// Emits
const emit = defineEmits<{
  (e: "selectTag", tag: Tag): void;
}>();

const selectTag = (tag: Tag) => {
  emit("selectTag", tag);
};

const tooltipRef = ref();
const tooltip = useTooltip(tooltipRef, t(`type.${props.row.data.type}`), {
  placement: "bottom",
  popperOptions: {
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          tether: false,
          boundary: "clippingParents",
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["bottom", "top"],
          fallbackPlacements: ["bottom", "top"],
          altBoundary: true,
        },
      },
    ],
  },
});
watchEffect(() => {
  if (xlBp.value) {
    tooltip.disable();
    return;
  }
  tooltip.enable();
});

const stringTags = computed<Tags>(() => {
  return props.row.data.tags.map<Tag>((tag) => ({
    id: tag,
    name: tag,
    color: "#FFF",
  }));
});

const stateTheme = computed<BadgeTheme>(() => {
  if (props.row.data.archived) return "gray";
  if (props?.row?.data.state === "SENT") return "green";
  if (props?.row?.data.state === "SENDING") return "light-green";
  if (props?.row?.data.state === "SCHEDULED") return "blue";
  if (props?.row?.data.state === "CANCELLED") return "red";
  if (props?.row?.data.state === "PENDING_APPROVAL") return "yellow";

  return "gray";
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderDescription": "Sin descripción",
    "type": {
      "SIMPLE": "Simple",
      "SPLIT_AB": "Test A/B",
      "SMART": "Inteligente"
    },
    "state": {
      "SENT": "Enviada",
      "SENDING": "Enviando",
      "CANCELLED": "Cancelada",
      "SCHEDULED": "Programada",
      "DRAFT": "Borrador",
      "PENDING_APPROVAL": "En revisión",
      "ARCHIVED": "Archivada"
    }
  },
  "pt": {
    "placeholderDescription": "Sem descrição",
    "type": {
      "SIMPLE": "Simples",
      "SPLIT_AB": "Teste A/B",
      "SMART": "Inteligente"
    },
    "state": {
      "SENT": "Enviada",
      "SENDING": "Enviando",
      "CANCELLED": "Cancelada",
      "SCHEDULED": "Agendada",
      "DRAFT": "Rascunho",
      "PENDING_APPROVAL": "Em revisão",
      "ARCHIVED": "Arquivada"
    }
  }
}
</i18n>
