import { StoreDashboardData } from "./store";
import { EmailsDashboardData } from "./emails";
import { ContactsDashboardData } from "./contacts";
import { Period, PlanInfo, BatchType, IDashboardDataSet, IDashboardDataPoint, PeriodType, IEmailMetrics } from "./base";

export class DashboardData {
  // readonly planInfo: any; //para calculo de ROI, necesitamos costo del plan si es mensual o costo unitario de envios si es pack. Y si es mensula por envios y tiene excedentes?
  readonly store: StoreDashboardData;
  readonly emails: EmailsDashboardData;
  readonly contacts: ContactsDashboardData;

  constructor(
    readonly dataSet: IDashboardDataSet,
    readonly period: Period,
    readonly planInfo: PlanInfo,
    readonly batchTypeFilter: BatchType,
    readonly attributionModel: "clicks" | "opens" = "opens"
  ) {
    this.store = new StoreDashboardData(this.dataSet, this.period, this.planInfo, this.attributionModel);
    this.emails = new EmailsDashboardData(this.dataSet, this.period, this.batchTypeFilter);
    this.contacts = new ContactsDashboardData(this.dataSet, this.period);
  }
}

export { Period, BatchType, IDashboardDataPoint, IDashboardDataSet, PeriodType, IEmailMetrics };
