import { ref } from "vue";

import { defineStore } from "pinia";
import { useSenders } from "@api/modules/senders/senders";
import type { Senders } from "@domain/Senders";

export const useSendersStore = defineStore("sendersStore", () => {
  const sendersService = useSenders();
  const senders = ref<Senders>();
  const sendersFetched = ref<boolean>(false);

  return {
    getSenders() {
      return senders.value;
    },
    async fetchSenders() {
      senders.value = await sendersService.list();
      sendersFetched.value = true;
    },
    senders,
    sendersFetched,
  };
});
