<template>
  <div class="text-sm">
    <TabGroup :selected-index="selectedTabIndex" vertical @change="onTabChange">
      <TabList class="flex flex-col">
        <TabEl v-for="tab in tabs" :key="tab.id" v-slot="{ selected }" as="template">
          <button
            class="group py-2 focus:outline-none border-l-4"
            :data-intercom-target="`EditorConfigTab-${tab.id}`"
            :class="[selected ? 'bg-sky-50 border-sky-400' : 'border-transparent']"
          >
            <div class="flex ml-6 space-x-3 items-center">
              <component
                :is="tab.icon"
                class="h-6 w-6"
                :class="[selected ? 'text-sky-400' : 'text-gray-400 group-hover:text-gray-500']"
                aria-hidden="true"
              />
              <span
                class="font-medium"
                :class="[selected ? 'text-sky-500' : 'text-gray-500 group-hover:text-gray-600']"
                >{{ tab.name }}</span
              >
            </div>
          </button>
        </TabEl>
      </TabList>
    </TabGroup>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
//Components
import { TabGroup, TabList, Tab as TabEl } from "@headlessui/vue";

//Type
import type { Component } from "vue";
export interface Tab<idType = number | string> {
  id: idType;
  name: string;
  icon: Component;
}

export type Tabs<idType = number | string> = Array<Tab<idType>>;

//Props
const props = withDefaults(
  defineProps<{
    tabs: Tabs;
    selectedTab: Tab;
  }>(),
  {
    tabs: () => [],
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:selectedTab", tab: Tab): void;
}>();

const onTabChange = (index) => {
  const tab = props.tabs[index];
  emit("update:selectedTab", tab);
};

//State
const selectedTabIndex = computed(() => props.tabs.findIndex((tab) => tab.id === props.selectedTab.id));
</script>
