import { Predicates } from "./predicates";

export type TypesNames = "rfm"; // Habrá mas casos de types en el futuro

export type StageNames =
  | "champion"
  | "loyalist"
  | "recent"
  | "highpotential"
  | "neednutring"
  | "cantlose"
  | "atrisk"
  | "abouttolose";

// Stats
export interface Stage {
  contacts: number;
  orders: number;
  gmv: number;
  list_id: string;
  predicate: Predicates;
}

export type Stats = {
  cantlose: Stage;
  abouttolose: Stage;
  neednutring: Stage;
  highpotential: Stage;
  atrisk: Stage;
  recent: Stage;
  loyalist: Stage;
  champion: Stage;
};

// Definitions

export interface StageDefinition {
  name: StageNames;
  listId: string;
  ttl: number;
  predicate: Predicates;
}

export type Definitions = {
  id: string;
  trigger: string;
  type: TypesNames;
  field: string;
  created: DateString;
  account: string;
  stages: Array<StageDefinition>;
};

export const getEmptyDefinition = (): Definitions => ({
  id: "id",
  trigger: "order.rfm.update",
  type: "rfm",
  stages: [
    {
      name: "champion",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "6",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-45",
          },
        ],
        operator: "and",
      },
      listId: "30",
      ttl: 45,
    },
    {
      name: "loyalist",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "3",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-45",
          },
        ],
        operator: "and",
      },
      listId: "31",
      ttl: 45,
    },
    {
      name: "recent",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "1",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-45",
          },
        ],
        operator: "and",
      },
      listId: "32",
      ttl: 45,
    },
    {
      name: "highpotential",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "5",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-90",
          },
        ],
        operator: "and",
      },
      listId: "33",
      ttl: 45,
    },
    {
      name: "neednutring",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "1",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-90",
          },
        ],
        operator: "and",
      },
      listId: "34",
      ttl: 45,
    },
    {
      name: "cantlose",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "5",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-180",
          },
        ],
        operator: "and",
      },
      listId: "35",
      ttl: 90,
    },
    {
      name: "atrisk",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "1",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-180",
          },
        ],
        operator: "and",
      },
      listId: "36",
      ttl: 90,
    },
    {
      name: "abouttolose",
      predicate: {
        attributes: [
          {
            attribute: "orders",
            operator: "gte",
            type: "number",
            value: "1",
          },
          {
            attribute: "last_order",
            operator: "gtrel",
            type: "date",
            value: "-365",
          },
        ],
        operator: "and",
      },
      listId: "37",
      ttl: 180,
    },
  ],
  field: "rfm_status",
  account: "test20220128",
  created: "2023-02-23T18:49:37Z",
});

export const getEmptyStats = (): Stats => ({
  cantlose: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "312",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "5",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-180",
        },
      ],
      operator: "and",
    },
  },
  abouttolose: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "321",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "1",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-365",
        },
      ],
      operator: "and",
    },
  },
  neednutring: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "318",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "1",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-90",
        },
      ],
      operator: "and",
    },
  },
  highpotential: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "317",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "5",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-90",
        },
      ],
      operator: "and",
    },
  },
  atrisk: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "320",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "1",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-180",
        },
      ],
      operator: "and",
    },
  },
  recent: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "316",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "1",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-45",
        },
      ],
      operator: "and",
    },
  },
  champion: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "314",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "6",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-45",
        },
      ],
      operator: "and",
    },
  },
  loyalist: {
    orders: 0,
    gmv: 0,
    contacts: 0,
    list_id: "315",
    predicate: {
      attributes: [
        {
          attribute: "orders",
          operator: "gte",
          type: "number",
          value: "3",
        },
        {
          attribute: "last_order",
          operator: "gtrel",
          type: "date",
          value: "-45",
        },
      ],
      operator: "and",
    },
  },
});

export const getSumAllContacts = (stats: Stats): number => {
  let total = 0;
  Object.entries(stats).forEach(([key, value]) => {
    total += value.contacts;
  });
  return total;
};
