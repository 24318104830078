<template>
  <div class="flex flex-col space-y-2">
    <div v-for="tag in tags" :key="tag.id" ref="filteredTagsRef">
      <SimpleBadge
        :id="tag.id"
        square
        :deletable="!readonly"
        :clickable="clickable"
        :size="tagSize"
        :dot="showTagColors"
        :dot-color="tag.color"
        :tooltip="(el) => handleTooltip(el, tag)"
        theme="gray"
        @delete="() => onDelete(tag)"
        @click="() => clickTag(tag)"
      >
        <span :id="tag.id" class="max-w-[8rem] truncate">{{ tag.name }}</span>
      </SimpleBadge>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import type { Size } from "@atoms/SimpleBadge";

// Types
import type { Tags, Tag } from "@/vue/types/tag";

// Props
interface Props {
  tags: Tags;
  readonly: boolean;
  showTagColors?: boolean;
  clickable?: boolean;
  tagSize?: Size;
}

const props = withDefaults(defineProps<Props>(), {
  tags: () => [],
  readonly: false,
  showTagColors: true,
  clickable: false,
});

// Emits
const emit = defineEmits<{
  (e: "delete", tag: Tag): void;
  (e: "click", tag: Tag): void;
}>();

const onDelete = (tag: Tag) => emit("delete", tag);
const clickTag = (tag: Tag) => emit("click", tag);

// Tooltip Handlers
const filteredTagsRef = ref<Array<HTMLElement>>();

const handleTooltip = (element: HTMLDivElement, tag: Tag) => {
  const foundTagElement = filteredTagsRef.value?.find(
    (tagElement) => (tagElement.firstChild as HTMLSpanElement)?.id.toString() === tag.id.toString()
  );

  if (!foundTagElement) return;

  const textWidth = (foundTagElement.firstChild as HTMLSpanElement).children.namedItem(tag.id)?.scrollWidth;

  if (!textWidth) return;

  const containerWidth = element.scrollWidth;

  return textWidth > containerWidth ? tag.name : undefined;
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
