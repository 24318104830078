import { Collection } from '@/core/magnet'
import { _ } from '@/helpers/utils'
import ContactsModel from './model'

export default Collection.extend({
  url: 'contacts',
  model: ContactsModel,
  parse: function (response) {
    return response.data;
  },
  filters: {
    list: {
      key: 'list',
      icon: 'pi-filter-lists',
      name: function () {
        return window.lang.lists.singular;
      },
      source: function () {
        return $.get('lists', { limit: 1000 });
      },
      parser: function (item) {
        var legend = item.totalContacts + ' ' + window.lang.contacts.plural;
        return {
          id: item.id,
          title: item.name,
          subtitle: item.totalContacts ? legend : window.lang.contacts.none,
          tags: item.tags,
          keywords: _.flatten([item.name, item.tags]).join(' '),
          params: {
            key: 'filter.list',
            value: item.id
          }
        };
      }
    },
    interest: {
      key: 'interest',
      icon: 'pi-filter-interests',
      name: function () {
        return window.lang.interests.singular;
      },
      source: function () {
        return $.get('interests', { limit: 10000 });
      },
      parser: function (item) {
        return {
          id: item.id,
          title: item.name,
          keywords: item.name,
          params: {
            key: 'filter.interest',
            value: item.id
          }
        };
      }
    },
    state: {
      key: 'state',
      icon: 'pi-filter-state',
      name: function () {
        return window.lang.status;
      },
      source: function () {
        var data = [
          { id: 'ACTIVE' },
          { id: 'LIVE' },
          { id: 'NOT_LIVE' },
          { id: 'BOUNCED' },
          { id: 'UNSUBSCRIBED' },
          { id: 'COMPLAINED' },
          { id: 'INACTIVE' }
        ];
        return Promise.resolve({ data: data });
      },
      parser: function (item) {
        var translated = window.lang.contacts.filters.state[item.id];
        return {
          id: item.id,
          title: translated,
          keywords: translated,
          params: {
            key: 'filter.state',
            value: item.id
          }
        };
      }
    }
  },
  additionals: {
    columns: function () {
      return $.get('lists/0/columns', { limit: 100 });
    }
  },
  export: function (data) {
    return $.post('contacts/export', data);
  },
  getColumns: function () {
    return $.get('lists/0/columns', { limit: 100 });
  }
});