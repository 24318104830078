<template>
  <div v-if="!loading">
    <div v-if="showOptinRule && rule">
      <FormSwitch
        v-model="rule.enabled"
        :reverse="true"
        :label="t('toggleSwitch.title')"
        class="mb-6"
        @update:model-value="showEnableConfirmation"
      >
        <template #description>
          {{ rule?.enabled ? t("toggleSwitch.descriptionToggleOn") : t("toggleSwitch.descriptionToggleOff") }}
          <a href="#" @click.prevent="gotoHelp(6967467)">Leer más</a>
        </template>
      </FormSwitch>

      <template v-if="rule.enabled">
        <FormGroup id="open-mode" class="mb-6" :label="t('visualization.title')">
          <div class="mb-4 flex items-center space-x-2">
            <span>
              {{ t("visualization.description") }}
            </span>
            <SelectMenu
              v-model:selected="selectedLocationMode"
              :data-items="LocationModes"
              class="inline-block w-60"
              @update:selected="selectedLocationChanged"
            />
            <span v-if="selectedLocationMode.key === 'contains'">
              {{ t("visualization.pathWords.contains") }}
            </span>
            <span v-if="selectedLocationMode.key === 'not_contains'">
              {{ t("visualization.pathWords.noContains") }}
            </span>

            <SimpleInput
              v-if="selectedLocationMode.key !== 'all_pages' && selectedLocationMode.key !== 'advanced'"
              v-model="locationKeywords"
              :inline="true"
              class="w-64"
              :placeholder="t('visualization.pathWords.input')"
              @keyup="selectedLocationChanged"
            ></SimpleInput>
          </div>
          <div v-if="advancedMode" class="mb-4">
            <SimpleInput v-model="rule.trigger_options.url" placeholder="raw regular expression here"></SimpleInput>
          </div>
          <div class="mb-4 grid grid-cols-2 gap-2">
            <SelectMenu
              v-model:selected="selectedTriggerMode"
              :data-items="TriggerModes"
              @update:selected="selectedTriggerModeChanged"
            />

            <div v-if="selectedTriggerMode.key === 'delayed'" class="col-span-1 flex items-center space-x-2">
              <span>
                {{ t("visualization.triggerModes.description.of") }}
              </span>
              <SimpleInput
                v-model.number="rule.trigger_mode_options.delay"
                type="number"
                min="1"
                max="120"
                class="w-16"
                :inline="true"
                @blur="validate"
              ></SimpleInput>
              <span>
                {{ t("visualization.triggerModes.description.seconds", rule.trigger_mode_options.delay) }}
              </span>
            </div>

            <div v-if="selectedTriggerMode.key === 'scroll'" class="col-span-1 flex items-center space-x-2">
              <span>
                {{ t("visualization.triggerModes.description.see") }}
              </span>
              <SimpleInput
                v-model.number="rule.trigger_mode_options.scroll"
                type="number"
                min="10"
                max="100"
                class="w-16"
                :inline="true"
                @blur="validate"
              />
              <span>
                {{ t("visualization.triggerModes.description.contain") }}
              </span>
            </div>
          </div>
        </FormGroup>

        <FormGroup id="repeat-mode" class="mb-6" :label="t('repetition.title')">
          <div class="mb-4">
            {{ t("repetition.descriptionContinue") }}
            <SelectMenu
              v-model:selected="selectedStopMode"
              :data-items="StopModes"
              class="ml-1 inline-block w-60"
              @update:selected="selectedStopModeChanged"
            />
          </div>
          <div class="mb-4 flex items-center space-x-2">
            <span>
              {{ t("repetition.descriptionToWatch") }}
            </span>
            <SimpleInput
              v-model.number="rule.trigger_options.max_displays"
              type="number"
              min="1"
              max="100"
              class="w-16"
              :inline="true"
              @blur="validate"
            />
            <span>
              {{ t("repetition.wait", rule.trigger_options.max_displays) }}
            </span>
            <SimpleInput
              v-model.number="rule.trigger_options.interval_days"
              type="number"
              min="0"
              max="180"
              class="w-16"
              :inline="true"
              @blur="validate"
            />
            <span v-if="selectedStopMode.key === 'stop_on_success'">
              {{ t("repetition.subscription", rule.trigger_options.interval_days) }}
            </span>
            <span v-else>
              {{ t("repetition.visualization", rule.trigger_options.interval_days) }}
            </span>
          </div>
        </FormGroup>
      </template>
      <AlertBox v-if="canSave" theme="info" class="mb-6">
        {{ t("alertBox.changes") }}
      </AlertBox>
      <div class="mt-6 flex flex-col gap-4 sm:flex-row-reverse">
        <SimpleButton class="mr-4" theme="secondary" :loading="saving" :disabled="!canSave" @click="saveRule">
          <template #leading><CheckIcon /></template>
          {{ t("button.saveChanges") }}
        </SimpleButton>
        <SimpleButton v-if="rule.id && advancedMode" theme="white" @click="deleteRule">{{
          t("button.deleteRule")
        }}</SimpleButton>
      </div>
    </div>

    <div v-else>
      <FormSwitch
        :reverse="true"
        :disabled="true"
        :label="t('noRule.title')"
        :description="t('noRule.description')"
        class="mb-6"
      />
      <AlertBox>
        {{ t("alertBox.description") }}
        <i18n-t keypath="alertBox.helpCenterText">
          <template #helpCenter>
            <a href="#" @click.prevent="gotoHelp('6967392')">{{ t("alertBox.helpCenter") }}</a>
          </template>
        </i18n-t>
      </AlertBox>
    </div>
  </div>
  <div v-else class="grid grid-cols-12 gap-4">
    <div class="w-13 mt-1 h-8 animate-pulse rounded-full bg-gray-100" />
    <div class="col-span-11">
      <div class="mb-2 h-4 w-1/3 animate-pulse rounded-full bg-gray-100" />
      <div class="h-4 w-2/3 animate-pulse rounded-full bg-gray-100" />
    </div>
  </div>

  <div v-if="advancedMode" class="mt-4 grid grid-cols-2 gap-2">
    <pre>loadedRule<br/>{{ loadedRule }}</pre>
    <pre>rule<br/>{{ rule }}
      <br/>ruleChanged: {{ ruleChanged }}
      <br/>newRule: {{ newRule }}</pre>
  </div>
  <ConfirmationModal
    id="enable-confirmation"
    v-bind="enableConfirmModal"
    @cancel="closeEnableConfirmModal"
    @accept="closeEnableConfirmModal"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { cloneDeep, isEqual } from "lodash";
import { useOptinRules } from "@api/modules/optinrules/optinrules";
import { useNotifications } from "@composables/notifications";
import { useAccountFeatures } from "@api/modules/account";
import { useTrackingEvents } from "@/vue/composables/trackingevents";
import { RulesList, Rule } from "@/vue/api/modules/optinrules/optinrules.types";

import { CheckIcon } from "@heroicons/vue/solid";
import AlertBox from "@atoms/AlertBox.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";

import SimpleButton from "@atoms/SimpleButton.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import FormGroup from "@molecules/FormGroup.vue";
import FormSwitch from "@molecules/FormSwitch.vue";
import SelectMenu from "@molecules/SelectMenu.vue";
import type { DataItem, DataItems } from "@molecules/SelectMenu.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

// Store
import { useIntegrationsStore, useOnboardingStore } from "@store";

//Props
const props = defineProps<{
  optinId: string;
}>();

// Composables
const { t } = useI18n();
const { notify } = useNotifications();
const OptinRules = useOptinRules();
const integrationStore = useIntegrationsStore();
const AccountFeatures = useAccountFeatures();
const TrackingEvents = useTrackingEvents();
const onboardingStore = useOnboardingStore();

const defaultRule: Rule = {
  optin_id: props.optinId,
  enabled: false,
  name: props.optinId,
  created: "",
  priority: 10,
  trigger_options: {
    url: ".*",
    stop_on_success: true,
    interval_days: 1,
    max_displays: 5,
  },
  trigger_mode: "delayed",
  trigger_mode_options: {
    delay: 0,
  },
};

// State
const rules = ref<RulesList>();
const loadedRule = ref<Rule>();
const rule = ref<Rule>();
const showOptinRule = ref(false);
const loading = ref(false);
const saving = ref(false);
const locationKeywords = ref("");
const advancedMode = ref(false);

const TriggerModes = [
  { key: "immediate", value: t("visualization.triggerModes.immediate") },
  { key: "delayed", value: t("visualization.triggerModes.delayed") },
  { key: "exit_intent", value: t("visualization.triggerModes.exitIntent") },
  { key: "scroll", value: t("visualization.triggerModes.scroll") },
] as DataItems<string, string, string>;
const selectedTriggerMode = ref<DataItem>(TriggerModes[0]);

const StopModes = [
  { key: "stop_on_success", value: t("repetition.stopOnSuccess") },
  { key: "continue_on_success", value: t("repetition.continueOnSuccess") },
] as DataItems<string, string, string>;
const selectedStopMode = ref<DataItem>(StopModes[0]);

const LocationModes = [
  { key: "all_pages", value: t("visualization.showOnPages.all") },
  { key: "contains", value: t("visualization.showOnPages.contain") },
  { key: "not_contains", value: t("visualization.showOnPages.notContain") },
] as DataItems<string, string, string>;
const selectedLocationMode = ref<DataItem>(LocationModes[0]);

//ConfirmationModal
const enableConfirmModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("enableConfirmModal.confirmationTitle"),
  message: t("enableConfirmModal.confirmationMessage"),
  cancelText: t("enableConfirmModal.cancelButton"),
  severity: "info",
});

// Methods
const selectedTriggerModeChanged = () => {
  if (!rule.value) return;
  if (selectedTriggerMode.value.key === "exit_intent") {
    rule.value.trigger_mode = "exit_intent";
    rule.value.trigger_mode_options = {};
  } else if (selectedTriggerMode.value.key === "immediate") {
    rule.value.trigger_mode = "delayed";
    delete rule.value.trigger_mode_options.scroll;
    rule.value.trigger_mode_options.delay = 0;
  } else if (selectedTriggerMode.value.key === "delayed") {
    rule.value.trigger_mode = "delayed";
    delete rule.value.trigger_mode_options.scroll;
    rule.value.trigger_mode_options.delay = 5;
  } else if (selectedTriggerMode.value.key === "scroll") {
    rule.value.trigger_mode = "scroll";
    delete rule.value.trigger_mode_options.delay;
    rule.value.trigger_mode_options.scroll = 50;
  }
};

const selectedStopModeChanged = () => {
  if (!rule.value) return;
  rule.value.trigger_options.stop_on_success = selectedStopMode.value.key === "stop_on_success";
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\/]/g, "\\$&");
};

const enteradvancedMode = () => {
  if (advancedMode.value) return;
  advancedMode.value = true;
  LocationModes.push({ key: "advanced", value: t("visualization.showOnPages.advanced") });
  selectedLocationMode.value = LocationModes[3];
};

const selectedLocationChanged = () => {
  if (!rule.value) return;
  if (selectedLocationMode.value.key === "all_pages" || locationKeywords.value.trim() === "") {
    rule.value.trigger_options.url = ".*";
  } else if (selectedLocationMode.value.key === "contains") {
    rule.value.trigger_options.url =
      "^.*(" +
      locationKeywords.value
        .split(/[ ,;|]/)
        .map((w) => w.trim())
        .filter((w) => w !== "")
        .map(escapeRegExp)
        .join("|") +
      ").*$";
  } else if (selectedLocationMode.value.key === "not_contains") {
    rule.value.trigger_options.url =
      "^((?!" +
      locationKeywords.value
        .split(/[ ,;|]/)
        .map((w) => w.trim())
        .filter((w) => w !== "")
        .map(escapeRegExp)
        .join("|") +
      ").)*$";
  }

  if (locationKeywords.value === "ABACABB") {
    enteradvancedMode();
  }
};

const loadRule = async () => {
  if (!showOptinRule.value) return;

  loading.value = true;
  rules.value = undefined;
  rule.value = undefined;
  loadedRule.value = undefined;

  try {
    const gen = OptinRules.getRules({ name: props.optinId });
    const iterator = await gen.next();
    if (!iterator.done) {
      //solo cargo una pagina, deberia haber 0 o 1 resultado filtrando por nombre
      rules.value = iterator.value;
      if (rules.value?.length > 0) {
        loadedRule.value = rules.value[0];
      }
    }
    if (!loadedRule.value) {
      rule.value = cloneDeep(defaultRule);
    } else {
      rule.value = cloneDeep(loadedRule.value);
    }

    selectedTriggerMode.value = TriggerModes.find((t) => t.key == rule.value?.trigger_mode) || TriggerModes[0];
    if (rule.value?.trigger_mode === "delayed" && rule.value?.trigger_mode_options.delay === 0) {
      selectedTriggerMode.value = TriggerModes[0];
    }

    selectedStopMode.value = rule.value?.trigger_options.stop_on_success ? StopModes[0] : StopModes[1];

    if (rule.value?.trigger_options.url === ".*") {
      selectedLocationMode.value = LocationModes[0];
    } else if (rule.value?.trigger_options.url.startsWith("^.*(") && rule.value?.trigger_options.url.endsWith(").*$")) {
      selectedLocationMode.value = LocationModes[1];
      locationKeywords.value = rule.value?.trigger_options.url
        .replace("^.*(", "")
        .replace(").*$", "")
        .split("|")
        .join(",");
    } else if (
      rule.value?.trigger_options.url.startsWith("^((?!") &&
      rule.value?.trigger_options.url.endsWith(").)*$")
    ) {
      selectedLocationMode.value = LocationModes[2];
      locationKeywords.value = rule.value?.trigger_options.url
        .replace("^((?!", "")
        .replace(").)*$", "")
        .split("|")
        .join(",");
    } else {
      enteradvancedMode();
    }
  } finally {
    loading.value = false;
  }
};

const newRule = computed(() => {
  return !rule.value?.id;
});

const ruleChanged = computed(() => {
  // if (!rule.value?.id && rule.value?.enabled) return true;
  if (!loadedRule.value) return false;

  return !isEqual(loadedRule.value, rule.value);
});

const canSave = computed(() => {
  return (newRule.value && rule.value?.enabled) || ruleChanged.value;
});

const deleteRule = async () => {
  if (!rule.value?.id) return;
  await OptinRules.deleteRule({ id: rule.value.id });
  await loadRule();
};

const saveRule = async () => {
  if (loadedRule.value) {
    await updateRule();
  } else {
    await createRule();
  }
};

// TODO: unificar update y create que repite mucho codigo
const updateRule = async () => {
  if (!rule.value?.id) return;
  try {
    saving.value = true;
    const updatedRule = await OptinRules.updateRule({
      id: rule.value.id,
      rule: rule.value,
    });

    onboardingStore.submitUserEvent({
      action: "optin.enabled",
      data: {
        id: updatedRule?.id ?? updatedRule.optin_id,
        name: updatedRule.name,
      },
    });

    rule.value = cloneDeep(updatedRule);
    loadedRule.value = cloneDeep(updatedRule);
    notify({
      title: t("notify.updateSuccess.title"),
      text: t("notify.updateSuccess.text"),
    });
  } finally {
    saving.value = false;
  }
};

const createRule = async () => {
  if (!rule.value) return;
  try {
    saving.value = true;
    const createdRule = await OptinRules.createRule(rule.value);

    onboardingStore.submitUserEvent({
      action: "optin.enabled",
      data: {
        id: createdRule?.id ?? createdRule.optin_id,
        name: createdRule.name,
      },
    });

    rule.value = cloneDeep(createdRule);
    loadedRule.value = cloneDeep(createdRule);
    notify({
      title: t("notify.updateSuccess.title"),
      text: t("notify.updateSuccess.text"),
    });
    TrackingEvents.dispatchAll({ name: "APP_OPTIN_RULE_CREATED", includeMasterUsers: true, data: createdRule });
  } finally {
    saving.value = false;
  }
};

const validate = () => {
  if (!rule.value) return;

  if (rule.value.trigger_mode_options.delay < 1) {
    rule.value.trigger_mode_options.delay = 1;
  }
  if (rule.value.trigger_mode_options.delay > 120) {
    rule.value.trigger_mode_options.delay = 120;
  }
  if (rule.value.trigger_mode_options.scroll < 10) {
    rule.value.trigger_mode_options.scroll = 10;
  }
  if (rule.value.trigger_mode_options.scroll > 100) {
    rule.value.trigger_mode_options.scroll = 100;
  }
  if (rule.value.trigger_options.max_displays < 1) {
    rule.value.trigger_options.max_displays = 1;
  }
  if (rule.value.trigger_options.max_displays > 100) {
    rule.value.trigger_options.max_displays = 100;
  }
  if (!rule.value.trigger_options.interval_days || rule.value.trigger_options.interval_days < 0) {
    rule.value.trigger_options.interval_days = 0;
  }
  if (rule.value.trigger_options.interval_days > 180) {
    rule.value.trigger_options.interval_days = 180;
  }
};

const gotoHelp = (id) => {
  window.Intercom("showArticle", id);
};

const showEnableConfirmation = () => {
  if (rule.value?.enabled && !loadedRule.value?.enabled) {
    enableConfirmModal.open = true;
  }
};
const closeEnableConfirmModal = () => (enableConfirmModal.open = false);

// Lifecycle
onMounted(async () => {
  try {
    loading.value = true;
    const features = await AccountFeatures.get();

    if (features.ALWAYS_SHOW_OPTIN_RULE_CONFIG === "1") {
      showOptinRule.value = true;
    } else {
      await integrationStore.fetchIntegrations();
      const integrations = integrationStore.integrations;

      showOptinRule.value = integrations.some((i) => ["tiendanube", "mshops", "tray", "vtex", "wbuy"].includes(i));
    }

    if (showOptinRule.value) {
      await loadRule();
    }
  } finally {
    loading.value = false;
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "toggleSwitch": {
      "title": "Activar formulario pop-up",
      "descriptionToggleOn": "El formulario se mostrará en tu sitio según las reglas definidas.",
      "descriptionToggleOff": "El formulario no se mostrará en tu sitio."
    },
    "alertBox": {
      "description": "Para utilizar la implementación sin código, primero debes activar alguna de las integraciones soportadas.",
      "helpCenter": "centro de ayuda",
      "helpCenterText": "Consulta el {helpCenter} para conocer más detalles.",
      "changes": "Ten en cuenta que los cambios que realices en la configuración pueden demorar algunos minutos en aplicarse."
    },
    "button": {
      "saveChanges": "Guardar cambios",
      "deleteRule": "Eliminar regla"
    },
    "visualization": {
      "title": "Visualización",
      "description": "Mostrar",
      "showOnPages": {
        "all": "en todas las páginas",
        "contain": "si la url contiene",
        "notContain": "si la url no contiene",
        "advanced": "matchea con la regex"
      },
      "pathWords": {
        "contains": "alguna de estas palabras",
        "noContains": "ninguna de estas palabras",
        "input": "checkout, hotsale, ..."
      },
      "triggerModes": {
        "immediate": "inmediatamente al ingresar a la página",
        "delayed": "luego de un tiempo de espera",
        "exitIntent": "justo antes de abandonar la página",
        "scroll": "al desplazarse hacia abajo en la página",
        "description": {
          "of": "de",
          "seconds": "segundos. | segundo. | segundos.",
          "see": "y llega a ver el ",
          "contain": "% del contenido."
        }
      }
    },
    "repetition": {
      "title": "Repetición",
      "descriptionContinue": "Luego de ser completado con éxito",
      "stopOnSuccess": "no volver a mostrarlo",
      "continueOnSuccess": "seguir mostrándolo",
      "descriptionToWatch": "Mostrar hasta",
      "wait": "veces, con una espera de al menos | vez, con una espera de al menos | veces, con una espera de al menos",
      "subscription": "días, o hasta lograr la suscripción. | día, o hasta lograr la suscripción. | dias, o hasta lograr la suscripción.",
      "visualization": "días entre cada visualización. | día entre cada visualización. | días entre cada visualización."
    },
    "noRule": {
      "title": "Mostrar formulario",
      "description": "El formulario no se mostrará en tu sitio."
    },
    "notify": {
      "updateSuccess": {
        "title": "Regla de visualización actualizada",
        "text": "El cambio puede demorar algunos minutos en impactar en forma efectiva."
      }
    },
    "enableConfirmModal": {
      "confirmationTitle": "Activar formulario",
      "confirmationMessage": "Luego de guardar los cambios, el formulario comenzará a mostrarse en tu sitio de acuerdo a las reglas definidas. Ten en cuenta que los cambios que realices pueden demorar algunos minutos en aplicarse a todos los visitantes.",
      "cancelButton": "Entiendo"
    }
  },
  "pt": {
    "toggleSwitch": {
      "title": "Ativar formulário pop-up",
      "descriptionToggleOn": "O formulário será exibido em seu site de acordo com as regras definidas.",
      "descriptionToggleOff": "O formulário não será exibido em seu site."
    },
    "alertBox": {
      "description": "Para usar a implementação sem código, você precisa primeiro ativar uma das integrações suportadas.",
      "helpCenter": "centro de ajuda",
      "helpCenterText": "Consulte o {helpCenter} para conhecer mais detalhes.",
      "changes": "Observe que as alterações feitas na configuração podem demorar alguns minutos para serem aplicadas."
    },
    "button": {
      "saveChanges": "Salvar alterações",
      "deleteRule": "Excluir regra"
    },
    "visualization": {
      "title": "Visualização",
      "description": "Exibir",
      "showOnPages": {
        "all": "em todas as páginas",
        "contain": "se a URL contém",
        "notContain": "se a URL não contém",
        "advanced": "corresponde à regex"
      },
      "pathWords": {
        "contains": "alguma dessas palavras",
        "noContains": "nenhuma dessas palavras",
        "input": "checkout, hotsale, ..."
      },
      "triggerModes": {
        "immediate": "imediatamente ao entrar na página",
        "delayed": "após um tempo de espera",
        "exitIntent": "logo antes de abandonar a página",
        "scroll": "ao rolar para baixo na página",
        "description": {
          "of": "de",
          "seconds": "segundos. | segundo. | segundos.",
          "see": "e chega a ver ",
          "contain": "% do conteúdo."
        }
      }
    },
    "repetition": {
      "title": "Repetição",
      "descriptionContinue": "Após ser concluído com sucesso",
      "stopOnSuccess": "não exibir mais",
      "continueOnSuccess": "continuar exibindo",
      "descriptionToWatch": "Exibir até",
      "wait": "vezes, com uma espera de pelo menos | vez, com uma espera de pelo menos | vezes, com uma espera de pelo menos",
      "subscription": "dias, ou até obter a inscrição. | dia, ou até obter a inscrição. | dias, ou até obter a inscrição.",
      "visualization": "dias entre cada visualização. | dia entre cada visualização. | dias entre cada visualização."
    },
    "noRule": {
      "title": "Exibir formulário",
      "description": "O formulário não será exibido no seu site."
    },
    "notify": {
      "updateSuccess": {
        "title": "Regra de visualização atualizada",
        "text": "A alteração pode demorar alguns minutos para ter um impacto efetivo."
      }
    },
    "enableConfirmModal": {
      "confirmationTitle": "Ativar formulário",
      "confirmationMessage": "Após salvar as alterações, o formulário começará a ser exibido em seu site de acordo com as regras definidas. Observe que pode levar alguns minutos para que as alterações feitas sejam aplicadas a todos os visitantes.",
      "cancelButton": "Entendo"
    }
  }
}
</i18n>
