import type { UserRoleItems } from "./userInputs.types";

// I18n
import { useI18n } from "vue-i18n";

export const getRoles = (): UserRoleItems => {
  const { t } = useI18n();
  return [
    { key: "ADMIN", value: t("roles.ADMIN") },
    { key: "MANAGER", value: t("roles.MANAGER") },
    { key: "AUTHOR", value: t("roles.AUTHOR") },
    { key: "VIEWER", value: t("roles.VIEWER") },
  ];
};
