<template>
  <div class="flex w-full flex-col justify-center rounded-lg p-16 py-32 text-center">
    <div>
      <PhotographIcon class="mx-auto h-14 w-14 stroke-2 text-gray-200" />
      <span class="mt-2 block text-base font-normal text-gray-300">{{ t("noTemplatesFoundTitle") }}</span>
      <span class="mt-1 block text-sm font-normal text-gray-300">{{ t("noTemplatesFoundDescription") }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
//Icons
import { PhotographIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<i18n lang="jsonc">
{
  "es": {
    "noTemplatesFoundTitle": "Crea una nueva campaña para comenzar",
    "noTemplatesFoundDescription": "Aquí verás todas las plantillas de tus campañas"
  },
  "pt": {
    "noTemplatesFoundTitle": "Crie uma nova campanha para começar",
    "noTemplatesFoundDescription": "Aqui você verá todos os templates de suas campanhas"
  }
}
</i18n>
