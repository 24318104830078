import {
  differenceInDays,
  subDays,
  isWithinInterval,
  endOfDay,
  startOfDay,
  addDays,
  getYear,
  isToday,
  isYesterday,
} from "date-fns";
import type { PlanType } from "@domain/plan";

export interface IEmailMetrics {
  sent: number;
  clicked: number;
  quantity: number;
  unsubscribed_total: number;
  opened: number;
  first_opened: number;
  first_clicked: number;
  bounced: {
    hard: number;
    soft: number;
  };
  viewed_online: number;
  shared_on: {
    total: number;
  };
  unsubscribed: {
    other: number;
    total: number;
    not_interested?: number;
    oneclick?: number;
    too_frequent?: number;
    not_specified?: number;
    spam_never_subscribed?: number;
    spam_offensive?: number;
    spam_fbl?: number;
  };
}

export interface IWebTrackSessions {
  visits_total: number;
  visits_identified: number;
  visitors_total: number;
  visitors_identified: number;
}

export interface IOrdersBySource {
  group: "automation" | "bulk" | "unattributed";
  count: number;
  sum: number;
}

export type ContactSource = "OPTIN_FORM" | "IMPORTED_CSV" | "INTEGRATION" | "MANUAL";

export interface IDashboardDataPoint {
  order: {
    source: IOrdersBySource[];
    source_click: IOrdersBySource[];
  };
  mail_metrics: {
    bulk: IEmailMetrics;
    automation: IEmailMetrics;
    transactional: IEmailMetrics;
  };
  web_track_sessions: IWebTrackSessions;
  pem_api_contacts: {
    total: number;
    engaged: number;
    recent: number;
    asleep: number;
    active: number;
    never_engaged: number;
    mailed: number;
    new_sources: Partial<Record<ContactSource, number>>;
    active_sources: Partial<Record<ContactSource, number>>;
  };
}

export type IDashboardDataSet = Record<string, IDashboardDataPoint>;

export type BatchType = "bulk" | "automation" | "transactional" | "all";

export type PeriodType = "current" | "previous" | null;

export class Period {
  readonly from: Date;
  readonly to: Date;
  readonly days: number;
  readonly prevFrom: Date;
  readonly prevTo: Date;

  constructor(from: Date, to: Date) {
    this.to = endOfDay(to);
    this.from = startOfDay(from);

    this.days = differenceInDays(to, from) + 1;
    this.prevFrom = startOfDay(subDays(from, this.days));
    this.prevTo = startOfDay(subDays(to, this.days));
  }

  type(date: Date): PeriodType {
    if (this.isCurrent(date)) return "current";
    else if (this.isPrevious(date)) return "previous";
    return null;
  }
  isCurrent(date: Date) {
    return isWithinInterval(date, { start: this.from, end: this.to });
  }
  isPrevious(date: Date) {
    return isWithinInterval(date, { start: this.prevFrom, end: this.from });
  }
  addPeriod(date: Date): Date {
    return addDays(date, this.days);
  }
  subPeriod(date: Date): Date {
    return subDays(date, this.days);
  }
  isWithinSameYear() {
    return getYear(this.from) == getYear(this.to);
  }
  isUntilLastDay() {
    return isToday(this.to) || isYesterday(this.to);
  }
}

export interface Accumulator {
  accumulate(dataPoint: IDashboardDataPoint, period: PeriodType): void;
}

export const variation = (curr: number, prev: number) => {
  const variation = (curr - prev) / prev;
  // let rounding = 1000;
  // if (Math.abs(variation) < 0.1) rounding = 10000;
  // return Math.round((rounding * (curr - prev)) / prev) / rounding;
  return variation;
};

export interface PlanInfo {
  planType?: PlanType;
  planCost?: number;
}
