<template>
  <div class="mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <div class="flex items-center space-x-2">
        <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
        <PlusIcon class="h-4 w-4 stroke-[3]" aria-hidden="true" />
        <img class="h-10 w-auto" src="/img/login-tiendanube-icon.svg" alt="Perfit" />
      </div>
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
        <button type="button" class="font-semibold text-sky-400 hover:text-sky-500" @click="goToLogin">
          {{ t("login") }}
        </button>
      </p>
    </div>

    <div class="mt-5">
      <div class="mt-5">
        <form @submit.prevent="signup">
          <div class="mt-6">
            <FormInput
              v-model="signUpInfo.website"
              :label="t('websiteLabel')"
              :placeholder="t('websitePlaceholder')"
              :error="v$.website.$error ? t('fieldRequired') : undefined"
              disabled
              class="bg-gray-50"
            />
          </div>

          <div class="mt-4">
            <div>
              <FormInput
                v-model="signUpInfo.email"
                :label="t('emailLabel')"
                :placeholder="t('emailPlaceholder')"
                :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
                autocomplete="email"
                @focus="clearEmailValidation"
                @input="clearEmailValidation"
              />
            </div>

            <FormCheckBox
              v-model="signUpInfo.termsAndConditionsAccepted"
              :disabled="false"
              :error="v$.termsAndConditionsAccepted.$error"
              class="mt-6"
              @update:model-value="v$.termsAndConditionsAccepted.$reset()"
            >
              <template #label>
                <i18n-t keypath="termsAndConditions">
                  <template #link>
                    <a href="https://docs.myperfit.com/articles/17241" target="_blank" @click.stop>{{
                      t("termsAndConditionsLink")
                    }}</a>
                  </template>
                </i18n-t>
              </template>
            </FormCheckBox>
            <FormCheckBox
              v-model="signUpInfo.noCommercialActivityEurope"
              :disabled="false"
              :error="v$.noCommercialActivityEurope.$error"
              :label="t('noCommercialActivityInEurope')"
              class="mt-2"
              @update:model-value="v$.noCommercialActivityEurope.$reset()"
            />
          </div>

          <SimpleButton
            type="submit"
            :loading="submitting"
            :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
          >
            <template #leading>
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </template>
            {{ t("login") }}
          </SimpleButton>

          <div class="mt-5 text-center text-sm leading-6">
            <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
              t("loginProblems")
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watchEffect } from "vue";

import { validateUserFormat } from "../authentication";

// components
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";

// Icons
import { CheckIcon, PlusIcon } from "@heroicons/vue/solid";

// Utils
import { decodeHTMLEntities, getDomainFromUrl } from "@helpers/formatters";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import type { ErrorObject } from "@vuelidate/core";

// Application
import { useAuthenticationApp, useRouterApp } from "@application";

// Types
import type { Industry } from "@domain/account";

// I18n
import { useI18n } from "vue-i18n";

const authApp = useAuthenticationApp();
const routerApp = useRouterApp();

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    tiendanubeStoreInfo: {
      businessName: string;
      website: string;
      email: string;
      preinit: string;
      country: string;
      name: string;
      phone: string;
      address: string;
      industry: Industry | "";
      tnPlan: string;
      tnCreatedAt: string;
    };
  }>(),
  {
    tiendanubeStoreInfo: () => ({
      businessName: "",
      website: "",
      email: "",
      preinit: "",
      phone: "",
      country: "",
      name: "",
      address: "",
      industry: "",
      tnPlan: "",
      tnCreatedAt: "",
    }),
  },
);

const emit = defineEmits<{
  goToLogin: [void];
  goToWaitlist: [void];
  helpArticle: [void];
}>();

const goToLogin = () => {
  emit("goToLogin");
};

const goToWaitlist = () => {
  emit("goToWaitlist");
};

// Data
const signUpInfo = reactive<{
  businessName: string;
  website: string;
  store: string;
  email: string;
  preinit: string;
  country: string;
  name: string;
  address: string;
  phone: string;
  industry: Industry | "";
  tnPlan: string;
  tnCreatedAt: string;
  termsAndConditionsAccepted: boolean;
  noCommercialActivityEurope: boolean;
}>({
  website: "",
  email: "",
  store: "tiendanube",
  country: "",
  businessName: "",
  name: "",
  preinit: "",
  industry: "",
  address: "",
  phone: "",
  tnPlan: "",
  tnCreatedAt: "",
  termsAndConditionsAccepted: false,
  noCommercialActivityEurope: false,
});

watchEffect(() => {
  signUpInfo.businessName = props.tiendanubeStoreInfo.businessName.replace(/\W/g, " ");
  signUpInfo.website = props.tiendanubeStoreInfo.website;
  signUpInfo.email = props.tiendanubeStoreInfo.email;
  signUpInfo.preinit = props.tiendanubeStoreInfo.preinit;
  signUpInfo.country = props.tiendanubeStoreInfo.country;
  signUpInfo.name = props.tiendanubeStoreInfo.name;
  signUpInfo.industry = props.tiendanubeStoreInfo.industry;
  signUpInfo.address = decodeHTMLEntities(props.tiendanubeStoreInfo.address);
  signUpInfo.phone = props.tiendanubeStoreInfo.phone;
  signUpInfo.tnPlan = props.tiendanubeStoreInfo.tnPlan;
  signUpInfo.tnCreatedAt = props.tiendanubeStoreInfo.tnCreatedAt;
});

// Validation
const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const emailForceInvalid = ref(false);
const customEmailValidation = (value: string) => {
  const valid = validateUserFormat(value);

  return !emailForceInvalid.value && valid;
};
const clearEmailValidation = () => {
  emailForceInvalid.value = false;
  v$.value.email.$reset();
};
const setEmailInvalid = () => {
  emailForceInvalid.value = true;
  v$.value.email.$touch();
};

const validationRules = {
  website: { required },
  email: { required, customEmailValidation },
  termsAndConditionsAccepted: { checked: (value: boolean) => value },
  noCommercialActivityEurope: { checked: (value: boolean) => value },
};

const v$ = useVuelidate(validationRules, signUpInfo);

// Login
const submitting = ref(false);
const signup = async () => {
  try {
    // Fields validation
    const validation = await v$.value.$validate();
    if (!validation) return;

    submitting.value = true;

    const businessCode =
      signUpInfo.name.replace(/\W/g, " ") ||
      getDomainFromUrl(signUpInfo.website) ||
      signUpInfo.businessName.replace(/\W/g, " ") ||
      signUpInfo.website;

    const waitlist = window.config.waitlist;
    if (waitlist === "all") {
      // Save signup info endpoint
      goToWaitlist();
    } else {
      const res = await authApp.signupTiendanube({
        country: signUpInfo.country,
        email: signUpInfo.email,
        website: signUpInfo.website,
        businessName: businessCode,
        preinit: signUpInfo.preinit,
        address: signUpInfo.address,
        industry: signUpInfo.industry,
        phone: signUpInfo.phone,
        tnPlan: signUpInfo.tnPlan,
        tnCreatedAt: signUpInfo.tnCreatedAt,
      });

      // Logged in
      if (res.isOk() && !res.value) {
        routerApp.navigate({
          path: "home",
        });
        location.reload();
        return;
      }

      // Logged in
      // if (res.isErr() && res.error.type === "VALIDATION_ERROR" && res.error.invalidFields?.email) {
      //   setEmailInvalid();
      // }

      // TODO AUTH: Handle errors new api
      // if (res.isErr() && res.error.type === "UNAUTHORIZED") {
      //   console.log("erros")
      // }
    }
  } catch (e) {
    // No handler
  } finally {
    submitting.value = false;
  }
};

const openHelpArticle = () => {
  emit("helpArticle");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Crear una cuenta",
    "subtitle": "¿Ya tienes una cuenta?",
    "login": "Crear cuenta",
    "fieldRequired": "Campo requerido.",
    "invalidEmail": "El email ingresado es inválido.",
    "websiteLabel": "Sitio web",
    "websitePlaceholder": "Ingresa la url de tu sitio",
    "emailLabel": "Email",
    "emailPlaceholder": "Ingresa tu email",
    "noCommercialActivityInEurope": "No realizaré actividades comerciales en la Unión Europea.",
    "termsAndConditions": "He leído y acepto {link} de uso.",
    "termsAndConditionsLink": "términos y condiciones",
    "loginProblems": "¿Problemas para ingresar?"
  },
  "pt": {
    "title": "Criar uma conta",
    "subtitle": "Já tem uma conta?",
    "login": "Criar conta",
    "fieldRequired": "Campo obrigatório.",
    "invalidEmail": "O e-mail inserido é inválido.",
    "websiteLabel": "Site",
    "websitePlaceholder": "Insira a URL do seu site",
    "emailLabel": "E-mail",
    "emailPlaceholder": "Insira seu e-mail",
    "noCommercialActivityInEurope": "Não realizarei atividades comerciais na União Europeia.",
    "termsAndConditions": "Li e aceito os {link} de uso.",
    "termsAndConditionsLink": "termos e condições",
    "loginProblems": "Problemas para iniciar sessão?"
  }
}
</i18n>
