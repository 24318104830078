<template>
  <div class="mx-auto flex h-full w-full flex-col items-center justify-center space-y-4 text-center lg:w-[62rem]">
    <slot name="icon" />
    <h1 class="text-3xl font-bold text-gray-700">{{ props.title }}</h1>
    <p class="mx-auto w-[40%] text-center text-base font-normal text-gray-600">
      {{ props.message }}
    </p>
    <button class="text-sm font-semibold text-sky-400" @click="openArticle">
      <div class="flex space-x-2">
        <span>
          {{ props.articleText }}
        </span>
        <ArrowRightIcon class="my-auto h-3 w-3" />
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
// Icon
import { ArrowRightIcon } from "@heroicons/vue/outline";
const props = withDefaults(
  defineProps<{
    title: string;
    message: string;
    articleId: number;
    articleText: string;
  }>(),
  {},
);

const emit = defineEmits<{
  action: [];
}>();

const openArticle = () => {
  window.Intercom("showArticle", props.articleId);
};
</script>
