// Check if a value is contained in the array
export default function (value, collection, options) {
  var keys = Object.keys(collection);
  if (!keys || !keys.length) {
    return false;
  }
  if (keys.indexOf(value) > -1) {
    return options.fn(this);
  }
  return options.inverse(this);
}