<template>
  <div>
    <div class="flex w-full">
      <div class="w-[48%]">
        <InputLabel>{{ t("emailLabel") }}</InputLabel>
      </div>
      <div class="flex w-[48%] space-x-2">
        <InputLabel>{{ t("roleLabel") }}</InputLabel>
        <InfoIcon :content="t('roleInfo')" placement="right" class="ml-1" />
      </div>
    </div>
    <transition-group
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-300 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
      <div v-for="(userItem, index) in users" :key="userItem.id" class="mb-4 flex space-x-4">
        <div class="w-[48%]">
          <SimpleInput v-model:model-value="userItem.email" :placeholder="t('emailPlaceholder')" autocomplete="email" />
        </div>
        <div class="w-[48%]">
          <SelectMenu v-model:selected="userItem.roleItem" :data-items="roleItems" :rem-height="11" />
        </div>
        <div class="w-[4%]">
          <IconButton
            :label="index == 0 ? t('addIconButton') : t('trashIconButton')"
            :theme="index == 0 ? 'success' : 'danger'"
            :class="
              ((users.length >= max && index == 0) || (users.length <= min && index != 0)) &&
              'pointer-events-none opacity-50'
            "
            @click="() => (index == 0 ? addUser() : deleteSubject(userItem.id))"
          >
            <PlusCircleIcon v-if="index == 0" />
            <TrashIcon v-else />
          </IconButton>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { watchEffect } from "vue";

import type { UserItems } from "./userInputs.types";
import { getRoles } from "./userInputs.data";

//Components
import InfoIcon from "@atoms/InfoIcon.vue";
import InputLabel from "@atoms/InputLabel.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import SelectMenu from "@molecules/SelectMenu.vue";
import IconButton from "@atoms/IconButton.vue";

//Icon
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/outline";

// Utils
import { v4 as uuid } from "uuid";

//I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

// Props
const props = withDefaults(
  defineProps<{
    users: UserItems;
    min?: number;
    max?: number;
  }>(),
  {
    users: () => [],
    min: 1,
    max: 5
  }
);

//Emits
const emit = defineEmits<{
  "update:users": [UserItems];
}>();

const roleItems = getRoles();

const addUser = () => {
  const id = uuid();

  const newUsers: UserItems = [
    ...props.users,
    {
      id: id,
      email: "",
      roleItem: { key: "ADMIN", value: t("roles.ADMIN") }
    }
  ];
  emit("update:users", newUsers);
};

const deleteSubject = (id: string) => {
  const atIndex = props.users.findIndex((user) => user.id === id);

  if (atIndex === -1) return;

  const newUsers = [...props.users];

  newUsers.splice(atIndex, 1);

  emit("update:users", newUsers);
};

watchEffect(() => {
  if (props.users.length < props.min) {
    addUser();
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "addIconButton": "Agregar usuario",
    "trashIconButton": "Eliminar usuario",
    "emailLabel": "Email",
    "emailPlaceholder": "Email del colaborador",
    "roleLabel": "Rol de usuario",
    "roleInfo": "Administrador: Accede a todas las funcionalidades, modifica configuraciones de cuenta, plan y gestión de usuarios.<br/><br/>Autor: Edita diseños y campañas, pero no envía. No gestiona listas ni contactos.<br/><br/>Gestor: Envía campañas, sin gestionar usuarios ni modificar la cuenta o plan.<br/><br/>Observador: Permite acceder únicamente a ver los reportes de campañas enviadas.",
    "roles": {
      "ADMIN": "Administrador",
      "MANAGER": "Gestor",
      "AUTHOR": "Autor",
      "VIEWER": "Observador"
    }
  },
  "pt": {
    "addIconButton": "Adicionar usuário",
    "trashIconButton": "Eliminar usuário",
    "emailLabel": "Email",
    "emailPlaceholder": "Email do colaborador",
    "roleLabel": "Permissões do usuário",
    "roleInfo": "Administrador: Acessa todas as funcionalidades, modifica configurações de conta, plano e gestão de usuários.<br/><br/>Autor: Edita designs e campanhas, mas não envia. Não gerencia listas nem contatos.<br/><br/>Gestor: Envia campanhas, sem gerenciar usuários ou modificar a conta ou plano.<br/><br/>Observador: Permite acessar apenas para ver os relatórios de campanhas enviadas.",
    "roles": {
      "ADMIN": "Administrador",
      "MANAGER": "Gestor",
      "AUTHOR": "Autor",
      "VIEWER": "Observador"
    }
  }
}
</i18n>
