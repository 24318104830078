import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";

export interface IPagoUnoTokenizeData {
  primary_account_number: string;
  expiration_date: string;
  card_security_code: string;
  card_holder: {
    first_name: string;
    last_name: string;
    front_name: string;
    telephone: string;
    email: string;
    address: {
      country: string;
      state: string;
      city: string;
      street: string;
      door_number: string;
    };
    identification: {
      document_type: string;
      document_number: string;
    };
  };
}

export function usePagoUno(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function tokenize(
    tokenizeData: IPagoUnoTokenizeData,
    loadingRef?: Ref<boolean>,
  ): Promise<any> {
    const res = await request({
      url: "/Transaction/token",
      method: "post",
      data: [tokenizeData],
      loadingRef,
      disableNotify400: true,
      disableAccountPrefix: true,
      axiosRequestConfig: {
        headers: {
          Authorization: "2e021f35-6dcd-4c1b-a8e4-8f9b0b8b657b",
          "X-Auth-Token": null,
        },
        baseURL: "https://api.pagouno.com/v1",
      },
    });

    return res.data[0];
  }

  return { tokenize };
}
