var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " centered ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <h1>\n      <a data-action='back'><i class='icon icon-back'></i></a>\n      <span class='edit-inline' data-action=\"edit-inline\" data-field=\"name\" style=\"display: inline;\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n      <input autocomplete=\"off\" maxlength=\"80\" class='edit-inline' data-action=\"edit-inline\" name=\"name\"\n        value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" style=\"display: none;\" required />\n      <small class='lift'>\n        <span class='edit-inline "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":69}}})) != null ? stack1 : "")
    + "' data-action=\"edit-inline\" data-field=\"comments\"\n          style=\"display: inline;\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"comments") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":12,"column":93}}})) != null ? stack1 : "")
    + "</span>\n        <input autocomplete=\"off\" maxlength=\"200\" class='edit-inline' data-action=\"edit-inline\" name=\"comments\"\n          value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" data-original-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "\" style=\"display: none;\"\n          placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0))
    + "\" />\n      </small>\n    </h1>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "empty";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"commentsPlaceholder")), depth0));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-disabled' data-action=\"cantActivateLifeCycle\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n      </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":33,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":39,"column":17}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='disable'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"disable")), depth0))
    + "\n      </button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-primary' data-action='enable'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enable")), depth0))
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"enableRestricted") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":37,"column":100}}})) != null ? stack1 : "")
    + "\n      </button>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-lock\" aria-hidden=\"true\"></i>";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"hasTemplatesApiV3") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":66,"column":10},"end":{"line":78,"column":17}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\n            <a data-navigate='automations/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/templates'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n            </a>\n          </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li>\n            <a data-navigate='automations/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/contents'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n            </a>\n          </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "selected";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"period-selector\">\n          <div class='form-group'>\n            <label for='step-filter'>filtrar por: </label>\n            <select id='step-filter' class='form-control input-mini' data-action='step-filter' style=\"margin: 0;\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"emailSteps") : stack1),{"name":"each","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":14},"end":{"line":124,"column":23}}})) != null ? stack1 : "")
    + "            </select>\n          </div>\n        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <option value='"
    + alias2(alias1(depth0, depth0))
    + "' "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"params"))) && lookupProperty(stack1,"filter"))) && lookupProperty(stack1,"step_id")),depth0,{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":36},"end":{"line":121,"column":88}}})) != null ? stack1 : "")
    + ">\n                "
    + alias2(alias1(depth0, depth0))
    + "\n              </option>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "active";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class='sent "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"sent",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":26},"end":{"line":141,"column":72}}})) != null ? stack1 : "")
    + "'>\n            <a href='#' data-action='filter' data-params='{\"key\": \"filter.type\", \"value\": \"sent\"}'>\n              <strong>\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"sent") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":144,"column":16},"end":{"line":144,"column":68}}}))
    + "\n              </strong>\n              <small>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"sendings")), depth0))
    + "\n              </small>\n            </a>\n          </li>\n          <li class='opened "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"opened",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":28},"end":{"line":151,"column":76}}})) != null ? stack1 : "")
    + "'>\n            <a href='#' data-action='filter' data-params='{\"key\": \"filter.type\", \"value\": \"opened\"}'>\n              <strong class=\"nohover-metric\">\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"opened") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":154,"column":16},"end":{"line":154,"column":70}}}))
    + "\n              </strong>\n              <strong class=\"hover-metric\">\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"openedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":157,"column":16},"end":{"line":157,"column":71}}}))
    + "%\n              </strong>\n              <small>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"opened")), depth0))
    + "\n              </small>\n            </a>\n          </li>\n          <li class='clicks "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"clicked",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":28},"end":{"line":164,"column":77}}})) != null ? stack1 : "")
    + "'>\n            <a href='#' data-action='filter' data-params='{\"key\": \"filter.type\", \"value\": \"clicked\"}'>\n              <strong class=\"nohover-metric\">\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"clicked") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":167,"column":16},"end":{"line":167,"column":71}}}))
    + "\n              </strong>\n              <strong class=\"hover-metric\">\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"clickedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":170,"column":16},"end":{"line":170,"column":72}}}))
    + "%\n              </strong>\n              <small>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"clicks")), depth0))
    + "\n              </small>\n            </a>\n          </li>\n          <li class='bounced "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"converted",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":29},"end":{"line":177,"column":80}}})) != null ? stack1 : "")
    + "'>\n            <a href='#' data-action='filter' data-params='{\"key\": \"filter.type\", \"value\": \"converted\"}'>\n              <strong class=\"nohover-metric\">\n                "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"converted") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":180,"column":16},"end":{"line":180,"column":73}}}))
    + "\n              </strong>\n              <strong class=\"hover-metric\">\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"conversionCurrency")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"converted_amount") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":183,"column":58},"end":{"line":183,"column":122}}}))
    + "\n              </strong>\n              <small>\n                "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"conversions")), depth0))
    + "\n              </small>\n            </a>\n          </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "                  <div data-params='[\"filter.email\"]' data-action='remove-filter' class='clear-search'>\n                    <i class='fa fa-close'></i>\n                  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='automations metrics section show'>\n  <div class='section-header "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":86}}})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":18,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n\n  <div class='toolbar edit-mode'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"trigger") : stack1),"lifecycles.rfm",{"name":"is","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"trigger") : stack1),"lifecycles.rfm",{"name":"isNot","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":40,"column":14}}})) != null ? stack1 : "")
    + "\n    <div class=\"btn-group\">\n      <button type='button' class='btn btn-default' data-action='cancel' data-require-permission='campaigns:create'>\n        "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='duplicate' data-require-permission='campaigns:create'>\n        "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-danger' data-action='delete' data-class='btn-danger'\n        data-require-permission='campaigns:delete'>\n        "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n      </button>\n    </div>\n\n  </div>\n\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <ul id='automation-tabs' class='nav nav-pills'>\n        <li>\n          <a data-navigate='automations/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"automation") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n            "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"rules")), depth0))
    + "\n          </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"noEmailSteps") : stack1),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":8},"end":{"line":79,"column":19}}})) != null ? stack1 : "")
    + "        <li class=\"active\">\n          <a data-navigate='automations/"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "/activity'>\n            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"activity")), depth0))
    + "\n          </a>\n        </li>\n      </ul>\n      <div class='activity panel panel-default'>\n        <div class=\"period-selector\">\n          <ul>\n            <li>\n              <a href='#' data-action='filter' data-params='{\"key\": \"filter.created.gtrel\", \"value\": \"now-3M\"}'\n                class='"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"created") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-3M",{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":23},"end":{"line":91,"column":82}}})) != null ? stack1 : "")
    + "'>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastQuarter")), depth0))
    + "\n              </a>\n            </li>\n            <li>\n              <a href='#' data-action='filter' data-params='{\"key\": \"filter.created.gtrel\", \"value\": \"now-1M\"}'\n                class='"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"created") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1M",{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":23},"end":{"line":97,"column":82}}})) != null ? stack1 : "")
    + "'>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastMonth")), depth0))
    + "\n              </a>\n            </li>\n            <li>\n              <a href='#' data-action='filter' data-params='{\"key\": \"filter.created.gtrel\", \"value\": \"now-1w\"}'\n                class='"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"created") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1w",{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":23},"end":{"line":103,"column":82}}})) != null ? stack1 : "")
    + "'>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastWeek")), depth0))
    + "\n              </a>\n            </li>\n            <li>\n              <a href='#' data-action='filter' data-params='{\"key\": \"filter.created.gtrel\", \"value\": \"now-1d\"}'\n                class='"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"created") : stack1)) != null ? lookupProperty(stack1,"gtrel") : stack1),"now-1d",{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":23},"end":{"line":109,"column":82}}})) != null ? stack1 : "")
    + "'>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"periods"))) && lookupProperty(stack1,"lastDay")), depth0))
    + "\n              </a>\n            </li>\n          </ul>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"emailSteps") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":8},"end":{"line":128,"column":15}}})) != null ? stack1 : "")
    + "        <ul class='filtering'>\n          <li class='sent "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"triggered",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":26},"end":{"line":130,"column":77}}})) != null ? stack1 : "")
    + "'>\n            <a href='#' data-action='filter' data-params='{\"key\": \"filter.type\", \"value\": \"triggered\"}'>\n              <strong>\n                "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"total") : stack1)) != null ? lookupProperty(stack1,"triggered") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":133,"column":73}}}))
    + "\n              </strong>\n              <small>\n                Iniciados\n              </small>\n            </a>\n          </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"additionalsData") : depth0)) != null ? lookupProperty(stack1,"noEmailSteps") : stack1),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":10},"end":{"line":190,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class='list'>\n          <div class='row'>\n            <div class='col-md-12'>\n              <form data-form='search' data-role='search'>\n                <div class='list-search'>\n                  <input type='text' placeholder='Buscar' class='form-control finder' name='filter.email'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"filter") : stack1)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":18},"end":{"line":202,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </form>\n            </div>\n          </div>\n          <div data-role='content'>\n            <ul class='table' data-role='rows'></ul>\n            <div class='loading-box' data-role='items-loader'></div>\n            <button class='btn btn-primary load-more' data-action='load-more'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"loadMore")), depth0))
    + "\n            </button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});