import { formatTextToSearch } from "@helpers/formatters";

import { cloneDeep } from "lodash";

// Types
import type { Component, RenderFunction } from "vue";
import type { Tags, Tag } from "@domain/tag";
import type { DataItems, DataItem } from "@domain/data";
import { isTags, isTag } from "@domain/tag";

export interface Filter<
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
> {
  id: IdType;
  text: TextType;
  badgeText?: string;
  customSearch?: boolean;
  isAction?: boolean;
  list?: DataItems<ListKeyType, ListValueType, ListDataType, ListContentType> | Tags;
  highlightList?: DataItems<ListKeyType, ListValueType, ListDataType, ListContentType> | Tags;
  icon?: RenderFunction | Component;
}

export type Filters<
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
> = Array<Filter<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>>;

export interface FilterData<
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
> {
  filters: Filters<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>;
  filtersRoles?: {
    primary?: IdType;
    tags?: IdType;
    date?: IdType;
  };
}

export const getFilterById = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>,
  id: string
): Filter<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType> | undefined => {
  const filter = filterData.filters.find((filter) => filter.id === id);
  return filter;
};

export const getPrimaryFilter = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>
): Filter<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType> => {
  const firstFilter = filterData.filters[0];

  if (!filterData.filtersRoles?.primary) return firstFilter;

  const primaryFilterId = filterData.filtersRoles.primary;
  const filter = getFilterById(filterData, primaryFilterId);

  return filter ?? firstFilter;
};

export const getListItemFilter = <
  FilterIdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>,
  item: DataItem<ListKeyType, ListValueType, ListDataType, ListContentType>
): Filter<FilterIdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType> | undefined => {
  const firstFilter = filterData.filters.find((filter) => {
    if (!filter.list && !filter.highlightList) return false;

    if (filter.list) {
      return filter.list.some((listItem: Tag | DataItem<ListKeyType, ListValueType, ListDataType, ListContentType>) => {
        if (isTag(listItem)) return listItem.id === item.key;
        return listItem.key === item.key;
      });
    }
    if (filter.highlightList) {
      return filter.highlightList.some(
        (listItem: Tag | DataItem<ListKeyType, ListValueType, ListDataType, ListContentType>) => {
          if (isTag(listItem)) return listItem.id === item.key;
          return listItem.key === item.key;
        }
      );
    }
  });

  return firstFilter;
};

export const getPrimaryHighlightList = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>
): DataItems<ListKeyType, ListValueType, ListDataType, ListContentType> | Tags => {
  const filter = getPrimaryFilter(filterData);

  return filter?.highlightList ?? [];
};

export const getHighlightTags = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>
): Tags => {
  if (!filterData.filtersRoles || !filterData.filtersRoles.tags) return [];

  const filterId = filterData.filtersRoles.tags;

  const filter = getFilterById(filterData, filterId);

  const list = filter?.highlightList ?? [];

  if (!isTags(list)) return [];

  return list;
};

export const getTagsByText = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>,
  search: string
): Tags => {
  if (!filterData.filtersRoles || !filterData.filtersRoles.tags) return [];

  const filterId = filterData.filtersRoles.tags;

  const filter = getFilterById(filterData, filterId);
  const list = filter?.list ?? [];

  if (!isTags(list)) return [];

  const filteredList = list.filter((tag) => formatTextToSearch(tag.name).includes(formatTextToSearch(search)));

  return filteredList;
};

export const getFiltersListsByText = <
  IdType extends string = string,
  TextType extends string = string,
  ListKeyType extends string = string,
  ListValueType extends string = string,
  ListDataType = Record<string, unknown>,
  ListContentType extends Record<string, unknown> = Record<string, unknown>
>(
  filterData: FilterData<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>,
  search: string
): Filters<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType> => {
  const filteredList = Object.values(filterData.filters).reduce<
    Filters<IdType, TextType, ListKeyType, ListValueType, ListDataType, ListContentType>
  >((filters, filter) => {
    if (!filter.list || isTags(filter.list)) return filters;

    const filteredFilterList = cloneDeep(filter);

    filteredFilterList.list = filter.list.filter((item) =>
      formatTextToSearch(item.value).includes(formatTextToSearch(search))
    );

    if (filteredFilterList.list.length === 0) return filters;

    filters = [...filters, filteredFilterList];

    return filters;
  }, []);

  return filteredList;
};
