<template>
  <div class="relative rounded-md shadow-sm" :class="{ 'inline-block': inline }">
    <input
      ref="inputRef"
      :type="type"
      v-bind="$attrs"
      :value="modelValue"
      :class="inputClass"
      :placeholder="placeholder"
      :disabled="disabled"
      :aria-invalid="hasError"
      @input="emitValue"
      @keydown.enter="emitEnter"
    />
    <transition
      leave-active-class="transition duration-100 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-5"
      enter-active-class="transition duration-100 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-5"
    >
      <div v-if="hasError" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
      </div>
      <div v-else-if="loading" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";
import { ref, nextTick, defineComponent } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

import LoadingSpinner from "@atoms/LoadingSpinner.vue";

export default defineComponent({
  name: "SimpleInput",
  components: { ExclamationCircleIcon, LoadingSpinner },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    type: {
      type: String as PropType<
        | "date"
        | "datetime-local"
        | "month"
        | "week"
        | "time"
        | "email"
        | "password"
        | "number"
        | "text"
        | "search"
        | "range"
        | "url"
      >,
      required: false,
      default: "text",
    },
    modelModifiers: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "enter"],

  setup(props, { emit }) {
    const inputRef = ref<InstanceType<typeof HTMLInputElement>>();

    // FIXME TODO: aca pasan cosas raras cuando en input de tipo text se pone una coma al inicio
    const emitValue = (e) => {
      let value = e.target.value as string;
      let updateCursor = false;
      const cursorPos = inputRef.value?.selectionStart;

      if (props.modelModifiers.lowercase && value !== value.toLowerCase()) {
        value = value.toLowerCase();
        updateCursor = true;
      }

      if (props.modelModifiers.uppercase && value !== value.toUpperCase()) {
        value = value.toUpperCase();
        updateCursor = true;
      }

      const splitNumberValue = value.split(/[,.]/);

      if (splitNumberValue[0] == "" && splitNumberValue[1]) {
        value = "0" + value;
        (e.target as HTMLInputElement).value = value;
      }

      if (props.modelModifiers.maxDecimals) {
        const decimals = splitNumberValue?.[1];

        if (decimals && decimals?.length > props.modelModifiers?.maxDecimals) {
          const integerLength = splitNumberValue?.[0]?.length;

          let fixLength = props.modelModifiers.maxDecimals;

          //toFixed limit
          fixLength = Math.abs(fixLength) > 10 ? 10 : Math.abs(fixLength);

          value = value.toString().slice(0, integerLength + 1 + fixLength);
          (e.target as HTMLInputElement).value = value;
        }
      }

      emit("update:modelValue", value);

      if (updateCursor && cursorPos !== undefined) {
        // Esto es para mantener la posicion del cursor y no se vaya al final
        nextTick().then(() => {
          inputRef.value?.setSelectionRange(cursorPos, cursorPos);
        });
      }
    };

    const focus = () => {
      inputRef.value?.focus();
    };

    const emitEnter = (event: Event) => {
      emit("enter", event);
    };

    return { inputRef, emitValue, emitEnter, focus };
  },

  computed: {
    inputClass() {
      const classes: string[] = [];
      const { hasError } = this;

      classes.push("block w-full focus:outline-none sm:text-sm rounded-md");

      if (hasError) {
        classes.push("pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ");
      } else {
        classes.push("border-gray-300 focus:ring-sky-500 focus:border-sky-500");
      }

      return classes.join(" ");
    },
  },
});
</script>

<!-- <style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style> -->
