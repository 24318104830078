import { _ } from "@/helpers/utils";

export default {
  filterConditions: {
    TEXT: [
      "EQUALS",
      "NOT_EQUALS",
      "CONTAINS",
      "NOT_CONTAINS",
      "STARTS_WITH",
      "ENDS_WIDTH",
      "SET",
      "NOT_SET",
    ],
    INT: [
      "EQUALS",
      "NOT_EQUALS",
      "GREATER_THAN",
      "LESS_THAN",
      "SET",
      "NOT_SET",
    ],
    DATE: ["EQUALS", "NOT_EQUALS", "BEFORE", "AFTER", "SET", "NOT_SET"],
    LANGUAGE: ["EQUALS", "NOT_EQUALS", "SET", "NOT_SET"],
    SOURCE: ["EQUALS", "NOT_EQUALS"],
    GENDER: ["EQUALS", "SET", "NOT_SET"],
  },

  // Opens the filter modal
  requestFilters: function (item, template) {
    var view = this;
    $.get("fields", { limit: 300 }, function (response) {
      var fields = response.data.map(function (item) {
        if (_.contains(["GENDER", "LANGUAGE", "SOURCE"], item.tag)) {
          item.format = item.tag;
        }
        return item;
      });
      view.openModal(
        template,
        {
          lang: window.lang,
          fields: fields,
          conditions: view.filterConditions,
          data: item,
        },
        function () {
          view
            .getModal()
            .find("[data-action=change-filter-condition]")
            .trigger("change");
        }
      );
    });
    return this;
  },

  // Updates the condition selector on the substraction modal
  changeFilterCondition: function (e) {
    var filter = $(e.currentTarget).find(":selected"),
      format = filter.data("format"),
      tag = filter.data("tag"),
      select = this.getModal().find("[data-format-type=" + format + "]"),
      datepicker = this.$("[data-value=DATEPICKER]"),
      input = this.getModal().find("[data-value=ALL]");

    // Start datepicker
    datepicker.datepicker();

    // Show input
    this.showValueFormControl(select);
    this.toogleConditionValue();
    switch (tag) {
      case "GENDER":
      case "SOURCE":
      case "LANGUAGE": {
        const el = this.$("[data-value=" + tag + "]");
        el.get(0).selectedIndex = 0;
        this.showValueFormControl(el);
        break;
      }
      default:
        if (format === "DATE") {
          this.showValueFormControl(datepicker);
        } else {
          input.val("");
          this.showValueFormControl(input);
        }
        break;
    }

    // Add validation for integers
    if (format === "INT") {
      input.attr({ "data-validate": "int", type: "number" });
    } else {
      input.removeAttr("data-validate", "int").attr("type", "text");
    }

    // Update name
    this.updateListName();
  },

  // Removes the validation errors
  cleanValidationErrors: function () {
    this.cleanFormErrorMessages(this.getModal().find("form").get(0));
  },

  // Updates the condition selector on the substraction modal
  toogleConditionValue: function () {
    var format = this.getModal().find("[data-format-type]:visible").val(),
      target = this.getModal().find("[data-role=filter-value]");
    if (_.contains(["SET", "NOT_SET"], format)) {
      target.addClass("hide");
    } else {
      target.removeClass("hide");
    }

    // Clean validation
    this.cleanValidationErrors();

    // Update name
    this.updateListName();
  },

  // Shows the value form control according with the selected filter
  showValueFormControl: function (el) {
    el.removeClass("hide")
      .removeAttr("disabled")
      .siblings()
      .addClass("hide")
      .attr("disabled", true);
  },

  // Updates the name of the list to be created according to the filter and condition
  updateListName: function () {
    var modal = this.getModal();
    let name = [
      modal.find("[name=listName]").val(),
      "-",
      modal.find("[name=field]").find(":selected").text().trim(),
      modal
        .find("[name=condition]:visible")
        .find(":selected")
        .text()
        .trim()
        .toLowerCase(),
      // eslint-disable-next-line prettier/prettier
      modal.find("[name=value]:visible").find(":selected").text().trim() || modal.find("[name=value]:visible").val(),
    ].join(" ");
    this.getModal().find("[name=newListName]").val(name);
  },
};
