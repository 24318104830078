// import { isHexString } from "./colors";

export interface Tag {
  id: string;
  name: string;
  color: HexColor;
}

export type NewTag = {
  name: string;
  color: HexColor;
};
export type Tags = Array<Tag>;

export const isTag = <V = unknown>(t: Tag | V): t is Tag => {
  const tag = t as Tag;
  return tag?.color !== undefined && tag?.name !== undefined && tag?.id !== undefined;
  // && isHexString(tag?.color) //TODO: No funcionaba
};

export const isTags = <V = unknown>(t: Tags | Array<V>): t is Tags => {
  return (t as Tags).every((item) => isTag(item));
};
