var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='templates section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'><i class='icon icon-back'></i></a>\n      "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n      <small class='lift'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "      </small>\n    </h1>\n  </div>\n  <form class='save' data-form='save'>\n    <input type='hidden' name='id' value='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n    <div class='toolbar edit-mode'>\n      <button class='btn btn-primary' data-action='edit-template' data-require-permission='templates:update'>\n        <i class='fa fa-pencil'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"edit")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-rename-modal'\n        data-require-permission='templates:update'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"renameFile")), depth0))
    + "\n      </button>\n      <button class='btn btn-default' data-action='open-duplicate-modal' data-require-permission='templates:update'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"duplicateFile")), depth0))
    + "\n      </button>\n      <button class='btn btn-default' data-action='delete' data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n        data-require-permission='templates:delete'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n      </button>\n    </div>\n    <div class='content edit scrollbar'>\n      <div class='padder'>\n        <div class='preview "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"html") : stack1),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":28},"end":{"line":36,"column":82}}})) != null ? stack1 : "")
    + "' data-role='preview'>\n          <div class='content-preview'>\n            <div class='head'>\n              <div class='device-switch'>\n                <button type='button' class='desktop active' data-action='change-preview' data-device='desktop'>\n                  <i class='fa fa-desktop'></i>\n                </button>\n                <button type='button' class='mobile' data-action='change-preview' data-device='mobile'>\n                  <i class='fa fa-mobile'></i>\n                </button>\n              </div>\n            </div>\n            <div class='loading-box hidden'></div>\n            <div class='frame'>\n              <iframe id='content-preview' src='' frameborder='0'></iframe>\n            </div>\n            <div id='content-error' class=\"hint-box hint-warning\" style=\"display: none;\">\n              "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"contentPreviewError")), depth0)) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"html") : stack1),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </form>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/timeago.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"created") : stack1),{"name":"timeago","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":43}}}))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='panel panel-default no-content'>\n          <div class='panel-body'>\n            <p class='empty-message'>\n              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"noContent")), depth0))
    + "\n            </p>\n          </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":70,"column":7}}})) != null ? stack1 : "");
},"useData":true});