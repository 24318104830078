<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div>
      <div :class="!pdfMode && 'flex items-center justify-between'">
        <h1 v-if="pdfMode" class="mx-1 whitespace-nowrap text-base font-medium">
          {{ selection == "amount" ? t("income") : t("quantity") }}
        </h1>
        <ToggleButtons
          v-else
          v-model="selection"
          :buttons="[
            {
              id: 'amount',
              label: t('income'),
              icon: !mdBp ? CurrencyDollarIcon : undefined,
            },
            {
              id: 'count',
              label: t('quantity'),
              icon: !mdBp ? HashtagIcon : undefined,
            },
          ]"
        />
        <div v-if="!pdfMode" class="ml-4 flex flex-shrink items-center space-x-4">
          <SimpleSwitch v-model="compare" :small="true" :disabled="chartType === 'spline'">
            <label
              class="over m-0 block overflow-ellipsis whitespace-nowrap text-sm font-normal"
              :class="[chartType === 'spline' ? 'text-gray-300' : 'cursor-pointer text-gray-700']"
              >{{ t("compare") }}</label
            >
          </SimpleSwitch>
          <ToggleButtons
            v-model="chartType"
            :buttons="[
              { id: 'column', label: t('columnsChart'), icon: ChartBarIcon },
              { id: 'spline', label: t('linesChart'), icon: TrendingUpIcon },
            ]"
          />
        </div>
      </div>
    </div>
    <div class="mt-6">
      <Chart :options="chartOptions" :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, PropType, toRef, inject, watch, toRaw } from "vue";
import Chart from "@molecules/Chart.vue";
import { ChartBarIcon, TrendingUpIcon, CurrencyDollarIcon, HashtagIcon } from "@heroicons/vue/solid";
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@composables/breakpoints";
import { DashboardData } from "@domain/dashboard/dashboard";
import SimpleSwitch from "@molecules/SimpleSwitch.vue";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import {
  useCharts,
  CategoryResolvers,
  Accumulator,
  CategoryResolver,
  ChartPresets,
  sumIfCurrent,
  sumIfPrevious,
} from "@composables/charts";

//Store
import { storeToRefs } from "pinia";
import { useUserConfigStore } from "@/vue/stores/userConfigStore/userConfigStore";

interface IChartPoint {
  current: {
    assisted: { count: number; amount: number };
    total: { count: number; amount: number };
  };
  previous: {
    assisted: { count: number; amount: number };
    total: { count: number; amount: number };
  };
}

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const userConfigStore = useUserConfigStore();

const { dashBoardState } = storeToRefs(userConfigStore);

const { mdBp } = useBreakpoints();

const { t, n, d } = useI18n();
const selection = ref<"amount" | "count">(dashBoardState.value.chartSales.selection || "amount");
const chartType = ref<"column" | "spline">(dashBoardState.value.chartSales.chartType || "column");
const compare = ref(dashBoardState.value.chartSales.compare || false);

const newChartPoint = (): IChartPoint => ({
  current: {
    assisted: { count: 0, amount: 0 },
    total: { count: 0, amount: 0 },
  },
  previous: {
    assisted: { count: 0, amount: 0 },
    total: { count: 0, amount: 0 },
  },
});

watch([selection, chartType, compare], () => {
  dashBoardState.value = {
    ...toRaw(dashBoardState.value),
    chartSales: {
      ...toRaw(dashBoardState.value.chartSales),
      selection: selection.value,
      chartType: chartType.value,
      compare: compare.value,
    },
  };
});

const accumulate: Accumulator<IChartPoint> = (acc, newData, periodType) => {
  let source = newData.order.source;

  if (props.data?.attributionModel === "clicks") source = newData.order.source_click;

  return {
    current: {
      assisted: {
        count: sumIfCurrent(
          periodType,
          acc.current.assisted.count,
          source
            .filter((i) => i.group !== "unattributed")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfCurrent(
          periodType,
          acc.current.assisted.amount,
          source
            .filter((i) => i.group !== "unattributed")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
      total: {
        count: sumIfCurrent(
          periodType,
          acc.current.total.count,
          source.map((i) => i.count).reduce((p, v) => p + v, 0)
        ),
        amount: sumIfCurrent(
          periodType,
          acc.current.total.amount,
          source.map((i) => i.sum).reduce((p, v) => p + v, 0)
        ),
      },
    },
    previous: {
      assisted: {
        count: sumIfPrevious(
          periodType,
          acc.previous.assisted.count,
          source
            .filter((i) => i.group !== "unattributed")
            .map((i) => i.count)
            .reduce((p, v) => p + v, 0)
        ),
        amount: sumIfPrevious(
          periodType,
          acc.previous.assisted.amount,
          source
            .filter((i) => i.group !== "unattributed")
            .map((i) => i.sum)
            .reduce((p, v) => p + v, 0)
        ),
      },
      total: {
        count: sumIfPrevious(
          periodType,
          acc.previous.total.count,
          source.map((i) => i.count).reduce((p, v) => p + v, 0)
        ),
        amount: sumIfPrevious(
          periodType,
          acc.previous.total.amount,
          source.map((i) => i.sum).reduce((p, v) => p + v, 0)
        ),
      },
    },
  };
};

const categoryResolver = computed<CategoryResolver>(() => {
  if (chartType.value === "spline" || (props.data && props.data.period.days <= 7)) return CategoryResolvers.daily;
  if (props.data && props.data.period.days > 35) return CategoryResolvers.monthly;
  return CategoryResolvers.weekly;
});

const { chartData } = useCharts(toRef(props, "data"), categoryResolver, accumulate, newChartPoint, compare, (date) =>
  props.data?.period.isWithinSameYear() ? d(date, "short") : d(date, "shortWithYear")
);

const plotOptions = pdfMode
  ? {
      ...ChartPresets.plotOptions,
      chart: {
        animation: false,
      },
      series: {
        ...ChartPresets.plotOptions.series,
        animation: false,
      },
      drilldown: {
        animation: false,
      },
    }
  : ChartPresets.plotOptions;

const chartOptions = computed(() => {
  const options: any = {
    title: "",
    tooltip: ChartPresets.tooltip((val) => n(val, selection.value === "amount" ? "currency" : "decimal")),
    chart: pdfMode ? { width: 1266 } : {},
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      ...ChartPresets.xAxis,
      categories: chartData.value && Object.keys(chartData.value),
    },
    credits: false,
    plotOptions: plotOptions,
    legend: {
      ...ChartPresets.legend,
    },
    series: [
      {
        name: t("assisted"),
        id: "assisted",
        index: 1,
        color: ChartPresets.colors.green, //"rgba(222, 235, 178, 0.9)",
        data:
          chartData.value &&
          Object.values(chartData.value).map((v) =>
            selection.value === "count" ? v.current.assisted.count : v.current.assisted.amount
          ),
        type: chartType.value,
      },
      {
        name: t("total"),
        id: "total",
        index: 3,
        color: ChartPresets.colors.blue,
        data:
          chartData.value &&
          Object.values(chartData.value).map((v) =>
            selection.value === "count" ? v.current.total.count : v.current.total.amount
          ),
        type: chartType.value,
      },
    ],
  };

  if (chartType.value === "column" && compare.value) {
    options.series.push({
      name: t("assistedPrev"),
      id: "prev-assisted",
      index: 0,
      color: ChartPresets.colors.lightGreen,
      data:
        chartData.value &&
        Object.values(chartData.value).map((v) =>
          selection.value === "count" ? v.previous.assisted.count : v.previous.assisted.amount
        ),
      type: chartType.value,
      linkedTo: "assisted",
      pointPlacement: 0.02,
    });
    options.series.push({
      name: t("totalPrev"),
      id: "prev-total",
      index: 2,
      color: ChartPresets.colors.lightBlue,
      data:
        chartData.value &&
        Object.values(chartData.value).map((v) =>
          selection.value === "count" ? v.previous.total.count : v.previous.total.amount
        ),
      type: chartType.value,
      linkedTo: "total",
      pointPlacement: 0.02,
    });
  }

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "assisted": "Asistidas",
    "total": "Totales",
    "assistedPrev": "Asistidas (ant.)",
    "totalPrev": "Totales (ant.)",
    "income": "Ingresos por ventas",
    "quantity": "Cantidad de ventas",
    "compare": "Comparar con período anterior",
    "columnsChart": "Gráfico de barras",
    "linesChart": "Gráfico de líneas"
  },
  "pt": {
    "assisted": "Assistidas",
    "total": "Totais",
    "assistedPrev": "Assistidas (ant.)",
    "totalPrev": "Totais (ant.)",
    "income": "Ingressos por vendas",
    "quantity": "Quantidade de vendas",
    "compare": "Comparar com o período anterior",
    "columnsChart": "Gráfico de barras",
    "linesChart": "Gráfico de linhas"
  }
}
</i18n>
