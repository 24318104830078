<template>
  <div class="flex h-full flex-col space-y-6">
    <AlertBox theme="warning" class="flex-grow">
      {{ t("alertMessage") }}
    </AlertBox>
    <div
      :class="[
        hoverArea ? 'border-sky-300' : 'border-gray-300',
        'mx-auto h-full w-2/3 rounded border-2 border-dashed xl:w-1/2',
      ]"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      @drop="onDropFile"
    >
      <div class="my-auto flex h-full flex-col items-center justify-center">
        <CodeIcon :class="[hoverArea ? 'text-sky-400' : 'text-gray-300', 'my-2 h-16 w-16']" />
        <TextParagraph :class="[hoverArea ? 'text-sky-600' : 'text-gray-700', 'font-medium']">{{
          t("title")
        }}</TextParagraph>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted, ref, computed } from "vue";

// Components
import TextParagraph from "@/vue/components/atoms/TextParagraph.vue";
import AlertBox from "@/vue/components/atoms/AlertBox.vue";

// Icon
import { CodeIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

const { t } = useI18n();
const trackingEventsService = useTrackingEvents();

const emit = defineEmits<{
  (e: "useHTMLTemplate", html: string): void;
}>();

const dragEnterCounter = ref(0);
const hoverArea = computed(() => dragEnterCounter.value > 0);

const useHTML = (html: string) => {
  trackingEventsService.dispatchAll({
    name: "APP_TPLEDITOR_IMPORT_HTML",
    includeMasterUsers: true,
  });
  emit("useHTMLTemplate", html);
};

const onDropFile = (event: DragEvent) => {
  event.preventDefault();

  dragEnterCounter.value--;

  if (!event.dataTransfer) return;

  const file = event.dataTransfer.files.item(0);

  if (!file) return;

  const MIMEType = file.type;

  if (MIMEType !== "text/html") {
    console.log("Error not an HTML file");
    return;
  }

  const reader = new FileReader();

  reader.onload = (e) => {
    if (!e.target) return;

    const HTML = e.target.result as string;

    useHTML(HTML);
  };
  reader.readAsText(file);
};

const onDragEnter = (event: DragEvent) => {
  event.preventDefault();

  dragEnterCounter.value++;
};

const onDragLeave = (event: DragEvent) => {
  event.preventDefault();

  dragEnterCounter.value--;
};

const globalDropEvent = (event: DragEvent) => {
  event.preventDefault();
};

const globalDragOverEvent = (event: DragEvent) => {
  event.preventDefault();
};

addEventListener("drop", globalDropEvent);
addEventListener("dragover", globalDragOverEvent);

onUnmounted(() => {
  removeEventListener("drop", globalDropEvent);
  removeEventListener("dragover", globalDragOverEvent);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Arrastra aquí tu archivo HTML",
    "alertMessage": "Para obtener buenos resultados se requieren conocimientos avanzados sobre código HTML, y sobre compatibilidad con los distintos dispositivos y clientes de correo electrónico. Nuestro equipo no podrá dar soporte en estos aspectos en caso que utilices tu propio código HTML. Si no cuentas con experiencia, te recomendamos utilizar nuestro editor drag & drop y la galería de plantillas prediseñadas."
  },
  "pt": {
    "title": "Arraste aqui o seu arquivo HTML.",
    "alertMessage": "Para obter bons resultados, é necessário ter conhecimentos avançados em HTML e sobre compatibilidade com diferentes dispositivos e clientes de e-mail. Nossa equipe não poderá oferecer suporte nessas áreas se você estiver usando seu próprio código HTML. Se não tiver experiência, recomendamos que utilize nosso editor de arrastar e soltar e a galeria de modelos pré-projetados."
  }
}
</i18n>
