import type { Ecommerce } from "./ecommerce";

export type ExternalIntegration =
  | "tiendanube"
  | "mshops"
  | "vtex"
  | "jumpseller"
  | "woocommerce"
  | "shopify"
  | "tray"
  | "tiendup"
  | "wbuy"
  | "mercadopago"
  | "ecwid"
  | "bootic"
  | "wobiz"
  | "meli"
  | "fbleadads"
  | "zapier"
  | "storydots"
  | "flowy"
  | "jivochat"
  | "tokkobroker"
  | "signos"
  | "wordpress"
  | "integrately"
  | "magento"
  | "pow"
  | "cliengo"
  | "persat";

export type InternalIntegration = "perfitapi" | "transactional" | "webjs" | "webhooks";

export type Integration = ExternalIntegration | Ecommerce | InternalIntegration;

export interface IntegrationInfo {
  id: InternalIntegration | ExternalIntegration;
  requiresActivation?: boolean;
  url?: UrlString;
  beta?: boolean;
  fields?: string[];
}

export interface PerfitIntegration extends IntegrationInfo {
  id: InternalIntegration;
  hideOnWhiteLabel?: true;
}

export interface ExternalIntegrationInfo extends IntegrationInfo {
  id: ExternalIntegration;
  redirect?: {
    development: UrlString;
    production: UrlString;
    staging: UrlString;
    testing: UrlString;
  };
}

export const isExternalIntegration = (i: ExternalIntegrationInfo | PerfitIntegration): i is ExternalIntegrationInfo => {
  return (i as ExternalIntegrationInfo).redirect !== undefined;
};

export type IntegrationsInfo = PerfitIntegration | ExternalIntegrationInfo;

export const integrations: IntegrationsInfo[] = [
  {
    id: "perfitapi",
    requiresActivation: false,
    url: "https://developers.myperfit.com",
    hideOnWhiteLabel: true,
  },
  {
    id: "transactional",
    requiresActivation: true,
    url: "https://developers.myperfit.com",
    hideOnWhiteLabel: true,
  },
  {
    id: "webjs",
    requiresActivation: true,
    url: "https://developers.myperfit.com",
    hideOnWhiteLabel: true,
  },
  {
    id: "webhooks",
    requiresActivation: true,
    fields: ["webhookUrl"],
    hideOnWhiteLabel: true,
  },
  {
    id: "tiendanube",
    requiresActivation: true,
    redirect: {
      production: "https://www.tiendanube.com/apps/738/authorize",
      staging: "https://www.tiendanube.com/apps/738/authorize",
      testing: "https://www.tiendanube.com/apps/572/authorize",
      development: "https://www.tiendanube.com/apps/572/authorize",
    },
  },
  {
    id: "mshops",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4663039523043456&redirect_uri={{appUrl}}/integrations/mshops/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4663039523043456&redirect_uri={{appUrl}}/integrations/mshops/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2754238524747973&redirect_uri={{appUrl}}/integrations/mshops/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2754238524747973&redirect_uri={{appUrl}}/integrations/mshops/auth",
    },
  },
  {
    id: "shopify",
    requiresActivation: true,
    fields: ["accessToken", "shopId"],
  },
  {
    id: "jumpseller",
    requiresActivation: true,
    redirect: {
      production:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=ab1aaf1e83dd2344c3e5c1f9d7aaa49a15c0b3cfdd4aaa252649dd14684258fd&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      staging:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=ab1aaf1e83dd2344c3e5c1f9d7aaa49a15c0b3cfdd4aaa252649dd14684258fd&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      testing:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=a0f84b357529914b542485ebb40e708e98faf14e066c6ad243348ecbc53fb045&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      development:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=a0f84b357529914b542485ebb40e708e98faf14e066c6ad243348ecbc53fb045&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
    },
  },
  {
    id: "vtex",
    requiresActivation: true,
    fields: ["appKey", "appToken", "appAccount", "baseUrl"],
  },
  {
    id: "woocommerce",
    beta: true,
    requiresActivation: true,
  },
  {
    id: "mercadopago",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4068495328940902&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4068495328940902&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=7785634434621509&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=7785634434621509&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
    },
  },
  {
    id: "fbleadads",
    requiresActivation: true,
    redirect: {
      production:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      staging:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      testing:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      development:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
    },
  },
  {
    id: "tiendup",
    requiresActivation: true,
  },
  {
    id: "zapier",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/1738462",
  },
  {
    id: "storydots",
    url: "https://docs.myperfit.com/articles/8219182",
  },
  {
    id: "flowy",
    requiresActivation: true,
  },
  {
    id: "jivochat",
    requiresActivation: true,
  },

  {
    id: "bootic",
    requiresActivation: true,
    redirect: {
      production: "https://auth.bootic.net/oauth/authorize?client_id=2721de21c235d9d112640b74ce4d8e25&scope=admin",
      staging: "https://auth.bootic.net/oauth/authorize?client_id=2721de21c235d9d112640b74ce4d8e25&scope=admin",
      testing: "https://auth.bootic.net/oauth/authorize?client_id=91d930b3c44f16d295833574b7b773a8&scope=admin",
      development: "https://auth.bootic.net/oauth/authorize?client_id=91d930b3c44f16d295833574b7b773a8&scope=admin",
    },
    beta: true,
  },
  {
    id: "ecwid",
    requiresActivation: true,
    redirect: {
      production:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      staging:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      testing:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit-dev&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      development:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit-dev&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
    },
  },
  {
    id: "tokkobroker",
    requiresActivation: true,
    fields: ["apiKey"],
  },
  {
    id: "signos",
    requiresActivation: true,
    fields: ["url_orders", "url_products_suggested"],
  },
  {
    id: "cliengo",
    requiresActivation: true,
    fields: ["apiKey"],
  },
  {
    id: "tray",
    requiresActivation: true,
    fields: ["baseUrl"],
    redirect: {
      production:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      staging:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      testing:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      development:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
    },
  },
  {
    id: "wbuy",
    requiresActivation: true,
    fields: ["code"],
  },
  {
    id: "wordpress",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/1641",
  },
  {
    id: "integrately",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/6720373",
  },
  {
    id: "magento",
    requiresActivation: true,
    fields: ["apiKey", "shopUrl"],
  },
  {
    id: "pow",
    requiresActivation: true,
    fields: ["apiKey", "shopUrl"],
  },
  {
    id: "persat",
    requiresActivation: false,
    url: "http://docs.persat.com.ar/preguntas-frecuentes/generalidades/que-es-y-como-obtener-api-key-de-persat",
  },
  {
    id: "wobiz",
    requiresActivation: true,
    redirect: {
      production:
        "https://admin.wobiz.com.ar/authorize?response_type=code&client_id=20ff4dff-c562-11ec-a758-02420a650003&redirect_uri={{appUrl}}/integrations/wobiz/auth&scope=customer_read,product_read,order_read,coupon_read,coupon_write&state={{random}}",
      staging:
        "https://admin.wobiz.com.ar/authorize?response_type=code&client_id=20ff4dff-c562-11ec-a758-02420a650003&redirect_uri={{appUrl}}/integrations/wobiz/auth&scope=customer_read,product_read,order_read,coupon_read,coupon_write&state={{random}}",
      testing:
        "https://admin.staging.wobiz.info/authorize?response_type=code&client_id=75fa1513-4f20-4be5-9c71-9f1cac1ccbd6&redirect_uri={{appUrl}}/integrations/wobiz/auth&scope=customer_read,product_read,order_read,coupon_read,coupon_write&state={{random}}",
      development:
        "https://admin.staging.wobiz.info/authorize?response_type=code&client_id=75fa1513-4f20-4be5-9c71-9f1cac1ccbd6&redirect_uri={{appUrl}}/integrations/wobiz/auth&scope=customer_read,product_read,order_read,coupon_read,coupon_write&state={{random}}",
    },
  },
  {
    id: "meli",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=6550531446492109&redirect_uri={{appUrl}}/integrations/meli/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=6550531446492109&redirect_uri={{appUrl}}/integrations/meli/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=1689728236868286&redirect_uri={{appUrl}}/integrations/meli/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=1689728236868286&redirect_uri={{appUrl}}/integrations/meli/auth",
    },
  },
];

export const integrationsPT: IntegrationsInfo[] = [
  {
    id: "perfitapi",
    requiresActivation: false,
    url: "https://developers.myperfit.com",
  },
  {
    id: "transactional",
    requiresActivation: true,
    url: "https://developers.myperfit.com",
  },
  {
    id: "webjs",
    requiresActivation: true,
    url: "https://developers.myperfit.com",
    hideOnWhiteLabel: true,
  },
  {
    id: "webhooks",
    requiresActivation: true,
    fields: ["webhookUrl"],
  },
  {
    id: "tiendanube",
    requiresActivation: true,
    redirect: {
      production: "https://www.nuvemshop.com.br/apps/738/authorize",
      staging: "https://www.nuvemshop.com.br/apps/738/authorize",
      testing: "https://www.nuvemshop.com.br/apps/572/authorize",
      development: "https://www.nuvemshop.com.br/apps/572/authorize",
    },
  },
  {
    id: "mshops",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2040651613101635&redirect_uri={{appUrl}}/integrations/mshops/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2040651613101635&redirect_uri={{appUrl}}/integrations/mshops/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2754238524747973&redirect_uri={{appUrl}}/integrations/mshops/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=2754238524747973&redirect_uri={{appUrl}}/integrations/mshops/auth",
    },
  },
  {
    id: "shopify",
    requiresActivation: true,
    fields: ["accessToken", "shopId"],
  },
  {
    id: "jumpseller",
    requiresActivation: true,
    redirect: {
      production:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=ab1aaf1e83dd2344c3e5c1f9d7aaa49a15c0b3cfdd4aaa252649dd14684258fd&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      staging:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=ab1aaf1e83dd2344c3e5c1f9d7aaa49a15c0b3cfdd4aaa252649dd14684258fd&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      testing:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=a0f84b357529914b542485ebb40e708e98faf14e066c6ad243348ecbc53fb045&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
      development:
        "https://accounts.jumpseller.com/oauth/authorize?response_type=code&client_id=a0f84b357529914b542485ebb40e708e98faf14e066c6ad243348ecbc53fb045&redirect_uri={{appUrl}}/integrations/jumpseller/auth&scope=read_orders+read_products+read_customers+read_hooks+write_hooks+read_store+read_custom_fields+read_categories+read_customer_categories+read_jsapps+write_jsapps&state={{account}}",
    },
  },
  {
    id: "vtex",
    requiresActivation: true,
    fields: ["appKey", "appToken", "appAccount", "baseUrl"],
  },
  {
    id: "tray",
    requiresActivation: true,
    fields: ["baseUrl"],
    redirect: {
      production:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      staging:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      testing:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
      development:
        "{{fields.baseUrl}}/auth.php?response_type=code&consumer_key=05998ec22a99e069df65ce67f1dfa0a336c8dc15f77e35a6fafd27c21817e699&callback={{appUrl}}/integrations/tray/auth",
    },
  },
  {
    id: "wbuy",
    requiresActivation: true,
    fields: ["code"],
  },
  {
    id: "woocommerce",
    beta: true,
    requiresActivation: true,
  },
  {
    id: "mercadopago",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4068495328940902&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=4068495328940902&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=7785634434621509&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=7785634434621509&redirect_uri={{appUrl}}/integrations/mercadopago/auth",
    },
  },
  {
    id: "jivochat",
    requiresActivation: true,
  },
  {
    id: "fbleadads",
    requiresActivation: true,
    redirect: {
      production:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      staging:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      testing:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
      development:
        "https://www.facebook.com/v3.2/dialog/oauth?response_type=code&display=page&client_id=2137367163246208&redirect_uri={{appUrl}}/integrations/fbleadads/auth&scope=pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management,pages_show_list",
    },
  },
  {
    id: "zapier",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/1738462",
  },
  {
    id: "ecwid",
    requiresActivation: true,
    redirect: {
      production:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      staging:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      testing:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit-dev&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
      development:
        "https://my.ecwid.com/api/oauth/authorize?client_id=myperfit-dev&redirect_uri={{appUrl}}/integrations/ecwid/auth&response_type=code&scope=public_storefront,update_catalog,read_catalog,update_customers,read_customers,update_orders,read_store_profile,read_orders",
    },
  },
  {
    id: "bootic",
    requiresActivation: true,
    redirect: {
      production: "https://auth.bootic.net/oauth/authorize?client_id=2721de21c235d9d112640b74ce4d8e25&scope=admin",
      staging: "https://auth.bootic.net/oauth/authorize?client_id=2721de21c235d9d112640b74ce4d8e25&scope=admin",
      testing: "https://auth.bootic.net/oauth/authorize?client_id=91d930b3c44f16d295833574b7b773a8&scope=admin",
      development: "https://auth.bootic.net/oauth/authorize?client_id=91d930b3c44f16d295833574b7b773a8&scope=admin",
    },
    beta: true,
  },
  {
    id: "tiendup",
    requiresActivation: true,
  },
  {
    id: "storydots",
    url: "https://docs.myperfit.com/articles/8219182",
  },
  {
    id: "flowy",
    requiresActivation: true,
  },
  {
    id: "meli",
    requiresActivation: true,
    redirect: {
      production:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=6550531446492109&redirect_uri={{appUrl}}/integrations/meli/auth",
      staging:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=6550531446492109&redirect_uri={{appUrl}}/integrations/meli/auth",
      testing:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=1689728236868286&redirect_uri={{appUrl}}/integrations/meli/auth",
      development:
        "https://auth.mercadolibre.com/authorization?response_type=code&client_id=1689728236868286&redirect_uri={{appUrl}}/integrations/meli/auth",
    },
  },
  {
    id: "integrately",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/6720373",
  },
  {
    id: "cliengo",
    requiresActivation: true,
    fields: ["apiKey"],
  },
  {
    id: "tokkobroker",
    requiresActivation: true,
    fields: ["apiKey"],
  },
  {
    id: "signos",
    requiresActivation: true,
    fields: ["url_orders", "url_products_suggested"],
  },
  {
    id: "wordpress",
    requiresActivation: false,
    url: "https://docs.myperfit.com/articles/1641",
  },
  {
    id: "persat",
    requiresActivation: false,
    url: "http://docs.persat.com.ar/preguntas-frecuentes/generalidades/que-es-y-como-obtener-api-key-de-persat",
  },
];
