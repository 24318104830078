<template>
  <Table
    :sort-by="sortBy"
    :columns="columns"
    :rows="rows"
    :selected-rows="selectedRows"
    :loading="loading"
    :skeleton="skeleton"
    :skeleton-count="5"
    :selectable="campaignsUpdate"
    class="ml-6 overflow-x-hidden pb-16"
    @update:sort-by="updateSortBy"
    @update:selected-rows="updateSelectedRows"
    @scroll-bottom="fetchCampaigns"
  >
    <template #name="{ row }">
      <NameCell v-if="row" :row="row" @select-tag="selectTag" />
    </template>
    <template #name-skeleton>
      <div class="my-4 w-[20rem] animate-pulse space-y-2">
        <div class="w-72 rounded-md bg-sky-50 py-2" />
        <div class="w-80 rounded-md bg-gray-50 py-2" />
      </div>
    </template>
    <template #launchDate="{ row }">
      <span
        v-if="row"
        :class="[
          !row?.data?.launchDate || row?.data?.state === 'DRAFT' ? 'pl-[70%]' : 'ml-auto',
          'flex w-max min-w-[5rem] flex-nowrap text-right text-sm text-gray-500',
        ]"
      >
        {{ row?.data?.launchDate && row?.data?.state !== "DRAFT" ? launchDateFormatter(row.data.launchDate) : "-" }}
      </span>
    </template>
    <template #openedP="{ row }">
      <span class="text-sm text-gray-500">{{
        row.data.metrics?.openedP !== undefined ? `${n(row.data.metrics?.openedP, "decimal")}%` : "-"
      }}</span>
    </template>
    <template #clickedP="{ row }">
      <span class="text-sm text-gray-500">{{
        row.data.metrics?.clickedP !== undefined ? `${n(row.data.metrics?.clickedP, "decimal")}%` : "-"
      }}</span>
    </template>
    <template #bouncedP="{ row }">
      <span class="text-sm text-gray-500">{{
        row.data.metrics?.bouncedP !== undefined ? `${n(row.data.metrics?.bouncedP, "decimal")}%` : "-"
      }}</span>
    </template>
    <template #conversionsAmount="{ row }">
      <div class="flex flex-col space-y-1 pl-4">
        <span class="text-sm text-gray-500">
          {{
            row.data.metrics?.conversionsAmount
              ? n(Number(row.data.metrics.conversionsAmount) ?? 0, "currencyNoCents")
              : "-"
          }}
        </span>
        <span
          v-if="!!row.data.metrics?.conversions && Number(row.data.metrics?.conversions) > 0"
          class="text-xs text-gray-400"
        >
          {{
            t(
              "conversions",
              { amount: n(Number(row.data.metrics?.conversions), "decimal") },
              { plural: Number(row.data.metrics?.conversions) },
            )
          }}
        </span>
      </div>
    </template>
    <template #defaultCell="{ row, item }">
      <div v-if="row" class="flex w-full justify-center pl-10 text-sm text-gray-500">
        <span class="w-full shrink-0 grow">
          {{ item ? n(Number(item) ?? 0, "decimal") : "-" }}
        </span>
      </div>
    </template>
    <template #defaultCell-skeleton>
      <div class="my-4 ml-auto h-4 w-20 rounded-xl bg-gray-50" />
    </template>
    <template #options="{ row }">
      <div class="flex justify-center">
        <DropDownButton
          :small="true"
          :navigation="getMenuOptions(row.data.state, !!row.data.archived)"
          theme="transparent-white"
          placement="bottom-end"
          :auto-placements="['bottom-end', 'left', 'top-end']"
          :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
          @update:selected="(option) => handleListOption(option, row)"
        />
      </div>
    </template>
    <template #options-skeleton>
      <div class="flex justify-center">
        <div class="my-4 w-4 animate-pulse rounded-md bg-gray-50 py-2" />
      </div>
    </template>
  </Table>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { ColumnsIds, CampaignsOptionsKeys, CampaignsRows, CampaignsRow } from "../../campaigns.types";
import {
  campaignsToRows,
  getListOptions,
  getListOptionsSending,
  getListOptionsArchived,
  getListOptionsScheduled,
  getListOptionsSent,
} from "../../campaigns.data";

// Components
import NameCell from "./components/NameCell.vue";
import Table from "@molecules/Table";
import type { SortColumn, Columns } from "@molecules/Table";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem, Categories } from "@molecules/DropDownButton";
import type { Items } from "@molecules/CheckboxListButton";

// Utils
import { formatDate } from "@helpers/formatters";
import { useI18n } from "vue-i18n";

// Store
import { useSessionStore, useAlertStore } from "@store";

// Domain
import type { Campaigns, Campaign, ListColumn, CampaignStates } from "@domain/campaigns";
import type { Tag } from "@domain/tag";

const { t, n } = useI18n();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const campaignsUpdate = sessionStore.hasPermission("campaigns:update");
const campaignsDelete = sessionStore.hasPermission("campaigns:delete");

const props = withDefaults(
  defineProps<{
    campaigns: Campaigns;
    selected: Campaigns;
    selectedColumns: Items<string, string, ListColumn>;
    columns: Columns<ColumnsIds | "options">;
    sortBy: SortColumn<ColumnsIds>;
    loading?: boolean;
    skeleton?: boolean;
  }>(),
  {
    campaigns: () => [],
    loading: false,
    skeleton: false,
  },
);

const emit = defineEmits<{
  archiveCampaign: [Campaign];
  unarchiveCampaign: [Campaign];
  duplicateCampaign: [Campaign];
  exportCampaign: [Campaign];
  deleteCampaign: [Campaign];
  stopCampaign: [Campaign];
  updateSelected: [Campaigns];
  fetchCampaigns: [void];
  "update:sortBy": [SortColumn<ColumnsIds>];
  selectTag: [Tag];
}>();

const fetchCampaigns = () => emit("fetchCampaigns");
const selectTag = (tag: Tag) => emit("selectTag", tag);
const updateSortBy = (sort: SortColumn<ColumnsIds>) => emit("update:sortBy", sort);

const launchDateFormatter = (dateString: DateString) => {
  const date = new Date(dateString);
  const isThisYear = date.getFullYear() === new Date().getFullYear();
  if (isThisYear) {
    return formatDate(date, "MMM dd, HH:mm");
  }

  return formatDate(date, "MMM dd, y");
};

const rows = computed<CampaignsRows>(() => campaignsToRows(props.campaigns));
const selectedRows = computed<CampaignsRows>(() => campaignsToRows(props.selected));

// Table
const updateSelectedRows = (newRows: CampaignsRows) => {
  emit(
    "updateSelected",
    newRows.map((r) => r.data),
  );
};

const listOptions = getListOptions();
const listOptionsScheduled = getListOptionsScheduled();
const listOptionsSent = getListOptionsSent();
const listOptionsSending = getListOptionsSending();
const listOptionsArchived = getListOptionsArchived();

const getMenuOptions = (state: CampaignStates, archived?: boolean): Categories => {
  if (archived) return listOptionsArchived;
  if (state === "SENT" ) return listOptionsSent;
  if (state === "SENDING" || state ==="CANCELLED") return listOptionsSending;
  if (state === "DRAFT") return listOptions;
  return listOptionsScheduled;
};

const listOptionHandler: Record<CampaignsOptionsKeys, (row: CampaignsRow) => void> = {
  archive: (row) => {
    emit("archiveCampaign", row.data);
  },
  unarchive: (row) => {
    emit("unarchiveCampaign", row.data);
  },
  stop: (row) => {
    emit("stopCampaign", row.data);
  },
  duplicate: (row) => {
    emit("duplicateCampaign", row.data);
  },
  export: (row) => {
    emit("exportCampaign", row.data);
  },
  delete: (row) => {
    if (!sessionStore.hasPermission("campaigns:delete")) {
      alertStore.showPermissionDenied(["campaigns:delete"]);
      return;
    }

    emit("deleteCampaign", row.data);
  },
};

const handleListOption = async (option: DropDownDataItem, row: CampaignsRow) => {
  if (!campaignsDelete && option.key === "delete") {
    alertStore.showPermissionDenied(["campaigns:delete"]);
    return;
  }

  if (!campaignsUpdate && option.key !== "export") {
    alertStore.showPermissionDenied(["campaigns:update"]);
    return;
  }

  await listOptionHandler[option.key]?.(row);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "conversions": "{amount} ventas | {amount} venta | {amount} ventas",
    "listOptions": {
      "duplicate": "Duplicar",
      "archive": "Archivar",
      "unarchive": "Desarchivar",
      "export": "Exportar",
      "delete": "Eliminar",
      "stop": "Detener",
      "pause": "Pausar"
    }
  },
  "pt": {
    "conversions": "{amount} vendas | {amount} venda | {amount} vendas",
    "listOptions": {
      "duplicate": "Duplicar",
      "archive": "Arquivar",
      "unarchive": "Desarquivar",
      "export": "Exportar",
      "delete": "Excluir",
      "stop": "Parar",
      "pause": "Pausar"
    }
  }
}
</i18n>
