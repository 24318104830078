<template>
  <th
    scope="col"
    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    :class="rightAligned && 'text-right'"
  >
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: "SimpleTableHeader",
  props: {
    rightAligned: { type: Boolean, default: false },
  },
};
</script>
