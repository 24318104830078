import { createPinia } from "pinia";

export { useContactsStore } from "./contactsStore/contacts.store";
export { useUserConfigStore } from "./userConfigStore/userConfigStore";
export { useIntegrationsStore } from "./integrationsStore/integrations.store";
export { useAccountStore } from "./accountStore/account.store";
export { useFeaturesStore } from "./featuresStore/features.store";
export { useNotificationStore } from "./notificationsStore/notifications.store";
export { useAlertStore } from "./alertStore/alert.store";
export { useInvoicePaymentsStore } from "./invoicePaymentsStore/invoicePayments.store";
export { useSessionStore, setLegacyParseSession, getLegacyParseSession } from "./sessionStore/session.store";
export { useSendersStore } from "./sendersStore/senders.store";
export { useUserStore } from "./userStore/user.store";
export { useOnboardingStore } from "./onboardingStore/onboarding.store";
export { useGlobalStore } from "./globalStore/global.store";
export { useUserActivityStore } from "./userActivityStore/userActivity.store";

export * from "./utils/fetching";

export const piniaIntance = createPinia();

export const usePinia = () => {
  return piniaIntance;
};
