<template>
  <button
    class="relative flex flex-col justify-center space-y-2 border-2 border-gray-100 focus:outline-none focus:border-sky-300 hover:border-sky-300 rounded-lg h-36 w-48 cursor-pointer"
  >
    <PlusIcon class="h-6 w-6 text-gray-500 mx-auto" />
    <SimpleButton tabindex="-1" theme="primary-light" class="mx-auto pointer-events-none">
      {{ t("addFontButton") }}
    </SimpleButton>
  </button>
</template>

<script lang="ts" setup>
// Components
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";

// Icons
import { PlusIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<i18n lang="jsonc">
{
  "es": {
    "addFontButton": "Agregar fuente"
  },
  "pt": {
    "addFontButton": "Adicionar fonte"
  }
}
</i18n>
