import { ref } from "vue";
import { defineStore } from "pinia";

// Services
import { useAccountFeatures } from "@api/modules/account";

// Types
import type { AccountFeatures } from "@/vue/types/account";

export const useFeaturesStore = defineStore("featuresStore", () => {
  const features = ref<AccountFeatures>();
  const getFeatures = (): AccountFeatures | undefined => {
    return features.value;
  };

  const useAccountFeaturesAPI = useAccountFeatures();
  const fetchFeatures = async () => {
    features.value = await useAccountFeaturesAPI.get();
  };

  return { features, getFeatures, fetchFeatures };
});
