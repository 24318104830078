<template>
  <ModalDialog :open="open" without-overflow @close="close">
    <template #title> {{ t("addFontTitle") }} </template>

    <div class="w-[30rem] space-y-6">
      <FormInput
        :model-value="editMode ? font?.url : fontLinkField"
        :loading="loadingValidation"
        :error="hintError"
        :label="t('form.fontLinkLabel')"
        :placeholder="t('form.fontLinkPlaceholder')"
        :disabled="editMode"
        class="disabled:bg-gray-100"
        @update:model-value="updateFontLink"
      />
      <div v-if="editMode || (!loadingValidation && fontFamilyName)">
        <FormInput
          :model-value="editMode ? font?.name : fontFamilyName"
          :label="t('form.fontNameLabel')"
          disabled
          class="disabled:bg-gray-100"
        />
      </div>
      <AlertBox>
        <i18n-t keypath="alertFonts">
          <template #fontslink>
            <a target="_blank" href="https://fonts.google.com/">Google Fonts</a>
          </template>
          <template #helplink>
            <a href="#" @click.prevent="gotoHelp(6979913)">{{ t("helpcenter") }}</a>
          </template>
        </i18n-t>
      </AlertBox>
      <div>
        <ComboBox
          :model-value="alterfont"
          :data-items="defaultFontsDataItems"
          :label="t('form.fontAlterLabel')"
          :placeholder="t('form.fontAlterPlaceholder')"
          :show-key="false"
          @update:model-value="updateAlterFont"
        />
        <InputHint>{{ t("form.fontAlterHint") }}</InputHint>
      </div>
    </div>

    <template #buttons>
      <div :class="[editMode ? 'justify-between' : 'justify-end', 'flex w-full']">
        <SimpleButton v-if="editMode" theme="danger-alter" @click="deleteFont">{{ t("deleteButton") }}</SimpleButton>

        <div class="flex space-x-2">
          <SimpleButton theme="white" @click="close"> {{ t("cancelButton") }}</SimpleButton>

          <SimpleButton
            theme="primary-light"
            :disabled="!editMode && (!isValidFontUrl || loadingValidation || !alterfont || duplicatedFont)"
            @click="() => (editMode ? editFont() : createFont())"
          >
            <template #leading><CheckIcon class="h-5 w-5" aria-hidden="true" /></template>
            {{ editMode ? t("editFontButton") : t("addFontButton") }}
          </SimpleButton>
        </div>
      </div>
    </template>
  </ModalDialog>
</template>
<script lang="ts" setup>
import { ref, computed, watch } from "vue";

import { defaultFontsDataItems } from "../defaultFonts";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import FormInput from "@/vue/components/molecules/FormInput.vue";
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import AlertBox from "@/vue/components/atoms/AlertBox.vue";
import InputHint from "@atoms/InputHint.vue";
import ComboBox from "@molecules/ComboBox.vue";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";

//Utils
import { useI18n } from "vue-i18n";

// Type
import type { FontWithAlter, Fonts } from "@/vue/types/fonts";
import type { DataItem } from "@molecules/ComboBox.vue";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    open: boolean;
    font?: FontWithAlter;
    fonts: Fonts;
    editMode?: boolean;
  }>(),
  {
    open: false,
    font: undefined,
    fonts: () => [],
    editMode: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
  (e: "create", font: FontWithAlter): void;
  (e: "delete", font: FontWithAlter): void;
  (e: "edit", font: FontWithAlter): void;
}>();

// trackChange
const changesMade = ref(false);

// Methods

const alterfont = ref<DataItem<{ url?: string; name?: string }>>(defaultFontsDataItems[0]);
const updateAlterFont = (value) => {
  alterfont.value = value;
  changesMade.value = true;
};

const close = () => emit("update:open", false);
const deleteFont = () => {
  if (!props.font) return close();
  emit("delete", props.font);
};
const createFont = () => {
  const fontUrl = extractedURLFont.value?.[0]?.toString();
  if (!fontUrl || !fontFamilyName.value) return close();
  emit("create", {
    id: `custom_${fontFamilyName.value}`,
    name: fontFamilyName.value,
    value: `${fontFamilyName.value}`,
    alterFont: {
      id: alterfont.value?.key,
      name: alterfont.value?.data?.name ?? alterfont.value?.value,
      value: alterfont.value?.value,
      url: alterfont.value?.data?.url,
    },
    url: fontUrl,
  });
};

const editFont = () => {
  const font = props.font;
  if (!font || !changesMade.value) return close();
  emit("edit", {
    ...font,
    alterFont: {
      id: alterfont.value?.key,
      name: alterfont.value?.data?.name || alterfont.value.value,
      url: alterfont.value?.data?.url,
      value: alterfont.value.value,
    },
  });
};

//Font
const fontlink = ref(props.font?.url ?? "");
const fontLinkField = ref(props.font?.url ?? "");
const updateFontLink = (value: string) => {
  fontlink.value = value;
  fontLinkField.value = value;
};

// create font
watch(
  () => props.open,
  () => {
    if (!props.open) {
      fontlink.value = "";
      fontLinkField.value = "";

      alterfont.value = defaultFontsDataItems[0];
      return;
    }
    const font = props.font;
    const foundAlterFont = defaultFontsDataItems.find((alterFont) => alterFont.key === font?.alterFont?.id);

    alterfont.value = foundAlterFont ?? defaultFontsDataItems[0];
  }
);

// Google fonts validation
const loadingValidation = ref(false);
const urlIsValidToFetch = ref(false);

const extractedURLFont = computed(() => {
  const fonts = fontlink.value.matchAll(/https:\/\/fonts\.googleapis\.com\/css2[^"|']+/g);
  return [...fonts];
});

const hasMultipleUrls = computed(() => {
  return extractedURLFont.value.length > 1;
});

const textHasMultipleFonts = computed(() => {
  if (hasMultipleUrls.value || !extractedURLFont.value?.[0]) return false;
  const url = extractedURLFont.value?.[0].toString();
  return [...url.matchAll(/family/g)].length > 1;
});

const fontFamilyName = computed(() => {
  if (hasMultipleUrls.value || !extractedURLFont.value?.[0] || textHasMultipleFonts.value) return "";
  return extractedURLFont.value?.[0]
    ?.toString()
    .match(/family=([^:|&]+)/g)?.[0]
    .slice(7)
    .replaceAll("+", " ");
});

const duplicatedFont = computed(() => {
  if (hasMultipleUrls.value || textHasMultipleFonts.value || !fontFamilyName.value) return false;

  return props.fonts.some((font) => font.name === fontFamilyName.value);
});

const fetchFontValidator = async (url: string) => {
  try {
    urlIsValidToFetch.value = false;
    await fetch(url);
    urlIsValidToFetch.value = true;
  } catch (e) {
    urlIsValidToFetch.value = false;
  }
};

const isValidUrl = computed(() => {
  if (
    fontlink.value.length === 0 ||
    hasMultipleUrls.value ||
    textHasMultipleFonts.value ||
    extractedURLFont.value.length === 0
  )
    return false;
  return true;
});

const isValidFontUrl = computed(() => isValidUrl.value && urlIsValidToFetch.value);

const timerToFetch = ref();
watch(
  () => extractedURLFont.value,
  async () => {
    if (isValidUrl.value) {
      loadingValidation.value = true;
      timerToFetch.value && clearTimeout(timerToFetch.value);
      timerToFetch.value = setTimeout(async () => {
        const formattedUrl = extractedURLFont.value?.[0].toString();
        await fetchFontValidator(formattedUrl);
        fontLinkField.value = formattedUrl;
        loadingValidation.value = false;
      }, 600);
    }
  }
);

// input Errors
const hintError = computed(() => {
  if (props.editMode || fontlink.value.length === 0 || loadingValidation.value) return undefined;
  if (hasMultipleUrls.value) return t("form.fontMultipleUrlsError");
  if (textHasMultipleFonts.value) return t("form.fontMultipleFontFamiliesError");
  if (!isValidFontUrl.value) return t("form.fontInvalidError");
  if (duplicatedFont.value) return t("form.duplicatedFont");
  return undefined;
});

const gotoHelp = (id) => {
  window.Intercom("showArticle", id);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "addFontTitle": "Agregar fuente personalizada",
    "addFontButton": "Agregar fuente",
    "editFontButton": "Aceptar",
    "cancelButton": "Cancelar",
    "deleteButton": "Eliminar fuente",
    "alertFonts": "El enlace debe ser cargado como figura en {fontslink}, visita el {helplink} para más información .",
    "helpcenter": "centro de ayuda",
    "form": {
      "fontLinkLabel": "Enlace de la fuente",
      "fontLinkPlaceholder": "Copia y pega el enlace desde Google Fonts.",
      "fontInvalidError": "No se ha encontrado una url valida de Google Fonts.",
      "duplicatedFont": "La fuente ya se encuentra cargada.",
      "fontMultipleUrlsError": "Multiples urls de fuentes encontradas.",
      "fontMultipleFontFamiliesError": "La url contiene multiples familias de fuentes.",
      "fontNameLabel": "Nombre de la fuente",
      "fontAlterLabel": "Fuente alternativa",
      "fontAlterPlaceholder": "Selecciona una fuente",
      "fontAlterHint": "Será utilizada por los lectores que no soportan fuentes personalizadas.",
      "fontAlterHintLink": "Leer más"
    }
  },
  "pt": {
    "addFontTitle": "Adicionar fonte personalizada.",
    "addFontButton": "Adicionar fonte",
    "editFontButton": "Aceitar",
    "cancelButton": "Cancelar",
    "deleteButton": "Excluir fonte",
    "alertFonts": "O link deve ser carregado como aparecem em {fontslink}, visite o {helplink} para mais informações.",
    "helpcenter": "centro de ajuda",
    "form": {
      "fontLinkLabel": "Link da fonte",
      "fontLinkPlaceholder": "Copie e cole o link desde Google Fonts.",
      "fontInvalidError": "Não foi encontrada uma url válida do Google Fonts.",
      "duplicatedFont": "A fonte já se encontra carregada.",
      "fontMultipleUrlsError": "Várias urls de fontes encontradas.",
      "fontMultipleFontFamiliesError": "A url contém várias famílias de Fontes.",
      "fontNameLabel": "Nome da fonte",
      "fontAlterLabel": "Fonte alternativa",
      "fontAlterPlaceholder": "Selecione uma fonte",
      "fontAlterHint": "Será utilizada pelos leitores que não suportam Fontes personalizadas.",
      "fontAlterHintLink": "Ler mais"
    }
  }
}
</i18n>
