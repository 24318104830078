<template>
  <div class="mx-auto w-full max-w-lg lg:w-[28rem] hlg:py-8">
    <div>
      <div class="flex items-center space-x-2">
        <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
        <PlusIcon v-if="integrationStore.isTiendanube" class="h-4 w-4 stroke-[3]" aria-hidden="true" />
        <img
          v-if="integrationStore.isTiendanube"
          class="h-10 w-auto"
          src="/img/login-tiendanube-icon.svg"
          alt="Perfit"
        />
      </div>
      <h1 class="mt-4 whitespace-nowrap pr-36 text-3xl font-extrabold leading-9 tracking-tight text-gray-900 lg:pr-20">
        {{ t("title") }}
      </h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
      </p>
    </div>

    <div class="mt-6">
      <form @submit.prevent="updateUserData">
        <div class="mt-6">
          <FormInput
            v-model="formData.name"
            :label="t('nameLabel')"
            :placeholder="t('namePlaceholder')"
            :hint="t('nameHint')"
            :error="v$.name.$error ? getNameErrorMessage(v$.name.$errors) : undefined"
            autocomplete="username"
            @input="v$.name.$reset()"
          />
        </div>
        <div class="mt-6">
          <PhoneInput
            v-model:phone="formData.phone"
            v-model:selected-country="defaultCountry"
            format="national"
            :label="t('phoneLabel')"
            :hint="t('phoneHint')"
            :error="v$.phone.$error ? getPhoneErrorMessage() : undefined"
            @update:validity="validatePhone"
            @input="v$.phone.$reset()"
            @enter.prevent
          />
        </div>

        <div class="mt-6 flex space-x-2">
          <div :class="[showReferralExtra ? 'w-[50%]' : 'w-full']">
            <SelectMenuLabel
              v-model:selected="selectedReferral"
              :label="t('referralLabel')"
              :data-items="referrals"
              :rem-height="10"
              :error="v$.referral.$error ? t('fieldRequired') : undefined"
              @update:selected="cleanReferralError"
            />
          </div>
          <div v-show="showReferralExtra" class="w-[50%]">
            <FormInput
              v-model="formData.referralExtra"
              :label="t('referralExtraLabel')"
              :placeholder="t('referralExtraPlaceholder')"
              :error="v$.referralExtra.$error ? t('fieldRequired') : undefined"
              @input="v$.referralExtra.$reset()"
            />
          </div>
        </div>

        <SimpleButton
          type="submit"
          :loading="submitting"
          :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
        >
          <template #leading>
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </template>
          {{ t("save") }}
        </SimpleButton>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, computed } from "vue";

import { getTimezones, getReferrals } from "../accountForm";

// components
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItem } from "@molecules/SelectMenuLabel.vue";
import PhoneInput from "@organisms/phoneInput";

// Icons
import { CheckIcon, PlusIcon } from "@heroicons/vue/solid";

// Application
import { useGeoLocationApplication, useUserActivityApplication, useRouterApp } from "@application";

// Services
import { useAccountFeatures } from "@api/modules/account";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Utils
import parsePhoneNumber from "libphonenumber-js";
import { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import Intercom from "@helpers/intercom";

// Stores
import { useAccountStore, useUserStore, useIntegrationsStore, useSessionStore } from "@store";

// Type
import type { CountryCode as PNCountryCode } from "libphonenumber-js";
import type { Referral } from "@domain/referrals";
import { Timezone } from "@domain/timezones";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const sessionStore = useSessionStore();
const accountStore = useAccountStore();
const userStore = useUserStore();
const accountFeatures = useAccountFeatures();
const integrationStore = useIntegrationsStore();
const trackingEventsService = useTrackingEvents();
const geoLocationApp = useGeoLocationApplication();
const userActivityApp = useUserActivityApplication();
const accountFeaturesApp = useAccountFeatures();
const routerApp = useRouterApp();

const emit = defineEmits<{
  close: [void];
}>();

const close = () => {
  emit("close");
};

const formData = reactive<{
  name: string;
  phone: string;
  referral: Referral | "other" | "";
  referralExtra: string;
}>({
  name: "",
  phone: "",
  referral: "",
  referralExtra: ""
});

const timezones = getTimezones();
const tz: Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone;
const browserTimezone = timezones.find((t) => t.key === tz);

const referrals = getReferrals();
const referralsWithExtra: Array<Referral | "empty" | "other"> = ["agency", "instagram", "youtube", "tiktok", "other"];
const selectedReferral = ref<DataItem<unknown, Referral | "empty" | "other", string>>({
  key: "empty",
  value: t("referral.empty")
});
const showReferralExtra = computed(() => {
  return referralsWithExtra.includes(selectedReferral.value.key);
});

const cleanReferralError = () => {
  v$.value.referral.$reset();
  v$.value.referralExtra.$reset();
};

watch([() => selectedReferral.value], () => {
  formData.referral = selectedReferral.value.key !== "empty" ? selectedReferral.value.key : "";

  if (referralsWithExtra.includes(selectedReferral.value.key)) {
    return;
  }

  formData.referralExtra = "";
});

const countryDetecteFromPhone = ref(false);
const defaultCountry = ref<PNCountryCode>(
  (sessionStore.session.account.country?.toUpperCase() as PNCountryCode) ?? "AR"
);
const detectCountry = async () => {
  const res = await geoLocationApp.detectCountry();

  if (res.isErr()) return;

  defaultCountry.value = res.value.toUpperCase() as PNCountryCode;
};

const phoneIsValid = ref(false);
const validatePhone = (valid: boolean) => {
  phoneIsValid.value = valid;
};

const getNameErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "maxLength") return t("maxLengthError", { length: nameMaxLength });
  return t("fieldRequired");
};
const getPhoneErrorMessage = () => {
  const phoneExample = getExampleNumber(defaultCountry.value, examples)?.formatNational();
  if (!phoneIsValid.value && formData.phone.length > 0) return t("invalidPhoneError", { phoneFormat: phoneExample });
  return t("fieldRequired");
};

const nameMaxLength = 50;
const validationRules = {
  name: { required, maxLength: maxLength(nameMaxLength) },
  phone: { required, custom: () => phoneIsValid.value },
  referral: {
    required
  },
  referralExtra: {
    // custom: (value: string) => (referralsWithExtra.includes(selectedReferral.value?.key) ? value !== "" : true),
  }
};

const v$ = useVuelidate(validationRules, formData);

const submitting = ref(false);
const updateUserData = async () => {
  // Fields validation
  const validation = await v$.value.$validate();
  if (!validation) return;

  submitting.value = true;

  await userStore.setUserData({
    timezone: browserTimezone?.key,
    name: formData.name
  });

  const formattedPhoneInternational =
    parsePhoneNumber(formData.phone, defaultCountry.value)?.formatInternational() ?? formData.phone;

  Intercom.update({
    name: formData.name,
    phone: formattedPhoneInternational,
    signup_attribution: formData.referral,
    signup_attribution_details: formData.referralExtra
  });

  await accountFeatures.put({
    key: "FIRST_LOGIN",
    value: "0"
  });

  userActivityApp.submit({
    action: `account.created`
  });

  await sessionStore.fetchSession();

  trackingEventsService.dispatchAll({ name: "APP_SIGNUP_CONFIRMED" });

  if (window?.dataLayer !== undefined && window?.dataLayer.push !== undefined) {
    window?.dataLayer?.push({ event: "signup.completed" });
  }
  if (window?.tap !== undefined && accountStore.accountData?.code) {
    window?.tap("trial", accountStore.accountData.code);
  }

  submitting.value = false;

  close();
};

watch(
  [() => accountStore.accountConfigData],
  () => {
    const parsedPhone = parsePhoneNumber(accountStore.accountConfigData?.phone ?? "");
    if (!parsedPhone) return;
    countryDetecteFromPhone.value = true;

    formData.phone = parsedPhone.formatNational();
    defaultCountry.value = parsedPhone?.country ?? defaultCountry.value;
  },
  { immediate: true }
);

watch(
  [() => userStore.userData],
  () => {
    const storedName = userStore.userData?.name ?? "";
    const formattedStoredName = storedName.slice(0, nameMaxLength);

    formData.name = ["admin", "storeadmin"].includes(storedName.toLowerCase()) ? "" : formattedStoredName;
  },
  { immediate: true }
);

onMounted(async () => {
  if (countryDetecteFromPhone.value) {
    await userStore.fetchUserData();
  } else {
    await Promise.all([userStore.fetchUserData(), detectCountry()]);
  }
});
</script>

<i18n lang="json">
{
  "es": {
    "title": "Cuéntanos un poco mas de ti",
    "subtitle": "Completa los siguientes datos para avanzar con la creación de tu cuenta y personalizar tu experiencia.",
    "nameLabel": "Nombre personal",
    "namePlaceholder": "Ingresa tu nombre",
    "nameHint": "Lo usaremos para comunicarnos de forma personalizada.",
    "phoneLabel": "Teléfono",
    "phoneHint": "Lo usaremos para asistirte en tus primeros pasos.",
    "referralLabel": "¿Cómo nos conociste?",
    "referralExtraLabel": "Cuéntanos más",
    "referralExtraPlaceholder": "agencia, canal, influencer, ...",
    "fieldRequired": "Este campo es requerido",
    "save": "Comenzar",
    "invalidPhoneError": "El formato es invalido. Formato: {phoneFormat}",
    "maxLengthError": "Máximos {length} caracteres",
    "referral": {
      "empty": "Selecciona una opción",
      "appstore": "Por mi plataforma de ecommerce",
      "agency": "Recomendación de mi agencia",
      "user": "Utilicé Perfit en otra ocasión",
      "instagram": "Instagram",
      "youtube": "Youtube",
      "tiktok": "Tiktok",
      "footer": "Recibí un email con el pie de Perfit",
      "search": "Buscando en Google",
      "other": "Otra fuente"
    }
  },
  "pt": {
    "title": "Queremos saber mais sobre você",
    "subtitle": "Complete os seguintes dados para avançar na criação da sua conta e personalizar sua experiência.",
    "nameLabel": "Nome pessoal",
    "namePlaceholder": "Insira seu nome",
    "nameHint": "Vamos usar isso para nos comunicarmos de forma personalizada.",
    "phoneLabel": "Telefone",
    "phoneHint": "Vamos usar isso para ajudá-lo nos primeiros passos.",
    "referralLabel": "Como nos conheceu?",
    "referralExtraLabel": "Conte-nos mais",
    "referralExtraPlaceholder": "agência, canal, influenciador, ...",
    "fieldRequired": "Este campo é obrigatório",
    "save": "Começar",
    "invalidPhoneError": "O formato é inválido. Formato: {phoneFormat}",
    "maxLengthError": "Máximo de {length} caracteres",
    "referral": {
      "empty": "Selecione uma opção",
      "appstore": "Por meio da minha plataforma de e-commerce",
      "agency": "Recomendação da minha agência",
      "user": "Usei Perfit em outra ocasião",
      "instagram": "Instagram",
      "youtube": "Youtube",
      "tiktok": "Tiktok",
      "footer": "Recebi um e-mail com o rodapé da Perfit",
      "search": "Pesquisando no Google",
      "other": "Outra fonte"
    }
  }
}
</i18n>
