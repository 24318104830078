// API
import { useRequests } from "@api/requests";

// Types
import type { UserService } from "@application/ports";
import type {
  CreateUserAPIData,
  CreateAPIResponse,
  CreateUserErrorAPIResponse,
  GetUserAPIResponse,
  GetUsersAPIResponse,
  UserUpdateAPIData,
  UpdateUserAPIResponse,
} from "./user.types";

// Utils
import { ok, err } from "neverthrow";
import { ensureAxiosError } from "@/vue/helpers/error";

export const useUserService = (): UserService => {
  const requestAPIV2 = useRequests();

  const userService: UserService = {
    async getUsers() {
      const res = await requestAPIV2<GetUsersAPIResponse>({
        url: "/users",
        method: "get",
        disableNotifyErrors: true,
      });

      return ok(
        res.data.map((user) => ({
          created: user.created,
          disabled: user.disabled,
          email: user.email,
          language: user.language,
          lastLogin: user.lastLogin,
          main: user.main,
          name: user.name,
          passLastChanged: user.passLastChanged,
          passNeverExpires: user.passNeverExpires,
          permissions: user.permissions,
          role: user.role,
          timezone: user.timezone,
          username: user.username,
        })),
      );
    },
    async get() {
      const res = await requestAPIV2<GetUserAPIResponse>({
        url: "/users/me",
        method: "get",
      });

      return ok({
        created: res.data.created,
        disabled: res.data.disabled,
        email: res.data.email,
        language: res.data.language,
        lastLogin: res.data.lastLogin,
        main: res.data.main,
        name: res.data.name,
        passLastChanged: res.data.passLastChanged,
        passNeverExpires: res.data.passNeverExpires,
        permissions: res.data.permissions,
        role: res.data.role,
        timezone: res.data.timezone,
        username: res.data.username,
      });
    },
    async put(params) {
      const res = await requestAPIV2<UpdateUserAPIResponse, UserUpdateAPIData>({
        url: "/users/me",
        method: "put",
        data: {
          language: params.language,
          name: params.name,
          timezone: params.timezone,
        },
      });

      return ok(res.data);
    },
    async create(params) {
      try {
        const res = await requestAPIV2<CreateAPIResponse, CreateUserAPIData>({
          url: "/users",
          method: "post",
          disableNotifyErrors: true,
          data: {
            email: params.email,
            name: params.name,
            passNeverExpires: params.passNeverExpires ?? false,
            reportMonthly: params.reportMonthly ? "1" : "0",
            reportWeekly: params.reportWeekly ? "1" : "0",
            role: params.role,
            username: params.username,
          },
        });

        return ok({
          created: res.data.created,
          disabled: res.data.disabled,
          email: res.data.email,
          language: res.data.language,
          lastLogin: res.data.lastLogin,
          main: res.data.main,
          name: res.data.name,
          passLastChanged: res.data.passLastChanged,
          passNeverExpires: res.data.passNeverExpires,
          permissions: res.data.permissions,
          role: res.data.role,
          timezone: res.data.timezone,
          username: res.data.username,
        });
      } catch (e) {
        const error = ensureAxiosError(e);

        const data = error.response?.data as CreateUserErrorAPIResponse;

        if (data.error.type === "RESOURCE_EXISTS") {
          return err({
            type: "DUPLICATED",
          });
        }

        return err({
          type: "UNKNOWN",
        });
      }
    },
  };

  return userService;
};
