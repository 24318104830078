<!--
TODO:
- disabled no deberia poner en disabled el button, no es bueno para la accesibildad. Pero deberia evitarse que el boton mande eventos
-->
<template>
  <button ref="buttonRef" type="button" :class="buttonClass" :disabled="disabled" :aria-disabled="disabled">
    <span class="sr-only">{{ label }}</span>
    <span :class="[size === 'base' && 'h-6 w-6', 'block']" aria-hidden="true">
      <slot></slot>
    </span>
  </button>
</template>

<script lang="ts" setup>
import { onMounted, ref, computed, watchEffect } from "vue";

import { useTooltip } from "@composables/tooltips";

import type { Placement, Modifier } from "@popperjs/core";

// Props
const props = withDefaults(
  defineProps<{
    label: string;
    disabled?: boolean;
    showTooltip?: boolean;
    tooltipProps?: Record<string, any> | null;
    colored?: boolean;
    showFocus?: boolean;
    size?: "min" | "base";
    theme?: "none" | "default" | "info" | "success" | "warning" | "danger";
    placement?: Placement;
    autoPlacements?: Array<Placement>;
    modifiers?: Array<Partial<Modifier<any, any>>>;
  }>(),
  {
    disabled: false,
    showTooltip: true,
    tooltipProps: null,
    colored: false,
    showFocus: true,
    size: "base",
    theme: "default",
    placement: "bottom",
    autoPlacements: () => ["bottom", "top", "bottom-end", "top-end"],
    modifiers: undefined
  }
);

const buttonRef = ref();
defineExpose({
  buttonRef
});

const getButtonTooltip = () => {
  return useTooltip(
    buttonRef,
    computed(() => props.label),
    {
      placement: props.placement,
      popperOptions: {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              tether: false,
              boundary: "clippingParents"
            }
          },
          {
            name: "flip",
            options: {
              allowedAutoPlacements: props.autoPlacements,
              fallbackPlacements: props.autoPlacements,
              altBoundary: true
            }
          },
          ...(props.modifiers ?? [])
        ]
      },
      ...props.tooltipProps
    }
  );
};

const tooltip = ref<ReturnType<typeof getButtonTooltip> | undefined>();
onMounted(() => {
  if (props.showTooltip) {
    tooltip.value = getButtonTooltip();
  }
});

watchEffect(() => {
  if (!tooltip.value) return;

  if (props.showTooltip) {
    tooltip.value.enable();
  } else {
    tooltip.value.disable();
  }
});

const buttonClass = computed(() => {
  const classes: Array<string> = [];
  const { colored, disabled, theme, showFocus } = props;

  classes.push("p-1 rounded-md focus:outline-none");

  if (showFocus) {
    classes.push("focus:ring-2  focus:ring-sky-300");
  }

  if (theme !== "none") {
    classes.push("bg-white");
  }

  if (disabled) {
    classes.push("text-gray-200 cursor-default");
  } else {
    if (!colored) classes.push("text-gray-400");
    if (theme === "default") {
      classes.push("hover:text-gray-600");
    } else if (theme === "danger") {
      if (colored) classes.push("text-red-400");
      classes.push("hover:text-red-600");
    } else if (theme === "warning") {
      if (colored) classes.push("text-yellow-400");
      classes.push("hover:text-yellow-600");
    } else if (theme === "info") {
      if (colored) classes.push("text-blue-400");
      classes.push("hover:text-blue-600");
    } else if (theme === "success") {
      if (colored) classes.push("text-green-400");
      classes.push("hover:text-green-600");
    }
  }

  return classes.join(" ");
});
</script>
