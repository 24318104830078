<template>
  <form class="space-y-6" @submit.prevent="onSubmit">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <FormGroup
        id="card"
        v-slot="{ describedBy }"
        :label="t('card.label')"
        :hint="t('card.hint')"
        :error="
          v$.number.$errors[0]?.$message ||
          v$.expiration.$errors[0]?.$message ||
          v$.cvv.$errors[0]?.$message
        "
      >
        <CreditCardInput
          ref="creditCardInputRef"
          v-model:number="number"
          v-model:expiration="expiration"
          v-model:cvv="cvv"
          :number-error="!!v$.number.$errors[0]?.$message"
          :exp-error="!!v$.expiration.$errors[0]?.$message"
          :cvv-error="!!v$.cvv.$errors[0]?.$message"
          :aria-describedby="describedBy"
          @blur:number="onCardChange"
        />
      </FormGroup>

      <FormInput
        v-model="name"
        autocomplete="cc-name"
        :label="t('name.label')"
        :placeholder="t('name.placeholder')"
        :hint="t('name.hint')"
        :error="v$.name.$errors[0]?.$message"
      />

      <FormGroup
        id="document"
        v-slot="{ describedBy, hasError }"
        :label="t('docNumber.label')"
        :hint="t('docNumber.hint')"
        :error="v$.docNumber.$errors[0]?.$message || v$.docType.$errors[0]?.$message"
      >
        <InputWithDropdown
          id="document"
          v-model:value.numbersOnly="docNumber"
          v-model:option="docType"
          :placeholder="t('docNumber.placeholder')"
          :aria-describedby="describedBy"
          :options="docTypes"
          :has-error="hasError"
        ></InputWithDropdown>
      </FormGroup>

      <FormInput
        v-model.lowercase="email"
        :label="t('email.label')"
        :placeholder="t('email.placeholder')"
        :hint="t('email.hint')"
        :error="v$.email.$errors[0]?.$message"
      />
    </div>
    <AlertBox v-if="submitError" theme="warning">
      {{ submitError }}
      <template #actions>
        <a href="#" @click.prevent="contactSupport">{{ t("notify.contactSupport") }}</a>
      </template>
    </AlertBox>
    <div class="flex justify-between mt-8 space-x-10">
      <TextParagraph>
        {{ t("acceptTerms") }}
      </TextParagraph>

      <div class="flex flex-shrink-0 justify-end space-x-4 items-start">
        <SimpleButton v-if="cancelable" theme="white" @click="$emit('cancel')">
          {{ t("cancel") }}
        </SimpleButton>
        <SimpleButton theme="primary" type="submit" :loading="isValidating">
          <template #leading><LockClosedIcon /></template>
          {{ t("saveCard") }}
        </SimpleButton>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { ref, reactive, toRefs, defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { LockClosedIcon } from "@heroicons/vue/solid";

import Intercom from "@helpers/intercom";
import { required, email, maxLength, minLength } from "@helpers/validators";
import { useCreditCards } from "@api/modules/creditcards";
import { useNotifications } from "@composables/notifications";
import { ICreditCardMercadoPagoCreate } from "@api/models/CreditCard";

import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import InputWithDropdown from "@atoms/InputWithDropdown.vue";
import CreditCardInput from "@molecules/CreditCardInput.vue";
import AlertBox from "@atoms/AlertBox.vue";
import FormInput from "@molecules/FormInput.vue";
import FormGroup from "@molecules/FormGroup.vue";

declare global {
  interface Window {
    MercadoPago: any;
    config: any;
  }
}

export default defineComponent({
  name: "CreditCardValidatorMercadoPago",
  components: {
    FormInput,
    SimpleButton,
    LockClosedIcon,
    FormGroup,
    InputWithDropdown,
    CreditCardInput,
    AlertBox,
    TextParagraph,
  },
  props: {
    cancelable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cardCreated", "cancel"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { notify } = useNotifications();
    const CreditCards = useCreditCards();
    const creditCardInputRef = ref<InstanceType<typeof CreditCardInput>>();

    const mp = new window.MercadoPago(
      window.config?.mpKey || "TEST-c2683fcf-b715-42e6-b8d9-8321256f5095",
      {
        locale: "es-AR",
      }
    );

    const docTypes = ref<{ id: string; name: string; maxLength: number; minLength: number }[]>([]);
    mp.getIdentificationTypes().then((res) => {
      docTypes.value = res.map((t: any) => ({
        id: t.id,
        name: t.name,
        maxLength: t.max_length,
        minLength: t.min_length,
      }));
      state.docType = docTypes.value[0].id;
    });

    const selectedDocType = computed(() => {
      return docTypes.value.find((d) => d.id === state.docType);
    });

    const initialState = {
      number: "",
      expiration: "",
      cvv: "",
      name: "",
      docType: "",
      docNumber: "",
      email: "",
      paymentMethodId: "",
      issuerId: "",
    };
    const state = reactive({ ...initialState });

    const rules = computed(() => ({
      number: {
        required: required(t("number.errors.required")),
        valid: helpers.withMessage(
          t("number.errors.invalid"),
          () =>
            !!creditCardInputRef.value?.cardValidation?.isValid &&
            !!state.paymentMethodId &&
            !!state.issuerId
        ),
      },
      expiration: {
        required: required(t("expiration.errors.required")),
        valid: helpers.withMessage(
          t("expiration.errors.invalid"),
          () => !!creditCardInputRef.value?.expValidation?.isValid
        ),
      },
      cvv: {
        required: required(t("cvv.errors.required")),
        valid: helpers.withMessage(
          t("cvv.errors.invalid"),
          () => !!creditCardInputRef.value?.cvvValidation?.isValid
        ),
      },
      name: {
        required: required(t("name.errors.required")),
      },
      email: {
        required: required(t("email.errors.required")),
        email: email(t("email.errors.email")),
      },
      docType: {
        required: required(t("docType.errors.required")),
      },
      docNumber: {
        required: required(t("docNumber.errors.required")),
        maxLength: maxLength(selectedDocType.value?.maxLength || 20, t("docNumber.errors.invalid")),
        minLength: minLength(selectedDocType.value?.minLength || 1, t("docNumber.errors.invalid")),
      },
    }));
    const v$ = useVuelidate(rules, state);

    const isValidating = ref(false);

    const onCardChange = async () => {
      const number = state.number.replaceAll(/[^\d]/g, "");
      if (number.length < 6) return;
      const firstSix = number.substring(0, 6);

      const paymentMethodsRes = await mp.getPaymentMethods({ bin: firstSix });
      const payMethod = paymentMethodsRes.results[0];

      state.paymentMethodId = payMethod?.id;
      state.issuerId = payMethod?.issuer?.id;
    };

    const submitError = ref("");
    const onSubmit = async () => {
      if (isValidating.value) return;
      v$.value.$touch();
      if (v$.value.$error) return;

      submitError.value = "";

      try {
        isValidating.value = true;
        await onCardChange();

        const [expMonth, expYear] = state.expiration.split("/").map((s) => s.trim());
        const tokenizeInfo = {
          cardNumber: state.number.replaceAll(/\s/g, ""),
          cardholderName: state.name,
          cardExpirationMonth: expMonth,
          cardExpirationYear: expYear,
          securityCode: state.cvv,
          identificationType: state.docType,
          identificationNumber: state.docNumber,
        };

        const token = await mp.createCardToken(tokenizeInfo);

        const createMP: ICreditCardMercadoPagoCreate = {
          token: token.id,
          cvv: state.cvv,
          issuer_id: state.issuerId,
          payment_method_id: state.paymentMethodId,
          email: state.email,
          name: state.name,
          doc_type: state.docType,
          doc_number: state.docNumber,
        };

        const newCard = await CreditCards.createMercadoPago(createMP);

        notify({
          title: t("notify.success"),
        });

        emit("cardCreated", newCard);
      } catch (error: any) {
        console.error(error);
        if (!(error.response || error.request) || error.response?.status === 400) {
          submitError.value = t("notify.error");
        }
      } finally {
        isValidating.value = false;
      }
    };

    const contactSupport = () => {
      Intercom.showNewMessage(t(`notify.helpMessage`));
    };

    return {
      t,
      v$,
      ...toRefs(state),
      onSubmit,
      onCardChange,
      isValidating,
      docTypes,
      creditCardInputRef,
      selectedDocType,
      submitError,
      contactSupport,
    };
  },
});
</script>

<i18n lang="json">
{
  "es": {
    "saveCard": "Guardar tarjeta",
    "acceptTerms": "Al hacer click en \"Guardar tarjeta\", autorizas a Perfit a realizar cobros a la tarjeta ingresada de acuerdo a los términos de servicio del plan contratado. Todo los cobros se realizan en forma segura a través de Mercado Pago.",
    "notify": {
      "success": "Tarjeta almacenada con éxito.",
      "error": "No pudimos validar la tarjeta ingresada. Verifica que todos los datos sean correctos y vuelve a intentar.",
      "contactSupport": "Si sigues con problemas contactános.",
      "helpMessage": "Hola, estoy intentando guardar los datos de mi tarjeta para realizar un pago, pero veo un error de validación. Podrían ayudarme?"
    },
    "name": {
      "label": "Nombre y apellido",
      "placeholder": "Juan Pérez",
      "hint": "Nombre del titular, tal como figura en la tarjeta.",
      "errors": {
        "required": "Ingresa el nombre tal como figura en la tarjeta."
      }
    },
    "email": {
      "label": "Dirección de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Casilla donde recibirás las notificaciones de pagos.",
      "errors": {
        "required": "Debes ingresar la casilla donde recibir las notificaciones.",
        "email": "La dirección de email ingresada es inválida."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo y número de documento del titular.",
      "errors": {
        "required": "Ingresa el tipo y número de documento del titular.",
        "invalid": "El número documento ingresado es inválido."
      }
    },
    "card": {
      "label": "Datos de la tarjeta",
      "hint": "Número, vencimiento y código de seguridad."
    },
    "number": {
      "errors": {
        "required": "Debes ingresar el número de tarjeta.",
        "invalid": "El número de tarjeta ingresado es inválido."
      }
    },
    "expiration": {
      "errors": {
        "required": "Debes ingresar la fecha de vencimiento.",
        "invalid": "La fecha de vencimiento ingresada es inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Debes ingresar el código de seguridad.",
        "invalid": "El código de seguridad ingresado es inválido."
      }
    }
  },
  "pt": {
    "saveCard": "Salvar cartão",
    "acceptTerms": "Ao clicar em \"Salvar cartão\", você autoriza Perfit a realizar cobranças no cartão cadastrado conforme os termos e condições de serviço do plano contratado. Todas as cobranças serão realizadas de forma segura a través de Mercado Pago.",
    "notify": {
      "success": "Cartão salvo com sucesso.",
      "error": "Não foi possível validar o cartão cadastrado. Verifique que todos os dados sejam corretos e tente novamente.",
      "contactSupport": "Se o problema persistir, entre em contato conosco.",
      "helpMessage": "Olá, estou tentando cadastrar meu cartão para realizar um pagamento, mas vejo uma mensagem de erro de validação. Podem me ajudar?"
    },
    "name": {
      "label": "Nome e sobrenome",
      "placeholder": "Maria Souza",
      "hint": "Nome do titular, como está escrito no cartão.",
      "errors": {
        "required": "Insira o nome como está escrito no cartão."
      }
    },
    "email": {
      "label": "Endereço de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Endereço onde você receberá as notificações de pagamento.",
      "errors": {
        "required": "Você deve cadastrar um email para receber as notificações.",
        "email": "O endereço de email inserido é inválido."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo e número de documento do titular.",
      "errors": {
        "required": "Insira o tipo e número de documento do titular.",
        "invalid": "O número do documento inserido é inválido."
      }
    },
    "card": {
      "label": "Dados do cartão",
      "hint": "Número, vencimento e código de segurança."
    },
    "number": {
      "errors": {
        "required": "Você deve inserir o número do cartão.",
        "invalid": "O número do cartão inserido é inválido."
      }
    },
    "expiration": {
      "errors": {
        "required": "Você deve inserir a data de validade.",
        "invalid": "A data de validade inserida é inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Você deve inserir o código de segurança.",
        "invalid": "O código de segurança inserido é inválido."
      }
    }
  }
}
</i18n>
