// API
import { useRequests } from "@api/requests";

// Types
import type { SessionService } from "@application/ports";
import type { GetSessionAPIResponse } from "./session.types";

// Utils
import { ok } from "neverthrow";
import axios from "axios";

const sessionInstance = axios.create({
  timeout: 20000,
});

export const useSessionService = (): SessionService => {
  const requestAPIV2 = useRequests();

  const sessionService: SessionService = {
    async get() {
      const res = await requestAPIV2<GetSessionAPIResponse>({
        url: `/session`,
        method: "get",
        disableAccountPrefix: true,
      });

      return ok(res.data);
    },
    async getWithToken(params) {
      const res = await sessionInstance.request<GetSessionAPIResponse>({
        method: "get",
        url: `${window.config.api}/session`,
        headers: {
          "x-account": params.account,
          "X-Auth-Token": params.token,
          "Accept-Language": params.lang,
        },
      });

      return ok(res.data.data);
    },
  };

  return sessionService;
};
