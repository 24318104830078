<template>
  <input
    ref="CheckBoxComponent"
    type="checkbox"
    :class="[
      {
        'border-red-500 bg-red-50 text-red-500 focus:ring-sky-500': error,
        'bg-neutral-100 text-neutral-300': disabled && !error,
        'border-neutral-300 text-sky-500 focus:ring-sky-500': !disabled && !error,
      },
      ' rounded',
    ]"
    :disabled="disabled"
    :value="modelValue"
    :checked="modelValue"
    @change="onChange"
    @click="onClick"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Props
withDefaults(
  defineProps<{
    disabled?: boolean;
    modelValue: boolean;
    error?: boolean;
  }>(),
  {
    disabled: false,
    error: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [boolean];
  change: [Event];
}>();

const CheckBoxComponent = ref<HTMLInputElement | null>(null);

defineExpose({
  CheckBoxComponent,
});

//Emits
const onChange = (event: Event) => {
  emit("change", event);
};

const onClick = (event: Event) => {
  event.preventDefault();
  setTimeout(() => {
    emit("update:modelValue", !(event.target as HTMLInputElement).checked);
  });
};
</script>

<style scoped>
input[type="checkbox"]:focus {
  outline-offset: 0;
  outline: none;
}
</style>
