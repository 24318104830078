<template>
  <div class="h-full">
    <Table
      :sort-by="sortBy"
      :columns="columns"
      :rows="rows"
      :selected-rows="selectedRows"
      :skeleton="skeletonTable"
      :skeleton-count="5"
      :selectable="optinsUpdate"
      class="ml-6 h-full overflow-x-hidden pb-10"
      @update:sort-by="updateSortBy"
      @update:selected-rows="updateSelectedRows"
    >
      <template #name="{ row }">
        <NameCell v-if="row" :id="row.id">
          {{ row.content?.name }}
        </NameCell>
      </template>
      <template #name-skeleton>
        <div class="my-4 w-40 animate-pulse rounded bg-sky-50" />
      </template>
      <template #defaultCell="{ row, item }">
        <div v-if="row" class="flex w-full justify-center pr-5 text-sm text-gray-500">
          <span class="w-full shrink-0 grow">
            {{ n(Number(item) ?? 0, "decimal") }}
          </span>
        </div>
      </template>
      <template #defaultCell-skeleton>
        <div class="my-4 ml-auto mr-5 w-40 rounded-md bg-gray-50 py-2" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="listOptions"
            placement="bottom-end"
            theme="transparent-white"
            :auto-placements="['bottom-end', 'left', 'top-end']"
            :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="flex justify-center">
          <div class="my-4 w-4 animate-pulse rounded-md bg-gray-50 py-2" />
        </div>
      </template>
    </Table>
    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selectedLists",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(totalCount) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
        </template>
        <IconButton
          v-if="selectedRows?.length < totalCount"
          :label="t('selectAllButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="selectAll"
        >
          <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
      <div class="flex space-x-4">
        <PermissionsIconButton
          :permissions="['optins:delete']"
          :label="t('deleteSelectedButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </PermissionsIconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteField"
      @cancel="closeDeleteListAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteFontAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, toRaw } from "vue";

import type { ColumnsIds, OptinsOptionsKeys, OptinsRows, OptinsRow } from "../../optins.types";
import { getListOptions } from "../../optins.data";

// Components
import NameCell from "./components/NameCell.vue";
import Table from "@molecules/Table";
import type { Columns, SortColumn } from "@molecules/Table";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";

// Icon
import { DocumentIcon, DocumentDuplicateIcon, TrashIcon, XIcon } from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useSessionStore, useAlertStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Service
import { useOptins } from "@api/modules/optins/optins";

const { t, n } = useI18n();
const optinsAPI = useOptins();
const { notify } = useNotifications();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const optinsUpdate = sessionStore.hasPermission("optins:update");

const props = withDefaults(
  defineProps<{
    skeletonTable: boolean;
    sortBy: SortColumn<ColumnsIds>;
    columns: Columns<ColumnsIds | "options", ColumnsIds>;
    rows: OptinsRows;
    fetchLimit: number;
    totalCount: number;
  }>(),
  {},
);

const emit = defineEmits<{
  fetchOptins: [void];
  "update:rows": [OptinsRows];
  "update:sortBy": [SortColumn<ColumnsIds | "options">];
  "update:totalCount": [number];
}>();

const updateSortBy = (newSortBy: SortColumn<ColumnsIds | "options">) => {
  emit("update:sortBy", newSortBy);
};

// Table
const selectedRows = ref<OptinsRows>([]);
const selectedRow = ref<OptinsRow>();
const updateSelectedRows = (newRows: OptinsRows) => {
  selectedRows.value = newRows;
};
const clearSelected = () => {
  selectedRows.value = [];
};
const selectAll = () => {
  selectedRows.value = props.rows;
};

const removeRows = (rowsToDelete: OptinsRows) => {
  const filteredRows = toRaw(props.rows).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  emit("update:rows", filteredRows);
};

const removeRow = (rowToDelete: OptinsRow) => {
  const filteredRows = toRaw(props.rows).filter((row) => {
    return row.id !== rowToDelete.id;
  });
  emit("update:rows", filteredRows);
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteField = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await optinsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;
  } finally {
    emit("update:totalCount", props.totalCount - 1);
    removeRow(rowToDelete);
    if (props.rows.length < props.fetchLimit && props.totalCount >= props.fetchLimit) {
      emit("fetchOptins");
    }
    closeDeleteListAlertModal();
    notify({
      title: t("notifications.delete.title"),
      text: t("notifications.delete.text"),
      theme: "success",
    });
  }
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) => optinsAPI.delete({ id: selected.id }));
    await Promise.all(deletePromises);
  } finally {
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    emit("update:totalCount", props.totalCount - selectedRows.value.length);
    removeRows(selectedRows.value);
    if (props.rows.length < props.fetchLimit && props.totalCount >= props.fetchLimit) {
      emit("fetchOptins");
    }
    clearSelected();
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

// Search
const listOptions = getListOptions();
const listOptionHandler: Record<OptinsOptionsKeys, (row: OptinsRow) => void> = {
  delete: (row) => {
    if (!sessionStore.hasPermission("optins:delete")) {
      alertStore.showPermissionDenied(["optins:delete"]);
      return;
    }

    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: OptinsRow) => await listOptionHandler[option.key]?.(row);
</script>

<i18n lang="jsonc">
{
  "es": {
    "selectedLists": "1 formulario seleccionado de {total} | {count} formularios seleccionados de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Eliminar",
    "selectAllButton": "Seleccionar todo",
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar formulario",
      "confirmationMessage": "Se eliminará 1 formulario seleccionado. ¿Deseas continuar? | Se eliminaran {count} formularios seleccionados. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar formulario",
      "confirmationMessage": "Se eliminará el formulario {name}. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Formulario eliminado",
        "text": "El formulario se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Formularios eliminados",
        "text": "Los formularios se eliminaron correctamente."
      }
    },
    "listOptions": {
      "delete": "Eliminar"
    }
  },
  "pt": {
    "selectedLists": "1 formulário selecionado de {total} | {count} formulários selecionados de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Excluir",
    "selectAllButton": "Selecionar tudo",
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir formulário",
      "confirmationMessage": "Será excluído 1 formulário selecionado. Deseja continuar? | Serão excluídos {count} formulários selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir formulário",
      "confirmationMessage": "O formulário {name} será excluído. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Formulário excluído",
        "text": "O formulário foi excluído com sucesso."
      },
      "deleteBulk": {
        "title": "Formulários excluídos",
        "text": "Os formulários foram excluídos com sucesso."
      }
    },
    "listOptions": {
      "delete": "Excluir"
    }
  }
}
</i18n>
