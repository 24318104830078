<template>
  <div class="mb-6 flex justify-between">
    <div class="flex">
      <FeedActivityContactSelector v-model:selected="selectedOption" class="mr-4 w-96" />
      <ToggleButtons
        v-if="isOnlySale && (loadingActivity || (!noActivity && !noFilterResult))"
        v-model="selectedToggle"
        class="mr-4"
        :buttons="[
          {
            id: 'allSales',
            label: t('toggle.allSales'),
          },
          {
            id: 'onlyAssisted',
            label: t('toggle.onlyAssisted'),
          },
        ]"
      />
    </div>
    <PeriodSelectorMenu
      v-model:from="periodFrom"
      v-model:to="periodTo"
      :selected-preset-id="selectedPresetID"
      :options="['all', 'last7d', 'last30d', 'last90d', 'custom']"
      class="w-full sm:w-min"
      @update:selectedPresetID="UpdatePresetID"
    />
  </div>
  <div class="flow-root h-full pr-2">
    <ul v-if="!loadingActivity" role="list" class="-mb-8">
      <li v-for="(item, index) in activityItemsInUse" :key="index">
        <div class="relative pb-8">
          <span
            v-if="index !== activityItemsInUse.length - 1"
            class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex space-x-3">
            <div>
              <span
                :style="{ background: item.bgColor, borderColor: item.iconColor || '#FFF' }"
                :class="{ 'border-2': item.iconColor }"
                class="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white"
              >
                <component
                  v-if="item.icon"
                  :is="item.icon"
                  :style="{ color: item.iconColor || '#FFF' }"
                  class="h-5 w-5"
                  aria-hidden="true"
                />
                <svg
                  v-if="!item.icon"
                  fill="#fff"
                  height="1.25rem"
                  width="1.25rem"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 308 308"
                  xml:space="preserve"
                >
                  <g id="XMLID_468_">
                    <path
                      id="XMLID_469_"
                      d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
                    />
                    <path
                      id="XMLID_470_"
                      d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div class="space-y-2">
                <p class="text-sm text-gray-500">
                  {{ item.title }}
                  <a
                    v-if="item.href?.link"
                    :href="item.href?.link"
                    :target="item.href?.target"
                    class="font-medium text-sky-500"
                    >{{ item.href?.text }}
                  </a>
                  <span v-if="item.title2">{{ item.title2 }}</span>
                  <a v-if="item.title2" :href="item.href2?.link" class="font-medium text-sky-500"
                    >{{ item.href2?.text }}
                  </a>
                </p>
                <div v-if="item.preferredProducts.length > 0" class="flex items-center gap-x-2">
                  <IconBullet
                    :icon="CubeIcon"
                    :tooltip="t('tooltip.preferredProducts')"
                    class="h-7 w-7 text-gray-500"
                  />
                  <a v-for="product in item.preferredProducts" :key="product.id" :href="product.url" target="_blank">
                    <SimpleBadge theme="gray">{{ product.name }}</SimpleBadge>
                  </a>
                </div>
                <div v-if="item.preferredCategories.length > 0" class="flex items-center gap-x-2">
                  <IconBullet
                    :icon="CollectionIcon"
                    :tooltip="t('tooltip.preferredCategories')"
                    class="h-7 w-7 text-gray-500"
                  />
                  <a
                    v-for="category in item.preferredCategories"
                    :key="category.id"
                    :href="category.url"
                    target="_blank"
                  >
                    <SimpleBadge theme="gray">{{ category.name }}</SimpleBadge>
                  </a>
                </div>
                <div v-if="item.attributionOrder?.type" class="flex items-center">
                  <IconBullet color="red" :icon="BadgeCheckIcon" />
                  {{ item.attributionOrder.text }}
                  <a class="font-medium text-sky-500" :href="item.attributionOrder.link" target="_blank">
                    <span class="ml-1"> {{ item.attributionOrder.type }}</span
                    >.
                  </a>
                </div>

                <div v-if="item.viewOnline" class="flex items-center gap-x-2">
                  <a :href="item.viewOnline.link" target="_blank">
                    <SimpleBadge theme="gray">{{ t("tooltip.viewContent") }}</SimpleBadge>
                  </a>
                </div>
              </div>

              <div class="flex text-right text-sm text-gray-500">
                <div>
                  <IconBullet
                    v-if="item.device?.icon"
                    :icon="item.device.icon"
                    :tooltip="item.device.format"
                    class="inline"
                  />
                </div>
                <div>
                  <IconBullet
                    v-if="item.location"
                    :icon="item.location.icon"
                    :tooltip="item.location.tooltip"
                    class="inline"
                  />
                </div>
                <span
                  :ref="(element) => activityDateRefTooltip({ element, text: item.createdFull })"
                  style="height: fit-content"
                  >{{ item.createdShort }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <FeedActivitySkeleton v-else />

    <div v-if="!loadingActivity && noFilterResult" class="ml-4 mt-8">
      <span
        >{{ t("emptyStates.noFilterResult") }}
        <a class="text-sky-500 underline" @click="cleanFilters">
          {{ t("emptyStates.cleanFilters") }}
        </a></span
      >
    </div>
    <div v-if="!loadingActivity && noActivity" class="ml-4 mt-8">
      <span> {{ t("emptyStates.noActivity") }} </span>
    </div>
    <div v-if="!loadingActivity && showFetch" class="mt-4 flex justify-center">
      <SimpleButton theme="secondary" :loading="!loadingActivity && loadingMore" @click="fetchNextActivity">
        <template #leading><PlusCircleIcon /></template> {{ t("button.more") }}</SimpleButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useTooltip } from "@composables/tooltips";
import { useActivityContactFeed } from "./FeedActivityContactFunctions";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import SimpleButton from "@atoms/SimpleButton.vue";
import IconBullet from "@atoms/IconBullet.vue";
import { BadgeCheckIcon, CollectionIcon, CubeIcon } from "@heroicons/vue/outline";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import FeedActivitySkeleton from "./FeedActivityContactSkeleton.vue";
import FeedActivityContactSelector from "./FeedActivityContactSelector.vue";
import PeriodSelectorMenu from "@molecules/PeriodSelectorMenu.vue";
import ToggleButtons from "@atoms/ToggleButtons.vue";

// Types
import { Activity } from "@domain/contacts";
import type { ActivityItem } from "./FeedActivityContactFunctions";
import type { ActivityContactOptionsTypes, SalesType, Activities } from "@/vue/types/contacts";

// Utils
import { useDebounceFn } from "@vueuse/core";

// API
import { useContacts } from "@/vue/api/modules/contacts/contacts";

// Props
const props = withDefaults(
  defineProps<{
    id: number;
  }>(),
  {},
);

// States
const selectedOption = ref<Array<ActivityContactOptionsTypes>>([]);
const loadingActivity = ref(false);
const loadingMore = ref(false);
const activityItems = ref<Activities>([]);
const totalActivityItems = ref(0);
const activityItemsInUse = computed<Array<ActivityItem>>(() => {
  const resultValue = activityItems.value.filter((item) => isInUse(item));

  const responseToItems = (response: Array<Activity>): Array<ActivityItem> => {
    return response.map<ActivityItem>((item) => translateEvent(item));
  };

  return responseToItems(resultValue);
});
const periodFrom = ref<Date>();
const periodTo = ref<Date>();
const showFetch = computed<boolean>(() => {
  return totalActivityItems.value > activityItems.value.length;
});
const selectedPresetID = ref<string | undefined>("all");
const selectedToggle = ref<SalesType>("allSales");

// Data
const { t } = useI18n();
const { isInUse, translateEvent } = useActivityContactFeed();
const contactsAPI = useContacts();

// Functions
const activityDateRefTooltip = ({ element, text }: { element: any; text: string }) => {
  if (!element || text === "" || text === undefined) return;
  useTooltip(element, text, { placement: "auto" });
};

const fetchNextActivity = async () => {
  loadingMore.value = true;
  try {
    const value = await contactsAPI.activityList({
      id: props.id,
      type: selectedOption.value,
      sale: selectedToggle.value,
      gt: periodFrom.value,
      lt: periodTo.value,
      limit: 10,
    });

    totalActivityItems.value = value.total ?? 0;

    activityItems.value = [...activityItems.value, ...value.value];
  } finally {
    loadingMore.value = false;
  }
};

const getActivity = async () => {
  activityItems.value = [];
  totalActivityItems.value = 0;
  resetAssistedToggle();
  await fetchNextActivity();
  loadingActivity.value = false;
};

const newGetActivityGenBehavior = useDebounceFn(getActivity, 1000);

watch([props.id, selectedOption, periodFrom, periodTo, selectedToggle], () => {
  loadingActivity.value = true;
  newGetActivityGenBehavior();
});

const noActivity = computed(() => {
  const isAll = selectedOption.value.every((option) => option === "all");
  if (isAll && selectedPresetID.value === "all" && activityItemsInUse.value.length === 0) return true;
  return false;
});

const noFilterResult = computed(() => {
  if (!noActivity.value && activityItemsInUse.value.length === 0) return true;
  return false;
});

const isOnlySale = computed(() => {
  return selectedOption.value.every((element) => element === "sale");
});

const resetAssistedToggle = () => {
  if (isOnlySale.value) return;
  selectedToggle.value = "allSales";
  return;
};

const cleanFilters = () => {
  selectedOption.value = ["all"];
  selectedPresetID.value = "all";
};

const UpdatePresetID = (presetID: string) => {
  selectedPresetID.value = presetID;
};
</script>

<i18n lang="json">
{
  "es": {
    "button": {
      "more": "Ver más"
    },
    "title": {
      "automation": {
        "open": "Abrió el automation",
        "sent": "Le enviaste el automation",
        "triggered": "Se disparó el automation",
        "viewOnline": "Visitó la versión online del automation"
      },
      "bounced": {
        "hard": "Rebotó de forma definitiva (hard bounce) el envío de la campaña ",
        "soft": "Rebotó en forma temporal (soft bounce) el envío de la campaña "
      },
      "campaign": {
        "open": "Abrió la campaña",
        "sent": "Le enviaste la campaña",
        "viewed_online": "Visitó la versión online de la campaña",
        "clicked": "Hizo click en el link",
        "bounced": "Rebotó",
        "unsubscribed": "Se desuscribió indicando la razón",
        "viewOnline": "Visitó la versión online de la campaña"
      },
      "click": {
        "campaign": " de la campaña ",
        "automation": " del automation ",
        "done": " Hizo click en el link"
      },
      "ecommerce": {
        "cart": {
          "created": "Inició un nuevo carrito en "
        },
        "order": {
          "completed": "Completó una compra en ",
          "total": " por un total de ",
          "attribution": {
            "automation": "un automation",
            "campaign": "una campaña",
            "click": "hacer click en",
            "open": "abrir",
            "title": "Asistida por Perfit al "
          }
        },
        "optin": {
          "confirmedApp": "Completó el formulario de Perfit ",
          "confirmedSource": "Completó el formulario de "
        },
        "session": {
          "finished": "Visitó tu sitio de ",
          "during": " durante ",
          "minutes": "minutos",
          "seen": "Estos son los productos y categorías más vistos:",
          "seconds": " segundos. "
        }
      },
      "integrations": {
        "fbleads": "Se suscribió a través de Facebook Lead.",
        "jivochat": "Finalizó una conversación a través de Jivochat."
      },
      "interest": {
        "added": "Ingresó el interés",
        "expired": "Expiró el interés ",
        "removed": "Salió del interés "
      },
      "list": {
        "added": "Ingresó a la lista ",
        "removed": "Salió de la lista "
      },
      "rfm": {
        "abouttolose": "Por perder",
        "atrisk": "En riesgo",
        "champion": "Campeones",
        "cantlose": "No se pueden perder",
        "highpotential": "Alto potencial",
        "in": "Ingresó a la etapa",
        "loyalist": "Leales",
        "neednutring": "Necesitan incentivo",
        "out": "Salió de la etapa",
        "recent": "Recientes"
      },
      "shared": {
        "to": "Compartió a través de ",
        "contain": " el contenido de la ",
        "campaign": "la campaña ",
        "automation": "del automation "
      },
      "unsubscribed": {
        "indicate": "Se desuscribió indicando la razón: ",
        "not_interested": "\"Ya no me interesa recibir estos correos\"",
        "too_frequent": "\"Recibo estos correos con demasiada frecuencia\"",
        "spam_never_subscribed": "\"Nunca me suscribí para recibir esto\"",
        "spam_offensive": "\"Recibí contenido ofensivo o engañoso\"",
        "spam_fbl": "\"Este correo es spam\"",
        "one_click": "\"Ya no quiero recibir estos envíos\"",
        "one_click_email": "\"Ya no quiero recibir estos envíos\"",
        "campaign": " de la campaña",
        "automation": " del automation,"
      },
      "whatsapp": {
        "clicked": "Hizo click en el mensaje por WhatsApp.",
        "opened": "Abrió el mensaje por WhatsApp.",
        "sent": "Le enviaste un mensaje por WhatsApp."
      }
    },
    "emptyStates": {
      "noFilterResult": "No se encontraron resultados con los filtros seleccionados.",
      "noActivity": "Este contacto no registra actividad en tu cuenta.",
      "cleanFilters": "Limpiar filtros"
    },
    "toggle": {
      "allSales": "Todas las ventas",
      "onlyAssisted": "Solo asistidas"
    },
    "tooltip": {
      "viewContent": "Ver contenido",
      "preferredCategories": "Categorías",
      "preferredProducts": "Productos"
    }
  },
  "pt": {
    "button": {
      "more": "Ver mais"
    },
    "title": {
      "automation": {
        "open": "Abriu o envio da automation",
        "sent": "Você enviou a automation",
        "triggered": "A automation foi disparada",
        "viewOnline": "Visitou a versão on-line da automation"
      },
      "bounced": {
        "hard": "Rejeitou de forma definitiva (hard bounce) o envio da campanha",
        "soft": "Rejeitou de forma temporária (soft bounce) o envio da campanha"
      },
      "campaign": {
        "open": "Abriu a campanha",
        "sent": "Você enviou a campanha",
        "clicked": "Clicou no link",
        "bounced": "Rejeitou",
        "unsubscribed": "Se descadastrou indicando o motivo",
        "viewOnline": "Visitou a versão on-line da campanha"
      },
      "click": {
        "campaign": " da campanha ",
        "automation": " da automation ",
        "done": " Clicou no link"
      },
      "ecommerce": {
        "cart": {
          "created": "Iniciou um novo carrinho de compras em "
        },
        "order": {
          "completed": "Completou uma compra em ",
          "total": " por um total de ",
          "attribution": {
            "automation": "um automation",
            "campaign": "uma campanha",
            "click": "clicar em",
            "open": "abrir",
            "title": "Assistida por Perfit ao "
          }
        },
        "optin": {
          "confirmedApp": "Completou o formulário de Perfit.",
          "confirmedSource": "Completou o formulário de "
        },
        "session": {
          "finished": "Visitou seu site da ",
          "during": " durante ",
          "minutes": "minutos",
          "seen": "Estas foram as categorias e os produtos mais vistos:",
          "seconds": " segundos. "
        }
      },
      "integrations": {
        "fbleads": "Inscreveu-se através do Facebook Lead.",
        "jivochat": "Encerrou uma conversa via Jivochat."
      },
      "interest": {
        "added": "Entrou no interesse ",
        "expired": "Saiu do interesse ",
        "removed": "Seu interesse expirou em "
      },
      "list": {
        "added": "Entrou na lista ",
        "removed": "Saiu da lista "
      },
      "rfm": {
        "abouttolose": "Por perder",
        "atrisk": "Em risco",
        "champion": "Campeões",
        "cantlose": "Não se podem perder",
        "highpotential": "Alto potencial",
        "in": "Entrou no estágio",
        "loyalist": "Leais",
        "neednutring": "Precisam de incentivo",
        "out": "Saiu do estágio",
        "recent": "Recentes"
      },
      "shared": {
        "to": "Compartilhado via ",
        "contain": " conteúdo da ",
        "campaign": "campanha ",
        "automation": "automation  "
      },
      "unsubscribed": {
        "indicate": "Se descadastrou indicando o motivo: ",
        "not_interested": "\"Perdi o interesse nestes envios\"",
        "too_frequent": "\"Recebo estes emails com muita frequência\"",
        "spam_never_subscribed": "\"Nunca me cadastrei para receber isto\"",
        "spam_offensive": "\"Recibi conteúdo ofensivo ou enganoso\"",
        "spam_fbl": "\"EEste email é spam\"",
        "one_click": "\"Eu não quero mais receber estes emails\"",
        "one_click_email": "\"Eu não quero mais receber estes emails\"",
        "campaign": " da campanha",
        "automation": " da automation,"
      },
      "whatsapp": {
        "clicked": "Clicou na mensagem pelo WhatsApp.",
        "opened": "Abriu a mensagem pelo WhatsApp.",
        "sent": "Você enviou uma mensagem pelo WhatsApp."
      }
    },
    "emptyStates": {
      "noFilterResult": "Não foram encontrados resultados com os filtros selecionados.",
      "noActivity": "Este contato não registra atividade em sua conta.",
      "cleanFilters": "Limpar filtros"
    },
    "toggle": {
      "allSales": "Todas as vendas",
      "onlyAssisted": "Somente assistidas"
    },
    "tooltip": {
      "viewContent": "Ver o conteúdo",
      "preferredCategories": "Categorias",
      "preferredProducts": "Produtos"
    }
  }
}
</i18n>
