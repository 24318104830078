import { useRequestsAPIV3, usePagingRequestsAPIV3 } from "@api/requests";

//Utils
import { email as emailValidator } from "@vuelidate/validators";

//Types
import {
  AutoSaveRequest,
  AutoSaveResponse,
  CleanAutoSaveRequest,
  CleanAutoSaveResponse,
  CopyPublicTemplateRequest,
  CopyPublicTemplateResponse,
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  DuplicateTemplateRequest,
  DuplicateTemplateResponse,
  GetHistoryTemplateRequest,
  GetHistoryTemplateResponse,
  GetPublicTemplatePreviewRequest,
  GetPublicTemplateRequest,
  GetPublicTemplateResponse,
  GetPublicTemplatesAsyncGenerator,
  GetPublicTemplatesParams,
  GetPublicTemplatesRequest,
  GetPublicTemplatesResponse,
  GetPublicTemplatPreviewResponse,
  GetShareLinkRequest,
  GetShareLinkResponse,
  GetShareLinkTestABRequest,
  GetShareLinkTestABResponse,
  GetTemplateHistoryPreviewRequest,
  GetTemplateHistoryPreviewResponse,
  GetTemplatePreviewRequest,
  GetTemplatePreviewResponse,
  GetTemplatePreviewTestABRequest,
  GetTemplatePreviewTestABResponse,
  GetTemplateRequest,
  GetTemplateResponse,
  GetTemplatesAsyncGenerator,
  GetTemplatesParams,
  GetTemplatesRequest,
  GetTemplatesResponse,
  GetTestEmailsResponse,
  SaveTemplateRequest,
  SaveTemplateResponse,
  SendTestEmailsRequest,
  SendTestEmailsResponse,
  GetTemplateTagsResponse,
  PutModifyTemplateRequest,
  StatsByTag,
  GetStatsResponse,
  PutModifyTemplateTagsResponse,
  CreateTemplateTagRequest,
  CreateTemplateTagResponse,
  DeleteTemplateTagRequest,
  DeleteTemplateTagResponse,
  TemplatesBulkRemoveTagResponse,
  TemplatesBulkRemoveTagRequest,
  TemplatesBulkAddTagResponse,
  TemplatesBulkAddTagRequest,
  TemplatesBulkDeleteResponse,
  TemplatesBulkDeleteRequest,
} from "./templates.types";

import {
  HistoryTemplate,
  ShareLink,
  Template,
  TemplateHistoryPreview,
  TemplatePreview,
  TemplatePreviewTestAB,
  TemplateToSave,
  TestEmails,
} from "@/vue/types/Templates";

//I18N
import { ReducedTemplate } from "../../models/templates";

// TODO: Validation Library
const isValidEmail = (email: string) => Boolean(emailValidator.$validator(email, null, null));

export function useEmailTemplate() {
  const request = useRequestsAPIV3();
  const requestPaging = usePagingRequestsAPIV3();

  async function getTemplate({ tpl_id }: GetTemplateRequest): Promise<Template> {
    const res = await request<GetTemplateResponse>({
      url: `/templates/${tpl_id}`,
      method: "get",
    });

    return res.data.data;
  }

  async function* getTemplates(options?: GetTemplatesRequest): GetTemplatesAsyncGenerator {
    const tagsFilter = options?.tags?.map((tag) => tag.id);

    const requestGen = await requestPaging<GetTemplatesResponse, unknown, GetTemplatesParams>(
      {
        url: `/templates`,
        urlScroll: `/templates/scroll`,
        method: "get",
        axiosRequestConfig: {
          paramsSerializer: (params) => {
            const urlParams = params as Record<string, string>;

            const paramsString = Object.entries(urlParams).reduce((paramsString, [paramKey, paramValue]) => {
              if (!paramValue) return paramsString;

              return `${paramsString}&${paramKey}=${encodeURIComponent(paramValue)}`;
            }, "");

            return paramsString;
          },
        },
        urlParams: {
          "filters.name": options?.name !== undefined ? options?.name : undefined,
          "filters.relation_type": options?.relation !== undefined ? options?.relation : undefined,
          "filters.relation_type.ne":
            options?.relationNotEqualTo !== undefined ? options?.relationNotEqualTo : undefined,
          "filters.tags": tagsFilter?.join() || undefined,
        },
      },
      { limit: options?.limit || 20, offset: options?.offset || 0 },
      true,
    );

    yield* requestGen as GetTemplatesAsyncGenerator;
  }

  async function templatesBulkDelete({ tpl_ids }: TemplatesBulkDeleteRequest): Promise<boolean> {
    const res = await request<TemplatesBulkDeleteResponse>({
      url: `/templates/_bulk `,
      method: "post",
      data: {
        type: "TEMPLATE_DELETE",
        ids: tpl_ids,
      },
    });

    return res.data.data;
  }

  async function templatesBulkAssignTag({ tpl_ids, tag }: TemplatesBulkAddTagRequest): Promise<boolean> {
    const res = await request<TemplatesBulkAddTagResponse>({
      url: `/templates/_bulk `,
      method: "post",
      data: {
        type: "TAGS_ASSIGN",
        ids: tpl_ids,
        options: {
          tag: tag.id,
        },
      },
    });

    return res.data.data;
  }

  async function templatesBulkRemoveTag({ tpl_ids, tag }: TemplatesBulkRemoveTagRequest): Promise<boolean> {
    const res = await request<TemplatesBulkRemoveTagResponse>({
      url: `/templates/_bulk `,
      method: "post",
      data: {
        type: "TAGS_REMOVE",
        ids: tpl_ids,
        options: {
          tag: tag.id,
        },
      },
    });

    return res.data.data;
  }

  async function getTemplateTags() {
    const res = await request<GetTemplateTagsResponse>({
      url: `/templates/tags`,
      method: "get",
    });

    return res.data.data;
  }

  async function createTemplateTag({ color, name }: CreateTemplateTagRequest) {
    const res = await request<CreateTemplateTagResponse>({
      url: `/templates/tags`,
      method: "post",
      data: { color, name },
    });

    return res.data.data;
  }

  async function deleteTemplateTag({ id }: DeleteTemplateTagRequest) {
    const res = await request<DeleteTemplateTagResponse>({
      url: `/templates/tags/${id}`,
      method: "delete",
    });

    return res.data.data;
  }

  async function modifyTemplateTag({ color, id, name }: PutModifyTemplateRequest) {
    const res = await request<PutModifyTemplateTagsResponse>({
      url: `/templates/tags/${id}`,
      method: "put",
      data: { color, name },
    });

    return res.data.data;
  }

  async function getPublicTemplate({ tpl_id }: GetPublicTemplateRequest): Promise<Template> {
    const res = await request<GetPublicTemplateResponse>({
      url: `/templates/public/${tpl_id}`,
      method: "get",
    });

    return res.data.data;
  }

  async function copyPublicTemplate({ tpl_id }: CopyPublicTemplateRequest): Promise<Template> {
    const res = await request<CopyPublicTemplateResponse>({
      url: `/templates/public/${tpl_id}/copy`,
      method: "post",
      data: {},
    });

    return res.data.data;
  }

  async function duplicateTemplate({
    tpl_id,
    relation_type,
    relation_id,
  }: DuplicateTemplateRequest): Promise<Template> {
    const res = await request<DuplicateTemplateResponse>({
      url: `/templates/${tpl_id}/duplicate`,
      method: "post",
      data: {
        relation_type: relation_type || undefined,
        relation_id: relation_id || undefined,
      },
    });

    return res.data.data;
  }

  async function deleteTemplate({ tpl_id }: DeleteTemplateRequest): Promise<unknown> {
    const res = await request<DeleteTemplateResponse>({
      url: `/templates/${tpl_id}`,
      method: "delete",
    });

    return res.data.data;
  }

  async function* getPublicTemplates(options?: GetPublicTemplatesRequest): GetPublicTemplatesAsyncGenerator {
    const requestGen = await requestPaging<GetPublicTemplatesResponse, unknown, GetPublicTemplatesParams>(
      {
        url: `/templates/public`,
        urlScroll: `/templates/public/scroll`,
        method: "get",
        urlParams: {
          "filters.name": options?.name !== undefined ? options?.name : undefined,
          "filters.relation_type": options?.relation !== undefined ? options?.relation : undefined,
          "filters.relation_type.ne":
            options?.relationNotEqualTo !== undefined ? options?.relationNotEqualTo : undefined,
          "filters.tags": options?.tags?.join(",") || undefined,
          "filters.tags.in": options?.tags_in?.join(",") || undefined,
        },
      },
      { limit: options?.limit || 20, offset: options?.offset || 0 },
    );

    yield* requestGen as GetPublicTemplatesAsyncGenerator;
  }

  async function getPublicTemplatePreview({ tpl_id }: GetPublicTemplatePreviewRequest) {
    const res = await request<GetPublicTemplatPreviewResponse>({
      url: `/templates/public/${tpl_id}/preview`,
      method: "post",
      data: {},
    });

    return res.data.data;
  }

  async function getTestEmails(): Promise<TestEmails> {
    const res = await request<GetTestEmailsResponse>({
      url: `/templates/testemails`,
      method: "get",
    });

    return res.data.data;
  }

  async function sendTestEmail({
    emails,
    tpl_id,
    use_autosave,
    contact_id,
    utm_campaign,
    subject,
    sender_id,
  }: SendTestEmailsRequest): Promise<unknown> {
    const res = await request<SendTestEmailsResponse>({
      url: `/templates/${tpl_id}/sendtest`,
      method: "post",
      data: {
        emails,
        use_autosave,
        contact_id,
        utm_campaign,
        subject,
        sender_id,
      },
    });

    return res.data.data;
  }

  async function cleanAutoSave({ tpl_id }: CleanAutoSaveRequest): Promise<unknown> {
    const res = await request<CleanAutoSaveResponse>({
      url: `/templates/${tpl_id}/autosave/clean`,
      method: "post",
    });

    return res.data.data;
  }

  async function getHistoryTemplate({ tpl_id, tplh_id }: GetHistoryTemplateRequest): Promise<HistoryTemplate> {
    const res = await request<GetHistoryTemplateResponse>({
      url: `/templates/${tpl_id}/history/${tplh_id}`,
      method: "get",
    });

    return res.data.data;
  }

  async function getTemplatePreview({
    tpl_id,
    contact_id,
    utm_campaign,
    use_autosave,
  }: GetTemplatePreviewRequest): Promise<TemplatePreview> {
    const res = await request<GetTemplatePreviewResponse>({
      url: `/templates/${tpl_id}/preview`,
      method: "post",
      data: {
        contact_id,
        utm_campaign,
        use_autosave,
      },
    });

    return res.data.data;
  }

  async function getTemplateHistoryPreview({
    tpl_id,
    tplh_id,
  }: GetTemplateHistoryPreviewRequest): Promise<TemplateHistoryPreview> {
    const res = await request<GetTemplateHistoryPreviewResponse>({
      url: `/templates/${tpl_id}/history/${tplh_id}/preview`,
      method: "post",
    });

    return res.data.data;
  }

  async function getTemplatePreviewTestAB({
    tpl_id,
    contact_id,
    subjects,
    utm_campaign,
    use_autosave,
  }: GetTemplatePreviewTestABRequest): Promise<TemplatePreviewTestAB> {
    const res = await request<GetTemplatePreviewTestABResponse>({
      url: `/templates/${tpl_id}/preview`,
      method: "post",
      data: {
        contact_id,
        subjects,
        utm_campaign,
        use_autosave,
      },
    });

    return res.data.data;
  }

  async function createTemplate({ type }: CreateTemplateRequest): Promise<Template> {
    const res = await request<CreateTemplateResponse>({
      url: `/templates`,
      method: "post",
      data: {
        type,
      },
    });

    return res.data.data;
  }

  async function saveTemplate({ tpl_id, template }: SaveTemplateRequest): Promise<Template> {
    const filterInvalidOptions = (template: Partial<TemplateToSave>): Partial<TemplateToSave> => {
      return {
        ...template,
        options: {
          ...template.options,
          sample_context: template.options?.sample_context !== "" ? template.options?.sample_context : undefined,
          override_to:
            template.options?.override_to && isValidEmail(template.options.override_to)
              ? template.options.override_to
              : undefined,
          bcc: template.options?.bcc && isValidEmail(template.options.bcc) ? template.options.bcc : undefined,
          subjects: template.options?.subjects?.filter((subject) => subject !== ""),
        },
      };
    };
    const getReducedTemplate = (template: Partial<TemplateToSave>): ReducedTemplate => {
      const filteredTpl = filterInvalidOptions(template);
      return {
        type: filteredTpl.type,
        name: filteredTpl.name,
        tags: filteredTpl.tags,
        sender_id: filteredTpl.sender_id,
        subject: filteredTpl.subject,
        preheader: filteredTpl.preheader,
        js_params: filteredTpl.js_params,
        contents: filteredTpl.contents,
        audit_results: filteredTpl.audit_results,
        tracking: filteredTpl.tracking,
        options: filteredTpl.options,
      };
    };

    const reducedTemplate = getReducedTemplate(template);

    const res = await request<SaveTemplateResponse>({
      url: `/templates/${tpl_id}`,
      method: "put",
      data: reducedTemplate,
    });

    return res.data.data;
  }

  async function autosave({
    tpl_id,
    save_history,
    subject,
    preheader,
    json,
    html,
    text,
    type,
  }: AutoSaveRequest): Promise<unknown> {
    const res = await request<AutoSaveResponse>({
      url: `/templates/${tpl_id}/autosave`,
      method: "post",
      disableNotifyErrors: true,
      data: {
        save_history,
        subject,
        preheader,
        json,
        html,
        text,
        type,
      },
    });

    return res.data.data;
  }

  async function getShareLink({
    tpl_id,
    contact_id,
    utm_campaign,
    use_autosave,
  }: GetShareLinkRequest): Promise<ShareLink> {
    const res = await request<GetShareLinkResponse>({
      url: `/templates/${tpl_id}/preview/link/share`,
      method: "post",
      data: {
        contact_id,
        utm_campaign,
        use_autosave,
      },
    });

    return res.data.data;
  }

  async function getShareLinkTestAB({
    tpl_id,
    contact_id,
    subjects,
    utm_campaign,
    use_autosave,
  }: GetShareLinkTestABRequest): Promise<ShareLink> {
    const res = await request<GetShareLinkTestABResponse>({
      url: `/templates/${tpl_id}/preview/link/share`,
      method: "post",
      data: {
        contact_id,
        utm_campaign,
        subjects,
        use_autosave,
      },
    });

    return res.data.data;
  }

  async function getPublicStats(): Promise<StatsByTag> {
    const res = await request<GetStatsResponse>({
      url: `/templates/public/stats`,
      method: "get",
    });

    return res.data.data;
  }

  async function getPrivateStats(): Promise<StatsByTag> {
    const res = await request<GetStatsResponse>({
      url: `/templates/stats`,
      method: "get",
    });

    return res.data.data;
  }

  return {
    getTemplate,
    getTemplates,
    templatesBulkDelete,
    templatesBulkAssignTag,
    templatesBulkRemoveTag,
    getTemplateTags,
    createTemplateTag,
    deleteTemplateTag,
    modifyTemplateTag,
    getPublicTemplate,
    copyPublicTemplate,
    duplicateTemplate,
    deleteTemplate,
    getPublicTemplates,
    getPublicTemplatePreview,
    getTestEmails,
    sendTestEmail,
    cleanAutoSave,
    getTemplatePreview,
    getTemplateHistoryPreview,
    getHistoryTemplate,
    getTemplatePreviewTestAB,
    createTemplate,
    saveTemplate,
    autosave,
    getShareLink,
    getShareLinkTestAB,
    getPublicStats,
    getPrivateStats,
  };
}
