import { ref, watch } from "vue";
import { defineStore } from "pinia";

// Application
import { useInvoicePaymentsStore, useSessionStore, useSendersStore, useFeaturesStore } from "@store";
import { useBillingInfoService } from "@services";
import { useLocalStorageApp } from "@application";
import { usePlan } from "@/vue/api/modules/plan/plan";

// Helpers
import { differenceInDays, compareAsc, addDays } from "date-fns";
import { useDebounceFn } from "@vueuse/core";
import { isEmpty } from "lodash";
import { formatDate } from "@helpers/formatters";

// Domain
import { anySenderStatusOk } from "@domain/Senders";
import type { DismissObject, DismissableAlertTypes, DismissedAlertTypes, Message } from "@domain/statusAlert";
import type { Permissions } from "@domain/permissions";
import type { ScheduledCancelPlanAction } from "@domain/plan";

export const useAlertStore = defineStore("alertStore", () => {
  const sessionStore = useSessionStore();
  const featuresStore = useFeaturesStore();
  const invoicePaymentsStore = useInvoicePaymentsStore();
  const sendersStore = useSendersStore();
  const billingInfoService = useBillingInfoService();
  const planService = usePlan();
  const localStorage = useLocalStorageApp();
  const message = ref<Message | undefined>();
  const cleanMessage = () => (message.value = undefined);

  const scheduledCancelPlanAction = ref<ScheduledCancelPlanAction>();
  const showBillingInfoError = ref(false);

  const update = async () => {
    if (["", "*"].includes(sessionStore.session?.account?.code)) return;

    await Promise.all([
      sessionStore.fetchPlan(),
      sendersStore.fetchSenders(),
      invoicePaymentsStore.fetchPaymentCards(),
      invoicePaymentsStore.fetchUnpaidInvoices(),
      featuresStore.fetchFeatures(),
    ]);

    const scheduledPlanActions = await planService.getScheduledCancelPlanActions();
    scheduledCancelPlanAction.value = scheduledPlanActions.find((planAction) => planAction.type === "cancel");

    try {
      showBillingInfoError.value = false;

      const billignInfo = await billingInfoService.get();

      if (billignInfo.isOk() && isEmpty(billignInfo.value.info)) {
        showBillingInfoError.value = true;
      }
    } catch (e) {
      showBillingInfoError.value = true;
    }
  };

  const contactDemoThreshold = 2500;
  const messageExpirationDays = 1;
  const invoicesExpirationThreshold = 5;

  const recalculateMessage = useDebounceFn(() => {
    const senders = sendersStore.getSenders();
    const unpaidInvoices = invoicePaymentsStore.unpaidInvoices;
    const someInvoiceWithinThreshold = unpaidInvoices.some((i) => {
      return Math.abs(differenceInDays(new Date(i.expires), new Date())) <= invoicesExpirationThreshold && !i.paid;
    });
    const features = featuresStore.getFeatures();
    const isMasterUser = sessionStore.session.isMasterUser;

    if (scheduledCancelPlanAction.value) {
      message.value = {
        type: "SCHEDULED_CANCEL_PLAN",
        dismissable: false,
        payload: formatDate(new Date(scheduledCancelPlanAction.value.date_execution), "PPP")
      }
      return;
    }

    if (sessionStore.plan?.trialExpired && !sessionStore.plan?.trialEnds && sessionStore.plan?.type === "FREE") {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "TRIAL_EXPIRED_DISMISSED",
      });
      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("TRIAL_EXPIRED_DISMISSED");
        message.value = {
          type: "TRIAL_EXPIRED",
          dismissable: false,
        };
        return;
      }
      if (isMasterUser) clearDismiss("TRIAL_EXPIRED_DISMISSED");
    }

    if (sessionStore.plan?.trialEnds && sessionStore.plan?.type === "EMAILS") {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "TRIAL_ACTIVE_DISMISSED",
      });

      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("TRIAL_ACTIVE_DISMISSED");
        message.value = {
          type: "TRIAL_ACTIVE",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("TRIAL_ACTIVE_DISMISSED");
    }

    if (
      !sessionStore.plan?.trialExpired &&
      !sessionStore.plan?.trialEnds &&
      sessionStore.plan?.state === "BLOCKED_CONTACTS_EXCEEDED" &&
      sessionStore.plan?.type === "FREE"
    ) {
      if (sessionStore.plan?.contacts.active < contactDemoThreshold) {
        message.value = {
          type: "BLOCKED_CONTACTS_EXCEEDED_DEMO_FREE",
          dismissable: false,
        };
        return;
      }
      message.value = {
        type: "BLOCKED_CONTACTS_EXCEEDED_FREE",
        dismissable: false,
      };
      return;
    }

    if (sessionStore.plan?.state === "BLOCKED_CONTACTS_EXCEEDED" && sessionStore.plan?.type !== "FREE") {
      message.value = {
        type: "BLOCKED_CONTACTS_EXCEEDED",
        dismissable: false,
      };
      return;
    }

    if (sessionStore.plan?.state === "BLOCKED_EXPIRED_INVOICES") {
      message.value = {
        type: "BLOCKED_EXPIRED_INVOICES",
        dismissable: false,
      };
      return;
    }

    if (sessionStore.plan?.state === "BLOCKED_ABUSE") {
      message.value = {
        type: "BLOCKED_ABUSE",
        dismissable: false,
      };
      return;
    }

    if (sessionStore.plan?.state === "BLOCKED_ACCOUNT_DISABLED") {
      message.value = {
        type: "BLOCKED_ACCOUNT_DISABLED",
        dismissable: false,
      };
      return;
    }

    if (someInvoiceWithinThreshold) {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "INVOICE_ABOUT_TO_EXPIRE_DISMISSED",
      });
      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("INVOICE_ABOUT_TO_EXPIRE_DISMISSED");
        message.value = {
          type: "INVOICE_ABOUT_TO_EXPIRE",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("INVOICE_ABOUT_TO_EXPIRE_DISMISSED");
    }

    if (
      !(sessionStore.plan?.trialEnds && sessionStore.plan?.type === "EMAILS") && //not in trial
      features?.ALLOW_USE_GENERIC_DOMAIN !== "1" &&
      senders &&
      senders.length > 0 &&
      !anySenderStatusOk(senders)
    ) {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "ALL_SENDERS_STATUS_NOT_OK_DISMISSED",
      });

      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("TRIAL_EXPIRED_DISMISSED");
        message.value = {
          type: "ALL_SENDERS_STATUS_NOT_OK",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("ALL_SENDERS_STATUS_NOT_OK_DISMISSED");
    }

    if (
      invoicePaymentsStore.cardsList.length === 0 &&
      sessionStore.plan?.type === "MONTHLY" &&
      (sessionStore.plan?.subscription?.payMethod?.includes("STRIPE") ||
        sessionStore.plan?.subscription?.payMethod?.includes("PAGOUNO"))
    ) {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "PAYMENT_CARD_REQUIRED_DISMISSED",
      });

      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("PAYMENT_CARD_REQUIRED_DISMISSED");
        message.value = {
          type: "PAYMENT_CARD_REQUIRED",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("PAYMENT_CARD_REQUIRED_DISMISSED");
    }

    if (invoicePaymentsStore.cardsList?.[0]?.isExpired && sessionStore.plan?.type === "MONTHLY") {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "PAYMENT_CARD_EXPIRED_DISMISSED",
      });

      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("PAYMENT_CARD_EXPIRED_DISMISSED");
        message.value = {
          type: "PAYMENT_CARD_EXPIRED",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("PAYMENT_CARD_EXPIRED_DISMISSED");
    }

    if (
      showBillingInfoError.value &&
      sessionStore.plan?.type &&
      ["MONTHLY", "EMAILS"].includes(sessionStore.plan?.type)
    ) {
      const isDismissed = localStorage.getGlobal<DismissObject>({
        id: "BILLING_INFO_REQUIRED_DISMISSED",
      });

      if (!isDismissed || compareAsc(new Date(isDismissed.expirationDate), new Date()) <= 0) {
        clearDismiss("BILLING_INFO_REQUIRED_DISMISSED");
        message.value = {
          type: "BILLING_INFO_REQUIRED",
          dismissable: true,
        };
        return;
      }
      if (isMasterUser) clearDismiss("BILLING_INFO_REQUIRED_DISMISSED");
    }

    cleanMessage();
  }, 250);

  watch([() => scheduledCancelPlanAction.value, () => invoicePaymentsStore.cardsList, () => showBillingInfoError.value], () => {
    recalculateMessage();
  });

  watch(
    [() => sessionStore.plan],
    () => {
      recalculateMessage();
    },
    { deep: true },
  );

  const dismissMessage = (messageId: DismissableAlertTypes) => {
    let dismissExpiration = addDays(new Date(), messageExpirationDays).toString();

    localStorage.saveGlobal<DismissObject>({
      id: `${messageId}_DISMISSED`,
      value: {
        expirationDate: dismissExpiration,
      },
    });
    recalculateMessage();
  };

  const clearDismiss = (id: DismissedAlertTypes) => {
    localStorage.clear({ id });
  };

  const permissionDenied = ref(false);
  const permissionsNeeded = ref<Permissions>();
  const showPermissionDenied = (permissions?: Permissions) => {
    permissionDenied.value = true;

    if (!permissions) return;
    permissionsNeeded.value = permissions;
  };
  const closePermissionDenied = () => {
    permissionDenied.value = false;
    permissionsNeeded.value = undefined;
  };

  return {
    permissionDenied,
    permissionsNeeded,
    showPermissionDenied,
    closePermissionDenied,
    message,
    dismissMessage,
    recalculateMessage,
    update,
  };
});
