import { ListView } from "@/core/magnet";
import app from "@/app";
import listTpl from "../templates/list.hbs";

export default ListView.extend({
  module: "interests",
  templates: {
    initial: listTpl,
  },
  limit: 100000,
  query: {
    order: "name asc",
  },
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=open-export-modal]": "openExportModal",
      "submit [data-form=export]": "export",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Send events
    self.broadcast();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Emmit events
  broadcast: function () {
    var self = this;
    if (self.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_INTERESTS");
    }
  },

  // Empty cache after deleting a list
  eachMass: function (id, method) {
    if (method === "destroy") {
      localStorage.removeItem("interests");
    }
  },

  // Opens the export modal
  openExportModal: function () {
    var self = this,
      checked = self.getChecked(),
      lang = window.lang.interests,
      data = {
        lang: window.lang,
        data: checked,
        legend: lang.exportLegendSingular,
      };
    if (checked.length > 1) {
      data.legend = lang.exportLegendPlural.replace("###", checked.length);
    }
    self.openModal(require("../templates/modals/export.hbs"), data);
  },

  // Exports the interests
  export: function (e) {
    var self = this,
      interests = self.getChecked(),
      modal = $(self.modal),
      resolve = [];
    e.preventDefault();

    // Create one deferred per checked list
    $.each(interests, function (index, list) {
      resolve.push(
        $.ajax({
          type: "POST",
          url: "interests/" + list.id + "/export",
        }),
      );
    });

    // Resolve deferreds
    $.when.apply(null, resolve).done(function () {
      // Update the tasks indicator
      app.instances.singleton("tasks", "list").render();

      // Close modal
      modal.modal("hide");

      // Display feedback
      self.displayMessage(window.lang.interests.feedback.massExported);

      // Reload
      self.reload();
    });
  },
});
