<template>
  <div ref="elementRef" class="relative w-max">
    <IconButton
      :disabled="disabled"
      :label="tooltip"
      :show-tooltip="!menuIsOpen"
      :class="[{ '!bg-gray-200': disabled }, 'border border-gray-300']"
      @click.stop="toggleMenu"
      @keydown.escape="closeMenu"
    >
      <SortDescendingIcon class="ml-0.5 mt-0.5 h-5 w-5 text-gray-500" />
    </IconButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="menuIsOpen"
        class="absolute right-0 top-10 isolate z-50 w-max rounded bg-white pr-2 shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <label class="w-full px-4 pt-2 text-xs font-medium text-gray-400">{{ label }}</label>
        <div class="max-h-96 space-y-4 overflow-y-auto px-4 py-4">
          <div v-for="item in items" :key="item.key" class="flex items-center" @click.stop="() => toggleItem(item)">
            <input
              :id="item.key"
              name="notification-method"
              type="radio"
              :checked="selected.key === item.key"
              :class="[
                'h-4 w-4 border-gray-300 text-sky-500 outline-none focus:!outline-none focus:ring-sky-500 focus-visible:ring-sky-500',
              ]"
            />
            <label :for="item.key" :class="['ml-3 mt-1 text-sm font-medium text-gray-500']">
              {{ item.value }}
            </label>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts" setup generic="KeyType extends string = string, ValueType = string, DataType = unknown">
import { ref } from "vue";

import { Items, Item } from "./radioListButton.types";

// Components
import IconButton from "@atoms/IconButton.vue";

// Icons
import { SortDescendingIcon } from "@heroicons/vue/outline";

// Utils
import { onClickOutside } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    label: string;
    tooltip: string;
    items: Items<KeyType, ValueType, DataType>;
    selected: Item<KeyType, ValueType, DataType>;
    disabled?: boolean;
  }>(),
  {
    label: "",
    placement: "bottom",
    autoPlacements: () => ["bottom"],
    disabled: false,
  }
);

const emit = defineEmits<{
  "update:selected": [Item<KeyType, ValueType, DataType>];
}>();

const menuIsOpen = ref(false);
const toggleMenu = () => {
  menuIsOpen.value = !menuIsOpen.value;
};
const closeMenu = () => {
  menuIsOpen.value = false;
};

const toggleItem = (item: Item<KeyType, ValueType, DataType>) => {
  if (props.selected.key === item.key) return;
  emit("update:selected", item);
};

const elementRef = ref();
onClickOutside(elementRef, () => {
  closeMenu();
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
