var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form data-form='import-options'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"advancedConfiguration")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <div class='form-group'>\n                        <div class='graphic checkbox'>\n                            <input type='checkbox' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"normalizeCase") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":88}}})) != null ? stack1 : "")
    + " name='normalizeCase'\n                                id='normalizeCase'>\n                            <label for='normalizeCase'>\n                                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"importNormalizeCase")), depth0))
    + "\n                            </label>\n                        </div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CSV",{"name":"is","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":34,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n"
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CSV",{"name":"is","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":104,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"MELI",{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":20},"end":{"line":116,"column":27}}})) != null ? stack1 : "")
    + "                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"interestDefaultExpirationDays")), depth0))
    + "\n                        </label>\n                        <input class='form-control' name='interestDefaultExpirationDays' type='number'\n                            value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"interestDefaultExpirationDays") : depth0), depth0))
    + "' data-validate='int'\n                            placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"interestDefaultExpirationDaysPlaceholder")), depth0))
    + "\" min=\"1\">\n                        <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"interestDefaultExpirationDaysLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='submit' class='btn btn-primary btn-outline'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class='graphic checkbox'>\n                            <input type='checkbox' "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"columnHeaders") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":51},"end":{"line":28,"column":88}}})) != null ? stack1 : "")
    + " name='columnHeaders'\n                                id='columnHeaders'>\n                            <label for='columnHeaders'>\n                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"importColumnHeaders")), depth0))
    + "\n                            </label>\n                        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"row\">\n                        <div class=\"col-md-4\">\n                            <div class='form-group'>\n                                <label>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"columnSeparator")), depth0))
    + "\n                                </label>\n                                <select class='form-control' name='columnSeparator'>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"columnSeparator") : depth0),"COMMA",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":44},"end":{"line":44,"column":93}}})) != null ? stack1 : "")
    + " value='COMMA'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"separatorComma")), depth0))
    + "\n                                    </option>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"columnSeparator") : depth0),"SEMICOLON",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":44},"end":{"line":47,"column":97}}})) != null ? stack1 : "")
    + " value='SEMICOLON'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"separatorSemicolon")), depth0))
    + "\n                                    </option>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"columnSeparator") : depth0),"TAB",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":44},"end":{"line":50,"column":91}}})) != null ? stack1 : "")
    + " value='TAB'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"separatorTab")), depth0))
    + "\n                                    </option>\n                                </select>\n                            </div>\n                        </div>\n                        <div class=\"col-md-4\">\n                            <div class='form-group'>\n                                <label>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"stringDelimiter")), depth0))
    + "\n                                </label>\n                                <select class='form-control' name='stringDelimiter'>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"stringDelimiter") : depth0),"QUOTES",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":44},"end":{"line":62,"column":94}}})) != null ? stack1 : "")
    + " value='QUOTES'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"delimiterQuotes")), depth0))
    + "\n                                    </option>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"stringDelimiter") : depth0),"DOUBLE_QUOTES",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":44},"end":{"line":65,"column":101}}})) != null ? stack1 : "")
    + "\n                                        value='DOUBLE_QUOTES'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"delimiterDoubleQuotes")), depth0))
    + "\n                                    </option>\n                                </select>\n                            </div>\n                        </div>\n                        <div class=\"col-md-4\">\n                            <div class='form-group'>\n                                <label>\n                                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"sourceFileEncoding")), depth0))
    + "\n                                </label>\n                                <select class='form-control' name='sourceFileEncoding'>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sourceFileEncoding") : depth0),"UTF-8",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":44},"end":{"line":78,"column":96}}})) != null ? stack1 : "")
    + ">\n                                        UTF-8\n                                    </option>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sourceFileEncoding") : depth0),"ISO-8859-1",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":44},"end":{"line":81,"column":101}}})) != null ? stack1 : "")
    + ">\n                                        ISO-8859-1\n                                    </option>\n                                    <option "
    + ((stack1 = __default(require("../../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sourceFileEncoding") : depth0),"US-ASCII",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":84,"column":44},"end":{"line":84,"column":99}}})) != null ? stack1 : "")
    + ">\n                                        US-ASCII\n                                    </option>\n                                </select>\n                            </div>\n                        </div>\n\n                    </div>\n\n                    <div class='form-group'>\n                        <label>\n                            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"dateFormat")), depth0))
    + "\n                        </label>\n                        <input class='form-control' name='dateFormat' type='text' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dateFormat") : depth0), depth0))
    + "'\n                            placeholder='dd/mm/yyyy'>\n                        <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"dateFormatLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n\n\n\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class='form-group'>\n                        <div class='graphic checkbox'>\n                            <input type='checkbox' "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"categoriesAsInterests") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":51},"end":{"line":108,"column":96}}})) != null ? stack1 : "")
    + "\n                                name='categoriesAsInterests' id='catAsInterests'>\n                            <label for='catAsInterests'>\n                                "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"categoriesAsInterests")), depth0))
    + "\n                            </label>\n                        </div>\n                        <p class=\"input-legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"categoriesAsInterestsLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":135,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});