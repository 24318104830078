import { ListView } from "@/core/magnet";
import ListMixin from "@/mixins/list";
import { _ } from "@/helpers/utils";
import app from "@/app";

export default ListView.mix(ListMixin).extend({
  module: "designs",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  cache: false,
  paginate: true,
  limit: 30,
  query: {
    order: "created desc",
  },
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "dragover [data-role=dropzone]": "prevent",
      // "click [data-action=show-template]": "showTemplate",
      "click [data-action=open-create-folder-modal]": "openCreateFolderModal",
      "submit [data-form=create-folder]": "createFolder",
      "click [data-action=open-rename-modal]": "openRenameModal",
      "submit [data-form=rename]": "rename",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=open-move-modal]": "openMoveModal",
      "click [data-action=explore-folders]": "exploreFolders",
      "submit [data-form=move]": "move",
      "click [data-action=open-create-eddie-template-modal]": "openCreateEddieTemplateModal",
      "submit [data-form=create-eddie-template]": "createEddieTemplate",
      "click [data-action=open-create-html-template-modal]": "openCreateHtmlTemplateModal",
      "shown.bs.tab [data-toggle=tab]": "changedTab",
      "dragenter [data-role=dropzone]": "addDropHighlight",
      "dragleave [data-role=dropzone]": "removeDropHighlight",
      "drop [data-role=dropzone]": "getDroppedFiles",
      "click [data-role=uploader]": "openFileSelection",
      "change [data-input-type=upload-file]": "getSelectedFiles",
      "submit [data-form=create-html-template]": "createHtmlTemplate",
    });
  },

  renderData: function () {
    const unlayerEnabled =
      app.session.attributes.details?.account?.features?.UNLAYER_OPTIONAL === "1" ||
      app.session.attributes.details?.account?.features?.UNLAYER_DEFAULT === "1";
    return { ...ListView.prototype.renderData.call(this), unlayerEnabled };
  },

  // Runs custom actions after renderization
  afterRenderPage: function () {
    // Save references to common elements
    this.uploader = {
      progressBar: ".upload-progress",
      loading: ".loading",
      container: ".upload-container",
      dropzone: ".dropzone",
    };

    // Set default source
    this.file = false;
    this.source = "htmlFile";

    // Send events
    this.broadcast();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Adds dividers
  afterRenderItems: function () {
    var area = $(this.renderArea),
      lastFolder = area.find(".folder").last().parent();
    area.find(".divider").remove();
    $('<li class="divider">').insertAfter(lastFolder);
  },

  // Emmit events
  broadcast: function () {
    var self = this;
    if (self.isEmpty()) {
      app.integrations.intercom.event("APP_VISIT_EMPTY_TEMPLATES");
    }
  },

  // Adds a filter parameter to the URL
  filter: function (e) {
    var self = this,
      hash,
      params = $(e.currentTarget).data("params");
    e.preventDefault();

    // If the filter is a location, erase the search
    if (params.key === "filter.location") {
      hash = self.unfilter({ key: "q" });
      self.urlFilter(params, hash);
      return self;
    }

    // Add filter
    self.urlFilter(params);
  },

  // Shows action buttons according to the checked elements
  toggleActions: function () {
    var self = this,
      toolbar = self.$("[data-role=toolbar]"),
      none = self.$("[data-type=none]"),
      justOne = self.$("[data-type=just-one]"),
      onePlus = self.$("[data-type=one-plus]"),
      twoPlus = self.$("[data-type=two-plus]"),
      checkboxes = self.$("[data-action=check]"),
      count = checkboxes.filter(":checked").length;

    // Hide buttons when no items are checked
    if (count === 0) {
      none.removeClass("hide");
      justOne.addClass("hide");
      onePlus.addClass("hide");
      twoPlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Show actions when just one item is checked
    if (count === 1) {
      none.addClass("hide");
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      twoPlus.addClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Show actions when two or more items are checked
    if (count > 1) {
      none.addClass("hide");
      justOne.addClass("hide");
      onePlus.removeClass("hide");
      twoPlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Hide move and duplicate action if a folder is selected
    if (checkboxes.filter("[data-item-type=FOLDER]").is(":checked")) {
      self.$("[data-action=open-move-modal]").addClass("hide");
      self.$("[data-action=open-duplicate-modal]").addClass("hide");
    }
  },

  // Prevents unwanted events
  prevent: function (e) {
    e.preventDefault();
  },

  // // Navigates to the template details page
  // showTemplate: function (e) {
  //   var id = $(e.currentTarget).data("id");
  //   e.preventDefault();
  //   app.router.navigateTo("templates" + id);
  // },

  // Opens a modal to create a folder
  openCreateFolderModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/folder.hbs"), self.renderData());
  },

  // Creates a folder
  createFolder: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = self.collection.currentUrl,
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      success: function (response) {
        // Close modal
        modal.modal("hide");

        // Display feedback
        self.displayMessage(window.lang.designs.feedback.folderCreated);

        // Reload current section
        self.urlFilter({
          key: "filter.location",
          value: response.data.id,
        });
      },
      error: function (xhr) {
        var error = xhr.responseJSON.error;

        // Show remote validation errors
        if (error.validationErrors !== undefined) {
          return self.showValidationErrors(error.validationErrors);
        }
      },
    });
  },

  // Opens a modal to rename an item
  openRenameModal: function () {
    this.openModal(require("../templates/modals/rename.hbs"), {
      data: this.getFirstChecked(),
    });
  },

  // Renames a file
  rename: function (e) {
    var form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Performs an AJAX call to the rename endpoint
    this.getFirstChecked()
      .rename(data)
      .done(
        function () {
          this.reload();
          this.getModal().modal("hide");
        }.bind(this)
      );
  },

  // Opens a modal to duplicate a template
  openDuplicateModal: function () {
    var self = this,
      data = {
        lang: window.lang,
        data: self.getFirstChecked(),
      };
    self.openModal(require("../templates/modals/duplicate.hbs"), data);
  },

  // Renames a file
  duplicate: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = "templates" + form.attr("action") + "/duplicate",
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      success: function (response) {
        // Close modal
        modal.modal("hide");
        console.log(response.data);

        // Navigate to duplicated template
        app.router.navigateTo(`templates${response.data.id}`);
      },
    });
  },

  // Opens a modal to move items
  openMoveModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/move.hbs"), self.renderData());
    self.exploreFolders();
  },

  // Explores the shared folders
  exploreFolders: function (e) {
    var self = this,
      template = require("../templates/modals/folders.hbs"),
      target = self.$("[data-role=folder-list]"),
      path = "",
      parentFolder = false,
      targetName = window.lang.root,
      targetLocation = "/",
      moveButtonText = $(self.modal).find("[type=submit]").find("span"),
      locationInput = $(self.modal).find("[name=location]");

    // If needed, prevent the event default
    if (e && e.preventDefault !== undefined) {
      e.preventDefault();

      // Get path parameter
      path = $(e.currentTarget).data("path");

      // Get parent folder path
      if (path !== "/") {
        parentFolder = path.substring(0, path.lastIndexOf("/") + 1);
      }

      // Get folder name
      targetName = $(e.currentTarget).data("name");

      // Get folder ID
      targetLocation = $(e.currentTarget).data("path");
    }

    // Turn on loader
    self.loading(true);

    // Request folder list
    $.ajax({
      url: "templates" + path,
      data: {
        filter: {
          type: "folder",
        },
        limit: 1000,
      },
      success: function (response) {
        // Render explorer
        target.html(
          template({
            data: response.data,
            parentFolder: parentFolder,
            targetName: targetName,
            lang: window.lang,
          })
        );

        // Write target folder name
        moveButtonText.text(targetName);

        // Set value of location input
        locationInput.val(targetLocation);

        // Turn off loader
        self.loading(false);
      },
    });
  },

  // Move templates
  move: function (e) {
    var self = this,
      form = $(e.currentTarget),
      items = self.getChecked(),
      data = form.serializeObject(),
      deferreds = [],
      resolved = 0,
      notMoved = [];
    e.preventDefault();

    // Create array of deferreds
    $.each(items, function (key, item) {
      deferreds.push(
        $.ajax({
          type: "PUT",
          data: data,
          url: "templates" + item.id,
          error: function (xhr) {
            // Catch non moved templates
            if (xhr.status === 409) {
              notMoved.push(xhr.responseJSON.data.name);
            }
          },
        })
          .done(function () {
            resolved++;
          })
          .fail(function () {
            resolved++;
          })
          .always(function () {
            // When all deferreds are resolved
            if (resolved === items.length) {
              // Show not moved template names
              if (notMoved.length) {
                self.displayAlert(window.lang.couldNotMove + notMoved.join(", "), "warning");
              }

              // Hide modal
              $(self.modal).modal("hide");

              // Refresh the list when done
              self.reload();
            }
          })
      );
    });

    // Process deferreds
    $.when.apply(null, deferreds);
  },

  // Opens a modal to create an Eddie template
  openCreateEddieTemplateModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/eddieTemplate.hbs"), self.renderData());
  },

  // Creates an Eddie template
  createEddieTemplate: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = self.collection.currentUrl,
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields()) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      success: function (response) {
        // Close modal
        modal.modal("hide");

        // Go to template details
        app.router.navigateTo(`templates${response.data.id}`);
      },
    });
  },

  // Opens a modal to create a template
  openCreateHtmlTemplateModal: function () {
    var self = this;

    // Open HTML file import modal
    self.openModal(require("../templates/modals/htmlTemplate.hbs"), self.renderData(), function () {
      var textarea = $("[name=htmlRaw]").get(0);

      // Start CodeMirror editor
      self.codeMirror = window.CodeMirror.fromTextArea(textarea, {
        lineNumbers: true,
        autofocus: false,
        mode: {
          name: "htmlmixed",
          scriptTypes: [
            { matches: /\/x-handlebars-template|\/x-mustache/i, mode: null },
            {
              matches: /(text|application)\/(x-)?vb(a|script)/i,
              mode: "vbscript",
            },
          ],
        },
      });
      self.codeMirror.refresh();
    });
  },

  // Fires when a tab is focused
  changedTab: function (e) {
    var self = this;

    // Set new source
    self.source = $(e.currentTarget).data("target").replace("#", "");

    // Refresh Code Mirror editor
    if (self.codeMirror !== undefined) {
      self.codeMirror.refresh();
    }
  },

  // Add active class to hovered dropzone
  addDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).addClass("active");
  },

  // Remove active class from hovered dropzone
  removeDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).removeClass("active");
  },

  // Manages dropped files
  getDroppedFiles: function (e) {
    var self = this,
      files = e.originalEvent.dataTransfer.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.previewFile(files[0]);
  },

  // Open the file selector
  openFileSelection: function () {
    $("[data-input-type=upload-file]").trigger("click");
  },

  // Manage selected files
  getSelectedFiles: function (e) {
    var self = this,
      files = e.currentTarget.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.previewFile(files[0]);
  },

  // Show selected file name
  previewFile: function (file) {
    var self = this;

    // Check file type
    if (file.type !== "text/html") {
      return alert("Incorrect type");
    }

    // Update inteface
    $(self.uploader.dropzone).hide();
    $(self.modal).find(".filename").text(file.name);
    $(self.modal).find('[data-validation-message="htmlFile"]').addClass("error-hidden");

    // Save file reference
    self.file = file;
  },

  // Creates an HTML template
  createHtmlTemplate: function (e) {
    var self = this,
      form = $(e.currentTarget),
      fields = form.serializeObject(),
      modal = $(self.modal),
      validationErrors = {},
      formData = new FormData();
    e.preventDefault();

    // Remove error messages from the view
    self.cleanFormErrorMessages(self.modal);

    // Add type
    formData.append("type", "HTML");

    // Check template name
    if (fields.name === undefined || fields.name.trim() === "") {
      validationErrors.name = window.lang.invalidRequired;
    } else {
      formData.append("name", fields.name);
    }

    // Check if a file is selected on file source
    if (self.source === "htmlFile") {
      if (!self.file) {
        validationErrors.htmlFile = window.lang.fileRequired;
      } else {
        formData.append("htmlFile", self.file);
      }
    }

    // Check if a file is selected on file source
    if (self.source === "htmlRaw") {
      if (fields.htmlRaw === undefined || fields.htmlRaw.trim() === "") {
        validationErrors.htmlRaw = window.lang.invalidRequired;
      } else {
        formData.append("htmlRaw", fields.htmlRaw);
      }
    }

    // Check if the URL is valid on url source
    if (self.source === "htmlUrl") {
      if (fields.htmlUrl === undefined || fields.htmlUrl.trim() === "") {
        if (!self.validateFormFields(form)) {
          return self;
        }
      } else {
        formData.append("htmlUrl", fields.htmlUrl);
      }
    }

    // Show errors
    if (_.size(validationErrors)) {
      return self.showValidationErrors(validationErrors, modal);
    }

    // Start loader
    self.loading(true);

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: self.collection.currentUrl,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: false,
      success: function (response) {
        // Stop loader
        self.loading(false);

        // Close modal
        modal.modal("hide");

        // Display feedback
        self.displayMessage(window.lang.designs.feedback.contentCreated);

        // Go to template details
        app.router.navigateTo(`templates${response.data.id}`);
      },
    });
  },
});
