var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n      data-require-permission='lists:update' ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-default' data-action='open-export-modal'\n        data-require-permission='contacts:export'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"export")), depth0))
    + "\n      </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button type='button' class='btn btn-default' data-action='analyze'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"analyze")), depth0))
    + "\n      </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            -\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":12},"end":{"line":162,"column":21}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span\n              class='label "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"params"))) && lookupProperty(stack1,"filter"))) && lookupProperty(stack1,"tag")),depth0,{"name":"is","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":161,"column":27},"end":{"line":161,"column":108}}})) != null ? stack1 : "")
    + "'>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " label-default ";
},"15":function(container,depth0,helpers,partials,data) {
    return " label-outline ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='lists section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'>\n        <i class='icon icon-back'></i>\n      </a>\n      "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n      <small class='lift'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"created")), depth0))
    + "\n        "
    + alias2(__default(require("../../../helpers/handlebars/timeago.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"created") : stack1),{"name":"timeago","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":43}}}))
    + "\n      </small>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n    <button type='button' class='btn btn-primary' data-action='edit' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":69},"end":{"line":17,"column":52}}})) != null ? stack1 : "")
    + ">\n      <i class='fa fa-pencil'></i>\n      "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"edit")), depth0))
    + "\n    </button>\n    <div class='btn-group' role='group'>\n      <button type='button' class='btn btn-default' data-action='unsubscribe'\n        data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"confirmUnsubscribe")), depth0))
    + "' data-require-permission='contacts:update'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"unsubscribe")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='respawn'\n        data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"confirmRespawn")), depth0))
    + "' data-require-permission='contacts:respawn'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"respawn")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-clean-modal'\n        data-require-permission='contacts:update contacts:delete'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"clean")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-filter-modal'\n        data-require-permission='contacts:update lists:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"filter")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-duplicate-modal'\n        data-require-permission='contacts:update lists:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-split-modal'\n        data-require-permission='contacts:update lists:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"split")), depth0))
    + "\n      </button>\n      <button type='button' class='btn btn-default' data-action='open-sample-modal'\n        data-require-permission='contacts:update lists:create'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"sample")), depth0))
    + "\n      </button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"details"))) && lookupProperty(stack1,"account"))) && lookupProperty(stack1,"features"))) && lookupProperty(stack1,"EXPORT_CONTACTS_HIDDEN")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":55,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":6},"end":{"line":60,"column":13}}})) != null ? stack1 : "")
    + "      <button type='button' class='btn btn-danger' data-action='open-delete-modal' data-class='btn-danger'\n        data-require-permission='lists:delete'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n      </button>\n    </div>\n  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <div class='panel panel-default'>\n        <div class='panel-body'>\n          <div class='row'>\n            <div class='col-md-3'>\n              <a href='/contacts?filter.list="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n                <div class='icon-box total-contacts'>\n                  <i class='icon icon-lists-show-main'></i>\n                  <h4>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":77,"column":20},"end":{"line":77,"column":67}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"plural")), depth0))
    + "\n                  </h4>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"totalContactsInList")), depth0))
    + "\n                  </p>\n                </div>\n              </a>\n            </div>\n\n            <div class='col-md-3'>\n              <a href='/contacts?filter.list="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "&filter.state=LIVE'>\n                <div class='icon-box contacts-with-opens'>\n                  <div class=\"pie opened\">\n                    <div class=\"chart\" data-percent=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"liveContactsP") : stack1), depth0))
    + "\" data-bar-color=\"#93C52D\"\n                      data-track-color=\"#DFEFB4\">\n                      <span class=\"percent\">\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"liveContactsP") : stack1), depth0))
    + "%\n                      </span>\n                    </div>\n                  </div>\n                  <h4>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"withOpens")), depth0))
    + "\n                  </h4>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"contactsWithAtLeastOneOpen")), depth0))
    + "\n                  </p>\n                </div>\n              </a>\n            </div>\n\n            <div class='col-md-3'>\n              <a href='/contacts?filter.list="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "&filter.state=INACTIVE'>\n                <div class='icon-box inactive-contacts'>\n                  <div class=\"pie inactive\">\n                    <div class=\"chart\" data-percent=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"inactiveContactsP") : stack1), depth0))
    + "\" data-bar-color=\"#FF5722\"\n                      data-track-color=\"#FFD0C1\">\n                      <span class=\"percent\">\n                        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"inactiveContactsP") : stack1), depth0))
    + "%\n                      </span>\n                    </div>\n                  </div>\n                  <h4>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"inactive")), depth0))
    + "\n                  </h4>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"unsubscribedAndBouncedContacts")), depth0))
    + "\n                  </p>\n                </div>\n              </a>\n            </div>\n\n\n\n            <div class='col-md-3'>\n              <div class='icon-box contacts-quality'>\n                <i class='icon icon-lists-show-quality'></i>\n                <div class='stars rating-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"rating") : stack1), depth0))
    + "'></div>\n                <p>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"averageQualityOfActiveContacts")), depth0))
    + "\n                </p>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class='panel panel-default'>\n        <div class='panel-body panel-body-padded'>\n          <p class='field-name'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"description")), depth0))
    + "\n          </p>\n          <p class='field-data'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":148,"column":12},"end":{"line":152,"column":19}}})) != null ? stack1 : "")
    + "          </p>\n          <p class='field-name'>\n            "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"tags"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":155,"column":12},"end":{"line":155,"column":49}}}))
    + "\n          </p>\n          <p class='field-data'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":158,"column":12},"end":{"line":165,"column":19}}})) != null ? stack1 : "")
    + "          </p>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});