<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div>
      <div class="flex items-center justify-between">
        <h1 v-if="pdfMode" class="mx-1 whitespace-nowrap text-base font-medium">
          {{ t(selection) }}
        </h1>
        <div v-else class="flex items-center justify-between space-x-4">
          <ToggleButtons v-model="selection" :buttons="selectionOptions" :disabled="chartType !== 'column'" />
          <ToggleButtons v-model="selectionP" :buttons="selectionPOptions" :disabled="selectionPDisabled" />
        </div>
        <div v-if="!pdfMode" class="ml-4 flex flex-shrink items-center space-x-4">
          <SimpleSwitch v-model="compare" :small="true" :disabled="chartType === 'spline'">
            <label
              class="over m-0 block overflow-ellipsis whitespace-nowrap text-sm font-normal"
              :class="[chartType === 'spline' ? 'text-gray-300' : 'cursor-pointer text-gray-700']"
              >{{ t("compare") }}</label
            >
          </SimpleSwitch>
          <ToggleButtons
            v-model="chartType"
            :buttons="[
              { id: 'column', label: t('columnsChart'), icon: ChartBarIcon },
              { id: 'spline', label: t('linesChart'), icon: TrendingUpIcon },
            ]"
          />
        </div>
      </div>
    </div>
    <div class="mt-6">
      <Chart :options="chartOptions" :loading="loading" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, PropType, toRef, watchEffect, inject, toRaw, watch } from "vue";
import Chart from "@molecules/Chart.vue";
import { ChartBarIcon, TrendingUpIcon } from "@heroicons/vue/solid";
import { useI18n } from "vue-i18n";
import { DashboardData, IEmailMetrics } from "@domain/dashboard/dashboard";
import SimpleSwitch from "@molecules/SimpleSwitch.vue";
import ToggleButtons, { ToggleOption } from "@atoms/ToggleButtons.vue";
import {
  useCharts,
  CategoryResolvers,
  Accumulator,
  CategoryResolver,
  ChartPresets,
  sumIfCurrent,
  sumIfPrevious,
} from "@composables/charts";

//Store
import { storeToRefs } from "pinia";
import { useUserConfigStore } from "@/vue/stores/userConfigStore/userConfigStore";

interface IChartPoint {
  current: {
    sent: number;
    opened: number;
    clicked: number;
    bounced: number;
    unsubscribed: number;
    complained: number;
  };
  previous: {
    sent: number;
    opened: number;
    clicked: number;
    bounced: number;
    unsubscribed: number;
    complained: number;
  };
}

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  batchType: {
    type: String as PropType<"all" | "bulk" | "automation" | "transactional">,
    default: "all",
    validator: (value: string) => {
      return ["all", "bulk", "automation", "transactional"].includes(value);
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const userConfigStore = useUserConfigStore();

const { dashBoardState } = storeToRefs(userConfigStore);

const { t, n, d } = useI18n();

const selection = ref<"sent" | "opened" | "clicked" | "openedP" | "clickedP">(
  dashBoardState.value.chartEmails.selection || "sent"
);
const selectionP = ref<"number" | "percent">(dashBoardState.value.chartEmails.selectionP || "number");

const selectionOptions = computed<ToggleOption[]>(() => {
  return [
    {
      id: "sent",
      label: t("sent"),
    },
    {
      id: "opened",
      label: t("opened"),
    },
    // {
    //   id: "openedP",
    //   label: t("openedP"),
    //   disabled: props.data && props.data.period.days <= 7,
    // },
    {
      id: "clicked",
      label: t("clicked"),
    },
    // {
    //   id: "clickedP",
    //   label: t("clickedP"),
    //   disabled: props.data && props.data.period.days <= 7,
    // },
  ];
});

const selectionPOptions = computed<ToggleOption[]>(() => {
  return [
    {
      id: "number",
      label: "#",
    },
    {
      id: "percent",
      label: "%",
      disabled: props.data && props.data.period.days <= 7, // || selection.value === "sent",
    },
  ];
});

const selectionPDisabled = computed(() => {
  return (props.data && props.data.period.days <= 7) || chartType.value !== "column" || selection.value === "sent";
});

watchEffect(() => {
  if (props.data && props.data.period.days <= 7 && selectionP.value === "percent") {
    selection.value = "sent";
  }
});

watchEffect(() => {
  if (selectionP.value === "percent" && selection.value === "sent") {
    selectionP.value = "number";
  }
});

const chartType = ref<"column" | "spline">(dashBoardState.value.chartEmails.chartType || "column");
const compare = ref(dashBoardState.value.chartEmails.compare || false);

watch([selection, selectionP, chartType, compare], () => {
  dashBoardState.value = {
    ...toRaw(dashBoardState.value),
    chartEmails: {
      ...toRaw(dashBoardState.value.chartEmails),
      selection: selection.value,
      selectionP: selectionP.value,
      chartType: chartType.value,
      compare: compare.value,
    },
  };
});

const newChartPoint = (): IChartPoint => ({
  current: {
    sent: 0,
    opened: 0,
    clicked: 0,
    bounced: 0,
    unsubscribed: 0,
    complained: 0,
  },
  previous: {
    sent: 0,
    opened: 0,
    clicked: 0,
    bounced: 0,
    unsubscribed: 0,
    complained: 0,
  },
});

// const map = (dp: IDashboardDataPoint) =>{
// }

const sumByBatchType = (metrics: Record<string, IEmailMetrics>, metric: string): number => {
  let sum = 0;
  if (props.batchType === "all" || props.batchType === "bulk") sum += metrics.bulk[metric];
  if (props.batchType === "all" || props.batchType === "automation") sum += metrics.automation[metric];
  if (props.batchType === "all" || props.batchType === "transactional") sum += metrics.transactional[metric];
  return sum;
};

const accumulate: Accumulator<IChartPoint> = (acc, newData, periodType) => {
  return {
    current: {
      sent: sumIfCurrent(periodType, acc.current.sent, sumByBatchType(newData.mail_metrics, "sent")),
      opened: sumIfCurrent(periodType, acc.current.opened, sumByBatchType(newData.mail_metrics, "first_opened")),
      clicked: sumIfCurrent(periodType, acc.current.clicked, sumByBatchType(newData.mail_metrics, "first_clicked")),
      bounced: sumIfCurrent(periodType, acc.current.bounced, sumByBatchType(newData.mail_metrics, "bounced_total")),
      unsubscribed: sumIfCurrent(
        periodType,
        acc.current.unsubscribed,
        sumByBatchType(newData.mail_metrics, "unsubscribed_total")
      ),
      complained: sumIfCurrent(
        periodType,
        acc.current.complained,
        sumByBatchType(newData.mail_metrics, "complained_total")
      ),
    },
    previous: {
      sent: sumIfPrevious(periodType, acc.previous.sent, sumByBatchType(newData.mail_metrics, "sent")),
      opened: sumIfPrevious(periodType, acc.previous.opened, sumByBatchType(newData.mail_metrics, "first_opened")),
      clicked: sumIfPrevious(periodType, acc.previous.clicked, sumByBatchType(newData.mail_metrics, "first_clicked")),
      bounced: sumIfPrevious(periodType, acc.previous.bounced, sumByBatchType(newData.mail_metrics, "bounced_total")),
      unsubscribed: sumIfPrevious(
        periodType,
        acc.previous.unsubscribed,
        sumByBatchType(newData.mail_metrics, "unsubscribed_total")
      ),
      complained: sumIfPrevious(
        periodType,
        acc.previous.complained,
        sumByBatchType(newData.mail_metrics, "complained_total")
      ),
    },
  };
};

const categoryResolver = computed<CategoryResolver>(() => {
  if (chartType.value === "spline" || (props.data && props.data.period.days <= 7)) return CategoryResolvers.daily;
  if (props.data && props.data.period.days > 35) return CategoryResolvers.monthly;
  return CategoryResolvers.weekly;
});

const { chartData } = useCharts(toRef(props, "data"), categoryResolver, accumulate, newChartPoint, compare, (date) =>
  props.data?.period.isWithinSameYear() ? d(date, "short") : d(date, "shortWithYear")
);

const plotOptions = pdfMode
  ? {
      ...ChartPresets.plotOptions,
      series: {
        ...ChartPresets.plotOptions.series,
        animation: false,
      },
    }
  : ChartPresets.plotOptions;

const chartOptions = computed(() => {
  const options: any = {
    title: "",
    tooltip: ChartPresets.tooltip((val) => n(val, "decimal")),
    chart: pdfMode ? { width: 1266 } : {},
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      ...ChartPresets.xAxis,
      categories: chartData.value && Object.keys(chartData.value),
      tickInterval: chartData.value && ChartPresets.tickIntervaler(Object.keys(chartData.value).length),
    },
    credits: false,
    plotOptions: plotOptions,
    legend: {
      ...ChartPresets.legend,
    },
    series: [],
  };

  if (selection.value === "sent" || chartType.value == "spline") {
    options.series.push({
      name: t("sent"),
      id: "sent",
      index: 1,
      color: ChartPresets.colors.blue,
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.sent),
      type: chartType.value,
    });
  }

  if (chartType.value == "spline" || (selection.value === "opened" && selectionP.value === "number")) {
    options.series.push({
      name: t("opened"),
      id: "opened",
      index: 3,
      color: ChartPresets.colors.green,
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.opened),
      type: chartType.value,
    });
  }

  if (chartType.value === "column" && selection.value === "opened" && selectionP.value === "percent") {
    options.series.push({
      name: t("openedP"),
      id: "openedP",
      index: 3,
      color: ChartPresets.colors.green,
      data:
        chartData.value &&
        Object.values(chartData.value).map((v) => (100 * v.current.opened) / (v.current.sent - v.current.bounced) || 0),
      type: chartType.value,
    });
  }

  if (chartType.value == "spline" || (selection.value === "clicked" && selectionP.value === "number")) {
    options.series.push({
      name: t("clicked"),
      id: "clicked",
      index: 5,
      color: ChartPresets.colors.emerald,
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.clicked),
      type: chartType.value,
    });
  }

  if (chartType.value === "column" && selection.value === "clicked" && selectionP.value === "percent") {
    options.series.push({
      name: t("clickedP"),
      id: "clickedP",
      index: 5,
      color: ChartPresets.colors.emerald,
      data:
        chartData.value && Object.values(chartData.value).map((v) => (100 * v.current.clicked) / v.current.opened || 0),
      type: chartType.value,
    });
  }
  if (chartType.value == "spline") {
    options.series.push({
      name: t("bounced"),
      id: "bounced",
      index: 7,
      color: ChartPresets.colors.orange, //"rgba(222, 235, 178, 0.9)",
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.bounced),
      type: chartType.value,
    });
    options.series.push({
      name: t("unsubscribed"),
      id: "unsubscribed",
      index: 9,
      color: ChartPresets.colors.purple,
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.unsubscribed),
      type: chartType.value,
    });
    options.series.push({
      name: t("complained"),
      id: "complained",
      index: 11,
      color: ChartPresets.colors.rose,
      data: chartData.value && Object.values(chartData.value).map((v) => v.current.complained),
      type: chartType.value,
    });
  }

  if (chartType.value === "column" && compare.value) {
    if (selection.value === "sent") {
      options.series.push({
        name: t("sentPrev"),
        id: "prev-sent",
        index: 0,
        color: ChartPresets.colors.lightBlue,
        data: chartData.value && Object.values(chartData.value).map((v) => v.previous.sent),
        type: chartType.value,
        linkedTo: "sent",
        pointPlacement: 0.02,
      });
    }

    if (selection.value === "opened" && selectionP.value === "number") {
      options.series.push({
        name: t("openedPrev"),
        id: "prev-opened",
        index: 2,
        color: ChartPresets.colors.lightGreen,
        data: chartData.value && Object.values(chartData.value).map((v) => v.previous.opened),
        type: chartType.value,
        linkedTo: "opened",
        pointPlacement: 0.02,
      });
    }

    if (selection.value === "opened" && selectionP.value === "percent") {
      options.series.push({
        name: t("openedPPrev"),
        id: "prev-openedP",
        index: 2,
        color: ChartPresets.colors.lightGreen,
        data:
          chartData.value &&
          Object.values(chartData.value).map(
            (v) => (100 * v.previous.opened) / (v.previous.sent - v.previous.bounced) || 0
          ),
        type: chartType.value,
        linkedTo: "openedP",
        pointPlacement: 0.02,
      });
    }

    if (selection.value === "clicked" && selectionP.value === "number") {
      options.series.push({
        name: t("clickedPrev"),
        id: "prev-clicked",
        index: 4,
        color: ChartPresets.colors.lightEmerald,
        data: chartData.value && Object.values(chartData.value).map((v) => v.previous.clicked),
        type: chartType.value,
        linkedTo: "clicked",
        pointPlacement: 0.02,
      });
    }

    if (selection.value === "clicked" && selectionP.value === "percent") {
      options.series.push({
        name: t("clickedPPrev"),
        id: "prev-clickedP",
        index: 4,
        color: ChartPresets.colors.lightEmerald,
        data:
          chartData.value &&
          Object.values(chartData.value).map((v) => (100 * v.previous.clicked) / v.previous.opened || 0),
        type: chartType.value,
        linkedTo: "clickedP",
        pointPlacement: 0.02,
      });
    }
  }

  return options;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "sent": "Envíos",
    "opened": "Aperturas",
    "openedP": "Aperturas %",
    "clicked": "Clicks",
    "clickedP": "Clicks %",
    "bounced": "Rebotes",
    "unsubscribed": "Desuscripciones",
    "complained": "Marcados como spam",
    "sentPrev": "Envíos (ant.)",
    "openedPrev": "Aperturas (ant.)",
    "openedPPrev": "Aperturas % (ant.)",
    "clickedPrev": "Clicks (ant.)",
    "clickedPPrev": "Clicks % (ant.)",
    "bouncedPrev": "Rebotes (ant.)",
    "unsubscribedPrev": "Desuscripciones (ant.)",
    "complainedPrev": "Marcados como spam (ant.)",
    "compare": "Comparar con período anterior",
    "columnsChart": "Gráfico de barras",
    "linesChart": "Gráfico de líneas"
  },
  "pt": {
    "sent": "Envios",
    "opened": "Aberturas",
    "openedP": "Aberturas %",
    "clicked": "Cliques",
    "clickedP": "Cliques %",
    "bounced": "Rejeições",
    "unsubscribed": "Descadastrados",
    "complained": "Marcados como spam",
    "sentPrev": "Envios (ant.)",
    "openedPrev": "Aberturas (ant.)",
    "openedPPrev": "Aberturas % (ant.)",
    "clickedPrev": "Cliques (ant.)",
    "clickedPPrev": "Cliques % (ant.)",
    "bouncedPrev": "Rejeições (ant.)",
    "unsubscribedPrev": "Descadastrados (ant.)",
    "complainedPrev": "Marcados como spam (ant.)",
    "compare": "Comparar com o período anterior",
    "columnsChart": "Gráfico de barras",
    "linesChart": "Gráfico de linhas"
  }
}
</i18n>
