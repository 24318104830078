import Bugsnag from "@bugsnag/js";
import app from "@/app";
const moment = window.moment;

// eslint-disable-next-line no-undef
window.config = CONFIG;

Bugsnag.start({
  apiKey: window.config.bugsnagApiKey,
  releaseStage: window.config.environment,
  appVersion: window.config.version,
});

export default {
  bugsnag: Bugsnag,

  intercom: {
    isMasterUser: false,
    show: true,
    data: null,

    // Booting
    boot: function () {
      if (window.Intercom !== undefined) {
        window.Intercom("boot", {
          app_id: window.config.intercomId,
        });
      }
    },

    // Update Intercom
    update: function (data) {
      data = data || this.data || null;
      this.data = data;
      if (window.Intercom !== undefined) {
        window.Intercom("update", this.data);
      }
    },

    // Sends an Intercom message
    message: function (message) {
      if (window.Intercom !== undefined) {
        window.Intercom("showNewMessage", message);
      }
    },

    event: function (name, data, includeMasterUsers) {
      if (!window.Intercom) return;
      if (!includeMasterUsers && this.isMasterUser) return false;

      window.Intercom("trackEvent", name, data);
      setTimeout(function () {
        window.Intercom("update");
      }, 1000);
    },
  },

  amplitude: {
    isMasterUser: false,

    boot: function () {
      if (!window.amplitude) return;

      window.amplitude.getInstance().init(window.config.amplitudeId);
    },

    identify: function (account, properties) {
      if (!window.amplitude) return;

      window.amplitude.getInstance().setUserId(account);
      window.amplitude.getInstance().setUserProperties(properties);

      if (this.isMasterUser) {
        window.amplitude.getInstance().logEvent("MASTERUSER_ACCESS", { master_user: app.session.attributes.user });
      }
    },

    event: function (name, eventProperties, includeMasterUsers) {
      if (!window.amplitude) return;
      if (!includeMasterUsers && this.isMasterUser) return;

      if (this.isMasterUser) {
        window.amplitude.getInstance().logEvent(name, { master_user: app.session.attributes.user, ...eventProperties });
      } else {
        window.amplitude
          .getInstance()
          .logEvent(name, { account_user: app.session.attributes.user, ...eventProperties });
      }
    },
  },

  salesmachine: {
    identify: function (account, user, properties) {
      if (!window.salesmachine) return;
      window.salesmachine.contact(user, {
        ...properties,
        account_uid: account,
      });
    },

    pageview: function (account, user) {
      if (!window.salesmachine) return;
      window.salesmachine.track(user, "pageview", {
        account_uid: account,
      });
    },
  },

  gtm: {
    event: function (data) {
      if (window.dataLayer !== undefined && window.dataLayer.push !== undefined) {
        window.dataLayer.push(data);
      }
    },
  },

  // Starts integrations
  start: function () {
    var session = app.session.toJSON(),
      account = session.account,
      details = session.details || {},
      mainAccount = session.mainAccount,
      companyDetails;

    // Set account name or 'masterusers'
    if (session.userType === "MASTER" || session.isMasterUser) {
      this.intercom.isMasterUser = true;
      mainAccount = "masterusers";
      companyDetails = {
        id: mainAccount,
        name: "Master Users",
      };
    } else {
      // If current account is the mainAccount, send account details to Intercom
      if (account === mainAccount) {
        companyDetails = this.getCompanyDetails();
      } else {
        companyDetails = {
          id: account,
          parent: mainAccount,
        };
      }
    }

    // Identify user in Bugsnag
    Bugsnag.setUser(
      session.isMasterUser ? session.user + "@masterusers" : account,
      details.user.email,
      details.user.name
    );
    // Delete auth token from session object for security reasons, before including in bugsnag report
    delete session.token;
    // Attach session info to Bugsnag reports
    Bugsnag.addMetadata("session", session);

    // Identify user in Intecom
    this.intercom.update({
      user_id: session.user + "@" + mainAccount,
      name: details.user.name,
      email: details.user.email,
      created_at: moment(details.user.created).unix(),
      role: details.user.role,
      company: companyDetails,
    });

    if (session.userType !== "MASTER") {
      // Identify user in Salesmachine
      this.salesmachine.identify(mainAccount, session.user + "@" + mainAccount, {
        name: details.user.name,
        email: details.user.email,
      });
      this.salesmachine.pageview(mainAccount, session.user + "@" + mainAccount);
    }

    // Boot Amplitude
    if (session.userType !== "MASTER") {
      this.amplitude.isMasterUser = false;
      this.amplitude.identify(account, {
        plan: details.plan.type,
        plan_state: details.plan.state,
        plan_subscription_usd: details.plan.subscription === undefined ? 0 : details.plan.subscription.price.totalUSD,
        is_agency: details.account.container,
        //children: details.stats.children,
        industry: details.account.industry,
      });
    } else {
      this.amplitude.isMasterUser = true;
      this.amplitude.identify(account);
    }
  },

  // Creates an object to send to the integrations
  getCompanyDetails: function () {
    var session = app.session.toJSON(),
      account = session.account,
      details = session.details || {},
      name = "";

    if (details?.account?.name) {
      name = details.account.name;
    }

    return {
      id: account,
      name: name,
      created_at: moment(details.account.created).unix(),
      parent: details.account.parent,
      is_agency: details.account.container,
      industry: details.account.industry,
      monthly_spend: details.plan.subscription === undefined ? 0 : details.plan.subscription.price.totalUSD,
      plan: details.plan.type,
      plan_state: details.plan.state,
      plan_emails: details.plan.emails !== undefined ? details.plan.emails.limit : 0,
      plan_emails_available: details.plan.emails !== undefined ? details.plan.emails.available : 0,
      plan_contacts: details.plan.contacts !== undefined ? details.plan.contacts.limit : 0,
      contacts_active: details.plan.contacts !== undefined ? details.plan.contacts.active : 0,
    };
  },
};
