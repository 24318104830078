<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" class="fixed inset-0 z-80" @close="close">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay
          class="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm backdrop-filter transition-opacity"
        />
      </TransitionChild>

      <div class="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="flex max-h-[95vh] w-full max-w-5xl transform flex-col overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all"
          >
            <div class="relative">
              <SimpleInput
                v-model="search"
                :placeholder="placeholder ?? t('defaultPlaceholder')"
                class="rounded-b-none border-0 border-b py-4 pl-12"
              />
              <SearchIcon class="absolute left-4 top-4 h-5 w-5 text-gray-500" />
            </div>
            <div class="flex grow flex-col overflow-y-auto overflow-x-hidden">
              <ListC
                v-show="!skeleton && (filteredLists.length > 0 || preSelected.length > 0)"
                :selected="preSelected"
                :filtered-lists="filteredLists"
                :selected-list-keys="selectedListKeys"
                class="flex-grow"
                @selectList="selectList"
              />
              <div v-show="skeleton" class="flex h-14 items-center border-b border-gray-100 px-8">
                <div class="h-4 w-20 animate-pulse rounded bg-gray-100" />
              </div>
              <div
                v-for="index in 5"
                v-show="skeleton"
                :key="index"
                class="flex h-14 animate-pulse items-center space-x-2 border-b border-gray-100 px-8"
              >
                <div class="h-4 w-80 animate-pulse rounded bg-gray-100" />
                <div class="h-4 w-28 animate-pulse rounded bg-gray-100" />
                <div class="h-4 w-28 animate-pulse rounded bg-gray-100" />
              </div>
              <span
                v-show="!skeleton && filteredLists.length === 0 && search.length === 0"
                class="h-40 p-6 text-sm font-medium text-gray-900"
              >
                {{ noListsText ?? t("noLists") }}
              </span>
              <div
                v-if="allowCreate"
                v-show="!searchExactMatch"
                :class="[{ 'h-40': filteredLists.length === 0 }, 'w-full']"
              >
                <button
                  v-show="search.length > 0"
                  :class="['flex w-full items-center space-x-1 px-8 py-4 hover:bg-sky-50']"
                  @click="() => createList(search)"
                >
                  <UserGroupIcon class="h-6 w-6 text-gray-700" />
                  <h3 class="m-0 text-base font-medium text-gray-700">
                    {{ t("createNewList") }}: <span class="text-sm italic text-gray-500">"{{ search }}"</span>
                  </h3>
                  <LoadingSpinner v-show="loadingCreate" class="h-5 w-5 text-sky-500" aria-hidden="true" />
                </button>
              </div>
            </div>

            <div class="flex justify-between p-6">
              <div class="flex items-center space-x-2 text-sm font-medium text-gray-400">
                <span>
                  {{ t("selectedLists", { count: selected.length, total: countLists }, { plural: selected.length }) }}
                </span>
                <span class="h-4 w-4">
                  <slot v-if="$slots.icon" name="icon" />
                </span>
              </div>
              <div class="flex space-x-4">
                <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
                <SimpleButton :disabled="selected.length === 0" :loading="loadingAccept" @click="accept">
                  <template #leading>
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </template>
                  {{ t("acceptButton") }}
                </SimpleButton>
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script
  lang="ts"
  setup
  generic="
    ListId extends string = string,
    ListValueType extends string = string,
    KeyType extends string = string,
    ValueType extends string = string,
    DataType = Record<string, unknown>
  "
>
import { ref, shallowRef, computed, watch } from "vue";

import ListC from "./components/List.vue";
import type { List, ListItems, Lists, ListDataItem } from "./listSelector.types";

// Components
import SimpleInput from "@atoms/SimpleInput.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import { Dialog, DialogPanel, TransitionRoot, DialogOverlay, TransitionChild } from "@headlessui/vue";

// Icon
import { CheckIcon, SearchIcon, UserGroupIcon } from "@heroicons/vue/solid";

// Helpers
import { formatTextToSearch } from "@helpers/formatters";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open?: boolean;
    loadingAccept?: boolean;
    loadingCreate?: boolean;
    placeholder?: string;
    noListsText?: string;
    allowCreate?: boolean;
    skeleton?: boolean;
    lists?: Lists<ListId, ListValueType, KeyType, ValueType, DataType>;
    selected?: ListItems<KeyType, ValueType, DataType>;
  }>(),
  {
    open: false,
    loadingAccept: false,
    loadingCreate: false,
    placeholder: undefined,
    noListsText: undefined,
    allowCreate: false,
    skeleton: false,
    lists: () => [],
    selected: () => [],
  },
);

const emit = defineEmits<{
  "update:selected": [ListItems<KeyType, ValueType, DataType>];
  close: [void];
  accept: [void];
  createList: [string];
}>();

const close = () => {
  emit("close");
};

const accept = () => {
  emit("accept");
};

const createList = (value: string) => {
  emit("createList", value);
  cleanSearch();
};

const preSelected = shallowRef<ListItems<KeyType, ValueType, DataType>>([]);

watch([() => props.open], () => {
  preSelected.value = props.selected;
});

const search = ref("");
const cleanSearch = () => {
  search.value = "";
};

const selectedListKeys = computed<Array<KeyType>>(() => props.selected.map((s) => s.key));

const filteredLists = computed<Lists<ListId, ListValueType, KeyType, ValueType, DataType>>(() => {
  return props.lists.reduce<Lists<ListId, ListValueType, KeyType, ValueType, DataType>>(
    (newLists, list) => {
      const filterList: List<ListId, ListValueType, KeyType, ValueType, DataType> = {
        id: list.id,
        value: list.value,
        list: list.list.filter((l) => {
          const emptySearch = search.value === "";
          const formattedSearch = formatTextToSearch(search.value);
          const searchMatch =
            formatTextToSearch(l.key).includes(formattedSearch) ||
            formatTextToSearch(l.value).includes(formattedSearch);
          return emptySearch || searchMatch;
        }),
      };

      if (filterList.list.length > 0) {
        newLists.push(filterList);
      }

      return newLists;
    },
    [] as Lists<ListId, ListValueType, KeyType, ValueType, DataType>,
  );
});

const countLists = computed(() =>
  props.lists.reduce((count, list) => {
    count += list.list.length;
    return count;
  }, 0),
);

const searchExactMatch = computed(() =>
  props.lists.some((list) =>
    list.list.some((l) => {
      const formattedSearch = formatTextToSearch(search.value);
      const searchMatch = formatTextToSearch(l.value) === formattedSearch;

      return searchMatch;
    }),
  ),
);

const selectList = (listItem: ListDataItem<KeyType, ValueType, DataType>) => {
  const itemIndex = props.selected.findIndex((s) => s.key === listItem.key);

  if (itemIndex !== -1) {
    const filteredSelected = props.selected.filter((s) => s.key !== listItem.key);
    emit("update:selected", filteredSelected);

    return;
  }

  emit("update:selected", [...props.selected, listItem]);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "defaultPlaceholder": "Buscar o crea una lista ingresando el nombre",
    "noLists": "No hay listas para mostrar",
    "createNewList": "Crear",
    "selectedLists": "1 seleccionada de {total} | {count} seleccionadas de {total}"
  },
  "pt": {
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "defaultPlaceholder": "Buscar o crea una lista ingresando el nombre",
    "noLists": "No hay listas para mostrar",
    "createNewList": "Crear",
    "selectedLists": "1 seleccionada de {total} | {count} seleccionadas de {total}"
  }
}
</i18n>
