<template>
  <div
    :class="[{ 'select-none opacity-40': !active }, 'flex items-center justify-between space-x-4 p-4 text-gray-500']"
  >
    <div class="flex items-center gap-x-4">
      <StarIcon v-if="stage === 'champion'" class="h-5 w-5 flex-shrink-0 text-teal-400" />
      <ThumbUpIcon v-if="stage === 'loyalist'" class="h-5 w-5 flex-shrink-0 text-teal-300" />
      <PencilAltIcon v-if="stage === 'recent'" class="h-5 w-5 flex-shrink-0 text-teal-200" />
      <TrendingUpIcon v-if="stage === 'highpotential'" class="h-5 w-5 flex-shrink-0 text-yellow-300" />
      <GiftIcon v-if="stage === 'neednutring'" class="h-5 w-5 flex-shrink-0 text-yellow-200" />
      <InformationCircleIcon v-if="stage === 'cantlose'" class="h-5 w-5 flex-shrink-0 text-orange-300" />
      <TrendingDownIcon v-if="stage === 'atrisk'" class="h-5 w-5 flex-shrink-0 text-orange-200" />
      <XCircleIcon v-if="stage === 'abouttolose'" class="h-5 w-5 flex-shrink-0 text-red-400" />
      <div class="flex-grow-0 space-y-1">
        <p class="text-lg font-medium text-gray-600 md:text-base md:leading-7">
          {{ stageTitle }}
        </p>
        <div class="space-y-1">
          <p class="flex space-x-1 text-sm leading-5 text-gray-500">
            <span :data-intercom-target="'LifecycleRFMDescriptionContacts-' + stage">
              {{
                t("contacts.description", {
                  active: sn(stageContactsCount.activeContacts, "decimal"),
                  total: sn(stageContactsCount.totalContacts, "decimal"),
                })
              }}
            </span>
            <IconBullet
              v-if="stageContactsCount.differenceCount > 0"
              :icon="QuestionMarkCircleIcon"
              :tooltip="
                t(
                  'contacts.tooltip',
                  { count: sn(stageContactsCount.differenceCount, 'decimal') },
                  { plural: stageContactsCount.differenceCount }
                )
              "
              color="lightGray"
            />
          </p>
          <p
            class="text-sm leading-5 text-gray-500"
            :data-intercom-target="'LifecycleRFMDescriptionPredicate-' + stage"
          >
            {{ stageDescription }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <span
        ref="iconRef"
        :class="[{ 'cursor-pointer': active }, 'flex gap-x-2 text-sm font-medium leading-6 hover:text-gray-600']"
        :data-intercom-target="'LifecycleRFMDescriptionGotoList-' + stage"
        @click="onClick"
      >
        {{ t("goToList") }}
        <UserIcon class="h-6" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, toRef, watchEffect } from "vue";

// Components
import IconBullet from "@atoms/IconBullet.vue";

// Icons
import {
  UserIcon,
  StarIcon,
  ThumbUpIcon,
  PencilAltIcon,
  TrendingUpIcon,
  GiftIcon,
  InformationCircleIcon,
  TrendingDownIcon,
  XCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/vue/solid";

// Utils
import { useTooltip } from "@composables/tooltips";
import { Placement } from "@popperjs/core";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { StageNames } from "@/vue/types/lifecycle";
import type { StageContactsCount } from "../dashboardRFM.data";

const { t, n } = useI18n();

const props = withDefaults(
  defineProps<{
    stage: StageNames;
    stageTitle: string;
    stageContactsCount: StageContactsCount;
    stageDescription: string;
    infoTooltip?: string;
    placement?: Placement;
    active?: boolean;
  }>(),
  {
    infoTooltip: "",
    placement: "auto",
    active: true,
  }
);

// Format
const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

// Data
const iconRef = ref();

// Methods
watchEffect(() => {
  if (!props.active) return;
  useTooltip(iconRef, toRef(props, "infoTooltip"), { placement: props.placement });
});

// Emits
const emit = defineEmits<{
  (e: "click"): void;
}>();

const onClick = () => {
  if (!props.active) return;
  emit("click");
};
</script>
<i18n lang="json">
{
  "es": {
    "goToList": "Ir a la lista",
    "contacts": {
      "description": "{active} contactos habilitados de {total} compradores",
      "tooltip": "La diferencia de 1 contacto con los compradores se debe a que pueden estar desuscriptos, rebotados, marcaron tus emails como spam o fueron eliminados.|La diferencia de {count} contactos con los compradores se debe a que pueden estar desuscriptos, rebotados, marcaron tus emails como spam o fueron eliminados."
    }
  },
  "pt": {
    "goToList": "Ir para a lista",
    "contacts": {
      "description": "{active} contatos habilitados de {total} compradores",
      "tooltip": "A diferença de 1 contato com os compradores ocorre porque eles podem estar desinscritos, terem retornado, marcado seus emails como spam ou sido excluídos.|A diferença de {count} contatos com os compradores ocorre porque eles podem estar desinscritos, terem retornado, marcado seus emails como spam ou sido excluídos."
    }
  }
}
</i18n>
