import { Collection } from "@/core/magnet";

export default Collection.extend({
  url: "tasks",
  parse: function (response) {
    // Set percentage for each task
    $.each(response.data, function () {
      if (this.completed !== undefined) {
        this.percentage = Math.ceil(this.completed * 100);
      }
      if (this.eta !== -1) {
        this.minutes = Math.ceil(this.eta / 60);
      }
    });

    // Return updated data
    return response.data;
  },
});
