<script setup lang="ts">
import { ref, toRef, PropType } from "vue";
import { InformationCircleIcon } from "@heroicons/vue/solid";
import { useTooltip } from "@composables/tooltips";
import { Placement } from "@popperjs/core";

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  size: {
    type: String as PropType<"base" | "small">,
    default: "base",
  },
  theme: {
    type: String as PropType<"normal" | "header">,
    default: "normal",
  },
  placement: {
    type: String as PropType<Placement>,
    default: "top",
  },
});

const iconRef = ref();

useTooltip(iconRef, toRef(props, "content"), { placement: props.placement });
</script>

<template>
  <span data-intercom-target="InfoIcon">
    <InformationCircleIcon
      ref="iconRef"
      class="inline align-text-top focus:outline-none"
      :class="[
        size === 'small' ? 'h-4 w-4' : 'h-5 w-5 ',
        theme === 'header' ? 'text-gray-400 hover:text-gray-300' : 'text-gray-300 hover:text-gray-400',
      ]"
    />
  </span>
</template>

<style>
.tippy-box {
  @apply z-50 rounded-md shadow;
}
.tippy-content {
  @apply whitespace-normal px-4 py-3 font-normal;
}
.tippy-content pre {
  @apply mt-2;
}
.tippy-content a {
  @apply whitespace-nowrap text-gray-400 underline transition-colors hover:text-gray-100 hover:underline;
}
</style>
