var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='sidebar'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"config") : depth0)) != null ? lookupProperty(stack1,"whiteLabel") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "    <div class='sidescroll scrollbar flex flex-col justify-between'>\n      <ul class='menu'>\n        <li class='active'>\n          <a href='/dashboard' data-section='dashboard'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--dashboard'></use>\n              <use class='icon-active' xlink:href='#nav--dashboard-a'></use>\n            </svg>\n            <span>\n              "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"singular")), depth0))
    + "\n            </span>\n          </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"inAccount") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":207,"column":15}}})) != null ? stack1 : "")
    + "      </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowSwitchLayout") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":6},"end":{"line":216,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "    <h1 class='brand' data-canny-changelog>\n      Perfit\n    </h1>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='has-childs' data-permission='lists:list'>\n          <a href='/lists' data-section='lists' data-permission='lists:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--lists'></use>\n              <use class='icon-active' xlink:href='#nav--lists-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":34,"column":14},"end":{"line":34,"column":52}}}))
    + "\n            </span>\n          </a>\n          <ul>\n            <li>\n              <a href='/contacts' data-section='contacts' data-permission='contacts:list'>\n                <svg class='icon'>\n                  <use class='icon-normal' xlink:href='#nav--contacts'></use>\n                  <use class='icon-active' xlink:href='#nav--contacts-a'></use>\n                </svg>\n                <span>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":45,"column":59}}}))
    + "\n                </span>\n              </a>\n            </li>\n            <li>\n              <a href='/interests' data-section='interests' data-permission='interests:list'>\n                <svg class='icon'>\n                  <use class='icon-normal' xlink:href='#nav--interests'></use>\n                  <use class='icon-active' xlink:href='#nav--interests-a'></use>\n                </svg>\n                <span>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"interests"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":56,"column":18},"end":{"line":56,"column":60}}}))
    + "\n                </span>\n              </a>\n            </li>\n            <li>\n              <a href='/fields' data-section='fields' data-permission='fields:list'>\n                <svg class='icon'>\n                  <use class='icon-normal' xlink:href='#nav--fields'></use>\n                  <use class='icon-active' xlink:href='#nav--fields-a'></use>\n                </svg>\n                <span>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fields"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":67,"column":18},"end":{"line":67,"column":57}}}))
    + "\n                </span>\n              </a>\n            </li>\n          </ul>\n        </li>\n        <li>\n          <a href='/optins' data-section='optins' data-permission='optins:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--optins'></use>\n              <use class='icon-active' xlink:href='#nav--optins-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":80,"column":14},"end":{"line":80,"column":53}}}))
    + "\n            </span>\n          </a>\n        </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unlayerEnabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":8},"end":{"line":98,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hideLegacyTemplates") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":8},"end":{"line":126,"column":19}}})) != null ? stack1 : "")
    + "\n        <li>\n          <a href='/campaigns' data-section='campaigns' data-permission='campaigns:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--campaigns'></use>\n              <use class='icon-active' xlink:href='#nav--campaigns-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":135,"column":14},"end":{"line":135,"column":56}}}))
    + "\n            </span>\n          </a>\n        </li>\n        <li>\n          <a href='/automations' data-section='automations' data-permission='campaigns:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--automations'></use>\n              <use class='icon-active' xlink:href='#nav--automations-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":146,"column":14},"end":{"line":146,"column":58}}}))
    + "\n            </span>\n          </a>\n        </li>\n        <li>\n          <a href='/drips' data-section='drips' data-permission='campaigns:list' data-feature='DRIP_ENABLED'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--drip'></use>\n              <use class='icon-active' xlink:href='#nav--drip-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":157,"column":14},"end":{"line":157,"column":52}}}))
    + "\n            </span>\n          </a>\n        </li>\n        <li>\n          <a href='/accounts' data-section='accounts' data-permission='accounts:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--accounts'></use>\n              <use class='icon-active' xlink:href='#nav--accounts-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":168,"column":14},"end":{"line":168,"column":55}}}))
    + "\n            </span>\n          </a>\n        </li>\n        <li>\n          <a href='/settings' data-section='settings' data-permission='account:update'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--settings'></use>\n              <use class='icon-active' xlink:href='#nav--settings-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accounts"))) && lookupProperty(stack1,"settings")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":179,"column":14},"end":{"line":179,"column":57}}}))
    + "\n            </span>\n          </a>\n        </li>\n        <li>\n          <a href='/integrations' data-section='integrations' data-permission='account:update'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--integrations'></use>\n              <use class='icon-active' xlink:href='#nav--integrations-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":190,"column":14},"end":{"line":190,"column":59}}}))
    + "\n            </span>\n          </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"config") : depth0)) != null ? lookupProperty(stack1,"whiteLabel") : stack1),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":8},"end":{"line":206,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='' data-permission='templates:list'>\n          <a href='/gallery' data-section='gallery' data-permission='templates:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--templates2'></use>\n              <use class='icon-active' xlink:href='#nav--templates2-a'></use>\n            </svg>\n            <span>\n              "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/capitalize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"templateMenu")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":94,"column":14},"end":{"line":94,"column":60}}}))
    + "\n            </span>\n          </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='has-childs' data-permission='templates:list'>\n          <a href='/templates' data-section='templates' data-permission='templates:list'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--templates'></use>\n              <use class='icon-active' xlink:href='#nav--templates-a'></use>\n            </svg>\n            <span>\n              "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"designs"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":109,"column":14},"end":{"line":109,"column":54}}}))
    + "\n            </span>\n          </a>\n          <ul>\n            <li>\n              <a href='/images' data-section='images' data-permission='images:list'>\n                <svg class='icon'>\n                  <use class='icon-normal' xlink:href='#nav--images'></use>\n                  <use class='icon-active' xlink:href='#nav--images-a'></use>\n                </svg>\n                <span>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":120,"column":18},"end":{"line":120,"column":57}}}))
    + "\n                </span>\n              </a>\n            </li>\n          </ul>\n        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a href='/plan' data-section='plan' data-permission='account:plan'>\n            <svg class='icon'>\n              <use class='icon-normal' xlink:href='#nav--plan'></use>\n              <use class='icon-active' xlink:href='#nav--plan-a'></use>\n            </svg>\n            <span>\n              "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/capitalize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"singular")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":202,"column":14},"end":{"line":202,"column":54}}}))
    + "\n            </span>\n          </a>\n        </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <button data-action='switch-to-new-layout' class=\"flex justify-center space-x-2 border-t border-gray-300 py-4 text-gray-500\">\n        <span>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"goToNewLayout")), depth0))
    + "</span>\n        <svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"w-6 h-6\">\n          <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z\" />\n        </svg>\n      </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " wide ";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='header'>\n      <div class='left-menu'>\n        <button class='hamburguer' data-action='toggle-sidebar'>\n          <i class='fa fa-bars'></i>\n        </button>\n        <button class='account-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"canChangeAccount") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":38},"end":{"line":227,"column":84}}})) != null ? stack1 : "")
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"canChangeAccount") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":86},"end":{"line":228,"column":51}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"inAccount") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":229,"column":10},"end":{"line":231,"column":17}}})) != null ? stack1 : "")
    + "        </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"parent") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":8},"end":{"line":240,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div class='right-menu'>\n        <ul>\n          <li class='tasks indicator'></li>\n          <li class='notifications indicator'></li>\n          <li class='session'></li>\n        </ul>\n      </div>\n      <div class='trial'></div>\n    </div>\n    <div class='alerts'></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "can-change";
},"18":function(container,depth0,helpers,partials,data) {
    return "\n          data-action='open-accounts-modal' ";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"canChangeAccount") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":234,"column":8},"end":{"line":239,"column":15}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <button data-action='goto-account' data-code='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"parent") : stack1), depth0))
    + "'\n          class='account-button can-change goto-parent'>\n          volver a "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"details") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"parent") : stack1), depth0))
    + "\n        </button>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "  <div class='cover'></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"vue-global-components\"/>\n<div class='messenger' data-messenger='main'>\n  <span></span>\n</div>\n<div class='wrapper'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"isGuest") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":219,"column":13}}})) != null ? stack1 : "")
    + "  <div class='main "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"isGuest") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":19},"end":{"line":220,"column":52}}})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"isGuest") : stack1),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":221,"column":4},"end":{"line":252,"column":15}}})) != null ? stack1 : "")
    + "    <div id=\"perfitPageUniqueID\" class='page overflow-auto h-full w-full'></div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"isGuest") : stack1),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":255,"column":2},"end":{"line":257,"column":13}}})) != null ? stack1 : "")
    + "  <div class='mobile-splash'>\n    <div class='head'>\n      <h4>\n        "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"hello")), depth0))
    + "\n      </h4>\n      <div class=\"circle-logo\"></div>\n    </div>\n    <div class='body'>\n      <p>\n        "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"copy")), depth0))
    + "\n      </p>\n      <div class=\"mt-8\">\n        <a data-action='goto-dashboard' class='btn btn-primary'>\n          "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"gotoDashboard")), depth0))
    + "\n        </a>\n      </div>\n      <button>\n    </div>\n  </div>\n  <div class='modal fade' tabindex='-1' data-backdrop='static' data-modal='main'></div>\n  <div class='modal fade' tabindex='-1' data-backdrop='static' data-modal='selector'></div>\n  <div class='modal confirm fade' tabindex='-1' data-backdrop='static' data-modal='confirm'>\n    <div class='modal-dialog'>\n      <form>\n        <div class='modal-content'>\n          <div class='modal-header'>\n            <button data-dismiss='modal' class='close' type='button'>\n              <span aria-hidden='true'>&times;</span>\n            </button>\n            <h4 class='modal-title'>\n              <span></span>\n            </h4>\n          </div>\n          <div class='modal-body'>\n            <div class='modal-body-padded'>\n              <span class='question'></span>\n            </div>\n          </div>\n          <div class='modal-footer'>\n            <button type='reset' class='btn btn-link' data-dismiss='modal' id='confirm-modal-cancel-btn'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n            </button>\n            <button type='submit' class='btn btn-primary' data-not-disable='1' id='confirm-modal-accept-btn'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n            </button>\n          </div>\n        </div>\n      </form>\n    </div>\n  </div>\n</div>";
},"useData":true});