var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='not-found'>\n    <h1>\n        ¯\\_(ツ)_/¯\n        <br>\n        <small>\n            "
    + alias1(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"notFound")),(depth0 != null ? lookupProperty(depth0,"status") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":52}}}))
    + "\n        </small>\n    </h1>\n    <small>(error "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + ")</small>\n</div>";
},"useData":true});