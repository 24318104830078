const moment = window.moment;

// Returns a human readable time ago date
export default function (value, format, optionalYear = false) {
  var result = "-";

  if (optionalYear === true && !format.includes("Y") && !moment(value).isSame(new Date(), "year")) {
    format = format + ", YYYY";
  }

  if (value) {
    result = moment(value).tz(window.config.timezone).format(format);
  }
  return result;
}
