<template>
  <div class="flex space-x-6" data-intercom-target="DashboardPlanUsage">
    <template v-if="accountDisabled">
      <AlertBox theme="warning" class="py-2"> {{ t("accountDisabled") }} </AlertBox>
    </template>
    <template v-else>
      <UsageBar
        v-if="!accountPlan || accountPlan.contacts.limit > 0"
        :filled-perc="contactsFillPerc"
        :loading="loading"
        :warning="contactsExceeded || contactsAboutToExceed"
        :danger="contactsBlocked"
        :tooltip="
          contactsBlocked
            ? t('contacts.tooltip.blocked')
            : contactsExceeded
            ? t('contacts.tooltip.exceeded')
            : contactsAboutToExceed
            ? t('contacts.tooltip.aboutToExceed')
            : t('contacts.tooltip.used')
        "
      >
        <template #title>
          {{ contactsExceeded ? t("contacts.exceeded") : t("contacts.used") }}
        </template>
        <template #description>
          {{ n(accountPlan?.contacts.active || 0, "decimal") }} {{ t("of") }}
          {{ n(accountPlan?.contacts.limit || 0, "decimal") }}
        </template>
      </UsageBar>
      <UsageBar
        v-if="
          accountPlan &&
          (accountPlan.emails.limit > 0 || accountPlan.type === 'EMAILS') &&
          (xlBp || accountPlan.contacts.limit === 0)
        "
        :filled-perc="emailsFillPerc"
        :loading="loading"
        :warning="emailsExceeded"
        :danger="emailsBlocked"
        :tooltip="emailsTooltip"
      >
        <template #title>
          {{ emailsExceeded ? t("emails.exceeded") : t("emails.available") }}
        </template>
        <template #description>
          <template v-if="!emailsExceeded">
            {{ n(accountPlan?.emails.available || 0, "decimal") }}
          </template>
          <template v-if="emailsExceeded">
            {{ n(Math.abs(accountPlan?.emails.available || 0), "decimal") }} {{ t("of") }}
            {{ n(accountPlan?.emails.excessLimit || 0, "decimal") }}
          </template>
        </template>
      </UsageBar>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import UsageBar from "@molecules/UsageBar.vue";
import AlertBox from "@atoms/AlertBox.vue";
import { IAccountPlan } from "@api/modules/accountplan";
import { useBreakpoints } from "@composables/breakpoints";

const { xlBp } = useBreakpoints();

const { t, n, d } = useI18n();

const props = defineProps({
  accountPlan: {
    type: Object as PropType<IAccountPlan>,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const accountDisabled = computed(() => {
  return props.accountPlan?.state === "BLOCKED_ACCOUNT_DISABLED";
});

const contactsExceeded = computed(() => {
  if (!props.accountPlan?.contacts) return false;
  return props.accountPlan.contacts.active > props.accountPlan.contacts.limit;
});

const contactsAboutToExceed = computed(() => {
  if (!props.accountPlan?.contacts) return false;
  const active = props.accountPlan.contacts.active;
  const maximumLimit = props.accountPlan.contacts.limit;
  const minimumLimit = (95 * maximumLimit) / 100;
  return active >= minimumLimit && active <= maximumLimit;
});

const contactsBlocked = computed(() => {
  if (!props.accountPlan) return false;
  return (
    props.accountPlan.state === "BLOCKED_CONTACTS_EXCEEDED" ||
    props.accountPlan?.contacts.active > props.accountPlan?.contacts.limit
  );
});

const contactsFillPerc = computed(() => {
  if (!props.accountPlan?.contacts) return 0;
  return (100 * props.accountPlan?.contacts.active) / props.accountPlan?.contacts.limit;
});

const emailsExceeded = computed(() => {
  if (!props.accountPlan?.emails) return false;
  return props.accountPlan.emails.available < 0;
});

const emailsBlocked = computed(() => {
  if (!props.accountPlan?.emails) return false;
  return -props.accountPlan.emails.available >= props.accountPlan.emails.excessLimit;
});

const emailsFillPerc = computed(() => {
  if (!props.accountPlan?.emails || props.accountPlan?.emails.available === 0) return 0;
  if (emailsExceeded.value) {
    return (-100 * props.accountPlan?.emails.available) / props.accountPlan?.emails.excessLimit;
  } else {
    return (100 * props.accountPlan?.emails.available) / props.accountPlan?.emails.limit;
  }
});

const emailsTooltip = computed(() => {
  if ((props.accountPlan.type === "MONTHLY" || props.accountPlan.type === "FREE") && props.accountPlan.subscription) {
    const next = props.accountPlan.subscription.periodEnds ? d(props.accountPlan.subscription.periodEnds, "long") : "";
    if (emailsBlocked.value) {
      return t("emails.tooltip.monthly.blocked", { next });
    } else if (emailsExceeded.value) {
      return t("emails.tooltip.monthly.exceeded", { next });
    } else {
      return t("emails.tooltip.monthly.nextPeriod", { next });
    }
  } else if (props.accountPlan.type === "EMAILS") {
    if (emailsBlocked.value) {
      return t("emails.tooltip.packs.blocked");
    } else {
      return t("emails.tooltip.packs.ok");
    }
  }

  return "";
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "of": "de",
    "accountDisabled": "La cuenta está deshabilitada.",
    "contacts": {
      "used": "Contactos utilizados",
      "exceeded": "Contactos excedidos",
      "tooltip": {
        "used": "Un contacto utilizado es aquel que recibió al menos un email en los últimos 30 días, o se encuentra habilitado en tus listas. <a target='_blank' href='https://docs.myperfit.com/articles/2845428'>Leer más</a>",
        "aboutToExceed": "Estás llegando al límite de contactos de tu plan. Una vez superado, deberás aumentar tu suscripción para seguir enviando.<br/><br/>@:contacts.tooltip.used",
        "exceeded": "Estás dentro del límite permitido de contactos excedidos. Una vez superado, deberás aumentar tu suscripción para seguir enviando.<br/><br/>@:contacts.tooltip.used",
        "blocked": "Has superado el límite de contactos de tu plan. Deberás aumentar tu suscripción para seguir enviando. <a href='/plan?product_tour_id=306625'>Modificar plan</a><br/><br/>@:contacts.tooltip.used"
      }
    },
    "emails": {
      "available": "Envíos disponibles",
      "exceeded": "Envíos excedidos",
      "tooltip": {
        "monthly": {
          "blocked": "Has alcanzado el límite de envíos. Deberás aumentar tu suscripción para seguir enviando. <a href='/plan?product_tour_id=306625'>Modificar plan</a><br/><br/>@:emails.tooltip.monthly.nextPeriod",
          "exceeded": "Puedes seguir enviando emails hasta el límite indicado. Los envíos excedentes serán facturados junto al siguiente período.<br/><br/>@:emails.tooltip.monthly.nextPeriod",
          "nextPeriod": "La próxima acreditación<br/>de envíos se realizará el día:<br/>{next}."
        },
        "packs": {
          "blocked": "Has utilizado todos los envíos de tu pack. Deberás adquirir un nuevo pack o contratar una suscripción mensual para seguir enviando. <a href='/plan'>Comprar pack</a>",
          "ok": "Tus packs de envíos no tienen vencimiento.<br/>Puedes comprar un nuevo pack y tus envíos disponibles serán acumulados."
        }
      }
    }
  },
  "pt": {
    "of": "de",
    "accountDisabled": "A conta está desativada",
    "contacts": {
      "used": "Contatos utilizados",
      "exceeded": "Contatos excedidos",
      "tooltip": {
        "used": "Um contato utilizado é aquele que recebeu ao menos um e-mail nos últimos 30 dias ou se encontra habilitado em suas listas. <a target='_blank' href='https://docs.myperfit.com/articles/2845428'>Ler mais</a>",
        "aboutToExceed": "Você está chegando ao limite de contatos do seu plano. Uma vez ultrapassado, você precisará atualizar sua assinatura para continuar enviando.<br/><br/>@:contacts.tooltip.used",
        "exceeded": "Você está dentro do limite permitido de contatos excedidos. Uma vez superado, deverá aumentar seu plano para continuar enviando. <br/><br/>@:contacts.tooltip.used",
        "blocked": "Você superou o limite de contatos da sua conta. Deverá aumentar seu plano para continuar enviando. <a href='/plan'>Modificar plano</a><br/><br/>@:contacts.tooltip.used"
      }
    },
    "emails": {
      "available": "Envios disponíveis",
      "exceeded": "Envios excedidos",
      "tooltip": {
        "monthly": {
          "blocked": "Você atingiu o limite de envios. Deverá aumentar o seu plano para continuar enviando. <a href='/plan'>Modificar plano</a><br/><br/>@:emails.tooltip.monthly.nextPeriod",
          "exceeded": "Você pode continuar enviando e-mails até o limite indicado. Os envios excedentes serão cobrados na próxima fatura.<br/><br/>@:emails.tooltip.monthly.nextPeriod",
          "nextPeriod": "A próxima acreditação<br/>se realizará no dia:<br/>{next}."
        },
        "packs": {
          "blocked": "Você utilizou todos os envios do seu Pack. Deverá adquirir um Pack novo ou contratar um plano mensal para continuar enviando. <a href='/plan'>Comprar pack</a>",
          "ok": "Os seus packs de envios não têm vencimento.<br/>Você pode comprar um pack novo e seus envios disponíveis serão acumulados."
        }
      }
    }
  }
}
</i18n>
