import type { Interest, Interests } from "@domain/interests";
import { formatDate } from "@helpers/formatters";
import type { InterestLists, InterestListItem } from "./interestSelector.types";

export const interestToItem = (interest: Interest): InterestListItem => {
  return {
    key: interest.id.toString(),
    value: interest.name,
    content: {
      dateText: formatDate(new Date(interest.created), "MMM dd, HH:mm"),
    },
    data: interest,
  };
};

export const interestsToItems = (
  interests: Interests,
  valueTexts: {
    interest: string;
  }
): InterestLists => {
  return [
    {
      id: "interests",
      value: valueTexts.interest,
      list: interests.map<InterestListItem>(interestToItem),
    },
  ];
};
