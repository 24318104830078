<template>
  <SenderSelectorMenu
    ref="senderSelectoMenuRef"
    v-model="selectedSenderId"
    :select-by-email="emailField"
    @sender-updated="senderUpdated"
  />
  <input
    v-if="includeFields.includes('senderId')"
    ref="senderIdRef"
    name="senderId"
    type="hidden"
    :value="selectedSender?.id"
    :data-changed="dataChanged"
  />
  <input
    v-if="includeFields.includes('email')"
    ref="emailRef"
    :name="emailFieldName"
    type="hidden"
    :value="truncateEmail(selectedSender?.effective_email)"
    :data-changed="dataChanged"
  />
  <input
    v-if="includeFields.includes('name')"
    ref="nameRef"
    :name="nameFieldName"
    type="hidden"
    :value="selectedSender?.name"
    :data-changed="dataChanged"
  />
  <input
    v-if="includeFields.includes('replyTo')"
    ref="replyToRef"
    name="replyTo"
    type="hidden"
    :value="selectedSender?.reply_to"
    :data-changed="dataChanged"
  />
</template>

<script>
import { ref } from "vue";
import SenderSelectorMenu from "@organisms/Senders/SenderSelectorMenu.vue";

export default {
  components: { SenderSelectorMenu },
  props: {
    senderId: {
      type: String,
      default: null,
    },
    emailFieldName: {
      type: String,
      default: "from",
    },
    nameFieldName: {
      type: String,
      default: "fromName",
    },
    includeFields: {
      type: Array,
      default: () => ["email", "name", "replyTo", "senderId"],
    },
    emailField: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const selectedSenderId = ref(props.senderId);
    const selectedSender = ref(null);
    const senderIdRef = ref(null);
    const emailRef = ref(null);
    const nameRef = ref(null);
    const replyToRef = ref(null);
    const senderSelectoMenuRef = ref(null);

    const senderUpdated = (newSender) => {
      selectedSender.value = newSender;
      if (senderIdRef.value) senderIdRef.value.setAttribute("data-changed", "1");
      if (emailRef.value) emailRef.value.setAttribute("data-changed", "1");
      if (nameRef.value) nameRef.value.setAttribute("data-changed", "1");
      if (replyToRef.value) replyToRef.value.setAttribute("data-changed", "1");
    };

    const truncateEmail = (email) => {
      if (!email || email.length <= 50) return email;
      const excess = email.length - 50;
      const parts = email.split("@");
      return parts[0].substring(0, parts[0].length - excess) + "@" + parts[1];
    };

    return {
      selectedSenderId,
      selectedSender,
      senderUpdated,
      senderIdRef,
      emailRef,
      nameRef,
      replyToRef,
      senderSelectoMenuRef,
      truncateEmail,
    };
  },
};
</script>
