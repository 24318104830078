<template>
  <ModalDialog :title="t('currencyAndPlanCostTitle')" :open="isOpen" @close="HandleClose">
    <CurrencyAndPlanCost :account="account" @saved="EmitSave" />
  </ModalDialog>
</template>

<script lang="ts" setup>
//Components
import CurrencyAndPlanCost from "@organisms/Account/CurrencyAndPlanCost/CurrencyAndPlanCostConfig.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

//I18n
import { useI18n } from "vue-i18n";
import { IAccountConfig } from "@/vue/api/modules/account";

//I18n
const { t } = useI18n();

//Props
withDefaults(
  defineProps<{
    isOpen?: boolean;
    account?: string;
  }>(),
  {
    isOpen: false,
    account: undefined,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:isOpen", value: boolean): void;
  (e: "close"): void;
  (e: "saved", config: IAccountConfig): void;
}>();

const HandleClose = () => {
  emit("update:isOpen", false);
  emit("close");
};

const EmitSave = (config: IAccountConfig) => emit("saved", config);
</script>

<i18n lang="jsonc">
{
  "es": {
    "currencyAndPlanCostTitle": "Configurar conversiones"
  },
  "pt": {
    "currencyAndPlanCostTitle": "Configurar conversões"
  }
}
</i18n>
