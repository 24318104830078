import Pusher from "pusher-js";
import { useUnlayer } from "../unlayer/unlayer.app";

// Modules
import { searchTemplateContentModules } from "./modules/templateSearch";
import { getRuleModule } from "./modules/templateRules";

// Utils
import { findDeep, generateRandomId } from "@helpers/data";
import { v4 as uuidV4 } from "uuid";
import { attachToNewIframe } from "@helpers/iframe";
import { ok, err } from "neverthrow";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useFeaturesStore } from "@store";

// Services
import { useNotifications } from "@composables/notifications";
import { useEmailTemplate } from "@api/modules/templates/templates";

// Types
import type {
  TemplateContentJSON,
  Content as TemplateContent,
  TemplateContents,
  TemplateRules,
} from "@domain/Templates";
import type { TemplateCategories, TemplatesApplication } from "./templates.types";
import { isValidUnlayerTemplate } from "@domain/unlayer";
import type { Content } from "@domain/unlayer";
import type { Fonts } from "@domain/fonts";

//I18n
import { getI18nInstance, getCurrentLocale } from "@locales/i18n";
import esMessages from "./i18n/templates.es.json";
import ptMessages from "./i18n/templates.pt.json";

const { mergeLocaleMessage } = getI18nInstance().global;

mergeLocaleMessage("es", esMessages);
mergeLocaleMessage("pt", ptMessages);

const readFile = async (): Promise<File> => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", `.json`);
  input.click();

  return new Promise((resolve) => {
    input.addEventListener("change", () => {
      const file = input.files?.[0];
      if (file) {
        resolve(file);
      }
    });
  });
};

export const templateTimes = {
  timeAPISaveStart: 0,
  timeAPISaveEnd: 0,

  timeExportHTMLStart: 0,
  timeExportHTMLEnd: 0,
  timeExportTextStart: 0,
  timeExportTextEnd: 0,
  timeExportTemplateFirstStart: 0,
  timeExportTemplateFirstEnd: 0,

  timeExportHTMLSecondStart: 0,
  timeExportHTMLSecondEnd: 0,
  timeExportTextSecondStart: 0,
  timeExportTextSecondEnd: 0,
  timeExportTemplateSecondStart: 0,
  timeExportTemplateSecondEnd: 0,
};

export const resetTemplateTimes = () => {
  templateTimes.timeAPISaveStart = 0;
  templateTimes.timeAPISaveEnd = 0;

  templateTimes.timeExportHTMLStart = 0;
  templateTimes.timeExportHTMLEnd = 0;
  templateTimes.timeExportTextStart = 0;
  templateTimes.timeExportTextEnd = 0;
  templateTimes.timeExportTemplateFirstStart = 0;
  templateTimes.timeExportTemplateFirstEnd = 0;

  templateTimes.timeExportHTMLSecondStart = 0;
  templateTimes.timeExportHTMLSecondEnd = 0;
  templateTimes.timeExportTextSecondStart = 0;
  templateTimes.timeExportTextSecondEnd = 0;
  templateTimes.timeExportTemplateSecondStart = 0;
  templateTimes.timeExportTemplateSecondEnd = 0;
};

export const useTemplatesApp = (): TemplatesApplication => {
  const { t } = getI18nInstance().global;
  const { notify } = useNotifications();

  // Services
  const TemplatesAPI = useEmailTemplate();

  // Applications
  const unlayerApp = useUnlayer();

  // Store
  const featuresStore = useFeaturesStore();

  const templatesApplication: TemplatesApplication = {
    ...searchTemplateContentModules,
    getRule: ({ rule }) => {
      return getRuleModule({ rule, templatesApp: templatesApplication });
    },
    getPublicTagIdOrName: ({ tagId, tagNameKey }) => {
      const tagsMap = {
        tag_clf2pw8ba007e0e377ui4ilka: "ecommerce",
        tag_clf2v51bz009f0e37oowm4gz5: "promotions",
        tag_clf2vc0qy009k0e37oabrlpiy: "notifications",
        tag_clctd01c6003e0a8598n4mgkf: "newsletters",
        tag_clf2vrhn5009v0e37wpv29icr: "events",
        tag_clf2vumuv00a10e372oqu0ndi: "salutations",
        tag_clf2vllgu009q0e37xvwsj8cq: "clothing",
        tag_clf2voh5c009u0e372ittu4w8: "accesories",
        tag_clf2v4iww009e0e375hy120ct: "food",
        tag_clf2wa8kl00a70e37iwa0chgy: "home",
        tag_clf2pwtsc008e0853g8dzj6ye: "health",
        tag_clf5xarwv00ft0e37j3377omu: "pets",
        tag_clctd01ca003f0a855jj85fav: "tourism",
        tag_clf2w94bm00a60e37rmempih4: "sports",
        tag_clf2v7twr009i0e37elxy61tx: "tech",
        tag_clf2v9ls0009j0e37jufvxfpk: "art",
        tag_clf4l9ymz00fa0e37l0er95q9: "education",
        tag_clf4kr7go00f80e37b0bhrl6b: "nonprofit",
        tag_clf2wc8g100a80e37zcwcrvg0: "business",
        tag_clf2whkby00aj0e37cefc41v7: "entertainment",
        tag_clf2wfrgg00ae0e37ga8mcujr: "realstate",
        tag_clf2wdryo00bx0853pvd2hx2c: "aumotive",
        tag_clf4kg2n200f50e37kmar05jr: "cybermonday",
        tag_clf4k42gg00gg0853xe75nnf6: "mothers",
        tag_clf5x6cns00fs0e37l9ly3ndd: "hotsale",
        tag_clf4k88nd00gi08539ygen6ri: "xmas",
        tag_clf4ka89q00f40e373uicv5zt: "blackfriday",
        tag_clf2vm6mv009r0e37wirl0q4k: "fathers",
        tag_clf5x25u100gz0853aa0ykmnx: "children",
        tag_clf4kkjhu00f70e3706sj7b8w: "women",
        tag_clf4kcbk100gj08530tm7bz8w: "valentine",
        tag_clf4k5zxy00gh0853amsu5cf2: "newyear",
        tag_clf4k0nw000f30e37eldajo0p: "halloween",
        tag_clf5wxbwa00fr0e37f51svdzv: "pride",
        tag_clf5wpm3y00gy0853fg4pi5vv: "easter",
        tag_clhi5w2g6042g08137uf8yjnt: "friends",
        tag_clgwoert90cm90869iphjky6t: "tiendanube",
        tag_cliooovk101ua0851bjg4xxtl: "juninas",
        tag_clinjqe7l00y40851kjx0kfhx: "suggested",
        tag_clinjry3400yd0b58jggwreaq: "suggested",
        tag_clinjst5f00y90851tpchw7y3: "suggested",
        tag_clinjtsmi00yb0851xkcbh9ue: "suggested",
        tag_clkwyrjgq0tjn09275jozr32q: "spring",
        tag_clr6h09ni0edj078836zx9w6y: "carnaval",
      };

      if (tagId) {
        return tagsMap?.[tagId];
      }

      if (tagNameKey) {
        const tag = Object.entries(tagsMap).find(([key, value]) => value === tagNameKey);
        return tag?.[0];
      }
    },
    getPublicTagTranslation: ({ tagId }) => {
      const textKey: string | undefined = templatesApplication.getPublicTagIdOrName({ tagId });
      return textKey ? t(`publicTemplatesMenu.tags.${textKey}`) : undefined;
    },
    getTemplateCategories: async () => {
      const stats = await TemplatesAPI.getPublicStats();
      const sessionStore = useSessionStore();
      const { session } = storeToRefs(sessionStore);

      const country = session.value?.account.country;

      const PUBLISHED_TAG = "tag_clacrfpti001i0b32qoxl7xyb";
      const suggestedGeneral = "tag_clinjqe7l00y40851kjx0kfhx";
      const suggestedAR = "tag_clinjry3400yd0b58jggwreaq";
      const suggestedBR = "tag_clinjst5f00y90851tpchw7y3";
      const suggestedMX = "tag_clinjtsmi00yb0851xkcbh9ue";

      const getSuggestedTags = (): Array<string> => {
        if (country === "ar") return [suggestedGeneral, suggestedAR];
        if (country === "br" || country === "pr") return [suggestedGeneral, suggestedBR];
        if (country === "mx") return [suggestedGeneral, suggestedMX];
        return [suggestedGeneral];
      };

      const getSuggestedCount = (): number => {
        const generalCount = stats.tags?.[suggestedGeneral] ?? 0;
        if (country === "ar") return generalCount + (stats.tags?.[suggestedAR] ?? 0);
        if (country === "br" || country === "pr") return generalCount + (stats.tags?.[suggestedBR] ?? 0);
        if (country === "mx") return generalCount + (stats.tags?.[suggestedMX] ?? 0);
        return generalCount;
      };

      const templateCategories: TemplateCategories = {
        items: [
          {
            id: "suggested",
            text: t("publicTemplatesMenu.categories.suggested"),
            count: getSuggestedCount(),
            data: {
              tags: getSuggestedTags(),
            },
          },
          {
            id: "all",
            text: t("publicTemplatesMenu.categories.allTemplates"),
            count: stats.tags?.[PUBLISHED_TAG] ?? 0,
          },
        ],
        categories: [
          {
            id: "usesCategory",
            name: t("publicTemplatesMenu.categories.uses"),
            items: [
              {
                id: "ecommerce",
                text: t("publicTemplatesMenu.tags.ecommerce"),
                count: stats.tags?.["tag_clf2pw8ba007e0e377ui4ilka"] ?? 0,
                data: {
                  tags: ["tag_clf2pw8ba007e0e377ui4ilka"],
                },
              },
              {
                id: "promotions",
                text: t("publicTemplatesMenu.tags.promotions"),
                count: stats.tags?.["tag_clf2v51bz009f0e37oowm4gz5"] ?? 0,
                data: {
                  tags: ["tag_clf2v51bz009f0e37oowm4gz5"],
                },
              },
              {
                id: "notifications",
                text: t("publicTemplatesMenu.tags.notifications"),
                count: stats.tags?.["tag_clf2vc0qy009k0e37oabrlpiy"] ?? 0,
                data: {
                  tags: ["tag_clf2vc0qy009k0e37oabrlpiy"],
                },
              },
              {
                id: "newsletters",
                text: t("publicTemplatesMenu.tags.newsletters"),
                count: stats.tags?.["tag_clctd01c6003e0a8598n4mgkf"] ?? 0,
                data: {
                  tags: ["tag_clctd01c6003e0a8598n4mgkf"],
                },
              },
              {
                id: "events",
                text: t("publicTemplatesMenu.tags.events"),
                count: stats.tags?.["tag_clf2vrhn5009v0e37wpv29icr"] ?? 0,
                data: {
                  tags: ["tag_clf2vrhn5009v0e37wpv29icr"],
                },
              },
              {
                id: "salutations",
                text: t("publicTemplatesMenu.tags.salutations"),
                count: stats.tags?.["tag_clf2vumuv00a10e372oqu0ndi"] ?? 0,
                data: {
                  tags: ["tag_clf2vumuv00a10e372oqu0ndi"],
                },
              },
              {
                id: "tiendanube",
                text: t("publicTemplatesMenu.tags.tiendanube"),
                count: stats.tags?.["tag_clgwoert90cm90869iphjky6t"] ?? 0,
                data: {
                  tags: ["tag_clgwoert90cm90869iphjky6t"],
                },
              },
            ],
          },
          {
            id: "sectorCategory",
            name: t("publicTemplatesMenu.categories.sectors"),
            items: [
              {
                id: "clothing",
                text: t("publicTemplatesMenu.tags.clothing"),
                count: stats.tags?.["tag_clf2vllgu009q0e37xvwsj8cq"] ?? 0,
                data: {
                  tags: ["tag_clf2vllgu009q0e37xvwsj8cq"],
                },
              },
              {
                id: "accesories",
                text: t("publicTemplatesMenu.tags.accesories"),
                count: stats.tags?.["tag_clf2voh5c009u0e372ittu4w8"] ?? 0,
                data: {
                  tags: ["tag_clf2voh5c009u0e372ittu4w8"],
                },
              },
              {
                id: "food",
                text: t("publicTemplatesMenu.tags.food"),
                count: stats.tags?.["tag_clf2v4iww009e0e375hy120ct"] ?? 0,
                data: {
                  tags: ["tag_clf2v4iww009e0e375hy120ct"],
                },
              },
              {
                id: "home",
                text: t("publicTemplatesMenu.tags.home"),
                count: stats.tags?.["tag_clf2wa8kl00a70e37iwa0chgy"] ?? 0,
                data: {
                  tags: ["tag_clf2wa8kl00a70e37iwa0chgy"],
                },
              },
              {
                id: "health",
                text: t("publicTemplatesMenu.tags.health"),
                count: stats.tags?.["tag_clf2pwtsc008e0853g8dzj6ye"] ?? 0,
                data: {
                  tags: ["tag_clf2pwtsc008e0853g8dzj6ye"],
                },
              },
              {
                id: "pets",
                text: t("publicTemplatesMenu.tags.pets"),
                count: stats.tags?.["tag_clf5xarwv00ft0e37j3377omu"] ?? 0,
                data: {
                  tags: ["tag_clf5xarwv00ft0e37j3377omu"],
                },
              },
              {
                id: "tourism",
                text: t("publicTemplatesMenu.tags.tourism"),
                count: stats.tags?.["tag_clctd01ca003f0a855jj85fav"] ?? 0,
                data: {
                  tags: ["tag_clctd01ca003f0a855jj85fav"],
                },
              },
              {
                id: "sports",
                text: t("publicTemplatesMenu.tags.sports"),
                count: stats.tags?.["tag_clf2w94bm00a60e37rmempih4"] ?? 0,
                data: {
                  tags: ["tag_clf2w94bm00a60e37rmempih4"],
                },
              },
              {
                id: "tech",
                text: t("publicTemplatesMenu.tags.tech"),
                count: stats.tags?.["tag_clf2v7twr009i0e37elxy61tx"] ?? 0,
                data: {
                  tags: ["tag_clf2v7twr009i0e37elxy61tx"],
                },
              },
              {
                id: "art",
                text: t("publicTemplatesMenu.tags.art"),
                count: stats.tags?.["tag_clf2v9ls0009j0e37jufvxfpk"] ?? 0,
                data: {
                  tags: ["tag_clf2v9ls0009j0e37jufvxfpk"],
                },
              },
              {
                id: "education",
                text: t("publicTemplatesMenu.tags.education"),
                count: stats.tags?.["tag_clf4l9ymz00fa0e37l0er95q9"] ?? 0,
                data: {
                  tags: ["tag_clf4l9ymz00fa0e37l0er95q9"],
                },
              },
              {
                id: "nonprofit",
                text: t("publicTemplatesMenu.tags.nonprofit"),
                count: stats.tags?.["tag_clf4kr7go00f80e37b0bhrl6b"] ?? 0,
                data: {
                  tags: ["tag_clf4kr7go00f80e37b0bhrl6b"],
                },
              },
              {
                id: "business",
                text: t("publicTemplatesMenu.tags.business"),
                count: stats.tags?.["tag_clf2wc8g100a80e37zcwcrvg0"] ?? 0,
                data: {
                  tags: ["tag_clf2wc8g100a80e37zcwcrvg0"],
                },
              },
              {
                id: "entertainment",
                text: t("publicTemplatesMenu.tags.entertainment"),
                count: stats.tags?.["tag_clf2whkby00aj0e37cefc41v7"] ?? 0,
                data: {
                  tags: ["tag_clf2whkby00aj0e37cefc41v7"],
                },
              },
              {
                id: "realstate",
                text: t("publicTemplatesMenu.tags.realstate"),
                count: stats.tags?.["tag_clf2wfrgg00ae0e37ga8mcujr"] ?? 0,
                data: {
                  tags: ["tag_clf2wfrgg00ae0e37ga8mcujr"],
                },
              },
              {
                id: "aumotive",
                text: t("publicTemplatesMenu.tags.aumotive"),
                count: stats.tags?.["tag_clf2wdryo00bx0853pvd2hx2c"] ?? 0,
                data: {
                  tags: ["tag_clf2wdryo00bx0853pvd2hx2c"],
                },
              },
            ],
          },
          {
            id: "collectionsCategory",
            name: t("publicTemplatesMenu.categories.collections"),
            items: [
              {
                id: "carnaval",
                text: t("publicTemplatesMenu.tags.carnaval"),
                count: stats.tags?.["tag_clr6h09ni0edj078836zx9w6y"] ?? 0,
                data: {
                  tags: ["tag_clr6h09ni0edj078836zx9w6y"],
                },
              },
              {
                id: "cybermonday",
                text: t("publicTemplatesMenu.tags.cybermonday"),
                count: stats.tags?.["tag_clf4kg2n200f50e37kmar05jr"] ?? 0,
                data: {
                  tags: ["tag_clf4kg2n200f50e37kmar05jr"],
                },
              },
              {
                id: "mothers",
                text: t("publicTemplatesMenu.tags.mothers"),
                count: stats.tags?.["tag_clf4k42gg00gg0853xe75nnf6"] ?? 0,
                data: {
                  tags: ["tag_clf4k42gg00gg0853xe75nnf6"],
                },
              },
              {
                id: "hotsale",
                text: t("publicTemplatesMenu.tags.hotsale"),
                count: stats.tags?.["tag_clf5x6cns00fs0e37l9ly3ndd"] ?? 0,
                data: {
                  tags: ["tag_clf5x6cns00fs0e37l9ly3ndd"],
                },
              },
              {
                id: "xmas",
                text: t("publicTemplatesMenu.tags.xmas"),
                count: stats.tags?.["tag_clf4k88nd00gi08539ygen6ri"] ?? 0,
                data: {
                  tags: ["tag_clf4k88nd00gi08539ygen6ri"],
                },
              },
              {
                id: "blackfriday",
                text: t("publicTemplatesMenu.tags.blackfriday"),
                count: stats.tags?.["tag_clf4ka89q00f40e373uicv5zt"] ?? 0,
                data: {
                  tags: ["tag_clf4ka89q00f40e373uicv5zt"],
                },
              },
              {
                id: "fathers",
                text: t("publicTemplatesMenu.tags.fathers"),
                count: stats.tags?.["tag_clf2vm6mv009r0e37wirl0q4k"] ?? 0,
                data: {
                  tags: ["tag_clf2vm6mv009r0e37wirl0q4k"],
                },
              },
              {
                id: "children",
                text: t("publicTemplatesMenu.tags.children"),
                count: stats.tags?.["tag_clf5x25u100gz0853aa0ykmnx"] ?? 0,
                data: {
                  tags: ["tag_clf5x25u100gz0853aa0ykmnx"],
                },
              },
              {
                id: "women",
                text: t("publicTemplatesMenu.tags.women"),
                count: stats.tags?.["tag_clf4kkjhu00f70e3706sj7b8w"] ?? 0,
                data: {
                  tags: ["tag_clf4kkjhu00f70e3706sj7b8w"],
                },
              },
              {
                id: "valentine",
                text: t("publicTemplatesMenu.tags.valentine"),
                count: stats.tags?.["tag_clf4kcbk100gj08530tm7bz8w"] ?? 0,
                data: {
                  tags: ["tag_clf4kcbk100gj08530tm7bz8w"],
                },
              },
              {
                id: "newyear",
                text: t("publicTemplatesMenu.tags.newyear"),
                count: stats.tags?.["tag_clf4k5zxy00gh0853amsu5cf2"] ?? 0,
                data: {
                  tags: ["tag_clf4k5zxy00gh0853amsu5cf2"],
                },
              },
              {
                id: "halloween",
                text: t("publicTemplatesMenu.tags.halloween"),
                count: stats.tags?.["tag_clf4k0nw000f30e37eldajo0p"] ?? 0,
                data: {
                  tags: ["tag_clf4k0nw000f30e37eldajo0p"],
                },
              },
              {
                id: "pride",
                text: t("publicTemplatesMenu.tags.pride"),
                count: stats.tags?.["tag_clf5wxbwa00fr0e37f51svdzv"] ?? 0,
                data: {
                  tags: ["tag_clf5wxbwa00fr0e37f51svdzv"],
                },
              },
              {
                id: "easter",
                text: t("publicTemplatesMenu.tags.easter"),
                count: stats.tags?.["tag_clf5wpm3y00gy0853fg4pi5vv"] ?? 0,
                data: {
                  tags: ["tag_clf5wpm3y00gy0853fg4pi5vv"],
                },
              },
              {
                id: "friends",
                text: t("publicTemplatesMenu.tags.friends"),
                count: stats.tags?.["tag_clhi5w2g6042g08137uf8yjnt"] ?? 0,
                data: {
                  tags: ["tag_clhi5w2g6042g08137uf8yjnt"],
                },
              },
              {
                id: "juninas",
                text: t("publicTemplatesMenu.tags.juninas"),
                count: stats.tags?.["tag_cliooovk101ua0851bjg4xxtl"] ?? 0,
                data: {
                  tags: ["tag_cliooovk101ua0851bjg4xxtl"],
                },
              },
              {
                id: "spring",
                text: t("publicTemplatesMenu.tags.spring"),
                count: stats.tags?.["tag_clkwyrjgq0tjn09275jozr32q"] ?? 0,
                data: {
                  tags: ["tag_clkwyrjgq0tjn09275jozr32q"],
                },
              },
            ],
          },
        ],
      };

      templateCategories.categories.forEach((cat) => {
        cat.items = cat.items.filter((i) => i.count > 0).sort((a, b) => b.count - a.count);
      });

      // Filter by quantity
      const filteredCategories = {
        ...templateCategories,
        items: templateCategories.items?.filter((item) => item.count > 0),
      };

      // Filter by alphabetical order
      filteredCategories.categories.forEach((category) => {
        if (category.id === "sectorCategory" || category.id === "collectionsCategory") {
          category.items.sort((a, b) => a.text.localeCompare(b.text));
        }
      });

      return filteredCategories;
    },
    applyRules: ({ template, auditMode }) => {
      const accountFeatures = featuresStore.getFeatures();
      const rules: TemplateRules = [];

      if (
        auditMode !== "transactional" &&
        (!accountFeatures?.NO_FORCE_CAMPAIGN_FOOTER || accountFeatures?.NO_FORCE_CAMPAIGN_FOOTER === "0")
      ) {
        const footerRule = templatesApplication.getRule({ rule: "footerRule" });
        rules.push(footerRule);
      }

      if (template.type === "html") {
        const insertTrackingTagRule = templatesApplication.getRule({ rule: "insertTrackingTag" });
        rules.push(insertTrackingTagRule);
      }

      rules.forEach((rule) => {
        template = rule(template);
      });

      return template;
    },
    applyFormatRules: async ({ html, json, template }) => {
      const socialLinks: Set<string> = new Set();

      //JSON
      const contents = templatesApplication.getContents<{
        icons: {
          icons: Array<{
            name: string;
            url: UrlString;
          }>;
        };
      }>({ parsedJSON: json, identifiers: { type: "social" } });

      const schemes = ["mailto:", "tel:", "whatsapp:", "sms:", "file:", "data:"];

      contents.forEach((content) => {
        content.values.icons.icons.forEach((icon) => {
          const isEmptyLink = icon.url.trim().length === 0;
          const hasHttp = icon.url.trim().slice(0, 4) === "http";
          const hasScheme = schemes.some((scheme) => icon.url.trim().includes(scheme));

          if (!hasHttp && !isEmptyLink && !hasScheme) {
            socialLinks.add(icon.url);
            icon.url = `https://${icon.url.trim()}`;
          }
        });
      });

      //HTML
      let newHTML = html;

      const getFreeMarkerLocale = () => {
        const locale = getCurrentLocale();
        return locale.replace("-", "_");
      };
      const locale = getFreeMarkerLocale();
      const bodyTagIndex = newHTML.indexOf("<body");
      const bodyTagClosingIndex = newHTML.indexOf(">", bodyTagIndex) + 1;
      newHTML =
        newHTML.slice(0, bodyTagClosingIndex) + `[#setting locale="${locale}"]` + newHTML.slice(bodyTagClosingIndex);

      // insert data-ids into links
      if (template.tracking.click) {
        const parser = new DOMParser();

        const htmlDoc = parser.parseFromString(newHTML, "text/html");

        const aTagElements: NodeListOf<HTMLAnchorElement> = htmlDoc.querySelectorAll('a[href]:not([href=""])');

        if (aTagElements.length > 0) {
          const aTags = [...aTagElements];
          await Promise.all(
            aTags.map(async (aTag) => {
              const dataId = await generateRandomId();

              aTag.setAttribute("data-id", dataId.slice(0, 6));
            }),
          );
        }

        const modifiedHtmlString = htmlDoc.documentElement.outerHTML;
        newHTML = modifiedHtmlString;
      }

      const alreadyFormatted = newHTML.includes(
        '<meta property="og:title" content="${subject}" /><meta property="og:description" content="${preheader}" /><meta property="og:image" content="${campaign.thumbnail}" /></head>',
      );
      if (!alreadyFormatted) {
        newHTML = newHTML
          .replace("<title></title>", "<title>${subject}</title>")
          .replace(
            "</head>",
            '<meta property="og:title" content="${subject}" /><meta property="og:description" content="${preheader}" /><meta property="og:image" content="${campaign.thumbnail}" /></head>',
          );
      }

      socialLinks.forEach((link) => {
        newHTML = newHTML.replaceAll(link, `https://${link}`);
      });

      return {
        html: newHTML,
        json,
      };
    },
    attachHTMLToIframe: ({ html, iframeElement }) => {
      const filteredHTML = html.replace("${open.track}", "");

      attachToNewIframe({
        html: filteredHTML,
        iframeRef: iframeElement,
      });
    },
    getTemplateJSON: ({ template }) => {
      const templateJson = template.contents.json;

      if (!templateJson) return "";

      return JSON.parse(templateJson);
    },
    getTemplateFonts: ({ template }) => {
      const templateJSON = templatesApplication.getTemplateJSON({ template });

      const fonts: Array<
        | string
        | {
            label: string;
            value: string;
            url: string;
          }
      > = findDeep(templateJSON, (_, key) => key === "fontFamily");

      const filteredValues = fonts.filter(
        (
          font,
        ): font is {
          label: string;
          value: string;
          url: string;
        } => typeof font !== "string",
      ) as Array<{
        label: string;
        value: string;
        url: string;
      }>;

      return filteredValues.map((font) => {
        return {
          name: font.label,
          value: font.value,
          url: font.url,
        };
      }) as Fonts;
    },
    autoSaveTemplate: async ({ unlayerInstance, template, saveHistory }) => {
      let templateContent = template.contents;
      if (unlayerInstance) {
        const exportResponse = await unlayerApp.exportTemplate({
          unlayerInstance,
          options: {
            exportText: false,
          },
        });

        if (exportResponse.isOk()) {
          templateContent = exportResponse.value;
        }
      }

      if (templateContent.html && templateContent.json) {
        const data = await templatesApplication.applyFormatRules({
          html: templateContent.html,
          json: JSON.parse(templateContent.json),
          template: template,
        });
        templateContent.html = data.html;
        templateContent.json = JSON.stringify(data.json);
      }

      await TemplatesAPI.autosave({
        html: templateContent.html ?? "",
        json: templateContent.json ?? "",
        text: "",
        type: template.type,
        save_history: saveHistory ?? false,
        tpl_id: template.id,
        preheader: template.preheader ?? "",
        subject: template.subject ?? "",
      });
    },
    saveTemplate: async ({ unlayerInstance, template, auditMode }) => {
      if (!unlayerInstance && template.type === "html") {
        await templatesApplication.applyRules({ template, auditMode });
      } else {
        if (!unlayerInstance) return ok(template);

        let templateContents: TemplateContents = {
          html: "",
          json: "",
          text: "",
        };

        templateTimes.timeExportTemplateFirstStart = performance.now();

        const firstExportResponse = await unlayerApp.exportTemplate({
          unlayerInstance,
          options: {
            timeOutTime: 10000,
          },
        });

        templateTimes.timeExportTemplateFirstEnd = performance.now();

        if (firstExportResponse.isOk()) {
          templateContents = firstExportResponse.value;
          templateTimes.timeExportHTMLStart = firstExportResponse.value.timeExportHTMLStart;
          templateTimes.timeExportHTMLEnd = firstExportResponse.value.timeExportHTMLEnd;
          templateTimes.timeExportTextStart = firstExportResponse.value.timeExportTextStart;
          templateTimes.timeExportTextEnd = firstExportResponse.value.timeExportTextEnd;
        }

        if (firstExportResponse.isErr() && firstExportResponse.error.type === "TIMEOUT") {
          return err({
            type: "TIMEOUT",
            times: {
              timeExportHTMLStart: firstExportResponse.error.times.timeExportHTMLStart,
              timeExportHTMLEnd: firstExportResponse.error.times.timeExportHTMLEnd,
              timeExportTextStart: firstExportResponse.error.times.timeExportTextStart,
              timeExportTextEnd: firstExportResponse.error.times.timeExportTextEnd,
            },
          });
        }

        if (firstExportResponse.isErr()) {
          return err({
            type: "ERROR",
          });
        }

        template.contents.html = templateContents?.html ?? "";
        template.contents.json = templateContents?.json ?? "";
        template.contents.text = templateContents?.text ?? "";

        templatesApplication.applyRules({ template, auditMode });

        if (template.contents.json?.length !== templateContents.json?.length) {
          const templateJSON = templatesApplication.getTemplateJSON({ template: template });
          unlayerApp.loadTemplate({ unlayerInstance, templateJSON });

          templateTimes.timeExportTemplateSecondStart = performance.now();

          const secondExportResponse = await unlayerApp.exportTemplate({
            unlayerInstance,
            options: {
              timeOutTime: 10000,
            },
          });

          templateTimes.timeExportTemplateSecondEnd = performance.now();

          if (secondExportResponse.isOk()) {
            templateContents = secondExportResponse.value;
            templateTimes.timeExportHTMLSecondStart = secondExportResponse.value.timeExportHTMLStart;
            templateTimes.timeExportHTMLSecondEnd = secondExportResponse.value.timeExportHTMLEnd;
            templateTimes.timeExportTextSecondStart = secondExportResponse.value.timeExportTextStart;
            templateTimes.timeExportTextSecondEnd = secondExportResponse.value.timeExportTextEnd;
          }

          if (secondExportResponse.isErr() && secondExportResponse.error.type === "TIMEOUT") {
            return err({
              type: "TIMEOUT",
              times: {
                timeExportHTMLStart: firstExportResponse.value.timeExportHTMLStart,
                timeExportHTMLEnd: firstExportResponse.value.timeExportHTMLEnd,
                timeExportTextStart: firstExportResponse.value.timeExportTextStart,
                timeExportTextEnd: firstExportResponse.value.timeExportTextEnd,
                timeExportHTMLSecondStart: secondExportResponse.error.times.timeExportHTMLStart,
                timeExportHTMLSecondEnd: secondExportResponse.error.times.timeExportHTMLEnd,
                timeExportTextSecondStart: secondExportResponse.error.times.timeExportTextStart,
                timeExportTextSecondEnd: secondExportResponse.error.times.timeExportTextEnd,
              },
            });
          }

          if (firstExportResponse.isErr()) {
            return err({
              type: "ERROR",
            });
          }
        }

        if (templateContents.html && templateContents.json) {
          const data = await templatesApplication.applyFormatRules({
            html: templateContents.html,
            json: JSON.parse(templateContents.json),
            template: template,
          });
          templateContents.html = data.html;
          templateContents.json = JSON.stringify(data.json);
        }

        template.contents.html = templateContents?.html ?? "";
        template.contents.json = templateContents?.json ?? "";
        template.contents.text = templateContents?.text ?? "";
      }

      templateTimes.timeAPISaveStart = performance.now();
      const savedTemplate = await TemplatesAPI.saveTemplate({
        tpl_id: template.id,
        template: template,
      });
      templateTimes.timeAPISaveEnd = performance.now();

      notify({
        title: t("saveTemplate.successTitle"),
        text: t("saveTemplate.successMessage"),
      });

      return ok(savedTemplate);
    },
    startNewTemplate: async ({ unlayerInstance, template, auditMode }) => {
      unlayerApp.setBlank({ unlayerInstance });

      const exportTemplate = await unlayerApp.exportTemplate({
        unlayerInstance,
        options: {
          exportText: false,
        },
      });

      template.contents.html = "";
      template.contents.json = "";
      template.contents.text = "";

      if (exportTemplate.isOk()) {
        template.contents.html = exportTemplate.value?.html ?? "";
        template.contents.json = exportTemplate.value?.json ?? "";
        // template.contents.text = exportTemplate.value?.text ?? "";
      }

      templatesApplication.applyRules({ template, auditMode });

      const templateJSON = templatesApplication.getTemplateJSON({ template: template });
      unlayerApp.loadTemplate({ unlayerInstance, templateJSON });

      return template;
    },
    auditTemplateUnlayer: async ({ editorId, unlayerInstance, template, auditMode }) => {
      console.log("auditTemplateUnlayer");
      unlayerApp.applyAuditValidations({ editorId, unlayerInstance, template, auditMode });
      const auditData = await unlayerApp.audit({ unlayerInstance });
      const hasError = auditData.errors.some((error) => error.severity === "ERROR");
      if (hasError) {
        auditData.status = "FAIL";
      } else {
        auditData.status = "PASS";
      }
      template.audit_results = { ...auditData, mode: template.audit_results.mode };

      return template;
    },
    auditTemplateHTML: ({ template, auditMode }) => {
      let status: "PASS" | "WARNING" | "FAIL" = "PASS";
      const errors: Array<{ id: string; severity: "ERROR" | "WARNING"; title: string; description: string }> = [];

      if (!template.contents.html?.includes("${urls.unsubscribe}")) {
        status = "FAIL";
        errors.push({
          id: "MISSING_UNSUBSCRIBE_LINK",
          severity: "ERROR",
          title: t("auditErrors.missing_unsubscribe_link"),
          description: t("auditErrors.missing_unsubscribe_link_description"),
        });
      }

      if (!template.subject || template.subject === "") {
        status = "FAIL";
        errors.push({
          id: "MISSING_SUBJECT",
          severity: "ERROR",
          title: t("auditErrors.missing_subject"),
          description: t("auditErrors.missing_subject_description"),
        });
      }

      if (!template.sender_id || template.sender_id === "") {
        status = "FAIL";
        errors.push({
          id: "MISSING_SENDER",
          severity: "ERROR",
          title: t("auditErrors.missing_sender"),
          description: t("auditErrors.missing_sender_description"),
        });
      }
      template.audit_results = {
        mode: auditMode,
        status,
        errors,
      };

      return template;
    },
    importTemplate: async () => {
      try {
        const JSONFile = await readFile();
        const reader = new FileReader();
        reader.readAsText(JSONFile);

        const JSONText = await new Promise<string>((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

        const jsonTemplate = JSON.parse(JSONText);

        const isValidJSONTemplate = isValidUnlayerTemplate(jsonTemplate);

        if (!isValidJSONTemplate) {
          notify({
            title: t("importTemplateJSON.invalidErrorTitle"),
            text: t("importTemplateJSON.invalidErrorMessage"),
            theme: "error",
          });
          return;
        }

        return jsonTemplate;
      } catch (e) {
        notify({
          title: t("importTemplateJSON.errorTitle"),
          text: t("importTemplateJSON.errorMessage"),
          theme: "error",
        });
      }
    },
    isEmptyTemplate: ({ template, ignoreContents }) => {
      if (template.type === "html") {
        return template.contents.html === undefined || template.contents.html === "";
      }

      if (!template.contents.json) return true;

      const parsedJSON: TemplateContentJSON = JSON.parse(template.contents.json);
      const rows = parsedJSON.body.rows;

      return !rows.some((row) =>
        row.columns.some((column) => {
          const filteredContents = column.contents.filter((content) =>
            ignoreContents
              ? !ignoreContents.every(
                  (ignoreContent) => ignoreContent.slug === content.slug && ignoreContent.type === content.type,
                )
              : true,
          );
          return filteredContents.length > 0;
        }),
      );
    },
    getUsersEditing: async ({ channel: channelName, username, name, email, account, isMasterUser }) => {
      return new Promise((resolve, reject) => {
        const pusherKey = window.config.pusherKey;
        if (!pusherKey) {
          console.error("No pusher key defined");
          reject();
          return;
        }

        const pusher: any = new Pusher(pusherKey, {
          cluster: "us2",
          channelAuthorization: {
            endpoint: "https://authorizepresencechannel-hom7rbjfwa-uc.a.run.app",
            transport: "ajax",
            params: {
              environment: window.config.environment ?? "production",
            },
          },
          userAuthentication: {
            endpoint: "https://authenticatepresenceuser-hom7rbjfwa-uc.a.run.app",
            transport: "ajax",
            params: {
              environment: window.config.environment ?? "production",
              id: uuidV4(),
              username,
              name,
              email,
              account,
              isMasterUser,
            },
          },
        });

        const rejectWithError = (err) => {
          console.error("Pusher error", err);
          reject();
        };

        pusher.bind("pusher:error", rejectWithError);
        pusher.connection.bind("error", rejectWithError);

        pusher.signin();

        pusher.bind("pusher:signin_success", function () {
          const channel: any = pusher.subscribe(channelName);

          channel.bind("pusher:subscription_error", rejectWithError);

          channel.bind("pusher:subscription_succeeded", function () {
            const me = channel.members.me;
            const filterMembers = Object.entries(channel.members.members).filter(
              ([_, value]: [any, any]) => value.id?.toString() !== me.id?.toString(),
            );

            const members = filterMembers.map(([_, value]: [any, any]) => ({
              id: value.id,
              username: value.username,
              name: value.name,
              email: value.email,
              account: value.account,
              isMasterUser: value.isMasterUser,
            }));

            resolve({ pusherInstance: pusher, members, me: me.info });
          });
        });
      });
    },
    clearTimerTools: ({ template }) => {
      if (!template.options?.duplicated || !template.contents.json) return template;
      const templateJSON = JSON.parse(template.contents.json) as TemplateContentJSON;

      const contentIndexes = templatesApplication.getIndexesByContent({
        parsedJSON: templateJSON,
        identifiers: { type: "timer" },
      });

      contentIndexes.forEach((contentIndex) => {
        const content = templateJSON.body.rows[contentIndex.rowIndex].columns[contentIndex.colIndex].contents[
          contentIndex.contentIndex
        ] as TemplateContent<{
          countdown: {
            countdownUrl: string;
            id: string;
          };
        }>;
        if (!content.values?.countdown || !content.values?.countdown?.countdownUrl) return;

        content.values.countdown.countdownUrl = "";
        content.values.countdown.id = "";
      });

      template.contents.json = JSON.stringify(templateJSON);

      return template;
    },
    getRowIndexByContentUnlayer: ({ design, content }) => {
      if (!content.id && !content.slug && !content.type) return -1;

      return design.body.rows.findIndex((row) =>
        row.columns.some((column) =>
          column.contents.some((colContent) =>
            Object.entries(content).every(([key, value]) => colContent[key as keyof Content] === value),
          ),
        ),
      );
    },
    getColumnIndexByContentUnlayer: ({ design, rowIndex, content }) => {
      if (!content.id && !content.slug && !content.type) return -1;

      return design.body.rows?.[rowIndex]?.columns?.findIndex((column) =>
        column.contents.some((colContent) =>
          Object.entries(content).every(([key, value]) => colContent[key as keyof Content] === value),
        ),
      );
    },
    getContentIndexUnlayer: ({ design, rowIndex, columnIndex, content }) => {
      return design.body.rows?.[rowIndex]?.columns?.[columnIndex]?.contents?.findIndex((colContent) =>
        Object.entries(content).every(([key, value]) => colContent[key as keyof Content] === value),
      );
    },
    getContentUnlayer: ({ design, rowIndex, columnIndex, contentIndex }) =>
      design.body.rows?.[rowIndex]?.columns?.[columnIndex]?.contents?.slice(contentIndex, contentIndex + 1)?.[0],
  };

  return templatesApplication;
};
