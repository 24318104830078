import { isColorByRGB, isColorByHex } from "@/vue/types/colors";
import type { Color } from "@/vue/types/colors";

export const getColorStyle = (color: Color): string => {
  if (isColorByRGB(color)) return color.rgbValue;
  if (isColorByHex(color)) return color.hexValue;
  return color.value;
};

//Convert Hex color to rgb color - Only 6 digits allowed
export const hexColorToRGB = (hexColor: HexColor): [number, number, number] | undefined => {
  const cleanHexColor = hexColor.replace("#", "");
  const aRGBHex = cleanHexColor.match(/.{1,2}/g);
  if (!aRGBHex) return;

  const aRgb = [parseInt(aRGBHex[0], 16), parseInt(aRGBHex[1], 16), parseInt(aRGBHex[2], 16)] as [
    number,
    number,
    number,
  ];
  return aRgb;
};

export const rgbaTohex = (rgba: RGBString) =>
  `#${rgba
    .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)
    ?.slice(1)
    .map((n, i) =>
      (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, "0").replace("NaN", ""),
    )
    .join("")}`;

export const rgbStringToArray = (rgbString: RGBString): [string, string, string] | [string, string, string, string] => {
  if (rgbString.includes("rgba"))
    return rgbString.slice(5, -1).replaceAll(" ", "").split(",") as [string, string, string, string];
  return rgbString.slice(4, -1).replaceAll(" ", "").split(",") as [string, string, string];
};

//Contrast
//https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure

//Luminance contrast beetween two colors
export const luminance = (red: number, green: number, blue: number) => {
  const luminance = [red, green, blue].map((color) => {
    color /= 255;
    return color <= 0.03928 ? color / 12.92 : Math.pow((color + 0.055) / 1.055, 2.4);
  });
  return luminance[0] * 0.2126 + luminance[1] * 0.7152 + luminance[2] * 0.0722;
};

//contrast beetween two colors - [0-21]
export const contrast = (rgb1: [number, number, number], rgb2: [number, number, number]) => {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
};

// 3:1 - minimal recommended contrast ratio for larger font-sizes
// 4.5:1 - minimal recommended contrast ratio
// 7:1 - easier for people with low vision to read
export const meetsMinimumPerfitContrast = (contrastRatio: number) => contrastRatio > 2.5;
export const meetsMinimumContrast = (contrastRatio: number) => contrastRatio > 4.5;
export const meetsLowVisionContrast = (contrastRatio: number) => contrastRatio > 7.1;
