import { _ } from "@/helpers/utils";
import app from "@/app";
import feedbackTpl from "@/modules/froms/templates/feedback.hbs";
import addTpl from "@/modules/froms/templates/modals/add.hbs";
import sendInstructionsTpl from "@/modules/froms/templates/modals/sendInstructions.hbs";
import updateTpl from "@/modules/froms/templates/modals/update.hbs";
import verificationTpl from "@/modules/froms/templates/modals/verification.hbs";

export default {
  // Changes the from field
  changeFrom: function (e) {
    var view = this,
      select = $(e.currentTarget),
      wrapper = select.closest("[data-role=from-selector]"),
      fromStatus = wrapper.find("[data-role=from-status]"),
      fromNameField = wrapper.find(select.data("target")),
      value = select.val(),
      renderData = this.renderData(),
      template = feedbackTpl;
    if (!value) {
      this.$("[name=fromName]").val("").attr("data-changed", 1);
      fromStatus.html(template({ lang: window.lang, from: false }));
      return this;
    }
    if (value === "new") {
      this.currentSelect = select;
      renderData.hasAccountUpdatePermission = app.session.hasPermission(
        "account:update"
      );
      return this.openModal(addTpl, renderData, function (modal) {
        modal.on("hide.bs.modal", function () {
          if (!view.newFrom) {
            select.val("").trigger("change");
          }
          modal.off("hide.bs.modal");
        });
      });
    }
    var from = _.findWhere(this.item.additionalsData.froms, { email: value });
    fromStatus.html(template({ lang: window.lang, from: from }));
    this.afterChangeFrom(from, fromNameField);
  },

  // After change from hook
  // eslint-disable-next-line no-unused-vars
  afterChangeFrom: function (from, fromNameField) {
    //fromNameField.val(from.name).attr('data-changed', 1);
    return this;
  },

  // Adds a from
  addFrom: function (e) {
    var form = e.currentTarget,
      email = $(form).find("input").val(),
      data = $(form).serializeObject();
    e.preventDefault();
    email = data.email;
    this.cleanFormErrorMessages(form);

    if (
      [
        "yahoo",
        "ymail.com",
        "aol.com",
        "rocketmail.com",
        "zohomail",
        "zoho.com",
      ].some(function (k) {
        return email.includes(k);
      })
    ) {
      return this.showValidationErrors(
        { email: window.lang.froms.notAllowed },
        $(form)
      );
    }

    // Check if the email already exists
    if (_.findWhere(this.item.additionalsData.froms, { email: email.trim() })) {
      return this.showValidationErrors(
        { email: window.lang.froms.exists },
        $(form)
      );
    }

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Add from
    this.newFrom = false;
    $.post(
      "froms",
      data,
      function () {
        this.openModal(verificationTpl, { data: data });
      }.bind(this)
    );
  },

  // Verifies a from
  verifyFrom: function (e) {
    var data = $(e.currentTarget).serializeObject();
    // select = this.$("[name=from]");
    e.preventDefault();
    this.cleanFormErrorMessages(e.currentTarget);

    // Send verification code
    $.post(
      "froms",
      data,
      function (response) {
        var from = response.data;
        this.item.additionalsData.froms.push(from);
        this.newFrom = from;
        if (from.status !== "OK") {
          this.openUpdateFromModal(from.email);
        } else {
          this.displayMessage(window.lang.froms.added);
          this.getModal().modal("hide");
        }
        this.verifiedCallback(response);
      }.bind(this)
    );
  },

  // Runs after verifying a from
  verifiedCallback: function () {
    return this;
  },

  // Returns an option element for the new from
  renderFromOption: function (from) {
    return [
      "<option data-name=",
      from.name,
      " data-status=",
      from.status,
      ">",
      from.email,
      "</option>",
    ].join("");
  },

  // Open a modal to update the from
  openUpdateFromModal: function (e) {
    var value = _.isObject(e) ? $(e.currentTarget).data("email") : e;
    //data = this.renderData();
    $.post(
      "froms/refresh",
      { email: value },
      function (response) {
        var data = response.data;
        this.item.additionalsData.froms[
          _.findIndex(this.item.additionalsData.froms, { email: data.email })
        ] = data;
        data.from = _.findWhere(this.item.additionalsData.froms, {
          email: data.email,
        });
        data.spfErrors = ["PERMERROR", "TEMPERROR"];
        this.openModal(updateTpl, data);
      }.bind(this)
    );
  },

  // Updates the from
  updateFrom: function (e) {
    var form = e.currentTarget,
      data = $(form).serializeObject(),
      select = this.$("[name=from]"),
      modal = $(this.modal);
    e.preventDefault();

    // Refresh from
    $.post(
      "froms/refresh",
      data,
      function (response) {
        var data = response.data;
        this.item.additionalsData.froms[
          _.findIndex(this.item.additionalsData.froms, { email: data.email })
        ] = data;
        select.trigger("change");
        if (data.status === "OK") {
          this.displayMessage(window.lang.froms.updated);
          modal.modal("hide");
        } else {
          this.openModal(updateTpl, {
            from: data,
            spfErrors: ["PERMERROR", "TEMPERROR"],
            tryAgain: true,
          });
        }
      }.bind(this)
    );
  },

  // Opens the modal to send configuration instructions to a mail
  openSendInstructionsModal: function (e) {
    var data = $(e.currentTarget).data();
    this.openModal(sendInstructionsTpl, data);
  },

  // Send from configuration instructions
  sendInstructions: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();
    $.post("froms/" + data.id + "/sendinstructions", {
      email: data.email,
      message: data.message,
    }).done(
      function () {
        this.getModal().modal("hide").empty();
        this.displayMessage(window.lang.froms.feedback.instructionsSent);
      }.bind(this)
    );
  },
};
