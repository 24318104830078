<template>
  <div :class="allowEditConfig && 'space-y-10'">
    <div v-if="allowEditConfig" class="space-y-5">
      <p class="text-sm text-gray-500">
        {{ t("PeriodicReportConfigDescription") }}
        <a href="https://docs.myperfit.com/articles/4302126" target="_blank" class="text-blue-400">{{
          t("readMoreButton")
        }}</a>
      </p>
    </div>
    <div v-if="allowEditConfig" class="space-y-10">
      <FormSwitch
        v-model="PeriodicReportConfig.daily.value"
        :label="t('dailyTitle')"
        :description="t('dailyDescription')"
        :loading="PeriodicReportConfig.daily.loading"
        :disabled="PeriodicReportConfig.daily.loading"
      />
      <FormSwitch
        v-model="PeriodicReportConfig.weekly.value"
        :label="t('weeklyTitle')"
        :description="t('weeklyDescription')"
        :loading="PeriodicReportConfig.weekly.loading"
        :disabled="PeriodicReportConfig.weekly.loading"
      />
      <FormSwitch
        v-model="PeriodicReportConfig.monthly.value"
        :label="t('monthlyTitle')"
        :description="t('monthlyDescription')"
        :loading="PeriodicReportConfig.monthly.loading"
        :disabled="PeriodicReportConfig.monthly.loading"
      />
    </div>
    <AlertBox v-else tabindex="0" class="max-w-3xl"> {{ t("messageCannotEditConfig") }}</AlertBox>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, Ref, ref, watch } from "vue";

//lodash
import { cloneDeep } from "lodash";

//Components
import FormSwitch from "@molecules/FormSwitch.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

//API
import {
  DeleteFunctionEmailReportsType,
  IAccountEmailReports,
  PostFunctionEmailReportsType,
  useAccountEmailReports,
} from "@api/modules/account";

//I18n
import { useI18n } from "vue-i18n";

//I18n
const { t } = useI18n();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Types
interface SwitchConfig {
  value: boolean | undefined;
  loading: boolean;
}

interface IPeriodicReportConfig {
  daily: SwitchConfig;
  weekly: SwitchConfig;
  monthly: SwitchConfig;
}

const PeriodicReportConfig: IPeriodicReportConfig = reactive({
  daily: {
    value: undefined,
    loading: false,
  },
  weekly: {
    value: undefined,
    loading: false,
  },
  monthly: {
    value: undefined,
    loading: false,
  },
});

//State
const hasAdminRights = sessionStore.hasPermission("account:update");

const accountReports: Ref<IAccountEmailReports | undefined> = ref(undefined);

const allowEditConfig = computed(
  () =>
    !session.value?.isMasterUser &&
    hasAdminRights &&
    (session.value?.account.parent == null ? true : !session.value?.canChangeAccount)
);

//API
const GetEmailReportsConfig = useAccountEmailReports().get;
const PostDaily = useAccountEmailReports().postDailyConfig;
const PostWeekly = useAccountEmailReports().postWeeklyConfig;
const PostMonthly = useAccountEmailReports().postMonthlyConfig;
const DeleteDaily = useAccountEmailReports().deleteDailyConfig;
const DeleteWeekly = useAccountEmailReports().deleteWeeklyConfig;
const DeleteMonthly = useAccountEmailReports().deleteMonthlyConfig;

const emailReportsAPIMethods = {
  daily: {
    true: PostDaily,
    false: DeleteDaily,
  },
  weekly: {
    true: PostWeekly,
    false: DeleteWeekly,
  },
  monthly: {
    true: PostMonthly,
    false: DeleteMonthly,
  },
};

const UpdateAccountEmailReportsConfig = async ({
  data,
  value,
  configName,
  userID,
  account,
}: {
  data?: {
    email: string;
    context: { first_name: string };
  };
  value: boolean;
  configName: "daily" | "weekly" | "monthly";
  userID: string;
  account?: string;
}) => {
  const Method = emailReportsAPIMethods[configName][value.toString()] as
    | PostFunctionEmailReportsType
    | DeleteFunctionEmailReportsType;

  if (value && data) {
    await (Method as PostFunctionEmailReportsType)({
      data: data,
      userID: userID,
      account: account,
    });
  } else if (!value) {
    await (Method as DeleteFunctionEmailReportsType)({
      userID: userID,
      account: account,
    });
  }
};

//Functions
const FindKeyOfModifiedConfig = (
  arr: Array<keyof IPeriodicReportConfig>,
  newConfig: IPeriodicReportConfig,
  oldConfig: IPeriodicReportConfig
) =>
  arr.find((key) => {
    return (
      newConfig[key].value != undefined &&
      oldConfig[key].value != undefined &&
      newConfig[key].value != oldConfig[key].value
    );
  });

// Watchers
watch(
  () => cloneDeep(PeriodicReportConfig),
  async (newConfig, oldConfig) => {
    if (!allowEditConfig.value || !session.value) return;

    const changedConfigKey = FindKeyOfModifiedConfig(
      Object.keys(newConfig) as Array<keyof IPeriodicReportConfig>,
      newConfig,
      oldConfig
    );

    if (changedConfigKey == undefined || newConfig[changedConfigKey].value == undefined) return;

    PeriodicReportConfig[changedConfigKey].loading = true;

    await UpdateAccountEmailReportsConfig({
      data: {
        email: session.value?.user.email,
        context: { first_name: session.value?.user.name },
      },
      value: newConfig[changedConfigKey].value as boolean,
      configName: changedConfigKey,
      userID: session.value?.account.username,
      account: session.value?.account.code,
    });

    PeriodicReportConfig[changedConfigKey].loading = false;
  },
  { deep: true }
);

//Lifecycle
onMounted(async () => {
  accountReports.value = await GetEmailReportsConfig({
    userID: session.value?.account.username,
    account: session.value?.account.code,
  });

  PeriodicReportConfig.daily.value = accountReports.value.find((report) => report.type == "daily") != undefined;
  PeriodicReportConfig.weekly.value = accountReports.value.find((report) => report.type == "weekly") != undefined;
  PeriodicReportConfig.monthly.value = accountReports.value.find((report) => report.type == "monthly") != undefined;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "PeriodicReportConfigDescription": "Selecciona con qué frecuencia quieres recibir los reportes por email.",
    "readMoreButton": "Leer más",
    "dailyTitle": "Diario",
    "dailyDescription": "Recibirás los reportes todos los días por la mañana.",
    "weeklyTitle": "Semanal",
    "weeklyDescription": "Recibirás los reportes todos los lunes.",
    "monthlyTitle": "Mensual",
    "monthlyDescription": "Recibirás los reportes el primer día de cada mes.",
    "messageCannotEditConfig": "Para poder activar los informes periódicos debes utilizar un usuario propio de la cuenta. Puedes gestionar la configuración de otros usuarios de la cuenta desde la sección Ajustes."
  },
  "pt": {
    "PeriodicReportConfigDescription": "Selecione com que frequência você quer receber os reportes por email.",
    "readMoreButton": "Ler mais",
    "dailyTitle": "Diário",
    "dailyDescription": "Receberá os reportes diariamente pela manhã.",
    "weeklyTitle": "Semanal",
    "weeklyDescription": "Receberá os reportes toda Segunda-feira.",
    "monthlyTitle": "Mensal",
    "monthlyDescription": "Receberá os reportes no primeiro dia de cada mês.",
    "messageCannotEditConfig": "Para poder ativar os relatórios periódicos deverá utilizar um usuário próprio da conta. Você pode gerenciar a configuração de outros usuários da conta desde a seção Configurações."
  }
}
</i18n>
