var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hint-box hint-alert\">\n        <h4>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"summaryTitle")), depth0))
    + "</h4>\n        <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"cannotSend")), depth0))
    + "</p>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":28,"column":15}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='hint-box "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":112}}})) != null ? stack1 : "")
    + "'>\n            <h4>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"from")), depth0))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "            </h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"errors") : stack1),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":22,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"warnings") : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":26,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " hint-warning ";
},"5":function(container,depth0,helpers,partials,data) {
    return " hint-good ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <small>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"senderIsValid")), depth0))
    + "\n                </small>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class='validation-error'>"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</p>\n            <p class=\"legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"fromAuthHelp")), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class='validation-warning'>"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</p>\n            <p class=\"legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"fromAuthHelp")), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"hint-box hint-good\">\n        <h4>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"summaryTitle")), depth0))
    + "</h4>\n        <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"readyToSend")), depth0))
    + "</p>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"inTrial")),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":42,"column":15}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='hint-box hint-info'>\n        <h4>\n            "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"campaignsSlow")), depth0)) != null ? stack1 : "")
    + "\n            <small></small>\n        </h4>\n    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div style=\"margin-top: 0.5rem;\">\n\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"SPLIT_AB",{"name":"is","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":71,"column":23}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div style=\"margin-top: 0.5rem;\">\n                <p class='field-name'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"from")), depth0))
    + "\n                </p>\n                <p class='from'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"from") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":79,"column":20},"end":{"line":83,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n            </div>\n            <div style=\"margin-top: 0.5rem;\">\n                <p class='field-name'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"replyTo")), depth0))
    + "\n                </p>\n                <p class='from'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"replyTo") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":91,"column":20},"end":{"line":96,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n            </div>\n            <div style=\"margin-top: 0.5rem;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"includeAllContacts") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":100,"column":16},"end":{"line":150,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"exclude") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":151,"column":16},"end":{"line":201,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"subjects")), depth0))
    + "\n                </p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"subjects")),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":57,"column":25}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                <p class='subject'>\n                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                </p>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n                </p>\n\n                <p class='subject'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":69,"column":27}}})) != null ? stack1 : "")
    + "                </p>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"empty\">("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"empty")), depth0))
    + ")</span>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"fromName") : stack1), depth0))
    + " &lt;"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"from") : stack1), depth0))
    + "&gt;\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    &lt;"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"defaultContent") : depth0)) != null ? lookupProperty(stack1,"replyTo") : stack1), depth0))
    + "&gt;\n\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name include'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"includedContacts")), depth0))
    + "\n                </p>\n                <div class='selected-items-list'>\n                    <ul class='lists'>\n                        <li>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"allContacts")), depth0))
    + "</li>\n                    </ul>\n                </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='selected-items-list'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":20},"end":{"line":124,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"interests") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":20},"end":{"line":136,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"campaigns") : stack1),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":148,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class='field-name include'>\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"includedLists")), depth0))
    + "\n                    </p>\n                    <ul class='lists'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":24},"end":{"line":122,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":24},"end":{"line":121,"column":31}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\n                            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                        </li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class='field-name include'>\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"includedInterests")), depth0))
    + "\n                    </p>\n                    <ul class='interests'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"interests") : stack1),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":24},"end":{"line":134,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class='field-name include'>\n                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"includedCampaigns")), depth0))
    + "\n                    </p>\n                    <ul class='campaigns'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"include") : depth0)) != null ? lookupProperty(stack1,"campaigns") : stack1),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":24},"end":{"line":146,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\n                            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " ("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"action") : depth0), depth0))
    + ")\n                        </li>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":16},"end":{"line":165,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"interests") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":16},"end":{"line":179,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"campaigns") : stack1),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":16},"end":{"line":193,"column":23}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name exclude'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"excludedLists")), depth0))
    + "\n                </p>\n                <div class='selected-items-list'>\n                    <ul class='lists'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":24},"end":{"line":162,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name exclude'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"excludedInterests")), depth0))
    + "\n                </p>\n                <div class='selected-items-list'>\n                    <ul class='interests'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"interests") : stack1),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":24},"end":{"line":176,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name exclude'>\n                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"excludedCampaigns")), depth0))
    + "\n                </p>\n                <div class='selected-items-list'>\n                    <ul class='campaigns'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"exclude") : depth0)) != null ? lookupProperty(stack1,"campaigns") : stack1),{"name":"each","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":24},"end":{"line":190,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class='field-name exclude'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"excludedContacts")), depth0))
    + "\n                </p>\n                <p class='field-data'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"none")), depth0))
    + "\n                </p>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":24},"end":{"line":207,"column":71}}})) != null ? stack1 : "")
    + " type='button' class='btn btn-primary'\n                    data-action=\"launch-now\" data-confirm-launch=\"1\" data-require-permission='campaigns:launch'>\n                    <i class='fa fa-paper-plane'></i>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"launchNow")), depth0))
    + "\n                </button>\n                <button "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":24},"end":{"line":212,"column":71}}})) != null ? stack1 : "")
    + " type='button'\n                    class='btn btn-primary btn-outline' data-action=\"open-schedule-modal\"\n                    data-require-permission='campaigns:launch'>\n                    <i class='fa fa-calendar'></i>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"scheduleLaunch")), depth0))
    + "\n                </button>\n";
},"51":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"hint-box hint-info\" style=\"margin-bottom: 2rem;\">\n                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"smartCampaignInfo")), depth0)) != null ? stack1 : "")
    + "\n                </div>\n                <div id=\"vue-smart-scheduler\"></div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <small>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"required") : stack1),"TBD",{"name":"is","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":239,"column":16},"end":{"line":244,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"options") : stack1)) != null ? lookupProperty(stack1,"EXCLUDE_INACTIVE") : stack1),"1",{"name":"is","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":16},"end":{"line":248,"column":23}}})) != null ? stack1 : "")
    + "            </small>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"undefinedRecipients")), depth0))
    + "\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"destinatariesCount")), depth0))
    + ":\n                "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"required") : stack1), depth0))
    + "\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <br/><br/>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"recpientsExcludeWarning")), depth0))
    + "\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class='validation-error'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"invalidCountIntro")), depth0))
    + "\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"required") : stack1), depth0))
    + "\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"invalidCountOutro")), depth0))
    + "\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"available") : stack1), depth0))
    + ".\n        </p>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <small>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"senderIsValid")), depth0))
    + "\n            </small>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class='validation-error'>"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</p>\n        <p class=\"legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"fromAuthHelp")), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class='validation-warning'>"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</p>\n        <p class=\"legend\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"fromAuthHelp")), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</li>\n";
},"72":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='campaign-summary'>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "    <div class='row'>\n        <div class='col-md-8'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":203,"column":21}}})) != null ? stack1 : "")
    + "\n            <div class='launch-actions'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"SMART",{"name":"isNot","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":206,"column":12},"end":{"line":223,"column":22}}})) != null ? stack1 : "")
    + "            </div>\n\n        </div>\n        <div class='col-md-4'>\n            <div class=\"preview\">\n                <img src=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"thumbnail") : stack1), depth0))
    + "?_="
    + alias3(__default(require("../../../helpers/handlebars/random.js")).call(alias1,{"name":"random","hash":{},"data":data,"loc":{"start":{"line":229,"column":58},"end":{"line":229,"column":68}}}))
    + "\" class=\"img-responsive\">\n            </div>\n        </div>\n    </div>\n    <hr />\n    <div class='hint-box "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"recipients") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":234,"column":25},"end":{"line":234,"column":114}}})) != null ? stack1 : "")
    + "'>\n        <h4>\n            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"destinataries")), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"canSend") : stack1),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":12},"end":{"line":250,"column":19}}})) != null ? stack1 : "")
    + "        </h4>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"count") : stack1)) != null ? lookupProperty(stack1,"canSend") : stack1),{"name":"unless","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":252,"column":8},"end":{"line":259,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n    <div class='hint-box "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":261,"column":25},"end":{"line":261,"column":108}}})) != null ? stack1 : "")
    + "'>\n        <h4>\n            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"from")), depth0))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":265,"column":12},"end":{"line":269,"column":19}}})) != null ? stack1 : "")
    + "        </h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"errors") : stack1),{"name":"each","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":271,"column":8},"end":{"line":274,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"from") : stack1)) != null ? lookupProperty(stack1,"warnings") : stack1),{"name":"each","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":275,"column":8},"end":{"line":278,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n    <div class='hint-box "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"contents") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":280,"column":25},"end":{"line":280,"column":112}}})) != null ? stack1 : "")
    + "'>\n        <h4>\n            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"content")), depth0))
    + "\n            <small>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"contents") : stack1)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\n            </small>\n        </h4>\n        <p>\n            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"contents") : stack1)) != null ? lookupProperty(stack1,"errors") : stack1),{"name":"each","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":289,"column":16},"end":{"line":291,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"contents") : stack1)) != null ? lookupProperty(stack1,"warnings") : stack1),{"name":"each","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":292,"column":16},"end":{"line":294,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </p>\n    </div>\n    <div class='hint-box "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"valid") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":298,"column":25},"end":{"line":298,"column":111}}})) != null ? stack1 : "")
    + "'>\n        <h4>\n            "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"accountStatus")), depth0))
    + "\n            <small>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"message") : stack1), depth0))
    + "\n            </small>\n        </h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"errors") : stack1),{"name":"each","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":305,"column":8},"end":{"line":307,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"validation") : depth0)) != null ? lookupProperty(stack1,"tests") : stack1)) != null ? lookupProperty(stack1,"account") : stack1)) != null ? lookupProperty(stack1,"warnings") : stack1),{"name":"each","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":308,"column":8},"end":{"line":310,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true});