<template>
  <TransitionRoot :show="open" as="div" appear @after-leave="cleanSearch">
    <Dialog as="div" static class="relative z-60" :open="open" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-60 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox v-slot="{ activeOption }" @update:modelValue="updateSelected">
              <div class="relative">
                <SearchIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                  :placeholder="t('inputPlaceholder')"
                  @change="(e) => updateSearch(e.target.value)"
                />
                <LoadingSpinner
                  v-show="loading"
                  class="absolute right-3 top-3.5 h-5 w-5 text-sky-500"
                  aria-hidden="true"
                />
              </div>
              <ComboboxOptions
                v-if="search === '' || filteredItems.length > 0"
                class="flex divide-x divide-gray-100"
                as="div"
                static
                hold
              >
                <div
                  :class="[
                    'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                    activeOption && 'sm:h-96',
                  ]"
                >
                  <h2 v-show="showStaticItems" class="mb-2 mt-2 text-xs font-semibold text-gray-500">
                    {{ staticItemLabel }}
                  </h2>
                  <div v-show="!loading" hold class="-mx-2 text-sm text-gray-700">
                    <ComboboxOption
                      v-for="item in staticItems"
                      v-show="staticItems?.length > 0"
                      :key="item.id"
                      v-slot="{ active }"
                      :value="item"
                      as="template"
                    >
                      <div
                        :class="[
                          'group flex cursor-pointer select-none items-center justify-between rounded-md p-2',
                          active && 'bg-gray-100 text-gray-900',
                        ]"
                      >
                        <div class="flex space-x-2 truncate">
                          <img v-show="item.image" :src="item.image" alt="" class="h-6 w-6 flex-none rounded-full" />
                          <div class="ml-3 flex space-x-2">
                            <span class="text-gray-700">{{ item.title }}</span>
                            <span class="text-sm text-gray-400">{{ item.subtitle }}</span>
                          </div>
                        </div>
                        <ChevronRightIcon v-if="active" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      </div>
                    </ComboboxOption>
                    <h2 v-show="showRecentItems" class="mb-2 ml-2 mt-4 text-xs font-semibold text-gray-500">
                      {{ recentsLabel }}
                    </h2>
                    <h2
                      v-show="!showRecentItems && filteredItems.length > 0"
                      class="mb-2 ml-2 mt-4 text-xs font-semibold text-gray-500"
                    >
                      {{ itemsLabel }}
                    </h2>
                    <ComboboxOption
                      v-for="item in showRecentItems ? recent : filteredItems"
                      :key="item.id"
                      v-slot="{ active }"
                      :value="item"
                      as="template"
                    >
                      <div
                        :class="[
                          'group flex cursor-pointer select-none items-center justify-between rounded-md p-2',
                          active && 'bg-gray-100 text-gray-900',
                        ]"
                      >
                        <div class="flex space-x-2 truncate">
                          <ClockIcon v-show="!item.image && showRecentItems" class="h-6 w-6 shrink-0 text-gray-300" />
                          <img v-show="item.image" :src="item.image" alt="" class="h-6 w-6 flex-none rounded-full" />
                          <div class="ml-3 flex space-x-2">
                            <span class="text-gray-700">{{ item.title }}</span>
                            <span class="text-sm text-gray-400">{{ item.subtitle }}</span>
                          </div>
                        </div>
                        <ChevronRightIcon v-if="active" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      </div>
                    </ComboboxOption>
                  </div>
                </div>
                <div
                  v-show="!loading && !isEmpty(activeOption)"
                  class="flex h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto"
                >
                  <div v-if="activeOption?.image" class="flex-none px-6 py-2 text-center">
                    <img :src="activeOption?.image" alt="" class="mx-auto h-16 w-16 rounded-full" />
                    <h2 class="mt-3 font-semibold text-gray-900">
                      {{ activeOption?.name }}
                    </h2>
                    <p class="text-sm leading-6 text-gray-500">{{ activeOption?.role }}</p>
                  </div>
                  <div v-else-if="activeOption?.icon" class="flex items-center space-x-4 p-6">
                    <component :is="activeOption?.icon" class="h-12 w-12 text-gray-300" />
                    <div class="items-center">
                      <h2 class="m-0 text-base font-semibold text-gray-600">
                        {{ activeOption?.title }}
                      </h2>
                      <p class="text-sm leading-6 text-gray-400">{{ activeOption?.subtitle }}</p>
                    </div>
                  </div>
                  <div class="flex flex-auto flex-col justify-between p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <template v-for="content in activeOption?.content" :key="content.key">
                        <dt class="col-end-1 font-semibold text-gray-900">{{ content?.data?.key ?? content?.key }}</dt>
                        <SimpleBadge v-if="content?.data?.isBadge" :theme="content?.data?.badgeColor" class="w-max">
                          {{ content.value }}
                        </SimpleBadge>
                        <dd v-else>{{ content.value }}</dd>
                      </template>

                      <!-- <dt class="col-end-1 font-semibold text-gray-900">URL</dt>
                      <dd class="truncate">
                        <a :href="activeOption.url" class="text-indigo-600 underline">
                          {{ activeOption.url }}
                        </a>
                      </dd>
                      <dt class="col-end-1 font-semibold text-gray-900">Email</dt>
                      <dd class="truncate">
                        <a :href="`mailto:${activeOption.email}`" class="text-indigo-600 underline">
                          {{ activeOption.email }}
                        </a>
                      </dd> -->
                    </dl>
                  </div>
                </div>
              </ComboboxOptions>

              <div
                v-else-if="search !== '' && filteredItems.length === 0"
                class="px-6 py-14 text-center text-sm sm:px-14"
              >
                <SearchCircleIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 font-semibold text-gray-900">{{ t("noResultsTitle") }}</p>
                <p class="mt-2 text-gray-500">{{ t("noResultsText") }}</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import type { Items } from "./command.type";

// Helpers
import { formatTextToSearch } from "@helpers/formatters";

// Components
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import SimpleBadge from "@atoms/SimpleBadge";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import { SearchIcon, ClockIcon } from "@heroicons/vue/solid";
import { ChevronRightIcon, SearchCircleIcon } from "@heroicons/vue/outline";

// Utils
import { useI18n } from "vue-i18n";
import { isEmpty } from "lodash";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open: boolean;
    search: string;
    items: Items;
    itemsLabel?: string;
    staticItems?: Items;
    staticItemLabel?: string;
    recent?: Items;
    recentsLabel?: string;
    loading?: boolean;
    asyncSearch?: boolean;
  }>(),
  {
    open: false,
    search: "",
    loading: false,
    items: () => [],
    staticItems: () => [],
    recent: () => [],
  },
);

// Emits
const emit = defineEmits<{
  "update:search": [string];
  "update:open": [boolean];
  select: [{ id: string; title: string }];
}>();

const close = () => {
  emit("update:open", false);
};
const cleanSearch = () => {
  emit("update:search", "");
};

const updateSearch = (value: string) => {
  emit("update:search", value);
};

const showRecentItems = computed(() => {
  return props.search === "" && props.recent?.length > 0;
});

const showStaticItems = computed(() => {
  return props.staticItems?.length > 0;
});

const filteredItems = computed(() => {
  if (props.asyncSearch) return props.items;

  return props.items.filter((item) => {
    const searchText = formatTextToSearch(props.search);
    return [formatTextToSearch(item.id), formatTextToSearch(item.title)].some((text) => text.includes(searchText));
  });
});

const selected = ref<{ id: string; title: string }>();
const updateSelected = (newValue: { id: string; title: string }) => {
  selected.value = newValue;

  const selectedItem = props.items.find((item) => item.id === newValue.id);
  if (selectedItem) {
    emit("select", selectedItem);
    close();
    return;
  }

  const selectedItemStatic = props.staticItems.find((item) => item.id === newValue.id);
  if (selectedItemStatic) {
    emit("select", selectedItemStatic);
    close();
    return;
  }

  const selectedRecentItems = props.recent.find((item) => item.id === newValue.id);
  if (selectedRecentItems) {
    emit("select", selectedRecentItems);
    close();
    return;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "inputPlaceholder": "Busqueda",
    "noResultsTitle": "Sin resultados",
    "noResultsText": "Mejore su busqueda"
  },
  "pt": {
    "inputPlaceholder": "Pesquisar",
    "noResultsTitle": "Sem resultados",
    "noResultsText": "Melhore sua pesquisa"
  }
}
</i18n>
