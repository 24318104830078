import { ref } from "vue";
import { defineStore } from "pinia";

// Application
import { useUserActivityApplication } from "@application";

// Types
import type { UserActivity } from "@domain/userActivity";

export const useUserActivityStore = defineStore("userActivityStore", () => {
  const userActivityApp = useUserActivityApplication();

  const events = ref<UserActivity[]>([]);
  const eventsFetched = ref<boolean>(false);

  const fetchEvents = async () => {
    const res = await userActivityApp.getFirstEvents();

    if (res.isErr()) return;
    events.value = res.value;
    eventsFetched.value = true;
  };

  return { events, eventsFetched, fetchEvents };
});
