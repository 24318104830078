<template>
  <ModalDialog :title="t('ApplyPlanDiscountTitle')" :open="isOpen" without-overflow @close="HandleClose">
    <ComboBox
      v-model="selectedCode"
      v-model:data-items="Promotions"
      :label="t('applyPlanDiscountLabel')"
      allow-custom-values
      class="w-96"
    />
    <template #buttons>
      <Button :disabled="!selectedCode" :loading="loading" @click="ApplyDiscount">{{ t("ApplyButton") }}</Button>
      <Button theme="secondary-text" @click="HandleClose">{{ t("cancelButton") }}</Button>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
//Types
import type { DataItem, DataItems } from "@molecules/ComboBox.vue";

//Components
import ModalDialog from "@molecules/ModalDialog.vue";
import ComboBox from "@molecules/ComboBox.vue";
import Button from "@atoms/SimpleButton.vue";
import { useI18n } from "vue-i18n";
import { useAccountPlanDiscount } from "@/vue/api/modules/accountplan";
import { useNotifications } from "@/vue/composables/notifications";

//API

//Props
const props = withDefaults(
  defineProps<{
    isOpen?: boolean;
    defaultPromoCode?: string;
  }>(),
  {
    isOpen: false,
    defaultPromoCode: undefined,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:isOpen", value: boolean): void;
  (e: "AppliedDiscount"): void;
  (e: "close"): void;
}>();

const HandleClose = () => {
  emit("update:isOpen", false);
  emit("close");
};

const { notify } = useNotifications();

//I18n
const { t } = useI18n();

//Const
const { applyDiscount } = useAccountPlanDiscount();

const Promotions: DataItems = [
  {
    key: "CUSTADISC10OFF",
    value: "CUSTADISC10OFF - 10%",
  },
  {
    key: "CUSTHDISC15OFF",
    value: "CUSTHDISC15OFF - 15%",
  },
  {
    key: "CUSTDDISC20OFF",
    value: "CUSTDDISC20OFF - 20%",
  },
  {
    key: "TIENDANUBE",
    value: "TIENDANUBE - 20%",
  },
  {
    key: "CUSTBDISC25OFF",
    value: "CUSTBDISC25OFF - 25%",
  },
  {
    key: "CUSTIDISC30OFF",
    value: "CUSTIDISC30OFF - 30%",
  },
  {
    key: "EVOLUCION30OFF",
    value: "EVOLUCION30OFF - 30%",
  },
  {
    key: "NEXT30OFF",
    value: "NEXT30OFF - 30%"
  },
  {
    key: "CUSTEDISC35OFF",
    value: "CUSTEDISC35OFF - 35%",
  },
  {
    key: "ONG50OFF",
    value: "ONG50OFF - 50%",
  }
];

//State
const selectedCode = ref<DataItem>();
const loading = ref(false);

//Methods
const ApplyDiscount = async () => {
  try {
    if (!selectedCode.value) return;

    await applyDiscount(selectedCode.value?.key?.toString(), loading);
    notify({
      title: t("successTitleApplyDiscount"),
      text: t("successTextApplyDiscount") + " " + selectedCode.value?.value,
      theme: "success",
    });
    loading.value = false;
    emit("AppliedDiscount");
    HandleClose();
  } catch (e) {
    loading.value = false;
    HandleClose();
  }
};

const getAccountPlanDiscount = () => {
  const promoCode = props.defaultPromoCode;

  if (!promoCode) return;

  const discount = Promotions.find((discount) => discount?.key == promoCode);

  if (promoCode && discount) selectedCode.value = discount;

  selectedCode.value = { key: promoCode, value: promoCode };
};

onMounted(() => {
  getAccountPlanDiscount();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "ApplyPlanDiscountTitle": "Aplicar descuento",
    "applyPlanDiscountLabel": "Seleccione el descuento a aplicar",
    "cancelButton": "Cancelar",
    "ApplyButton": "Aplicar descuento",
    "successTitleApplyDiscount": "Descuento aplicado",
    "successTextApplyDiscount": "Se ha aplicado el cupón:"
  },
  "pt": {
    "ApplyPlanDiscountTitle": "Aplicar desconto",
    "applyPlanDiscountLabel": "Selecione o desconto a aplicar",
    "cancelButton": "Cancelar",
    "ApplyButton": "Aplicar desconto",
    "successTitleApplyDiscount": "Desconto aplicado",
    "successTextApplyDiscount": "Se ha aplicado o cupão:"
  }
}
</i18n>
