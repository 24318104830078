var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"myself") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":12,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"me")), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"create")), depth0))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <form class='save' data-form='save'>\n    <div class='toolbar edit-mode'>\n      <button type='submit' class='btn btn-primary' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":21,"column":52},"end":{"line":22,"column":57}}})) != null ? stack1 : "")
    + ">\n        <i class='fa fa-check'></i>\n        "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"save")), depth0))
    + "\n      </button>\n    </div>\n    <div class=\"padder\">\n      <div class='panel panel-default'>\n        <div class='panel-body panel-body-padded'>\n          <div class='row'>\n            <div class='col-md-6'>\n              <div class='form-group'>\n                <label>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"language")), depth0))
    + "\n                </label>\n                <select class='form-control' name='language'>\n                  <option "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"language") : stack1),"es",{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":37,"column":81}}})) != null ? stack1 : "")
    + " value='es'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"es")), depth0))
    + "\n                  </option>\n                  <option "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"language") : stack1),"pt",{"name":"is","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":40,"column":81}}})) != null ? stack1 : "")
    + " value='pt'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"pt")), depth0))
    + "\n                  </option>\n                </select>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " data-require-permission='contacts:update' ";
},"9":function(container,depth0,helpers,partials,data) {
    return "\n        data-require-permission='contacts:create' ";
},"11":function(container,depth0,helpers,partials,data) {
    return " selected ";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <form class='save' data-form='save'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":57,"column":11}}})) != null ? stack1 : "")
    + "    <div class='toolbar edit-mode'>\n      <button type='submit' class='btn btn-primary' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":59,"column":52},"end":{"line":60,"column":57}}})) != null ? stack1 : "")
    + ">\n        <i class='fa fa-check'></i>\n        "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"save")), depth0))
    + "\n      </button>\n    </div>\n    <div class='content edit scrollbar'>\n      <div class='padder'>\n        <div class='panel panel-default'>\n          <div class='panel-body panel-body-padded'>\n            <div class='row'>\n              <div class='col-md-12'>\n                <div class='form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"name")), depth0))
    + "\n                  </label>\n                  <input class='form-control' name='name' type='text' value='"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "'\n                    placeholder='"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fullname")), depth0))
    + "'>\n                </div>\n              </div>\n            </div>\n            <div class='row'>\n              <div class='col-md-6'>\n                <div class='form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"email")), depth0))
    + "\n                  </label>\n                  <input class='form-control' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":46},"end":{"line":86,"column":106}}})) != null ? stack1 : "")
    + " name='email'\n                    autocomplete='off' type='text' value='"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "'\n                    placeholder='"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"emailPlaceholder")), depth0))
    + "'>\n                </div>\n              </div>\n              <div class='col-md-6'>\n                <div class='form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"username")), depth0))
    + "\n                  </label>\n                  <input class='form-control' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":46},"end":{"line":96,"column":106}}})) != null ? stack1 : "")
    + "\n                    name='username' type='text' value='"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "' autocomplete='off'\n                    placeholder='"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"usernamePlaceholder")), depth0))
    + "'>\n                </div>\n              </div>\n            </div>\n            <div class='row'>\n              <div class='col-md-6'>\n                <div class='form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"language")), depth0))
    + "\n                  </label>\n                  <select class='form-control' name='language'>\n                    <option "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"language") : stack1),"es",{"name":"is","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":28},"end":{"line":109,"column":83}}})) != null ? stack1 : "")
    + " value='es'>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"es")), depth0))
    + "\n                    </option>\n                    <option "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"language") : stack1),"pt",{"name":"is","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":28},"end":{"line":112,"column":83}}})) != null ? stack1 : "")
    + " value='pt'>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"languages"))) && lookupProperty(stack1,"pt")), depth0))
    + "\n                    </option>\n                  </select>\n                </div>\n              </div>\n              <div class='col-md-6'>\n                <div class='form-group'>\n                  <label>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"localtime")), depth0))
    + ": <span id=\"local-time-sample\"></span>\n                  </label>\n                  <select class='form-control' name='timezone'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"relationshipsData"))) && lookupProperty(stack1,"timezone")),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":20},"end":{"line":128,"column":29}}})) != null ? stack1 : "")
    + "                  </select>\n                </div>\n              </div>\n            </div>\n            <div class='form-group' data-require-permission='users:update'>\n              <div class='graphic checkbox'>\n                <input id='passNeverExpires' value='1' name='passNeverExpires' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"passNeverExpires") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":79},"end":{"line":136,"column":43}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"unless","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":44},"end":{"line":137,"column":29}}})) != null ? stack1 : "")
    + " type='checkbox'>\n                <label for='passNeverExpires'>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"passNeverExpires")), depth0))
    + "\n                </label>\n                <p class='input-legend'>"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"passNeverExpiresLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n              </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"unless","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":12},"end":{"line":164,"column":23}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"config") : depth0)) != null ? lookupProperty(stack1,"whiteLabel") : stack1),{"name":"unless","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":8},"end":{"line":208,"column":19}}})) != null ? stack1 : "")
    + "        <div class='panel panel-default'>\n          <div class='panel-body panel-body-padded'>\n            <div class='roles'>\n              <h4 class='fields-title'>\n                "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"permissions")), depth0))
    + "\n                <small>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"role") : stack1),"CUSTOM",{"name":"is","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.program(38, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":215,"column":18},"end":{"line":219,"column":25}}})) != null ? stack1 : "")
    + "                </small>\n              </h4>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"role") : stack1),"CUSTOM",{"name":"is","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":222,"column":14},"end":{"line":235,"column":21}}})) != null ? stack1 : "")
    + "              <div class='tab-content'>\n                <div id='permissions' class='tab-pane "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"role") : stack1),"CUSTOM",{"name":"is","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":54},"end":{"line":238,"column":49}}})) != null ? stack1 : "")
    + " permissions-list'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"relationshipsData") : stack1)) != null ? lookupProperty(stack1,"permissions") : stack1),{"name":"each","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":239,"column":18},"end":{"line":255,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div id='roles' class='tab-pane "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"unless","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":257,"column":48},"end":{"line":258,"column":57}}})) != null ? stack1 : "")
    + "'>\n                  <div class='radios'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"relationshipsData") : stack1)) != null ? lookupProperty(stack1,"role") : stack1),{"name":"each","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":260,"column":20},"end":{"line":278,"column":29}}})) != null ? stack1 : "")
    + "                  </div>\n                </div>\n              </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"account"))) && lookupProperty(stack1,"container")),{"name":"if","hash":{},"fn":container.program(62, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":282,"column":14},"end":{"line":294,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </form>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input type='hidden' name='id' value='"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return " disabled='disabled' ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <option value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":43},"end":{"line":125,"column":86}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"id") : depth0),"-",{"name":"is","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":87},"end":{"line":125,"column":117}}})) != null ? stack1 : "")
    + ">\n                      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\n                    </option>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return " selected='selected' ";
},"21":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"23":function(container,depth0,helpers,partials,data) {
    return "\n                  checked='checked' ";
},"25":function(container,depth0,helpers,partials,data) {
    return " checked='checked' data-changed='1'\n                  ";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class='form-group'>\n              <div class='graphic checkbox'>\n                <input id='field-with-password' type='checkbox' data-toggle='optional' data-target='#create-password'>\n                <label for='field-with-password' class='check-cell'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"createUserWithPassword")), depth0))
    + "\n                </label>\n                <p class='input-legend'>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"createUserWithPasswordLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n              </div>\n            </div>\n            <div class='form-group optional disabled' id='create-password'>\n              <label>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"password")), depth0))
    + "\n              </label>\n              <div class='input-group'>\n                <input type='password' class='form-control' name='password' autocomplete='new-password'>\n                <a class='input-group-addon toggle-password'><i class='fa fa-eye'></i></a>\n              </div>\n              <p class='input-legend'>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"passwordUpdateRequired")), depth0)) != null ? stack1 : "")
    + "</p>\n            </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='panel panel-default' data-require-permission='users:update'>\n          <div class='panel-body panel-body-padded'>\n            <h4 class='fields-title'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reports")), depth0))
    + "\n              <small>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"myself") : stack1),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":173,"column":16},"end":{"line":177,"column":23}}})) != null ? stack1 : "")
    + "              </small>\n            </h4>\n            <div class='form-group'>\n              <div class='graphic checkbox'>\n                <input id='reportDaily' value='1' name='reportDaily' data-changed='1' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"emailReports") : stack1)) != null ? lookupProperty(stack1,"daily") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":86},"end":{"line":184,"column":80}}})) != null ? stack1 : "")
    + " type='checkbox'>\n                <label for='reportDaily'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reportDaily")), depth0))
    + "\n                </label>\n              </div>\n              <div class='graphic checkbox'>\n                <input id='reportWeekly' value='1' name='reportWeekly' data-changed='1' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"emailReports") : stack1)) != null ? lookupProperty(stack1,"weekly") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":88},"end":{"line":191,"column":81}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":82},"end":{"line":192,"column":47}}})) != null ? stack1 : "")
    + " type='checkbox'>\n                <label for='reportWeekly'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reportWeekly")), depth0))
    + "\n                </label>\n              </div>\n              <div class='graphic checkbox'>\n                <input id='reportMonthly' value='1' name='reportMonthly' data-changed='1' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"emailReports") : stack1)) != null ? lookupProperty(stack1,"monthly") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":90},"end":{"line":199,"column":82}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":83},"end":{"line":200,"column":47}}})) != null ? stack1 : "")
    + " type='checkbox'>\n                <label for='reportMonthly'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reportMonthly")), depth0))
    + "\n                </label>\n              </div>\n            </div>\n          </div>\n        </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reportsDescriptionMyself")), depth0)) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"reportsDescription")), depth0)) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    return " checked='checked' ";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"customPermissionsDescription")), depth0)) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + ((stack1 = container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"permissionsDescription")), depth0)) != null ? stack1 : "")
    + "\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <ul class='nav nav-pills'>\n                <li class='active'>\n                  <a href='#permissions' data-toggle='tab'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"editPermissions")), depth0))
    + "\n                  </a>\n                </li>\n                <li>\n                  <a href='#roles' data-toggle='tab'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"assignRole")), depth0))
    + "\n                  </a>\n                </li>\n              </ul>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "\n                                    active";
},"44":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h4>"
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"permissionGroupNames")),(data && lookupProperty(data,"key")),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":240,"column":22},"end":{"line":240,"column":78}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":241,"column":18},"end":{"line":254,"column":27}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class='graphic checkbox sublist'>\n                    <input name='permissions[]' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":243,"column":63},"end":{"line":243,"column":104}}})) != null ? stack1 : "")
    + "\n                      id='permission-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "' type='checkbox' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"myself")),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":244,"column":79},"end":{"line":245,"column":49}}})) != null ? stack1 : "")
    + ">\n                    <label for='permission-"
    + alias2(alias1((container.data(data, 1) && lookupProperty(container.data(data, 1),"index")), depth0))
    + "-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "'\n                      class='check-cell "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"myself")),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":40},"end":{"line":247,"column":96}}})) != null ? stack1 : "")
    + "'>\n                      "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"permissionNames")),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":248,"column":22},"end":{"line":248,"column":71}}}))
    + "\n                      <small>\n                        "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"permissionDescriptions")),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":250,"column":24},"end":{"line":250,"column":80}}}))
    + "\n                      </small>\n                    </label>\n                  </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "\n                      disabled='disabled' ";
},"48":function(container,depth0,helpers,partials,data) {
    return " not-allowed ";
},"50":function(container,depth0,helpers,partials,data) {
    return " active ";
},"52":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"role") : stack1),"\n                  CUSTOM",{"name":"isNot","hash":{},"fn":container.program(50, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":257,"column":84},"end":{"line":258,"column":45}}})) != null ? stack1 : "")
    + " ";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),"CUSTOM",{"name":"isNot","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":20},"end":{"line":277,"column":30}}})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class='graphic radio'>\n                      <input id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='radio' name='role' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"id")),{"name":"if","hash":{},"fn":container.program(56, data, 0),"inverse":container.program(59, data, 0),"data":data,"loc":{"start":{"line":263,"column":81},"end":{"line":265,"column":39}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"myself")),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":265,"column":40},"end":{"line":265,"column":104}}})) != null ? stack1 : "")
    + ">\n                      <label for='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n                        <div class='role'>\n                          <h4>\n                            "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users")),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":269,"column":28},"end":{"line":269,"column":61}}}))
    + "\n                          </h4>\n                          <p>\n                            "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"roleDescriptions")),(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":272,"column":28},"end":{"line":272,"column":78}}}))
    + "\n                          </p>\n                        </div>\n                      </label>\n                    </div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":103},"end":{"line":264,"column":49}}})) != null ? stack1 : "")
    + " ";
},"57":function(container,depth0,helpers,partials,data) {
    return "\n                        checked='checked' ";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),"ADMIN",{"name":"is","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":264,"column":59},"end":{"line":265,"column":31}}})) != null ? stack1 : "")
    + " ";
},"60":function(container,depth0,helpers,partials,data) {
    return " checked='checked' data-changed='1'\n                        ";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class='form-group'>\n                <div class='graphic checkbox'>\n                  <input id='cascade-permissions' type='checkbox' name='cascadePermissions' "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"cascadePermissions") : stack1),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":285,"column":92},"end":{"line":286,"column":82}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"unless","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":286,"column":83},"end":{"line":287,"column":66}}})) != null ? stack1 : "")
    + ">\n                  <label for='cascade-permissions' class='check-cell'>\n                    "
    + container.escapeExpression(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"cascadePermissions")), depth0))
    + "\n                  </label>\n                  <p class='input-legend'>"
    + ((stack1 = alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"users"))) && lookupProperty(stack1,"cascadePermissionsLegend")), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n              </div>\n";
},"63":function(container,depth0,helpers,partials,data) {
    return "\n                    checked='checked' data-changed='1' ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='users section'>\n  <div class='section-header'>\n    <h1>\n      <a data-action='back'>\n        <i class='icon icon-back'></i>\n      </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "    </h1>\n  </div>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),"storeadmin",{"name":"is","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":2},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNotOrUndefined.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"username") : stack1),"storeadmin",{"name":"isNotOrUndefined","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":2},"end":{"line":301,"column":23}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});