import { ListView } from "@/core/magnet";
import { _, objHas } from "@/helpers/utils";
import app from "@/app";

export default ListView.extend({
  module: "drips",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  query: {
    order: "id desc",
  },
  cache: false,
  limit: 10,
  paginate: true,
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=open-select-flow-modal]": "openSelectFlowModal",
      "click [data-action=select-flow]": "selectFlow",
      "click [data-action=download-replies]": "downloadReplies",
      "click [data-action=open-usage-modal]": "openUsageModal",
      "click [data-action=open-credits-modal]": "openCreditsModal",
      "submit [data-form=assign-credits]": "assignCredits",
    });
  },

  // Runs custom actions after renderization
  afterRenderPage: function () {
    // If the archived filter is present, highlight the selector
    if (objHas(this.query, "filter", "archived")) {
      this.setActiveArchivedFilter();
    }
  },

  // Highlights 'Archived' in the state filter
  setActiveArchivedFilter: function () {
    var toggler = this.$('[data-filter-name="filter.state"]'),
      dropdown = toggler.closest("li");

    // Set the toggler as active
    toggler
      .text(window.lang.campaigns.filters.state.ARCHIVED)
      .closest("a")
      .addClass("active");

    // Set dropdown option as active
    dropdown.find('[data-value="ARCHIVED"]').addClass("active");
  },

  // Hides or show action buttons depending on the number of items checked
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      buttons = toolbar.find(".btn"),
      none = toolbar.find("[data-type=none]"),
      justOne = toolbar.find("[data-type=just-one]"),
      onePlus = toolbar.find("[data-type=one-plus]"),
      checked = this.getChecked();

    // Hide all actions
    buttons.addClass("hide");

    // Hide buttons when no items are checked
    if (checked.length === 0) {
      none.removeClass("hide");
      toolbar.removeClass("edit-mode");
      return this;
    }

    // Show actions when just one item is checked
    if (checked.length === 1) {
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }

    if (
      checked.every(function (item) {
        return item.get("archived");
      })
    ) {
      this.$("[data-method=unarchive]").removeClass("hide");
    }

    // With every sent, show archive button
    if (
      checked.every(function (item) {
        return _.contains(["SENT", "PAUSED"], item.get("state"));
      }) &&
      !objHas(this.filters, "archived")
    ) {
      this.$("[data-method=archive]").removeClass("hide");
    }

    // With every draft, show delete
    if (
      checked.every(function (item) {
        return item.get("state") === "DRAFT";
      })
    ) {
      this.$("[data-method=destroy]").removeClass("hide");
    }
  },

  // Opens a modal to see the usage
  openUsageModal: function () {
    $.get(
      "drips/credits",
      function (response) {
        var data = this.renderData();
        data.usage = _.map(response.data.usage, function (item) {
          item.periodStart = item.periodStart.split("T")[0];
          item.periodEnd = item.periodEnd.split("T")[0];
          return item;
        });
        data.available = response.data.available;
        this.openModal(require("../templates/modals/usage.hbs"), data);
      }.bind(this)
    );
  },

  // Opens a modal to assign credits
  openCreditsModal: function () {
    this.openModal(require("../templates/modals/credits.hbs"));
  },

  // Opens a modal to select the flow
  openSelectFlowModal: function () {
    this.openModal(
      require("../templates/modals/selectFlow.hbs"),
      this.renderData()
    );
  },

  // Creates a drip based on a flow
  selectFlow: function (e) {
    var name = $(e.currentTarget).data("name"),
      flow = _.findWhere(this.additionalsData.flows, { name: name });
    if (!flow) {
      return this.displayMessage(window.lang.drips.flowError, 3000, "error");
    }

    this.collection
      .create({
        flow: name,
        nodes: flow.nodes,
      })
      .done(function (response) {
        app.router.navigateTo(`drips/${response.data.id}`);
      });
  },

  // Download the replies
  downloadReplies: function () {
    var checked = _.pluck(this.getChecked(), "id");
    this.collection.replies({ id: checked }).done(
      function () {
        this.displayMessage(window.lang.drips.feedback.downloadedReplies);
        this.reload();
      }.bind(this)
    );
  },

  // Assigns credits
  assignCredits: function (e) {
    var data = $(e.currentTarget).serializeObject();
    e.preventDefault();
    $.ajax({
      url: "drips/credits/assign",
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
      success: function () {
        this.displayMessage(window.lang.drips.feedback.creditsAssigned);
        this.reload();
      }.bind(this),
    });
  },
});
