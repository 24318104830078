<template>
  <div class="h-full">
    <Menu v-if="!isEmpty(items) && selected" class="focus:outline-none">
      <MenuItems static class="w-full overflow-auto focus:outline-none">
        <MenuItem
          v-for="item in items"
          v-slot="{ active }"
          :key="item.id"
          :class="[selected.id == item.id ? 'border-l-sky-300 border-l-4' : ' padding-l-4']"
          class="group flex items-center cursor-pointer w-full focus:ring-0 focus:outline-none border"
          @click="updateSelected(item.id)"
        >
          <div
            :class="[selected.id == item.id ? 'bg-sky-100' : active ? ' bg-gray-50' : 'bg-white hover:bg-gray-50']"
            class="relative flex items-center w-full"
          >
            <div class="px-6 py-4 flex items-center text-sm font-medium">
              <div
                :class="[selected.id == item.id ? 'border-sky-300' : 'border-gray-300']"
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center rounded-full border-2 self-start"
              >
                <StarIcon
                  v-if="item.type === 'autosave'"
                  :class="[selected.id == item.id ? 'text-sky-500 ' : 'text-gray-500']"
                  class="w-6 h-6"
                  aria-hidden="true"
                />
                <ArchiveIcon
                  v-if="item.type === 'history_manual'"
                  :class="[selected.id == item.id ? 'text-sky-500 ' : 'text-gray-500']"
                  class="w-6 h-6"
                  aria-hidden="true"
                />
                <ClockIcon
                  v-if="item.type === 'history_auto'"
                  :class="[selected.id == item.id ? 'text-sky-500 ' : 'text-gray-500']"
                  class="w-6 h-6"
                  aria-hidden="true"
                />
              </div>
              <div
                :class="[selected.id == item.id ? 'text-sky-500' : 'text-gray-500']"
                class="inline-grid ml-4 text-sm font-mediums"
              >
                <div>
                  <span class="uppercase mr-2">{{ d(item.date, "shortWithTime") }}</span>
                  <SimpleBadge v-if="selected.id == item.id && item.type === 'autosave'" theme="blue" size="small">
                    {{ t("versionType.autosave") }}
                  </SimpleBadge>
                  <SimpleBadge v-if="selected.id == item.id && item.type === 'history_auto'" theme="gray" size="small">
                    {{ t("versionType.history_auto") }}
                  </SimpleBadge>
                  <SimpleBadge
                    v-if="selected.id == item.id && item.type === 'history_manual'"
                    theme="gray"
                    size="small"
                  >
                    {{ t("versionType.history_manual") }}
                  </SimpleBadge>
                </div>
                <div>
                  <p :class="[selected.id == item.id && 'mb-2']" class="font-medium text-sm">{{ item.user }}</p>
                  <transition
                    enter-active-class="transition-all transform overflow-hidden duration-200"
                    enter-from-class="max-h-0"
                    enter-to-class="max-h-10"
                    leave-active-class="transition-all transform overflow-hidden duration-200"
                    leave-from-class="max-h-10"
                    leave-to-class="max-h-0"
                  >
                    <div v-if="selected.id == item.id">
                      <SimpleButton @click="restore($event, item.id)">
                        <template #leading><CheckIcon /></template>
                        {{ t("button.restoreVersion") }}</SimpleButton
                      >
                    </div>
                  </transition>
                </div>
              </div>
            </div>
            <div class="absolute right-0 mr-8">
              <LoadingSpinner v-if="item?.loading" class="h-6 w-6 text-sky-400" />
              <ChevronRightIcon v-else-if="selected.id != item.id" class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </div>
          </div>
        </MenuItem>
      </MenuItems>
    </Menu>
    <div v-else class="h-full">
      <div class="flex flex-col justify-center text-center mx-12 h-full text-sm">
        <ClockIcon class="w-7 h-7 mx-auto text-gray-500" aria-hidden="true" />
        <h1 class="font-medium text-sm mt-2 mb-3 text-gray-500">{{ t("emptyState.title") }}</h1>
        <p class="text-gray-400">{{ t("emptyState.description") }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

// Components
import { Menu, MenuItems, MenuItem } from "@headlessui/vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import SimpleButton from "@atoms/SimpleButton.vue";

//Icons
import { StarIcon, ArchiveIcon, ClockIcon, CheckIcon } from "@heroicons/vue/outline";
import { ChevronRightIcon } from "@heroicons/vue/solid";

//Utils
import { isEmpty } from "lodash";

// Types
import { HistoryItems, HistoryItem } from "./VersionHistory.types";

// Props
const props = withDefaults(
  defineProps<{
    items: HistoryItems;
    selected: HistoryItem;
  }>(),
  {
    items: () => [] as HistoryItems,
    selected: () => ({} as HistoryItem),
  }
);

// Lang
const { d, t } = useI18n();

// Emits
const emit = defineEmits<{
  (e: "update:selected", selected: HistoryItem): void;
  (e: "restore", restored: HistoryItem): void;
}>();

const findSelected = (id: string) => {
  return props.items.find((item) => item.id == id);
};

const updateSelected = (id: string) => {
  const selected = findSelected(id);
  if (!selected || selected.id == props.selected.id) return;
  emit("update:selected", selected);
};

const restore = (event: Event, id: string) => {
  event.stopPropagation();
  const selected = findSelected(id);
  if (!selected) return;
  emit("restore", selected);
};
</script>

<i18n lang="json">
{
  "es": {
    "button": {
      "restoreVersion": "Restaurar versión"
    },
    "emptyState": {
      "title": "Sin versiones para mostrar",
      "description": "Actualmente no existen versiones de esta plantilla para el filtro seleccionado. Trabaja desde el editor para que se generen y guarden en este historial."
    },
    "versionType": {
      "autosave": "Versión recuperada",
      "history_auto": "Versión automática",
      "history_manual": "Versión manual"
    }
  },
  "pt": {
    "button": {
      "restoreVersion": "Restaurar esta versão"
    },
    "emptyState": {
      "title": "Sin versiones para mostrar",
      "description": "Actualmente no existen versiones de esta plantilla para el filtro seleccionado. Trabaja desde el editor para que se generen y guarden en este historial."
    },
    "versionType": {
      "autosave": "Versão manual",
      "history_auto": "Versão automática",
      "history_manual": "Versão recuperada"
    }
  }
}
</i18n>
