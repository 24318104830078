<template>
  <div>
    <NotificationsContainer />
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from "vue";

// Components
import NotificationsContainer from "@molecules/NotificationsContainer.vue";

//Store
import { useUserConfigStore } from "@/vue/stores/userConfigStore/userConfigStore";

const userConfigStore = useUserConfigStore();

onMounted(async () => {
  await userConfigStore.getUserConfig();
});
</script>
