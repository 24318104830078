import { EditView } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";
import importerTpl from "../templates/index.hbs";
import importPreviewTpl from "../templates/importPreview.hbs";
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

import { useOnboardingStore } from "@store";

export default EditView.extend({
  module: "contacts",
  templates: {
    initial: importerTpl,
  },
  ignoreColumnFields: false,
  cache: false,
  replacements: [
    [/Á/gi, "A"],
    [/É/gi, "E"],
    [/Í/gi, "I"],
    [/Ó/gi, "O"],
    [/Ú/gi, "U"],
    [/Ñ/gi, "N"],
  ],
  allowedMimeTypes: [
    "application/csv",
    "application/vnd.ms-excel",
    "application/x-csv",
    "text/comma-separated-values",
    "text/csv",
    "text/plain",
    "text/tab-separated-values",
    "text/x-comma-separated-values",
    "text/x-csv",
  ],
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-tab].disabled": "prevent",
      "dragover [data-role=dropzone]": "prevent",
      "dragenter [data-role=dropzone]": "addDropHighlight",
      "dragleave [data-role=dropzone]": "removeDropHighlight",
      "drop [data-role=dropzone]": "getDroppedFiles",
      "click [data-role=uploader]": "openFileSelection",
      "change [data-input-type=upload-file]": "getSelectedFiles",
      "show.bs.tab [data-toggle=tab]": "beforeTabChange",
      "shown.bs.tab [data-toggle=tab]": "changedTab",
      "click [data-target=#preview]": "setSource",
      "click [data-action=set-source]": "setSource",
      "click [data-action=preview]": "preview",
      "submit [data-form=add-field]": "addField",
      "click [data-action=cancel-add-field]": "cancelAddField",
      "keyup [data-role=field-name]": "updateTagName",
      "change [data-role=preview] select": "changeSelect",
      "click [data-action=open-import-options-modal]": "openImportOptionsModal",
      "submit [data-form=import-options]": "updateImportOptions",
      "click [data-action=tab-source]": "tabSource",
      "click [data-action=tab-target]": "tabTarget",
      "click [data-action=tab-preview]": "tabPreview",
      "submit [data-form=importer]": "importer",
      "change [data-action=toggle-contacts-consent]": "toggleContactsConsent",
    });
  },

  beforeRender: function (callback) {
    this.setTemplate(importerTpl);
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this,
      textarea = $("[data-source=raw]").get(0);

    // Save references to common elements
    self.uploader = {
      progressBar: ".upload-progress",
      loading: ".loading",
      container: ".upload-container",
      dropzone: ".dropzone",
    };

    // Set default options
    self.item.set("options", { normalizeCase: true });

    // Start CodeMirror editor
    self.codeMirror = window.CodeMirror.fromTextArea(textarea, {
      lineNumbers: true,
      autofocus: true,
    });
    self.codeMirror.refresh();
    self.codeMirror.on("keyup", function (editor) {
      var content = editor.getValue(),
        isValid = true;

      // Check the content length
      if (content.length > window.config.copyPasteImportLimit) {
        isValid = false;
        self.$(".max-length-error").removeClass("hide");
      } else {
        self.$(".max-length-error").addClass("hide");
        if (!content) {
          isValid = false;
        }
      }

      // Enable next button if the content is valid
      if (isValid) {
        self.$("[data-tab=preview]").removeClass("disabled").find("a").attr("data-type", "raw");
        self.$("[data-type=raw]").removeClass("disabled");
      } else {
        self.$("[data-type=raw]").addClass("disabled");
        self.$("[data-tab=preview]").removeClass("disabled").find("a").removeAttr("data-type", "raw");
      }
    });

    // Update layout
    app.layout.updateLayout();

    // Selects a tab
    if (self.options.tab !== undefined) {
      app.router.updateRoute("#import/list");
      self.$("[data-target=#" + self.options.tab + "]").tab("show");
    }
  },

  // Checker before tab changes
  beforeTabChange: function (e) {
    var self = this,
      button = $(e.currentTarget);

    // If moving to target an email column is not selected, abort
    if (button.data("target") === "#target") {
      if (!self.hasEmailColumn()) {
        self.$("[data-role=email-column-error]").removeClass("hide");
        return false;
      }
    }
  },

  // Determines if the import preview table has an email column selected
  hasEmailColumn: function () {
    var selects = $('select[name="columnFields[]"]'),
      hasEmailColumn = false;
    $.each(selects, function (index, value) {
      if (parseInt($(value).val(), 10) === 3) {
        hasEmailColumn = true;
      }
    });
    return hasEmailColumn;
  },

  // Prevents unwanted events
  prevent: function (e) {
    e.preventDefault();
    e.stopPropagation();
  },

  // Add active class to hovered dropzone
  addDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).addClass("active");
  },

  // Remove active class from hovered dropzone
  removeDropHighlight: function () {
    var self = this;
    $(self.uploader.dropzone).removeClass("active");
  },

  // Manages dropped files
  getDroppedFiles: function (e) {
    var self = this,
      files = e.originalEvent.dataTransfer.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.uploadFile(files[0]);
  },

  // Open the file selector
  openFileSelection: function () {
    $("[data-input-type=upload-file]").trigger("click");
  },

  // Manage selected files
  getSelectedFiles: function (e) {
    var self = this,
      files = e.currentTarget.files;

    // Prevent defaults
    e.preventDefault();
    e.stopPropagation();

    // Upload dropped files
    self.uploadFile(files[0]);
  },

  // Submit file form
  uploadFile: function (file) {
    var self = this,
      formData = new FormData();

    // Remove error messages
    self.$("[data-role=size-error]").addClass("hide");

    // Check file size
    if (!file || !self.checkFileSize(file)) {
      return false;
    }

    // Check file type
    if (!self.checkFileType(file)) {
      alert(window.lang.wrongTypeUpload);
      $(".dropzone.dropper").removeClass("active");
      return false;
    }

    // Send multipart form via AJAX
    formData.append("fileToUpload", file);
    $.ajax({
      type: "POST",
      url: "contacts/import/upload",
      dataType: "json",
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: false,
      beforeSend: function () {
        self.fileSubmitBefore();
      },
      xhr: function () {
        var xhr = $.ajaxSettings.xhr();
        if (xhr.upload) {
          xhr.upload.addEventListener(
            "progress",
            function (e) {
              var percent = 0,
                position = e.loaded || e.position,
                total = e.total;
              if (e.lengthComputable) {
                percent = Math.ceil((position / total) * 100);
              }
              self.fileSubmitProgress(percent);
            },
            false,
          );
        }
        return xhr;
      },
      success: function (response) {
        self.fileSubmitSuccess(response);
      },
    });
  },

  // Check file size
  checkFileSize: function (file) {
    var self = this,
      maxSize = window.config.fileUploadLimit * 1000000;
    if (file !== undefined && file.size !== undefined && file.size > maxSize) {
      self
        .$("[data-role=size-error]")
        .text(`${window.lang.oversizedUpload} (${window.config.fileUploadLimit} MB)`)
        .removeClass("hide");
      return false;
    }
    return true;
  },

  // Check file type
  checkFileType: function () {
    // FIXME: Temporarily skip validation. Some browsers fail validation even with valid files.
    return true;

    // // If the browser does not support file API, skip the validation
    // if (file === undefined || file.type === undefined || file.name === undefined) {
    //     return true;
    // }

    // // Perform validation
    // mimeType = file.type;
    // if ($.inArray(mimeType, self.allowedMimeTypes) > -1) {
    //     return true;
    // }

    // // Otherwise, abort
    // return false;
  },

  // Prepares file submission
  fileSubmitBefore: function () {
    var self = this;

    // Remove dropzone highlight
    self.removeDropHighlight();

    // Hide button and text
    $(self.uploader.container).hide();

    // Show loader
    $(self.uploader.loading).removeClass("off").animate({ opacity: 1 });

    // Show progress
    $(self.uploader.progressBar).closest(".progress").removeClass("hidden");
  },

  // Updates progress bar
  fileSubmitProgress: function (percent) {
    var self = this;
    $(self.uploader.progressBar)
      .css("width", percent + "%")
      .text(percent + "%");
  },

  // Success callback to be executed when a file is submitted
  fileSubmitSuccess: function (response) {
    var self = this,
      file;

    // Show button and text
    $(self.uploader.container).show();

    // Hide loader
    $(self.uploader.loading).animate({ opacity: 0 }, function () {
      $(this).addClass("off");
    });

    // Reset progress bar
    $(self.uploader.progressBar).closest(".progress").addClass("hidden");
    $(self.uploader.progressBar).css("width", 0).text("0%");

    // Execute callback
    if (response.success) {
      // Get file ID
      file = response.data.id;

      // Assign file ID to the input
      self.$("[data-source=file]").val(file);

      // Show filename
      self.$(".filename").text(file.substring(0, file.length - 9));

      // Remove previous preview
      self.$(".table-container").find("table").html("");

      // Set source
      self.item.clear();
      self.item.set("source", "file");

      // Enable next button and tab
      self.$("[data-tab=preview]").removeClass("disabled").find("a").attr("data-type", "file");
      self.$("[data-type=file]").removeClass("disabled");

      // Go to the preview tab
      self.preview();
    }
  },

  // Fires when a tab is focused
  changedTab: function () {
    var self = this;

    // Remove tabs
    self.$("#source-options").remove();

    // Refresh Code Mirror editor
    self.codeMirror.refresh();

    // Scroll top
    self.$(".content").scrollTop(0);
  },

  // Sets a source and goes to the preview tab
  setSource: function (param) {
    var self = this,
      source,
      sourceFile = false;

    // Avoid sending column fields
    self.ignoreColumnFields = true;

    // Check source
    if (typeof param === "string") {
      source = param;
    }
    if (param.currentTarget !== undefined) {
      source = $(param.currentTarget).data("type");
    }

    // Validate source
    switch (source) {
      case "file":
        sourceFile = self.$("[data-source=file]").val();
        if (!sourceFile) {
          return self;
        }
        break;
      case "raw":
        if (!self.codeMirror.getValue()) {
          return self;
        }
        break;
      default:
        return self;
    }

    // Set source
    self.item.clear();
    self.item.set("source", source);

    // Render preview
    self.preview();
  },

  // Renders the preview
  preview: function (e) {
    var self = this,
      data = self.importData(),
      trigger = self.$("[data-action=set-source]:visible");

    // If needed, prevent the default action
    if (e !== undefined && e.preventDefault !== undefined && $.isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    // If the source is raw, set the content via Code Mirror
    if (self.item.get("source") === "raw") {
      data.sourceRaw = self.codeMirror.getValue();
    }

    if (self.ignoreColumnFields) {
      delete data.columnFields;
      self.ignoreColumnFields = false;
    }

    // Disable triggered button
    trigger.addClass("disabled");

    // Perform an AJAX call to obtain the preview array of data
    if (data) {
      // Start loader
      self.loading(true);

      // Post form data to the API preview endpoint
      $.ajax({
        type: "POST",
        url: "contacts/import/preview",
        data: data,
        timeout: false,
        success: function (response) {
          if (response.success && response.data !== undefined) {
            // Stop loader
            self.loading(false);

            // Set data in the view's model
            self.item.set(response.data);

            // Render the preview table
            self.renderTable(self.item);

            // Set selects to its guessed value
            self.updateSelects();

            // Re enable triggered button
            trigger.removeClass("disabled");

            // Enable next step
            self.$("[data-tab=target]").removeClass("disabled");

            // Show preview tab
            self.tabPreview();
          }
        },
        error: function (xhr, textStatus, error) {
          // Re enable triggered button
          trigger.removeClass("disabled");

          // Get JSON error data
          if (textStatus.responseJSON !== undefined) {
            error = textStatus.responseJSON.error;
          }

          // Timeout
          if (textStatus === "timeout") {
            alert(window.lang.requestTimeout);
            return false;
          }

          // Show validation errors
          if (error.validationErrors !== undefined) {
            return self.showValidationErrors(error.validationErrors);
          }

          // Or show error message on server failure
          if (error.status !== undefined && error.status === 500) {
            alert(error.userMessage + " (" + error.incidentCode + ")");
            return false;
          }
        },
      });
    }
  },

  // Renders a preview table showing an extract of contacts
  renderTable: function (data) {
    var self = this,
      html = "",
      renderData = {},
      tableTemplate = importPreviewTpl,
      fields = {},
      fieldsData = [];

    // Set an array of fields to be assignable to each preview column
    fields = _.filter(self.item.additionalsData.fields, function (value) {
      return !value.readOnly;
    });

    // Add extra information to table header
    $.each(data.attributes.options.columnFields, function (index, column) {
      var columnFields = $.extend(true, [], fields),
        header = false,
        isInterest = false,
        interestColumn = data.attributes.options.interestColumn;

      // Check if the column is the interests column
      if (interestColumn !== null && interestColumn > 0 && interestColumn - 1 === index) {
        isInterest = true;
      }

      // Check headers
      if (data.attributes.headers && $.isArray(data.attributes.headers)) {
        header = data.attributes.headers[index];
      }

      // Check againts the array of column fields
      $.each(columnFields, function (key, field) {
        if (field.id === column) {
          field.isSelected = true;
        }
      });
      fieldsData.push({
        id: column,
        columnFields: columnFields,
        header: header,
        isInterest: isInterest,
      });
    });

    // Setup render data
    renderData = $.extend(true, {}, self.renderData(), {
      fieldsData: fieldsData,
      previewData: [],
    });

    // Add extra information to table body
    $.each(data.attributes.preview, function (row, contact) {
      // Define the common row attributes
      renderData.previewData[row] = {
        highlight: "",
        title: "",
        fields: [],
      };

      // If the row has an error, highlight with a class
      if (data.attributes.errors[row]) {
        renderData.previewData[row].highlight = "danger";
        renderData.previewData[row].title = data.attributes.errors[row].replace('"', "");
      }

      // Complete with original or value
      $.each(contact, function (index, value) {
        renderData.previewData[row].fields[index] = !value ? data.attributes.original[row][index] : value;
      });
    });

    // Show table
    html = tableTemplate(renderData);
    self.$("table").html(html);

    // Go to preview tab
    self.$("[data-target=#preview]").tab("show");
  },

  // Update all selectors
  updateSelects: function () {
    var self = this,
      form = self.$("[data-form=importer]"),
      selects = form.find("select");

    // Update each selector
    selects.each(function (index, select) {
      self.updateSelect($(select));
    });
  },

  // Selects an option and disables that option in other selectors
  updateSelect: function (select) {
    var self = this,
      form = self.$("[data-form=importer]"),
      value = select.val(),
      options = select
        .closest("th")
        .siblings()
        .find('option[value="' + value + '"]'),
      previousValue = select.attr("data-selected-value");

    // Mark the previous value as available
    if (previousValue !== undefined) {
      form.find('option[value="' + previousValue + '"]').removeAttr("disabled");
    }

    // If the previous value was interest, resets the interest column value
    if (previousValue === "interest") {
      delete self.item.attributes.options.interestColumn;
    }

    // Disable the right options
    options.each(function (index, option) {
      if ($(option).is(":selected")) {
        $(option).closest("select").val("0");
      }
      if ($(option).val() !== "0") {
        $(option).attr("disabled", true);
      }
    });

    // Set the selected value in the selector
    select.attr("data-selected-value", value);
  },

  // Sets a selector as the interest column
  selectInterestColumn: function (column) {
    var form = $("[data-form=importer]"),
      select = form.find("select").eq(column);

    // If an interest option is found, mark it as selected
    $(select)
      .find("option")
      .filter(function () {
        return $(this).attr("value") === "interest";
      })
      .prop("selected", true);
  },

  // Updates the preview table after a selector change
  changeSelect: function (e) {
    var self = this,
      select = $(e.currentTarget),
      selected = select.find(":selected");

    // Check if the selected option is a new field
    if (selected.data("action") === "open-add-field-modal") {
      return self.openAddFieldModal(select);
    }

    // Check if an email column is selected
    if (self.hasEmailColumn()) {
      self.$("[data-role=email-column-error]").addClass("hide");
    }

    // Update the selector
    self.updateSelect(select);

    // Render the preview table
    self.preview();
  },

  // Opens a modal to create a new field
  openAddFieldModal: function (select) {
    var self = this,
      column = select.data("column"),
      data = self.renderData(),
      headers = self.item.get("headers");

    if (headers !== undefined && $.isArray(headers) && headers[column] !== undefined) {
      data.fieldName = headers[column];
      data.tagName = !headers[column] ? "" : headers[column].replace(" ", "").toUpperCase();
      this.replacements.forEach(
        function (replacement) {
          data.tagName = data.tagName.replace(replacement[0], replacement[1]);
        }.bind(this),
      );
    }
    self.changingColumn = select;
    data.column = column;
    self.openModal(require("../templates/modals/field.hbs"), data);
  },

  // Cancel the field addition
  cancelAddField: function (e) {
    var self = this;
    e.preventDefault();
    if (self.changingColumn === undefined && !self.changingColumn) {
      return false;
    }
    $(self.modal).modal("hide");
    self.changingColumn.val(0);
    delete self.changingColumn;
  },

  // Update tag name
  updateTagName: function (e) {
    var text = $(e.currentTarget).val(),
      regex = new RegExp(" ", "g"),
      tag = text.replace(regex, "").toUpperCase();
    this.replacements.forEach(
      function (replacement) {
        tag = tag.replace(replacement[0], replacement[1]);
      }.bind(this),
    );
    this.$("[name=tag]").val(tag).attr("data-changed", 1);
  },

  // Adds a custom field
  addField: function (e) {
    var self = this,
      form = $(e.currentTarget),
      importer = $("[data-form=importer]"),
      data = form.serializeObject(),
      column = data.column,
      select = importer.find("[data-column=" + column + "]");

    e.preventDefault();
    if (self.changingColumn !== undefined) {
      delete self.changingColumn;
    }

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Create field
    $.ajax({
      type: "POST",
      url: "fields",
      data: data,
      success: function (response) {
        if (response.success) {
          self.item.additionalsData.fields.push(response.data);
          select.append(`<option value="${response.data.id}">${response.data.name}</option>`);
          select.val(response.data.id).trigger("change");
          $(self.modal).modal("hide");
        }
      },
      error: function (xhr) {
        var error = xhr.responseJSON.error;

        // Show remote validation errors
        if (error.validationErrors !== undefined) {
          return self.showValidationErrors(error.validationErrors, form);
        }
      },
    });
  },

  // Opens the options modal
  openImportOptionsModal: function () {
    var self = this;
    self.openModal(require("../templates/modals/options.hbs"), self.renderData());
  },

  // Updates the import options
  updateImportOptions: function (e) {
    var self = this,
      form = $(e.currentTarget),
      modal = $(self.modal),
      options = form.serializeObject();
    e.preventDefault();

    // Validate form
    if (!self.validateFormFields(form)) {
      return false;
    }

    // Set normalizeCase and columnHeaders options
    $.each(["columnHeaders", "normalizeCase"], function () {
      if (options[this] === undefined) {
        options[this] = false;
      }
    });

    // Set the options in the model
    self.item.set("options", options);

    // Render the preview table
    self.preview();

    // Close modal
    modal.modal("hide");
  },

  // Selects and shows the source tab
  tabSource: function () {
    var self = this;
    self.$("[data-target=#source]").tab("show");
  },

  // Selects and shows the target tab
  tabTarget: function () {
    var self = this;
    self.$("[data-target=#target]").tab("show");
  },

  // Selects and shows the preview tab
  tabPreview: function () {
    var self = this;
    self.$("[data-target=#preview]").tab("show");
  },

  // Performs the import call
  importer: function (e) {
    var self = this,
      data = self.importData();
    e.preventDefault();

    // Abort if no list was selected
    if (data.list === 0) {
      self.$(".content").scrollTop(0);
      return self.showValidationErrors({
        list: window.lang.lists.selectRequired,
      });
    }

    // Perform an AJAX call to import the contacts
    if (data) {
      $.post("contacts/import", data, function (res) {
        try {
          const notificationStore = useNotificationStore();
          notificationStore?.fetchTasks();
        } catch {
          // No handler
        } finally {
          const onboardingStore = useOnboardingStore();

          onboardingStore.submitUserEvent({
            action: "list.imported",
            data: {
              id: res.task.parameters.list,
              name: "",
            },
          });

          // Update the tasks indicator
          app.instances.singleton("tasks", "list").render();

          // Remove lists and interests cache
          localStorage.removeItem("lists");
          localStorage.removeItem("interests");

          // Display feedback
          self.displayMessage(window.lang.contacts.feedback.imported);

          // Broadcast event
          app.integrations.amplitude.event("APP_LIST_IMPORTED", true);
          app.integrations.intercom.event("APP_LIST_IMPORTED");

          // Return to the list
          self.back();
        }
      });
    }
  },

  // Collects the parameters for the preview or import call
  importData: function () {
    var self = this,
      form = self.$("[data-form=importer]"),
      source = self.item.get("source"),
      formObject = form.serializeObject(),
      interestSelect = form.find("option[value=interest]:selected").closest("select"),
      data = {},
      params,
      defaults = {
        list: 0,
        type: "CSV",
        mode: "IGNORE",
        normalizeCase: true,
      };

    // Define source
    switch (source) {
      case "file":
        data.sourceFile = self.$("[data-source=file]").val();
        delete formObject.appId;
        break;
      case "raw":
        if (!_.isUndefined(self.item.get("options")) && !_.isUndefined(self.item.get("options").sourceFile)) {
          data.sourceFile = self.item.get("options").sourceFile;
          delete formObject.sourceRaw;
        } else {
          data.sourceRaw = self.$("[data-source=raw]").val();
        }
        delete formObject.appId;
        break;
      default:
        return false;
    }

    // Sets the interest column number
    if (interestSelect.length) {
      data.interestColumn = parseInt(interestSelect.data("column"), 10) + 1;
      formObject.columnFields[interestSelect.data("column")] = 0;
    }

    // Return final parameters
    params = $.extend(true, defaults, self.item.get("options"), formObject, data);
    return params;
  },

  toggleContactsConsent() {
    const checkbox = $("#contacts-consent-checkbox");

    if (checkbox.is(":checked")) {
      $("#select-source").show();
    } else {
      $("#select-source").hide();
    }
  },
});
