<template>
  <div class="mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
        <button type="button" class="font-semibold text-sky-400 hover:text-sky-500" @click="goToLogin">
          {{ t("login") }}
        </button>
      </p>
    </div>

    <div class="mt-5">
      <Transition
        leave-active-class="transition-all duration-300 overflow-hidden ease-in-out"
        leave-from-class="opacity-100 max-h-40"
        leave-to-class="opacity-0 max-h-0"
        enter-active-class="transition-all duration-300 overflow-hidden ease-in-out"
        enter-from-class="opacity-0 max-h-0"
        enter-to-class="opacity-100 max-h-40"
      >
        <div v-if="!tiendanubeStore && noStoreSelected" class="mt-6">
          <div>
            <label class="block text-sm font-medium leading-6 text-gray-900">{{ t("createAccountWith") }}</label>
            <TiendanubeButton />
          </div>
          <div class="relative mt-6">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-200" />
            </div>
            <div class="relative flex justify-center text-sm font-medium leading-6">
              <span class="bg-white px-6 text-gray-500">{{ t("continueWith") }}</span>
            </div>
          </div>
        </div>
      </Transition>
      <div class="mt-5">
        <form @submit.prevent="signup">
          <div class="mt-6">
            <FormInput
              v-model="signUpInfo.website"
              :label="t('websiteLabel')"
              :placeholder="t('websitePlaceholder')"
              :loading="fetchingStore"
              :hint="
                undefined && storeTypeFetched && storeDetected
                  ? t('storeDetected', { store: selectedStore.value })
                  : undefined
              "
              :error="v$.website.$error ? getWebsiteErrorMessage(v$.website.$errors) : undefined"
              @input="onWebsiteInput"
              @blur="getEcomerce"
              @keydown.enter.prevent="getEcomerce"
            />
          </div>

          <Transition
            leave-active-class="transition-all duration-100 overflow-hidden ease-in-out"
            leave-from-class="opacity-100 max-h-16"
            leave-to-class="opacity-0 max-h-0"
            enter-active-class="transition-all duration-100 overflow-hidden ease-in-out"
            enter-from-class="opacity-0 max-h-0"
            enter-to-class="opacity-100 max-h-16"
          >
            <div
              v-if="!websiteForceInvalid && storeTypeFetched && !storeDetected && signUpInfo.website !== ''"
              class="mt-6 flex space-x-4"
            >
              <div :class="[customStore ? 'w-[50%]' : 'w-full']">
                <SelectMenuLabel
                  v-model:selected="selectedStore"
                  :label="t('storeLabel')"
                  :data-items="storeDataItems"
                  :rem-height="11"
                  :error="v$.store.$error ? t('fieldRequired') : undefined"
                  @update:selected="clearStoreValidation"
                />
              </div>
              <div v-show="customStore" class="w-[50%]">
                <FormInput
                  v-model="signUpInfo.store"
                  :error="v$.store.$error ? t('fieldRequired') : undefined"
                  :label="t('storeLabelOther')"
                  :placeholder="t('storeLabelOtherPlaceholder')"
                  @input="clearStoreValidation"
                />
              </div>
            </div>
          </Transition>
          <Transition
            leave-active-class="transition-all duration-300 overflow-hidden ease-in-out"
            leave-from-class="opacity-100 max-h-80"
            leave-to-class="opacity-0 max-h-0"
            enter-active-class="transition-all duration-300 overflow-hidden ease-in-out"
            enter-from-class="opacity-0 max-h-0"
            enter-to-class="opacity-100 max-h-80"
          >
            <div v-if="!tiendanubeStore" class="mt-4">
              <div>
                <FormInput
                  v-model="signUpInfo.email"
                  :label="t('emailLabel')"
                  :placeholder="t('emailPlaceholder')"
                  :error="v$.email.$error ? getEmailErrorMessage(v$.email.$errors) : undefined"
                  autocomplete="email"
                  @input="clearEmailValidation"
                />
              </div>

              <div class="relative mt-6">
                <PasswordInput
                  v-model="signUpInfo.password"
                  :label="t('passwordLabel')"
                  :placeholder="t('passwordPlaceholder')"
                  :error="v$.password.$error ? getPasswordErrorMessage(v$.password.$errors) : undefined"
                  autocomplete="new-password"
                  @input="clearPasswordValidation"
                />
              </div>

              <div class="mt-4">
                <SelectMenuLabel
                  v-model:selected="selectedCountry"
                  :label="t('countryLabel')"
                  :data-items="countryDataItems"
                  :rem-height="11"
                  class="mt-6"
                />
                <p v-show="selectedCountryIsBanned" class="mt-2 text-sm text-red-600">
                  <i18n-t keypath="bannedCountryError">
                    <template #link>
                      <a href="https://docs.myperfit.com/articles/17241" target="_blank" @click.stop>{{
                        t("bannedCountryErrorLink")
                      }}</a>
                    </template>
                  </i18n-t>
                </p>
              </div>

              <FormCheckBox
                v-model="signUpInfo.termsAndConditionsAccepted"
                :disabled="false"
                :error="v$.termsAndConditionsAccepted.$error"
                class="mt-6"
                @update:model-value="v$.termsAndConditionsAccepted.$reset()"
              >
                <template #label>
                  <i18n-t keypath="termsAndConditions">
                    <template #link>
                      <a href="https://docs.myperfit.com/articles/17241" target="_blank" @click.stop>{{
                        t("termsAndConditionsLink")
                      }}</a>
                    </template>
                  </i18n-t>
                </template>
              </FormCheckBox>
              <FormCheckBox
                v-model="signUpInfo.noCommercialActivityEurope"
                :disabled="false"
                :error="v$.noCommercialActivityEurope.$error"
                :label="t('noCommercialActivityInEurope')"
                class="mt-2"
                @update:model-value="v$.noCommercialActivityEurope.$reset()"
              />
            </div>
          </Transition>

          <SimpleButton
            v-if="!tiendanubeStore"
            type="submit"
            :loading="submitting"
            :disabled="!allowSubmit || fetchingStore"
            :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
          >
            <template #leading>
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </template>
            {{ t("signup") }}
          </SimpleButton>

          <!-- <TiendanubeButton v-if="tiendanubeStore" class="mt-6" /> -->
          <div v-if="tiendanubeStore" class="mt-6">
            <label class="block text-sm font-medium leading-6 text-gray-900">{{ t("createAccountWith") }}</label>
            <TiendanubeButton />
          </div>

          <div class="mt-5 text-center text-sm leading-6">
            <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
              t("loginProblems")
            }}</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, computed, onMounted } from "vue";

import { getCountryDataItems, getStores, bannedCountryKeys, validateUserFormat } from "../authentication";
import type { CountryDataItem, StoresDataItem } from "../authentication.types";

// components
import TiendanubeButton from "./TiendanubeButton.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import PasswordInput from "@organisms/PasswordInput";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import type { ErrorObject } from "@vuelidate/core";
import { analyzePasswordStrength } from "@helpers/validators";
import {
  getUrlOrigin,
  formatUrl,
  isValidURL,
  getIdentifierFromSocialNetworkUrl,
  getDomainFromUrl,
} from "@helpers/formatters";

// Application
import { useAuthenticationApp, useGeoLocationApplication, useRouterApp } from "@application";

// Services
import { useEcommerceService } from "@services";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import { isSocialNetworkUrl } from "@domain/socialNetwork";

const authApp = useAuthenticationApp();
const ecommerceRadarService = useEcommerceService();
const geoLocationApp = useGeoLocationApplication();
const routerApp = useRouterApp();

const { t } = useI18n();

const emit = defineEmits<{
  goToLogin: [void];
  goToWaitlist: [void];
  helpArticle: [void];
}>();

const openHelpArticle = () => {
  emit("helpArticle");
};

const goToLogin = () => {
  emit("goToLogin");
};

const goToWaitlist = () => {
  emit("goToWaitlist");
};

// Data
const countryDataItems = getCountryDataItems();

const storeDataItems = getStores();

const signUpInfo = reactive<{
  website: string;
  store: string;
  email: string;
  password: string;
  country: string;
  businessName: string;
  termsAndConditionsAccepted: boolean;
  noCommercialActivityEurope: boolean;
}>({
  website: "",
  store: "",
  email: "",
  password: "",
  country: "",
  businessName: "",
  termsAndConditionsAccepted: false,
  noCommercialActivityEurope: false,
});

const selectedCountry = ref<CountryDataItem>({ key: "ar", data: { flag: "🇦🇷" }, value: "Argentina" });
const selectedStore = ref<StoresDataItem>({ key: "empty", value: t("storeOptions.empty") });
const customStore = computed<boolean>(() => {
  return selectedStore.value?.key === "other";
});
const tiendanubeStore = computed<boolean>(() => {
  return selectedStore.value?.key === "tiendanube";
});
const noStoreSelected = computed<boolean>(() => {
  return selectedStore.value?.key === "empty";
});

const fetchingStore = ref(false);
const storeTypeFetched = ref(false);
const storeDetected = ref(false);

const getEcommerceFromUrl = async (url) => {
  fetchingStore.value = true;

  const res = await ecommerceRadarService.detect({ url });

  fetchingStore.value = false;

  if (res.isErr()) {
    signUpInfo.businessName = res.error.title ?? "";

    selectedStore.value = { key: "empty", value: t("storeOptions.empty") };
    storeDetected.value = false;
    return;
  }

  signUpInfo.businessName = res.value.title;

  const item = storeDataItems.find((s) => s.key === res.value.ecommerce);

  if (!item) {
    selectedStore.value = { key: "other", value: t("storeOptions.other") };
    storeDetected.value = false;
    return;
  }

  selectedStore.value = item;
  storeDetected.value = true;
};

const getEcomerce = async () => {
  if (storeTypeFetched.value) return;

  if (signUpInfo.website === "") {
    selectedStore.value = { key: "empty", value: t("storeOptions.empty") };
    return;
  }

  const formattedUrl = formatUrl(signUpInfo.website);
  const urlValidation = isValidURL(formattedUrl);

  if (!urlValidation) {
    setWebsiteInvalid();
    return;
  }
  const urlOrigin = getUrlOrigin(formattedUrl);

  const isSocialNetwork = isSocialNetworkUrl(urlOrigin);

  if (urlOrigin.includes("testrel")) {
    signUpInfo.businessName = getDomainFromUrl(formattedUrl) ?? urlOrigin;
    signUpInfo.website = urlOrigin;
  } else if (!isSocialNetwork) {
    signUpInfo.website = urlOrigin;
    await getEcommerceFromUrl(urlOrigin);
  } else if (isSocialNetwork) {
    signUpInfo.businessName = getIdentifierFromSocialNetworkUrl(formattedUrl);
  }

  storeTypeFetched.value = true;
};

const onWebsiteInput = () => {
  clearWebsiteValidation();

  storeTypeFetched.value = false;
};

// Validation
const getEmailErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidEmail");
};

const getPasswordErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "format") return t("invalidPassword");
  return t("fieldRequired");
};

const emailForceInvalid = ref(false);
const customEmailValidation = (value: string) => {
  const valid = validateUserFormat(value);

  return !emailForceInvalid.value && valid;
};

const websiteForceInvalid = ref(false);
const setWebsiteInvalid = () => {
  websiteForceInvalid.value = true;
  selectedStore.value = { key: "empty", value: t("storeOptions.empty") };
  v$.value.website.$touch();
};

const customWebsiteValidation = () => {
  return !websiteForceInvalid.value;
};

const getWebsiteErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "required") return t("fieldRequired");
  return t("invalidUrl");
};

const clearEmailValidation = () => {
  emailForceInvalid.value = false;
  v$.value.email.$reset();
};
const clearPasswordValidation = () => {
  v$.value.password.$reset();
};
const setEmailInvalid = () => {
  emailForceInvalid.value = true;
  v$.value.email.$touch();
};

const clearWebsiteValidation = () => {
  v$.value.website.$reset();
  websiteForceInvalid.value = false;
  signUpInfo.businessName = "";
};

const clearStoreValidation = () => {
  v$.value.store.$reset();
};

const selectedCountryIsBanned = computed<boolean>(() => {
  if (!selectedCountry.value?.key) return false;
  return bannedCountryKeys.includes(selectedCountry.value.key);
});

const customCountryValidation = () => {
  return !selectedCountryIsBanned.value;
};

const validationRules = {
  website: { required, customWebsiteValidation },
  store: { custom: (value: string) => (value === "" ? selectedStore.value.key === "noStore" : true) },
  email: { required, customEmailValidation },
  password: { required, format: (value: string) => analyzePasswordStrength(value).score >= 3 },
  country: { required, customCountryValidation },
  termsAndConditionsAccepted: { checked: (value: boolean) => value },
  noCommercialActivityEurope: { checked: (value: boolean) => value },
};

const v$ = useVuelidate(validationRules, signUpInfo);

watch([selectedCountry], () => {
  signUpInfo.country = selectedCountry.value?.key ?? "";
  v$.value.country.$validate();
});

watch([selectedStore], () => {
  const selectedStoreKey = selectedStore.value.key;
  signUpInfo.store = ["other", "noStore", "empty"].includes(selectedStoreKey) ? "" : selectedStoreKey;
  if (selectedStoreKey !== "empty") clearStoreValidation();
});

// Signup
const submitting = ref(false);
const allowSubmit = computed<boolean>(() => {
  return !selectedCountryIsBanned.value;
});

const signup = async () => {
  try {
    // Fields validation
    const validation = await v$.value.$validate();

    if (!validation) return;

    submitting.value = true;

    const waitlist = window.config.waitlist;
    if (
      waitlist === "all" ||
      (waitlist === "not_tn" && signUpInfo.store !== "tiendanube") ||
      (waitlist === "not_ecommerce" && selectedStore.value.key === "noStore")
    ) {
      // Save signup info endpoint
      goToWaitlist();
    } else {
      const businessCode =
        getDomainFromUrl(signUpInfo.website) || signUpInfo.businessName.replace(/\W/g, " ") || signUpInfo.website;

      const res = await authApp.signup({
        country: signUpInfo.country,
        email: signUpInfo.email,
        store: signUpInfo.store,
        website: signUpInfo.website,
        password: signUpInfo.password,
        code: businessCode,
        businessName: signUpInfo.businessName || businessCode,
      });

      // Logged in
      if (res.isOk() && !res.value) {
        routerApp.navigate({
          path: "home",
          trigger: false,
        });
        location.reload();
        return;
      }

      // Logged in
      if (res.isErr() && res.error.type === "VALIDATION_ERROR" && res.error.invalidFields?.email) {
        setEmailInvalid();
      }
    }
  } catch (e) {
    // No handler
  } finally {
    submitting.value = false;
  }
};

const detectCountry = async () => {
  const res = await geoLocationApp.detectCountry();

  if (res.isErr()) return;
  const countryItem = countryDataItems.find((c) => c.key === res.value);

  if (!countryItem) return;

  selectedCountry.value = countryItem;
};

onMounted(async () => {
  await detectCountry();
});
</script>

<i18n lang="json">
{
  "es": {
    "title": "Crear una cuenta",
    "subtitle": "¿Ya tienes una cuenta?",
    "login": "Iniciar sesión",
    "signup": "Crear cuenta",
    "continueWith": "O continúa con",
    "createAccountWith": "Crear cuenta con",
    "fieldRequired": "Este campo es requerido",
    "invalidEmail": "El email ingresado es inválido.",
    "websiteLabel": "Sitio web",
    "websitePlaceholder": "Ingresa la url de tu sitio",
    "storeLabel": "Tipo de tienda",
    "storeLabelOther": "Otra",
    "storeLabelOtherPlaceholder": "¿Cuál plataforma?",
    "emailLabel": "Email",
    "emailPlaceholder": "Ingresa tu email",
    "passwordLabel": "Contraseña",
    "passwordPlaceholder": "Define tu contraseña",
    "countryLabel": "País",
    "noCommercialActivityInEurope": "No realizo actividades en la Unión Europea.",
    "termsAndConditions": "He leído y acepto {link} de uso.",
    "termsAndConditionsLink": "términos y condiciones",
    "loginProblems": "¿Problemas para ingresar?",
    "bannedCountryError": "El país que seleccionaste no es admitido. {link}",
    "bannedCountryErrorLink": "Saber más",
    "invalidPassword": "La contraseña no es lo suficientemente fuerte",
    "invalidUrl": "La url ingresada es inválida",
    "storeDetected": "Tienda detectada: {store}",
    "countries": {
      "ar": "Argentina",
      "bo": "Bolivia",
      "br": "Brasil",
      "cl": "Chile",
      "co": "Colombia",
      "cr": "Costa Rica",
      "ec": "Ecuador",
      "sv": "El Salvador",
      "gt": "Guatemala",
      "hn": "Honduras",
      "mx": "México",
      "ni": "Nicaragua",
      "pa": "Panamá",
      "py": "Paraguay",
      "pe": "Perú",
      "pr": "Puerto Rico",
      "do": "República Dominicana",
      "uy": "Uruguay",
      "ve": "Venezuela",
      "us": "Estados Unidos",
      "es": "España",
      "pt": "Portugal",
      "fr": "Francia",
      "it": "Italia"
    },
    "storeOptions": {
      "tiendanube": "Tiendanube",
      "mshops": "Mercado Shops",
      "vtex": "Vtex",
      "jumpseller": "Jumpseller",
      "woocommerce": "Woocommerce",
      "shopify": "Shopify",
      "tray": "Tray",
      "tiendup": "TiendUp",
      "wbuy": "Wbuy",
      "other": "Utilizo otra plataforma",
      "noStore": "No tengo una tienda online",
      "empty": "Selecciona una opción"
    }
  },
  "pt": {
    "title": "Criar uma conta",
    "subtitle": "Já tem uma conta?",
    "login": "Entrar",
    "signup": "Criar conta",
    "continueWith": "Ou continue com",
    "createAccountWith": "Criar conta com",
    "fieldRequired": "Este campo é obrigatório",
    "invalidEmail": "O e-mail inserido é inválido.",
    "websiteLabel": "Site",
    "websitePlaceholder": "Insira a url do seu site",
    "storeLabel": "Tipo de loja",
    "storeLabelOther": "Outra",
    "storeLabelOtherPlaceholder": "Qual plataforma?",
    "emailLabel": "E-mail",
    "emailPlaceholder": "Insira seu e-mail",
    "passwordLabel": "Senha",
    "passwordPlaceholder": "Defina sua senha",
    "countryLabel": "País",
    "noCommercialActivityInEurope": "Não realizo atividades na União Europeia.",
    "termsAndConditions": "Li e aceito os {link} de uso.",
    "termsAndConditionsLink": "termos e condições",
    "loginProblems": "Problemas ao entrar?",
    "bannedCountryError": "O país que você selecionou não é permitido. {link}",
    "bannedCountryErrorLink": "Saiba mais",
    "invalidPassword": "A senha não é forte o suficiente",
    "invalidUrl": "A URL inserida é inválida",
    "storeDetected": "Loja detectada: {store}",
    "storeOptions": {
      "tiendanube": "Nuvemshop",
      "mshops": "Mercado Shops",
      "vtex": "Vtex",
      "jumpseller": "Jumpseller",
      "woocommerce": "Woocommerce",
      "shopify": "Shopify",
      "tray": "Tray",
      "tiendup": "TiendUp",
      "wbuy": "Wbuy",
      "other": "Uso outra plataforma",
      "noStore": "Não tenho uma loja online",
      "empty": "Selecione uma opção"
    },
    "countries": {
      "ar": "Argentina",
      "bo": "Bolívia",
      "br": "Brasil",
      "cl": "Chile",
      "co": "Colômbia",
      "cr": "Costa Rica",
      "ec": "Equador",
      "sv": "El Salvador",
      "gt": "Guatemala",
      "hn": "Honduras",
      "mx": "México",
      "ni": "Nicarágua",
      "pa": "Panamá",
      "py": "Paraguai",
      "pe": "Peru",
      "pr": "Porto Rico",
      "do": "República Dominicana",
      "uy": "Uruguai",
      "ve": "Venezuela",
      "us": "Estados Unidos",
      "es": "Espanha",
      "pt": "Portugal",
      "fr": "França",
      "it": "Itália"
    }
  }
}
</i18n>
