<template>
  <div>
    <InputLabel :for="id">{{ label }}</InputLabel>
    <SimpleInput
      :id="id"
      ref="inputRef"
      v-bind="$attrs"
      :class="[loading && 'pr-9', 'mt-1']"
      :model-value="modelValue"
      :placeholder="placeholder"
      :has-error="!!error"
      :loading="loading"
      :disabled="disabled"
      :aria-describedby="error ? `${id}-error` : `${id}-hint`"
      @update:model-value="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
    />
    <InputHint v-if="!error" :id="`${id}-hint`">{{ hint }}</InputHint>
    <InputError v-if="error" :id="`${id}-error`">{{ error }}</InputError>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { v4 as uuid } from "uuid";

import InputLabel from "@atoms/InputLabel.vue";
import InputHint from "@atoms/InputHint.vue";
import InputError from "@atoms/InputError.vue";
import SimpleInput from "@atoms/SimpleInput.vue";

export default defineComponent({
  name: "FormInput",
  components: { InputLabel, InputHint, InputError, SimpleInput },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: () => uuid(),
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "blur"],

  setup() {
    const inputRef = ref<InstanceType<typeof SimpleInput>>();

    const focus = () => {
      inputRef.value?.focus();
    };

    return { inputRef, focus };
  },
});
</script>
