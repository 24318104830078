<script setup lang="ts">
import { onMounted, PropType, provide, computed } from "vue";
import { useI18n } from "vue-i18n";
import { DashboardData, BatchType } from "@domain/dashboard/dashboard";
import PageHeader from "@templates/PageHeader.vue";
import DashboardEcommerce from "@organisms/Dashboard/DashboardEcommerce.vue";
import DashboardContacts from "@organisms/Dashboard/DashboardContacts.vue";
import DashboardEmails from "@organisms/Dashboard/DashboardEmails.vue";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const props = defineProps({
  showRoi: {
    type: Boolean,
    required: false,
    default: false,
  },
  periodFrom: {
    type: Date,
    required: true,
    default: null,
  },
  periodTo: {
    type: Date,
    required: true,
    default: null,
  },
  batchType: {
    type: String as PropType<BatchType>,
    required: true,
    default: "all",
  },
  dashboardData: {
    type: Object as PropType<DashboardData>,
    required: true,
    default: undefined,
  },
  showSessions: {
    type: Boolean,
    required: true,
    default: false,
  },
  hasActiveIntegrations: {
    type: Boolean,
    required: true,
    default: false,
  },
  hadIntegration: {
    type: Boolean,
    required: true,
    default: false,
  },
  hasAdminRights: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const emit = defineEmits(["pdf-downloaded"]);

provide("pdfMode", true);

const { d } = useI18n();

//State
const windowConfig = computed(() => window.config);

//PDF
const PDFelementHeaderID = "pdf-element-header";

const elementsIdsToPrintPDF = ["hidden-dashboard-pdf-second-page", "hidden-dashboard-pdf-third-page"];

const exportDashBoardToPDF = async () => {
  let pdf = new jsPDF("p", "pt", "a4");

  const headerCanvas = await html2canvas(document.getElementById(PDFelementHeaderID)!, {
    scale: 3,
    windowWidth: 1920,
  });

  const imgHeaderData = headerCanvas.toDataURL("image/jpeg");

  const promises = elementsIdsToPrintPDF.map((id) =>
    html2canvas(document.getElementById(id)!, {
      scale: 2,
      windowWidth: 1920,
    })
  );

  const canvases = await Promise.all(promises);

  canvases.forEach((canvas, index, array) => {
    const imgData = canvas.toDataURL("image/jpeg");

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    let imgWidth = pdfWidth;
    let imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
    let yPosition = 0;
    let xPosition = 0;

    pdf.addImage({
      imageData: imgHeaderData,
      format: "JPEG",
      width: imgWidth,
      height: 40,
      x: xPosition,
      y: yPosition,
    });

    yPosition += 45;
    imgWidth -= 20;
    xPosition = 10;

    pdf.addImage({
      imageData: imgData,
      format: "JPEG",
      width: imgWidth,
      height: imgHeight,
      x: xPosition,
      y: yPosition,
    });

    if (index !== array.length - 1) {
      pdf.addPage();
    }
  });

  pdf.save(
    `${session.value?.account.code || "DashBoard"}_${d(props.periodFrom, "short")}-${d(props.periodTo, "short")}.pdf`
  );
  emit("pdf-downloaded");
};

onMounted(async () => {
  if (props.hasAdminRights && (props.hasActiveIntegrations || props.hadIntegration)) {
    elementsIdsToPrintPDF.unshift("hidden-dashboard-pdf-first-page");
  }

  exportDashBoardToPDF();
});
</script>

<template>
  <div>
    <PageHeader id="pdf-element-header">
      <template #title>
        <div class="mb-3">
          <h1 class="m-0 text-2xl font-semibold leading-8 text-white">
            {{ session?.account?.name || "Dashboard" }}
          </h1>
          <span class="text-white"> {{ d(props.periodFrom, "long") }} - {{ d(props.periodTo, "long") }} </span>
        </div>
      </template>
      <template v-if="!windowConfig.whiteLabel" #right>
        <img src="/img/logo-white.png" class="h-10" />
      </template>
    </PageHeader>
    <DashboardEcommerce
      id="hidden-dashboard-pdf-first-page"
      :data="dashboardData"
      :show-sessions="props.showSessions"
      :show-roi="showRoi"
      class="mx-6"
    />
    <DashboardEmails id="hidden-dashboard-pdf-second-page" :data="dashboardData" :batch-type="batchType" class="mx-6" />
    <DashboardContacts id="hidden-dashboard-pdf-third-page" :data="dashboardData" class="mx-6" />
  </div>
</template>
