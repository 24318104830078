<template>
  <div class="mb-6 border border-gray-200 bg-white sm:rounded-lg">
    <div class="px-4 py-4 sm:p-6">
      <h3 class="m-0 text-base font-medium leading-6 text-gray-900">{{ t("paymentMethod") }}</h3>
      <div class="mt-5">
        <CreditCardEditor :gateway="gateway" :paymethod="paymethod" :disallow-delete="disallowDelete" mode="update" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { useI18n } from "vue-i18n";
import CreditCardEditor from "@organisms/CreditCards/CreditCardEditor.vue";

defineProps({
  gateway: {
    type: String as PropType<"mp" | "pagouno" | "tnpagouno" | "stripeus" | "stripebr" | "stripemx">,
    required: false,
    default: "tnpagouno",
    validator: (value: string) => ["mp", "pagouno", "tnpagouno", "stripeus", "stripebr", "stripemx"].includes(value),
  },
  paymethod: {
    type: String,
    required: false,
    default: undefined,
  },
  disallowDelete: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useI18n();
</script>

<i18n lang="json">
{
  "es": {
    "paymentMethod": "Método de pago"
  },
  "pt": {
    "paymentMethod": "Forma de pagamento"
  }
}
</i18n>
