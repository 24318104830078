import { useI18n } from "vue-i18n";

import { email } from "@vuelidate/validators";

import type { CountryDataItems, StoresDataItems } from "./authentication.types";

export const getCountryDataItems = (): CountryDataItems => {
  const { t } = useI18n();

  return [
    { key: "ar", data: { flag: "🇦🇷" }, value: t("countries.ar") },
    { key: "bo", data: { flag: "🇦🇷" }, value: t("countries.bo") },
    { key: "br", data: { flag: "🇦🇷" }, value: t("countries.br") },
    { key: "cl", data: { flag: "🇦🇷" }, value: t("countries.cl") },
    { key: "co", data: { flag: "🇦🇷" }, value: t("countries.co") },
    { key: "cr", data: { flag: "🇦🇷" }, value: t("countries.cr") },
    { key: "ec", data: { flag: "🇦🇷" }, value: t("countries.ec") },
    { key: "sv", data: { flag: "🇦🇷" }, value: t("countries.sv") },
    { key: "gt", data: { flag: "🇦🇷" }, value: t("countries.gt") },
    { key: "hn", data: { flag: "🇦🇷" }, value: t("countries.hn") },
    { key: "mx", data: { flag: "🇦🇷" }, value: t("countries.mx") },
    { key: "ni", data: { flag: "🇦🇷" }, value: t("countries.ni") },
    { key: "pa", data: { flag: "🇦🇷" }, value: t("countries.pa") },
    { key: "py", data: { flag: "🇦🇷" }, value: t("countries.py") },
    { key: "pe", data: { flag: "🇦🇷" }, value: t("countries.pe") },
    { key: "pr", data: { flag: "🇦🇷" }, value: t("countries.pr") },
    { key: "do", data: { flag: "🇦🇷" }, value: t("countries.do") },
    { key: "uy", data: { flag: "🇦🇷" }, value: t("countries.uy") },
    { key: "ve", data: { flag: "🇦🇷" }, value: t("countries.ve") },
    { key: "us", data: { flag: "🇦🇷" }, value: t("countries.us") },
    { key: "es", data: { flag: "🇦🇷" }, value: t("countries.es") },
    { key: "pt", data: { flag: "🇦🇷" }, value: t("countries.pt") },
    { key: "fr", data: { flag: "🇦🇷" }, value: t("countries.fr") },
    { key: "it", data: { flag: "🇦🇷" }, value: t("countries.it") },
  ];
};

export const bannedCountryKeys = ["it", "fr", "pt", "es"];

export const getStores = (): StoresDataItems => {
  const { t } = useI18n();

  return [
    { key: "noStore", value: t("storeOptions.noStore") },
    { key: "other", value: t("storeOptions.other") },
    { key: "tiendanube", value: t("storeOptions.tiendanube") },
    { key: "mshops", value: t("storeOptions.mshops") },
    { key: "shopify", value: t("storeOptions.shopify") },
    { key: "jumpseller", value: t("storeOptions.jumpseller") },
    { key: "vtex", value: t("storeOptions.vtex") },
    { key: "woocommerce", value: t("storeOptions.woocommerce") },
    { key: "tray", value: t("storeOptions.tray") },
    { key: "tiendup", value: t("storeOptions.tiendup") },
    { key: "wbuy", value: t("storeOptions.wbuy") },
  ];
};

const isAlphanumeric = (input: string): boolean => {
  return /^[a-zA-Z0-9]+$/.test(input);
};

export const validateUserFormat = (value: string): boolean => {
  if (value.length === 0) return false;

  const splitAccount = value.split("@");
  const splitMasterUser = value.split("!");

  // Master users
  if (splitMasterUser[1] !== undefined) {
    const masterUserFormat =
      Boolean(email.$validator(splitMasterUser[0], null, null)) && splitMasterUser[1].length === 6;

    return masterUserFormat;
  }

  // Email
  const emailValidation = Boolean(email.$validator(value, null, null));

  ///
  const userAccountValidation =
    splitAccount.length === 2 &&
    splitAccount[1].length > 0 &&
    isAlphanumeric(splitAccount[0]) &&
    isAlphanumeric(splitAccount[1]);

  return emailValidation || userAccountValidation;
};
