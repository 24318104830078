<template>
  <label
    class="block text-sm font-medium overflow-hidden text-ellipsis whitespace-nowrap"
    :class="[disabled ? 'text-gray-300' : 'text-gray-700']"
  >
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "InputLabel",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
