<template>
  <div v-if="!!scheduledCancelPlanAction" class="mb-6 border border-red-200 bg-red-50 sm:rounded-lg">
    <div class="px-4 py-4 sm:p-6">
      <h3 class="m-0 text-base font-medium leading-6 text-red-600">{{ t("title") }}</h3>
      <div class="mt-2 flex items-center justify-between">
        <TextParagraph class="!my-auto">
          <i18n-t keypath="planExpirationDate">
            <span class="font-semibold text-gray-700">
              {{ formatDate(new Date(scheduledCancelPlanAction.date_execution), "PPP") }}
            </span>
          </i18n-t>
        </TextParagraph>
        <div class="flex justify-end space-x-4 pl-4">
          <SimpleButton theme="white" class="w-max" @click="openAlertCancelPlanCancellation">{{
            t("buttonCancelPlanCancellation")
          }}</SimpleButton>
          <SimpleButton v-if="isMasterUser" theme="danger" class="w-max" @click="openAlertCancelPlanImmediately">{{
            t("buttonCancelPlanImmediately")
          }}</SimpleButton>
        </div>
        <ConfirmationModal
          id="alertCancelPlanCancellation"
          v-bind="alertCancelPlanCancellation"
          @cancel="closeAlertCancelPlanCancellation"
          @accept="cancelPlanCancellation"
        >
          <template #acceptButtonLeading>
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </template>
        </ConfirmationModal>
        <ConfirmationModal
          id="alertCancelPlanImmediately"
          v-bind="alertCancelPlanImmediately"
          @cancel="closeAlertCancelPlanImmediately"
          @accept="immediateCancelPlan"
        >
          <template #acceptButtonLeading>
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </template>
        </ConfirmationModal>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, ref } from "vue";

// Components
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import TextParagraph from "@atoms/TextParagraph.vue";

// Icons
import { CheckIcon } from "@heroicons/vue/outline";

// Utils
import { formatDate } from "@helpers/formatters";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Services
import { usePlan } from "@/vue/api/modules/plan/plan";

// I18n
import { useI18n } from "vue-i18n";

// Domain
import type { ScheduledCancelPlanAction } from "@domain/plan";

const emit = defineEmits<{
  (e: "cancelationScheduled"): void;
  (e: "abortCancelation"): void;
}>();

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value?.isMasterUser;

const { t } = useI18n();

const planAPI = usePlan();

const scheduledCancelPlanAction = ref<ScheduledCancelPlanAction>();
const cleanScheduledCancelPlanAction = () => {
  scheduledCancelPlanAction.value = undefined;
};

const alertCancelPlanCancellation = reactive<ConfirmationModalType>({
  open: false,
  title: t("alertCancelPlanCancellation.confirmationTitle"),
  message: t("alertCancelPlanCancellation.confirmationMessage"),
  acceptText: t("alertCancelPlanCancellation.acceptButton"),
  acceptLoading: false,
  cancelText: t("alertCancelPlanCancellation.cancelButton"),
  dismissValue: false,
  severity: "info",
});

const openAlertCancelPlanCancellation = () => (alertCancelPlanCancellation.open = true);
const closeAlertCancelPlanCancellation = () => (alertCancelPlanCancellation.open = false);

const cancelPlanCancellation = async () => {
  if (!scheduledCancelPlanAction.value?.id) return;

  alertCancelPlanCancellation.acceptLoading = true;
  await planAPI.cancelScheduleCancelPlan({
    id: scheduledCancelPlanAction.value?.id,
  });
  alertCancelPlanCancellation.acceptLoading = false;
  closeAlertCancelPlanCancellation();
  cleanScheduledCancelPlanAction();
  emit("abortCancelation");
};

const alertCancelPlanImmediately = reactive<ConfirmationModalType>({
  open: false,
  title: t("alertCancelPlanImmediately.confirmationTitle"),
  message: t("alertCancelPlanImmediately.confirmationMessage"),
  acceptText: t("alertCancelPlanImmediately.acceptButton"),
  acceptLoading: false,
  cancelText: t("alertCancelPlanImmediately.cancelButton"),
  dismissValue: false,
  severity: "warning",
});

const openAlertCancelPlanImmediately = () => (alertCancelPlanImmediately.open = true);
const closeAlertCancelPlanImmediately = () => (alertCancelPlanImmediately.open = false);

const immediateCancelPlan = async () => {
  if (!isMasterUser || !scheduledCancelPlanAction.value?.id) return;
  alertCancelPlanImmediately.acceptLoading = true;

  await planAPI.immediateCancelPlan({
    reason: scheduledCancelPlanAction.value.options.reason,
    comments: scheduledCancelPlanAction.value.options.comments,
    id: scheduledCancelPlanAction.value?.id,
  });
  alertCancelPlanImmediately.acceptLoading = false;
  closeAlertCancelPlanImmediately();
  cleanScheduledCancelPlanAction();
};

const getScheduledCancelPlanActions = async () => {
  const scheduledPlanActions = await planAPI.getScheduledCancelPlanActions();
  scheduledCancelPlanAction.value = scheduledPlanActions.find((planAction) => planAction.type === "cancel");

  if (scheduledCancelPlanAction.value) {
    emit("cancelationScheduled");
  }
};

onMounted(async () => {
  await getScheduledCancelPlanActions();
});
</script>

<i18n lang="json">
{
  "es": {
    "title": "Cancelación de suscripción programada",
    "planExpirationDate": "Tu plan está vigente hasta el día {0}. Puedes continuar usando tu cuenta hasta esta fecha.",
    "buttonCancelPlanCancellation": "Anular cancelación",
    "buttonCancelPlanImmediately": "Cancelación inmediata",
    "alertCancelPlanCancellation": {
      "confirmationTitle": "Anular cancelación",
      "confirmationMessage": "¿Cancelar la baja de la suscripción y mantener el plan actual activo?",
      "acceptButton": "Cancelar baja",
      "cancelButton": "Cerrar"
    },
    "alertCancelPlanImmediately": {
      "confirmationTitle": "Cancelar inmediatamente",
      "confirmationMessage": "Esta acción cancelará la suscripción de forma inmediata.",
      "acceptButton": "Cancelar ahora",
      "cancelButton": "Cerrar"
    }
  },
  "pt": {
    "title": "Cancelamento de assinatura agendado",
    "planExpirationDate": "Seu plano está vigente até o dia {0}. Você pode continuar usando sua conta até essa data.",
    "buttonCancelPlanCancellation": "Anular cancelamento",
    "buttonCancelPlanImmediately": "Cancelar imediatamente",
    "alertCancelPlanCancellation": {
      "confirmationTitle": "Anular cancelamento",
      "confirmationMessage": "Anular o cancelamento da assinatura e manter o plano atual ativo?",
      "acceptButton": "Anular cancelamento",
      "cancelButton": "Fechar"
    },
    "alertCancelPlanImmediately": {
      "confirmationTitle": "Cancelar imediatamente",
      "confirmationMessage": "Essa ação cancelará a assinatura imediatamente.",
      "acceptButton": "Cancelar agora",
      "cancelButton": "Fechar"
    }
  }
}
</i18n>
