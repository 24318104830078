<template>
  <!-- <Listbox v-model="selectedSenderId" as="div"> -->
  <Listbox :model-value="modelValue" as="div" @update:model-value="updateModelValue">
    <ListboxLabel class="block text-sm font-medium text-gray-700">
      {{ t("sender") }}
    </ListboxLabel>
    <div class="mt-1 flex items-center">
      <div class="relative w-full flex-grow">
        <ListboxButton
          class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
        >
          <span class="inline-flex w-full truncate">
            <template v-if="selectedSender">
              <span class="truncate">{{ selectedSender.name }}</span>

              <span class="ml-2 truncate text-gray-500">
                {{ selectedSender.email }}
              </span>

              <span
                v-if="
                  selectedSender.email === selectedSender.effective_email &&
                  selectedSender.reply_to &&
                  selectedSender.reply_to !== selectedSender.email
                "
                class="ml-2 truncate text-gray-500"
              >
                - {{ t("replyTo") }} {{ selectedSender.reply_to }}
              </span>
            </template>
            <template v-else>
              <span class="truncate text-gray-500">{{ t("selectSender") }}</span>
            </template>
          </span>
          <div v-if="isLoading" class="pointer-events-none absolute inset-y-0 right-6 flex items-center pr-3">
            <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
          </div>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption v-slot="{ active }" value="new">
              <div
                :class="{ 'bg-green-50 text-green-600': active, 'text-green-600': !active }"
                class="relative cursor-default select-none py-2 pl-3 pr-9 font-medium"
              >
                <div>
                  {{ t("addNewSender") }}
                </div>
                <span
                  :class="[
                    active ? 'text-green-600' : 'text-green-600',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <PlusCircleIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </ListboxOption>
            <ListboxOption
              v-for="[id, sender] in senders"
              v-slot="{ active, selected, disabled }"
              :key="id"
              :value="id"
              :disabled="sender.auth.status === 'UNKNOWN'"
            >
              <div
                class="relative cursor-default select-none py-2 pl-3 pr-9"
                :class="{
                  'bg-sky-200 text-gray-800': active,
                  'text-gray-800': !active && !disabled,
                  'text-gray-300': !active && disabled,
                }"
              >
                <div class="flex">
                  <span class="truncate" :class="{ 'font-semibold': selected, 'font-normal': !selected }">
                    {{ sender.name }}
                  </span>
                  <span
                    class="ml-2 truncate"
                    :class="{
                      'text-gray-500': active || (!active && !disabled),
                      'text-gray-200': !active && disabled,
                    }"
                  >
                    {{ sender.email }}
                    <span
                      v-if="
                        sender.email === sender.effective_email && sender.reply_to && sender.reply_to !== sender.email
                      "
                    >
                      - {{ t("replyTo") }} {{ sender.reply_to }}
                    </span>
                  </span>
                </div>

                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-sky-600">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
      <IconButton class="ml-2 flex-shrink-0" :label="t('manageSenders')" @click="openSendersManager">
        <CogIcon />
      </IconButton>
    </div>
    <InputHint v-if="selectedSender" class="flex items-start">
      <ShieldCheckIcon
        v-if="selectedSender.auth.status === 'OK'"
        class="mr-1 mt-px h-4 w-4 flex-shrink-0 text-green-500"
      />
      <ShieldExclamationIcon
        v-if="selectedSender.auth.status === 'CHANGE_REQUIRED'"
        class="mr-1 mt-px h-4 w-4 flex-shrink-0 text-red-500"
      />
      <ShieldExclamationIcon
        v-if="selectedSender.auth.status === 'CHANGE_SUGGESTED'"
        class="mr-1 mt-px h-4 w-4 flex-shrink-0 text-yellow-500"
      />
      <InformationCircleIcon
        v-if="selectedSender.auth.status === 'PUBLIC_DOMAIN'"
        class="mr-1 mt-px h-4 w-4 flex-shrink-0 text-yellow-500"
      />

      <i18n-t :keypath="`hints.${selectedSender.auth.status}`" tag="span">
        <template #moredetails>
          <a v-if="canManageSenders" class="ml-1" href="#" @click.prevent="openDomainDetails(selectedSender?.domain)">{{
            t("hints.moreDetails")
          }}</a>
        </template>
      </i18n-t>
    </InputHint>
    <InputHint v-else class="flex items-start">
      {{ t("hints.empty") }}
    </InputHint>
  </Listbox>

  <SenderCreatorModal
    :is-open="senderCreatorModalVisible"
    @close="senderCreatorModalVisible = false"
    @sender-created="senderCreated($event, true)"
  />

  <SendersManagerModal
    :is-open="sendersManagerVisible"
    @close="closeSenderManager"
    @sender-created="senderCreated"
    @sender-updated="senderUpdated"
    @sender-deleted="senderDeleted"
  />

  <PermissionDeniedModal
    :message="t('permissionDenied')"
    :open="permissionDeniedVisible"
    @close="permissionDeniedVisible = false"
  ></PermissionDeniedModal>
</template>

<script lang="ts">
import { ref, reactive, onMounted, watch, computed, provide } from "vue";
import { useI18n } from "vue-i18n";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import {
  CheckIcon,
  SelectorIcon,
  PlusCircleIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/solid";
import { CogIcon } from "@heroicons/vue/outline";

import { useSenders } from "@api/modules/senders/senders";
import { Sender } from "@/vue/types/Senders";

import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import IconButton from "@atoms/IconButton.vue";
import InputHint from "@atoms/InputHint.vue";
import PermissionDeniedModal from "@molecules/PermissionDeniedModal.vue";
import SenderCreatorModal from "@organisms/Senders/SenderCreatorModal.vue";
import SendersManagerModal from "@organisms/Senders/SendersManagerModal.vue";

// Store
import { useSessionStore } from "@store";

export default {
  name: "SenderSelectorMenu",
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    InputHint,
    CheckIcon,
    CogIcon,
    PlusCircleIcon,
    InformationCircleIcon,
    ShieldExclamationIcon,
    ShieldCheckIcon,
    IconButton,
    SelectorIcon,
    SenderCreatorModal,
    SendersManagerModal,
    LoadingSpinner,
    PermissionDeniedModal,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    //Sólo para compatibilidad con selector de form optin que no tiene senderId
    selectByEmail: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "senderUpdated", "senderManagerOpen", "senderManagerClose"],
  setup(props, { emit }) {
    const initialDomainAuthDetails = ref("");
    provide("initialDomainAuthDetails", initialDomainAuthDetails);

    const senders = reactive(new Map<string, Sender>());
    // const selectedSenderId = ref<string | null>(props.modelValue);
    // Props.modelValue = selectedSenderId
    const selectedSender = computed(() => {
      if (props.modelValue) {
        return senders.get(props.modelValue);
      }
      return null;
    });

    const sessionStore = useSessionStore();

    const canManageSenders = sessionStore.hasPermission("campaigns:launch");

    const senderCreatorModalVisible = ref(false);
    const sendersManagerVisible = ref(false);
    const setSendersManagerVisible = () => {
      emit("senderManagerOpen");
      sendersManagerVisible.value = true;
    };
    const closeSenderManager = () => {
      emit("senderManagerClose");
      sendersManagerVisible.value = false;
    };
    const permissionDeniedVisible = ref(false);

    const { t } = useI18n();

    const isLoading = ref(false);
    const sendersAPI = useSenders();

    onMounted(async () => {
      senders.clear();
      try {
        isLoading.value = true;
        const sendersList = await sendersAPI.list();
        isLoading.value = false;

        sendersList.forEach((sender) => {
          senders.set(sender.id, sender);
          //Sólo para compatibilidad con selector de form optin que no tiene senderId
          if (props.selectByEmail === sender.email) {
            emit("update:modelValue", sender.id);
            // selectedSenderId.value = sender.id;
          }
        });
      } catch (err) {
        console.error(err);
      }
      if (props.modelValue) {
        emit("senderUpdated", senders.get(props.modelValue));
      }
    });

    watch(
      () => props.modelValue,
      (newValue: string | null) => {
        if (newValue === "new") {
          if (canManageSenders) {
            senderCreatorModalVisible.value = true;
            emit("update:modelValue", null);
            // selectedSenderId.value = null;
          } else {
            permissionDeniedVisible.value = true;
            emit("update:modelValue", null);
            // selectedSenderId.value = null;
          }
        }
        if (props.modelValue) {
          // emit("update:modelValue", selectedSenderId.value);
          emit("senderUpdated", senders.get(props.modelValue));
        }
      },
    );

    const senderCreated = (newSender: Sender, select: boolean) => {
      senders.set(newSender.id, newSender);
      if (select) {
        emit("update:modelValue", newSender.id);
        // selectedSenderId.value = newSender.id;
        if (newSender.auth.status !== "OK" && newSender.auth.status !== "PUBLIC_DOMAIN") {
          setSendersManagerVisible();
        }
      }
    };

    const senderUpdated = (sender: Sender) => {
      if (sender.id == props.modelValue) {
        emit("senderUpdated", sender);
      }
      senders.set(sender.id, sender);
    };

    const senderDeleted = (senderId: string) => {
      if (senderId == props.modelValue) {
        emit("senderUpdated", undefined);
      }
      if (props.modelValue === senderId) emit("update:modelValue", null); // selectedSenderId.value = null;
      senders.delete(senderId);
    };

    const openSendersManager = () => {
      if (canManageSenders) {
        setSendersManagerVisible();
      } else {
        permissionDeniedVisible.value = true;
      }
    };

    const openDomainDetails = (domain) => {
      initialDomainAuthDetails.value = domain;
      setSendersManagerVisible();
    };

    const updateModelValue = (value: string) => emit("update:modelValue", value);

    return {
      t,
      senders,
      selectedSender,
      // selectedSenderId,
      senderCreatorModalVisible,
      openSendersManager,
      closeSenderManager,
      sendersManagerVisible,
      senderCreated,
      senderUpdated,
      senderDeleted,
      updateModelValue,
      isLoading,
      permissionDeniedVisible,
      openDomainDetails,
      canManageSenders,
    };
  },
};
</script>

<i18n lang="json">
{
  "es": {
    "sender": "Remitente",
    "selectSender": "Selecciona un remitente",
    "addNewSender": "Agregar nuevo remitente...",
    "manageSenders": "Gestionar remitentes",
    "permissionDenied": "Sólo los administradores de la cuenta pueden crear y modificar remitentes. Contáctalos para realizar los cambios necesarios.",
    "hints": {
      "OK": "El remitente está autenticado correctamente.",
      "CHANGE_REQUIRED": "Es necesario hacer algunos ajustes para autenticar correctamente el remitente. {moredetails}",
      "CHANGE_SUGGESTED": "Es necesario hacer algunos ajustes para autenticar correctamente el remitente. {moredetails}",
      "PUBLIC_DOMAIN": "Los dominios públicos no pueden ser autenticados correctamente. Configura un domínio propio. {moredetails}",
      "empty": "Selecciona un remitente que tus contactos reconozcan fácilmente.",
      "moreDetails": "Ver más detalles"
    },
    "replyTo": "responder a"
  },
  "pt": {
    "sender": "Remetente",
    "selectSender": "Selecionar um remetente",
    "addNewSender": "Adicionar um remetente...",
    "manageSenders": "Gerenciar remetentes",
    "permissionDenied": "Somente os administradores da conta podem criar e modificar remetentes. Entre em contato com a pessoa responsável para fazer as modificações necessárias",
    "hints": {
      "OK": "O remetente está autenticado corretamente.",
      "CHANGE_REQUIRED": "Você precisa fazer alguns ajustes para autenticar corretamente o seu remetente. {moredetails}",
      "CHANGE_SUGGESTED": "Você precisa fazer alguns ajustes para autenticar corretamente o seu remetente. {moredetails}",
      "PUBLIC_DOMAIN": "Os domínios públicos não podem ser autenticados corretamente. Configurar um domínio próprio. {moredetails}",
      "empty": "Selecione um remetente que seus contatos reconheçam facilmente.",
      "moreDetails": "Ver detalhes"
    }
  },
  "replyTo": "responder a"
}
</i18n>
