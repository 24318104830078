import { ref } from "vue";

// Store
import { defineStore } from "pinia";

// Services
import { useInvoiceService } from "@services";
import { useCreditCards } from "@api/modules/creditcards";

// Domain
import type { CreditCard } from "@/vue/api/models/CreditCard";
import type { Invoice } from "@domain/invoice";

export const useInvoicePaymentsStore = defineStore("invoicePaymentsStore", () => {
  const creditCardsAPI = useCreditCards();
  const invoiceService = useInvoiceService();

  const cardsList = ref<CreditCard[]>([]);

  const getCards = () => {
    return cardsList.value;
  };

  const fetchPaymentCards = async () => {
    cardsList.value = await creditCardsAPI.list();
  };

  const invoices = ref<Invoice[]>([]);
  const fetchInvoices = async () => {
    const res = await invoiceService.getInvoices();

    if (res.isOk()) {
      invoices.value = res.value;
    }
  };

  const unpaidInvoices = ref<Invoice[]>([]);
  const fetchUnpaidInvoices = async () => {
    const res = await invoiceService.getUnpaidInvoices();

    if (res.isOk()) {
      unpaidInvoices.value = res.value;
    }
  };

  return { cardsList, getCards, fetchPaymentCards, unpaidInvoices, fetchUnpaidInvoices, invoices, fetchInvoices };
});
