// API
import { useRequestsAPIV3Pub } from "@api/requests";

// Types
import type { EcommerceRadarService } from "@application/ports";
import type { DetectAPIResponse, DetectAPIParams, DetectErrorAPIResponse } from "./ecommerceRadar.types";

// Utils
import { ok, err } from "neverthrow";
import { getUrlOrigin, formatUrl, isValidURL } from "@helpers/formatters";
import { ensureAxiosError } from "@helpers/error";

export const useEcommerceService = (): EcommerceRadarService => {
  const requestAPIV3Pub = useRequestsAPIV3Pub();

  const ecommerceRadarService: EcommerceRadarService = {
    async detect(params) {
      try {
        const formattedUrl = formatUrl(params.url);
        const urlValidation = isValidURL(formattedUrl);

        if (!urlValidation) {
          return err({
            type: "INVALID_URL",
          });
        }

        const urlOrigin = getUrlOrigin(formattedUrl);

        const res = await requestAPIV3Pub<DetectAPIResponse, undefined, DetectAPIParams>({
          url: `ecommerceradar/detect`,
          method: "get",
          disableNotifyErrors: true,
          axiosRequestConfig: {
            paramsSerializer: (params) => {
              const urlParams = params as Record<string, string>;

              const paramsString = Object.entries(urlParams).reduce((paramsString, [paramKey, paramValue]) => {
                if (!paramValue) return paramsString;

                return `${paramsString}&${paramKey}=${decodeURI(paramValue)}`;
              }, "");

              return paramsString;
            },
          },
          urlParams: {
            url: urlOrigin,
          },
        });

        if (res.data.data.result === "unknown") {
          return err({
            type: "UNKNOWN",
            title: res.data.data.extra.title,
          });
        }

        if (res.data.data.result === "invalid") {
          return err({
            type: "INVALID_URL",
          });
        }

        return ok({
          ecommerce: res.data.data.result,
          title: res.data.data.extra.title,
        });
      } catch (e) {
        const error = ensureAxiosError(e);
        const data = error.response?.data as DetectErrorAPIResponse;

        if (data.error.type === "internal_server_error" && data.error.status === 500) {
          return err({
            type: "INVALID_URL",
          });
        }

        return err({
          type: "UNKNOWN",
        });
      }
    },
  };

  return ecommerceRadarService;
};
