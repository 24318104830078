<template>
  <div class="h-full">
    <Teleport to="#audience-menuOptions">
      <div class="flex space-x-4">
        <PermissionsButton href="/fields/new" :permissions="['fields:create']" theme="green-lime">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createFieldButton") }}
        </PermissionsButton>
      </div>
    </Teleport>
    <Teleport to="#audience-stickyContent">
      <FiltersPanel>
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div>
            <span v-show="!skeletonTable" class="text-sm text-gray-500">{{
              t("fieldCount", { count: n(Number(totalCount) ?? 0, "decimal") }, { plural: totalCount })
            }}</span>
            <span v-show="skeletonTable" class="animate-pulse rounded bg-gray-100 px-12 text-sm" />
          </div>
        </div>
      </FiltersPanel>
    </Teleport>
    <Table
      :columns="columns"
      :rows="rows"
      :selected-rows="selectedRows"
      :skeleton="skeletonTable"
      :skeleton-count="5"
      :selectable="fieldsUpdate"
      class="ml-6 h-full overflow-x-hidden bg-white pb-12"
      @update:selected-rows="updateSelectedRows"
    >
      <template #name="{ row }">
        <NameCell v-if="row" :id="row.id" :link="row.data?.isCustom ?? false">
          {{ row.content?.name }}
        </NameCell>
      </template>
      <template #name-skeleton>
        <div class="my-3 w-40 animate-pulse rounded bg-sky-50 py-2" />
      </template>
      <template #fieldType="{ row }">
        <FieldTypeCell :field-type="row.content?.fieldType ?? ''" />
      </template>
      <template #fieldType-skeleton>
        <div class="my-3 w-10 animate-pulse rounded bg-gray-50 py-2" />
      </template>
      <template #replaceCode="{ row }">
        <span class="flex justify-start text-sm text-gray-500">
          {{ row.content?.replaceCode }}
        </span>
      </template>
      <template #replaceCode-skeleton>
        <div class="my-3 w-10 animate-pulse rounded bg-gray-50 py-2" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="listOptions"
            theme="transparent-white"
            position="bottom-end"
            :auto-placements="['bottom-end', 'left']"
            :class="[selectedRows?.length === 0 && row.data?.isCustom ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="mx-auto my-2 w-4 animate-pulse rounded bg-gray-50 py-2" />
      </template>
    </Table>

    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selected",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(totalCount) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
        </template>
        <IconButton
          v-if="selectedRows?.length < totalCount"
          :label="t('selectAllButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="selectAll"
        >
          <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
      <div class="flex space-x-4">
        <PermissionsIconButton
          :permissions="['fields:delete']"
          :label="t('deleteSelectedButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </PermissionsIconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteField"
      @cancel="closeDeleteListAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteFontAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, computed, watch, toRaw } from "vue";

import { getColumns, fieldsToRows, getListOptions } from "./audienceFields.data";
import { ColumnsIds, FieldRow, FieldRows, ListOptionsKeys } from "./audienceFields.types";

// Components
import NameCell from "./components/NameCell.vue";
import FieldTypeCell from "./components/FieldTypeCell.vue";
import FiltersPanel from "@templates/FiltersPanel";
import Table from "@molecules/Table";
import type { Columns } from "@molecules/Table";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import IconButton from "@atoms/IconButton.vue";
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import SelectionBar from "@atoms/SelectionBar";

// Icon
import { DocumentIcon, DocumentDuplicateIcon, XIcon, TrashIcon } from "@heroicons/vue/outline";
import { PlusIcon, PencilIcon } from "@heroicons/vue/solid";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";

// Utils
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";

// Store
import { useSessionStore, useAlertStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Service
import { useFields } from "@api/modules/fields/fields";

// Application
import { useLocalStorageApp } from "@application";

// Domain
import type { FilterData } from "@domain/filters";
import type { DataItems } from "@domain/data";
import { getPrimaryHighlightList } from "@domain/filters";

const { t, n } = useI18n();
const fieldsAPI = useFields();
const { notify } = useNotifications();
const localStorageApp = useLocalStorageApp();
const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const fieldsUpdate = sessionStore.hasPermission("fields:update");

// Search
const selectedSearch = ref<SelectedValues>([]);
const filterInputIsOpen = ref(false);
const highlightSearches = ref<DataItems>([]);

const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "audience_fields_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "audience_fields_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "fields");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "fields");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const filterData = computed<FilterData>(() => ({
  filters: [{ id: "fields", text: t("filters.fields"), icon: PencilIcon, highlightList: highlightSearches.value }],
  filtersRoles: {
    primary: "fields",
  },
}));

// Table
const skeletonTable = ref(false);
const columns: Columns<ColumnsIds | "options", ColumnsIds> = getColumns();
const rows = ref<FieldRows>([]);
const totalCount = ref<number>(0);
const selectedRows = ref<FieldRows>([]);
const selectedRow = ref<FieldRow>();
const fetchlimit = 10000;

const clearSelected = () => {
  selectedRows.value = [];
};
const selectAll = () => {
  const selectableRows = rows.value.filter((row) => row.options?.selectable);

  selectedRows.value = selectableRows;
};

const updateSelectedRows = (newRows: FieldRows) => {
  selectedRows.value = newRows;
};

const removeRows = (rowsToDelete: FieldRows) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  rows.value = filteredRows;
};

const removeRow = (rowToDelete: FieldRow) => {
  const filteredRows = toRaw(rows.value).filter((row) => {
    return row.id !== rowToDelete.id;
  });
  rows.value = filteredRows;
};

const fetchFields = async () => {
  skeletonTable.value = true;

  const getFieldSearch = selectedSearch.value.find((search) => search.filterId === "fields") as SelectedValueText;
  const fields = await fieldsAPI.getFields({
    search: getFieldSearch ? getFieldSearch.value : undefined,
    limit: fetchlimit,
  });

  rows.value = [...rows.value, ...fieldsToRows(fields.value)];

  totalCount.value = fields.total ?? 0;

  skeletonTable.value = false;
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) => fieldsAPI.delete({ id: selected.id }));
    await Promise.all(deletePromises);
  } finally {
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    totalCount.value = totalCount.value - selectedRows.value.length;
    removeRows(selectedRows.value);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchFields();
    }
    clearSelected();
  }
};

const deleteField = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await fieldsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;
  } finally {
    totalCount.value = totalCount.value - 1;
    removeRow(rowToDelete);
    if (rows.value.length < fetchlimit && totalCount.value >= fetchlimit) {
      await fetchFields();
    }
    closeDeleteListAlertModal();
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.content?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.content?.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

const listOptions = getListOptions();
const listOptionHandler: Record<ListOptionsKeys, (row: FieldRow) => void> = {
  delete: (row) => {
    if (!sessionStore.hasPermission("fields:delete")) {
      alertStore.showPermissionDenied(["fields:delete"]);
      return;
    }

    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: FieldRow) => {
  await listOptionHandler[option.key]?.(row);
};

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    fields: "q",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

watch([selectedSearch], () => {
  rows.value = [];
  totalCount.value = 0;

  cleanQueryParams();
  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    if (isSelectedValueTag(selected)) {
      updateQueryParam({ key: paramName, value: selected.tag.id });
    } else {
      updateQueryParam({ key: paramName, value: selected.id });
    }
  });

  fetchFields();
});

onMounted(() => {
  const queryParams = getQueryParams();

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      value,
      id: value,
    });
  });

  insertRecommmendedNames();
  fetchFields();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderSearch": "Buscar campos",
    "fieldCount": "0 campos | 1 campo | {count} campos",
    "createFieldButton": "Crear campo",
    "selected": "1 campo seleccionado de {total} | {count} campos seleccionadas de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Eliminar",
    "selectAllButton": "Seleccionar todo",
    "filters": {
      "fields": "Nombre"
    },
    "columns": {
      "name": "Nombre",
      "fieldType": "Tipo de dato",
      "replaceCode": "Código de reemplazo"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar campos",
      "confirmationMessage": "Se eliminará 1 campo seleccionado. ¿Deseas continuar? | Se eliminaran {count} campos seleccionados. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar campo",
      "confirmationMessage": "Se eliminará el campo {name}. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Campo eliminado",
        "text": "El campo se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Campos eliminados",
        "text": "Los campos se eliminaron correctamente."
      }
    },
    "listOptions": {
      "delete": "Eliminar"
    }
  },
  "pt": {
    "placeholderSearch": "Pesquisar campos",
    "fieldCount": "0 campos | 1 campo | {count} campos",
    "createFieldButton": "Criar campo",
    "selected": "1 campo selecionado de {total} | {count} campos selecionados de {total}",
    "closeButton": "Cancelar",
    "deleteSelectedButton": "Excluir",
    "selectAllButton": "Selecionar tudo",
    "filters": {
      "fields": "Nome"
    },
    "columns": {
      "name": "Nome",
      "fieldType": "Tipo de dado",
      "replaceCode": "Código de substituição"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir campos",
      "confirmationMessage": "Será excluído 1 campo selecionado. Deseja continuar? | Serão excluídos {count} campos selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir campo",
      "confirmationMessage": "O campo {name} será excluído. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "delete": {
        "title": "Campo excluído",
        "text": "O campo foi excluído com sucesso."
      },
      "deleteBulk": {
        "title": "Campos excluídos",
        "text": "Os campos foram excluídos com sucesso."
      }
    },
    "listOptions": {
      "delete": "Excluir"
    }
  }
}
</i18n>
