<template>
  <Combobox
    as="div"
    :model-value="modelValue"
    :multiple="multiple"
    :nullable="allowEmptyValue"
    class="border-0"
    @update:model-value="UpdateModelValue"
  >
    <ComboboxLabel class="block text-sm font-medium text-gray-700">{{ label }}</ComboboxLabel>
    <div class="relative mt-1">
      <ComboboxInput
        class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
        :display-value="(dataItem) => dataItem?.value"
        :placeholder="placeholder"
        autocomplete="off"
        @change="query = $event.target.value"
      />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-if="query.length > 0 && allowCustomValues && !queryIsSelectedItem"
          key="custom"
          v-slot="{ active, selected }"
          as="template"
          class="hover:cursor-pointer"
        >
          <div
            :class="[
              'flex cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-sky-600 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['truncate', selected && 'font-semibold']"> Create "{{ query }}" </span>
            <span :class="['ml-2 truncate text-gray-500', active ? 'text-sky-200' : 'text-gray-500']">
              {{ query }}
            </span>
          </div>
        </ComboboxOption>
        <ComboboxOption
          v-for="dataItem in filteredDataItems"
          :key="dataItem.key"
          v-slot="{ active, selected }"
          :value="dataItem"
          as="template"
          class="hover:cursor-pointer"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-sky-600 text-white' : 'text-gray-900',
            ]"
            :style="{
              fontFamily: dataItem.options?.font.fontFamily,
            }"
          >
            <div class="flex">
              <span :class="['truncate', selected && 'font-semibold']">
                {{ dataItem.value }}
              </span>
              <span v-if="showKey" :class="['ml-2 truncate text-gray-500', active ? 'text-sky-200' : 'text-gray-500']">
                {{ dataItem.key }}
              </span>
            </div>

            <span
              v-if="selected"
              :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-sky-600']"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { isEmpty } from "lodash";

//Components
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";

//Icons
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";

//Types
export interface DataItem<
  DataType = Record<string, unknown> | undefined,
  keyType = string | number,
  valueType = string | number
> {
  key: keyType;
  value: valueType;
  data?: DataType;
  options?: {
    font: {
      fontFamily: string;
    };
  };
}

export type DataItems<
  DataType = Record<string, unknown> | undefined,
  keyType = string | number,
  valueType = string | number
> = Array<DataItem<DataType, keyType, valueType>>;

//Props
const props = withDefaults(
  defineProps<{
    dataItems: DataItems;
    modelValue?: DataItem;
    label?: string;
    placeholder?: string;
    multiple?: boolean;
    showKey?: boolean;
    allowCustomValues?: boolean;
    allowEmptyValue?: boolean;
  }>(),
  {
    dataItems: () => [] as DataItems,
    modelValue: undefined,
    label: "",
    placeholder: undefined,
    multiple: false,
    showKey: true,
    allowCustomValues: false,
    allowEmptyValue: false,
  }
);

const emit = defineEmits<{
  (e: "update:dataItems", dataItems: DataItems): void;
  (e: "update:modelValue", selected: DataItem): void;
}>();

const UpdateModelValue = (selected: DataItem) => {
  const customValue = query.value;
  query.value = "";
  if (!selected) {
    emit("update:modelValue", { key: customValue, value: customValue });
    return;
  }
  emit("update:modelValue", selected);
};

const query = ref("");

const queryIsSelectedItem = computed(() => props.modelValue && query.value === props.modelValue.key);

const filteredDataItems = computed(() => {
  const filteredDataItems = props.dataItems.filter((dataItem) => {
    const queryValue = query.value?.toLowerCase();
    const keyValue = dataItem.key?.toString()?.toLowerCase();
    const value = dataItem.value?.toString()?.toLowerCase();

    return keyValue?.includes(queryValue) || value?.includes(queryValue);
  });

  if (isEmpty(props.modelValue)) return filteredDataItems;

  const element = props.dataItems.find((dataItem) => dataItem.key === props.modelValue?.key);

  if (!element && query.value == "" && props.allowCustomValues) return [props.modelValue, ...filteredDataItems];
  if (queryIsSelectedItem.value) return [props.modelValue];
  return filteredDataItems;
});
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  @apply bg-neutral-100;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
