var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button class='btn btn-primary' data-action='open-select-product-modal' data-product-type='monthly' data-limit='contacts'>\n                                            "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitContacts")), depth0))
    + "\n                                        </button>\n                                        <p>\n                                            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitContactsLegend")), depth0)) != null ? stack1 : "")
    + "\n                                        </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <button class='btn btn-primary' disabled>\n                                            "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitContacts")), depth0))
    + "\n                                        </button>\n                                        <p>\n                                            "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitContactsLegendUnavailable")), depth0)) != null ? stack1 : "")
    + "\n                                        </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog modal-lg select-limit'>\n    <div class='modal-content'>\n        <div class='modal-header'>\n            <button type='button' class='close' data-dismiss='modal'>\n                <span aria-hidden='true'>\n                    &times;\n                </span>\n            </button>\n            <h4 class='modal-title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlySubscription")), depth0))
    + "\n            </h4>\n        </div>\n        <div class='modal-body'>\n            <div class='modal-body-padded'>\n                <p>\n                    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"chooseMonthlySubscription")), depth0)) != null ? stack1 : "")
    + "\n                </p>\n                <div class='limit-chooser'>\n                    <div class='row'>\n                        <div class='col-md-4 col-md-offset-2'>\n                            <div class='panel panel-default limit-type'>\n                                <div class='panel-body'>\n                                    <i class='icon icon-plan-confirmation-monthly-contacts'></i>\n                                </div>\n                                <div class='panel-footer "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"canChooseContactsPlan") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":57},"end":{"line":25,"column":109}}})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"canChooseContactsPlan") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":40,"column":43}}})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                        </div>\n                        <div class='col-md-4'>\n                            <div class='panel panel-default limit-type'>\n                                <div class='panel-body'>\n                                    <i class='icon icon-plan-confirmation-monthly-sendings'></i>\n                                </div>\n                                <div class='panel-footer'>\n                                    <button class='btn btn-primary' data-action='open-select-product-modal' data-product-type='monthly' data-limit='emails'>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitEmails")), depth0))
    + "\n                                    </button>\n                                    <p>\n                                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"monthlyLimitEmailsLegend")), depth0))
    + "\n                                    </p>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});