var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class='instant-search has-feedback'>\n        <input type='text' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' class='form-control' data-action='search-filter' data-results='[data-role=list]' data-no-results='[data-role=no-lists]'>\n    </li>\n    <li class='no-results hide' data-role='no-lists'>\n        <span>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noResults")), depth0))
    + "\n        </span>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":28,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li data-role='list' data-value='"
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"keywords") : depth0),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":11,"column":41},"end":{"line":11,"column":63}}}))
    + "'>\n            <a data-action='filter' data-params='"
    + alias2(__default(require("../../../helpers/handlebars/stringify.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"stringify","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":69}}}))
    + "' title=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\">\n                "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"subtitle") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <em>\n                        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"subtitle") : depth0), depth0))
    + "\n                    </em>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class='tags'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":23,"column":33}}})) != null ? stack1 : "")
    + "                    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                            <span class='label label-primary'>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class='no-results'>\n        <span>\n            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noValuesForFilter")), depth0))
    + "\n        </span>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"items") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"useData":true});