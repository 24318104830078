export const showNewMessage = (message: string) => {
  window.Intercom("showNewMessage", message);
};

export const openArticle = (article: string) => {
  window.Intercom("showArticle", article);
};

export const update = (data: object) => {
  window.Intercom("update", data);
};

export default {
  showNewMessage,
  openArticle,
  update,
};
