import { useDefaultFonts } from "@/vue/composables/fonts";

//Types
interface DataItem<DataType = unknown, keyType = string, valueType = string> {
  key: keyType;
  value: valueType;
  data?: DataType;
  options?: {
    font: {
      fontFamily: string;
    };
  };
}

type DataItems<DataType = unknown, keyType = string, valueType = string> = Array<
  DataItem<DataType, keyType, valueType>
>;

const defaultFonts = useDefaultFonts();

export const defaultFontsDataItems: DataItems<{ url?: UrlString }> = defaultFonts.map((font) => {
  const dataItem: DataItem<{ url?: UrlString; name?: string }> = {
    key: font.id,
    value: font.value || "",
    data: {
      url: font.url,
      name: font.name,
    },
  };

  if (font.value)
    dataItem.options = {
      font: {
        fontFamily: font.value,
      },
    };

  return dataItem;
});
