// Store
import { useGlobalStore } from "@/vue/stores";

// Types
import type { RouterApplication } from "@application";

// Utils
import { ok } from "neverthrow";

export const useRouterApp = (): RouterApplication => {
  const globalStore = useGlobalStore();
  return {
    async navigate(params) {
      window.Backbone.history.navigate(params.path, { trigger: params.trigger ?? true });
      globalStore.updateState();
      return ok(undefined);
    },
  };
};
