<template>
  <div class="space-y-6">
    <Teleport to="#teleport-layout-selector">
      <FormGroup id="layout" :label="t('layout.label')">
        <div class="overflow-x-auto">
          <ToggleButtons v-model="selectedLayout" :buttons="LayoutOptions" :icon-height="5" />
        </div>
      </FormGroup>
    </Teleport>

    <div class="grid grid-cols-3 gap-x-6 gap-y-6 2xl:grid-cols-6">
      <FormGroup id="headerBackgroundColor" :label="t('headerBackgroundColor.label')">
        <InputColorPicker
          v-model="configState.styles.headerBackgroundColor"
          :disabled="configState.styles.titleInBody"
        />
      </FormGroup>
      <FormGroup id="buttonBackgroundColor" :label="t('buttonBackgroundColor.label')">
        <InputColorPicker v-model="configState.styles.buttonBackgroundColor" />
      </FormGroup>
      <FormGroup id="backgroundColor" :label="t('backgroundColor.label')">
        <InputColorPicker v-model="configState.styles.backgroundColor" />
      </FormGroup>

      <FormGroup id="headerTextColor" :label="t('headerTextColor.label')">
        <InputColorPicker v-model="configState.styles.headerTextColor" />
      </FormGroup>
      <FormGroup id="buttonTextColor" :label="t('buttonTextColor.label')">
        <InputColorPicker v-model="configState.styles.buttonTextColor" />
      </FormGroup>
      <FormGroup id="textColor" :label="t('textColor.label')">
        <InputColorPicker v-model="configState.styles.textColor" />
      </FormGroup>
    </div>

    <div class="grid grid-cols-3 gap-x-6 gap-y-6 2xl:grid-cols-6">
      <FormGroup id="titleInBody" :label="t('titleLocation.label')">
        <SelectMenu v-model:selected="titleLocationSelection" :data-items="TitleLocations" />
      </FormGroup>
      <FormGroup id="maxWidth" :label="t('maxWidth.label')">
        <SimpleInput
          v-model="maxWidthNumber"
          type="number"
          :placeholder="t('maxWidth.placeholder')"
          :step="10"
          min="300"
        />
      </FormGroup>

      <FormGroup id="textAlign" :label="t('textAlign.label')">
        <ToggleButtons
          v-model="configState.styles.textAlign"
          :buttons="[
            { id: 'left', label: t('align.left'), icon: MenuAlt1Icon },
            { id: 'center', label: t('align.right'), icon: MenuIcon },
          ]"
        />
      </FormGroup>
    </div>

    <div class="grid grid-cols-1 gap-x-6 gap-y-6 2xl:grid-cols-2">
      <div
        v-if="configState.layout !== 'default' && configState.layout !== 'inline'"
        class="grid grid-cols-2 gap-x-6 gap-y-6"
      >
        <FormGroup id="image" :label="t('image.label')">
          <InputUpload
            file-types=".png, .jpg, .jpeg, .gif"
            :file-name="configState.styles.image"
            :loading="uploadingImage"
            @clear="configState.styles.image = ''"
            @update:model-value="updateImageFile"
          />
        </FormGroup>
        <FormGroup id="imageSize" :label="t('imageSize.label')" class="max-w-xs">
          <SelectMenu v-model:selected="selectedImageSize" :data-items="ImageSizes" />
        </FormGroup>
      </div>

      <div class="grid grid-cols-2 gap-x-6 gap-y-6">
        <FormGroup id="backgroundImage" :label="t('backgroundImage.label')">
          <InputUpload
            file-types=".png, .jpg, .jpeg, .gif"
            :file-name="configState.styles.backgroundImage"
            :loading="uploadingBackgroundImage"
            @clear="configState.styles.backgroundImage = ''"
            @update:model-value="updateBackgroundImageFile"
          />
        </FormGroup>
        <FormGroup id="backgroundImageSize" :label="t('imageSize.label')" class="max-w-xs">
          <SelectMenu v-model:selected="selectedBackgroundImageSize" :data-items="ImageSizes" />
        </FormGroup>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-x-6 gap-y-6">
      <FormGroup v-if="selectedFont.key !== 'custom'" id="font" :label="t('font.label')" :hint="t('font.hint')">
        <SelectMenu v-model:selected="selectedFont" :rem-height="15" :data-items="AvailableFonts" />
      </FormGroup>
      <FormGroup
        v-if="selectedFont.key === 'custom'"
        id="custom-font"
        :label="t('font.label')"
        :hint="t('customFont.hint')"
      >
        <SimpleInput
          v-model="configState.fonts.text.family"
          :placeholder="t('customFont.placeholder')"
          @blur="onFontInputBlur"
        />
      </FormGroup>

      <FormGroup
        v-if="selectedTitleFont.key !== 'custom'"
        id="font"
        :label="t('titleFont.label')"
        :hint="t('titleFont.hint')"
      >
        <SelectMenu v-model:selected="selectedTitleFont" :rem-height="15" :data-items="AvailableFonts" />
      </FormGroup>

      <FormGroup
        v-if="selectedTitleFont.key === 'custom'"
        id="custom-font"
        :label="t('titleFont.label')"
        :hint="t('customFont.hint')"
      >
        <SimpleInput
          v-model="configState.fonts.title.family"
          :placeholder="t('customFont.placeholder')"
          @blur="onTitleFontInputBlur"
        />
      </FormGroup>
    </div>

    <FormSwitch
      v-model="configState.perfitAd"
      :description="t('perfitAd.description')"
      reverse
      :disabled="isFreeAccount"
    >
      <template #label>
        <div class="flex items-center space-x-2">
          <span>{{ t("perfitAd.label") }}</span>
          <template v-if="isFreeAccount">
            <IconBullet :icon="InformationCircleIcon" :tooltip="t('perfitAd.tooltip')" size="small" color="sky" />
          </template>
        </div>
      </template>
    </FormSwitch>

    <div
      class="space-y-6"
      :class="{
        hidden: !isMasterUser,
      }"
    >
      <hr />
      <FormTextArea
        id="formTextAreaCustomCss"
        v-model="configState.customCss"
        class="font-mono"
        :label="t('customCss.label')"
        :hint="t('customCss.hint')"
        rows="10"
        placeholder=".class {property: value}"
      />

      <FormTextArea
        id="formTextAreaFields"
        v-model="fieldsConfigString"
        class="font-mono"
        :label="t('fields.label')"
        hint='Ejemplo: {"12": { "options": [ "Buenos Aires", "Mar del Plata", "Rosario" ] }, "13": { "options": [ "Argentina", "Brasil", "Colombia" ] } }'
        :error="fieldsConfigError"
        rows="10"
        placeholder="{...}"
      />
    </div>
  </div>

  <input name="form[config]" type="hidden" :value="JSON.stringify(configState)" />
</template>

<script lang="ts" setup>
import { watch, reactive, toRaw, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAccountFiles } from "@api/modules/accountfiles";
import { cloneDeep, isString } from "lodash";

// Icons
import { MenuIcon, MenuAlt1Icon, InformationCircleIcon } from "@heroicons/vue/solid";

//Components
import FormGroup from "@molecules/FormGroup.vue";
import FormTextArea from "@molecules/FormTextArea.vue";
import InputColorPicker from "@atoms/InputColorPicker.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import SelectMenu from "@molecules/SelectMenu.vue";
import ToggleButtons from "@atoms/ToggleButtons.vue";
import InputUpload from "@molecules/InputUpload.vue";
import FormSwitch from "@molecules/FormSwitch.vue";
import IconBullet from "@atoms/IconBullet.vue";

// Layout images
import defaultLayout from "./layouts/default.vue";
import inlineLayout from "./layouts/inline.vue";
import leftImageLayout from "./layouts/leftImage.vue";
import rightImageLayout from "./layouts/rightImage.vue";
import topImageLayout from "./layouts/topImage.vue";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Types
import type { DataItem, DataItems } from "@molecules/SelectMenu.vue";

interface IFont {
  family: string;
  weight?: number;
  italic?: boolean;
}

interface IConfig {
  layout: string;
  styles: {
    headerBackgroundColor: string;
    headerTextColor: string;
    buttonBackgroundColor: string;
    buttonTextColor: string;
    backgroundColor: string;
    textColor: string;
    image: string;
    imageSize: string;
    backgroundImage: string;
    backgroundImageSize: string;
    textAlign: string;
    fontSize: string;
    titleInBody: boolean;
    maxWidth?: string;
  };
  customCss: string;
  perfitAd: boolean;
  fonts: {
    text: IFont;
    title: IFont;
  };
  fields: string;
}

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

// Props
const props = withDefaults(
  defineProps<{
    config: IConfig;
  }>(),
  {}
);

const emit = defineEmits<{
  (e: "update:config", value: IConfig): void;
}>();

// Language
const { t } = useI18n();

// Sessions
const isMasterUser = session.value?.isMasterUser;
const isFreeAccount = session.value?.account?.plan?.type === "FREE";

const configState: IConfig = reactive(cloneDeep(props.config));
configState.styles = configState.styles || {};
configState.perfitAd = configState.perfitAd || isFreeAccount;
configState.styles.textAlign = configState.styles.textAlign || "left";
configState.fonts = configState.fonts || {};
configState.fonts.text = configState.fonts.text || {};
configState.fonts.title = configState.fonts.title || {};

//Watchers
watch(
  () => configState,
  (newValue) => {
    emit("update:config", toRaw(newValue));
  },
  { deep: true }
);

// TitleLocations
const TitleLocations = [
  {
    key: "header",
    value: t("titleLocation.options.header"),
  },
  {
    key: "inbody",
    value: t("titleLocation.options.body"),
  },
] as DataItems<string, string, string>;

const titleLocationSelection = ref<DataItem>(TitleLocations[0]);

if (configState.styles.titleInBody) titleLocationSelection.value = TitleLocations[1];

watch(titleLocationSelection, (newValue) => {
  configState.styles.titleInBody = newValue.key === "inbody";
});

// Fonts
const AvailableFonts = [
  {
    key: "",
    value: t("fonts.default"),
  },
  {
    key: "Inter",
    value: "Inter",
  },
  {
    key: "Lato",
    value: "Lato",
  },
  {
    key: "Montserrat",
    value: "Montserrat",
  },
  {
    key: "Open Sans",
    value: "Open Sans",
  },
  {
    key: "Oswald",
    value: "Oswald",
  },
  {
    key: "Poppins",
    value: "Poppins",
  },
  {
    key: "PT Sans Narrow",
    value: "PT Sans Narrow",
  },
  {
    key: "Raleway",
    value: "Raleway",
  },
  {
    key: "Roboto",
    value: "Roboto",
  },
  {
    key: "Source Sans Pro",
    value: "Source Sans Pro",
  },
  {
    key: "Space Mono",
    value: "Space Mono",
  },
  {
    key: "custom",
    value: t("fonts.custom"),
  },
] as DataItems<string, string, string>;

const selectedFont = ref<DataItem>(AvailableFonts[0]);
const selectedTitleFont = ref<DataItem>(AvailableFonts[0]);

if (configState.fonts?.text?.family) {
  selectedFont.value =
    AvailableFonts.find((f) => f.key === configState.fonts.text.family) || AvailableFonts[AvailableFonts.length - 1];
}
watch(selectedFont, () => {
  if (selectedFont.value.key !== "custom") {
    if (isString(configState.fonts.text)) {
      // solo para compatib con version anterior
      configState.fonts.text = { family: selectedFont.value.key };
    } else {
      configState.fonts.text.family = selectedFont.value.key;
    }
  }
});

const onFontInputBlur = () => {
  if (configState.fonts.text.family.trim() === "") {
    selectedFont.value = AvailableFonts[0];
  }
};

const onTitleFontInputBlur = () => {
  if (configState.fonts.title.family.trim() === "") {
    selectedTitleFont.value = AvailableFonts[0];
  }
};

if (configState.fonts?.title?.family) {
  selectedTitleFont.value =
    AvailableFonts.find((f) => f.key === configState.fonts.title.family) || AvailableFonts[AvailableFonts.length - 1];
}
watch(selectedTitleFont, () => {
  if (selectedTitleFont.value.key !== "custom") {
    if (isString(configState.fonts.title)) {
      // solo para compatib con version anterior
      configState.fonts.title = { family: selectedTitleFont.value.key };
    } else {
      configState.fonts.title.family = selectedTitleFont.value.key;
    }
  }
});

// Layouts
const LayoutOptions = [
  {
    id: "default",
    label: t("layouts.default"),
    icon: defaultLayout,
  },
  {
    id: "leftImage",
    label: t("layouts.leftImage"),
    icon: leftImageLayout,
  },
  {
    id: "rightImage",
    label: t("layouts.rightImage"),
    icon: rightImageLayout,
  },
  {
    id: "topImage",
    label: t("layouts.topImage"),
    icon: topImageLayout,
  },
  {
    id: "inline",
    label: t("layouts.inline"),
    icon: inlineLayout,
  },
];

const selectedLayout = ref(LayoutOptions.find((l) => l.id === configState.layout)?.id || LayoutOptions[0].id);

watch(selectedLayout, (newValue) => {
  configState.layout = newValue;
});

// Images
const ImageSizes = [
  {
    key: "auto",
    value: t("imageSizes.normal"),
  },
  {
    key: "contain",
    value: t("imageSizes.contain"),
  },
  {
    key: "cover",
    value: t("imageSizes.cover"),
  },
] as DataItems<string, string, string>;

const selectedImageSize = ref<DataItem>(ImageSizes[0]);
if (configState.styles.imageSize) {
  selectedImageSize.value = ImageSizes.find((f) => f.key === configState.styles.imageSize) || ImageSizes[0];
}

watch(selectedImageSize, () => {
  configState.styles.imageSize = selectedImageSize.value.key;
});

const selectedBackgroundImageSize = ref<DataItem>(ImageSizes[0]);
if (configState.styles.backgroundImageSize) {
  selectedBackgroundImageSize.value =
    ImageSizes.find((f) => f.key === configState.styles.backgroundImageSize) || ImageSizes[0];
}
watch(selectedBackgroundImageSize, () => {
  configState.styles.backgroundImageSize = selectedBackgroundImageSize.value.key;
});

const accountFiles = useAccountFiles();

const uploadImage = async (file) => {
  const res: any = await accountFiles.postImages({ file, folder: "/imageTest" });
  if (!res) return "";
  return `url(${res?.data?.location?.absolute})`;
};

const imageFile = ref({});
const uploadingImage = ref(false);
const updateImageFile = async (newFile) => {
  uploadingImage.value = true;
  imageFile.value = newFile;
  try {
    configState.styles.image = await uploadImage(newFile);
  } finally {
    uploadingImage.value = false;
  }
};

const backgroundImageFile = ref({});
const uploadingBackgroundImage = ref(false);
const updateBackgroundImageFile = async (newFile) => {
  uploadingBackgroundImage.value = true;
  backgroundImageFile.value = newFile;
  try {
    configState.styles.backgroundImage = await uploadImage(newFile);
  } finally {
    uploadingBackgroundImage.value = false;
  }
};

// maxWidth
const maxWidthNumber = ref("");

if (configState.styles.maxWidth) {
  maxWidthNumber.value = String(Number.parseInt(configState.styles.maxWidth));
}

watch(maxWidthNumber, (newValue) => {
  if (newValue) {
    const val = Number.parseInt(newValue);
    configState.styles.maxWidth = (val > 300 ? val : 300) + "px";
  } else {
    delete configState.styles.maxWidth;
  }
});

// Fields config
const fieldsConfigString = ref(JSON.stringify(configState.fields));
const fieldsConfigError = ref("");

watch(fieldsConfigString, (value: string) => {
  try {
    const fields = value !== "" ? JSON.parse(value) : {};
    configState.fields = fields;
    fieldsConfigError.value = "";
  } catch (e) {
    fieldsConfigError.value = t("invalidJsonError");
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "invalidJsonError": "Formato JSON inválido",
    "layout": {
      "label": "Formato"
    },
    "perfitAd": {
      "label": "Creado usando Perfit",
      "description": "Mostrar leyenda \"Creado usando Perfit\" al pie del formulario.",
      "tooltip": "Es necesario contratar un plan pago para modificar esta opción."
    },
    "fonts": {
      "default": "Por defecto (utiliza la fuente de tu sitio)",
      "custom": "Personalizada"
    },
    "font": {
      "label": "Fuente",
      "hint": "Se utilizará esta tipografía para todos los textos."
    },
    "titleFont": {
      "label": "Fuente del título",
      "hint": "Se utilizará esta tipografía el título o encabezado."
    },
    "image": {
      "label": "Imagen principal"
    },
    "backgroundImage": {
      "label": "Imagen de fondo"
    },
    "customFont": {
      "label": "Fuente personalizada",
      "placeholder": "Nombre de fuente",
      "hint": "Ingresa el nombre de la tipografía, tal como figura en <a href='https://fonts.google.com/' target='_blank'>Google Fonts</a>."
    },
    "headerBackgroundColor": {
      "label": "Fondo encabezado"
    },
    "headerTextColor": {
      "label": "Texto encabezado"
    },
    "buttonBackgroundColor": {
      "label": "Fondo botón"
    },
    "buttonTextColor": {
      "label": "Texto botón"
    },
    "backgroundColor": {
      "label": "Fondo contenido"
    },
    "textColor": {
      "label": "Texto contenido"
    },
    "imageSize": {
      "label": "Tamaño"
    },
    "textAlign": {
      "label": "Alineación de textos"
    },
    "maxWidth": {
      "label": "Ancho del formulario",
      "placeholder": "en pixels"
    },
    "imageSizes": {
      "normal": "Original",
      "contain": "Mostrar completa",
      "cover": "Cubrir el área"
    },
    "customCss": {
      "label": "Estilos personalizados [sólo master users]",
      "hint": "Utiliza este espacio para incluir clases CSS propias del form."
    },
    "fields": {
      "label": "Configuración avanzada de campos [sólo master users]",
      "hint": ""
    },
    "layouts": {
      "default": "Simple",
      "leftImage": "Imagen a la izquierda",
      "rightImage": "Imagen a la derecha",
      "leftImageFullHeight": "Imagen a la izquierda sin márgen",
      "rightImageFullHeight": "Imagen a la derecha sin márgen",
      "backgroundImage": "Imagen de fondo",
      "inline": "Campos en línea"
    },
    "titleLocation": {
      "label": "Ubicación del título",
      "options": {
        "header": "Encabezado",
        "body": "Contenido"
      }
    }
  },
  "pt": {
    "invalidJsonError": "Formato JSON inválido",
    "layout": {
      "label": "Formato"
    },
    "perfitAd": {
      "label": "Criado usando Perfit",
      "description": "Mostrar legenda \"Criado usando Perfit\" no rodapé do formulário.",
      "tooltip": "É necessário contratar um plano pago para modificar esta opção."
    },
    "headerBackgroundColor": {
      "label": "Fundo do cabeçalho"
    },
    "headerTextColor": {
      "label": "Texto do cabeçalho"
    },
    "buttonBackgroundColor": {
      "label": "Fundo do botão"
    },
    "buttonTextColor": {
      "label": "Texto do botão"
    },
    "backgroundColor": {
      "label": "Fundo do conteúdo"
    },
    "textColor": {
      "label": "Texto do conteúdo"
    },
    "customCss": {
      "label": "Código livre [sólo master users]",
      "hint": "Use este espaço para usar as próprias classes CSS do formulário"
    },
    "fields": {
      "label": "Configuración avanzada de campos [sólo master users]",
      "hint": "Opciones adicionales para validación y menu desplegable."
    },
    "fonts": {
      "default": "Por padrão (utiliza a fonte do seu site)",
      "custom": "Personalizada"
    },
    "font": {
      "label": "Fonte",
      "hint": "Será utilizada essa tipografia para todos os textos."
    },
    "titleFont": {
      "label": "Fonte do título",
      "hint": "Essa tipografia será usada para o título ou cabeçalho."
    },
    "image": {
      "label": "Imagem principal"
    },
    "backgroundImage": {
      "label": "Imagem de fundo"
    },
    "customFont": {
      "label": "Fonte personalizada",
      "placeholder": "Nome da fonte",
      "hint": "Insira o nome da tipografia conforme mostra no <a href='https://fonts.google.com/' target='_blank'>Google Fonts</a>."
    },
    "imageSize": {
      "label": "Tamanho"
    },
    "textAlign": {
      "label": "Alinhamento de texto"
    },
    "maxWidth": {
      "label": "Largura do formulário",
      "placeholder": "em pixels"
    },
    "imageSizes": {
      "normal": "Original",
      "contain": "Mostrar completa",
      "cover": "Cobrir espaço"
    },
    "layouts": {
      "default": "Simples",
      "inline": "Campos Inline",
      "leftImage": "Imagem à esquerda",
      "rightImage": "Imagem certa",
      "leftImageFullHeight": "Imagem à esquerda sem margem",
      "rightImageFullHeight": "Imagem certa sem margem",
      "backgroundImage": "Imagem de fundo"
    },
    "titleLocation": {
      "label": "Localização do título",
      "options": {
        "header": "Cabeçalho",
        "body": "Conteúdo"
      }
    }
  }
}
</i18n>
