<template>
  <div ref="containerRef">
    <SimpleInput
      v-if="editing || loading"
      ref="inputRef"
      v-model.trim="editedValue"
      :model-modifiers="inputModifiers"
      :placeholder="placeholder"
      :has-error="v$.$error"
      :loading="loading"
      @keydown="v$.$touch()"
      @keydown.stop.enter="commit"
      @keydown.stop.esc="cancel"
    />
    <p
      v-else
      class="px-[13px] py-[9px] text-sm flex items-center justify-between group rounded-md"
      :class="{
        'hover:text-gray-700 hover:bg-gray-50 cursor-pointer': editable && content,
        'hover:text-gray-400 hover:bg-gray-50 cursor-pointer': editable && !content,
        'text-gray-600': content,
        'text-gray-300': !content,
      }"
      @click="startEdit"
    >
      {{ content || placeholder }}
      <PencilAltIcon v-if="editable" class="block h-5 w-5 opacity-0 group-hover:opacity-100" />
    </p>
  </div>
</template>

<script>
import { ref, nextTick, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { onClickOutside } from "@vueuse/core";
import SimpleInput from "@atoms/SimpleInput";

export default {
  name: "EditableText",
  components: { PencilAltIcon, SimpleInput },
  props: {
    content: {
      type: String,
      default: "",
    },
    inputModifiers: {
      type: Object,
      default: () => ({}),
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    validationRules: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["commit", "cancel"],
  setup(props, { emit }) {
    const editing = ref(false);
    const editedValue = ref(props.content);

    const inputRef = ref(null);
    const containerRef = ref(null);

    const rules = computed(() => ({
      editedValue: {
        required: !props.allowEmpty && required,
        ...props.validationRules,
      },
    }));

    const v$ = useVuelidate(rules, { editedValue });

    const cancel = () => {
      emit("cancel");
      editing.value = false;
      editedValue.value = props.content;
    };

    const startEdit = () => {
      if (!props.editable) return;
      editedValue.value = props.content;
      editing.value = true;
      nextTick().then(() => {
        inputRef.value.focus();
      });
    };

    const commit = () => {
      if (!editing.value) return;

      editing.value = false;
      if (
        v$.value.$error ||
        editedValue.value === props.content ||
        (editedValue.value === "" && !props.allowEmpty)
      ) {
        return cancel();
      }

      emit("commit", editedValue.value);
    };

    onClickOutside(containerRef, commit);

    return {
      editing,
      editedValue,
      inputRef,
      startEdit,
      commit,
      cancel,
      containerRef,
      v$,
    };
  },
};
</script>
