<template>
  <div class="flex flex-col bg-white">
    <TemplatePreviewSelector
      v-if="selectedTemplate"
      :is-public-template="isPublic"
      :template="selectedTemplate"
      @select="openUseTemplateModal"
      @close="deleteSelectedTemplate"
    />
    <div v-show="!selectedTemplate" class="flex justify-between px-9 py-5">
      <div class="flex space-x-4">
        <!-- :links="{ es: { helpId: '123', youTubeLink: '123' } }" -->
        <PageTitle size="medium" theme="white">{{ t("templateGalleryTitle") }}</PageTitle>
      </div>
      <button
        type="button"
        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-300 focus:ring-offset-2"
        @click="close"
      >
        <XIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
    <TemplateGalleryTabs
      v-show="!selectedTemplate"
      :tpl-id="tplId"
      :show="{
        myCampaigns: show.myCampaigns,
        myTemplates: show.myTemplates,
        templateGallery: show.templateGallery,
      }"
      @select="selectTemplate"
      @selectHTML="useHTMLTemplate"
      @select-new-template="openUseTemplateModal"
    />
    <ConfirmationModal
      id="useTemplateModal"
      v-bind="useTemplateModal"
      @cancel="closeUseTemplateModal"
      @accept="onSaveAuditedTemplate"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from "vue";

//Components
import PageTitle from "@molecules/PageTitle.vue";
import TemplateGalleryTabs from "./TemplateGalleryTabs.vue";
import TemplatePreviewSelector from "@organisms/Templates/TemplatePreviewSelector/TemplatePreviewSelector.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";

//Icon
import { XIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";

//API
import { useEmailTemplate } from "@api/modules/templates/templates";

//Types
import { Template, TemplateItem } from "@/vue/types/Templates";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    showAlertApplyTemplate?: boolean;
    tplId?: string;
    show?: {
      templateGallery?: boolean;
      myTemplates?: boolean;
      myCampaigns?: boolean;
    };
  }>(),
  {
    showAlertApplyTemplate: false,
    tplId: undefined,
    show: () => ({
      templateGallery: true,
      myTemplates: true,
      myCampaigns: true,
    }),
  },
);

//Emits
const emit = defineEmits<{
  (e: "select", selectedTemplateData: { template: Template; isPublicTemplate: boolean }): void;
  (e: "selectHTML", template: string): void;
  (e: "selectNewTemplate"): void;
  (e: "close"): void;
}>();

const close = () => emit("close");
const useEmptyTemplate = () => {
  emit("selectNewTemplate");
  close();
};

const selectedTemplate = ref<TemplateItem | undefined>();
const isPublic = ref(false);

//Confirmation use template
const useTemplateModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("useTemplateModal.confirmationTitle"),
  message: t("useTemplateModal.confirmationMessage"),
  acceptText: t("accept"),
  acceptLoading: false,
  cancelText: t("cancel"),
  dismissValue: false,
  severity: "info",
});

const closeUseTemplateModal = () => (useTemplateModal.open = false);
const openUseTemplateModal = (template?: TemplateItem) => {
  if (!props.showAlertApplyTemplate) {
    template && selectedTemplate.value ? useTemplate(selectedTemplate.value) : useEmptyTemplate();
    return;
  }
  selectedTemplate.value = template ? template : undefined;
  useTemplateModal.open = true;
};
const onSaveAuditedTemplate = () => {
  selectedTemplate.value ? useTemplate(selectedTemplate.value) : useEmptyTemplate();
};
//Preview Template
const selectTemplate = ({ template, isPublicTemplate }: { template: TemplateItem; isPublicTemplate: boolean }) => {
  isPublic.value = isPublicTemplate;
  selectedTemplate.value = template;
};

//Use Template
const useTemplate = async (selectedTemplate: TemplateItem) => {
  try {
    const template = isPublic.value ? await getPublicTemplate(selectedTemplate) : await getTemplate(selectedTemplate);
    if (!template) return;

    emit("select", { template, isPublicTemplate: isPublic.value });
    close();
  } catch (e) {
    //TODO: Handle Error
  }
};

const useHTMLTemplate = (selectedTemplate: string) => {
  emit("selectHTML", selectedTemplate);
  close();
};

const templatesAPI = useEmailTemplate();
const getTemplate = async (selectedTemplate: TemplateItem) => {
  try {
    return await templatesAPI.getTemplate({ tpl_id: selectedTemplate.id });
  } catch (e) {
    //TODO: Manejo de loading
  }
};

const getPublicTemplate = async (selectedTemplate: TemplateItem) => {
  try {
    return await templatesAPI.getPublicTemplate({ tpl_id: selectedTemplate.id });
  } catch (e) {
    //TODO: Manejo de loading
  }
};

const deleteSelectedTemplate = () => (selectedTemplate.value = undefined);
</script>

<i18n lang="jsonc">
{
  "es": {
    "templateGalleryTitle": "Selecciona una plantilla para comenzar",
    "useTemplateModal": {
      "confirmationTitle": "Usar plantilla",
      "confirmationMessage": "Se aplicará la plantilla seleccionada y perderás los cambios que hayas hecho en el contenido. ¿Deseas continuar?"
    }
  },
  "pt": {
    "templateGalleryTitle": "Selecione um template para começar",
    "useTemplateModal": {
      "confirmationTitle": "Usar template",
      "confirmationMessage": "Se aplicará o template selecionado e as mudanças realizadas no conteúdo serão perdidas. Deseja continuar?"
    }
  }
}
</i18n>
