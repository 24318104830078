<template>
  <ModalDialog :open="open" @close="cancel">
    <div class="max-w-2xl">
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <InformationCircleIcon class="h-6 w-6 text-sky-500" aria-hidden="true" />
        </div>
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
          <h1 class="mt-1 text-lg font-medium text-gray-800">{{ t("title") }}</h1>
          <p class="mt-3 text-sm text-gray-500">{{ t("description") }}</p>
          <div class="flex justify-between py-4">
            <FormCheckBox
              v-model:model-value="selectedOptions.useContent"
              :disabled="false"
              :label="t('useContentCheckbox')"
              class="mt-3"
            />
            <FormCheckBox
              v-model:model-value="selectedOptions.useSubject"
              :disabled="false"
              :label="t('useSubjectCheckbox')"
              class="mt-3"
            />
            <FormCheckBox
              v-model:model-value="selectedOptions.usePreheader"
              :disabled="false"
              :label="t('usePreheaderCheckbox')"
              class="mt-3"
            />
          </div>
        </div>
      </div>
      <AlertBox class="mt-4 ml-12 pr-20">
        <div>
          <span class="block">
            {{ t("alertMessageFirstParagraph") }}
          </span>
          <span class="block">
            {{ t("alertMessageSecondParagraph") }}
          </span>
        </div>
      </AlertBox>
    </div>
    <template #buttons>
      <SimpleButton :disabled="!optionIsSelected" :loading="acceptLoading" theme="primary-light" @click="accept">
        <template #leading><RefreshIcon /></template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" class="focus:ring-gray-300" @click="cancel">
        {{ t("cancelButton") }}
      </SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed, reactive } from "vue";

//Components
import SimpleButton from "@atoms/SimpleButton.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";
import AlertBox from "@/vue/components/atoms/AlertBox.vue";

//Icons
import { InformationCircleIcon, RefreshIcon } from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

//Types
export interface AcceptDetails {
  useContent: boolean;
  useSubject: boolean;
  usePreheader: boolean;
}

withDefaults(
  defineProps<{
    open: boolean;
    acceptLoading?: boolean;
  }>(),
  {}
);

// Emits
const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
  (e: "accept", value: AcceptDetails): void;
}>();

const accept = () => emit("accept", selectedOptions);
const cancel = () => {
  emit("update:open", false);
};

const { t } = useI18n();

//State
const selectedOptions = reactive<AcceptDetails>({
  useContent: false,
  useSubject: false,
  usePreheader: false,
});

const optionIsSelected = computed(() => Object.values(selectedOptions).some(Boolean));
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Restaurar versión anterior",
    "description": "Selecciona cuáles de los siguientes elementos deseas restaurar en tu plantilla:",
    "useContentCheckbox": "Contenido",
    "useSubjectCheckbox": "Asunto",
    "usePreheaderCheckbox": "Preheader",
    "alertMessageFirstParagraph": "Los elementos que selecciones reemplazarán a los de tu plantilla actual.",
    "alertMessageSecondParagraph": "El resto no será modificado.",
    "acceptButton": "Restaurar",
    "cancelButton": "Cancelar"
  },
  "pt": {
    "title": "Restaurar Versão anterior",
    "description": "Selecione quais dos seguintes elementos você deseja restaurar no seu template:",
    "useContentCheckbox": "Conteúdo",
    "useSubjectCheckbox": "Assunto",
    "usePreheaderCheckbox": "Preheader",
    "alertMessageFirstParagraph": "Os elementos selecionados vão substituir os do template atual.",
    "alertMessageSecondParagraph": "O restante não será modificado.",
    "acceptButton": "Restaurar",
    "cancelButton": "Cancelar"
  }
}
</i18n>
