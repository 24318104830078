import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";

// import { startOfDay, formatISO } from "date-fns";

// Types
import type {
  GetParams,
  GetAPIParams,
  GetAutomationsAsyncGenerator,
  AutomationsService,
  DuplicateAPIResponse,
  CopyAPIResponse,
  TagsAPIResponse,
  PauseAPIResponse,
  ActivateAPIResponse,
} from "./automations.types";
import type { SuccessAPIOffsetPaging } from "../../models/requestWrappers";
import type { Automations } from "@domain/automations";

export function useAutomations(): AutomationsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let getAutomationsGenerator: GetAutomationsAsyncGenerator | undefined = undefined;
  let apiParams: GetParams | undefined = undefined;

  return {
    async get(options) {
      if (!isEqual(apiParams, options) || !getAutomationsGenerator) {
        apiParams = options;
        getAutomationsGenerator = requestPaging<Automations, unknown, GetAPIParams>(
          {
            url: `/automations`,
            method: "get",
            urlParams: {
              q: apiParams?.search ? `*${apiParams?.search}*` : undefined,
              sortBy: apiParams?.sortBy ?? "id",
              sortDir: apiParams?.sortDir ?? "desc",
              "filters.timestamp.gtrel": options.gtrel,
              // "filters.timestamp.gt": apiParams.from
              //   ? formatISO(startOfDay(apiParams.from)).slice(0, 19) + "Z"
              //   : undefined,
              // "filters.timestamp.lt": apiParams.to ? formatISO(startOfDay(apiParams.to)).slice(0, 19) + "Z" : undefined,
              "filters.enabled": apiParams?.enabled !== undefined ? apiParams.enabled : undefined,
            },
          },
          { limit: apiParams?.limit ?? 20, offset: apiParams?.offset ?? 0 },
          true,
        );
      }

      if (!getAutomationsGenerator || getAutomationsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
        };

      const data = (await getAutomationsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<Automations>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
      };
    },
    async copy(params) {
      const res = await request<CopyAPIResponse>({
        url: `/automations/copy`,
        method: "post",
        data: {
          account: params.account,
          id: params.id,
        },
      });

      return res.data;
    },
    async tags() {
      const res = await request<TagsAPIResponse>({
        url: "/automations/tags",
        method: "get",
      });

      return res.data;
    },
    async activate(params) {
      const res = await request<ActivateAPIResponse>({
        url: `/automations/${params.id}`,
        method: "put",
        disableNotify400: true,
        data: {
          enabled: true,
        },
      });

      return res.data;
    },
    async duplicate(params) {
      const res = await request<DuplicateAPIResponse>({
        url: `/automations/${params.id}/duplicate`,
        method: "post",
      });

      return res.data;
    },
    async pause(params) {
      const res = await request<PauseAPIResponse>({
        url: `/automations/${params.id}`,
        method: "put",
        data: {
          enabled: false,
        },
      });

      return res.data;
    },
    async delete(params) {
      await request({
        url: `/automations/${params.id}`,
        method: "delete",
      });
    },
  };
}
