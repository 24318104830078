<template>
  <TransitionRoot :show="open" as="template" appear @after-leave="query = ''">
    <Dialog as="div" class="relative z-100" @close="close">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox v-slot="{ activeOption }: { activeOption: Contact }" @update:model-value="selectContact">
              <div class="border-b-1 relative border-gray-100">
                <SearchIcon
                  class="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                  :placeholder="t('getResult.placeholder')"
                  @change="inputChangeDebounced($event.target.value)"
                />
                <LoadingSpinner
                  v-if="loadingContacts"
                  class="pointer-events-none absolute right-4 top-3.5 mx-auto h-5 w-5 text-sky-500"
                  aria-hidden="true"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || contacts.length > 0 || recentContacts.length > 0"
                class="flex divide-x divide-gray-100"
                as="div"
                static
                hold
              >
                <div
                  v-if="(query === '' && recentContacts?.length) || contacts?.length"
                  ref="scrollElement"
                  :class="[
                    'max-h-96 min-w-0 flex-auto overflow-y-auto px-6',
                    !loadingContacts && query !== '' && 'pb-3',
                    activeOption && 'sm:h-96',
                    query !== '' && 'scroll-py-4 py-4',
                  ]"
                >
                  <h2
                    v-if="query === '' && recentContacts.length > 0"
                    class="mb-4 mt-2 text-xs font-semibold text-gray-500"
                  >
                    {{ t("result.title") }}
                  </h2>
                  <div hold class="-mx-2 mb-3 text-sm text-gray-700">
                    <ComboboxOption
                      v-for="contact in query === '' ? recentContacts : contacts"
                      v-slot="{ active }"
                      :key="contact.id"
                      :value="contact"
                      as="template"
                    >
                      <div
                        :class="[
                          'group flex cursor-pointer select-none items-center rounded-md p-2',
                          active && 'bg-gray-100 text-gray-900',
                        ]"
                      >
                        <div>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M50 25C50 31.6304 47.3661 37.9893 42.6777 42.6777C37.9893 47.3661 31.6304 50 25 50C18.3696 50 12.0107 47.3661 7.32233 42.6777C2.63392 37.9893 0 31.6304 0 25C0 18.3696 2.63392 12.0107 7.32233 7.32233C12.0107 2.63392 18.3696 0 25 0C31.6304 0 37.9893 2.63392 42.6777 7.32233C47.3661 12.0107 50 18.3696 50 25ZM31.25 15.625C31.25 17.2826 30.5915 18.8723 29.4194 20.0444C28.2473 21.2165 26.6576 21.875 25 21.875C23.3424 21.875 21.7527 21.2165 20.5806 20.0444C19.4085 18.8723 18.75 17.2826 18.75 15.625C18.75 13.9674 19.4085 12.3777 20.5806 11.2056C21.7527 10.0335 23.3424 9.375 25 9.375C26.6576 9.375 28.2473 10.0335 29.4194 11.2056C30.5915 12.3777 31.25 13.9674 31.25 15.625ZM25 28.125C22.0081 28.1244 19.079 28.9828 16.5607 30.5981C14.0424 32.2135 12.0408 34.5179 10.7937 37.2375C12.5521 39.2831 14.7321 40.9243 17.1842 42.0484C19.6363 43.1725 22.3025 43.753 25 43.75C27.6975 43.753 30.3637 43.1725 32.8158 42.0484C35.2679 40.9243 37.4479 39.2831 39.2062 37.2375C37.9592 34.5179 35.9576 32.2135 33.4393 30.5981C30.921 28.9828 27.9919 28.1244 25 28.125Z"
                              fill="#D4D4D4"
                            />
                          </svg>
                        </div>
                        <span class="ml-3 flex-auto truncate">
                          {{ contact.email }}
                        </span>
                        <ChevronRightIcon
                          v-if="active"
                          class="ml-3 h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </ComboboxOption>
                  </div>
                  <LoadingSpinner v-if="loadingScroll" class="mx-auto mt-4 h-6 w-6 text-sky-500" aria-hidden="true" />
                </div>

                <div v-if="activeOption" class="h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto">
                  <div class="flex gap-x-4 p-4 text-left">
                    <div>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M50 25C50 31.6304 47.3661 37.9893 42.6777 42.6777C37.9893 47.3661 31.6304 50 25 50C18.3696 50 12.0107 47.3661 7.32233 42.6777C2.63392 37.9893 0 31.6304 0 25C0 18.3696 2.63392 12.0107 7.32233 7.32233C12.0107 2.63392 18.3696 0 25 0C31.6304 0 37.9893 2.63392 42.6777 7.32233C47.3661 12.0107 50 18.3696 50 25ZM31.25 15.625C31.25 17.2826 30.5915 18.8723 29.4194 20.0444C28.2473 21.2165 26.6576 21.875 25 21.875C23.3424 21.875 21.7527 21.2165 20.5806 20.0444C19.4085 18.8723 18.75 17.2826 18.75 15.625C18.75 13.9674 19.4085 12.3777 20.5806 11.2056C21.7527 10.0335 23.3424 9.375 25 9.375C26.6576 9.375 28.2473 10.0335 29.4194 11.2056C30.5915 12.3777 31.25 13.9674 31.25 15.625ZM25 28.125C22.0081 28.1244 19.079 28.9828 16.5607 30.5981C14.0424 32.2135 12.0408 34.5179 10.7937 37.2375C12.5521 39.2831 14.7321 40.9243 17.1842 42.0484C19.6363 43.1725 22.3025 43.753 25 43.75C27.6975 43.753 30.3637 43.1725 32.8158 42.0484C35.2679 40.9243 37.4479 39.2831 39.2062 37.2375C37.9592 34.5179 35.9576 32.2135 33.4393 30.5981C30.921 28.9828 27.9919 28.1244 25 28.125Z"
                          fill="#D4D4D4"
                        />
                      </svg>
                    </div>
                    <div class="flex flex-col justify-center">
                      <h2 class="my-auto text-lg font-medium text-gray-600">
                        {{ activeOption.email }}
                      </h2>
                      <p v-if="activeOption.firstName && activeOption.lastName" class="text-sm text-gray-400">
                        {{ activeOption.firstName }} {{ activeOption.lastName }}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-auto flex-col justify-between overflow-y-auto p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <template
                        v-for="(value, index) in activeOption.customFields.filter((cf) => cf.value != '')"
                        :key="index"
                      >
                        <dt :id="'dt-' + index" class="col-end-1 font-semibold text-gray-600">{{ value.tag }}</dt>
                        <dd :id="'dd-' + index" class="truncate">
                          {{ value.value }}
                        </dd>
                      </template>
                    </dl>
                  </div>
                </div>
              </ComboboxOptions>

              <!-- Empty Case -->
              <div v-if="query === '' && recentContacts.length === 0" class="px-6 py-14 text-center text-sm sm:px-14">
                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 text-sm font-medium text-gray-500">{{ t("getResult.title") }}</p>
                <p class="mt-2 text-sm font-normal text-gray-400">{{ t("getResult.subtitle") }}</p>
              </div>

              <!-- No Result -->
              <div
                v-if="contacts.length === 0 && query !== '' && !loadingContacts"
                class="px-6 py-14 text-center text-sm sm:px-14"
              >
                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 text-sm font-medium text-gray-500">{{ t("noResult.title") }}</p>
                <p class="mt-2 text-sm font-normal text-gray-400">{{ t("noResult.subtitle") }}</p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { watchEffect, ref, watch } from "vue";

//Components
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

//Icons
import { ChevronRightIcon, SearchIcon } from "@heroicons/vue/solid";
import { UsersIcon } from "@heroicons/vue/outline";

//Utils
import { useDebounceFn } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { useInfiniteScroll } from "@vueuse/core";
import { isEmpty } from "lodash";

//Store
import { useUserConfigStore } from "@/vue/stores/userConfigStore/userConfigStore";

//API
import { useContacts } from "@/vue/api/modules/contacts/contacts";

//Type
import type { Contact, Contacts } from "@domain/contacts";

export type ContactType = Contact;
export type ContactsType = Contacts;

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

// Emits
const emit = defineEmits<{
  (e: "contactSelected", value: Contact): void;
  (e: "close"): void;
}>();

const close = () => {
  contacts.value = [];
  recentContacts.value = [];
  query.value = "";
  emit("close");
};

const selectContact = (contact: Contact) => {
  userConfigStore.recentContacts.add(contact.id);
  emit("contactSelected", contact);
  close();
};

const ContactsAPI = useContacts();
const userConfigStore = useUserConfigStore();

//Refs
const scrollElement = ref<HTMLElement>();

//Flags
const loadingContacts = ref(false);
const loadingScroll = ref(false);

//State
const contacts = ref<Contacts>([]);
const recentContacts = ref<Contacts>([]);
const query = ref("");

const fetchNextContacts = async (search?: string) => {
  loadingContacts.value = true;

  try {
    const value = await ContactsAPI.get({ search, limit: 10 });

    if (value.done) {
      return;
    }

    contacts.value = [...contacts.value, ...value.value];
  } finally {
    loadingContacts.value = false;
  }
};

const fetchBulkContacts = async () => {
  loadingContacts.value = true;
  const value = await ContactsAPI.bulkList({ ids: [...userConfigStore.recentContacts] });
  recentContacts.value = value.reverse();
  loadingContacts.value = false;
};

const inputChange = async (search: string) => {
  query.value = search.trim();
  contacts.value = [];

  if (query.value !== "") {
    await fetchNextContacts(query.value);
  }
};

const inputChangeDebounced = useDebounceFn(inputChange, 500, { maxWait: 5000 });

//Life cycle
watchEffect(async () => {
  if (!props.open) return;

  if (!isEmpty(userConfigStore.recentContacts)) await fetchBulkContacts();
});

watch(userConfigStore.recentContacts, async () => {
  await fetchBulkContacts();
});

useInfiniteScroll(
  scrollElement,
  async () => {
    loadingScroll.value = true;
    await fetchNextContacts();
    loadingScroll.value = false;
  },
  { distance: 10 },
);
</script>

<i18n lang="json">
{
  "es": {
    "getResult": {
      "title": "Ingresa el email del contacto",
      "subtitle": "Selecciona un contacto para previsualizar tu plantilla con su información.",
      "placeholder": "Buscar contacto"
    },
    "result": {
      "title": "Búsquedas recientes"
    },
    "noResult": {
      "title": "Lo sentimos, no pudimos encontrar nada.",
      "subtitle": "No hay resultados de búsqueda para el email ingresado. Inténtalo de nuevo."
    }
  },
  "pt": {
    "getResult": {
      "title": "Ingresse o email de contato",
      "subtitle": "Busca um contato para pré-visualizar seu template colocando o email.",
      "placeholder": "Buscar contato"
    },
    "result": {
      "title": "Pesquisas recentes"
    },
    "noResult": {
      "title": "Desculpa, não podemos encontrar nada",
      "subtitle": "Não há resultados de pesquisas para a informação introduzida. Tente novamente."
    }
  }
}
</i18n>
