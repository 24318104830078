import app from "@/app";
import Eddie from "@plugins/eddie/eddie.js";
import { cleanHTML } from "@/helpers/utils";

import tinyTpl from "@/modules/campaigns/templates/modals/tiny.hbs";

export default {
  // Returns the preview iframe URL
  getPreviewIframeUrl: function () {
    return "";
  },

  // Returns the preview iframe
  getPreviewIframe: function () {
    return document.getElementById("content-preview");
  },

  // Returns the title
  getTitle: function () {
    return "";
  },

  // Returns the JSON content
  getJsonContent: function () {
    return "";
  },

  // Returns the default HTML for Tiny MCE
  getHtmlContent: function () {
    return "";
  },

  // Returns the Tiny MCE selector
  getTinySelector: function () {
    return "";
  },

  // // Returns the Tiny MCE modal name
  // getTinyModalName: function () {
  //     return 'tiny';
  // },

  // Returns the endpoint to save the JSON
  saveContentEndpoint: function () {
    return "";
  },

  // Returns the saving content message
  getSavingMessage: function () {
    return "";
  },

  // After save hook
  afterContentSave: function () {
    this.renderIframePreview();
    return this;
  },

  // Opens the modal to edit the content with Tiny MCE
  openTinyModal: function () {
    var self = this,
      renderData = self.renderData(),
      height = $(window).height() - 210,
      modal = $(self.modal);

    renderData.html = self.getHtmlContent();

    // Open modal
    self.isDirty = false;
    self.openModal(tinyTpl, renderData);

    // Initialize Tiny MCE
    window.tinymce.init(
      $.extend(window.tinymceDefaults, {
        selector: self.getTinySelector(),
        branding: false,
        height: height,
        setup: function (editor) {
          // When the editor loads, hide the loader
          editor.on("LoadContent", function () {
            self.editor = editor;
            modal.find(".loading-box").addClass("hidden");
          });

          // When the content changes, set the dirty property
          editor.on("Change", function () {
            self.isDirty = true;
          });

          // Prevent navigation
          window.onbeforeunload = function () {
            return "";
          };
        },
      })
    );

    // If the content changed, alert when the modal closes
    modal.on("hide.bs.modal", function () {
      if (self.isDirty) {
        if (!confirm(window.lang.confirmLoseChanges)) {
          return false;
        }
      }
      modal.off("hide.bs.modal");
    });

    // Destroy Tiny MCE when modal closes
    modal.on("hidden.bs.modal", function () {
      window.onbeforeunload = null;
      self.editor.destroy();
      modal.off("hidden.bs.modal");
    });
  },

  // Saves an Tiny MCE editor content
  saveTinyContent: function (e) {
    var self = this,
      html = cleanHTML(self.editor.getContent());
    e.preventDefault();
    self.saveContent(html);
  },

  // Opens a modal to edit the content with Eddie
  openEddieModal: function () {
    var self = this,
      tutorial = true,
      title = self.getTitle() || "",
      content = self.getJsonContent() || false,
      useLowercaseTags = false,
      conditionalRegions = false;
    self.loading(true);

    // Get tutorial value from user session
    if (
      app.session.get("context") !== undefined &&
      app.session.get("context").eddieTutorial !== undefined
    ) {
      tutorial = parseInt(app.session.get("context").eddieTutorial);
    }

    // Hide tutorial when content is defined or the user is master
    if (content || app.session.get("userType") === "MASTER") {
      tutorial = false;
    }

    // Set content as dirty
    self.isDirty = true;

    // Ahora es todo es composer v2
    useLowercaseTags = true;
    conditionalRegions = true;

    const account = app.session.get("account");

    // Create a new Eddie editor
    window.editor = new Eddie({
      // Title
      title: title,

      // User's account ID
      accountId: app.session.get("account"),

      // User's token
      token: app.session.get("token"),

      // Tags format
      useLowercaseTags: useLowercaseTags,
      conditionalRegions: conditionalRegions,

      // Language
      lang: app.lang,

      // Default content
      content: content,

      // Show or hide tutorial
      tutorial: tutorial,

      allowFileUpload:
        ["acumar", "acumarinterna"].includes(account) ||
        (app.getTrial() && app.getTrial().inTrial) ||
        app.session.getPlanType() === "MONTHLY" ||
        app.session.getPlanType().includes("CHILD"),

      // Callback to be executed when done
      callback: function (editor, html, json) {
        self.saveContent(html, JSON.parse(json));
      },

      // Callback to be executed in intervals
      intervalCallback: function (editor, html, json) {
        self.saveContent(html, JSON.parse(json));
      },

      // Initial callback
      initCallback: function () {
        self.loading(false);
      },

      // Callback to be executed when cancelled
      cancelCallback: function (editor) {
        // Ask the user if he want to lose the changes
        if (editor.isDirty) {
          if (!confirm(window.lang.confirmLoseChanges)) {
            return false;
          }
        }

        // Turn off loader
        self.loading(false);

        // Close editor
        return true;
      },
    });

    // Return instance
    return self;
  },

  // Saves a content
  saveContent: function (html, json) {
    var self = this;

    html = html || false;
    json = json || false;

    // Return if no HTML content is defined
    if (!html) {
      return self;
    }

    // Show loading feedback
    if (window.editor !== undefined && window.editor.shouldClose) {
      self.displayMessage(self.getSavingMessage());
    }

    // Prevent view changes until the content is saved
    window.preventViewChange = true;

    // Save JSON and HTML
    if (json) {
      self.saving = { json: json, html: html };
      return self.updateContent(html, json);
    }

    // Or save HTML only
    self.saving = { html: html };
    return self.updateContent(html);
  },

  // Saves the content
  updateContent: function (html, json) {
    var self = this,
      endpoint = self.saveContentEndpoint(),
      data = { html: html };
    if (!endpoint) {
      return this.displayMessage(window.lang.endpointError, 3000, "error");
    }
    if (json) {
      data.json = JSON.stringify(json);
    }

    // Send content to API
    $.ajax({
      type: "PUT",
      data: JSON.stringify(data),
      url: endpoint,
      dataType: "json",
      contentType: "application/json",
      success: function (response) {
        // Turn off loader
        self.loading(false);

        // Enable navigation
        window.preventViewChange = false;

        // Close modal window
        self.isDirty = false;
        $(self.modal).modal("hide");

        // Close Eddie modal
        if (window.editor !== undefined && window.editor.shouldClose) {
          self.displayMessage(self.getSavedMessage());
          window.editor.close();
        }
        self.afterContentSave(response);
      },
    });
  },

  // Sets the preview HTML
  renderIframePreview: function () {
    var self = this,
      loadingBox = self.$(".loading-box"),
      noContentBox = self.$(".no-content"),
      previewBox = self.$("[data-role=preview]"),
      iframe = self.getPreviewIframe(),
      url = self.getPreviewIframeUrl();

    // Remove no content box
    noContentBox.addClass("hidden");

    // Hide iframe
    iframe.style.height = 0;

    // Show iframe container
    previewBox.removeClass("hidden");

    // Display loader
    loadingBox.removeClass("hidden");

    $("#content-error").hide();

    // Update iframe URL and resize iframe height
    $.ajax({
      type: "GET",
      url: url,
      dataType: "html",
      headers: {
        "Api-Prefix": false,
        "Account-Prefix": false,
      },
      success: (res) => {
        if (!iframe.contentDocument) return;

        iframe.contentDocument.open();
        iframe.contentDocument.write(res);
        iframe.contentDocument.close();

        //limpio links vacios
        const links = iframe.contentDocument.querySelectorAll("a[href=''], a[href='#']");
        links.forEach((link) => {
          link.removeAttribute("href");
        });

        self.resizeIframe();
        loadingBox.addClass("hidden");

        $(iframe).on("load", () => {
          $(iframe).off("load");
          self.resizeIframe();
        });
      },
      error: () => {
        loadingBox.addClass("hidden");
        $("#content-error").show();
      },
    });
  },

  // Resizes the preview iframe
  resizeIframe: function () {
    var self = this,
      iframe = self.getPreviewIframe();
    iframe.style.height = 0;
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
  },

  // Changes the preview
  changePreview: function (e) {
    var self = this,
      button = $(e.currentTarget),
      device = button.data("device"),
      preview = self.$(".content-preview");

    // Add active class
    button.addClass("active").siblings().removeClass("active");

    // Add or remove the mobile class
    if (device === "mobile") {
      preview.addClass("mobile");
    } else {
      preview.removeClass("mobile");
    }

    // Resize iframe
    setTimeout(() => self.resizeIframe(), 1000);
  },
};
