<template>
  <div class="space-y-6">
    <TextParagraph>{{ t("description") }}</TextParagraph>
    <div class="flex justify-between gap-4">
      <div class="flex-grow">
        <SearchInput v-model="search" :placeholder="t('searchPlaceholder')" />
      </div>
      <SimpleButton
        :disable="tagSelectedEditing"
        :class="tagSelectedEditing && 'pointer-events-none select-none opacity-50'"
        theme="secondary-light"
        @click="createNewTag"
      >
        <template #leading>
          <TagIcon class="h-5 w-5" />
        </template>
        {{ t("addTagButton") }}
      </SimpleButton>
    </div>
    <TagList
      v-show="tagsLoading || filteredTags.length > 0 || tagSelectedEditing"
      :tag-selected="tagSelectedEditing"
      :tags="filteredTags"
      :stats="stats"
      :loading="tagsLoading"
      :loading-accept="loadingAccept"
      :loading-delete="loadingDelete"
      class="max-h-[26rem] overflow-y-auto"
      @edit-tag="selectTagToEdit"
      @open-delete="stopEditing"
      @delete-tag="deleteTag"
      @cancelEditing="stopEditing"
      @create-tag="createTag"
      @update-tag="updateTag"
    />
    <p
      v-show="!tagsLoading && !tagSelectedEditing && filteredTags.length === 0 && search.length > 0"
      class="text-sm text-gray-500"
    >
      {{ t("emptySearch") }}
    </p>
    <p
      v-show="!tagsLoading && !tagSelectedEditing && filteredTags.length === 0 && search.length === 0"
      class="text-sm text-gray-500"
    >
      {{ t("noTags") }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { watchDebounced } from "@vueuse/core";

//Components
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import TagList from "./components/TagList.vue";
import SearchInput from "@atoms/SearchInput.vue";

// Icon
import { TagIcon } from "@heroicons/vue/solid";

// Utils
import { formatTextToSearch } from "@helpers/formatters";

// Services
import { useEmailTemplate } from "@api/modules/templates/templates";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// I18n
import { useI18n } from "vue-i18n";

// Types
import type { NewTag, Tag, Tags } from "@domain/tag";
import type { StatsByTag } from "@/vue/api/modules/templates/templates.types";

const trackingEventsService = useTrackingEvents();
const { t } = useI18n();

const templatesAPI = useEmailTemplate();

//Props
withDefaults(
  defineProps<{
    stats?: StatsByTag;
  }>(),
  {
    stats: undefined,
  }
);

const emit = defineEmits<{
  (e: "tagUpdated", tag: Tag): void;
  (e: "tagDeleted", tag: Tag): void;
  (e: "tagCreated", tag: Tag): void;
}>();

const search = ref("");

const tagSelectedEditing = ref<Tag | NewTag>();

const loadingAccept = ref(false);
const loadingDelete = ref(false);

const selectTagToEdit = (tag: Tag) => {
  tagSelectedEditing.value = tag;
};

const deleteTag = async (tagToDelete: Tag) => {
  loadingDelete.value = true;
  await templatesAPI.deleteTemplateTag(tagToDelete);
  tags.value = tags.value.filter((tag) => tag.id !== tagToDelete.id);
  loadingDelete.value = false;

  trackingEventsService.amplitude({
    name: "TPL_GALLERY_TAG_DELETE",
  });

  calculateFilteredTags();
  emit("tagDeleted", tagToDelete);
};

const createTag = async (tag: NewTag) => {
  loadingAccept.value = true;

  // trim, maxLength 50, remove extra spaces
  const formattedTag: NewTag = {
    ...tag,
    name: tag.name.replaceAll(/\s+/g, " ").trim().slice(0, 50),
  };

  const newTag = await templatesAPI.createTemplateTag(formattedTag);
  tags.value = [newTag, ...tags.value];
  loadingAccept.value = false;

  trackingEventsService.amplitude({
    name: "TPL_GALLERY_TAG_CREATE",
  });

  stopEditing();
  calculateFilteredTags();
  emit("tagCreated", newTag);
};

const updateTag = async (tag: Tag) => {
  loadingAccept.value = true;

  // trim, maxLength 50, remove extra spaces
  const formattedTag: Tag = {
    ...tag,
    name: tag.name.replaceAll(/\s+/g, " ").trim().slice(0, 50),
  };

  const tagFound = tags.value.find((existingTag) => existingTag.id === formattedTag.id);
  if (!tagFound) return;
  await templatesAPI.modifyTemplateTag(formattedTag);
  tagFound.color = formattedTag.color;
  tagFound.name = formattedTag.name;
  loadingAccept.value = false;

  trackingEventsService.amplitude({
    name: "TPL_GALLERY_TAG_EDIT",
  });

  stopEditing();
  emit("tagUpdated", tag);
};

const createNewTag = () => {
  tagSelectedEditing.value = { color: "#38bdf8", name: "" };
};

const stopEditing = () => {
  tagSelectedEditing.value = undefined;
};

const tags = ref<Tags>([]);
const tagsLoading = ref(false);

const filteredTags = ref<Tags>([]);

const calculateFilteredTags = () => {
  filteredTags.value = tags.value.filter((tag) =>
    formatTextToSearch(tag.name).includes(formatTextToSearch(search.value))
  );
};

watchDebounced(search, calculateFilteredTags, { debounce: 200 });

const getTags = async () => {
  tagsLoading.value = true;
  const tagList = await templatesAPI.getTemplateTags();
  tags.value = tagList;
  calculateFilteredTags();
  tagsLoading.value = false;
};

onMounted(async () => {
  await getTags();
});
</script>

<i18n lang="json">
{
  "es": {
    "description": "Utiliza etiquetas para organizar mejor tus plantillas. Puedes usar el buscador para encontrarlas más fácilmente.",
    "addTagButton": "Crear etiqueta",
    "searchPlaceholder": "Filtrar por nombre de etiqueta",
    "emptySearch": "No se encontraron etiquetas",
    "noTags": "Crea una etiqueta para comenzar"
  },
  "pt": {
    "description": "Utilize etiquetas para organizar melhor seus modelos. Você pode usar o mecanismo de busca para encontrá-los mais facilmente.",
    "addTagButton": "Criar etiqueta",
    "searchPlaceholder": "Filtrar por nome da etiqueta",
    "emptySearch": "Não foram encontradas etiquetas",
    "noTags": "Crie uma etiqueta para começar"
  }
}
</i18n>
