// Compares a value of a key within the objects of an array
export default function (key, value, collection, options) {

  if (!collection || !collection.length) {
    return options.inverse(this);
  }

  const found = collection.some((obj) => obj[key] === value);

  if (found) return options.fn(this);
  return options.inverse(this);
}
