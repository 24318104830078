import { useRequestsAPIV3 } from "@api/requests";

//Types
import { SuccessAPIBase } from "@api/models/requestWrappers";

import {
  RevalidateRequest,
  RevalidateResponse,
  SenderListResponse,
  CreateSenderRequest,
  CreateSenderResponse,
  VerifySenderRequest,
  VerifySenderResponse,
  ModifySenderRequest,
  ModifySenderResponse,
  RemoveSenderRequest,
  RemoveSenderResponse,
  SendInstructionsRequest,
  SendInstructionsResponse,
} from "./senders.types";

import { Sender, Senders } from "@/vue/types/Senders";

export const useSenders = () => {
  const request = useRequestsAPIV3();

  return {
    async revalidate({ sender_id }: RevalidateRequest): Promise<Sender> {
      const res = await request<SuccessAPIBase<RevalidateResponse>>({
        url: `/senders/${sender_id}/revalidate`,
        method: "post",
      });

      return new Sender(res.data.data);
    },
    async list(): Promise<Senders> {
      const res = await request<SuccessAPIBase<SenderListResponse>>({
        url: "/senders",
        method: "get",
      });

      return res.data.data.map((sender) => new Sender(sender));
    },
    async create(data: CreateSenderRequest): Promise<Sender> {
      const res = await request<SuccessAPIBase<CreateSenderResponse>>({
        url: "/senders",
        method: "post",
        data: data,
        disableNotifyErrors: true,
      });

      return new Sender(res.data.data);
    },
    async verify(data: VerifySenderRequest): Promise<void> {
      await request<SuccessAPIBase<VerifySenderResponse>>({
        url: "/senders/verify",
        method: "post",
        data: data,
      });
    },

    async modify(data: ModifySenderRequest): Promise<Sender> {
      const res = await request<SuccessAPIBase<ModifySenderResponse>>({
        url: `/senders/${data.id}`,
        method: "put",
        data: data,
      });

      return new Sender(res.data.data);
    },

    async remove({ id }: RemoveSenderRequest): Promise<void> {
      await request<SuccessAPIBase<RemoveSenderResponse>>({
        url: `/senders/${id}`,
        method: "delete",
      });
    },

    async sendInstructions(data: SendInstructionsRequest): Promise<void> {
      await request<SuccessAPIBase<SendInstructionsResponse>>({
        url: `/senders/${data.id}/sendinstructions`,
        method: "post",
        data: {
          to: data.to,
          cc: data.cc,
          subject: data.subject,
          message: data.message,
        },
      });
    },
  };
};
