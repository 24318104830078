<template>
  <PermissionsIconButton
    :permissions="permissions"
    :label="t('tagsEditor')"
    size="min"
    type="button"
    :class="['border border-gray-300', $attrs.class]"
    show-tooltip
    @click="openTagsManager"
  >
    <TagIcon class="mx-1 h-5 w-5 text-gray-500" />
  </PermissionsIconButton>
  <TagsManagerModal
    :open="tagsManagerIsOpen"
    :stats="stats"
    @tagUpdated="onTagUpdated"
    @tagDeleted="onTagDeleted"
    @tagCreated="onTagCreated"
    @close="closeTagsManager"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import PermissionsIconButton from "@organisms/Permissions/PermissionsIconButton";
import TagsManagerModal from "./TagsManagerModal.vue";

// Icons
import { TagIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";
import { StatsByTag } from "@/vue/api/modules/templates/templates.types";

// Domain
import type { Permissions } from "@domain/permissions";
import type { Tag } from "@domain/tag";

const { t } = useI18n();

//Props
withDefaults(
  defineProps<{
    stats?: StatsByTag;
    permissions?: Permissions;
  }>(),
  {
    stats: undefined,
    permissions: () => [],
  },
);

// Emits
const emit = defineEmits<{
  (e: "tagUpdated", tag: Tag): void;
  (e: "tagDeleted", tag: Tag): void;
  (e: "tagCreated", tag: Tag): void;
}>();

const tagsManagerIsOpen = ref(false);
const openTagsManager = () => {
  tagsManagerIsOpen.value = true;
};
const closeTagsManager = () => {
  tagsManagerIsOpen.value = false;
};

const onTagUpdated = (tag: Tag) => {
  emit("tagUpdated", tag);
};

const onTagDeleted = (tag: Tag) => {
  emit("tagDeleted", tag);
};

const onTagCreated = (tag: Tag) => {
  emit("tagCreated", tag);
};
</script>

<i18n lang="json">
{
  "es": {
    "tagsEditor": "Administrar etiquetas"
  },
  "pt": {
    "tagsEditor": "Gerir etiquetas"
  }
}
</i18n>
