<template>
  <p class="text-sm mb-4 text-gray-500 text-paragraph"><slot /></p>
</template>

<script>
export default {};
</script>

<style>
p.text-paragraph a {
  @apply font-normal underline text-sky-500;
}
p.text-paragraph a:hover {
  @apply text-sky-700;
}
</style>
