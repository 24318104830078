var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div class='clear-search' data-action='remove-filter' data-params='[\"q\"]'>\n              <i class='fa fa-close'></i>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='list-controls'>\n    <div class='check-all'>\n      <input id='check-all' type='checkbox' data-action='check-all'>\n      <label for='check-all' class='iconic'></label>\n    </div>\n    <div class='paging-total'>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"paging") : depth0)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":36}}}))
    + " "
    + alias2(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fields"))) && lookupProperty(stack1,"plural")), depth0))
    + "\n    </div>\n  </div>\n  <div class='content list scrollbar'>\n    <ul class='table'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":81,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n        <div class='check-cell "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":31},"end":{"line":50,"column":79}}})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":10},"end":{"line":53,"column":17}}})) != null ? stack1 : "")
    + "          <label for='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='iconic "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":50},"end":{"line":54,"column":88}}})) != null ? stack1 : "")
    + "'></label>\n        </div>\n        <div class='main-cell'>\n          <div class=\"name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":58,"column":12},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + "            <p>\n              "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang")),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"format") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":68,"column":14},"end":{"line":68,"column":56}}}))
    + "\n            </p>\n          </div>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"replacementTag") : stack1), depth0))
    + "\n          </strong>\n          <small>\n            "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fields"))) && lookupProperty(stack1,"tag")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":77,"column":12},"end":{"line":77,"column":47}}}))
    + "\n          </small>\n        </div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <input id='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='checkbox' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-action='check'>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "custom";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href='/fields/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class='title'>\n              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='content'>\n    <div class='padder'>\n      <div class='empty-state'>\n        <div class='row'>\n          <div class='col-md-5'>\n            <img class='illustration' src='/img/empty-states/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":61},"end":{"line":90,"column":101}}})) != null ? stack1 : "")
    + "'>\n          </div>\n          <div class='col-md-7'>\n            <div class='info'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":14},"end":{"line":97,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "search.svg";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsTitle")), depth0))
    + "</h2>\n              <p>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsLegend")), depth0))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='fields section'>\n  <div class='section-header'>\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fields"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":45}}}))
    + "\n    </h1>\n    <div class='filters'>\n      <ul class='nav nav-pills' data-role='filters'>\n        <li class='search'>\n          <form data-role='search' data-form='search'>\n            <button class='btn btn-default' type='button' data-action='open-search-form'>\n              <i class='filter-icon pi-filter-search'></i>\n            </button>\n            <input type='text' placeholder='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' class='form-control' name='q'\n              value='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "          </form>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class='toolbar' data-role='toolbar'>\n    <a data-navigate='fields/new' class='btn btn-primary' data-type='none' data-require-permission='fields:create'>\n      <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"fields"))) && lookupProperty(stack1,"create")), depth0))
    + "\n    </a>\n    <button class='btn btn-danger hide' data-type='one-plus' data-action='mass' data-method='destroy'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyM")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n      data-require-permission='fields:delete'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n    <div class='paging-selected' data-role='check-count'></div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":36,"column":2},"end":{"line":104,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});