<template>
  <BaseButton :disabled="disabled" @click="open = true">
    <CalendarIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
    <span>{{ i18n.campaigns.scheduleLaunch }}</span>
  </BaseButton>

  <Dialog :open="open" @close="open = false">
    <template #title>
      {{ i18n.campaigns.scheduleSmartCampaign }}
    </template>
    <template #content>
      <p class="text-sm mb-4 text-gray-500">
        {{ i18n.campaigns.scheduleSmartCampaignSelectDays }}
      </p>
      <DatePicker
        ref="datepicker"
        v-model="datesRange"
        is-range
        :model-config="modelConfig"
        :locale="lang"
        :columns="2"
        :step="1"
        :min-date="minDate"
        :max-date="maxDate"
        @dayclick="updateAllowedDates"
      ></DatePicker>
      <div class="mt-6">
        <p class="text-sm mb-4 text-gray-500">
          {{ i18n.campaigns.scheduleSmartCampaignSelectHours }}
        </p>
        <Slider v-model="slider.value" v-bind="slider" class="mt-14 mb-6" @update="sliderUpdate" />
      </div>
      <p class="text-sm text-gray-400">
        {{ i18n.campaigns.scheduleLocalTimeOf }}
        {{ timezone }} ({{ i18n.campaigns.scheduleCurrentTime }}: {{ localTime }})
      </p>
    </template>
    <template #buttons>
      <BaseButton :disabled="!isValidSelection" @click="schedule">
        {{ i18n.campaigns.schedule }}
      </BaseButton>
      <BaseButton type="secondary" @click="open = false">
        {{ i18n.cancel }}
      </BaseButton>
    </template>
  </Dialog>
</template>

<script>
import app from "@/app";
import { ref, computed, watch } from "vue";
import { add, max, set, format, isBefore, getHours, isToday } from "date-fns";
import Dialog from "@/components/vue/Dialog";
import BaseButton from "@/components/vue/BaseButton";
import { CalendarIcon } from "@heroicons/vue/outline";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import Slider from "@vueform/slider";
const moment = window.moment;

export default {
  components: {
    Dialog,
    BaseButton,
    CalendarIcon,
    DatePicker,
    Slider,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    view: {
      type: Object,
      required: true,
    },
  },

  emits: ["schedule"],

  setup(props) {
    const open = ref(false);
    const i18n = window.lang;
    const lang = app.lang;
    const timezone = window.config.timezone;
    const localTime = moment().tz(timezone).format("HH:mm");

    const maxDate = ref(null);
    const minDate = ref(new Date());
    const datepicker = ref(null);
    const datesRange = ref({
      start: null,
      end: null,
    });
    const formatHour = (hour) =>
      hour === 24 ? "23:59" : `${hour < 10 ? "0" : ""}${Math.round(hour)}:00`;

    const slider = ref({
      min: 0,
      max: 24,
      tooltips: true,
      merge: 20,
      value: [7, 22],
      format: function (value) {
        return formatHour(value);
      },
    });

    watch(datesRange, () => {
      if (isToday(datesRange.value.start) && isToday(datesRange.value.end)) {
        slider.value.min = getHours(new Date()) + 1;
        slider.value.value[0] = Math.max(slider.value.value[0], slider.value.min);
      } else {
        slider.value.min = 0;
      }
    });

    const modelConfig = ref({
      start: {
        timeAdjust: "00:00:00",
      },
      end: {
        timeAdjust: "23:59:59",
      },
    });

    const updateAllowedDates = () => {
      if (datepicker.value.dragValue) {
        minDate.value = max([add(datepicker.value.dragValue.start, { days: -6 }), new Date()]);
        maxDate.value = add(datepicker.value.dragValue.start, { days: 6 });
      } else {
        maxDate.value = null;
        minDate.value = new Date();
      }
    };

    const minDistance = 4;
    const sliderUpdate = () => {
      if (slider.value.value[1] - slider.value.value[0] < minDistance) {
        if (slider.value.value[1] === 24) slider.value.value[0] = 24 - minDistance;
        else slider.value.value[1] = slider.value.value[0] + minDistance;
      }
    };

    const isValidSelection = computed(() => {
      return (
        datesRange.value.start !== null &&
        datesRange.value.end !== null &&
        datepicker.value?.dragValue === null
      );
    });

    const schedule = () => {
      const fromHour = Math.round(slider.value.value[0]);
      const toHour = Math.round(slider.value.value[1]);
      const isoFormat = "yyyy-MM-dd'T'HH:mm:ssxxx";

      let startDate = set(datesRange.value.start, {
        hours: fromHour,
        minutes: 0,
        seconds: 0,
      });

      const today = new Date();
      if (isBefore(startDate, today)) {
        startDate = set(startDate, {
          hours: getHours(today),
          minutes: 0,
          seconds: 0,
        });
      }

      let endDate = set(datesRange.value.end, {
        hours: toHour,
        minutes: 0,
        seconds: 0,
      });
      if (isBefore(endDate, today)) {
        endDate = set(endDate, {
          hours: getHours(today) + 1,
          minutes: 0,
          seconds: 0,
        });
      }

      const options = {
        from: format(startDate, isoFormat, {
          timeZone: timezone,
        }),
        to: format(endDate, isoFormat, {
          timeZone: timezone,
        }),
        fromTime: formatHour(fromHour),
        toTime: formatHour(toHour),
        plusTime: 1,
      };

      props.view.launchSmartCampaign(options);
      open.value = false;
    };

    return {
      datepicker,
      open,
      i18n,
      lang,
      datesRange,
      modelConfig,
      updateAllowedDates,
      maxDate,
      minDate,
      slider,
      timezone,
      sliderUpdate,
      isValidSelection,
      localTime,
      schedule,
    };
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
.slider-tooltip {
  @apply border-sky-700 bg-sky-700;
}
.slider-connect {
  @apply bg-sky-700;
}
</style>
