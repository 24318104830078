<template>
  <ModalDialog :open="open" :help-id="helpId" @close="close">
    <template #title> {{ t("fontSelectorTitle") }} </template>
    <div class="w-full pr-6 h-[75vh] space-y-10 overflow-y-auto max-h-full">
      <div>
        <h2 class="m-0 text-base font-semibold leading-6 text-gray-600">{{ t("webFonts.title") }}</h2>
        <TextParagraph>
          <i18n-t keypath="webFonts.description">
            <template #link>
              <a href="#" @click.prevent="gotoHelp(6979913)">{{ t("webFonts.helpcenter") }}</a>
            </template>
          </i18n-t>
        </TextParagraph>
        <div class="mt-6 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-6">
          <NewFontCard @click="openFontEditor" />
          <FontCard
            v-for="font in fonts"
            :key="font.id"
            :font="font"
            allow-edit
            @edit="() => editFont(font)"
            @disable="() => onToggleFont(font)"
          />
        </div>
      </div>
      <div class="h-[60%]">
        <h2 class="m-0 text-base font-semibold leading-6 text-gray-600">{{ t("safeFonts.title") }}</h2>
        <TextParagraph>
          {{ t("safeFonts.description") }}
        </TextParagraph>
        <div
          class="mt-6 pr-2 grid items-center sm:items-start sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-6"
        >
          <FontCard
            v-for="(font, index) in mergeDefaultFonts"
            :key="index"
            :font="font"
            @edit="() => editFont(font)"
            @disable="() => onToggleDefaultFont(font)"
          />
        </div>
      </div>
    </div>
    <FontEditorModal
      :open="fontEditorIsOpen"
      :font="fontToEdit"
      :fonts="fonts"
      :edit-mode="fontEditorEditMode"
      @update:open="closeFontEditor"
      @create="onCreateFont"
      @edit="onEditFont"
      @delete="openDeleteFontAlertModal"
    />
    <ConfirmationModal
      id="autoSaveAlertModal"
      v-bind="deleteFontAlertModal"
      @accept="onDeleteFont"
      @cancel="closeDeleteFontAlertModal"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { computed, watch, onMounted, reactive, ref } from "vue";

import { useDefaultFonts } from "@/vue/composables/fonts";

// Components
import TextParagraph from "@atoms/TextParagraph.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import FontCard from "./components/FontCard.vue";
import NewFontCard from "./components/NewFontCard.vue";
import FontEditorModal from "./components/FontEditorModal.vue";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";

//Utils
import { useI18n } from "vue-i18n";
import { localesLanguages } from "@locales/i18n";

//API
import { useAccountConfig } from "@api/modules/account";

// Types
import type { FontWithAlter, Fonts } from "@/vue/types/fonts";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";

const { t, locale } = useI18n();
const accountConfig = useAccountConfig();
const defaultFonts = useDefaultFonts();

//Props
const props = withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:open", value: boolean): void;
  (e: "change"): void;
}>();

const change = () => emit("change");
const close = () => {
  emit("update:open", false);
  changesMade.value && change();
  changesMade.value = false;
};

// trackChange
const changesMade = ref(false);

// Default Fonts
const accountDefaultFonts = ref<Fonts>([]);

const mergeDefaultFonts = ref<Fonts>([]);

// Account fonts
const loading = ref(false);
const fonts = ref<Fonts>([]);
const getOrderedFonts = (fonts: Fonts) =>
  fonts.sort((fontA, fontB) => {
    if (fontA.disabled && fontB.disabled) return 0;
    if (fontA.disabled && !fontB.disabled) return 1;
    if (!fontA.disabled && fontB.disabled) return -1;
    return 0;
  });

const fetchAccountFonts = async () => {
  loading.value = true;
  const accountFonts = await accountConfig.get();

  fonts.value = getOrderedFonts(accountFonts.fonts ?? []);
  accountDefaultFonts.value = accountFonts.defaultFonts ?? [];

  mergeDefaultFonts.value = getOrderedFonts(
    defaultFonts.map((defaultFont) => {
      const fontFound = accountDefaultFonts.value.find((font) => font.id === defaultFont.id);

      return !fontFound ? defaultFont : { ...fontFound, disabled: true };
    })
  );

  loading.value = false;
};

watch(
  () => props.open,
  () => {
    if (!props.open) return;
    fonts.value = getOrderedFonts(fonts.value);
    mergeDefaultFonts.value = getOrderedFonts(mergeDefaultFonts.value);
  }
);

onMounted(() => {
  fetchAccountFonts();
});

// Font Editor
const fontEditorIsOpen = ref(false);
const fontEditorEditMode = ref(false);
const fontToEdit = ref<FontWithAlter>();

const openFontEditor = () => {
  fontToEdit.value = undefined;
  fontEditorIsOpen.value = true;
};
const editFont = (font: FontWithAlter) => {
  fontToEdit.value = font;
  fontEditorIsOpen.value = true;
  fontEditorEditMode.value = true;
};
const closeFontEditor = () => {
  fontEditorIsOpen.value = false;
  fontEditorEditMode.value = false;
};

// Create font
const onCreateFont = async (font: FontWithAlter) => {
  closeFontEditor();
  fonts.value.push(font);
  await accountConfig.put({
    data: {
      fonts: fonts.value,
    },
  });
  changesMade.value = true;
};

// Edit font
const onEditFont = async (font: FontWithAlter) => {
  closeFontEditor();
  const foundFont = fonts.value.find((accountFont) => accountFont.id === font.id);
  if (!foundFont) return;
  foundFont.alterFont = font.alterFont;

  await accountConfig.put({
    data: {
      fonts: fonts.value,
    },
  });
  changesMade.value = true;
  fontToEdit.value = undefined;
};

// Toggle font
const onToggleFont = async (font: FontWithAlter) => {
  const foundFont = fonts.value.find((accountFont) => accountFont.id === font.id);
  if (!foundFont) return;
  foundFont.disabled = !foundFont.disabled;

  await accountConfig.put({
    data: {
      fonts: fonts.value,
    },
  });
  changesMade.value = true;
};

const onToggleDefaultFont = async (font: FontWithAlter) => {
  const fontIndex = accountDefaultFonts.value.findIndex((accountFont) => accountFont.id === font.id);
  const fontLocal = mergeDefaultFonts.value?.find((accountFont) => accountFont.id === font.id);

  if (fontLocal) {
    fontLocal.disabled = !fontLocal.disabled;
  }

  if (fontIndex === -1) {
    accountDefaultFonts.value.push(font);
  } else {
    accountDefaultFonts.value.splice(fontIndex, 1);
  }
  await accountConfig.put({
    data: {
      defaultFonts: accountDefaultFonts.value,
    },
  });
  changesMade.value = true;
};

// Delete font
const deleteFontAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteFontAlert.confirmationTitle"),
  message: "",
  acceptText: t("deleteFontAlert.confirmButton"),
  cancelText: t("deleteFontAlert.cancelButton"),
  severity: "critical",
});
const closeDeleteFontAlertModal = () => {
  deleteFontAlertModal.open = false;
  fontToEdit.value = undefined;
};
const openDeleteFontAlertModal = () => {
  closeFontEditor();
  deleteFontAlertModal.message = t("deleteFontAlert.confirmationMessage", { fontName: `"${fontToEdit.value?.name}"` });
  deleteFontAlertModal.open = true;
};

const onDeleteFont = async () => {
  closeFontEditor();

  const font = fontToEdit.value;
  closeDeleteFontAlertModal();
  if (!font) return;

  const fontIndex = fonts.value.findIndex((accountFont) => accountFont.id === font.id);
  if (fontIndex === -1) return;

  fonts.value.splice(fontIndex, 1);

  await accountConfig.put({
    data: {
      fonts: fonts.value,
    },
  });
  changesMade.value = true;
};

// Links
const links = {
  es: {
    helpId: "6979913",
  },
  pt: {
    helpId: "6979913",
  },
};

const lang = computed(() => locale.value?.slice(0, 2) as localesLanguages);
const helpId = computed(() => links[lang.value]?.helpId);
const gotoHelp = (id) => {
  window.Intercom("showArticle", id);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "fontSelectorTitle": "Ajustes de fuentes",
    "safeFonts": {
      "title": "Fuentes seguras",
      "description": "Estas fuentes pueden utilizarse sin problemas en la mayoría de los lectores de correo."
    },
    "webFonts": {
      "title": "Fuentes personalizadas",
      "description": "Estas fuentes sólo se visualizarán en ciertos lectores de correo, como Apple Mail. Para conocer más detalles visita el {link}.",
      "helpcenter": "centro de ayuda"
    },
    "deleteFontAlert": {
      "confirmationTitle": "¿Deseas eliminar esta fuente?",
      "confirmationMessage": "Se eliminará {fontName} de tus fuentes personalizadas y tendrás que agregarla nuevamente para utilizarla. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    }
  },
  "pt": {
    "fontSelectorTitle": "Ajustes de fontes",
    "safeFonts": {
      "title": "Fontes seguras",
      "description": "Essas fontes podem ser utilizadas sem problemas na maioria dos leitores de e-mails."
    },
    "webFonts": {
      "title": "Fontes personalizadas",
      "description": "As fontes personalizadas só serão visualizadas em certos leitores de e-mails, como Apple Mail. Para saber mais detalhes visite o {link}.",
      "helpcenter": "centro de ajuda"
    },
    "deleteFontAlert": {
      "confirmationTitle": "Deseja excluir essa fonte?",
      "confirmationMessage": "A fonte {fontName} será excluída de suas fontes personalizadas e você terá que adicioná-la novamente para usá-la. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    }
  }
}
</i18n>
