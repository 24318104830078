import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";
import { Currencies } from "@/vue/locales/i18n";
import { DashboardState, TemplatesEditorConfig } from "@/vue/stores/userConfigStore/userConfigStore";

export interface UserConfig {
  currency?: Currencies | "" | undefined;
  use_custom_currency?: boolean | undefined;
  monthly_cost?: number | undefined;
  dashboardState?: DashboardState;
  recentContacts?: Array<number>;
  templatesEditorConfig?: TemplatesEditorConfig;
}

export interface IUserAccountConfig {
  [key: string]: UserConfig | undefined;
}

export function useUserConfig(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function get({
    account,
    loadingRef,
  }: {
    account?: string;
    loadingRef?: Ref<boolean>;
  } = {}): Promise<IUserAccountConfig> {
    const res = await request({
      url: "/userconfig",
      account: account,
      method: "get",
      disableNotifyErrors: true,
      loadingRef,
    });

    return res.data?.data as IUserAccountConfig;
  }

  async function put({
    account,
    data,
    loadingRef,
  }: {
    account?: string;
    data?: IUserAccountConfig;
    loadingRef?: Ref<boolean>;
  }): Promise<IUserAccountConfig> {
    const res = await request({
      url: "/userconfig",
      account: account,
      method: "put",
      data: data,
      loadingRef,
    });

    return res.data?.data as IUserAccountConfig;
  }

  return { get, put };
}
