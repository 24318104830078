<template>
  <div class="relative mt-6">
    <div class="relative">
      <InputLabel :for="id">{{ label }}</InputLabel>
      <SimpleInput
        :id="id"
        ref="inputRef"
        v-bind="$attrs"
        :class="[loading && 'pr-9', 'mt-1']"
        :model-value="modelValue"
        :placeholder="placeholder"
        :has-error="!!error"
        :loading="loading"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :type="showPassword ? 'text' : 'password'"
        :aria-describedby="error ? `${id}-error` : `${id}-hint`"
        @update:model-value="updateModelValue"
        @blur="blur"
        @focus="focus"
      />
      <div class="absolute bottom-[30px] ml-[2px] h-1 w-[calc(100%-4px)] rounded-b-full bg-gray-200">
        <div class="h-1 rounded-b-full transition-all duration-500" :style="styles"></div>
      </div>
      <InputHint v-if="!error" :id="`${id}-hint`">
        <span>
          {{ t("hint") }}
        </span>
        <InfoIcon :content="t('passwordTips')" placement="right" class="ml-1" />
      </InputHint>
      <InputError v-if="error" :id="`${id}-error`">
        <span>
          {{ error }}
        </span>
        <InfoIcon :content="t('passwordTips')" placement="right" class="ml-1" />
      </InputError>
    </div>
    <button
      v-show="!loading && !error"
      type="button"
      class="absolute right-3 top-8 text-gray-400 focus:outline-sky-400"
      @click="toggleShowPassword"
    >
      <EyeIcon class="h-5 w-5" v-show="showPassword" />
      <EyeOffIcon class="h-5 w-5" v-show="!showPassword" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref, watch } from "vue";

// Components
import InputLabel from "@atoms/InputLabel.vue";
import InputHint from "@atoms/InputHint.vue";
import InputError from "@atoms/InputError.vue";
import SimpleInput from "@atoms/SimpleInput.vue";
import InfoIcon from "@atoms/InfoIcon.vue";

// Icons
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";

// Utils
import { v4 as uuid } from "uuid";
import { analyzePasswordStrength } from "@helpers/validators";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    modelValue: string;
    id?: string;
    label?: string;
    placeholder?: string;
    autocomplete?: string;
    error?: string;
    loading?: boolean;
    disabled?: boolean;
  }>(),
  {
    id: uuid(),
    label: undefined,
    placeholder: undefined,
    autocomplete: undefined,
    error: undefined,
    loading: false,
    disabled: false,
  },
);

const emit = defineEmits<{
  "update:modelValue": [string];
  blur: [Event];
  focus: [Event];
}>();

const updateModelValue = (value: string) => {
  emit("update:modelValue", value);
};

const blur = (value: Event) => {
  emit("blur", value);
};

const focus = (value: Event) => {
  emit("focus", value);
};

const styles = reactive({
  width: "0%",
  backgroundColor: "red",
});

watch([() => props.modelValue], () => {
  if (props.modelValue.length === 0) {
    styles.width = "0%";
    styles.backgroundColor = "#ef4444";
    return;
  }

  const score: 0 | 1 | 2 | 3 | 4 = analyzePasswordStrength(props.modelValue).score;
  const scoreWidths: Record<0 | 1 | 2 | 3 | 4, string> = {
    0: "10%",
    1: "30%",
    2: "50%",
    3: "70%",
    4: "100%",
  };

  const scoreColors: Record<0 | 1 | 2 | 3 | 4, string> = {
    0: "#ef4444",
    1: "#f97316",
    2: "#f59e0b",
    3: "#34d399",
    4: "#10b981",
  };

  styles.width = scoreWidths[score];
  styles.backgroundColor = scoreColors[score];
});

const showPassword = ref(false);
const toggleShowPassword = () => (showPassword.value = !showPassword.value);
</script>

<i18n lang="json">
{
  "es": {
    "hint": "Ingresa una contraseña segura",
    "passwordTips": "Utiliza mayúsculas y minúsculas.<br/>Incluye letras, números y símbolos.<br/>Ingresa al menos 9 caracteres.<br/>No uses palabras o patrones comunes.<br/>No incluyas datos personales."
  },
  "pt": {
    "hint": "Insira uma senha segura",
    "passwordTips": "Use letras maiúsculas e minúsculas.<br/>Inclua letras, números e símbolos.<br/>Insira pelo menos 9 caracteres.<br/>Não use palavras ou padrões comuns.<br/>Não inclua dados pessoais."
  }
}
</i18n>
