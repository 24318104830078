<template>
  <div class="mx-auto flex h-full w-full flex-col items-center justify-center space-y-4 text-center lg:w-[62rem]">
    <SearchCircleIcon class="h-16 w-16 stroke-1 text-sky-400" />
    <h1 class="text-3xl font-bold">{{ t("title") }}</h1>
    <p class="mx-auto w-[40%] text-center text-base font-normal text-gray-600">
      {{ t("message") }}
    </p>
    <button class="text-sm font-semibold text-sky-400" @click="cleanSearch">{{ t("cleanSearch") }}</button>
  </div>
</template>

<script lang="ts" setup>
// Icon
import { SearchCircleIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  cleanSearch: [];
}>();

const cleanSearch = () => {
  emit("cleanSearch");
};
</script>
<i18n lang="jsonc">
{
  "es": {
    "title": "La búsqueda no obtuvo resultados",
    "message": "Intenta usando un filtro más amplio o modifica los criterios de búsqueda.",
    "cleanSearch": "Limpiar la búsqueda"
  },
  "pt": {
    "title": "A pesquisa não obteve resultados",
    "message": "Tente usar um filtro mais abrangente ou modificar os critérios de pesquisa.",
    "cleanSearch": "Limpar a pesquisa"
  }
}
</i18n>
