<template>
  <ModalDialog without-overflow show-close-button :open="isOpen" :title="t('header.title')" @close="emitClose">
    <h3 class="mb-4 text-base font-medium text-gray-700">{{ t(`subtitle.${feature}`) }}</h3>
    <div class="flex gap-8">
      <div class="grid grid-cols-2 justify-items-start gap-6">
        <IconBullet
          v-for="bullet in features"
          :key="bullet.key"
          :icon="CheckCircleIcon"
          :title="t('featureTitle.' + bullet.key)"
          color="yellow"
          :tooltip="t('featureTooltip.' + bullet.key)"
        />
      </div>
      <div class="w-72 rounded-md bg-gray-100 p-8">
        <LockOpenIcon class="mb-2 h-6 w-6 text-yellow-400" />
        <h4 class="mb-2 text-base font-medium text-gray-700">{{ t(`grayBox.title.${feature}`) }}</h4>
        <p class="mb-6 text-sm text-gray-400">{{ t("grayBox.subtitle") }}</p>
        <div class="mb-6">
          <SimpleButton theme="yellow" class="w-full" @click="redirectToPlan">
            <template #leading><ArrowCircleUpIcon /></template> {{ t("buttons.getPlan") }}
          </SimpleButton>
        </div>
        <p class="mb-4 text-sm text-gray-400">
          {{ t("grayBox.trial") }}
        </p>
        <div>
          <SimpleButton theme="white" class="w-full" @click.prevent="contactSupport">
            <template #leading><ChatIcon /></template> {{ t("buttons.talkToUs") }}
          </SimpleButton>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import SimpleButton from "@atoms/SimpleButton.vue";
import IconBullet from "@atoms/IconBullet.vue";
import ModalDialog from "@molecules/ModalDialog.vue";
import { CheckCircleIcon, LockOpenIcon, ArrowCircleUpIcon, ChatIcon } from "@heroicons/vue/outline";
import Intercom from "@helpers/intercom";

const { t } = useI18n();

//Props
withDefaults(
  defineProps<{
    isOpen: boolean;
    feature?: "rfm" | "automations" | "campaigns" | "heatmap";
  }>(),
  {
    isOpen: false,
    feature: "campaigns",
  }
);

interface Feature {
  key: string;
}

const features: Array<Feature> = [
  {
    key: "automationAccess",
  },
  {
    key: "automationCustom",
  },
  {
    key: "testABCampaign",
  },
  {
    key: "smartCampaign",
  },
  {
    key: "lifecycleRFM",
  },
  {
    key: "attachedFiles",
  },
  {
    key: "suggestionCrossSelling",
  },
  {
    key: "generationCoupon",
  },
  {
    key: "heatmapCampaigns",
  },
  {
    key: "savedBlocks",
  },
  {
    key: "conditionalRules",
  },
  // {
  //   key: "stockImage",
  // },
  {
    key: "customFonts",
  },
];

// Emits
const emit = defineEmits<{
  (e: "close"): void;
}>();

const emitClose = () => {
  emit("close");
};

const contactSupport = () => {
  Intercom.showNewMessage(t("helpMessage.wantToTalk"));
};

const redirectToPlan = () => {
  window.location.href = "/plan?product_tour_id=306625";
};
</script>

<i18n lang="json">
{
  "es": {
    "header": {
      "title": "Activa tu suscripción mensual"
    },
    "subtitle": {
      "automations": "Desbloquea este Automation y accede a todos estos beneficios.",
      "campaigns": "Desbloquea nuevos tipos de campañas y accede a todos estos beneficios.",
      "rfm": "Desbloquea segmentación automática de compradores y accede a todos estos beneficios.",
      "heatmap": "Desbloquea el mapa de calor de clicks y accede a todos estos beneficios."
    },
    "grayBox": {
      "title": {
        "automations": "Contrata un plan mensual",
        "campaigns": "Contrata un plan mensual",
        "rfm": "Contrata un plan mensual",
        "heatmap": "Contrata un plan mensual"
      },
      "subtitle": "Accede a nuevas funcionalidades y beneficios para tu cuenta.",
      "trial": "Puedes coordinar una demo y probar todas las funcionalidades antes de contratar."
    },
    "featureTitle": {
      "automationAccess": "Automations avanzados",
      "automationCustom": "Automations personalizados",
      "smartCampaign": "Campañas Inteligentes",
      "testABCampaign": "Campañas Test A/B",
      "suggestionCrossSelling": "Sugerencias y venta cruzada",
      "generationCoupon": "Giftcards y cupones",
      "conditionalRules": "Contenido condicional",
      "savedBlocks": "Bloques guardados",
      "stockImage": "Imágenes de stock",
      "customFonts": "Tipografias personalizadas",
      "attachedFiles": "Upload y envío de archivos adjuntos",
      "autoSegment": "Segmentos automáticos",
      "lifecycleRFM": "Ciclo de vida de compradores",
      "heatmapCampaigns": "Mapa de calor de clicks"
    },
    "featureTooltip": {
      "automationAccess": "Accede a una gran variedad de automations prediseñados, especialmente pensados para tu tienda online.",
      "automationCustom": "Cuenta con la ayuda de nuestro equipo para adaptar hasta 3 automations a tus necesidades específicas.",
      "smartCampaign": "Selecciona un periodo de tiempo para enviarle a cada contacto en el día y horario que suele tener mayor actividad.",
      "testABCampaign": "Define hasta cuatro variantes de asuntos y analiza cuál obtiene los mejores resultados.",
      "suggestionCrossSelling": "Ofrece productos relacionados basadas en la actividad de compra histórica en tu tienda, novedades o más vendidos.",
      "generationCoupon": "Envía cupones de descuento para recuperar ventas y ofrece giftcards en tu tienda. Disponible según integración.",
      "conditionalRules": "Crea reglas para mostrar contenido diferenciado según los intereses y atributos de tus contactos.",
      "savedBlocks": "Almacena tus propios bloques para utilizarlos fácilmente en otras plantillas.",
      "stockImage": "Busca las imágenes que necesites para crear un diseño único utilizando repositorios de imágenes de uso libre.",
      "customFonts": "Incluye tipografías adicionales en tus emails desde Google Fonts u otras fuentes.",
      "attachedFiles": "Inlcuye fácilmente links de descarga de archivos (pdf, xls, etc) en tus emails, subiéndolos en forma directa a nuestros servidores.",
      "autoSegment": "Creamos por ti las listas automáticos que necesites para segmentar tus contactos y comunicar de una forma más eficiente.",
      "lifecycleRFM": "Conoce la salud de los compradores de tu tienda. Utiliza la segmentación automática para enviar mensajes más personalizados.",
      "heatmapCampaigns": "Analiza cuáles son los enlaces que más interés generan y qué ubicaciones dentro del contenido funcionan mejor."
    },
    "helpMessage": {
      "wantToTalk": "Hola! Quisiera coordinar una demo y probar todas las funcionalidades."
    },
    "buttons": {
      "getPlan": "Contratar plan",
      "talkToUs": "Habla con nosotros"
    }
  },
  "pt": {
    "header": {
      "title": "Ative sua inscrição mensal"
    },
    "subtitle": {
      "automations": "Desbloqueie esse Automation e tenha acesso a todos esses benefícios.",
      "campaigns": "Desbloqueie novos tipos de campanhas e tenha acesso a todos esses benefícios.",
      "rfm": "Desbloquea nuevas segmentaciones de tus clientes y accede a todos estos beneficios.",
      "heatmap": "Desbloquea el mapa de calor de clicks y accede a todos estos beneficios."
    },
    "grayBox": {
      "title": {
        "automations": "Contrate um plano mensal",
        "campaigns": "Contrate um plano mensal",
        "rfm": "Contrate um plano mensal",
        "heatmap": "Contrata un plan mensual"
      },
      "subtitle": "Acesse a novas funcionalidades e benefícios para sua conta.",
      "trial": "Você pode agendar uma Demo e provar todas as funcionalidades antes de contratar."
    },
    "featureTitle": {
      "automationAccess": "Automations avançados",
      "automationCustom": "Automations personalizados",
      "smartCampaign": "Campanhas Inteligentes",
      "testABCampaign": "Campanhas Test A/B",
      "suggestionCrossSelling": "Sugestões e venda cruzada",
      "generationCoupon": "Giftcards e cupons",
      "conditionalRules": "Conteúdo condicional",
      "savedBlocks": "Blocos salvos",
      "stockImage": "Imagens stock",
      "customFonts": "Tipografias personalizadas",
      "attachedFiles": "Upload e envio de arquivos anexados",
      "autoSegment": "Segmentos automáticos",
      "lifecycleRFM": "Ciclo de vida dos compradores",
      "heatmapCampaigns": "Mapa de calor de cliques"
    },
    "featureTooltip": {
      "automationAccess": "Acesse uma grande variedade de automations pré-desenhados, pensados especialmente para sua loja online.",
      "automationCustom": "Conte com a ajuda da nossa equipe para adaptar até 3 automations às suas necessidades específicas.",
      "smartCampaign": "Selecione um período de tempo para enviar a cada contato no dia e horário que costumam ter maior atividade.",
      "testABCampaign": "Defina até quatro assuntos diferentes e analise qual obtém os melhores resultados.",
      "suggestionCrossSelling": "Ofereça produtos relacionados baseados na atividade de compra histórica na sua loja, novidades ou mais vendidos.",
      "generationCoupon": "Envie cupons de desconto para recuperar vendas e ofereça Giftcards na sua loja. Disponível segundo integração. ",
      "conditionalRules": "Crie regras para mostrar um conteúdo diferente segundo os interesses e atributos de seus contatos.",
      "savedBlocks": "Armazene seus próprios blocos para utilizá-los facilmente em outros templates.",
      "stockImage": "Procure as imagens que precisa para criar um design único utilizando repositórios de imagens de uso livre.",
      "customFonts": "Inclua tipografias adicionais em seus emails desde Google Fonts ou outras fontes.",
      "attachedFiles": "Coloque facilmente links de download de arquivos (pdf, xls, etc) em seus emails, subindo diretamente a nossos servidores.",
      "autoSegment": "Criamos para você as listas automáticas que precisar para segmentar seus contatos e comunicar de uma forma mais eficiente.",
      "lifecycleRFM": "Conheça o Ciclo de Vida dos seus compradores. Utilize a segmentação automática para enviar mensagens mais personalizadas.",
      "heatmapCampaigns": "Analise quais são os links que geram mais interesse e quais localizações dentro do conteúdo funcionam melhor."
    },
    "helpMessage": {
      "wantToTalk": "Olá! Gostaria de agendar uma Demo e provar todas as funcionalidades."
    },
    "buttons": {
      "getPlan": "Contratar plano",
      "talkToUs": "Fale conosco"
    }
  }
}
</i18n>
