// Applications
// import { INTERESTS_ATTRIBUTEOPTION_ID } from "../conditions/conditions.const";

// Types
import type { FreeMarkerService } from "./freeMarker.ports";
import type { Attribute, ComparisonOperator } from "@/vue/types/predicates";

export const useFreeMarkerService = (): FreeMarkerService => {
  const freeMarkerService: FreeMarkerService = {
    generateComparisonStatement: ({ attribute, operator, value }) => {
      const formattedValue = typeof value === "string" ? `'${value}'` : value;

      const operatorsMap: Record<ComparisonOperator, string> = {
        eq: `${attribute} == ${formattedValue}`,
        ne: `${attribute} != ${formattedValue}`,
        lt: `${attribute} < ${formattedValue}'`,
        lte: `${attribute} <= ${formattedValue}`,
        ltrel: "", // TODO - Date
        gt: `${attribute} > ${formattedValue}`,
        gte: `${attribute} >= ${formattedValue}`,
        gtrel: "", // TODO - Date
        contains: `${attribute}?contains(${formattedValue})`,
        notContains: `!(${attribute}?contains(${formattedValue}))`,
        exists: `${formattedValue ? `${attribute}??` : `!(${attribute}??)`}`,
        in: `${attribute}??`,
        nin: `!(${attribute}??)`,
      };
      return operatorsMap[operator];
    },
    convertToFreeMarkerField: (fieldId) => {
      const fieldsMap = {
        fname: "first_name",
        lname: "last_name",
      };
      const formatID = fieldId.toLowerCase();

      return `contact.${fieldsMap[formatID] ?? formatID}`;
    },
    convertToFreeMarkerInterest: (interestId) => {
      return `contact.int_${interestId}`;
    },
    predicateToConditional: ({ predicates, fields, interests, attributeIds }) => {
      let beforeTagConditions = "";

      const getAttribute = (attribute: Attribute): string => {
        if (!fields && !interests) return `contact.${attribute.attribute}`;

        const foundField = fields?.find((field) => field.tag === attribute.attribute);
        if (foundField) {
          return freeMarkerService.convertToFreeMarkerField(attribute.attribute);
        }

        if (interests && attribute.attribute === attributeIds.interestsId) {
          const interestFound = interests?.find((interest) => interest.id === attribute.value);
          if (interestFound) {
            return freeMarkerService.convertToFreeMarkerInterest(Number(attribute.value));
          }
        }

        return `contact.${attribute.attribute}`;
      };

      predicates.attributes.forEach((attribute, index) => {
        if (index > 0) {
          beforeTagConditions = beforeTagConditions + ` ${predicates.operator === "and" ? "&&" : "||"} `;
        }

        beforeTagConditions =
          beforeTagConditions +
          freeMarkerService.generateComparisonStatement({
            attribute: `${getAttribute(attribute)}`,
            operator: attribute.operator,
            value: attribute.value,
          });
      });

      return {
        before: `[#if ${beforeTagConditions}]`,
        after: "[/#if]",
      };
    },
  };

  return freeMarkerService;
};
