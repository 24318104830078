<template>
  <SimpleButton
    :loading="loadingFeatures || activating"
    :disabled="loadingFeatures || isActive"
    theme="primary-light"
    @click="activate()"
  >
    <template #leading><CodeIcon class="h-5 w-5 text-white" /></template>
    {{ loadingFeatures ? "" : isActive ? t("activated") : t("activate") }}</SimpleButton
  >
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";

// Components
import SimpleButton from "@atoms/SimpleButton.vue";
import { CodeIcon } from "@heroicons/vue/solid";

// API
import { useAccountFeatures } from "@api/modules/account";

//Utils
import { useNotifications } from "@composables/notifications";

// Language
const { t } = useI18n();

// States
const getFeature = useAccountFeatures().get;
const putFeature = useAccountFeatures().put;
const { notify } = useNotifications();
const loadingFeatures = ref(true);
const activating = ref(false);
const isActive = ref(false);

// Methods
const activate = async () => {
  try {
    activating.value = true;
    await putFeature({ key: "ALWAYS_SHOW_OPTIN_RULE_CONFIG", value: "1" });
    isActive.value = true;
    notify({
      title: t(`success.title`),
      text: t(`success.text`),
      theme: "success",
    });
  } catch {
    notify({
      title: t(`errors.server.title`),
      text: t(`errors.server.text`),
      theme: "error",
    });
  } finally {
    activating.value = false;
  }
};

onMounted(async () => {
  try {
    loadingFeatures.value = true;
    const features = await getFeature();
    if (features.ALWAYS_SHOW_OPTIN_RULE_CONFIG === "1") {
      isActive.value = true;
      return;
    }
  } catch {
    loadingFeatures.value = false;
  } finally {
    loadingFeatures.value = false;
  }
});
</script>

<i18n lang="json">
{
  "es": {
    "activate": "Ya realicé esta configuración",
    "activated": "Integración activa",
    "success": {
      "title": "Integración activada",
      "text": "Ya puedes empezar a utilizar los formularios sin código."
    }
  },
  "pt": {
    "activate": "Já fiz essa configuração",
    "activated": "Integração ativa",
    "success": {
      "title": "Integração ativada",
      "text": "Agora você pode começar a usar os formulários sem código."
    }
  }
}
</i18n>
