<template>
  <ModalDialog :title="t('title')" show-close-button :open="open" @close="emitClose">
    <div class="max-w-xl pb-2">
      <div class="pb-4">
        <TextParagraph>
          {{ t("subtitle") }}
        </TextParagraph>
        <AlertBox theme="info" class="mb-4">
          {{ t("alertBoxText.title") }}
          <div v-if="email">
            <br />
            {{ t("alertBoxText.email") + email + "." }}
          </div>
        </AlertBox>
      </div>
      <ClipboardInput :value="url" />
    </div>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import AlertBox from "@atoms/AlertBox.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import ClipboardInput from "@molecules/ClipboardInput.vue";
import ModalDialog from "@molecules/ModalDialog.vue";

withDefaults(
  defineProps<{
    url: string;
    open: boolean;
    email?: string;
  }>(),
  {
    url: "",
    open: false,
    email: "",
  },
);

const { t } = useI18n();

//Emits
const emit = defineEmits<{
  (e: "close"): void;
}>();

const emitClose = () => {
  emit("close");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Compartir enlace de vista previa",
    "subtitle": "Envía este enlace a la persona con quien quieras compartir la vista previa de esta plantilla.",
    "alertBoxText": {
      "title": "El enlace generado muestra el contenido actual de la plantilla. Si realizas cambios, deberás generar y compartir un nuevo enlace.",
      "email": "La vista previa de la plantilla se muestra como la recibirá "
    }
  },
  "pt": {
    "title": "Obter link para compartilhar",
    "subtitle": "Envia este link a pessoa com quem queira compartilhar a pré-visualização do seu template. Qualquer pessoa com o link poderá ver. ",
    "alertBoxText": {
      "title": "O link gerado exibe o conteúdo atual do modelo. Se você fizer alterações, será necessário gerar e compartilhar um novo link.",
      "email": "A pré-visualização do template mostra como receberá "
    }
  }
}
</i18n>
