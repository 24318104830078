<template>
  <div>
    <transition
      enter-active-class="duration-100 ease-out"
      leave-active-class="duration-100 ease-in"
      enter-to-class="transform translate-x-0 opacity-100"
      leave-from-class="transform translate-x-0 opacity-100"
      :enter-from-class="'transform opacity-0 ' + (selectedAutomation ? 'translate-x-20' : '-translate-x-20')"
      :leave-to-class="'transform opacity-0 ' + (selectedAutomation ? '-translate-x-20' : 'translate-x-20')"
      mode="out-in"
      @enter="emit('selected', selectedAutomation)"
    >
      <div v-if="!selectedAutomation" class="" style="_height: 35rem">
        <div class="flex items-center justify-between">
          <div class="w-full md:w-1/2">
            <SearchInput
              v-model="searchString"
              :placeholder="t('search')"
              data-intercom-target="AutomationsCatalogSearch"
            />
          </div>
          <div class="flex">
            <button
              v-if="!searchString"
              :onclick="getCustomAutomation"
              class="group inline-flex flex-wrap content-center gap-1.5 pr-4 text-sky-300 hover:text-sky-400"
            >
              <SparklesIcon class="h-5 w-5 self-center" aria-hidden="true" />
              <span class="inline-block align-middle text-sm font-semibold">
                {{ t("customAutomation.title") }}
              </span>
            </button>
            <SelectMenu
              v-if="isMasterUser"
              v-model:selected="selectedLanguage"
              class="w-24"
              :data-items="LanguageDataItems"
            />
          </div>
        </div>
        <div class="mt-6 flex h-[66vh] items-start gap-6">
          <div v-if="!searchString" class="h-full overflow-auto" style="min-width: 18rem">
            <VerticalNavigation
              v-model:selected="selectedNavItem"
              :items="navItems"
              :sections="navSections"
              :loading="loading"
              data-intercom-target="AutomationsCatalogSections"
            />
          </div>
          <div
            v-if="!loading"
            ref="itemsList"
            class="h-full overflow-y-scroll pb-8 pr-4"
            data-intercom-target="AutomationsCatalogItems"
          >
            <template v-if="showGrouped">
              <template v-for="(group, idxGroup) in Object.keys(groupedAutomations)" :key="group">
                <ListHeader
                  :title="t(`categoriesTitle.${group}`)"
                  :action-label="selectedNavItem.key === 'suggested' ? t('seeAll') : ''"
                  class="mb-4"
                  @action-click="gotoNavItem(group)"
                />
                <div class="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <AutomationCard
                    v-for="(automation, idx) in groupedAutomations[group]"
                    :key="automation.source.id"
                    :automation="automation"
                    :allow-restricted="allowRestricted"
                    :data-intercom-target="idx === 0 && idxGroup === 0 ? 'AutomationsCatalogFirstItem' : undefined"
                    @click="gotoDetails(automation)"
                  />
                </div>
              </template>
            </template>
            <template v-else>
              <ListHeader
                v-if="searchString"
                :title="t('results', automations.length)"
                :action-label="t('cleanSearch')"
                class="mb-4"
                @action-click="searchString = ''"
              />
              <ListHeader v-if="!searchString && sectionTitle" :title="sectionTitle" class="mb-4" />
              <div
                class="mb-6 grid gap-4"
                :class="{
                  'grid-cols-1 md:grid-cols-2 lg:grid-cols-3': !searchString,
                  'grid-cols-2 md:grid-cols-3 lg:grid-cols-4': searchString,
                }"
              >
                <AutomationCard
                  v-for="(automation, idx) in automations"
                  :key="automation.source.account + '-' + automation.source.id"
                  :automation="automation"
                  :allow-restricted="allowRestricted"
                  :data-intercom-target="idx === 0 ? 'AutomationsCatalogFirstItem' : undefined"
                  :highlight-keyword="searchString"
                  @click="gotoDetails(automation)"
                />
                <GridCard
                  v-if="
                  integrationsStore.ecommerceIntegrations.length > 0 &&
                    !searchString &&
                    ['reactivation', 'lifecycleRFM'].includes(selectedNavItem?.key) &&
                    automations.length == 0
                  "
                  :readonly="true"
                  :title="t('enableRFM.title')"
                  :description="t('enableRFM.description')"
                  @click="gotoLifecycleRFM"
                >
                  <template #footer>
                    <SimpleBadge theme="gray" :icon="HeartIcon">{{ t("enableRFM.count") }}</SimpleBadge>
                  </template>
                </GridCard>
                <GridCard
                  v-else-if="
                    (selectedNavItem?.key !== 'lifecycleRFM' && !searchString) ||
                    (searchString && automations.length == 0)
                  "
                  :readonly="true"
                  :title="t('connectMoreApps.title')"
                  :description="t('connectMoreApps.description')"
                  @click="gotoIntegrations"
                >
                  <template #footer>
                    <SimpleBadge theme="gray" :icon="ViewGridAddIcon">{{ t("connectMoreApps.count") }}</SimpleBadge>
                  </template>
                </GridCard>
              </div>
            </template>
          </div>

          <div v-if="loading" class="h-full animate-pulse overflow-auto">
            <template v-for="n in 2" :key="n">
              <div class="mb-3 mt-2 h-4 w-40 rounded-xl bg-gray-100"></div>
              <div class="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div v-for="m in 3" :key="m * n" class="h-44 w-56 rounded-xl bg-gray-50"></div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <AutomationDetails
        v-else-if="!selectingEditor"
        :automation="selectedAutomation"
        :allow-restricted="allowRestricted"
        :creating="!!creating"
        @back="gotoList"
        @next="createSelectedAutomation"
      />

      <TemplateEditorSelector v-else-if="selectingEditor" :loading="creating" @select="editorSelected" />
    </transition>
  </div>
</template>

<script setup lang="ts">
// Types
import type { Automation, AutomationSource } from "./catalog/AutomationsCatalog.types";

import { ref, onMounted, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAutomationsCatalog } from "./catalog/AutomationsCatalog";
import { useAccountPlan } from "@api/modules/accountplan";
import { useAccountFeatures } from "@api/modules/account";

import SelectMenu from "@molecules/SelectMenu.vue";
import { ViewGridAddIcon, HeartIcon } from "@heroicons/vue/solid";
import { SparklesIcon } from "@heroicons/vue/outline";
import SearchInput from "@atoms/SearchInput.vue";
import ListHeader from "@atoms/ListHeader.vue";
import AutomationCard from "./AutomationCard.vue";
import SimpleBadge from "@atoms/SimpleBadge";
import VerticalNavigation, { NavItem, NavItemArray, NavSectionArray } from "@molecules/VerticalNavigation.vue";
import GridCard from "@molecules/GridCard.vue";
import AutomationDetails from "./AutomationDetails.vue";
import TemplateEditorSelector from "@organisms/TemplateEditorSelector/TemplateEditorSelector.vue";
import Intercom from "@helpers/intercom";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useIntegrationsStore } from "@store";

//Types
import type { DataItem, DataItems } from "@/vue/components/molecules/SelectMenuLabel.vue";
import type { SelectEvent } from "@organisms/TemplateEditorSelector/TemplateEditorSelector.vue";
import { Languages } from "@locales/i18n";

export interface CreateEvent {
  automation: Automation;
  selectedSource: AutomationSource;
}

const integrationsStore = useIntegrationsStore();

const props = withDefaults(
  defineProps<{
    defaultSelectedAutomationId?: string;
    defaultSelectedCategory?: string;
  }>(),
  {
    defaultSelectedAutomationId: undefined,
    defaultSelectedCategory: undefined,
  },
);

const emit = defineEmits<{
  (e: "selected", a: Automation | null): void;
  (e: "create", a: CreateEvent): void;
  (e: "close"): void;
  (e: "selectingEditor"): void;
  (e: "gotoIntegrations"): void;
  (e: "gotoLifecycleRFM"): void;
}>();

// Session
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const isMasterUser = session.value?.isMasterUser;

const { t, locale } = useI18n();
const AutomationsCatalog = useAutomationsCatalog();
const AccountPlan = useAccountPlan();
const AccountFeatures = useAccountFeatures();

const defaultCategories = ["welcome", "recover", "loyalty", "cross-selling", "reactivation", "transactional"];
let categories = [...defaultCategories];
const navItems = ref<NavItemArray>([]);

const navSections: NavSectionArray = [
  { id: "categories", name: t("sections.categories") },
  { id: "integrations", name: t("sections.integrations") },
];

// State
const selectedNavItem = ref<NavItem>(navItems.value[0]);
const searchString = ref("");
const selectedAutomation = ref<Automation | null>(null);

const automations = ref<Automation[]>([]);
const groupedAutomations = ref<Record<string, Automation[]>>({});
const showGrouped = ref(false);
const sectionTitle = ref("");
const loading = ref(false);
const creating = ref<"eddie" | "unlayer" | undefined>();
const selectingEditor = ref(false);
const itemsList = ref();
const allowRestricted = ref(false);
let unlayerOptional = ref(false);
let unlayerDefault = ref(false);

const gotoNavItem = (key: string) => {
  selectedNavItem.value = navItems.value.find((i) => i.key === key) || navItems.value[0];
};

const gotoIntegrations = () => {
  emit("gotoIntegrations");
};

const gotoLifecycleRFM = () => {
  emit("gotoLifecycleRFM");
};

const gotoDetails = (automation: Automation) => {
  selectedAutomation.value = automation;
};

const gotoList = () => {
  selectedAutomation.value = null;
};

const has2Sources = computed(() => {
  const hasUnlayerVersion = !!selectedAutomation.value?.sourceUnlayer;
  return hasUnlayerVersion;
});

const bestSource = computed(() => {
  return selectedAutomation.value?.sourceUnlayer || selectedAutomation.value?.source;
});

const createSelectedAutomation = () => {
  if (!selectedAutomation.value) return;

  if (unlayerDefault.value && bestSource.value) {
    // si tiene UNLAYER_DEFAULT y hay 2 sources, usar siempre source unlayer
    emit("create", { automation: selectedAutomation.value, selectedSource: bestSource.value });
    creating.value = "eddie"; //da igual
  } else if (unlayerOptional.value && has2Sources.value) {
    // si tiene feature UNLAYER_OPTIONAL y hay 2 version que elija
    selectingEditor.value = true;
    emit("selectingEditor");
  } else {
    // tiene una solo source el automation (eddie o unlayer da igual) o no tiene unlayerOptional
    emit("create", { automation: selectedAutomation.value, selectedSource: selectedAutomation.value.source });
    creating.value = "eddie"; //da igual
  }
};

const editorSelected = (event: SelectEvent) => {
  if (!selectedAutomation.value || !selectedAutomation.value) return;
  let source: AutomationSource;
  if (event.selectedEditor === "eddie" || selectedAutomation.value.sourceUnlayer === undefined) {
    source = selectedAutomation.value.source;
    creating.value = "eddie";
  } else {
    source = selectedAutomation.value.sourceUnlayer;
    creating.value = "unlayer";
  }
  emit("create", { automation: selectedAutomation.value, selectedSource: source });
};

const LanguageDataItems = [
  {
    key: Languages.es,
    value: t("languages.es"),
  },
  {
    key: Languages.pt,
    value: t("languages.pt"),
  },
] as DataItems<unknown, Languages, Languages>;

const defaultLanguage: Languages = (locale.value?.slice(0, 2) || "es") as Languages;

const selectedLanguage = ref<DataItem<unknown, Languages, Languages>>(
  LanguageDataItems.find((obj) => obj.key == defaultLanguage) || LanguageDataItems[0],
);

const loadAutomation = async () => {
  loading.value = true;
  await AutomationsCatalog.init(selectedLanguage.value.key);

  categories = [...defaultCategories];
  if (AutomationsCatalog.listIntegrationsEnabled().includes("tiendanube")) {
    // Categoria especial Black / Cyber / Hot Sale según el idioma y si es TN
    // Activar if cuando se requiera su uso.
    //if (selectedLanguage.value.key === "es") categories.unshift("hotSale");
    // if (selectedLanguage.value.key === "pt") categories.unshift("black");
  }

  const accountPlan = await AccountPlan.get();
  allowRestricted.value =
    accountPlan.type === "MONTHLY" || accountPlan.type.includes("CHILD") || !!accountPlan.trialEnds;

  loading.value = false;

  navItems.value = [];
  const statsBySuggested = AutomationsCatalog.statsBySuggested();
  navItems.value.push({
    key: "suggested",
    name: t("categories.suggested"),
    count: statsBySuggested.count,
  });

  const statsByAll = AutomationsCatalog.statsByAll();
  navItems.value.push({
    key: "all",
    name: t("categories.all"),
    count: statsByAll.count,
  });

  const isEcommerce = ref(false);
  isEcommerce.value = AutomationsCatalog.listIntegrationsEnabled().some((i) =>
    [
      "tiendanube",
      "vtex",
      "jumpseller",
      "woocommerce",
      "shopify",
      "mshops",
      "pow",
      "signos",
      "tray",
      "bootic",
      "wbuy",
      "vnda",
    ].includes(i),
  );

  const statsByLifecycleRFM = AutomationsCatalog.statsByLifecycleRFM();
  if (statsByLifecycleRFM.count || isEcommerce.value) {
    navItems.value.push({
      key: "lifecycleRFM",
      name: t("categories.lifecycleRFM"),
      count: statsByLifecycleRFM.count,
      hasNew: statsByLifecycleRFM.hasNew,
    });
  }

  const statsByCateg = AutomationsCatalog.statsByCategory();
  categories.forEach((c) => {
    navItems.value.push({
      section: "categories",
      key: c,
      name: t(`categories.${c}`),
      count: statsByCateg[c]?.count || 0,
      hasNew: statsByCateg[c]?.hasNew,
    });
  });

  const statsByInt = AutomationsCatalog.statsByIntegration();
  Object.keys(statsByInt).forEach((k) => {
    navItems.value.push({
      section: "integrations",
      key: k,
      name: t(`integrations.${k}`),
      count: statsByInt[k].count,
      hasNew: statsByInt[k].hasNew,
    });
  });

  selectedNavItem.value = navItems.value[0];
};

const selectCategory = () => {
  const categorySelected = navItems.value.find((category) => {
    return category.key === props.defaultSelectedCategory;
  });

  if (!categorySelected) return;

  selectedNavItem.value = categorySelected;
};

const selectAutomation = () => {
  const automSelected = AutomationsCatalog.listAvailable().find(
    (autom) =>
      autom.source.id === props.defaultSelectedAutomationId ||
      autom.sourceUnlayer?.id === props.defaultSelectedAutomationId,
  );

  if (!automSelected) return;

  selectedAutomation.value = automSelected;
};

// Lifecycle
onMounted(async () => {
  await loadAutomation();
  const features = await AccountFeatures.get();

  unlayerOptional.value = features.UNLAYER_OPTIONAL === "1";
  unlayerDefault.value = features.UNLAYER_DEFAULT === "1";

  if (props.defaultSelectedAutomationId) {
    selectAutomation();
    return;
  }

  if (props.defaultSelectedCategory) {
    selectCategory();
    return;
  }
});

const refreshAutomationsList = () => {
  showGrouped.value = false;
  sectionTitle.value = "";
  if (searchString.value) {
    automations.value = AutomationsCatalog.listByKeyword(searchString.value);
  } else if (selectedNavItem.value.section === "integrations") {
    automations.value = AutomationsCatalog.listByIntegration(selectedNavItem.value.key);
    sectionTitle.value = t(`categoriesTitle.worksWith`, [t(`integrations.${selectedNavItem.value.key}`)]);
  } else if (selectedNavItem.value.section === "categories") {
    automations.value = AutomationsCatalog.listByCategory(selectedNavItem.value.key);
    sectionTitle.value = t(`categoriesTitle.${selectedNavItem.value.key}`);
  } else if (selectedNavItem.value.key === "suggested") {
    automations.value = AutomationsCatalog.listBySuggested();
    sectionTitle.value = t(`categoriesTitle.${selectedNavItem.value.key}`);
  } else if (selectedNavItem.value.key === "all") {
    automations.value = AutomationsCatalog.listAvailable();
    showGrouped.value = true;
  } else if (selectedNavItem.value.key === "lifecycleRFM") {
    automations.value = AutomationsCatalog.listByLifecycleRFM();
    sectionTitle.value = t(`categoriesTitle.lifecycleRFM`);
  }
  itemsList.value?.scrollTo(0, 0);
};

const groupAutomations = () => {
  const grouped: Record<string, Automation[]> = {};
  automations.value.forEach((a) => {
    if (!grouped[a.category]) grouped[a.category] = [];
    grouped[a.category].push(a);
  });
  groupedAutomations.value = grouped;
};

const getCustomAutomation = () => {
  Intercom.showNewMessage(t("customAutomation.message"));
};

watch([selectedNavItem, searchString], () => {
  refreshAutomationsList();
});

watch(automations, () => {
  groupAutomations();
});

watch(selectedLanguage, async () => {
  await loadAutomation();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "search": "Buscar automations",
    "cleanSearch": "Limpiar búsqueda",
    "customAutomation": {
      "title": "¡Consulta por un automation personalizado!",
      "message": "Hola! Quiero asesorarme para contratar un automation personalizado."
    },
    "results": "Sin resultados | 1 resultado | {n} resultados",
    "seeAll": "Ver todos",
    "sections": {
      "categories": "Categorias",
      "integrations": "Integraciones"
    },
    "categories": {
      "suggested": "Sugeridos",
      "all": "Todos",
      "lifecycleRFM": "Ciclo de vida",
      "welcome": "Bienvenida",
      "recover": "Recupero de visitas",
      "loyalty": "Fidelización",
      "cross-selling": "Venta cruzada",
      "reactivation": "Reactivación",
      "transactional": "Transaccionales",
      "events": "Ocasiones especiales",
      "black": "Black Friday 🔥",
      "cyber": "Cyber Monday 🔥",
      "hotSale": "Hot Sale 🔥"
    },
    "categoriesTitle": {
      "suggested": "Recomendaciones para comenzar",
      "all": "Todos",
      "lifecycleRFM": "Ciclo de vida de compradores",
      "welcome": "Mensajes y saludos de bienvenida",
      "recover": "Recupero de visitas abandonadas",
      "loyalty": "Fidelización de clientes",
      "cross-selling": "Estrategias de cross-selling y up-selling",
      "reactivation": "Reactivación de clientes dormidos",
      "transactional": "Notificaciones sobre procesos de compra",
      "events": "Ocasiones especiales",
      "cyber": "Diseños creados para el Cyber Monday 🔥",
      "black": "Diseños creados para el Black Friday 🔥",
      "hotSale": "Diseños creados para el Hot Sale 🔥",
      "worksWith": "Funcionan con {0}"
    },
    "connectMoreApps": {
      "title": "Integra otras aplicaciones y accede a más opciones",
      "description": "Conoce las plataformas con las que puedes conectar tu cuenta.",
      "count": "+15 apps"
    },
    "enableRFM": {
      "title": "Activa el ciclo de vida",
      "description": "Implementa estrategias más efectivas segmentando según el comportamiento de tus clientes.",
      "count": "Ciclo de vida"
    },
    "languages": {
      "es": "ES",
      "pt": "PT"
    }
  },
  "pt": {
    "search": "Procurar automations",
    "cleanSearch": "Limpar pesquisa",
    "customAutomation": {
      "title": "Consulte por um automation personalizado!",
      "message": "Olá! Quero me assessorar para contratar um automation personalizado."
    },
    "results": "Sem resultados | 1 resultado | {n} resultados",
    "seeAll": "Ver todos",
    "sections": {
      "categories": "Categorias",
      "integrations": "Integrações"
    },
    "categories": {
      "suggested": "Sugeridos",
      "all": "Todos",
      "lifecycleRFM": "Ciclo de vida",
      "welcome": "Boas-vindas",
      "recover": "Recuperar visitas",
      "loyalty": "Fidelização",
      "cross-selling": "Venda Cruzada",
      "reactivation": "Reativação",
      "transactional": "Transacionais",
      "events": "Ocasiões especiais",
      "black": "Black Friday 🔥",
      "cyber": "Cyber Monday 🔥",
      "hotSale": "Hot Sale 🔥"
    },
    "categoriesTitle": {
      "suggested": "Recomendações para começar",
      "all": "Todos",
      "lifecycleRFM": "Ciclo de vida dos compradores",
      "welcome": "Mensagens e cumprimentos de boas-vindas",
      "recover": "Recuperar visitas abandonadas",
      "loyalty": "Fidelização de clientes",
      "cross-selling": "Estratégias de Cross-selling e Up-selling",
      "reactivation": "Reativação de clientes inativos",
      "transactional": "Notificações sobre processos de compra",
      "events": "Ocasiões especiais",
      "black": "Templates criados para a Black Friday 🔥",
      "cyber": "Templates criados para a Cyber Monday 🔥",
      "hotSale": "Templates criados para a Hot Sale 🔥",
      "worksWith": "Funcionam com {0}"
    },
    "connectMoreApps": {
      "title": "Integra outras aplicações e acessa mais opções",
      "description": "Conheça as plataformas com as quais você pode conectar sua conta.",
      "count": "+15 apps"
    },
    "enableRFM": {
      "title": "Ativar ciclo de vida",
      "description": "Implemente estratégias mais eficazes segmentando com base no comportamento de seus clientes.",
      "count": "Ciclo de vida"
    },
    "languages": {
      "es": "ES",
      "pt": "PT"
    }
  }
}
</i18n>
