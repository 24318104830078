var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='metrics section show'>\n  <div class='section-header "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":86}}})) != null ? stack1 : "")
    + "'>\n    <h1>\n      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":7,"column":52}}})) != null ? stack1 : "")
    + "\n      "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n    </h1>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":37,"column":13}}})) != null ? stack1 : "")
    + "  <div class='content edit scrollbar'>\n    <div class='padder'>\n\n      <ul class='nav nav-pills'>\n        <li class='active'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"details")), depth0))
    + "\n            </a>\n        </li>\n        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":53,"column":10},"end":{"line":58,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"links")), depth0))
    + "\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":68,"column":19}}})) != null ? stack1 : "")
    + "        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":70,"column":10},"end":{"line":75,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n            </a>\n        </li>\n      </ul>\n\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"SPLIT_AB",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":6},"end":{"line":94,"column":13}}})) != null ? stack1 : "")
    + "      <div class='row'>\n        <div class='col-sm-12'>\n          <div class='summary panel panel-default'>\n            <div class='row'>\n              <div class='col-sm-4'>\n                <div class='data sent'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"SENDING",{"name":"is","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data,"loc":{"start":{"line":101,"column":18},"end":{"line":117,"column":25}}})) != null ? stack1 : "")
    + "                  <a href=\"#\" data-action=\"open-criterias-modal\"\n                    class=\"text-uppercase text-xs\">"
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"showCriterias")), depth0))
    + "</a>\n                </div>\n              </div>\n              <div class='col-sm-4'>\n                <div class='data delivered'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"deliveredP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":125,"column":20},"end":{"line":125,"column":77}}}))
    + "%\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"deliverings")), depth0))
    + "\n                  </p>\n                </div>\n              </div>\n              <div class='col-sm-4'>\n                <div class='data calendar'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),"SMART",{"name":"is","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":136,"column":18},"end":{"line":148,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n            </div>\n            <hr>\n            <div class='row'>\n              <div class='col-sm-6'>\n                <div class='description'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":156,"column":18},"end":{"line":160,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n              <div class='col-sm-6'>\n                <div class='tags'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(47, data, 0),"data":data,"loc":{"start":{"line":165,"column":18},"end":{"line":173,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n      \n     \n      <div class='row'>\n        <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConversionsColumn") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":201,"column":20},"end":{"line":201,"column":80}}})) != null ? stack1 : "")
    + "'>\n          <div class='opens panel panel-default' data-link='OPEN'>\n            <h2 class='panel-head with-icon'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"opened")), depth0))
    + "\n            </h2>\n            <div class='panel-body'>\n              <div class=\"pie opened\">\n                <div class=\"\" data-percent=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"openedP") : stack1), depth0))
    + "\" data-bar-color=\"#93C52D\"\n                  data-track-color=\"#DFEFB4\" data-size='250' data-line-width='10'>\n                  <div class=\"percent\">\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"openedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":211,"column":20},"end":{"line":211,"column":74}}}))
    + "<sup>%</sup>\n                    <div class='total'>\n                      "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"opened") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":213,"column":22},"end":{"line":213,"column":75}}}))
    + "\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <div class='row tricard'>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"openedT") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":221,"column":20},"end":{"line":221,"column":74}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"totalOpens")), depth0))
    + "\n                  </p>\n                </div>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"viewsOnline") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":229,"column":20},"end":{"line":229,"column":78}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"onlineViews")), depth0))
    + "\n                  </p>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConversionsColumn") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data,"loc":{"start":{"line":239,"column":20},"end":{"line":239,"column":80}}})) != null ? stack1 : "")
    + "'>\n          <div class='clicks panel panel-default' data-link='CLICK'>\n            <h2 class='panel-head with-icon'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"clicks")), depth0))
    + "\n            </h2>\n            <div class='panel-body'>\n              <div class=\"pie clicked\">\n                <div class=\"\" data-percent=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clickedP") : stack1), depth0))
    + "\" data-bar-color=\"#2FC5A0\"\n                  data-track-color=\"#96e2cf\" data-size='250' data-line-width='10'>\n                  <div class=\"percent\">\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clickedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":249,"column":20},"end":{"line":249,"column":75}}}))
    + "<sup>%</sup>\n                    <div class='total'>\n                      "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clicked") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":251,"column":22},"end":{"line":251,"column":76}}}))
    + "\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <div class='row tricard'>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clickedT") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":259,"column":20},"end":{"line":259,"column":75}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"totalClicks")), depth0))
    + "\n                  </p>\n                </div>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"ctor") : stack1), depth0))
    + "%\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"ctor")), depth0))
    + "\n                  </p>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConversionsColumn") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":277,"column":8},"end":{"line":323,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n      <div id=\"timeline\" class='row'>\n        <div class='col-sm-12'>\n          <div class='timeline panel panel-default'>\n            <h2 class='panel-head with-icon'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"timeline")), depth0))
    + "\n            </h2>\n            <div class='panel-body'>\n              <canvas id='clicks-timeline'></canvas>\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class='row'>\n        <div class='col-sm-4'>\n          <div class='bounced panel panel-default' data-link='BOUNCE'>\n            <h2 class='panel-head with-icon'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"bounced")), depth0))
    + "\n            </h2>\n            <div class='panel-body'>\n              <div class=\"pie bounced\">\n                <div class=\"chart\" data-percent=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"bouncedP") : stack1), depth0))
    + "\" data-bar-color=\"#ffa426\"\n                  data-track-color=\"#ffcc85\" data-size='150' data-line-width='7'>\n                  <div class=\"percent\">\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"bouncedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":348,"column":20},"end":{"line":348,"column":75}}}))
    + "<sup>%</sup>\n                    <div class='total'>\n                      "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"bounced") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":350,"column":22},"end":{"line":350,"column":76}}}))
    + "\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <div class='row tricard'>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"bouncedSoft") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":358,"column":20},"end":{"line":358,"column":78}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"bouncedSoft")), depth0))
    + "\n                  </p>\n                </div>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"bouncedHard") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":366,"column":20},"end":{"line":366,"column":78}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"bouncedHard")), depth0))
    + "\n                  </p>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class='col-sm-8'>\n          <div class='row'>\n            <div class='col-sm-6'>\n              <div class='unsubscribed panel panel-default' data-link='UNSUBSCRIBE'>\n                <h2 class='panel-head with-icon'>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"unsubscribed")), depth0))
    + "\n                  <span class='amount'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"unsubscribedP") : stack1), depth0))
    + "%\n                  </span>\n                </h2>\n                <div class='panel-body'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"unsubscribed") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":388,"column":20},"end":{"line":388,"column":79}}}))
    + "\n                    <small>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"unsubscribedLegend")), depth0))
    + "\n                    </small>\n                  </h4>\n                </div>\n              </div>\n            </div>\n            <div class='col-sm-6'>\n              <div class='complained panel panel-default' data-link='COMPLAINT'>\n                <h2 class='panel-head with-icon'>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"complained")), depth0))
    + "\n                  <span class='amount'>\n                    "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"complainedP") : stack1), depth0))
    + "%\n                  </span>\n                </h2>\n                <div class='panel-body'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"complained") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":406,"column":20},"end":{"line":406,"column":77}}}))
    + "\n                    <small>\n                      "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"complainedLegend")), depth0))
    + "\n                    </small>\n                  </h4>\n                </div>\n              </div>\n            </div>\n          </div>\n          <div class='shared panel panel-default' data-link='SHARE'>\n            <h2 class='panel-head with-icon'>\n              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"shared")), depth0))
    + "\n            </h2>\n            <div class='panel-body'>\n              <div class='row'>\n                <div class='col-sm-2  col-sm-offset-1'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sharedDetails") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":423,"column":20},"end":{"line":423,"column":86}}}))
    + "\n                    <small>\n                      Total\n                    </small>\n                  </h4>\n                </div>\n                <div class='col-sm-2'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sharedDetails") : stack1)) != null ? lookupProperty(stack1,"whatsapp") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":431,"column":20},"end":{"line":431,"column":89}}}))
    + "\n                    <small>\n                      Whatsapp\n                    </small>\n                  </h4>\n                </div>\n                <div class='col-sm-2'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sharedDetails") : stack1)) != null ? lookupProperty(stack1,"facebook") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":439,"column":20},"end":{"line":439,"column":89}}}))
    + "\n                    <small>\n                      Facebook\n                    </small>\n                  </h4>\n                </div>\n                <div class='col-sm-2'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sharedDetails") : stack1)) != null ? lookupProperty(stack1,"twitter") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":447,"column":20},"end":{"line":447,"column":88}}}))
    + "\n                    <small>\n                      Twitter\n                    </small>\n                  </h4>\n                </div>\n                <div class='col-sm-2'>\n                  <h4>\n                    "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sharedDetails") : stack1)) != null ? lookupProperty(stack1,"linkedin") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":455,"column":20},"end":{"line":455,"column":89}}}))
    + "\n                    <small>\n                      Linkedin\n                    </small>\n                  </h4>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " centered ";
},"4":function(container,depth0,helpers,partials,data) {
    return "<a data-navigate=\"campaigns\"><i\n          class='icon icon-back'></i></a>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='toolbar edit-mode'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"SENDING",{"name":"is","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "    <button type='button' class='btn btn-default' data-action='open-duplicate-modal'\n      data-require-permission='campaigns:create'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default' data-action='open-share-modal'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"share")), depth0))
    + "\n    </button>\n  </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='stop' data-confirm=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"confirmStopCampaign")), depth0))
    + "\"\n      data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"stop")), depth0))
    + "\n    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"archived") : stack1),null,{"name":"is","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='archive'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"archive")), depth0))
    + "\n    </button>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='unarchive'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\n    </button>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/links'>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/links'>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-navigate='metrics/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/activity?filter.type=SENT'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"activity")), depth0))
    + "\n          </a>\n        </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/contents'>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/contents'>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class='form-group version-selector'>\n        <select name=\"selected-version\" class=\"form-control\" data-action=\"switch-version\">\n          <option value=\"\">\n            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"generalResults")), depth0))
    + "\n          </option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"splitABSubjects")),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":10},"end":{"line":91,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n      </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"version")),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"is","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":34},"end":{"line":88,"column":74}}})) != null ? stack1 : "")
    + ">\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"version")), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"number") : depth0), depth0))
    + ": "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subject") : depth0), depth0))
    + "\n          </option>\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "selected";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <strong data-link='SENT'>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sent") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":103,"column":66}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"smartSentP")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sentP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":106,"column":56},"end":{"line":106,"column":103}}}))
    + "%\n                    "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"smartSentOf")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"recipients") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":107,"column":57},"end":{"line":107,"column":101}}}))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"recipients")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":107,"column":102},"end":{"line":108,"column":53}}}))
    + "\n                  </p>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <strong data-link='SENT'>\n                    "
    + alias1(__default(require("../../../helpers/handlebars/formatInteger.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sent") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":112,"column":20},"end":{"line":112,"column":71}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"sendings")), depth0))
    + "\n                  </p>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class='sent-date'>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"smartOptions") : stack1)) != null ? lookupProperty(stack1,"from") : stack1),"LLLL",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":138,"column":20},"end":{"line":138,"column":71}}}))
    + " "
    + alias2(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"to")), depth0))
    + " <br />\n                    "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"smartOptions") : stack1)) != null ? lookupProperty(stack1,"to") : stack1),"LLLL",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":139,"column":20},"end":{"line":139,"column":69}}}))
    + "\n                  </p>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p class='sent-date'>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"launchDate") : stack1),"D [de] MMMM [de] YYYY",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":143,"column":20},"end":{"line":143,"column":81}}}))
    + "\n                  </p>\n                  <p class='sent-hour'>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"launchDate") : stack1),"H:mm [hs]",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":146,"column":20},"end":{"line":146,"column":69}}}))
    + "\n                  </p>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <i>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noDescription")), depth0))
    + "</i>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"tags") : stack1),{"name":"each","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":18},"end":{"line":170,"column":27}}})) != null ? stack1 : "");
},"45":function(container,depth0,helpers,partials,data) {
    return "                  <span class='label label-outline'>\n                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                  </span>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <i>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noTags")), depth0))
    + "</i>\n";
},"49":function(container,depth0,helpers,partials,data) {
    return "col-sm-4";
},"51":function(container,depth0,helpers,partials,data) {
    return "col-sm-6";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='col-sm-4'>\n          <div class='assists panel panel-default' data-link='CONVERSION'>\n            <div class=\"align-div-tooltip\">\n              <h2 class='panel-head with-icon tooltip-icon'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"assistedSales")), depth0))
    + "\n              </h2>\n              <div id=\"vue-info-icon\"/>\n            </div>\n            <div class='panel-body'>\n              <div class=\"pie assisted\">\n                <div class=\"\" data-percent=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clickedP") : stack1), depth0))
    + "\" data-bar-color=\"#2FC5A0\"\n                  data-track-color=\"#96e2cf\" data-size='250' data-line-width='10'>\n                  <div class=\"percent\">\n                    "
    + alias2(__default(require("../../../helpers/handlebars/formatIntegerRound.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"conversions") : stack1),{"name":"formatIntegerRound","hash":{},"data":data,"loc":{"start":{"line":291,"column":20},"end":{"line":291,"column":78}}}))
    + "\n                    <div class='total'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"conversions") : stack1),"1",{"name":"is","hash":{},"fn":container.program(54, data, 0),"inverse":container.program(56, data, 0),"data":data,"loc":{"start":{"line":293,"column":22},"end":{"line":297,"column":29}}})) != null ? stack1 : "")
    + "                    </div>\n                  </div>\n                </div>\n              </div>\n              <div class='row tricard'>\n                <div class='col-sm-6 text-center'>\n                  <strong>\n                     "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"conversionCurrency")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatIntegerRound.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"conversionsAmount") : stack1),{"name":"formatIntegerRound","hash":{},"data":data,"loc":{"start":{"line":305,"column":63},"end":{"line":305,"column":127}}}))
    + "\n                  </strong>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"assistedSalesAmount")), depth0))
    + "\n                  </p>\n                </div>   \n                <div class='col-sm-6 text-center'>\n                  <strong>\n                    "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"conversionRate") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":313,"column":20},"end":{"line":313,"column":76}}}))
    + " %\n                  </strong>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"conversion")), depth0))
    + "\n                  </p>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>        \n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/lowercase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"sale")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":294,"column":24},"end":{"line":294,"column":61}}}))
    + "\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/lowercase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"conversions")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":296,"column":24},"end":{"line":296,"column":68}}}))
    + "\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noResults")), depth0))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(58, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":471,"column":7}}})) != null ? stack1 : "");
},"useData":true});