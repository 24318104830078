<template>
  <div>
    <div class="relative rounded-md shadow-sm">
      <input
        v-bind="$attrs"
        ref="inputRef"
        :value="modelValue"
        :placeholder="placeholder"
        type="text"
        class="block w-full rounded-md border-gray-300 pr-16 placeholder-gray-500 focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
        maxlength="200"
        @input="UpdateModelValue"
      />
      <div class="absolute right-0 top-1.5 flex flex-row items-center space-x-1 pr-2">
        <div ref="emojiContainerRef" class="relative inline-block text-left">
          <IconButton
            :label="t('insertEmoji')"
            type="button"
            tabindex="-1"
            class="!p-0"
            :show-tooltip="!showEmojiDropdown"
            @click="() => (showEmojiDropdown = !showEmojiDropdown)"
          >
            <EmojiHappyIcon />
          </IconButton>

          <transition
            leave-active-class="transition duration-100 ease"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0 transform translate-y-6 "
            enter-active-class="transition duration-100 ease"
            enter-to-class="opacity-100"
            enter-from-class="opacity-0 transform translate-y-6"
          >
            <div
              v-show="showEmojiDropdown"
              class="absolute right-0 z-10 mt-3 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="{ 'bottom-9': dropdownsUpwards }"
              role="menu"
              aria-orientation="vertical"
            >
              <Picker
                :native="true"
                :data="emojiIndex"
                :emoji-tooltip="true"
                :i18n="emojiI18n"
                emoji="point_right"
                color="#00aee8"
                set="apple"
                :title="t('selectEmoji')"
                @select="insertEmoji"
              />
            </div>
          </transition>
        </div>

        <div ref="fieldsContainerRef" class="relative inline-block text-left">
          <IconButton
            :label="t('insertField')"
            type="button"
            tabindex="-1"
            class="!p-0"
            :show-tooltip="!showFieldsDropdown"
            @click="() => (showFieldsDropdown = !showFieldsDropdown)"
          >
            <DotsCircleHorizontalIcon />
          </IconButton>

          <transition
            leave-active-class="transition duration-100 ease"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0 transform translate-y-6 "
            enter-active-class="transition duration-100 ease"
            enter-to-class="opacity-100"
            enter-from-class="opacity-0 transform translate-y-6"
          >
            <div
              v-show="showFieldsDropdown"
              class="absolute right-0 z-10 mt-3 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="{ 'bottom-9': dropdownsUpwards }"
              role="menu"
              aria-orientation="vertical"
            >
              <ul
                tabindex="-1"
                role="listbox"
                class="my-0 max-h-80 overflow-auto rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <li
                  v-for="field in fields"
                  :key="field.name"
                  role="option"
                  class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-800 hover:bg-sky-200"
                  @click="insertFieldTag(field)"
                >
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="block truncate font-normal">
                    {{ field.name }}
                  </span>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <slot name="icon-buttons" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import IconButton from "@atoms/IconButton.vue";

//Picker
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import data from "emoji-mart-vue-fast/data/apple.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";

//Icons
import { DotsCircleHorizontalIcon, EmojiHappyIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";
import { onClickOutside } from "@vueuse/core";

//Types
import { localesLanguages } from "@/vue/locales/i18n";

export interface Field {
  name: string;
  tag: string;
}

const { locale, t } = useI18n();

const emojiIndex = new EmojiIndex(data);

withDefaults(
  defineProps<{
    modelValue: string;
    fields: Array<Field>;
    dropdownsUpwards?: boolean;
    placeholder?: string;
  }>(),
  {
    modelValue: undefined,
    fields: () => [],
    dropdownsUpwards: false,
    placeholder: "",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const UpdateModelValue = (event: Event) => {
  const modelValue = (event.target as HTMLInputElement).value;
  emit("update:modelValue", modelValue);
};

const inputRef = ref<HTMLInputElement>();
const emojiContainerRef = ref(null);
const fieldsContainerRef = ref(null);
const showEmojiDropdown = ref(false);
const showFieldsDropdown = ref(false);

const emojiI18nES = {
  search: "Buscar...",
  notfound: "No se encontró ese Emoji :(",
  categories: {
    search: "Resultados",
    recent: "Recientes",
    smileys: "Caritas & Emoticones",
    people: "Personas",
    nature: "Animales y Naturaleza",
    foods: "Comidas y Bebidas",
    activity: "Actividades",
    places: "Viajes y Lugares",
    objects: "Objetos",
    symbols: "Símbolos",
    flags: "Banderas",
    custom: "Personalizados",
  },
};
const emojiI18nPT = {
  search: "Pesquisar...",
  notfound: "Não encontramos esse Emoji :(",
  categories: {
    search: "Resultados",
    recent: "Recentes",
    smileys: "Rostos & Emoções",
    people: "Pessoas",
    nature: "Animais e Natureza",
    foods: "Comidas e Bebidas",
    activity: "Atividades",
    places: "Viagem e Lugares",
    objects: "Objetos",
    symbols: "Símbolos",
    flags: "Bandeiras",
    custom: "Personalizados",
  },
};

const emojiI18n = (locale.value?.slice(0, 2) as localesLanguages) === "es" ? emojiI18nES : emojiI18nPT;

onClickOutside(emojiContainerRef, () => {
  showEmojiDropdown.value = false;
});

onClickOutside(fieldsContainerRef, () => {
  showFieldsDropdown.value = false;
});

//TODO: pasar esta función a un composable
const insertAtCursor = (text) => {
  const inputEl = inputRef.value;
  if (!inputEl) return;

  // get cursor's position:
  var startPos = inputEl.selectionStart || 0,
    endPos = inputEl.selectionEnd || 0,
    cursorPos = startPos,
    tmpStr = inputEl.value;

  // mark dirty
  inputEl.setAttribute("data-changed", "1");

  // insert:

  const newStr = tmpStr.substring(0, startPos) + text + tmpStr.substring(endPos, tmpStr.length);

  emit("update:modelValue", newStr);

  // move cursor:
  setTimeout(() => {
    cursorPos += text.length;
    inputEl.selectionStart = inputEl.selectionEnd = cursorPos;
  }, 10);
};

const insertEmoji = (emoji) => {
  if (emoji.native) insertAtCursor(emoji.native);
};

const insertFieldTag = (field: Field) => {
  let tag = field.tag.toLowerCase();

  insertAtCursor(" ${contact." + tag + "} ");
};
</script>

<style>
.emoji-mart {
  user-select: none;
}

.emoji-mart-search {
  padding: 2px 6px;
  margin-bottom: 6px;
}

.emoji-mart-search input {
  font-size: 0.9rem;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer;
}

.emoji-mart-category-label span {
  font-size: 0.8rem;
}

.has-error input {
  @apply border-red-400 placeholder-red-300 focus:border-red-500 focus:ring-red-500;
}
</style>

<i18n lang="json">
{
  "es": {
    "insertEmoji": "Insertar emoji",
    "insertField": "Insertar campo",
    "selectEmoji": "Selecciona un emoji"
  },
  "pt": {
    "insertEmoji": "Insira emoji",
    "insertField": "Insira campo",
    "selectEmoji": "Selecione um emoji"
  }
}
</i18n>
