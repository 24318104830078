var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='login-panel'>\n    <div class='login-panel-header'>\n        <h1 class='error-title'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"signUp"))) && lookupProperty(stack1,"errorCreatingAccount")), depth0))
    + "\n        </h1>\n        <div class='logo'></div>\n    </div>\n    <div class='login-panel-body'>\n        <h2 class='error-message'>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"errorMessage") || (depth0 != null ? lookupProperty(depth0,"errorMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorMessage","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\n        </h2>\n    </div>\n    <div class='login-panel-footer'>\n        <a href='#' class='btn btn-primary btn-outline' data-action='render-form'>\n            <i class='fa fa-arrow-left'></i>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"back")), depth0))
    + "\n        </a>\n    </div>\n</div>\n<div class='login-help'>\n    "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"login"))) && lookupProperty(stack1,"help")), depth0)) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});