<template>
  <div :class="disabled && 'pointer-events-none cursor-auto'" class="flex select-none items-center gap-3">
    <!-- <div class="flex items-center h-6"> -->
    <CheckBox
      ref="FormCheckBoxComponent"
      :disabled="disabled"
      :model-value="modelValue"
      :error="error"
      @update:model-value="$emit('update:modelValue', $event)"
      @change="UpdateModelValue"
    />
    <!-- </div> -->

    <!-- <div class="ml-3"> -->
    <Label :disabled="disabled" :error="error" class="select-none" @click="ClickUpdateModelValue">
      <slot name="label">
        {{ label }}
      </slot>
    </Label>
    <div :class="disabled ? 'text-gray-400' : 'text-gray-500'" class="text-base">
      <slot />
      <p>{{ description }}</p>
    </div>
    <!-- </div> -->
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

//Components
import CheckBox from "@atoms/CheckBox.vue";
import Label from "@atoms/Label.vue";

//Props
withDefaults(
  defineProps<{
    modelValue?: boolean;
    label?: string;
    description?: string;
    disabled?: boolean;
    error?: boolean;
  }>(),
  {
    modelValue: false,
    label: undefined,
    description: undefined,
    disabled: false,
    error: false,
  },
);

const emit = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
  (e: "update:id", id: string): void;
}>();

const FormCheckBoxComponent = ref<InstanceType<typeof CheckBox> | null>(null);

//Emits
const UpdateModelValue = (ev: Event) => {
  emit("update:modelValue", (ev.target as HTMLInputElement).checked);
};

const ClickUpdateModelValue = () => FormCheckBoxComponent.value?.CheckBoxComponent?.click();
</script>
