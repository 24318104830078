var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\n        <div class=\"col-md-6\">\n            <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"from")), depth0))
    + "\n            </p>\n            <p class='field-data'>\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"fromName") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"from") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </p>\n        </div>\n        <div class=\"col-md-6\">\n            <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"replyTo")), depth0))
    + "\n            </p>\n            <p class='field-data'>\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyToName") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyTo") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":46,"column":23}}})) != null ? stack1 : "")
    + "            </p>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-6\">\n            <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"sendTo")), depth0))
    + "\n            </p>\n            <div id='included-lists-preview' class='selected-items-list'></div>\n        </div>\n        <div class=\"col-md-6\">\n            <p class='field-name'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"exclude")), depth0))
    + "\n            </p>\n            <div id='excluded-lists-preview' class='selected-items-list'></div>\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":99,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"from") : stack1), depth0))
    + ")\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    -\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"from") : depth0)) != null ? lookupProperty(stack1,"replyTo") : stack1), depth0))
    + ")\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h3 class=\"\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":29}}}) : helper)))
    + "\n                    <br/>\n                    <small>\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"descriptionWithDate") || (depth0 != null ? lookupProperty(depth0,"descriptionWithDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"descriptionWithDate","hash":{},"data":data,"loc":{"start":{"line":71,"column":24},"end":{"line":71,"column":49}}}) : helper))) != null ? stack1 : "")
    + "\n                    </small>\n                </h4>\n                <div class='form-group'>\n                    <label>\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"subject")), depth0))
    + "\n                    </label>\n                    <div>\n                        "
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0)) != null ? stack1 : "")
    + "\n                    </div>\n                </div>\n                <div class='form-group'>\n                    <label>\n                        "
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"content")), depth0))
    + "\n                    </label>\n                    <div>\n                    "
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? lookupProperty(stack1,"html") : stack1), depth0)) != null ? stack1 : "")
    + "\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"first")),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":95,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":98,"column":27}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class='form-group'>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"offsetStart")), depth0))
    + "\n                        "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"offset") : depths[1]), depth0))
    + "\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"offsetEnd")), depth0))
    + "\n                    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                    <hr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4 class=\"fields-title campaign-summary  campaign-valid\">\n    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"summaryTitle")), depth0))
    + "\n    <small>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"readyToSend")), depth0))
    + "\n    </small>\n</h4>\n\n<div class=\"launch-actions\">\n    <button type='button' class='btn btn-primary' data-action='launch' data-confirm-launch='true'>\n        <i class='fa fa fa-paper-plane'></i>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"init")), depth0))
    + "\n    </button>\n\n    <button type='button' class='btn btn-primary btn-outline' data-action='open-send-test-modal'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"drips"))) && lookupProperty(stack1,"sendTest")), depth0))
    + "\n    </button>\n</div>\n\n<hr>\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":0},"end":{"line":102,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});