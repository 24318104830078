// Utils
import { ok, err } from "neverthrow";
import { isAfter } from "date-fns";

//I18n
import { getCurrentLocale } from "@locales/i18n";

// Services
import { useLivestormService } from "@/vue/services";

// Types
import type { LiveStormEventSession } from "@domain/livestorm";
import type { LivestormApplication } from "./livestorm.types";

export const useLivestormApplication = (): LivestormApplication => {
  const livestormService = useLivestormService();

  return {
    async getEvents(params) {
      const [events, sessions] = await Promise.all([
        livestormService.getEvents({ schedulingStatus: params.status }),
        livestormService.getSessions({
          schedulingStatus: params.status,
        }),
      ]);

      if (events.isErr() || sessions.isErr()) {
        return err({
          type: "NO_DATA",
        });
      }

      const currentLocale = getCurrentLocale();

      const filteredEvents = events.value.filter((event) => {
        const languageFilter = event.language.slice(0, 2) === currentLocale.slice(0, 2);
        const filterPublic = params.public ? event.public : true;

        return languageFilter && filterPublic;
      });

      const sessionsOrderedByDate = sessions.value.sort((a, b) => {
        return isAfter(a.nextEventDate, b.nextEventDate) ? 1 : -1;
      });

      const filteredSessionByEvent = sessionsOrderedByDate.filter((session, index, self) => {
        return self.findIndex((s) => s.eventId === session.eventId) === index;
      });

      const eventSessions = filteredSessionByEvent.reduce((eventSessions, session) => {
        const eventFound = filteredEvents.find((event) => event.id === session.eventId);

        if (!eventFound) return eventSessions;

        let formattedTitle = "";
        let formattedTag = "";

        const closingBracketIndex = eventFound.title.indexOf("]");
        if (closingBracketIndex === -1) {
          formattedTitle = eventFound.title;
        } else {
          formattedTag = eventFound.title.substring(1, closingBracketIndex);
          formattedTitle = eventFound.title.substring(closingBracketIndex + 2);
        }

        eventSessions.push({
          event_id: eventFound.id,
          link: session.link,
          nextEventDate: session.nextEventDate,
          session_id: session.id,
          status: eventFound.schedulingStatus,
          title: formattedTitle,
          description: eventFound.description.replace(/<[^>]+>/g, ""),
          tag: formattedTag,
          owner: {
            avatar: eventFound.owner.avatar,
            name: eventFound.owner.name,
          },
        });

        return eventSessions;
      }, [] as LiveStormEventSession[]);

      return ok(eventSessions);
    },
  };
};
