<template>
  <form id="payment-form" class="space-y-6" @submit.prevent="onSubmit">
    <div class="grid grid-cols-1 gap-6">
      <div v-if="!isMasterUser" :id="paymentElementId">
        <!-- Elements will create form elements here -->
      </div>
      <AlertBox v-else theme="warning">
        Los Master Users no pueden cargar tarjetas de crédito en Stripe. La carga de los datos de la tarjeta la debe
        hacer el usuario final.
      </AlertBox>
    </div>
    <AlertBox v-if="submitError" theme="warning">
      {{ submitError }}
      <a href="#" @click.prevent="contactSupport">{{ t("notify.contactSupport") }}</a>
    </AlertBox>

    <div class="mt-8 flex justify-between space-x-10">
      <p></p>
      <div class="flex flex-shrink-0 items-start justify-end space-x-4">
        <SimpleButton v-if="cancelable" theme="white" @click="$emit('cancel')">
          {{ t("cancel") }}
        </SimpleButton>
        <SimpleButton theme="primary" type="submit" :loading="isValidating || loading"
          :disabled="loading || stripeError || isMasterUser">
          <template #leading>
            <LockClosedIcon />
          </template>
          {{ t("saveCard") }}
        </SimpleButton>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { LockClosedIcon } from "@heroicons/vue/solid";

import Intercom from "@helpers/intercom";
import { useCreditCards } from "@api/modules/creditcards";
import { useStripe } from "@api/modules/stripe";
import { useNotifications } from "@composables/notifications";

import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

import { loadStripe } from "@stripe/stripe-js";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const props = defineProps({
  country: {
    type: String,
    default: "us",
  },
  cancelable: {
    type: Boolean,
    default: false,
  },
  paymethod: {
    type: String,
    default: undefined,
  },
});

const emit = defineEmits(["cardCreated", "cancel"]);

const { t } = useI18n();
const { notify } = useNotifications();
const CreditCards = useCreditCards();
const StripeAPI = useStripe();

const isValidating = ref(false);
const stripeError = ref(false);
const loading = ref(true);
const submitError = ref("");

let stripeSDK, stripeElements, paymentElement;

const isMasterUser = false && session.value?.isMasterUser;
const paymentElementId = "payment-element-" + new Date().getTime();

onMounted(async () => {
  if (isMasterUser || !session.value) {
    loading.value = false;
    return;
  }
  try {
    const setupIntent = await StripeAPI.setupIntent(
      {
        email: session.value?.user.email,
        locale: session.value?.lang === "pt" ? "pt-BR" : "es-419",
      },
      props.country
    );

    const stripeKey = props.country === "br" ? window.config.stripeKeyBR : (props.country === "mx" ? window.config.stripeKeyMX : window.config.stripeKey);

    stripeSDK = await loadStripe(stripeKey);
    if (!stripeSDK) {
      throw new Error("stripeSDK init error");
    }

    stripeElements = stripeSDK.elements({
      clientSecret: setupIntent.client_secret,
    });

    // Create and mount the Payment Element
    paymentElement = stripeElements.create("payment");
    paymentElement.mount("#" + paymentElementId);

    paymentElement.on("loaderror", (event) => {
      console.error("Stripe loaderror", event);
      submitError.value = t("notify.initError");
    });
    loading.value = false;
  } catch (error: any) {
    console.error("Stripe init error", error);
    stripeError.value = true;
    submitError.value = t("notify.initError");
  }
});

const onSubmit = async () => {
  try {
    isValidating.value = true;

    // console.log(stripeElements);

    if (!stripeSDK) {
      return;
    }

    const { setupIntent, error } = await stripeSDK.confirmSetup({
      elements: stripeElements,
      redirect: "if_required",
    });

    if (!error) {
      // console.log(setupIntent);
      const newCard = await CreditCards.createStripe({ setup_intent: setupIntent.id }, props.country, props.paymethod);

      notify({
        title: t("notify.success"),
      });

      emit("cardCreated", newCard);
    } else {
      console.error(error);
      submitError.value = error.message;
    }
  } catch (error: any) {
    console.error(error);
    if (!(error.response || error.request) || error.response?.status === 400) {
      submitError.value = t("notify.error");
    }
  } finally {
    isValidating.value = false;
  }
};

const contactSupport = () => {
  Intercom.showNewMessage(t(`notify.helpMessage`));
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "saveCard": "Guardar tarjeta",
    "acceptTerms": "Al hacer click en \"Guardar tarjeta\", autorizas a Perfit a realizar cobros a la tarjeta ingresada de acuerdo a los términos de servicio del plan contratado.",
    "notify": {
      "success": "Tarjeta almacenada con éxito.",
      "error": "No pudimos validar la tarjeta ingresada. Verifica que todos los datos sean correctos y vuelve a intentar.",
      "initError": "Ocurrió un error al intenta iniciar el proceso de pago. Por favor intenta nuevamente.",
      "contactSupport": "Si necesitas ayuda para realizar el pago contactános.",
      "helpMessage": "Hola, estoy intentando guardar los datos de mi tarjeta para realizar un pago, pero veo un error de validación. Podrían ayudarme?"
    },
    "name": {
      "label": "Nombre y apellido",
      "placeholder": "Juan Pérez",
      "hint": "Nombre del titular, tal como figura en la tarjeta.",
      "errors": {
        "required": "Ingresa el nombre tal como figura en la tarjeta."
      }
    },
    "email": {
      "label": "Dirección de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Casilla donde recibirás las notificaciones de pagos.",
      "errors": {
        "required": "Debes ingresar la casilla donde recibir las notificaciones.",
        "email": "La dirección de email ingresada es inválida."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo y número de documento del titular.",
      "errors": {
        "required": "Ingresa el tipo y número de documento del titular.",
        "invalid": "El número documento ingresado es inválido."
      }
    },
    "card": {
      "label": "Datos de la tarjeta",
      "hint": "Número, vencimiento y código de seguridad."
    },
    "number": {
      "errors": {
        "required": "Debes ingresar el número de tarjeta.",
        "invalid": "El número de tarjeta ingresado es inválido.",
        "amex": "Para pagar con tarjetas American Express por favor contáctanos."
      }
    },
    "expiration": {
      "errors": {
        "required": "Debes ingresar la fecha de vencimiento.",
        "invalid": "La fecha de vencimiento ingresada es inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Debes ingresar el código de seguridad.",
        "invalid": "El código de seguridad ingresado es inválido."
      }
    }
  },
  "pt": {
    "saveCard": "Salvar cartão",
    "acceptTerms": "Ao clicar em \"Salvar cartão\", você autoriza Perfit a realizar cobranças no cartão cadastrado conforme os termos e condições de serviço do plano contratado.",
    "notify": {
      "success": "Cartão salvo com sucesso.",
      "error": "Não foi possível validar o cartão cadastrado. Verifique que todos os dados sejam corretos e tente novamente.",
      "initError": "Ocorreu um erro ao tentar iniciar o processo de pagamento. Por favor, tente novamente",
      "contactSupport": "Se o problema persistir, entre em contato conosco.",
      "helpMessage": "Olá, estou tentando cadastrar meu cartão para realizar um pagamento, mas vejo uma mensagem de erro de validação. Podem me ajudar?"
    },
    "name": {
      "label": "Nome e sobrenome",
      "placeholder": "Maria Souza",
      "hint": "Nome do titular, como está escrito no cartão.",
      "errors": {
        "required": "Insira o nome como está escrito no cartão."
      }
    },
    "email": {
      "label": "Endereço de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Endereço onde você receberá as notificações de pagamento.",
      "errors": {
        "required": "Você deve cadastrar um email para receber as notificações.",
        "email": "O endereço de email inserido é inválido."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo e número de documento do titular.",
      "errors": {
        "required": "Insira o tipo e número de documento do titular.",
        "invalid": "O número do documento inserido é inválido."
      }
    },
    "card": {
      "label": "Dados do cartão",
      "hint": "Número, vencimento e código de segurança."
    },
    "number": {
      "errors": {
        "required": "Você deve inserir o número do cartão.",
        "invalid": "O número do cartão inserido é inválido."
      }
    },
    "expiration": {
      "errors": {
        "required": "Você deve inserir a data de validade.",
        "invalid": "A data de validade inserida é inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Você deve inserir o código de segurança.",
        "invalid": "O código de segurança inserido é inválido."
      }
    }
  }
}
</i18n>
