<script setup lang="ts">
import { PropType, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@composables/breakpoints";
import { DashboardData } from "@domain/dashboard/dashboard";

import AlertBox from "@atoms/AlertBox.vue";
import SectionHeader from "@atoms/SectionHeader.vue";
import MetricCard from "@molecules/MetricCard.vue";
import DashboardChartContacts from "@organisms/Dashboard/DashboardChartContacts.vue";

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const { t, n, d } = useI18n();
const { smBp } = useBreakpoints();

//TODO: esto se repite entre dashboards, reutilizarlo
const sn = (value: number | undefined, format: string): string => n(value || 0, format);
const prevPeriod = computed(() =>
  props.data ? `${d(props.data.period.prevFrom, "short")} - ${d(props.data.period.prevTo, "short")}` : ""
);
</script>

<template>
  <div :class="!pdfMode && 'mb-8'" class="mx-auto max-w-7xl">
    <AlertBox
      v-if="data?.contacts.currentDataPoints === 0"
      :data-html2canvas-ignore="pdfMode"
      theme="info"
      class="mb-4"
    >
      {{ data?.period.isUntilLastDay() ? t("emptyPeriodNewAccount") : t("emptyPeriod") }}
    </AlertBox>
    <SectionHeader>{{ t("mainMetrics.header") }}</SectionHeader>
    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <MetricCard
        :variation="data?.contacts.total.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.total.variationTooltip', {
                amount: sn(data?.contacts.total.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :info-tooltip="t('mainMetrics.total.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.total.title") }}</template>
        <template #value> {{ n(data?.contacts.total.current.metric || 0, "decimal") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.total.comment">
            <template #perc>
              {{
                sn((data?.contacts.enabled.current.metric || 0) / (data?.contacts.total.current.metric || 0), "percent")
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.contacts.engaged.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.engaged.variationTooltip', {
                amount: sn(data?.contacts.engaged.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :info-tooltip="t('mainMetrics.engaged.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.engaged.title") }}</template>
        <template #value> {{ n(data?.contacts.engaged.current.metric || 0, "decimal") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.engaged.comment">
            <template #perc>
              {{
                sn((data?.contacts.engaged.current.metric || 0) / (data?.contacts.total.current.metric || 0), "percent")
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.contacts.new.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.new.variationTooltip', {
                amount: sn(data?.contacts.new.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :info-tooltip="t('mainMetrics.new.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.new.title") }}</template>
        <template #value> {{ n(data?.contacts.new.current.metric || 0, "decimal") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.new.comment">
            <template #perc>
              {{
                data?.contacts.total.previous.metric
                  ? sn((data?.contacts.new.current.metric || 0) / data?.contacts.total.previous.metric, "percent")
                  : ""
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.contacts.asleep.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.asleep.variationTooltip', {
                amount: sn(data?.contacts.asleep.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :variation-inverse="true"
        :info-tooltip="t('mainMetrics.asleep.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.asleep.title") }}</template>
        <template #value> {{ n(data?.contacts.asleep.current.metric || 0, "decimal") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.asleep.comment">
            <template #perc>
              {{
                sn((data?.contacts.asleep.current.metric || 0) / (data?.contacts.total.current.metric || 0), "percent")
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.contacts.neverEngaged.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.neverEngaged.variationTooltip', {
                amount: sn(data?.contacts.neverEngaged.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :variation-inverse="true"
        :info-tooltip="t('mainMetrics.neverEngaged.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.neverEngaged.title") }}</template>
        <template #value>
          {{ n(data?.contacts.neverEngaged.current.metric || 0, "decimal") }}
        </template>
        <template #description>
          <i18n-t keypath="mainMetrics.neverEngaged.comment">
            <template #perc>
              {{
                sn(
                  (data?.contacts.neverEngaged.current.metric || 0) / (data?.contacts.total.current.metric || 0),
                  "percent"
                )
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>

      <MetricCard
        :variation="data?.contacts.disabled.variation"
        :variation-tooltip="
          data?.contacts.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('mainMetrics.disabled.variationTooltip', {
                amount: sn(data?.contacts.disabled.previous.metric, 'decimal'),
                period: prevPeriod,
              }) + (data?.contacts.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.contacts.isPreviousIncomplete"
        :variation-inverse="true"
        :info-tooltip="t('mainMetrics.disabled.tooltip')"
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.disabled.title") }}</template>
        <template #value>
          {{ n(data?.contacts.disabled.current.metric || 0, "decimal") }}
        </template>
        <template #description>
          <i18n-t keypath="mainMetrics.disabled.comment">
            <template #perc>
              {{
                sn(
                  (data?.contacts.disabled.current.metric || 0) / (data?.contacts.total.current.metric || 0),
                  "percent"
                )
              }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
    </div>
    <DashboardChartContacts v-if="smBp" class="mt-4" :data="data" :loading="loading" />
  </div>
</template>

<i18n lang="jsonc">
{
  "es": {
    "emptyPeriod": "No contamos con datos sobre la actividad de tus contactos en el período indicado. Intenta seleccionado un rango mas reciente.",
    "emptyPeriodNewAccount": "Aún no contamos con información sobre tus listas. En cuanto comiences a captar nuevos contactos mediante formularios, integraciones o importación manual, aquí verás cómo crecen tus listas y cuál es su nivel de interacción.",
    "emptyPreviousPeriod": "No se dispone de datos sobre el período anterior.",
    "partialPreviousPeriod": "<br/><br/>*No se dispone de datos completos sobre el período anterior. Es posible que la comparación no sea precisa.",
    "mainMetrics": {
      "header": "Métricas principales de tus contactos",
      "total": {
        "title": "Contactos totales",
        "tooltip": "Todos los contactos que existen en tus listas, incluyendo los inhabilitados.",
        "comment": "{perc} de los contactos se encontraban habilitados para recibir tus emails al final del período.",
        "variationTooltip": "En comparación con los {amount} contactos totales al final del período anterior ({period})."
      },
      "engaged": {
        "title": "Contactos activos",
        "tooltip": "Contactos habilitados que registraron interacción con tus emails.<br/><br/>Consideramos a un contacto activo cuando abrió al menos uno de los últimos 15 emails enviados.",
        "comment": "{perc} de los contactos registraban interacción reciente con tus emails al final del período.",
        "variationTooltip": "En comparación con los {amount} contactos activos al final del período anterior ({period})."
      },
      "new": {
        "title": "Contactos nuevos",
        "tooltip": "Contactos que fueron añadidos a tus listas durante este período.",
        "comment": "Durante este período, los nuevos contactos hicieron crecer tus listas un {perc}.",
        "variationTooltip": "En comparación con los {amount} contactos nuevos del período anterior ({period})."
      },
      "asleep": {
        "title": "Contactos dormidos",
        "tooltip": "Contactos habilitados que alguna vez tuvieron actividad, pero dejaron de interactuar con tus emails.<br/><br/>Consideramos a un contacto dormido cuando no abrió ninguno de los últimos 15 emails enviados.",
        "comment": "{perc} de los contactos habían dejado de interactuar con tus emails al final del período.",
        "variationTooltip": "En comparación con los {amount} contactos dormidos al final del período anterior ({period})."
      },
      "neverEngaged": {
        "title": "Contactos sin actividad",
        "tooltip": "Contactos que nunca tuvieron interacción con tus emails.",
        "comment": "{perc} de los contactos no habían registrado interacción alguna con tus emails al final del período.",
        "variationTooltip": "En comparación con los {amount} contactos sin actividad al final del período anterior ({period})."
      },
      "disabled": {
        "title": "Contactos inhabilitados",
        "tooltip": "Contactos que ya no pueden recibir tus emails por haberlos marcado como spam, solicitar la desuscripción, o porque los intentos de entrega fueron rechazados.",
        "comment": "{perc} de los contactos estaban inhabilitados para recibir tus emails al final del período.",
        "variationTooltip": "En comparación con los {amount} contactos inhabilitados al final del período anterior ({period})."
      }
    }
  },
  "pt": {
    "emptyPeriod": "Não contamos com dados sobre a atividade dos seus contatos no período indicado. Tente selecionar um período mais recente.",
    "emptyPeriodNewAccount": "Ainda não contamos com informação sobre suas listas. Quando começar a captar novos contatos mediante formulários, integrações ou importação manual, aqui você verá como crescem suas listas e qual é o seu nível de interação.",
    "emptyPreviousPeriod": "Não há dados disponíveis do período anterior",
    "partialPreviousPeriod": "<br/><br/>*Não temos dados completos sobre o período anterior. É possível que a comparação não seja precisa.",
    "mainMetrics": {
      "header": "Métricas principais dos seus contatos",
      "total": {
        "title": "Contatos totais",
        "tooltip": "Todos os contatos que existem em suas listas, incluindo os desativados.",
        "comment": "{perc} dos contatos se encontravam habilitados para receber seus emails no final do período.",
        "variationTooltip": "Em comparação com {amount} contatos totais no final do período anterior ({period})."
      },
      "engaged": {
        "title": "Contatos ativos",
        "tooltip": "Contatos habilitados que registraram interação com seus emails.<br/><br/>Consideramos um contato como ativo quando ele abriu ao menos um dos últimos 15 emails enviados",
        "comment": "{perc} dos contatos registravam interação recente com seus emails no final do período.",
        "variationTooltip": "Em comparação com {amount} contatos ativos no final do período anterior ({period})."
      },
      "new": {
        "title": "Contatos novos",
        "tooltip": "Contatos adicionados às suas listas durante este período.",
        "comment": "Durante este período, os novos contatos fizeram suas listas crescerem {perc}.",
        "variationTooltip": "Em comparação com {amount} novos contatos do período anterior ({period})."
      },
      "asleep": {
        "title": "Contatos que deixaram de interagir ",
        "tooltip": "Contatos habilitados que apresentaram atividade, mas pararam de interagir com seus emails.<br/><br/>Consideramos que um contato deixou de interagir quando ele não abriu nenhum dos últimos 15 emails enviados.",
        "comment": "{perc} dos contatos deixaram de interagir com seus emails no final do período.",
        "variationTooltip": "Em comparação com {amount} contatos dormidos no final do período anterior ({period})."
      },
      "neverEngaged": {
        "title": "Contatos sem interação",
        "tooltip": "Contatos que nunca interagiram com seus emails.",
        "comment": "{perc} de contatos não registraram nenhuma interação com seus emails no final do período.",
        "variationTooltip": "Em comparação com {amount} contatos sem atividade no final do período anterior ({period})."
      },
      "disabled": {
        "title": "Contatos desativados",
        "tooltip": "Contatos que não podem mais receber seus emails, pois marcaram seus envios como spam, solicitaram cancelar a inscrição ou porque as tentativas de entrega foram rejeitadas.",
        "comment": "{perc} dos contatos estavam inabilitados para receber seus emails no final do período.",
        "variationTooltip": "Em comparação com {amount} contatos inabilitados no final do período anterior ({period})."
      }
    }
  }
}
</i18n>
