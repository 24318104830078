import { _ } from "@/helpers/utils";
import filtersTpl from "./templates/index.hbs";
import itemsTpl from "./templates/items.hbs";

export default {
  renderFilters: function () {
    var target = $("[data-role=filters]"),
      filters = this.collection.filters,
      template = filtersTpl;
    _.values(filters)
      .reverse()
      .forEach(
        function (filter) {
          var remove = _.isUndefined(filter.remove)
            ? ["filter." + filter.key]
            : filter.remove;
          target.prepend(
            template({
              loading: window.lang.loading,
              key: filter.key,
              icon: filter.icon,
              name: filter.name(),
              remove: JSON.stringify(remove),
            })
          );
        }.bind(this)
      );
  },
  loadFilter: function (e) {
    var trigger = $(e.currentTarget),
      params = trigger.data("params"),
      target = trigger.next(),
      template = itemsTpl,
      filter = this.collection.filters[params.filter];
    e.preventDefault();
    if (trigger.data("loaded")) {
      this.focusFilterSearch(target);
    } else {
      filter.source().then(
        function (response) {
          var items = response.data.map(
            function (item) {
              return filter.parser(item);
            }.bind(this)
          );
          trigger.data("loaded", true);
          target.html(template({ lang: window.lang, items: items }));
          this.focusFilterSearch(target);
        }.bind(this)
      );
    }
  },
  filter: function (e) {
    var params = $(e.currentTarget).data("params");
    e.preventDefault();
    this.urlFilter(params);
  },
  focusFilterSearch: function (target) {
    setTimeout(function () {
      target
        .find("input")
        .focus()
        .on("click", function (e) {
          e.stopPropagation();
        });
    }, 100);
  },
  resetFilterSearch: function (e) {
    var dropdown = $(e.currentTarget),
      input = dropdown.find("input");
    input.val("").trigger("keyup");
  },
  searchFilter: function (e) {
    var finder = $(e.currentTarget),
      items = $(finder.data("results")),
      noResults = $(finder.data("no-results")),
      search = finder.val().toLowerCase(),
      results = false;

    // Hide elements not matching the keyword
    items.each(function () {
      var item = $(this),
        value = item.data("value");
      if (typeof value === "number") {
        value = value + "";
      }
      if (typeof value === "string") {
        value = value.toLowerCase();
      }
      if (value && value.indexOf(search) >= 0) {
        item.removeClass("hide");
        results = true;
      } else {
        item.addClass("hide");
      }
    });

    // If there ar not matching elements, show the `not item found` message
    if (results) {
      noResults.addClass("hide");
    } else {
      noResults.removeClass("hide");
    }
  },
};
