var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='title'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"notifications")), depth0))
    + "\n        </li>\n        <li class='listing'>\n            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":28,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n        <li class='action'>\n            <button class='btn btn-primary btn-outline' data-action='read-all'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"markAllAsRead")), depth0))
    + "\n            </button>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class='notification "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"priority") : stack1),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":15,"column":44},"end":{"line":15,"column":77}}}))
    + "' data-action='open' data-id='"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-type='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "'>\n                        <div class='description'>\n                            <div class='remove' data-action='read' data-id='"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-toggle=\"tooltip\" data-placement=\"bottom\" title=\"Marcar como leida\">\n                                <i class='icon icon-close'></i>\n                            </div>\n                            <div class='title'>\n                                "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n                            </div>\n                            <div class='details'>\n                                "
    + alias2(__default(require("../../../helpers/handlebars/timeago.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"created") : stack1),{"name":"timeago","hash":{},"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":24,"column":62}}}))
    + "\n                            </div>\n                        </div>\n                    </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='message'>\n            "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"emptyNotifications")), depth0))
    + "\n        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href='#' class='dropdown-toggle' data-toggle='dropdown'>\n    <i class='icon pi-menu-notification'></i>\n    <span class='counter "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"paging") : depth0)) != null ? lookupProperty(stack1,"results") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":68}}})) != null ? stack1 : "")
    + "' data-counter='notifications'>\n        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"paging") : depth0)) != null ? lookupProperty(stack1,"results") : stack1), depth0))
    + "\n    </span>\n</a>\n<ul class='dropdown-menu dropdown-menu-right'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});