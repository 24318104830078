// Types
import { Fonts } from "@/vue/types/fonts";

export function useDefaultFonts(): Fonts {
  return [
    {
      id: "fallback_andale_mono",
      name: "Andale Mono",
      value: "'andale mono',monospace",
    },
    {
      id: "fallback_arial",
      name: "Arial",
      value: "arial,sans-serif",
    },
    {
      id: "fallback_arial_black",
      name: "Arial Black",
      value: "'arial black',sans-serif",
    },
    {
      id: "fallback_book_antiqua",
      name: "Book Antiqua",
      value: "'book antiqua',serif",
    },
    {
      id: "fallback_comic_sans_MS",
      name: "Comic Sans MS",
      value: "'comic sans ms',sans-serif",
    },
    {
      id: "fallback_courier_new",
      name: "Courier New",
      value: "'courier new',monospace",
    },
    {
      id: "fallback_georgia",
      name: "Georgia",
      value: "georgia,serif",
    },
    {
      id: "fallback_helvetica",
      name: "Helvetica",
      value: "helvetica,sans-serif",
    },
    {
      id: "fallback_impact",
      name: "Impact",
      value: "impact,sans-serif",
    },
    {
      id: "fallback_tahoma",
      name: "Tahoma",
      value: "tahoma,sans-serif",
    },
    {
      id: "fallback_terminal",
      name: "Terminal",
      value: "terminal,monospace",
    },
    {
      id: "fallback_times_new_roman",
      name: "Times New Roman",
      value: "'times new roman',serif",
    },
    {
      id: "fallback_trebuchet_ms",
      name: "Trebuchet MS",
      value: "'trebuchet ms',sans-serif",
    },
    {
      id: "fallback_verdana",
      name: "Verdana",
      value: "verdana,sans-serif",
    },
    {
      id: "fallback_century_gothic",
      name: "Century Gothic",
      value: "'Century Gothic',serif",
    },
    {
      id: "fallback_calibri",
      name: "Calibri",
      value: "calibri,serif",
    },
    {
      id: "fallback_lucida_grande",
      name: "Lucida Grande",
      value: "'lucida grande',sans-serif",
    },
    {
      id: "fallback_lucida_sans",
      name: "Lucida Sans",
      value: "'lucida sans',sans-serif",
    },
    {
      id: "fallback_palatino",
      name: "Palatino",
      value: "palatino,sans-serif",
    },
  ];
}
