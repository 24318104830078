<template>
  <GridCard :title="automation.name" :description="automation.description" :highlight-keyword="highlightKeyword">
    <template #footer>
      <div class="flex flex-wrap gap-2">
        <SimpleBadge
          v-if="automation.restricted && !allowRestricted"
          theme="sky"
          :icon="LockClosedIcon"
          :tooltip="t('restricted')"
        ></SimpleBadge>
        <SimpleBadge v-if="isNew(automation)" theme="green" :dot="true">{{ t("new") }}</SimpleBadge>
        <SimpleBadge
          v-if="automation.lifecycle === 'rfm'"
          theme="green"
          :icon="HeartIcon"
          :tooltip="t('lifecycle.rfm')"
        ></SimpleBadge>
        <SimpleBadge
          v-if="['black', 'cyber', 'hotSale'].includes(automation.category)"
          theme="red"
          :tooltip="t(automation.category)"
          >🔥</SimpleBadge
        >
        <SimpleBadge
          v-if="automation.attributes.emails"
          theme="gray"
          :icon="MailIcon"
          :tooltip="t('emails', automation.attributes.emails)"
        >
          x{{ automation.attributes.emails }}
        </SimpleBadge>
        <SimpleBadge
          v-for="requires in automation.requires"
          :key="requires"
          :tooltip="t('worksWith', [t(`integrations.${requires}`)])"
          theme="gray"
          >{{ t(`integrations.${requires}`) }}</SimpleBadge
        >
      </div>
    </template>
  </GridCard>
</template>

<script setup lang="ts">
import type { Automation } from "./catalog/AutomationsCatalog.types";

import { isNew } from "./catalog/AutomationsCatalog";
import { useI18n } from "vue-i18n";

import { LockClosedIcon, MailIcon, HeartIcon } from "@heroicons/vue/solid";
import GridCard from "@molecules/GridCard.vue";
import SimpleBadge from "@atoms/SimpleBadge";

const { t } = useI18n();
defineProps<{
  automation: Automation;
  allowRestricted?: boolean;
  highlightKeyword?: string;
}>();
</script>

<i18n lang="jsonc">
{
  "es": {
    "new": "Nuevo",
    "restricted": "Disponible sólo para planes mensuales.",
    "emails": "Envía hasta 1 email. | Envía hasta {n} emails.",
    "worksWith": "Funciona con {0}.",
    "lifecycle": {
      "rfm": "Funciona con el ciclo de vida de compra."
    },
    "black": "Black Friday",
    "cyber": "Cyber Monday"
  },
  "pt": {
    "new": "Novo",
    "restricted": "Disponível somente para planos mensais.",
    "emails": "Envia até 1 email. | Envia até {n} emails.",
    "worksWith": "Funciona com {0}.",
    "lifecycle": {
      "rfm": "Funciona com o ciclo de vida de compra."
    },
    "black": "Black Friday",
    "cyber": "Cyber Monday"
  }
}
</i18n>
