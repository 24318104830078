<template>
  <TransitionRoot appear :show="open" as="template">
    <Dialog as="div" static :open="open" @close="$emit('close')">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 backdrop-filter backdrop-blur-sm bg-opacity-50 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:py-8 sm:px-10"
            >
              <div v-if="showX" class="absolute top-0 right-0 pt-8 pr-8">
                <button
                  tabindex="-1"
                  type="button"
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  @click="$emit('close')"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="mt-0 mb-6 text-lg font-semibold leading-6 text-gray-600"
                  >
                    <slot name="title">{{ title }}</slot>
                  </DialogTitle>
                  <div>
                    <slot name="content"></slot>
                  </div>
                </div>
              </div>
              <div
                class="flex mt-5 sm:mt-4 sm:flex sm:flex-row-reverse space-x-4 space-x-reverse"
              >
                <slot name="buttons"></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,

    XIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    showX: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  emits: ["close"],
};
</script>
