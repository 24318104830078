import { parseISO, isBefore, endOfMonth } from "date-fns";

export type CardCardSource = "mp" | "stripe";
export type CardCardStatus = "valid" | "invalid";

export type VatIds = "responsable_monotributo" | "consumidor_final" | "iva_exento" | "responsable_inscripto";

export interface AFIPData {
  vat_id: VatIds;
  id: string;
  name?: string;
  full_response: {
    datosGenerales: {
      esSucesion: "NO" | "SI";
      fechaContratoSocial: DateString;
      razonSocial?: string;
      nombre?: string;
      apellido?: string;
      mesCierre: number;
      tipoPersona: string;
      domicilioFiscal: {
        idProvincia: number;
        direccion: string;
        descripcionProvincia: string;
        tipoDomicilio: string;
        codPostal: string;
      };
      tipoClave: string;
      estadoClave: string;
      idPersona: number;
    };
    metadata: {
      servidor: string;
      fechaHora: DateString;
    };
    datosRegimenGeneral: {
      impuesto: Array<{
        descripcionImpuesto: string;
        periodo: number;
        idImpuesto: number;
      }>;
      regimen: Array<{
        periodo: number;
        descripcionRegimen: string;
        idRegimen: number;
        idImpuesto: number;
      }>;
      actividad: Array<{
        idActividad: number;
        periodo: number;
        nomenclador: number;
        descripcionActividad: string;
        orden: number;
      }>;
    };
  };
}

export interface ICreditCard {
  id: string;
  source: CardCardSource;
  status: CardCardStatus;
  last_digits: string;
  expiration_month: number;
  expiration_year: number;
  issuer: string;
  created: string;
}

export interface ICreditCardMercadoPagoCreate {
  token: string;
  issuer_id: string;
  payment_method_id: string;
  email: string;
  cvv: string;
  name: string;
  doc_type: string;
  doc_number: string;
}

export interface ICreditCardPagoUnoCreate {
  token: string;
  issuer_id: string;
  email: string;
  name: string;
  doc_type: string;
  doc_number: string;
  first_six_digits: string;
  expiration_year: string;
  expiration_month: string;
}

export interface ICreditCardStripeCreate {
  setup_intent: string;
}

export class CreditCard {
  id: string;
  source: CardCardSource;
  issuer: string;
  lastDigits: string;
  expirationYear: number;
  expirationMonth: number;
  created: Date;
  status: CardCardStatus;
  isExpired: boolean;
  isExpiring: boolean;

  constructor(creditCard: ICreditCard) {
    this.id = creditCard.id;
    this.source = creditCard.source;
    this.issuer = creditCard.issuer;
    this.lastDigits = creditCard.last_digits;
    this.expirationYear = creditCard.expiration_year;
    this.expirationMonth = creditCard.expiration_month;
    this.status = creditCard.status;
    this.created = parseISO(creditCard.created);

    this.isExpired = isBefore(
      endOfMonth(new Date(this.expirationYear, this.expirationMonth - 1, 1)),
      endOfMonth(new Date())
    );

    this.isExpiring = isBefore(new Date(this.expirationYear, this.expirationMonth - 2, 1), new Date());
  }
}
