// Icons
import {
  DocumentSearchIcon,
  ColorSwatchIcon,
  FilterIcon,
  MinusCircleIcon,
  SwitchHorizontalIcon,
  UserGroupIcon,
  ChartPieIcon,
  CalculatorIcon,
  TrashIcon,
  DownloadIcon,
} from "@heroicons/vue/outline";
import BroomIcon from "@tokens/icons/BroomIcon.vue";
import MergeArrowsIcon from "@tokens/icons/MergeArrowsIcon.vue";
import DivideIcon from "@tokens/icons/DivideIcon.vue";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// I18n
import { useI18n } from "vue-i18n";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { Columns } from "@molecules/Table";
import type { ColumnsIds, ListRows, ListRow } from "./audienceLists.types";
import type { ContactsList } from "@domain/lists";
import type { DataItems, DataItem } from "@domain/data";
import { markRaw } from "vue";

export const getMenuOptions = (): Categories => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);

  return {
    user: [
      {
        key: "newList",
        value: t("menuOptions.newList"),
        icon: UserGroupIcon,
      },
      {
        key: "autoSegments",
        value: t("menuOptions.autoSegments"),
        icon: ChartPieIcon,
      },
    ],
    masteruser: session.value?.isMasterUser
      ? [
          {
            key: "reCalculateTotals",
            value: t("menuOptions.reCalculateTotals"),
            icon: CalculatorIcon,
          },
        ]
      : [],
  };
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  const hasExportPermission = sessionStore.hasPermission("contacts:export");

  return {
    user: [
      {
        key: "clean",
        value: t("listOptions.clean"),
        icon: BroomIcon,
      },
      {
        key: "filter",
        value: t("listOptions.filter"),
        icon: FilterIcon,
      },
      {
        key: "duplicate",
        value: t("listOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "divide",
        value: t("listOptions.divide"),
        icon: DivideIcon,
      },
      {
        key: "sample",
        value: t("listOptions.sample"),
        icon: ColorSwatchIcon,
      },
      ...(hasExportPermission
        ? [
            {
              key: "export",
              value: t("listOptions.export"),
              icon: DownloadIcon,
            },
          ]
        : []),
      ...(session.value?.isMasterUser
        ? [
            {
              key: "analyze",
              value: t("listOptions.analyze"),
              icon: DocumentSearchIcon,
            },
          ]
        : []),
    ],
    delete: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const getSelectionOptions = (items: number): Categories => {
  const { t } = useI18n();
  const sessionStore = useSessionStore();
  const { session } = storeToRefs(sessionStore);
  const hasExportPermission = sessionStore.hasPermission("contacts:export");

  if (items > 1) {
    return {
      user: [
        {
          key: "clean",
          value: t("selectionOptions.clean"),
          icon: BroomIcon,
        },
        {
          key: "intersect",
          value: t("selectionOptions.intersect"),
          icon: SwitchHorizontalIcon,
        },
        ...(session.value?.isMasterUser
          ? [
              {
                key: "analyze",
                value: t("selectionOptions.analyze"),
                icon: DocumentSearchIcon,
              },
            ]
          : []),
        {
          key: "subtract",
          value: t("selectionOptions.subtract"),
          icon: MinusCircleIcon,
        },
        {
          key: "merge",
          value: t("selectionOptions.merge"),
          icon: MergeArrowsIcon,
        },
        ...(hasExportPermission
          ? [
              {
                key: "export",
                value: t("listOptions.export"),
                icon: DownloadIcon,
              },
            ]
          : []),
      ],
    };
  }

  return {
    user: [
      ...(session.value?.isMasterUser
        ? [
            {
              key: "analyze",
              value: t("selectionOptions.analyze"),
              icon: DocumentSearchIcon,
            },
          ]
        : []),
      {
        key: "clean",
        value: t("selectionOptions.clean"),
        icon: BroomIcon,
      },
      {
        key: "sample",
        value: t("selectionOptions.sample"),
        icon: ColorSwatchIcon,
      },
      {
        key: "divide",
        value: t("selectionOptions.divide"),
        icon: DivideIcon,
      },
      {
        key: "duplicate",
        value: t("selectionOptions.duplicate"),
        icon: markRaw(ClipboardDocumentListIcon),
      },
      {
        key: "filter",
        value: t("selectionOptions.filter"),
        icon: FilterIcon,
      },
      ...(hasExportPermission
        ? [
            {
              key: "export",
              value: t("listOptions.export"),
              icon: DownloadIcon,
            },
          ]
        : []),
    ],
  };
};

export const getColumns = (): Columns<ColumnsIds | "options"> => {
  const { t } = useI18n();
  return [
    {
      id: "name",
      text: t("columns.name"),
      sortModes: ["ASC", "DESC"],
      headerTextAlign: "left",
      textAlign: "left",
      headerWidthRem: 42,
    },
    {
      id: "contacts",
      sortKey: "totalContacts",
      text: t("columns.contacts"),
      sortModes: ["DESC", "ASC"],
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "activity",
      text: t("columns.activity"),
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "disabled",
      text: t("columns.disabled"),
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "created",
      text: t("columns.created"),
      sortModes: ["DESC", "ASC"],
      breakpoint: "lg",
      headerTextAlign: "right",
      textAlign: "right",
      headerWidthRem: 10,
    },
    {
      id: "options",
    },
  ];
};

export const listsToRows = (lists: ContactsList): ListRows => {
  return lists.map<ListRow>((list) => ({
    id: list.id.toString(),
    content: {
      name: list.name,
      contacts: list.totalContacts.toString(),
      activity: list.liveContactsP.toString(),
      disabled: list.inactiveContactsP.toString(),
      created: list.created,
      quality: list.quality.toString(),
      options: "",
    },
    data: {
      ...list,
      tags: list.tags.map((t) => ({
        color: "#000",
        id: t,
        name: t,
      })),
    },
  }));
};

export const listTagsToDataItems = (tags: Array<string>): DataItems => {
  return tags.map<DataItem>((tag) => ({
    key: tag,
    value: tag,
  }));
};
