import { ListView } from "@/core/magnet";
import app from "@/app";
import tasksListTpl from "../templates/list.hbs";

export default ListView.extend({
  module: "tasks",
  templates: {
    initial: tasksListTpl,
  },
  gender: "F",
  renderArea: ".tasks",
  useLoader: false,
  hasFilters: false,
  query: {
    limit: 100,
    filter: {
      state: "active",
    },
  },
  events: {
    "click [data-action=cancel-task]": "cancel",
  },
  firstCheck: true,
  original: 0,

  // Runs custom actions after renderization
  afterRenderPage: function () {
    var seconds = 120;

    // Save the original data
    if (this.firstCheck) {
      this.original = this.collection.length;
      this.firstCheck = false;
    }

    // If there are not incomplete tasks, get notifications and refresh each 30 seconds

    // Patch new layout don´t fetch tasks
    const allowUseNewLayout = app.session.attributes?.details?.account?.features.USE_NEW_LAYOUT == "1";

    if (allowUseNewLayout) {
      this.setRefresh(1000000);
    } else {
      if (this.collection.length) {
        this.setRefresh(2);
      } else {
        this.setRefresh(seconds);
      }
    }

    // If there are a different amount of tasks, get notifications
    if (!this.firstCheck && this.collection.length !== this.original) {
      app.instances.singleton("notifications", "list").check();
      this.original = this.collection.length;
    }
  },

  // Refresh the view after a defined amount of seconds
  setRefresh: function (seconds) {
    window.clearInterval(this.timer);
    this.timer = window.setInterval(
      function () {
        if (!app.session.get("isGuest")) {
          this.render();
        }
      }.bind(this),
      seconds * 1000,
    );
  },

  // Pauses the refresh
  pause: function () {
    window.clearInterval(this.timer);
  },

  // Cancel an ongoing task
  cancel: function (e) {
    var button = $(e.currentTarget),
      id = button.data("id");
    e.preventDefault();
    this.pause();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Request cancelation
    $.ajax({
      type: "POST",
      url: "tasks/" + id + "/cancel",
      success: function () {
        this.getModal().modal("hide");
        this.displayMessage(window.lang.tasks.willBeCancelled);
        this.render();
      }.bind(this),
    });
  },
});
