// I18n
import { useI18n } from "vue-i18n";

// Type
import type { OptinsTabs } from "./optins.types";
import type { Items } from "@templates/PageTemplate";

// Icons
import { TrashIcon } from "@heroicons/vue/outline";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { ColumnsIds, OptinsRow, OptinsRows } from "./optins.types";
import type { Columns } from "@molecules/Table";
import type { Optins } from "@domain/optins";

export const getTabs = (): Items<OptinsTabs> => {
  const { t } = useI18n();
  return [
    {
      key: "all",
      value: t("tabs.all"),
    },
  ];
};

export const getColumns = (): Columns<ColumnsIds | "options", ColumnsIds> => {
  const { t } = useI18n();
  return [
    {
      id: "name",
      text: t("columns.name"),
      textMaxWidth: true,
      sortModes: ["ASC", "DESC"],
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "totalsubscriptions",
      text: t("columns.totalsubscriptions"),
      textMaxWidth: true,
      sortModes: ["DESC", "ASC"],
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "lastmonthsubscriptions",
      text: t("columns.lastmonthsubscriptions"),
      textMaxWidth: true,
      sortModes: ["DESC", "ASC"],
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "lastweeksubscriptions",
      text: t("columns.lastweeksubscriptions"),
      textMaxWidth: true,
      sortModes: ["DESC", "ASC"],
      headerTextAlign: "right",
      textAlign: "right",
    },
    {
      id: "options",
      headerWidthRem: 2,
    },
  ];
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const optinsToRows = (optins: Optins): OptinsRows => {
  return optins.map<OptinsRow>((optin) => ({
    id: optin.id.toString(),
    content: {
      name: optin.name,
      totalsubscriptions: optin.subscriptions.total.toString(),
      lastmonthsubscriptions: optin.subscriptions.lastMonth.toString(),
      lastweeksubscriptions: optin.subscriptions.lastWeek.toString(),
      options: "",
    },
    data: optin,
  }));
};
