import { EditView } from "@/core/magnet";
import EmailTemplatesModelApiv3 from "@/modules/emailtemplates/model.apiv3";
import { _, objHas } from "@/helpers/utils";
import app from "@/app";
import { mountComponent } from "@/helpers/vue";
import TemplatePreview from "@organisms/Templates/TemplatePreview/TemplatePreview";
import { useSessionStore } from "@store";

import { useOnboardingStore } from "@store";

export default EditView.extend({
  module: "automations",
  templates: {
    initial: require("../templates/template.hbs"),
  },
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "shown.bs.tab [data-toggle=tab]": "afterTabChange",
      "click [data-action=goto-content-tab]": "gotoContentTab",
      "change [data-action=switch-template]": "switchTemplate",
      "click [data-action=enable]": "enable",
      "click [data-action=disable]": "disable",
      "click [data-action=cancel]": "cancel",
      "click [data-action=cantActivateLifeCycle]": "cantActivateLifeCycle",
      "click [data-action=duplicate]": "duplicate",
      "blur input[data-action=edit-inline]": "inlineUpdateSave",
      "keyup input[data-action=edit-inline]": "inlineUpdateKeyPress",
      "click span[data-action=edit-inline]": "inlineUpdateStart",
    });
  },

  enable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    const sessionStore = useSessionStore();
    const campaignsUpdate = sessionStore.hasPermission("campaigns:update");

    if (!campaignsUpdate) {
      self.displayMessage(window.lang.automations.noUpdatePermissions, 3000, "error");
      return;
    }

    if (this.enableRestricted /*&& !app.session.get('isMasterUser')*/) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.accountBlocked,
        });
      } else if (
        this.item.additionalsData.plan.type !== "MONTHLY" &&
        !this.item.additionalsData.plan.type.includes("CHILD")
      ) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.monthlyPlanRequired,
        });
      }
      return false;
    }

    // Perform API call
    return model.enable({
      success: function (res) {
        self.displayMessage(window.lang.automations.automationEnabled);
        self.reload();

        const onboardingStore = useOnboardingStore();

        const eventPromises = res.data?.options?.types?.map((type) => {
          return onboardingStore.submitUserEvent({
            action: `automation.${type}.enabled`,
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          });
        });

        Promise.all([
          onboardingStore.submitUserEvent({
            action: "automation.enabled",
            data: {
              id: res.data.id,
              name: res.data.name,
            },
          }),
          ...eventPromises,
        ]);
      },
      error: function (err) {
        if (
          err.responseJSON &&
          err.responseJSON.error &&
          ["validation_error", "invalid_operation"].includes(err.responseJSON.error.type)
        ) {
          self.displayMessage(window.lang.automations.enableError, 8000, "error");
        } else {
          self.displayMessage(window.lang.automations.enableErrorUnknown, 8000, "error");
        }
        return false;
      },
    });
  },

  disable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    const sessionStore = useSessionStore();
    const campaignsUpdate = sessionStore.hasPermission("campaigns:update");

    if (!campaignsUpdate) {
      self.displayMessage(window.lang.automations.noUpdatePermissions, 3000, "error");
      return;
    }

    var confirmText = window.lang.automations.confirmDisable,
      button = null,
      titleText = window.lang.automations.disable,
      type = "info",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        // Perform API call
        return model.pause({
          success: function () {
            self.displayMessage(window.lang.automations.automationDisabled);
            self.reload();
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  cancel: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmCancel,
      button = null,
      titleText = window.lang.automations.cancel,
      type = "warning",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return model.cancel({
          success: function () {
            self.displayMessage(window.lang.automations.automationCanceled);
          },
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this),
    );
  },

  cantActivateLifeCycle: function () {
    var self = this;
    return self.displayMessage(window.lang.automations.enableErrorLifeCycle, 8000, "error");
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      template: this.selectedEmailTemplate,
      automationTemplates: this.emailTemplates,
      multiContent: this.emailTemplates.length > 1,
      enableRestricted: this.enableRestricted,
      lang: window.lang,
      config: window.config,
      session: app.session,
    };
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    this.enableRestricted = false;
    if (objHas(this.item, "additionalsData", "plan", "state")) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.enableRestricted = true;
      }
    }
    if (
      app.getTrial() &&
      !app.getTrial().inTrial &&
      this.item.additionalsData.plan.type !== "MONTHLY" &&
      !this.item.additionalsData.plan.type.includes("CHILD") &&
      this.item.attributes.options &&
      this.item.attributes.options.restricted
    ) {
      this.enableRestricted = true;
    }

    this.loadEmailTemplates(
      function () {
        //this.updateEmailStepsPreviews();

        if (this.options.template) {
          this.selectedEmailTemplate = this.getEmailTemplate(this.options.template);
        } else {
          // Select first email template if not defined
          this.selectedEmailTemplate = this.getEmailTemplate();

          app.router.navigateTo(`automations/${this.item.get("id")}/templates/${this.selectedEmailTemplate.get("id")}`);
        }

        callback();
      }.bind(this),
    );
  },

  // Load email templates used by the automation and calls callback when finished
  loadEmailTemplates: function (callback) {
    var templates = [];
    var steps = this.item.get("steps");
    var deferreds = [];

    for (var i = 0; i < steps.length; i++) {
      if (steps[i].type === "msg_email") {
        var emailtpl = new EmailTemplatesModelApiv3({
          id: steps[i].data.template_id,
        });
        templates.push(emailtpl);
        deferreds.push(emailtpl.fetch());
      }
    }

    this.emailTemplates = templates;

    // Executes the callback when all deferreds are done
    $.when.apply(null, deferreds).done(function () {
      callback();
    });
  },

  getEmailTemplate: function (id) {
    var models = this.emailTemplates;

    if (_.isUndefined(id)) {
      return models[0];
    }

    for (var i = 0; i < models.length; i++) {
      if (models[i].get("id") === id) {
        return models[i];
      }
    }
  },

  switchTemplate: function (e) {
    var tplId = e.currentTarget.value;
    app.router.navigateTo("#automations/" + this.item.get("id") + "/templates/" + tplId);
  },

  // Runs custom actions after renderization
  afterRender: function () {
    const self = this;

    if (this.item) {
      const types = this.item.attributes.options.types;
      const isTransactional = types && types.includes('transactional');

      const props = {
        tplId: self.options.template,
        relation: {
          relationId: this.item.get("id"),
          relationName: this.item.get("name"),
          relationType: "automation",
        },
        auditMode: isTransactional ? 'transactional' : "automation",
        notAllowErrors: !!this.item.get("enabled"),
      };

      if (self.options.template) {
        mountComponent(TemplatePreview, props, "#vue-template-preview");
      }
    }
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Returns to the previous page
  back: function () {
    app.router.navigateTo("automations");
  },

  getTitle: function () {
    return this.item.attributes.name;
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Restore original template
    if (self.originalTemplate !== undefined && self.originalTemplate !== self.template) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    if (this.previewTimeout) {
      clearTimeout(this.previewTimeout);
    }

    // Return true to confirm clean up
    return true;
  },

  duplicate: function () {
    this.item.duplicate().done(function (response) {
      app.router.navigateTo(`automations/${response.data.id}`);
    });
  },
});
