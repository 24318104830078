var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='modal-dialog'>\n  <div class='modal-content'>\n    <div class='modal-header'>\n      <button type='button' class='close' data-dismiss='modal'>\n        <span aria-hidden='true'>\n          &times;\n        </span>\n      </button>\n      <h4 class='modal-title'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"addImage")), depth0))
    + "\n      </h4>\n    </div>\n    <div class='modal-body'>\n      <div class='modal-body-padded'>\n        <ul class='nav nav-tabs' role='tablist'>\n          <li class='active'>\n            <a href='#upload-file' data-toggle='tab'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"addFromComputer")), depth0))
    + "\n            </a>\n          </li>\n          <li>\n            <a href='#upload-url' data-toggle='tab'>\n              "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"addFromUrl")), depth0))
    + "\n            </a>\n          </li>\n        </ul>\n        <div class='tab-content'>\n          <div role='tabpanel' class='tab-pane active' id='upload-file'>\n            <div class='upload-container'>\n              <div class='form-group'>\n                <div class='dropzone dropper' data-role='dropzone'>\n                  <h3>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dropFilesHere")), depth0))
    + "\n                  </h3>\n                  <p>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"ifYouPrefer")), depth0))
    + "\n                  </p>\n                  <button type='button' data-role='uploader' class='btn btn-primary btn-outline'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"uploadFile")), depth0))
    + "\n                  </button>\n                </div>\n              </div>\n            </div>\n            <div class='upload-preview'></div>\n          </div>\n          <div role='tabpanel' class='tab-pane' id='upload-url'>\n            <form data-form='import-image'>\n              <input type='hidden' name='type' value='IMAGE'>\n              <div class='form-group'>\n                <label>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"url")), depth0))
    + "\n                </label>\n                <input class='form-control' name='imageUrl' type='url' value='' data-validate='required'\n                  placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"urlPlaceholder")), depth0))
    + "'>\n              </div>\n              <button type='submit' class='btn btn-primary'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"images"))) && lookupProperty(stack1,"import")), depth0))
    + "\n              </button>\n            </form>\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class='modal-footer'>\n      <button type='button' class='btn btn-link' data-dismiss='modal'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"close")), depth0))
    + "\n      </button>\n    </div>\n  </div>\n  <form data-form='upload-file' action='"
    + alias2(((helper = (helper = lookupProperty(helpers,"currentUrl") || (depth0 != null ? lookupProperty(depth0,"currentUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"currentUrl","hash":{},"data":data,"loc":{"start":{"line":71,"column":40},"end":{"line":71,"column":54}}}) : helper)))
    + "' class='hidden' method='POST'>\n    <input type='hidden' name='type' value='IMAGE'>\n    <input type='file' name='image' multiple data-input-type='upload-file'\n      accept='image/gif, image/jpeg, image/jpg, image/png'>\n  </form>\n</div>";
},"useData":true});