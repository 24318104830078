<template>
  <div
    class="grid grid-cols-1 place-content-start overflow-hidden rounded-lg border-2 border-solid border-gray-100 bg-white px-4 py-5 sm:p-6"
  >
    <div :class="[warning ? 'text-red-500' : 'text-gray-500']" class="text-lg font-medium md:text-base">
      <slot name="title"></slot>
      <InfoIcon
        v-if="infoTooltip && smBp && !pdfMode"
        :data-html2canvas-ignore="pdfMode"
        :content="infoTooltip"
        class="ml-1"
      />
    </div>
    <div v-if="loading" class="mt-3 flex animate-pulse">
      <div class="h-5 w-24 rounded-xl bg-gray-100"></div>
      <div class="ml-3 h-5 w-16 rounded-xl bg-gray-100"></div>
    </div>
    <div
      v-else
      :class="[warning ? 'text-red-500' : 'text-gray-900']"
      class="mt-1 flex items-center space-x-4 text-2xl font-semibold"
    >
      <span class="whitespace-nowrap" :class="{ 'text-gray-300': grayed }" data-intercom-target="MetricMainValue">
        <slot name="value"></slot>
      </span>
      <MetricChangeBadge
        :direction="isFinite(variation) ? variation : 0"
        :inverted="variationInverse"
        :tooltip="smBp ? variationTooltip : ''"
      >
        {{ isFinite(variation) ? n(Math.abs(variation), "percent") + (variationInaccurate ? "*" : "") : "N/A" }}
      </MetricChangeBadge>
    </div>
    <div v-if="loading" class="mt-3 animate-pulse">
      <div class="h-4 rounded-xl bg-gray-100"></div>
      <div class="mt-2 h-4 rounded-xl bg-gray-100"></div>
    </div>
    <div
      v-if="barColor !== null && !loading"
      :class="[warning ? 'bg-red-100' : 'bg-gray-100']"
      class="mt-2 h-1.5 w-full rounded-lg"
    >
      <div
        :style="`width: ${barPerc <= 0 ? 1 : barPerc >= 100 ? 100 : barPerc}%`"
        :class="barFillColor"
        class="h-1.5 rounded-lg"
      ></div>
    </div>
    <div
      v-if="$slots.description !== undefined && !loading"
      :class="[warning ? 'text-gray-700' : 'text-gray-400']"
      class="mt-2 text-sm"
    >
      <slot name="description"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, PropType } from "vue";
import { useBreakpoints } from "@composables/breakpoints";
import InfoIcon from "@atoms/InfoIcon.vue";
import MetricChangeBadge from "@atoms/MetricChangeBadge.vue";
import { useI18n } from "vue-i18n";

const { smBp } = useBreakpoints();
const { n } = useI18n();

const props = defineProps({
  variation: {
    type: Number,
    default: 0,
  },
  variationInverse: {
    type: Boolean,
    default: false,
  },
  barPerc: {
    type: Number,
    default: 0,
    validator: (value: number) => {
      return value >= 0 && value <= 100;
    },
  },
  barColor: {
    type: String as PropType<"sky" | "gray" | "red">,
    default: null,
  },
  warning: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  infoTooltip: {
    type: String,
    default: null,
  },
  variationTooltip: {
    type: String,
    default: null,
  },
  variationInaccurate: {
    type: Boolean,
    default: false,
  },
  grayed: {
    type: Boolean,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const barFillColor = computed(() => {
  if (props.warning) return "bg-red-400";
  switch (props.barColor) {
    case "sky":
      return "bg-sky-200";
    case "gray":
      return "bg-gray-200";
    case "red":
      return "bg-red-200";
    default:
      return "bg-gray-200";
  }
});
</script>
