// Domain
import { UnlayerValidator } from "@domain/unlayer";

// Helpers
import { getElementByElementChildren } from "@helpers/DOM";
import { contrast, meetsMinimumPerfitContrast, rgbStringToArray } from "@helpers/colors";

export const footerContrast = (): Array<UnlayerValidator> => {
  const editorBody = document.getElementById("u_body");
  const rows = document.getElementsByClassName("u_row");
  const cols = document.getElementsByClassName("u_column u-col");

  const footer = document.getElementById("unlayer-custom-tool-footer");
  const linkFooter = document.getElementById("unlayer-custom-tool-footer-unsubscribe-link");
  const unsubscribeText = document.getElementById("unlayer-custom-tool-unsubscribe-text");
  const unsubscribeMessage = document.getElementById("p-footer-email-link-formatter");
  const postalAddress = document.getElementById("unlayer-custom-tool-postal-address");
  const perfitAd = document.getElementById("unlayer-custom-tool-perfit-ad");

  const footerRow = rows && footer && getElementByElementChildren(rows, footer);
  const footerRowContainer = footerRow && footerRow.children[0];
  const footerCol = cols && footer && getElementByElementChildren(cols, footer);

  const allElementsFound =
    footer &&
    linkFooter &&
    unsubscribeText &&
    unsubscribeMessage &&
    postalAddress &&
    editorBody &&
    footerRow &&
    footerRowContainer &&
    footerCol;

  if (!allElementsFound) return [] as Array<UnlayerValidator>;

  const editorBackgroundColor = getComputedStyle(editorBody).backgroundColor as RGBString;

  const RowBackgroundColor = getComputedStyle(footerRow).backgroundColor as RGBString;
  const RowContainerBackgroundColor = getComputedStyle(footerRowContainer).backgroundColor as RGBString;
  const ColBackgroundColor = getComputedStyle(footerCol).backgroundColor as RGBString;

  const footerBackgroundColor = getComputedStyle(footer).backgroundColor as RGBString;
  const linkTextColor = getComputedStyle(linkFooter).color as RGBString;
  const unsubscribeTextColor = getComputedStyle(unsubscribeText).color as RGBString;
  const unsubscribeMessageColor = getComputedStyle(unsubscribeMessage).color as RGBString;
  const postalAddressColor = getComputedStyle(postalAddress).color as RGBString;
  const perfitAdColor = perfitAd ? (getComputedStyle(perfitAd).color as RGBString) : undefined;

  const editorRGB = rgbStringToArray(editorBackgroundColor);
  const footerRGB = rgbStringToArray(footerBackgroundColor);
  const RowRGB = rgbStringToArray(RowBackgroundColor);
  const RowContainerRGB = rgbStringToArray(RowContainerBackgroundColor);
  const ColRGB = rgbStringToArray(ColBackgroundColor);

  const linkTextRGB = rgbStringToArray(linkTextColor);
  const unsubscribeTextRGB = rgbStringToArray(unsubscribeTextColor);
  const unsubscribeMessageRGB = rgbStringToArray(unsubscribeMessageColor);
  const postalAddressRGB = rgbStringToArray(postalAddressColor);
  const perfitAdRGB = perfitAdColor ? rgbStringToArray(perfitAdColor) : undefined;

  const footerRGBFormatted = footerRGB.slice(0, 3).map((value) => Number(value)) as [number, number, number];
  const editorRGBFormatted = editorRGB.map((value) => Number(value)) as [number, number, number];
  const RowRGBFormatted = RowRGB.map((value) => Number(value)) as [number, number, number];
  const RowContainerRGBFormatted = RowContainerRGB.map((value) => Number(value)) as [number, number, number];
  const ColRGBFormatted = ColRGB.map((value) => Number(value)) as [number, number, number];

  const linkTextRGBFormatted = linkTextRGB.map((value) => Number(value)) as [number, number, number];
  const unsubscribeTextRGBFormatted = unsubscribeTextRGB.map((value) => Number(value)) as [number, number, number];
  const unsubscribeMessageRGBFormatted = unsubscribeMessageRGB.map((value) => Number(value)) as [
    number,
    number,
    number,
  ];
  const postalAddressRGBFormatted = postalAddressRGB.map((value) => Number(value)) as [number, number, number];
  const perfitAdRGBFormatted = perfitAdRGB
    ? (perfitAdRGB.map((value) => Number(value)) as [number, number, number])
    : undefined;

  const allColorsFormmated =
    editorRGBFormatted &&
    linkTextRGBFormatted &&
    unsubscribeTextRGBFormatted &&
    unsubscribeMessageRGBFormatted &&
    postalAddressRGBFormatted &&
    RowRGBFormatted &&
    ColRGBFormatted;

  if (!allColorsFormmated) return [] as Array<UnlayerValidator>;

  const effectiveBackgroundColor = (() => {
    if (footerRGB[3] !== "0") return footerRGBFormatted;
    if (ColRGB[3] !== "0") return ColRGBFormatted;
    if (RowContainerRGB[3] !== "0") return RowContainerRGBFormatted;
    if (RowRGB[3] !== "0") return RowRGBFormatted;

    return editorRGBFormatted;
  })();

  const contrastRatiolinkText = contrast(effectiveBackgroundColor, linkTextRGBFormatted).toPrecision(3);
  const contrastRatiounsubscribeText = contrast(effectiveBackgroundColor, unsubscribeTextRGBFormatted).toPrecision(3);
  const contrastRatiounsubscribeMessage = contrast(
    effectiveBackgroundColor,
    unsubscribeMessageRGBFormatted,
  ).toPrecision(3);
  const contrastRatiopostalAddress = contrast(effectiveBackgroundColor, postalAddressRGBFormatted).toPrecision(3);
  const contrastRatioperfitAd = perfitAdRGBFormatted
    ? contrast(effectiveBackgroundColor, perfitAdRGBFormatted).toPrecision(3)
    : 21;

  const minimumContrastlinkText = meetsMinimumPerfitContrast(Number(contrastRatiolinkText));
  const minimumContrastunsubscribeText = meetsMinimumPerfitContrast(Number(contrastRatiounsubscribeText));
  const minimumContrastunsubscribeMessage = meetsMinimumPerfitContrast(Number(contrastRatiounsubscribeMessage));
  const minimumContrastpostalAddress = meetsMinimumPerfitContrast(Number(contrastRatiopostalAddress));
  const minimumContrastperfitAd = meetsMinimumPerfitContrast(Number(contrastRatioperfitAd));

  const allTextMeetsMinimumContrast =
    minimumContrastlinkText &&
    minimumContrastunsubscribeText &&
    minimumContrastunsubscribeMessage &&
    minimumContrastpostalAddress &&
    minimumContrastperfitAd;

  if (allTextMeetsMinimumContrast) return [] as Array<UnlayerValidator>;

  return [
    {
      id: "footerColorsContrast",
      icon: "fa-tint",
      severity: "ERROR",
      title: "footer_tool_audit_contrast_title",
      description: "footer_tool_audit_contrast_description",
    },
  ];
};
