import type { ActivityContactOptionsTypes } from "@/vue/types/contacts";

export const mapFilter = (arr: Array<ActivityContactOptionsTypes>): string => {
  let urlParams = "";
  let hasParam = false;
  if (arr.includes("all")) urlParams = "";
  if (arr.includes("trigger")) {
    urlParams += `${hasParam ? "," : ""}automation.triggered`;
    hasParam = true;
  }
  if (arr.includes("send")) {
    urlParams += `${hasParam ? "," : ""}mail.sent`;
    hasParam = true;
  }
  if (arr.includes("interaction")) {
    urlParams += `${hasParam ? "," : ""}mail.opened,mail.clicked,mail.shared`;
    hasParam = true;
  }
  if (arr.includes("lifecycle")) {
    urlParams += `${
      hasParam ? "," : ""
    }lifecycles.rfm.abouttolose.in,lifecycles.rfm.atrisk.in,lifecycles.rfm.cantlose.in,lifecycles.rfm.champion.in,lifecycles.rfm.highpotential.in,lifecycles.rfm.loyalist.in,lifecycles.rfm.recent.in,lifecycles.rfm.neednutring.in`;
    hasParam = true;
  }
  if (arr.includes("store")) {
    urlParams += `${hasParam ? "," : ""}session.finished,cart.created`;
    hasParam = true;
  }
  if (arr.includes("sale")) {
    urlParams += `${hasParam ? "," : ""}order.completed`;
    hasParam = true;
  }
  if (arr.includes("subscription")) {
    urlParams += `${
      hasParam ? "," : ""
    }optin.confirmed,contact.interest.expired,contact.interest.added,contact.interest.removed,contact.list.added,contact.list.removed`;
    hasParam = true;
  }
  if (arr.includes("unsubscribe")) {
    urlParams += `${hasParam ? "," : ""}mail.bounced,mail.unsubscribed`;
    hasParam = true;
  }
  if (arr.includes("whatsapp")) {
    urlParams += `${hasParam ? "," : ""}whatsapp.sent,whatsapp.opened,whatsapp.clicked`;
    hasParam = true;
  }
  return urlParams;
};
