var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"lastCampaign") : stack1),{"name":"with","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":107,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='panel panel-default dashboard-campaign'>\n            <div class='panel-head'>\n              <a data-navigate='campaigns' class=\"right-link\">\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"seeAll")), depth0))
    + "\n                <i class=\"fa fa-chevron-right\"></i>\n              </a>\n              <a data-navigate='metrics/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='title'>\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n              </a>\n              <p class='subtitle'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n              <div class='cta'>\n                <a data-navigate=\"campaigns/new\" class='btn btn-primary' data-require-permission='campaigns:create'>\n                  <i class='fa fa-plus'></i>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"create")), depth0))
    + "\n                </a>\n              </div>\n            </div>\n            <div class='panel-body'>\n              <div class=\"row\">\n                <div class=\"col-md-4\">\n                  <div class=\"stats\">\n                    <div class=\"stat sendings\">\n                      <h3>\n                        "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"sent") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":54}}}))
    + "\n                      </h3>\n                      <small>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"sendings")), depth0))
    + "\n                      </small>\n                    </div>\n                    <div class=\"stat date\">\n                      <h3>\n                        "
    + alias2(__default(require("../../../helpers/handlebars/moment.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"launchDate") : depth0),"D MMM",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":58,"column":53}}}))
    + "\n                      </h3>\n                      <small>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"launchDate")), depth0))
    + "\n                      </small>\n                    </div>\n                  </div>\n                </div>\n                <div class=\"col-md-8\">\n                  <div class=\"row\">\n                    <div class=\"col-md-4\">\n                      <div class=\"pie opened\">\n                        <div class=\"chart\" data-percent=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"openedP") : stack1), depth0))
    + "\" data-bar-color=\"#93C52D\"\n                          data-track-color=\"#DFEFB4\">\n                          <span class=\"percent\">\n                            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"openedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":73,"column":28},"end":{"line":73,"column":61}}}))
    + "%\n                          </span>\n                        </div>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"opened")), depth0))
    + "\n                      </div>\n                    </div>\n                    <div class=\"col-md-4\">\n                      <div class=\"pie clicked\">\n                        <div class=\"chart\" data-percent=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"clickedP") : stack1), depth0))
    + "\" data-bar-color=\"#2FC5A0\"\n                          data-track-color=\"#CEEDE5\">\n                          <span class=\"percent\">\n                            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"clickedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":84,"column":28},"end":{"line":84,"column":62}}}))
    + "%\n                          </span>\n                        </div>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"clicked")), depth0))
    + "\n                      </div>\n                    </div>\n                    <div class=\"col-md-4\">\n                      <div class=\"pie bounced\">\n                        <div class=\"chart\" data-percent=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"bouncedP") : stack1), depth0))
    + "\" data-bar-color=\"#FFA426\"\n                          data-track-color=\"#FFE8D0\">\n                          <span class=\"percent\">\n                            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"metrics") : depth0)) != null ? lookupProperty(stack1,"bouncedP") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":95,"column":28},"end":{"line":95,"column":62}}}))
    + "%\n                          </span>\n                        </div>\n                        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"bounced")), depth0))
    + "\n                      </div>\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"campaignsHelp")), depth0))
    + "\" target=\"_blank\" class=\"helper\">?</a>\n            </div>\n          </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                -\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='panel panel-default dashboard-campaign'>\n            <div class='panel-head'>\n              <p class='title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"lastCampaignTitle")), depth0))
    + "\n              </p>\n              <p class='subtitle'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"noCampaignSubtitle")), depth0))
    + "\n              </p>\n              <div class='cta'>\n                <a data-navigat=\"#campaigns/new\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":118,"column":49},"end":{"line":119,"column":91}}})) != null ? stack1 : "")
    + ">\n                  <i class='fa fa-plus'></i>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"create")), depth0))
    + "\n                </a>\n              </div>\n            </div>\n            <div class='panel-body'>\n              <ul class='steps'>\n                <li "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":127,"column":20},"end":{"line":128,"column":25}}})) != null ? stack1 : "")
    + ">\n                  <a data-navigate='lists/import'>\n                    <p class='intro'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepOneIntro")), depth0))
    + "\n                    </p>\n                    <h3 class='title'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepOneTitle")), depth0))
    + "\n                    </h3>\n                    <p class='subtitle'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepOneSubtitle")), depth0))
    + "\n                    </p>\n                  </a>\n                </li>\n                <li "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":141,"column":105}}})) != null ? stack1 : "")
    + ">\n                  <a "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":142,"column":21},"end":{"line":142,"column":108}}})) != null ? stack1 : "")
    + ">\n                    <p class='intro'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepTwoIntro")), depth0))
    + "\n                    </p>\n                    <h3 class='title'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepTwoTitle")), depth0))
    + "\n                    </h3>\n                    <p class='subtitle'>\n                      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"stepTwoSubtitle")), depth0))
    + "\n                    </p>\n                  </a>\n                </li>\n              </ul>\n              <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"campaignsHelp")), depth0))
    + "\" target=\"_blank\" class=\"helper\">?</a>\n            </div>\n          </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "\n                  class='btn btn-primary' ";
},"10":function(container,depth0,helpers,partials,data) {
    return " class='btn btn-primary disabled' ";
},"12":function(container,depth0,helpers,partials,data) {
    return " class='completed' ";
},"14":function(container,depth0,helpers,partials,data) {
    return " class='available'\n                  ";
},"16":function(container,depth0,helpers,partials,data) {
    return " class='unavailable' ";
},"18":function(container,depth0,helpers,partials,data) {
    return " data-navigate='campaigns/new' ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class='panel panel-default dashboard-plan "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":162,"column":57},"end":{"line":162,"column":75}}}))
    + "'>\n            <div class='panel-head'>\n              <p class='title'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"FREE",{"name":"is","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":165,"column":16},"end":{"line":167,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"EMAILS",{"name":"is","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":16},"end":{"line":174,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"MONTHLY",{"name":"is","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":16},"end":{"line":177,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CHILD_UNCAPPED",{"name":"is","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":16},"end":{"line":180,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CHILD_LIMITED",{"name":"is","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":16},"end":{"line":183,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"state") : depth0),"ACTIVE",{"name":"is","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(36, data, 0),"data":data,"loc":{"start":{"line":185,"column":14},"end":{"line":195,"column":21}}})) != null ? stack1 : "")
    + "              <div class='cta "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"currentAccount"))) && lookupProperty(stack1,"isChild")),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":30},"end":{"line":196,"column":100}}})) != null ? stack1 : "")
    + "'\n                data-parents-only='1'>\n                <a data-navigate='plan' class='btn btn-primary' data-require-permission='account:plan'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"FREE",{"name":"is","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":18},"end":{"line":201,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"EMAILS",{"name":"is","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":18},"end":{"line":204,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"MONTHLY",{"name":"is","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":18},"end":{"line":207,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CHILD_UNCAPPED",{"name":"is","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":18},"end":{"line":210,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"CHILD_LIMITED",{"name":"is","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":18},"end":{"line":213,"column":25}}})) != null ? stack1 : "")
    + "                </a>\n              </div>\n            </div>\n            <div class='panel-body'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"limit") : stack1),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":14},"end":{"line":230,"column":21}}})) != null ? stack1 : "")
    + "              <div class='legend'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNegative.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"available") : stack1),{"name":"isNegative","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(50, data, 0),"data":data,"loc":{"start":{"line":232,"column":16},"end":{"line":268,"column":31}}})) != null ? stack1 : "")
    + "              </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"limit") : stack1),{"name":"unless","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":14},"end":{"line":282,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"renews") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":283,"column":14},"end":{"line":288,"column":21}}})) != null ? stack1 : "")
    + "              <a href=\""
    + alias2(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"planHelp")), depth0))
    + "\" target=\"_blank\" class=\"helper\">?</a>\n            </div>\n          </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeFree")), depth0))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"trial") : depth0)) != null ? lookupProperty(stack1,"inTrial") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":169,"column":16},"end":{"line":173,"column":23}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeEmailsTrial")), depth0))
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeEmails")), depth0))
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeMonthly")), depth0))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeChildUncapped")), depth0))
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"typeChildLimited")), depth0))
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class='subtitle success'>\n                <img class='icon' src='/img/dashboard/active.svg'>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"readyToSend")), depth0))
    + "\n              </p>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class='subtitle error'>\n                <img class='icon' src='/img/dashboard/inactive.svg'>\n                "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"plans"))) && lookupProperty(stack1,"disabledShortLegend")),(depth0 != null ? lookupProperty(depth0,"state") : depth0),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":193,"column":16},"end":{"line":193,"column":72}}}))
    + "\n              </p>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"buy")), depth0))
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"modify")), depth0))
    + "\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class='legend'>\n                <div class='consumed "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"excess") : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":37},"end":{"line":220,"column":75}}})) != null ? stack1 : "")
    + "'>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":221,"column":18},"end":{"line":221,"column":51}}}))
    + " "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"of")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"limit") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":221,"column":70},"end":{"line":221,"column":102}}}))
    + "\n                </div>\n                <div class='progress "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"excess") : stack1),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":223,"column":37},"end":{"line":223,"column":75}}})) != null ? stack1 : "")
    + "'>\n                  <div class='progress-bar' style='width: "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"availableP") : stack1), depth0))
    + "%'></div>\n                </div>\n                <div class='quota'>\n                  "
    + ((stack1 = alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"usedContacts")), depth0)) != null ? stack1 : "")
    + "\n                </div>\n              </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    return " excess ";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='consumed excess'>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"excess") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":234,"column":18},"end":{"line":234,"column":49}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isChildLimited")),{"name":"unless","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":18},"end":{"line":238,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n                <div class='progress excess'>\n                  <div class='progress-bar' style='width: "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"excessP") : stack1), depth0))
    + "%'></div>\n                </div>\n                <div class='quota excess'>\n                  "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"excessSendings")), depth0))
    + "\n                </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"of")), depth0))
    + "\n                  "
    + alias1(__default(require("../../../helpers/handlebars/formatInteger.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"plan"))) && lookupProperty(stack1,"emails"))) && lookupProperty(stack1,"excessLimit")),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":237,"column":18},"end":{"line":237,"column":81}}}))
    + "\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"limited") : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":247,"column":16},"end":{"line":267,"column":23}}})) != null ? stack1 : "");
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='consumed'>\n                  "
    + alias1(__default(require("../../../helpers/handlebars/formatInteger.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"available") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":249,"column":18},"end":{"line":249,"column":52}}}))
    + "\n                </div>\n                <div class='progress'>\n                  <div class='progress-bar' style='width: "
    + alias1(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"emails") : depth0)) != null ? lookupProperty(stack1,"availableP") : stack1), depth0))
    + "%'></div>\n                </div>\n                <div class='quota'>\n                  "
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"availableSendings")), depth0))
    + "\n                </div>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class='consumed'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"unlimited")), depth0))
    + "\n                </div>\n                <div class='progress'>\n                  <div class='progress-bar progress-bar-striped'></div>\n                </div>\n                <div class='quota'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"availableSendings")), depth0))
    + "\n                </div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class='legend'>\n                <div class='consumed'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"unlimited")), depth0))
    + "\n                </div>\n                <div class='progress'>\n                  <div class='progress-bar progress-bar-striped'></div>\n                </div>\n                <div class='quota'>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"activeContacts")), depth0))
    + "\n                </div>\n              </div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span class=\"legend\">\n                "
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"renovationDay")), depth0))
    + "\n                "
    + alias1(__default(require("../../../helpers/handlebars/moment.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"data"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"plan"))) && lookupProperty(stack1,"subscription"))) && lookupProperty(stack1,"periodEnds")),"D [de] MMMM",{"name":"moment","hash":{},"data":data,"loc":{"start":{"line":286,"column":16},"end":{"line":286,"column":91}}}))
    + "\n              </span>\n";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"listsLegend")), depth0))
    + "\n";
},"61":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"emptyListsLegend")), depth0))
    + "\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1),{"name":"with","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":319,"column":14},"end":{"line":360,"column":23}}})) != null ? stack1 : "");
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"row\">\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias1(__default(require("../../../helpers/handlebars/formatInteger.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalContacts") : depth0),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":324,"column":22},"end":{"line":324,"column":53}}}))
    + "\n                    </h3>\n                    <small>\n                      "
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"contacts")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"liveContactsP") : depth0), depth0))
    + " %\n                    </h3>\n                    <small>\n                      "
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"live")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"inactiveContactsP") : depth0), depth0))
    + " %\n                    </h3>\n                    <small>\n                      "
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"inactive")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n              </div>\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class='instructions'>\n                "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"listInstructions")), depth0))
    + "\n              </p>\n              <p class='instructions-link'>\n                "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"listInstructionsLink")), depth0)) != null ? stack1 : "")
    + "\n              </p>\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinsLegend")), depth0))
    + "\n";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"emptyOptinsLegend")), depth0))
    + "\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"optinSubscriptions") : stack1),{"name":"with","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":395,"column":14},"end":{"line":428,"column":23}}})) != null ? stack1 : "");
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"row\">\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":400,"column":22},"end":{"line":400,"column":45}}}))
    + "\n                    </h3>\n                    <small>\n                      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinTotal")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"lastMonth") : depth0),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":410,"column":22},"end":{"line":410,"column":49}}}))
    + "\n                    </h3>\n                    <small>\n                      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinLastMonth")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n                <div class=\"col-md-4\">\n                  <div class=\"stat\">\n                    <h3>\n                      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"lastWeek") : depth0),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":420,"column":22},"end":{"line":420,"column":48}}}))
    + "\n                    </h3>\n                    <small>\n                      "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinLastWeek")), depth0))
    + "\n                    </small>\n                  </div>\n                </div>\n              </div>\n";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class='instructions'>\n                "
    + container.escapeExpression(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinsInstructions")), depth0))
    + "\n              </p>\n              <p class=\"instructions-link\">\n                "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinsInstructionsLink")), depth0)) != null ? stack1 : "")
    + "\n              </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='dashboard section show'>\n  <div class='section-header'>\n    <h1>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"singular")), depth0))
    + "\n    </h1>\n  </div>\n  <div class='content display scrollbar'>\n    <div class='padder'>\n      <div class='row'>\n        <div class='col-md-8'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"lastCampaign") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":19,"column":10},"end":{"line":158,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class='col-md-4'>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"plan") : stack1),{"name":"with","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":10},"end":{"line":292,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n      <div class='row'>\n        <div class='col-md-6'>\n          <div class='panel panel-default dashboard-lists'>\n            <div class='panel-head'>\n              <a data-navigate='lists' class='title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"listsTitle")), depth0))
    + "\n              </a>\n              <p class='subtitle'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.program(61, data, 0),"data":data,"loc":{"start":{"line":303,"column":16},"end":{"line":307,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n              <div class='cta'>\n                <a data-navigate=\"lists/import\" class='btn btn-primary'\n                  data-require-permission='lists:create contacts:import'>\n                  <i class='fa fa-plus'></i>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"importList")), depth0))
    + "\n                </a>\n              </div>\n            </div>\n            <div class='panel-body'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"allContacts") : stack1)) != null ? lookupProperty(stack1,"totalContacts") : stack1),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.program(66, data, 0),"data":data,"loc":{"start":{"line":318,"column":14},"end":{"line":368,"column":21}}})) != null ? stack1 : "")
    + "              <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"listsHelp")), depth0))
    + "\" target=\"_blank\" class=\"helper\">?</a>\n            </div>\n          </div>\n        </div>\n        <div class='col-md-6'>\n          <div class='panel panel-default dashboard-forms'>\n            <div class='panel-head'>\n              <a data-navigate='optins' class='title'>\n                "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinsTitle")), depth0))
    + "\n              </a>\n              <p class='subtitle'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"optinSubscriptions") : stack1),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.program(70, data, 0),"data":data,"loc":{"start":{"line":380,"column":16},"end":{"line":384,"column":23}}})) != null ? stack1 : "")
    + "              </p>\n              <div class='cta'>\n                <a data-navigate=\"optins/new\" class='btn btn-primary' data-require-permission='optins:create'>\n                  <i class='fa fa-plus'></i>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"createOptin")), depth0))
    + "\n                </a>\n              </div>\n            </div>\n            <div class='panel-body'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"optinSubscriptions") : stack1),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":394,"column":14},"end":{"line":436,"column":21}}})) != null ? stack1 : "")
    + "              <a href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"dashboard"))) && lookupProperty(stack1,"optinsHelp")), depth0))
    + "\" target=\"_blank\" class=\"helper\">?</a>\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});