import { ListView } from "@/core/magnet";
import app from "@/app";
import { mountComponent } from "@/helpers/vue";
import AutomationsCatalogModal from "@organisms/Automations/AutomationsCatalogModal";

export default ListView.extend({
  module: "automations",
  templates: {
    initial: require("../templates/list.hbs"),
    rows: require("../templates/rows.hbs"),
    empty: require("../templates/empty.hbs"),
  },
  query: {
    order: "id desc",
    filter: { timestamp: { gtrel: "now-1M" } },
  },
  cache: false,
  limit: 500,
  paginate: true,
  events: function () {
    return $.extend({}, ListView.prototype.events, {
      "click [data-action=open-create-modal]": "openCreateAutomationModal",
      "click [data-action=create-automation]": "createAutomation",
      "click [data-action=show-collection]": "toggleCollectionPane",
      "click [data-action=custom-automation]": "sendIntercomMessage",
      "click [data-action=duplicate]": "duplicate",
    });
  },

  // Hides or show action buttons depending on the number of items checked
  toggleActions: function () {
    var toolbar = this.$("[data-role=toolbar]"),
      none = toolbar.find("[data-type=none]"),
      justOne = toolbar.find("[data-type=just-one]"),
      onePlus = toolbar.find("[data-type=one-plus]"),
      twoPlus = toolbar.find("[data-type=two-plus]"),
      checked = this.getChecked(),
      count = checked.length;

    // Hide buttons when no items are checked
    if (count === 0) {
      none.removeClass("hide");
      justOne.addClass("hide");
      onePlus.addClass("hide");
      twoPlus.addClass("hide");
      toolbar.removeClass("edit-mode");
    }

    // Show actions when just one item is checked
    if (count === 1) {
      none.addClass("hide");
      justOne.removeClass("hide");
      onePlus.removeClass("hide");
      twoPlus.addClass("hide");
      toolbar.addClass("edit-mode");
    }

    // Show actions when two or more items are checked
    if (count > 1) {
      none.addClass("hide");
      justOne.addClass("hide");
      onePlus.removeClass("hide");
      twoPlus.removeClass("hide");
      toolbar.addClass("edit-mode");
    }
  },

  afterRenderPage: function () {
    app.integrations.intercom.event("APP_VISIT_AUTOMATIONS", { lang: app.lang }, true);
  },

  openCreateAutomationModal: function () {
    if (!this.vueAutomationsCatalogModal) {
      this.vueAutomationsCatalogModal = mountComponent(
        AutomationsCatalogModal,
        {
          onCreate: (e) => {
            const source = e.selectedSource;
            const automationName = e.automation.name;
            this.collection
              .copy({
                account: source.account,
                id: source.id,
              })
              .done((response) => {
                this.vueAutomationsCatalogModal.closeModal();
                app.router.navigateTo(`automations/${response.data.id}`);
                app.integrations.amplitude.event(
                  "APP_AUTOMATION_CREATED",
                  {
                    source_id: source.id,
                    source_account: source.account,
                    name: automationName,
                  },
                  true
                );
              });
          },
          onGotoIntegrations: () => {
            this.vueAutomationsCatalogModal.closeModal();
            app.router.navigateTo(`integrations`);
          },
          onGotoLifecycleRFM: () => {
            this.vueAutomationsCatalogModal.closeModal();
            app.router.navigateTo(`dashboard/buyers`);
          },
        },
        "#vue-automations-catalog-modal"
      );
    }

    this.vueAutomationsCatalogModal.openModal();
  },

  createAutomation: function (e) {
    var el = $(e.currentTarget);

    this.collection
      .copy({
        account: el.data("copy-account"),
        id: el.data("copy-id"),
      })
      .done(function (response) {
        app.router.navigateTo(`automations/${response.data.id}`);
      });
  },

  toggleCollectionPane: function (e) {
    var pill = $(e.currentTarget);
    var collection = pill.data("collection");

    $(".collection-pane").hide();
    $(".collection-pane[data-collection=" + collection + "]").show();

    $(".nav-pills > li").removeClass("active");
    pill.closest("li").addClass("active");
  },

  sendIntercomMessage: function () {
    app.integrations.intercom.message(window.lang.automations.requestCustomAutomation);
  },

  duplicate: function () {
    this.getChecked()[0]
      .duplicate()
      .done(function (response) {
        app.router.navigateTo(`automations/${response.data.id}`);
      });
  },
});
