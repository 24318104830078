import { useRequestsAPIV3 } from "@api/requests";

//Types
import {
  TemplateBlockAPI,
  GetTemplateBlocksResponse,
  PostTemplateBlockResponse,
  DeleteTemplateBlockResponse,
  PutTemplateBlockResponse,
} from "./blocks.types";

export function useTemplateBlocks() {
  const request = useRequestsAPIV3();

  async function getBlocks(): Promise<TemplateBlockAPI[]> {
    try {
      const res = await request<GetTemplateBlocksResponse>({
        url: `/templates/blocks`,
        method: "get",
      });

      return res.data.data;
    } catch (e) {
      return [];
    }
  }

  async function getPublicBlocks(lang = "es"): Promise<TemplateBlockAPI[]> {
    try {
      if (lang !== "es" && lang !== "pt") lang = "es";
      const res = await request<GetTemplateBlocksResponse>({
        url: `/templates/blocks/public/${lang}`,
        method: "get",
      });
      return res.data.data;
    } catch (e) {
      return [];
    }
  }

  async function deleteBlock(blockId: string): Promise<void> {
    await request<DeleteTemplateBlockResponse>({
      url: `/templates/blocks/${blockId}`,
      method: "delete",
    });
  }

  async function createBlock(block: TemplateBlockAPI): Promise<TemplateBlockAPI> {
    const res = await request<PostTemplateBlockResponse>({
      url: `/templates/blocks`,
      method: "post",
      data: block,
    });

    return res.data.data;
  }

  async function modifyBlock(block: TemplateBlockAPI): Promise<TemplateBlockAPI> {
    const res = await request<PutTemplateBlockResponse>({
      url: `/templates/blocks/${block.id}`,
      method: "put",
      data: block,
    });

    return res.data.data;
  }

  return {
    getPublicBlocks,
    getBlocks,
    deleteBlock,
    modifyBlock,
    createBlock,
  };
}
