var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " centered ";
},"3":function(container,depth0,helpers,partials,data) {
    return "<a data-navigate=\"campaigns\"><i\n          class='icon icon-back'></i></a>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='toolbar edit-mode'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"state") : stack1),"SENDING",{"name":"is","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "    <button type='button' class='btn btn-default' data-action='open-duplicate-modal'\n      data-require-permission='campaigns:create'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"duplicate")), depth0))
    + "\n    </button>\n    <button type='button' class='btn btn-default' data-action='open-share-modal'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"share")), depth0))
    + "\n    </button>\n  </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='stop' data-confirm=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"confirmStopCampaign")), depth0))
    + "\" data-confirm-title=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"stop")), depth0))
    + "\n    </button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"archived") : stack1),null,{"name":"is","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='archive'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"archive")), depth0))
    + "\n    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class='btn btn-primary' data-action='unarchive'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"unarchive")), depth0))
    + "\n    </button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "'>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/links'>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/links'>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-navigate='metrics/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/activity?filter.type=SENT'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"activity")), depth0))
    + "\n          </a>\n        </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <a\n            data-navigate='shares/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "/campaigns/"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"token")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/contents'>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-navigate='metrics/"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "/contents'>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class='list'>\n          <ul class='table'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"links") : stack1),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":12},"end":{"line":137,"column":21}}})) != null ? stack1 : "")
    + "          </ul>\n        </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n              <div class='main-cell'>\n                <div class='name'>\n                  <p>\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                  </p>\n                  <a target='_blank' href='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "'>\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\n                  </a>\n                </div>\n              </div>\n              <div class='info-cell' data-link='activity?filter.type=CLICK&filter.linkId="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n                <strong>\n                  "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalClicks") : depth0),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":130,"column":18},"end":{"line":130,"column":47}}}))
    + "\n                </strong>\n                <small>\n                  "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"totalClicks")), depth0))
    + "\n                </small>\n              </div>\n            </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class='empty-message'>\n          "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noResults")), depth0))
    + "\n        </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='metrics section show'>\n  <div class='section-header "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":86}}})) != null ? stack1 : "")
    + "'>\n    <h1>\n      "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":6,"column":52}}})) != null ? stack1 : "")
    + "\n      "
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n    </h1>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":35,"column":13}}})) != null ? stack1 : "")
    + "  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <ul class='nav nav-pills'>\n        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":45,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"details")), depth0))
    + "\n            </a>\n        </li>\n        <li class='active'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":55,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"links")), depth0))
    + "\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"unless","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "        <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isGuest")),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":72,"column":21}}})) != null ? stack1 : "")
    + "              "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"contents")), depth0))
    + "\n            </a>\n        </li>\n      </ul>\n      <div id=\"vue-links-heatmap-alertbox\"></div>\n      <div class='links panel panel-default'>\n        <div class='info'>\n          <div class='row'>\n            <div class='col-sm-4'>\n              <div class='data yes'>\n                <strong>\n                  "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clicked") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":84,"column":18},"end":{"line":84,"column":67}}}))
    + "\n                </strong>\n                <p>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"contactsClicks")), depth0))
    + "\n                </p>\n              </div>\n            </div>\n            <div class='col-sm-4'>\n              <div class='data no'>\n                <strong>\n                  "
    + alias3(__default(require("../../../helpers/handlebars/subtract.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"sent") : stack1),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clicked") : stack1),{"name":"subtract","hash":{},"data":data,"loc":{"start":{"line":94,"column":18},"end":{"line":94,"column":91}}}))
    + "\n                </strong>\n                <p>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"contactsNoClicks")), depth0))
    + "\n                </p>\n              </div>\n            </div>\n            <div class='col-sm-4'>\n              <div class='data total'>\n                <strong>\n                  "
    + alias3(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"metrics") : stack1)) != null ? lookupProperty(stack1,"clickedT") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":104,"column":18},"end":{"line":104,"column":73}}}))
    + "\n                </strong>\n                <p>\n                  "
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"metrics"))) && lookupProperty(stack1,"contactsClicksTotal")), depth0))
    + "\n                </p>\n              </div>\n            </div>\n          </div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"additionalsData") : stack1)) != null ? lookupProperty(stack1,"links") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":113,"column":8},"end":{"line":144,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});