<template>
  <svg
    viewBox="0 0 180 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="margin: 0.5rem 1rem"
  >
    <path
      d="M174 1H6C3.23858 1 1 3.23858 1 6V41H179V6C179 3.23858 176.761 1 174 1Z"
      fill="#E3F5FC"
    />
    <path
      opacity="0.675"
      d="M107.838 8.49575C106.272 7.57274 104.328 7.57274 102.762 8.49575L75.022 24.8415C73.4556 25.7645 71.5116 25.7645 69.9453 24.8415L59.7219 18.8174C58.1555 17.8944 56.2116 17.8944 54.6452 18.8174L17 41H47.6001H97.3671H163L107.838 8.49575Z"
      fill="#00ADE8"
    />
    <path
      opacity="0.675"
      d="M26 25C30.9706 25 35 20.9706 35 16C35 11.0294 30.9706 7 26 7C21.0294 7 17 11.0294 17 16C17 20.9706 21.0294 25 26 25Z"
      fill="#00ADE8"
    />
    <rect x="1" y="1" width="178" height="118" rx="4" stroke="#00ADE8" stroke-width="2" />
    <rect x="18" y="64" width="143" height="16" rx="1" stroke="#00ADE8" stroke-width="2" />
    <rect x="18" y="51" width="144" height="5" rx="2.5" fill="#E3F5FC" />
    <rect x="17" y="88" width="145" height="18" rx="2" fill="#00ADE8" />
  </svg>
</template>

<script setup></script>
