<template>
  <div class="flex select-none flex-col items-center space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
    <div v-for="tag in filteredTags" :key="tag.id" ref="filteredTagsRef">
      <SimpleBadge
        :id="tag.id"
        square
        :deletable="!readonly"
        :clickable="clickable"
        :size="tagSize"
        :dot="showTagColors"
        :dot-color="tag.color"
        :tooltip="(el) => handleTooltip(tag)"
        theme="gray"
        @delete="() => removeTag(tag)"
        @click="() => clickTag(tag)"
      >
        <span :id="tag.id" class="max-w-[10rem] truncate">
          {{ tag.name }}
        </span>
      </SimpleBadge>
    </div>
    <div v-show="hiddenTags.length" ref="hiddenTagsRef" class="relative w-min">
      <SimpleBadge square :size="tagSize" clickable theme="gray" :class="[{ 'mt-[6px]': tagSize === 'small' }]">
        <template #icon>
          <PlusIcon v-show="filteredTags.length > 0" class="h-3 w-3 text-gray-500" />
          <TagIcon v-show="filteredTags.length === 0" class="h-4 w-4 text-gray-400" />
        </template>
        <span>
          {{ hiddenTags.length }}
        </span>
      </SimpleBadge>

      <ModalTagsList
        v-show="hiddenTagsIsOpen"
        ref="hiddenTagsListRef"
        :tags="hiddenTags"
        :readonly="readonly"
        :show-tag-colors="showTagColors"
        :clickable="clickable"
        :tag-size="tagSize"
        class="absolute z-10 shadow"
        @delete="removeTag"
        @click="clickTag"
      />
    </div>
    <div v-if="!readonly && !loading" ref="createTagRef" class="relative w-min">
      <TagsDropDown
        :open="tagSelectorIsOpen"
        :selected-tags="selectedTags"
        :tags="tags"
        :loading="loadingTagsOptions"
        @toggleTag="toggleTagSelection"
        @createTag="createTag"
        @toggle="toggleTagSelector"
      >
        <template #button-content>
          <SimpleBadge square :size="tagSize" clickable theme="black-alter">
            <template #icon>
              <PlusIcon class="h-3 w-3 text-white" />
            </template>
            <span class="flex min-w-max" style="line-height: 1.3">{{ t("addTagButton") }}</span>
          </SimpleBadge>
        </template>
      </TagsDropDown>
    </div>
    <div v-else-if="loading" class="mt-2 flex animate-pulse space-x-3">
      <div class="h-4 w-24 rounded bg-gray-50 opacity-25" />
      <div class="h-4 w-24 rounded bg-gray-50 opacity-25" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, nextTick } from "vue";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import { ModalTagsList, TagsDropDown } from "@organisms/Tags";
import { Size } from "@atoms/SimpleBadge";

//Icons
import { PlusIcon, TagIcon } from "@heroicons/vue/solid";

// Utils
import { onClickOutside, useElementHover } from "@vueuse/core";
import { useBreakpoints } from "@composables/breakpoints";
import { useI18n } from "vue-i18n";
import { createPopper, Modifier } from "@popperjs/core";
import type { Placement } from "@popperjs/core";

// Types
import type { Tags, Tag } from "@/vue/types/tag";

const { t } = useI18n();

// Props
interface Props {
  selectedTags: Tags;
  tags?: Tags;
  readonly?: boolean;
  loading?: boolean;
  clickable?: boolean;
  loadingTagsOptions?: boolean;
  showTagColors?: boolean;
  tagSize?: Size;
  maxVisibleTags?: number;
  placement?: Placement;
  autoPlacements?: Array<Placement>;
  modifiers?: Array<Partial<Modifier<any, any>>>;
}

const props = withDefaults(defineProps<Props>(), {
  selectedTags: () => [],
  tags: () => [],
  readonly: false,
  loading: false,
  clickable: false,
  loadingTagsOptions: false,
  showTagColors: true,
  tagSize: "xlarge",
  maxVisibleTags: 4,
  placement: "bottom-start",
  autoPlacements: () => ["bottom-start"],
  modifiers: undefined,
});

// Emits
const emit = defineEmits<{
  (e: "createTag", tag: Tag): void;
  (e: "addTag", tag: Tag): void;
  (e: "removeTag", tag: Tag): void;
  (e: "click", tag: Tag): void;
}>();

const createTag = (tag: Tag) => emit("createTag", tag);
const addTag = (tag: Tag) => emit("addTag", tag);
const removeTag = (tag: Tag) => emit("removeTag", tag);
const clickTag = (tag: Tag) => emit("click", tag);

// Hidden Tags
const hiddenTagsRef = ref();
const hiddenTagsListRef = ref();
let popperInstance: ReturnType<typeof createPopper> | undefined = undefined;
let timeoutInstance: ReturnType<typeof setTimeout> | undefined = undefined;

const openDropDown = () => {
  if (popperInstance) {
    clearTimeout(timeoutInstance);
    timeoutInstance = undefined;
    return;
  }

  popperInstance = createPopper(hiddenTagsRef.value, hiddenTagsListRef.value?.ref, {
    placement: props.placement,
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          altAxis: true,
          boundary: "clippingParents",
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: props.autoPlacements,
          fallbackPlacements: props.autoPlacements,
          altBoundary: true,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
      ...(props.modifiers ?? []),
    ],
  });
  nextTick(() => {
    popperInstance?.forceUpdate();
  });
};

const closeDropDown = () => {
  timeoutInstance = setTimeout(() => {
    if (!popperInstance) return;
    popperInstance.destroy();
    popperInstance = undefined;
  }, 100);
};

onClickOutside(hiddenTagsRef, () => {
  closeHiddenTags();
});

const hiddenTagsIsOpen = ref(false);
const openHiddenTags = () => {
  clearHiddenTagsCloseTimeout();
  hiddenTagsIsOpen.value = true;
  openDropDown();
};

const closeHiddenTags = () => {
  clearHiddenTagsCloseTimeout();
  hiddenTagsIsOpen.value = false;
  closeDropDown();
};

const hiddenTagsIsHover = useElementHover(hiddenTagsRef);

watch([hiddenTagsIsHover], () => {
  if (hiddenTagsIsHover.value) {
    closeTagSelector();
    openHiddenTags();
    return;
  }
  onMouseLeaveHiddenTags();
});

const delayHiddenTagsTimeout = 100;
let hiddenTagsCloseTimeOut;
const clearHiddenTagsCloseTimeout = () => {
  clearTimeout(hiddenTagsCloseTimeOut);
  hiddenTagsCloseTimeOut = undefined;
};

const onMouseLeaveHiddenTags = () => {
  if (hiddenTagsCloseTimeOut) return;

  hiddenTagsCloseTimeOut = setTimeout(() => {
    closeHiddenTags();
    clearHiddenTagsCloseTimeout();
  }, delayHiddenTagsTimeout);
};

// Select Tag
const createTagRef = ref();
onClickOutside(createTagRef, () => {
  closeTagSelector();
});

const tagSelectorIsOpen = ref(false);
const closeTagSelector = () => (tagSelectorIsOpen.value = false);
const toggleTagSelector = () => {
  tagSelectorIsOpen.value = !tagSelectorIsOpen.value;
};

// Tag selection / creation hover
const tagSelectorIsHover = useElementHover(createTagRef);

watch([tagSelectorIsHover], () => {
  if (tagSelectorIsHover.value) {
    closeHiddenTags();
    return;
  }
});

// Tags Change
const toggleTagSelection = (tag: Tag) => {
  const tagFound = props.selectedTags.find((selected) => selected.id === tag.id);
  if (tagFound) return removeTag(tag);
  return addTag(tag);
};

// Max tags filter
const { smBp, mdBp, lgBp, xxlBp } = useBreakpoints();

const tagsToShow = computed(() => {
  if (xxlBp.value) return Math.max(0, props.maxVisibleTags);
  if (lgBp.value) return Math.max(0, props.maxVisibleTags - 1);
  if (mdBp.value) return Math.max(0, props.maxVisibleTags - 2);
  if (smBp.value) return Math.max(0, props.maxVisibleTags - 3);
  return Math.max(0, props.maxVisibleTags - 4);
});

const filteredTags = computed(() => {
  if (props.selectedTags.length === tagsToShow.value) return props.selectedTags;
  return props.selectedTags.slice(0, tagsToShow.value);
});

const hiddenTags = computed(() => {
  if (props.selectedTags.length === tagsToShow.value) return [];

  return props.selectedTags.slice(tagsToShow.value);
});

// Tooltip Handlers
const filteredTagsRef = ref<Array<HTMLElement>>();

const handleTooltip = (tag: Tag): string | undefined => {
  if (!tag.id) return;

  const foundTagElement = filteredTagsRef.value?.find(
    (tagElement) => (tagElement.firstChild as HTMLSpanElement)?.id.toString() === tag.id?.toString(),
  );

  if (!foundTagElement) return;

  const firstChildElement = (foundTagElement.firstChild as HTMLSpanElement).children.namedItem(tag.id);

  if (!firstChildElement) return;

  return firstChildElement.scrollWidth > firstChildElement.clientWidth ? tag.name : undefined;
};
</script>
<i18n lang="json">
{
  "es": {
    "addTagButton": "Agregar etiqueta"
  },
  "pt": {
    "addTagButton": "Adicionar etiqueta"
  }
}
</i18n>
