<template>
  <ListSelector
    v-model:selected="selectedLists"
    :lists="interestLists"
    :open="open"
    :loading-accept="loadingAccept"
    :loading-create="loadingCreate"
    :allow-create="allowCreate"
    :skeleton="loadingLists"
    @close="close"
    @accept="accept"
    @createList="createList"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

import type { InterestListItems, InterestLists } from "./interestSelector.types";
import { interestToItem, interestsToItems } from "./interestSelector.data";

// Components
import ListSelector from "@molecules/ListSelector/ListSelector.vue";

// Icons
import { HeartIcon } from "@heroicons/vue/outline";

// Services
import { useInterests } from "@api/modules/interests/interests";
import { useI18n } from "vue-i18n";

const interestsAPI = useInterests();

const { t } = useI18n();

withDefaults(
  defineProps<{
    open: boolean;
    loadingAccept?: boolean;
    allowCreate?: boolean;
  }>(),
  {
    loadingAccept: false,
    allowCreate: false,
  },
);

// Emits
const emit = defineEmits<{
  close: [void];
  selectLists: [InterestListItems];
}>();

const close = () => {
  emit("close");
};

const accept = () => {
  emit("selectLists", selectedLists.value);
};

const interestLists = ref<InterestLists>([]);
const selectedLists = ref<InterestListItems>([]);

const loadingLists = ref(false);
const loadingCreate = ref(false);
const fetchlimit = 100000;
const fetchLists = async () => {
  loadingLists.value = true;

  const results = await interestsAPI.list({
    limit: fetchlimit,
  });

  if (results.done) {
    loadingLists.value = false;
    return;
  }

  interestLists.value = interestsToItems(results.value, {
    interest: t("interestsListTitle"),
  });

  loadingLists.value = false;
};

const createList = async (interestName: string) => {
  loadingCreate.value = true;
  const newInterest = await interestsAPI.create({ name: interestName });

  const allInterestsList = interestLists.value.find((l) => l.id === "interests");
  if (!allInterestsList) return;

  allInterestsList.list.unshift(interestToItem(newInterest));
  selectedLists.value = [...selectedLists.value, interestToItem(newInterest)];
  loadingCreate.value = false;
};

onMounted(async () => {
  await fetchLists();
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "interestsListTitle": "Intereses"
  },
  "pt": {
    "interestsListTitle": "Intereses"
  }
}
</i18n>
