import { Model } from "@/core/magnet";
import { _ } from "@/helpers/utils";

export default Model.extend({
  urlRoot: "pemapitriggers",
  parse: function (trigger) {
    if (_.isDefined(trigger.data)) {
      trigger = trigger.data;
    }
    return trigger;
  },
});
