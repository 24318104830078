<template>
  <SenderCreatorModal :is-open="openCreatorModal" @close="openCreatorModal = false" @sender-created="senderCreated" />
  <transition
    enter-active-class="duration-300 ease-out"
    leave-active-class="duration-300 ease-in"
    enter-to-class="transform translate-x-0 opacity-100"
    leave-from-class="transform translate-x-0 opacity-100"
    :enter-from-class="
      'transform opacity-0 ' + (viewingSenderAuthDetails || editingSenderDetails ? 'translate-x-20' : '-translate-x-20')
    "
    :leave-to-class="
      'transform opacity-0 ' + (viewingSenderAuthDetails || editingSenderDetails ? '-translate-x-20' : 'translate-x-20')
    "
    mode="out-in"
  >
    <div v-if="!viewingSenderAuthDetails && !editingSenderDetails" class="w-full">
      <AlertBox v-if="domainsByStatus.UNKNOWN.size > 0" theme="danger" class="mb-4">
        {{ t("alert.unknown") }}
        <template #actions>
          <a href="#" @click.prevent="viewDomainAuthDetails([...domainsByStatus.UNKNOWN][0])">{{
            t("alert.moreInfo")
          }}</a>
        </template>
      </AlertBox>

      <AlertBox v-else-if="domainsByStatus.CHANGE_REQUIRED.size === 1" theme="warning" class="mb-4">
        <i18n-t keypath="alert.changeRequired">
          <strong>{{ [...domainsByStatus.CHANGE_REQUIRED][0] }}</strong>
        </i18n-t>
        <!-- <p class="mt-2">
          {{ t("alert.perfitDomain") }}
        </p> -->
        <template #actions>
          <a href="#" @click.prevent="viewDomainAuthDetails([...domainsByStatus.CHANGE_REQUIRED][0])">{{
            t("alert.moreInfo")
          }}</a>
        </template>
      </AlertBox>
      <AlertBox v-else-if="domainsByStatus.CHANGE_REQUIRED.size > 1" theme="warning" class="mb-4">
        {{ t("alert.changesRequired") }}
        <!-- {{ t("alert.perfitDomain") }} -->
        <template #actions>
          <div v-for="domain in domainsByStatus.CHANGE_REQUIRED" :key="domain" class="mt-2">
            <a href="#" @click.prevent="viewDomainAuthDetails(domain)">{{ t("alert.moreInfo") }}: {{ domain }}</a>
          </div>
        </template>
      </AlertBox>
      <AlertBox v-else-if="domainsByStatus.CHANGE_SUGGESTED.size > 0" theme="warning" class="mb-4">
        <i18n-t keypath="alert.changeSuggested">
          <strong>{{ [...domainsByStatus.CHANGE_SUGGESTED][0] }}</strong>
        </i18n-t>
        <template #actions>
          <a href="#" @click.prevent="viewDomainAuthDetails([...domainsByStatus.CHANGE_SUGGESTED][0])">{{
            t("alert.moreInfo")
          }}</a>
        </template>
      </AlertBox>
      <AlertBox v-else-if="domainsByStatus.PUBLIC_DOMAIN.size > 0" theme="warning" class="mb-4">
        {{ t("alert.publicDomain") }}
        <template #actions>
          <a href="#" @click.prevent="viewDomainAuthDetails([...domainsByStatus.PUBLIC_DOMAIN][0])">{{
            t("alert.moreInfo")
          }}</a>
        </template>
      </AlertBox>
      <AlertBox v-else-if="domainsByStatus.OK.size > 0" theme="success" class="mb-4">
        {{ t("alert.ok") }}
      </AlertBox>

      <SimpleTable v-if="senders.size || isLoadingList">
        <template #headers>
          <SimpleTableHeader class="w-[30%]">{{ t("name") }}</SimpleTableHeader>
          <SimpleTableHeader class="w-[33%]">{{ t("email") }}</SimpleTableHeader>
          <SimpleTableHeader class="w-[30%]">{{ t("replyTo") }} </SimpleTableHeader>
          <SimpleTableHeader class="w-[7%]"></SimpleTableHeader>
        </template>

        <template #rows>
          <template v-if="isLoadingList">
            <tr v-for="n in 2" :key="n">
              <td>
                <div class="mx-6 my-4 h-4 animate-pulse rounded-full bg-gray-100" />
              </td>
              <td>
                <div class="mx-6 my-4 h-4 animate-pulse rounded-full bg-gray-100" />
              </td>
              <td>
                <div class="mx-6 my-4 h-4 animate-pulse rounded-full bg-gray-100" />
              </td>
              <td>
                <div class="mx-4 my-4 h-4 w-14 animate-pulse rounded-full bg-gray-100" />
              </td>
            </tr>
          </template>

          <tr v-for="sender in senders.values()" :key="sender.id" class="relative">
            <SimpleTableCell class="px-3 py-2">
              <EditableText
                class="w-full"
                :allow-empty="false"
                :content="sender.name"
                :validation-rules="{ maxLength: maxLengthRule(50) }"
                :loading="updatingSenderField.id === sender.id && updatingSenderField.field === 'name'"
                @commit="updateSender(sender, 'name', $event)"
              />
            </SimpleTableCell>

            <SimpleTableCell class="px-6">
              <span class="item-center flex justify-between space-x-3">
                <div class="my-1.5 truncate">
                  <template v-if="sender.effective_email === sender.email">
                    {{ sender.effective_email }}
                  </template>
                  <template v-else>
                    {{ sender.effective_email.split("@")[0]
                    }}<span class="text-gray-200">@{{ sender.effective_email.split("@")[1] }}</span>
                  </template>
                </div>

                <IconButton
                  v-if="sender.auth.status === 'OK'"
                  :label="t('authResult.OK')"
                  theme="success"
                  placement="left"
                  colored
                  @click="viewingSenderAuthDetails = sender"
                  ><ShieldCheckIcon
                /></IconButton>
                <IconButton
                  v-else-if="sender.auth.status === 'CHANGE_REQUIRED'"
                  :label="t('authResult.CHANGE_REQUIRED')"
                  theme="danger"
                  colored
                  placement="left"
                  @click="viewingSenderAuthDetails = sender"
                  ><ShieldExclamationIcon
                /></IconButton>
                <IconButton
                  v-else-if="sender.auth.status === 'CHANGE_SUGGESTED'"
                  :label="t('authResult.CHANGE_SUGGESTED')"
                  theme="warning"
                  colored
                  placement="left"
                  @click="viewingSenderAuthDetails = sender"
                  ><ShieldExclamationIcon
                /></IconButton>
                <IconButton
                  v-else-if="sender.auth.status === 'PUBLIC_DOMAIN'"
                  :label="t('authResult.PUBLIC_DOMAIN')"
                  theme="warning"
                  colored
                  placement="left"
                  @click="viewingSenderAuthDetails = sender"
                  ><InformationCircleIcon
                /></IconButton>
                <IconButton
                  v-else-if="sender.auth.status === 'UNKNOWN'"
                  :label="t('authResult.UNKNOWN')"
                  theme="danger"
                  colored
                  placement="left"
                  @click="viewingSenderAuthDetails = sender"
                  ><ExclamationCircleIcon
                /></IconButton>
              </span>
            </SimpleTableCell>

            <SimpleTableCell class="px-3 py-2">
              <EditableText
                class="w-full"
                placeholder="dirección de respuesta"
                :input-modifiers="{ lowercase: true }"
                :validation-rules="{
                  emailRule,
                  maxLength: maxLengthRule(50)
                }"
                :content="sender.reply_to"
                :editable="sender.auth.status === 'OK' || sender.auth.status === 'CHANGE_SUGGESTED'"
                :loading="updatingSenderField.id === sender.id && updatingSenderField.field === 'reply_to'"
                @commit="updateSender(sender, 'reply_to', $event)"
              />
            </SimpleTableCell>

            <SimpleTableCell right-aligned class="space-x-1 px-3">
              <IconButton
                :label="t('editSenderDetails')"
                :disabled="sender.auth.status === 'UNKNOWN'"
                placement="left"
                @click="editingSenderDetails = sender"
                ><IdentificationIcon
              /></IconButton>
              <IconButton theme="danger" :label="t('delete')" placement="left" @click="confirmDelete(sender)"
                ><TrashIcon
              /></IconButton>
            </SimpleTableCell>

            <transition
              enter-active-class="duration-200 ease-out transform"
              enter-from-class="opacity-0 translate-x-4"
              enter-to-class="opacity-100 translate-x-0"
              leave-active-class="duration-200 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-show="confirmDeletingSenderId === sender.id"
                class="absolute inset-0 flex justify-center bg-opacity-50 backdrop-blur-sm backdrop-filter"
              >
                <div class="flex space-x-2 self-center">
                  <SimpleButton
                    ref="confirmDeleteButtonRef"
                    theme="danger"
                    size="small"
                    :loading="isDeleting"
                    @click="deleteSender(sender)"
                    >{{ t("confirmDeleteSender", { email: sender.email }) }}
                    <template #leading>
                      <TrashIcon />
                    </template>
                  </SimpleButton>
                  <SimpleButton theme="white" size="small">{{ t("betterNot") }}</SimpleButton>
                </div>
              </div>
            </transition>
          </tr>
        </template>
      </SimpleTable>

      <AlertBox v-if="!senders.size && !isLoadingList" theme="info">
        {{ t("noSenders.createSender") }}
        <template #actions>
          <i18n-t keypath="noSenders.knowMore">
            <template #helpcenter>
              <a href="https://docs.myperfit.com/articles/5386042" target="_blank">{{ t("noSenders.helpCenter") }}</a>
            </template>
          </i18n-t>
        </template>
      </AlertBox>

      <div :class="[showArticle ? 'justify-between' : 'justify-end', 'mt-6 flex justify-end']">
        <a
          v-if="showArticle"
          @click="openArticle"
          class="flex items-center space-x-1 text-sm font-semibold text-sky-400"
        >
          <span>
            {{ t("articleText") }}
          </span>
          <ArrowRightIcon class="h-3.5 w-3.5" />
        </a>
        <SimpleButton theme="secondary" @click="openCreatorModal = true">
          <template #leading>
            <PlusCircleIcon />
          </template>
          {{ t("addNewSender") }}
        </SimpleButton>
      </div>
    </div>
    <div v-else-if="editingSenderDetails" class="w-full">
      <SenderDetails :sender="editingSenderDetails" @back="senderDetailsUpdated" />
    </div>
    <div v-else class="w-full">
      <SenderAuthDetails
        :sender="viewingSenderAuthDetails"
        @back="viewingSenderAuthDetails = null"
        @revalidated="senderRevalidated"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { onMounted, reactive, Ref, ref, watchEffect, inject, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { PlusCircleIcon } from "@heroicons/vue/solid";
import {
  TrashIcon,
  IdentificationIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  ShieldCheckIcon,
  ExclamationCircleIcon,
  ArrowRightIcon
} from "@heroicons/vue/outline";

import { ShieldExclamationIcon as ShieldExclamationIconSolid } from "@heroicons/vue/solid";

import { onClickOutside } from "@vueuse/core";
import { email as emailRule, maxLength as maxLengthRule } from "@vuelidate/validators";

import { useNotifications } from "@composables/notifications";
import { useSenders } from "@api/modules/senders/senders";
import { useAlertStore } from "@store";
import { Sender, SenderModify, SenderDetails as ISenderDetails } from "@/vue/types/Senders";

import IconButton from "@atoms/IconButton.vue";
import SimpleTableCell from "@atoms/SimpleTableCell.vue";
import SimpleTableHeader from "@atoms/SimpleTableHeader.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import SimpleTable from "@molecules/SimpleTable.vue";
import EditableText from "@molecules/EditableText.vue";
import SenderCreatorModal from "@organisms/Senders/SenderCreatorModal.vue";
import SenderAuthDetails from "@organisms/Senders/SenderAuthDetails.vue";
import SenderDetails from "@organisms/Senders/SenderDetails.vue";

export default {
  name: "SendersManager",
  components: {
    SenderCreatorModal,
    SenderAuthDetails,
    SenderDetails,
    EditableText,
    AlertBox,
    SimpleTable,
    SimpleTableHeader,
    SimpleTableCell,
    SimpleButton,
    IconButton,
    PlusCircleIcon,
    TrashIcon,
    IdentificationIcon,
    InformationCircleIcon,
    ShieldExclamationIcon,
    ShieldCheckIcon,
    ExclamationCircleIcon,
    ArrowRightIcon,
    ShieldExclamationIconSolid
  },
  props: {
    showArticle: {
      type: Boolean,
      default: false
    }
  },
  emits: ["senderCreated", "senderUpdated", "senderDeleted"],

  setup(props, { emit }) {
    const openCreatorModal = ref(false);
    const { t } = useI18n();
    const { notify } = useNotifications();
    const sendersAPI = useSenders();
    const alertStore = useAlertStore();

    const senders = reactive(new Map<string, Sender>());
    const viewingSenderAuthDetails: Ref<Sender | null> = ref(null);
    const editingSenderDetails: Ref<Sender | null> = ref(null);

    const domainsToFix = reactive(new Set());
    const showAlert = ref(false);

    const domainsByStatus = reactive<Record<string, Set<string>>>({
      UNKNOWN: new Set(),
      CHANGE_REQUIRED: new Set(),
      CHANGE_SUGGESTED: new Set(),
      PUBLIC_DOMAIN: new Set(),
      OK: new Set()
    });

    watchEffect(() => {
      domainsByStatus.UNKNOWN.clear();
      domainsByStatus.CHANGE_REQUIRED.clear();
      domainsByStatus.CHANGE_SUGGESTED.clear();
      domainsByStatus.PUBLIC_DOMAIN.clear();
      domainsByStatus.OK.clear();
      [...senders.values()].forEach((sender) => {
        domainsByStatus[sender.auth.status].add(sender.domain);
      });
    });

    const viewDomainAuthDetails = (domain) => {
      viewingSenderAuthDetails.value = [...senders.values()].find((sender) => sender.domain === domain) || null;
    };

    const initialDomainAuthDetails = inject<Ref<string>>("initialDomainAuthDetails");

    watchEffect(() => {
      domainsToFix.clear();
      [...senders.values()].forEach((sender) => {
        if (sender.auth.status === "CHANGE_REQUIRED") {
          domainsToFix.add(sender.domain);
          showAlert.value = true;
        }
      });
    });

    const isLoadingList = ref(false);
    onMounted(async () => {
      senders.clear();
      try {
        isLoadingList.value = true;
        const list = await sendersAPI.list();
        isLoadingList.value = false;
        // const res = await get("/senders", null, isLoadingList);
        list.forEach((sender) => {
          senders.set(sender.id, sender);
        });
        if (initialDomainAuthDetails && initialDomainAuthDetails.value) {
          nextTick(() => {
            viewDomainAuthDetails(initialDomainAuthDetails.value);
            initialDomainAuthDetails.value = "";
          });
        }
      } catch (err) {
        console.error(err);
        isLoadingList.value = true;
      }
    });

    const senderCreated = (newSender: Sender) => {
      senders.set(newSender.id, newSender);
      emit("senderCreated", newSender);
    };

    const confirmDeleteButtonRef = ref(null);
    const confirmDeletingSenderId: Ref<string | null> = ref(null);

    const confirmDelete = (sender: Sender) => {
      confirmDeletingSenderId.value = sender.id;
      onClickOutside(confirmDeleteButtonRef, () => {
        confirmDeletingSenderId.value = null;
      });
    };

    const isDeleting = ref(false);
    const deleteSender = async (sender: Sender) => {
      try {
        const deletedId = sender.id;
        isDeleting.value = true;
        await sendersAPI.remove({ id: sender.id });
        await alertStore.update();
        isDeleting.value = false;
        senders.delete(sender.id);
        notify({
          title: t("senderDeleted")
        });
        emit("senderDeleted", deletedId);
      } catch (error) {
        confirmDeletingSenderId.value = null;
      }
    };

    const updatingSenderField: { id: string | null; field: "name" | "reply_to" | null } = reactive({
      id: null,
      field: null
    });

    const updateSender = async (sender: Sender, field: "name" | "reply_to", newValue: string) => {
      const putData: SenderModify = {
        id: sender.id
      };
      putData[field] = newValue;
      try {
        updatingSenderField.id = sender.id;
        updatingSenderField.field = field;

        const updatedSender = await sendersAPI.modify(putData);
        await alertStore.update();
        senders.set(sender.id, updatedSender);
        notify({
          title: t("senderUpdated")
        });
        emit("senderUpdated", updatedSender);
      } catch (err) {
        console.error(err);
      } finally {
        updatingSenderField.id = null;
        updatingSenderField.field = null;
      }
    };

    const senderRevalidated = (updatedSender: Sender) => {
      senders.set(updatedSender.id, updatedSender);
      viewingSenderAuthDetails.value = updatedSender;
      emit("senderUpdated", senders.get(updatedSender.id));
    };

    const senderDetailsUpdated = (details: ISenderDetails) => {
      if (details && editingSenderDetails.value) {
        editingSenderDetails.value.details = details;
      }
      editingSenderDetails.value = null;
    };

    const openArticle = () => {
      window.Intercom("showArticle", 1820858);
    };

    return {
      senders,
      isLoadingList,
      isDeleting,
      openCreatorModal,
      t,
      senderCreated,
      confirmDelete,
      confirmDeleteButtonRef,
      deleteSender,
      confirmDeletingSenderId,
      updateSender,
      showAlert,
      domainsToFix,
      viewingSenderAuthDetails,
      editingSenderDetails,
      senderRevalidated,
      senderDetailsUpdated,
      emailRule,
      maxLengthRule,
      updatingSenderField,
      domainsByStatus,
      viewDomainAuthDetails,
      openArticle
    };
  }
};
</script>

<i18n lang="json">
{
  "es": {
    "addNewSender": "Agregar nuevo remitente",
    "editSenderDetails": "Datos adicionales",
    "confirmDeleteSender": "¿Eliminar el remitente {email}?",
    "senderUpdated": "Remitente modificado con éxito",
    "senderDeleted": "Remitente eliminado",
    "authResult": {
      "OK": "Dominio autenticado",
      "CHANGE_SUGGESTED": "Ver cambios necesarios",
      "CHANGE_REQUIRED": "Ver cambios necesarios",
      "PUBLIC_DOMAIN": "Ver información sobre dominios públicos",
      "UNKNOWN": "Error al verificar autenticación"
    },
    "alert": {
      "unknown": "Parece que ocurrió un problema al intentar verificar algunos de tus dominios. Si tienes dudas contacta al equipo de soporte para verificarlo.",
      "changeRequired": "Es necesario realizar algunos cambios en el dominio {0} para autenticarlo correctamente y poder utilizarlo como remitente de tus envíos.",
      "changesRequired": "Es necesario realizar cambios en algunos de tus dominios para autenticarlos correctamente y poder utilizarlos como remitentes de tus envíos.",
      "changeSuggested": "Es necesario realizar algunos cambios en el dominio {0} para autenticarlo correctamente y poder utilizarlo como remitente de tus envíos.",
      "publicDomain": "Es necesario configurar un remitente con dominio propio correctamente autenticado para poder realizar nuevos envíos.",
      "ok": "¡Excelente! Todos tus remitentes están correctamente configurados para ser utilizados en tus envíos.",
      "perfitDomain": "Mientras tanto, tus envíos se harán usando un dominio alternativo hasta que se realicen estos cambios. Recibirás las respuestas en la dirección de email registrada.",
      "moreInfo": "Ver más detalles"
    },
    "noSenders": {
      "createSender": "No tienes ningún remitente registrado aún. Crea tu primer remitente para poder realizar envíos.",
      "knowMore": "Para conocer más sobre los remitentes visita el {helpcenter}.",
      "helpCenter": "centro de ayuda"
    },
    "articleText": "Leer más sobre remitentes"
  },
  "pt": {
    "addNewSender": "Adicionar novo remetente",
    "editSenderDetails": "Dados adicionais",
    "confirmDeleteSender": "Eliminar o remetente {email}?",
    "senderUpdated": "Remetente modificado com sucesso",
    "senderDeleted": "Remetente eliminado",
    "authResult": {
      "OK": "Domínio autenticado",
      "CHANGE_SUGGESTED": "Ver modificações necessárias",
      "CHANGE_REQUIRED": "Ver modificações necessárias",
      "PUBLIC_DOMAIN": "Ver informações sobre domínios públicos",
      "UNKNOWN": "Erro ao verificar autenticação"
    },
    "alert": {
      "changeRequired": "É necessário realizar algumas alterações no domínio {0} para poder usá-lo como remetente. Isso deve ser feito antes de 1º de fevereiro de 2024 a fim de estar preparado para as novas políticas de envio a serem implementadas por Gmail e Yahoo para poder continuar enviando.",
      "changesRequired": "É necessário fazer alterações em alguns dos seus domínios para autenticá-los corretamente e poder utilizá-los como remetentes dos seus envios.",
      "changeSuggested": "É necessário fazer algumas alterações no domínio {0} para autenticá-lo corretamente e poder utilizá-lo como remetente dos seus envios.",
      "publicDomain": "É necessário configurar um remetente com domínio próprio corretamente autenticado para poder realizar novos envios.",
      "ok": "Ótimo! Todos os seus remetentes estão configurados corretamente para serem utilizados nos seus envios.",
      "perfitDomain": "Enquanto isso, seus envíos serão feitos utilizando um domínio alternativo até que se realizem essas modificações. Você receberá as respostas no endereço de email registrado.",
      "moreInfo": "Ver detalhes"
    },
    "noSenders": {
      "createSender": "Você ainda não tem nenhum remetente. Crie seu primeiro remetente para poder enviar seus emails.",
      "knowMore": "Para conhecer mais sobre os remetentes visite nosso {helpcenter}.",
      "helpCenter": "centro de ajuda"
    },
    "articleText": "Leia mais sobre remetentes."
  }
}
</i18n>
