import { isBefore, parseISO } from "date-fns";
import { Automation } from "./AutomationsCatalog.types";
import { useLifecycle } from "@api/modules/lifecycles/lifecycles";
import { Languages } from "@/vue/locales/i18n";

// Store
import { useIntegrationsStore } from "@store";

interface Stats {
  count: number;
  hasNew: boolean;
}

export function useAutomationsCatalog() {
  const integrationStore = useIntegrationsStore();
  const Lifecycles = useLifecycle();

  let integrationsEnabled: string[] = [];
  let automations: Automation[] = [];
  let hasRfmLifecycle = false;

  async function init(lang: Languages) {
    await Promise.all([
      (async () => {
        await integrationStore.fetchIntegrations();

        integrationsEnabled = integrationStore.integrations;
      })(),
      Lifecycles.definitions("rfm").then((res) => (hasRfmLifecycle = !!res.id)),
      import(`./data/${lang}`).then((data) => (automations = data.default)),
    ]);
  }

  const listAvailable = (): Automation[] => {
    return automations
      .filter((a) => !a.requires || a.requires.every((i) => integrationsEnabled.includes(i)))
      .filter((a) => !a.lifecycle || (a.lifecycle === "rfm" && hasRfmLifecycle));
  };

  const statsByIntegration = (): Record<string, Stats> => {
    const automations = listAvailable();
    const stats: Record<string, Stats> = {};
    automations.forEach((a) => {
      if (a.requires) {
        a.requires.forEach((i) => {
          if (!stats[i]) stats[i] = { count: 0, hasNew: false };
          if (isNew(a)) stats[i].hasNew = true;
          stats[i].count++;
        });
      }
    });
    return stats;
  };

  const statsByCategory = (): Record<string, Stats> => {
    const automations = listAvailable();
    const stats: Record<string, Stats> = {};
    const today = new Date();
    automations.forEach((a) => {
      const c = a.category;
      if (!stats[c]) stats[c] = { count: 0, hasNew: false };
      if (a.newUntil && isBefore(today, parseISO(a.newUntil))) stats[c].hasNew = true;
      stats[c].count++;
    });
    return stats;
  };

  const statsBySuggested = (): Stats => {
    const automations = listAvailable();
    const stats: Stats = { count: 0, hasNew: false };
    const today = new Date();
    automations.forEach((a) => {
      if (a.suggested) {
        if (a.newUntil && isBefore(today, parseISO(a.newUntil))) stats.hasNew = true;
        stats.count++;
      }
    });
    return stats;
  };

  const statsByAll = (): Stats => {
    const automations = listAvailable();
    const stats: Stats = { count: 0, hasNew: false };
    const today = new Date();
    automations.forEach((a) => {
      if (a.newUntil && isBefore(today, parseISO(a.newUntil))) stats.hasNew = true;
      stats.count++;
    });
    return stats;
  };

  const statsByLifecycleRFM = (): Stats => {
    const automations = listByLifecycleRFM();
    const stats: Stats = { count: 0, hasNew: false };
    const today = new Date();
    automations.forEach((a) => {
      if (a.newUntil && isBefore(today, parseISO(a.newUntil))) stats.hasNew = true;
      stats.count++;
    });
    return stats;
  };

  const listByCategory = (category: string): Automation[] => {
    return listAvailable().filter((a) => a.category === category);
  };

  const listByLifecycleRFM = (): Automation[] => {
    return listAvailable().filter((a) => a.lifecycle === "rfm");
  };

  const listByIntegration = (integration: string): Automation[] => {
    return listAvailable().filter((a) => a.requires?.includes(integration));
  };

  const listBySuggested = (): Automation[] => {
    //TODO: suggested es para todos igual?
    return listAvailable().filter((a) => a.suggested);
  };

  const listByKeyword = (keyword: string): Automation[] => {
    //TODO: suggested es para todos igual?
    return listAvailable().filter(
      (a) =>
        normalizeString(a.name).includes(normalizeString(keyword)) ||
        normalizeString(a.description).includes(normalizeString(keyword)),
    );
  };

  return {
    automations,
    init,
    listAvailable,
    listByCategory,
    listByIntegration,
    listBySuggested,
    listByKeyword,
    listByLifecycleRFM,
    statsByIntegration,
    statsByCategory,
    statsBySuggested,
    statsByAll,
    statsByLifecycleRFM,
    listIntegrationsEnabled: () => integrationsEnabled,
  };
}

const normalizeString = (str: string) => {
  return str
    .normalize("NFKD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();
};

export const isNew = (a: Automation): boolean => {
  const today = new Date();
  return !!a.newUntil && isBefore(today, parseISO(a.newUntil));
};
