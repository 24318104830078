<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    style="z-index: 5000"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition-group
        enter-active-class="transform ease-out duration-300 transition-all"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-300"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <NotificationToast
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          @close="close(notification)"
        />
      </transition-group>
    </div>
  </div>
</template>

<script lang="ts">
import NotificationToast from "@molecules/NotificationToast.vue";
import { useNotifications, Notification } from "@composables/notifications";

export default {
  name: "NotificationsContainer",
  components: {
    NotificationToast,
  },
  setup() {
    const { notifications, remove } = useNotifications();

    const close = (notification: Notification) => {
      remove(notification);
    };

    return { notifications, close };
  },
};
</script>
