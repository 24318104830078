var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class='dropdown hidden'>\n          <!-- ocultado temporalemente, filtro no implementado en api -->\n          <a data-toggle='dropdown' class='filter-list'>\n            <i class='filter-icon pi-filter-lists'></i>\n            <span data-filter-relationship='lists' data-filter-name='filter.list'>\n              "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"lists"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":52}}}))
    + "\n            </span>\n            <i class='drop fa fa-angle-down'></i>\n            <i class='remove fa fa-close' data-action='remove-filter' data-params='{\"key\": \"filter.list\"}'></i>\n          </a>\n          <ul class='dropdown-menu scrollbar'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"relationshipsData") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":58,"column":19}}})) != null ? stack1 : "")
    + "          </ul>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class='instant-search has-feedback'>\n              <input class='finder' type='text' placeholder='"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' data-results='[data-role=list]'\n                data-no-results='[data-role=no-lists]'>\n            </li>\n            <li class='no-results hide' data-role='no-lists'>\n              <span>\n                "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noResults")), depth0))
    + "\n              </span>\n            </li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"relationshipsData") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":51,"column":21}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li data-role='list' data-value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":53},"end":{"line":32,"column":81}}})) != null ? stack1 : "")
    + "'>\n              <a data-action='filter' data-params='{\"key\": \"filter.list\", \"value\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"}'>\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                <em>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"totalContacts") : depth0),0,{"name":"isNot","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":36,"column":18},"end":{"line":40,"column":28}}})) != null ? stack1 : "")
    + "                </em>\n                <span class='tags'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":18},"end":{"line":47,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n              </a>\n            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0));
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"totalContacts") : depth0), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"plural")), depth0))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"none")), depth0))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                  <span class='label label-primary'>\n                    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n                  </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class='no-results'>\n              <span>\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noValuesForFilter")), depth0))
    + "\n              </span>\n            </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "            <div class='clear-search' data-action='remove-filter' data-params='[\"q\"]'>\n              <i class='fa fa-close'></i>\n            </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='list-controls'>\n    <div class='check-all'>\n      <input id='check-all' type='checkbox' data-action='check-all'>\n      <label for='check-all' class='iconic'></label>\n    </div>\n    <div class='sort dropdown'>\n      <button class='btn btn-default dropdown-toggle' type='button' data-toggle='dropdown' data-role='order-button'>\n        <span>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + "</span>\n        <i class='drop fa fa-angle-down'></i>\n      </button>\n      <ul class='dropdown-menu'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"sortOptions")),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":8},"end":{"line":108,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n    <div class='paging-total'>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"paging") : depth0)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":112,"column":6},"end":{"line":112,"column":36}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"plural")), depth0))
    + "\n    </div>\n  </div>\n  <div class='content list scrollbar'>\n    <ul class='table'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":6},"end":{"line":164,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n          <a data-action='order' data-params='{\"column\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"column") : depth0), depth0))
    + "\", \"type\": \""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"}'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"sortBy")), depth0))
    + " "
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang")),(depth0 != null ? lookupProperty(depth0,"label") : depth0),true,{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":105,"column":34},"end":{"line":105,"column":69}}}))
    + "\n          </a>\n        </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n        <div class='check-cell'>\n          <input id='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='checkbox' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-action='check'>\n          <label for='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='iconic'></label>\n        </div>\n        <div class='main-cell'>\n          <div class=\"name\">\n            <a href='/optins/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </a>\n            <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"description") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":129,"column":14},"end":{"line":135,"column":21}}})) != null ? stack1 : "")
    + "            </p>\n          </div>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"subscriptions") : stack1)) != null ? lookupProperty(stack1,"total") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":141,"column":12},"end":{"line":141,"column":60}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"subscriptionsTotal")), depth0))
    + "\n          </small>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"subscriptions") : stack1)) != null ? lookupProperty(stack1,"lastMonth") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":149,"column":12},"end":{"line":149,"column":64}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"subscriptionsLastMonth")), depth0))
    + "\n          </small>\n        </div>\n        <div class='info-cell'>\n          <strong>\n            "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"subscriptions") : stack1)) != null ? lookupProperty(stack1,"lastWeek") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":157,"column":12},"end":{"line":157,"column":63}}}))
    + "\n          </strong>\n          <small>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"subscriptionsLastWeek")), depth0))
    + "\n          </small>\n        </div>\n      </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <em class=\"grayed\">\n                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"noDescription")), depth0))
    + "\n              </em>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='content'>\n    <div class='padder'>\n      <div class='empty-state'>\n        <div class='row'>\n          <div class='col-md-5'>\n            <img class='illustration'\n              src='/img/empty-states/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":174,"column":37},"end":{"line":174,"column":95}}})) != null ? stack1 : "")
    + "'>\n          </div>\n          <div class='col-md-7'>\n            <div class='info'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"hasFilter")),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":178,"column":14},"end":{"line":189,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "search.svg";
},"27":function(container,depth0,helpers,partials,data) {
    return "optins.svg";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsTitle")), depth0))
    + "</h2>\n              <p>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"searchNoResultsLegend")), depth0))
    + "</p>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"noResultsTitle")), depth0))
    + "</h2>\n              <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"noResultsSubtitle")), depth0))
    + "</h3>\n              <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"noResultsLegend")), depth0))
    + "</p>\n              <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"noResultsMore")), depth0))
    + "</h3>\n              <div class='links-list'>\n                "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"noResultsLinks")), depth0)) != null ? stack1 : "")
    + "\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Edited -->\n<div class='optins section'>\n  <div class='section-header'>\n    <h1>\n      "
    + alias2(__default(require("../../../helpers/handlebars/capitalize.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"plural")),{"name":"capitalize","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":45}}}))
    + "\n    </h1>\n    <div class='filters'>\n      <ul class='nav nav-pills'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"relationshipsData") : depth0)) != null ? lookupProperty(stack1,"lists") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + "        <li class='search'>\n          <form data-role='search' data-form='search'>\n            <button class='btn btn-default' type='button' data-action='open-search-form'>\n              <i class='filter-icon pi-filter-search'></i>\n            </button>\n            <input type='text' placeholder='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"search")), depth0))
    + "...' class='form-control' name='q'\n              value='"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"params") : depth0)) != null ? lookupProperty(stack1,"q") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":73,"column":19}}})) != null ? stack1 : "")
    + "          </form>\n        </li>\n      </ul>\n    </div>\n  </div>\n  <div class='toolbar' data-role='toolbar'>\n    <a data-navigate='optins/new' class='btn btn-primary' data-type='none' data-require-permission='optins:create'>\n      <i class='fa fa-plus'></i> "
    + alias2(alias3(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"optins"))) && lookupProperty(stack1,"create")), depth0))
    + "\n    </a>\n    <button class='btn btn-danger hide' data-type='one-plus' data-action='mass' data-method='destroy'\n      data-feedback='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"massDestroyM")), depth0))
    + "' data-confirm='"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"confirmDelete")), depth0))
    + "'\n      data-require-permission='optins:delete'>\n      "
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"delete")), depth0))
    + "\n    </button>\n    <div class='paging-selected' data-role='check-count'></div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":90,"column":2},"end":{"line":196,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});