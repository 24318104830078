import { Collection } from "@/core/magnet";

export default Collection.extend({
  url: "fields",
  parse: function (response) {
    return response.data.map(function (field) {
      if (field.tag !== undefined) {
        if (field.tag === "FNAME") {
          field.tag = "first_name";
        } else if (field.tag === "LNAME") {
          field.tag = "last_name";
        } else {
          field.tag = field.tag.toLowerCase();
        }
        field.replacementTag = "${contact." + field.tag + "}";
      }
      return field;
    });
  },
  filters: {
    custom: {
      key: "custom",
      icon: "pi-filter-state",
      name: function () {
        return window.lang.type;
      },
      source: function () {
        var data = [{ id: 0 }, { id: 1 }];
        return Promise.resolve({ data: data });
      },
      parser: function (item) {
        var translated = window.lang.fields.filters.custom[item.id];
        return {
          id: item.id,
          title: translated,
          keywords: translated,
          params: {
            key: "filter.custom",
            value: item.id,
          },
        };
      },
    },
  },
});
