import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";

//Types
import type {
  ListParams,
  InterestsModel,
  ListAPIParams,
  GetInterestsAsyncGenerator,
  InterestsService,
  CreateResponse,
} from "./interests.types";
import type { SuccessAPIOffsetPaging } from "../../models/requestWrappers";

export function useInterests(): InterestsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let GetInterestsGenerator: GetInterestsAsyncGenerator | undefined = undefined;
  let apiParams: ListParams | undefined = undefined;

  return {
    async list(options) {
      let isNewRequest = false;
      if (!isEqual(apiParams, options) || !GetInterestsGenerator) {
        isNewRequest = true;
        apiParams = options;
        GetInterestsGenerator = requestPaging<InterestsModel, unknown, ListAPIParams>(
          {
            url: `/interests`,
            method: "get",
            urlParams: {
              q: options?.search ? `*${options?.search}*` : undefined,
              sortBy: apiParams?.sortBy ?? "id",
              sortDir: apiParams?.sortDir ?? "asc",
            },
          },
          { limit: apiParams?.limit ?? 20, offset: apiParams?.offset ?? 0 },
          true,
        );
      }

      if (!GetInterestsGenerator || GetInterestsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };

      const data = (await GetInterestsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<InterestsModel>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
          isNewRequest,
        };
      }

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data,
        isNewRequest,
      };
    },
    async create(params) {
      const data = await request<CreateResponse>({
        url: `/interests`,
        method: "post",
        data: {
          name: params.name,
        },
      });
      return data.data;
    },
    async delete(params) {
      await request({
        url: `/interests/${params.id}`,
        method: "delete",
      });
    },
  };
}
