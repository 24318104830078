import app from "@/app";
import trialTpl from "../templates/index.hbs";

export default window.Backbone.View.extend({
  // Element selector
  el: ".trial",

  events: {
    "click [data-action=ask-for-trial]": "askForTrial",
    "click [data-action=ask-for-help-abuse]": "askForHelpAbuse",
  },

  // Custom initialization method
  initialize: function () {
    const self = this;
    // Set main template
    self.template = trialTpl;
  },

  // Renders the app layout
  render: function () {
    const self = this;

    const plan = app.getPlan();
    const planType = plan?.type;
    const planState = plan?.state;
    const freeExceeded = planType === "FREE" && planState === "BLOCKED_CONTACTS_EXCEEDED";
    const monthlyExceeded = planType === "MONTHLY" && planState === "BLOCKED_CONTACTS_EXCEEDED";
    const blockedAbuse = planState === "BLOCKED_ABUSE";
    const expiredInvoices = ["MONTHLY", "EMAILS"].includes(planType) && planState === "BLOCKED_EXPIRED_INVOICES";

    const trialExpired = planType === "FREE" && plan.trialExpired;

    const html = self.template({
      trial: app.getTrial(),
      freeExceeded,
      monthlyExceeded,
      expiredInvoices,
      trialExpired,
      blockedAbuse,
      lang: window.lang,
    });

    self.$el.html(html);
    return self;
  },

  askForTrial: function () {
    window.Intercom("showNewMessage", window.lang.trial.askForTrialMessage);
  },

  askForHelpAbuse: function () {
    window.Intercom("showNewMessage", window.lang.plans.blockedAbuseHelpMessage);
  },
});
