<template>
  <p class="mt-2 text-sm text-red-600">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "InputError",
};
</script>
