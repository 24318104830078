<template>
  <div class="flex items-end space-x-4">
    <!-- <h1 class="text-lg font-medium text-gray-700">{{ title }}</h1> -->
    <a
      v-if="helpId"
      href=""
      target="_blank"
      class="inline-flex items-center text-sm font-medium leading-5 text-gray-400 hover:text-gray-500"
      data-intercom-target="PageTitleLink-HelpArticle"
      tabindex="-1"
      @click.prevent="openArticle"
    >
      <BookOpenIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      <span class="whitespace-nowrap">{{ t("helpCenter") }}</span>
    </a>
    <a
      v-if="showYouTubeLink"
      :href="`https://www.youtube.com/watch?v=${youTubeId}`"
      target="_blank"
      class="inline-flex items-center text-sm font-medium leading-5 text-gray-400 hover:text-gray-500"
      data-intercom-target="PageTitleLink-VideoTutorial"
    >
      <PlayIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
      <span class="whitespace-nowrap">{{ t("videoTutorials") }}</span>
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { BookOpenIcon, PlayIcon } from "@heroicons/vue/solid";
import { useI18n } from "vue-i18n";
// import { useBreakpoints } from "@composables/breakpoints";

// const { lgBp } = useBreakpoints();

const props = withDefaults(
  defineProps<{
    // title: string;
    helpId?: string;
    youTubeIdEs?: string;
    youTubeIdPt?: string;
  }>(),
  {
    // title: "",
    helpId: "",
    youTubeIdEs: "",
    youTubeIdPt: "",
  }
);

const { t, locale } = useI18n();

const lang = computed(() => locale.value.substr(0, 2));

const showYouTubeLink = computed(
  () => (lang.value === "pt" && props.youTubeIdPt) || (lang.value === "es" && props.youTubeIdEs)
);

const youTubeId = computed(() => (lang.value === "pt" ? props.youTubeIdPt : props.youTubeIdEs));

const openArticle = () => {
  window.Intercom("showArticle", props.helpId);
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "helpCenter": "Ayuda",
    "videoTutorials": "Videos"
  },
  "pt": {
    "helpCenter": "Ajuda",
    "videoTutorials": "Videos"
  }
}
</i18n>
