// Types
import type { IntegrationApplication } from "./integration.types";

// Services
import { useIntegrationsService } from "@services";

// Utils
import { ok, err } from "neverthrow";

// Domain
import { isExternalIntegration, integrations, integrationsPT } from "@domain/integrations";

export const useIntegrationsApp = (): IntegrationApplication => {
  const integrationsService = useIntegrationsService();

  const integrationApp: IntegrationApplication = {
    async getExternalIntegrationUrl(params) {
      const integrationsSelected = params.lang === "pt" ? integrationsPT : integrations;

      const info = integrationsSelected.find((i) => i.id === params.integration);

      if (!info || !isExternalIntegration(info) || !info?.redirect)
        return err({
          type: "NO_INTEGRATION_FOUND",
        });

      const url = info.redirect?.[window?.config?.environment];

      if (!url)
        return err({
          type: "NO_URL",
        });

      return ok(url);
    },
    async getIntegrations() {
      const res = await integrationsService.getIntegrations();

      if (res.isErr()) return ok([]);

      return ok(res.value.map((s) => s.name));
    },
  };

  return integrationApp;
};
