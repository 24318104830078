import { Model } from "@/core/magnet";
import { _, round, objHas } from "@/helpers/utils";
import app from "@/app";
const moment = window.moment;

export default Model.extend({
  urlRoot: "contacts",
  parse: function (contact) {
    // Get data from API direct call
    if (_.isDefined(contact.data)) {
      contact = contact.data;
    }

    // Set quality for the contact
    if (contact.quality !== undefined) {
      contact.rating = round(contact.quality, 1) * 100;
      if (contact.quality == 0) {
        contact.interaction = 0;
      } else if (contact.quality > 0 && contact.quality < 0.33) {
        contact.interaction = 1;
      } else if (contact.quality > 0.33 && contact.quality < 0.66) {
        contact.rating = 50;
        contact.interaction = 2;
      } else if (contact.quality >= 0.66) {
        contact.interaction = 3;
      }
    }
    return contact;
  },
  additionals: {
    fields: function () {
      return $.get("fields", { limit: 300 });
    },
    format: [
      {
        id: "TEXT",
        name: "Texto",
        description: "Texto libre de hasta 100 caracteres, ej: 'Buenos Aires', 'Calle 123'",
      },
      {
        id: "INT",
        name: "N\u00FAmero",
        description: "Valores numéricos enteros positivos, máximo 2.000.000.000",
      },
      {
        id: "DATE",
        name: "Fecha",
        description: "Fecha en formato AAAA-MM-DD, ej: '1983-10-17', '2016-10-26'",
      },
    ],
  },

  // Parse additionals
  parseAdditionals: function (additionals) {
    var events,
      refNow = moment(),
      refToday = refNow.clone().startOf("day"),
      refYesterday = refNow.clone().subtract(1, "days").startOf("day"),
      refWeekStart = refNow.clone().startOf("week"),
      refMonthStart = refNow.clone().startOf("month");

    $.each(additionals, function (name, values) {
      if (name === "activity") {
        events = {
          today: [],
          yesterday: [],
          thisWeek: [],
          thisMonth: [],
          before: [],
        };

        $.each(values, function (index, event) {
          var eventMoment = moment(event.created);
          if (event.type === "session.finished") {
            event.details.session_duration = Math.floor(event.details.session_duration / 60);
          } else if (event.type === "mail.sent" && event.details?.version) {
            event.details.version_number = event.details.version.replace("version_", "");
          }

          if (eventMoment.isSame(refToday, "d")) {
            events.today.push(event);
          } else if (eventMoment.isSame(refYesterday, "d")) {
            events.yesterday.push(event);
          } else if (eventMoment.isAfter(refWeekStart)) {
            events.thisWeek.push(event);
          } else if (eventMoment.isAfter(refMonthStart)) {
            events.thisMonth.push(event);
          } else {
            events.before.push(event);
          }
        });

        additionals.activity = [
          {
            period: "today",
            events: events.today,
            hasItems: events.today.length > 0,
          },
          {
            period: "yesterday",
            events: events.yesterday,
            hasItems: events.yesterday.length > 0,
          },
          {
            period: "thisWeek",
            events: events.thisWeek,
            hasItems: events.thisWeek.length > 0,
          },
          {
            period: "thisMonth",
            events: events.thisMonth,
            hasItems: events.thisMonth.length > 0,
          },
          {
            period: "before",
            events: events.before,
            hasItems: events.before.length > 0,
          },
        ];

        if (values.length === 0) {
          additionals.noActivity = true;
        }
      }
    });
    return additionals;
  },

  // Adds a contact to a list
  addToList: function (listId) {
    return $.ajax({
      type: "PUT",
      url: "contacts/" + this.id + "/lists/" + listId,
    });
  },

  // Adds a contact to a list
  removeFromList: function (listId) {
    return $.ajax({
      type: "DELETE",
      url: "contacts/" + this.id + "/lists/" + listId,
    });
  },

  // Adds a contact to an interest
  addToInterest: function (interestId) {
    return $.ajax({
      type: "PUT",
      url: "contacts/" + this.id + "/interests/" + interestId,
    });
  },

  // Unsubscribes a contact
  unsubscribe: function () {
    const eventData = {
      id: this.id,
      email: this.attributes.email,
    };
    return $.post("contacts/" + this.id + "/unsubscribe").then(() => {
      app.integrations.amplitude.event("APP_CONTACT_UNSUBSCRIBED", eventData, true);
      app.integrations.intercom.event("APP_CONTACT_UNSUBSCRIBED", eventData);
    });
  },

  // Respawns the contact
  respawn: function () {
    const eventData = {
      id: this.id,
      email: this.attributes.email,
    };
    return $.post("contacts/" + this.id + "/respawn").then(() => {
      app.integrations.amplitude.event("APP_CONTACT_RESPAWN", eventData, true);
      app.integrations.intercom.event("APP_CONTACT_RESPAWN", eventData);
    });
  },

  // Resets the contact's activity
  reset: function () {
    const eventData = {
      id: this.id,
      email: this.attributes.email,
    };
    return $.post("contacts/" + this.id + "/reset").then(() => {
      app.integrations.amplitude.event("APP_CONTACT_RESET", eventData, true);
      app.integrations.intercom.event("APP_CONTACT_RESET", eventData);
    });
  },

  // Gets a field
  getField: function (fieldId) {
    // If the field ID is a number, the field is custom
    if (Number.isInteger(fieldId)) {
      return this.getCustomField(fieldId);
    }

    // If the field ID is a string, the field is common
    if (typeof fieldId === "string") {
      return this.getCommonField(fieldId);
    }

    // Otherwise, return undefined
    return;
  },

  // Gets a custom field
  getCustomField: function (fieldId) {
    var field = _.findWhere(this.get("customFields"), { id: fieldId });
    return objHas(field, "value") ? field.value : false;
  },

  // Gets a common field
  getCommonField: function (fieldId) {
    return this.get(fieldId);
  },

  // Gets a common field translated
  getTranslatedCommonField: function (fieldId) {
    var value = this.getCommonField(fieldId);

    // Traslate the value if needed
    switch (fieldId) {
      case "gender":
        if (value) {
          value = value === "M" ? window.lang.genderMale : window.lang.genderFemale;
        }
        break;
      case "state":
        value = window.lang.contacts.status[value];
        break;
      case "language":
        value = window.lang.languages[value];
        break;
      case "source":
        value = window.lang.contacts.sources[value];
        break;
    }

    // Return the translate value
    return value;
  },
});
