<template>
  <Listbox :model-value="selected" multiple @update:modelValue="updateSelectedOption">
    <div class="relative">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-1.5 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm"
      >
        <span class="flex w-full items-center truncate text-gray-500">
          <span><SearchIcon class="mr-2 h-5 w-5" /></span>
          <span class="truncate">{{ formattedSelectedOptions.text }}</span
          ><span v-if="formattedSelectedOptions.number > 2">
            {{ "(+" + (formattedSelectedOptions.number - 2) + ")" }}</span
          >
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in activityContactOptions"
            :key="option.key"
            v-slot="{ active, selected }"
            :value="option.key"
          >
            <li
              :class="[
                active ? 'bg-sky-500 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <div class="flex">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'truncate']">
                  {{ option.value }}
                </span>
              </div>
              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-sky-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import type { ActivityContactOptionsDataItems } from "./feedActivity.types";

// Components
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { SearchIcon } from "@heroicons/vue/outline";

// Language
const { t } = useI18n();

// Domain
import type { ActivityContactOptionsTypes } from "@/vue/types/contacts";

// Props
const props = withDefaults(
  defineProps<{
    selected: Array<ActivityContactOptionsTypes>;
  }>(),
  {
    selected: () => ["all"],
  }
);

const activityContactOptions: ActivityContactOptionsDataItems = [
  { key: "all", value: t("options.all") },
  { key: "store", value: t("options.store") },
  { key: "sale", value: t("options.sale") },
  { key: "send", value: t("options.send") },
  { key: "interaction", value: t("options.interaction") },
  { key: "lifecycle", value: t("options.lifecycle") },
  { key: "trigger", value: t("options.trigger") },
  { key: "subscription", value: t("options.subscription") },
  { key: "unsubscribe", value: t("options.unsubscribe") },
] as ActivityContactOptionsDataItems;

// Emits
const emit = defineEmits<{
  (e: "update:selected", value: Array<ActivityContactOptionsTypes>): void;
}>();

emit("update:selected", [activityContactOptions[0].key]);

const updateSelected = (value: ActivityContactOptionsDataItems) => {
  emit("update:selected", getArrayKeys(value));
};

const updateSelectedKeys = (value: Array<ActivityContactOptionsTypes>) => {
  emit("update:selected", value);
};

const getArrayKeys = (options: ActivityContactOptionsDataItems): Array<ActivityContactOptionsTypes> => {
  return options.map((obj) => obj.key);
};

const getValueByKey = (keys: Array<ActivityContactOptionsTypes>): Array<ActivityContactOptionsTypes> => {
  return keys.reduce((values, key) => {
    const optionFound = activityContactOptions.find((o) => o.key === key);
    if (optionFound) {
      values.push(optionFound.value);
    }
    return values;
  }, [] as Array<ActivityContactOptionsTypes>);
};

const isAllSelected = (keys: Array<ActivityContactOptionsTypes>) => {
  const optionsKeys: Array<ActivityContactOptionsTypes> = [
    "send",
    "interaction",
    "lifecycle",
    "store",
    "sale",
    "subscription",
    "trigger",
    "unsubscribe",
  ];
  if (keys.some((key) => key === "all")) return true;

  return optionsKeys.every((optionKey) => keys.some((key) => key === optionKey));
};

// Computed
const formattedSelectedOptions = computed(() => {
  const arrValues = getValueByKey(props.selected);
  const numberValues = arrValues.length;
  return { text: arrValues.join(", "), number: numberValues };
});

const resetSelectedOption = () => {
  updateSelected([activityContactOptions[0]]);
};

const removeAllOptionFromOptions = (keys: Array<ActivityContactOptionsTypes>): Array<ActivityContactOptionsTypes> => {
  return keys.filter((key) => key !== "all");
};

const updateSelectedOption = (values: Array<ActivityContactOptionsTypes>) => {
  const hadAllSelected = isAllSelected(props.selected);
  const hasAllSelected = isAllSelected(values);

  if ((hasAllSelected && (values.length === 1 || !hadAllSelected)) || values.length === 0) {
    resetSelectedOption();
    return;
  }
  updateSelectedKeys(removeAllOptionFromOptions(values));
};
</script>

<i18n lang="json">
{
  "es": {
    "options": {
      "all": "Todas las actividades",
      "trigger": "Inicios de automations",
      "send": "Envíos de emails",
      "interaction": "Interacciones con emails",
      "lifecycle": "Ciclo de vida",
      "store": "Visitas a la tienda",
      "sale": "Ventas en la tienda",
      "subscription": "Formularios, listas e intereses",
      "unsubscribe": "Rebotes y desuscripciones"
    }
  },
  "pt": {
    "options": {
      "all": "Todas as atividades",
      "trigger": "Inicios de automations",
      "send": "Envios de e-mails",
      "interaction": "Interações em e-mails",
      "lifecycle": "Ciclo de vida",
      "store": "Visitas à loja",
      "sale": "Vendas para a loja",
      "subscription": "Formulários, listas e interesses",
      "unsubscribe": "Cancelamentos de inscrição"
    }
  }
}
</i18n>
