import type { Contents } from "@/vue/types/Templates";
import type { SearchTemplateContentModules, ContentIndexes } from "./templateSearch.type";

export const searchTemplateContentModules: SearchTemplateContentModules = {
  getIndexesByContent: ({ parsedJSON, identifiers }) => {
    if (!identifiers.id && !identifiers.slug && !identifiers.type) return [];

    const indexes: ContentIndexes = [];

    parsedJSON.body.rows.forEach((row, rowIndex) => {
      if (row.columns.length === 0) return;

      row.columns.forEach((column, colIndex) => {
        if (column.contents.length === 0) return;

        column.contents.forEach((content, contentIndex) => {
          let match = false;
          if (identifiers.id && content.id) {
            match = content.id === identifiers.id;
          }

          if (identifiers.slug && content.slug) {
            match = content.slug === identifiers.slug;
          }

          if (identifiers.type && content.type) {
            match = content.type === identifiers.type;
          }

          if (!match) return;

          indexes.push({
            rowIndex,
            colIndex,
            contentIndex,
          });
        });
      });
    });

    return indexes;
  },
  getRowIndexByContent: ({ parsedJSON, content }) => {
    if (!content.id && !content.slug && !content.type) return -1;

    return parsedJSON.body.rows.findIndex((row) =>
      row.columns.some((column) =>
        column.contents.some((colContent) =>
          Object.entries(content).every(([key, value]) => colContent[key] === value),
        ),
      ),
    );
  },
  getRowIndex: ({ templateJSON, row }) => {
    return templateJSON.body.rows.findIndex((rowA) => rowA.id === row.id);
  },
  getColumnIndexByContent: ({ parsedJSON, rowIndex, content }) => {
    if (!content.id && !content.slug && !content.type) return -1;

    return parsedJSON.body.rows[rowIndex].columns.findIndex((column) =>
      column.contents.some((colContent) => Object.entries(content).every(([key, value]) => colContent[key] === value)),
    );
  },
  getContentIndex: ({ parsedJSON, rowIndex, columnIndex, content }) => {
    return parsedJSON.body.rows[rowIndex]?.columns?.[columnIndex].contents.findIndex((colContent) =>
      Object.entries(content).every(([key, value]) => colContent[key] === value),
    );
  },
  insertRow: ({ parsedJSON, template, row }) => {
    parsedJSON.body.rows.push(row);
    template.contents.json = JSON.stringify(parsedJSON);
  },
  insertContent: ({ parsedJSON, template, columnIndex, rowIndex, content }) => {
    parsedJSON.body.rows[rowIndex].columns[columnIndex].contents.push(content);
    template.contents.json = JSON.stringify(parsedJSON);
  },
  isEmptyRow: ({ parsedJSON, rowIndex }) => {
    return parsedJSON.body.rows[rowIndex]?.columns.every((column) => column.contents.length === 0);
  },
  IsLastRowIndex: ({ parsedJSON, rowIndex }) => {
    return rowIndex == parsedJSON.body.rows.length - 1;
  },
  isColumnLastContentIndex: ({ parsedJSON, rowIndex, columnIndex, contentIndex }) => {
    return contentIndex == parsedJSON.body.rows[rowIndex]?.columns?.[columnIndex].contents.length - 1;
  },
  isUniqueColumn: ({ parsedJSON, rowIndex }) => {
    return parsedJSON.body.rows[rowIndex]?.columns.length === 1;
  },
  deleteRow: ({ parsedJSON, template, rowIndex }) => {
    parsedJSON.body.rows.splice(rowIndex, 1);
    template.contents.json = JSON.stringify(parsedJSON);
  },
  getContent: ({ parsedJSON, rowIndex, columnIndex, contentIndex }) => {
    return parsedJSON.body.rows[rowIndex]?.columns?.[columnIndex].contents.slice(contentIndex, contentIndex + 1)?.[0];
  },
  getContents: <V>({ parsedJSON, identifiers }) => {
    const contentsFound = parsedJSON.body.rows.reduce((contents, row) => {
      const newContents = row.columns.reduce((contents, column) => {
        const filteredContents = column.contents.filter((content) => {
          let match = false;
          if (identifiers.id && content.id) {
            match = content.id === identifiers.id;
          }

          if (identifiers.slug && content.slug) {
            match = content.slug === identifiers.slug;
          }

          if (identifiers.type && content.type) {
            match = content.type === identifiers.type;
          }

          return match;
        });
        return [...contents, ...filteredContents];
      }, [] as Contents<V>);

      return [...contents, ...newContents];
    }, [] as Contents<V>);

    return contentsFound;
  },
  deleteContent: ({ parsedJSON, template, rowIndex, columnIndex, contentIndex }) => {
    parsedJSON.body.rows[rowIndex]?.columns?.[columnIndex].contents.splice(contentIndex, 1);
    template.contents.json = JSON.stringify(parsedJSON);
  },
  getEmptyRows: ({ templateJSON }) => {
    return templateJSON.body.rows.filter((row) => {
      if (row.columns.length === 0) return true;

      return row.columns.every((column) => column.contents.length === 0);
    });
  },
};

// interface GetColumnIndexByIDParams {
//   parsedJSON: TemplateContentJSON;
//   columnID: string;
// }

// const getColumnIndexByID = ({ parsedJSON, columnID }: GetColumnIndexByIDParams): number => {
//   return parsedJSON.body.rows.findIndex((row) => row.columns.some((column) => column.id === columnID));
// };
