<template>
  <div :class="[tags?.length === 0 ? 'py-6' : 'py-3', 'items-left flex max-w-2xl flex-col flex-wrap space-y-1']">
    <div class="flex items-center gap-2">
      <a :href="`/lists/${id}`" class="line-clamp-1 w-fit text-sm font-medium text-sky-400">
        <slot />
      </a>
      <div class="flex justify-end">
        <StarIcon v-for="index in starCount" :key="`starCount${index}`" class="h-4 w-4 text-sky-400" />
        <StarIcon v-for="index in startEmptyCount" :key="`startEmptyCount${index}`" class="h-4 w-4 text-gray-200" />
      </div>
    </div>
    <p :class="[description ? 'text-gray-500' : 'text-gray-300', 'mb-2 line-clamp-1 text-xs ']">
      {{ description || t("placeholderDescription") }}
    </p>

    <TagsGroup readonly clickable :show-tag-colors="false" tag-size="small" :selected-tags="tags" @click="selectTag" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Components
import { TagsGroup } from "@organisms/Tags";

// Icon
import { StarIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Tags, Tag } from "@domain/tag";

const { t } = useI18n();

const props = defineProps<{
  id: string;
  quality: number;
  tags?: Tags;
  description?: string;
}>();

// Emits
const emit = defineEmits<{
  (e: "selectTag", tag: Tag): void;
}>();

const selectTag = (tag: Tag) => {
  emit("selectTag", tag);
};

const MAX_STARS = 3;

const starCount = computed<number>(() => {
  if (props.quality <= 0.09 || props.quality > 1) return 0;
  if (props.quality <= 0.33) return 1;
  if (props.quality <= 0.66) return 2;
  return 3;
});

const startEmptyCount = computed<number>(() => {
  return Math.max(Math.min(MAX_STARS - starCount.value, MAX_STARS), 0);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "placeholderDescription": "Sin descripción"
  },
  "pt": {
    "placeholderDescription": "Sem descrição"
  }
}
</i18n>
