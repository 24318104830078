<template>
  <transition
    leave-active-class="transition duration-200 ease"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0 transform translate-y-6 "
    enter-active-class="transition duration-200 ease"
    enter-to-class="opacity-100"
    enter-from-class="opacity-0 transform translate-y-6"
  >
    <div class="pointer-events-none absolute inset-x-0 bottom-10 z-30 mx-auto mt-2">
      <div
        class="pointer-events-auto relative mx-auto flex w-full items-center justify-between space-x-10 rounded-md bg-neutral-600 px-4 py-3 shadow md:w-[60%] xl:w-[45%] xl:space-x-0 2xl:w-[40%]"
      >
        <slot />
      </div>
    </div>
  </transition>
</template>
