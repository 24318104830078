<template>
  <div class="relative rounded-md shadow-sm">
    <textarea
      ref="inputRef"
      type="text"
      v-bind="$attrs"
      :value="modelValue"
      :class="inputClass"
      :placeholder="placeholder"
      :aria-invalid="hasError"
      @input="emitValue"
    />
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SimpleInput",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const inputRef = ref(null);

    const emitValue = (e) => {
      let value = e.target.value;
      emit("update:modelValue", value);
    };

    return { inputRef, emitValue };
  },

  computed: {
    inputClass() {
      const classes = [];
      const { hasError } = this;

      classes.push("shadow-sm block w-full focus:outline-none sm:text-sm rounded-md resize-none");

      if (hasError) {
        classes.push("pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ");
      } else {
        classes.push("border-gray-300 focus:ring-sky-500 focus:border-sky-500");
      }

      return classes.join(" ");
    },
  },

  methods: {
    focus() {
      this.$refs.inputRef.focus();
    },
  },
};
</script>
