<template>
  <div class="mx-auto w-full max-w-lg lg:w-[28rem] hlg:py-8">
    <div>
      <div class="flex items-center space-x-2">
        <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
        <PlusIcon v-if="integrationStore.isTiendanube" class="h-4 w-4 stroke-[3]" aria-hidden="true" />
        <img
          v-if="integrationStore.isTiendanube"
          class="h-10 w-auto"
          src="/img/login-tiendanube-icon.svg"
          alt="Perfit"
        />
      </div>
      <h1 class="mt-4 pr-36 text-3xl font-extrabold leading-9 tracking-tight text-gray-900 lg:pr-20">
        {{ t("title") }}
      </h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
      </p>
    </div>

    <div class="mt-6">
      <form @submit.prevent="updateAccountData">
        <div class="mt-6">
          <FormInput
            v-model="formData.name"
            :label="t('nameLabel')"
            :placeholder="t('namePlaceholder')"
            :hint="t('nameHint')"
            :error="v$.name.$error ? getNameErrorMessage(v$.name.$errors) : undefined"
            autocomplete="organization"
            @input="v$.name.$reset()"
          />
        </div>

        <div class="mt-6">
          <FormInput
            v-model="formData.address"
            :label="t('addressLabel')"
            :placeholder="t('addressPlaceholder')"
            :hint="t('addressHint')"
            :error="v$.address.$error ? getAddressErrorMessage(v$.address.$errors) : undefined"
            autocomplete="street-address"
            @input="v$.address.$reset()"
          />
        </div>

        <SelectMenuLabel
          v-model:selected="selectedIndustry"
          :label="t('industryLabel')"
          :data-items="industries"
          :rem-height="10"
          :error="v$.industry.$error && selectedIndustry.key !== 'other' ? t('fieldRequired') : undefined"
          class="mt-6"
        />

        <div class="mt-4">
          <FormInput
            v-show="selectedIndustry.key === 'other'"
            v-model="formData.industry"
            :label="t('otherIndustryLabel')"
            :placeholder="t('otherIndustryPlaceholder')"
            :error="v$.industry.$error ? t('fieldRequired') : undefined"
            autocomplete="email"
            @input="v$.industry.$reset()"
          />
        </div>

        <SimpleButton
          type="submit"
          :loading="submitting"
          :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
        >
          <template #leading>
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </template>
          {{ t("save") }}
        </SimpleButton>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, watchEffect } from "vue";

import { getIndustries } from "../accountForm";

// components
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";
import SelectMenuLabel from "@molecules/SelectMenuLabel.vue";
import type { DataItem } from "@molecules/SelectMenuLabel.vue";

// Icons
import { CheckIcon, PlusIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import type { ErrorObject } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import Intercom from "@helpers/intercom";
import { parseISO, getUnixTime } from "date-fns";

// Stores
import { useAccountStore, useIntegrationsStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const accountStore = useAccountStore();
const integrationStore = useIntegrationsStore();

const emit = defineEmits<{
  continue: [void];
}>();

const continueToUserStep = () => {
  emit("continue");
};

const industries = getIndustries();
const selectedIndustry = ref<DataItem>({ key: "empty", value: t("industries.empty") });

const formData = reactive({
  name: "",
  address: "",
  industry: "",
});

watchEffect(() => {
  const selectedIndustryKey = selectedIndustry.value.key;

  if (["other", "empty"].includes(selectedIndustryKey)) {
    formData.industry = "";
    return;
  }

  formData.industry = selectedIndustryKey;
});

const nameMaxLength = 50;
const addressMaxLength = 50;
const validationRules = {
  name: { required, maxLength: maxLength(nameMaxLength) },
  address: { required, maxLength: maxLength(addressMaxLength) },
  industry: { required },
};

const v$ = useVuelidate(validationRules, formData);

const getNameErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "maxLength") return t("maxLengthError", { length: nameMaxLength });
  return t("fieldRequired");
};

const getAddressErrorMessage = (errors: ErrorObject[]): string | undefined => {
  if (errors.length === 0) return undefined;

  const error = errors[0];
  if (error.$validator === "maxLength") return t("maxLengthError", { length: nameMaxLength });
  return t("fieldRequired");
};

const submitting = ref(false);
const updateAccountData = async () => {
  // Fields validation
  const validation = await v$.value.$validate();
  if (!validation) return;

  submitting.value = true;

  await accountStore.setAccountData({
    data: {
      name: formData.name,
      address: formData.address,
      industry: formData.industry,
      code: accountStore.accountData?.code ?? "",
      country: accountStore.accountData?.country ?? "",
    },
  });

  const safeISOtoUNIX = (date: string | undefined) => {
    if (!date) return;
    try {
      return getUnixTime(parseISO(date));
    } catch (err) {
      return;
    }
  };

  //TODO: update tambien accountConfigData
  Intercom.update({
    company: {
      company_id: accountStore.accountData?.code,
      name: formData.name,
      industry: formData.industry,
      postal_address: formData.address,
      website: accountStore.accountConfigData?.website,
      country: accountStore.accountConfigData?.country,
      store_type: accountStore.accountConfigData?.store_type,
      tn_plan: accountStore.accountConfigData?.tn_plan,
      tn_created_at: safeISOtoUNIX(accountStore.accountConfigData?.tn_created_at),
    },
  });

  submitting.value = false;

  continueToUserStep();
};

watch(
  [() => accountStore.accountConfigData],
  () => {
    formData.address =
      accountStore.accountData?.postalAddress?.slice(0, addressMaxLength) ||
      accountStore.accountConfigData?.postal_address?.slice(0, addressMaxLength) ||
      "";
    formData.name =
      accountStore.accountData?.name.slice(0, nameMaxLength) ||
      accountStore.accountConfigData?.business_name?.slice(0, nameMaxLength) ||
      "";
    formData.industry = accountStore.accountData?.industry || accountStore.accountConfigData?.industry || "";

    const industry = industries.find((i) => i.key === formData.industry);
    if (!industry && !accountStore.accountConfigData?.industry) {
      selectedIndustry.value = { key: "empty", value: t("industries.empty") };
      return;
    }
    if (!industry) {
      selectedIndustry.value = { key: "other", value: t("industries.other") };
      return;
    }

    selectedIndustry.value = industry;
  },
  { immediate: true },
);
</script>

<i18n lang="json">
{
  "es": {
    "title": "¡Falta poco para comenzar a potenciar tus ventas!",
    "subtitle": "Completa los siguientes datos para avanzar con la creación de tu cuenta y personalizar tu experiencia.",
    "nameLabel": "Nombre de empresa",
    "namePlaceholder": "Mi Tienda Online S.A.",
    "nameHint": "Usaremos esta información para que tu audiencia te reconozca.",
    "addressLabel": "Domicilio postal",
    "addressPlaceholder": "Avenida 123, Ciudad, País.",
    "addressHint": "Se incluirá en tus emails para cumplir con políticas antispam.",
    "industryLabel": "Rubro o sector",
    "otherIndustryLabel": "Otro rubro",
    "otherIndustryPlaceholder": "Escribe el rubro de tu empresa",
    "fieldRequired": "Este campo es requerido",
    "maxLengthError": "Máximos {length} caracteres",
    "save": "Continuar",
    "industries": {
      "clothing_accesories": "Accesorios de indumentaria",
      "antiques": "Antigüedades",
      "art": "Arte",
      "cars": "Automóviles",
      "home_garden": "Casa y jardín",
      "food_drinks": "Comida y bebida",
      "industrial": "Construcción/Industrial",
      "sports": "Deportes/Recreación",
      "digital": "Digital/Bienes digitales",
      "education": "Educación",
      "electronics_it": "Electrónica/IT/Computación",
      "clothing": "Indumentaria",
      "office_supplies": "Insumos de oficina",
      "jewelry": "Joyas/Relojes",
      "toys": "Juguetes/Juegos/Hobbies",
      "books": "Libros/Revistas",
      "pets": "Mascotas",
      "music_movies": "Música/Películas",
      "gifts": "Regalos",
      "health_beauty": "Salud y belleza",
      "services": "Servicios",
      "travel": "Viajes",
      "erotics": "Erótica",
      "bookstore_graphic": "Librería/Gráfica",
      "equipment_machines": "Equipamiento/Maquinaria",
      "other": "Otro",
      "empty": "Selecciona una opción"
    }
  },
  "pt": {
    "title": "Está quase na hora de potencializar a sua loja!",
    "subtitle": "Complete os seguintes dados para avançar com a criação da sua conta e personalizar a sua experiência.",
    "nameLabel": "Nome da empresa",
    "namePlaceholder": "Minha Loja Online S.A.",
    "nameHint": "Usaremos estas informações para que sua audiência o reconheça.",
    "addressLabel": "Endereço postal",
    "addressPlaceholder": "Avenida 123, Cidade, País.",
    "addressHint": "Será incluído nos seus e-mails para cumprir com as políticas anti-spam.",
    "industryLabel": "Setor ou ramo",
    "otherIndustryLabel": "Outro setor",
    "otherIndustryPlaceholder": "Digite o setor da sua empresa",
    "fieldRequired": "Este campo é obrigatório",
    "maxLengthError": "Máximo de {length} caracteres",
    "save": "Continuar",
    "industries": {
      "clothing_accesories": "Acessórios de moda",
      "antiques": "Antiguidades",
      "art": "Artesanato",
      "cars": "Automóveis",
      "home_garden": "Casa e jardim",
      "food_drinks": "Comida e bebida",
      "industrial": "Construção e Indústria",
      "sports": "Esportes e Recreação",
      "digital": "Bens digitais",
      "education": "Educação",
      "electronics_it": "Tecnologia",
      "clothing": "Moda e Vestuário",
      "office_supplies": "Material de escritório",
      "jewelry": "Jóias e Relógios",
      "toys": "Jogos e Hobbies",
      "books": "Livros e Revistas",
      "pets": "Pets e Mascotes",
      "music_movies": "Música e cinema",
      "gifts": "Presentes",
      "health_beauty": "Saúde e Beleza",
      "services": "Serviços",
      "travel": "Turismo",
      "erotics": "Sex shop",
      "bookstore_graphic": "Livraria e Gráfica",
      "equipment_machines": "Equipamentos e Maquinário",
      "other": "Outra",
      "empty": "Selecione uma opção"
    }
  }
}
</i18n>
