<template>
  <div class="space-y-10">
    <div class="space-y-8">
      <div>
        <RadioGroup
          v-model:selected="selectedConversionOption"
          :options="conversionOptions"
          :disabled="loadingData"
          :label="t('attributionLabel')"
        />
      </div>
      <div class="flex space-x-10">
        <div>
          <FormCheckBox v-model="enableCurrencySelector" :label="t('CurrencyCheckBoxLabel')" />
          <SelectMenuLabel
            v-model:selected="selectedCurrency"
            :disabled="!enableCurrencySelector"
            :loading="loadingData"
            :hint="t('CurrencyCheckBoxDescription')"
            :data-items="CurrenciesDataItems"
            :rem-height="11"
            :class="!enableCurrencySelector && 'opacity-50'"
            class="ml-7 mt-2 w-96"
          />
        </div>
        <div>
          <FormCheckBox
            v-model="enableMonthlyPlanCost"
            :disabled="!enableCurrencySelector || !allowMonthlyPlanCost"
            :label="t('MonthlyPlanCostlabel')"
          />
          <div class="ml-7 mt-2 w-96">
            <InputCurrencyFormat
              v-model="monthlyPlanCost"
              :hint="t('MonthlyPlanCost')"
              :currency="selectedCurrency?.key"
              :disabled="!enableCurrencySelector || !allowMonthlyPlanCost || !enableMonthlyPlanCost"
              :loading="loadingData"
              :error="!!v$.monthly_cost.$errors[0]"
              :class="!enableCurrencySelector && 'opacity-50'"
              @blur="v$.$validate()"
            />
          </div>
        </div>
      </div>
      <div class="max-w-4xl">
        <AlertBox> {{ t("alertInfoConfiguration") }}</AlertBox>
      </div>
    </div>
    <div class="flex justify-end space-x-3">
      <SimpleButton
        :loading="loadingSave"
        :disabled="!!v$.monthly_cost.$errors[0] || (enableMonthlyPlanCost && !monthlyPlanCost)"
        @click="saveConfig"
      >
        {{ t("ButtonSaveConfig") }}
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
      </SimpleButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, watchEffect } from "vue";

//Components
import FormCheckBox from "@molecules/FormCheckBox.vue";
import InputCurrencyFormat from "@/vue/components/molecules/InputCurrencyFormat.vue";
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import SelectMenuLabel from "@/vue/components/molecules/SelectMenuLabel.vue";
import RadioGroup from "@molecules/RadioGroup.vue";
import AlertBox from "@/vue/components/atoms/AlertBox.vue";

//Icons
import { CheckIcon } from "@heroicons/vue/solid";

//Types
import type { DataItem, DataItems } from "@/vue/components/molecules/SelectMenuLabel.vue";
import { RadioOption, RadioOptions } from "@molecules/RadioGroup.vue";

//API
import { useAccountConfig, IAccountConfig, AccountConfigAttributionModelType } from "@api/modules/account";

//Notifications
import { useNotifications } from "@composables/notifications";

//Vuelidate
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";

//I18n
import { useI18n } from "vue-i18n";
import { Currencies } from "@locales/i18n";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Props
const props = withDefaults(
  defineProps<{
    account?: string;
  }>(),
  {
    account: undefined,
  }
);

//Emits
const emit = defineEmits<{
  (e: "saved", config: IAccountConfig): void;
}>();

//I18n
const { t } = useI18n();

const { notify } = useNotifications();

const AccountConfig = useAccountConfig();

//Const
const CurrenciesDataItems = ref([
  {
    key: Currencies.ARS,
    value: t("Currencies.ARS"),
  },
  {
    key: Currencies.BRL,
    value: t("Currencies.BRL"),
  },
  {
    key: Currencies.MXP,
    value: t("Currencies.MXP"),
  },
  {
    key: Currencies.USD,
    value: t("Currencies.USD"),
  },
  {
    key: Currencies.EUR,
    value: t("Currencies.EUR"),
  },
  {
    key: Currencies.PEN,
    value: t("Currencies.PEN"),
  },
  {
    key: Currencies.PYG,
    value: t("Currencies.PYG"),
  },
  {
    key: Currencies.UYU,
    value: t("Currencies.UYU"),
  },
  {
    key: Currencies.GTQ,
    value: t("Currencies.GTQ"),
  },
  {
    key: Currencies.HNL,
    value: t("Currencies.HNL"),
  },
  {
    key: Currencies.SVC,
    value: t("Currencies.SVC"),
  },
  {
    key: Currencies.CLP,
    value: t("Currencies.CLP"),
  },
  {
    key: Currencies.COP,
    value: t("Currencies.COP"),
  },
  {
    key: Currencies.CRC,
    value: t("Currencies.CRC"),
  },
  {
    key: Currencies.BOB,
    value: t("Currencies.BOB"),
  },
  {
    key: Currencies.CAD,
    value: t("Currencies.CAD"),
  },
  {
    key: Currencies.VEF,
    value: t("Currencies.VEF"),
  },
] as DataItems<unknown, Currencies, Currencies>);

const conversionOptions = ref<RadioOptions>([
  {
    key: "opens",
    title: t("attributionByOpening"),
    description: t("attributionByOpeningDescription"),
  },
  {
    key: "clicks",
    title: t("attributionByClickTitle"),
    description: t("attributionByClickDescription"),
  },
]);

//Flags
const enableCurrencySelector = ref(false);
const enableMonthlyPlanCost = ref(false);
const allowMonthlyPlanCost = ref(
  ["MONTHLY", "CHILD_UNCAPPED", "CHILD_LIMITED"].includes(session.value?.account?.plan?.type ?? "")
);
const loadingData = ref(false);
const loadingSave = ref(false);

//State
const selectedCurrency = ref<DataItem<unknown, Currencies, Currencies> | undefined>(CurrenciesDataItems.value[0]);
const selectedConversionOption = ref<RadioOption>(conversionOptions.value[0]);
const monthlyPlanCost = ref("");

const accountConfig = computed<IAccountConfig>(() => ({
  currency: enableCurrencySelector.value ? selectedCurrency.value?.key : "",
  use_custom_currency: enableCurrencySelector.value,
  monthly_cost:
    monthlyPlanCost.value && enableCurrencySelector.value && enableMonthlyPlanCost.value
      ? Number(monthlyPlanCost.value)
      : 0,
  attribution_model: selectedConversionOption.value.key as AccountConfigAttributionModelType,
}));

//Validations
const isValidNumberIfRequired = (value) => !helpers.req(value) || !isNaN(Number(value));

const validationRules = {
  monthly_cost: {
    isValidNumberIfRequired,
    MonthlyCostRequiredIfEnabled: requiredIf(() => enableMonthlyPlanCost.value),
  },
};

const v$ = useVuelidate(validationRules, { monthly_cost: accountConfig.value.monthly_cost });

//Methods
const saveConfig = async () => {
  loadingSave.value = true;

  const validation = await v$.value.$validate();
  if (validation) {
    await AccountConfig.put({
      account: props.account,
      data: accountConfig.value,
    });

    loadingSave.value = false;

    notify({
      title: t("configSavedNotificationTitle"),
      text: t("configSavedNotificationMessage"),
    });
    emit("saved", accountConfig.value);
  }
};

//Life cycle
onMounted(async () => {
  loadingData.value = true;

  const data = await AccountConfig.get({
    account: props.account,
  });

  selectedCurrency.value = data?.currency
    ? CurrenciesDataItems.value.find((item) => item.key === data.currency)
    : CurrenciesDataItems.value[0];
  enableCurrencySelector.value = !!data?.use_custom_currency;
  monthlyPlanCost.value = data?.monthly_cost?.toString() ?? "";
  enableMonthlyPlanCost.value = !!data?.monthly_cost;

  const conversionOption = conversionOptions.value.find((option) => option.key === data.attribution_model);

  if (conversionOption) selectedConversionOption.value = conversionOption;

  loadingData.value = false;
});

//Watchers
watchEffect(() => {
  enableMonthlyPlanCost.value = enableCurrencySelector.value ? enableMonthlyPlanCost.value : false;
});

watch(
  () => enableMonthlyPlanCost.value,
  () => !enableMonthlyPlanCost.value && v$.value.$reset()
);
</script>

<i18n lang="jsonc">
{
  "es": {
    "alertInfoConfiguration": "Esta configuración se utilizará sólo a nivel visual, para presentar la información de las ventas y realizar el cálculo del retorno de inversión. Los datos almacenados no se verán afectados.",
    "MonthlyPlanCostLabel": "Precio",
    "configSavedNotificationTitle": "Configuración guardada",
    "configSavedNotificationMessage": "La configuración se ha guardado correctamente.",
    "CurrencyCheckBoxLabel": "Personalizar moneda",
    "CurrencyLabel": "Moneda",
    "CurrencyCheckBoxDescription": "Moneda en la que se realizan las ventas de la tienda.",
    "MonthlyPlanCostlabel": "Personalizar costo mensual",
    "MonthlyPlanCost": "Costo mensual a utilizar para el cálculo del ROI.",
    "ButtonSaveConfig": "Guardar",
    "Currencies": {
      "ARS": "Peso Argentino (ARS)",
      "BRL": "Real Brasileño (BRL)",
      "USD": "Dólar Americano (USD)",
      "EUR": "Euro (EUR)",
      "MXP": "Peso Mexicano (MXP)",
      "PEN": "Sol Peruano (PEN)",
      "PYG": "Guaraní (PYG)",
      "UYU": "Peso Uruguayo (UYU)",
      "GTQ": "Quetzal (GTQ)",
      "HNL": "Lempira (HNL)",
      "SVC": "Colón Salvadoreño (SVC)",
      "CLP": "Peso Chileno (CLP)",
      "COP": "Peso Colombiano (COP)",
      "CRC": "Colón Costarricense (CRC)",
      "BOB": "Boliviano (BOB)",
      "CAD": "Dólar Canadiense (CAD)",
      "VEF": "Bolívar (VEF)"
    },
    "attributionLabel": "Modelo de atribución",
    "attributionByClickTitle": "Atribución por click",
    "attributionByClickDescription": "Contabilizamos una conversión cuando se realiza una compra dentro de los 7 días de un click.",
    "attributionByOpening": "Atribución por apertura",
    "attributionByOpeningDescription": "Contabilizamos una conversión cuando se realiza una compra dentro de los 7 días de una apertura."
  },
  "pt": {
    "alertInfoConfiguration": "Essa configuração será utilizada somente a nível visual, para apresentar a informação das vendas e realizar o cálculo do retorno de inversão. Os dados armazenados não serão afetados.",
    "MonthlyPlanCostLabel": "Preço",
    "configSavedNotificationTitle": "Configuração salva",
    "configSavedNotificationMessage": "A configuração foi salva corretamente.",
    "CurrencyCheckBoxLabel": "Personalizar moeda",
    "CurrencyLabel": "Moeda",
    "CurrencyCheckBoxDescription": "Moeda em que se realizam as vendas da loja.",
    "MonthlyPlanCostlabel": "Personalizar custo mensal",
    "MonthlyPlanCost": "Custo mensal a utilizar para o cálculo do ROI.",
    "ButtonSaveConfig": "Salvar",
    "Currencies": {
      "ARS": "Peso Argentino (ARS)",
      "BRL": "Real Brasileiro (BRL)",
      "USD": "Dólar Americano (USD)",
      "EUR": "Euro (EUR)",
      "MXP": "Peso Mexicano (MXP)",
      "PEN": "Sol Peruano (PEN)",
      "PYG": "Guaraní (PYG)",
      "UYU": "Peso Uruguaio (UYU)",
      "GTQ": "Quetzal (GTQ)",
      "HNL": "Lempira (HNL)",
      "SVC": "Colón Salvadoreño (SVC)",
      "CLP": "Peso Chileno (CLP)",
      "COP": "Peso Colombiano (COP)",
      "CRC": "Colón Costarricense (CRC)",
      "BOB": "Boliviano (BOB)",
      "CAD": "Dólar Canadense (CAD)",
      "VEF": "Bolívar (VEF)"
    },
    "attributionLabel": "Modelo de atribuição",
    "attributionByClickTitle": "Atribuição por clique",
    "attributionByClickDescription": "Contabilizamos uma conversão quando se realiza uma compra dentro de 7 dias de um clique.",
    "attributionByOpening": "Atribuição por abertura",
    "attributionByOpeningDescription": "Contabilizamos uma conversão quando se realiza uma compra dentro de 7 dias de uma abertura."
  }
}
</i18n>
