<template>
  <div class="max-w-xs rounded-lg bg-white shadow">
    <div class="p-4">
      <div class="flex gap-x-2">
        <div>
          <ArrowCircleUpIcon class="h-6 w-6 text-yellow-500" />
        </div>
        <div>
          <h3 class="m-0 text-sm font-medium leading-6 text-gray-700">{{ t("header.title") }}</h3>
          <p class="text-sm text-gray-400">{{ t(`subtitle.${feature}`) }}</p>
        </div>
      </div>
    </div>
    <hr class="border-1 m-0 border-solid border-gray-100" />
    <div class="p-4 px-4">
      <SimpleButton :onclick="openPlanUpgradeModal" theme="yellow" size="small" class="w-full flex-col">{{
        t("button.moreInfo")
      }}</SimpleButton>
    </div>
  </div>
  <PlanUpgradeModal :is-open="isOpen" :feature="feature" @close="isOpen = false" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { ArrowCircleUpIcon } from "@heroicons/vue/outline";
import SimpleButton from "@atoms/SimpleButton.vue";
import PlanUpgradeModal from "@molecules/PlanUpgradeModal.vue";

//Props
withDefaults(
  defineProps<{
    feature?: "rfm" | "automations" | "campaigns" | "heatmap";
  }>(),
  {
    feature: "campaigns",
  }
);

const { t } = useI18n();
const isOpen = ref(false);
const openPlanUpgradeModal = () => (isOpen.value = true);
</script>

<i18n lang="json">
{
  "es": {
    "header": {
      "title": "Activa una suscripción mensual"
    },
    "subtitle": {
      "automations": "Accede a nuevos automations",
      "campaigns": "Accede a campañas avanzadas",
      "rfm": "Accede a la segmentación automática de compradores",
      "heatmap": "Accede al mapa de calor de clicks"
    },
    "button": {
      "moreInfo": "Más información"
    }
  },
  "pt": {
    "header": {
      "title": "Ative sua assinatura mensal"
    },
    "subtitle": {
      "automations": "Acesse novas automações",
      "campaigns": "Acesse campanhas avançadas",
      "rfm": "Acesse a segmentação automática de compradores",
      "heatmap": "Acesse o mapa de calor de cliques"
    },
    "button": {
      "moreInfo": "Mais informação"
    }
  }
}
</i18n>
