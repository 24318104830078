import { EditView } from "@/core/magnet";
import ListMixin from "@/mixins/list";
import { _ } from "@/helpers/utils";
import app from "@/app";
import editTpl from "../templates/edit.hbs";
import showTpl from "../templates/show.hbs";
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

export default EditView.mix(ListMixin).extend({
  module: "lists",
  templates: {
    initial: showTpl,
    show: showTpl,
    edit: editTpl,
  },
  gender: "F",
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-clean-modal]": "openCleanModal",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-split-modal]": "openSplitModal",
      "click [data-action=open-sample-modal]": "openSampleModal",
      "click [data-action=open-export-modal]": "openExportModal",
      "click [data-action=open-filter-modal]": "openFilterModal",
      "click [data-action=open-delete-modal]": "openDeleteModal",
      "change [data-action=change-filter-condition]": "changeFilterCondition",
      "change [data-format-type]": "toogleConditionValue",
      "submit [data-form=create-task]": "createTask",
      "submit [data-form=clean]": "clean",
      "submit [data-form=export]": "export",
      "submit [data-form=delete-list]": "deleteList",
      "click [data-action=analyze]": "analyze",
      "keyup [data-value=ALL]": "updateListName",
      "change [data-value=DATEPICKER]": "updateListName",
      "change [data-role=filter-value] select": "updateListName",
      "click [data-action=unsubscribe]": "unsubscribe",
      "click [data-action=respawn]": "respawn",
      "change [name=deleteContacts]": "onChangeDeleteRadio",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Render tags
    this.renderTags();

    // Draw charts
    $(".chart").easyPieChart({
      scaleColor: false,
      lineWidth: 7,
      size: 100,
    });

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Render the tag component
  renderTags: function () {
    var view = this,
      tagsInput = $(".tags-input"),
      options = tagsInput.data("options"),
      tags = new window.Bloodhound({
        datumTokenizer: window.Bloodhound.tokenizers.obj.whitespace("id"),
        queryTokenizer: window.Bloodhound.tokenizers.whitespace,
        local: options,
      }),
      lastRemoved = false;

    // Setups the typeahead tags component
    tags.initialize();
    tagsInput.tagsinput({
      tagClass: "label label-outline",
      addOnBlur: false,
      typeaheadjs: {
        name: "tags",
        displayKey: "id",
        valueKey: "id",
        source: tags.ttAdapter(),
      },
    });

    // Manage focus and blur
    this.$(".tt-input")
      .on("focus", function (e) {
        var target = $(e.currentTarget);
        target.addClass("tt-editing").val("").removeAttr("value");
        lastRemoved = false;
      })
      .on("blur", function (e) {
        var target = $(e.currentTarget),
          value = target.val(),
          internal = target.data("internal"),
          list = tagsInput.tagsinput("items");
        if ($.inArray(value, list) === -1 && lastRemoved !== internal) {
          tagsInput.tagsinput("add", value);
          tagsInput.attr("data-changed", 1);
        }
        target.removeClass("tt-editing").val("").removeAttr("value");
      });

    // Check last removed item
    tagsInput.on("itemRemoved", function (e) {
      lastRemoved = e.item;
      tagsInput.attr("data-changed", 1);
      view.$(".tt-input").data("internal", lastRemoved);
    });
  },

  // Gets a form data
  getFormData: function (form) {
    var view = this,
      data = view.getFormFieldsObject(form),
      tagsChanged = this.$(".tags-input").data("changed");

    // Ensure sending an empty array of tags if there were removed
    if (tagsChanged && view.item.has("tags") && view.item.get("tags").length && _.isUndefined(data.tags)) {
      data.tags = [];
    }

    // Return form data
    return data;
  },

  // Opens clean modal
  openCleanModal: function () {
    return this.openModal(require("../templates/modals/clean.hbs"), {
      lang: window.lang,
      data: this.item,
    });
  },

  // Opens duplicate modal
  openDuplicateModal: function () {
    return this.openModal(require("../templates/modals/duplicate.hbs"), {
      lang: window.lang,
      data: this.item,
    });
  },

  // Opens split modal
  openSplitModal: function () {
    return this.openModal(require("../templates/modals/split.hbs"), {
      lang: window.lang,
      data: this.item,
    });
  },

  // Opens sample modal
  openSampleModal: function () {
    this.openModal(require("../templates/modals/sample.hbs"), {
      lang: window.lang,
      data: this.item,
    });
  },

  // Opens the export modal
  openExportModal: function () {
    return this.openModal(require("../templates/modals/export.hbs"), {
      lang: window.lang,
      legend: window.lang.exportLegendSingular,
    });
  },

  // Opens a modal to filter
  openFilterModal: function () {
    this.requestFilters(this.item, require("../templates/modals/filter.hbs"));
  },

  openDeleteModal: function () {
    return this.openModal(require("../templates/modals/delete.hbs"), {
      lang: window.lang,
    });
  },

  // Cleans the list
  deleteList: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    this.item.delete(!!data.deleteContacts).done(function () {
      self.item = null;
      self.afterDelete();
    });
  },

  onChangeDeleteRadio: function () {
    $("#button-delete-list").removeAttr("disabled");
  },

  // Creates a task based on the modal options
  createTask: function (e) {
    var view = this,
      form = $(e.currentTarget),
      feedback = form.data("feedback"),
      url = form.attr("action"),
      data = form.serializeObject();
    e.preventDefault();

    // Validate values
    if (!view.validateFormFields(form)) {
      return view;
    }

    // If needed, parse quality
    if (data.quality !== undefined) {
      data.quality = data.quality / 5;
    }

    // Performs an AJAX call to the action endpoint
    $.post(url, data, function () {
      view.finish(feedback);
    });
  },

  // Cleans the list
  clean: function (e) {
    var view = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Validate form
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Transform quality value
    if (_.isDefined(data.quality)) {
      data.quality = data.quality / 10;
    }

    // Make request to clean list
    this.item.clean(data).done(function () {
      view.finish(window.lang.lists.feedback.cleaned);
    });
  },

  // Exports the list
  export: function (e) {
    try {
      const notificationStore = useNotificationStore();
      notificationStore?.fetchTasks();
    } catch {
      // No handler
    } finally {
      e.preventDefault();
      this.item.export().done(
        function () {
          this.finish(window.lang.lists.feedback.exported);
        }.bind(this),
      );
    }
  },

  // Reloads after a successful call
  finish: function (feedback) {
    // Update the tasks indicator
    app.instances.singleton("tasks", "list").render();

    // Close modal
    this.getModal().modal("hide");

    // Display feedback
    this.displayMessage(feedback);

    // Reload
    this.reload();
  },

  // Unsubscribe contacts
  unsubscribe: function (e) {
    var button = $(e.currentTarget);
    e.preventDefault();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Fetch API
    this.item.unsubscribe().done(
      function () {
        this.finish(window.lang.lists.feedback.unsubscribed);
      }.bind(this),
    );
  },

  // Respawn contacts
  respawn: function (e) {
    var button = $(e.currentTarget);
    e.preventDefault();

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Fetch API
    this.item.respawn().done(
      function () {
        this.finish(window.lang.lists.feedback.respawned);
      }.bind(this),
    );
  },

  // Analyze
  analyze: function () {
    this.item.analyze().done(
      function () {
        this.finish(window.lang.lists.feedback.analyze);
      }.bind(this),
    );
  },
});
