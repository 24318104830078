var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-danger' data-action='deactivate'\n      data-confirm='"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"confirmDeactivate")), depth0))
    + "'>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"deactivate")), depth0))
    + "\n    </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"fields") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-action='open-fields-modal'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"activate")), depth0))
    + "\n    </button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button type='button' class='btn btn-primary' data-activate='1'>\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"activate")), depth0))
    + "\n    </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a class='btn btn-primary' target='_blank' href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\">\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"seeDocs")), depth0))
    + "\n    </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " enabled ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"form-group\">\n            <label>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"connectedStore")), depth0))
    + "</label>\n            <div class=\"input\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"vtexAccount") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n          <div class=\"form-group\">\n            <label>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"storeUrl")), depth0))
    + "</label>\n            <div class=\"input\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"baseUrl") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n\n\n          <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"refreshProductsInstructions")), depth0)) != null ? stack1 : "")
    + "</p>\n          <br/>\n          <button type='button' class='btn btn-primary' data-action='refresh-all'>\n            "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"refreshProducts")), depth0))
    + "\n          </button>\n\n\n          <br /><br />\n          <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"manualTriggerConfigurationTitle")), depth0))
    + "</h3>\n          <p>"
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"manualTriggerConfigurationTextVTEX")), depth0)) != null ? stack1 : "")
    + "</p>\n\n          <div class=\"form-group\">\n            <label>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"manualTriggerAbandonedCart")), depth0))
    + "</label>\n            <div class=\"input\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"webhooks") : stack1)) != null ? lookupProperty(stack1,"cart-abandoned") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n          <div class=\"form-group\">\n            <label>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"manualTriggerNewsletterSubscribed")), depth0))
    + "</label>\n            <div class=\"input\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"webhooks") : stack1)) != null ? lookupProperty(stack1,"newsletter-subscribed") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n          <div class=\"form-group\">\n            <label>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"manualTriggerUserCreated")), depth0))
    + "</label>\n            <div class=\"input\">\n              <input class=\"form-control monospace\" readonly=\"\" name=\"apikey\" id=\"apikey\"\n                value=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"webhooks") : stack1)) != null ? lookupProperty(stack1,"user-created") : stack1), depth0))
    + "\">\n            </div>\n          </div>\n\n          <h3>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"trackingCodeTitleVTEX")), depth0))
    + "</h3>\n          <p>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"trackingCodeTextVTEX")), depth0))
    + "</p>\n          <br/>\n<pre>\n&lt;script&gt;\nwindow.perfitSettings = {app:'"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"account")), depth0))
    + "',source:'vtex'};\n(function(){var t=window;if(\"function\"!=typeof t.Perfit){var e=document,n=function(){n.c(arguments)};(n.q=[]),(n.c=function(t){n.q.push(t);}),(t.Perfit=n);\n(function(){var t = e.createElement(\"script\");(t.type=\"text/javascript\"),(t.async=!0),(t.src=\"https://js.myperfit.net/perfit.js\");\nvar n=e.getElementsByTagName(\"script\")[0];n.parentNode.insertBefore(t,n);})();}})();\nPerfit('identify');\nPerfit('visit');\n&lt;/script&gt;\n</pre>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='integrations section show'>\n  <div class='section-header'>\n    <h1>\n      <a data-navigate='integrations'><i class='icon icon-back'></i></a>\n      <span>\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n      </span>\n    </h1>\n  </div>\n  <div class='toolbar edit-mode'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"requiresActivation") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":32,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <div class='content edit scrollbar'>\n    <div class='padder'>\n      <div class='row'>\n        <div class='col-md-4'>\n          <div class='thumbnail integration "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"enabled") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":44},"end":{"line":38,"column":91}}})) != null ? stack1 : "")
    + "'>\n            <div class='preview' style='background-image: url(/img/integrations/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + ".png)'>\n            </div>\n          </div>\n          <div>\n            "
    + ((stack1 = __default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"features")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":51,"column":79}}})) != null ? stack1 : "")
    + "\n          </div>\n        </div>\n        <div class='col-md-8'>\n          <h2>"
    + alias2(__default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"titles")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":55,"column":14},"end":{"line":55,"column":77}}}))
    + "</h2>\n          <p>"
    + ((stack1 = __default(require("../../../helpers/handlebars/translate.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"integrations"))) && lookupProperty(stack1,"descriptions")),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":56,"column":13},"end":{"line":56,"column":84}}})) != null ? stack1 : "")
    + "</p>\n          <br />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"attributes") : stack1)) != null ? lookupProperty(stack1,"webhooks") : stack1)) != null ? lookupProperty(stack1,"cart-abandoned") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":10},"end":{"line":121,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});