import { Collection } from "@/core/magnet";
import { round } from "@/helpers/utils";
import ListsModel from "./model";

export default Collection.extend({
  url: "lists",
  model: ListsModel,
  parse: function (response) {
    return response.data.map(function (list) {
      list.rating = list.quality ? round(list.quality, 1) * 100 : 0;
      return list;
    });
  },
  filters: {
    tags: {
      key: "tags",
      icon: "pi-filter-tag",
      name: function () {
        return window.lang.tags.plural;
      },
      source: function () {
        return $.get("lists/tags", { limit: 1000 });
      },
      parser: function (item) {
        return {
          id: item,
          title: item,
          keywords: item,
          params: {
            key: "filter.tags",
            value: item,
          },
        };
      },
    },
  },
});
