<template>
  <div>
    <ul class="min-h-[22rem] space-y-2 overflow-hidden px-1">
      <TagListSkeleton v-show="loading" />
      <li
        v-if="!loading && tagSelected && !isTag(tagSelected)"
        class="rounded-md border border-gray-200 py-3 px-3 hover:border-gray-300 focus-visible:border-gray-300"
      >
        <TagItemEdit :tag="tagSelected" :loading-accept="loadingAccept" @cancel="cancelEditing" @accept="updateTag" />
      </li>
      <template v-if="!loading">
        <TransitionGroup
          leave-active-class="transition duration-200 ease"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0 transform translate-x-3"
          enter-active-class="transition duration-200 ease"
          enter-to-class="opacity-100"
          enter-from-class="opacity-0 transform -translate-x-3"
        >
          <li
            v-for="tag in tags"
            :key="tag.id"
            class="group rounded-md border border-gray-200 py-1 px-3 hover:border-gray-300 focus-visible:border-gray-300"
          >
            <TagItemEdit
              v-if="tagSelected && isTag(tagSelected) && tag.id === tagSelected?.id"
              :tag="tagSelected"
              :loading-accept="loadingAccept"
              class="py-3"
              @cancel="cancelEditing"
              @accept="updateTag"
            />
            <TagItem
              v-else
              :tag="tag"
              :templates-count="stats?.tags?.[tag.id]"
              :loading-delete="loadingDelete"
              @open-delete="openDelete"
              @delete-tag="() => deleteTag(tag)"
              @edit-tag="() => editTag(tag)"
            />
          </li>
        </TransitionGroup>
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
// Components
import TagItem from "./TagItem.vue";
import TagItemEdit from "./TagItemEdit.vue";
import TagListSkeleton from "./TagListSkeleton.vue";

// Type
import { isTag } from "@domain/tag";
import type { Tags, Tag, NewTag } from "@domain/tag";
import type { StatsByTag } from "@/vue/api/modules/templates/templates.types";

// Props
withDefaults(
  defineProps<{
    tags: Tags;
    stats?: StatsByTag;
    tagSelected?: Tag | NewTag;
    loading?: boolean;
    loadingAccept?: boolean;
    loadingDelete?: boolean;
  }>(),
  {
    loading: false,
    stats: undefined,
    tagSelected: undefined,
    loadingAccept: false,
    loadingDelete: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "deleteTag", tag: Tag): void;
  (e: "editTag", tag: Tag): void;
  (e: "cancelEditing"): void;
  (e: "createTag", tag: NewTag): void;
  (e: "updateTag", tag: Tag): void;
  (e: "openDelete"): void;
}>();

const openDelete = () => {
  emit("openDelete");
};

const deleteTag = (tag: Tag) => {
  emit("deleteTag", tag);
};

const editTag = (tag: Tag) => {
  emit("editTag", tag);
};

const cancelEditing = () => {
  emit("cancelEditing");
};

const updateTag = (tag: Tag | NewTag) => {
  if (isTag(tag)) {
    emit("updateTag", tag);
  } else {
    emit("createTag", tag);
  }
};
</script>
