<template>
  <div ref="modalTagListRef" class="rounded bg-white px-4 py-4">
    <TagsList
      v-bind="props"
      class="max-h-[50vh] overflow-y-auto pr-2"
      :tag-size="tagSize"
      @delete="onDelete"
      @click="onClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import TagsList from "./TagsList.vue";

// Types
import type { Tags, Tag } from "@domain/tag";
import type { Size } from "@atoms/SimpleBadge";

// Props
interface Props {
  tags: Tags;
  readonly: boolean;
  clickable?: boolean;
  showTagColors?: boolean;
  tagSize?: Size;
}

const props = withDefaults(defineProps<Props>(), {
  tags: () => [],
  readonly: false,
  showTagColors: true,
  clickable: false,
});

// Emits
const emit = defineEmits<{
  (e: "delete", tag: Tag): void;
  (e: "click", tag: Tag): void;
}>();

const modalTagListRef = ref();

const onDelete = (tag: Tag) => emit("delete", tag);
const onClick = (tag: Tag) => emit("click", tag);

defineExpose({
  ref: modalTagListRef,
});
</script>
