// Helpers
import { generateExpirationDate } from "@helpers/dates";

// Type
import type { CookiesService } from "@application/ports";
import { isSetCookieByDate } from "@application/ports";

export const useCookiesService = (): CookiesService => {
  return {
    setCookie(params) {
      const { cname, value } = params;
      let expires: DateString;

      if (isSetCookieByDate(params)) {
        const { exDate } = params;
        expires = exDate;
      } else {
        const { exDays, exHours, exMinutes, exSeconds, exMiliseconds } = params;
        expires = generateExpirationDate({
          exDays,
          exHours,
          exMinutes,
          exSeconds,
          exMiliseconds,
        });
      }

      document.cookie = cname + "=" + value + ";expires=" + expires + ";path=/";
    },
    deleteCookie(cookieName) {
      document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    getCookie(cookieName) {
      const name = cookieName + "=";
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) == " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return "";
    },
    getParsedCookie(cookieName: string) {
      const value = this.getCookie(cookieName);
      try {
        return JSON.parse(value);
      } catch (e) {
        return undefined;
      }
    },
  };
};
