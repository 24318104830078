<template>
  <div>
    <div class="space-y-4">
      <transition-group
        leave-active-class="transition duration-300 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 transform translate-x-10"
        enter-active-class="transition duration-300 ease-in-out"
        enter-to-class="opacity-100"
        enter-from-class="opacity-0 transform translate-x-10"
      >
        <div v-for="(subject, index) in subjects" :key="subject.id" class="flex space-x-2">
          <SubjectInput
            :model-value="subject.value"
            :dropdowns-upwards="dropdownsUpwards"
            :fields="fields"
            :placeholder="placeholder"
            class="flex-grow my-auto"
            @update:model-value="(value: string)=>updateSubject(value, subject.id)"
          >
            <template #icon-buttons>
              <slot name="icon-buttons" :index="index" />
            </template>
          </SubjectInput>
          <IconButton
            :label="index == 0 ? t('addIconButton') : t('trashIconButton')"
            :theme="index == 0 ? 'success' : 'danger'"
            class="ml-2"
            :class="
              ((subjects.length >= maxInputs && index == 0) || (subjects.length <= minInputs && index != 0)) &&
              'opacity-50 pointer-events-none'
            "
            @click="() => (index == 0 ? addSubject() : deleteSubject(subject.id))"
          >
            <PlusCircleIcon v-if="index == 0" />
            <TrashIcon v-else />
          </IconButton>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";

//Components
import SubjectInput from "./SubjectInput.vue";
import IconButton from "@atoms/IconButton.vue";

//Icon
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/outline";

//I18n
import { useI18n } from "vue-i18n";

//Types
import { Field } from "./SubjectInput.vue";

export interface SubjectWithID {
  id: number;
  value: string;
}

export type SubjectsWithID = Array<SubjectWithID>;

const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    modelValue: Array<string>;
    fields: Array<Field>;
    dropdownsUpwards?: boolean;
    placeholder?: string;
    minInputs?: number;
    maxInputs?: number;
  }>(),
  {
    modelValue: () => [],
    fields: () => [],
    dropdownsUpwards: false,
    placeholder: "",
    minInputs: 2,
    maxInputs: 4,
  }
);

//Emits
const emit = defineEmits<{
  (e: "update:modelValue", value: Array<string>): void;
}>();

const mapToSubjectObjects = (subjects: Array<string>) =>
  subjects.map((subject, index) => ({
    id: index,
    value: subject,
  }));

const mapToSubjectsArray = (subjects: SubjectsWithID) => subjects.map((subject) => subject.value);

//State
const subjects = ref<SubjectsWithID>(mapToSubjectObjects(props.modelValue));
watchEffect(() => (subjects.value = mapToSubjectObjects(props.modelValue)));

//Methods
const addSubject = () => {
  subjects.value.push({
    id: subjects.value.length,
    value: "",
  });
  emit("update:modelValue", [...mapToSubjectsArray(subjects.value)]);
};
const updateSubject = (newValue: string, id: number) => {
  const subjectFound = subjects.value.find((subject) => subject.id == id);
  if (!subjectFound) return;

  subjectFound.value = newValue;
  emit("update:modelValue", mapToSubjectsArray(subjects.value));
};

const deleteSubject = (id: number) => {
  const subjectIndexFound = subjects.value.findIndex((subject) => subject.id == id);
  if (!subjectIndexFound) return;

  subjects.value.splice(subjectIndexFound, 1);
  emit("update:modelValue", mapToSubjectsArray(subjects.value));
};

//Watchers
watchEffect(() => {
  while (subjects.value.length < props.minInputs) {
    addSubject();
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "addIconButton": "Agregar asunto",
    "trashIconButton": "Eliminar asunto"
  },
  "pt": {
    "addIconButton": "Agregar asunto",
    "trashIconButton": "Eliminar asunto"
  }
}
</i18n>
