import script from "./QRTemplateTwo.vue?vue&type=script&lang=ts&setup=true"
export * from "./QRTemplateTwo.vue?vue&type=script&lang=ts&setup=true"

import "./QRTemplateTwo.vue?vue&type=style&index=0&id=91e61646&lang=css"
/* custom blocks */
import block0 from "./QRTemplateTwo.vue?vue&type=custom&index=0&blockType=i18n&lang=jsonc"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__