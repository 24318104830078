import { Ref } from "vue";
import { Requestor, useRequests } from "@api/requests";
import type { PlanState, PlanType } from "@domain/plan";

export interface IAccountPlan {
  state: PlanState;
  type: PlanType;
  subscription?: {
    price: {
      total: number;
    };
    periodEnds: string;
  };
  emails: {
    available: number;
    excessLimit: number;
    limit: number;
  };
  promocode?: string;
  contacts: {
    active: number;
    limit: number;
  };
  trialEnds?: string;
  trialExpired?: string;
}

export function useAccountPlan(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function get(loadingRef?: Ref<boolean>): Promise<IAccountPlan> {
    const res = await request({
      url: "/plan",
      method: "get",
      loadingRef,
    });

    return res.data as IAccountPlan;
  }

  return { get };
}

export function useAccountPlanDiscount(requestor?: Requestor) {
  const request = requestor || useRequests();

  async function applyDiscount(promocode: string, loadingRef?: Ref<boolean>): Promise<unknown> {
    const res = await request({
      url: `/plan/promocode/${promocode}/apply`,
      method: "post",
      loadingRef,
    });

    return res;
  }

  return { applyDiscount };
}
