import { EditView } from "@/core/magnet";
import EmailTemplatesModel from "@/modules/emailtemplates/model";
import EmailTemplatesModelApiv3 from "@/modules/emailtemplates/model.apiv3";
import RecurringTriggerModel from "@/modules/automations/recurringtriggermodel";
import { objHas } from "@/helpers/utils";
import { mountComponent } from "@/helpers/vue";
import app from "@/app";
import AutomationGiftCardCreation from "@organisms/Automations/AutomationGiftCardCreation";

import { useOnboardingStore } from "@store";

const moment = window.moment;

export default EditView.extend({
  module: "automations",
  templates: {
    initial: require("../templates/show.hbs")
  },
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=enable]": "enable",
      "click [data-action=disable]": "disable",
      "click [data-action=cancel]": "cancel",
      "click [data-action=cantActivateLifeCycle]": "cantActivateLifeCycle",
      "click [data-action=goto-content-tab]": "gotoContentTab",
      "click [data-action=edit-js]": "openEditJsModal",
      "submit [data-form=save-js]": "updateJs",

      "click [data-action=edit-params]": "openEditParamsModal",
      "submit [data-form=save-params]": "updateParams",

      "click [data-action=edit-json]": "openEditJsonModal",
      "submit [data-form=save-json]": "updateJson",

      "click [data-action=edit-delay]": "openEditDelayModal",
      "click [data-action=edit-trigger]": "openTriggerModal",
      "change [data-action=show-trigger-details]": "showTriggerDetails",
      "submit [data-form=save-trigger]": "updateTrigger",
      "submit [data-form=save-delay]": "updateDelay",
      "change [data-action=toggle-window-details]": "toggleWindowDetails",
      "change [data-action=toggle-exec-limit-details]": "toggleExecLimitDetails",
      "click [data-action=duplicate]": "duplicate",

      "blur input[data-action=edit-inline]": "inlineUpdateSave",
      "keyup input[data-action=edit-inline]": "inlineUpdateKeyPress",
      "click span[data-action=edit-inline]": "inlineUpdateStart",

      "click [data-action=toggle-params-adv-cfg]": "toggleParamsAdvancedConfig",

      "click [data-select-option]": "selectTriggerOption",

      "click [data-action=edit-whatsapp]": "openEditWhatsappModal",
      "submit [data-form=save-whatsapp]": "updateWhatsapp"
    });
  },

  enable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    if (this.enableRestricted /*&& !app.session.get('isMasterUser')*/) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.accountBlocked
        });
      } else if (
        this.item.additionalsData.plan.type !== "MONTHLY" &&
        !this.item.additionalsData.plan.type.includes("CHILD")
      ) {
        this.openRestrictedFeatureModal({
          title: window.lang.automations.enableAutomation,
          message: window.lang.automations.monthlyPlanRequired
        });
      }
      return false;
    }

    // Perform API call
    return model.enable({
      success: function (res) {
        self.displayMessage(window.lang.automations.automationEnabled);
        self.reload();

        const onboardingStore = useOnboardingStore();

        const eventPromises = res.data?.options?.types?.map((type) => {
          return onboardingStore.submitUserEvent({
            action: `automation.${type}.enabled`,
            data: {
              id: res.data.id,
              name: res.data.name
            }
          });
        });

        Promise.all([
          onboardingStore.submitUserEvent({
            action: "automation.enabled",
            data: {
              id: res.data.id,
              name: res.data.name
            }
          }),
          ...eventPromises
        ]);
      },
      error: function (err) {
        if (
          err.responseJSON &&
          err.responseJSON.error &&
          ["validation_error", "invalid_operation"].includes(err.responseJSON.error.type)
        ) {
          self.displayMessage(window.lang.automations.enableError, 8000, "error");
        } else {
          self.displayMessage(window.lang.automations.enableErrorUnknown, 8000, "error");
        }
        return false;
      }
    });
  },

  disable: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmDisable,
      button = null,
      titleText = window.lang.automations.disable,
      type = "info",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        // Perform API call
        return model.pause({
          success: function () {
            self.displayMessage(window.lang.automations.automationDisabled);
            self.reload();
          }
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  cancel: function (e) {
    var self = this,
      model = self.item;
    e.preventDefault();

    var confirmText = window.lang.automations.confirmCancel,
      button = null,
      titleText = window.lang.automations.cancel,
      type = "warning",
      flag = null;

    this.confirmModal(
      confirmText,
      button,
      titleText,
      type,
      flag,
      function () {
        return model.cancel({
          success: function () {
            self.displayMessage(window.lang.automations.automationCanceled);
          }
        });
      }.bind(this),
      function () {
        this.getModal().modal("hide");
      }.bind(this)
    );
  },

  cantActivateLifeCycle: function () {
    var self = this;
    return self.displayMessage(window.lang.automations.enableErrorLifeCycle, 8000, "error");
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    this.enableRestricted = false;
    if (objHas(this.item, "additionalsData", "plan", "state")) {
      if (this.item.additionalsData.plan.state.includes("BLOCKED")) {
        this.enableRestricted = true;
      }
    }

    if (
      app.getTrial() &&
      !app.getTrial().inTrial &&
      this.item.additionalsData.plan.type !== "MONTHLY" &&
      !this.item.additionalsData.plan.type.includes("CHILD") &&
      this.item.attributes.options &&
      this.item.attributes.options.restricted
    ) {
      this.enableRestricted = true;
    }

    this.loadEmailTemplates(
      function () {
        this.updateEmailStepsPreviews();
        callback();
      }.bind(this)
    );
  },

  // Returns the data to be rendered by the template function
  renderData: function () {
    return {
      data: this.item,
      enableRestricted: this.enableRestricted,
      showTemplatesTab: this.emailTemplates?.length && this.hasTemplatesApiV3,
      showContentsTab: this.emailTemplates?.length && !this.hasTemplatesApiV3,
      // emailStepsCount: this.emailTemplates?.length || 0,
      lang: window.lang,
      config: window.config,
      session: app.session
    };
  },

  // Load email templates used by the automation and calls callback when finished
  loadEmailTemplates: function (callback) {
    var templates = [];
    var steps = this.item.get("steps");
    var deferreds = [];

    for (var i = 0; i < steps.length; i++) {
      if (steps[i].type === "email") {
        const emailtpl = new EmailTemplatesModel({
          id: steps[i].data.template_id
        });
        templates.push(emailtpl);
        deferreds.push(emailtpl.fetch());
      } else if (steps[i].type === "msg_email") {
        this.hasTemplatesApiV3 = true;
        const emailtpl = new EmailTemplatesModelApiv3({
          id: steps[i].data.template_id
        });
        templates.push(emailtpl);
        deferreds.push(emailtpl.fetch());
      }
    }

    if (this.item.attributes.options.recurring_trigger_id) {
      var recurringDetails = new RecurringTriggerModel({
        id: this.item.attributes.options.recurring_trigger_id
      });
      this.item.recurringTrigger = recurringDetails;
      deferreds.push(recurringDetails.fetch());
    }

    this.emailTemplates = templates;

    // Executes the callback when all deferreds are done
    $.when.apply(null, deferreds).done(function () {
      callback();
    });
  },

  updateEmailStepsPreviews: function () {
    var steps = this.item.get("steps");

    for (var i = 0; i < steps.length; i++) {
      if (steps[i].type === "email" || steps[i].type === "msg_email") {
        steps[i].template = this.getEmailTemplateById(steps[i].data.template_id).attributes;
      }
    }
  },

  getEmailTemplateById: function (id) {
    var models = this.emailTemplates;
    for (var i = 0; i < models.length; i++) {
      if (models[i].get("id") === id) {
        return models[i];
      }
    }
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    if (
      this.item.attributes.triggerDetails &&
      this.item.attributes.triggerDetails.type === "recurring.birthday" &&
      !this.item.attributes.options.recurring_trigger_id
    ) {
      this.openTriggerModal();
    }

    if (this.item.attributes.triggerDetails?.type === "lifecycles.rfm") {
      this.item.attributes.triggerDetails.type = "lifecycles.rfm.highpotential.in";
      this.openTriggerModal();
    }
  },

  gotoContentTab: function (e) {
    var templateId = $(e.currentTarget).data("template");
    if (templateId.startsWith("etpl")) {
      app.router.navigateTo("#automations/" + this.item.get("id") + "/contents/" + templateId);
    } else {
      app.router.navigateTo("#automations/" + this.item.get("id") + "/templates/" + templateId);
    }
  },

  // Returns to the previous page
  back: function () {
    app.router.navigateTo("automations");
  },

  mountAutomationGiftCardCreation: function (params, defaultContext) {
    params.forEach((param) => {
      if (param.type === "gift-card") {
        const automationInputUrlValue = defaultContext.params.gc_landing_logo
          ? defaultContext.params.gc_landing_logo
          : "";
        const automationInputColorValue = defaultContext.params.gc_landing_color
          ? defaultContext.params.gc_landing_color
          : "";
        const automationInputBackgroundValue = defaultContext.params.gc_landing_bg
          ? defaultContext.params.gc_landing_bg
          : "";

        const AutomationGiftCardProps = {
          inputUrlValue: automationInputUrlValue,
          inputColorValue: automationInputColorValue,
          inputBackgroundValue: automationInputBackgroundValue
        };

        mountComponent(
          AutomationGiftCardCreation,
          AutomationGiftCardProps,
          "#vue-automation-gift-card-creation-params"
        );
      }
    });
  },

  openEditParamsModal: function (e) {
    var stepId = $(e.currentTarget).data("step");

    var params = this.item.getStepData(stepId).params;

    var defaultContext = this.item.attributes.default_context;

    var js = this.item.getStepData(stepId).js;

    const showAdvCfg = params.some((p) => p.advanced);

    const isTiendanube = js.includes("tiendanube");

    if (defaultContext.params) {
      for (var i = 0; i < params.length; i++) {
        if (defaultContext.params[params[i].key]) {
          params[i].value = defaultContext.params[params[i].key];
        }
      }
    }

    let isCouponIncludesShipping = false;
    let isCouponFirstConsumerPurchase = false;

    if (defaultContext.params) {
      isCouponIncludesShipping = Boolean(defaultContext.params.coupon_includes_shipping === "true");
      isCouponFirstConsumerPurchase = Boolean(defaultContext.params.coupon_first_consumer_purchase === "true");
    }

    const couponCfg = {
      isTiendanube: isTiendanube,
      isCouponIncludesShipping: isCouponIncludesShipping,
      isCouponFirstConsumerPurchase: isCouponFirstConsumerPurchase
    };

    this.openModal(
      require("../templates/modals/editParams.hbs"),
      $.extend({}, this.renderData(), { params: params, showAdvCfg, couponCfg })
    );

    this.mountAutomationGiftCardCreation(params, defaultContext);
  },

  updateParams: function (e) {
    e.preventDefault();
    var form = e.currentTarget,
      formData = $(form).serializeObject();

    this.item.updateDefaultContextParams(formData).done(
      function () {
        this.displaySavedMessage();
        this.getModal().modal("hide");
        this.reload();
      }.bind(this)
    );
  },

  openEditWhatsappModal: function (e) {
    var stepId = $(e.currentTarget).data("step");

    var template_id = this.item.getStepData(stepId).template_id;

    this.openModal(require("../templates/modals/editWhatsapp.hbs"), $.extend({}, this.renderData(), { template_id }));
  },

  updateWhatsapp: function (e) {
    e.preventDefault();

    this.getModal().modal("hide");

    // var form = e.currentTarget,
    //   formData = $(form).serializeObject();

    // this.item.updateDefaultContextParams(formData).done(
    //   function () {
    //     this.displaySavedMessage();
    //     this.getModal().modal("hide");
    //     this.reload();
    //   }.bind(this),
    // );
  },

  // Opens the modal to sends a drip test
  openEditJsModal: function (e) {
    e.stopPropagation();
    var stepId = $(e.currentTarget).data("step"),
      stepData = this.item.getStepData(stepId),
      comments = this.item.getStepComments(stepId),
      js = stepData.js,
      data = {
        comments: comments
      };

    if (stepData.on_false) {
      data.on_false = stepData.on_false;
    }

    if (stepData.template_id) {
      data.template_id = stepData.template_id;
    }

    this.openModal(require("../templates/modals/editJs.hbs"), $.extend({}, this.renderData(), data));

    this.editingStepId = stepId;
    this.jsEditor = window.ace.edit("code");
    this.jsEditor.setValue(js);
    this.jsEditor.session.setMode("ace/mode/javascript");
    this.jsEditor.setShowPrintMargin(false);
    //disable warnings (async/await issues)
    this.jsEditor.getSession().setUseWorker(false);
  },

  updateJs: function (e) {
    e.preventDefault();
    var form = e.currentTarget,
      formData = $(form).serializeObject();

    var stepData = {
      js: this.jsEditor.getValue()
    };

    if (formData.on_false) {
      stepData.on_false = formData.on_false;
    }

    if (formData.template_id) {
      stepData.template_id = formData.template_id;
    }

    this.item.updateStepData(this.editingStepId, stepData, formData.comments).done(
      function () {
        this.displaySavedMessage();
        this.getModal().modal("hide");
        this.reload();
      }.bind(this)
    );
  },

  // Opens the modal to sends a drip test
  openEditJsonModal: function () {
    this.openModal(require("../templates/modals/editJson.hbs"), {});

    this.jsEditor = window.ace.edit("codeJson");
    this.jsEditor.setValue(this.item.attributes.json);
    this.jsEditor.session.setMode("ace/mode/json");
    this.jsEditor.setShowPrintMargin(false);
    //disable warnings (async/await issues)
    this.jsEditor.getSession().setUseWorker(false);
  },

  updateJson: function (e) {
    e.preventDefault();

    var json = this.jsEditor.getValue();
    var auto;

    try {
      auto = JSON.parse(json);
    } catch (err) {
      alert("JSON inválido!");
      this.enableSubmitButton(e.currentTarget);
      return false;
    }

    try {
      for (var i = 0; i < auto.steps.length; i++) {
        if (i === 0) {
          if (auto.steps[i].id !== auto.first_step) throw "Revisar id primer paso";
        } else if (i === auto.steps.length - 1) {
          if (auto.steps[i].next_step !== "") throw "next_step del ultimo paso debe estar vacio";
        }

        if (i > 0) {
          if (auto.steps[i].id !== auto.steps[i - 1].next_step)
            throw "Revisar next_step[" + auto.steps[i - 1].next_step + "]->id[" + auto.steps[i].id + "]";
        }
      }
    } catch (err) {
      alert(err);
      this.enableSubmitButton(e.currentTarget);
      return false;
    }

    this.item
      .updateJson(
        json,
        function (jqXHR) {
          console.error(jqXHR.responseJSON);
          alert("Error! Ver detalle consola.");
          this.enableSubmitButton(e.currentTarget);
          return false;
        }.bind(this)
      )
      .done(
        function () {
          this.displaySavedMessage();
          this.getModal().modal("hide");
          this.reload();
        }.bind(this)
      );
  },

  toggleWindowDetails: function () {
    if ($("#enable-window").prop("checked")) {
      $("#window-details").show();
    } else {
      $("#window-details").hide();
    }
  },

  toggleExecLimitDetails: function () {
    if ($("#enable-exec-limit").prop("checked")) {
      $("#exec-limit-details").show();
    } else {
      $("#exec-limit-details").hide();
    }
  },

  // Opens the modal to sends a drip test
  openEditDelayModal: function (e) {
    var stepId = $(e.currentTarget).data("step");
    var stepData = this.item.getStepData(stepId);

    var data = {
      amount: stepData.delay.amount,
      unit: stepData.delay.unit,
      windowEnabled: stepData.window ? true : false
    };

    if (data.windowEnabled) {
      data.monday = stepData.window.days.includes("monday");
      data.tuesday = stepData.window.days.includes("tuesday");
      data.wednesday = stepData.window.days.includes("wednesday");
      data.thursday = stepData.window.days.includes("thursday");
      data.friday = stepData.window.days.includes("friday");
      data.saturday = stepData.window.days.includes("saturday");
      data.sunday = stepData.window.days.includes("sunday");
      data.from = stepData.window.periods[0].from;
      data.to = stepData.window.periods[0].to;
    } else {
      data.monday = true;
      data.tuesday = true;
      data.wednesday = true;
      data.thursday = true;
      data.friday = true;
      data.saturday = false;
      data.sunday = false;
      data.from = "09:00";
      data.to = "18:00";
    }

    this.openModal(require("../templates/modals/editDelay.hbs"), $.extend({}, this.renderData(), data));

    this.toggleWindowDetails();
    this.editingStepId = stepId;
  },

  updateDelay: function (e) {
    var form = e.currentTarget,
      formData = $(form).serializeObject();
    e.preventDefault();

    var stepData = {
      delay: {
        amount: parseInt(formData.amount),
        unit: formData.unit
      }
    };

    if (formData.windowEnabled) {
      var days = [];
      if (formData.monday) days.push("monday");
      if (formData.tuesday) days.push("tuesday");
      if (formData.wednesday) days.push("wednesday");
      if (formData.thursday) days.push("thursday");
      if (formData.friday) days.push("friday");
      if (formData.saturday) days.push("saturday");
      if (formData.sunday) days.push("sunday");
      stepData.window = {
        days: days,
        periods: [
          {
            from: formData.from,
            to: formData.to
          }
        ],
        timezone: moment().tz(window.config.timezone).format("Z")
      };
    }

    this.item.updateStepData(this.editingStepId, stepData).done(
      function () {
        this.displaySavedMessage();
        this.getModal().modal("hide");
        this.reload();
      }.bind(this)
    );
  },

  // Opens the modal to sends a drip test
  openTriggerModal: function () {
    //var stepId = $(e.currentTarget).data('step');
    //var stepData = this.item.getStepData(stepId);

    var availableTriggerTypes = [
      "contact.list.added",
      "contact.list.removed",
      "contact.interest.added",
      "contact.interest.expired",
      "contact.interest.removed",
      "optin.confirmed",
      "recurring.birthday"
    ];

    if (this.item.attributes.triggerDetails.rfm) {
      availableTriggerTypes = [
        "lifecycles.rfm.champion.in",
        "lifecycles.rfm.loyalist.in",
        "lifecycles.rfm.recent.in",
        "lifecycles.rfm.highpotential.in",
        "lifecycles.rfm.neednutring.in",
        "lifecycles.rfm.cantlose.in",
        "lifecycles.rfm.atrisk.in",
        "lifecycles.rfm.abouttolose.in",
        "lifecycles.rfm.champion.out",
        "lifecycles.rfm.loyalist.out",
        "lifecycles.rfm.recent.out",
        "lifecycles.rfm.highpotential.out",
        "lifecycles.rfm.neednutring.out",
        "lifecycles.rfm.cantlose.out",
        "lifecycles.rfm.atrisk.out",
        "lifecycles.rfm.abouttolose.out"
      ];
    }

    //var integrations = this.item.additionalsData.integrations.map(function (i) { return i.name });
    // if (integrations.includes('tiendanube')) {
    //     availableTriggerTypes.push('tiendanube.cart.created');
    //     availableTriggerTypes.push('tiendanube.newsletter.subscribed');
    //     availableTriggerTypes.push('tiendanube.order.completed');
    //     availableTriggerTypes.push('tiendanube.order.created');
    // }
    // if (integrations.includes('vtex')) {
    //     availableTriggerTypes.push('vtex.cart.abandoned');
    //     availableTriggerTypes.push('vtex.order.completed');
    //     availableTriggerTypes.push('vtex.user.created');
    //     availableTriggerTypes.push('vtex.newsletter.subscribed');
    // }
    // if (integrations.includes('woocommerce')) {
    //     availableTriggerTypes.push('woocommerce.order.completed');
    // }
    // if (integrations.includes('fbleadads')) {
    //     availableTriggerTypes.push('fbleadads.contact.subscribed');
    // }
    // if (integrations.includes('signos')) {
    //     availableTriggerTypes.push('signos.order.completed');
    //     availableTriggerTypes.push('signos.cart.created');
    // }

    var triggerDetails = this.item.get("triggerDetails");
    if (!triggerDetails.execLimit.amount) {
      triggerDetails.execLimit.amount = 7;
      triggerDetails.execLimit.unit = "DAYS";
    }

    let daysBefore = 0;
    if (this.item.recurringTrigger?.attributes?.options?.field_value?.includes("+")) {
      daysBefore = parseInt(this.item.recurringTrigger.attributes.options.field_value.split("+")[1]);
    }

    var data = {
      lists: this.item.additionalsData.lists,
      interests: this.item.additionalsData.interests,
      optins: this.item.additionalsData.optins,
      fields: this.item.additionalsData.fields,
      triggerDetails: triggerDetails,
      availableTriggerTypes: availableTriggerTypes,
      recurringTrigger: this.item.recurringTrigger,
      recurringTriggerDaysBefore: daysBefore
    };

    this.openModal(require("../templates/modals/editTrigger.hbs"), data);
    this.showTriggerDetails();
    this.toggleExecLimitDetails();
  },

  showTriggerDetails: function () {
    var selection = $("#selected-trigger").val();
    $("#list-trigger-details").hide();
    $("#interest-trigger-details").hide();
    $("#optin-trigger-details").hide();
    $("#recurring-trigger-details").hide();
    if (selection.includes("contact.list")) {
      $("#list-trigger-details").show();
    } else if (selection.includes("contact.interest")) {
      $("#interest-trigger-details").show();
    } else if (selection == "optin.confirmed") {
      $("#optin-trigger-details").show();
    } else if (selection == "recurring.birthday") {
      $("#recurring-trigger-details").show();
    }
    $("#edit-trigger-comments").html(window.lang.automations.triggersComments[selection]);
  },

  updateTrigger: function (e) {
    var form = e.currentTarget,
      formData = $(form).serializeObject();
    e.preventDefault();

    var param,
      trigger = formData.type,
      exec_limit;

    // exec_limit
    if (formData.execLimitEnabled && formData.execLimitAmount) {
      exec_limit = {
        count: 1
      };
      if (formData.execLimitUnit === "MINUTES") {
        exec_limit.every = formData.execLimitAmount + "m";
      } else if (formData.execLimitUnit === "HOURS") {
        exec_limit.every = formData.execLimitAmount + "h";
      } else if (formData.execLimitUnit === "DAYS") {
        exec_limit.every = formData.execLimitAmount + "d";
      }
    }

    if (formData.type.includes("recurring.")) {
      // recurring
      if (this.item.recurringTrigger) {
        // si es recurring y tengo recurringTrigger, actualizar trigger

        trigger = this.item.attributes.trigger;

        const recurringData = {
          id: this.item.recurringTrigger.attributes.id,
          options: {}
        };

        if (formData.recurringList) {
          recurringData.options.list_id = formData.recurringList;
        }
        if (formData.recurringField) {
          recurringData.options.field_id = formData.recurringField;
          recurringData.options.field_value = "now";
          if (formData.recurringTriggerDaysBefore && formData.recurringTriggerDaysBefore !== "0") {
            recurringData.options.field_value = "now+" + parseInt(formData.recurringTriggerDaysBefore) + "d";
          }
          recurringData.options.field_date_pattern = formData.recurringDatePattern;
        }

        const recurringTrigger = new RecurringTriggerModel(recurringData);
        recurringTrigger.save().done(
          function () {
            this.item.updateTrigger(trigger, exec_limit).done(
              function () {
                this.displaySavedMessage();
                this.getModal().modal("hide");
                this.reload();
              }.bind(this)
            );
          }.bind(this)
        );
      } else {
        // si es recurring y no tengo recurringTrigger, crear trigger
        trigger = "recurring.birthday." + Math.random().toString(36).substring(2, 8);

        const recurringData = {
          options: {},
          trigger_key: trigger,
          every: "1d",
          next_execution: moment().add(1, "days").set("hour", 7).set("minute", 0).set("second", 0).format()
        };

        if (formData.recurringList) {
          recurringData.options.list_id = formData.recurringList;
        }
        if (formData.recurringField) {
          recurringData.options.field_id = formData.recurringField;
          recurringData.options.field_value = "now";
          if (formData.recurringTriggerDaysBefore && formData.recurringTriggerDaysBefore !== "0") {
            recurringData.options.field_value = "now+" + parseInt(formData.recurringTriggerDaysBefore) + "d";
          }
          recurringData.options.field_date_pattern = formData.recurringDatePattern;
        }

        const recurringTrigger = new RecurringTriggerModel(recurringData);

        recurringTrigger.save().done(
          function (res) {
            // setear options.recurring_trigger_id
            this.item.attributes.options.recurring_trigger_id = res.data.id;

            this.item.updateTrigger(trigger, exec_limit).done(
              function () {
                this.displaySavedMessage();
                this.getModal().modal("hide");
                this.reload();
              }.bind(this)
            );
          }.bind(this)
        );
      }
    } else {
      // no es recurring

      // trigger key
      if (formData.type.includes("contact.list")) {
        param = formData.list;
      } else if (formData.type.includes("contact.interest")) {
        param = formData.interest;
      } else if (formData.type == "optin.confirmed") {
        param = formData.optin;
      }
      if (param) {
        trigger += "." + param;
      }

      if (formData.type === "custom") {
        trigger = "custom." + formData.customTriggerKey;
      }

      if (this.item.recurringTrigger) {
        // limpiar options.recurring_trigger_id
        delete this.item.attributes.options.recurring_trigger_id;

        // si no es recurring y tengo recurringTrigger, eliminar trigger
        this.item.recurringTrigger.destroy().done(
          function () {
            this.item.updateTrigger(trigger, exec_limit).done(
              function () {
                this.displaySavedMessage();
                this.getModal().modal("hide");
                this.reload();
              }.bind(this)
            );
          }.bind(this)
        );
      } else {
        this.item.updateTrigger(trigger, exec_limit).done(
          function () {
            this.displaySavedMessage();
            this.getModal().modal("hide");
            this.reload();
          }.bind(this)
        );
      }
    }
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },

  duplicate: function () {
    this.item.duplicate().done(function (response) {
      app.router.navigateTo(`automations/${response.data.id}`);
    });
  },

  displaySavedMessage: function () {
    this.displayMessage("Automation guardado");
  },

  toggleParamsAdvancedConfig: function () {
    $("#params-adv-cfg").toggleClass("hide");
  },

  selectTriggerOption: function (e) {
    const option = e.currentTarget.dataset.selectOption;
    document.querySelector("#selected-trigger").value = option;
    this.showTriggerDetails();
  }
});
