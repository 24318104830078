<template>
  <div class="w-full text-base">
    <div class="mx-auto flex flex-col">
      <TextParagraph>
        {{ t("qrViewerDescription") }}
      </TextParagraph>
      <div>
        <div class="mx-auto flex w-min space-x-8 pb-6">
          <div class="space-y-2">
            <FormGroup :id="uuid()" :label="t('fileFormat')">
              <ToggleButtons v-model="selectedOption" :buttons="downloadOptions" :icon-height="5" />
            </FormGroup>
          </div>
          <div class="space-y-2">
            <FormGroup :id="uuid()" :label="t('fileSize')">
              <ToggleButtons
                v-model="selectedLayout"
                :buttons="LayoutOptions"
                :disabled="selectedOption == '1'"
                :icon-height="5"
              />
            </FormGroup>
          </div>
          <Button v-if="qrDataImage" theme="secondary" class="my-auto h-9" @click="DownloadFile">
            <template #leading>
              <component :is="DownloadIcon" class="h-5 w-5" />
            </template>
            {{ t("downloadButton") }}
          </Button>
        </div>
        <div
          v-if="selectedOption == '0'"
          class="transparent-background-img relative mx-auto flex w-min rounded border border-gray-300 px-2"
        >
          <button class="my-auto h-12" @click="PreviousTemplate">
            <component
              :is="ChevronLeftIcon"
              class="h-12 w-12 rounded-full bg-sky-400 p-2 text-white hover:bg-sky-500"
            />
          </button>
          <component
            :is="templates[selectedTemplate]"
            :id="QRViewID"
            v-model:qrDataImage="qrDataImage"
            :title="title"
            :description="description"
            :tag="tag"
            :url="url"
            @update:title="UpdateTitle"
            @update:description="UpdateDescription"
            @update:tag="UpdateTag"
          />
          <button class="my-auto h-12" @click="NextTemplate">
            <component
              :is="ChevronRightIcon"
              class="h-12 w-12 rounded-full bg-sky-400 p-2 text-white hover:bg-sky-500"
            />
          </button>
        </div>
        <div v-else-if="localQRDataImage">
          <img :src="localQRDataImage" class="mx-auto mt-4 h-96 w-96" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, provide, ref } from "vue";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useI18n } from "vue-i18n";
import QRCode from "qrcode";
import { v4 as uuid } from "uuid";

//Components
import Button from "@atoms/SimpleButton.vue";
import ToggleButtons from "@/vue/components/atoms/ToggleButtons.vue";
import FormGroup from "@molecules/FormGroup.vue";
import TextParagraph from "@atoms/TextParagraph.vue";

//Icons
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon } from "@heroicons/vue/solid";

//Templates
import TemplateOne from "./templates/QRTemplateOne.vue";
import TemplateTwo from "./templates/QRTemplateTwo.vue";
import TemplateThree from "./templates/QRTemplateThree.vue";
import TemplateFour from "./templates/QRTemplateFour.vue";
import TemplateFive from "./templates/QRTemplateFive.vue";
import TemplateSix from "./templates/QRTemplateSix.vue";
import TemplateSeven from "./templates/QRTemplateSeven.vue";

//Utils
import { useNotifications } from "@composables/notifications";

const { notify } = useNotifications();
const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    url: string;
  }>(),
  {
    url: "",
  }
);

const UpdateTitle = (value: string) => (title.value = value);
const UpdateDescription = (value: string) => (description.value = value);
const UpdateTag = (value: string) => (tag.value = value);

//State
const title = ref(`${t("titleDefault")}`);
const description = ref(`${t("descriptionDefault")}`);
const tag = ref(`${t("tagDefault")}`);

//Const
const templates = [TemplateOne, TemplateTwo, TemplateThree, TemplateFour, TemplateFive, TemplateSix, TemplateSeven];

const LayoutOptions = [
  {
    id: "0",
    label: "A4 (1:1)",
  },
  {
    id: "1",
    label: "A5 (2:1)",
  },
];

const downloadOptions = [
  {
    id: "0",
    label: t("downloadFlyer"),
  },
  {
    id: "1",
    label: t("downloadQR"),
  },
];

//State
const selectedLayout = ref("0");
const selectedOption = ref("0");
const selectedTemplate = ref(0);
const QRViewID = "pdf-element-qr-view";
const qrDataImage = ref("");
const localQRDataImage = ref("");
const pdfName = "QRPerfit";
const qrName = "QRPerfit";

const pdfMode = ref(false);
provide("pdfMode", pdfMode);

//Functions
const generateQR = async (text) => {
  try {
    const QRImage = await QRCode.toDataURL(text, { errorCorrectionLevel: "H", scale: 12 });

    localQRDataImage.value = QRImage;
  } catch (err) {
    notify({
      title: t(`errors.server.title`),
      text: t(`errors.server.text`),
      theme: "error",
      timeout: 0,
    });
  }
};

onMounted(() => {
  generateQR(props.url);
});

//Methods
const handleA4Format = (pdf: jsPDF, qrViewData: string) => {
  const imgProps = pdf.getImageProperties(qrViewData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  let imgWidth = pdfWidth;
  let imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
  let yPosition = 20;
  let xPosition = 20;

  pdf.addImage({
    imageData: qrViewData,
    format: "JPEG",
    width: imgWidth - 40,
    height: imgHeight - 80,
    x: xPosition,
    y: yPosition,
  });

  // TODO: Ver con Die el nombre
  pdf.save(`${pdfName}.pdf`);
};
const handleA5Format = (pdf: jsPDF, qrViewData: string) => {
  // const imgProps = pdf.getImageProperties(qrViewData);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  let imgWidth = pdfHeight / 2 - 20;
  let imgHeight = pdfWidth - 20;
  let yPosition = -imgHeight - 55 + (pdfHeight - imgHeight) / 4;
  let xPosition = 10;

  pdf.addImage({
    imageData: qrViewData,
    format: "JPEG",
    width: imgWidth,
    height: imgHeight,
    x: xPosition,
    y: yPosition,
    rotation: -90,
  });

  pdf.addImage({
    imageData: qrViewData,
    format: "JPEG",
    width: imgWidth,
    height: imgHeight,
    x: xPosition,
    y: yPosition + imgHeight * (2 / 3) + 30,
    rotation: -90,
  });

  // TODO: Ver con Die el nombre
  pdf.save(`${pdfName}.pdf`);
};
const DownloadPDF = async () => {
  pdfMode.value = true;
  nextTick(async () => {
    let pdf = new jsPDF("p", "pt", "a4");
    const canvas = await html2canvas(document.getElementById(QRViewID)!, {
      scale: 6,
      windowWidth: 1920,
    });
    const qrViewData = canvas.toDataURL("image/jpeg");
    selectedLayout.value == "1" ? handleA5Format(pdf, qrViewData) : handleA4Format(pdf, qrViewData);
    pdfMode.value = false;
  });
};

const DownloadImage = () => {
  //Download image
  const link = document.createElement("a");

  link.href = localQRDataImage.value;
  // TODO: Ver con Die el nombre
  link.download = `${qrName}.png`;
  link.click();

  link.remove();
};

const DownloadFile = () => (selectedOption.value == "0" ? DownloadPDF() : DownloadImage());

const NextTemplate = () => {
  selectedTemplate.value += 1;
  if (selectedTemplate.value > templates.length - 1) {
    selectedTemplate.value = 0;
  }
};

const PreviousTemplate = () => {
  selectedTemplate.value -= 1;

  if (selectedTemplate.value < 0) {
    selectedTemplate.value = templates.length - 1;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "downloadFlyer": "Flyer para imprimir",
    "downloadQR": "Sólo código QR",
    "fileFormat": "Formato",
    "fileSize": "Tamaño",
    "downloadButton": "Descargar",
    "titleDefault": "¡Suscríbete para recibir las mejores ofertas y novedades de nuestra tienda!",
    "descriptionDefault": "Recibirás un beneficio exclusivo para tu próxima compra.",
    "tagDefault": "ESCANÉAME",
    "qrViewerDescription": "Elige un diseño listo para imprimir o descarga sólo el código QR. Utilízalo en tu tienda física para captar más suscriptores."
  },
  "pt": {
    "downloadFlyer": "Flyer para impressão",
    "downloadQR": "Somente QR",
    "fileFormat": "Formato",
    "fileSize": "Tamanho",
    "downloadButton": "Download",
    "titleDefault": "Inscreva-se para receber as melhores ofertas e novidades da nossa loja!",
    "descriptionDefault": "Receberá um benefício exclusivo para sua próxima compra.",
    "tagDefault": "ESCANEIE-ME",
    "qrViewerDescription": "Escolha um design pronto para imprimir ou baixe somente o código QR para usá-lo onde queira."
  }
}
</i18n>
