<script setup lang="ts">
import { PropType, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@composables/breakpoints";
import { DashboardData } from "@domain/dashboard/dashboard";

import AlertBox from "@atoms/AlertBox.vue";
import SectionHeader from "@atoms/SectionHeader.vue";
import MetricCard from "@molecules/MetricCard.vue";
import DashboardChartSales from "@organisms/Dashboard/DashboardChartSales.vue";
import DashboardChartSalesByType from "@organisms/Dashboard/DashboardChartSalesByType.vue";
import DashboardChartVisits from "@organisms/Dashboard/DashboardChartVisits.vue";

const props = defineProps({
  data: {
    type: Object as PropType<DashboardData>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showSessions: {
    type: Boolean,
    default: false,
  },
  showRoi: {
    type: Boolean,
    default: false,
  },
  showWarningIntegrations: {
    type: Boolean,
    required: false,
    default: false,
  },
  engagementByClicks: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const pdfMode = inject("pdfMode", false);

const { t, n, d } = useI18n();
const { smBp } = useBreakpoints();

//TODO: esto se repite entre dashboards, reutilizarlo
const sn = (value: number | undefined, format: string): string =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  n(isFinite(value!) && value ? value : 0, format);
const prevPeriod = computed(() =>
  props.data ? `${d(props.data.period.prevFrom, "short")} - ${d(props.data.period.prevTo, "short")}` : ""
);
</script>

<template>
  <div class="mx-auto mb-8 max-w-7xl">
    <AlertBox v-if="showWarningIntegrations" :data-html2canvas-ignore="pdfMode" theme="warning" class="mb-4">
      {{ t("integrationDisabled") }}
    </AlertBox>
    <AlertBox v-if="data?.store.currentDataPoints === 0" :data-html2canvas-ignore="pdfMode" theme="info" class="mb-4">
      {{ data?.period.isUntilLastDay() ? t("emptyPeriodNewAccount") : t("emptyPeriod") }}
    </AlertBox>
    <SectionHeader>{{ t("mainMetrics.header") }}</SectionHeader>
    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <MetricCard
        class="md:col-span-2 lg:col-span-1"
        :variation="data?.store.assisted.amountVar"
        :variation-tooltip="
          data?.store.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : !data?.store.assisted.previous.count
            ? t('noAssistedSalesLastPeriod')
            : t('mainMetrics.assisted.variationTooltip', {
                amount: sn(data?.store.assisted.previous.amount, 'currency'),
                period: prevPeriod,
              }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.store.isPreviousIncomplete"
        :info-tooltip="
          engagementByClicks ? t('mainMetrics.assisted.tooltip_clicks') : t('mainMetrics.assisted.tooltip_opens')
        "
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.assisted.title") }}</template>
        <template #value>{{ sn(data?.store.assisted.current.amount, "currency") }} </template>
        <template #description>
          <i18n-t keypath="mainMetrics.assisted.comment" :plural="data?.store.assisted.current.count || 0">
            <template #count>
              {{ sn(data?.store.assisted.current.count, "decimal") }}
            </template>
            <template #percent>
              {{ sn(data && data?.store.assisted.current.amount / data?.store.total.current.amount, "percent") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
      <MetricCard
        :variation="data?.store.assisted.conversionVar"
        :variation-tooltip="
          data?.store.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : !data?.store.assisted.previous.count
            ? t('noAssistedSalesLastPeriod')
            : t('mainMetrics.conversion.variationTooltip', {
                amount: sn(data?.store.assisted.previous.conversion, 'percent'),
                period: prevPeriod,
              }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.store.isPreviousIncomplete"
        :info-tooltip="
          engagementByClicks ? t('mainMetrics.conversion.tooltip_clicks') : t('mainMetrics.conversion.tooltip_opens')
        "
        :loading="loading"
      >
        <template #title>{{ t("mainMetrics.conversion.title") }}</template>
        <template #value>{{ sn(data?.store.assisted.current.conversion, "percent") }}</template>
        <template #description>
          <i18n-t keypath="mainMetrics.conversion.comment" :plural="data?.store.assisted.current.count || 0">
            <template #conversions>
              {{ sn((data && data?.store.assisted.current.conversion * 1000) || 0, "decimal") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
      <MetricCard
        :variation="data?.store.assisted.roiVar"
        :variation-tooltip="
          !showRoi
            ? ''
            : data?.store.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : !data?.store.assisted.previous.count
            ? t('noAssistedSalesLastPeriod')
            : t('mainMetrics.roi.variationTooltip', {
                amount:
                  sn(
                    data?.store.assisted.previous.roi,
                    data?.store.assisted.previous.roi < 20 ? 'decimal1FD' : 'decimal0FD'
                  ) + 'x',
                period: prevPeriod,
              }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.store.isPreviousIncomplete"
        :info-tooltip="
          showRoi
            ? t('mainMetrics.roi.tooltip', {
                cost: sn(data?.store.assisted.costOfPeriod, 'currency'),
              })
            : undefined
        "
        :loading="loading"
        :grayed="!showRoi"
      >
        <template #title>{{ t("mainMetrics.roi.title") }}</template>
        <template #value>
          {{
            showRoi && data?.store.assisted.current.roi
              ? sn(
                  data?.store.assisted.current.roi,
                  data?.store.assisted.current.roi < 20 ? "decimal1FD" : "decimal0FD"
                )
              : 0
          }}x
        </template>
        <template #description>
          <i18n-t v-if="showRoi" keypath="mainMetrics.roi.comment" :plural="data?.store.assisted.current.count || 0">
            <template #unit>
              {{ sn(1, "currency") }}
            </template>
            <template #income>
              {{ sn(data && data?.store.assisted.current.roi + 1, "currency") }}
            </template>
          </i18n-t>
          <span v-else>
            {{ t("mainMetrics.roi.commentUnavailable") }}
          </span>
        </template>
      </MetricCard>
    </div>
    <DashboardChartSales v-if="smBp" class="mt-4" :data="data" :loading="loading" />
    <SectionHeader class="mt-8">{{ t("salesByType.header") }}</SectionHeader>
    <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <MetricCard
        class="lg:row-start-1"
        :variation="data?.store.assistedBulk.amountVar"
        :variation-tooltip="
          data?.store.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('salesByType.bulk.variationTooltip', {
                amount: sn(data?.store.assistedBulk.previous.amount, 'currency'),
                period: prevPeriod,
              }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.store.isPreviousIncomplete"
        :info-tooltip="engagementByClicks ? t('salesByType.bulk.tooltip_clicks') : t('salesByType.bulk.tooltip_opens')"
        :loading="loading"
      >
        <template #title> {{ t("salesByType.bulk.title") }} </template>
        <template #value>
          {{ sn(data?.store.assistedBulk.current.amount, "currency") }}
        </template>
        <template #description>
          <i18n-t keypath="salesByType.bulk.comment" :plural="data?.store.assistedBulk.current.count || 0">
            <template #count>
              {{ sn(data?.store.assistedBulk.current.count, "decimal") }}
            </template>
            <template #percent>
              {{ sn(data && data?.store.assistedBulk.current.amount / data?.store.total.current.amount, "percent") }}
            </template>
            <template #conversion>
              {{ sn(data?.store.assistedBulk.current.conversion, "percent") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
      <MetricCard
        class="lg:row-start-2"
        :variation="data?.store.assistedAutomation.amountVar"
        :variation-tooltip="
          data?.store.isPreviousEmpty
            ? t('emptyPreviousPeriod')
            : t('salesByType.automation.variationTooltip', {
                amount: sn(data?.store.assistedAutomation.previous.amount, 'currency'),
                period: prevPeriod,
              }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
        "
        :variation-inaccurate="data?.store.isPreviousIncomplete"
        :info-tooltip="
          engagementByClicks ? t('salesByType.automation.tooltip_clicks') : t('salesByType.automation.tooltip_opens')
        "
        :loading="loading"
      >
        <template #title> {{ t("salesByType.automation.title") }} </template>
        <template #value>
          {{ n(data?.store.assistedAutomation.current.amount || 0, "currency") }}
        </template>
        <template #description>
          <i18n-t keypath="salesByType.automation.comment" :plural="data?.store.assistedAutomation.current.count || 0">
            <template #count>
              {{ n(data?.store.assistedAutomation.current.count || 0, "decimal") }}
            </template>
            <template #percent>
              {{
                n(
                  (data && data?.store.assistedAutomation.current.amount / data?.store.total.current.amount) || 0,
                  "percent"
                )
              }}
            </template>
            <template #conversion>
              {{ sn(data?.store.assistedAutomation.current.conversion, "percent") }}
            </template>
          </i18n-t>
        </template>
      </MetricCard>
      <DashboardChartSalesByType v-if="smBp" class="md:col-span-2 lg:row-span-2" :data="data" :loading="loading" />
    </div>
    <template v-if="showSessions">
      <SectionHeader class="mt-8"> {{ t("sessions.header") }} </SectionHeader>
      <div class="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <MetricCard
          class="lg:row-start-1"
          :variation="data?.store.sessions.totalVar"
          :variation-tooltip="
            data?.store.isPreviousEmpty
              ? t('emptyPreviousPeriod')
              : t('sessions.total.variationTooltip', {
                  amount: sn(data?.store.sessions.previous.total, 'decimal'),
                  period: prevPeriod,
                }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
          "
          :variation-inaccurate="data?.store.isPreviousIncomplete"
          :info-tooltip="t('sessions.total.tooltip')"
          :loading="loading"
        >
          <template #title> {{ t("sessions.total.title") }} </template>
          <template #value>{{ sn(data?.store.sessions.current.total, "decimal") }} </template>
          <template #description>
            <i18n-t keypath="sessions.total.comment" :plural="data?.store.sessions.current.total || 0">
              <template #count>
                {{ sn(data?.store.sessions.current.vistsPerVisitor, "decimal") }}
              </template>
            </i18n-t>
          </template>
        </MetricCard>
        <MetricCard
          class="lg:row-start-2"
          :variation="data?.store.sessions.identifiedVar"
          :variation-tooltip="
            data?.store.isPreviousEmpty
              ? t('emptyPreviousPeriod')
              : t('sessions.identified.variationTooltip', {
                  amount: sn(data?.store.sessions.previous.identified, 'decimal'),
                  period: prevPeriod,
                }) + (data?.store.isPreviousIncomplete ? t('partialPreviousPeriod') : '')
          "
          :variation-inaccurate="data?.store.isPreviousIncomplete"
          :info-tooltip="t('sessions.identified.tooltip')"
          :loading="loading"
        >
          <template #title>{{ t("sessions.identified.title") }}</template>
          <template #value>{{ sn(data?.store.sessions.current.identified, "decimal") }} </template>
          <template #description>
            <i18n-t keypath="sessions.identified.comment" :plural="data?.store.sessions.current.identified || 0">
              <template #ratio>
                {{ sn(data?.store.sessions.current.identifiedVisitsRatio, "percent") }}
              </template>
            </i18n-t>
          </template>
        </MetricCard>
        <DashboardChartVisits v-if="smBp" class="md:col-span-2 lg:row-span-2" :data="data" :loading="loading" />
      </div>
    </template>
    <!-- <SectionHeader class="mt-8"> {{ t("resultsByBatch.header") }} </SectionHeader> -->
  </div>
</template>

<i18n lang="jsonc">
{
  "es": {
    "emptyPeriod": "No contamos con datos sobre la actividad de tu tienda en el período indicado. Intenta seleccionado un rango mas reciente.",
    "emptyPeriodNewAccount": "Aún no contamos con información sobre la actividad de tu tienda. En cuanto comencemos a recibir datos y realices envíos de campañas y automations, aquí verás como estos ayudan a incrementar tus ventas y cuán efectiva es tu inversión.",
    "integrationDisabled": "Parece que la conexión con tu tienda se encuentra desactivada. Esto hace que no se inicien nuevos automations, ni se sincronicen los contactos y productos. Por favor, verifica el estado de la integración e intenta volver a activarla.",
    "emptyPreviousPeriod": "No se dispone de datos sobre el período anterior.",
    "partialPreviousPeriod": "<br/><br/>*No se dispone de datos completos sobre el período anterior. Es posible que la comparación no sea precisa.",
    "noAssistedSalesLastPeriod": "No se registraron ventas asistidas en el período anterior.",
    "tryUpdatingFilters": "Intenta modificando los filtros.",
    "mainMetrics": {
      "header": "Métricas principales de tu tienda",
      "noAssistedSales": "No se registró ninguna venta asistida en este período. @:tryUpdatingFilters",
      "assisted": {
        "title": "Ventas asistidas",
        "tooltip_opens": "Ingresos generados con la ayuda de las acciones realizadas con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores a la apertura de un email.",
        "tooltip_clicks": "Ingresos generados con la ayuda de las acciones realizadas con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores al click de un email.",
        "comment": "@:mainMetrics.noAssistedSales | Perfit ayudó a completar 1 venta, que representan el {percent} del monto total de ingresos. | Perfit ayudó a completar {count} ventas, que representan el {percent} del monto total de ingresos.",
        "variationTooltip": "En comparación con los {amount} generados por ventas asistidas en el período anterior ({period})."
      },
      "roi": {
        "title": "Retorno de la inversión (ROI)",
        "tooltip": "Beneficio generado por las ventas asistidas, en relación a la inversión realizada en el período seleccionado.<br/><br/>Se calcula como:<br/><pre>(ventas asistidas – costo) / costo</pre><br/>Un ROI mayor a 0 implica que la inversión es rentable.<br/><br/>El costo estimado del período es {cost}",
        "tooltipUnavailable": "Beneficio generado por las ventas asistidas, en relación a la inversión realizada en el período seleccionado.<br/><br/>Se calcula como:<br/><pre>(ventas asistidas – costo) / costo</pre>",
        "commentOneOrMany": "Cada {unit} invertido, Perfit ayudó a generar {income} en ventas asistidas.",
        "comment": "@:mainMetrics.noAssistedSales | @:mainMetrics.roi.commentOneOrMany | @:mainMetrics.roi.commentOneOrMany",
        "commentUnavailable": "El cálculo del ROI sólo está disponible en las suscripciones mensuales.",
        "variationTooltip": "En comparación con el ROI de {amount} obtenido en el período anterior ({period})."
      },
      "conversion": {
        "title": "Tasa de conversión",
        "tooltip_opens": "Índice de efectividad de tus acciones en relación con las aperturas.<br/><br/>Contabilizamos una conversión cuando un contacto realiza una compra dentro de los 7 días posteriores a la apertura de un email.",
        "tooltip_clicks": "Índice de efectividad de tus acciones en relación con los clicks.<br/><br/>Contabilizamos una conversión cuando un contacto realiza una compra dentro de los 7 días posteriores al click de un email.",
        "commentOneOrMany": "Por cada 1000 emails abiertos, Perfit ayudó a completar {conversions} ventas.",
        "comment": "@:mainMetrics.noAssistedSales | @:mainMetrics.conversion.commentOneOrMany | @:mainMetrics.conversion.commentOneOrMany",
        "variationTooltip": "En comparación con la tasa de conversión de {amount} obtenida en el período anterior ({period})."
      }
    },
    "salesByType": {
      "header": "Ingresos por tipo de envío",
      "bulk": {
        "title": "Campañas",
        "tooltip_opens": "Ingresos generados gracias a los anuncios masivos enviados con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores a la apertura de un email.",
        "tooltip_clicks": "Ingresos generados gracias a los anuncios masivos enviados con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores al click de un email.",
        "commentOne": "Las campañas ayudaron a completar 1 venta, que representa el {percent} del monto total, logrando una tasa de conversión del {conversion}.",
        "commentMany": "Las campañas ayudaron a completar {count} ventas, que representan el {percent} del monto total, logrando una tasa de conversión del {conversion}.",
        "commentNone": "Las campañas no generaron ninguna venta asistida en este período. @:tryUpdatingFilters",
        "comment": "@:salesByType.bulk.commentNone | @:salesByType.bulk.commentOne | @:salesByType.bulk.commentMany",
        "variationTooltip": "En comparación con los {amount} generados por ventas asistidas por campañas en el período anterior ({period})."
      },
      "automation": {
        "title": "Automations",
        "tooltip_opens": "Ingresos generados gracias a los automations enviados con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores a la apertura de un email.",
        "tooltip_clicks": "Ingresos generados gracias a los automations enviados con Perfit.<br/><br/>Contabilizamos una venta asistida cuando un contacto realiza una compra dentro de los 7 días posteriores al click de un email.",
        "commentOne": "Los automations ayudaron a completar 1 venta, que representa el {percent} del monto total, logrando una tasa de conversión del {conversion}.",
        "commentMany": "Los automations ayudaron a completar {count} ventas, que representan el {percent} del monto total, logrando una tasa de conversión del {conversion}.",
        "commentNone": "Los automations no generaron ninguna venta asistida en este período. @:tryUpdatingFilters",
        "comment": "@:salesByType.automation.commentNone | @:salesByType.automation.commentOne | @:salesByType.automation.commentMany",
        "variationTooltip": "En comparación con los {amount} generados por ventas asistidas por automations en el período anterior ({period})."
      }
    },
    "sessions": {
      "header": "Visitas a tu tienda",
      "total": {
        "title": "Sesiones totales",
        "tooltip": "El total de visitas que fueron detectadas por Perfit, incluyendo tanto a las identificadas, como a las anónimas.",
        "commentNone": "Tu tienda no recibió visitas en este período. @:tryUpdatingFilters",
        "commentOneOrMany": "Tu tienda recibió en promedio {count} visitas por cada usuario único.",
        "comment": "@:sessions.total.commentNone | @:sessions.total.commentOneOrMany | @:sessions.total.commentOneOrMany",
        "variationTooltip": "En comparación con las {amount} visitas totales registradas en el período anterior ({period})."
      },
      "identified": {
        "title": "Sesiones identificadas",
        "tooltip": "Visitas que pudieron ser relacionadas con un usuario de tu tienda o contacto de Perfit.<br/><br/>Sólo podemos enviar mensajes de remarketing y carritos abandonados a las visitas identificadas.",
        "commentNone": "Tu tienda no recibió visitas identificadas en este período. @:tryUpdatingFilters",
        "commentOneOrMany": "{ratio} del total de las visitas pudieron ser identificadas.",
        "comment": "@:sessions.identified.commentNone | @:sessions.identified.commentOneOrMany | @:sessions.identified.commentOneOrMany",
        "variationTooltip": "En comparación con las {amount} visitas identificadas registradas en el período anterior ({period})."
      }
    },
    "resultsByBatch": {
      "header": "Resultados por campaña"
    }
  },
  "pt": {
    "emptyPeriod": "Não contamos com dados sobre a atividade da sua loja no período indicado. Tente selecionar um período mais recente.",
    "emptyPeriodNewAccount": "Ainda não contamos com informação sobre a atividade da sua loja. Quando você realizar envios de campanhas e automations, começaremos a receber dados e aqui você verá como eles ajudam a incrementar suas vendas e quão efetiva é a sua inversão.",
    "integrationDisabled": "Parece que a conexão com a sua loja se encontra desativada. Isso faz com que não se iniciem novos automations, nem sincronizem os contatos e produtos. Por favor, verifique o estado da integração e tente ativá-la novamente.",
    "emptyPreviousPeriod": "Não há dados disponíveis do período anterior.",
    "partialPreviousPeriod": "<br/><br/>*Não temos dados completos sobre o período anterior. É possível que a comparação não seja precisa.",
    "noAssistedSalesLastPeriod": "Não foram registradas vendas assistidas no período anterior.",
    "tryUpdatingFilters": "Tente modificar os filtros.",
    "mainMetrics": {
      "header": "Métricas principais da sua loja",
      "noAssistedSales": "Não foi registrada nenhuma venda assistida nesse período. @:tryUpdatingFilters",
      "assisted": {
        "title": "Vendas assistidas",
        "tooltip_opens": "Ingressos gerados com ajuda das ações realizadas com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra no prazo de 7 dias após a abertura de um email.",
        "tooltip_clicks": "Ingressos gerados com ajuda das ações realizadas com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra no prazo de 7 dias após clicar em um e-mail.",
        "comment": "@:mainMetrics.noAssistedSales | A Perfit ajudou a completar 1 venda, que representa {percent} do valor total de ingressos. | A Perfit ajudou a completar {count} vendas, que representam {percent} do valor total de ingressos.",
        "variationTooltip": "Em comparação com {amount} gerados por vendas assistidas no período anterior ({period})."
      },
      "roi": {
        "title": "Retorno sobre o Investimento (ROI)",
        "tooltip": "Benefício gerado por vendas assistidas, em relação ao investimento do período selecionado.<br/><br/>Calculamos assim:<br/><pre>(vendas asistidas – custo) / custo</pre><br/>Um ROI maior que 0 quer dizer que a inversão é rentável.<br/><br/>O custo estimado do período é {cost}",
        "tooltipUnavailable": "Benefício gerado por vendas assistidas, em relação ao investimento do período selecionado.<br/><br/>Calculamos assim:<br/><pre>(vendas assistidas - custo) / custo</pre>",
        "commentOneOrMany": "Cada {unit} investido, a Perfit ajudou a gerar {income} em vendas assistidas.",
        "comment": "@:mainMetrics.noAssistedSales | @:mainMetrics.roi.commentOneOrMany | @:mainMetrics.roi.commentOneOrMany",
        "commentUnavailable": "O cálculo do ROI está disponível apenas para planos mensais.",
        "variationTooltip": "Em comparação com o ROI de {amount} obtido no período anterior ({period})."
      },
      "conversion": {
        "title": "Taxa de conversão",
        "tooltip_opens": "Índice de efetividade de suas ações em relação às aberturas.<br/><br/>Contabilizamos uma conversão quando um contato faz uma compra no prazo de 7 dias após a abertura de um email.",
        "tooltip_clicks": "Índice de efetividade de suas ações em relação às aberturas.<br/><br/>Contabilizamos uma conversão quando um contato faz uma compra no prazo de 7 dias após clicar em um e-mail.",
        "commentOneOrMany": "Por cada 1000 emails abertos, a Perfit ajudou a completar {conversions} vendas.",
        "comment": "@:mainMetrics.noAssistedSales | @:mainMetrics.conversion.commentOneOrMany | @:mainMetrics.conversion.commentOneOrMany",
        "variationTooltip": "Em comparação com a taxa de conversão de {amount} obtida no período anterior ({period})."
      }
    },
    "salesByType": {
      "header": "Ingressos por tipo de envio",
      "bulk": {
        "title": "Campanhas",
        "tooltip_opens": "Ingressos gerados graças aos anúncios enviados com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra no prazo de 7 dias após a abertura de um email.",
        "tooltip_clicks": "Ingressos gerados graças aos anúncios enviados com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra no prazo de 7 dias após clicar em um e-mail.",
        "commentOne": "As campanhas ajudaram a completar 1 venda, que representa {percent} do valor total, obtendo uma taxa de conversão de {conversion}.",
        "commentMany": "As campanhas ajudaram a completar {count} vendas, que representam {percent} do valor total, obtendo uma taxa de conversão de {conversion}.",
        "commentNone": "As campanhas não geraram nenhuma venda assistida nesse período. @:tryUpdatingFilters",
        "comment": "@:salesByType.bulk.commentNone | @:salesByType.bulk.commentOne | @:salesByType.bulk.commentMany",
        "variationTooltip": "Em comparação com os {amount} gerados por vendas assistidas no período anterior ({period})."
      },
      "automation": {
        "title": "Automations",
        "tooltip_opens": "Ingressos gerados graças aos automations enviados com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra dentro de 7 dias após a abertura de um email.",
        "tooltip_clicks": "Ingressos gerados graças aos automations enviados com Perfit.<br/><br/>Contabilizamos uma venda assistida quando um contato faz uma compra dentro de 7 dias após clicar em um e-mail.",
        "commentOne": "Os automations ajudaram a completar 1 venda, que representa {percent} do valor total, obtendo uma taxa de conversão de {conversion}.",
        "commentMany": "Os automations ajudaram a completar {count} vendas, que representam {percent} do valor total, obtendo uma taxa de conversão de {conversion}.",
        "commentNone": "Os automations não geraram nenhuma venda assistida nesse período. @:tryUpdatingFilters",
        "comment": "@:salesByType.automation.commentNone | @:salesByType.automation.commentOne | @:salesByType.automation.commentMany",
        "variationTooltip": "Em comparação com os {amount} gerados por vendas assistidas por automations no período anterior ({period})."
      }
    },
    "sessions": {
      "header": "Visitas à sua loja",
      "total": {
        "title": "Sessões totais",
        "tooltip": "Número total de visitas detectadas por Perfit, incluindo as identificadas e as anônimas. Essa é a média {contagem} de visitas que sua loja recebeu por cada usuário.",
        "commentNone": "Sua loja não recebeu visitas nesse período. @:tryUpdatingFilters",
        "commentOneOrMany": "Sua loja recebeu em média {count} visitas por cada usuário.",
        "comment": "@:sessions.total.commentNone | @:sessions.total.commentOneOrMany | @:sessions.total.commentOneOrMany",
        "variationTooltip": "Em comparação com as {amount} visitas totais registradas no período anterior ({period})."
      },
      "identified": {
        "title": "Sessões identificadas",
        "tooltip": "Visitas que podem estar relacionadas a um usuário de sua loja ou contato de Perfit.<br/><br/>Nós só podemos enviar mensagens de remarketing e carrinhos abandonados para visitas identificadas.",
        "commentNone": "Sua loja recebeu visitas identificadas nesse período. @:tryUpdatingFilters",
        "commentOneOrMany": "{ratio} do total de visitas puderam ser identificadas. ",
        "comment": "@:sessions.identified.commentNone | @:sessions.identified.commentOneOrMany | @:sessions.identified.commentOneOrMany",
        "variationTooltip": "Em comparação com as {amount} visitas identificadas registradas no período anterior ({period})."
      }
    },
    "resultsByBatch": {
      "header": "Resultados por campanha"
    }
  }
}
</i18n>
