//Types
import type { UnlayerValidators } from "@domain/unlayer";
import type { ApplyAuditValidationsModule, AuditModule } from "../unlayer.types";
import type { AuditResults } from "@domain/Templates";
import { useTemplateAuditApp } from "@application";

export const audit: AuditModule = async ({ unlayerInstance }) => {
  return new Promise((resolve, reject) => {
    try {
      unlayerInstance.audit((data: AuditResults) => {
        resolve(data);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const applyAuditValidations: ApplyAuditValidationsModule = ({
  editorId,
  unlayerInstance,
  template,
  auditMode
}) => {
  console.log("unlayerAudit.applyAuditValidations");
  // const unlayerIframe = document.querySelector(`${editorId} > iframe`) as HTMLIFrameElement;
  // if (!unlayerIframe) return;

  const clonedTemplated = template ? JSON.parse(JSON.stringify(template)) : {};

  // Esto es por el cambio de contexto de las funciones de auditoria de unlayer (Ejecución en window principal en vez de iframe)
  window.unlayerPerfitKit = {
    ...window.unlayerPerfitKit,
    templateData: {
      ...(window?.unlayerPerfitKit?.templateData ? window?.unlayerPerfitKit?.templateData : []),
      template: clonedTemplated,
      auditMode: auditMode
    }
  };

  unlayerInstance.audit((data: AuditResults) => {
    console.log("unlayerAudit.applyAuditValidations: audit finished", data);
  });
  // Fallback por si unlayer cambia de nuevo
  // unlayerIframe.contentWindow?.postMessage(
  //   {
  //     action: "setTemplate",
  //     template: clonedTemplated,
  //     auditMode: auditMode,
  //   },
  //   "*",
  // );
};

export const setupTemplateValidator = ({ unlayerInstance }) => {
  console.log("unlayerAudit.setupTemplateValidator");

  let setValidatorCallNumber = 1;
  unlayerInstance.setValidator((props) => {
    const { html, design, defaultErrors } = props;

    console.log("TemplateValidator call: ", setValidatorCallNumber++);
    if (!window.unlayerPerfitKit) return defaultErrors;

    const setFirst = performance.now();
    let errors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    // const footerContrastErrors = audit.footerContrast();
    // errors = errors.concat(footerContrastErrors);

    const gmailSizeLimitErrors = audit.gmailSizeLimit({ html });
    errors = errors.concat(gmailSizeLimitErrors);

    const senderSelectedErrors = audit.senderSelected();
    errors = errors.concat(senderSelectedErrors);

    const footerConditionErrors = audit.footerDisplayCondition({ design });
    errors = errors.concat(footerConditionErrors);

    if (window?.unlayerPerfitKit.templateData.auditMode !== "transactional") {
      const unsubscribeLinkErrors = audit.unsubscribeLink({ html });
      errors = errors.concat(unsubscribeLinkErrors);
    }

    if (window?.unlayerPerfitKit.templateData.template?.relation_type === "campaign_testab") {
      const minSubjectsTestABErrors = audit.minSubjectsTestAB();
      errors = errors.concat(minSubjectsTestABErrors);
    } else {
      const minSubjectsErrors = audit.minSubjects();
      errors = errors.concat(minSubjectsErrors);
    }
    const setSecond = performance.now();
    console.log("TemplateValidator time: ", setSecond - setFirst);

    return errors.concat(defaultErrors);
  });
};

export const setupToolValidators = ({ unlayerInstance }) => {
  console.log("unlayerAudit.setupToolValidators");

  unlayerInstance.setToolValidator("custom#fileButton", function (data) {
    //console.log("ToolValidator: custom#fileButton");
    const { defaultErrors, values } = data as {
      defaultErrors: UnlayerValidators;
      values: {
        link: {
          target: string;
          url: UrlString;
        };
      };
    };
    const errors: UnlayerValidators = [];
    if (!values.link || !values.link.url || values.link.url?.length === 0) {
      errors.push({
        id: "FILE_BUTTON_EMPTY_LINK",
        icon: "fa-rectangle-wide",
        severity: "ERROR",
        title: "filebutton_empty_link_title",
        description: "filebutton_empty_link_description"
      });
    }
    return errors.concat(defaultErrors);
  });

  unlayerInstance.setToolValidator("button", function (data) {
    //console.log("ToolValidator: button");
    const { defaultErrors, values } = data as {
      defaultErrors: UnlayerValidators;
      values: {
        href: {
          values: {
            href: string;
          };
        };
      };
    };
    let errors: UnlayerValidators = [];
    let newDefaultErrors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    // Override errors severity
    const errorsIds = ["BUTTON_EMPTY_LINKS"];

    const isOldButton = typeof values.href === "string";
    if (isOldButton && (!values.href || (values.href as unknown as string).length > 0)) {
      errors.push({
        id: "BUTTON_EMPTY_LINKS",
        severity: "ERROR",
        icon: "fa-rectangle-wide",
        title: "tabs.audit.rules.button.empty_links.title",
        description: "tabs.audit.rules.button.empty_links.description",
        tool: {
          type: "button"
        }
      });
    }

    newDefaultErrors = defaultErrors.map((error) => {
      if (errorsIds.includes(error.id)) {
        error.severity = "ERROR";
      }
      return error;
    });

    // if (isOldButton && (values.href || (values.href as unknown as string).length > 0)) {
    //   const urlValue = values.href as unknown as string;
    //   const urlLinkErrors = audit.isValidUrl({ url: urlValue });
    //   errors = errors.concat(urlLinkErrors);
    // }

    // if (!isOldButton && values.href?.values?.href.length > 0) {
    //   const urlLinkErrors = audit.isValidUrl({ url: values?.href?.values?.href });
    //   errors = errors.concat(urlLinkErrors);
    // }

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    return errors.concat(newDefaultErrors);
  });

  unlayerInstance.setToolValidator("social", function (data) {
    //console.log("ToolValidator: social");
    const { defaultErrors, values } = data as {
      defaultErrors;
      values: {
        icons: {
          icons: Array<{
            name: string;
            url: string;
          }>;
        };
      };
    };
    let errors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    //Default URls
    const defaultIconsData = [
      {
        name: "Facebook",
        url: "https://facebook.com/"
      },
      {
        name: "Twitter",
        url: "https://twitter.com/"
      },
      {
        name: "LinkedIn",
        url: "https://linkedin.com/"
      },
      {
        name: "Instagram",
        url: "https://instagram.com/"
      },
      {
        name: "Pinterest",
        url: "https://pinterest.com/"
      },
      {
        name: "Tumblr",
        url: "https://tumblr.com/"
      },
      {
        name: "Email",
        url: "https://email.com/"
      },
      {
        name: "Spotify",
        url: "https://spotify.com/"
      },
      {
        name: "YouTube",
        url: "https://youtube.com/"
      },
      {
        name: "Vimeo",
        url: "https://vimeo.com/"
      },
      {
        name: "SoundCloud",
        url: "https://soundcloud.com/"
      },
      {
        name: "RSS",
        url: "https://rss.com/"
      },
      {
        name: "Meetup",
        url: "https://meetup.com/"
      },
      {
        name: "Messenger",
        url: "https://messenger.com/"
      },
      {
        name: "Tripadvisor",
        url: "https://tripadvisor.com/"
      },
      {
        name: "ProductHunt",
        url: "https://producthunt.com/"
      },
      {
        name: "Snapchat",
        url: "https://snapchat.com/"
      },
      {
        name: "WhatsApp",
        url: "https://whatsapp.com/"
      },
      {
        name: "Reddit",
        url: "https://reddit.com/"
      },
      {
        name: "Tinder",
        url: "https://tinder.com/"
      },
      {
        name: "AppleMusic",
        url: "https://apple.com/"
      },
      {
        name: "Yelp",
        url: "https://yelp.com/"
      },
      {
        name: "Medium",
        url: "https://medium.com/"
      },
      {
        name: "Skype",
        url: "https://skype.com/"
      },
      {
        name: "Flickr",
        url: "https://flickr.com/"
      },
      {
        name: "GitHub",
        url: "https://github.com/"
      },
      {
        name: "GooglePlus",
        url: "https://google.com/"
      },
      {
        name: "Discord",
        url: "https://discordapp.com/"
      },
      {
        name: "Telegram",
        url: "https://telegram.org/"
      },
      {
        name: "TikTok",
        url: "https://tiktok.com/"
      }
    ];
    const someIconHasDefaultLink = values?.icons?.icons?.some((icon) => {
      if (icon?.url?.trim() === "") return true;

      const defaultValue = defaultIconsData.find((defaultIcon) => defaultIcon.name === icon.name);
      if (!defaultValue) return false;

      return defaultValue?.url === icon?.url?.trim();
    });

    if (someIconHasDefaultLink) {
      errors.push({
        id: "MISSING_SOCIAL_LINK",
        severity: "ERROR",
        icon: "fa-link",
        title: "missing_social_link_title",
        description: "missing_social_link_description"
      });
    }

    return errors.concat(defaultErrors);
  });

  unlayerInstance.setToolValidator("image", (data) => {
    //console.log("ToolValidator: image");
    const { defaultErrors, values } = data as {
      defaultErrors;
      values: {
        action: {
          values: {
            href: string;
          };
        };
      };
    };
    let errors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    // const urlLinkErrors = audit.isValidUrl({ url: values.action?.values?.href });
    // errors = errors.concat(urlLinkErrors);

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    return errors.concat(defaultErrors);
  });

  unlayerInstance.setToolValidator("menu", function (data) {
    //console.log("ToolValidator: menu");
    const { defaultErrors, values } = data as {
      defaultErrors: UnlayerValidators;
      values: {
        menu: {
          items: Array<{
            link: {
              values: {
                href: string;
              };
            };
          }>;
        };
      };
    };
    let errors: UnlayerValidators = [];
    let newDefaultErrors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    // Override errors severity
    const errorsIds = ["MENU_EMPTY_LINKS"];

    newDefaultErrors = defaultErrors.map((error) => {
      if (errorsIds.includes(error.id)) {
        error.severity = "ERROR";
      }
      return error;
    });

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    return errors.concat(newDefaultErrors);
  });

  unlayerInstance.setToolValidator("heading", (data) => {
    //console.log("ToolValidator: heading");
    const { defaultErrors, values } = data as {
      defaultErrors;
      values: {
        html: {
          children: HTMLCollection;
          firstChild: {
            href: string;
          };
        };
      };
    };
    let errors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    return errors.concat(defaultErrors);
  });

  unlayerInstance.setToolValidator("text", (data) => {
    //console.log("ToolValidator: text");
    const { defaultErrors, values } = data as {
      defaultErrors;
      values: {
        html: {
          children: HTMLCollection;
          firstChild: {
            href: string;
          };
        };
      };
    };
    let errors: UnlayerValidators = [];

    // const audit = window.unlayerPerfitKit.audit;
    const audit = useTemplateAuditApp();

    const bannedDomainsErrors = audit.bannedDomains({ values });
    errors = errors.concat(bannedDomainsErrors);

    const utmErrors = audit.utmActive({ values });
    errors = errors.concat(utmErrors);

    return errors.concat(defaultErrors);
  });
};
