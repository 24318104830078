<template>
  <div class="grid max-w-4xl grid-cols-2 gap-6">
    <div class="flex flex-col justify-between">
      <div>
        <div class="flex items-center gap-2">
          <span class="mr-2 text-base font-medium text-sky-400">{{ t("editorType.unlayer.title") }} </span>
          <SimpleBadge dot theme="green">
            {{ t("editorType.unlayer.badge") }}
          </SimpleBadge>
        </div>
        <p class="mt-2 text-sm text-gray-500">{{ t("editorType.unlayer.description") }}</p>
        <div class="mt-4">
          <IconBullet
            v-for="bullet in bullets"
            :key="bullet.text"
            class="mt-1"
            :icon="InformationCircleIcon"
            :title="bullet.text"
            :tooltip="bullet.tooltip"
            color="green"
          />
          <a href="" class="mt-1 inline-flex" @click.prevent="openArticle('6827777')">
            <span class="group inline-flex content-center gap-1.5">
              <InformationCircleIcon class="h-5 w-5 self-center text-sky-500" aria-hidden="true" />
              <span class="text-sm text-sky-400 group-hover:text-sky-500">
                {{ t("editorType.unlayer.bullets.seeMore") }}
              </span>
            </span>
          </a>
        </div>
      </div>
      <div class="pt-4">
        <FormCheckBox
          v-if="false"
          v-model="setAsDefault"
          class="mt-2"
          :label="t('editorType.unlayer.checkbox')"
          :disabled="false"
        />
        <SimpleButton
          class="mt-4"
          theme="primary"
          :loading="loading === 'unlayer'"
          :disabled="loading === 'eddie'"
          @click="useUnlayer()"
        >
          <template #leading>
            <CheckIcon class="h-5 w-5 text-white" />
          </template>
          {{ t("editorType.unlayer.selectEditor") }}
        </SimpleButton>
      </div>
    </div>
    <div class="flex flex-col justify-between">
      <div>
        <span class="mr-2 text-base font-medium text-sky-400">{{ t("editorType.eddie.title") }} </span>
        <p class="mt-2 text-sm text-gray-500">{{ t("editorType.eddie.description") }}</p>
        <AlertBox theme="info" class="mt-6">
          <p>{{ t("editorType.eddie.alertBoxFirstDescription") }}</p>
          <br />
          <p>{{ t("editorType.eddie.alertBoxSecondDescription") }}</p>
          <template #actions>
            {{ t("editorType.eddie.docText") }}
            <a href="" @click.prevent="openArticle('6922138')"> {{ t("editorType.eddie.docLink") }}</a
            >.
          </template>
        </AlertBox>
      </div>
      <div>
        <SimpleButton
          class="mt-6"
          theme="primary"
          :loading="loading === 'eddie'"
          :disabled="loading === 'unlayer'"
          @click="useEddie()"
        >
          <template #leading>
            <CheckIcon class="h-5 w-5 text-white" />
          </template>
          {{ t("editorType.eddie.selectEditor") }}
        </SimpleButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

// Components
import SimpleBadge from "@atoms/SimpleBadge/SimpleBadge.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import IconBullet from "@atoms/IconBullet.vue";
import FormCheckBox from "@molecules/FormCheckBox.vue";

//API
// import { useAccountFeatures } from "@api/modules/account";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";
import { InformationCircleIcon } from "@heroicons/vue/outline";

// Types
export interface SelectEvent {
  selectedEditor: "unlayer" | "eddie";
  setAsDefault?: boolean;
}
interface Bullet {
  text: string;
  tooltip?: string;
}

// States
const { t } = useI18n();
const setAsDefault = ref(false);
// const AccountFeatures = useAccountFeatures();

//Consts
const bullets: Array<Bullet> = [
  {
    text: t("editorType.unlayer.bullets.feature1"),
    tooltip: t("editorType.unlayer.bullets.tooltip1"),
  },
  {
    text: t("editorType.unlayer.bullets.feature2"),
    tooltip: t("editorType.unlayer.bullets.tooltip2"),
  },
  {
    text: t("editorType.unlayer.bullets.feature3"),
    tooltip: t("editorType.unlayer.bullets.tooltip3"),
  },
  {
    text: t("editorType.unlayer.bullets.feature4"),
    tooltip: t("editorType.unlayer.bullets.tooltip4"),
  },
  {
    text: t("editorType.unlayer.bullets.feature5"),
    tooltip: t("editorType.unlayer.bullets.tooltip5"),
  },
  {
    text: t("editorType.unlayer.bullets.feature6"),
    tooltip: t("editorType.unlayer.bullets.tooltip6"),
  },
];

// Props
defineProps<{
  loading?: "unlayer" | "eddie";
}>();

// Emits
const emit = defineEmits<{
  (e: "select", selectEvent: SelectEvent): void;
}>();

const useUnlayer = async () => {
  emit("select", { selectedEditor: "unlayer", setAsDefault: setAsDefault.value });
  //   if (setAsDefault.value) {
  //     await AccountFeatures.put({ key: "UNLAYER_DEFAULT", value: "1" });
  //   }
};

const useEddie = () => {
  emit("select", { selectedEditor: "eddie" });
};

const openArticle = (id: string) => {
  window.Intercom("showArticle", id);
};
</script>

<i18n lang="json">
{
  "es": {
    "editorType": {
      "unlayer": {
        "title": "Nuevo editor de plantillas drag & drop",
        "badge": "Nuevo",
        "description": "Crea tu campaña usando el nuevo editor y accede a múltiples mejoras en la edición de tus emails.",
        "bullets": {
          "feature1": "Edición drag & drop para crear tus plantillas.",
          "tooltip1": "Incorpora nuevos bloques y elementos, o cambia su ubicación arrastrándolos y soltándolos.",
          "feature2": "Inserción mejorada de productos desde tu tienda.",
          "tooltip2": "Ahora es mucho más fácil buscar tus productos, seleccionar entre las distintas variantes e imágenes, aplicar estilos y contar con más opciones para organizar la información. ",
          "feature3": "Personalización y vista previa en formato mobile.",
          "tooltip3": "Edita tu diseño en modo desktop o mobile, con estilos diferenciados, y controlando la visibilidad de los bloques y elementos según cada dispositivo.",
          "feature4": "Almacenamiento de bloques reutilizables.",
          "tooltip4": "Guarda tus propios bloques para utilizarlos fácilmente en otras plantillas.",
          "feature5": "Edición de imágenes: recortes, filtros y más!",
          "tooltip5": "Personaliza tus imágenes con filtros, marcos, stickers, acción de rotar, recortar, redimensionar, y mucho más.",
          "feature6": "Historial de versiones y autoguardado.",
          "tooltip6": "Nunca más perderás tus cambios, y también podrás recuperar a una versión guardada previamente.",
          "seeMore": "Conoce todas las mejoras del nuevo editor."
        },
        "checkbox": "Usar siempre como predeterminado.",
        "selectEditor": "Usar el nuevo editor"
      },
      "eddie": {
        "title": "Editor de plantillas habitual",
        "description": "Utiliza Eddie para crear tu campaña. Diseña tu envío bloque por bloque, eligiendo de una amplia variedad de regiones predefinidas.",
        "alertBoxFirstDescription": "Te recomendamos comenzar a utilizar el nuevo editor para aprovechar todas las mejoras y novedades.",
        "alertBoxSecondDescription": "¡No te preocupes! Eddie seguirá estando disponible el tiempo necesario para que puedas aprender a usarlo.",
        "docText": "Conoce los detalles para comenzar a utilizar el nuevo editor en",
        "docLink": "esta guía",
        "selectEditor": "Seguir usando Eddie"
      }
    }
  },
  "pt": {
    "editorType": {
      "unlayer": {
        "title": "Novo editor de templates drag & drop",
        "badge": "Novo",
        "description": "Crie sua campanha usando o novo editor e acesse a diversas melhorias na edição de seus emails.",
        "bullets": {
          "feature1": "Edição drag & drop para criar seus templates.",
          "tooltip1": "Incorpore novos blocos e elementos ou mude sua localização arrastando e soltando.",
          "feature2": "Inclusão de produtos melhorada desde sua loja.",
          "tooltip2": "Agora é muito mais fácil procurar seus produtos, selecionar entre as diferentes variantes e imagens, aplicar estilos e contar com mais opções para organizar as informações.",
          "feature3": "Personalização e pré-visualização em formato mobile.",
          "tooltip3": "Edite seu template em modo desktop ou mobile com diversos estilos, controlando a visualização dos blocos e elementos segundo cada dispositivo.",
          "feature4": "Armazenamento de blocos reutilizáveis.",
          "tooltip4": "Salve seus próprios blocos para utilizá-los facilmente em outros templates.",
          "feature5": "Edição de imagens: recortes, filtros e mais!",
          "tooltip5": "Personalize suas imagens com filtros, armações, stickers, ação de girar, recortar, redimensionar e muito mais.",
          "feature6": "Histórico de versões e salvamento automático.",
          "tooltip6": "Nunca mais perderá suas modificações e também poderá recuperar uma versão salva anteriormente.",
          "seeMore": "Conheça todas as melhorias do novo editor."
        },
        "checkbox": "Usar sempre como predeterminado.",
        "selectEditor": "Usar o novo editor"
      },
      "eddie": {
        "title": "Editor de templates habitual",
        "description": "Use Eddie para criar sua campanha. Desenhe um envio bloco por bloco, escolhendo uma ampla variedade de regiões predefinidas.",
        "alertBoxFirstDescription": "Recomendamos que você comece a usar o novo editor para aproveitar todas as melhorias e novidades.",
        "alertBoxSecondDescription": "Não se preocupe! Eddie continuará disponível pelo tempo que for necessário para que você possa aprender a usá-lo.",
        "docText": "Conheça os detalhes para começar a usar o novo editor",
        "docLink": "nesse guia",
        "selectEditor": "Continuar usando o Eddie"
      }
    }
  }
}
</i18n>
