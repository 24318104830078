<template>
  <PageTemplate
    :permissions="['optins:list']"
    :title="t('title')"
    :tabs="tabs"
    :selected="selectedTab"
    :links="{
      es: {
        helpId: 1748,
      },
      pt: {
        helpId: 1748,
      },
      en: {
        helpId: 1748,
      },
    }"
    @update:selected="updateSelectedTab"
  >
    <template #options>
      <div class="flex space-x-4">
        <PermissionsButton href="/optins/new" :permissions="['optins:create']" theme="green-lime">
          <template #leading>
            <PlusIcon class="m-0.5 h-4 w-4" />
          </template>
          {{ t("createOptinButton") }}
        </PermissionsButton>
      </div>
    </template>
    <template #content>
      <FiltersPanel v-show="!firstLoad && (rows.length > 0 || selectedSearch.length > 0 || loadingTable)">
        <div class="my-auto flex w-full items-center justify-between space-x-4 px-8">
          <FilterInput
            :selected="selectedSearch"
            :filter-data="filterData"
            :placeholder="t('placeholderSearch')"
            class="min-w-[30rem] max-w-[50%] grow 2xl:max-w-[40%]"
            @update:selected="updateSelected"
            @open="onOpenSearch"
            @close="onCloseSearch"
          />
          <div>
            <span v-show="!loadingTable" class="text-sm text-gray-500">
              {{ t("optinCount", { count: n(Number(rows.length) ?? 0, "decimal") }, { plural: rows.length }) }}
            </span>
            <span v-show="loadingTable" class="animate-pulse rounded bg-gray-100 px-12 py-0.5 text-sm" />
          </div>
        </div>
      </FiltersPanel>
    </template>
    <template #all>
      <div class="h-full">
        <OptinsTable
          v-show="!loadingTable && rows.length > 0"
          v-if="mounted"
          v-model:rows="rows"
          v-model:total-count="totalCount"
          :sort-by="sortBy"
          :columns="columns"
          :skeleton-table="skeletonTable"
          :fetch-limit="fetchlimit"
          @update:sort-by="updateSortBy"
          @fetch-optins="fetchOptins"
        />
        <EmptyState
          v-show="!loadingTable && selectedSearch.length === 0 && rows.length === 0"
          :title="t('emptyState.title')"
          :article-id="1748"
          :article-text="t('emptyState.moreAbout')"
          :points="[t('emptyState.first'), t('emptyState.second'), t('emptyState.third')]"
        >
          <template #image>
            <EmptyStateImage v-show="lgBp" />
          </template>
        </EmptyState>
        <EmptySearchState
          v-show="!loadingTable && selectedSearch.length > 0 && rows.length === 0"
          @cleanSearch="cleanSearch"
        />
        <div v-show="loadingTable" class="flex h-full w-full items-center justify-center">
          <LoadingSpinner class="h-6 w-6 text-sky-500" aria-hidden="true" />
        </div>
      </div>
    </template>
  </PageTemplate>
</template>

<script lang="ts" setup>
import { ref, watch, computed, onMounted, toRaw } from "vue";

import { getTabs, optinsToRows, getColumns } from "./optins.data";
import type { OptinsTabs, ColumnsIds, OptinsRows } from "./optins.types";

// Components
import EmptySearchState from "@molecules/EmptySearchState/EmptySearchState.vue";
import EmptyStateImage from "./EmptyStateImage.vue";
import EmptyState from "@molecules/EmptyState/EmptyState.vue";
import FiltersPanel from "@templates/FiltersPanel";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";
import FilterInput, { isSelectedValueTag } from "@molecules/FilterInput";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import PageTemplate from "@templates/PageTemplate";
import OptinsTable from "./components/OptinsTable/OptinsTable.vue";
import type { Columns, SortColumn, SortingModes } from "@molecules/Table";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icon
import { PlusIcon, PencilIcon } from "@heroicons/vue/solid";

// I18n
import { useI18n } from "vue-i18n";

// Composable
import { cleanQueryParams, updateQueryParam, getQueryParams } from "@composables/router";
import { useBreakpoints } from "@composables/breakpoints";

// Application
import { useLocalStorageApp } from "@application";

// Service
import { useOptins } from "@api/modules/optins/optins";

// Domain
import type { DataItems } from "@domain/data";
import type { FilterData } from "@domain/filters";
import type { Item, Items } from "@templates/PageTemplate";
import { getPrimaryHighlightList } from "@domain/filters";

const { t, n } = useI18n();
const optinsAPI = useOptins();
const localStorageApp = useLocalStorageApp();
const { lgBp } = useBreakpoints();

const tabs: Items<OptinsTabs> = getTabs();
const selectedTab = ref<Item<OptinsTabs>>(tabs[0]);
const updateSelectedTab = (newTab: Item<OptinsTabs>) => {
  selectedTab.value = newTab;
  // emit("changeRoute", newTab.key);
};
const skeletonTable = ref(false);
const fetchlimit = 100000;
const skeletonCount = ref(5);
const calculateSkeletonCount = () => {
  if (rows.value.length > fetchlimit) {
    skeletonCount.value = fetchlimit;
    return;
  }

  skeletonCount.value = rows.value.length;
  return;
};
const loadingTable = ref(false);
const firstLoad = ref(false);

const totalCount = ref<number>(0);

const columns: Columns<ColumnsIds | "options", ColumnsIds> = getColumns();
const rows = ref<OptinsRows>([]);
const savedSortBy = localStorageApp.get<SortColumn<ColumnsIds>>({ id: "optins_sort_by" });
const sortBy = ref<SortColumn<ColumnsIds>>(
  savedSortBy ?? {
    columnId: "name",
    sort: "ASC",
  },
);
const updateSortBy = (newSortBy: SortColumn<ColumnsIds>) => {
  sortBy.value = newSortBy;
  localStorageApp.save<SortColumn<ColumnsIds>>({ id: "optins_sort_by", value: sortBy.value });
};
const selectedSearch = ref<SelectedValues>([]);
const cleanSearch = () => {
  selectedSearch.value = [];
};

const filterData = computed<FilterData>(() => ({
  filters: [{ id: "optins", text: t("filters.optins"), icon: PencilIcon, highlightList: highlightSearches.value }],
  filtersRoles: {
    primary: "optins",
  },
}));

const highlightSearches = ref<DataItems>([]);

const insertRecommmendedNames = () => {
  const searches = localStorageApp.get<DataItems | undefined>({ id: "optins_searches" });
  if (!searches) return;
  highlightSearches.value = searches;
};

const filterInputIsOpen = ref(false);
const onOpenSearch = () => {
  filterInputIsOpen.value = true;
};
const onCloseSearch = () => {
  filterInputIsOpen.value = false;
};

const fetchOptins = async () => {
  const getOptinsSearch = selectedSearch.value.find((search) => search.filterId === "optins") as SelectedValueText;

  const optins = await optinsAPI.get({
    sortBy: sortBy.value?.columnId,
    sortDir: sortBy.value?.sort === "DESC" ? "desc" : "asc",
    search: getOptinsSearch ? getOptinsSearch.value : undefined,
    limit: fetchlimit,
  });

  if (optins.isNewRequest) {
    rows.value = optinsToRows(optins.value);
    totalCount.value = optins.total ?? 0;
    return;
  }

  rows.value = [...rows.value, ...optinsToRows(optins.value)];
};

const updatePrimaryHighlightList = (newValue: SelectedValueText) => {
  const list = getPrimaryHighlightList(filterData.value) as DataItems;
  if (list.some((search) => search.value === newValue.value)) return;

  if (list.length >= 5) list.pop();
  list.unshift({
    key: newValue.value,
    value: newValue.value,
  });

  localStorageApp.save<DataItems>({ id: "optins_searches", value: toRaw(list) });
  insertRecommmendedNames();
};

const updateSelected = async (newSelected: SelectedValues) => {
  selectedSearch.value = newSelected;

  const newPrimaryValue = newSelected.find((selected) => selected.filterId === "optins");

  const oldPrimaryValue = selectedSearch.value.find((selected) => selected.filterId === "optins");

  if (newPrimaryValue && newPrimaryValue !== oldPrimaryValue && !isSelectedValueTag(newPrimaryValue)) {
    updatePrimaryHighlightList(newPrimaryValue);
  }
};

const getQueryParamsKeyOrValue = (searchKeyOrValue: string): string | undefined => {
  const filterToQueryParams = {
    optins: "q",
  };

  if (Object.values(filterToQueryParams).includes(searchKeyOrValue)) {
    const foundKey = Object.keys(filterToQueryParams).find((key) => filterToQueryParams[key] === searchKeyOrValue);
    return foundKey;
  }

  if (filterToQueryParams?.[searchKeyOrValue]) {
    return filterToQueryParams[searchKeyOrValue];
  }

  return;
};

const updateQueryParams = () => {
  cleanQueryParams();

  selectedSearch.value.forEach((selected) => {
    const paramName = getQueryParamsKeyOrValue(selected.filterId);
    if (!paramName) return;

    updateQueryParam({ key: paramName, value: selected.id });
  });

  if (sortBy.value) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  }
};

watch([selectedSearch], async () => {
  updateQueryParams();

  loadingTable.value = true;
  await fetchOptins();
  loadingTable.value = false;
});

watch([sortBy], async () => {
  calculateSkeletonCount();
  skeletonTable.value = true;

  updateQueryParams();
  await fetchOptins();
  skeletonTable.value = false;
});

const mounted = ref(false);
onMounted(async () => {
  firstLoad.value = true;
  loadingTable.value = true;
  const queryParams = getQueryParams();

  queryParams.forEach((value, key) => {
    const filterId = getQueryParamsKeyOrValue(key);
    if (!filterId) return;

    selectedSearch.value.push({
      filterId,
      id: value,
      value: value,
    });
  });

  const sortParams = queryParams?.get("sort")?.split(" ");

  if (!sortParams) {
    updateQueryParam({ key: "sort", value: `${sortBy.value.columnId} ${sortBy.value.sort}` });
  } else {
    sortBy.value = {
      columnId: sortParams?.[0] as ColumnsIds,
      sort: sortParams?.[1] as SortingModes,
    };
  }

  insertRecommmendedNames();

  mounted.value = true;
  await fetchOptins();
  loadingTable.value = false;
  firstLoad.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Formularios",
    "optinCount": "0 formularios | 1 formulario | {count} formularios",
    "placeholderSearch": "Buscar formularios",
    "createOptinButton": "Crear formulario",
    "emptyState": {
      "title": "Crea formularios de suscripción y aumenta tu audiencia",
      "moreAbout": "Saber más sobre formularios",
      "first": "Personaliza los formularios con la imagen, colores y estilos de tu marca. ¡Es muy sencillo!",
      "second": "Insértalos en tu tienda online con un simple click, configurando su comportamiento y ubicación.",
      "third": "Aumenta su efectividad ofreciendo descuentos o beneficios, y envíalos usando los automations."
    },
    "filters": {
      "optins": "Nombre"
    },
    "columns": {
      "name": "Nombre",
      "totalsubscriptions": "Suscripciones",
      "lastmonthsubscriptions": "Último mes",
      "lastweeksubscriptions": "Última semana"
    },
    "tabs": {
      "all": "Todos"
    }
  },
  "pt": {
    "title": "Formulários",
    "optinCount": "0 formulários | 1 formulário | {count} formulários",
    "placeholderSearch": "Pesquisar formulários",
    "createOptinButton": "Criar formulário",
    "emptyState": {
      "title": "Crie formulários de inscrição e aumente sua audiência",
      "moreAbout": "Aprender mais sobre formulários",
      "first": "Personalize os formulários com imagem, cores e estilos da sua marca. É muito simples!",
      "second": "Coloque-os na sua loja online com um simples clique configurando seu comportamento e localização.",
      "third": "Aumente a sua eficácia oferecendo descontos ou benefícios e envie-os usando os automations."
    },
    "filters": {
      "optins": "Nome"
    },
    "columns": {
      "name": "Nome",
      "totalsubscriptions": "Inscrições totais",
      "lastmonthsubscriptions": "Último mês",
      "lastweeksubscriptions": "Última semana"
    },
    "tabs": {
      "all": "Todos"
    }
  }
}
</i18n>
