import { EditView } from "@/core/magnet";
import { _ } from "@/helpers/utils";
import app from "@/app";
import { useNotifications } from "@composables/notifications";
import ActivateFormWithoutCodeButton from "@organisms/Optins/ActivateFormWithoutCodeButton";
import { mountComponent } from "@/helpers/vue";
import { useIntegrationsStore } from "@store";

const { notify } = useNotifications();

export default EditView.extend({
  module: "integrations",
  templates: {
    initial: require("../templates/edit.hbs"),
  },
  gender: "F",
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-fields-modal]": "openFieldsModal",
      "click [data-activate]": "activate",
      "click [data-activate-flowy]": "activateFlowy",
      "submit [data-form=save-fields]": "saveFields",
      "click [data-action=deactivate]": "deactivate",
      "click [data-action=copy-api-key]": "copyApiKey",
      "click [data-action=refresh-products]": "refreshProducts",
      "click [data-action=refresh-all]": "refreshAll",
      "click [data-action=select-all-on-focus]": "selectAll",
    });
  },

  // Performs an action before fetching the model
  beforeFetch: function (params) {
    var id = app.router.args[1];

    if (
      _.contains(["facebook", "wordpress", "perfitapi", "persat", "zapier", "webjs", "integrately", "storydots"], id)
    ) {
      this.item = new app.models.integrations({ id: id });
    }
    return params;
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    if (this.item.id == "transactional") {
      if (this.item.attributes.plan) {
        const consumed = this.item.attributes.plan.consumed / this.item.attributes.plan.limit_monthly;
        this.item.set("availablePerc", consumed * 100);
      }
      this.changeTemplate(require("../templates/edit-transactional.hbs"));
    }

    if (this.item.id == "webjs") {
      this.changeTemplate(require("../templates/edit-webjs.hbs"));
    }

    if (this.item.id == "customtriggers") {
      this.changeTemplate(require("../templates/edit-customtriggers.hbs"));
    }

    if (this.item.id == "webhooks") {
      this.changeTemplate(require("../templates/edit-webhooks.hbs"));
    }

    if (this.item.id == "vtex") {
      this.changeTemplate(require("../templates/edit-vtex.hbs"));
    }

    if (this.item.id == "signos") {
      this.changeTemplate(require("../templates/edit-signos.hbs"));
    }

    if (this.item.id == "storydots") {
      this.changeTemplate(require("../templates/edit-storydots.hbs"));
    }

    if (this.item.id == "woocommerce") {
      this.changeTemplate(require("../templates/edit-woocommerce.hbs"));
    }

    if (this.item.id == "tiendanube") {
      this.changeTemplate(require("../templates/edit-tiendanube.hbs"));
    }

    if (this.item.id == "jivochat") {
      this.changeTemplate(require("../templates/edit-jivochat.hbs"));
    }

    if (this.item.id == "pow") {
      this.changeTemplate(require("../templates/edit-pow.hbs"));
    }

    if (this.item.id == "magento") {
      this.changeTemplate(require("../templates/edit-magento.hbs"));
    }

    if (this.item.id == "flowy") {
      this.changeTemplate(require("../templates/edit-flowy.hbs"));
    }

    if (this.item.id == "tiendup") {
      this.changeTemplate(require("../templates/edit-tiendup.hbs"));
    }

    if (this.item.id == "mshops") {
      this.changeTemplate(require("../templates/edit-mshops.hbs"));
    }

    if (this.item.id == "fbleadads") {
      this.changeTemplate(require("../templates/edit-fbleadads.hbs"));
    }

    // Set basic integration data
    this.item.set(_.findWhere(this.item.additionalsData.integrations, { id: this.item.id }));

    // If the query string has a code, post to the API to finish the integration setup
    const code = this.options.code;
    const integrationEnabled = this.item.get("enabled");
    if (code && !integrationEnabled) {
      const initData = { code: code };
      if (this.item.id === "shopify") {
        initData.shop = this.options.shop;
      }
      if (this.options.id === "jumpseller") {
        initData.redirect_uri = window.location.origin + "/integrations/jumpseller/auth";
      }
      if (this.options.id === "mshops") {
        initData.lang = app.lang;
      }
      if (this.options.id === "tray") {
        initData.code = this.options.code;
        initData.shopUrl = this.options.api_address;
      }

      this.item
        .init(initData)
        .done(
          function (response) {
            this.item.set("enabled", true);
            this.item.set(response.data);
            notify({
              title: window.lang.integrations.feedback.successTitle,
              text: window.lang.integrations.feedback.activated,
              theme: "success",
              timeout: 10000,
            });
            callback();
          }.bind(this),
        )
        .fail(
          function (e) {
            if (
              e.responseJSON?.error?.validationErrors?.store_id === "duplicated" ||
              e.responseJSON?.error?.validationErrors?.store_id === "exists"
            ) {
              if (this.item.id === "tiendanube") {
                notify({
                  title: window.lang.integrations.feedback.failedTitle,
                  text: window.lang.integrations.activationFailStoreIdExists,
                  theme: "error",
                  timeout: 0,
                });
              } else {
                notify({
                  title: window.lang.integrations.feedback.failedTitle,
                  text: window.lang.integrations.activationFail,
                  theme: "error",
                  timeout: 0,
                });
              }
            } else if (e.responseJSON?.error?.validationErrors?.account === "exists") {
              notify({
                title: window.lang.integrations.feedback.failedTitle,
                text: window.lang.integrations.activationFailAccountExists,
                theme: "error",
                timeout: 0,
              });
            } else {
              notify({
                title: window.lang.integrations.feedback.failedTitle,
                text:
                  this.item.id === "mshops"
                    ? window.lang.integrations.feedback.mshopsFailed
                    : window.lang.integrations.activationFail,
                theme: "error",
                timeout: 0,
              });
            }

            callback();
          }.bind(this),
        );
      return this;
    }

    // Continue with render
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Hide elements if the user has not the right permissions

    if (this.item.id == "webjs") {
      mountComponent(ActivateFormWithoutCodeButton, {}, "#vue-activate-form-without-code");
    }

    app.layout.updateLayout();
  },

  // Starts the activation process
  activate: function () {
    const integrationStore = useIntegrationsStore();

    // Redirect to the integration to continue
    if (this.item.get("redirect")) {
      this.openModal(require("../templates/modals/redirecting.hbs"), this.renderData());
      setTimeout(
        function () {
          window.location.href = this.item.getActivationUrl();
        }.bind(this),
        2000,
      );
    } else {
      // Activate via API
      this.item.init(this.item.get("activationData")).done(
        function (response) {
          this.item.set("enabled", true);
          this.item.set(response.data);
          this.getModal().modal("hide");
          this.reload();
          this.displayMessage(window.lang.integrations.feedback.activated);
          integrationStore.fetchIntegrations();
        }.bind(this),
      );
    }
  },

  // Starts the activation process
  activateFlowy: function () {
    const integrationStore = useIntegrationsStore();

    // Activate via API
    $.get("integrations/tiendanube", null, (res) => {
      if (!res.data || !res.data.shopId) {
        notify({
          title: window.lang.integrations.feedback.failedTitle,
          text: window.lang.integrations.flowy.tiendanubeRequired,
          theme: "error",
          timeout: 15000,
        });
        return;
      }

      $.ajax("integrations/flowy/init", {
        method: "post",
        data: { shopId: res.data.shopId },
        success: (response) => {
          this.item.set("enabled", true);
          this.item.set(response.data);
          this.getModal().modal("hide");
          this.reload();
          notify({
            title: window.lang.integrations.feedback.successTitle,
            text: window.lang.integrations.flowy.activated,
            theme: "success",
            timeout: 5000,
          });
          integrationStore.fetchIntegrations();
        },
        error: () => {
          notify({
            title: window.lang.integrations.feedback.failedTitle,
            text: window.lang.integrations.flowy.flowyRequired,
            theme: "error",
            timeout: 15000,
          });
        },
      });
    });
  },

  // Opens a modal to complete required fields before activation
  openFieldsModal: function () {
    this.openModal(require("../templates/modals/fields.hbs"), {
      data: this.item,
    });
  },

  // Saves the fields into the current item
  saveFields: function (e) {
    var form = e.currentTarget,
      data = $(form).serializeObject();
    e.preventDefault();

    // Validate
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Set fields and activate
    this.item.set("activationData", data);
    this.activate();
  },

  // Deactivates the integration
  deactivate: function (e) {
    const integrationStore = useIntegrationsStore();

    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Send API request
    this.item.deactivate().done(
      function () {
        this.displayMessage(window.lang.integrations.feedback.deactivated);
        integrationStore.fetchIntegrations();
        this.reload();
      }.bind(this),
    );
  },

  // Selects the text in the image link input
  selectApiKeyText: function () {
    var input = document.getElementById("apikey");
    input.select();
  },

  // Copies the API key input content
  copyApiKey: function (e) {
    var button = $(e.currentTarget);
    this.selectApiKeyText();
    if (document.execCommand("copy")) {
      button.text(window.lang.done);
    }
  },

  refreshProducts: function () {
    // Send request
    this.item.refreshProducts().done(
      function () {
        this.displayMessage(window.lang.integrations.feedback.refreshingProducts);
      }.bind(this),
    );
  },

  refreshAll: function () {
    // Send request
    this.item.refresh().done(
      function () {
        this.displayMessage(window.lang.integrations.feedback.refreshingProducts);
      }.bind(this),
    );
  },

  selectAll: function (e) {
    var strLength = $(e.currentTarget).val().length;
    e.currentTarget.setSelectionRange(0, strLength);
  },
});
