import MetricBaseView from "./base";
import { EditView } from "@/core/magnet";
import Chart from "chart.js";
import app from "@/app";
import { _ } from "@/helpers/utils";
import InfoIcon from "@atoms/InfoIcon.vue";
import { mountComponent } from "@/helpers/vue";
const moment = window.moment;

export default MetricBaseView.extend({
  module: "metrics",
  templates: {
    initial: require("../templates/details.hbs"),
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=archive]": "archive",
      "click [data-action=stop]": "stop",
      "click [data-action=join-lists]": "join",
      "click [data-action=unarchive]": "unarchive",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-share-modal]": "openShareModal",
      "click [data-action=copy-share-url]": "copyShareUrl",
      "click [data-link]": "linkToActivity",
      "change [data-action=switch-version]": "switchVersion",
      "click [data-action=open-criterias-modal]": "openCriteriasModal",
    });
  },

  // Performs an action before fetching the model
  beforeFetchItems: function (params) {
    $("body").addClass("guest");
    return params;
  },

  // Draws the charts after renderization
  afterRender: function () {
    // Parse criterias
    this.parseRecipients();

    // Redirect to contents if the campaign is pending
    this.redirectToContents(this.item.attributes);

    // Draw charts
    $(".chart").easyPieChart({
      scaleColor: false,
    });

    // Show timeline
    if (window.outerWidth > 640) {
      this.loadTimelineChart();
    } else {
      $("#timeline").remove();
    }

    const assistedSalesTooltipText = window.lang.metrics.assistedSalesTooltip;
    const props = {
      content: assistedSalesTooltipText,
    };
    mountComponent(InfoIcon, props, "#vue-info-icon");

    // Force resize
    $(window).resize();
  },

  // Parses the campaign contacts criterias
  parseRecipients: function () {
    var included = this.item.attributes.include || false,
      excluded = this.item.attributes.exclude || false;

    // Parse included contacts
    if (included.lists !== undefined && _.isArray(included.lists) && included.lists.length && included.lists[0] === 0) {
      this.item.set("includeAllContacts", true);
    }

    // Parse excluded contacts
    if (!_.size(excluded)) {
      this.item.set("notExcludeContacts", true);
    }
  },

  // Renders the timeline chart
  loadTimelineChart: function () {
    var data,
      labels = [],
      clicks = [],
      opens = [],
      ctx = document.getElementById("clicks-timeline");

    // Set chart data
    data = {
      datasets: [
        {
          label: " " + window.lang.metrics.opened,
          backgroundColor: "rgba(150, 196, 47, 0.1)",
          borderColor: "#96C42E",
          // pointBackgroundColor: "#fff",
          // pointBorderColor: "#96C42E",
          // pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointHoverBackgroundColor: "#96C42E",
          pointHoverRadius: 6,
          pointRadius: 2,
        },
        {
          label: " " + window.lang.metrics.clicks,
          // backgroundColor: "rgba(234, 243, 214, 0.5)",
          backgroundColor: "rgba(62, 198, 160, 0.1)",
          borderColor: "#2EC6A0",
          // pointBackgroundColor: "#fff",
          // pointBorderColor: "#2EC6A0",
          // pointBorderColor: "#FFF",
          pointBorderWidth: 1,
          pointHoverBackgroundColor: "#2EC6A0",
          pointHoverRadius: 6,
          pointRadius: 2,
        },
      ],
    };

    // Parse response data
    this.item.additionalsData.timeline.forEach(function (item) {
      var hour = moment
        .utc(item.datetime + "+00:00")
        .tz(window.config.timezone)
        .format("dd HH[hs]");
      labels.push(hour);
      clicks.push(item.clicks);
      opens.push(item.opens);
    });
    data.labels = labels;
    data.datasets[0].data = opens;
    data.datasets[1].data = clicks;

    // Render chart
    ctx.height = 250;
    return new Chart(ctx, {
      type: "line",
      data: data,
      options: {
        legend: {
          display: true,
          position: "bottom",
        },
        defaultFontColor: "#DDD",
        // animation: true,
        responsive: true,
        bezierCurve: true,
        tooltips: {
          mode: "x",
          bodySpacing: 10,
          bodyFontSize: 14,
          callbacks: {
            title: function (tooltipItems) {
              var items = $.extend(true, [], tooltipItems);
              return items.pop().xLabel /*+ ":00 hs"*/;
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontColor: "#696969",
                userCallback: function (label) {
                  if (Math.floor(label) === label) {
                    return label;
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                // display: false,
                fontColor: "#696969",
                callback: function (val, index) {
                  // Hide the label of every 2nd dataset
                  return index % 6 === 0 ? val : "";
                },
              },
            },
          ],
        },
      },
    });
  },

  // Renders the rebounds chart
  loadBouncedChart: function () {
    var target = "#bounced-pie",
      data;

    // Set chart data
    data = [
      {
        value: this.item.additionalsData.metrics.bouncedHard,
        color: "#ffca28",
        highlight: "#ffca28",
        label: window.lang.reboundHard,
      },
      {
        value: this.item.additionalsData.metrics.bouncedSoft,
        color: "#ffa426",
        highlight: "#ffa426",
        label: window.lang.reboundSoft,
      },
      {
        value:
          this.item.additionalsData.metrics.sent -
          this.item.additionalsData.metrics.bouncedHard -
          this.item.additionalsData.metrics.bouncedSoft,
        color: "#F5F5F5",
        highlight: "#F5F5F5",
        label: window.lang.delivered,
      },
    ];

    // Draw chart
    this.createPieChart(target, data);
  },

  // Links
  linkToActivity: function (e) {
    e.preventDefault();
    const linkTo = $(e.currentTarget).data("link");
    const version = this.options.version;
    if (version) {
      app.router.navigateTo(
        `metrics/${this.item.get("id")}/activity?filter.type=${linkTo}&filter.version=${version.toLowerCase()}`
      );
    } else {
      app.router.navigateTo(`metrics/${this.item.get("id")}/activity?filter.type=${linkTo}`);
    }
  },

  switchVersion: function (e) {
    const version = e.currentTarget.value;
    if (version) {
      app.router.navigateTo(`metrics/${this.item.get("id")}?filters.version=${version}`);
    } else {
      app.router.navigateTo(`metrics/${this.item.get("id")}`);
    }
  },
});
