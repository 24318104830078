var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n  <div class='check-cell'>\n    <input id='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='checkbox' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-action='check'>\n    <label for='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='iconic "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"gender") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":5,"column":44},"end":{"line":6,"column":42}}})) != null ? stack1 : "")
    + "'>\n      <span class='mini-avatar'></span>\n    </label>\n  </div>\n  <div class='main-cell'>\n    <div class=\"name\">\n      <a href='/contacts/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "\n      </a>\n      <p>\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + "\n        "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n      </p>\n    </div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"additionalsData"))) && lookupProperty(stack1,"columns")),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":21,"column":2},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + "  <div class='info-cell'>\n"
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),"ACTIVE",{"name":"is","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.program(13, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":31,"column":4},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"gender") : stack1),"\n      M",{"name":"is","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":5,"column":69},"end":{"line":6,"column":35}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "male";
},"5":function(container,depth0,helpers,partials,data) {
    return "female";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../helpers/handlebars/isNot.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"email",(depth0 != null ? lookupProperty(depth0,"prop") : depth0),{"name":"isNot","hash":{},"fn":container.program(8, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":22,"column":2},"end":{"line":28,"column":12}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shown") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":23,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "  <div class='custom-cell'>\n    "
    + ((stack1 = __default(require("../../../helpers/handlebars/getContactColumn.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,blockParams[3][0],{"name":"getContactColumn","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":39}}})) != null ? stack1 : "")
    + "\n  </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div data-quality='"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"quality") : stack1), depth0))
    + "' class='stars rating-"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"rating") : stack1), depth0))
    + "'></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class='contact-state'>\n      "
    + container.escapeExpression(__default(require("../../../helpers/handlebars/translate.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"contacts"))) && lookupProperty(stack1,"status")),((stack1 = (depth0 != null ? lookupProperty(depth0,"attributes") : depth0)) != null ? lookupProperty(stack1,"state") : stack1),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":63}}}))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 2, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useBlockParams":true});