import { EditView } from "@/core/magnet";
import app from "@/app";
import Handlebars from "handlebars";

export default EditView.extend({
  module: "integrations",
  createInstance: false,

  beforeRender() {
    if (!app.session) {
      this.noSessionAuth();
      return;
    }

    if (this.trayRedirect()) return;

    // Hay sesión, paso info de auth a vista de integracion
    app.router.current = app.instances.get("integrations", "edit");
    app.router.current.render(this.options);
    app.router.updateRoute(`integrations/${this.options.id}`);
  },

  noSessionAuth() {
    const integration = new app.models.integrations({ id: this.options.id });

    if (this.options.id === "shopify" && !this.options.code) {
      // viene desde shopify sin authorization code, redirect a shop para pedir autorizacion
      const shop = this.options.shop.replace(".myshopify.com", "");

      $.get(`/data/integrations-${app.lang}.json?_=${Date.now()}`).done((res) => {
        const redirect = res.find((i) => i.id === "shopify").redirect[window.config.environment];
        const template = Handlebars.compile(redirect);

        const url = template({
          appUrl: window.location.origin,
          account: "",
          fields: { shopId: shop },
        });
        window.location.href = url;
      });

      return;
    }

    if (this.options.id === "jumpseller" && !this.options.code) {
      // viene desde jumpseller sin authorization code, redirect a shop para pedir autorizacion

      $.get(`/data/integrations-${app.lang}.json?_=${Date.now()}`).done((res) => {
        const redirect = res.find((i) => i.id === "jumpseller").redirect[window.config.environment];
        const template = Handlebars.compile(redirect);

        const url = template({
          appUrl: window.location.origin,
          account: "",
        });
        window.location.href = url;
      });

      return;
    }

    if (this.trayRedirect()) return;

    const preinitData = { ...this.options, id: undefined };
    if (this.options.id === "jumpseller") {
      preinitData.redirect_uri = window.location.href.split("?")[0];
    } else if (this.options.id === "tray") {
      preinitData.code = this.options.code;
      preinitData.shopUrl = this.options.api_address;
    }

    // shopify o tiendanube con authorization code en las options

    if (this?.options?.id === "tiendanube" && this?.options?.code) {
      app.router.navigateTo(`/login/tiendanube?auth_code=${this?.options?.code}`, { trigger: true });
      return;
    }

    // Preinit shopify, woocommerce
    integration
      .preinit(preinitData)
      .done((res) => {
        res = res.data;

        window.localStorage.setItem("preinit", JSON.stringify({ ...res, integration: this.options.id }));

        app.router.current = app.instances.singleton("signup", "index");
        app.router.current.render();
        app.router.updateRoute("signup");

        app.router.current.displayMessage(window.lang.integrations.activationPreinit, 5000);
      })
      .fail(() => {
        app.router.current = app.instances.singleton("signup", "index");
        app.router.current.render();
        app.router.updateRoute("signup");

        app.router.current.displayMessage(window.lang.integrations.activationFail, 10000, "error");
      });
  },

  trayRedirect() {
    if (this.options.id === "tray" && this.options.url) {
      // viene desde tray con parametro url, redirect a shop para pedir autorizacion, con o sin sesion
      $.get(`/data/integrations-${app.lang}.json?_=${Date.now()}`).done((res) => {
        const redirect = res.find((i) => i.id === "tray").redirect[window.config.environment];
        const template = Handlebars.compile(redirect);

        const url = template({
          fields: {
            baseUrl: this.options.url,
          },
          appUrl: window.location.origin,
          account: "",
        });
        window.location.href = url;
      });

      return true;
    }
  },
});
