<!--
TODO:
- disabled no deberia poner en disabled el button, no es bueno para la accesibildad. Pero deberia evitarse que el boton mande eventos
- mejorar estado loading
- responsive: ancho completo en sm?
- useThrottle para el click, asi no toma doble clicks
-->

<template>
  <button
    ref="simpleButtonRef"
    type="button"
    :class="buttonClass"
    :disabled="disabled"
    :aria-disabled="disabled"
    @click="onClick"
  >
    <span v-if="!!$slots.leading && !loading" aria-hidden="true" :class="leadingIconClass">
      <slot name="leading"></slot>
    </span>
    <LoadingSpinner v-if="!!$slots.leading && loading" :class="leadingIconClass" aria-hidden="true" />
    <slot>{{ modelValue }}</slot>

    <span v-if="!!$slots.trailing && !loading" aria-hidden="true" :class="trailingIconClass">
      <slot name="trailing"></slot>
    </span>
    <LoadingSpinner v-if="!!$slots.trailing && loading" :class="trailingIconClass" aria-hidden="true" />
    <!-- <div v-if="loading" class="absolute left-0 right-0 bottom-0 h-1 bg-red-200 animate-pulse" /> -->
  </button>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect } from "vue";

// Components
import LoadingSpinner from "./LoadingSpinner.vue";

// Composables
import { useTooltip } from "@composables/tooltips";

// Types
import { TippyOptions } from "vue-tippy";

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    disabled?: boolean;
    loading?: boolean;
    tooltip?: string;
    showTooltip?: boolean;
    selected?: boolean;
    tooltipProps?: TippyOptions;
    size?: "min" | "small" | "base";
    theme?:
      | "primary"
      | "primary-light"
      | "primary-clean"
      | "primary-clean-2"
      | "secondary"
      | "secondary-light"
      | "secondary-text"
      | "white"
      | "yellow"
      | "yellow-clean"
      | "danger"
      | "danger-alter"
      | "header-primary"
      | "header-accent"
      | "green-lime"
      | "header-sky";
  }>(),
  {
    modelValue: undefined,
    disabled: false,
    loading: false,
    tooltip: undefined,
    showTooltip: true,
    selected: false,
    tooltipProps: undefined,
    size: "base",
    theme: "primary",
  },
);

const emit = defineEmits<{
  click: [MouseEvent];
}>();

const slots = defineSlots<{
  leading?: () => any;
  default?: () => any;
  trailing?: () => any;
}>();

const onClick = (event: MouseEvent) => {
  if (props.loading) return;

  emit("click", event);
};

const simpleButtonRef = ref();
const tooltip = useTooltip(
  simpleButtonRef,
  computed(() => props.tooltip),
  {
    placement: "bottom",
    popperOptions: {
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            altAxis: true,
            tether: false,
            boundary: "clippingParents",
          },
        },
        {
          name: "flip",
          options: {
            allowedAutoPlacements: ["bottom", "top", "bottom-end", "top-end"],
            fallbackPlacements: ["bottom", "top", "bottom-end", "top-end"],
            altBoundary: true,
          },
        },
      ],
    },
    ...props.tooltipProps,
  },
);
watchEffect(() => {
  if (props.showTooltip) {
    tooltip.enable();
  } else {
    tooltip.disable();
  }
});

const buttonClass = computed(() => {
  const classes: Array<string> = [];
  classes.push(
    "relative overflow-hidden inline-flex items-center justify-center border rounded-md font-medium focus:outline-none",
  );

  if (props.selected) {
    classes.push("outline-none");
  }

  if (props.size === "min") {
    classes.push("text-base sm:text-sm leading-4");
  } else if (props.size === "small") {
    classes.push("px-3 py-1.5 text-base sm:text-sm leading-4");
  } else if (props.size === "base") {
    classes.push("px-4 py-2 text-base sm:text-sm");
  }

  if (props.theme === "primary") {
    classes.push("border-transparent shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      classes.push("text-white bg-sky-500 hover:bg-sky-700");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "primary-light") {
    classes.push("border-transparent shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-sky-300");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-300");
    }
    if (!props.disabled) {
      classes.push("text-white bg-sky-400 hover:bg-sky-600");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "primary-clean") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      if (props.size !== "min") {
        classes.push("hover:bg-sky-100");
      }
      classes.push("text-sky-700  hover:text-sky-800");
    } else {
      classes.push("text-gray-50");
    }
  } else if (props.theme === "primary-clean-2") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      if (props.size !== "min") {
        classes.push("bg-sky-100 hover:bg-sky-200");
      }
      classes.push("text-sky-700  hover:text-sky-800");
    } else {
      classes.push("text-gray-50");
    }
  } else if (props.theme === "secondary") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      classes.push("text-sky-700 bg-sky-200 hover:bg-sky-300");
    } else {
      classes.push("text-gray-400 bg-gray-100");
    }
  } else if (props.theme === "secondary-light") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-sky-300");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-300");
    }
    if (!props.disabled) {
      classes.push("text-sky-500 bg-sky-100 hover:bg-sky-200");
    } else {
      classes.push("text-gray-400 bg-gray-100");
    }
  } else if (props.theme === "secondary-text") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      classes.push("text-sky-700");
    } else {
      classes.push("text-gray-400");
    }
  } else if (props.theme === "white") {
    classes.push("shadow-sm  focus:ring-2 focus:ring-offset-2 focus:ring-sky-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-sky-400");
    }
    if (!props.disabled) {
      classes.push("border-gray-300 text-gray-700 bg-white hover:bg-gray-50");
    } else {
      classes.push("border-gray-100 text-gray-400 bg-gray-100");
    }
  } else if (props.theme === "danger") {
    classes.push("border-transparent shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-red-400");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-red-400");
    }
    if (!props.disabled) {
      classes.push("text-white bg-red-600 hover:bg-red-700");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "danger-alter") {
    classes.push("border-transparent shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-red-400");
    if (!props.disabled) {
      classes.push("text-red-700 bg-red-100 hover:bg-red-200");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "yellow") {
    classes.push("border-transparent shadow-sm focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-yellow-300");
    }
    if (!props.disabled) {
      classes.push("text-white bg-yellow-400 hover:bg-yellow-500");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "yellow-clean") {
    classes.push("border-transparent focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500");
    if (props.selected) {
      classes.push("ring-2 ring-offset-2 ring-yellow-500");
    }
    if (!props.disabled) {
      classes.push("text-yellow-700 hover:bg-yellow-100");
    } else {
      classes.push("text-gray-50");
    }
  } else if (props.theme === "header-primary") {
    classes.push(
      "border-gray-500 shadow-sm focus:ring-2 focus:ring-offset-gray-600 focus:outline-none focus:ring-offset-2 focus:ring-gray-500",
    );
    if (props.selected) {
      classes.push("ring-2 ring-offset-gray-600 outline-none ring-offset-2 ring-gray-500");
    }
    if (!props.disabled) {
      classes.push("text-gray-100 bg-gray-600 hover:border-gray-500 hover:bg-gray-700");
    } else {
      classes.push("text-gray-400 bg-gray-500");
    }
  } else if (props.theme === "header-accent") {
    classes.push(
      "border-transparent shadow-sm focus:ring-offset-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400",
    );
    if (props.selected) {
      classes.push("ring-offset-gray-600 outline-none ring-2 ring-offset-2 ring-yellow-400");
    }
    if (!props.disabled) {
      classes.push("text-gray-700 bg-yellow-400 hover:bg-yellow-500");
    } else {
      classes.push("text-gray-50 bg-gray-300");
    }
  } else if (props.theme === "green-lime") {
    classes.push(
      "border-transparent shadow-sm focus:ring-offset-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500",
    );
    if (props.selected) {
      classes.push("ring-offset-gray-600 outline-none ring-2 ring-offset-2 ring-lime-500");
    }
    if (!props.disabled) {
      classes.push("text-gray-700 bg-lime-300 hover:bg-lime-400");
    } else {
      classes.push("text-gray-400 bg-gray-500");
    }
  } else if (props.theme === "header-sky") {
    classes.push(
      "border-transparent shadow-sm focus:ring-offset-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-300",
    );
    if (props.selected) {
      classes.push("ring-offset-gray-600 outline-none ring-2 ring-offset-2 ring-sky-300");
    }
    if (!props.disabled) {
      classes.push("text-gray-700 bg-sky-300 hover:bg-sky-400");
    } else {
      classes.push("text-gray-400 bg-gray-500");
    }
  }

  if (props.disabled) {
    classes.push("cursor-default");
  }

  return classes.join(" ");
});

const leadingIconClass = computed(() => {
  const classes: Array<string> = [];
  if (props.size === "small") {
    classes.push("h-5 w-5");
    if (slots.default || props.modelValue) {
      classes.push("-ml-0.5 mr-2");
    }
  } else {
    classes.push("h-5 w-5");
    if (slots.default || props.modelValue) {
      classes.push("-ml-1 mr-2");
    }
  }
  if (props.theme === "white" && !props.disabled) classes.push("text-sky-500 hover:text-sky-600");

  return classes;
});

const trailingIconClass = computed(() => {
  const classes: Array<string> = [];
  if (props.size === "small") {
    classes.push("h-5 w-5");
    if (slots.default || props.modelValue) {
      classes.push("ml-2 -mr-0.5");
    }
  } else {
    classes.push("h-5 w-5");
    if (slots.default || props.modelValue) {
      classes.push("ml-2 -mr-1");
    }
  }
  if (props.theme === "white" && !props.disabled) classes.push("text-sky-500 hover:text-sky-600");

  return classes;
});
</script>
