// Translates a term
export default function (dictionary, term) {
  var text = "";
  if (dictionary !== undefined /*&& dictionary[term] !== undefined*/) {
    text =
      dictionary[term] ||
      dictionary[term.toLowerCase()] ||
      dictionary[term.toUpperCase()];
  }
  return text;
}
