var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attributes") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":82,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div data-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n  <div class='check-cell'>\n    <input id='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' type='checkbox' value='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' data-action='check'>\n    <label for='check-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class='iconic'></label>\n  </div>\n  <div class='main-cell'>\n    <div class=\"name\">\n      <a href='/automations/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "'>\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n      </a>\n      <span class='list-tags'>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"tags") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "      </span>\n      <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"comments") : depth0), depth0))
    + "</p>\n    </div>\n  </div>\n  <div class='info-cell'>\n    <strong>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"triggered") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":29,"column":39}}}))
    + "\n    </strong>\n    <small>\n      "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"triggered")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":32,"column":52}}}))
    + "\n    </small>\n  </div>\n  <div class='info-cell'>\n    <strong>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":37,"column":36}}}))
    + "\n    </strong>\n    <small>\n      "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"active")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":40,"column":6},"end":{"line":40,"column":49}}}))
    + "\n    </small>\n  </div>\n  <div class='info-cell'>\n    <strong>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"completed") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":45,"column":6},"end":{"line":45,"column":39}}}))
    + "\n    </strong>\n    <small>\n      "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"completed")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":48,"column":52}}}))
    + "\n    </small>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"attributes"))) && lookupProperty(stack1,"isMasterUser")),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":2},"end":{"line":68,"column":9}}})) != null ? stack1 : "")
    + "  <div class='info-cell'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"enabled") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":4},"end":{"line":79,"column":15}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a data-params='{\"key\": \"filter.tags\", \"value\": "
    + alias2(__default(require("../../../helpers/handlebars/stringify.js")).call(alias1,depth0,{"name":"stringify","hash":{},"data":data,"loc":{"start":{"line":18,"column":56},"end":{"line":18,"column":71}}}))
    + " }'>\n          <span\n            class='label "
    + ((stack1 = __default(require("../../../helpers/handlebars/is.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"params"))) && lookupProperty(stack1,"filter"))) && lookupProperty(stack1,"tags")),depth0,{"name":"is","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":20,"column":25},"end":{"line":20,"column":107}}})) != null ? stack1 : "")
    + "'>"
    + alias2(container.lambda(depth0, depth0))
    + "</span>\n        </a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " label-default ";
},"6":function(container,depth0,helpers,partials,data) {
    return " label-outline ";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class='info-cell'>\n    <strong>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"filtered") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":54,"column":6},"end":{"line":54,"column":38}}}))
    + "\n    </strong>\n    <small>\n      filtrados\n    </small>\n  </div>\n  <div class='info-cell'>\n    <strong>\n      "
    + alias2(__default(require("../../../helpers/handlebars/formatInteger.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"stats") : depth0)) != null ? lookupProperty(stack1,"failed") : stack1),{"name":"formatInteger","hash":{},"data":data,"loc":{"start":{"line":62,"column":6},"end":{"line":62,"column":36}}}))
    + "\n    </strong>\n    <small>\n      "
    + alias2(__default(require("../../../helpers/handlebars/lowercase.js")).call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"failed")),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":65,"column":6},"end":{"line":65,"column":49}}}))
    + "\n    </small>\n  </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <strong style=\"color: #aacc42\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"enabled")), depth0))
    + "\n    </strong>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <strong style=\"color: #9b9b9b\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"automations"))) && lookupProperty(stack1,"disabled")), depth0))
    + "\n    </strong>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":83,"column":9}}})) != null ? stack1 : "");
},"useData":true});