<template>
  <div class="h-full">
    <Table
      :sort-by="sortBy"
      :columns="columns"
      :rows="filteredRows"
      :selected-rows="selectedRows"
      :skeleton="skeletonTable"
      :skeleton-count="5"
      :selectable="campaignsUpdate"
      class="ml-6 h-full overflow-x-hidden pb-16"
      @update:sort-by="updateSortBy"
      @update:selected-rows="updateSelectedRows"
    >
      <template #name="{ row }">
        <NameCell v-if="row" :row="row" />
      </template>
      <template #name-skeleton>
        <div class="my-3 ml-4 animate-pulse space-y-2 py-2">
          <div class="w-72 rounded bg-sky-50 py-2" />
          <div class="w-96 rounded bg-gray-50 py-2" />
        </div>
      </template>
      <template #convertedAmount="{ row }">
        <div class="flex flex-col space-y-1 pl-4">
          <span class="text-sm text-gray-500">
            {{
              row.data.stats?.converted_amount
                ? n(Number(row.data.stats.converted_amount) ?? 0, "currencyNoCents")
                : "-"
            }}
          </span>
          <span
            v-if="!!row.data.stats?.converted && Number(row.data.stats?.converted) > 0"
            class="text-xs text-gray-400"
          >
            {{
              t(
                "conversions",
                { amount: n(Number(row.data.stats?.converted), "decimal") },
                { plural: Number(row.data.stats?.converted) },
              )
            }}
          </span>
        </div>
      </template>
      <template #defaultCell="{ item }">
        <span class="text-sm text-gray-500">
          {{ n(Number(item) ?? 0, "decimal") }}
        </span>
      </template>
      <template #defaultCell-skeleton>
        <div class="my-4 ml-auto h-4 w-10 rounded-xl bg-gray-50" />
      </template>
      <template #options="{ row }">
        <div class="flex justify-center">
          <DropDownButton
            :small="true"
            :navigation="row.data.enabled ? listOptionsActive : listOptionsPaused"
            position="bottom-end"
            theme="transparent-white"
            :auto-placements="['bottom-end', 'left']"
            :class="[selectedRows?.length === 0 ? 'visible' : 'invisible']"
            @update:selected="(option) => handleListOption(option, row)"
          />
        </div>
      </template>
      <template #options-skeleton>
        <div class="mx-auto my-2 w-6 animate-pulse rounded bg-gray-50 py-3" />
      </template>
    </Table>
    <SelectionBar v-show="selectedRows && selectedRows.length > 0">
      <div class="flex items-center space-x-2">
        <template v-if="selectedRows?.length && selectedRows.length > 0">
          <span class="text-sm text-white">{{
            t(
              "selectedLists",
              { count: n(Number(selectedRows.length) ?? 0, "decimal"), total: n(Number(props.count) ?? 0, "decimal") },
              { plural: selectedRows.length },
            )
          }}</span>
        </template>
        <IconButton
          v-if="selectedRows?.length < props.count"
          :label="t('selectAllButton')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="selectAll"
        >
          <MultiSelectionIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
      <div class="flex space-x-4">
        <IconButton
          v-show="!allSelectedAreActive"
          :label="t('selected.start')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="activateAutomationBulk"
        >
          <PlayIcon class="h-5 w-5" />
        </IconButton>
        <IconButton
          v-show="!allSelectedArePaused"
          :label="t('selected.pause')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="pauseAutomationBulk"
        >
          <PauseIcon class="h-5 w-5" />
        </IconButton>
        <IconButton
          v-show="selectedRows.length === 1"
          :label="t('selected.duplicate')"
          size="min"
          theme="none"
          class="bg-gray-600 !py-0"
          @click="() => duplicateAutomation(selectedRows[0])"
        >
          <ClipboardDocumentListIcon class="h-6 w-6" />
        </IconButton>
        <IconButton
          :label="t('selected.delete')"
          size="min"
          theme="none"
          class="bg-gray-600"
          @click="openDeleteSelectedAlertModal"
        >
          <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
        </IconButton>
        <IconButton :label="t('closeButton')" size="min" theme="none" class="bg-gray-600" @click="clearSelected">
          <XIcon class="h-5 w-5 text-gray-400 hover:text-white" />
        </IconButton>
      </div>
    </SelectionBar>
    <ConfirmationModal
      id="deleteAlertModal"
      v-bind="deleteAlertModal"
      @accept="deleteAutomation"
      @cancel="closeDeleteListAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
    <ConfirmationModal
      id="deleteSelectedAlertModal"
      v-bind="deleteSelectedAlertModal"
      @accept="deleteSelected"
      @cancel="closeDeleteFontAlertModal"
      ><template #acceptButtonLeading>
        <TrashIcon class="h-5 w-5 text-red-200 hover:text-red-300" />
      </template>
    </ConfirmationModal>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, reactive, toRaw, computed } from "vue";

import type { ColumnsIds, AutomationsOptionsKeys, AutomationsRows, AutomationsRow } from "../../automations.types";
import { automationsToRows, getListOptions, automationToRow } from "../../automations.data";

// Components
import NameCell from "./components/NameCell.vue";
import type { SelectedValues, SelectedValueText } from "@molecules/FilterInput";
import Table from "@molecules/Table";
import type { SortColumn, Columns } from "@molecules/Table";
import ConfirmationModal from "@molecules/ConfirmationModal.vue";
import type { ConfirmationModal as ConfirmationModalType } from "@molecules/ConfirmationModal.vue";
import DropDownButton from "@molecules/DropDownButton";
import type { DataItem as DropDownDataItem } from "@molecules/DropDownButton";
import IconButton from "@atoms/IconButton.vue";
import SelectionBar from "@atoms/SelectionBar";

// Icon
import { TrashIcon, XIcon, PauseIcon, PlayIcon } from "@heroicons/vue/outline";
import MultiSelectionIcon from "@/vue/components/tokens/icons/MultiSelectionIcon.vue";
import ClipboardDocumentListIcon from "@/vue/components/tokens/icons/ClipboardDocumentListIcon.vue";

// Composables
import { useNotifications } from "@composables/notifications";
import { formatTextToSearch } from "@helpers/formatters";

// I18n
import { useI18n } from "vue-i18n";

// Service
import { useAutomations } from "@api/modules/automations/automations";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore, useAlertStore, useOnboardingStore } from "@store";

// Domain
import type { Automation } from "@domain/automations";

const { t, n } = useI18n();
const sessionStore = useSessionStore();
const { plan, isInTrial } = storeToRefs(sessionStore);
const automationsAPI = useAutomations();
const { notify } = useNotifications();
const alertStore = useAlertStore();
const onboardingStore = useOnboardingStore();

const campaignsUpdate = sessionStore.hasPermission("campaigns:update");
const campaignsDelete = sessionStore.hasPermission("campaigns:delete");

const props = withDefaults(
  defineProps<{
    state?: "all" | "active" | "inactive";
    search?: SelectedValues;
    count: number;
    rows: AutomationsRows;
    // from?: Date;
    // to?: Date;
    selectedPresetId?: "lastDay" | "last7d" | "last30d" | "last90d";
    columns?: Columns<ColumnsIds | "options", ColumnsIds>;
    sortBy: SortColumn<ColumnsIds>;
  }>(),
  {
    state: "all",
    search: () => [],
    rows: () => [],
    from: undefined,
    to: undefined,
    columns: () => [],
  },
);

const emit = defineEmits<{
  "update:sortBy": [SortColumn<ColumnsIds>];
  "update:count": [number];
  "update:skeleton": [boolean];
  "update:rows": [AutomationsRows];
  "update:firstLoad": [boolean];
  "update:loading": [boolean];
}>();

const updateRows = (newRows: AutomationsRows) => {
  emit("update:rows", newRows);
};

const updateSortBy = (sortBy: SortColumn<ColumnsIds | "options">) => {
  if (sortBy.columnId === "options") return;

  emit("update:sortBy", sortBy as SortColumn<ColumnsIds>);
};

// Table
const skeletonTable = ref(false);
const filteredRows = ref<AutomationsRows>([]);

watch([() => props.search, () => props.rows], () => {
  const getSearch = props.search.find((search) => search.filterId === "automations") as SelectedValueText;
  if (!getSearch?.value) {
    filteredRows.value = toRaw(props.rows);
    updateTotalCount(filteredRows.value.length);

    return;
  }

  filteredRows.value = props.rows.filter((r) => {
    return formatTextToSearch(r.content?.name ?? "").includes(formatTextToSearch(getSearch.value));
  });
  updateTotalCount(filteredRows.value.length);
});

const updateTotalCount = (newCount: number) => {
  emit("update:count", newCount);
};
const selectedRows = ref<AutomationsRows>([]);
const allSelectedAreActive = computed<boolean>(() => {
  return selectedRows.value.every((row) => row.data.enabled);
});
const allSelectedArePaused = computed<boolean>(() => {
  return selectedRows.value.every((row) => !row.data.enabled);
});
const selectedRow = ref<AutomationsRow>();
const fetchlimit = 100000;
const updateSelectedRows = (newRows: AutomationsRows) => {
  selectedRows.value = newRows;
};
const clearSelected = () => {
  selectedRows.value = [];
};
const selectAll = () => {
  selectedRows.value = filteredRows.value;
};

const removeRows = (rowsToDelete: AutomationsRows) => {
  const filteredRows = toRaw(props.rows).filter((row) => {
    return !rowsToDelete.some((r) => r.id === row.id);
  });
  updateRows(filteredRows);
};

const removeRow = (rowToDelete: AutomationsRow) => {
  const filteredRows = toRaw(props.rows).filter((row) => {
    return row.id !== rowToDelete.id;
  });

  updateRows(filteredRows);
};

const getRelativeDate = (preset: "lastDay" | "last7d" | "last30d" | "last90d" | undefined) => {
  if (preset === "lastDay") return "now-1d";
  if (preset === "last7d") return "now-1w";
  if (preset === "last30d") return "now-1M";
  if (preset === "last90d") return "now-3M";
  return undefined;
};

const fetchAutomations = async () => {
  emit("update:loading", true);
  updateRows([]);

  const automations = await automationsAPI.get({
    sortBy: props.sortBy?.columnId,
    sortDir: props.sortBy?.sort === "DESC" ? "desc" : "asc",
    // search: getOptinsSearch ? getOptinsSearch.value : undefined,
    enabled: props.state === undefined || props.state === "all" ? undefined : props.state === "active",
    // from: props.from,
    // to: props.to,
    gtrel: getRelativeDate(props.selectedPresetId),
    limit: fetchlimit,
  });

  updateRows([...props.rows, ...automationsToRows(automations.value)]);
  updateTotalCount(automations.total ?? 0);
  emit("update:loading", false);
};

const deleteAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteAlert.confirmationTitle"),
  message: t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" }),
  acceptText: t("deleteAlert.confirmButton"),
  cancelText: t("deleteAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteListAlertModal = () => {
  deleteAlertModal.open = false;
};
const openDeleteAlertModal = () => {
  deleteAlertModal.message = t("deleteAlert.confirmationMessage", { name: selectedRow.value?.data?.name ?? "" });
  deleteAlertModal.open = true;
};

const deleteAutomation = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    deleteAlertModal.acceptLoading = true;
    await automationsAPI.delete({ id });
    deleteAlertModal.acceptLoading = false;
  } finally {
    updateTotalCount(props.count - 1);
    removeRow(rowToDelete);
    if (filteredRows.value.length < fetchlimit && props.count >= fetchlimit) {
      await fetchAutomations();
    }
    closeDeleteListAlertModal();
    selectedRow.value = undefined;
    notify({
      title: t("notifications.delete.title"),
      text: t("notifications.delete.text"),
      theme: "success",
    });
  }
};

const deleteBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const deletePromises = selectedRows.value?.map((selected) => automationsAPI.delete({ id: selected.id }));
    await Promise.allSettled(deletePromises);
  } finally {
    notify({
      title: t("notifications.deleteBulk.title"),
      text: t("notifications.deleteBulk.text"),
      theme: "success",
    });
    updateTotalCount(props.count - selectedRows.value.length);
    removeRows(selectedRows.value);
    if (filteredRows.value.length < fetchlimit && props.count >= fetchlimit) {
      await fetchAutomations();
    }
    clearSelected();
  }
};

const pauseAutomation = async () => {
  const rowToDelete = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !rowToDelete) return;

  try {
    const automation = await automationsAPI.pause({ id });

    const automationIndex = props.rows.findIndex((a) => a.data.id === automation.id);
    const automationRow = props.rows[automationIndex];
    if (!automationRow) return;
    automationRow.data.enabled = !automationRow?.data.enabled;
    if (props.state === "active") {
      props.rows.splice(automationIndex, 1);
    } else {
      props.rows.splice(automationIndex, 1, automationRow);
    }

    updateRows([...props.rows]);
    notify({
      title: t("notifications.pause.title"),
      text: t("notifications.pause.text"),
      theme: "success",
    });
  } catch (e) {
    return;
  } finally {
    selectedRow.value = undefined;
  }
};

const pauseAutomationBulk = async () => {
  if (!selectedRows.value) return;
  try {
    const pausePromises = selectedRows.value.reduce(
      (promises, row) => {
        if (row.data.enabled) {
          promises.push(automationsAPI.pause({ id: row.id }));
        }

        return promises;
      },
      [] as Array<Promise<Automation>>,
    );
    const automations = await Promise.all(pausePromises);

    automations.forEach((automation) => {
      const automationIndex = props.rows.findIndex((a) => a.data.id === automation.id);
      const automationRow = props.rows[automationIndex];
      if (!automationRow) return;
      automationRow.data.enabled = !automationRow?.data.enabled;
      if (props.state === "active") {
        props.rows.splice(automationIndex, 1);
      } else {
        props.rows.splice(automationIndex, 1, automationRow);
      }
    });

    updateRows([...props.rows]);

    notify({
      title: t("notifications.pauses.title"),
      text: t("notifications.pauses.text"),
      theme: "success",
    });
  } catch (e) {
    return;
  } finally {
    clearSelected();
  }
};

const activateAutomation = async () => {
  const row = selectedRow.value;
  const id = selectedRow.value?.data?.id?.toString();
  if (!id || !row) return;

  if (
    selectedRow.value?.data.options.restricted &&
    !isInTrial.value &&
    (plan.value?.state.includes("BLOCKED") || (plan.value?.type !== "MONTHLY" && !plan.value?.type.includes("CHILD")))
  ) {
    notify({
      title: t("notifications.activatePermissionError.title"),
      text: t("notifications.activatePermissionError.text"),
      theme: "error",
    });
    return;
  }

  try {
    const automation = await automationsAPI.activate({ id });
    notify({
      title: t("notifications.activate.title"),
      text: t("notifications.activate.text"),
      theme: "success",
    });

    const eventPromises =
      automation?.options?.types?.map((type) => {
        return onboardingStore.submitUserEvent({
          action: `automation.${type}.enabled`,
          data: {
            id: automation.id,
            name: automation.name,
          },
        });
      }) ?? [];

    await Promise.allSettled([
      onboardingStore.submitUserEvent({
        action: "automation.enabled",
        data: {
          id: automation.id,
          name: automation.name,
        },
      }),
      ...eventPromises,
    ]);

    const automationIndex = props.rows.findIndex((a) => a.data.id === automation.id);
    const automationRow = props.rows[automationIndex];
    if (!automationRow) return;
    automationRow.data.enabled = !automationRow?.data.enabled;
    if (props.state === "inactive") {
      props.rows.splice(automationIndex, 1);
    } else {
      props.rows.splice(automationIndex, 1, automationRow);
    }

    updateRows([...props.rows]);
  } catch (e) {
    notify({
      title: t("notifications.activateError.title"),
      text: t("notifications.activateError.text"),
      theme: "error",
      timeout: 8000,
    });
    return;
  } finally {
    selectedRow.value = undefined;
  }
};

const activateAutomationBulk = async () => {
  if (!selectedRows.value) return;

  const automationsRows = selectedRows.value.filter((row) => {
    if (
      row?.data.options.restricted &&
      !isInTrial.value &&
      (plan.value?.state.includes("BLOCKED") || (plan.value?.type !== "MONTHLY" && !plan.value?.type.includes("CHILD")))
    ) {
      return false;
    }

    return true;
  });

  const automationsFiltered = automationsRows.length < selectedRows.value.length;

  try {
    const pausePromises = automationsRows.reduce(
      (promises, row) => {
        if (!row.data.enabled) {
          promises.push(automationsAPI.activate({ id: row.id }));
        }

        return promises;
      },
      [] as Array<Promise<Automation>>,
    );
    const automations = await Promise.all(pausePromises);

    const eventPromises = automations.reduce((promises, autom) => {
      promises = [
        ...promises,
        ...(autom?.options?.types?.map((type) => {
          return onboardingStore.submitUserEvent({
            action: `automation.${type}.enabled`,
            data: {
              id: autom.id,
              name: autom.name,
            },
          });
        }) ?? []),
      ];
      return promises;
    }, [] as any);

    await Promise.allSettled([
      ...automations.map((autom) =>
        onboardingStore.submitUserEvent({
          action: "automation.enabled",
          data: {
            id: autom.id,
            name: autom.name,
          },
        }),
      ),
      ...eventPromises,
    ]);

    automations.forEach((automation) => {
      const automationIndex = props.rows.findIndex((a) => a.data.id === automation.id);
      const automationRow = props.rows[automationIndex];
      if (!automationRow) return;
      automationRow.data.enabled = !automationRow?.data.enabled;
      if (props.state === "inactive") {
        props.rows.splice(automationIndex, 1);
      } else {
        props.rows.splice(automationIndex, 1, automationRow);
      }
    });

    updateRows([...props.rows]);

    if (automationsFiltered) {
      notify({
        title: t("notifications.activatePermissionsBulkError.title"),
        text: t("notifications.activatePermissionsBulkError.text"),
        theme: "error",
        timeout: 8000,
      });
    } else {
      notify({
        title: t("notifications.activateSelected.title"),
        text: t("notifications.activateSelected.text"),
        theme: "success",
      });
    }
  } catch (e) {
    notify({
      title: t("notifications.activateMultipleError.title"),
      text: t("notifications.activateMultipleError.text"),
      theme: "error",
      timeout: 8000,
    });
    return;
  } finally {
    clearSelected();
  }
};

const duplicateAutomation = async (row?: AutomationsRow) => {
  const rowToDuplicate = row ?? selectedRow.value;
  const id = rowToDuplicate?.data?.id?.toString();
  if (!id || !rowToDuplicate) return;

  try {
    const newAutomation = await automationsAPI.duplicate({ id });
    notify({
      title: t("notifications.duplicate.title"),
      text: t("notifications.duplicate.text"),
      theme: "success",
    });

    updateRows([automationToRow(newAutomation), ...toRaw(props.rows)]);

    updateTotalCount(props.count + 1);
  } catch (e) {
    return;
  } finally {
    selectedRow.value = undefined;
    selectedRows.value = [];
  }
};

const deleteSelectedAlertModal = reactive<ConfirmationModalType>({
  open: false,
  title: t("deleteSelectedAlert.confirmationTitle"),
  message: t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  ),
  acceptText: t("deleteSelectedAlert.confirmButton"),
  cancelText: t("deleteSelectedAlert.cancelButton"),
  acceptLoading: false,
  severity: "critical",
});

const closeDeleteFontAlertModal = () => {
  deleteSelectedAlertModal.open = false;
};
const openDeleteSelectedAlertModal = () => {
  deleteSelectedAlertModal.message = t(
    "deleteSelectedAlert.confirmationMessage",
    { count: selectedRows.value?.length },
    { plural: selectedRows.value?.length },
  );
  deleteSelectedAlertModal.open = true;
};

const deleteSelected = async () => {
  deleteSelectedAlertModal.acceptLoading = true;
  await deleteBulk();
  deleteSelectedAlertModal.acceptLoading = false;
  deleteSelectedAlertModal.open = false;
};

// Search
const listOptionsActive = getListOptions({ automationIsActive: true });
const listOptionsPaused = getListOptions({ automationIsActive: false });
const listOptionHandler: Record<AutomationsOptionsKeys, (row: AutomationsRow) => void> = {
  start: (row) => {
    selectedRow.value = row;
    activateAutomation();
  },
  pause: (row) => {
    selectedRow.value = row;
    pauseAutomation();
  },
  duplicate: (row) => {
    selectedRow.value = row;
    duplicateAutomation();
  },
  delete: (row) => {
    selectedRow.value = row;
    openDeleteAlertModal();
  },
};

const handleListOption = async (option: DropDownDataItem, row: AutomationsRow) => {
  if (!campaignsDelete && option.key === "delete") {
    alertStore.showPermissionDenied(["campaigns:delete"]);
    return;
  }

  if (!campaignsUpdate && option.key !== "export") {
    alertStore.showPermissionDenied(["campaigns:update"]);
    return;
  }

  await listOptionHandler[option.key]?.(row);
};

watch(
  [() => props.selectedPresetId, () => props.state, () => props.from, () => props.to, () => props.sortBy],
  async () => {
    updateRows([]);
    updateTotalCount(0);
    await fetchAutomations();
  },
);

onMounted(async () => {
  emit("update:firstLoad", true);
  emit("update:loading", true);

  await fetchAutomations();
  emit("update:firstLoad", false);
  emit("update:loading", false);
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "conversions": "{amount} ventas | {amount} venta | {amount} ventas",
    "selectedLists": "1 automation seleccionado de {total} | {count} automations seleccionados de {total}",
    "closeButton": "Cancelar",
    "selectAllButton": "Seleccionar todo",
    "selected": {
      "start": "Activar",
      "duplicate": "Duplicar",
      "pause": "Pausar",
      "delete": "Eliminar"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Eliminar automation",
      "confirmationMessage": "Se eliminará 1 automation seleccionado. ¿Deseas continuar? | Se eliminarán {count} automations seleccionados. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Eliminar automation",
      "confirmationMessage": "Se eliminará el automation {name}. ¿Deseas continuar?",
      "confirmButton": "Eliminar",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "activate": {
        "title": "Automation activado",
        "text": "El automation se activó correctamente"
      },
      "activateError": {
        "title": "No se pudo activar el automation",
        "text": "Verifica que no existan problemas de auditoría en el contenido y que el remitente y asunto esten completos."
      },
      "activatePermissionError": {
        "title": "No se pudo activar el automation",
        "text": "Este automation sólo está disponible para las suscripciones mensuales."
      },
      "activatePermissionsBulkError": {
        "title": "No se pudo activar uno o más automations",
        "text": "Algunos de los automations sólo están disponibles para las suscripciones mensuales."
      },
      "activateMultipleError": {
        "title": "No se pudo activar uno o más automations",
        "text": "Verifica que no existan problemas de auditoría en el contenido y que el remitente y asunto esten completos."
      },
      "activateSelected": {
        "title": "Automations activados",
        "text": "Los automations se activaron correctamente."
      },
      "delete": {
        "title": "Automation eliminado",
        "text": "El automation se eliminó correctamente."
      },
      "deleteBulk": {
        "title": "Automation eliminados",
        "text": "Los automations se eliminaron correctamente."
      },
      "pause": {
        "title": "Automation pausado",
        "text": "El automation se pausó correctamente."
      },
      "pauses": {
        "title": "Automations pausados",
        "text": "Los automations se pausaron correctamente."
      },
      "duplicate": {
        "title": "automation duplicado",
        "text": "El automation se duplicó correctamente."
      }
    },
    "listOptions": {
      "start": "Activar",
      "duplicate": "Duplicar",
      "pause": "Pausar",
      "delete": "Eliminar"
    }
  },
  "pt": {
    "conversions": "{amount} vendas | {amount} venda | {amount} vendas",
    "selectedLists": "1 automation selecionado de {total} | {count} automations selecionados de {total}",
    "closeButton": "Cancelar",
    "selectAllButton": "Selecionar tudo",
    "selected": {
      "start": "Ativar",
      "duplicate": "Duplicar",
      "pause": "Pausar",
      "delete": "Excluir"
    },
    "deleteSelectedAlert": {
      "confirmationTitle": "Excluir automation",
      "confirmationMessage": "Será excluído 1 automation selecionado. Deseja continuar? | Serão excluídos {count} automations selecionados. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "deleteAlert": {
      "confirmationTitle": "Excluir automation",
      "confirmationMessage": "O automation {name} será excluído. Deseja continuar?",
      "confirmButton": "Excluir",
      "cancelButton": "Cancelar"
    },
    "notifications": {
      "activate": {
        "title": "Automation ativado",
        "text": "O automation foi ativado com sucesso"
      },
      "activateError": {
        "title": "Não foi possível ativar o automation",
        "text": "Verifique se não há problemas de auditoria no conteúdo e se o remetente e o assunto estão completos."
      },
      "activateMultipleError": {
        "title": "Não foi possível ativar um ou mais automations",
        "text": "Verifique se não há problemas de auditoria no conteúdo e se o remetente e o assunto estão completos."
      },
      "activatePermissionError": {
        "title": "Não foi possível ativar a automação",
        "text": "Este automation somente está disponível para as assinaturas mensais."
      },
      "activatePermissionsBulkError": {
        "title": "Não foi possível ativar uma ou mais automações",
        "text": "Alguns dos automations só estão disponíveis para as assinaturas mensais."
      },
      "activateSelected": {
        "title": "Automations ativados",
        "text": "Os automations foram ativados com sucesso."
      },
      "delete": {
        "title": "Automation excluído",
        "text": "O automation foi excluído com sucesso."
      },
      "deleteBulk": {
        "title": "Automations excluídos",
        "text": "Os automations foram excluídos com sucesso."
      },
      "pause": {
        "title": "Automation pausado",
        "text": "O automation foi pausado com sucesso."
      },
      "pauses": {
        "title": "Automations pausados",
        "text": "Os automations foram pausados com sucesso."
      },
      "duplicate": {
        "title": "Automation duplicado",
        "text": "O automation foi duplicado com sucesso."
      }
    },
    "listOptions": {
      "start": "Ativar",
      "duplicate": "Duplicar",
      "pause": "Pausar",
      "delete": "Excluir"
    }
  }
}
</i18n>
