<template>
  <SearchModal
    v-model:search="searchAccount"
    :open="open"
    :loading="searchingAccounts"
    :static-items="staticItems"
    :static-item-label="t('parentAccount')"
    :recent="searchedAccounts"
    :recents-label="t('recentSearches')"
    :items="accountsItems"
    :items-label="session.isMasterUser ? t('accountSearches') : t('accounts')"
    async-search
    @update:open="updateOpen"
    @select="selectAccount"
  />
  <!-- :recent="accountsItems" -->
</template>

<script lang="ts" setup>
import { ref, onMounted, watch, computed } from "vue";

// Components
import SearchModal from "@molecules/SearchModal";

// Icons
import BuildingStorefrontSolid from "@tokens/icons/BuildingStorefrontSolid.vue";

// Type
import type { Items, Item } from "@molecules/SearchModal";

// Application
import { useLocalStorageApp } from "@application";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Utils
import { formatDate } from "@helpers/formatters";
import { differenceInDays } from "date-fns";
import { useI18n } from "vue-i18n";
import { useDebounceFn } from "@vueuse/core";

// Types
import { Accounts, Account } from "@domain/account";

// Services
import { useAccounts } from "@api/modules/accounts/accounts";

const { t } = useI18n();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);
const accountsAPI = useAccounts();
const localStorageApp = useLocalStorageApp();

withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

const emit = defineEmits<{
  "update:open": [boolean];
}>();

const updateOpen = (value: boolean) => {
  emit("update:open", value);
};

const accounts = ref<Accounts>([]);
const parentAccount = ref<Account>();
const searchedAccounts: Items = localStorageApp.get<Items>({ id: "perfit_accounts_selected" }) ?? [];

const searchAccount = ref<string>("");
const searchingAccounts = ref<boolean>(false);

const getDifference = (date: Date) => differenceInDays(new Date(), date);

const accountsItems = computed<Items>(() => {
  const filteredAccounts = accounts.value.filter((a) => {
    if (searchedAccounts.some((sc) => sc.id === a.code) && searchAccount.value === "") return false;
    if (session.value.account.code === a.code) return false;

    return a.code !== parentAccount.value?.code;
  });

  return filteredAccounts.map<Item>((item) => ({
    id: item.code,
    title: item.code,
    subtitle: item.name,
    name: item.name,
    icon: BuildingStorefrontSolid,
    content: [
      {
        key: "lastLogin",
        value: item.lastLogin
          ? t("lastDays", getDifference(new Date(item.lastLogin)), {
              plural: getDifference(new Date(item.lastLogin)),
            })
          : "-",
        data: {
          key: t("content.lastLogin"),
        },
      },
      {
        key: "created",
        value: item.created ? formatDate(new Date(item.created), "MMM d, y") : "-",
        data: {
          key: t("content.created"),
        },
      },
      {
        key: "lastUsage",
        value: item.lastUsage ? formatDate(new Date(item.lastUsage), "MMM d, y") : "-",
        data: {
          key: t("content.lastUsage"),
        },
      },
      {
        key: "country",
        value: t(`countries.${item.country}`),
        data: {
          key: t("content.country"),
        },
      },
    ],
  }));
});

const staticItems = computed<Items>(() => {
  if (!parentAccount.value && searchedAccounts.length === 0) return [];

  return [
    ...((parentAccount.value
      ? [
          {
            id: parentAccount.value.code,
            name: parentAccount.value.name,
            title: t("goToParentAccountText"),
            subtitle: `(${parentAccount.value.code})`,
            icon: BuildingStorefrontSolid,
            content: [
              {
                key: "lastLogin",
                value: t("lastDays", getDifference(new Date(parentAccount.value.lastLogin)), {
                  plural: getDifference(new Date(parentAccount.value.lastLogin)),
                }),
                data: {
                  key: t("content.lastLogin"),
                },
              },
              {
                key: "created",
                value: parentAccount.value.created
                  ? formatDate(new Date(parentAccount.value.created), "MMM d, y")
                  : "-",
                data: {
                  key: t("content.created"),
                },
              },
              {
                key: "lastUsage",
                value: parentAccount.value.lastUsage
                  ? formatDate(new Date(parentAccount.value.lastUsage), "MMM d, y")
                  : "-",
                data: {
                  key: t("content.lastUsage"),
                },
              },
              {
                key: "country",
                value: t(`countries.${parentAccount.value.country}`),
                data: {
                  key: t("content.country"),
                },
              },
            ],
          },
        ]
      : []) as Items),
  ];
});

const selectAccount = (account: Item) => {
  const duplicatedAccount = searchedAccounts.findIndex((c) => c.id === account.id);
  const formattedAccount = {
    ...account,
    title: account.id,
    subtitle: account.name,
  };
  const newSearchedAccounts = searchedAccounts;
  if (newSearchedAccounts?.length === 5 && duplicatedAccount === -1) {
    newSearchedAccounts.pop();
  }
  if (duplicatedAccount !== -1) {
    newSearchedAccounts.splice(duplicatedAccount, 1);
  }
  newSearchedAccounts.unshift(formattedAccount);

  localStorageApp.save({ id: "perfit_accounts_selected", value: newSearchedAccounts });
  window.app.router.changeAccount(formattedAccount.id);
};

const fetchParentAccount = async (account: string) => {
  const accountRes = await accountsAPI.getAccount({ account });

  parentAccount.value = accountRes;
};

const fetchAccounts = async (search?: string) => {
  const accountsRes = await accountsAPI.getAccounts({ limit: 100, q: search });

  accounts.value = accountsRes.value;
  searchingAccounts.value = false;
};

const searchAccounts = async (search?: string) => {
  const accountsRes = await accountsAPI.getAccounts({ limit: 100, q: search });

  accounts.value = accountsRes.value;
  searchingAccounts.value = false;
};

const fetchAccountsDebounce = useDebounceFn(searchAccounts, 500);

watch(searchAccount, () => {
  searchingAccounts.value = true;
  accounts.value = [];
  fetchAccountsDebounce(searchAccount.value);
});

onMounted(async () => {
  searchingAccounts.value = true;
  const promises = [fetchAccounts(searchAccount.value)];
  if (session.value?.account.parent) {
    promises.push(fetchParentAccount(session.value?.account.parent));
  }
  await Promise.all(promises);
  searchingAccounts.value = false;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "recentSearches": "Búsquedas recientes",
    "accountSearches": "Resultados de búsqueda",
    "accounts": "Cuentas",
    "goToParentAccountText": "Ir a cuenta principal",
    "parentAccount": "Cuenta principal",
    "lastDays": "Hoy | Ayer | Hace {n} dias",
    "content": {
      "country": "Pais",
      "lastLogin": "Último ingreso",
      "created": "Fecha de creación",
      "lastUsage": "Fecha de último envío"
    }
  },
  "pt": {
    "recentSearches": "Pesquisas recentes",
    "accountSearches": "Resultados da pesquisa",
    "accounts": "Contas",
    "parentAccount": "Conta principal",
    "goToParentAccountText": "Ir para conta principal",
    "lastDays": "Hoje | Ontem | Há {n} dias",
    "content": {
      "country": "País",
      "lastLogin": "Último acesso",
      "created": "Data de criação",
      "lastUsage": "Data do último envio"
    }
  }
}
</i18n>
