<template>
  <span ref="spanRef" class="group inline-flex content-center items-center gap-1.5">
    <component
      :is="icon"
      :class="[colorIconClass, { 'h-5 w-5': size === 'base', 'h-4 w-4': size === 'small' }]"
      class="self-center"
      aria-hidden="true"
    >
    </component>
    <span :class="tooltip && 'underline decoration-dotted group-hover:text-gray-500'" class="text-sm text-gray-400">
      {{ title }}
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import type { Component } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";
import { useTooltip } from "@composables/tooltips";

//Types
import { Placement } from "@popperjs/core";
export type ColorsType = "gray" | "lightGray" | "green" | "lightRed" | "red" | "sky" | "yellow";

const props = withDefaults(
  defineProps<{
    icon: Component;
    title: string;
    color?: ColorsType;
    tooltip?: string;
    tooltipPlacement?: Placement;
    size?: "small" | "base";
  }>(),
  {
    icon: CheckCircleIcon,
    title: "",
    color: "gray",
    tooltip: "",
    tooltipPlacement: "auto",
    size: "base",
  }
);

const spanRef = ref();
const contentToRef = toRef(props, "tooltip");
contentToRef.value && useTooltip(spanRef, contentToRef, { placement: props.tooltipPlacement });

const colorIconClass = computed(() => {
  switch (props.color) {
    case "green":
      return "text-green-400";
    case "yellow":
      return "text-yellow-400";
    case "red":
      return "text-red-400";
    case "lightRed":
      return "text-red-300";
    case "sky":
      return "text-sky-500";
    case "gray":
      return "text-gray-400";
    case "lightGray":
      return "text-gray-300";
    default:
      return "";
  }
});
</script>

<style scoped>
.decoration-dotted {
  text-decoration-style: dotted;
}
</style>
