/* eslint-disable vue/one-component-per-file */
import { _, objHas } from "@/helpers/utils";
import Session from "@/modules/session/model";
import app from "@/app";
import { createApp } from "vue";
import Dashboard from "@pages/Dashboard.vue";
import HomePage from "@pages/Home/HomePage.vue";
import { createAppI18n } from "@/helpers/vue";
import ExternalTemplateGallery from "@pages/ExternalTemplateGallery/ExternalTemplateGallery.vue";
import Audience from "@pages/Audience/AudiencePage.vue";
import OptinsPage from "@pages/Optins/OptinsPage.vue";
import AutomationsPage from "@pages/Automations/AutomationsPage.vue";
import CampaignsPage from "@pages/Campaigns/CampaignsPage.vue";
import AuthenticationPage from "@pages/Authentication/AuthenticationPage.vue";
import { setI18nLocale } from "@locales/i18n";

import { usePinia } from "@store";

// Application
import { useLocalStorageApp, useAuthenticationApp, useGeoLocationApplication } from "@application";

const moment = window.moment;
import { getI18nInstance } from "@locales/i18n";

/**
 * Application router
 *
 * @module Router
 * @extends Backbone.Router
 */
export default window.Backbone.Router.extend({
  current: false,
  routes: {
    "(dashboard)": "dashboard",
    dashboard: "dashboard",
    "dashboard/:tab": "dashboard",
    home: "home",

    //login: "login", // TODO AUTH: Esta ruta se usa?
    "lists/import": "importList",
    "templates/*id": "editTemplates",
    "metrics/:id/links": "metricsLinks",
    "metrics/:id/activity": "metricsActivity",
    "metrics/:id/contents": "metricsContents",
    "metrics/:id": "metrics",
    "automations/:id/activity": "automationActivity",
    "automations/:id/contents(/:template)": "automationContent",
    "automations/:id/templates(/:template)": "automationTemplate",

    plan: "plan",
    settings: "settings",
    "settings/:account/users/new": "user",
    "settings/:account/users/:id": "user",
    "accounts/new": "createAccount",
    "accounts/:account/users/new": "user",
    "accounts/:account/users/:id": "user",
    "accounts/:id": "account",
    "plan-payment-success-*type": "planPaymentSuccess",
    "plan-payment-error-*type": "planPaymentError",
    "shares/:account/campaigns/:hash/:id/links": "campaignShareLinks",
    "shares/:account/campaigns/:hash/:id/contents": "campaignShareContents",
    "shares/:account/campaigns/:hash/:id": "campaignShare",
    "campaigns/newsplitab": "createCampaignSplitAB",
    "campaigns/newsmart": "createCampaignSmart",

    "campaigns/new/simple": "createCampaignSimpleUnlayer",
    "campaigns/new/splitab": "createCampaignSplitABUnlayer",
    "campaigns/new/smart": "createCampaignSmartUnlayer",

    gallery: "gallery",
    "gallery/:type(/*id)": "gallery",

    lists: "audience",
    contacts: "audience",
    interests: "audience",
    fields: "audience",

    optins: "optins",

    automations: "automations",
    "automations/all": "automations",
    "automations/active": "automations",
    "automations/inactive": "automations",

    campaigns: "campaigns",
    "campaigns/all": "campaigns",
    "campaigns/sent": "campaigns",
    "campaigns/scheduled": "campaigns",
    "campaigns/draft": "campaigns",

    "integrations/:id/auth": "integrationAuth",
    ":module": "list",
    ":module/new": "create",
    ":module/:id": "edit"
  },

  // Changes URL and navigates to the specified view
  navigateTo(route) {
    this.navigate(route, { trigger: true });
    return;
  },

  // Changes URL but does not refresh de view
  updateRoute(route) {
    this.navigate(route, { trigger: false });
    const customEvent = new Event("updateNavigation");

    document.dispatchEvent(customEvent);
    return;
  },

  // navigates to the listing view of the current route (/lists/123 -> /lists)
  navigateToSectionRoot() {
    let route = this.currentRoute();
    this.navigateTo(route.split("/")[0]);
    return;
  },

  // Returns if route is the current route
  isCurrentRoute(route) {
    return route === this.currentRoute();
  },

  // Returns path of current route
  currentRoute() {
    // Elimino el primer / del path
    return window.location.pathname.replace("/", "");
  },

  async loginWithToken(token, account, route, params) {
    try {
      const geoLocationApp = useGeoLocationApplication();
      const authApp = useAuthenticationApp();

      let navigatorLang = navigator.language && navigator.language.slice(0, 2);

      const res = await geoLocationApp.detectCountry();

      // Override del lang por si en brasil tienen navegador en ingles
      if (res.isOk() && res.value === "br") {
        navigatorLang = "pt";
      }

      await authApp.loginWithToken({
        account: account,
        token: token,
        lang: navigatorLang
      });

      if (params.length > 0) {
        this.navigateTo(`${route}?${params}`);
      } else {
        this.navigateTo(`${route}`);
      }
      // window.Backbone.history.navigate("");
      // location.reload();
      return;
    } catch (e) {
      // Continue...
    }
  },

  // This method is called internally within the router, whenever a route matches and its corresponding callback is about to be executed.
  // Return false from execute to cancel the current transition.
  execute: function (callback, args) {
    const customEvent = new Event("updateNavigation");

    document.dispatchEvent(customEvent);

    const self = this;
    var sessionData = app.readSessionCookie(),
      login = app.instances.singleton("login", "index"),
      layout = app.instances.singleton("layout", "index"),
      signup = app.instances.singleton("signup", "index"),
      module = _.first(args) || "login",
      store = args?.[1] || "",
      guestSection = this.currentRoute().startsWith(["shares/"]),
      currentAccount;
    this.args = args;

    // Set shortcut to layout view instance
    app.layout = layout;

    // clean previous vue view
    if (this.vueapp) {
      this.vueapp.unmount(".page");
      delete this.vueapp;
    }

    const isIntegrationAuthRoute = this.currentRoute().match(/integrations\/(\w+)\/auth/);

    const url = new URL(location.href);

    const params = url.searchParams;
    const authToken = params.get("auth_token");
    const authAccount = params.get("auth_account");
    const intercomMessage = params.get("intercom_message");
    const intercomArticle = params.get("intercom_article");

    if (intercomArticle) {
      params.delete("intercom_article");
      window.Intercom("showArticle", intercomArticle);
      if (params.size > 0) {
        this.updateRoute(`${url.pathname}${params}`);
      } else {
        if (params.size > 0) {
          this.updateRoute(`${url.pathname}`);
        }
      }
    }

    if (intercomMessage) {
      params.delete("intercom_message");
      window.Intercom("showNewMessage", intercomMessage);
      if (params.size > 0) {
        this.updateRoute(`${url.pathname}${params}`);
      } else {
        if (params.size > 0) {
          this.updateRoute(`${url.pathname}`);
        }
      }
    }

    if (!!authToken && !!authAccount) {
      params.delete("auth_token");
      params.delete("auth_account");

      this.loginWithToken(authToken, authAccount, url.pathname, params.toString());
      return;
    }

    if (!sessionData && !guestSection && !isIntegrationAuthRoute) {
      const resetToken = window.location.search.replace("?reset_token=", "");
      const authCode = window.location.search.replace("?auth_code=", "");
      const isTiendanube = store === "tiendanube";

      const loginRoutes = ["login", "signup", "reset"];

      if (!loginRoutes.includes(module)) {
        module = "login";
      }

      this.navigateTo(`${module}${window.location.search}`);

      let locale = app.lang;
      setI18nLocale(locale);

      this.vueapp = createAppI18n(AuthenticationPage, {
        mode: isTiendanube ? "tiendanubeAuth" : module,
        authCode,
        resetToken
      });

      this.vueapp.mount(".login");

      return;
    }

    // Create a temporal session for guests
    if (guestSection) {
      // Disable integrations
      app.integrations.intercom.show = false;
      $("body").addClass("hide-intercom");

      // Save current session
      if (app.session !== undefined) {
        app.previousSession = true;
      }

      // Start a guest session
      app.session = new Session({
        isGuest: true,
        account: args[0],
        token: args[1],
        userType: "GUEST",
        acl: {}
      });

      // Render layout
      if (layout.renderedForGuest === undefined) {
        layout.render();
      }
    } else {
      // Restore the previous logged in session
      if (app.previousSession !== undefined) {
        app.session = new Session(sessionData);
        delete app.previousSession;
        delete layout.renderedForGuest;
        layout.render();
        self.session();
        if (app.session.get("inAccount")) {
          self.jobs();
        }
      }
    }

    // If this is the first section landed, start a user session
    if (sessionData && (app.session === undefined || app.session.get("started") === undefined)) {
      // Start session
      app.session = new Session(sessionData);

      // If an account is received as query parameter, change account
      args.forEach(function (argument) {
        if (_.isObject(argument) && objHas(argument, "account")) {
          if (argument.account !== app.session.get("account") && app.session.get("isMasterUser")) {
            var newAccount = argument.account;

            self.changeAccount(newAccount);
          }
        }
      });

      // Check if the account has incomplete details
      currentAccount = app.session.get("details")?.account;
      // if (
      //   currentAccount &&
      //   (currentAccount.name === "" || currentAccount.postalAddress === "") &&
      // //   !app.session.get("isMasterUser")
      // ) {
      //   window.shouldCompleteDetails = true;
      // }

      //DEBUG
      try {
        if (window.localStorage.getItem("debugSignupDetails")) {
          window.shouldCompleteDetails = true;
        }
      } catch (err) {
        console.error(err);
      }

      // Render layout

      layout.render();

      // Show session view
      self.session();

      if (currentAccount) {
        // Start integrations
        app.integrations.start();

        // Broadcast login event
        // if (window.justLoggedIn !== undefined) {
        //   delete window.justLoggedIn;
        //   app.integrations.amplitude.event("APP_LOGIN_OK");
        //   app.integrations.intercom.event("APP_LOGIN_OK");
        // }
      }

      // Get context
      app.session.getContext();

      // If an account is selected show alerts, notifications and tasks
      if (app.session.get("inAccount")) {
        self.jobs();
      }
    }

    const localStorageApp = useLocalStorageApp();
    const useNewLayoutLS = localStorageApp.getGlobal({
      id: "useNewLayout"
    });

    // Set main template
    const isMasterUser = app.session?.attributes.isMasterUser || false;

    const allowUseNewLayout = app.session?.attributes?.details?.account?.features.USE_NEW_LAYOUT == "1";
    const allowSwitchLayout = app.session?.attributes?.details?.account?.features.ALLOW_TOGGLE_LAYOUT == "1";
    const userSelectedNewLayout = useNewLayoutLS === true;

    const masterUserLayoutConfig = isMasterUser && userSelectedNewLayout;
    const userLayoutConfig = !isMasterUser && allowUseNewLayout && (allowSwitchLayout ? userSelectedNewLayout : true);

    const shouldApplyNewLayout = masterUserLayoutConfig || userLayoutConfig;
    self.shouldApplyNewLayout = shouldApplyNewLayout;

    const executeCallback = () => {
      // Perform the method corresponding to the route
      if (callback) {
        // Prevent navigation when flag is active
        if (window.preventViewChange) {
          if (_.isDefined(window.preventedChange)) {
            window.clearTimeout(window.preventedChange);
          }
          window.preventedChange = setTimeout(function () {
            self.execute(callback, args);
          }, 1000);
          return false;
        }

        // Close sidebar on small viewports
        layout.closeSidebar();

        // Highlight the active section
        layout.setActiveSection();

        // Close the open modals
        layout.closeOpenModals();

        // If the session started and the module is incorrect, redirect to dashboard
        if (module === "reset" || module === "signup") {
          return self.closeSessionToUseThisLink();
        }

        // Redirect templates
        if (module === "templates") {
          args[0] = "designs";
        }

        // If the request has a reset token but the session is started, go to the dashboard
        if (_.has(module, "reset_token")) {
          return self.closeSessionToResetPassword();
        }

        // Clean up events from the previous view
        if (self.current !== undefined && self.current) {
          // Perform route callback after clean up
          self.current.undelegateEvents();
          if (self.current.cleanUp()) {
            callback.apply(self, args);
            app.integrations.intercom.update();
          } else {
            return false;
          }
        } else {
          // Perform route callback
          if (app.session?.get("inAccount")) {
            if (window.shouldCompleteDetails) {
              return self.account();
            } else {
              callback.apply(self, args);
              app.integrations.intercom.update();
            }
          } else {
            //caso integration auth
            callback.apply(self, args);
          }
        }
      }
    };

    app.detectedCountry = window.localStorage.getItem("detectedCountry");

    if (!guestSection && currentAccount && !app.detectedCountry) {
      $.get("geoiplocation/data")
        .done((res) => {
          app.detectedCountry = res.data?.geoip_data?.country?.toLowerCase() || "ar";
        })
        .fail(() => {
          app.detectedCountry = "ar";
        })
        .always(() => {
          window.localStorage.setItem("detectedCountry", app.detectedCountry);
          executeCallback();
        });
    } else {
      executeCallback();
    }
  },
  session: function () {
    app.instances.singleton("session", "index").render();
  },
  jobs: function () {
    app.instances.singleton("tasks", "list").start();
    app.instances.singleton("notifications", "list").start();
  },
  dashboard: function (tab) {
    if (tab && !["ecommerce", "emails", "contacts", "buyers"].includes(tab)) {
      tab = "emails";
      this.updateRoute("dashboard/emails");
    }

    if (app.session.get("account") === "*") return;

    this.vueapp = createApp(Dashboard, {
      initialTab: tab,
      onPlanLoaded: (plan) => {
        // hack para que sigan funcionando las notificaciones de trial y bloqueos de la barra de sesion
        app.setPlan(plan);
        app.setTrial(plan.trialEnds);
      },
      onNavigateTo: (route) => {
        this.navigateTo(route);
      },
      onRouteChanged: (newRoute) => {
        this.updateRoute("dashboard/" + newRoute);
      }
    });
    this.vueapp.use(getI18nInstance());
    this.vueapp.use(usePinia());
    this.vueapp.mount(".page");
  },
  home: function () {
    if (app.session.get("account") === "*") return;

    const isMasterUser = app.session?.attributes.isMasterUser || false;
    const homeAccess = isMasterUser || app.session?.attributes?.details?.account?.features.ALLOW_HOME_ACCESS == "1";

    if (!homeAccess) {
      this.navigateTo("dashboard");
      return;
    }

    this.vueapp = createApp(HomePage);
    this.vueapp.use(getI18nInstance());
    this.vueapp.use(usePinia());
    this.vueapp.mount(".page");
  },
  gallery: function (type, id) {
    let gallery = type;
    let category;
    if (!["public", "private"].includes(type)) {
      gallery = "public";
      id = undefined;
      this.updateRoute("gallery/public");
    }

    if (id && id.startsWith("category/")) {
      category = id.split("/")[1];
      id = undefined;
    }

    this.vueapp = createApp(ExternalTemplateGallery, {
      public: gallery === "public",
      id,
      category,
      onRouteChanged: (newRoute) => {
        this.updateRoute("gallery/" + newRoute);
      }
    });
    this.vueapp.use(getI18nInstance());
    this.vueapp.use(usePinia());
    this.vueapp.mount(".page");
  },
  audience: function (params) {
    if (!this.shouldApplyNewLayout) {
      const route = window.Backbone.history.getFragment();
      const paramsIndex = route.indexOf("?");
      const formattedRoute = paramsIndex !== -1 ? route.slice(0, paramsIndex) : route;
      this.list(formattedRoute, params);
      return;
    }

    const route = window.Backbone.history.getFragment();
    this.vueapp = createAppI18n(Audience, {
      initialTab: route,
      onChangeRoute: (tab) => {
        this.updateRoute(tab);
      }
    });
    this.vueapp.mount(".page");
  },
  optins: function (params) {
    if (!this.shouldApplyNewLayout) {
      const route = window.Backbone.history.getFragment();
      const paramsIndex = route.indexOf("?");
      const formattedRoute = paramsIndex !== -1 ? route.slice(0, paramsIndex) : route;
      this.list(formattedRoute, params);
      return;
    }

    this.vueapp = createAppI18n(OptinsPage);
    this.vueapp.mount(".page");
  },
  automations: function (params) {
    if (!this.shouldApplyNewLayout) {
      const route = window.Backbone.history.getFragment();
      const paramsIndex = route.indexOf("?");
      const formattedRoute = paramsIndex !== -1 ? route.slice(0, paramsIndex) : route;
      this.list(formattedRoute, params);
      return;
    }
    const route = window.Backbone.history.getFragment();
    const selectedTab = route?.split("/")?.[1];

    this.vueapp = createAppI18n(AutomationsPage, {
      initialTab: selectedTab,
      onChangeRoute: (newRoute) => {
        this.updateRoute("automations/" + newRoute);
      },
      onChangeNavigation: (newRoute) => {
        this.navigateTo(newRoute);
      }
    });
    this.vueapp.mount(".page");
  },
  campaigns: function (params) {
    if (!this.shouldApplyNewLayout) {
      const route = window.Backbone.history.getFragment();
      const paramsIndex = route.indexOf("?");
      const formattedRoute = paramsIndex !== -1 ? route.slice(0, paramsIndex) : route;
      this.list(formattedRoute, params);
      return;
    }
    const route = window.Backbone.history.getFragment();
    const selectedTab = route?.split("/")?.[1];

    this.vueapp = createAppI18n(CampaignsPage, {
      initialTab: selectedTab,
      onChangeRoute: (newRoute) => {
        this.updateRoute("campaigns/" + newRoute);
      },
      onChangeNavigation: (newRoute) => {
        this.navigateTo(newRoute);
      }
    });
    this.vueapp.mount(".page");
  },
  closeSessionToResetPassword: function () {
    app.router.navigateTo("dashboard");
    this.current = app.instances.get("dashboard", "index").render({ id: app.session.get("account") });
    this.current.displayMessage(window.lang.login.closeSessionToResetPassword, 5000, "error");
  },
  closeSessionToUseThisLink: function () {
    app.router.navigateTo("dashboard");
    this.current = app.instances.get("dashboard", "index").render({ id: app.session.get("account") });
    this.current.displayMessage(window.lang.login.closeSessionToUseThisLink, 5000, "error");
  },
  // TODO AUTH: Esta ruta se usa?
  // login: function () {
  // this.current = app.instances.get("login", "index").render();
  // },
  settings: function () {
    this.current = app.instances.get("accounts", "edit").render({ id: app.session.get("account") });
  },
  importList: function (params) {
    this.current = app.instances.get("contacts", "importer").render(params);
  },
  editTemplates: function (id) {
    this.current = app.instances.get("designs", "edit").render({ id: "/" + id });
  },
  plan: function (params) {
    this.current = app.instances.get("plan", "edit").render($.extend({ id: app.session.get("account") }, params));
  },
  planPaymentSuccess: function (type) {
    this.current = app.instances
      .get("plan", "edit")
      .render($.extend({ id: app.session.get("account") }, { success: 1, type: type }));
  },
  planPaymentError: function (type) {
    this.current = app.instances
      .get("plan", "edit")
      .render($.extend({ id: app.session.get("account") }, { error: 1, type: type }));
  },
  metrics: function (id, params) {
    const options = { id: id };
    if (params?.filters?.version) {
      options.version = params.filters.version;
    }
    this.current = app.instances.get("metrics", "details").render(options);
  },
  metricsLinks: function (id) {
    this.current = app.instances.get("metrics", "links").render({ id: id });
  },
  metricsActivity: function (id, params) {
    params = params || {};
    params.id = id;
    this.current = app.instances.get("metrics", "activity").start(params);
  },
  metricsContents: function (id, params) {
    const options = { id: id };
    if (params?.filters?.version) {
      options.version = params.filters.version;
    }
    this.current = app.instances.get("metrics", "contents").render(options);
  },
  campaignShare: function (account, hash, id) {
    this.current = app.instances.get("metrics", "details").render({ id: id });
  },
  campaignShareLinks: function (account, hash, id) {
    this.current = app.instances.get("metrics", "links").render({ id: id });
  },
  campaignShareContents: function (account, hash, id) {
    this.current = app.instances.get("metrics", "contents").render({ id: id });
  },
  automationActivity: function (id, params) {
    params = params || {};
    params.id = id;
    this.current = app.instances.get("automations", "activity").start(params);
  },
  automationContent: function (id, template) {
    this.current = app.instances.get("automations", "content").render({ id: id, template: template });
  },
  automationTemplate: function (id, template) {
    this.current = app.instances.get("automations", "template").render({ id: id, template: template });
  },

  user: function (account, id) {
    var instance = app.instances.get("users", "edit");
    this.current = instance;
    if (!id) {
      instance.changeTemplate(instance.templates.edit).render({ id: null, account: account });
    } else {
      instance.render({ id: id, account: account });
    }
  },
  account: function (id) {
    this.current = app.instances.get("accounts", "edit").render({ id: id });
  },
  createAccount: function () {
    const instance = app.instances.get("accounts", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null });
  },
  list: function (module, params) {
    var instance = app.instances.get(module, "list");
    if (!instance) {
      return this.notFound();
    }
    this.current = instance;
    instance.start(params);
  },
  create: function (module, id) {
    var instance = app.instances.get(module, "edit");
    if (!instance) {
      return this.notFound();
    }
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: id });
  },
  createCampaignSplitAB: function () {
    const instance = app.instances.get("campaigns", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null, type: "SPLIT_AB" });
  },
  createCampaignSmart: function () {
    const instance = app.instances.get("campaigns", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null, type: "SMART" });
  },
  createCampaignSimpleUnlayer: function () {
    const instance = app.instances.get("campaigns", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null, type: "SIMPLE", useUnlayer: true });
  },
  createCampaignSplitABUnlayer: function () {
    const instance = app.instances.get("campaigns", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null, type: "SPLIT_AB", useUnlayer: true });
  },
  createCampaignSmartUnlayer: function () {
    const instance = app.instances.get("campaigns", "edit");
    this.current = instance;
    instance.changeTemplate(instance.templates.edit).render({ id: null, type: "SMART", useUnlayer: true });
  },

  edit: function (module, id) {
    var instance = app.instances.get(module, "edit");
    if (!instance) {
      return this.notFound();
    }
    this.current = instance;
    instance.render({ id: id });
  },
  integrationAuth: function (id, params) {
    this.current = app.instances.get("integrations", "auth").render({ ...params, id });
  },
  notFound: function () {
    this.current = app.instances.get("layout", "index").notFound();
  },

  // Changes the selected account
  changeAccount: function (account) {
    var encoded, newSession;

    $.ajax({
      url: account,
      headers: {
        "Account-Prefix": false
      },
      success: function (response) {
        var accountDetails = response.data,
          expires = moment().add(365, "days").toDate().toUTCString();

        // Create a new session object
        newSession = {
          token: app.session.get("token"),
          user: app.session.get("user"),
          mainAccount: app.session.get("mainAccount"),
          userType: app.session.get("userType"),
          account: account,
          details: _.extend(app.session.get("details"), {
            account: accountDetails
          })
        };

        // Updates session to include the selected account
        app.session.set("account", account);
        app.session.set("inAccount", true);
        delete app.session.attributes.started;
        encoded = encodeURIComponent(JSON.stringify(newSession));
        document.cookie = "session=" + encoded + "; expires=" + expires + "; path=/";

        // Clear local storage
        var acls = localStorage.getItem("acl");
        localStorage.removeItem("acl");
        localStorage.removeItem("trial");
        localStorage.setItem("acl", acls);

        // Close modal and relaunch
        app.router.navigateTo("dashboard");
        window.Backbone.history.stop();
        window.Backbone.history.start();

        //hack capocha para recargue bien el menu lateral
        location.reload();
      }
    });
  }
});
