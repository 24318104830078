import { usePagingRequests, useRequests } from "@api/requests";

import { isEqual } from "lodash";

// Types
import {
  FieldModel,
  FieldsModel,
  FieldsAPIParams,
  GetFieldsAsyncGenerator,
  GetParams,
  FieldsService,
} from "./fields.types";

import type { SuccessAPIOffsetPaging } from "../../models/requestWrappers";

export function useFields(): FieldsService {
  const requestPaging = usePagingRequests();
  const request = useRequests();

  let GetFieldsGenerator: GetFieldsAsyncGenerator | undefined = undefined;
  let apiParams: GetParams | undefined = undefined;

  return {
    async getFields(options) {
      if (!isEqual(apiParams, options) || !GetFieldsGenerator) {
        apiParams = options;
        GetFieldsGenerator = await requestPaging<FieldsModel, unknown, FieldsAPIParams>(
          {
            url: `/fields?sortBy=id&sortDir=asc`,
            method: "get",
            urlParams: {
              q: apiParams?.search ? `*${apiParams?.search}*` : undefined,
              sortBy: apiParams?.sortBy ?? "id",
              sortDir: apiParams?.sortDir ?? "asc",
            },
          },
          {
            limit: apiParams?.limit ?? 1000,
            offset: apiParams?.offset ?? 0,
          },
          true,
        );
      }

      if (!GetFieldsGenerator || GetFieldsGenerator === undefined)
        return {
          done: true,
          value: [],
          total: undefined,
        };

      const data = (await GetFieldsGenerator.next()) as IteratorResult<SuccessAPIOffsetPaging<FieldsModel>>;

      if (data.done || !data.value) {
        return {
          done: true,
          value: [],
          total: undefined,
        };
      }

      const getFieldReplaceCode = (field: FieldModel) => {
        if (field.tag.toLowerCase() === "fname") return "${contact.first_name}";
        if (field.tag.toLowerCase() === "lname") return "${contact.last_name}";
        return `\${contact.${field.tag.toLowerCase()}}`;
      };

      return {
        done: false,
        total: data.value.paging.total,
        value: data.value.data.map((field) => ({
          ...field,
          replaceCode: getFieldReplaceCode(field),
        })),
      };
    },
    async delete(params) {
      await request({
        url: `/fields/${params.id}`,
        method: "delete",
      });
    },
  };
}
