<template>
  <InterestSelector
    :open="open"
    :loading-accept="loadingAddInterests"
    allow-create
    @close="close"
    @select-lists="addInterests"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";

import type { InterestListItems } from "@organisms/Interests/InterestSelector";

// Components
import InterestSelector from "@organisms/Interests/InterestSelector";

// Composables
import { useNotifications } from "@composables/notifications";

// I18n
import { useI18n } from "vue-i18n";

// Services
import { useContacts } from "@api/modules/contacts/contacts";

// Domain
import type { ContactRows } from "../audienceContacts.types";

const contactsAPI = useContacts();
const { notify } = useNotifications();
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    open: boolean;
    contacts: ContactRows;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  interestAdded: [void];
}>();

const close = () => {
  emit("close");
};

const loadingAddInterests = ref(false);

const addInterests = async (selectedList: InterestListItems) => {
  try {
    loadingAddInterests.value = true;
    let promises: Array<ReturnType<typeof contactsAPI.addInterest>> = [];

    promises = selectedList.reduce(
      (interestPromises, item) => {
        if (!item.data?.id) return interestPromises;

        const contactListPromises = props.contacts.reduce(
          (contactPromises, contact) => {
            if (!contact.data?.id || !item.data?.id) return contactPromises;

            const contactPromise = contactsAPI.addInterest({
              contactId: contact.data.id.toString(),
              interestId: item.data.id.toString(),
            });

            contactPromises.push(contactPromise);

            return contactPromises;
          },
          [] as Array<ReturnType<typeof contactsAPI.addInterest>>,
        );

        interestPromises = [...promises, ...contactListPromises];

        return interestPromises;
      },
      [] as Array<ReturnType<typeof contactsAPI.addInterest>>,
    );

    await Promise.all(promises);
    emit("interestAdded");
    notify({
      title: t(`notifications.addInterest.title`, {}, { plural: selectedList.length }),
      text: t(`notifications.addInterest.text`, {}, { plural: selectedList.length }),
      theme: "success",
    });
  } catch {
    notify({
      title: t(`notifications.addInterestError.title`, {}, { plural: selectedList.length }),
      text: t(`notifications.addInterestError.text`, {}, { plural: selectedList.length }),
      theme: "error",
    });
  } finally {
    loadingAddInterests.value = false;
    close();
  }
};
</script>
<i18n lang="json">
{
  "es": {
    "notifications": {
      "addInterest": {
        "title": "Interés asignado | Intereses asignados",
        "text": "Se asignó el interés. | Se asignaron los intereses."
      },
      "addInterestError": {
        "title": "Error al asignar interés | Error al asignar intereses",
        "text": "No se pudo asignar el interés. | No se pudieron asignar todos los intereses."
      }
    }
  },
  "pt": {
    "notifications": {
      "addInterest": {
        "title": "Interesse atribuído | Interesses atribuídos",
        "text": "O interesse foi atribuído. | Os interesses foram atribuídos."
      },
      "addInterestError": {
        "title": "Erro ao atribuir interesse | Erro ao atribuir interesses",
        "text": "Não foi possível atribuir o interesse. | Não foi possível atribuir todos os interesses."
      }
    }
  }
}
</i18n>
