<template>
  <div
    class="flex flex-wrap justify-between overflow-hidden rounded-lg px-4 py-5 sm:p-6"
    :class="[loading ? 'animate-pulse bg-gray-100' : cardColor]"
  >
    <div
      :class="[{ 'select-none opacity-40': !active }, 'my-auto flex flex-wrap gap-4 pr-3']"
      :style="{
        flexBasis: flexBasisTextPercentage, // auto
      }"
    >
      <span v-if="loading" class="h-5 w-14 rounded-xl bg-gray-200"></span>
      <span v-else :class="['text-xl font-semibold leading-8']">{{ percentage }}% </span>
      <div class="flex items-center gap-x-1 text-sm font-normal leading-none">
        <span v-if="loading" class="h-5 w-20 rounded-xl bg-gray-200"></span>
        <div v-else :class="['flex items-end']">
          <UserIcon class="h-4" /> <span>{{ contactsNumber }}</span>
        </div>
      </div>
    </div>
    <!-- <span v-if="loading" class="bg-gray-200 w-24 rounded-xl h-5"></span> -->
    <div
      v-if="!loading"
      :class="[{ 'select-none': !active }, 'my-auto flex justify-end']"
      :style="{
        flexBasis: 'auto',
      }"
    >
      <SimpleBadge theme="white" size="xlarge" square :icon="InformationCircleIcon" :tooltip="infoTooltip">
        {{ stageTitle }}
      </SimpleBadge>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

// Components
import SimpleBadge from "@atoms/SimpleBadge";
import { UserIcon, InformationCircleIcon } from "@heroicons/vue/solid";

// Types
import type { StageNames } from "@/vue/types/lifecycle";

const props = withDefaults(
  defineProps<{
    stage: StageNames;
    percentage: string;
    contactsNumber: string;
    stageTitle: string;
    infoTooltip: string;
    loading?: boolean;
    active?: boolean;
    flexBasisTextPercentage?: string;
  }>(),
  {
    loading: false,
    active: true,
    flexBasisTextPercentage: "65%",
  }
);

const cardColor = computed(() => {
  switch (props.stage) {
    case "champion":
      return "text-teal-700 bg-teal-300";
    case "loyalist":
      return "text-teal-600 bg-teal-200";
    case "recent":
      return "text-teal-500 bg-teal-50";
    case "highpotential":
      return "text-yellow-600 bg-yellow-100";
    case "neednutring":
      return "text-yellow-500 bg-yellow-50";
    case "cantlose":
      return "text-orange-600 bg-orange-100";
    case "atrisk":
      return "text-orange-500 bg-orange-50";
    case "abouttolose":
      return "text-red-500 bg-red-100";
    default:
      return "text-gray-400 bg-white";
  }
});

// Que las tarjetas sean flex no grid, ver en el primer div que tengo grid flex -> más simple
</script>
