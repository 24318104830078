import { EditView } from "@/core/magnet";
import app from "@/app";
import loginTpl from "../templates/index.hbs";
import resetPasswordTpl from "../templates/resetPassword.hbs";
import resetPasswordSuccessTpl from "../templates/resetPasswordSuccess.hbs";
import updatePasswordTpl from "../templates/updatePassword.hbs";
import accountsTpl from "../templates/accounts.hbs";

// Application
import { useLocalStorageApp } from "@application";

export default EditView.extend({
  module: "login",
  templates: {
    initial: loginTpl,
  },
  createInstance: false,
  renderArea: ".app",
  events: {
    "click [data-action=reveal-password]": "revealPassword",
    "submit [data-form=login]": "login",
    "click [data-action=render-reset-password]": "renderResetPassword",
    "submit [data-form=reset-password]": "resetPassword",
    "click [data-action=render-login]": "renderLogin",
    "submit [data-form=update-password]": "updatePassword",
  },

  // Performs the needed bindings after renderization
  afterRender: function () {
    var self = this;
    self.$("input:visible").first().focus();
    $(".app").addClass("login");
    $("html").removeAttr("style").addClass("has-pattern");
    if ($.cookie("expired")) {
      self.showExpiredSessionErrorMessage();
    }

    // app.integrations.amplitude.event("APP_VISIT_LOGIN");
  },

  // Reveals the password in the login form
  revealPassword: function () {
    var self = this,
      password = self.$(".password-input"),
      newType = password.attr("type") === "password" ? "text" : "password";
    password.attr("type", newType);
    password.closest(".input-group").toggleClass("revealed");
  },

  // Attemps a login
  login: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Disable submit button to prevent multiple submits
    self.disableSubmitButton(e);

    // Validate values
    if (!self.validateFormFields(form)) {
      return false;
    }

    // Clean previous validation error messages
    self.cleanFormErrorMessages(form);

    // Attempts a login
    $.ajax({
      type: "POST",
      url: "login",
      data: data,
      success: function (response) {
        // If the token is not received, alert an error
        if (response.data === undefined || response.data.token === undefined) {
          self.showAuthorizarionErrorMessage();
          return false;
        }

        // If the account details are missing, alert an error
        if (
          response.data.userType !== "MASTER" &&
          (!response?.data?.details?.account || !response.data.details.account)
        ) {
          self.showGenericErrorMessage();
          self.enableSubmitButton(form);
          return false;
        }

        // Otherwise, start the session
        app.saveSessionCookie(response.data);

        // Remove expired session
        $.removeCookie("expired");

        // Show updated password message
        if (self.isUpdatingPassword !== undefined || self.expiredPassword !== undefined) {
          setTimeout(function () {
            app.router.current.displayMessage(window.lang.login.passwordUpdated, 5000);
          }, 1000);
        }

        app.initLang();

        app.setupLang().then(() => {
          // Start router
          if (self.isUpdatingPassword !== undefined) {
            app.router.navigateTo("dashboard");
          } else {
            window.Backbone.history.loadUrl(window.Backbone.history.fragment);
          }
        });

        const localStorageApp = useLocalStorageApp();

        const accountsLoggedIn = localStorageApp.getGlobal({ id: "ACCOUNTS_LOGGED_IN" }) ?? [];
        if (
          response?.data?.account === "*" ||
          response?.data?.userType === "MASTER" ||
          accountsLoggedIn.includes(response?.data?.account)
        ) {
          return;
        }

        localStorageApp.saveGlobal({ id: "ACCOUNTS_LOGGED_IN", value: [...accountsLoggedIn, response?.data?.account] });
      },
      error: function (xhr) {
        var response = xhr.responseJSON || false,
          template = accountsTpl,
          html;

        // Restore submit button
        self.enableSubmitButton(form);

        // Check if we received a response
        if (!response) {
          self.showGenericErrorMessage();
          return false;
        }

        // Show account selector for valid login with multiple accounts
        if (response.error.type === "ACCOUNT_REQUIRED") {
          html = template({
            lang: window.lang,
            accounts: response.data,
          });
          self.$(".form-group-last").removeClass("form-group-last");
          self.$(".accounts").html(html).closest(".form-group").removeClass("hide");
          return false;
        }

        // Show validation errors
        if (response.error.type === "VALIDATION_ERROR") {
          self.showValidationErrors(response.error.validationErrors);
          return false;
        }

        // Show expired password form
        if (response.error.type === "PASSWORD_EXPIRED") {
          self.expiredUser = data.user;
          self.expiredPassword = data.password;
          if (data.account !== undefined) {
            self.expiredAccount = data.account;
          }
          self.renderUpdatePassword();
          return false;
        }

        // Show an auth error
        if (response.error.type === "ACCOUNT_DISABLED") {
          self.showAccountDisabledErrorMessage();
          return false;
        }

        // Show an auth error
        if (response.error.type === "UNAUTHORIZED") {
          self.showAuthorizarionErrorMessage();
          return false;
        }
      },
    });
  },

  // Show a generic error message
  showGenericErrorMessage: function () {
    var self = this;
    self.$(".password-recovery-link").addClass("hide");
    self.$(".server-error").removeClass("hide");
  },

  // Show the error for disabled accounts
  showAccountDisabledErrorMessage: function () {
    $(".password-recovery-link").addClass("hide");
    $(".disabled-account-error").removeClass("hide");
  },

  // Show expired session error
  showExpiredSessionErrorMessage: function () {
    this.$(".expired-session-error").removeClass("hide");
  },

  // Show an auth error message
  showAuthorizarionErrorMessage: function () {
    var self = this;
    self.$(".password-recovery-link").addClass("hide");
    self.$(".auth-error").removeClass("hide");
  },

  // Shows the main login page
  renderLogin: function () {
    var self = this;
    self.changeTemplate(loginTpl).render();
  },

  // Shows the reset password page
  renderResetPassword: function (e) {
    e.preventDefault();
    var self = this;
    self.changeTemplate(resetPasswordTpl).render();
  },

  // Sends a request to reset the password
  resetPassword: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Disable submit button to prevent multiple submits
    self.disableSubmitButton(e);

    // Validate values
    if (!self.validateFormFields(form)) {
      return false;
    }

    // Clean previous validation error messages
    self.cleanFormErrorMessages(form);

    // Add account for emails with multiple accounts
    if (self.expiredAccount !== undefined) {
      data.account = self.expiredAccount;
    }

    // Attempts a reset
    $.ajax({
      type: "POST",
      url: "resetpassword",
      data: data,
      success: function () {
        // Show success message
        self.changeTemplate(resetPasswordSuccessTpl).render();
      },
      error: function (xhr) {
        var template = accountsTpl,
          html;

        // Restore submit button
        self.enableSubmitButton(form);

        // Show format error
        if (xhr.status === 400) {
          self.showValidationErrors(xhr.responseJSON.error.validationErrors, form);
          return false;
        }

        // Show validation error
        if (xhr.status === 401) {
          self.showValidationErrors({ user: window.lang.login.invalidAccount }, form);
          return false;
        }

        // Show input for multiple accounts
        if (xhr.status === 409) {
          html = template({
            lang: window.lang,
          });
          self.$(".form-group-last").removeClass("form-group-last");
          self.$(".accounts").html(html).closest(".form-group").removeClass("hide");
          return false;
        }

        // Show validation error
        if (xhr.status >= 500) {
          self.$(".server-error").removeClass("hide");
          return false;
        }
      },
    });
  },

  // Cleans a form
  cleanFormErrorMessages: function (form) {
    $(form).find(".server-error, .login-error").addClass("hide");
    $(form).find(".error-message").addClass("error-hidden");
    $(form).find(".has-error").removeClass("has-error");
  },

  // Shows the update password page
  renderUpdatePassword: function () {
    var self = this;
    self.isUpdatingPassword = true;
    self.changeTemplate(updatePasswordTpl).render();
  },

  // Updates the user's password
  updatePassword: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Disable submit button to prevent multiple submits
    self.disableSubmitButton(e);

    // Validate values
    if (!self.validateFormFields(form)) {
      return false;
    }

    // Clean previous validation error messages
    self.cleanFormErrorMessages(form);

    // Check if the passwords match
    if (data.password !== data.newPassword) {
      return self.showValidationErrors({
        password: window.lang.login.passwordsDontMatch,
      });
    }
    delete data.password;

    // Add reset token if present
    if (window.location.search.includes("reset_token")) {
      data.resetToken = window.location.search.replace("?reset_token=", "");
    }

    // Add user and password if the password is expired
    if (self.expiredPassword !== undefined) {
      data.user = self.expiredUser;
      data.password = self.expiredPassword;
      if (self.expiredAccount !== undefined) {
        data.account = self.expiredAccount;
      }
    }

    // Attempts a login
    $.ajax({
      type: "POST",
      url: "login",
      data: data,
      success: function (response) {
        // If the token is not received, alert an error
        if (response.data === undefined || response.data.token === undefined) {
          self.showAuthorizarionErrorMessage();
          return false;
        }

        // Otherwise, start the session
        app.saveSessionCookie(response.data);

        // Show updated password message
        setTimeout(function () {
          app.router.current.displayMessage(window.lang.login.passwordUpdated, 5000);
        }, 1000);

        // Start router
        app.router.navigateTo("dashboard");
      },
      error: function (xhr, error) {
        error = xhr.responseJSON.error;

        // Restore submit button
        self.enableSubmitButton(form);

        // Show errors
        if (error.validationErrors !== undefined) {
          self.showValidationErrors(error.validationErrors, form);
        } else {
          self.$(".user-message").text(error.userMessage).removeClass("hide");
        }
      },
    });
  },
});
