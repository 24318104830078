// Icons
import { TrashIcon } from "@heroicons/vue/outline";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Categories } from "@molecules/DropDownButton";
import type { ColumnsIds, InterestRow, InterestRows } from "./audienceInterests.types";
import type { Columns } from "@molecules/Table";
import type { Interests } from "@domain/interests";

export const getColumns = (): Columns<ColumnsIds | "options"> => {
  const { t } = useI18n();
  return [
    {
      id: "name",
      text: t("columns.name"),
      // sortModes: ["ASC"],
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "expiration",
      text: t("columns.expiration"),
      headerWidthRem: 10,
      headerTextAlign: "left",
      textAlign: "left",
    },
    {
      id: "options",
      headerWidthRem: 2,
    },
  ];
};

export const getListOptions = (): Categories => {
  const { t } = useI18n();
  return {
    user: [
      {
        key: "delete",
        value: t("listOptions.delete"),
        icon: TrashIcon,
        theme: "red",
      },
    ],
  };
};

export const interestsToRows = (interests: Interests): InterestRows => {
  return interests.map<InterestRow>((interest) => ({
    id: interest.id.toString(),
    content: {
      name: interest.name,
      expiration: interest.expirationDays.toString(),
      options: "",
    },
    data: interest,
  }));
};
