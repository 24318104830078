<template>
  <form class="space-y-6" @submit.prevent="onSubmit">
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <FormGroup
        id="card"
        v-slot="{ describedBy }"
        :label="t('card.label')"
        :hint="t('card.hint')"
        :error="v$.number.$errors[0]?.$message || v$.expiration.$errors[0]?.$message || v$.cvv.$errors[0]?.$message"
      >
        <CreditCardInput
          ref="creditCardInputRef"
          v-model:number="number"
          v-model:expiration="expiration"
          v-model:cvv="cvv"
          :number-error="!!v$.number.$errors[0]?.$message"
          :exp-error="!!v$.expiration.$errors[0]?.$message"
          :cvv-error="!!v$.cvv.$errors[0]?.$message"
          :aria-describedby="describedBy"
        />
      </FormGroup>

      <FormInput
        v-model="name"
        autocomplete="cc-name"
        :label="t('name.label')"
        :placeholder="t('name.placeholder')"
        :hint="t('name.hint')"
        :error="v$.name.$errors[0]?.$message"
      />

      <FormGroup
        id="document"
        v-slot="{ describedBy, hasError }"
        :label="t('docNumber.label')"
        :hint="t('docNumber.hint')"
        :error="v$.docNumber.$errors[0]?.$message || v$.docType.$errors[0]?.$message"
      >
        <InputWithDropdown
          id="document"
          v-model:value.numbersOnly="docNumber"
          v-model:option="docType"
          :placeholder="t('docNumber.placeholder')"
          :aria-describedby="describedBy"
          :options="docTypes"
          :has-error="hasError"
        ></InputWithDropdown>
      </FormGroup>

      <FormInput
        v-model.lowercase="email"
        :label="t('email.label')"
        :placeholder="t('email.placeholder')"
        :hint="t('email.hint')"
        :error="v$.email.$errors[0]?.$message"
      />
    </div>
    <AlertBox v-if="submitError" theme="warning">
      {{ submitError }}
      <template #actions>
        <a href="#" @click.prevent="contactSupport">{{ t("notify.contactSupport") }}</a>
      </template>
    </AlertBox>
    <div class="mt-8 flex justify-between space-x-10">
      <TextParagraph>
        {{ t("acceptTerms") }}
      </TextParagraph>

      <div class="flex flex-shrink-0 items-start justify-end space-x-4">
        <SimpleButton v-if="cancelable" theme="white" @click="$emit('cancel')">
          {{ t("cancel") }}
        </SimpleButton>
        <SimpleButton theme="primary" type="submit" :loading="isValidating">
          <template #leading><LockClosedIcon /></template>
          {{ t("saveCard") }}
        </SimpleButton>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, reactive, toRefs, computed, toRaw } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { LockClosedIcon } from "@heroicons/vue/solid";

import Intercom from "@helpers/intercom";
import { required, email as emailValidator, maxLength, minLength } from "@helpers/validators";
import { useCreditCards } from "@api/modules/creditcards";
import { usePagoUno, IPagoUnoTokenizeData } from "@api/modules/pagouno";
import { useNotifications } from "@composables/notifications";
import { ICreditCardPagoUnoCreate } from "@api/models/CreditCard";
import { useSessionStore } from "@store";

import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import InputWithDropdown from "@atoms/InputWithDropdown.vue";
import CreditCardInput from "@molecules/CreditCardInput.vue";
import AlertBox from "@atoms/AlertBox.vue";
import FormInput from "@molecules/FormInput.vue";
import FormGroup from "@molecules/FormGroup.vue";

const props = defineProps({
  cancelable: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(["cardCreated", "cancel"]);

const { t } = useI18n();
const { notify } = useNotifications();
const CreditCards = useCreditCards();
const PagoUno = usePagoUno();
const creditCardInputRef = ref<InstanceType<typeof CreditCardInput>>();

const docTypes = ref([
  {
    id: "DNI",
    name: "DNI",
    maxLength: 8,
    minLength: 7
  },
  {
    id: "CI",
    name: "Cédula",
    maxLength: 9,
    minLength: 1
  },
  {
    id: "LC",
    name: "L.C.",
    maxLength: 7,
    minLength: 6
  },
  {
    id: "LE",
    name: "L.E.",
    maxLength: 7,
    minLength: 6
  },
  {
    id: "Otro",
    name: "Otro",
    maxLength: 20,
    minLength: 5
  }
]);

const selectedDocType = computed(() => {
  return docTypes.value.find((d) => d.id === state.docType);
});

const initialState = {
  number: "",
  expiration: "",
  cvv: "",
  name: "",
  docType: "",
  docNumber: "",
  email: ""
};
const state = reactive({ ...initialState });

const { number, expiration, cvv, name, docType, docNumber, email } = toRefs(state);

const rules = computed(() => ({
  number: {
    required: required(t("number.errors.required")),
    valid: helpers.withMessage(t("number.errors.invalid"), () => !!creditCardInputRef.value?.cardValidation?.isValid)
    // amex: helpers.withMessage(t("number.errors.amex"), () =>
    //   !["amex", "american-express"].includes(
    //     creditCardInputRef.value?.cardValidation?.card?.type || ""
    //   )
    // ),
  },
  expiration: {
    required: required(t("expiration.errors.required")),
    valid: helpers.withMessage(t("expiration.errors.invalid"), () => !!creditCardInputRef.value?.expValidation?.isValid)
  },
  cvv: {
    required: required(t("cvv.errors.required")),
    valid: helpers.withMessage(t("cvv.errors.invalid"), () => !!creditCardInputRef.value?.cvvValidation?.isValid)
  },
  name: {
    required: required(t("name.errors.required"))
  },
  email: {
    required: required(t("email.errors.required")),
    email: emailValidator(t("email.errors.email"))
  },
  docType: {
    required: required(t("docType.errors.required"))
  },
  docNumber: {
    required: required(t("docNumber.errors.required")),
    maxLength: maxLength(selectedDocType.value?.maxLength || 20, t("docNumber.errors.invalid")),
    minLength: minLength(selectedDocType.value?.minLength || 1, t("docNumber.errors.invalid"))
  }
}));
const v$ = useVuelidate(rules, state);

const isValidating = ref(false);

const submitError = ref("");
const onSubmit = async () => {
  creditCardInputRef.value?.cleanExpDate();
  if (isValidating.value) return;
  v$.value.$touch();
  if (v$.value.$error) return;

  submitError.value = "";

  try {
    isValidating.value = true;

    const [expMonth, expYear] = state.expiration.split("/").map((s) => s.trim());

    const cardNumber = state.number.replaceAll(/\s/g, "");

    const sessionStore = useSessionStore();
    const account = sessionStore.session.account.code;

    const tokenizeData: IPagoUnoTokenizeData = {
      primary_account_number: cardNumber,
      expiration_date: `${expYear}${expMonth}`,
      card_security_code: state.cvv,
      card_holder: {
        first_name: state.name.split(" ")[0] || "",
        last_name: state.name.split(" ")[1] || "",
        front_name: state.name,
        telephone: "",
        email: `perfit.invoices.ar+${account}@tiendanube.com`,
        address: {
          country: "",
          state: "",
          city: "",
          street: "",
          door_number: ""
        },
        identification: {
          document_type: state.docType,
          document_number: state.docNumber
        }
      }
    };

    const pagoUnoResponse = await PagoUno.tokenize(tokenizeData);
    const tokenId = pagoUnoResponse.id;

    console.log("creditCardInputRef: ", creditCardInputRef.value);

    const createRequestData: ICreditCardPagoUnoCreate = {
      token: tokenId,
      issuer_id: creditCardInputRef.value!.cardValidation.card.type,
      email: state.email,
      name: state.name,
      doc_type: state.docType,
      doc_number: state.docNumber,
      expiration_month: expMonth,
      expiration_year: expYear,
      first_six_digits: cardNumber.substr(0, 6)
    };

    const newCard = await CreditCards.createTnPagoUno(createRequestData);

    notify({
      title: t("notify.success")
    });

    emit("cardCreated", newCard);
  } catch (error: any) {
    console.error(error);
    if (!(error.response || error.request) || error.response?.status === 400) {
      submitError.value = t("notify.error");
    }
  } finally {
    isValidating.value = false;
  }
};

const contactSupport = () => {
  Intercom.showNewMessage(t(`notify.helpMessage`));
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "saveCard": "Guardar tarjeta",
    "acceptTerms": "Al hacer click en \"Guardar tarjeta\", autorizas a Perfit a realizar cobros a la tarjeta ingresada de acuerdo a los términos de servicio del plan contratado.",
    "notify": {
      "success": "Tarjeta almacenada con éxito.",
      "error": "No pudimos validar la tarjeta ingresada. Verifica que todos los datos sean correctos y vuelve a intentar.",
      "contactSupport": "Si sigues con problemas contactános.",
      "helpMessage": "Hola, estoy intentando guardar los datos de mi tarjeta para realizar un pago, pero veo un error de validación. Podrían ayudarme?"
    },
    "name": {
      "label": "Nombre y apellido",
      "placeholder": "Juan Pérez",
      "hint": "Nombre del titular, tal como figura en la tarjeta.",
      "errors": {
        "required": "Ingresa el nombre tal como figura en la tarjeta."
      }
    },
    "email": {
      "label": "Dirección de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Casilla donde recibirás las notificaciones de pagos.",
      "errors": {
        "required": "Debes ingresar la casilla donde recibir las notificaciones.",
        "email": "La dirección de email ingresada es inválida."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo y número de documento del titular.",
      "errors": {
        "required": "Ingresa el tipo y número de documento del titular.",
        "invalid": "El número documento ingresado es inválido."
      }
    },
    "card": {
      "label": "Datos de la tarjeta",
      "hint": "Número, vencimiento y código de seguridad."
    },
    "number": {
      "errors": {
        "required": "Debes ingresar el número de tarjeta.",
        "invalid": "El número de tarjeta ingresado es inválido.",
        "amex": "Para pagar con tarjetas American Express por favor contáctanos."
      }
    },
    "expiration": {
      "errors": {
        "required": "Debes ingresar la fecha de vencimiento.",
        "invalid": "La fecha de vencimiento ingresada es inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Debes ingresar el código de seguridad.",
        "invalid": "El código de seguridad ingresado es inválido."
      }
    }
  },
  "pt": {
    "saveCard": "Salvar cartão",
    "acceptTerms": "Ao clicar em \"Salvar cartão\", você autoriza Perfit a realizar cobranças no cartão cadastrado conforme os termos e condições de serviço do plano contratado.",
    "notify": {
      "success": "Cartão salvo com sucesso.",
      "error": "Não foi possível validar o cartão cadastrado. Verifique que todos os dados sejam corretos e tente novamente.",
      "contactSupport": "Se o problema persistir, entre em contato conosco.",
      "helpMessage": "Olá, estou tentando cadastrar meu cartão para realizar um pagamento, mas vejo uma mensagem de erro de validação. Podem me ajudar?"
    },
    "name": {
      "label": "Nome e sobrenome",
      "placeholder": "Maria Souza",
      "hint": "Nome do titular, como está escrito no cartão.",
      "errors": {
        "required": "Insira o nome como está escrito no cartão."
      }
    },
    "email": {
      "label": "Endereço de email",
      "placeholder": "email{'@'}dominio.com",
      "hint": "Endereço onde você receberá as notificações de pagamento.",
      "errors": {
        "required": "Você deve cadastrar um email para receber as notificações.",
        "email": "O endereço de email inserido é inválido."
      }
    },
    "docNumber": {
      "label": "Documento",
      "placeholder": "30123123",
      "hint": "Tipo e número de documento do titular.",
      "errors": {
        "required": "Insira o tipo e número de documento do titular.",
        "invalid": "O número do documento inserido é inválido."
      }
    },
    "card": {
      "label": "Dados do cartão",
      "hint": "Número, vencimento e código de segurança."
    },
    "number": {
      "errors": {
        "required": "Você deve inserir o número do cartão.",
        "invalid": "O número do cartão inserido é inválido."
      }
    },
    "expiration": {
      "errors": {
        "required": "Você deve inserir a data de validade.",
        "invalid": "A data de validade inserida é inválida."
      }
    },
    "cvv": {
      "errors": {
        "required": "Você deve inserir o código de segurança.",
        "invalid": "O código de segurança inserido é inválido."
      }
    }
  }
}
</i18n>
