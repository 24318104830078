import { SelectorView } from "@/core/magnet";

export default SelectorView.extend({
  module: "campaigns",
  templates: {
    initial: require("../templates/selector.hbs"),
    selectorSnippet: require("../templates/selectorSnippet.hbs"),
  },
  cache: false,
  query: {
    filter: {
      state: "FINISHED",
    },
    order: "launchDate desc",
  },
  checkboxInputName: "campaigns[]",
  radioInputName: "campaign",
});
