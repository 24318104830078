/* eslint-disable vue/one-component-per-file */
import { EditView } from "@/core/magnet";
import FromsMixin from "@/mixins/froms";
import app from "@/app";
import { _, isEmptyString } from "@/helpers/utils";
import editTpl from "../templates/edit.hbs";
import SenderSelector from "@/components/vue/SenderSelector";
import QRViewer from "@organisms/Optins/QR/QRViewer";
import OptinStylesEditor from "@organisms/Optins/OptinStylesEditor";
import OptinRuleEditor from "@organisms/Optins/OptinRuleEditor";
import { mountComponent } from "@/helpers/vue";

export default EditView.mix(FromsMixin).extend({
  module: "optins",
  templates: {
    initial: editTpl,
    edit: editTpl,
  },
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-embed-modal]": "openEmbedModal",
      "click [name=embed-code]": "selectEmbedCode",
      "click [data-action=open-field-modal]": "openFieldModal",
      "click [data-action=open-interest-modal]": "openInterestModal",
      "click [data-action=toggle-option]": "toggleOption",
      "click [data-action=no-toggle]": "noToggle",
      "submit [data-form=update-option]": "updateOption",
      "keyup [data-action=update-previews]": "updatePreviews",
      "change [data-action=change-embed-mode]": "changeEmbedMode",
      'change [name="confirmation[fromAddress]"]': "changeFrom",
      "submit [data-form=add-from]": "addFrom",
      "submit [data-form=verify-from]": "verifyFrom",
      "click [data-action=open-update-from-modal]": "openUpdateFromModal",
      "submit [data-form=update-from]": "updateFrom",
      "click [data-action=open-send-instructions-modal]": "openSendInstructionsModal",
      "submit [data-form=send-instructions]": "sendInstructions",
      "click [data-target=#design]": "updatePreviews",
    });
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Set default values as changed fields in the create form
    if (self.item.isNew()) {
      self
        .$("form")
        .find("input")
        .each(function () {
          if ($(this).val() !== "") {
            $(this).attr("data-changed", 1).closest("form").attr("data-changed", 1);
          }
        });
    }

    // Forbid navigation
    window.onbeforeunload = function () {
      return "";
    };

    // Set previews templates
    self.previews = {
      form: {
        default: require("../templates/layouts/default.hbs"),
        inline: require("../templates/layouts/inline.hbs"),
        rightImage: require("../templates/layouts/rightImage.hbs"),
        leftImage: require("../templates/layouts/leftImage.hbs"),
        topImage: require("../templates/layouts/topImage.hbs"),
        // rightImageFullHeight: require("../templates/layouts/rightImageFullHeight.hbs"),
        // leftImageFullHeight: require("../templates/layouts/leftImageFullHeight.hbs"),
        // backgroundImage: require("../templates/layouts/backgroundImage.hbs"),
      },
      email: require("../templates/email.hbs"),
    };

    // Bind drag and drop sort component
    self.$("[data-sortable]").dragsort({
      dragSelector: "i",
      dragEnd: function () {
        self.updatePreviews();
      },
    });

    // custom form config
    try {
      this.config = JSON.parse(this.item.attributes.form.config);
    } catch (err) {
      //console.error("Invalid form config: " + this.item.attributes.form.config);
    }
    if (!this.config) {
      this.config = {
        styles: {},
        layout: "default",
      };
    }

    // Update previews
    self.updatePreviews();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    if (app.session.attributes.details.account.features.SENDERSV2 && this.item.attributes.mode !== "SINGLE_OPTIN") {
      mountComponent(
        SenderSelector,
        {
          emailField: this.item.attributes.confirmation.fromAddress,
          emailFieldName: "confirmation[fromAddress]",
          nameFieldName: "confirmation[fromName]",
          includeFields: ["email", "name"],
        },
        "#vue-sender-selector",
      );
    }

    mountComponent(
      OptinStylesEditor,
      {
        config: this.config,
        "onUpdate:config": (e) => {
          this.config = e;
          this.updatePreviews();
        },
      },
      "#vue-styles-editor",
    );
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this,
      isDirty = this.$("[data-form=save]").data("dirty");

    // Check unsaved changes
    if (isDirty) {
      if (!confirm(window.lang.confirmLoseChanges)) {
        this.rebind();
        return false;
      }
    }

    // Allow navigation
    window.onbeforeunload = null;

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();

    // Restore original template
    if (self.originalTemplate !== undefined && self.originalTemplate !== self.template) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },

  // After change from hook
  afterChangeFrom: function (from, fromNameField) {
    fromNameField.val(from.name).attr("data-changed", 1);
    this.updatePreviews();
    return this;
  },

  // Runs after verifying a from
  verifiedCallback: function (response) {
    var from = response.data,
      html = this.renderFromOption(from);
    this.currentSelect.children().last().before(html);
    this.currentSelect.val(from.email).trigger("change");
  },

  // Performs after creating a model
  afterCreate: function () {
    this.openEmbedModal();
  },

  // Update previews
  updatePreviews: function () {
    var self = this,
      form = "[data-form=save]",
      data = $.extend(self.renderData(), {
        data: {
          attributes: self.parseFormData(self.getFormFieldsObject(form, false)),
        },
        formConfig: self.config,
        perfitAdText: window.lang.optins.perfitAdText,
      });

    // Render each preview
    self.$("[data-preview]").each(function () {
      const type = $(this).data("preview");

      if (type === "form") {
        let layout = self.config?.layout || "default";
        if (!self.previews.form[layout]) layout = "default";
        const html = self.previews.form[layout](data);
        $(this).html(html);

        const iframe = document.getElementById("form-preview-iframe");

        iframe.contentDocument.open();
        iframe.contentDocument.write(html);
        iframe.contentDocument.close();

        $(iframe).on("load", () => {
          $(iframe).off("load");
          iframe.style.height = iframe.contentWindow.document.body.offsetHeight + "px";
          const element = iframe.contentWindow.document.body.getElementsByClassName("forms-placeholder-image");

          if (element[0] && !self.config.styles.image) {
            const computedStyles = window.getComputedStyle(element[0]);
            const height = Math.floor(Number(computedStyles.height.slice(0, -2)));
            const width = Math.floor(Number(computedStyles.width.slice(0, -2)));
            self.config = {
              ...self.config,
              imagePlaceholder: {
                sizes: `${height}px &times; ${width}px`,
                height,
                width,
              },
            };
            self.updatePreviews();
          }
        });
      } else if (type === "email") {
        const html = self.previews[type](data);
        $(this).html(html);
      }
    });

    // Return view instance
    return self;
  },

  // Opens the modal with the embed code
  openEmbedModal: function () {
    var self = this,
      data = $.extend({}, self.renderData(), {
        config: window.config,
      });
    self.openModal(require("../templates/modals/embed.hbs"), data);

    mountComponent(
      QRViewer,
      {
        url: `${window.config.optinRoot}subscribe/${app.session.attributes.account}/${this.item.attributes.pubId}`,
      },
      "#vue-QRViewer",
    );

    mountComponent(
      OptinRuleEditor,
      {
        optinId: this.item.attributes.pubId,
      },
      "#vue-optin-rule-editor",
    );
  },

  // Selects the embed code
  selectEmbedCode: function (e) {
    var input = $(e.currentTarget);
    input.select();
  },

  // Triggers an update when a option changes
  toggleOption: function (e) {
    var self = this,
      checkbox = $(e.currentTarget),
      option = checkbox.closest("[data-option]");

    // Toggle checked class
    if (checkbox.is(":checked")) {
      option.addClass("checked");
    } else {
      option.removeClass("checked");
    }

    // Update previews
    self.updatePreviews();
  },

  // Opens the field modal
  openFieldModal: function (e) {
    var self = this,
      target = $(e.currentTarget),
      field = target.closest("[data-option]").data("params"),
      data = $.extend({}, self.renderData(), {
        data: field,
      });
    self.openModal(require("../templates/modals/field.hbs"), data);
  },

  // Opens the interests modal
  openInterestModal: function (e) {
    var self = this,
      target = $(e.currentTarget),
      interest = target.closest("[data-option]").data("params"),
      data = $.extend({}, self.renderData(), {
        data: interest,
      });
    self.openModal(require("../templates/modals/interest.hbs"), data);
  },

  // Updates an option
  updateOption: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      selector = "[data-option=" + data.type + "-" + data.id + "]",
      option = self.$(selector),
      displayer = option.find("[data-role=display-name]");
    e.preventDefault();

    // If the display name is not set, take the name as display name
    if (isEmptyString(data.displayName)) {
      data.displayName = data.name;
    }

    // Set option parameters as data
    option.data("params", data);

    // Show or hide the display name text
    if (data.displayName != data.name) {
      displayer.text("(" + data.displayName + ")");
    } else {
      displayer.text("");
    }

    // Close modal
    $(self.modal).modal("hide");

    // Update previews
    self.updatePreviews();
  },

  // Removes the dirty flag from the form
  removeDirtyFlag: function () {
    this.$("[data-form=save]").removeAttr("dirty").data("dirty", false);
  },

  // Runs before updating a model
  beforeUpdate: function (attributes, form) {
    this.cleanFormErrorMessages(form);
    if (!_.isArray(attributes.lists)) {
      return this.showValidationErrors({ lists: window.lang.lists.selectRequired }, $(form));
    }
    this.removeDirtyFlag();
    return true;
  },

  // Runs before creating a model
  beforeCreate: function (attributes, form) {
    this.cleanFormErrorMessages(form);
    if (!_.isArray(attributes.lists)) {
      return this.showValidationErrors({ lists: window.lang.lists.selectRequired }, $(form));
    }
    this.removeDirtyFlag();
    return true;
  },

  // Go to the list
  back: function () {
    if (!app.router.isCurrentRoute("optins")) {
      app.router.current.reload();
    } else {
      app.router.navigateTo("optins");
    }
    return false;
  },

  // Collects the form data
  getFormData: function (form) {
    var attributes = this.getFormFieldsObject(form, false);
    return this.parseFormData(attributes);
  },

  // Arranges the collected form data
  parseFormData: function (attributes) {
    // If there are not attributes collected, abort
    if (attributes.form === undefined) {
      return attributes;
    }

    // Parse fields and interests
    $.each(["fields", "interests"], function (key, type) {
      if ($.isPlainObject(attributes.form[type])) {
        $.each(attributes.form[type], function (index, value) {
          var selector = '[data-option="' + type + "-" + value.id + '"]',
            params = self.$(selector).data("params");

          // Set values found in data as part of each element
          attributes.form[type][index] = {
            id: params.id,
            displayName: params.displayName,
          };

          // Set format and required
          if (type === "fields") {
            params.format = params.format || "TEXT";
            attributes.form[type][index].required = params.required || false;
          }

          // Set selected
          if (type === "interests") {
            attributes.form[type][index].selected = params.selected || false;
          }
        });
      }

      // Convert object in array
      attributes.form[type] = _.values(attributes.form[type]);
      if (attributes[type] !== undefined) {
        delete attributes[type];
      }
    });

    // Define if the Opt-In is AJAX or not based on its redirect value
    if (attributes.ajax !== undefined) {
      if (attributes.ajax === "1") {
        attributes.form.redirect = "";
      } else {
        attributes.form.successMessage = "";
      }
      delete attributes.ajax;
    }

    // Remove unnecesary fields
    if (attributes.redirect !== undefined) {
      delete attributes.redirect;
    }

    // Remove unnecesary fields
    if (attributes.pubId !== undefined) {
      delete attributes.pubId;
    }

    // Return final attributes
    return attributes;
  },

  // Prevent toggle action
  noToggle: function (e) {
    e.preventDefault();
  },

  // Changes the embed code based on the selected tab
  changeEmbedMode: function (e) {
    var self = this,
      params = $(e.currentTarget).data("params"),
      textarea = $(self.modal).find("textarea"),
      code = textarea.val(),
      attributes = "";

    // Clean up the type and mode values
    code = code.replace(/ data-type="([\w]+)"/, "");
    code = code.replace(/ data-mode="([\w]+)"/, "");

    // Set type value
    attributes = attributes + ' data-type="' + params.type + '"';

    // Set the mode value
    if (params.mode) {
      attributes = attributes + ' data-mode="' + params.mode + '"';
    }

    // Perform replacement with the new values
    code = code.replace(/><\/div>/, attributes + "></div>");

    // Insert the embed code in the textarea
    textarea.val(code);
  },
});
