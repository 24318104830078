export const addStyleSheetURL = (url: string) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
};

export const getElementByElementChildren = (collection: HTMLCollectionOf<Element>, children: HTMLElement) => {
  let elementFound: Element | undefined = undefined;
  let counter = 0;
  while (!elementFound || counter < collection.length) {
    const element = collection.item(counter);
    if (!element) break;

    const hasFooter = element.contains(children);
    if (hasFooter) {
      elementFound = element;
      break;
    }

    counter++;
  }
  return elementFound;
};
