<template>
  <div class="flex rounded-md shadow-sm">
    <div class="relative flex items-stretch flex-grow z-10">
      <input
        ref="inputRef"
        type="text"
        class="focus:ring-sky-400 focus:border-sky-400 w-full rounded-none rounded-l-md pl-4 sm:text-sm border-gray-300 text-gray-400"
        :value="value"
        readonly
        @focus="selectAll"
      />
      <SimpleButton theme="primary" class="rounded-none rounded-r-md" @click="copy()">
        <template #leading
          ><ClipboardCopyIcon v-if="!copied" class="text-white h-5 w-5" /><ClipboardCheckIcon
            v-else
            class="text-white h-5 w-5"
        /></template>
        <span v-if="!copied"> {{ t("button.onclick") }}</span>
        <span v-else> {{ t("button.clicked") }}</span></SimpleButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { ClipboardCopyIcon, ClipboardCheckIcon } from "@heroicons/vue/outline";
import SimpleButton from "@atoms/SimpleButton.vue";
import { useClipboard } from "@vueuse/core";

// Props
const props = withDefaults(
  defineProps<{
    value: string;
  }>(),
  {
    value: "",
  }
);

const { t } = useI18n();
const inputRef = ref();
const { copy, copied } = useClipboard({ source: props.value });

const selectAll = () => {
  inputRef.value.select();
};
</script>

<i18n lang="json">
{
  "es": {
    "button": { "onclick": "Copiar", "clicked": "Copiado" }
  },
  "pt": {
    "button": { "onclick": "Copiar", "clicked": "Copiado" }
  }
}
</i18n>
