<template>
  <div class="flex w-full flex-col justify-center rounded-lg p-16 py-32 text-center">
    <div>
      <DocumentSearchIcon class="mx-auto h-14 w-14 stroke-2 text-gray-200" />
      <span class="mt-2 block text-base font-normal text-gray-300">{{ t("noTemplatesFoundFirst") }}</span>
      <span class="mt-1 block text-sm font-normal text-gray-300">{{ t("noTemplatesFoundSecond") }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
//Icons
import { DocumentSearchIcon } from "@heroicons/vue/outline";

//Utils
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>

<i18n lang="jsonc">
{
  "es": {
    "noTemplatesFoundFirst": "No hemos encontrado plantillas",
    "noTemplatesFoundSecond": "Inténtalo de nuevo modificando el criterio de búsqueda."
  },
  "pt": {
    "noTemplatesFoundFirst": "Nenhum template encontrado",
    "noTemplatesFoundSecond": "Tente novamente modificando os critérios de pesquisa."
  }
}
</i18n>
