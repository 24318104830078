<template>
  <ModalDialog :open="open" @close="closeTagsManager">
    <template #title>
      <div class="flex space-x-6">
        <h1 class="m-0 text-lg font-semibold leading-6 text-gray-600">{{ t("title") }}</h1>
      </div>
    </template>
    <div class="max-w-xl space-y-6">
      <TagsManager :stats="stats" @tagUpdated="onTagUpdated" @tagDeleted="onTagDeleted" @tagCreated="onTagCreated" />
    </div>
  </ModalDialog>
</template>

<script lang="ts" setup>
//Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TagsManager from "./TagsManager.vue";

// Utils
import { useI18n } from "vue-i18n";
import type { StatsByTag } from "@/vue/api/modules/templates/templates.types";

// Domain
import type { Tag } from "@domain/tag";

const { t } = useI18n();

// Props
withDefaults(
  defineProps<{
    open: boolean;
    stats?: StatsByTag;
  }>(),
  {
    stats: undefined,
  }
);

// Emits
const emit = defineEmits<{
  (e: "tagUpdated", tag: Tag): void;
  (e: "tagDeleted", tag: Tag): void;
  (e: "tagCreated", tag: Tag): void;
  (e: "close"): void;
}>();

const closeTagsManager = () => emit("close");

const onTagUpdated = (tag: Tag) => {
  emit("tagUpdated", tag);
};

const onTagDeleted = (tag: Tag) => {
  emit("tagDeleted", tag);
};

const onTagCreated = (tag: Tag) => {
  emit("tagCreated", tag);
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "Administrar etiquetas"
  },
  "pt": {
    "title": "Gerir etiquetas"
  }
}
</i18n>
