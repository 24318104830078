<template>
  <IconButton v-bind="props" @click="onClick">
    <slot />
  </IconButton>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import IconButton from "@atoms/IconButton.vue";

// Store
import { useSessionStore, useAlertStore } from "@store";

// Domain
import type { Permissions } from "@domain/permissions";
import type { Placement, Modifier } from "@popperjs/core";

const sessionStore = useSessionStore();
const alertStore = useAlertStore();

const props = withDefaults(
  defineProps<{
    label: string;
    disabled?: boolean;
    showTooltip?: boolean;
    tooltipProps?: Record<string, any> | null;
    colored?: boolean;
    showFocus?: boolean;
    size?: "min" | "base";
    theme?: "none" | "default" | "info" | "success" | "warning" | "danger";
    placement?: Placement;
    autoPlacements?: Array<Placement>;
    modifiers?: Array<Partial<Modifier<any, any>>>;
    permissions: Permissions;
  }>(),
  {
    disabled: false,
    showTooltip: true,
    tooltipProps: null,
    colored: false,
    showFocus: true,
    size: "base",
    theme: "default",
    placement: "bottom",
    autoPlacements: () => ["bottom", "top", "bottom-end", "top-end"],
    modifiers: undefined,
  },
);

const emit = defineEmits<{
  click: [MouseEvent];
}>();

const permission = computed<boolean>(() => {
  return props.permissions.every((p) => sessionStore.hasPermission(p));
});

const onClick = (event: MouseEvent) => {
  if (!permission.value) {
    alertStore.showPermissionDenied(props.permissions);

    return;
  }
  emit("click", event);
};
</script>
