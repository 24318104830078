import { Result } from "neverthrow";
import type { AuthenticationObject } from "@domain/authentication";
import type { TiendanubeStoreData } from "@domain/tiendanube";
import type { Industry } from "@domain/account";

// Login
export interface LoginParams {
  user: string;
  password: string;
  account?: string;
}

export interface LoginAccountsResponse {
  accounts: string[];
}

export type AuthenticationErrorTypes = "VALIDATION_ERROR" | "UNAUTHORIZED" | "ACCOUNT_DISABLED" | "PASSWORD_EXPIRED";

export interface AuthenticationErrors {
  type: AuthenticationErrorTypes;
}
export interface LoginInvalidFieldsResponse extends AuthenticationErrors {
  type: "VALIDATION_ERROR";
  invalidFields: {
    user?: string;
    password?: string;
  };
}

export interface LoginCredentialsErrorResponse extends AuthenticationErrors {
  type: "UNAUTHORIZED";
}

export interface LoginAccountDisabledErrorResponse extends AuthenticationErrors {
  type: "ACCOUNT_DISABLED";
}

export interface LoginPasswordExpiredErrorResponse extends AuthenticationErrors {
  type: "PASSWORD_EXPIRED";
}

export type LoginErrorsResponse =
  | LoginInvalidFieldsResponse
  | LoginCredentialsErrorResponse
  | LoginAccountDisabledErrorResponse
  | LoginPasswordExpiredErrorResponse;

export const isLoginAccountsResponse = (
  r: AuthenticationObject | LoginAccountsResponse,
): r is LoginAccountsResponse => {
  return (r as LoginAccountsResponse)?.accounts !== undefined;
};

export const isLoginInvalidFieldsResponse = (
  r: LoginInvalidFieldsResponse | LoginCredentialsErrorResponse,
): r is LoginInvalidFieldsResponse => {
  return (r as LoginInvalidFieldsResponse)?.type === "VALIDATION_ERROR";
};

export const isLoginCredentialsErrorResponse = (
  r: LoginInvalidFieldsResponse | LoginCredentialsErrorResponse,
): r is LoginCredentialsErrorResponse => {
  return (r as LoginCredentialsErrorResponse)?.type === "UNAUTHORIZED";
};

// Login Tiendanube
export interface LoginTiendanubeParams {
  code: string;
}

export interface LoginTiendanubeSignUp {
  result: "signup_required";
  preinit: string;
  store_id: string;
  store: TiendanubeStoreData;
}

export interface LoginTiendanubeLogin extends AuthenticationObject {
  result: "login";
}

export interface LoginTiendanubeSignupResponse {
  email: string;
  website: string;
  address: string;
  businessName: string;
}

// Reset Password
export interface ResetPasswordParams {
  user: string;
  account?: string;
}

export type ResetPasswordErrorTypes = "ACCOUNT_REQUIRED" | "VALIDATION_ERROR" | "UNAUTHORIZED";
export interface ResetPasswordError {
  type: ResetPasswordErrorTypes;
}
export interface ResetPaswordValidationError extends ResetPasswordError {
  type: "VALIDATION_ERROR";
  invalidFields: {
    email?: string;
  };
}
export interface ResetPaswordAccountRequiredError extends ResetPasswordError {
  type: "ACCOUNT_REQUIRED";
}
export interface ResetPaswordUnauthorizedError extends ResetPasswordError {
  type: "UNAUTHORIZED";
}
export type ResetPasswordErrors =
  | ResetPaswordValidationError
  | ResetPaswordAccountRequiredError
  | ResetPaswordUnauthorizedError;

// Update Password
export interface UpdatePasswordTokenParams {
  newPassword: string;
  resetToken: string;
}

export interface UpdatePasswordCredentialsParams {
  newPassword: string;
  user: string;
  password: string;
  account?: string;
}

export type UpdatePasswordParams = UpdatePasswordTokenParams | UpdatePasswordCredentialsParams;

export const isUpdatePasswordTokenParams = (
  p: UpdatePasswordTokenParams | UpdatePasswordCredentialsParams,
): p is UpdatePasswordTokenParams => {
  return (p as UpdatePasswordTokenParams).resetToken !== undefined;
};

export type UpdatePasswordErrorTypes = "VALIDATION_ERROR" | "INVALID_REQUEST";
export interface UpdatePasswordError {
  type: UpdatePasswordErrorTypes;
}
export interface UpdatePasswordInvalidFieldsError extends UpdatePasswordError {
  type: "VALIDATION_ERROR";
  invalidFields?: {
    newPassword?: string;
  };
}

export interface UpdatePasswordInvalidError extends UpdatePasswordError {
  type: "INVALID_REQUEST";
}

export type UpdatePasswordErrors = UpdatePasswordInvalidFieldsError | UpdatePasswordInvalidError;

// Sign Up
export interface SignUpParams {
  email: string;
  password: string;
  code: string;
  country: string;
  website: string;
  store: string;
  businessName: string;
  lang: string; // "es" | "pt"
}

export interface SignUpData {
  website: string;
  store: string;
  email: string;
  country: string;
}

export type SignUpErrorTypes = "VALIDATION_ERROR";

export interface SignUpError {
  type: UpdatePasswordErrorTypes;
}

export interface SignUpInvalidFieldsError extends SignUpError {
  type: "VALIDATION_ERROR";
  invalidFields?: {
    code?: string;
    password?: string;
    email?: string;
    name?: string;
  };
}

export type SignUpErrors = SignUpInvalidFieldsError;

// * ----- Signup Tiendanube -----
export interface SignupTiendanubeParams {
  preinit: string;
  email: string;
  website: string;
  country: string;
  businessName: string;
  industry: Industry | "";
  address: string;
  phone: string;
  tnPlan: string;
  tnCreatedAt: string;
  lang: string; // "es" | "pt"
}

export type SignupTiendanubeErrorTypes = "VALIDATION_ERROR";

export interface SignupTiemdanubeError {
  type: SignupTiendanubeErrorTypes;
}

export type SignupTiendanubeErrors = SignupTiemdanubeError;

// * ---------- Service ----------
export interface AuthenticationService {
  login: (params: LoginParams) => Promise<Result<AuthenticationObject | LoginAccountsResponse, LoginErrorsResponse>>;
  loginTiendanube: (
    params: LoginTiendanubeParams,
  ) => Promise<Result<LoginTiendanubeSignUp | LoginTiendanubeLogin, undefined>>;
  resetPassword: (params: ResetPasswordParams) => Promise<Result<undefined, ResetPasswordErrors>>;
  updatePassword: (params: UpdatePasswordParams) => Promise<Result<AuthenticationObject, UpdatePasswordErrors>>;
  signup: (params: SignUpParams) => Promise<Result<AuthenticationObject, SignUpErrors>>;
  signupTiendanube: (params: SignupTiendanubeParams) => Promise<Result<AuthenticationObject, SignupTiendanubeErrors>>;
}
