var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"messenger\" data-messenger=\"main\">\n  <span></span>\n</div>\n<div id=\"vue-sidebar-navigation\" class=\"h-full\" />\n<div class='mobile-splash'>\n  <div class='head'>\n    <h4>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"hello")), depth0))
    + "\n    </h4>\n    <div class=\"circle-logo\"></div>\n  </div>\n  <div class='body'>\n    <p>\n      "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"copy")), depth0))
    + "\n    </p>\n    <div class=\"mt-8\">\n      <a data-action='goto-dashboard' class='btn btn-primary'>\n        "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"mobileSpash"))) && lookupProperty(stack1,"gotoDashboard")), depth0))
    + "\n      </a>\n    </div>\n    <button>\n  </div>\n</div>\n<div class=\"modal fade\" tabindex=\"-1\" data-backdrop=\"static\" data-modal=\"main\"></div>\n<div class=\"modal fade\" tabindex=\"-1\" data-backdrop=\"static\" data-modal=\"selector\"></div>\n<div class=\"modal confirm fade\" tabindex=\"-1\" data-backdrop=\"static\" data-modal=\"confirm\">\n  <div class=\"modal-dialog\">\n    <form>\n      <div class=\"modal-content\">\n        <div class=\"modal-header\">\n          <button data-dismiss=\"modal\" class=\"close\" type=\"button\">\n            <span aria-hidden=\"true\">&times;</span>\n          </button>\n          <h4 class=\"modal-title\">\n            <span></span>\n          </h4>\n        </div>\n        <div class=\"modal-body\">\n          <div class=\"modal-body-padded\">\n            <span class=\"question\"></span>\n          </div>\n        </div>\n        <div class=\"modal-footer\">\n          <button type=\"reset\" class=\"btn btn-link\" data-dismiss=\"modal\" id=\"confirm-modal-cancel-btn\">\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n          </button>\n          <button type=\"submit\" class=\"btn btn-primary\" data-not-disable=\"1\" id=\"confirm-modal-accept-btn\">\n            "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"accept")), depth0))
    + "\n          </button>\n        </div>\n      </div>\n    </form>\n  </div>\n</div>";
},"useData":true});