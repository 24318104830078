<template>
  <div class="max-w- mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
      <p class="mt-2 text-sm leading-6 text-gray-500">
        {{ t("subtitle") }}
      </p>
    </div>

    <AlertBox v-show="!tokenError && email && password" theme="info" class="mt-4">
      {{ t("updatePasswordAlert") }}
    </AlertBox>
    <AlertBox v-show="tokenError" theme="danger" class="mt-4">
      {{ t("invalidRequestError") }}
    </AlertBox>

    <div class="mt-8">
      <form v-if="!tokenError" @submit.prevent="updatePassword">
        <div class="relative">
          <PasswordInput
            v-model="passwords.password"
            :label="t('passwordLabel')"
            :placeholder="t('passwordPlaceholder')"
            :error="passwordErrorMessage"
            autocomplete="new-password"
            @focus="clearPasswordValidation"
            @input="clearPasswordValidation"
          />
        </div>
        <SimpleButton
          type="submit"
          :disabled="tokenError"
          :loading="submitting"
          :class="[{ 'pointer-events-none select-none': submitting }, 'mt-6 w-full']"
        >
          <template #leading>
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </template>
          {{ t("updatePassword") }}
        </SimpleButton>
      </form>

      <div class="mt-5 text-center text-sm leading-6">
        <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
          t("loginProblems")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from "vue";

// components
import SimpleButton from "@atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";
import PasswordInput from "@organisms/PasswordInput";

// Icons
import { CheckIcon } from "@heroicons/vue/solid";

// Utils
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { analyzePasswordStrength } from "@helpers/validators";

// Application
import { useAuthenticationApp, useRouterApp } from "@application";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const authApp = useAuthenticationApp();
const routerApp = useRouterApp();

const props = withDefaults(
  defineProps<{
    resetToken?: string;
    password?: string;
    email?: string;
    account?: string;
  }>(),
  {
    resetToken: "",
    password: undefined,
    email: undefined,
    account: undefined,
  },
);

const emit = defineEmits<{
  helpArticle: [void];
}>();

const openHelpArticle = () => {
  emit("helpArticle");
};

// Data
const passwords = reactive({
  password: "",
});

// Validation
const tokenError = ref(false);

const passwordForceInvalid = ref(false);
const customPasswordValidation = () => {
  return !passwordForceInvalid.value;
};

const setPasswordInvalid = () => {
  passwordForceInvalid.value = true;
  v$.value.email.$touch();
};

const samePasswordError = ref(false);
const samePasswordValidation = () => {
  return !samePasswordError.value;
};

const setSamePasswordError = () => {
  samePasswordError.value = true;
  v$.value.email.$touch();
};

const clearPasswordValidation = () => {
  passwordForceInvalid.value = false;
  samePasswordError.value = false;
  v$.value.password.$reset();
};

const passwordErrorMessage = computed(() => {
  if (passwordForceInvalid.value) return t("invalidPasswordFormat");
  if (samePasswordError.value) return t("samePasswordError");
  if (v$.value.password?.$errors[0]?.$validator === "format") return t("invalidPassword");
  if (v$.value.password.$error) return t("fieldRequired");

  return undefined;
});

const validationRules = {
  password: {
    required,
    format: (value: string) => analyzePasswordStrength(value).score >= 3,
    customPasswordValidation,
    samePasswordValidation,
  },
};

const v$ = useVuelidate(validationRules, passwords);

const submitting = ref(false);
const updatePassword = async () => {
  try {
    // Fields validation
    const validation = await v$.value.$validate();
    if (!validation) return;

    if (!props.resetToken && passwords.password === props.password) {
      setSamePasswordError();
      return;
    }

    submitting.value = true;

    const res = await authApp.updatePassword(
      props.resetToken
        ? {
            newPassword: passwords.password,
            resetToken: props.resetToken,
          }
        : {
            newPassword: passwords.password,
            password: props.password ?? "",
            user: props.email ?? "",
            account: props.account ?? undefined,
          },
    );

    // Logged in
    if (res.isOk() && !res.value) {
      routerApp.navigate({
        path: "home",
      });
      location.reload();
      return;
    }

    // Invalid fields
    if (res.isErr() && res.error.type === "VALIDATION_ERROR") {
      setPasswordInvalid();
    }

    // Invalid fields
    if (res.isErr() && res.error.type === "INVALID_REQUEST") {
      tokenError.value = true;
    }
  } catch (e) {
    // No handler
  } finally {
    submitting.value = false;
  }
};

onMounted(() => {
  // Handle show no resetToken error
  if (!props.resetToken && (!props.email || !props.password)) {
    tokenError.value = true;
  }
});
</script>

<i18n lang="json">
{
  "es": {
    "title": "Actualiza tu contraseña",
    "subtitle": "Ingresa una nueva contraseña para iniciar sesión en tu cuenta.",
    "loginProblems": "¿Problemas para ingresar?",
    "updatePassword": "Confirmar",
    "fieldRequired": "Este campo es requerido",
    "invalidPasswordFormat": "El formato es inválido",
    "samePasswordError": "Ingresa una contraseña distinta a la actual",
    "passwordLabel": "Nueva contraseña",
    "passwordPlaceholder": "Ingresa tu nueva contraseña",
    "invalidPassword": "La contraseña no es lo suficientemente segura",
    "invalidRequestError": "El link utilizado es inválido o ha expirado. Por favor genera uno nuevo.",
    "updatePasswordAlert": "Tu contraseña ha expirado y debe ser actualizada."
  },
  "pt": {
    "title": "Atualize sua senha",
    "subtitle": "Insira uma nova senha para fazer login em sua conta.",
    "loginProblems": "Problemas ao entrar?",
    "updatePassword": "Confirmar",
    "fieldRequired": "Este campo é obrigatório",
    "invalidPasswordFormat": "O formato é inválido",
    "samePasswordError": "Insira uma senha diferente da atual",
    "passwordLabel": "Nova senha",
    "passwordPlaceholder": "Insira sua nova senha",
    "invalidPassword": "A senha não é suficientemente segura",
    "invalidRequestError": "O link utilizado é inválido ou expirou. Por favor, gere um novo.",
    "updatePasswordAlert": "Sua senha expirou e precisa ser atualizada."
  }
}
</i18n>
