<template>
  <div class="space-y-2">
    <transition-group
      leave-active-class="transition duration-300 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0 transform translate-x-10"
      enter-active-class="transition duration-300 ease-in-out"
      enter-to-class="opacity-100"
      enter-from-class="opacity-0 transform translate-x-10"
    >
      <div
        v-for="(version, index) in versions"
        :key="version.key"
        class="flex items-center"
      >
        <div class="flex-grow">
          <SubjectInput
            v-model="version.subject"
            :name="`version` + version.key"
            :fields="fields"
            :data-changed="dirty"
            :placeholder="placeholder"
          />
        </div>
        <div class="ml-3">
          <button
            v-if="index > 0"
            type="button"
            class="rounded-full flex items-center focus:outline-none focus:ring-0"
            :disabled="!canRemove"
            :class="{
              'text-gray-400 hover:text-red-600': canRemove,
              'text-gray-200 hover:text-gray-200': !canRemove,
            }"
            tabindex="-1"
            @click="removeVersion(version)"
          >
            <span class="sr-only">Eliminar versión</span>
            <HeroIcon icon="trash" class="h-6 w-6" />
          </button>
          <button
            v-else
            type="button"
            class="rounded-full flex items-center focus:outline-none focus:ring-0"
            :disabled="!canAdd"
            :class="{
              'text-gray-400 hover:text-green-600': canAdd,
              'text-gray-200 hover:text-gray-200': !canAdd,
            }"
            tabindex="-1"
            @click="addVersion"
          >
            <span class="sr-only">Agregar versión</span>
            <HeroIcon icon="plus" class="h-6 w-6" />
          </button>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { reactive, computed, ref } from "vue";
import SubjectInput from "@/components/vue/SubjectInput";
import HeroIcon from "@/components/vue/HeroIcon";

let i = 1;
const uuid = () => {
  return i++;
};

export default {
  components: { SubjectInput, HeroIcon },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxVersions: {
      type: Number,
      default: 4,
    },
    minVersions: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const versions = reactive(new Set());
    const dirty = ref(null);

    props.subjects
      .filter((i) => i !== "")
      .map((i) => {
        return { subject: i, key: uuid() };
      })
      .forEach((i) => versions.add(i));

    const removeVersion = (subject) => {
      versions.delete(subject);
      dirty.value = 1;
    };

    const addVersion = () => {
      versions.add({ subject: "", key: uuid() });
    };

    while (versions.size < props.minVersions) {
      addVersion();
    }

    const canAdd = computed(() => versions.size < props.maxVersions);
    const canRemove = computed(() => versions.size > 2);

    return { versions, addVersion, removeVersion, canAdd, canRemove, dirty };
  },
};
</script>
