<template>
  <div class="flex items-end space-x-4">
    <h1 :class="[sizeClass, themeClass, 'm-0 mr-2 font-semibold leading-8 sm:truncate']">
      <slot></slot>
    </h1>
    <HeaderLinks v-if="links" :links="links" class="my-auto" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

//Components
import HeaderLinks from "./HeaderLinks/HeaderLinks.vue";

//Types
import type { Links } from "./HeaderLinks/HeaderLinks.vue";

export type Themes = "white" | "gray";
export type Size = "medium" | "extraLarge";

const props = withDefaults(
  defineProps<{
    links?: Links;
    theme?: Themes;
    size?: Size;
  }>(),
  {
    theme: "gray",
    size: "medium",
    links: undefined,
  }
);

const themeClass = computed(() => {
  const classes: Record<Themes, string> = {
    gray: "text-white",
    white: "text-gray-700",
  };
  return classes[props.theme];
});

const sizeClass = computed(() => {
  const classes: Record<Size, string> = {
    medium: "text-md sm:text-lg",
    extraLarge: "text-xl sm:text-2xl",
  };
  return classes[props.size];
});
</script>
