import { EditView } from "@/core/magnet";
import ContentMixin from "@/mixins/content";
import FromsMixin from "@/mixins/froms";
import { _, objHas, cleanHTML } from "@/helpers/utils";
import app from "@/app";
const moment = window.moment;

export default EditView.mix(ContentMixin, FromsMixin).extend({
  module: "drips",
  templates: {
    initial: require("../templates/show.hbs"),
    show: require("../templates/show.hbs"),
    edit: require("../templates/edit.hbs"),
  },
  cache: false,
  validateOnSave: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-reschedule-modal]": "openRescheduleModal",
      "submit [data-form=reschedule]": "reschedule",
      "click [data-action=reschedule-to-now]": "rescheduleToNow",
      "click [data-action=open-send-test-modal]": "openSendTestModal",
      "submit [data-form=send-test]": "sendTest",
      'change [name="from[from]"]': "changeFrom",
      'change [name="from[replyTo]"]': "changeFrom",
      "submit [data-form=add-from]": "addFrom",
      "submit [data-form=verify-from]": "verifyFrom",
      "click [data-action=open-update-from-modal]": "openUpdateFromModal",
      "submit [data-form=update-from]": "updateFrom",
      "click [data-action=open-send-instructions-modal]":
        "openSendInstructionsModal",
      "submit [data-form=send-instructions]": "sendInstructions",
      "shown.bs.tab [data-form=save] [data-toggle=tab]": "afterTabChange",
      "show.bs.tab [data-form=save] [data-toggle=tab]": "beforeTabChange",
      "click [data-action=duplicate]": "duplicate",
      "click [data-action=pause]": "pause",
      "click [data-action=resume]": "resume",
      "click [data-action=insert-field]": "insertField",
      "change #custom-reply": "changeCustomReply",
      "click [data-action=open-replies-modal]": "openRepliesModal",
      "click [data-action=archive]": "archive",
      "click [data-action=unarchive]": "unarchive",
      "click [data-action=launch]": "launch",
    });
  },

  // Performs the needed bindings before renderization
  beforeRender: function (callback) {
    var parsed = this.item.parseNodes();
    if (!parsed) {
      this.displayMessage(window.lang.drips.parseError, 5000, "error");
      app.router.navigateTo("drips");
      return false;
    }
    if (this.item.get("state") === "DRAFT") {
      this.changeTemplate(require("../templates/edit.hbs"));
    }
    callback();
  },

  // Runs custom actions after renderization
  afterRender: function () {
    // Save a reference to the edit form
    this.editForm = $("[data-form=save]").get(0);

    // Renders the tags, editors and pickers
    this.renderTags();
    this.renderEditors();
    this.renderDatepickers();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();

    // Forbid navigation
    if (this.item.get("state") === "DRAFT") {
      this.preventNavigation = true;
      window.onbeforeunload = function () {
        return "";
      };
    }
  },

  // Shows the datetime picker to change the drip date
  openRescheduleModal: function (e) {
    var date = $(e.currentTarget).data("date"),
      id = $(e.currentTarget).data("id"),
      formattedDate = moment(date)
        .tz(window.config.timezone)
        .format("YYYY-MM-DD HH:mm");
    return this.openModal(
      require("../templates/modals/reschedule.hbs"),
      { id: id, date: formattedDate },
      function () {
        this.getModal()
          .find("[name=launchDate]")
          .datetimepicker({
            defaultDate: formattedDate,
            inline: true,
            locale: "es",
            sideBySide: true,
            format: "YYYY-MM-DD HH:mm",
            timeZone: window.config.timezone,
            minDate: Date.now() - 1000 * 60 * 60,
            useCurrent: false,
          });
      }.bind(this)
    );
  },

  // Changes the launch date to now
  rescheduleToNow: function (e) {
    var form = $(e.currentTarget).closest("form");
    form.find("[name=launchDate]").val(moment().format("YYYY-MM-DD HH:mm"));
    form.trigger("submit");
  },

  // Changes the launch date
  reschedule: function (e) {
    var form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();
    this.cleanFormErrorMessages(e.currentTarget);
    data.launchDate = moment
      .tz(data.launchDate, window.config.timezone)
      .format();
    this.item
      .rescheduleNode(data.id, { launchDate: data.launchDate })
      .done(
        function () {
          this.getModal().modal("hide");
          this.displayMessage(window.lang.drips.feedback.dateChanged);
          this.reload();
        }.bind(this)
      )
      .fail(
        function () {
          this.showValidationErrors(
            {
              launchDate: window.lang.drips.invalidDate,
            },
            form
          );
        }.bind(this)
      );
  },

  // Render the datepickers inputs
  renderDatepickers: function () {
    var view = this;
    $("[data-role=datetimepicker]").each(function (index, element) {
      var defaultDate = $(element).attr("value");
      $(element)
        .datetimepicker({
          defaultDate: defaultDate,
          inline: false,
          locale: "es",
          sideBySide: true,
          format: "YYYY-MM-DD HH:mm",
          timeZone: window.config.timezone,
          useCurrent: false,
        })
        .on("dp.hide", function (e) {
          view.updateDate(e);
        });
    });
  },

  // Unbind datetimepicker
  unbindPlugins: function () {
    var picker = $("[data-role=datetimepicker]").data("DateTimePicker");
    if (picker) {
      picker.destroy();
    }

    while (window.tinymce.editors.length > 0) {
      window.tinymce.remove(window.tinymce.editors[0]);
    }

    return this;
  },

  // Render the tags
  renderTags: function () {
    var view = this,
      tagsInput = $(".tags-input"),
      options = tagsInput.data("options"),
      tags = new window.Bloodhound({
        datumTokenizer: window.Bloodhound.tokenizers.obj.whitespace("id"),
        queryTokenizer: window.Bloodhound.tokenizers.whitespace,
        local: options,
      }),
      lastRemoved = false;

    // Setups the typeahead tags component
    tags.initialize();
    tagsInput.tagsinput({
      tagClass: "label label-outline",
      addOnBlur: false,
      typeaheadjs: {
        name: "tags",
        displayKey: "id",
        valueKey: "id",
        source: tags.ttAdapter(),
      },
    });

    // Manage focus and blur
    this.$(".tt-input")
      .on("focus", function (e) {
        var target = $(e.currentTarget);
        target.addClass("tt-editing").val("").removeAttr("value");
        lastRemoved = false;
      })
      .on("blur", function (e) {
        var target = $(e.currentTarget),
          value = target.val(),
          internal = target.data("internal"),
          list = tagsInput.tagsinput("items");
        if ($.inArray(value, list) === -1 && lastRemoved !== internal) {
          tagsInput.tagsinput("add", value);
          tagsInput.attr("data-changed", 1);
        }
        target.removeClass("tt-editing").val("").removeAttr("value");
      });

    // Check last removed item
    tagsInput.on("itemRemoved", function (e) {
      lastRemoved = e.item;
      tagsInput.attr("data-changed", 1);
      view.$(".tt-input").data("internal", lastRemoved);
    });
  },

  // Transforms textareas to editors
  renderEditors: function () {
    var view = this,
      fields = this.item.additionalsData.fields;

    window.tinymce.init(
      $.extend(window.tinymceDefaults, {
        selector: "textarea",
        branding: false,
        menubar: false,
        toolbar: window.tinymceDefaults.toolbar + "| fields",
        height: 500,
        setup: function (editor) {
          editor.addButton("fields", {
            type: "menubutton",
            text: window.lang.drips.insert,
            icon: false,
            menu: _.map(fields, function (field) {
              return {
                text: field.name,
                onclick: function () {
                  editor.insertContent("${CONTACT:" + field.tag + "}");
                },
              };
            }),
          });
          editor.on("change", function () {
            $(view.editForm).data("dirty", true);
          });
        },
      })
    );
  },

  // Runs before the tab changes
  beforeTabChange: function (e) {
    var attributes = this.getFormData(this.editForm);

    // Validate before switching to summary
    if ($(e.currentTarget).data("target") === "#summary") {
      return this.beforeCreate(attributes, this.editForm);
    }
  },

  // Runs after the tab change
  afterTabChange: function (e) {
    // Summary
    if ($(e.currentTarget).data("target") === "#summary") {
      this.showSummary();
    }
  },

  // Shows the summary
  showSummary: function () {
    var form = $("[data-form=save]").get(0),
      template = require("../templates/summary.hbs"),
      tab = $("#summary"),
      box = tab.find("[data-role=content]"),
      loader = tab.find(".loading-box"),
      data = this.getFormData(form);

    // If the item is saving, try again later
    if ($.active > 0) {
      return window.setTimeout(
        function () {
          this.showSummary();
        }.bind(this),
        1000
      );
    }

    // Show loader
    loader.removeClass("hidden");
    box.addClass("hidden");

    // Request preview and render
    this.item.saveDraft(data).done(
      function () {
        this.item.preview().done(
          function (response) {
            delete response.data.id;
            this.item.set(response.data).parseNodes();
            loader.addClass("hidden");
            box.html(template(this.renderData())).removeClass("hidden");
            ["included", "excluded"].forEach(function (type) {
              var html = $("#" + type + "-lists")
                .find("ul")
                .clone()
                .find("input")
                .remove()
                .end()
                .html();
              if (html) {
                html = "<ul>" + html + "</ul>";
              } else {
                html = "-";
              }
              $("#" + type + "-lists-preview").html(html);
            });
          }.bind(this)
        );
      }.bind(this)
    );
  },

  // Saves a copy of the current drip and redirects to the create form
  duplicate: function () {
    this.item.duplicate().done(function (response) {
      app.router.navigateTo(`drips/${response.data.id}`);
    });
  },

  // Opens the modal to sends a drip test
  openSendTestModal: function () {
    var attributes = this.getFormData(this.editForm);
    if (this.beforeCreate(attributes, this.editForm)) {
      $.getJSON(
        "campaigns/testcontacts",
        function (response) {
          this.openModal(
            require("../templates/modals/sendTest.hbs"),
            $.extend({}, this.renderData(), { testRecipients: response.data })
          );
        }.bind(this)
      );
    }
  },

  // Opens the modal to sends a drip test
  sendTest: function (e) {
    var form = e.currentTarget,
      emails = $(form).serializeObject().emails,
      errors = {},
      data;
    e.preventDefault();

    // Validate edit form
    if (!this.validateFormFields(this.editForm)) {
      return false;
    }

    // Replace spaces, semicolons and new lines by commas and split
    emails = _.compact(
      emails
        .replace(/[ |;]/g, ",")
        .replace(/\r?\n|\r/g, ",")
        .split(",")
    );

    // Remove previos errors
    this.cleanFormErrorMessages(form);

    // Validate emails
    emails.forEach(
      function (email) {
        if (!this.rules.email.test(email)) {
          errors.emails = window.lang.drips.invalidEmails;
        }
      }.bind(this)
    );
    if (_.size(errors)) {
      return this.showValidationErrors(errors, $(form));
    }

    // Create payload
    data = { recipients: emails };

    // Send test via AJAX
    this.item.saveDraft(this.getFormData(form)).done(
      function () {
        this.item.sendTest(data).done(
          function () {
            this.displayMessage(window.lang.drips.feedback.testSent);
            this.getModal().modal("hide");
          }.bind(this)
        );
      }.bind(this)
    );
  },

  // Pauses the drip
  pause: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Pause via AJAX
    this.item.pause().done(
      function () {
        this.displayMessage(window.lang.drips.feedback.paused);
        this.back();
      }.bind(this)
    );
  },

  // Unarchives the drip
  unarchive: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Pause via AJAX
    this.item.unarchive().done(
      function () {
        this.displayMessage(window.lang.drips.feedback.unarchived);
        this.back();
      }.bind(this)
    );
  },

  // Launches the drip
  launch: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    this.confirmModal(
      window.lang.drips.confirmLaunch,
      button,
      window.lang.drips.launch,
      "info",
      "data-confirm-launch",
      function () {
        this.item.saveDraft(this.getFormData(this.editForm)).done(
          function () {
            this.item.launch().done(
              function () {
                this.displayMessage(window.lang.drips.feedback.launched);
                $("[data-form=save]").data("dirty", false);
                this.back();
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  },

  // Archives the drip
  archive: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Pause via AJAX
    this.item.archive().done(
      function () {
        this.displayMessage(window.lang.drips.feedback.archived);
        this.back();
      }.bind(this)
    );
  },

  // Resumes the drip
  resume: function (e) {
    var button = $(e.currentTarget);

    // Abort if the button has a confirm data attribute
    if (button.attr("data-confirm")) {
      return false;
    }

    // Pause via AJAX
    this.item.resume().done(
      function () {
        this.displayMessage(window.lang.drips.feedback.resumed);
        this.back();
      }.bind(this)
    );
  },

  // Gets a form data
  getFormData: function () {
    var data = this.getFormFieldsObject(this.editForm, false);
    data.nodes = _.map(
      data.nodes,
      function (node) {
        var base;
        node.id = parseInt(node.id, 10);
        base = _.findWhere(this.item.get("nodes"), { id: node.id });
        node.launchDate = moment
          .tz(node.launchDate, window.config.timezone)
          .format();
        node.content.html = cleanHTML(
          window.tinyMCE.get("html" + node.id).getContent()
        );
        node.relation = base.relation;
        node.content.append = base.content.append;
        return node;
      }.bind(this)
    );
    data.flow = this.item.get("flow");
    data.language = data.language || "es";
    if (objHas(data, "nestContents")) {
      data.nestContents = true;
    } else {
      data.nestContents = false;
    }
    if (!objHas(data, "exclude")) {
      data.exclude = {};
    }
    if (!objHas(data, "from", "replyTo")) {
      data.from.replyTo = data.from.from;
      data.from.replyToName = data.from.fromName;
    }
    return data;
  },

  // Runs before creating a model
  beforeCreate: function (attributes, form) {
    var errors = {};

    // Abort if the form has errors
    if (!this.validateFormFields(form)) {
      return false;
    }

    // Abort if the content is not complete
    _.each(attributes.nodes, function (node, index) {
      if (node.content.html === "") {
        errors["nodes[" + index + "][content][html]"] =
          window.lang.invalidRequired;
      }
    });
    if (_.size(errors)) {
      this.showValidationErrors(errors, $(form));
      return false;
    }

    // Abort if the drip has no lists
    if (!objHas(attributes, "include", "lists")) {
      this.cleanFormErrorMessages(form);
      this.showValidationErrors({ lists: "lists" }, $(form));
      return false;
    }
    return true;
  },

  // Runs after verifying a from
  verifiedCallback: function (response) {
    var from = response.data,
      html = this.renderFromOption(from);
    $("[data-role=from-selector] select").each(function () {
      $(this).children().last().before(html);
    });
    this.currentSelect.val(from.email).trigger("change");
  },

  // Returns to the previous page
  back: function () {
    app.router.navigateToSectionRoot();
  },

  // Inserts a field in ths subject input
  insertField: function (e) {
    var tag = $(e.currentTarget).data("tag"),
      subject = $(e.currentTarget).closest(".input-group").find("input"),
      prefix = "CONTACT:";
    e.preventDefault();

    // Translate tag to composer v2
    prefix = "contact.";
    tag = tag.toLowerCase();
    if (tag === "lname") tag = "last_name";
    if (tag === "fname") tag = "first_name";

    window.insertText("${" + prefix + tag + "}", subject);
  },

  // Removes an error message
  removeErrorMessage: function (input) {
    var parent = $(input).closest(".form-group");
    parent.removeClass("has-error");
    parent.find(".error-message").remove();
  },

  // Updates the human readable date
  updateDate: function (e) {
    var view = this,
      input = $(e.currentTarget),
      newDate = moment.tz(input.val(), window.config.timezone),
      nodeId = input.data("node"),
      item = this.item,
      nodes = item.attributes.nodes,
      node = nodes[parseInt(nodeId, 10)],
      previousDate = node.launchDate,
      nodesCount = nodes.length,
      text,
      diff;

    // Clean error
    this.removeErrorMessage(input.get(0));

    // Abort if the node is the last one
    if (node.id === nodes.length) {
      return false;
    }

    // Calculate diff between current and new date
    diff = newDate.diff(previousDate);
    if (Math.abs(diff) < 1000 * 60) {
      return false;
    }
    text = require("../templates/modals/rescheduleText.hbs")({
      lang: window.lang,
    });

    // Set date for the updated node and update UI
    node.launchDate = newDate.format();
    item.parseNodes();
    $("#description-" + nodeId).html(node.descriptionWithDate);

    // If the user confirms, udpate following nodes
    this.confirmModal(
      text,
      null,
      window.lang.drips.reschedule,
      "info",
      null,
      function () {
        var i = nodeId + 1;
        for (i; i < nodesCount; i++) {
          node = nodes[i];
          node.launchDate = moment(node.launchDate)
            .add(diff, "milliseconds")
            .format();
        }
        item.parseNodes();
        i = nodeId + 1;
        for (i; i < nodesCount; i++) {
          $("#description-" + i).html(nodes[i].descriptionWithDate);
          $("[data-node=" + i + "]").val(
            moment(nodes[i].launchDate).format("YYYY-MM-DD HH:mm")
          );
          view.removeErrorMessage($("[data-node=" + i + "]"));
        }
      }
    );
  },

  // Changed custom reply
  changeCustomReply: function (e) {
    var checkbox = $(e.currentTarget),
      wrapper = $("#custom-reply-wrapper");
    if (checkbox.is(":checked")) {
      wrapper.removeClass("hide").find("select, input").removeAttr("disabled");
    } else {
      wrapper
        .addClass("hide")
        .find("select, input")
        .attr("disabled", "disabled");
    }
  },

  // Show replies
  openRepliesModal: function (e) {
    var data = $(e.currentTarget).data(),
      url = "drips/" + this.item.id;
    if (data.type === "node") {
      url += "/nodes/" + data.id + "/replies";
    } else {
      url += "/replies";
    }
    $.getJSON(url).done(
      function (response) {
        this.openModal(require("../templates/modals/replies.hbs"), {
          replies: response.data,
        });
      }.bind(this)
    );
  },

  // Runs after updating a model
  afterUpdate: function (attributes) {
    var self = this;
    self.showUpdatedMessage(attributes);
    $("[data-form=save]").data("dirty", false);
    self.back();
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this,
      isDirty = this.$("[data-form=save]").data("dirty");

    // Check unsaved changes
    if (this.preventNavigation) {
      if (isDirty) {
        if (!confirm(window.lang.confirmLoseChanges)) {
          this.rebind();
          return false;
        }
      }
      window.onbeforeunload = null;
    }

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();
    self.unbindPlugins();

    // Restore original template
    if (
      self.originalTemplate !== undefined &&
      self.originalTemplate !== self.template
    ) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },
});
