<template>
  <div class="alert-box p-4" :class="containerClass">
    <div class="flex">
      <div class="flex-shrink-0">
        <div v-if="$slots.icon" :class="iconClass">
          <slot name="icon" />
        </div>
        <template v-else>
          <component :is="iconComponent" :class="iconClass" aria-hidden="true" />
        </template>
      </div>
      <div class="ml-3 w-full">
        <h1 v-if="$slots.title" class="m-0 mb-4 text-sm font-semibold" :class="textClass">
          <slot name="title"></slot>
        </h1>
        <div class="mb-0 text-sm" :class="textClass">
          <slot></slot>
        </div>
        <div v-if="$slots.actions" class="mb-0 mt-4 text-sm" :class="textClass">
          <slot name="actions"></slot>
        </div>
      </div>
      <div v-if="$props.closable" class="ml-auto pl-3">
        <div class="-mx-1.5 -my-1.5">
          <button
            type="button"
            class="inline-flex p-1.5 text-gray-500 hover:text-gray-600 focus:outline-none"
            @click="close"
          >
            <span class="sr-only">Dismiss</span>
            <XIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

// Icons
import { InformationCircleIcon, ExclamationIcon, CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/vue/solid";

type Themes = "info" | "success" | "warning" | "danger";

const props = withDefaults(
  defineProps<{
    theme?: Themes;
    rounded?: boolean;
    closable?: boolean;
  }>(),
  {
    theme: "info",
    rounded: true,
    closable: false,
  },
);

const emit = defineEmits<{
  (e: "close"): void;
}>();

const close = () => {
  emit("close");
};

const iconComponent = computed(() => {
  if (props.theme === "warning") return ExclamationIcon;
  if (props.theme === "danger") return XCircleIcon;
  if (props.theme === "success") return CheckCircleIcon;
  return InformationCircleIcon;
});

const iconClass = computed(() => {
  const classesByTheme: Record<Themes, string> = {
    info: "h-5 w-5 text-sky-400",
    warning: "h-5 w-5 text-yellow-400",
    danger: "h-5 w-5 text-red-400",
    success: "h-5 w-5 text-green-400",
  };
  return classesByTheme[props.theme];
});

const containerClass = computed(() => {
  const classes: Array<string> = [];
  classes.push(`alert-box-${props.theme}`);

  if (props.rounded) {
    classes.push("rounded-md");
  } else {
    classes.push("border-l-4 border-yellow-400");
  }

  if (props.theme === "info") classes.push("bg-sky-50");
  else if (props.theme === "warning") classes.push("bg-yellow-50");
  else if (props.theme === "danger") classes.push("bg-red-50");
  else if (props.theme === "success") classes.push("bg-green-50");

  return classes.join(" ");
});

const textClass = computed(() => {
  const classesByTheme: Record<Themes, string> = {
    info: "text-sky-700",
    warning: "text-yellow-700",
    danger: "text-red-700",
    success: "text-green-700",
  };

  return classesByTheme[props.theme];
});
</script>

<style>
.alert-box p a {
  @apply font-semibold underline;
}
.alert-box-info p a {
  @apply text-sky-700;
}
.alert-box-info p a:hover {
  @apply text-sky-800;
}

.alert-box-warning p a {
  @apply text-yellow-700;
}
.alert-box-warning p a:hover {
  @apply text-yellow-800;
}

.alert-box-success p a {
  @apply text-green-700;
}
.alert-box-success p a:hover {
  @apply text-green-800;
}
</style>
