import { useRequestsAPIV3 } from "@api/requests";
import { getI18nInstance } from "@locales/i18n";
import { useNotifications } from "@composables/notifications";

// i18n
import esMessages from "./i18n/subjectsSuggester.es.json";
import prMessages from "./i18n/subjectsSuggester.pt.json";

// Types
import { Subjects, SubjectsSuggesterListRequest, SubjectsListAPIResponse } from "./subjectsSuggester.types";

export function useSubjectsSuggester() {
  const request = useRequestsAPIV3();
  const { notify } = useNotifications();
  const { t, mergeLocaleMessage } = getI18nInstance().global;

  mergeLocaleMessage("es", esMessages);
  mergeLocaleMessage("pt", prMessages);

  async function list(body: SubjectsSuggesterListRequest): Promise<Subjects> {
    try {
      const res = await request<SubjectsListAPIResponse>({
        url: `/templates/ai/subjects`,
        method: "post",
        data: body,
        disableNotifyErrors: true,
      });
      return res.data.data;
    } catch (e) {
      notify({
        title: t("subjectsSuggesterListAPI.errorTitle"),
        text: t("subjectsSuggesterListAPI.errorMessage"),
        theme: "error",
        timeout: 15000,
      });
      throw e;
    }
  }

  return { list };
}
