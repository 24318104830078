import { EditView } from "@/core/magnet";
import ContentMixin from "@/mixins/content";
import { objHas } from "@/helpers/utils";
import app from "@/app";
import editTpl from "../templates/edit.hbs";

export default EditView.mix(ContentMixin).extend({
  module: "designs",
  templates: {
    initial: editTpl,
    edit: editTpl,
  },
  cache: false,
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=edit-template]": "editTemplate",
      "submit [data-form=save-tiny-content]": "saveTinyContent",
      "click [data-action=explore-shared]": "exploreShared",
      "click [data-action=choose-template]": "chooseTemplate",
      "click [data-action=change-preview]": "changePreview",
      "click [data-action=open-rename-modal]": "openRenameModal",
      "submit [data-form=rename]": "rename",
    });
  },

  // Cleans up the view
  cleanUp: function () {
    var self = this;

    // If the window is open, close it
    if (window.editor !== undefined) {
      // Ask the user if he want to lose the changes
      if (!window.editor.closed && window.editor.isDirty) {
        if (!confirm(window.lang.confirmLoseChanges)) {
          return false;
        }
      }

      // Close editor
      window.editor.close();
      delete window.editor;
    }

    // Reset model
    self.item = null;

    // Stop loader
    self.loading(false);

    // Unbind events
    self.undelegateEvents();

    // Restore original template
    if (self.originalTemplate !== undefined) {
      self.setTemplate(self.originalTemplate);
    }

    // Close modal
    $(self.modal).modal("hide");

    // Return true to confirm clean up
    return true;
  },

  // Runs custom actions after renderization
  afterRender: function () {
    var self = this;

    // Render HTML preview or show shared templates list
    if (self.item.additionalsData.html) {
      self.renderIframePreview();
    } else {
      if (self.item.get("type") === "HTML") {
        self.openTinyModal();
      }
      if (self.item.get("type") === "EDDIE") {
        self.openEddieModal();
      }
    }

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  // Opens a modal to duplicate a template
  openDuplicateModal: function (e) {
    var self = this,
      data = {
        lang: window.lang,
        data: self.item,
      };
    e.preventDefault();
    self.openModal(require("../templates/modals/duplicate.hbs"), data);
  },

  // Renames a file
  duplicate: function (e) {
    var self = this,
      form = $(e.currentTarget),
      data = form.serializeObject(),
      url = "templates" + form.attr("action") + "/duplicate",
      modal = $(self.modal);
    e.preventDefault();

    // Validate values
    if (!self.validateFormFields(form)) {
      return self;
    }

    // Performs an AJAX call to the action endpoint
    $.ajax({
      type: "POST",
      url: url,
      data: data,
      success: function (response) {
        // Close modal
        modal.modal("hide");

        // Reload to newly created template
        app.router.navigateTo(`templates${response.data.id}`);
      },
      error: function (xhr) {
        var error = xhr.responseJSON.error;

        // Show remote validation errors
        if (error.validationErrors !== undefined) {
          return self.showValidationErrors(error.validationErrors);
        }
      },
    });
  },

  // Opens an editor according to template type
  editTemplate: function (e) {
    var self = this;
    e.preventDefault();
    if (self.item.get("type") === "HTML") {
      self.openTinyModal();
    }
    if (self.item.get("type") === "EDDIE") {
      self.openEddieModal();
    }
  },

  // Returns the default HTML for Tiny MCE
  getHtmlContent: function () {
    return this.item.additionalsData.html;
  },

  // Returns the title
  getTitle: function () {
    return this.item.attributes.name;
  },

  // Returns the JSON content
  getJsonContent: function () {
    return this.item.additionalsData.json;
  },

  // Returns the Tiny MCE selector
  getTinySelector: function () {
    return "#html-content";
  },

  // Returns the saving content message
  getSavingMessage: function () {
    return window.lang.designs.feedback.savingContent;
  },

  // Returns the saving content message
  getSavedMessage: function () {
    return window.lang.designs.feedback.contentSaved;
  },

  // Returns the save HTML endpoint
  saveContentEndpoint: function () {
    return "templates" + this.item.id + "/body";
  },

  // After save hook
  afterContentSave: function () {
    var self = this;

    // Reload iframe
    self.renderIframePreview();

    // Save new content in the item additional data
    if (objHas(this, "saving", "html")) {
      self.item.additionalsData.html = self.saving.html;
    }

    // Save new content in the item additional data
    if (objHas(this, "saving", "json")) {
      self.item.additionalsData.json = self.saving.json;
    }

    // Return
    return this;
  },

  // Gets the iframe URL
  getPreviewIframeUrl: function () {
    var self = this;

    return (
      window.config.api +
      app.session.get("account") +
      "/templates" +
      self.item.id +
      "/preview?token=" +
      app.session.attributes.token
    );
  },

  // Opens a modal to rename an item
  openRenameModal: function () {
    this.openModal(require("../templates/modals/rename.hbs"), {
      data: this.item,
    });
  },

  // Renames a file
  rename: function (e) {
    var form = $(e.currentTarget),
      data = form.serializeObject();
    e.preventDefault();

    // Validate values
    if (!this.validateFormFields(form)) {
      return this;
    }

    // Performs an AJAX call to the rename endpoint
    this.item.rename(data).done(
      function () {
        this.reload();
        this.getModal().modal("hide");
      }.bind(this)
    );
  },
});
