//I18n
import { getI18nInstance } from "@locales/i18n";

//Tools
import { getFooterBlock, getFooterTool } from "@application/unlayer/modules/unlayerFooterToolSnapshot";
import { getFooterHTML } from "./templateFooterHTML";
import { unsubscribeLinkOptions } from "@application/unlayer/modules/unlayerConfig";

// Type
import type { TemplateContentJSON, Rows } from "@domain/Templates";
import type { TemplateRule, TemplateRulesModule } from "./templateRules.type";

const generateFooter = () => {
  const lang = getI18nInstance().global.locale.value.slice(0, 2);
  return getFooterTool({ lang, options: unsubscribeLinkOptions });
};

export const getRuleModule = ({ rule, templatesApp }): TemplateRule => {
  const templateRulesModule: TemplateRulesModule = {
    footerRule: (template) => {
      if (template.type === "html") {
        if (!template.contents.html) return template;

        const footerHTML = getFooterHTML();
        const templateIncludesFooter = template.contents.html.includes(footerHTML);
        if (templateIncludesFooter) return template;
        template.contents.html = template.contents.html.replace("</body>", `${footerHTML}</body>`);
        return template;
      }

      if (!template.contents.json) return template;

      const footerContents = {
        slug: "footer",
        type: "custom",
      };

      const parsedJSON = JSON.parse(template.contents.json) as TemplateContentJSON;
      const rowIndex = templatesApp.getRowIndexByContent({ parsedJSON, content: footerContents });

      if (rowIndex === -1) {
        const footerRowBlock = generateFooter();
        templatesApp.insertRow({ parsedJSON, template, row: footerRowBlock });
        return template;
      }

      const columnIndex = templatesApp.getColumnIndexByContent({
        parsedJSON,
        rowIndex: rowIndex,
        content: footerContents,
      });
      const contentIndex = templatesApp.getContentIndex({ parsedJSON, rowIndex, columnIndex, content: footerContents });

      const isLastRow = templatesApp.IsLastRowIndex({ parsedJSON, rowIndex });
      const isOneColumn = templatesApp.isUniqueColumn({ parsedJSON, rowIndex });
      const isLastColumnContent = templatesApp.isColumnLastContentIndex({
        parsedJSON,
        rowIndex,
        columnIndex,
        contentIndex,
      });

      if (isLastRow && isOneColumn && isLastColumnContent) return template;

      if (!isLastRow || !isOneColumn) {
        const footer = templatesApp.getContent({ parsedJSON, rowIndex, columnIndex, contentIndex });

        templatesApp.deleteContent({ parsedJSON, template, rowIndex, columnIndex, contentIndex });

        const isEmpty = templatesApp.isEmptyRow({ parsedJSON, rowIndex });

        isEmpty && templatesApp.deleteRow({ parsedJSON, template, rowIndex });

        const newFooterRow = getFooterBlock();

        newFooterRow.columns[0].contents.push(footer);

        templatesApp.insertRow({ parsedJSON, template, row: newFooterRow });
        return template;
      }

      if (!isLastColumnContent) {
        const footer = templatesApp.getContent({ parsedJSON, rowIndex, columnIndex, contentIndex });

        templatesApp.deleteContent({ parsedJSON, template, rowIndex, columnIndex, contentIndex });

        templatesApp.insertContent({ parsedJSON, template, rowIndex, columnIndex, content: footer });
      }

      return template;
    },
    cleanEmptyRows: (template) => {
      if (!template.contents.json) return template;

      const templateJSON = JSON.parse(template.contents.json) as TemplateContentJSON;

      const emptyRows: Rows = templatesApp.getEmptyRows({ templateJSON });

      emptyRows.forEach((row) => {
        const rowIndex = templatesApp.getRowIndex({ templateJSON, row });
        templatesApp.deleteRow({ parsedJSON: templateJSON, template, rowIndex });
      });

      return template;
    },
    insertTrackingTag: (template) => {
      if (!template.contents.html) return template;

      const templateIncludesTrackTag = template.contents.html.includes("${open.track}");
      if (templateIncludesTrackTag) return template;

      const bodyTagIndex = template.contents.html.indexOf("<body");
      const bodyTagClosingIndex = template.contents.html.indexOf(">", bodyTagIndex) + 1;

      template.contents.html =
        template.contents.html.slice(0, bodyTagClosingIndex) +
        "${open.track}" +
        template.contents.html.slice(bodyTagClosingIndex);

      return template;
    },
  };
  return templateRulesModule[rule];
};
