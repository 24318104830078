<template>
  <div class="mx-auto mb-16 max-w-7xl">
    <div class="mb-4">
      <AlertBox v-if="!loading && !active">
        <template #title>
          <span class="font-medium text-sky-600">
            {{ t("activateRFM.activateTitle") }}
          </span>
        </template>
        <span class="block text-sky-600">
          {{ t("activateRFM.activateDescription") }}
        </span>
        <span class="block text-sky-600">
          {{ t("activateRFM.activateDescriptionTwo") }}
        </span>
        <template #actions>
          <div class="group relative w-max">
            <SimpleButton :disabled="isFreeAccount" :loading="loadingActive" @click="activateRFM">
              <template #leading>
                <LockClosedIcon v-if="isFreeAccount" />
                <CheckIcon v-else />
              </template>
              {{ t("activateRFM.button") }}
            </SimpleButton>
            <div
              v-if="isFreeAccount"
              class="invisible absolute left-2 top-10 z-10 min-w-max transition duration-150 ease-in-out group-hover:visible group-hover:-translate-y-1 group-hover:scale-105"
            >
              <PlanUpgradePopover feature="rfm" />
            </div>
          </div>
        </template>
      </AlertBox>
      <AlertBox v-if="!loading && active && creating">
        <template #title>
          <span>{{ t("activateRFM.creatingStatsFirst") }}</span>
          <span class="pl-1 font-normal">{{ t("activateRFM.creatingStatsSecond") }}</span>
        </template>
        <p>
          {{ t("activateRFM.creatingStatsDescription") }}
          <a
            href="https://blog.myperfit.com/ciclo-de-vida-de-compradores-la-clave-para-retenerlos-y-fidelizarlos/"
            target="_blank"
            class="pl-1"
            >{{ t("activateRFM.creatingStatsDescriptionLink") }}</a
          >
        </p>
      </AlertBox>
    </div>
    <SectionHeader class="flex items-center">
      {{ t("mainMetrics.header") }} <SimpleBadge theme="green" class="ml-4 hidden">Beta</SimpleBadge>
    </SectionHeader>
    <TextParagraph>
      <i18n-t keypath="mainMetrics.subtitle">
        <template #helpCenter>
          <a href="" target="_blank" @click.prevent="gotoHelp(7054063)"> {{ t("mainMetrics.helpCenter") }}</a>
        </template>
      </i18n-t>
    </TextParagraph>
    <div v-if="smBp" class="relative mt-6">
      <!-- <div class="absolute left-0 ml-[-9rem] mt-24 flex rotate-[270deg] py-5 items-center text-gray-400"> -->
      <div
        v-if="!loading"
        :class="[
          { 'select-none opacity-40': !active },
          'absolute bottom-[31px] left-[-6px] flex origin-bottom-left rotate-[270deg] items-center text-gray-400',
        ]"
      >
        <span class="text-xs font-medium leading-4">{{ t("infoCards.yAxis.start") }}</span>
        <div class="mx-2 w-28 border-t border-gray-200"></div>
        <ArrowSmRightIcon class="h-6" />
      </div>
      <div
        v-if="!loading && !loadingActive"
        class="grid grid-cols-1 gap-4 lg:grid-cols-6"
        data-intercom-target="LifecycleRFMGrid"
      >
        <div class="col-span-1 grid gap-4 lg:col-span-2">
          <RFMCard
            :loading="loading"
            stage="champion"
            :stage-title="t('metricCards.stageTitle.champion')"
            :info-tooltip="descriptionsStages.champion"
            :percentage="sn((stats.champion?.contacts * 100) / contactsCount, 'decimal')"
            :contacts-number="sn(stats.champion?.contacts, 'decimal')"
            :active="active"
            flex-basis-text-percentage="auto"
            data-intercom-target="LifecycleRFMGridChampions"
          />
          <RFMCard
            :loading="loading"
            stage="loyalist"
            :stage-title="t('metricCards.stageTitle.loyalist')"
            :info-tooltip="descriptionsStages.loyalist"
            :percentage="sn((stats.loyalist?.contacts * 100) / contactsCount, 'decimal')"
            :contacts-number="sn(stats.loyalist?.contacts, 'decimal')"
            :active="active"
            flex-basis-text-percentage="auto"
          />
          <RFMCard
            :loading="loading"
            stage="recent"
            :stage-title="t('metricCards.stageTitle.recent')"
            :info-tooltip="descriptionsStages.recent"
            :percentage="sn((stats.recent?.contacts * 100) / contactsCount, 'decimal')"
            :contacts-number="sn(stats.recent?.contacts, 'decimal')"
            :active="active"
            flex-basis-text-percentage="auto"
          />
        </div>
        <div class="col-span-1 grid gap-4 lg:col-span-3">
          <div class="col-span-1 grid gap-4 lg:grid-cols-2">
            <RFMCard
              :loading="loading"
              stage="highpotential"
              :stage-title="t('metricCards.stageTitle.highpotential')"
              :info-tooltip="descriptionsStages.highpotential"
              :percentage="sn((stats.highpotential.contacts * 100) / contactsCount, 'decimal')"
              :contacts-number="sn(stats.highpotential.contacts, 'decimal')"
              :active="active"
            />
            <RFMCard
              :loading="loading"
              stage="cantlose"
              :stage-title="t('metricCards.stageTitle.cantlose')"
              :info-tooltip="descriptionsStages.cantlose"
              :percentage="sn((stats.cantlose.contacts * 100) / contactsCount, 'decimal')"
              :contacts-number="sn(stats.cantlose.contacts, 'decimal')"
              :active="active"
            />
          </div>
          <div class="col-span-1 grid gap-4 lg:grid-cols-2">
            <RFMCard
              :loading="loading"
              stage="neednutring"
              :stage-title="t('metricCards.stageTitle.neednutring')"
              :info-tooltip="descriptionsStages.neednutring"
              :percentage="sn((stats.neednutring.contacts * 100) / contactsCount, 'decimal')"
              :contacts-number="sn(stats.neednutring.contacts, 'decimal')"
              :active="active"
            />
            <RFMCard
              :loading="loading"
              stage="atrisk"
              :stage-title="t('metricCards.stageTitle.atrisk')"
              :info-tooltip="descriptionsStages.atrisk"
              :percentage="sn((stats.atrisk.contacts * 100) / contactsCount, 'decimal')"
              :contacts-number="sn(stats.atrisk.contacts, 'decimal')"
              :active="active"
            />
          </div>
        </div>
        <div class="col-span-1 grid gap-4">
          <RFMCard
            :loading="loading"
            stage="abouttolose"
            :stage-title="t('metricCards.stageTitle.abouttolose')"
            :info-tooltip="descriptionsStages.abouttolose"
            :percentage="sn((stats.abouttolose.contacts * 100) / contactsCount, 'decimal')"
            :contacts-number="sn(stats.abouttolose.contacts, 'decimal')"
            :active="active"
          />
        </div>
      </div>
      <div
        v-if="!loading"
        :class="[{ 'select-none opacity-40': !active }, 'relative flex items-center pt-[6px] text-gray-400']"
      >
        <span class="flex-shrink text-xs font-medium leading-4">{{ t("infoCards.xAxis.start") }}</span>
        <div class="mx-2 flex-grow border-t border-gray-200"></div>
        <span class="flex-shrink text-xs font-medium leading-4">{{ t("infoCards.xAxis.end") }}</span>
        <ArrowSmRightIcon class="ml-2 h-6" />
      </div>
    </div>
    <div class="mt-6 grid grid-cols-1 divide-y divide-gray-200">
      <RFMDescription
        v-for="description in responseDescription"
        :key="description.stage"
        :stage-contacts-count="stagesContactsCount[description.stage]"
        :stage="description.stage"
        :stage-title="description.stageTitle"
        :stage-description="description.stageDescription"
        :active="active"
        :data-intercom-target="'LifecycleRFMDescription-' + description.stage"
        @click="goToList(description.listId)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";

import { getStagesDescriptions, getStagesContactsCount } from "./dashboardRFM.data";

// Components
import TextParagraph from "@atoms/TextParagraph.vue";
import SectionHeader from "@atoms/SectionHeader.vue";
import RFMCard from "@organisms/Dashboard/RFM/Components/RFMCard.vue";
import RFMDescription from "@organisms/Dashboard/RFM/Components/RFMDescription.vue";
import { ArrowSmRightIcon } from "@heroicons/vue/solid";
import SimpleBadge from "@atoms/SimpleBadge";
import PlanUpgradePopover from "@molecules/PlanUpgradePopover.vue";
import SimpleButton from "@/vue/components/atoms/SimpleButton.vue";
import AlertBox from "@atoms/AlertBox.vue";

// Icons
import { LockClosedIcon } from "@heroicons/vue/solid";
import { CheckIcon } from "@heroicons/vue/outline";

// Utils
import { useI18n } from "vue-i18n";
import { useBreakpoints } from "@/vue/composables/breakpoints";
import { isEmpty } from "lodash";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

// Services
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Type
import type { Stats, StageNames, Definitions } from "@/vue/types/lifecycle";
import type { DescriptionDefinitions } from "./dashboardRFM.data";
import type { ContactsList } from "@domain/lists";

type ResponseDescription = Array<{
  stage: StageNames;
  stageTitle: string;
  stageDescription: string;
  contactsNumber: string;
  listId: string;
}>;

const { n, t } = useI18n();
const { smBp } = useBreakpoints();
const trackingEventsService = useTrackingEvents();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

const props = withDefaults(
  defineProps<{
    definitions: Definitions;
    stats: Stats;
    contactsLists: ContactsList;
    contactsCount: number;
    active: boolean;
    creating?: boolean;
    loading?: boolean;
    loadingActive?: boolean;
  }>(),
  {
    creating: false,
    loading: false,
  }
);

// Emits
const emit = defineEmits<{
  (e: "activate"): void;
  (e: "goToList", id: string): void;
}>();

const activateRFM = () => {
  emit("activate");
};

// Format
const sn = (value: number | undefined, format: string): string => n(isFinite(value!) && value ? value : 0, format);

// Data
const stagesContactsCount = computed(() => getStagesContactsCount(props.definitions, props.stats, props.contactsLists));

const isFreeAccount = session.value?.account?.plan?.type === "FREE";
const descriptionsStages = computed<DescriptionDefinitions>(() => {
  return props.active && !isEmpty(props.definitions)
    ? getStagesDescriptions(props.definitions)
    : {
        champion: t("metricCards.stageDescription.champion"),
        loyalist: t("metricCards.stageDescription.loyalist"),
        recent: t("metricCards.stageDescription.recent"),
        highpotential: t("metricCards.stageDescription.highpotential"),
        neednutring: t("metricCards.stageDescription.neednutring"),
        cantlose: t("metricCards.stageDescription.cantlose"),
        atrisk: t("metricCards.stageDescription.atrisk"),
        abouttolose: t("metricCards.stageDescription.abouttolose"),
      };
});
const responseDescription = computed<ResponseDescription>(() => {
  const responseDescription: ResponseDescription = [
    {
      stage: "champion",
      stageTitle: t("metricCards.stageTitle.champion"),
      stageDescription: descriptionsStages.value.champion,
      contactsNumber: sn(props.stats.champion?.contacts, "decimal"),
      listId: props.stats.champion?.list_id,
    },
    {
      stage: "loyalist",
      stageTitle: t("metricCards.stageTitle.loyalist"),
      stageDescription: descriptionsStages.value.loyalist,
      contactsNumber: sn(props.stats.loyalist?.contacts, "decimal"),
      listId: props.stats.loyalist?.list_id,
    },
    {
      stage: "recent",
      stageTitle: t("metricCards.stageTitle.recent"),
      stageDescription: descriptionsStages.value.recent,
      contactsNumber: sn(props.stats.recent?.contacts, "decimal"),
      listId: props.stats.recent?.list_id,
    },
    {
      stage: "highpotential",
      stageTitle: t("metricCards.stageTitle.highpotential"),
      stageDescription: descriptionsStages.value.highpotential,
      contactsNumber: sn(props.stats.highpotential?.contacts, "decimal"),
      listId: props.stats.highpotential?.list_id,
    },
    {
      stage: "neednutring",
      stageTitle: t("metricCards.stageTitle.neednutring"),
      stageDescription: descriptionsStages.value.neednutring,
      contactsNumber: sn(props.stats.neednutring?.contacts, "decimal"),
      listId: props.stats.neednutring?.list_id,
    },
    {
      stage: "cantlose",
      stageTitle: t("metricCards.stageTitle.cantlose"),
      stageDescription: descriptionsStages.value.cantlose,
      contactsNumber: sn(props.stats.cantlose?.contacts, "decimal"),
      listId: props.stats.cantlose?.list_id,
    },
    {
      stage: "atrisk",
      stageTitle: t("metricCards.stageTitle.atrisk"),
      stageDescription: descriptionsStages.value.atrisk,
      contactsNumber: sn(props.stats.atrisk?.contacts, "decimal"),
      listId: props.stats.atrisk?.list_id,
    },
    {
      stage: "abouttolose",
      stageTitle: t("metricCards.stageTitle.abouttolose"),
      stageDescription: descriptionsStages.value.abouttolose,
      contactsNumber: sn(props.stats.abouttolose?.contacts, "decimal"),
      listId: props.stats.abouttolose?.list_id,
    },
  ];

  return responseDescription;
});

// Methods
const goToList = (id: string) => {
  emit("goToList", id);
};

const gotoHelp = (id) => {
  window.Intercom("showArticle", id);
};

onMounted(() => {
  trackingEventsService.dispatchAll({
    name: "APP_LIFECYCLE_RFM_VISIT",
  });
});
</script>

<i18n lang="json">
{
  "es": {
    "mainMetrics": {
      "header": "Ciclo de vida de compradores",
      "subtitle": "Segmenta a tus clientes según su frecuencia y cantidad de compras. Para conocer más visita el { helpCenter }.",
      "helpCenter": "centro de ayuda"
    },
    "metricCards": {
      "stageTitle": {
        "champion": "Campeones",
        "loyalist": "Leales",
        "recent": "Recientes",
        "highpotential": "Alto potencial",
        "neednutring": "Necesitan incentivo",
        "cantlose": "No se pueden perder",
        "atrisk": "En riesgo",
        "abouttolose": "Por perder"
      },
      "stageDescription": {
        "champion": "Clientes que han realizado 6 o más compras y la última fue dentro de los últimos 45 días.",
        "loyalist": "Clientes que han realizado entre 3 o 5 compras y la última fue dentro de los últimos 45 días.",
        "recent": "Clientes que han realizado entre 1 o 2 compras y la última fue dentro de los últimos 45 días.",
        "highpotential": "Clientes que han realizado desde 5 o más compras y la última fue dentro de los últimos 45 y 90 días.",
        "neednutring": "Clientes que han realizado de 1 a 4 compras y la última fue dentro de los últimos 45 y 90 días.",
        "cantlose": "Clientes que han realizado desde 5 o más compras y la última fue dentro de los últimos 90 y 180 días.",
        "atrisk": "Clientes que han realizado de 1 a 4 compras y la última fue dentro de los últimos 90 y 180 días.",
        "abouttolose": "Clientes que han realizado al menos 1 compra entre los últimos 180 días y más.",
        "tooltip": "Ir a la lista de contactos"
      }
    },
    "infoCards": {
      "xAxis": {
        "start": "Compras recientes",
        "end": "Compras antiguas"
      },
      "yAxis": {
        "start": "Cantidad de compras"
      }
    },
    "activateRFM": {
      "activateTitle": "Conoce la salud de los compradores de tu tienda",
      "activateDescription": "Utiliza la segmentación automática para enviar mensajes más personalizados. Aumenta tus ventas implementando estrategias de fidelización y reactivación.",
      "activateDescriptionTwo": "Premia a tus clientes más leales, incentiva a volver a compradores con alto potencial, envía ofertas diferenciadas y mucho más.",
      "button": "Activar ciclo de vida",
      "creatingStatsFirst": "Estamos segmentando los compradores de tu tienda,",
      "creatingStatsSecond": "este proceso puede demorar algunos minutos. Puedes salir de esta sección sin problemas.",
      "creatingStatsDescription": "Mientras tanto te recomendamos leer sobre estrategias de marketing utilizando nuestro artículo sobre el ",
      "creatingStatsDescriptionLink": "ciclo de vida de compradores",
      "errorActivatingTitle": "No se pudo activar la segmentación",
      "errorActivatingDescription": "No se pudo activar la segmentación de contactos. Intenta de nuevo en unos minutos."
    }
  },
  "pt": {
    "mainMetrics": {
      "header": "Ciclo de vida dos compradores",
      "subtitle": "Segmente seus clientes de acordo com a frequência e quantidade de compras. Para saber mais, visite a { helpCenter }.",
      "helpCenter": "centro de ajuda"
    },
    "metricCards": {
      "stageTitle": {
        "champion": "Campeões",
        "loyalist": "Leais",
        "recent": "Recentes",
        "highpotential": "Alto potencial",
        "neednutring": "Precisam de incentivo",
        "cantlose": "Não se podem perder",
        "atrisk": "Em risco",
        "abouttolose": "Por perder"
      },
      "stageDescription": {
        "champion": "Clientes que fizeram 6 ou mais compras e a última foi feita nos últimos 45 dias.",
        "loyalist": "Clientes que fizeram entre 3 e 5 compras e a última foi feita nos últimos 45 dias.",
        "recent": "Clientes que fizeram entre 1 ou 2 compras e a última foi feita nos últimos 45 dias.",
        "highpotential": "Clientes que fizeram 5 ou mais compras e a última foi feita entre os últimos 45 e 90 dias.",
        "neednutring": "Clientes que fizeram entre 1 e 4 compras e a última foi feita entre últimos 45 e 90 dias.",
        "cantlose": "Clientes que fizeram 5 ou mais compras e a última foi feita entre os últimos 90 e 180 dias.",
        "atrisk": "Clientes que fizeram entre 1 e 4 compras e a última foi feita entre os últimos 90 e 180 dias.",
        "abouttolose": "Clientes que fizeram pelo menos 1 compra entre os últimos 180 dias e mais no passado.",
        "tooltip": "Ir para a lista de contatos"
      }
    },
    "infoCards": {
      "xAxis": {
        "start": "Compras recentes",
        "end": "Compras antigas"
      },
      "yAxis": {
        "start": "Quantidade de compras"
      }
    },
    "activateRFM": {
      "activateTitle": "Conheça o Ciclo de Vida dos seus compradores",
      "activateDescription": "Utilize a segmentação automática para enviar mensagens mais personalizadas. Aumente suas vendas implementando estratégias de fidelização e reativação.",
      "activateDescriptionTwo": "Recompense seus clientes mais fiéis, incentive o retorno dos compradores, envie ofertas diferenciadas e muito mais.",
      "button": "Ativar ciclo de vida",
      "creatingStatsFirst": "Estamos segmentando os compradores da sua loja,",
      "creatingStatsSecond": "este processo pode levar alguns minutos. Você pode sair desta seção sem problemas. ",
      "creatingStatsDescription": "Enquanto isso, recomendamos que você leia atentamente sobre estratégias de marketing utilizando o nosso artigo sobre o",
      "creatingStatsDescriptionLink": "ciclo de vida dos compradores",
      "errorActivatingTitle": "Não foi possível ativar a segmentação",
      "errorActivatingDescription": "Não foi possível ativar a segmentação de contatos. Tente novamente em alguns minutos."
    }
  }
}
</i18n>
