<template>
  <div class="max-w- mx-auto w-full max-w-lg lg:w-[28rem]">
    <div>
      <img class="h-10 w-auto" src="/img/login-icon.svg" alt="Perfit" />
      <h1 class="mt-4 text-3xl font-extrabold leading-9 tracking-tight text-gray-900">{{ t("title") }}</h1>
    </div>

    <div class="mt-5">
      <div>
        <p class="mt-4 text-base font-normal text-gray-600">
          {{ t("subtitle") }}
        </p>

        <p class="mt-4 text-base font-normal text-gray-600">
          <i18n-t keypath="secondSubtitle">
            <template #text>
              <span class="font-semibold text-gray-800">
                {{ t("secondSubtitleBold") }}
              </span>
            </template>
          </i18n-t>
        </p>

        <div class="mt-6 flex flex-col-reverse gap-4 sm:flex-row">
          <SimpleButton type="button" class="my-auto w-full" @click="goToLogin">
            {{ t("goBack") }}
          </SimpleButton>
        </div>

        <div class="mt-5 text-center text-sm leading-6">
          <a href="" class="font-semibold text-sky-400 hover:text-sky-500" @click.prevent="openHelpArticle">{{
            t("loginProblems")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// components
import SimpleButton from "@atoms/SimpleButton.vue";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits<{
  goToLogin: [void];
  helpArticle: [void];
}>();

const goToLogin = () => {
  emit("goToLogin");
};

const openHelpArticle = () => {
  emit("helpArticle");
};
</script>

<i18n lang="json">
{
  "es": {
    "title": "¡Gracias por elegirnos!",
    "subtitle": "Estaremos verificando la información ingresada. Por cuestiones de alta demanda, este proceso puede demorar la apertura de tu cuenta.",
    "secondSubtitleBold": "¡No te preocupes!",
    "secondSubtitle": "{text} Ya estás en nuestra lista de espera y vamos a contactarte lo antes posible para avanzar con la creación de tu cuenta.",
    "goBack": "Entendido",
    "loginProblems": "¿Problemas para ingresar?"
  },
  "pt": {
    "title": "Obrigado por nos escolher!",
    "subtitle": "Estaremos verificando as informações fornecidas. Devido à alta demanda, esse processo pode demorar para abrir sua conta.",
    "secondSubtitleBold": "Não se preocupe!",
    "secondSubtitle": "{text} Você já está na nossa lista de espera e entraremos em contato o mais breve possível para avançar com a criação da sua conta.",
    "goBack": "Entendido",
    "loginProblems": "Problemas para fazer login?"
  }
}
</i18n>
