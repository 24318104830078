import type { Row } from "@/vue/types/Templates";
import type { FooterLocales } from "@/vue/types/unlayer";
import type { UnsubscribeLinkOptions } from "@/vue/types/unlayer";

interface getFooterToolParams {
  lang: unlayerLocales;
  options: UnsubscribeLinkOptions;
}

export const unsubscribeTextLangs: Record<FooterLocales, string> = {
  es: "Para dejar de recibir estos mensajes utiliza el siguiente enlace:",
  pt: "Para deixar de receber essas mensagens utilize o seguinte link:",
  en: "To stop receiving these messages, use the following link:",
  fr: "Si vous souhaitez vous désabonner des newsletters, cliquez ici:",
  iw: "כדי להפסיק לקבל הודעות אלו, השתמש בקישור הבא:",
};

export const getFooterBlock = (): Row => {
  return {
    id: "TheFooterBlock",
    cells: [1],
    columns: [
      {
        id: "TheFooterColumn",
        contents: [],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  };
};

export const getFooterTool = ({ lang, options }: getFooterToolParams): Row => {
  return {
    id: "TheFooterBlock",
    cells: [1],
    columns: [
      {
        id: "TheFooterColumn",
        contents: [
          {
            id: "TheFooterContent",
            type: "custom",
            slug: "footer",
            values: {
              containerPadding: "10px",
              anchor: "",
              _meta: { htmlID: "u_content_custom_footer_1", htmlClassNames: "u_content_custom_footer" },
              selectable: true,
              draggable: true,
              duplicatable: false,
              deletable: true,
              hideable: false,
              languageSelector: lang,
              unsubscribeText: unsubscribeTextLangs[lang],
              unsubscribeLink: options[lang][0].value,
              accountName: "${account.name}",
              postalAddress: "${account.postal_address}",
              textAlignment: "center",
              fontSize: "12",
              fontFamily: "arial",
            },
          },
        ],
        values: {
          backgroundColor: "",
          padding: "0px",
          border: {},
          _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
        },
      },
    ],
    values: {
      columns: false,
      backgroundColor: "",
      columnsBackgroundColor: "",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      padding: "0px",
      anchor: "",
      hideDesktop: false,
      _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
      selectable: true,
      draggable: true,
      duplicatable: true,
      deletable: true,
      hideable: true,
    },
  };
};
