<template>
  <div class="relative">
    <button
      class="rounded-sm"
      :disabled="disabled"
      :class="[
        {
          'bg-red-100 text-red-500 ring-offset-2 hover:bg-red-200 hover:text-red-800 focus-visible:bg-red-100  focus-visible:text-red-800 focus-visible:ring-2 focus-visible:ring-red-400':
            hasError && !disabled,
          'bg-gray-50 text-gray-500 ring-offset-2 hover:bg-sky-100 hover:text-sky-500 focus-visible:bg-sky-100 focus-visible:text-sky-500 focus-visible:ring-2 focus-visible:ring-sky-400':
            !hasError && !disabled,
        },
        'focus-visible:outline-none group my-auto flex cursor-pointer select-none space-x-1 py-2 px-3 text-sm disabled:bg-gray-100',
      ]"
      @click="openOptions"
    >
      <span class="font-semibold capitalize text-current">{{
        conditionsApp.getAttributeName({ attribute: attribute.attribute, fields })
      }}</span>
      <span class="font-normal text-current">
        {{ getAttributeText(attribute) }} {{ getAttributeValueText(attribute) }}</span
      >
      <IconButton
        size="min"
        theme="none"
        :label="t('deleteButton')"
        :class="[hasError && 'text-current group-hover:text-red-800']"
        @click.stop="deleteAttribute"
      >
        <XIcon class="h-3 w-3" />
      </IconButton>
    </button>
    <AttributeOptionsList
      v-if="optionsIsOpen && attributeComparisonOptions"
      type="string"
      :selected="attribute"
      :error="hasError"
      :attribute-options="attributeComparisonOptions"
      class="absolute top-11 z-20 w-64 border shadow"
      @update:selected="updateAttribute"
      @close="closeOptions"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, watchEffect, onMounted, inject } from "vue";
import type { ComputedRef, Ref } from "vue";

// Components
import AttributeOptionsList from "./AttributeOptionsList.vue";
import IconButton from "@/vue/components/atoms/IconButton.vue";

// Icons
import { XIcon } from "@heroicons/vue/outline";

// Application
import { useConditionsApp } from "@application";

// Utils
import { useI18n } from "vue-i18n";

// Types
import type { AttributeWithId, AttributeComparisonOptions } from "@domain/predicates";
import { isEmptyAttributeValue } from "@domain/predicates";
import type { Interests } from "@domain/interests";
import type { Fields } from "@/vue/types/fields";
import type { ConditionsPredicateTypes } from "@domain/conditions";

const { t } = useI18n();
const conditionsApp = useConditionsApp();

// Injects
const interests = inject<ComputedRef<Interests | undefined>>("interests");
const fields = inject<Ref<Fields | undefined>>("fields", ref([]));

// Props
const props = withDefaults(
  defineProps<{
    attribute: AttributeWithId<string, ConditionsPredicateTypes>;
    disabled?: boolean;
    mountOpen?: boolean;
  }>(),
  {
    disabled: false,
    mountOpen: false,
  }
);

const emit = defineEmits<{
  (e: "update:attribute", attribute: AttributeWithId<string, ConditionsPredicateTypes>): void;
  (e: "delete"): void;
}>();

const updateAttribute = (option: AttributeWithId<string, ConditionsPredicateTypes>) => {
  emit("update:attribute", {
    ...props.attribute,
    operator: option.operator,
    value: option?.value ?? undefined,
    name: option?.name ?? undefined,
  });
};

const deleteAttribute = () => {
  emit("delete");
};

// Errors
const hasError = ref(false);
const checkErrors = () => {
  hasError.value = !conditionsApp.attributeIsValid({
    attribute: props.attribute,
    fields: fields.value,
    interests: interests?.value,
  }).valid;
};

onMounted(() => {
  if (isEmptyAttributeValue(props.attribute)) return;
  checkErrors();
});

// Options
const optionsIsOpen = ref(props.mountOpen);
const openOptions = () => {
  optionsIsOpen.value = true;
};
const closeOptions = () => {
  optionsIsOpen.value = false;
  checkErrors();
};

const getAttributeText = (attribute: AttributeWithId<string, ConditionsPredicateTypes>): string => {
  if (attribute.operator === "exists") {
    return attribute.value ? t("comparisonOperator.def") : t("comparisonOperator.ndef");
  }
  return t(`comparisonOperator.${attribute.operator}`);
};

const getAttributeValueText = (attribute: AttributeWithId<string, ConditionsPredicateTypes>): string => {
  if (attribute?.value !== undefined && typeof attribute?.value === "boolean") {
    return "";
  }
  return attribute.name ?? attribute?.value.toString();
};

const attributeComparisonOptions = ref<AttributeComparisonOptions>();
watchEffect(() => {
  attributeComparisonOptions.value = conditionsApp.getAttributeComparisonOptions({
    type: props.attribute.type ?? "string",
    interests: interests?.value,
  });
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "deleteButton": "Borrar regla"
  },
  "pt": {
    "deleteButton": "Excluir regra"
  }
}
</i18n>
