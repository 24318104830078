var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(depth0, depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":122},"end":{"line":21,"column":152}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='modal-dialog'>\n    <form data-form='send-test'>\n        <div class='modal-content'>\n            <div class='modal-header'>\n                <button type='button' class='close' data-dismiss='modal'>\n                    <span aria-hidden='true'>\n                        &times;\n                    </span>\n                </button>\n                <h4 class='modal-title'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"selectRecipients")), depth0))
    + "\n                </h4>\n            </div>\n            <div class='modal-body'>\n                <div class='modal-body-padded'>\n                    <p>\n                        "
    + ((stack1 = alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"selectRecipientsLegend")), depth0)) != null ? stack1 : "")
    + "\n                    </p>\n                    <div class=\"form-group\">\n                        <textarea name=\"emails\" class=\"form-control\"\n                            placeholder=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"testRecipientsPlaceholder")), depth0))
    + "\">"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"testRecipients") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":93},"end":{"line":21,"column":161}}})) != null ? stack1 : "")
    + "</textarea>\n                        <p class='input-legend'>"
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"testRecipientsLegend")), depth0))
    + "</p>\n                    </div>\n                </div>\n            </div>\n            <div class='modal-footer'>\n                <button type='button' class='btn btn-link' data-dismiss='modal'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"cancel")), depth0))
    + "\n                </button>\n                <button type='submit' class='btn btn-default btn-primary'>\n                    "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"lang"))) && lookupProperty(stack1,"campaigns"))) && lookupProperty(stack1,"sendTest")), depth0))
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>";
},"useData":true});