import MetricBaseView from "./base";
import { EditView } from "@/core/magnet";
import app from "@/app";
import { _ } from "@/helpers/utils";
import { mountComponent } from "@/helpers/vue";
import TemplatePreview from "@organisms/Templates/TemplatePreview/TemplatePreview";

export default MetricBaseView.extend({
  module: "metrics",
  templates: {
    initial: require("../templates/contents.hbs"),
  },
  events: function () {
    return $.extend({}, EditView.prototype.events, {
      "submit [data-form=duplicate]": "duplicate",
      "click [data-action=archive]": "archive",
      "click [data-action=unarchive]": "unarchive",
      "click [data-confirm]": "confirm",
      "click [data-action=stop]": "stop",
      "click [data-action=join-lists]": "join",
      "click [data-action=approve]": "approve",
      "click [data-action=cancel]": "cancel",
      "click [data-action=open-duplicate-modal]": "openDuplicateModal",
      "click [data-action=open-share-modal]": "openShareModal",
      "click [data-action=change-preview]": "changePreview",
      "click [data-action=open-criterias-modal]": "openCriteriasModal",
      "click [data-action=copy-share-url]": "copyShareUrl",
      "change [data-action=switch-version]": "switchVersion",
    });
  },

  // Performs the needed bindings after renderization
  afterRender: function () {
    // Parse criterias
    this.parseRecipients();

    if (this.useUnlayer) {
      this.mountVueComponents();
    } else {
      // Render iframe
      this.renderIframePreview();
    }

    // Force resize
    $(window).resize();

    // Hide elements if the user has not the right permissions
    app.layout.updateLayout();
  },

  mountVueComponents: function () {
    if (this.templatePreviewComponent || !this.unlayerTemplateId) return;

    let relationType = "campaign_simple";
    if (this.item.attributes.type === "SPLIT_AB") {
      relationType = "campaign_testab";
    } else if (this.item.attributes.type === "SMART") {
      relationType = "campaign_smart";
    }

    const metricsData = this.item.additionalsData.links.map((link) => ({
      clicks: link.clicks,
      url: link.url,
      linkId: link?.link_id ?? "",
    }));

    const props = {
      tplId: this.unlayerTemplateId,
      relation: {
        relationId: this.item.get("id"),
        relationType,
      },
      metrics: metricsData,
      auditMode: "campaign",
      readonly: true,
      onTemplateUpdated: (content) => this.updateLegacyContent(content),
    };
    this.templatePreviewComponent = mountComponent(TemplatePreview, props, "#vue-template-preview");
  },

  // Returns the preview iframe
  getPreviewIframe: function () {
    return document.getElementById("preview");
  },

  // Returns the preview iframe URL
  getPreviewIframeUrl: function () {
    // var url,
    //   regex = /(.*)\/v2\//g;
    // if (app.session.get("isGuest")) {
    //   url =
    //     window.config.api +
    //     this.item.getGuestUrlRoot(
    //       ["/contents/", this.item.attributes.contents[0].id, "/preview"].join(
    //         ""
    //       )
    //     );
    // } else {
    //   url = this.item.attributes.contents[0].preview.replace(
    //     regex,
    //     window.config.api
    //   );
    // }
    // return url;
    return this.item.attributes.contents[0].preview;
  },

  // Sets the preview HTML
  renderIframePreview: function () {
    var view = this,
      loadingBox = this.$(".loading-box"),
      noContentBox = this.$(".no-content"),
      previewBox = this.$("[data-role=preview]"),
      iframe = this.getPreviewIframe(),
      url = this.getPreviewIframeUrl();

    // Remove no content box
    noContentBox.addClass("hidden");

    // Hide iframe
    iframe.style.height = 0;

    // Show iframe container
    previewBox.removeClass("hidden");

    // Display loader
    loadingBox.removeClass("hidden");

    // Update iframe URL and resize iframe height
    $.ajax({
      type: "GET",
      url: url,
      dataType: "html",
      headers: {
        "Api-Prefix": false,
        "Account-Prefix": false,
      },
      success: (res) => {
        if (!iframe.contentDocument) return;

        iframe.contentDocument.open();
        iframe.contentDocument.write(res);
        iframe.contentDocument.close();

        //limpio links vacios
        const links = iframe.contentDocument.querySelectorAll("a[href=''], a[href='#']");
        links.forEach((link) => {
          link.removeAttribute("href");
        });

        view.resizeIframe();
        loadingBox.addClass("hidden");

        $(iframe).on("load", () => {
          $(iframe).off("load");
          view.resizeIframe();
        });
      },
      error: () => {
        loadingBox.addClass("hidden");
        // $("#content-error").show();
      },
    });
    // $(iframe)
    //   .attr("src", url)
    //   .load(function () {
    //     $(iframe).off("load");
    //     view.resizeIframe();
    //     loadingBox.addClass("hidden");
    //   });
  },

  // Resizes the preview iframe
  resizeIframe: function () {
    var iframe = this.getPreviewIframe();
    iframe.style.height = 0;
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
  },

  // Parses the campaign contacts criterias
  parseRecipients: function () {
    var included = this.item.attributes.include || false,
      excluded = this.item.attributes.exclude || false;

    // Parse included contacts
    if (included.lists !== undefined && _.isArray(included.lists) && included.lists.length && included.lists[0] === 0) {
      this.item.set("includeAllContacts", true);
    }

    // Parse excluded contacts
    if (!_.size(excluded)) {
      this.item.set("notExcludeContacts", true);
    }
  },

  // Cancels a campaign
  cancel: function (e) {
    var button = $(e.currentTarget);
    if (button.attr("data-confirm")) {
      return false;
    }
    this.item.cancel().done(function () {
      app.router.navigateTo("campaigns");
    });
  },

  // Approves a campaign
  approve: function (e) {
    var button = $(e.currentTarget);
    if (button.attr("data-confirm")) {
      return false;
    }
    this.item.approve().done(function () {
      app.router.navigateTo("campaigns");
    });
  },

  // Changes the preview
  changePreview: function (e) {
    var button = $(e.currentTarget),
      device = button.data("device"),
      preview = this.$(".content-preview");

    // Add active class
    button.addClass("active").siblings().removeClass("active");

    // Add or remove the mobile class
    if (device === "mobile") {
      preview.addClass("mobile");
    } else {
      preview.removeClass("mobile");
    }

    // Resize iframe
    setTimeout(() => this.resizeIframe(), 1000);
  },

  switchVersion: function (e) {
    const version = e.currentTarget.value;
    if (version) {
      app.router.navigateTo(`metrics/${this.item.get("id")}/contents?filters.version=${version}`);
    } else {
      app.router.navigateTo(`metrics/${this.item.get("id")}/contents`);
    }
  },

  // Join lists
/*   join: function () {
    const self = this;
    const apiUrl = "lists/join";
    const listIds = this.item.attributes.include.lists;
    const campaignName = this.item.attributes.name;

    const truncateString = (str) => {
      if (str.length <= 50) return str;
      return str.slice(0, 50);
    };

    const randomString = (length) => {
      const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      return Array.from({ length }, () => characters[Math.floor(Math.random() * characters.length)]).join("");
    };

    const newListName = truncateString("_admin-" + randomString(3) + "-" + campaignName);

    return $.ajax({
      type: "POST",
      url: apiUrl,
      data: qs.stringify({
        newListName: newListName,
        listIds: listIds,
      }),
      success: function () {
        //self.displayMessage(window.lang.lists.feedback.joined);
        notify({
          text: window.lang.lists.feedback.joined,
          theme: "info",
        });
      },
      error: function () {
        notify({
          text: window.lang.ajaxError,
          theme: "error",
        });
      },
    });
  }, */
});
