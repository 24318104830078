<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <div class="w-[30rem]">
      <TextParagraph>
        <i18n-t keypath="description">
          <template #link>
            <a href="http://docs.myperfit.com/es/articles/1032" target="_blank">
              {{ t("descriptionLink") }}
            </a>
          </template>
        </i18n-t>
      </TextParagraph>
      <div class="divide-y">
        <ul class="max-h-52 space-y-1 overflow-y-auto py-4">
          <li v-for="row in rows" :key="row.id" class="text-sm text-gray-500">
            {{ row.data?.name ?? "" }}
          </li>
        </ul>
        <div class="pt-4">
          <FormInput v-model="newName" maxlength="50" :label="t('nameField')" class="w-full" />
        </div>
      </div>
    </div>
    <template #buttons>
      <SimpleButton :loading="loading" @click="merge">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import type { ListRows } from "../audienceLists.types";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";
import FormInput from "@molecules/FormInput.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

const props = withDefaults(
  defineProps<{
    open: boolean;
    rows: ListRows;
  }>(),
  {},
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const loading = ref(false);
const newName = ref(
  props.rows
    .map((row) => row.data?.name)
    .join(", ")
    .slice(0, 50),
);
const merge = async () => {
  if (!props.rows) return;

  try {
    loading.value = true;

    const ids = props.rows.reduce((ids, row) => {
      if (row.data?.id) {
        ids.push(row.data.id.toString());
      }
      return ids;
    }, [] as Array<string>);

    await listAPI.merge({
      ids: ids,
      name: newName.value,
    });
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.merge.title"),
      text: t("notifications.merge.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Unir",
    "description": "Se creará una nueva lista con todos los contactos de las listas seleccionadas. Las listas originales no serán modificadas. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "nameField": "Nombre de la nueva lista",
    "notifications": {
      "merge": {
        "title": "Uniendo listas",
        "text": "Las listas se están uniendo."
      }
    }
  },
  "pt": {
    "title": "Unir",
    "description": "Será criada uma nova lista com todos os contatos das listas selecionadas. As listas originais não serão modificadas. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "nameField": "Nome da nova lista",
    "notifications": {
      "merge": {
        "title": "Unindo listas",
        "text": "As listas estão sendo unidas."
      }
    }
  }
}
</i18n>
