<template>
  <ModalDialog without-overflow show-close-button :open="open" :title="t('title')" @close="close">
    <TextParagraph>
      <i18n-t keypath="description">
        <template #link>
          <a href="https://docs.myperfit.com/articles/2637585" target="_blank">
            {{ t("descriptionLink") }}
          </a>
        </template>
      </i18n-t>
    </TextParagraph>
    <template #buttons>
      <SimpleButton :loading="loading" @click="createAutoSegments">
        <template #leading>
          <CheckIcon class="h-5 w-5" aria-hidden="true" />
        </template>
        {{ t("acceptButton") }}
      </SimpleButton>
      <SimpleButton theme="white" @click="close">{{ t("cancelButton") }}</SimpleButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";

// Components
import ModalDialog from "@molecules/ModalDialog.vue";
import TextParagraph from "@atoms/TextParagraph.vue";
import SimpleButton from "@atoms/SimpleButton.vue";

// Icon
import { CheckIcon } from "@heroicons/vue/solid";

// Composables
import { useNotifications } from "@composables/notifications";

// Store
import { useNotificationStore } from "@store/notificationsStore/notifications.store";

// Services
import { useLists } from "@api/modules/lists/lists";

// I18n
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const listAPI = useLists();
const { notify } = useNotifications();
const notificationStore = useNotificationStore();

withDefaults(
  defineProps<{
    open: boolean;
  }>(),
  {
    open: false,
  },
);

// Emits
const emit = defineEmits<{
  close: [void];
  clearSelected: [void];
}>();

const close = () => {
  emit("close");
};

const clearSelected = () => {
  emit("clearSelected");
};

const loading = ref(false);
const createAutoSegments = async () => {
  try {
    loading.value = true;
    await listAPI.autoSegments();
    await notificationStore.fetchTasks();
    notify({
      title: t("notifications.autoSegments.title"),
      text: t("notifications.autoSegments.text"),
      theme: "info",
    });
    close();
    clearSelected();
  } finally {
    loading.value = false;
  }
};
</script>

<i18n lang="jsonc">
{
  "es": {
    "title": "Crear segmentos automáticos",
    "description": "Se crearán segmentos automáticos en base a la actividad de tus contactos. {link}",
    "descriptionLink": "Leer más",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceptar",
    "notifications": {
      "autoSegments": {
        "title": "Segmentando listas",
        "text": "Los segmentos están siendo generados."
      }
    }
  },
  "pt": {
    "title": "Criar segmentos automáticos",
    "description": "Serão criados segmentos automáticos com base na atividade de seus contatos. {link}",
    "descriptionLink": "Leia mais",
    "cancelButton": "Cancelar",
    "acceptButton": "Aceitar",
    "notifications": {
      "autoSegments": {
        "title": "Segmentando listas",
        "text": "Os segmentos estão sendo gerados."
      }
    }
  }
}
</i18n>
