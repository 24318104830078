export type Ecommerce =
  | "tiendanube"
  | "mshops"
  | "vtex"
  | "jumpseller"
  | "woocommerce"
  | "shopify"
  | "tray"
  | "tiendup"
  | "wbuy";

export type SupportedEcommerce = Omit<Ecommerce, "tiendup">;

export const isSupportedEcommerce = (e: string | SupportedEcommerce): e is SupportedEcommerce => {
  const ecommerces: SupportedEcommerce[] = [
    "tiendanube",
    "mshops",
    "vtex",
    "jumpseller",
    "woocommerce",
    "shopify",
    "tray",
    "wbuy",
  ];

  return ecommerces.includes(e);
};

export const ecommerceHasOptinSupport = (e: string | Ecommerce): boolean => {
  return ["tiendanube", "mshops", "tray", "vtex", "wbuy"].includes(e);
};

export const ecommerceHasAbandonedCart = (e: string | SupportedEcommerce): e is SupportedEcommerce => {
  const ecommerces: SupportedEcommerce[] = ["tiendanube", "tray", "vtex", "jumpseller", "wbuy"];

  return ecommerces.includes(e);
};

export const isEcommerceId = (e: string | Ecommerce): e is Ecommerce => {
  const ecommerces: Ecommerce[] = [
    "tiendanube",
    "mshops",
    "vtex",
    "jumpseller",
    "woocommerce",
    "shopify",
    "tray",
    "tiendup",
    "wbuy",
  ];

  return ecommerces.some((ecommerce) => e === ecommerce);
};
