<template>
  <div class="flex flex-col space-y-4 bg-white text-gray-600">
    <ContentOptions
      v-if="!showAbsolute"
      v-model:showHeatMap="showHeatMap"
      :template="template"
      :preview-template="previewTemplate"
      :relation="relation"
      :sender="sender"
      :preheader="preheader"
      :subject="subject"
      :subjects="subjects"
      :contact="contact"
      :preview-format="previewFormat"
      :show="show"
      :allow="{
        heatMapDataId: allowHeatMap,
      }"
      :loading-share-link="loadingShareLink"
      :loading="loadingTemplate"
      :loading-heat-map="showHeatMap && loadingHeatMap"
      :disabled="disableButtons"
      class="mt-4"
      @toggle-preview-format="onTogglePreviewFormat"
      @open-send-test="openSendTestModal"
      @open-contact-selector="openContactSelector"
      @openlink-share-modal="onOpenlinkShareModal"
    />
    <AlertBox v-if="contact" theme="info">
      <span class="mr-2">
        {{
          template?.options?.override_to
            ? t("contactSelectedOverrideAlert", { email: contact?.email })
            : t("contactSelectedAlert", { email: contact?.email })
        }}
      </span>
      <button class="font-medium underline hover:text-sky-500" @click="clearContactSelected">
        {{ t("contactSelectedButton") }}
      </button>
    </AlertBox>
    <AlertBox v-if="templateError" theme="warning" class="mx-0">
      <template #title>{{ t("templateErrorTitle") }}</template>
      {{ t("templateErrorDescription") }}<br />
      {{ t("templateErrorHint") }}
    </AlertBox>
    <div v-else-if="loadingTemplate" class="flex h-screen w-full items-center justify-center pt-3">
      <div class="h-full w-[700px] animate-pulse rounded-2xl bg-gray-50"></div>
    </div>
    <div v-else-if="emptyTemplate" class="flex h-96 w-full items-center justify-center pt-5">
      <div class="flex h-full w-full flex-col justify-center rounded-2xl bg-gray-50 text-center">
        <PhotographIcon class="mx-auto h-20 w-20 stroke-1 text-gray-200" aria-hidden="true" />
        <p class="text-sm font-medium text-gray-300">{{ t("emptyState.title") }}</p>
        <p class="text-sm text-gray-300">{{ t("emptyState.description") }}</p>
      </div>
    </div>

    <div v-else class="relative mx-auto w-full">
      <div class="absolute right-2 top-2 h-full">
        <ContentOptions
          v-if="showAbsolute"
          v-model:showHeatMap="showHeatMap"
          :template="template"
          :preview-template="previewTemplate"
          :relation="relation"
          :sender="sender"
          :preheader="preheader"
          :subject="subject"
          :subjects="subjects"
          :contact="contact"
          :preview-format="previewFormat"
          :show="show"
          :allow="{
            heatMapDataId: allowHeatMap,
          }"
          :loading-share-link="loadingShareLink"
          :loading="loadingTemplate"
          :disabled="disableButtons"
          :loading-heat-map="showHeatMap && loadingHeatMap"
          class="sticky right-2 top-2"
          @toggle-preview-format="onTogglePreviewFormat"
          @open-send-test="openSendTestModal"
          @open-contact-selector="openContactSelector"
          @openlink-share-modal="onOpenlinkShareModal"
        />
      </div>
      <div ref="iframeDivRef" class="relative mx-auto overflow-visible" :class="mobilePreview ? 'w-[360px]' : 'w-full'">
        <HeatMapTooltip v-if="showHeatMap" :radius="HEATMAP_POINTS_RADIUS" :data-points="dataPoints" />
        <iframe
          v-if="templatePreviewId"
          :id="templatePreviewId"
          ref="iframeRef"
          src=""
          frameborder="0"
          scrolling="no"
          class="h-full"
          :class="showHeatMap && 'opacity-50'"
          style="width: 100%; height: 80vh"
          data-preview="form"
        />
      </div>
    </div>
    <div v-if="isMasterUser" class="mt-0 text-xs text-gray-50">
      {{ template?.id }}
    </div>

    <TemplateTestSendModal
      v-if="sendTestIsOpen"
      v-model:is-open="sendTestIsOpen"
      :sender-id="templateSender?.id"
      :email="contact?.email"
      :contact-id="contact?.id"
      :utm-campaign-name="template?.tracking.utm.campaign"
      :subjects="subjects"
      :relation="relation"
      :use-auto-save="useAutoSave"
      :tpl-id="tplId"
    />
    <ContactSelector
      v-if="contactSelectorIsOpen"
      :open="contactSelectorIsOpen"
      @contact-selected="onSelectContact"
      @close="closeContactSelector"
    />
    <TemplatePreviewShareLinkModal
      :open="linkShareIsOpen"
      :email="contact?.email"
      :url="URL_SHARE_LINK + hashShareLink"
      @close="closeSendTestModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted, watchEffect, nextTick, onUnmounted } from "vue";

import { getDataPoints, metricsUrlsHaveDataIds } from "./heatmap.data";

//Components
import ContentOptions from "./components/ContentOptions.vue";
import TemplateTestSendModal from "@organisms/Templates/TemplateTestSend/TemplateTestSendModal.vue";
import AlertBox from "@atoms/AlertBox.vue";
import ContactSelector from "@organisms/Contacts/ContactSelector/ContactSelector.vue";
import TemplatePreviewShareLinkModal from "@/vue/components/molecules/TemplatePreviewShareLinkModal.vue";
import HeatMapTooltip from "./components/HeatMapTooltip.vue";

//Icons
import { PhotographIcon } from "@heroicons/vue/outline";

// Utils
import { useDebounceFn } from "@vueuse/core";
import { attachToNewIframe } from "@helpers/iframe";
import { createHeatMap, destroyHeatMapInstance } from "@composables/heatmap";
import type { DataPoint } from "@composables/heatmap";

//I18n
import { useI18n } from "vue-i18n";

//API
import { useEmailTemplate } from "@/vue/api/modules/templates/templates";
import { useTrackingEvents } from "@/vue/composables/trackingevents";

// Store
import { storeToRefs } from "pinia";
import { useSessionStore } from "@store";

//Types
import type { TemplatePreview, TemplatePreviewTestAB, Template, PublicTemplatePreview } from "@/vue/types/Templates";
import { isTemplatePreview } from "@/vue/types/Templates";

import type { ISender, Sender } from "@/vue/types/Senders";
import type { Relation } from "../TemplateEditor/TemplateEditor.vue";
import type { ContactType as Contact } from "@organisms/Contacts/ContactSelector/ContactSelector.vue";

export type previewWidthFormats = "mobile" | "desktop";

export interface ShowElements {
  showSendTest?: boolean;
  showShare?: boolean;
  showPreviewContact?: boolean;
  showFormatToggle?: boolean;
  showSubject?: boolean;
  showPreHeader?: boolean;
  showSender?: boolean;
  showMonitoringOptions?: boolean;
  showAudit?: boolean;
  showheatMapToggle?: boolean;
}

const trackingEventsService = useTrackingEvents();
const sessionStore = useSessionStore();
const { session } = storeToRefs(sessionStore);

//Props
const props = withDefaults(
  defineProps<{
    templatePreviewId: string;
    tplId: string;
    templatePreview?: TemplatePreview;
    template?: Template;
    isPublicTemplate?: boolean;
    relation?: Relation | undefined;
    sender?: Sender;
    preheader?: string;
    subject?: string;
    subjects?: Array<string>;
    contact?: Contact;
    previewFormat?: previewWidthFormats;
    showAbsolute?: boolean;
    show?: ShowElements;
    useAutoSave?: boolean;
    metrics?: Array<{
      clicks: number;
      url: string;
      linkId: string;
    }>;
  }>(),
  {
    sender: undefined,
    templatePreview: undefined,
    template: undefined,
    preheader: undefined,
    subject: undefined,
    contact: undefined,
    subjects: undefined,
    relation: undefined,
    previewFormat: "desktop",
    showAbsolute: false,
    metrics: undefined,
    show: () => ({
      showSendTest: false,
      showShare: false,
      showPreviewContact: false,
      showFormatToggle: false,
      showSubject: false,
      showPreHeader: false,
      showSender: false,
      showMonitoringOptions: false,
      showAudit: false,
      showheatMapToggle: false,
    }),
    useAutoSave: false,
  },
);

// Emits
const emit = defineEmits<{
  (e: "update:previewFormat", value: previewWidthFormats): void;
  (e: "update:contact", value: Contact | undefined): void;
}>();

const onTogglePreviewFormat = (value: previewWidthFormats) => {
  emit("update:previewFormat", value);
};
const onSelectContact = (value: Contact) => {
  emit("update:contact", value);
};

const clearContactSelected = () => {
  emit("update:contact", undefined);
};

//Flags
const isFreeAccount = session.value?.account?.plan?.type === "FREE";
const isMasterUser = session.value?.isMasterUser;

const templateError = ref(false);
const setTemplateError = () => (templateError.value = true);

//Const
const { t } = useI18n();
const TemplatesAPI = useEmailTemplate();

//State
const templateSender = computed<Sender | ISender | undefined>(() => {
  if (props.sender) return props.sender;
  if (previewTemplate.value && isTemplatePreview(previewTemplate.value)) return previewTemplate.value.sender;
  return undefined;
});

const previewTemplate = ref<TemplatePreview | PublicTemplatePreview | TemplatePreviewTestAB>();

const subjects = ref<Array<string>>(props.subjects ?? []);
watchEffect(() => (subjects.value = props.subjects ?? []));

const sendTestIsOpen = ref(false);
const openSendTestModal = () => (sendTestIsOpen.value = true);

const linkShareIsOpen = ref(false);
const loadingShareLink = ref(false);
const loadingTemplate = ref(false);
const closeSendTestModal = () => (linkShareIsOpen.value = false);
const URL_SHARE_LINK = "https://sm.pemres.net/preview/link/";
const hashShareLink = ref("");

const contactSelectorIsOpen = ref(false);
const openContactSelector = () => (contactSelectorIsOpen.value = true);
const closeContactSelector = () => (contactSelectorIsOpen.value = false);

const mobilePreview = computed(() => props.previewFormat === "mobile");

const emptyTemplate = computed(() => {
  if (props.template && props.template.type === "unlayer") return props.template?.contents?.json === "";
  return previewTemplate.value?.html === "";
});
const disableButtons = computed(() => loadingTemplate.value || templateError.value || emptyTemplate.value);

//Methods
const getShareLink = async () => {
  try {
    loadingShareLink.value = true;
    const utmCampaign = props.template?.tracking.utm.enabled ? props.template?.tracking.utm.campaign : undefined;

    const res = await TemplatesAPI.getShareLink({
      tpl_id: props.tplId,
      use_autosave: props.useAutoSave,
      contact_id: props.contact?.id.toString(),
      utm_campaign: utmCampaign,
    });
    loadingShareLink.value = false;
    return res;
  } catch (e) {
    return undefined;
  }
};

const getShareLinkTestAB = async () => {
  try {
    loadingShareLink.value = true;
    const utmCampaign = props.template?.tracking.utm.enabled ? props.template?.tracking.utm.campaign : undefined;

    const res = await TemplatesAPI.getShareLinkTestAB({
      tpl_id: props.tplId,
      use_autosave: false,
      subjects: props.useAutoSave && props.subjects ? props.subjects : [],
      contact_id: props.contact?.id.toString(),
      utm_campaign: utmCampaign,
    });
    loadingShareLink.value = false;
    return res;
  } catch (e) {
    return undefined;
  }
};

const onOpenlinkShareModal = async () => {
  trackingEventsService.dispatchAll({ name: "APP_TPLEDITOR_SHARELINK" });
  let resLink;
  if (props.relation?.relationType == "campaign_testab") {
    resLink = await getShareLinkTestAB();
  } else {
    resLink = await getShareLink();
  }

  hashShareLink.value = resLink.hash;
  linkShareIsOpen.value = true;
};

const useTemplate = async (id: string): Promise<TemplatePreview | undefined> => {
  const useAutoSave = !props.templatePreview && props.useAutoSave ? true : false;

  try {
    loadingTemplate.value = true;
    const utmCampaign = props.template?.tracking.utm.enabled ? props.template?.tracking.utm.campaign : undefined;

    const tpl = await TemplatesAPI.getTemplatePreview({
      tpl_id: id,
      contact_id: props.contact?.id.toString(),
      utm_campaign: utmCampaign,
      use_autosave: useAutoSave,
    });

    return tpl;
  } catch (e) {
    //TODO: Emit close event
    setTemplateError();
    return undefined;
  } finally {
    loadingTemplate.value = false;
  }
};

const usePublicTemplate = async (id: string): Promise<PublicTemplatePreview | undefined> => {
  try {
    loadingTemplate.value = true;

    const tpl = await TemplatesAPI.getPublicTemplatePreview({
      tpl_id: id,
    });

    return tpl;
  } catch (e) {
    //TODO: Emit close event
    setTemplateError();
    return undefined;
  } finally {
    loadingTemplate.value = false;
  }
};

const useTemplateTestAB = async (id: string): Promise<TemplatePreviewTestAB | undefined> => {
  try {
    loadingTemplate.value = true;
    const utmCampaign = props.template?.tracking.utm.enabled ? props.template?.tracking.utm.campaign : undefined;

    const tpl = await TemplatesAPI.getTemplatePreviewTestAB({
      tpl_id: id,
      subjects: props.useAutoSave && props.subjects ? props.subjects : [],
      contact_id: props.contact?.id.toString(),
      utm_campaign: utmCampaign,
      use_autosave: props.useAutoSave,
    });

    return tpl;
  } catch (e) {
    setTemplateError();
    return undefined;
  } finally {
    loadingTemplate.value = false;
  }
};

const refreshHeight = () => {
  const iframeHost = document.getElementById(props.templatePreviewId) as HTMLIFrameElement;
  nextTick(() => {
    if (!iframeHost?.contentWindow) return;
    const offsetHeight =
      iframeHost.contentWindow.document.body.offsetHeight > 100
        ? iframeHost.contentWindow.document.body.offsetHeight
        : 100;
    iframeHost.style.height = offsetHeight + "px";
  });
};

const initWithTemplate = async (templatePreview: TemplatePreview) => {
  if (!templatePreview.html) return;

  await attachToNewIframe({ html: templatePreview.html.toString(), iframeID: props.templatePreviewId });
  setTimeout(() => {
    generateDataPoints();
  }, 1000);
};

const initWithTemplateID = async (tplId: string) => {
  if (props.relation?.relationType == "campaign_testab") {
    previewTemplate.value = await useTemplateTestAB(tplId);
    subjects.value = (previewTemplate.value as TemplatePreviewTestAB).subjects;
  } else {
    previewTemplate.value = props.isPublicTemplate ? await usePublicTemplate(tplId) : await useTemplate(tplId);
  }

  if (!previewTemplate.value) return;

  if (previewTemplate.value.html) {
    await attachToNewIframe({ html: previewTemplate.value.html, iframeID: props.templatePreviewId });
  }
  setTimeout(() => {
    generateDataPoints();
  }, 1000);
};

watch(
  () => props.previewFormat,
  () => {
    refreshHeight();
  },
);

watch(
  () => [props.templatePreview, props.tplId, props.contact],
  async () => {
    if (props.templatePreview) {
      await initWithTemplate(props.templatePreview);
    } else {
      await initWithTemplateID(props.tplId);
    }
  },
);

// HeatMap
const iframeDivRef = ref<HTMLDivElement>();
const iframeRef = ref<HTMLIFrameElement>();

const allowHeatMap = computed<boolean>(() => {
  if (!props.metrics) return false;

  return (
    !!props.template?.tracking.click &&
    metricsUrlsHaveDataIds({
      metrics: props.metrics,
    })
  );
});

const showHeatMap = ref<boolean>(!isFreeAccount && allowHeatMap.value);
const loadingHeatMap = ref(true);
let clicksHeatMap: any = undefined;
const dataPoints = ref<Array<DataPoint>>([]);
const randomDataPoints = ref<Array<DataPoint>>([]);
const HEATMAP_POINTS_RADIUS = 40;
const HEATMAP_POINTS_INTENSITY = 10;

const generateDataPoints = () => {
  if (!iframeRef.value || !iframeDivRef.value || !props.metrics) return;
  const { dataPoints: points, randomDataPoints: randomPoints } = getDataPoints({
    element: iframeRef.value,
    metrics: props.metrics,
  });
  dataPoints.value = points;
  randomDataPoints.value = randomPoints;
  loadingHeatMap.value = false;
};

const instanceHeatMap = () => {
  if (!iframeDivRef.value || !props.metrics) return;
  const maxClicks = props.metrics.reduce((clicks, metric) => {
    if (metric.clicks > clicks) {
      clicks = metric.clicks;
    }
    return clicks;
  }, 0);

  clicksHeatMap = createHeatMap({
    element: iframeDivRef.value,
    radius: HEATMAP_POINTS_RADIUS,
    maxOpacity: 0.6,
    data: {
      min: 0,
      max: Math.max((maxClicks * HEATMAP_POINTS_INTENSITY) / 100, 5),
      data: randomDataPoints.value,
    },
  });
};

let timeOutInstanceHeatMap: ReturnType<typeof setTimeout>;
watch([showHeatMap, loadingHeatMap], () => {
  if (isFreeAccount) return;
  if (!showHeatMap.value || randomDataPoints.value.length === 0) {
    destroyHeatMapInstance(clicksHeatMap);
    clearTimeout(timeOutInstanceHeatMap);
    return;
  }

  trackingEventsService.amplitude({ name: "APP_CAMPAIGN_HEATMAP_USED" });

  instanceHeatMap();
});

const repaintHeatMap = ref(false);
const repaintFn = useDebounceFn(() => {
  if (isFreeAccount) return;
  generateDataPoints();
  if (repaintHeatMap.value) {
    repaintHeatMap.value = false;
    showHeatMap.value = true;
  }
}, 300);

const repaintLater = () => {
  repaintHeatMap.value = repaintHeatMap.value || showHeatMap.value;
  showHeatMap.value = false;
  repaintFn();
};

watch([() => props.previewFormat], repaintLater);

onUnmounted(() => {
  removeEventListener("resize", repaintLater);
});

onMounted(async () => {
  !isFreeAccount && addEventListener("resize", repaintLater);
  if (props.templatePreview) {
    await initWithTemplate(props.templatePreview);
  } else {
    await initWithTemplateID(props.tplId);
  }
});

defineExpose({ refreshHeight });
</script>

<i18n lang="jsonc">
{
  "es": {
    "contactSelectedAlert": "Estás viendo este email como lo recibirá {email}.",
    "contactSelectedOverrideAlert": "Estás viendo este email como si {email} hubiese iniciado el automation.",
    "contactSelectedButton": "Limpiar selección",
    "templateErrorTitle": "No fue posible generar la vista previa del contenido.",
    "templateErrorDescription": "Probablemente esto se deba a errores de sintaxis en los códigos de reemplazo o regiones condicionales.",
    "templateErrorHint": "Verifica si existen problemas en el contenido, o contáctanos si necesitas ayuda.",
    "useTemplate": {
      "errorTitle": "Error al cargar la plantilla",
      "errorMessage": "Hubo un problema al obtener la plantilla guardada"
    },
    "emptyState": {
      "title": "Edita el contenido para comenzar.",
      "description": "Aquí verás la vista previa de tu email."
    }
  },
  "pt": {
    "contactSelectedAlert": "Você está vendo este e-mail como {email} o receberá.",
    "contactSelectedOverrideAlert": "Você está vendo esse e-mail como se ${email} houvesse iniciado o automation.",
    "contactSelectedButton": "Limpar seleção",
    "templateErrorTitle": "Não foi possível gerar a pré-visualização do conteúdo.",
    "templateErrorDescription": "Provavelmente isso se deva a erros de sintaxis nos códigos de substituição ou regiões condicionais.",
    "templateErrorHint": "Verifique se existem problemas no conteúdo, ou entre em contato conosco se precisar de ajuda.",
    "useTemplate": {
      "errorTitle": "Erro ao carregar template",
      "errorMessage": "Houve um problema ao obter o template guardado."
    },
    "emptyState": {
      "title": "Edite o conteúdo para começar.",
      "description": "Aqui você verá a pré-visualização do seu e-mail."
    }
  }
}
</i18n>
