export interface DNSRecord {
  type: "CNAME" | "TXT";
  name: string;
  value: string;
}

export interface SenderAuth {
  status: "OK" | "CHANGE_REQUIRED" | "CHANGE_SUGGESTED" | "PUBLIC_DOMAIN" | "UNKNOWN";
  spf: {
    result: "PASS" | "NONE" | "FAIL";
    current: string;
  };
  dkim: {
    result: "PASS" | "NONE" | "FAIL";
    record: DNSRecord;
  };
  dmarc: {
    result: "QUARENTINE" | "REJECT" | "NONE" | "UNSET";
    record: DNSRecord;
  };
  return_path: {
    result: "PASS" | "NONE" | "FAIL";
    record: DNSRecord;
  };
}

export type SenderDetails = Record<string, unknown>;

export interface SenderModify {
  id: string;
  name?: string;
  reply_to?: string;
  details?: SenderDetails;
}

export interface ISender {
  id: string;
  name: string;
  email: string;
  reply_to: string;
  details: SenderDetails;
  auth: SenderAuth;
}

export class Sender implements ISender {
  id: string;
  name: string;
  email: string;
  effective_email: string;
  domain: string;
  reply_to: string;
  details: SenderDetails;
  auth: SenderAuth;

  constructor(sender: ISender) {
    this.id = sender.id;
    this.email = sender.email;
    this.name = sender.name;
    this.reply_to = sender.reply_to;
    this.auth = sender.auth;
    this.details = sender.details;
    this.domain = sender.email.split("@")[1];

    if (!["OK", "CHANGE_SUGGESTED"].includes(sender.auth.status)) {
      this.effective_email = sender.email.split("@")[0] + "@pemsv00.net";
    } else {
      this.effective_email = sender.email;
    }
  }
}

export type Senders = Array<Sender>;

export const anySenderStatusOk = (senders: Senders) => {
  return senders.some((s) => s.auth.status === "OK");
};

export const anySenderStatusNotOk = (senders: Senders) => {
  return senders.some((s) => s.auth.status !== "OK");
};