<script setup lang="ts">
import { RenderFunction, onMounted, ref } from "vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";

import { createPopper } from "@popperjs/core";

import { isEmpty } from "lodash";

import { useI18n } from "vue-i18n";

export interface DataItem<DataType = unknown, keyType = string, valueType = string> {
  key: keyType;
  keyText?: string;
  value: valueType;
  data?: DataType;
}

export type DataItems<DataType = unknown, keyType = string, valueType = string> = Array<
  DataItem<DataType, keyType, valueType>
>;

//I18n
const { t } = useI18n();

//Props
const props = withDefaults(
  defineProps<{
    selected?: DataItem;
    dataItems: DataItems;
    showKeyValue?: boolean;
    disabled?: boolean;
    remHeight?: number;
    loading?: boolean;
    icon?: RenderFunction;
    error?: boolean;
  }>(),
  {
    selected: undefined,
    dataItems: () => [],
    showKeyValue: false,
    disabled: false,
    remHeight: undefined,
    loading: false,
    icon: undefined,
    error: false,
  },
);

const buttonRef = ref();
const componentRef = ref();

onMounted(() => {
  if (!props.selected?.value && props.dataItems?.[0]) emit("update:selected", props.dataItems[0]);

  createPopper(buttonRef.value?.el, componentRef.value?.el, {
    placement: "bottom",
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "clippingParents",
        },
      },
      {
        name: "flip",
        options: {
          allowedAutoPlacements: ["top", "bottom"],
          fallbackPlacements: ["top", "bottom"],
          altBoundary: true,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 4],
        },
      },
    ],
  });
});

//Emits
const emit = defineEmits<{
  (e: "update:selected", dataItem: DataItem): void;
}>();

const emitSelected = (value: DataItem) => emit("update:selected", value);
</script>

<template>
  <Listbox
    v-slot="{ open }"
    as="div"
    data-intercom-target="BatchTypeSelectorMenu"
    :disabled="disabled"
    :model-value="selected"
    @update:modelValue="emitSelected"
  >
    <!-- <ListboxLabel class="block text-sm font-medium text-gray-700"> Assigned to </ListboxLabel> -->
    <div class="relative">
      <ListboxButton
        ref="buttonRef"
        :class="[
          disabled ? 'bg-gray-100' : 'bg-white',
          error ? 'border-red-500' : 'border-gray-300',
          'relative w-full cursor-default rounded-md border  py-2 pl-3 pr-10 text-left shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm',
        ]"
      >
        <span v-if="isEmpty(dataItems)">-</span>
        <div class="flex w-full items-center space-x-2 truncate text-gray-500">
          <span v-if="icon"><component :is="icon" class="mr-2 h-5 w-5" /></span>
          <span class="truncate">{{ selected?.value }}</span>
          <span v-if="showKeyValue" class="truncate text-sm text-sky-500 opacity-80">{{
            selected?.keyText || selected?.key
          }}</span>
        </div>
        <div v-if="loading" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <LoadingSpinner class="h-5 w-5 text-sky-500" aria-hidden="true" />
        </div>
        <span v-else class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          ref="componentRef"
          static
          class="absolute isolate z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          :class="open ? 'visible' : 'invisible'"
          :style="{
            maxHeight: remHeight ? remHeight + 'rem' : 'auto',
          }"
        >
          <ListboxOption v-if="isEmpty(dataItems)" class="pointer-events-none select-none text-center">
            {{ t("noDataItems") }}
          </ListboxOption>
          <ListboxOption
            v-for="dataItem in dataItems"
            :key="dataItem.key"
            v-slot="{ active, selected }"
            as="template"
            :value="dataItem"
          >
            <li
              :class="[
                active ? 'bg-sky-500 text-white' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-14',
              ]"
            >
              <div class="flex items-center space-x-3">
                <span :class="[selected ? 'font-medium' : 'font-normal', 'truncate']">
                  {{ dataItem?.value }}
                </span>
                <span
                  v-if="showKeyValue"
                  :class="[
                    active ? 'text-white' : 'text-sky-500 opacity-80',
                    selected ? 'font-medium' : 'font-normal',
                    'truncate',
                  ]"
                  class="text-sm"
                >
                  {{ dataItem?.keyText || dataItem.key }}
                </span>
              </div>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-sky-500', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<i18n lang="jsonc">
{
  "es": {
    "noDataItems": "No hay datos disponibles"
  },
  "pt": {
    "noDataItems": "Nenhum dado disponível"
  }
}
</i18n>

<style scoped>
::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  @apply bg-neutral-100;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
</style>
