<template>
  <div class="mx-auto gap-y-5 bg-gray-100">
    <nav class="flex h-full flex-col">
      <ul role="list" class="m-0 flex flex-1 flex-col justify-between">
        <li class="mt-2 hlg:mt-4 hxl:mt-8">
          <ol role="list">
            <li class="flex items-center justify-center p-2">
              <HomeLink v-if="allowedHomeAccess" :current="current === 'home'" @click="goToHome" />
              <Perfit v-else class="h-6 w-6 shrink-0" />
            </li>
            <li v-for="route in navigation" :key="route.name">
              <Link :route="route" :current="current === route.route" />
            </li>
          </ol>
        </li>
        <li>
          <ul class="pt-2 hlg:pb-6 hlg:pt-4 hxl:pt-8">
            <li data-canny-changelog class="flex justify-center px-2 py-1 hmd:py-1.5 hxl:py-2">
              <IconButton
                :label="t('cannyButton')"
                :tooltip-props="{
                  theme: 'light',
                  arrow: false,
                  delay: [0, 0]
                }"
                :modifiers="[{ name: 'offset', options: { offset: [0, 22] } }]"
                placement="right"
                size="min"
                :auto-placements="['right']"
                class="group relative !bg-gray-100"
              >
                <SpeakerphoneIcon
                  class="h-7 w-7 transform cursor-pointer stroke-[1.5] text-gray-500 duration-100 hover:text-sky-500 group-hover:scale-110"
                />
              </IconButton>
            </li>
            <li class="relative flex justify-center px-2 py-1 hmd:py-1.5 hxl:py-2">
              <IconButton
                id="open-notifications-button-id"
                :label="t('notificationsButton')"
                :tooltip-props="{
                  theme: 'light',
                  arrow: false,
                  delay: [0, 0]
                }"
                :modifiers="[{ name: 'offset', options: { offset: [0, 22] } }]"
                placement="right"
                size="min"
                :auto-placements="['right']"
                :show-tooltip="!notificationsOpen"
                :class="[notificationsOpen && 'pointer-events-none', 'group relative !bg-gray-100']"
                @click="openNotifications"
              >
                <BellIcon
                  class="h-7 w-7 transform stroke-[1.5] text-gray-500 duration-100 hover:text-sky-500 group-hover:scale-110"
                />
                <div
                  v-if="!loadingTask"
                  v-show="notificationCount > 0"
                  :class="[
                    'absolute -top-[2px] right-0 flex h-5 w-5 items-center justify-center rounded-full bg-red-400 text-xs text-gray-100'
                  ]"
                >
                  {{ notificationCount < 100 ? notificationCount : 99 }}
                </div>
                <div
                  v-else
                  :class="[
                    'absolute right-[1px] top-0 flex h-4 w-4 items-center justify-center rounded-full bg-red-400'
                  ]"
                >
                  <LoadingSpinner class="h-3 w-3 text-white" aria-hidden="true" />
                </div>
              </IconButton>

              <NotificationList
                v-model:open="notificationsOpen"
                class="bottom-0 left-20"
                @update:notification-count="updateNotificationCount"
                @tasks-finished="setLoadingTaskFinished"
                @task-in-process="setLoadingTask"
              />
            </li>
            <li class="relative flex justify-center px-2 py-1 hmd:py-1.5 hxl:py-2">
              <ProfileMenu @open-account-selector="openSelectAccount">
                <template #button>
                  <IconButton
                    :label="t('profileButton')"
                    :tooltip-props="{
                      theme: 'light',
                      arrow: false,
                      delay: [0, 0]
                    }"
                    :modifiers="[{ name: 'offset', options: { offset: [0, 22] } }]"
                    :show-tooltip="!selectAccountIsOpen"
                    placement="right"
                    size="min"
                    :auto-placements="['right']"
                    class="group !bg-gray-100"
                  >
                    <UserCircleIcon
                      class="h-8 w-8 transform stroke-[1.5] text-gray-500 duration-100 hover:text-sky-500 group-hover:scale-110"
                    />
                  </IconButton>
                </template>
              </ProfileMenu>
              <ChangeAccountModal v-model:open="selectAccountIsOpen" />
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from "vue";

import { getNavigation, getRouteIdByPath } from "./routes";
import type { RoutesIds } from "./routes.types";

// Components
import Link from "./components/Link.vue";
import HomeLink from "./components/HomeLink.vue";
import ProfileMenu from "./components/ProfileMenu.vue";
import NotificationList from "./components/NotificationList.vue";
import IconButton from "@atoms/IconButton.vue";
import ChangeAccountModal from "@organisms/ChangeAccount/ChangeAccountModal.vue";
import LoadingSpinner from "@atoms/LoadingSpinner.vue";

// Icons
import Perfit from "@tokens/icons/Perfit.vue";
import { SpeakerphoneIcon, BellIcon } from "@heroicons/vue/outline";
import { UserCircleIcon } from "@heroicons/vue/solid";

// Utils
import { useI18n } from "vue-i18n";

// Stores
import { storeToRefs } from "pinia";
import { useFeaturesStore, useSessionStore } from "@store";

// Application
import { useRouterApp } from "@application";

const { t } = useI18n();

const routerApp = useRouterApp();

const featuresStore = useFeaturesStore();
const sessionStore = useSessionStore();

const navigation = ref(getNavigation());
const { features } = storeToRefs(featuresStore);

const allowedHomeAccess = computed(() => {
  const homeFeature = features.value?.ALLOW_HOME_ACCESS === "1";
  const isMasterUser = sessionStore.session.isMasterUser;

  return homeFeature || isMasterUser;
});

const updateNavigation = () => {
  const showLegacyTemplates =
    features.value?.HIDE_LEGACY_TEMPLATES === undefined || features.value?.HIDE_LEGACY_TEMPLATES === "0";

  navigation.value = getNavigation({ legacyTemplates: showLegacyTemplates });
};

watch([features], () => {
  updateNavigation();
});

const current = ref<RoutesIds>();

const getRouteById = (pathname: string) => {
  const routeId = getRouteIdByPath(pathname);
  if (!routeId) {
    current.value = undefined;
    return;
  }
  current.value = routeId;
};

const goToHome = (event: Event) => {
  event.preventDefault();
  event.stopPropagation();

  routerApp.navigate({
    path: "home"
  });
};

const notificationCount = ref(0);
const updateNotificationCount = (newCount: number) => {
  notificationCount.value = newCount;
};

const notificationsOpen = ref(false);
const openNotifications = () => {
  notificationsOpen.value = true;
};

const selectAccountIsOpen = ref(false);
const openSelectAccount = () => {
  selectAccountIsOpen.value = true;
};

const loadingTask = ref(false);
const setLoadingTask = () => {
  loadingTask.value = true;
};
const setLoadingTaskFinished = () => {
  loadingTask.value = false;
};

document.addEventListener("updateNavigation", function (event) {
  getRouteById(location.pathname);
});

onMounted(async () => {
  getRouteById(location.pathname);
  if (window.Canny) {
    window.Canny("initChangelog", {
      appID: "5b5261f2ca89de4e79c4296b",
      position: "top",
      align: "right"
    });
  }
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "profileButton": "Perfil",
    "notificationsButton": "Notificaciones",
    "cannyButton": "Novedades"
  },
  "pt": {
    "profileButton": "Perfil",
    "notificationsButton": "Notificações",
    "cannyButton": "Novidades"
  }
}
</i18n>

<!-- <style scoped>
::-webkit-scrollbar {
  width: 0.3rem;
  border-radius: 9999px;
}

::-webkit-scrollbar-track {
  border-radius: 9999px;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  @apply bg-neutral-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
  border-radius: 9999px;
}
span {
  display: inline-block;
}
span[contenteditable]:empty::before {
  content: attr(data-placeholder);
  display: inline-block;
  color: rgb(180, 180, 180);
  opacity: 70%;
}
</style> -->
