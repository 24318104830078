<template>
  <div>
    <PageTemplate
      :permissions="['templates:list']"
      :title="t('title')"
      :links="{ es: { helpId: 7150769, tourId: 422946 }, pt: { helpId: 7150769, tourId: 424267 } }"
      :tabs="tabs"
      :selected="selectedTab"
      @update:selected="updateSelectedTab"
    >
      <template #options>
        <PermissionsButton
          v-if="templatesListPermission"
          :permissions="['templates:create']"
          theme="green-lime"
          data-intercom-target="TemplateGalleryCreatePrivate"
          @click="createNewTemplate"
        >
          <template #leading>
            <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
          </template>
          {{ t("createNewTemplateButton") }}
        </PermissionsButton>
      </template>
      <template #content>
        <div v-if="templatesListPermission" id="gallery-content-filters" />
      </template>
      <template #templateGallery>
        <TemplateGalleryTab
          v-if="mounted"
          :selected-category="selectedCategory"
          :allow-link="true"
          class="max-h-full overflow-auto"
          @update:selected-category="updateSelectedCategory"
          @select="selectPublicTemplate"
        />
      </template>
      <template #myTemplates>
        <MyTemplatesTab
          v-if="mounted"
          :allow-link="true"
          class="h-full"
          @select="selectTemplate"
          @duplicated="onTemplateDuplicated"
          @editTags="editTags"
          @deleted="onTemplateDeleted"
        />
      </template>
    </PageTemplate>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";

import { getTabs } from "./externalTemplateGallery.data";
import type { ExternalTemplateGalleryTabs } from "./externalTemplateGallery.types";

//Components
import PageTemplate from "@templates/PageTemplate";
import TemplateGalleryTab from "@organisms/Templates/TemplateGallery/components/TemplateGalleryCatalog/TemplateGalleryCatalog.vue";
import MyTemplatesTab from "@organisms/Templates/TemplateGallery/components/TabOwnCatalog/TemplateOwnCatalog.vue";
import PermissionsButton from "@organisms/Permissions/PermissionsButton";

//Icons
import { PlusSmIcon } from "@heroicons/vue/outline";

// Store
import { useSessionStore } from "@store";

// I18n
import { useI18n } from "vue-i18n";

// Type
import type { Item, Items } from "@templates/PageTemplate";
import { TemplateItem } from "@/vue/types/Templates";

const { t } = useI18n();
const sessionStore = useSessionStore();

const templatesListPermission = sessionStore.hasPermission("templates:list");

//Props
const props = withDefaults(
  defineProps<{
    initialTab?: "templateGallery" | "myTemplates";
    selectedCategory?: string;
  }>(),
  {
    initialTab: undefined,
    selectedCategory: undefined,
  }
);

//Emits
const emit = defineEmits<{
  (e: "select", selectedTemplateData: { templateItem: TemplateItem; isPublicTemplate: boolean }): void;
  (e: "createNewTemplate"): void;
  (e: "tabChanged", value: "templateGallery" | "myTemplates"): void;
  (e: "duplicated", template: TemplateItem): void;
  (e: "editTags", template: TemplateItem): void;
  (e: "deleted", template: TemplateItem): void;
  (e: "categoryChange", value: string): void;
}>();

const createNewTemplate = () => emit("createNewTemplate");

const editTags = (template: TemplateItem) => {
  emit("editTags", template);
};

const onTemplateDeleted = (template: TemplateItem) => {
  emit("deleted", template);
};

const onTemplateDuplicated = (template: TemplateItem) => {
  emit("duplicated", template);
};

//Tabs
const tabs: Items<ExternalTemplateGalleryTabs> = getTabs();
const selectedTab = ref<Item<ExternalTemplateGalleryTabs>>(tabs.find((tab) => tab.key === props.initialTab) ?? tabs[0]);
const updateSelectedTab = (newTab: Item<ExternalTemplateGalleryTabs>) => {
  emit("tabChanged", newTab.key);
  selectedTab.value = newTab;
};

// Category
const updateSelectedCategory = (categoryId: string) => {
  emit("categoryChange", categoryId);
};

//Preview Template
const selectTemplate = (templateItem: TemplateItem) => {
  emit("select", { templateItem, isPublicTemplate: false });
};

const selectPublicTemplate = (templateItem: TemplateItem) => {
  emit("select", { templateItem, isPublicTemplate: true });
};

const mounted = ref(false);

onMounted(() => {
  mounted.value = true;
});
</script>

<i18n lang="jsonc">
{
  "es": {
    "createNewTemplateButton": "Crear plantilla",
    "title": "Plantillas",
    "tabs": {
      "publicGallery": "Galería pública",
      "myTemplates": "Mis plantillas"
    }
  },
  "pt": {
    "createNewTemplateButton": "Criar template",
    "title": "Templates",
    "tabs": {
      "publicGallery": "Galeria pública",
      "myTemplates": "Meus templates"
    }
  }
}
</i18n>
