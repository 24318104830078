import MagicGrid from "magic-grid";

/*
- Los elementos hijo de la grid deben tener el mismo ancho
*/

export interface MasonryOptions {
  container: string | HTMLElement;
  itemCount: number;
  staticItems?: boolean;
  columnsCount?: number;
  gutter?: number;
  useMinColumns?: boolean;
  useCSSTransform?: boolean;
  animate?: boolean;
}

export const useMasonryGrid = ({
  container,
  staticItems = false,
  itemCount,
  columnsCount,
  gutter,
  useMinColumns,
  useCSSTransform,
  animate,
}: MasonryOptions) => {
  const magicGrid = new MagicGrid({
    container: container, // Required. Can be a class, id, or an HTMLElement
    static: staticItems, // Required for static content.
    items: itemCount, // Required for dynamic content. Number of elements
    maxColumns: columnsCount, // Optional. Maximum number of columns. Default: Infinite.
    gutter: gutter, // Optional. Space between items. Default: 25(px).
    useMin: useMinColumns, // Optional. Prioritize shorter columns when positioning items. Default: false.
    useTransform: useCSSTransform, // Optional. Position items using CSS transform. Default: True.
    animate: animate, // Optional. Animate item positioning. Default: false.
  });
  magicGrid.listen();
  return magicGrid;
};
